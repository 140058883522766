import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBusinessFunctionalityById, selectCurrentBusinessFunctionality, updateBusinessFunctionality,
} from 'services/adminSlice';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import FunctionalityForm from './FunctionalityForm';

export default function EditFunctionality() {
  const currentFunctionality = useSelector(selectCurrentBusinessFunctionality);
  const { functionalityId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getBusinessFunctionalityById(functionalityId));
  }, [functionalityId]);

  const updateFunctionality = (data) => {
    dispatch(updateBusinessFunctionality({ ...data, id: parseInt(functionalityId, 10) }));
    navigate('/admin/businessFunctionalities');
  };

  return (
    <>
      <Typography variant="h2">Edit Market Functionality</Typography>
      <FunctionalityForm onSubmit={updateFunctionality} functionality={currentFunctionality} />
    </>
  );
}
