/* eslint-disable max-len */
import * as yup from 'yup';
import i18n from 'i18n/i18n';
import { urlValidationRegex } from 'shared/constants';

const { t } = i18n;

const schema = {
  advertiserEmail: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.advertiserEmailErrorMessage')),
  businessNameForCallVerification: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.businessNameForCallVerificationErrorMessage')),
  businessWebsiteUrl: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.businessWebsiteUrlErrorMessage'))
    .matches(urlValidationRegex, t('pages.socialAds.socialAdsWebClicksSection.inputError.landingPageRequired')),
  isCaptchaInstalled: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.isCaptchaInstalledErrorMessage')),
  trackingForTheCaptchaPiece: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.trackingForTheCaptchaPieceErrorMessage')),
  clientHaveAnotherMaidAccount: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.clientHaveAnotherMaidAccountErrorMessage')),
  clientFullName: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.clientFullNameErrorMessage')),
  webEventType: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.webEventTypeErrorMessage')),
  facebookWebsiteUrl: yup.string().required(t('pages.socialAds.socialAdsWebClicksSection.inputError.landingPageRequired'))
    .matches(urlValidationRegex, t('pages.socialAds.socialAdsWebClicksSection.inputError.landingPageRequired')),
  facebookAdminAccessEmail: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.facebookAdminAccessEmailErrorMessage')),
  socialMockupApprovalEmail: yup.string().required(t('pages.xmo.solutionsStrategy.proxy.socialMockupApprovalEmailErrorMessage')),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
