import { StyledComponent } from '@emotion/styled';
import { IQButtonRadioContainer, IQTheme } from '@gannettdigital/shared-react-components';
import { Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { radioButtonOptions } from './FormControlsList';

interface Props {
  StyledSubHeading: StyledComponent<any>;
  StyledGridItem: StyledComponent<any>;
  theme: typeof IQTheme;
}

const BasicInfo = ({ StyledSubHeading, StyledGridItem, theme } :Props) => {
  const { t } = useTranslation();

  const basicRadioButtonElements = radioButtonOptions.map(option => (
    <StyledGridItem key={option.name} item theme={theme}>
      <IQButtonRadioContainer
        fontTitleWeight={option.fontTitleWeight}
        label={option.label}
        // @ts-ignore
        optionsList={option.options}
        name={option.name}
      />
    </StyledGridItem>
  ));

  return (
    <>
      <StyledSubHeading variant="h5" theme={theme}>
        {t('pages.addons.popUp.subTitle')}
      </StyledSubHeading>

      <Grid gap="24px" container direction="column"
        justifyContent="flex-start"
        sx={{
          'div:last-child > div': { display: 'flex', flexDirection: 'column' },
          'div:last-child > div > button': { maxWidth: '100%' },
          'button > label': { mb: 0, ml: '-7px' },
          '.MuiButtonBase-root': { background: 'transparent' },
          '.MuiButtonBase-root:hover': { background: 'transparent' },
          '.MuiButtonBase-root:focus': { background: 'transparent' },
          '.MuiButtonBase-root.Mui-selected': { background: 'transparent' },
          '.MuiButtonBase-root.Mui-selected:hover': { background: 'transparent' },
          '.MuiButtonBase-root.Mui-selected:focus': { background: 'transparent' },
          '.MuiTouchRipple-root > *': { display: 'none' },
        }}
      >
        {basicRadioButtonElements}
      </Grid>
    </>
  );
};

export default BasicInfo;
