import { FormProvider, useForm } from 'react-hook-form';

import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import Box from '@mui/material/Box';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';

import i18n from 'i18n/i18n';
import {
  Grid, InputAdornment, TextField, Typography,
} from '@mui/material';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import PiQWebsiteType from 'models/PiQWebsiteType';
import ErrorText from 'components/errorText/ErrorText';
import schemaValidate from 'components/schemaValidate';
import { inputStyles } from 'pages/seo/details/DetailsPageForm';
import schema from './PiQWebsite.schema';

const { t } = i18n;

interface Props {
  product: PiQWebsiteType;
  onSubmit: (data: PiQWebsiteFormProps) => void;
  onBack: () => void;
}

export type PiQWebsiteFormProps = {
  currentWebsite: string;
};

export default function PiQWebsitePageForm({ product, onSubmit, onBack }: Props) {
  const methods = useForm<PiQWebsiteFormProps>({
    mode: 'onChange',
    defaultValues: {
      currentWebsite: product?.currentWebsite || '',
    },
  });

  const handleFormSubmit = (data: PiQWebsiteFormProps) => onSubmit(data);

  const {
    handleSubmit,
    register,
    watch,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
      errors,
    },
  } = methods;

  const skipAndContinue = watch('currentWebsite') === '';

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={onBack}
      header={t('pages.piq.website.header')}
      onContinue={handleSubmit(handleFormSubmit)}
      skipAndContinue={skipAndContinue}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Grid container xs={12} sx={{ ...inputStyles }}>
              <Grid item mb={3} xs={9}>
                <Grid item mb={1}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}
                    color={errors.currentWebsite ? 'error.main' : 'common.black'}
                  >
                    Current Website for Reference
                  </Typography>
                </Grid>
                <TextField
                  hiddenLabel
                  required
                  name="currentWebsite"
                  error={!!errors.currentWebsite}
                  fullWidth
                  {...register('currentWebsite', {
                    validate: (value) => schemaValidate(value, 'currentWebsite', schema),
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorText
                  hasError={!!errors.currentWebsite}
                  errorText="Please enter a valid URL that includes either http:// or https://."
                />
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
