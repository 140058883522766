import * as yup from 'yup';

const schema = {
  options: yup.string().required(),
  contentTones: yup.array().required()
    .min(1, 'Please select at least one item.')
    .max(2, 'Please review and select only two items to continue.')
    .typeError('Please select at least one item.'),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
