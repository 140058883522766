import * as yup from 'yup';
import {FormControlLabel, Grid, Switch, TextField, Typography} from '@mui/material';
import ErrorText from 'components/errorText/ErrorText';
import i18n from 'i18n/i18n';
import schemaValidate from 'components/schemaValidate';
import { hideNumberFieldArrows } from '../GeneralQuestionsForm';
import schema from '../GeneralQuestions.schema';
import { useEffect} from "react";
import {useSelector} from "react-redux";
import {selectIsPartnerFlow, selectOrdersContent} from "services/ordersSlice";
import {OrderFlow, products} from "shared/constants";
import {selectProduct} from "services/productsSlice";

const { t } = i18n;

const budgetLimitError = (limits) => (
    `The budget value is not between $${limits.min} and $${limits.max}. Select Budget Override if the budget is out of the specified range.`
);

export const RenderBudgetField = (errors, register, limits, watch, setValue, clearErrors, trigger) => {
  const showBudgetOverride = watch('budgetOverride');
  const budgetValue = watch('budget')
  const isPartnerFlow = useSelector(selectIsPartnerFlow);
  const {orderFlow} = useSelector(selectOrdersContent);
  const product: any = useSelector(selectProduct);

  const handleShowBudgetOverride = (event) => {
    setValue('budgetOverride', event.target.checked);

    if (event.target.checked) {
      clearErrors('budget');
    }
  };

  if (showBudgetOverride) {
    schema.yupValidations.budget = yup.number()
    .transform((value, originalValue) => {
      return originalValue.trim() === '' ? null : value;
    }).nullable()
    .min(0).required(t('pages.generalQuestions.budget.error'))
  } else {
    schema.yupValidations.budget = yup.number()
    .typeError(t('pages.generalQuestions.budget.error'))
    .min(limits.min, budgetLimitError(limits))
    .max(limits.max, budgetLimitError(limits))
    .required(t('pages.generalQuestions.budget.error'));
  }

  useEffect(() => {
    if (showBudgetOverride) {
      clearErrors('budget');
    }
    if (!isPartnerFlow && orderFlow !== OrderFlow.INTERNAL) trigger('budget')
    if (budgetValue < 0) trigger('budget')
  }, [showBudgetOverride, clearErrors, budgetValue, isPartnerFlow, orderFlow]);

  useEffect(() => {
    if (budgetValue >= limits.min && budgetValue <= limits.max) {
      setValue('budgetOverride', false);
    }
  }, [budgetValue, limits.min, limits.max, setValue, trigger]);

  return (
  <>
    <TextField
      hiddenLabel
      required
      id="budget"
      name="budget"
      type="number"
      fullWidth
      error={!!errors.budget}
      sx={hideNumberFieldArrows}
      disabled={limits.min === limits.max || product?.content?.productType?.offeringType === products.CUSTOM_TRACKING}
      InputProps={{
        startAdornment: <Typography variant="body1">$</Typography>,
      }}
      {...register('budget', {
        validate: (value) => schemaValidate(value, 'budget', schema),
      })}
    />
    <ErrorText
      hasError={!!errors.budget}
      errorText={errors?.budget?.message}
    />

    {(budgetValue < limits.min || budgetValue > limits.max) &&
        <>
          <Grid mt={1}>
            <FormControlLabel
                control={(
                    <Switch
                        checked={showBudgetOverride}
                        onChange={handleShowBudgetOverride}
                        inputProps={{'aria-label': 'controlled'}}
                    />
                )}
                label={<>
                  <Typography variant="subtitle2" component="span">
                    Budget Override:{" "}
                  </Typography>
                  <Typography variant="body1" component="span" fontSize={14}>
                    The approved budget will be displayed in the Order Summary, but must be updated
                    manually in Campaign Central shopping cart.
                  </Typography>
                </>
                }
            />
          </Grid>
        </>
    }
  </>
  )
};
