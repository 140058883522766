import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import { Urls } from 'navigation/Urls';
import EcommerceType, { EcommerceStateObject } from 'models/EcommerceType';
import { StatusType } from 'models/StatusType';
import AgeGateForm from './AgeGateForm';

export default function AgeGate() {
  const { product, onBack, onSubmit } = useProduct<EcommerceType,
  StatusType<EcommerceStateObject>>(Urls.AgeGate);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <AgeGateForm onBack={onBack} onSubmit={onSubmit} product={product.content?.data} />
    </ContentLoader>
  );
}
