import {
  Grid, Typography, Radio, RadioGroup, FormControlLabel,
} from '@mui/material';
import schemaValidate from 'components/schemaValidate';
import { Children } from 'react';
import ContactForm from 'pages/seo/details/ContactForm';
import i18n from 'i18n/i18n';
import schema from './SMMDetailsPage.schema';

const { t } = i18n;

export const renderContactInfo = (props) => {
  const {
    campaignContact,
    setCampaignContact,
    isCampaignContactOther,
    accessContact,
    setAccessContact,
    isAccessContactOther,
    clientOptions,
    register,
    errors,
  } = props;

  return (
    <>
      <Grid item mb={4} xs={12}>
        <Typography variant="h5" component="h2">
          {t('pages.smm.details.contactInfoSectionLabel')}
        </Typography>
      </Grid>
      <Grid item mb={3} xs={12}
        sx={{
          '& .MuiFormGroup-root[role=radiogroup] .MuiFormControlLabel-root': {
            width: 'fit-content',
            display: 'flex',
            alignItems: 'flex-start',
          },
        }}
      >
        <Grid mb={1}>
          <Typography variant="subtitle2" component="h3" fontSize={16}>
            {t('pages.smm.details.campaignContactLabel')}
          </Typography>
          <RadioGroup
            aria-labelledby="campaignContact"
            name="campaignContact"
            value={campaignContact}
            onChange={(e) => setCampaignContact(e.target.value)}
            sx={{ width: '100%' }}
          >
            {clientOptions.map(option => (
              <FormControlLabel
                key={option.value}
                {...register('campaignContact', {
                  validate: (value) => schemaValidate(value, 'campaignContact', schema),
                })}
                value={option.value}
                control={<Radio />}
                sx={{
                  width: '50% !important',
                  marginRight: '0 !important',
                  '@media (max-width: 780px)': {
                    width: '100% !important',
                  },
                }}
                label={(
                  <Typography paragraph style={{ marginTop: '8px' }}>
                    {Children.toArray(option.label.split('\n').map((line) => (
                      <>
                        {line}
                        {line !== 'Other' ? <br /> : null}
                      </>
                    )))}
                  </Typography>
                )}
              />
            ))}
          </RadioGroup>
        </Grid>
        {isCampaignContactOther && (
        <ContactForm
          name="campaignContactName"
          email="campaignContactEmail"
          phone="campaignContactPhone"
          phoneExtension="campaignContactPhoneExtension"
          register={register}
          errors={errors}
          schema={schema}
          phoneRequired={false}
        />
        )}
      </Grid>
      <Grid item mb={3} xs={12}
        sx={{
          '& .MuiFormGroup-root[role=radiogroup] .MuiFormControlLabel-root': {
            width: 'fit-content',
            display: 'flex',
            alignItems: 'flex-start',
          },
        }}
      >
        <Grid mb={1}>
          <Typography variant="subtitle2" component="h3" fontSize={16}>
            {t('pages.smm.details.accessContactLabel')}
          </Typography>
          <RadioGroup
            aria-labelledby="accessContact"
            name="accessContact"
            value={accessContact}
            onChange={(e) => setAccessContact(e.target.value)}
            sx={{ width: '100%' }}
          >
            {clientOptions.map(option => (
              <FormControlLabel
                key={option.value}
                {...register('accessContact', {
                  validate: (value) => schemaValidate(value, 'accessContact', schema),
                })}
                value={option.value}
                control={<Radio />}
                sx={{
                  width: '50% !important',
                  marginRight: '0 !important',
                  '@media (max-width: 780px)': {
                    width: '100% !important',
                  },
                }}
                label={(
                  <Typography paragraph style={{ marginTop: '8px' }}>
                    {Children.toArray(option.label.split('\n').map((line) => (
                      <>
                        {line}
                        {line !== 'Other' ? <br /> : null}
                      </>
                    )))}
                  </Typography>
                )}
              />
            ))}
          </RadioGroup>
        </Grid>
        {isAccessContactOther && (
        <ContactForm
          name="accessContactName"
          email="accessContactEmail"
          phone="accessContactPhone"
          phoneExtension="accessContactPhoneExtension"
          register={register}
          errors={errors}
          schema={schema}
          phoneRequired={false}
        />
        )}
      </Grid>
    </>
  );
};
