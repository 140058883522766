/*
case: 1
In case you require custom URL validation with a custom message, the implementation should be like this:

componentName: {
    type: 'string',
    title: 'enter the title',
    customUrlValidation: true,
    urlValidationParameter: [
        'https://instagram.com', 'https://instagr.co' or pass custom validation parameter
    ],
    urlValidationErrorText: t('pages.smmInstagram.solutionStrategy.instagramBusinessPageURLError')
}
case:2
If you require only a custom message but with generic URL validation, the implementation should be like this:

componentName: {
    type: 'string',
    title: 'enter the title',
    urlValidationErrorText: 'custom validation message'
}

case 3
If you need to validate custom regex pattern for any url, the implementation should look like this:
componentName: {
  type: 'string',
  title: 'enter the title',
  customValidationPattern: /[a-z]+/gm  // pass any regex pattern you want
}
*
* */

import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import {
  FormHelperText, Grid, InputAdornment, TextField, Typography,
} from '@mui/material';
import ErrorText from 'components/errorText/ErrorText';
import { useState } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import i18n from 'i18n/i18n';
import { urlValidationRegex } from 'shared/constants';

const { t } = i18n;

function isValidURL(props:any, url:any) {
  // custom url validation if required
  if (props.schema.customUrlValidation) {
    return props.schema.urlValidationParameter.some((startParam:any) => url.startsWith(startParam));
  }
  if(props.schema.customValidationPattern)
    return props.schema.customValidationPattern.test(url);
  return urlValidationRegex.test(url);
}

export const validateUrl = (url: string) => urlValidationRegex.test(url);

export default function UrlTextField(props: any) {
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const { urlValidationErrorText } = props.schema;

  const handleBlur = () => {
    setIsTouched(true);
    if (props.value?.length > 0) {
      setIsValid(isValidURL(props, props.value));
    }
  };

  const handleChange = (value) => {
    props.onChange(value);
    setIsTouched(true);
    if (value.length > 0) {
      setIsValid(isValidURL(props, value));
    } else {
      setIsValid(true);
    }
  };

  const showError = ((props.value?.length === 0) && isTouched) || ((props.value?.length > 0) && !isValid);

  return (
    <Grid item xs={9} mt={3}>
      <Typography
        fontWeight={600}
        sx={{
          color: showError ? IQThemeV2.palette.error.main : IQThemeV2.palette.text.primary,
        }}
      >
        {props.label}
      </Typography>
      <TextField
        sx={{ mt: 1 }}
        id={props.id}
        disabled={props.disabled}
        name={props.name}
        type='text'
        error={showError}
        onBlur={handleBlur}
        value={props.value}
        onChange={(e) => handleChange(e.target.value)}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LinkIcon />
            </InputAdornment>
          ),
          'aria-invalid': showError,
          required: false,
          onInvalid: (e) => e.preventDefault(),
        }}
      />
      {(props.placeholder && (!showError)) && (
        <FormHelperText error={false}>{props.placeholder}</FormHelperText>
      )}
      <ErrorText
        hasError={showError}
        errorText={showError
          ? (props?.rawErrors?.[0] || (urlValidationErrorText || t('pages.genericUrlErrorMessage.urlErrorMessage')))
          : ''}
      />
    </Grid>
  );
}
