/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import AddWebEvents from 'pages/generic-product/details/custom-fields/AddWebEvents';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';

const { t } = i18n;

export const CustomTrackingSchema: RJSFSchema = {
  title: t('pages.customTracking.solutionStrategy.pageTitle'),
  // eslint-disable-next-line max-len
  type: 'object',
  required: [
    'clientHaveLocaliqWebsite',
    'whatWillBeTracked',
    'implementUtmStrings',
    'websiteHaveCaptchaInstalled',
    'clientHaveAnotherAccountTiedToCampaign',
  ],
  properties: {
    clientHaveLocaliqWebsite: {
      title: t('pages.customTracking.solutionStrategy.clientWebsiteLabel'),
      type: 'string',
      default: '',
      enum: [
        'Yes',
        'No',
      ],
    },
    whatWillBeTracked: {
      title: t('pages.customTracking.solutionStrategy.trackedLabel'),
      type: 'string',
      default: '',
      enum: [
        'Phone Only',
        'Phone and Web',
      ],
    },
    areaCodePreference1: {
      type: 'string',
      title: t('pages.customTracking.solutionStrategy.areaCodePreference1Label'),
      default: '',
    },
    areaCodePreference2: {
      type: 'string',
      title: t('pages.customTracking.solutionStrategy.areaCodePreference2Label'),
      default: '',
    },
    primaryNumberToBeProvisioned: {
      type: 'string',
      title: t('pages.customTracking.solutionStrategy.numberToBeProvisionedLabel'),
      default: '',
    },
    primaryBusinessNumberForwardToDifferentNumber: {
      title: t('pages.customTracking.solutionStrategy.primaryBusinessNumberLabel'),
      type: 'string',
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    implementUtmStrings: {
      title: t('pages.customTracking.solutionStrategy.utmStringsLabel'),
      type: 'string',
      default: '',
      enum: [
        'Yes - Use my UTM strings only',
        'Yes - Use UTM strings Best Practices',
        'No - Do NOT use UTM strings',
      ],
    },
    websiteHaveCaptchaInstalled: {
      title: t('pages.customTracking.solutionStrategy.captchaInstalledLabel'),
      type: 'string',
      default: '',
      enum: [
        'Yes',
        'No',
      ],
    },
    clientHaveAnotherAccountTiedToCampaign: {
      title: t('pages.customTracking.solutionStrategy.anotherAccountLabel'),
      type: 'string',
      default: '',
      enum: [
        'Yes',
        'No',
      ],
    },
    mmrCodeHelperText: {
      type: 'string',
      title: t('pages.customTracking.solutionStrategy.mmrCodeLabel'),
      enum: [
        'https://gannett.sharepoint.com/:w:/s/TheSource/EXlaLcJ8StRNtXMBKR1FbWgBWP0K_posivJmdPjdShbaXA?e=65Ar66',
        true,
      ],
    },
    doNotProxyFollowingAreasOfTheWebsite: {
      type: 'string',
      title: t('pages.customTracking.solutionStrategy.areasOfTheWebsiteLabel'),
      default: '',
      description: t('pages.customTracking.solutionStrategy.proxyHelperTextLabel'),
    },
    webEvents: {
      title: t('pages.customTracking.solutionStrategy.webEventsLabel'),
      type: 'string',
      default: '',
      enum: [
        '',
        'Please adjust web events per best practices',
        'Use only my web events',
      ],
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.customTracking.solutionStrategy.notesLabel'),
      default: '',
    },
  },
  dependencies: {
    clientHaveLocaliqWebsite: {
      oneOf: [
        {
          properties: {
            clientHaveLocaliqWebsite: {
              enum: ['Yes'],
            },
            isReachsiteLive: {
              title: t('pages.customTracking.solutionStrategy.clientWebsiteLiveLabel'),
              type: 'string',
              default: '',
              enum: [
                '',
                'Yes',
                'No',
              ],
            },
          },
        },
      ],
    },
    isReachsiteLive: {
      oneOf: [
        {
          properties: {
            isReachsiteLive: {
              enum: ['No'],
            },
            temporaryLink: {
              type: 'string',
              title: t('pages.customTracking.solutionStrategy.temporaryLinkLabel'),
              default: '',
              description: t('pages.customTracking.solutionStrategy.temporaryLinkHelperTextLabel'),
            },
          },
        },
      ],
    },
    whatWillBeTracked: {
      oneOf: [
        {
          properties: {
            whatWillBeTracked: {
              enum: ['Phone and Web'],
            },
            urlForWebsiteTracking: {
              type: 'string',
              title: t('pages.customTracking.solutionStrategy.urlWebsiteTrackingLabel'),
              default: '',
            },
          },
        },
      ],
    },
    primaryBusinessNumberForwardToDifferentNumber: {
      oneOf: [
        {
          properties: {
            primaryBusinessNumberForwardToDifferentNumber: {
              enum: ['Yes'],
            },
            forwardingNumber: {
              type: 'string',
              title: t('pages.customTracking.solutionStrategy.forwardingNumberLabel'),
              default: '',
            },
          },
        },
      ],
    },
    implementUtmStrings: {
      oneOf: [
        {
          properties: {
            implementUtmStrings: {
              enum: ['Yes - Use my UTM strings only'],
            },
            useThisUtm: {
              type: 'string',
              title: t('pages.customTracking.solutionStrategy.utmLabel'),
              default: '',
              description: t('pages.customTracking.solutionStrategy.utmHelperTextLabel'),
            },
          },
        },
      ],
    },
    websiteHaveCaptchaInstalled: {
      oneOf: [
        {
          properties: {
            websiteHaveCaptchaInstalled: {
              enum: ['Yes'],
            },
            canProvisioningProceedWithBreakingTrackingForCaptchaPiece: {
              title: t('pages.customTracking.solutionStrategy.captchaPieceLabel'),
              type: 'string',
              default: '',
              enum: [
                '',
                'Yes',
                'No, please hold campaign until client is able to remove CAPTCHA',
              ],
            },
          },
        },
      ],
    },
    webEvents: {
      oneOf: [
        {
          properties: {
            webEvents: {
              enum: ['Use only my web events'],
            },
            webEventItems: {
              type: 'string',
              items: {
                type: 'object',
              },
              title: 'Web Events',
              default: '',
            },
          },
        },
      ],
    },
  },
};

const textFieldKeys = [
  'areaCodePreference1', 'areaCodePreference2', 'useThisUtm',
];

const dropdownFieldKeys = [
  'isReachsiteLive', 'primaryBusinessNumberForwardToDifferentNumber',
  'canProvisioningProceedWithBreakingTrackingForCaptchaPiece', 'webEvents',
];

const textAreaFieldKeys = [
  'notesOrInstructions', 'doNotProxyFollowingAreasOfTheWebsite',
];

const urlFieldKeys = [
  'temporaryLink', 'urlForWebsiteTracking',
];

const phoneNumberTextFieldKeys = [
  'primaryNumberToBeProvisioned', 'forwardingNumber',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'textInputField' },
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'dropdown' },
}), {});

const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const urlFields = urlFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'UrlTextWidget' },
}), {});

const phoneNumberTextFields = phoneNumberTextFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'phoneNumberTextField' },
}), {});

export const CustomTrackingUISchema = (data) => ({
  'ui:order': ['clientHaveLocaliqWebsite', 'isReachsiteLive', 'temporaryLink',
    'whatWillBeTracked', 'urlForWebsiteTracking', 'areaCodePreference1', 'areaCodePreference2',
    'primaryNumberToBeProvisioned', 'primaryBusinessNumberForwardToDifferentNumber', 'forwardingNumber',
    'implementUtmStrings', 'useThisUtm', 'websiteHaveCaptchaInstalled',
    'canProvisioningProceedWithBreakingTrackingForCaptchaPiece', 'clientHaveAnotherAccountTiedToCampaign',
    'mmrCodeHelperText', 'doNotProxyFollowingAreasOfTheWebsite', 'webEvents', 'webEventItems',
    'webEventUrl', 'webEventName', 'value', 'notesOrInstructions'],
  inActivatePublishers: {
    'ui:widget': 'checkboxes',
  },
  mmrCodeHelperText: {
    'ui:widget': 'anchorTextLabel',
  },
  clientInformation: {
    'ui:widget': 'headingTextLabel',
  },
  webEventItems: {
    'ui:widget': (props) => <AddWebEvents {...props} data={data} />,
  },
  clientHaveLocaliqWebsite: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select one of the options from the dropdown"
      />
    ),
  },
  whatWillBeTracked: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select one of the options from the dropdown"
      />
    ),
  },
  implementUtmStrings: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select one of the options from the dropdown"
      />
    ),
  },
  websiteHaveCaptchaInstalled: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select one of the options from the dropdown"
      />
    ),
  },
  clientHaveAnotherAccountTiedToCampaign: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select one of the options from the dropdown"
      />
    ),
  },
  ...textFields,
  ...dropdownFields,
  ...textAreaFields,
  ...urlFields,
  ...phoneNumberTextFields,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CustomTrackingCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.isReachsiteLive === 'No') {
    if (formData?.temporaryLink !== '' && !validateUrl(formData?.temporaryLink)) {
      errors.temporaryLink.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.whatWillBeTracked === 'Phone and Web') {
    if (formData?.urlForWebsiteTracking !== '' && !validateUrl(formData?.urlForWebsiteTracking)) {
      errors.urlForWebsiteTracking.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  return errors;
};
