/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';

const { t } = i18n;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const facebookBoostedPostsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.smm.solutionStrategy.title'),
  pageDescription: t('pages.smm.solutionStrategy.pageDescription'),
  type: 'object',
  required: ['topicsToPrioritize'],
  properties: {
    topicsToPrioritize: {
      type: 'string',
      title: t('pages.smm.solutionStrategy.facebookTopicsToPrioritizeLabel'),
      default: '',
    },
    topicsToAvoid: {
      type: 'string',
      title: t('pages.smm.solutionStrategy.topicsToAvoid'),
      default: '',
    },
    additionalNotesOrInstructions: {
      type: 'string',
      title: t('pages.smm.solutionStrategy.additionalNotesLabel'),
      default: '',
    },
  },
});

const textFieldKeys = [
  'topicsToPrioritize',
  'topicsToAvoid',
  'additionalNotesOrInstructions',
];

const textFields = textFieldKeys.reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'TextareaWidget' } }), {});

export const facebookBoostedPostsUISchema = {
  'ui:order': ['facebookBusinessPageUrl', 'topicsToPrioritize',
    'topicsToAvoid', 'additionalNotesOrInstructions'],
  facebookBusinessPageUrl: {
    'ui:widget': 'UrlTextWidget',
  },
  ...textFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const facebookBoostedPostsCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.topicsToPrioritize === '') {
    errors.topicsToPrioritize.addError(t('pages.smm.solutionStrategy.facebookTopicsToPrioritizeErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
