import * as yup from 'yup';

// eslint-disable-next-line max-len
const httpRegex = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const schema = {
  existingWebsiteSelection: yup.string().required().nullable(),
  existingLandingPagesSelection: yup.string().required().nullable(),
  existingWebsiteUrl: yup.string()
    .url('Please enter a valid URL that includes either http:// or https://.').optional(),
  urlsToMigrate: yup.string().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
