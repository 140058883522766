import i18n from 'i18n/i18n';
import * as yup from 'yup';

const {t} = i18n;

const closesAtSchema = yup.string().nullable().when(['opensAt'], {
  is: (opensAt) => opensAt != null,
  then: yup.string().required().typeError(t('pages.business.location.hours.hoursOfOperation.closingHourErrorMessage')),
});

const daysSchema = yup.array(yup.object({
  weekday: yup.string(),
  open: yup.boolean(),
  hours: yup.array(yup.object({
    allDay: yup.boolean().notRequired(),
    opensAt: yup.string().nullable(),
    closesAt: yup.string().nullable(),
  }))
  .when(['open'], {
    is: (open) => open,
    then: yup.array(yup.object({
      allDay: yup.boolean().notRequired(),
      opensAt: yup.string().required().typeError(t('pages.business.location.hours.hoursOfOperation.openingHourErrorMessage')),
      closesAt: closesAtSchema,
    })),
  }),
}));

const schema = yup.object().shape({
  days: daysSchema
  .test({
    name: 'oneOpenDay',
    message: t('pages.business.location.hours.hoursOfOperation.noHoursErrorMessage'),
    test: days => !!days.find(day => day.open === true),
  }),
  applyHoursToAll: yup.boolean().notRequired(),
  grandOpeningDate: yup.date().notRequired().nullable(),
});

const noDaysRequiredSchema = yup.object().shape({
  days: daysSchema
  .test({
    name: 'oneOpenDay',
    message: t('pages.business.location.hours.hoursOfOperation.noHoursErrorMessage'),
    test: days => !!days.find(day => day.open === false),
  }),
  applyHoursToAll: yup.boolean().notRequired(),
  grandOpeningDate: yup.date().notRequired().nullable(),
});

export const schemaWithDMS = {
  yupValidations: noDaysRequiredSchema,
  customValidations: {},
};

export default {
  yupValidations: schema,
  customValidations: {},
};
