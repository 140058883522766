/* eslint-disable prefer-destructuring */
import { IQLocationPLCard, ValidationProvider } from '@gannettdigital/shared-react-components';
import { DragDropContext } from 'react-beautiful-dnd';
import { Typography } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import {
  createBusinessLocation,
  deleteBusinessLocation,
  getBusinessLocations,
  selectBusinessLocations,
  selectProviders,
  setLocations,
  setProviders,
  updateLocationStatusToFalse,
  updateSingleBusinessLocation,
} from 'services/businessLocationSlice';
import {
  selectIsPartnerFlow,
  selectOrderFlow,
  selectOrderItems,
  selectOrdersContent
} from 'services/ordersSlice';
import { sortOrderItems } from 'services/menus';
import { getFirstStepOfferingType } from 'pages/redirect/ProductMoveForward';
import { Urls } from 'navigation/Urls';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { isCustomProduct } from 'layouts/leftnav/LeftNavMenu';
import { OrderFlow } from 'shared/constants';
import NeDraggableList from './NeDraggableList';

const emptyItem = {
  locationName: '',
  businessAddressOne: '',
  businessAddressTwo: '',
  city: '',
  stateProvince: '',
  country: '',
  postalCode: '',
  sourceId: '',
  provider: null,
};

const maxItems = 9;

const getLocationStatus = (location) => {
  if (location.completed) {
    return 'Complete';
  }
  return location.locationName === '' ? 'NotStarted' : 'InProgress';
};

const generateCard = (data, onDelete, onSelected, locationTypeLabel) => (
  <IQLocationPLCard
    field={data.id}
    locationTypeLabel={locationTypeLabel}
    locationStatus={getLocationStatus(data)}
    locationName={data.locationName || data.providerName}
    addressLine1={data.businessAddressOne}
    addressLine2={data.businessAddressTwo}
    city={data.city}
    state={data.stateProvince}
    zipcode={data.postalCode}
    handleDelete={onDelete}
    onSelect={onSelected}
  />
);

export default function PlHealthcareLocations() {
  const { t } = useTranslation();

  const yupSchema = {};
  const schema = {
    yupValidations: yupSchema,
    customValidations: {},
  };

  const methods = useForm({ mode: 'all' });
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const locations = useSelector(selectBusinessLocations);
  const { businessId, orderFlow: orderDataFlow } = useSelector(selectOrdersContent);
  const providers = useSelector(selectProviders);
  const { content: orderItems } = useSelector(selectOrderItems);
  const orderFlow = useSelector(selectOrderFlow);
  const [locationItems, setLocationItems] = useState([]);
  const [providerItems, setProviderItems] = useState([]);
  const [movedItem, setMovedItem] = useState({} as any);
  const [openModal, setOpenModal] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [draggingFrom, setIsDraggingFrom] = useState('');
  const isSalesForce = orderFlow === OrderFlow.SALES_FORCE;
  const isPunchlist = (orderFlow === OrderFlow.PUNCHLIST || orderDataFlow === OrderFlow.PUNCHLIST);
  const premiumListingOrderItems = orderItems.filter(item => item.offeringType === 'PremiumListing');
  const regularPremiumListingOrderItems = orderItems.filter(item => item.offeringType === 'PremiumListing'
      && !item.healthcare);
  const healthCarePremiumListingOrderItems = orderItems.filter(item => item.offeringType === 'PremiumListing'
      && item.healthcare);
  const isPartnerFlow = useSelector(selectIsPartnerFlow);

  useEffect(() => {
    if (locations) { setLocationItems(locations); }
    if (providers) { setProviderItems(providers); }
  }, [locations, providers]);

  const handleLocationDelete = async (locationId) => {
    if (locationId) {
      await dispatch(deleteBusinessLocation(locationId));
      await dispatch(getBusinessLocations(businessId));
      setLocationItems(locationItems => {
        const updatedLocations = locationItems.filter((el) => el.id !== locationId);
        return updatedLocations;
      });
    }
  };

  const handleProviderDelete = async (locationId) => {
    if (locationId) {
      await dispatch(deleteBusinessLocation(locationId));
      await dispatch(getBusinessLocations(businessId));
      await setProviderItems(providerItems => {
        const updatedProviders = providerItems.filter((el) => el.id !== locationId);
        return updatedProviders;
      });
    }
  };

  const handleLocationSelected = (locationId, type) => {
    const urltonav = `premiumlistings/healthcare/${type}/${locationId}/address`;
    navigate.to(urltonav);
  };

  const updateDraggedListing = (listing, isProvider) => {
    const resource = {
      businessAddressOne: listing.businessAddressOne,
      businessAddressTwo: listing.businessAddressTwo,
      businessId: listing.businessId,
      city: listing.city,
      country: listing.country,
      id: listing.id,
      locationName: listing.locationName,
      postalCode: listing.postalCode,
      locationIdentifier: listing.locationIdentifier,
      sourceId: null,
      stateProvince: listing.stateProvince,
      provider: isProvider,
    };
    dispatch(updateSingleBusinessLocation(resource));
  };

  const handleLocationDropped = (droppedItem) => {
    if (droppedItem.destination.droppableId !== droppedItem.source.droppableId) {
      // locations -> providers
      const updatedProviders = [...providers];
      const updatedLocations = [...locations];
      const [locationToAdd] = updatedProviders.splice(droppedItem.source.index, 1);
      updatedLocations.splice(droppedItem.destination.index, 0, locationToAdd);

      dispatch(setLocations(updatedLocations));
      dispatch(setProviders(updatedProviders));

      updateDraggedListing(locationToAdd, false);
    } else {
      const updatedLocations = [...locations];
      const [reorderedItem] = updatedLocations.splice(droppedItem.source.index, 1);
      updatedLocations.splice(droppedItem.destination.index, 0, reorderedItem);
      dispatch(setLocations(updatedLocations));
    }
  };

  const handleProviderDropped = (droppedItem) => {
    if (droppedItem.destination.droppableId !== droppedItem.source.droppableId) {
      // providers -> locations
      const updatedProviders = [...providers];
      const updatedLocations = [...locations];
      const [providerToAdd] = updatedLocations.splice(droppedItem.source.index, 1);
      const copyProviderToAdd = { ...providerToAdd, completed: false };
      updatedProviders.splice(droppedItem.destination.index, 0, copyProviderToAdd);

      updateDraggedListing(copyProviderToAdd, true);

      if (providerToAdd.completed) {
        dispatch(updateLocationStatusToFalse({ locationId: providerToAdd.id }));
      }

      dispatch(setLocations(updatedLocations));
      dispatch(setProviders(updatedProviders));
    } else {
      const updatedProviders = [...providers];
      const [reorderedItem] = updatedProviders.splice(droppedItem.source.index, 1);
      updatedProviders.splice(droppedItem.destination.index, 0, reorderedItem);
      dispatch(setProviders(updatedProviders));
    }
  };

  const handleDragStart = (event) => {
    setIsDraggingFrom(event.source.droppableId);
    setIsDragging(true);
  };

  const handleListDrop = (droppedItem) => {
    setMovedItem(droppedItem);
    if (!droppedItem.destination) return;
    if (droppedItem?.source?.droppableId !== droppedItem?.destination?.droppableId) {
      setOpenModal(true);
    }
  };

  const handleListDropConfirmation = () => {
    setIsDragging(false);
    if (movedItem?.destination?.droppableId === 'providers') {
      handleProviderDropped(movedItem);
    } else if (movedItem?.destination?.droppableId === 'locations') {
      handleLocationDropped(movedItem);
    }
    setOpenModal(false);
  };

  const locationList = useMemo(() => locationItems.map(location => ({
    id: location.id,
    element: generateCard(
      location,
      () => handleLocationDelete(location.id),
      () => handleLocationSelected(location.id, 'location'),
      t('pages.premiumListings.location.healthcare.locationType'),
    ),
  })), [locationItems]);

  const providerList = useMemo(() => providers.map(provider => ({
    id: provider.id,
    element: generateCard(
      provider,
      () => handleProviderDelete(provider.id),
      () => handleLocationSelected(provider.id, 'provider'),
      t('pages.premiumListings.location.healthcare.providerType'),
    ),
  })), [providers]);

  const handleAddLocation = async () => {
    const updatedLocations = [...locationItems];
    updatedLocations.push({ ...emptyItem, id: locationItems.length + 1 });
    const defaultLocationToCreate = { ...emptyItem };
    defaultLocationToCreate.sourceId = uuidv4();
    await dispatch(createBusinessLocation({ businessId, defaultLocationToCreate }));
    await dispatch(getBusinessLocations(businessId));
  };

  const handleAddProvider = async () => {
    const updatedProviders = [...providerItems];
    updatedProviders.push({ ...emptyItem, id: providerItems.length + 1 });
    const defaultLocationToCreate = { ...emptyItem };
    defaultLocationToCreate.sourceId = uuidv4();
    defaultLocationToCreate.provider = true;
    await dispatch(createBusinessLocation({ businessId, defaultLocationToCreate }));
    await dispatch(getBusinessLocations(businessId));
  };

  const canAdd = useMemo(() => locations.length + providers.length < maxItems, [locations, providers]);

  const hasIncomplete = item => item.completed !== true;

  const canContinue = useMemo(
    () => {
      if (isSalesForce) {
        if (regularPremiumListingOrderItems.length > 0 || healthCarePremiumListingOrderItems.length > 0) {
          return ((!locations.some(hasIncomplete) && !providers.some(hasIncomplete))
              && (locations.length === regularPremiumListingOrderItems.length)
              && providers.length === healthCarePremiumListingOrderItems.length);
        }
        return !providers.some(hasIncomplete) && (providers.length === premiumListingOrderItems.length);
      }

      if (isPunchlist) {
        return (!providers.some(hasIncomplete) && providers.length > 0);
      }
      return ((!locations.some(hasIncomplete) && !providers.some(hasIncomplete)) && providers.length > 0);
    },
    [locations, providers],
  );

  const onContinue = () => {
    const sortedItems = sortOrderItems(orderItems, isSalesForce, isPunchlist);
    const nextItem = sortedItems[0];

    if (nextItem) {
      const offeringType = nextItem.offeringType === 'ProductsData' ? nextItem.pdOfferingType : nextItem.offeringType;

      navigate.to(
          getFirstStepOfferingType(offeringType, isSalesForce, isPunchlist, isPartnerFlow),
        nextItem.id,
      );
    } else {
      navigate.to(Urls.Overview);
    }
  };

  const onBack = () => ((isCustomProduct(orderItems) || isSalesForce || isPunchlist || isPartnerFlow) ? navigate.to(Urls.BusinessClient)
    : navigate.to(Urls.BusinessCampaign));

  const capitalize = (s) => s?.length && s[0].toUpperCase() + s.slice(1);

  const returnLocationName = (id) => {
    const isLocation = locations.filter(loc => loc.id.toString() === id);
    if (isLocation.length > 0) return isLocation[0].locationName;

    const isProvider = providers.filter(prov => prov.id.toString() === id);
    if (isProvider.length > 0) return isProvider[0].locationName;

    return 'Selected';
  };

  useEffect(() => {
    if (businessId) dispatch(getBusinessLocations(businessId));
  }, [businessId]);

  const providersDescription = useMemo(() => {
    if (isSalesForce) {
      if (regularPremiumListingOrderItems.length > 0) {
        return `Add total ${premiumListingOrderItems.length} Location Listings and Provider Listings.`;
      }
      return `Add total ${premiumListingOrderItems.length} Provider Listings.`;
    }
    return !isPunchlist
      ? t('pages.premiumListings.location.healthcare.providerListingsDescription')
      : t('pages.premiumListings.location.healthcare.providerListingsDescriptionPunchlist');
  }, [isSalesForce, premiumListingOrderItems]);

  const showLocationsSection = !isPunchlist && (!isSalesForce || regularPremiumListingOrderItems.length > 0);

  const canAddLocations = useMemo(() => {
    if (isSalesForce && regularPremiumListingOrderItems.length > 0) {
      return locations.length < regularPremiumListingOrderItems.length;
    }
    return canAdd;
  }, [isSalesForce, regularPremiumListingOrderItems, locations]);

  const canAddProviders = useMemo(() => {
    if (isSalesForce && healthCarePremiumListingOrderItems.length > 0) {
      return providers.length < healthCarePremiumListingOrderItems.length;
    }
    return canAdd;
  }, [isSalesForce, healthCarePremiumListingOrderItems, providers]);

  return (
    <DefaultPageLayout
      onContinue={onContinue}
      disableContinue={!canContinue}
      onBack={onBack}
      header={t('pages.premiumListings.location.healthcare.title')}
    >
      <ValidationProvider schema={schema}>
        <DragDropContext onDragStart={handleDragStart} onDragEnd={handleListDrop}>
          <FormProvider {...methods}>
            <GridContainer fullWidth>
              {showLocationsSection && (
                <>
                  <GridItem sizes={[8]}>
                    <Typography fontSize="24px" fontWeight="bold">
                      {t('pages.premiumListings.location.healthcare.locationListings')}
                    </Typography>
                  </GridItem>
                  <GridItem sizes={[6]}>
                    <Typography>
                      {t('pages.premiumListings.location.healthcare.locationListingsDescription')}
                    </Typography>
                  </GridItem>
                  <GridItem sizes={[12]}>
                    <NeDraggableList
                      id="locations"
                      items={[...locationList].reverse()}
                      handleAddItem={handleAddLocation}
                      addItems
                      addButtonLabel={t('pages.premiumListings.location.healthcare.addNewLocation')}
                      infoText={t('pages.premiumListings.location.healthcare.locationInfoText')}
                      buttonDisabled={!canAddLocations}
                      standalone
                      enableDrag={isDragging}
                      draggingFrom={draggingFrom}
                      droppableAreaCopy={t('pages.premiumListings.location.healthcare.providerToLocation')}
                    />
                  </GridItem>
                </>
              )}
              <GridItem sizes={[8]}>
                <Typography fontSize="24px" fontWeight="bold">
                  {t('pages.premiumListings.location.healthcare.providerListings')}
                </Typography>
              </GridItem>
              <GridItem sizes={!isPunchlist ? [6] : [8]}>
                <Typography>{providersDescription}</Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <NeDraggableList
                  id="providers"
                  items={[...providerList].reverse()}
                  handleAddItem={handleAddProvider}
                  addItems={!isPunchlist || (isPunchlist && providerList.length === 0)}
                  addButtonLabel={t('pages.premiumListings.location.healthcare.addNewProvider')}
                  infoText={t('pages.premiumListings.location.healthcare.providerInfoText')}
                  buttonDisabled={!canAddProviders}
                  standalone
                  enableDrag={isDragging}
                  draggingFrom={draggingFrom}
                  droppableAreaCopy={t('pages.premiumListings.location.healthcare.locationToProvider')}
                />
              </GridItem>
            </GridContainer>
          </FormProvider>
        </DragDropContext>
        <CoModalConfirm
          open={openModal}
          title=""
          description={(
            <Typography>
              Are you sure you want to change
              <strong>{` ${returnLocationName(movedItem?.draggableId)} `}</strong>
              into a
              {` ${capitalize(movedItem?.destination?.droppableId)} `}
              Listing
            </Typography>
          )}
          confirmText="Yes"
          handleClose={() => setOpenModal(false)}
          handleConfirm={handleListDropConfirmation}
        />
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
