import i18n from 'i18n/i18n';

const { t } = i18n;

export const fileUploaderOptions: any = {
  name: 'standardLogo',
  label: t('pages.business.location.photos.logos'),
  supportingText: t('pages.business.location.photos.standardLogo'),
  showRecommendedImageText: false,
  showAcceptedFileTypesText: true,
  showMaxNumberFilesText: true,
  showCheckboxes: false,
  showComments: false,
  uploaderType: 'logo_attachments',
  required: false,
  maxFiles: 1,
  totalAllowedFiles: 1,
  maxFileSize: 5000000, // 5mb
  imageTypes: ['.jpg', '.png'],
  videoTypes: [],
  documentTypes: [],
  size: 'md',
};
