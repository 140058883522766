import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";
import HeadingTextLabel from "pages/generic-product/details/custom-fields/HeadingTextLabel";
import {Grid, Typography} from "@mui/material";
import React from "react";

const {t} = i18n;

export const EblastSchema: RJSFSchema | any = (data) => ({
  title: t('pages.multiMediaCreatives.eblast.title'),
  type: 'object',
  required: [],
  properties: {
    doYouNeedAnEblast: {
      type: 'string',
      title: t('pages.multiMediaCreatives.eblast.doYouNeedAnEblast'),
      default: data?.doYouNeedAnEblast || '',
        enum: [
          '',
          t('pages.generic-translation.yesOption'),
          t('pages.generic-translation.noOption'),
        ],
    },
  },
  dependencies: {
    doYouNeedAnEblast: {
      oneOf: [
        {
          properties: {
            doYouNeedAnEblast: {
              enum: ['Yes'],
            },
            typeOfEblastDesign: {
              type: 'string',
              title: t('pages.multiMediaCreatives.eblast.typeOfEblastDesign'),
              default: data?.typeOfEblastDesign || '',
              enum: [
                '',
                t('pages.multiMediaCreatives.eblast.buildFromTemplate'),
                t('pages.multiMediaCreatives.eblast.custom'),
              ],
            },
            viewTemplates: {
              type: 'string',
              title: t('pages.multiMediaCreatives.eblast.viewTemplates'),
            },
          },
        },
      ],
    },
    typeOfEblastDesign: {
      oneOf: [
        {
          properties: {
            typeOfEblastDesign: {
              enum: [t('pages.multiMediaCreatives.eblast.buildFromTemplate')],
            },
            templateToUse: {
              type: 'string',
              title: t('pages.multiMediaCreatives.eblast.templateToUse'),
              default: data?.templateToUse || '',
            },
          },
          required: ['templateToUse'],
        },
      ],
    },
  }
});

const textFieldKeys = [
  'templateToUse',
];

const dropdownFieldKeys = [
  'doYouNeedAnEblast',
  'typeOfEblastDesign'
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

export const EblastUISchema = {
  'ui:order': [
    'doYouNeedAnEblast',
    'typeOfEblastDesign',
    'viewTemplates',
    'templateToUse',
  ],
  viewTemplates: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography fontSize={14}>
            (<a href={t('pages.multiMediaCreatives.eblast.viewTemplatesLink')} target="_blank"
                rel="noreferrer">
            <Typography component="span" color="primary" fontSize={14}>
              {t('pages.multiMediaCreatives.eblast.viewTemplates')}
            </Typography>
          </a>)
          </Typography>
        </Grid>
    ),
  },
  ...textFields,
  ...dropdownFields,
};

export const EblastCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.templateToUse === '') {
    errors.templateToUse.addError(t('pages.multiMediaCreatives.eblast.templateToUseErrorMsg'));
    errors.addError(true);
  }
  return errors;
};
