import {
  Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Modal, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { updateCartAddons, updateCartAddonsSelection } from 'services/cartSlice';
import { cartAddonsModal } from 'pages/orders/OrderDetailsConstants';
import { camelCaseToFlat } from './ShoppingCart';

interface Props {
  open: boolean
  handleClose: () => void
  addons: any
  orderItemId: number
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '1px solid rgba(0,0,0,0.2)',
  borderRadius: '6px',
  boxShadow: '0 5px 15px rgba(0,0,0,0.5)',
  p: '5px',
};

export default function EditAddonsModal({
  open, handleClose, addons, orderItemId,
}: Props) {
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      addons: cartAddonsModal.map(() => ({ name: '', selected: false })),
    },
  });
  const { control, setValue, getValues } = methods;

  useEffect(() => {
    cartAddonsModal.forEach((addon, index) => {
      setValue(`addons.${index}`, { name: addon, selected: addons.includes(addon) });
    });
  }, [addons]);

  const toggleAll = (value) => {
    addons.forEach((_, index) => setValue(`addons.${index}.selected`, value));
  };

  const updateCart = () => {
    const formData = getValues();
    // eslint-disable-next-line no-return-assign, no-sequences
    const resource = formData.addons.reduce((res, obj) => (res[obj.name] = obj.selected, res), {} as any);

    dispatch(updateCartAddonsSelection({ orderItemId, resource }));
    dispatch(updateCartAddons(resource)); // to update the addons state as the server response not matching
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Grid container>
          <Grid item xs={12} display="flex"
            justifyContent="flex-end"
          >
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={12} paddingX={7}
            marginBottom={2}
          >
            <Typography variant="h2">Edit Add-Ons</Typography>
          </Grid>
          <Grid item xs={12} paddingX={7}
            marginBottom={2}
          >
            <Typography fontSize="18px">Add or remove items from cart</Typography>
          </Grid>
          <FormProvider {...methods}>
            {cartAddonsModal.map((addon, index) => (
              <Grid item xs={12} paddingX={7}
                key={addon}
              >
                <FormControlLabel
                  control={(
                    <Controller
                      name={`addons.${index}.selected`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox onChange={e => onChange(e.target.checked)} checked={value} />
                      )}
                    />
                  )}
                  label={<Typography>{camelCaseToFlat(addon)}</Typography>}
                />
              </Grid>
            ))}
          </FormProvider>
          <Grid item xs={12} paddingX={7}
            marginY={1}
          >
            <Button variant="text" sx={{ padding: 0 }} onClick={() => toggleAll(true)}>
              <Typography>Select All</Typography>
            </Button>
            <Button variant="text" sx={{ marginLeft: 3, padding: 0 }} onClick={() => toggleAll(false)}>
              <Typography>Remove All</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} paddingX={12}
            marginY={7}
          >
            <Grid container>
              <Grid item xs={6} paddingRight={1.5}>
                <Button variant="contained" fullWidth sx={{ paddingY: 1.5 }}
                  onClick={updateCart}
                >
                  <Typography fontSize="22px" fontWeight="bold">Update Cart</Typography>
                </Button>
              </Grid>
              <Grid item xs={6} paddingLeft={1.5}>
                <Button variant="contained" fullWidth sx={{ paddingY: 1.5 }}
                  onClick={handleClose}
                >
                  <Typography fontSize="22px" fontWeight="bold">Back to Cart</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
