/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import { SalesAgent } from 'pages/sales_agent/SalesAgentData';
import { RootState } from './redux/store';
import AxiosAPI from './common/axios';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { thunkBuilder } from './common/functions';

export interface SalesAgentState {
  agents: StatusType<SalesAgent[]>
  agentTypes: StatusType<any>
  currentAgent: StatusType<SalesAgent>
}

const initialState: SalesAgentState = {
  agents: {
    status: 'idle',
    message: '',
    content: [],
  },
  agentTypes: {
    status: 'idle',
    message: '',
    content: [],
  },
  currentAgent: {
    status: 'idle',
    message: '',
    content: null,
  },
};

export const getSalesAgents = createAsyncThunkCatchErrors(
  'salesAgents/get',
  async (orderId: string) => {
    const response = await AxiosAPI.get(`/users/order/${orderId}/sales-rep`);
    return response.data;
  },
);

export const getSalesAgentTypes = createAsyncThunkCatchErrors(
  'salesAgentTypes/get',
  async () => {
    const response = await AxiosAPI.get('salesAgentTypes');
    return response.data.content?.filter((type) => type.active);
  },
);

export const getSalesAgentById = createAsyncThunkCatchErrors(
  'salesAgent/get',
  async (id: string) => {
    const response = await AxiosAPI.get(`/users/${id}`);
    return response.data;
  },
);

export const createSalesAgent = createAsyncThunkCatchErrors(
  'salesAgent/create',
  async ({ orderId, content } : { orderId: string, content: any }) => {
    const response = await AxiosAPI.post(`/users/order/${orderId}/sales-rep`, content);
    return response.data;
  },
);

export const updateAgent = createAsyncThunkCatchErrors(
  'salesAgent/update',
  async ({ id, content } : { id: string, content: any }) => {
    const response = await AxiosAPI.put(`/users/${id}/salesRepDetails`, content);
    return response.data;
  },
);

export const deleteAgent = createAsyncThunkCatchErrors(
  'salesAgents/delete',
  async ({ orderId, id } : { orderId:string, id: string }) => {
    const response = await AxiosAPI.delete(`/users/order/${orderId}/sales-rep/${id}`);
    return response.data;
  },
);

export const setAgents = createAction<any>('salesAgent/agent/get');

export const salesAgentSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder)
      .addCase(getSalesAgents, 'agents')
      .addCase(getSalesAgentById, 'currentAgent')
      .addCase(getSalesAgentTypes, 'agentTypes');

    builder.addCase(setAgents, (state, action) => {
      state.agents.content = action.payload;
    });
  },
);

export const selectAgents = (state: RootState) => state.salesAgent.agents.content;
export const selectAgentTypes = (state: RootState) => state.salesAgent.agentTypes.content;
export const selectCurrentAgent = (state: RootState) => state.salesAgent.currentAgent.content;

export default salesAgentSlice;
