import i18n from 'i18n/i18n';
import * as yup from 'yup';

const { t } = i18n;

const schema = {
  phoneNumbers: yup.array(yup.object({
    phoneType: yup.string().notRequired(),
    phoneNumber: yup.string().required(),
    ext: yup.string().notRequired(),
  }))
    .min(1),
  locationWebsite: yup.string().url(t('pages.premiumListings.location.contact.details.invalidWebsiteUrl'))
    .notRequired(),
  locationWebsiteSameForAll: yup.boolean().notRequired().nullable(),
  locationEmail: yup.string().email(t('pages.premiumListings.location.contact.details.invalidEmail')).notRequired(),
  locationEmailSameForAll: yup.boolean().notRequired().nullable(),
  accounts: yup.array(yup.object({
    name: yup.string(),
    enabled: yup.boolean(),
    url: yup.string().url().optional(),
    applyToAll: yup.boolean(),
  })),
  facebookYesNo: yup.string().required(),
  googleYesNo: yup.string().required(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
