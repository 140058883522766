import {
  Grid, Box, Typography, Tabs, Tab,
} from '@mui/material';
import CoCapacityHeader from 'components/capacityHeader/CoCapacityHeader';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import { useNavigate, useParams } from 'react-router';
import { Urls } from 'navigation/Urls';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentOpportunityTab, selectOpportunity, setCurrentOpportunityTab } from 'services/cefSlice';
import { getProductTypesMenu } from 'services/productsSlice';
import { useQuery } from 'pages/themes/details/ThemeDetails';
import { allCountries, setCountry } from 'services/topNavMenuSlice';
import { CustomTabPanel } from './solution/CustomTabPanel';
import { removeCartItem, selectCartItems } from "services/cartSlice";

const tabStyles = {
  width: '100%',
  '& .MuiTabs-root:has(.MuiTabs-scrollButtons)': {
    marginLeft: '-40px',
  },
  '& .MuiTabs-scroller': {
    borderBottom: `1px solid ${IQThemeV2.palette.text.secondary}`,
  },
  '& .MuiTabs-scrollButtons': {
    color: IQThemeV2.palette.primary.main,
  },
  '& .MuiTabs-indicator': {
    height: '4px',
  },
  '& .MuiTab-root': {
    fontWeight: 'normal',
    color: IQThemeV2.palette.text.primary,
    textTransform: 'none',
  },
  '& .MuiTab-root.Mui-selected': {
    color: IQThemeV2.palette.primary.main,
  },
};

export default function Opportunity() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { opportunityId, solutionId } = useParams();
  const currentTab = useSelector(selectCurrentOpportunityTab);
  const [animationDir, setAnimationDir] = useState('right' as any);
  const opportunity = useSelector(selectOpportunity);
  const [offers, setOffers] = useState([]);
  const query = useQuery();
  const isBlue = useMemo(() => query.get('isBlue'), [query]);
  const cartItems = useSelector(selectCartItems);

// Clear the cart on load if the Salesforce Id has changed, ensuring the shopping cart is reset for the different CEF link.
  useEffect(() => {
    if (opportunity) {
      cartItems?.content.filter((item: any) => {
        const salesforceIdList = opportunity?.offers?.map((sfItem: any) => {
          return sfItem?.forceOffer?.salesforceOfferId;
        })
        if (salesforceIdList
            && salesforceIdList?.length > 0
            && !salesforceIdList.includes(item?.orderItem?.salesforceId)) {
          dispatch(removeCartItem(item.orderItem?.id));
        }
      })
    }
  }, [cartItems, opportunity]);

  useEffect(() => {
    dispatch(getProductTypesMenu({}));
  }, []);

  useEffect(() => {
    if (opportunity?.account?.advertiserCountry) {
      const findCountryIndex = allCountries
        .findIndex((country: any) => opportunity.account.advertiserCountry === country.name);

      if (findCountryIndex === -1) {
        dispatch(setCountry(allCountries[0]));
      } else {
        dispatch(setCountry(allCountries[findCountryIndex]));
      }
    }
  }, [opportunity]);

  useEffect(() => {
    if (Object.keys(opportunity).length === 0) {
      if (solutionId) {
        // eslint-disable-next-line max-len
        navigate(`/${Urls.Cef}?${isBlue !== 'true' ? 'OpportunityId' : 'CaseId'}=${opportunityId}&SolutionId=${solutionId}`);
      } else navigate(`/${Urls.Cef}?${isBlue !== 'true' ? 'OpportunityId' : 'CaseId'}=${opportunityId}`);
    } else {
      const opportunityOffers = [];

      opportunity?.offers?.forEach(offer => opportunityOffers.push(offer));

      setOffers(opportunityOffers);
    }
  }, [opportunity]);

  useEffect(() => {
    if (solutionId && offers.length > 0) {
      const index = offers.findIndex(offer => offer.forceOffer.salesforceOfferId === solutionId);
      dispatch(setCurrentOpportunityTab(index !== -1 ? index : 0));
    }
  }, [solutionId, offers]);

  const handleTabChange = async (event, value) => {
    await setAnimationDir(value < currentTab ? 'right' : 'left');
    dispatch(setCurrentOpportunityTab(value));
  };

  const moveToNextTab = () => {
    setAnimationDir('left');
    if ((offers.length - 1) !== currentTab) {
      dispatch(setCurrentOpportunityTab(currentTab + 1));
    } else {
      const checkForSf: string = (isBlue && isBlue === "true") ? '?isBlue=true' : '?isBlue=false';
      navigate(`/shoppingCart/${Urls.Cef}/${opportunityId}${checkForSf}`);
    }
  };

  return (
    <Box sx={{ display: 'flex', padding: '65px' }}>
      <CoCapacityHeader />
      <Grid container spacing={2} sx={{ margin: '120px auto 0', maxWidth: '1500px' }}>
        <Box>
          <Typography variant="subtitle2" mb={1} style={{ textTransform: 'uppercase', fontSize: '12px' }}>
            {t('pages.opportunity.label')}
          </Typography>
          <Typography variant="h2" mb={3}>
            {opportunity?.opportunity?.name}
          </Typography>
          <Typography variant="body1" mb={4}>
            {t('pages.opportunity.tabsInstructions')}
          </Typography>
        </Box>

        <Box mt={6} sx={tabStyles}>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="opportunity solutions"
          >
            {offers?.map((solution) => (
              <Tab
                label={solution?.forceOffer?.rlOfferName || solution?.forceOffer?.salesforceOfferName}
                key={solution.forceOffer.salesforceOfferId}
              />
            ))}
          </Tabs>
        </Box>
        {offers?.map((solution, index) => (
          <CustomTabPanel
            value={currentTab}
            index={index}
            key={solution.forceOffer.salesforceOfferId}
            data={solution}
            moveToNextTab={moveToNextTab}
            animationDir={animationDir}
            isBlue={isBlue === 'true'}
          />
        ))}
      </Grid>
    </Box>
  );
}
