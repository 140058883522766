import { createEpicMiddleware } from 'redux-observable';
import {
  Action, applyMiddleware, configureStore, ThunkAction,
} from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { useDispatch } from 'react-redux';
import initialState from './initial-state';
import rootReducer from './root-reducer';
import rootEpic from './root-epic';

export const history = createBrowserHistory();

const vanillaPromise = store => next => action => {
  if (typeof action.then !== 'function') {
    return next(action);
  }
  return Promise.resolve(action).then(store.dispatch);
};

const epicMiddleware = createEpicMiddleware({
  dependencies: {
    history,
  },
});

export const store = configureStore({
  reducer: rootReducer(history),
  preloadedState: initialState,
  enhancers: [applyMiddleware(epicMiddleware, vanillaPromise)],
});

epicMiddleware.run(rootEpic);

export default store;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
