import * as yup from 'yup';

const schema = {
  inMarketAudience: yup.string().notRequired(),
  category: yup.string().notRequired(),
  subCategory: yup.string().notRequired(),
  specifics: yup.string().notRequired(),
  details: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
