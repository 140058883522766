import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getThemeBenefitById, selectCurrentThemeBenefit, updateThemeBenefit,
} from 'services/adminSlice';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import BenefitForm from './BenefitForm';

export default function EditBenefit() {
  const currentBenefit = useSelector(selectCurrentThemeBenefit);
  const { benefitId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getThemeBenefitById(parseInt(benefitId, 10)));
  }, [benefitId]);

  const updateBenefit = async (data) => {
    const benefit = {
      id: parseInt(benefitId, 10),
      title: data.title,
      description: data.description,
    };
    await dispatch(await updateThemeBenefit(benefit));
    navigate('/admin/themeBenefits');
  };

  return (
    <>
      <Typography variant="h2">Edit Theme Benefit</Typography>
      <BenefitForm onSubmit={updateBenefit} benefit={currentBenefit} />
    </>
  );
}
