import React, { ReactNode } from 'react';
import { Grid, SxProps } from '@mui/material';

type Props = {
  columnSpacing?: number;
  marginBottom?: number;
  marginTop?: number;
  inlineItems?: boolean;
  sizes?: number[];
  children?: ReactNode;
  className?: string;
  sx?: SxProps;
};

export default function GridItem(props: Props) {
  const {
    children, sizes, columnSpacing, marginBottom, inlineItems, marginTop, className, sx,
  } = props;
  const fullGrid = 12;
  if (Array.isArray(children)) {
    // @ts-ignore
    const { length } = props.children;
    const widths = sizes || Array(length).fill(inlineItems ? (fullGrid / length) : fullGrid);
    return (
      <Grid
        container
        columnSpacing={columnSpacing ?? 2}
        sx={sx}
        className={className}
      >
        {React.Children.toArray(children.map((c, i) => (
          <Grid item xs={widths[i]} marginBottom={marginBottom ?? 3}
            {...{ marginTop, className }}
          >
            {c}
          </Grid>
        )))}
      </Grid>
    );
  }
  return (
    <Grid item xs={sizes ? sizes[0] : fullGrid} marginBottom={marginBottom ?? 3}
      {...{ marginTop, className, sx }}
    >
      {children}
    </Grid>
  );
}
