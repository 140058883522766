import {
  AppBar, Box, Container, Link, Toolbar,
  Typography,
} from '@mui/material';
import IQTopNavIcon from 'icons/IQTopNavIcon.svg';
import { Urls } from 'navigation/Urls';
import { useNavigate } from 'react-router';
import { backgroundDefaultColor } from 'styles/common_styles';
import Check from 'icons/check.svg';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';

export default function PunchlistConfirmation() {
  const navigate = useNavigate();

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      minHeight: '100vh',
      width: '100vw',
      backgroundColor: backgroundDefaultColor,
    }}
    >
      <AppBar position="fixed"
        sx={{
          backgroundColor: 'black',
          height: '50px',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Container sx={{
          maxWidth: 'initial !important',
          marginLeft: 0,
          marginRight: 0,
        }}
        >
          <Toolbar
            disableGutters
            sx={{ minHeight: '50px !important', padding: '0 !important', justifyContent: 'space-between' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 !important' }}>
              <Box onClick={() => navigate(`/${Urls.Themes}`)}
                sx={{ cursor: 'pointer', padding: '0 !important', width: '126px' }}
              >
                <img src={IQTopNavIcon} alt="IQTopNavIcon" />
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box sx={{
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <img src={Check} alt="check" />
        <Typography variant="h2" marginTop={5} marginBottom={3}>
          Thank you for your submission!
        </Typography>
        <Typography>
          If you have any questions, please email
          {' '}
          <Link href="mailto:OS@gannett.com" sx={{ color: IQThemeV2.palette.primary.main }}>OS@gannett.com.</Link>
        </Typography>
      </Box>
    </Box>
  );
}
