import { useSelector } from 'react-redux';
import { selectFilesGetty } from 'services/fileUploaderSlice';
// import { clearExistingAssetsCompleted, selectExistingAssetsCompleted, selectWebsites } from 'services/websitesSlice';
import { Urls } from 'navigation/Urls';
import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import StockAssetsForm from './StockAssetsForm';

export default function StockAssets() {
  const prodFunc = useProduct(Urls.Assets);

  const filesGetty = useSelector(selectFilesGetty);

  return (
    <ContentLoader status={prodFunc.product.status} message={prodFunc.product.message}>
      <StockAssetsForm
        onSubmit={prodFunc.onSubmit}
        onBack={prodFunc.onBack}
        filesGettyList={filesGetty.content.map(f => ({ ...f, tag: 'stock' }))}
      />
    </ContentLoader>
  );
}
