import * as yup from 'yup';

const schema = {
  headline: yup.string().required('this field is required.').nullable(),
  description: yup.string().required('this field is required.').nullable(),
  callToActions: yup.string().required('this field is required.').nullable(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
