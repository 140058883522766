import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import {
  Typography, Autocomplete, Checkbox, TextField, Chip, RadioGroup, FormControlLabel, Radio,
  FormHelperText,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorText from 'components/errorText/ErrorText';
import GridItem from 'components/grid/GridItem';
import schemaValidate from 'components/schemaValidate';
import i18n from 'i18n/i18n';
import { TypeOfSaleConstants } from 'pages/shopping-cart/Constants';
import schema from '../GeneralQuestions.schema';
import { paymentMethodOptions, servicesProcess } from '../generalQuestionsData';
import { tooltip } from './Tracking';

const { t } = i18n;

export const Payments = (
  register,
  errors,
  setValue,
  getValues,
  paymentMethods,
  setPaymentMethods,
  paymentMethodsMinError,
  servicesProcessPayment,
  setServicesProcessPayment,
  typeOfSale,
) => {
  const isRebuild = typeOfSale === TypeOfSaleConstants.REBUILD;

  return (
    <>
      <GridItem marginBottom={3}>
        <Typography variant="h5" component="h2">
          {t('pages.generalQuestions.payments')}
        </Typography>
      </GridItem>

      <GridItem marginBottom={1}>
        <GridItem marginBottom={1}>
          <Typography variant="subtitle2" component="h3" fontSize={16}
            color={errors.paymentMethod ? IQThemeV2.palette.error.main : '#000000'}
          >
            {t('pages.generalQuestions.paymentMethod.title')}
          </Typography>
          <Typography variant="body1" component="p">
            {` ${t('pages.generalQuestions.paymentMethod.description')}`}
          </Typography>
        </GridItem>
      </GridItem>

      <GridItem marginBottom={3} sizes={[8]}>
        <GridItem marginBottom={0}>
          <Autocomplete
            multiple
            options={paymentMethodOptions}
            getOptionLabel={(option) => option}
            autoHighlight
            disableClearable
            disableCloseOnSelect
            value={paymentMethods}
            onChange={(e, v) => {
              setPaymentMethods(v);
              setValue('paymentMethod', v);
              paymentMethodsMinError();
            }}
            onClose={() => paymentMethodsMinError()}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                hiddenLabel
                placeholder={getValues('paymentMethod').length === 0
                  ? t('pages.generalQuestions.paymentMethod.placeholder') : ''}
                error={!!errors.paymentMethod}
              />
            )}
            renderTags={(value: string[], getTagProps) => value.map((option: string, index: number) => (
              <Chip
                deleteIcon={<ClearIcon />}
                variant="outlined"
                sx={{
                  borderColor: 'primary.main',
                  color: 'primary.main',
                  '& .MuiChip-deleteIcon': {
                    color: 'primary.main',
                  },
                }}
                label={option}
                {...getTagProps({ index })}
              />
            ))}
          />
          <ErrorText
            hasError={!!errors.paymentMethod}
            errorText={t('pages.generalQuestions.paymentMethod.error')}
          />
        </GridItem>
      </GridItem>

      <GridItem marginBottom={1}>
        <GridItem marginBottom={0}>
          <Typography variant="subtitle2" component="h3" fontSize={16}>
            {t('pages.generalQuestions.servicesProcessPayment.title')}
          </Typography>
        </GridItem>
        <GridItem marginBottom={0}>
          <RadioGroup
            aria-labelledby="services-process-payment"
            name="servicesProcessPayment"
            value={servicesProcessPayment}
            onChange={(e) => setServicesProcessPayment(e.target.value)}
          >
            {servicesProcess.map(opt => (
              <FormControlLabel
                {...register('servicesProcessPayment', {
                  validate: (value) => schemaValidate(value, 'servicesProcessPayment', schema),
                })}
                key={opt}
                value={opt}
                control={<Radio />}
                label={(isRebuild && opt === 'Yes')
                  ? (
                    <>
                      {opt}
                      {tooltip(
                        t('pages.generalQuestions.servicesProcessPayment.rebuildYesTooltip'),
                        <HelpOutlineOutlinedIcon />,
                        '208px',
                      )}
                    </>
                  )
                  : opt}
              />
            ))}
          </RadioGroup>
          {isRebuild && (
          <FormHelperText error={false}>
            {t('pages.generalQuestions.servicesProcessPayment.rebuildHelperText')}
          </FormHelperText>
          )}
        </GridItem>
      </GridItem>
    </>
  );
};
