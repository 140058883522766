import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Typography } from '@mui/material';
import { IQFormTextArea, IQTheme, ValidationProvider } from '@gannettdigital/shared-react-components';

import styled from '@emotion/styled';
import {
  ProductTypes, selectFilesGetty, selectFilesUploaded, setFiles,
} from 'services/fileUploaderSlice';
import HeroVideoType from 'models/HeroVideoType';

import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import ContentLoader from 'components/contentLoader/ContentLoader';
import CoFileUploader from 'components/fileUploader/CoFileUploader';
import useUpdateAssets from 'hooks/useUpdateAssets';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import { selectVideoBackgroundAdditionalNotes } from 'services/addons/addonsSlice';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import schema from './HeroVideo.schema';

interface Props {
  heroVideo: HeroVideoType
  onSubmit: (data) => any
  onBack: () => any
}

const directory = 'addons-hero-video';
const uploaderType = 'hero-video';
const megabyte = 1_000_000;
const defaultFileSize = 25 * megabyte;

const defaultFileUploaderOptions :Pick<React.ComponentProps<typeof CoFileUploader>, 'fileUploaderOptions'> = {
  fileUploaderOptions: {
    directory,
    uploaderType,
    name: 'heroVideoImages',
    showRecommendedImageText: false,
    showAcceptedFileTypesText: true,
    maxFileSize: defaultFileSize,
    label: '',
    supportingText: '',
    imageTypes: [],
    videoTypes: ['.webm', '.3g2', '.3gp', '.avi', '.mp4', '.mpeg', '.ogv', '.ts'],
    documentTypes: [],
    size: 'lg',
  },
};

const defaultGettyUploaderOptions :Pick<React.ComponentProps<typeof CoFileUploader>, 'gettyImagesUploaderOptions'> = {
  gettyImagesUploaderOptions: {
    name: 'herovideoGettyImages',
    displayOnly: 'videos',
    productType: ProductTypes.HERO_VIDEO,
    gettyDescription: 'Please work with your client to select a video they would like to utilize on their website.',
  },
};

const StyledTypography = styled(Typography)(({ theme, heading } : { theme: typeof IQTheme, heading?: boolean }) => `
  color: ${theme.palette.text.primary};
  font-family: ${theme.typography.fontFamily};
  font-style: normal;
  font-weight: ${heading ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular};
  font-size: 16px;
  line-height: ${heading ? 24 : 20}px;
  letter-spacing: ${heading ? 0.25 : 0}px;
  margin-bottom: ${heading ? '8px' : 0};
`);

const BoxStyled = styled(Box)`
  hr {
    margin-top: 36px !important;
  }
`;

export default function HeroVideoForm(props: Props) {
  const { t } = useTranslation();
  const { orderId, orderItemId } = useParams();
  const dispatch = useDispatch();
  const { content: heroVideoContent } = useSelector(selectVideoBackgroundAdditionalNotes);
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);

  // redux
  const { content: filesUploaded } = useSelector(
    selectFilesUploaded,
    (prev, curr) => prev.content.length === curr.content.length,
  );

  const { content: gettyFiles } = useSelector(selectFilesGetty);

  const { message: filesMessage, status: filesStatus } = useSelector(selectFilesUploaded, (prev, curr) => {
    if (prev.message === curr.message) {
      return true;
    }
    return prev.status === curr.status;
  });

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      additionalNotes: heroVideoContent[0]?.additionalNotes,
    },
  });
  const {
    handleSubmit, setValue, getValues, formState: {
      isDirty, isSubmitting, isSubmitSuccessful,
    },
    watch,
  } = methods;

  const { triggerAllUpdates } = useUpdateAssets(
    watch,
    defaultFileUploaderOptions.fileUploaderOptions.name,
    defaultGettyUploaderOptions.gettyImagesUploaderOptions.name,
    websiteId?.toString(),
    orderId,
    ProductTypes.HERO_VIDEO,
    offeringType as WebsiteOfferingTypes,
    orderItemId,
  );

  const onSubmit = async (data) => {
    await triggerAllUpdates();
    await props.onSubmit(data);
    dispatch(setFiles([]));
  };

  const skipAndContinue = !(!!filesUploaded.length || !!gettyFiles.length
    || getValues('additionalNotes') || heroVideoContent[0]?.additionalNotes);

  useEffect(() => {
    setValue('additionalNotes', heroVideoContent[0]?.additionalNotes);
  }, [heroVideoContent]);

  return (
    <ContentLoader status={filesStatus} message={filesMessage}>
      <ValidationProvider schema={schema}>
        <BoxStyled id="mainForm" component="form" sx={{ display: 'flex', mt: '65px' }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <DefaultPageLayout onContinue={handleSubmit(onSubmit)}
            header={t('pages.addons.heroVideo.title')}
            onBack={props.onBack}
            skipAndContinue={skipAndContinue}
          >
            <FormProvider {...methods}>
              <Grid container xs={6}>
                <Grid item xs={12} sx={{ mb: '8px', minWidth: '608px' }}>
                  <StyledTypography theme={IQTheme} heading>
                    Assets
                  </StyledTypography>
                  <StyledTypography theme={IQTheme}>
                    {t('pages.addons.heroVideo.supportingText')}
                  </StyledTypography>
                  <CoFileUploader
                    orderId={orderId}
                    orderItemId={orderItemId}
                    productId={websiteId?.toString()}
                    productType={ProductTypes.HERO_VIDEO}
                    offeringType={offeringType as WebsiteOfferingTypes}
                    schema={schema}
                    showFileUploader
                    showGetty
                    fileUploaderOptions={defaultFileUploaderOptions.fileUploaderOptions}
                    gettyImagesUploaderOptions={defaultGettyUploaderOptions.gettyImagesUploaderOptions}
                    containerStyles={{
                      '& > div > div': { width: '100%' },
                      '& > div:first-child': { mb: '24px', mt: '16px' },
                      '& > div:last-child': { mb: '12px', mt: '16px' },
                    }}
                  />

                  <IQFormTextArea
                    id="additionalNotes"
                    labelText="Additional Notes/Instructions"
                    name="additionalNotes"
                    rowCount={2}
                    fullWidth
                    fontLabelWeight="600"
                    showError
                  />
                </Grid>
              </Grid>
              {/* show the dialog if we changed the field and didn't submit the changes */}
              <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
              />
            </FormProvider>
          </DefaultPageLayout>
        </BoxStyled>
      </ValidationProvider>
    </ContentLoader>
  );
}
