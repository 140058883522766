import { Typography, Box, Link } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { Urls } from 'navigation/Urls';
import { headingStyle } from '../Business/BusinessSection';

export const renderProductsSection = (data, stockAssets, product, navigate, offeringType, isEditable) => (
  <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
    <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Products</Typography>

    <Box>
      {/* Product Details */}
      <Box sx={{ display: 'flex', marginBottom: '10px' }}>

        {editButton(isEditable, () => navigate.to(
          Urls.ProductsDetails,
          product.id,
        ))}
        <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Details</Typography>
      </Box>
      <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Types of Products Offered</Typography>
            {displayNotRequiredField('types of products provided', data?.productTypesOffered)}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Number of Products To Be Sold</Typography>
            {displayNotRequiredField('number provided', data?.numberProductToBeSold)}
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Products Change Based On Season</Typography>
            {data?.productsChangeBasedSeason
              ? <Typography sx={{ textTransform: 'capitalize' }}>{data?.productsChangeBasedSeason}</Typography>
              : <Typography fontWeight="bold">No selection</Typography>}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Seasonal Products</Typography>
            {displayNotRequiredField('seasonal products', data?.seasonalProducts)}
          </Box>
        </Box>
      </GridItem>

      {/* Product Catalog */}
      <Box sx={{ display: 'flex', margin: '10px 0' }}>
        {editButton(isEditable, () => navigate.to(
          Urls.ProductCatalog,
          product.id,
        ))}
        <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Catalog</Typography>
      </Box>
      <GridItem sizes={[6, 6]}>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Product Description Provided By Client</Typography>
            {data?.productDescriptionByClient
              ? <Typography sx={{ textTransform: 'capitalize' }}>{data?.productDescriptionByClient}</Typography>
              : <Typography fontWeight="bold">No selection</Typography>}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Number of Products That Require Content Written</Typography>
            {data?.numberOfProductsWritten === 'null'
              ? <Typography fontWeight="bold">No number provided</Typography>
              : <Typography>{data?.numberOfProductsWritten}</Typography>}
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Preferred Content Tones</Typography>
            {data?.contentTones?.length > 0
              ? data?.contentTones.map(item => (
                <Typography key={item} sx={{ textTransform: 'capitalize' }}>{item}</Typography>
              )) : <Typography fontWeight="bold">No content tones</Typography>}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Assets</Typography>
            {stockAssets?.length
              ? stockAssets.map((asset, index) => (
                <Box mb={2} key={asset.url}>
                  <Link href={asset.url} key={asset.url} target="_blank" rel="noopener noreferrer">
                    <Typography>{`Asset ${index + 1}`}</Typography>
                  </Link>
                  {asset?.notes
                    ? <Typography>{`Comments: ${asset.notes}`}</Typography>
                    : <Typography fontWeight="bold">No comments</Typography>}
                </Box>
              ))
              : <Typography fontWeight="bold">No Assets</Typography>}
          </Box>
        </Box>
      </GridItem>
    </Box>
  </GridItem>
);
