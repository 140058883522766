import { IQBigBoxMultipleSelect, ValidationProvider } from '@gannettdigital/shared-react-components';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import testimonials from 'icons/additionalFeatures/testimonials.svg';
import blog from 'icons/additionalFeatures/blog.svg';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { fetchDataForPut, updateFeatures } from 'shared/pages/features';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import WebsitesType from 'models/WebsitesType';
import schema from './AdditionalFeaturesContent.schema';

// features we want to select
// the name of the feature must match the property of Website object
const features = [
  {
    id: '0',
    name: 'testimonial',
    label: 'Testimonials',
    subDescription: 'Free',
    svgIcon: testimonials,
  },
  {
    id: '1',
    name: 'blog',
    label: 'Blog',
    subDescription: 'Free',
    svgIcon: blog,
  },
];

interface Props {
  website: WebsitesType
  onSubmit: (data) => any
  onBack: () => any
}

export default function AdditionalFeaturesContentForm(props: Props) {
  const { t } = useTranslation();

  const { website, onSubmit, onBack } = props;

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      additionalFeatures: updateFeatures(website, features),
    },
  });

  const {
    watch, handleSubmit, formState: {
      isDirty, isSubmitting, isSubmitSuccessful,
    },
  } = methods;

  const handleOnSubmit = (data) => {
    const content = fetchDataForPut(data.additionalFeatures, features);
    if (onSubmit) onSubmit(content);
  };

  const additionalFeatures = watch('additionalFeatures');

  const skipAndContinue = !additionalFeatures.filter(feature => feature.checked).length;

  return (
    <DefaultPageLayout disableContinue={false} onContinue={handleSubmit(handleOnSubmit)}
      header={t('pages.additionalFeaturesContent.additionalFeatures')}
      description={t('pages.additionalFeaturesContent.selectMany')}
      onBack={onBack}
      skipAndContinue={skipAndContinue}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <IQBigBoxMultipleSelect name="additionalFeatures" optionsList={features} />
        </FormProvider>
      </ValidationProvider>
      {/* show the dialog if we changed the field and didn't submit the changes */}
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
