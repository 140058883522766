/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';

const { t } = i18n;

export const ChatHybridSchema: RJSFSchema = {
  title: t('pages.smm.solutionStrategy.title'),
  // eslint-disable-next-line max-len
  type: 'object',
  required: ['nameAndEmailOfUsers', 'businessHours'],
  properties: {
    newOrExistingCustomer: {
      type: 'string',
      title: t('pages.chat.chatHybrid.newOrExistingCustomerLabel'),
      default: '',
      items: {
        enum: [
          `${t('pages.chat.chatHybrid.inputValues.newOrExistingCustomerNewValue')}`,
          `${t('pages.chat.chatHybrid.inputValues.newOrExistingCustomerExistingValue')}`,
        ],
      },
    },
    seatsPurchased: {
      type: 'string',
      title: t('pages.chat.chatHybrid.seatsPurchasedLabel'),
      default: '',
      items: {
        enum: [
          `${t('pages.chat.chatHybrid.inputValues.seatsPurchased1User')}`,
          `${t('pages.chat.chatHybrid.inputValues.seatsPurchased2to5Users')}`,
          `${t('pages.chat.chatHybrid.inputValues.seatsPurchased6to20Users')}`,
          `${t('pages.chat.chatHybrid.inputValues.seatsPurchased21PlusUsers')}`,
        ],
      },
    },
    nameAndEmailOfUsers: {
      type: 'string',
      title: t('pages.chat.chatHybrid.nameAndEmailOfUsersLabel'),
      default: '',
    },
    businessHours: {
      type: 'string',
      title: t('pages.chat.chatHybrid.businessHoursLabel'),
      default: '',
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.chat.chatHybrid.notesInstructions'),
      default: '',
    },
  },
  dependencies: {
    newOrExistingCustomer: {
      oneOf: [
        {
          properties: {
            newOrExistingCustomer: {
              enum: [`${t('pages.chat.chatHybrid.inputValues.newOrExistingCustomerNewValue')}`],
            },
            liveChatPlatinumProduct: {
              type: 'string',
              title: t('pages.chat.chatHybrid.liveChatPlatinumProductLabel'),
              default: '',
              items: {
                enum: [
                  `${t('pages.chat.chatHybrid.inputValues.liveChatPlatinumProductYes')}`,
                  `${t('pages.chat.chatHybrid.inputValues.liveChatPlatinumProductNo')}`,
                ],
              },
              description: t('pages.chat.chatHybrid.liveChatHelperTextLabel'),
            },
          },
        },
        {
          properties: {
            newOrExistingCustomer: {
              enum: [`${t('pages.chat.chatHybrid.inputValues.newOrExistingCustomerExistingValue')}`],
            },
            currentlyRunningCid: {
              type: 'string',
              title: t('pages.chat.chatHybrid.currentlyRunningCidLabel'),
              default: '',
            },
          },
        },
      ],
    },
  },
};

const textFieldKeys = [
  'currentlyRunningCid',
];

const textAreaFieldKeys = [
  'nameAndEmailOfUsers',
  'businessHours',
  'notesOrInstructions',
];

const radioFieldKeys = [
  'newOrExistingCustomer', 'liveChatPlatinumProduct', 'seatsPurchased',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'textInputField' },
}), {});

const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'radioButton' },
}), {});

export const ChatHybridUISchema = {
  'ui:order': ['newOrExistingCustomer', 'liveChatPlatinumProduct',
    'currentlyRunningCid', 'seatsPurchased', 'nameAndEmailOfUsers', 'businessHours', 'notesOrInstructions'],
  ...textFields,
  ...radioFields,
  ...textAreaFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ChatHybridCustomValidate = (formData: any, errors, uiSchema: any) => {
  if (formData?.nameAndEmailOfUsers === '') {
    errors.nameAndEmailOfUsers.addError(t('pages.chat.chatHybrid.nameAndEmailOfUsersErrorMessage'));
    errors.addError(true);
  }
  if (formData?.businessHours === '') {
    errors.businessHours.addError(t('pages.chat.chatHybrid.businessHoursErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
