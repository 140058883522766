import { t } from 'i18next';

type Fields = 'captureCode' | 'facebookPixels' | 'google' | 'otherTracking';

type Checkbox = {
  field: Fields;
  label: string;
  textAreaName: string;
  textAreaId: string;
};

const checkboxes :Checkbox[] = [
  {
    field: 'captureCode',
    label: t('pages.landing.tracking.captureCode'),
    textAreaName: 'captureCodeText',
    textAreaId: 'capture-code-notes',
  },
  {
    field: 'facebookPixels',
    label: t('pages.landing.tracking.facebookPixels'),
    textAreaName: 'facebookPixelsText',
    textAreaId: 'facebook-pixels-notes',
  },
  {
    field: 'google',
    label: t('pages.landing.tracking.googleAnalytics'),
    textAreaName: 'googleAnalyticsText',
    textAreaId: 'google-analytics-notes',
  },
  {
    field: 'otherTracking',
    label: t('pages.landing.tracking.other'),
    textAreaName: 'otherChannelsText',
    textAreaId: 'other-notes',
  },
];

export { checkboxes };
