import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearContentTonesCompleted,
  getContentTones,
  putWebsiteContentTones,
  selectContentTones,
  selectContentTonesCompleted,
} from 'services/contentTonesSlice';

import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import { Urls } from 'navigation/Urls';

import ContentLoader from 'components/contentLoader/ContentLoader';
import ContentTonesType from 'models/ContentTonesType';
import { getWebsite, selectWebsites } from 'services/websitesSlice';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import ContentWritingForm from './ContentWritingForm';

type SubmitData = {
  options: string;
  contentTones: ContentTonesType[] | [];
};

export default function ContentWriting() {
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const history: any = useLocation();
  const navigate = useNavigationHandler();
  const topNav = useTopnavMenu(Urls.ContentWriting);
  const dispatch = useDispatch();

  const contentTones = useSelector(selectContentTones);
  const website = useSelector(selectWebsites);
  const contentToneCompleted = useSelector(selectContentTonesCompleted);

  useEffect(() => {
    dispatch(getContentTones({ websiteId, offeringType }));
    dispatch(getWebsite({ websiteId, offeringType }));
  }, [websiteId]);

  const onBack = () => {
    navigate.to(Urls.AdditionalFeaturesDesign);
  };

  const onSubmitNo = () => {
    const content = [];
    dispatch(putWebsiteContentTones({ websiteId, content, offeringType }));
  };

  const onSubmitYes = (data: SubmitData) => {
    dispatch(putWebsiteContentTones({ websiteId, content: data.contentTones, offeringType }));
  };

  // after we receive the completed flag from redux, we move to the next page and clear the flag
  useEffect(() => {
    if (contentToneCompleted) {
      if (history.state?.previousPath.includes('overview')) {
        navigate.to(Urls.Overview);
      } else navigate.to(Urls.ExistingContent);
      dispatch(clearContentTonesCompleted());
    }
  }, [contentToneCompleted]);

  return (
    <ContentLoader status={[contentTones.status, website.status]} message={[contentTones.message, website.message]}>
      <ContentWritingForm contentTones={contentTones.content} onBack={onBack}
        onSubmitYes={onSubmitYes}
        onSubmitNo={onSubmitNo}
        newContentWritingRequired={website.content?.newContentWritingRequired}
      />
    </ContentLoader>
  );
}
