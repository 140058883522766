import { Grid, Typography } from '@mui/material';

export default function HeadingTextLabel(props: any) {
  return (
    <Grid item xs={9} mt={3}>
      <Typography style={{ fontSize: props?.fontSize || '30px', fontWeight: '600' }}>
        {props.label}
      </Typography>
    </Grid>
  );
}
