import {
  Autocomplete,
  Button, FormControl, Grid, TextField, Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import GridContainer from 'components/grid/GridContainer';
import { ProductType, RLOfferType } from 'services/offerMappingSlice';

interface OfferType {
  neonProductName: string
  offerId: number
}

export interface OfferEditFormType {
  offer: OfferType
  offers: RLOfferType[]
  productTypes: ProductType[]
  onSave?: (offer: OfferType) => unknown
}

const schema = {
  yupValidations: {},
  customValidations: {},
};

export default function OfferEditForm({
  offer, productTypes, offers, onSave,
}: OfferEditFormType) {
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      offeringType: offer.neonProductName,
      offerId: offer.offerId,
    },
  });
  const {
    handleSubmit, setValue, reset, control,
  } = methods;

  const onSubmit = (data) => {
    onSave(data);
    reset();
  };

  useEffect(() => {
    setValue('offeringType', offer.neonProductName);
    setValue('offerId', offer.offerId);
  }, [offer]);

  const returnProductLabel = (option) => {
    const product = productTypes.filter(type => type.offeringType === option)?.[0];
    return product?.name || option;
  };

  const returnOfferLabel = (option) => {
    const offer = offers.filter(offer => offer.offerId === option)?.[0];
    return `${offer.offerId} - ${offer.offerName}`;
  };

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <GridContainer sx={{
          width: '768px', padding: '0 80px 0 80px', maxWidth: '100% !important',
        }}
        >
          <Grid item xs={12} mb={3}>
            <FormControl sx={{ width: '608px' }}>
              <Controller
                name="offeringType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    options={productTypes.map(type => type.offeringType)}
                    getOptionLabel={(option) => returnProductLabel(option)}
                    value={value}
                    onChange={(_, value) => onChange(value)}
                    renderInput={(params) => <TextField placeholder="Select Product Name" {...params} />}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        <span>{returnProductLabel(option)}</span>
                      </li>
                    )}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} mb={3}>
            <FormControl sx={{ width: '608px' }}>
              <Controller
                name="offerId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    options={offers.map(offer => offer.offerId)}
                    getOptionLabel={(option) => returnOfferLabel(option)}
                    value={value}
                    onChange={(_, value) => onChange(value)}
                    renderInput={(params) => <TextField placeholder="Select Offer" {...params} />}
                    renderOption={(props, option) => (
                      <li {...props} key={option}>
                        <span>{returnOfferLabel(option)}</span>
                      </li>
                    )}
                  />
                )}
              />
              {/* <InputLabel id="offer-label">Select Offer</InputLabel>
              <Select
                {...register('offerId', { required: 'Please select Offer to continue.' })}
                labelId="offer-label"
                id="offerId"
                value={offerId}
                onChange={handleOfferIdChange}
                label="Select Offer"
              >
                {offers.map((option) => (
                  <MenuItem key={option.offerId} value={option.offerId}>
                    {`${option.offerId} - ${option.offerName}`}
                  </MenuItem>
                ))}
              </Select> */}
            </FormControl>
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center', marginTop: 12 }}>
            <Button variant="contained" onClick={handleSubmit(onSubmit)} sx={{ margin: '0 auto' }}>
              <Typography>Save</Typography>
            </Button>
          </Grid>
        </GridContainer>
      </FormProvider>
    </ValidationProvider>
  );
}
