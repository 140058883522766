/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import AxiosAPI from './common/axios';
import { thunkBuilder } from './common/functions';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { RootState } from './redux/store';

export interface GeneralQuestionsState {
  currentGeneralQuestions: StatusType<any>
}

const initialState: GeneralQuestionsState = {
  currentGeneralQuestions: {
    status: 'idle',
    message: '',
    content: [],
  },
};

export const createGeneralQuestions = createAsyncThunkCatchErrors(
  'generalQuestions/create',
  async (resource: any) => {
    const response = await AxiosAPI.post('/integrations/questions', resource);
    return response.data;
  },
);

export const getGeneralQuestionsByOrderItemId = createAsyncThunkCatchErrors(
  'generalQuestions/get',
  async (orderItemId: string | number) => {
    const response = await AxiosAPI.get(`/integrations/questions/id?id=${orderItemId}`);
    return response.data;
  },
);

export const generatePDF = createAsyncThunkCatchErrors(
  'generalQuestions/generatePDF',
  async (itemId: string | number) => AxiosAPI.get(`/integrations/freemarker/?itemId=${itemId}`),
);

export const setCurrentGeneralQuestions = createAction<any>('generalQuestions/set');

export const generalQuestionsSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder)
      .addCase(getGeneralQuestionsByOrderItemId, 'currentGeneralQuestions');

    builder
      .addCase(setCurrentGeneralQuestions, (state, { payload }) => {
        state.currentGeneralQuestions.content = payload;
      });
  },
);

// eslint-disable-next-line max-len
export const selectCurrentGeneralQuestions = (state: RootState) => state.generalQuestions.currentGeneralQuestions.content;

export default generalQuestionsSlice;
