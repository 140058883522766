import * as yup from 'yup';

const schema = {
  originalNumber: yup.string().required(),
  forwardToNumber: yup.string().required(),
  areaCodePreference: yup.string().required(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
