import { Button, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import {
  IQFormInput, IQFormTextArea, IQTheme, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';
import SitePagesType from 'models/SitePagesType';
import schema from './PageCard.schema';

export const CoPageCardFormButtonStyled = styled(Button)`
  text-transform: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;  
  width: 135px;
  height: 40px;
  
  &.Mui-disabled {
    color: #FFFFFF;
  }
`;

const GridStyledWrapper = styled('div')`
  .MuiFormControl-root {
    gap: 0;
    label {
      margin-bottom: 8px;
    }
  }
`;

const InputStyled = styled('div')`
  input {
    padding: 0 16px;
    width: 574px;
  }
`;

const styles = makeStyles(
  {
    gridContainer: {
      padding: '0 80px 0 80px',
      width: '768px',
    },
    grid: {
      marginTop: '9px',
      marginBottom: '14px',
    },
  },
);

export interface PageCardFormType {
  pageCard: SitePagesType,
  onSave?: (pageCardType: SitePagesType) => unknown
}

export default function CoPageCardForm(props: PageCardFormType) {
  const { t } = useTranslation();

  const style = styles();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      pageName: props.pageCard.pageName,
      targetMessaging: props.pageCard.targetMessaging,
      otherNotes: props.pageCard.otherNotes,
    },
  });
  const {
    handleSubmit, setValue, reset, formState: { isValid },
  } = methods;

  const onSubmit = (data) => {
    props.onSave({ ...props.pageCard, ...data });
    reset();
  };

  useEffect(() => {
    setValue('pageName', props.pageCard.pageName);
    setValue('targetMessaging', props.pageCard.targetMessaging);
    setValue('otherNotes', props.pageCard.otherNotes);
  }, [props.pageCard]);

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <GridStyledWrapper>
            <Grid container width="768px" className={style.gridContainer}>
              <Grid item xs={12} className={style.grid}>
                <InputStyled>
                  <IQFormInput
                    id="pageName"
                    name="pageName"
                    labelText={`${t('components.pageCard.labels.pageName')} *`}
                    fontLabelWeight="600"
                    fullWidth
                    theme={IQTheme}
                    schema={schema}
                  />
                </InputStyled>
              </Grid>
              <Grid item xs={12} className={style.grid}>
                <IQFormTextArea
                  id="targetMessaging"
                  name="targetMessaging"
                  labelText={t('components.pageCard.labels.targetMessaging')}
                  fontLabelWeight="600"
                  rowCount={5}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} className={style.grid}>
                <IQFormTextArea
                  id="otherNotes"
                  name="otherNotes"
                  fullWidth
                  labelText={t('components.pageCard.labels.otherNotes')}
                  fontLabelWeight="600"
                  rowCount={5}
                />
              </Grid>
              <Grid item xs={12} className={style.grid}
                style={{ textAlign: 'center', marginTop: 12 }}
              >
                <CoPageCardFormButtonStyled name="save" type="submit" variant="contained"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isValid}
                >
                  {t('components.pageCard.buttons.save')}
                </CoPageCardFormButtonStyled>
              </Grid>
            </Grid>
          </GridStyledWrapper>
        </form>
      </FormProvider>
    </ValidationProvider>
  );
}
