import {
  Box,
  Button, Dialog, DialogTitle, Grid, IconButton, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { IQButtonLink, IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { Business } from 'models/BusinessType';
import { loadingSalesforce } from 'pages/salesforce-entry/loadingScreen';

interface Props {
  open: boolean
  business: Business
  onConfirm: () => void
  onCancel: () => void
  isSalesforce: boolean
  isLoading: boolean
}

export default function VerifyBusinessModal(props: Props) {
  const {
    open, business, onConfirm, onCancel, isSalesforce, isLoading,
  } = props;
  const { t } = useTranslation();

  const renderBusinessDetails = () => (
    <Grid container sx={{ padding: '20px 40px' }}>
      <Grid item xs={12} mb={4}>
        <Typography fontWeight="bold" textAlign="center">
          {t('pages.business.search.verifyModal.text')}
          {isSalesforce && ' If business details are incorrect, please verify Account in Salesforce.'}
        </Typography>
      </Grid>
      <Grid container sx={{ padding: '20px' }} spacing={2}>
        <Grid item xs={4}>
          <Typography fontWeight="bold" paddingLeft={8}>{`${t('pages.business.search.columns.gmaid')}:`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className='gmaid'>{business.gmaid}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography fontWeight="bold" paddingLeft={8}>
            {`${t('pages.business.search.columns.businessName')}:`}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className='businessName'>{business.businessName}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography fontWeight="bold" paddingLeft={8}>{`${t('pages.business.search.columns.city')}:`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography
              className='city'>{business.city || ''}{business.city ? ', ' : ''}{business.stateProvince || ''}
          </Typography>
        </Grid>

        <Grid container direction="column"
          alignItems="center"
          sx={{ marginTop: '40px', '& .MuiButton-root': { fontWeight: '600' } }}
        >
          <Button variant="contained" onClick={onConfirm} sx={{ padding: '6px 54px' }}
            disabled={isLoading}
          >
            {isLoading && (<Box mr={1}><IQLoadingSpinner size={20} /></Box>)}
            {t('pages.business.search.verifyModal.confirm')}
          </Button>
          <IQButtonLink onClick={onCancel}>
            {t('pages.business.search.verifyModal.cancel')}
          </IQButtonLink>
        </Grid>
      </Grid>
    </Grid>
  );

  const loadingBusiness = () => (
    <Grid container sx={{ padding: '20px 40px' }}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {loadingSalesforce()}
      </Box>
    </Grid>
  );

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      className='verify-business-modal'
      sx={{
        padding: '20px',
        '& .MuiPaper-elevation': { maxWidth: '825px' },
      }}
    >
      <DialogTitle
        sx={{
          border: '10px solid',
          borderImageSlice: '1',
          borderWidth: '0 0 1px',
          borderImageSource: 'linear-gradient(to right, #0046FF, #F03C4B, #FF6437)',
          margin: '0 20px',
        }}
      >
        <Typography fontWeight="bold" textAlign="center" fontSize={24}>
          {t('pages.business.search.verifyModal.title')}
        </Typography>
        <IconButton
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {Object.keys(business).length > 0
        ? renderBusinessDetails()
        : loadingBusiness()}
    </Dialog>
  );
}
