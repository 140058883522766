import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { IQLabelTooltip, IQThemeV2, ValidationProvider } from '@gannettdigital/shared-react-components';
import {
  FormControlLabel, Grid, Radio, RadioGroup,
} from '@mui/material';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import i18n from 'i18n/i18n';
import GridItem from 'components/grid/GridItem';
import schemaValidate from 'components/schemaValidate';
import { useState } from 'react';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import schema from './BlogSolutionStrategy.schema';
import { blogPostFormOptions } from '../SeoBlogData';

const { t } = i18n;

interface Props {
  product: any;
  onSubmit: (data) => void;
  onBack: () => void
}

export default function BlogSolutionStrategyForm(props: Props) {
  const { product } = props;
  const [blogPostForm, setBlogPostForm] = useState(product?.blogPostForm || '');

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      blogPostForm: product?.blogPostForm || '',
      topicsToPrioritize: product?.topicsToPrioritize || '',
      topicsToAvoid: product?.topicsToAvoid || '',
      additionalNotes: product?.additionalNotes || '',
    },
  });

  const {
    handleSubmit,
    register,
    formState: {
      isValid,
      isDirty,
      isSubmitting,
      isSubmitSuccessful,
    },
  } = methods;

  const handleFormSubmit = (data) => props.onSubmit(data);

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={props.onBack}
      header="Solution Strategy"
      description={(
        <Trans
          i18nKey="pages.seo.blogSolutionStrategy.pageDescription"
          components={{ br: <br /> }}
        />
      )}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container>
            <Grid item xs={9} mb={3}>
              <Grid item mb={0}>
                <Grid item mb={1}>
                  <IQLabelTooltip
                    theme={IQThemeV2}
                    required
                    labelText={t('pages.seo.blogSolutionStrategy.blogPostFormLabel')}
                    tooltipText={t('pages.seo.blogSolutionStrategy.blogPostFormTooltip')}
                    tooltipPlacement="top"
                    hasError={false}
                  />
                </Grid>
                <RadioGroup
                  aria-labelledby="blogPostForm"
                  name="blogPostForm"
                  value={blogPostForm}
                  onChange={(e) => setBlogPostForm(e.target.value)}
                >
                  <GridItem sizes={[12]} marginBottom={0}>
                    {blogPostFormOptions.map(option => (
                      <FormControlLabel
                        key={option.value}
                        {...register('blogPostForm', {
                          validate: (value) => schemaValidate(value, 'blogPostForm', schema),
                        })}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </GridItem>
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid item xs={9} mb={3}>
              <NeFormTextArea
                {...register('topicsToPrioritize')}
                id="topicsToPrioritize"
                name="topicsToPrioritize"
                labelText={t('pages.seo.blogSolutionStrategy.topicsToPrioritizeLabel')}
                fullWidth
                rowCount={2}
                fontLabelWeight="600"
                required
                showError
              />
            </Grid>
            <Grid item xs={9} mb={3}>
              <NeFormTextArea
                {...register('topicsToAvoid')}
                id="topicsToAvoid"
                name="topicsToAvoid"
                labelText={t('pages.seo.blogSolutionStrategy.topicsToAvoidLabel')}
                fullWidth
                rowCount={2}
                fontLabelWeight="600"
              />
            </Grid>
            <Grid item xs={9} mb={3}>
              <NeFormTextArea
                {...register('additionalNotes')}
                id="additionalNotes"
                name="additionalNotes"
                labelText={t('pages.seo.solutionStrategy.additionalLabel')}
                fullWidth
                rowCount={2}
                fontLabelWeight="600"
              />
            </Grid>
          </Grid>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
