import { FormProvider, useForm } from 'react-hook-form';

import ChatPlatinumType from 'models/ChatPlatinumType';
import { IQFormTextArea, ValidationProvider } from '@gannettdigital/shared-react-components';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import schema from 'pages/chat-platinum/business/BusinessPage.schema';
import Box from '@mui/material/Box';

import i18n from 'i18n/i18n';
import { Grid } from '@mui/material';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';

const { t } = i18n;

interface Props {
  product: ChatPlatinumType;
  onSubmit: (data: AdditionalFormProps) => void;
  onBack: () => void;
}

export type AdditionalFormProps = {
  additionalNotes: string;
};

export default function AdditionalPageForm({ product, onSubmit, onBack }: Props) {
  const methods = useForm<AdditionalFormProps>({
    mode: 'all',
    defaultValues: {
      additionalNotes: product?.additionalNotes || '',
    },
  });

  const handleFormSubmit = (data: AdditionalFormProps) => onSubmit(data);

  const {
    handleSubmit,
    watch,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  const additionalNotesValue = watch('additionalNotes');
  const skipAndContinue = additionalNotesValue === null || additionalNotesValue === '';

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <Box
          id="mainForm"
          component="form"
          sx={{ display: 'flex' }}
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <DefaultPageLayout
            disableContinue={!isValid}
            onBack={onBack}
            header={t('pages.chat.additionalInfo.header')}
            description={t('pages.chat.additionalInfo.description')}
            onContinue={handleSubmit(handleFormSubmit)}
            skipAndContinue={skipAndContinue}
          >
            <Grid container xs={6}>
              <IQFormTextArea
                id="additionalNotes"
                name="additionalNotes"
                labelText={t('pages.chat.additionalInfo.additionalNotes')}
                fontLabelWeight="600"
                fullWidth
                rowCount={2}
              />
            </Grid>
          </DefaultPageLayout>
        </Box>
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
