import { useEffect } from 'react';
import { Urls } from 'navigation/Urls';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useNavigate } from 'react-router';

export default function Home() {
  const navigate = useNavigationHandler();
  const navigateNative = useNavigate();

  useEffect(() => {
    const localredirecturl = window.localStorage.getItem('neon_redirect');
    window.localStorage.removeItem('neon_redirect');
    if (localredirecturl && localredirecturl.length > 0) {
      const urlRedirectObject = new URL(localredirecturl);
      const currentUrlObject = new URL(window.location.href);
      if (urlRedirectObject.host.toLocaleLowerCase() === currentUrlObject.host.toLocaleLowerCase()
      && urlRedirectObject.pathname !== currentUrlObject.pathname) {
        navigateNative(`${urlRedirectObject.pathname}?${urlRedirectObject.searchParams}`);
        return;
      }
    }
    navigate.to(Urls.Home);
  }, []);

  return (
    <ContentLoader status="" message="" />
  );
}
