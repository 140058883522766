import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';

export const customWebsiteMenu: MenuType = ({
  label: 'Custom Website',
  url: '',
  status: 'incomplete',
  offeringType: 'CustomWebsite',
  subSteps: [
    {
      label: 'Details',
      url: Urls.CustomWebsiteDetails,
      status: 'incomplete',
    },
    {
      label: 'Design',
      url: Urls.CustomWebsiteDesign,
      status: 'incomplete',
    },
    {
      label: 'Features',
      url: Urls.CustomWebsiteFeatures,
      status: 'incomplete',
    },
    {
      label: 'Development',
      url: Urls.CustomWebsiteDevelopment,
      status: 'incomplete',
    },
    {
      label: 'Additional',
      url: Urls.CustomWebsiteAdditionalInfo,
      status: 'incomplete',
    },
  ],
});

// designList: {
//   steps: [
//     {
//       id: 0,
//       url: Urls.CustomDesign,
//       name: 'Custom Design',
//       status: TopNavStatusEnum.NotStarted,
//     },
//     {
//       id: 1,
//       url: Urls.CustomNavigation,
//       name: 'Custom Navigation',
//       status: TopNavStatusEnum.NotStarted,
//     },
//     {
//       id: 2,
//       url: Urls.CustomForm,
//       name: 'Custom Form',
//       status: TopNavStatusEnum.NotStarted,
//     },
//   ],
// },
//
// featuresList: {
//   currentStep: 0,
//   canGoToStep: 0,
//   steps: [
//     {
//       id: 0,
//       url: Urls.CrmIntegration,
//       name: 'CRM Integration',
//       status: TopNavStatusEnum.NotStarted,
//     },
//     {
//       id: 1,
//       url: Urls.LoginPortal,
//       name: 'Login Portal',
//       status: TopNavStatusEnum.NotStarted,
//     },
//     {
//       id: 2,
//       url: Urls.Ecommerce,
//       name: 'E-Commerce',
//       status: TopNavStatusEnum.NotStarted,
//     },
//     {
//       id: 3,
//       url: Urls.Scheduling,
//       name: 'Scheduling',
//       status: TopNavStatusEnum.NotStarted,
//     },
//   ],
// },
// development: {
//   currentStep: 0,
//   canGoToStep: 0,
//   steps: [
//     {
//       id: 0,
//       url: Urls.Content,
//       name: 'Content',
//       status: TopNavStatusEnum.NotStarted,
//     },
//     {
//       id: 1,
//       url: Urls.Applications,
//       name: 'Applications',
//       status: TopNavStatusEnum.NotStarted,
//     },
//     {
//       id: 2,
//       url: Urls.CustomExamples,
//       name: 'Examples',
//       status: TopNavStatusEnum.NotStarted,
//     },
//   ],
// },
//
