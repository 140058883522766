import React, {useEffect, useRef, useState} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {enGB} from "date-fns/locale";
import {format} from 'date-fns';
import {Button, Grid, Typography} from "@mui/material";
import {flexbox} from "@mui/system";

registerLocale("en-GB", enGB);

type DateTimeRangePickerProps = {
  onDateRangeChange: (startDate: Date | null, endDate: Date | null) => void;
};

const datePickerStyles = {
  width: "100%",
  borderRadius: "8px",
  border: "1px solid #ccc",
  backgroundColor: "#ffffff",
  minWidth: "250px",
  boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
};

const DateTimeRangePicker: React.FC<DateTimeRangePickerProps> = ({onDateRangeChange}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const prevStartDateRef = useRef<Date>(startDate);
  const prevEndDateRef = useRef<Date>(endDate);

  useEffect(() => {
    if (startDate !== prevStartDateRef.current || endDate !== prevEndDateRef.current) {
      onDateRangeChange(startDate, endDate);
      prevStartDateRef.current = startDate;
      prevEndDateRef.current = endDate;
    }
  }, [startDate, endDate, onDateRangeChange]);

  const isEndDateValid = (): boolean => {
    if (!startDate || !endDate) return true;
    return endDate > startDate;
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
      <Grid
          container
          sx={{
            alignItems: "center",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "2px",
            backgroundColor: "#ffffff",
            boxShadow:
                "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
          }}
      >
        <Grid item xs={4}>
          <Typography variant="subtitle2" component="label" fontWeight="bold">
            Select Start time and End time
          </Typography>
        </Grid>
        <Grid item xs={6.5} sx={{display: "flex", justifyContent: "space-around"}}>
          <DatePicker
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              showTimeSelect
              dateFormat="MM/dd/yyyy, HH:mm:ss"
              timeIntervals={15}
              maxDate={new Date()}
              placeholderText="Start Date & Time"
              locale="en-GB"
              style={datePickerStyles}
          />
          <Typography variant="subtitle2" component="label">
            to
          </Typography>
          <DatePicker
              selected={endDate}
              onChange={(date: Date) => setEndDate(date)}
              showTimeSelect
              dateFormat="MM/dd/yyyy, HH:mm:ss"
              timeIntervals={15}
              minDate={startDate || new Date()}
              maxDate={new Date()}
              placeholderText="End Date & Time"
              disabled={!startDate}
              locale="en-GB"
              popperPlacement="bottom"
              style={datePickerStyles}
          />
        </Grid>
        <Grid item xs={1.5}>
          <Button
              onClick={handleReset}
              variant="contained"
              sx={{
                backgroundColor: "#42a5f5",
                color: "#fff",
                padding: "2px 10px",
                borderRadius: "4px",
                border: "none",
                cursor: "pointer",
              }}
          >
            Reset
          </Button>
        </Grid>
        {!isEndDateValid() && (
            <Grid item xs={12}>
              <Typography variant="body2" color="error">
                End date and time must be after the start date and time.
              </Typography>
            </Grid>
        )}
      </Grid>
  );
}

export function formatDateToISOWithZeroMicroseconds(dateString: any) {
  const date = new Date(dateString);
  const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  const formattedDate = format(utcDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'000000'X");
  return formattedDate + "Z";
}

export default DateTimeRangePicker;
