import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import TextField from "pages/generic-product/details/custom-fields/TextField";

const {t} = i18n;

export const NotesAndInstructionsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.notesAndInstructions.title'),
  type: 'object',
  required: [],
  properties: {
    notesAndInstructions: {
      type: 'string',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      default: data?.notesAndInstructions || '',
    },
  },
});

const textAreaFieldKeys = [
  'notesAndInstructions',
];

const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': (props) => <TextField {...props} rows={8}/>,}
}), {});

export const NotesAndInstructionsUISchema = {
  'ui:order': [
    'notesAndInstructions',
  ],
  ...textAreaFields,
};

export const NotesAndInstructionsCustomValidate = (formData: any, errors, uiSchema) => {
  return errors;
};
