import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RegisterOptions, SetFieldValue } from 'react-hook-form';
import * as yup from 'yup';
import { IQFormInput, IQInputEmail } from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from 'react-i18next';
import { Button, Grid, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteEmailHostingEmail } from 'services/addons/addonsSlice';
import HostingEmailsType from 'models/HostingEmailsType';
import schema from 'pages/addons/emailHosting/EmailHosting.schema';
import CoModalConfirm from '../modals/CoModalConfirm';

interface Props {
  setValue: SetFieldValue<any>
  emailData: HostingEmailsType
  setEmailData: (data) => any
  // eslint-disable-next-line no-empty-pattern
  register: (name, options: RegisterOptions) => any
  unregister: (name) => any
  trigger: any
}

const EmailUserStyled = styled('div')`
  .MuiFormControl-root {
    width: 95%;
    gap: 0;
    label {
      margin-bottom: 8px;
    }
  }
`;

const ButtonStyled = styled(Button)`
  top: 50%;
`;

const AddEmailButton = styled(Button)`
  &.Mui-disabled {
    color: #FFFFFF
  }
  font-weight: 600;
  text-transform: none;
  font-size: 14px;
  line-height: 18px;
  padding: 0 40px 0 0;
  margin-left: 24px;
  min-width: 0;
`;

export default function EmailHostingRow(props: Props) {
  const {
    setValue, emailData, setEmailData, register, unregister, trigger,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const emptyObj = {
    id: '',
    name: '',
    email: '',
  };

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [funRemoveEmail, setFunRemoveEmail] = useState({ fn: () => {} });

  const customValidation = yup.string().email('Please enter a valid email in this format: name@example.com');

  const updateEmailData = (attr, key, value) => {
    setEmailData(emailData.map((obj, i) => {
      if (i === key) {
        return { ...obj, [`${attr}`]: value };
      }
      return obj;
    }));
  };

  const firstRow = (data) => {
    setValue('name-0', data.name);
    setValue('email-0', data.email);
    return (
      <Grid container key={0} xs={10}
        sx={{ marginTop: '12px' }}
      >
        <Grid item xs={5.5}>
          <EmailUserStyled>
            <IQFormInput
              {...register('name-0', { required: 'This is a required field.' })}
              labelText={`${t('pages.addons.emailHosting.nameTitle')} *`}
              fontLabelWeight="600"
              id="name-0"
              name="name-0"
              theme={theme}
              onChange={(e) => updateEmailData('name', 0, e.target.value)}
            />
          </EmailUserStyled>
        </Grid>
        <Grid item xs={6.5}>
          <EmailUserStyled>
            <IQInputEmail
              {...register('email-0', { required: 'Please enter a valid email in this format: name@example.com' })}
              labelText={`${t('pages.addons.emailHosting.emailTitle')} *`}
              fontLabelWeight="600"
              theme={theme}
              schema={schema}
              required
              label={t('pages.addons.emailHosting.emailTitle')}
              field="email-0"
              fullWidth
              customValidation={customValidation}
              adornmentIcon={<MailOutlineIcon />}
              onChange={(e) => updateEmailData('email', 0, e.target.value)}
            />
          </EmailUserStyled>
        </Grid>
      </Grid>
    );
  };

  const addNewRow = () => {
    setEmailData(prevState => [...prevState, emptyObj]);
  };

  const deleteEmail = (data, key) => {
    if (emailData[0].email) {
      trigger('email-0');
    }

    if (data.id === '') {
      setEmailData(emailData => {
        const updatedRows = emailData.filter((el, index) => index !== key);
        return updatedRows;
      });
    } else {
      setEmailData(emailData => {
        const updatedRows = emailData.filter((el) => Number(el.id) !== data.id);
        return updatedRows;
      });
      dispatch(deleteEmailHostingEmail({ emailId: data.id }));
    }
    setValue(`name-${key}`, '');
    setValue(`email-${key}`, '');
    unregister(`name-${key}`);
    unregister(`email-${key}`);
    setOpenDeleteConfirm(false);
  };

  const showDialogDeleteEmail = (funDelete, data, key) => {
    setFunRemoveEmail({ fn: () => funDelete(data, key) });
    setOpenDeleteConfirm(true);
  };

  const newRow = (data, key) => {
    setValue(`name-${key}`, data.name);
    setValue(`email-${key}`, data.email);
    return (
      <Grid container key={key} xs={10}
        sx={{ marginTop: '23px', position: 'relative' }}
      >
        <Grid item xs={5.5}>
          <EmailUserStyled>
            <IQFormInput
              {...register(`name-${key}`, { required: 'This is a required field.' })}
              labelText={`${t('pages.addons.emailHosting.nameTitle')} *`}
              fontLabelWeight="600"
              id={`name-${key}`}
              name={`name-${key}`}
              theme={theme}
              onChange={(e) => updateEmailData('name', key, e.target.value)}
            />
          </EmailUserStyled>
        </Grid>
        <Grid item xs={6}>
          <EmailUserStyled>
            <IQInputEmail
              {...register(`email-${key}`, { required: 'Please enter a valid email in this format: name@example.com' })}
              labelText={`${t('pages.addons.emailHosting.emailTitle')} *`}
              fontLabelWeight="600"
              theme={theme}
              schema={schema}
              required
              customValidation={customValidation}
              label={t('pages.addons.emailHosting.emailTitle')}
              field={`email-${key}`}
              fullWidth
              adornmentIcon={<MailOutlineIcon />}
              onChange={(e) => updateEmailData('email', key, e.target.value)}
            />
          </EmailUserStyled>
        </Grid>
        <Grid item xs={0.5} sx={{ position: 'absolute', top: '42px', right: '32px' }}>
          <ButtonStyled onClick={() => showDialogDeleteEmail(deleteEmail, data, key)}>
            <DeleteIcon />
          </ButtonStyled>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {emailData.map((data, index) => ((index === 0) ? firstRow(data) : newRow(data, index)))}
      <Grid container xs={12}>
        <Grid item xs={12} sx={{ marginTop: '33px', marginBottom: '11px' }}>
          <AddEmailButton
            onClick={() => addNewRow()}
          >
            +&nbsp;&nbsp;
            <u>Add Email</u>
          </AddEmailButton>
        </Grid>
      </Grid>
      <CoModalConfirm open={openDeleteConfirm}
        title="Remove Email Account?"
        description="Are you sure you want to remove the email account ?"
        confirmText="Remove"
        handleClose={() => setOpenDeleteConfirm(false)}
        handleConfirm={funRemoveEmail.fn}
      />
    </>
  );
}
