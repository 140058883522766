/* eslint-disable max-len */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';

const { t } = i18n;

export const SocialAdsWebClickWithDetailedTargetingSchema: RJSFSchema | any = (data) => ({
  title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.headerLabel'),
  type: 'object',
  required: [],
  properties: {
    guide: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.guideLabel'),
    },
    detailedTargetingPreferences: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.detailedTargetingPreferencesLabel'),
    },
    demographicTargeting: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.demographicTargetingDropDownLabel'),
      default: data?.demographicTargeting || '',
      enum: [
        '',
        t('form.big-box-labels.yes'),
        t('pages.custom.solutions.common.noOption'),
      ],
    },
    interestTargeting: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.interestTargetingDropDownLabel'),
      default: data?.interestTargeting || '',
      enum: [
        '',
        t('form.big-box-labels.yes'),
        t('pages.custom.solutions.common.noOption'),
      ],
    },
    behavioralTargeting: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.behavioralTargetingDropDownLabel'),
      default: data?.behavioralTargeting || '',
      enum: [
        '',
        t('form.big-box-labels.yes'),
        t('pages.custom.solutions.common.noOption'),
      ],
    },
    selectAll: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.selectAllLabel'),
    },
  },
  dependencies: {
    demographicTargeting: {
      oneOf: [
        {
          properties: {
            demographicTargeting: {
              enum: ['Yes'],
            },
            demographicTargetingHeaderLabel: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.demographicHeaderLabel'),
            },
            info: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.demographicHelp'),
            },
            info1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.demographicHelp1'),
            },
            info2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.demographicHelp2'),
            },
            info3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.demographicHelp3'),
            },
            info4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.demographicHelp4'),
            },
            info5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.demographicHelp5'),
            },
            info6: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.demographicHelp6'),
            },
            info7: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.demographicHelp7'),
            },
            desiredDetailsDemographicTargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.desiredDetailedLabel'),
              default: data?.desiredDetailsDemographicTargeting || '',
            },
          },
        },
        {
          properties: {
            demographicTargeting: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    interestTargeting: {
      oneOf: [
        {
          properties: {
            interestTargeting: {
              enum: ['Yes'],
            },
            interestTargetingHeaderLabel: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.interestHeaderLabel'),
            },
            advice: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.interestHelp'),
            },
            advice1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.interestHelp1'),
            },
            advice2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.interestHelp2'),
            },
            advice3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.interestHelp3'),
            },
            advice4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.interestHelp4'),
            },
            advice5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.interestHelp5'),
            },
            advice6: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.interestHelp6'),
            },
            advice7: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.interestHelp7'),
            },
            advice8: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.interestHelp8'),
            },
            desiredDetailsInterestTargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.desiredDetailedLabel'),
              default: data?.desiredDetailsInterestTargeting || '',
            },
          },
        },
        {
          properties: {
            interestTargeting: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    behavioralTargeting: {
      oneOf: [
        {
          properties: {
            behavioralTargeting: {
              enum: ['Yes'],
            },
            behavioralTargetingHeaderLabel: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.behavioralTargetingHeaderLabel'),
            },
            information: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.behavioralHelp'),
            },
            information1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.behavioralHelp1'),
            },
            information2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.behavioralHelp2'),
            },
            information3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.behavioralHelp3'),
            },
            information4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.behavioralHelp4'),
            },
            information5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.behavioralHelp5'),
            },
            information6: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.behavioralHelp6'),
            },
            information7: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.behavioralHelp7'),
            },
            information8: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.behavioralHelp8'),
            },
            desiredDetailsBehavioralTargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsWebClicksDetailedTargetingSection.socialAdsWebClicksDetailedTargeting.desiredDetailedLabel'),
              default: data?.desiredDetailsBehavioralTargeting || '',
            },
          },
        },
        {
          properties: {
            behavioralTargeting: {
              enum: ['No'],
            },
          },
        },
      ],
    },
  },
});

const dropDownKeys = [
  'demographicTargeting',
  'interestTargeting',
  'behavioralTargeting',
];

const dropDownFields = dropDownKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const textAreaKeys = [
  'desiredDetailsDemographicTargeting',
  'desiredDetailsInterestTargeting',
  'desiredDetailsBehavioralTargeting',
];

const textAreaFields = textAreaKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const textLabelKeys = [
  'guide',
  'detailedTargetingPreferences',
  'selectAll',
  'info', 'info1', 'info2', 'info3',
  'info4', 'info5', 'info6', 'info7',
  'advice', 'advice1', 'advice2', 'advice3',
  'advice4', 'advice5', 'advice6', 'advice7', 'advice8',
  'information', 'information1', 'information2', 'information3',
  'information4', 'information5', 'information6', 'information7', 'information8',
];

const textLabelFields = textLabelKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textLabel' },
}), {});

const headingTextKeys = [
  'demographicTargetingHeaderLabel',
  'interestTargetingHeaderLabel',
  'behavioralTargetingHeaderLabel',
];

const headingTextFields = headingTextKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'headingTextLabel' },
}), {});
export const SocialAdsWebClickWithDetailedTargetingUIOrderSchemaChange = {
  'ui:order': ['guide', 'detailedTargetingPreferences', 'demographicTargeting', 'interestTargeting', 'behavioralTargeting', 'selectAll',
    'demographicTargetingHeaderLabel', 'info', 'info1', 'info2', 'info3', 'info4',
    'info5', 'info6', 'info7',
    'desiredDetailsDemographicTargeting',
    'interestTargetingHeaderLabel', 'advice', 'advice1', 'advice2', 'advice3', 'advice4',
    'advice5', 'advice6', 'advice7', 'advice8',
    'desiredDetailsInterestTargeting',
    'behavioralTargetingHeaderLabel', 'information', 'information1', 'information2', 'information3', 'information4',
    'information5', 'information6', 'information7', 'information8',
    'desiredDetailsBehavioralTargeting'],

  ...headingTextFields,
  ...textLabelFields,
  ...textAreaFields,
  ...dropDownFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SocialAdsWebClickWithDetailedTargetingCustomValidateChange = (formData: any, errors: any, uiSchema: any) => errors;
