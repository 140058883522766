import { Urls } from 'navigation/Urls';

export const xmoUpgradeVariantsConstants = {
  1: {
    label: 'Solution Strategy (Lead Gen)',
    items: [
      Urls.GeneralQuestions,
      Urls.XmoDetails,
      '',
      Urls.XmoSocialSmartOptimization,
      Urls.XmoWebsiteClicks,
      Urls.XmoLeadAds,
      Urls.XmoRetargeting,
      Urls.XmoChat,
    ],
  },
};
