import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";

const {t} = i18n;

export const CustomFacebookMainSectionSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.customFacebookMainSection.title'),
  type: 'object',
  required: [],
  properties: {
    csmToUpdateFbRetargeting: {
      type: 'string',
      title: t('pages.ausProduct.customFacebookMainSection.csmToUpdateFbRetargeting'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
      description: t('pages.ausProduct.customFacebookMainSection.csmToUpdateFbRetargetingDescription'),
    },
    contactFieldsRequestFromTheCustomerLabel: {
      type: 'string',
      default: '',
      title: t('pages.ausProduct.customFacebookMainSection.contactFieldsRequestFromTheCustomer'),
    },
    contactFieldsRequestFromTheCustomer: {
      type: 'array',
      title: '',
      items: {
        type: 'string',
        enum: [
          t('pages.ausProduct.customFacebookMainSection.fullName'),
          t('pages.ausProduct.customFacebookMainSection.email'),
          t('pages.ausProduct.customFacebookMainSection.phoneNumber'),
          t('pages.ausProduct.customFacebookMainSection.postCode'),
        ],
      },
      uniqueItems: true,
    },
  },
});

const dropdownFieldKeys = [
  'csmToUpdateFbRetargeting'
];

const checkBoxesFieldKeys = [
  'contactFieldsRequestFromTheCustomer',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.tracking.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

const checkBoxesFields = checkBoxesFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'checkboxes'},
}), {});

export const CustomFacebookMainSectionUISchema = {
  'ui:order': [
    'csmToUpdateFbRetargeting',
    'contactFieldsRequestFromTheCustomerLabel',
    'contactFieldsRequestFromTheCustomer'
  ],
  contactFieldsRequestFromTheCustomerLabel: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginTop: '10px',
      fontWeight: '700',
      marginBottom: '5px',
    },
  },
  ...dropdownFields,
  ...checkBoxesFields
};

export const CustomFacebookMainSectionCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.contactFieldsRequestFromTheCustomer.length === 0) {
    errors.contactFieldsRequestFromTheCustomer.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
