/* eslint-disable max-len */
import i18n from 'i18n/i18n';

import {RJSFSchema} from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';

const {t} = i18n;

export const DemographicTargetingSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.ausTiktok.demographicTargetingLabel'),
  type: 'object',
  required: [
    'geographicTargeting',
    'whoAreWeLookingToTarget',
    'age',
    'additionalInformation',
  ],
  properties: {
    geographicTargeting: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.geographicTargetingLabel'),
      default: data?.geographicTargeting || '',
      description: t('pages.ausProduct.ausTiktok.geographicTargetingHelperText'),
    },
    whoAreWeLookingToTarget: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.whoAreWeLookingToTargetLabel'),
      default: data?.whoAreWeLookingToTarget || '',
    },
    age: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.ageLabel'),
      description: t('pages.ausProduct.ausTiktok.ageHelpText'),
      default: data?.age || '',
    },
    additionalInformation: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.additionalInformationLabel'),
      default: data?.additionalInformation || '',
    },
  },
});

const textFieldKeys = [
  'geographicTargeting',
  'whoAreWeLookingToTarget',
  'additionalInformation',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textInputField'},
}), {});

export const DemographicTargetingUISchema = {
  'ui:order': [
    'geographicTargeting',
    'whoAreWeLookingToTarget',
    'age',
    'additionalInformation'
  ],

  age: {
    'ui:widget': 'integerInputField'
  },
  ...textFields
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DemographicTargetingCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.geographicTargeting === '') {
    errors.geographicTargeting.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.whoAreWeLookingToTarget === '') {
    errors.whoAreWeLookingToTarget.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.age === '') {
    errors.age.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.additionalInformation === '') {
    errors.additionalInformation.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
