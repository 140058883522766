import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";

const {t} = i18n;

export const SpecificRequirementsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.specificRequirements.title'),
  type: 'object',
  required: [
    'source',
    'medium',
    'campaignName'
  ],
  properties: {
    helpText1: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.specificRequirements.helpText1'),
      margin: 0
    },
    source: {
      type: 'string',
      title: t('pages.ausProduct.specificRequirements.source'),
      default: data?.source || ''
    },
    medium: {
      type: 'string',
      title: t('pages.ausProduct.specificRequirements.medium'),
      default: data?.medium || ''
    },
    campaignName: {
      type: 'string',
      title: t('pages.ausProduct.specificRequirements.campaignName'),
      default: data?.campaignName || ''
    },
  },
});

const textLabelKeys = [
  'helpText1'
];

const textFieldKeys = [
  'source',
  'medium',
  'campaignName'
];

const textLabelFields = textLabelKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textLabel'},
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textInputField'},
}), {});

export const SpecificRequirementsUISchema = {
  'ui:order': [
    'helpText1',
    'source',
    'medium',
    'campaignName'
  ],
  ...textLabelFields,
  ...textFields
};

export const SpecificRequirementsCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.source === '') {
    errors.source.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.medium === '') {
    errors.medium.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.campaignName === '') {
    errors.campaignName.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
