import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import {validateUrl} from "pages/generic-product/details/custom-fields/UrlTextField";

const {t} = i18n;

export const ObjectiveDetailsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.objectiveDetails.title'),
  type: 'object',
  required: [],
  properties: {
    helpText1: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.objectiveDetails.helpText1'),
      margin: 0
    },
    anchorText1: {
      type: 'string',
      title: t('pages.ausProduct.objectiveDetails.anchorText1'),
      enum: [t('pages.ausProduct.objectiveDetails.anchorText1'), true],
    },
    helpText2: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.objectiveDetails.helpText2'),
      margin: 0
    },
    what: {
      type: 'string',
      title: t('pages.ausProduct.objectiveDetails.what'),
      default: '',
    },
    url: {
      type: 'string',
      title: t('pages.ausProduct.objectiveDetails.url'),
      default: '',
    },
    helpText3: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.objectiveDetails.helpText3'),
    },
    helpText4: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.objectiveDetails.helpText4'),
      margin: 0
    },
    anchorText2: {
      type: 'string',
      title: t('pages.ausProduct.objectiveDetails.anchorText2'),
      enum: [t('pages.ausProduct.objectiveDetails.anchorText2'), true],
    },
  },
});

const textLabelKeys = [
  'helpText1',
  'helpText2',
  'helpText3',
  'helpText4'
];

const anchorTextLabelKeys = [
  'anchorText1',
  'anchorText2'
];

const textFieldKeys = [
  'what'
];

const urlTextFieldKeys = [
  'url'
]

const textLabelFields = textLabelKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textLabel'},
}), {});

const anchorTextLabelFields = anchorTextLabelKeys.reduce((o, key) => ({
  ...o, [key]: {
    'ui:widget': 'anchorTextLabel',
    'ui:style': {
      marginTop: '-10px',
    },
  },
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textInputField'},
}), {});

const urlTextFields = urlTextFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'UrlTextWidget'},
}), {});

export const ObjectiveDetailsUISchema = {
  'ui:order': [
    'helpText1',
    'anchorText1',
    'helpText2',
    'what',
    'url',
    'helpText3',
    'helpText4',
    'anchorText2'
  ],
  ...textLabelFields,
  ...anchorTextLabelFields,
  ...textFields,
  ...urlTextFields
};

export const ObjectiveDetailsCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.url !== '' && !validateUrl(formData?.url)) {
    errors.url.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
