import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";

const {t} = i18n;

export const PremiumListings1To9Schema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.premiumListing.title'),
  type: 'object',
  required: [
    'campaignBudget',
    'isGmbAccessProvided',
    'confirmOrderViaLocalIq',
  ],
  properties: {
    campaignBudget: {
      type: 'string',
      title: t('pages.ausProduct.premiumListing.campaignBudgetLabel'),
      default: data?.campaignBudget || '',
      enum: [
        '',
        t('pages.ausProduct.premiumListing.campaignBudgetOpt1'),
        t('pages.ausProduct.premiumListing.campaignBudgetOpt2'),
        t('pages.ausProduct.premiumListing.campaignBudgetOpt3'),
        t('pages.ausProduct.premiumListing.campaignBudgetOpt4'),
      ]
    },
    isGmbAccessProvided: {
      type: 'string',
      title: t('pages.ausProduct.premiumListing.isGmbAccessProvidedLabel'),
      default: data?.isGmbAccessProvided || '',
      enum: [
        '',
        t('pages.ausProduct.premiumListing.yesOpt'),
        t('pages.ausProduct.premiumListing.noOpt'),
      ]
    },
    completeOrderInstructions: {
      type: 'string',
      title: t('pages.ausProduct.premiumListing.completeOrderInstructions'),
    },
    localIqLinks: {
      type: 'string',
      title: t('pages.ausProduct.premiumListing.localIqDesc'),
      enum: [t('pages.ausProduct.premiumListing.localIqLinks'), true]
    },
    grantAccessInstructions: {
      type: 'string',
      title: t('pages.ausProduct.premiumListing.grantAccessInstructions'),
    },
    grantAccessInstructionLinks: {
      type: 'string',
      title: t('pages.ausProduct.premiumListing.grantAccessInstructionLinkDes'),
      enum: [t('pages.ausProduct.premiumListing.grantAccessInstructionLinks'), true]
    },
    builderHelpGuide: {
      type: 'string',
      title: t('pages.ausProduct.premiumListing.builderHelpGuide'),
    },
    gannettLinks: {
      type: 'string',
      title: t('pages.ausProduct.premiumListing.gannettLinksDes'),
      enum: [t('pages.ausProduct.premiumListing.gannettLinks'), true]
    },
    confirmOrderViaLocalIq: {
      type: 'string',
      title: t('pages.ausProduct.premiumListing.confirmOrderViaLocalIqLabel'),
      default: data?.confirmOrderViaLocalIq || '',
      enum: [
        '',
        t('pages.ausProduct.premiumListing.yesOpt'),
        t('pages.ausProduct.premiumListing.noOpt'),
      ]
    },
  },
  dependencies: {
  }
});

const dropdownFieldKeys = [
  'campaignBudget',
  'isGmbAccessProvided',
  'confirmOrderViaLocalIq',
];

const anchorTextFieldsKeys = [
  'localIqLinks',
  'gannettLinks',
  'grantAccessInstructionLinks',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'dropdown'},
}), {});

const anchorTextFields = anchorTextFieldsKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': 'anchorTextLabel',
    'ui:style': {
      marginTop: '-8px',
    },
  },
}), {});

export const PremiumListings1To9UISchema = {
  'ui:order': [
    'campaignBudget',
    'isGmbAccessProvided',
    'grantAccessInstructions',
    'grantAccessInstructionLinks',
    'completeOrderInstructions',
    'localIqLinks',
    'builderHelpGuide',
    'gannettLinks',
    'confirmOrderViaLocalIq',
  ],

  builderHelpGuide: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginTop: '-8px',
    },
  },
  grantAccessInstructions: {
    'ui:widget': 'textLabel',
  },
  completeOrderInstructions: {
    'ui:widget': 'textLabel',
  },

  ...dropdownFields,
  ...anchorTextFields,
};
export const PremiumListings1To9CustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.campaignBudget === '') {
    errors.campaignBudget.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.isGmbAccessProvided === '') {
    errors.isGmbAccessProvided.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.confirmOrderViaLocalIq === '') {
    errors.confirmOrderViaLocalIq.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
