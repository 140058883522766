/* eslint-disable no-nested-ternary */
import {
  Box, Container, Theme, useTheme,
} from '@mui/material';
import styled from '@emotion/styled';
import { backgroundDefaultColor } from 'styles/common_styles';
import { useSelector } from 'react-redux';
import { selectOrderFlow, selectOrdersContent } from 'services/ordersSlice';
import { OrderFlow } from 'shared/constants';
import { useState } from 'react';
import { drawerWidth } from './navBars/OrderNavBar';
import TopnavMenu from './topnav/TopnavMenu';
import TopnavMenuContainer from './topnav/TopnavMenuContainer';
import LeftNavMenu from './leftnav/LeftNavMenu';

export type LayoutProps = {
  theme: Theme;
  popup?: boolean;
  toggleMenu?: boolean;
};

export type ThemeMainProps = {
  theme: Theme;
  drawerWidth: number;
};

interface OrderEditLayoutProps {
  children?: any
  menu?: string
  popup?: boolean
}

export const RootStyle = styled('div') <LayoutProps>`
  background-color: ${backgroundDefaultColor};
  display: grid;
  // eslint-disable-next-line no-nested-ternary
  grid-template-columns: ${(props) => (props.popup ? '' : (props.toggleMenu ? '123px' : '260px'))} auto;
  z-index: 1;
`;

export const MainBodyStyle = styled('div') <ThemeMainProps>`
  background-color: ${backgroundDefaultColor};
  margin-top: 50px;
  `;

export const ContainerStyle = styled(Container) <LayoutProps>`
  padding: 0;
  max-width: 968px;
  margin-left: auto;
  margin-right: auto; 
  &.MuiContainer-maxWidthLg {
    padding: 0px;
  }
  &.MuiContainer-maxWidthXl {
    padding: 0px;
  }
  `;

export default function OrderEditLayout(props: OrderEditLayoutProps) {
  const theme = useTheme();
  const orderFlow = useSelector(selectOrderFlow);
  const orderData = useSelector(selectOrdersContent);
  const isPunchListFlow = (orderFlow === OrderFlow.PUNCHLIST || orderData.orderFlow === OrderFlow.PUNCHLIST);
  const [toggleMenu, setToggleMenu] = useState<boolean>(isPunchListFlow);
  const { menu, popup } = props;
  return (
    <RootStyle theme={theme} popup={popup} toggleMenu={toggleMenu}>
      {!popup && (
        <Box>
          <LeftNavMenu
            menu={menu}
            isPunchListFlow={isPunchListFlow}
            toggleMenu={toggleMenu}
            setToggleMenu={setToggleMenu}
          />
        </Box>
      )}
      <Box sx={{ height: '100vh' }}>
        <TopnavMenuContainer popup={popup} toggleMenu={toggleMenu}>
          {/* {menu && <TopnavMenu menu={menu} />} */}
          <TopnavMenu menu={menu} />
        </TopnavMenuContainer>
        <MainBodyStyle theme={theme} drawerWidth={drawerWidth}>
          <ContainerStyle maxWidth={false} theme={theme}>
            {props.children}
          </ContainerStyle>
        </MainBodyStyle>
      </Box>
    </RootStyle>
  );
}
