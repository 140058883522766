import {
  Box, Button, Alert, useTheme, Link,
} from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { S1 } from 'components/labels/S1';
import { useEffect, useState } from 'react';
import CampaignModal from './CampaignModal';

interface Props {
  setShowBanner: (data) => any
  bannerType: 'ERROR' | 'SUCCESS';
  showModal: boolean
}

export default function CidBanner({ setShowBanner, bannerType, showModal }: Props) {
  const theme = useTheme();
  const [openCampaignModal, setOpenCampaignModal] = useState(showModal);
  const [modalBannerType, setModalBannerType] = useState(bannerType);

  useEffect(() => {
    if (showModal) {
      setOpenCampaignModal(true);
    }
  }, [showModal]);

  const dismissBanner = () => setShowBanner(false);

  const bannerColor = modalBannerType === 'ERROR' ? theme.palette.error.light : theme.palette.success.dark;

  const icon = modalBannerType === 'ERROR' ? <WarningAmberOutlinedIcon sx={{ fill: bannerColor, fontSize: 32 }} />
    : <CheckCircleOutlineIcon sx={{ fill: bannerColor, fontSize: 32 }} />;

  const openModal = () => setOpenCampaignModal(true);

  const bannerTitle = modalBannerType === 'ERROR' ? 'CID creation failed' : 'CID Update Successful';

  const linkUrl = 'https://cc-usa.localiq.com/';
  const linkText = 'Campaign Central';

  const htmlString = (
    <div>
      Please go to
      <Link href={linkUrl} target="_blank" rel="noopener noreferrer"
        sx={{ marginX: 1, color: theme.palette.common.black }}
      >
        {linkText}
      </Link>
      and create a new campaign for all failed CIDs. Then click ‘Select CID’ to select the CID for each product
      to continue processing your order.`
    </div>
  );

  const bannerDescription = modalBannerType === 'ERROR' ? htmlString
    : 'The new CID(s) has now been added to your order.';

  const bannerAction = modalBannerType === 'ERROR' ? (
    <Button
      onClick={openModal}
      disableElevation
      disableRipple
      sx={{
        textDecoration: 'underline',
        backgroundColor: 'transparent',
        fontWeight: '400',
        color: theme.palette.common.black,
      }}
    >
      Select CID
    </Button>
  )
    : (
      <Button
        onClick={dismissBanner}
        disableElevation
        disableRipple
        sx={{
          textDecoration: 'underline',
          backgroundColor: 'transparent',
          fontWeight: '400',
          color: theme.palette.common.black,
          position: 'relative',
          left: '110%',
        }}
      >
        Dismiss
      </Button>
    );

  return (
    <>
      <Box sx={{
        marginTop: '40px',
        marginBottom: '40px',
        width: '100%',
        justifyContent: 'space-between',
      }}
      >
        <Alert variant="outlined" severity="success" icon={icon}
          sx={{
            backgroundColor: 'common.white',
            border: '1px solid common.black',
            borderColor: 'common.black',
            color: 'common.black',
            borderRadius: '5px',
            width: '100%',
            borderLeft: `12px solid ${bannerColor}`,
            alignItems: 'center',
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="center"
            width="100%" justifyContent="space-between"
          >
            <S1 sx={{
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
              color: 'main.dark',
              whiteSpace: 'nowrap',
              marginRight: '20px',
            }}
            >
              {bannerTitle}
            </S1>
            <S1 sx={{
              fontSize: '14px', fontWeight: 400, lineHeight: '21px', marginRight: 'auto',
            }}
            >
              {bannerDescription}
            </S1>
            {bannerAction}
          </Box>
        </Alert>
      </Box>
      <CampaignModal open={openCampaignModal} closeModal={setOpenCampaignModal} setBannerType={setModalBannerType} />
    </>
  );
}
