import {
  Grid, InputAdornment, TextField, Typography,
} from '@mui/material';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import schemaValidate from 'components/schemaValidate';
import NePhoneInput from 'components/NePhoneInputContainer/IQPhoneInputContainer';
import ErrorText from 'components/errorText/ErrorText';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';

interface Props {
  name: string;
  phone: string;
  phoneExtension: string;
  email: string;
  register: any;
  errors: any;
  schema: any;
  phoneRequired: boolean;
}

export default function ContactForm(props: Props) {
  const {
    name, email, phone, phoneExtension, register, errors, schema, phoneRequired,
  } = props;

  const getNameLabel = () => {
    if (name.includes('developer')) return 'Web Developer Full Name *';
    if (name.includes('main') || name.includes('campaign')) return 'Main Contact Full Name *';
    if (name.includes('access')) return 'Access Contact Full Name *';
    return 'Contact Full Name *';
  };

  const getNameErrorText = () => {
    if (name.includes('developer')) return 'Please enter web developer full name.';
    if (name.includes('main') || name.includes('campaign')) return 'Please enter main contact full name.';
    if (name.includes('access')) return 'Please enter access contact full name.';
    return 'Please enter contact full name.';
  };

  return (
    <>
      <Grid item mb={3} xs={12}>
        <Typography variant="subtitle2" component="h3" fontSize={16}
          color={errors[name] ? 'error.main' : 'common.black'}
          marginBottom={1}
        >
          {getNameLabel()}
        </Typography>
        <TextField
          {...register(name, {
            validate: (value) => schemaValidate(value, name, schema),
          })}
          hiddenLabel
          required
          name={name}
          error={!!errors[name]}
          fullWidth
        />
        <ErrorText
          hasError={!!errors[name]}
          errorText={getNameErrorText()}
        />
      </Grid>
      <Grid item mb={3} xs={12}>
        <Typography variant="subtitle2" component="h3" fontSize={16}
          color={errors[email] ? 'error.main' : 'common.black'} mb={1}
        >
          Email *
        </Typography>
        <TextField
          {...register(email, {
            validate: (value) => schemaValidate(value, email, schema),
          })}
          hiddenLabel
          required
          name={email}
          type="email"
          error={!!errors[email]}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <ErrorText
          hasError={!!errors[email]}
          errorText="Please enter email."
        />
      </Grid>
      <Grid item mb={3} xs={12}>
        <NePhoneInput
          name={phone}
          defaultValue=""
          country="US"
          hasExtension
          extensionOptions={{
            name: phoneExtension,
            defaultValue: '',
          }}
          phoneLabelOptions={{
            tooltipText: '',
            labelText: 'Phone Number',
            hasError: phoneRequired,
            theme: IQThemeV2,
            required: phoneRequired,
          }}
          hasType={false}
          countryCallingCodeEditable={false}
          international
        />
      </Grid>
    </>
  );
}
