import * as yup from 'yup';
import i18n from 'i18n/i18n';
import {urlValidationRegex} from "shared/constants";

const { t } = i18n;

// eslint-disable-next-line max-len, no-useless-escape
const schema = {
  sitelinkName: yup.string().required(t('pages.xmo.sem.sitelinkNameErrorMessage')),
  sitelinkUrl: yup.string()
    .matches(urlValidationRegex, t('pages.xmo.sem.sitelinkUrlErrorMessage'))
    .required(t('pages.xmo.sem.sitelinkUrlErrorMessage')),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
