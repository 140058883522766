import {Urls} from 'navigation/Urls';
import useProduct from 'hooks/useProduct';
import {StatusType} from 'models/StatusType';
import ContentLoader from 'components/contentLoader/ContentLoader';
import {GetTargetDisplayResponse, TargetedDisplayLegacyRequestContentType} from "models/TargetedDisplayLegacyType";
import ClientInformationPageForm from "pages/target-display-legacy/client-information/ClientInformationPageForm";

export default function ClientInformationTargetedDisplayLeagacy() {
  const {product, onSubmit, onBack} = useProduct<TargetedDisplayLegacyRequestContentType,
      StatusType<GetTargetDisplayResponse>>(Urls.TargetedDisplayLeagacyClientInformation);
  return (
      <ContentLoader status={product.status} message={product.message}>
        <ClientInformationPageForm
            product={product.content.data}
            onSubmit={onSubmit}
            onBack={onBack}
        />
      </ContentLoader>
  );
}
