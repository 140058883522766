import {
  Box, Divider, Grid, Theme, useTheme,
} from '@mui/material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { IQTheme } from '@gannettdigital/shared-react-components';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { SaveAndCloseContext } from 'context/SaveAndCloseContext';
import { H2 } from 'components/labels/H2';
import { CoButtonContinue } from 'components/buttons/CoButtonContinue';
import { CoButtonBack } from 'components/buttons/CoButtonBack';
import { Urls } from 'navigation/Urls';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { backgroundDefaultColor } from 'styles/common_styles';

type ThemeProps = {
  theme: Theme;
};

const RootStyle = styled('div')<ThemeProps>`
  background-color: ${backgroundDefaultColor};
`;

export const GridDescription = styled(Grid)<ThemeProps>`
  text-transform: none;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Unify Sans';
  color: ${(props) => props.theme.palette.text.primary};
  margin-top: 16px;
`;

export interface DefaultPageLayoutProps {
  children: any;
  header: any;
  disableContinue?: boolean;
  skipAndContinue?: boolean;
  disableBack?: boolean;
  description?: any;
  onContinue?: () => any;
  onBack?: () => any;
  continueButtonLabel?: string;
  backButtonLabel?: string;
  subHeader?: string;
}

export function DefaultPageLayout({
  children,
  header,
  subHeader,
  disableContinue = false,
  skipAndContinue = false,
  disableBack = false,
  description,
  onContinue,
  onBack,
  continueButtonLabel,
  backButtonLabel,
}: DefaultPageLayoutProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigationHandler();
  const saveAndCloseContext = useContext(SaveAndCloseContext);

  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);
  const isRedirectedFromReviewPage = useMemo(() => history.state?.previousPath.includes('review'), [history]);
  const [showContinueModal, setShowContinueModal] = useState(false);

  // TODO: skipping the optional arguments with undefined is needs to be refactored
  const backClick = () => {
    if (isRedirectedFromOverviewPage) {
      saveAndCloseContext.setShowModal(false);
      return navigate.to(Urls.Overview, undefined, false);
    }
    if (isRedirectedFromReviewPage) return navigate.to('review?reviewId=true');

    return onBack ? onBack() : navigate.back();
  };

  useEffect(() => {
    saveAndCloseContext.setFormValid(!disableContinue);
  }, [disableContinue]);

  // the save and close will act like the continue, but sending the user to the home page
  useEffect(() => {
    saveAndCloseContext.setSaveAndCloseAction(onContinue);
  }, []);

  const continueButtonText = (isRedirectedFromOverviewPage || isRedirectedFromReviewPage)
    ? 'Save & Return' : (continueButtonLabel
    || (skipAndContinue ? t('form.buttons.skipAndContinue') : t('form.buttons.continue')));

  const backButtonText = (isRedirectedFromOverviewPage || isRedirectedFromReviewPage)
    ? 'Cancel' : (backButtonLabel || t('form.buttons.back'));

  const continueFunc = () => {
    navigate.clearDefaultNavigation();
    // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
    onContinue && onContinue();
  };

  const confirmSaveClose = () => {
    setShowContinueModal(false);
    continueFunc();
  };

  const handleContinue = () => {
    if ((isRedirectedFromOverviewPage || isRedirectedFromReviewPage) && saveAndCloseContext.isFormDirty) {
      setShowContinueModal(true);
    } else {
      continueFunc();
    }
  };

  const confirmModalDescription = (
    <div>
      <Typography marginBottom={2}>Are you sure you want to save these changes?</Typography>
      <Typography>
        <b>Note: </b>
        Changes may impact the accuracy of the order so please review the order overview before submitting.
      </Typography>
    </div>
  );

  return (
    <RootStyle theme={theme}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {subHeader && (
            <Typography
              sx={{ marginTop: '40px', fontSize: '18px' }}
              color={theme.palette.text.secondary}
            >
              {subHeader}
            </Typography>
          )}
          <H2 hasSubHeader={!!subHeader}>{header}</H2>
        </Grid>
        {description && (
          <GridDescription item xs={12} theme={IQTheme}>
            {description}
          </GridDescription>
        )}
        <Grid item xs={12} sx={{ marginTop: '28px' }}>
          {children}
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ marginTop: '64px !important', marginBottom: '40px !important' }} />
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: '117px' }}>
          <Box>
            <CoButtonBack
              label={backButtonText}
              onClick={() => {
                navigate.clearDefaultNavigation();
                backClick();
              }}
              disabled={disableBack}
              sx={{ float: 'left', minWidth: 'fit-content' }}
            />
            <CoButtonContinue
              label={continueButtonText}
              disabled={disableContinue}
              sx={{ float: 'right', minWidth: 'fit-content' }}
              id="continueButton"
              type="submit"
              onClick={() => handleContinue()}
            />
          </Box>
        </Grid>
        <CoModalConfirm
          open={showContinueModal}
          title="Save Changes"
          description={confirmModalDescription}
          confirmText="Save"
          cancelText="Back"
          handleClose={() => setShowContinueModal(false)}
          handleConfirm={confirmSaveClose}
        />
      </Grid>
    </RootStyle>
  );
}

export default DefaultPageLayout;
