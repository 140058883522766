import * as yup from 'yup';
import i18n from 'i18n/i18n';

const {t} = i18n;

const schema = {
  doesHaveWebsite: yup.string().required(t('pages.youtube.websites.doesHaveWebsiteErrorMsg')).nullable(),
  isReachSiteLive: yup.string().notRequired(),
  temporaryLink: yup.string().url(t('pages.youtube.websites.temporaryLinkErrorMsg')).notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};