/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import AxiosAPI from './common/axios';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { thunkBuilder } from './common/functions';

export interface AddressState {
  address: StatusType<any>
}

const initialState: AddressState = {
  address: {
    status: 'idle',
    message: '',
    content: {},
  },
};

export const getAddressSuggestions = createAsyncThunkCatchErrors(
  'addressSuggestions/get',
  async (country: string, query: string) => {
    const response = await AxiosAPI.get(`/address/suggestions?country=${country}&query=${query}`);
    return response.data;
  },
);

export const addressSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder).addCase(getAddressSuggestions, 'address');
  },
);

export const selectAddressSuggestions = (state: RootState) => state.address.address.content;

export default addressSlice;
