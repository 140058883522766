import { Typography } from '@mui/material';
import 'styles/website_fonts.css';

export interface H5Props {
  className?: string;
  children: Node | string;
}

export function H5({ children, className }: H5Props) {
  return (
    <Typography variant="h5" gutterBottom component="div"
      sx={{ color: 'common.black' }}
      {...{ className }}
    >
      {children}
    </Typography>
  );
}

export default H5;
