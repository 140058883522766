import { Suspense, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Typography } from '@mui/material';
import { IQDatePicker, IQThemeV2, ValidationProvider } from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';

import LandingPageType, { LandingPageRequestContentType } from 'models/LandingPageType';

import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import ContentLoader from 'components/contentLoader/ContentLoader';

import GridItem from 'components/grid/GridItem';
import ErrorText from 'components/errorText/ErrorText';
import schema from './Timeline.schema';

type StyledContainerProps = { hasError?: boolean };

const StyledGridContainer = styled(Grid, { shouldForwardProp: (prop) => prop !== 'hasError' })<StyledContainerProps>`
  display: flex;

  label {
    padding-bottom: 8px;
  }

  & > .MuiGrid-container {
    width: auto;
    max-width: 274px;
  }
`;

interface Props {
  product: LandingPageType;
  onSubmit: (data :LandingPageRequestContentType) => void;
  onBack: () => void;
}

export type TimelineFormProps = {
  goLiveDate: Date | null;
  endDate: Date | null;
};

const parseDate = (date: string) => {
  if (date === null || date === undefined) return null;
  return new Date(date);
};

/*
* NOTE: The backend accepts and returns the dates as strings, but the datepicker components work with dates
*/

export default function TimelineForm(props: Props) {
  const { product } = props;
  const { t } = useTranslation();

  const methods = useForm<TimelineFormProps>({
    mode: 'all',
    defaultValues: {
      goLiveDate: parseDate(product.goLiveDate),
      endDate: parseDate(product.endDate),
    },
  });

  const {
    handleSubmit,
    trigger,
    getValues,
    watch,
    setError,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful, errors,
    },
  } = methods;

  const hasError = Object.keys(errors).length > 0;

  const onSubmit = (data: TimelineFormProps) => {
    const goLiveDate = data.goLiveDate ? data.goLiveDate.toString() : null;
    const endDate = data.endDate ? data.endDate.toString() : null;
    const dataToSubmit = { goLiveDate, endDate };
    props.onSubmit(dataToSubmit);
  };

  useEffect(() => {
    const start = getValues('goLiveDate');
    const end = getValues('endDate');
    if (end < start) {
      setError('goLiveDate', {
        message: 'Please select or enter a date before the End Date selection.',
      });
      return;
    }

    if (isDirty) trigger();
  }, [watch('endDate'), watch('goLiveDate')]);

  return (
    <DefaultPageLayout
      disableContinue={hasError}
      onBack={props.onBack}
      header={t('pages.landing.timeline.header')}
      onContinue={handleSubmit(onSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Suspense fallback={<ContentLoader status="loading" />}>
              <StyledGridContainer xs={12} hasError={hasError}>
                <GridItem marginBottom={0}>
                  <IQDatePicker
                    id="timeline-datepicker-start"
                    name="goLiveDate"
                    labelText={t('pages.landing.timeline.goLiveDate')}
                    fontLabelWeight="600"
                    theme={IQThemeV2}
                    disablePast={false}
                    minDate={new Date('01-01-1800')}
                    hasError={!!errors?.goLiveDate}
                  />
                  <ErrorText
                    hasError={!!errors?.goLiveDate}
                    errorText={errors?.goLiveDate?.message}
                  />
                </GridItem>
                <Typography sx={{ margin: '0 16px', position: 'relative', top: '38px' }}>
                  to
                </Typography>
                <GridItem marginBottom={0}>
                  <IQDatePicker
                    id="timeline-datepicker-end"
                    name="endDate"
                    labelText={t('pages.landing.timeline.endDate')}
                    fontLabelWeight="600"
                    theme={IQThemeV2}
                    disablePast={false}
                    minDate={new Date('01-01-1800')}
                    hasError={!!errors?.endDate}
                  />
                  <ErrorText
                    hasError={!!errors?.endDate}
                    errorText={errors?.endDate?.message}
                  />
                </GridItem>
              </StyledGridContainer>
            </Suspense>
            {/* show the dialog if we changed the field and didn't submit the changes */}
            <CoNavigationConfirm
              showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </Box>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
