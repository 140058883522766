/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { OrderItems } from 'hooks/useOrderItemId';
import OrdersItemsType from 'models/OrderItemsType';
import OrdersType from 'models/OrdersType';
import { StatusType } from 'models/StatusType';
import { AddonsOfferingTypes } from 'pages/business/campaignIds/CampaignIds';
import { TypeOfSaleConstants } from 'pages/shopping-cart/Constants';
import { OrderFlow } from 'shared/constants';
import AxiosAPI from './common/axios';
import { thunkBuilder } from './common/functions';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { RootState } from './redux/store';

export interface OrdersState {
  order: StatusType<OrdersType>
  orderItems: StatusType<OrdersItemsType[]>
  orderFlow: StatusType<OrderFlow>
}

const initialState: OrdersState = {
  orderItems: {
    status: 'idle',
    message: '',
    content: [],
  },
  order: {
    status: 'idle',
    message: '',
    content: {},
  },
  orderFlow: {
    status: 'idle',
    message: '',
    content: OrderFlow.INTERNAL,
  },
};

export const requestNewOrder = createAsyncThunkCatchErrors(
  'orders/request',
  async () => {
    const response = await AxiosAPI.post('/orders');
    return response.data;
  },
);

export const addItemToOrder = createAsyncThunkCatchErrors(
  'orders/items/add',
  async ({ orderId, content } : { orderId: number, content: any }) => {
    const response = await AxiosAPI.post(`/orders/${orderId}/items/`, content);
    return response.data;
  },
);

export const setOrderItemTypeOfSale = createAsyncThunkCatchErrors(
  'orderItem/typeOfSale',
  async ({ orderItemId, content } : { orderItemId: number, content: string }) => {
    const response = await AxiosAPI.put(
      `/orders/items/${orderItemId}/saleType`,
      content,
      { headers: { 'Content-Type': 'text/plain' } },
    );
    return response.data;
  },
);

export const getOrder = createAsyncThunkCatchErrors(
  'orders/get',
  async (orderId: string) => {
    const response = await AxiosAPI.get(`/orders/${orderId}`);
    return response.data;
  },
);

export const getOrderItems = createAsyncThunkCatchErrors(
  'orders/getItems',
  async (orderId: string) => {
    const response = await AxiosAPI.get(`/orders/${orderId}/itemsWithTypes`);
    return response.data;
  },
);

export const startOrder = createAsyncThunkCatchErrors(
  'orders/start',
  async ({ orderId, orderFlow } : { orderId: string, orderFlow: string }) => {
    const response = await AxiosAPI.put(`/orders/${orderId}/start?orderFlow=${orderFlow}`);
    return response.data;
  },
);

export const submitOrder = createAsyncThunkCatchErrors(
  'order/submit',
  async (orderId: string) => {
    const response = await AxiosAPI.put(`/orders/${orderId}/submit/neon_order`);
    return response.data;
  },
);

export const finalizeOrder = createAsyncThunkCatchErrors(
  'order/finalize',
  async (orderId: string) => {
    const response = await AxiosAPI.put(`/orders/${orderId}/finalize/neon_listings`);
    return response.data;
  },
);

export const setOrderCountry = createAsyncThunkCatchErrors(
  'order/country',
  async ({ orderId, country } : { orderId: string, country: string }) => {
    const response = await AxiosAPI.put(`/orders/${orderId}/${country}`);
    return response;
  },
);

export const updateOrderItemsWithSalesforceId = createAsyncThunkCatchErrors(
  'orderItemSalesforceId/update',
  async (content: any) => {
    const response = await AxiosAPI.put('/orders/items/salesforce_offers', content);
    return response;
  },
);

export const removeOrderItem = createAsyncThunkCatchErrors(
  'orderItem/delete',
  async (orderItemId: number) => {
    const response = await AxiosAPI.delete(`/orders/items/${orderItemId}`);
    return response.data;
  },
);

export const setWebsiteThemeId = createAsyncThunkCatchErrors(
  'orderItem/setThemeId',
  async ({ orderItemId, content } : { orderItemId: number | string, content: any }) => {
    const response = await AxiosAPI.put(`/orders/items/${orderItemId}`, content);
    return response.data;
  },
);

export const removeAddonOrderItems = createAction('order/orderItems/setEmpty');
export const updateOrderFlow = createAction<OrderFlow>('order/updateOrderFlow');

export const ordersSlice = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(removeOrderItem.fulfilled, (state, { payload }) => {
        const updatedItems = [...state.orderItems.content];
        const index = updatedItems.findIndex(obj => obj.id === payload.id);
        updatedItems.splice(index, 1);
        state.orderItems.content = updatedItems;
        state.orderItems.status = 'idle';
        state.orderItems.message = '';
      })
      .addCase(removeAddonOrderItems, (state) => {
        const updatedItems = [...state.orderItems.content];
        const items = updatedItems.filter(item => !AddonsOfferingTypes.includes(item.offeringType));
        state.orderItems.content = items;
      })
      .addCase(updateOrderFlow, (state, action) => {
        state.orderFlow.content = action.payload;
      });

    thunkBuilder(builder)
      .addCase(requestNewOrder, 'order')
      .addCase(getOrder, 'order')
      .addCase(getOrderItems, 'orderItems');
  },
);

export const findOrderItem = (orderItems: StatusType<OrdersItemsType[]>, offeringType: OrderItems) => {
  const orderItem = orderItems.content.find(orderItem => orderItem.offeringType === offeringType);

  if (orderItem) return orderItem;

  return null;
};

export const selectOrdersContent = (state: RootState) => state.orders.order.content;

export const selectOrdersStatus = (state: RootState) => state.orders.order.status;

export const selectOrdersMessage = (state: RootState) => state.orders.order.message;

export const selectOrderItems = (state: RootState) => state.orders.orderItems;

export const selectIsPartnerFlow = (state: RootState) =>
  state.orders.orderItems.content.some(item => item.partnerAccountId && item.partnerAccountId.toString().length > 0);

export const selectIsBlueFlow = (state: RootState) => state.orders.orderItems.content
  .some(item => item.isBlue === true);

export const selectAllItemsRebuild = (state: RootState) => state.orders.orderItems.content
  .every(item => item.saleType === TypeOfSaleConstants.REBUILD);

export const selectOrderFlow = (state: RootState) => state.orders.orderFlow.content;

export default ordersSlice;
