import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';

export const customLandingQuotesMenu: MenuType = ({
  label: 'Custom Landing Page',
  url: '',
  status: 'incomplete',
  offeringType: 'CustomLandingPage',
  subSteps: [
    {
      label: 'Details',
      url: Urls.LandingCustomDetails,
      status: 'incomplete',
    },
    {
      label: 'Design',
      url: Urls.LandingCustomDesign,
      status: 'incomplete',
    },
    {
      label: 'Development',
      url: Urls.LandingCustomDevelopment,
      status: 'incomplete',
    },
    {
      label: 'Additional',
      url: Urls.LandingCustomAdditionalInfo,
      status: 'incomplete',
    },
  ],
});
