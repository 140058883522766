/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { isMobile } from 'react-device-detect';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { Grid, useTheme } from '@mui/material';
import { IQLabelTooltip } from '@gannettdigital/shared-react-components';
import {
  IQFileUploaderProps, ErrorInfoText, ErrorIconStyled, SupportiveText, IQButton, CloudUploadIconStyled, AssistiveText, Container,
} from './NeFileUploader';

enum UploadEventType {
  DRAGANDDROP = 'Drag & Drop',
  FILEUPLOAD = 'File Upload',
}

export default function BaseFileUploader({
  name,
  label = 'File Uploader',
  withLabelError = true,
  supportingText = 'Some supporting text',
  withSupportingTextError = false,
  buttonLabel = 'Browse',
  toolTiptext,
  recommendedImgText = '000px x 000px',
  size = 'lg',
  disabled = false,
  required = false,
  maxFiles = 4,
  totalAllowedFiles = 40,
  currentUploadedFiles = 0,
  minFileSize = 100,
  maxFileSize = 255000000,
  showAcceptedFileTypesText = true,
  showRecommendedImageText = true,
  showMaxFileSizeText = true,
  showMaxNumberFilesText = false,
  imageTypes = ['.png', '.gif', '.jpeg', '.jpg', '.CR2', '.DNG'],
  videoTypes = ['.avi', '.3g2', '.3gp', '.mp4', '.mpeg', '.ogv', '.ts', '.webm'],
  documentTypes = ['.pdf'],
  onSuccess,
  onFailure,
  onBlur,
  requiredError,
  onDropCallback,
}:IQFileUploaderProps) {
  const uploadedAmount = useRef(currentUploadedFiles);
  const [hasError, setHasError] = useState(false);
  const [uniqueErrorMsgs, setUniqueErrorMsgs] = useState(null);
  const fieldRef = React.useRef(null);
  const inputRef = React.useRef(null);

  const errorMsgs = [];

  useEffect(() => {
    if (requiredError) errorMsgs.push(requiredError);
  }, [requiredError]);

  const theme = useTheme();

  const acceptedFileExtensions = imageTypes.concat(documentTypes).concat(videoTypes)
    .map((extn) => extn.toUpperCase().replace('.', ''))
    .join(', ')
    .replace(/, ([^,]*)$/, ' and $1');

  const onDrop = useCallback(() => {
    /* if (field?.onChange) {
        field.onChange(acceptedFiles);
      } */
  }, []);

  const validator = (file) => {
    if (uploadedAmount.current >= totalAllowedFiles) {
      return {
        code: 'upload-limit-reached',
        message: `The file ${file.name} could not be uploaded. The file number has exceeded
             the maximum limit (${totalAllowedFiles} files).`,
      };
    }

    const fileExtension = file.path.slice(file.path.lastIndexOf('.'));

    const validFileType = (types: string[], extension: string) => types.some(
      (type: string) => type.toLowerCase() === extension.toLowerCase(),
    );

    if (!validFileType(imageTypes, fileExtension)
            && !validFileType(videoTypes, fileExtension)
            && !validFileType(documentTypes, fileExtension)) {
      return {
        code: ErrorCode.FileInvalidType,
        message: `The specified file ${file.name} could not be uploaded.
            Only files with the following extensions are allowed: ${acceptedFileExtensions}`,
      };
    }

    if (file && file.path) {
      uploadedAmount.current += 1;
    }

    return null;
  };

  const onDropAccepted = (files, event) => {
    if (event && event.type === 'drop') {
      uploadedAmount.current -= files.length;
      if (onDropCallback) {
        onDropCallback(UploadEventType.DRAGANDDROP);
      }
    } else if (onDropCallback) {
      onDropCallback(UploadEventType.FILEUPLOAD);
    }

    if (onSuccess && typeof onSuccess === 'function') {
      return onSuccess(files);
    }

    return null;
  };

  const onDropRejected = (files, event) => {
    if (event && event.type === 'drop') {
      if (onDropCallback) {
        onDropCallback(UploadEventType.DRAGANDDROP);
      }
    } else if (onDropCallback) {
      onDropCallback(UploadEventType.FILEUPLOAD);
    }

    if (onFailure && typeof onFailure === 'function') {
      return onFailure(files);
    }

    return null;
  };

  const {
    getRootProps, getInputProps, open, fileRejections,
  } = useDropzone({
    // Disable click and keydown behavior on dropzone
    noClick: true,
    noKeyboard: true,
    disabled,
    maxFiles,
    onDropAccepted,
    onDropRejected,
    onDrop,
    validator,
    minSize: minFileSize,
    maxSize: maxFileSize,
    accept: {
      'image/*': imageTypes,
      'application/*': documentTypes,
      'video/*': videoTypes,
    },
  });

  useEffect(() => {
    uploadedAmount.current = currentUploadedFiles;
  }, [currentUploadedFiles]);

  useEffect(() => {
    const isMultipleFiles = fileRejections.length > 1 ? 's' : '';

    fileRejections.forEach((rejectedFile: FileRejection) => {
      switch (rejectedFile.errors[0].code) {
        case ErrorCode.FileInvalidType:
          errorMsgs.push(
            `The specified file${isMultipleFiles} ${rejectedFile.file.name} could not be uploaded.
                Only files with the following extensions are allowed: ${acceptedFileExtensions}`,
          );
          break;
        case ErrorCode.FileTooLarge:
          errorMsgs.push(
            `The specified file${isMultipleFiles} ${rejectedFile.file.name} could not be uploaded. The file size is too
                large. Please reduce the file and try again.`,
          );
          break;
        case ErrorCode.FileTooSmall:
          errorMsgs.push(
            `The specified file${isMultipleFiles} ${rejectedFile.file.name} could not be uploaded. The file size is too
                small. Please reduce the file and try again.`,
          );
          break;
        case ErrorCode.TooManyFiles:
          errorMsgs.push(
            `The requested upload exceeds ${maxFiles} files. Please upload files in batches of ${maxFiles} or
                less. Multiple batches are allowed until the ${totalAllowedFiles} file maximum is reached.`,
          );
          break;
        case 'upload-limit-reached':
          errorMsgs.push(rejectedFile.errors[0].message);
          break;
        default:
          errorMsgs.push(rejectedFile.errors[0].message);
          break;
      }
    });

    const displayUniqueErrorMsgs = Array.from(new Set(errorMsgs)).map((msg, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <ErrorInfoText key={`${name}_${index}`}>
        <Grid container>
          <Grid item width="20px">
            <ErrorIconStyled />
          </Grid>
          <Grid item xs>
            {msg}
          </Grid>
        </Grid>
      </ErrorInfoText>
    ));

    setUniqueErrorMsgs(displayUniqueErrorMsgs);

    setHasError(displayUniqueErrorMsgs.length > 0);
  }, [fileRejections, requiredError]);

  return (
    <div>
      {label
            && (
            <IQLabelTooltip
              labelText={label}
              hasError={withLabelError && hasError}
              tooltipText={toolTiptext}
              theme={theme}
              paddingBottom={8}
              required={required}
            />
            )}
      {supportingText && (
      <SupportiveText
        hasError={withSupportingTextError && hasError}
      >
        {supportingText}
      </SupportiveText>
      )}
      <Container
        ref={fieldRef}
        required={required}
        hasError={hasError}
        disabled={disabled}
        size={size}
        {...getRootProps({ className: 'dropzone' })}
        onBlur={onBlur}
      >
        <input {...getInputProps()} />
        <IQButton
          startIcon={(
            <CloudUploadIconStyled
              hasError={hasError}
              disabled={disabled}
              required={false}
            />
                )}
          onClick={open}
          disabled={disabled}
          hasError={hasError}
          label={buttonLabel}
          sx={{ textTransform: 'none' }}
          ref={inputRef}
        />
        {
              !isMobile && (
                <p>
                  or drag and drop any
                  {' '}
                  {acceptedFileExtensions.replace('and', 'or')}
                  {' '}
                  file
                </p>
              )
            }
      </Container>
      { showAcceptedFileTypesText
                && (
                <AssistiveText>
                  {`Allowed File Types: ${acceptedFileExtensions}`}
                </AssistiveText>
                )}
      { showMaxNumberFilesText && (
      <AssistiveText>
        {`Max Number of Files: ${maxFiles}`}
      </AssistiveText>
      )}
      { showRecommendedImageText
          && (
          <AssistiveText>
            {`Recommended Image Size: ${recommendedImgText}`}
          </AssistiveText>
          )}
      { showMaxFileSizeText
          && (
          <AssistiveText>
            {`Max File Size: ${maxFileSize / 1000000} MB`}
          </AssistiveText>
          )}
      {uniqueErrorMsgs}
    </div>
  );
}
