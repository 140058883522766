import * as yup from 'yup';
import i18n from 'i18n/i18n';

const {t} = i18n;

const schema = {
    clientContactName: yup.string().required(t('pages.targetedDisplayLegacy.clientInformation.clientContactNameErrorMsg')),
    clientContactPhone: yup.string().required(t('pages.targetedDisplayLegacy.clientInformation.clientContactPhoneErrorMsg')),
    clientContactEmail: yup.string().email().required(t('pages.targetedDisplayLegacy.clientInformation.clientContactEmailErrorMsg')),
    bestNumberToReachYou: yup.string().required(t('pages.targetedDisplayLegacy.clientInformation.clientBestNumberToReachErrorMsg')),
    bestClientContactEmail: yup.string().email().notRequired(),
    weeklyReportEmail: yup.string().email().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};