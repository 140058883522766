/* eslint-disable max-len */
import i18n from 'i18n/i18n';

import {RJSFSchema} from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';

const {t} = i18n;

const validateRequiredFormField = (formData, fieldName, errors) => {
  if (formData?.[fieldName] === '') {
    errors[fieldName].addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
};
export const TrackingSocialUpgradeSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.xmoUpgradeSocial.tracking.title'),
  type: 'object',
  required: [
    'trackingRequirements',
  ],
  properties: {
    trackingRequirements: {
      type: 'string',
      title: t('pages.ausProduct.linkedin.trackingRequirementsLabel'),
      description: t('pages.ausProduct.xmoUpgradeSocial.tracking.trackingRequirementsDescription'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.linkedin.trackingRequirementsOpt1'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt2'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt3'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt4'),
      ],
    },
    utmStringCreationCorrectlyFormatted: {
      type: 'string',
      title: t('pages.ausProduct.youtube.utmStringCreationCorrectlyFormattedLabel'),
      default: data?.utmStringCreationCorrectlyFormatted || '',
      enum: [
        '',
        t('pages.ausProduct.youtube.yesRecommendedOpt'),
        t('pages.ausProduct.youtube.noOpt'),
      ],
    },
    isTacticalFlightedCampaign: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.tracking.isTacticalFlightedCampaignLabel'),
      description: t('pages.ausProduct.xmoUpgradeSocial.tracking.isTacticalFlightedCampaignDescription'),
      default: data?.isTacticalFlightedCampaign || '',
      enum: [
        '',
        t('pages.ausProduct.xmoUpgradeSocial.targeting.yesOpt'),
        t('pages.ausProduct.youtube.noOpt'),
      ],
    },
  },
  dependencies: {
    utmStringCreationCorrectlyFormatted: {
      oneOf: [
        {
          properties: {
            utmStringCreationCorrectlyFormatted: {
              enum: [t('pages.ausProduct.youtube.noOpt')],
            },
            utmStringCreationCorrectlyFormattedDescription: {
              type: 'string',
              title: t('pages.ausProduct.youtube.utmStringCreationCorrectlyFormattedDescription'),
            },
            source: {
              type: 'string',
              title: t('pages.ausProduct.youtube.sourceLabel'),
              default: data?.source || '',
            },
            medium: {
              type: 'string',
              title: t('pages.ausProduct.youtube.mediumLabel'),
              default: data?.medium || '',
            },
            campaignNameForUtmStringCreationCorrectlyFormatted: {
              type: 'string',
              title: t('pages.ausProduct.youtube.campaignNameForUtmStringCreationCorrectlyFormattedLabel'),
              default: data?.campaignNameForUtmStringCreationCorrectlyFormatted || '',
            },
          },
          required: [
            'source',
            'medium',
            'campaignNameForUtmStringCreationCorrectlyFormatted'
          ],
        },
      ],
    },
  }
});

const dropdownFieldKeys = [
  'trackingRequirements',
  'utmStringCreationCorrectlyFormatted',
  'googleAnalyticsReportingToolForCampaignSuccess',
  'isTacticalFlightedCampaign'
];

const inputFieldKeys = [
  'source',
  'medium',
  'campaignNameForUtmStringCreationCorrectlyFormatted',
];

const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});
const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.tracking.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

export const TrackingSocialUpgradeUISchema = {
  'ui:order': [
    'trackingRequirements',
    'utmStringCreationCorrectlyFormatted',
    'utmStringCreationCorrectlyFormattedDescription',
    'source',
    'medium',
    'campaignNameForUtmStringCreationCorrectlyFormatted',
    'isTacticalFlightedCampaign',
  ],
  utmStringCreationCorrectlyFormattedDescription: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '-15px',
    },
  },
  ...dropdownFields,
  ...inputFields
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TrackingSocialUpgradeCustomValidate = (formData: any, errors, uiSchema) => {
  validateRequiredFormField(formData, 'trackingRequirements', errors,);
  if (formData?.utmStringCreationCorrectlyFormatted === t('pages.ausProduct.youtube.noOpt')) {
    validateRequiredFormField(formData, 'source', errors,);
    validateRequiredFormField(formData, 'medium', errors,);
    validateRequiredFormField(formData, 'campaignNameForUtmStringCreationCorrectlyFormatted', errors,);
  }
  return errors;
};
