import {
  Box, Collapse, IconButton, Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCapacityHeaderByProductType,
  selectDeliveryDetails, selectFilter, selectThemes,
} from 'services/themesSlice';

export default function CoCapacityHeader() {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const deliveryDetails = useSelector(selectDeliveryDetails);
  const themes = useSelector(selectThemes);
  const filter = useSelector(selectFilter);

  useEffect(() => {
    if (themes.length > 0 && filter.value !== '' && !Array.isArray(filter.value)) {
      dispatch(getCapacityHeaderByProductType(themes[0].product.replace(' ', '%20')));
    }
  }, [themes, filter]);

  const formatDate = (value: Date | number) => {
    if (typeof value === 'number') {
      const tempValue = new Date(value);
      return `${tempValue.toLocaleString('default', { month: 'short' })} ${tempValue.getDate()}`;
    }
    return `${value.toLocaleString('default', { month: 'short' })} ${value.getDate()}`;
  };

  const handleClick = () => setExpanded(!expanded);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid blue',
      borderBottomLeftRadius: '30px',
      borderTopLeftRadius: '30px',
      padding: '18px 10px 18px 25px',
      width: 'fit-content',
      backgroundColor: 'blue',
      color: 'white',
      position: 'absolute',
      right: 0,
      cursor: 'pointer',
    }}
      onClick={handleClick}
    >
      <IconButton sx={{ padding: 0, color: 'white' }}>
        {expanded ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
      <IconButton sx={{ padding: 0, color: 'white', marginRight: 1 }}>
        <AccessTimeIcon />
      </IconButton>
      <Typography marginRight={3}>Estimated Delivery</Typography>
      <Typography fontWeight="bold" marginRight={8}>
        {(filter.value as any !== '' && !Array.isArray(filter.value))
          ? formatDate(deliveryDetails.estimatedDelivery)
          : 'Select Product Type'}
      </Typography>

      <Collapse in={expanded}
        timeout={1000}
        orientation="horizontal"
        unmountOnExit
        sx={{ '.MuiCollapse-wrapperInner': { display: 'flex' }, marginRight: '50px' }}
      >
        <Typography marginRight={3} whiteSpace="nowrap">Start Time</Typography>
        <Typography fontWeight="bold" marginRight={8} whiteSpace="nowrap">
          {(filter.value as any !== '' && !Array.isArray(filter.value))
            ? formatDate(deliveryDetails.startTime)
            : ''}
        </Typography>
        <Typography marginRight={3} whiteSpace="nowrap">Development Time</Typography>
        <Typography fontWeight="bold" whiteSpace="nowrap">
          {(filter.value as any !== '' && !Array.isArray(filter.value))
            ? `${deliveryDetails.developmentTime.toFixed(1)} weeks`
            : ''}
        </Typography>
      </Collapse>
    </Box>
  );
}
