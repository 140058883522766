import * as yup from 'yup';
import i18n from 'i18n/i18n';
import {urlValidationRegex} from "shared/constants";

const { t } = i18n;

const schema = {
  businessName: yup.string().required(t('pages.xmo.solutionsStrategy.general.businessNameErrorMessage')),
  businessWebsiteUrl: yup.string()
  .required(t('pages.xmo.solutionsStrategy.general.businessWebsiteUrlErrorMessage'))
  .matches(urlValidationRegex, t('pages.socialAds.socialAdsWebClicksSection.inputError.landingPageRequired')),
  advertiserEmail: yup.string()
    .required(t('pages.xmo.solutionsStrategy.general.advertiserEmailErrorMessage'))
    .email(t('pages.xmo.solutionsStrategy.general.invalidEmailFormat')),
  csmName: yup.string().required(t('pages.xmo.solutionsStrategy.general.csmNameErrorMessage')),
  csmEmail: yup.string()
    .required(t('pages.xmo.solutionsStrategy.general.csmEmailErrorMessage'))
    .email(t('pages.xmo.solutionsStrategy.general.invalidEmailFormat')),
  clientEmail: yup.string()
    .required('Client Email is required')
    .email(t('pages.xmo.solutionsStrategy.general.invalidEmailFormat')),
  timedEventPromotionOrSale: yup.string()
    .required(t('pages.xmo.solutionsStrategy.general.timedEventPromotionOrSaleErrorMessage')),
  startDate: yup.string().required(t('pages.xmo.solutionsStrategy.general.startDateErrorMessage')),
  endDate: yup.string().required(t('pages.xmo.solutionsStrategy.general.endDateErrorMessage')),
  detailsOfEvent: yup.string().required(t('pages.xmo.solutionsStrategy.general.detailsOfEventErrorMessage')),
  inventoryTier: yup.string(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
