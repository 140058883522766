import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getThemeFeatureById, selectCurrentThemeFeature, updateThemeFeature,
} from 'services/adminSlice';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import FeatureForm from './FeatureForm';

export default function EditFeature() {
  const currentFeature = useSelector(selectCurrentThemeFeature);
  const { featureId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getThemeFeatureById(parseInt(featureId, 10)));
  }, [featureId]);

  const updateFeature = async (data) => {
    const feature = {
      id: parseInt(featureId, 10),
      title: data.title,
      description: data.description,
      iconFileName: data.iconFileName,
      tiers: data.featureTiers,
    };
    await dispatch(await updateThemeFeature(feature));
    navigate('/admin/themeFeatures');
  };

  return (
    <>
      <Typography variant="h2">Edit Theme Feature</Typography>
      <FeatureForm onSubmit={updateFeature} feature={currentFeature} />
    </>
  );
}
