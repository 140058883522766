/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { StatusType } from 'models/StatusType';
import UserType from 'models/UserType';
import AxiosAPI from './common/axios';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { RootState } from './redux/store';
import { createAction } from '@reduxjs/toolkit';

export interface UserAccess {
  isAdmin: boolean,
  isManager: boolean,
  canDoFulfillment: boolean,
  canDoCarbon: boolean,
  isSales: boolean,
}

export interface User {
  id: number,
  email: string,
  userRoles: string,
}

export interface UserRole {
  userRoles: string,
}

export interface UserState {
  userData: StatusType<UserType>,
  access: UserAccess,
}

const initialState: UserState = {
  userData: {
    status: 'loading',
    message: '',
    content: {
      cobaltRole: 'sales',
      email: '',
      id: '',
      firstName: '',
      lastName: '',
      userRoles: '',
    },
  },
  access: {
    isAdmin: false,
    isManager: false,
    canDoFulfillment: false,
    canDoCarbon: false,
    isSales: true,
  },
};

export const getUsers = createAsyncThunkCatchErrors(
  'userData/get',
  async (num: string) => {
    const response = await AxiosAPI.get(`/users?size=${num}`);
    return response.data;
  },
);
export const getChickletUsers = createAsyncThunkCatchErrors(
  'userData/get',
  async (num: string) => {
    const response = await AxiosAPI.get(`/users/chicklet-users?size=${num}`);
    return response.data;
  },
);

export const getUserInfo = createAsyncThunkCatchErrors(
  'userInfo/get',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (user: string) => {
    const response = await AxiosAPI.get(`/users/${user}`);
    return response.data;
  },
);

export const updateUserRoles = createAsyncThunkCatchErrors(
  'userInfo/put',
  async (data) => {
    const response = await AxiosAPI.put(`/users/${data.id}/update-roles?userRoles=${data.roleQuery}`);
    return response;
  },
);

export const setUserRole = createAction<string>('user/setUserRole');

export const selectUserRoles = (state: RootState) => state.user.userData.content.userRoles;

export const getUserRoles = () => {
  const roles = useSelector(selectUserRoles);
  return roles;
};

const userSlice = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.userData.status = 'loading';
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.userData.status = 'idle';
        state.userData.content = action.payload;
        const { cobaltRole, userRolesRecord } = state.userData.content;
        const role = cobaltRole;
        const allRoles = userRolesRecord ? userRolesRecord.split(',') : [];
        allRoles.push(role);
        if (allRoles.includes('ROLE_SALES')
          || allRoles.includes('partner')
          || allRoles.includes('sales')
          || allRoles.includes('ROLE_PARTNER_CEF')) {
          state.access.isSales = true;
        }
        if (allRoles.includes('ROLE_MANAGER')) {
          state.access.isManager = true;
        }
        if (allRoles.includes('ROLE_FULFILLMENT')) {
          state.access.canDoFulfillment = true;
        }
        if (allRoles.includes('ROLE_CARBON')) {
          state.access.canDoCarbon = true;
        }
        if (allRoles.includes('ROLE_ADMIN') || allRoles.includes('admin')) {
          state.access.isAdmin = true;
        }
        if (state.access.isAdmin) {
          state.access.canDoCarbon = true;
          state.access.canDoFulfillment = true;
          state.access.isManager = true;
        }
      })
      .addCase(setUserRole, (state, action) => {
        const selectedRole: string = action.payload;
        state.userData.content.cobaltRole = selectedRole;
        state.access.isAdmin = false;
        state.access.isManager = false;
        state.access.canDoFulfillment = false;
        state.access.canDoCarbon = false;
        state.access.isSales = false;

        if(selectedRole === "manager"){
          state.access.isManager = true;
        }
        if(selectedRole === "fulfillment"){
          state.access.canDoFulfillment = true;
        }
        if(selectedRole === "carbon"){
          state.access.canDoCarbon = true;
        }
        if(selectedRole === "sales"){
          state.access.isSales = true;
        }

        if(selectedRole === "admin"){
          state.access.isAdmin = true;
          state.access.canDoCarbon = true;
          state.access.canDoFulfillment = true;
          state.access.isManager = true;
        }
      })
  },
);

export const selectUserRole = (state: RootState) => state.user.userData.content?.cobaltRole;
export const selectUserEmail = (state: RootState) => state.user.userData.content?.email;
export const selectUserDataStatus = (state: RootState) => state.user.userData.status;
export const selectUserId = (state: RootState) => state.user.userData.content?.userId;
export const selectUserAccess = (state: RootState) => state.user.access;

export const selectAllUsers = (state: RootState) => state.user.userData.content;

export default userSlice;
