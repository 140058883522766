import { ComponentProps } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Box, Grid, Typography,
} from '@mui/material';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import PiOWebsiteType from 'models/PiQWebsiteType';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { t } from 'i18next';
import { inputStyles } from 'pages/seo/details/DetailsPageForm';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import {
  ProductTypes, selectFilesUploaded, setFiles,
} from 'services/fileUploaderSlice';
import CoFileUploader from 'components/fileUploader/CoFileUploader';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import useUpdateAssets from 'hooks/useUpdateAssets';
import schema from './PiQAssets.schema';

interface Props {
  product: PiOWebsiteType;
  onSubmit: (data :PiQAssetsProps) => void;
  onBack: () => void;
}

export type PiQAssetsProps = {
  logo: string;
};

export default function PiQAssetsForm(props: Props) {
  const { product } = props;
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const files = useSelector(selectFilesUploaded);

  const methods = useForm<PiQAssetsProps>({
    mode: 'all',
    defaultValues: {
      logo: product?.logo || '',
    },
  });

  const {
    watch,
    handleSubmit,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful, errors,
    },
  } = methods;

  const uploaderType = ProductTypes.PIQ_ASSET;
  const megabyte = 1_000_000;
  const offeringType = useSelector(selectActiveOfferingType);
  const productType :ProductTypes = ProductTypes.PIQ_ASSET;

  const fileUploaderOptions:Pick<ComponentProps<typeof CoFileUploader>,
  'fileUploaderOptions'>['fileUploaderOptions'] = {
    name: 'uploadedFiles',
    uploaderType,
    showRecommendedImageText: true,
    recommendedImgText: 'Max Height 150 pixels',
    showAcceptedFileTypesText: true,
    maxFileSize: 256 * megabyte,
    supportingText: '',
    toolTiptext: '',
    label: null,
    imageTypes: ['.png', '.jpeg', '.jpg', '.gif', '.CR2', '.DNG'],
    videoTypes: [],
    documentTypes: [],
    size: 'md',
    directory: uploaderType,
    showComments: true,
    showCheckboxes: true,
  };

  const { triggerAllUpdates } = useUpdateAssets(
    watch,
    fileUploaderOptions.name,
  );

  const onSubmit = async (data: PiQAssetsProps) => {
    await triggerAllUpdates();
    await props.onSubmit(data);
    dispatch(setFiles([]));
  };

  const hasUploads = files?.content?.filter(file => file.uploaderType === ProductTypes.PIQ_ASSET).length > 0;

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={props.onBack}
      header={t('pages.piq.assets.header')}
      onContinue={handleSubmit(onSubmit)}
      skipAndContinue={!hasUploads}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="assetLogoForm"
            component="form"
            sx={{ display: 'flex' }}
          >
            <Grid container xs={12} sx={{ ...inputStyles }}>
              <Grid item mb={3} xs={9}>
                <Grid item mb={1}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}
                    color={errors.logo ? 'error.main' : 'common.black'}
                  >
                    Upload Logo
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={9}>
                {(productType && websiteId) && (
                <CoFileUploader
                  schema={schema}
                  productType={productType}
                  offeringType={offeringType as WebsiteOfferingTypes}
                  showFileUploader
                  productId={websiteId?.toString()}
                  fileUploaderOptions={fileUploaderOptions}
                  containerStyles={{
                    '& .dropzone': {
                      padding: '0 10px',
                      justifyContent: 'flex-start',
                      '& > p': {
                        marginLeft: '20px',
                      },
                    },
                  }}
                />
                ) }
              </Grid>
              <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
              />
            </Grid>
          </Box>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
