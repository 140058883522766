import {
  IQButtonRadioContainer, IQFormLayout, IQFormTextArea, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { Grid } from '@mui/material';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import YesNoRadio from 'components/yesNo/YesNoRadio';
import { useEffect, useMemo } from 'react';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import {
  getCustomLandingPage,
  selectDevelopmentCustomLadingPage,
  setDevelopment,
} from 'services/customLandingPageSlice';
import { useDispatch, useSelector } from 'react-redux';
import schema from './Development.schema';

export default function CustomLandingPageDevelopment() {
  const { t } = useTranslation();
  const { orderItemId } = useParams();
  const navigate = useNavigationHandler();
  const dispatch = useDispatch();
  const development = useSelector(selectDevelopmentCustomLadingPage);
  const history: any = useLocation();
  const isRedirectedFromOverview = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      copyingServicesNeeded: '',
      specialFunctionalityNeeded: '',
      specialFunctionality: '',
    },
  });

  const {
    getValues, watch, reset, formState: { isValid },
  } = methods;

  useEffect(() => {
    if (orderItemId) {
      dispatch(getCustomLandingPage(orderItemId));
    }
  }, [orderItemId]);

  useEffect(() => {
    if (development) {
      reset({
        copyingServicesNeeded: development.copyRequirement,
        specialFunctionalityNeeded: development.specialFunctionality !== 'no'
          ? 'yes'
          : development.specialFunctionality,
        specialFunctionality: development.specialFunctionality !== 'no'
          ? development.specialFunctionality
          : '',
      });
    }
  }, [development]);

  const copyingServicesOptions = [
    {
      value: 'yes',
      label: 'Yes',
      field: 'copyingServicesNeeded',
    },
    {
      value: 'no',
      label: t('pages.landing.custom.development.copyingServicesNoOption'),
      field: 'copyingServicesNeeded',
    },
  ];

  const specialFunctionalityNeeded = useMemo(
    () => getValues('specialFunctionalityNeeded') === 'yes',
    [watch('specialFunctionalityNeeded')],
  );

  const handleContinue = () => {
    const specialFuncData = {
      specialFunctionalityNeeded: getValues('specialFunctionalityNeeded'),
      specialFunctionality: getValues('specialFunctionality'),
    };
    const copyingServiceNeededData = {
      copyRequirement: getValues('copyingServicesNeeded'),
    };

    dispatch(setDevelopment({
      orderItemId, specialFuncData, copyingServiceNeededData,
    }));
    navigate.to(isRedirectedFromOverview ? Urls.Overview : Urls.LandingCustomAdditionalInfo);
  };

  const onBack = () => {
    navigate.to(Urls.LandingCustomDesign);
  };

  return (
    <DefaultPageLayout
      onContinue={handleContinue}
      disableContinue={!isValid}
      onBack={onBack}
      header={t('pages.landing.custom.development.title')}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container>
            <Grid item xs={6} marginBottom={3}>
              <IQButtonRadioContainer
                singleColumn
                name="copyingServicesNeeded"
                optionsList={copyingServicesOptions}
                label={t('pages.landing.custom.development.copyingServicesNeeded')}
                allowRadioDeselect
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6} marginBottom={3}>
              <IQFormLayout
                labelText={t('pages.landing.custom.development.specialFunctionalityNeeded')}
                fontLabelWeight="bold"
                tooltipText={t('pages.landing.custom.development.specialFunctionalityTooltip')}
                tooltipPaddingBottom={10}
                showError={false}
              >
                <YesNoRadio
                  label=""
                  name="specialFunctionalityNeeded"
                />
              </IQFormLayout>
            </Grid>
            <Grid item xs={6} />
            {specialFunctionalityNeeded && (
              <Grid item xs={6} marginBottom={3}>
                <IQFormTextArea
                  id="specialFunctionality"
                  name="specialFunctionality"
                  labelText={t('pages.landing.custom.development.specialFunctionality')}
                  fontLabelWeight="bold"
                  rowCount={3}
                  required
                  fullWidth
                  showError
                />
              </Grid>
            )}
          </Grid>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
