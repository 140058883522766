import * as yup from 'yup';

const schema = {
  options: yup.string().required(),
  numberOfPages: yup.number()
    .typeError('Please enter a numerical value')
    .min(0, 'Number of pages cannot be negative')
    .nullable()
    .transform((_, val) => (val !== '' ? Number(val) : null)),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
