export const AddonsObjList = [
  {
    productName: 'VideoBackground',
    productType: 'Addon',
    themeName: '',
    estimateDelivery: '',
    label: 'Video Background',
    imageUrl: 'addons-video.jpg',
    thumbnailUrl: 'addons-video-thumbnail.jpg',
  },
  {
    productName: 'EmailHosting',
    productType: 'Addon',
    themeName: '',
    estimateDelivery: '',
    label: 'Email Hosting',
    imageUrl: 'addons-email.jpg',
    thumbnailUrl: 'addons-email-thumbnail.jpg',
  },
  {
    productName: 'ExpandableMenu',
    productType: 'Addon',
    themeName: '',
    estimateDelivery: '',
    label: 'Expandable Menu',
    imageUrl: 'addons-menu.jpg',
    thumbnailUrl: 'addons-menu-thumbnail.jpg',
  },
  {
    productName: 'OnlineBooking',
    productType: 'Addon',
    themeName: '',
    estimateDelivery: '',
    label: 'Online Booking',
    imageUrl: 'addons-booking.jpg',
    thumbnailUrl: 'addons-booking-thumbnail.jpg',
  },
  {
    productName: 'PaymentForms',
    productType: 'Addon',
    themeName: '',
    estimateDelivery: '',
    label: 'Payment Forms',
    imageUrl: 'addons-payment.jpg',
    thumbnailUrl: 'addons-payment-thumbnail.jpg',
  },
  {
    productName: 'HipaaForm',
    productType: 'Addon',
    themeName: '',
    estimateDelivery: '',
    label: 'HIPAA Form',
    imageUrl: 'addons-hipaa.jpg',
    thumbnailUrl: 'addons-hipaa-thumbnail.jpg',
  },
  {
    productName: 'Popup',
    productType: 'Addon',
    themeName: '',
    estimateDelivery: '',
    label: 'Pop-up',
    imageUrl: 'addons-popup.jpg',
    thumbnailUrl: 'addons-popup-thumbnail.jpg',
  },
];

export const TypesOfSale = [
  {
    label: 'New Sale',
    value: 'NEW_SALE',
  },
  {
    label: 'Budget Reallocation',
    value: 'BUDGET_REALLOCATION',
  },
  {
    label: 'Rebuild of Previous Campaign',
    value: 'REBUILD',
  },
  {
    label: 'Modification',
    value: 'MODIFICATION',
  },
];

export const TypeOfSaleConstants = {
  NEW_SALE: 'NEW_SALE',
  BUDGET_REALLOCATION: 'BUDGET_REALLOCATION',
  REBUILD: 'REBUILD',
  MODIFICATION: 'MODIFICATION',
};
