import { Typography, Box, Link } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { Urls } from 'navigation/Urls';
import { setFiles } from 'services/fileUploaderSlice';
import { useDispatch } from 'react-redux';
import { headingStyle } from '../Business/BusinessSection';

export const customWebsiteFeaturesSection = (
  websiteData,
  websiteItems,
  additionalFeatureAttachments,
  product,
  navigate,
  isEditable,
) => {
  const dispatch = useDispatch();

  return (
    <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0' }}>
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Features</Typography>

      <Box>
        {/* CRM Integration */}
        {websiteItems?.filter(item => item?.id?.customWebsiteItems?.name === 'CRM Integration')?.length > 0 && (
          <>
            <Box sx={{ display: 'flex', margin: '10px 0' }}>
              {editButton(isEditable, () =>
                navigate.to(
                  `${Urls.CustomWebsiteFeatureList}/${Urls.Content}`,
                  product.id,
                ))}
              <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>CRM Integration</Typography>
            </Box>
            <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
              <Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">CRM Product Name</Typography>
                  <Typography>{websiteData?.crmIntegrationName}</Typography>
                </Box>
              </Box>
              <Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">CRM Integration</Typography>
                  <Typography>{websiteData?.crmIntegrationInfo}</Typography>
                </Box>
              </Box>
            </GridItem>
          </>
        )}

        {/* Login Portal */}
        {websiteItems?.filter(item => item?.id?.customWebsiteItems?.name === 'Login Portal')?.length > 0 && (
          <>
            <Box sx={{ display: 'flex', margin: '10px 0' }}>
              {editButton(isEditable, () =>
                navigate.to(
                  `${Urls.CustomWebsiteFeatureList}/${Urls.LoginPortal}`,
                  product.id,
                ))}
              <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Login Portal</Typography>
            </Box>
            <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
              <Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Login User Types</Typography>
                  {websiteData?.loginPortalUserType?.split(',')?.map(type => <Typography>{type}</Typography>)}
                </Box>
              </Box>
              <Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Features or Functionality</Typography>
                  <Typography>{websiteData?.loginPortalInfo}</Typography>
                </Box>
              </Box>
            </GridItem>
          </>
        )}

        {/* E-Commerce */}
        {websiteItems?.filter(item => item?.id?.customWebsiteItems?.name === 'E-Commerce')?.length > 0 && (
          <>
            <Box sx={{ display: 'flex', margin: '10px 0' }}>
              {editButton(isEditable, () =>
                navigate.to(
                  `${Urls.CustomWebsiteFeatureList}/${Urls.Ecommerce}`,
                  product.id,
                ))}
              <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>E-Commerce</Typography>
            </Box>
            <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
              <Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Unique SKU Products</Typography>
                  <Typography>{websiteData?.eCommerceProductsAmount}</Typography>
                </Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Product Types Sold</Typography>
                  <Typography>{websiteData?.eCommerceProducts}</Typography>
                </Box>
              </Box>
              <Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Products have variations/options?</Typography>
                  <Typography>{websiteData?.eCommerceVariations ? 'Yes' : 'No'}</Typography>
                </Box>
              </Box>
            </GridItem>
          </>
        )}

        {/* Scheduling */}
        {/* eslint-disable-next-line max-len */}
        {websiteItems?.filter(item => item?.id?.customWebsiteItems?.name === 'Online Appointment Scheduler')?.length > 0 && (
          <>
            <Box sx={{ display: 'flex', margin: '10px 0' }}>
              {editButton(isEditable, () =>
                navigate.to(
                  `${Urls.CustomWebsiteFeatureList}/${Urls.Scheduling}`,
                  product.id,
                ))}
              <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Scheduling</Typography>
            </Box>
            <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
              <Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Required Scheduling Fields</Typography>
                  <Typography>{websiteData?.onlineAppointmentSchedulerInfo}</Typography>
                </Box>
              </Box>
              <Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Additional Info</Typography>
                  {displayNotRequiredField('additional information', websiteData?.references)}
                </Box>
              </Box>
            </GridItem>
          </>
        )}

        {/* Additional */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, async () => {
            await dispatch(setFiles([]));
            navigate.to(
              `${Urls.CustomWebsiteFeatureList}/${Urls.Additional}`,
              product.id,
            );
          })}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Additional</Typography>
        </Box>
        <GridItem sizes={[6, 6]}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Additional Features Info</Typography>
              {displayNotRequiredField('additional information', websiteData?.additionalFeatures)}
            </Box>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Additional Features Attachments</Typography>
              {additionalFeatureAttachments?.length
                ? additionalFeatureAttachments.map((asset, index) => (
                  <Link href={asset.url} key={asset.url} target="_blank" rel="noopener noreferrer">
                    <Typography>{`Asset ${index + 1}`}</Typography>
                  </Link>
                )) : <Typography fontWeight="bold">No Assets</Typography>}
            </Box>
          </Box>
        </GridItem>

      </Box>
    </GridItem>
  );
};
