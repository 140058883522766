/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-redeclare, @typescript-eslint/no-redeclare */
import React, {
  FC, ReactElement, useContext, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { AnySchema } from 'yup';

import { FormHelperText, Grid, SxProps } from '@mui/material';
import { IQFileUploader, ValidationProvider } from '@gannettdigital/shared-react-components';
import IQStockList, {
  IQFileStockProps,
} from '@gannettdigital/shared-react-components/dist/uploader/IQStockList/IQStockList';

import { deleteFile, deleteGettyAsset, ProductTypes } from 'services/fileUploaderSlice';

import { WebsiteOfferingTypes } from 'services/websitesSlice';
import GettyImages from 'pages/websites/design/existing-assets/GettyImages';
import CoModalConfirm from '../modals/CoModalConfirm';
import { AllUploaders, FileUploader, GettyUploader } from './FileUploaders';
import { FileUploaderContext } from './FileUploaderContext';

import useUpdateAssets from '../../hooks/useUpdateAssets';

type UploadProps = {
  uploaderType: string;
  directory: string,
  showCheckboxes?: boolean,
  showComments?: boolean
  showRadioButtons?: boolean
  radioGroupName?: string
  radioGroupDefaultValue?: string
};

type IQFileUploaderProps = Omit<React.ComponentProps<typeof IQFileUploader>, 'onSuccess' | 'currentUploadedFiles'> &
UploadProps;
type GettyImagesProps = React.ComponentProps<typeof GettyImages>
& Pick<React.ComponentProps<typeof IQStockList>, 'name' | 'showComments' | 'showCheckboxes' | 'checkboxList'>;

type Schema<CustomT = {}> = {
  yupValidations: { [key: string]: AnySchema };
  customValidations: { [key: string]: CustomT };
};
interface Props {
  productId: string;
  productType: ProductTypes;
  offeringType: WebsiteOfferingTypes;
  schema: Schema;
  containerStyles?: SxProps;
}

export interface OnlyFileUploader extends Props {
  showFileUploader: boolean;
  fileUploaderOptions: IQFileUploaderProps;
}

export interface OnlyGetty extends Props {
  showGetty: boolean;
  gettyImagesUploaderOptions: GettyImagesProps;
  orderId: string;
  orderItemId: string;
}

export interface AllUploaders extends Props {
  showFileUploader: boolean;
  fileUploaderOptions: IQFileUploaderProps;
  showGetty: boolean;
  gettyImagesUploaderOptions: GettyImagesProps;
  orderId: string;
  orderItemId?: string;
}

type ModalOptions = {
  isModalOpen: boolean;
  modalTitle: string;
  modalDescription: string;
  modalConfirmText: string;
  handleModalClose: () => any;
  handleModalConfirm: (...args: any) => void;
};

type WrapperProps = {
  schema: Schema;
  containerStyles: SxProps & { xs?: number };
  modal: ModalOptions;
};

const defaultModalProps: Pick<ModalOptions, 'modalConfirmText' | 'modalDescription' | 'modalTitle'> = {
  modalConfirmText: 'Delete',
  modalDescription: 'Are you sure you want to delete this asset?',
  modalTitle: 'Delete Asset',
};

const Wrapper: FC<WrapperProps> = ({
  schema, containerStyles, modal, children,
}) => {
  const {
    handleModalConfirm, handleModalClose, isModalOpen, modalConfirmText, modalDescription, modalTitle,
  } = modal;

  const { xs } = containerStyles;

  const { watch } = useFormContext();
  const { fileUploaderName, gettyUploaderName } = useContext(FileUploaderContext);
  const { updateAllAssetsStore } = useUpdateAssets(
    watch,
    fileUploaderName,
    gettyUploaderName,
  );

  const onUploaderDragEnter = (e :any) => {
    if (e.target.classList.contains('dropzone')) {
      updateAllAssetsStore();
    }
  };

  const onUploaderClick = (e :any) => {
    if (e.target.tagName === 'BUTTON' || e.target.classList.contains('dropzone')) {
      updateAllAssetsStore();
    }
  };

  return (
    <ValidationProvider schema={schema}>
      <Grid onDragEnter={onUploaderDragEnter} onClick={onUploaderClick}
        item
        xs={xs || 'auto'}
        sx={{ ...containerStyles, textarea: { maxWidth: '100%' } }}
      >
        {children}
      </Grid>
      <CoModalConfirm
        open={isModalOpen}
        title={modalTitle}
        description={modalDescription}
        confirmText={modalConfirmText}
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
      />
    </ValidationProvider>
  );
};

function CoFileUploader({
  fileUploaderOptions,
  offeringType,
  productId,
  containerStyles,
  schema,
  showFileUploader,
}: OnlyFileUploader): ReactElement;

function CoFileUploader({
  gettyImagesUploaderOptions,
  offeringType,
  productId,
  containerStyles,
  schema,
  showGetty,
  orderId,
  orderItemId,
}: OnlyGetty): ReactElement;

function CoFileUploader({
  fileUploaderOptions,
  offeringType,
  gettyImagesUploaderOptions,
  productId,
  containerStyles,
  schema,
  showFileUploader,
  showGetty,
  orderId,
  orderItemId,
}: AllUploaders): ReactElement;

function CoFileUploader(props: AllUploaders | OnlyFileUploader | OnlyGetty): ReactElement {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalDeleteCb, setModalDeleteCb] = useState({ fn: () => {} });

  const dispatch = useDispatch();
  const { productId, schema, containerStyles } = props;

  const onFileDelete = (file: File | number) => {
    dispatch(deleteFile({ websiteId: productId, file }));
    setIsModalOpen(false);
  };

  const showDeleteModal = (deleteCb: (...args: any) => any) => (file: any) => {
    setModalDeleteCb({ fn: () => deleteCb(file) });
    setIsModalOpen(true);
  };

  const onGettyDelete = (file: IQFileStockProps) => {
    dispatch(deleteGettyAsset({ data: file }));
    setIsModalOpen(false);
  };

  if ('showFileUploader' in props && !('showGetty' in props)) {
    const { name } = props.fileUploaderOptions;

    const contextValue = useMemo(
      () =>
        ({
          fileUploaderName: name,
          gettyUploaderName: null,
        }),
      [name],
    );

    return (
      <FileUploaderContext.Provider
        value={contextValue}
      >
        <Wrapper
          modal={{
            ...defaultModalProps,
            handleModalClose: () => setIsModalOpen(false),
            handleModalConfirm: modalDeleteCb.fn,
            isModalOpen,
          }}
          containerStyles={containerStyles}
          schema={schema}
        >
          <FileUploader {...props} onDelete={showDeleteModal(onFileDelete)} />
        </Wrapper>
      </FileUploaderContext.Provider>
    );
  }
  if ('showGetty' in props && !('showFileUploader' in props)) {
    const { name } = props.gettyImagesUploaderOptions;

    const contextValue = useMemo(
      () =>
        ({
          fileUploaderName: null,
          gettyUploaderName: name,
        }),
      [name],
    );

    return (
      <FileUploaderContext.Provider
        value={contextValue}
      >
        <Wrapper
          modal={{
            ...defaultModalProps,
            handleModalClose: () => setIsModalOpen(false),
            handleModalConfirm: modalDeleteCb.fn,
            isModalOpen,
          }}
          containerStyles={containerStyles}
          schema={schema}
        >
          <GettyUploader {...props} onDelete={showDeleteModal(onGettyDelete)} />
        </Wrapper>
      </FileUploaderContext.Provider>
    );
  }

  const { name } = props.fileUploaderOptions;
  const { name: gettyName } = props.gettyImagesUploaderOptions;

  const contextValue = useMemo(
    () =>
      ({
        fileUploaderName: name,
        gettyUploaderName: gettyName,
      }),
    [name, gettyName],
  );

  return (
    <FileUploaderContext.Provider
      value={contextValue}
    >
      <Wrapper
        modal={{
          ...defaultModalProps,
          handleModalClose: () => setIsModalOpen(false),
          handleModalConfirm: modalDeleteCb.fn,
          isModalOpen,
        }}
        containerStyles={containerStyles}
        schema={schema}
      >
        <FormHelperText sx={{ marginBottom: '10px' }}>
          The total file uploads for both Stock Asset selections and
          Existing Asset uploads should not exceed 40 files.
        </FormHelperText>
        <AllUploaders
          {...props}
          gettyDescription={props.gettyImagesUploaderOptions.gettyDescription}
          onFileDelete={showDeleteModal(onFileDelete)}
          onGettyDelete={showDeleteModal(onGettyDelete)}
        />
      </Wrapper>
    </FileUploaderContext.Provider>
  );
}

export default CoFileUploader;
