import * as yup from 'yup';

const siteMessage = 'Please enter a correctly formatted site-name '
  + '(i.e. my-client-site). Omit https://, http://, dev-, or leading spaces when entering a site-name.'
  + ' Site name cannot end in pantheon.io.';
const bannedEndingPatternsMessage = 'Please enter a correctly formatted site-name -- cannot end in '
  + '.pantheon.io, .dev, .test, .live, or .com.';
const leadingWhiteSpaceMessage = 'Site-name cannot start with whitespace.';
const bannedCaseMessage = 'Site-name must be lowercase only.';

const bannedStartingPatterns = /^(?!(http:\/\/|https:\/\/|dev-|live-))/;
const bannedStartingPatternsWithWhitespace = /^(?!\s+(http:\/\/|https:\/\/|dev-|live-))/;
const banneEndingPatterns = /^(?!.*(\.dev$|\.live$|\.test$|\.pantheon.io$|\.pantheonsite.io$))/;
const leadingWhitespace = /^(?!\s+)/;
const upperCase = /^[a-z-0-9]+$/;

const schema = {
  siteName: yup.string().required(siteMessage)
    .matches(bannedStartingPatterns, siteMessage)
    .matches(leadingWhitespace, leadingWhiteSpaceMessage)
    .matches(bannedStartingPatternsWithWhitespace, siteMessage)
    .matches(banneEndingPatterns, bannedEndingPatternsMessage)
    .matches(upperCase, bannedCaseMessage),
  unfreeze: yup.string(),
  upgrade: yup.string(),
  downgrade: yup.string(),
  environment: yup.string(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
