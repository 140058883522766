import * as yup from 'yup';

const schema = {
  paymentFormRequirements: yup.string().required('This is a required field'),
  paymentsGateway: yup.string().required('Please select'),
  acknowledge: yup.boolean()
    .required('Select Acknowledge')
    .oneOf([true], 'Please check acknowledgement to continue.'),
  additionalNotesInstructions: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
