import * as yup from 'yup';
import i18n from 'i18n/i18n';

const {t} = i18n;

const schema = {
  localAreaCodePreference1: yup.string().notRequired(),
  localAreaCodePreference2: yup.string().notRequired(),
  doesPrimaryBusinessNumberNeedToForward: yup.string().required(),
  alternativeForwardingNumber: yup.string().notRequired(),
  doesWebsiteHaveCaptchaInstalled: yup.string().required(t('pages.targetedDisplayLegacy.proxyProvisioning.doesWebsiteHaveCaptchaInstalledErrorMsg')),
  doesClientHaveAnotherAccount: yup.string().required(t('pages.targetedDisplayLegacy.proxyProvisioning.doesClientHaveAnotherAccountErrorMsg')),
  doNotProxyWebsite: yup.string().notRequired(),
  webEvents: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};