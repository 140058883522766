import * as yup from 'yup';
import {t} from "i18next";

const schema = {
  disableThePublisher: yup.string().required(),
  advertiserLegalBusinessName: yup.string().required(t('pages.xmo.snapchat.advertiserLegalBusinessNameError')),
  campaignObjective: yup.string().required(),
  geoTargetingType: yup.string().required(),
  geoTargetingDetails: yup.string().required(t('pages.xmo.snapchat.geoTargetingDetailsError')),
  isThisAHousingCreditOrEmploymentCampaign: yup.string().required(),
  isThisAPoliticalCampaign: yup.string().required(),
  callToActionButton: yup.string().required(t('pages.xmo.snapchat.callToActionButtonError')),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
