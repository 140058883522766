import { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { useParams } from 'react-router';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { useAppDispatch } from 'services/redux/store';
import { getOnlineBooking } from 'services/addons/addonsSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useSelector } from 'react-redux';
import { selectOrderItems } from 'services/ordersSlice';
import { renderStatusPill } from 'pages/orders/OrdersTable';
import { headingStyle } from '../Business/BusinessSection';
import { ActionItems } from '../common/ActionItems';
import { findAddonId } from './MasterAddonSection';

const mapPaymentGateway = {
  authorizeNet: 'Authorize.net',
  paypalPaymentsPro: 'PayPal Payments Pro',
  stripe: 'Stripe',
};

export default function OnlineBookingSection({
  product, isEditable, showActionItems, openBusinessAndSalesAgent,
}: any) {
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const { orderId } = useParams();
  const orderItems = useSelector(selectOrderItems);
  const [onlineBookingData, setOnlineBookingData] = useState({} as any);
  const [addonStatus, setAddonStatus] = useState('');
  const addonName = 'onlineBooking';

  useEffect(() => {
    if (orderId) {
      appDispatch(getOnlineBooking({ orderId })).then((response) => setOnlineBookingData(response.payload));
    }
  }, [orderId]);

  useEffect(() => {
    if (orderItems?.content?.length && addonName) {
      const currentAddon = orderItems.content
        .filter((item) => item.offeringType?.toLowerCase() === addonName?.toLowerCase());
      if (currentAddon.length > 0) setAddonStatus(currentAddon[0].status);
    }
  }, [orderItems]);

  return (
    <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
        Online Booking
        {!isEditable && renderStatusPill(addonStatus)}
      </Typography>

      <Box>

        {/* Online Booking */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () =>
            navigate.to(Urls.OnlineBooking, product.id))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Online Booking Details</Typography>
        </Box>
        <GridItem sizes={[6, 6]}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Services Booked</Typography>
              <Typography>{onlineBookingData?.servicesBooked}</Typography>
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Number of Staff Members</Typography>
              <Typography>{onlineBookingData?.staffMembers}</Typography>
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Payment Gateway Used</Typography>
              {displayNotRequiredField('payment gateway', mapPaymentGateway[onlineBookingData?.paymentsGateway])}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Additional Notes/Instructions</Typography>
              {displayNotRequiredField('additional notes/instructions', onlineBookingData?.additionalNotes)}
            </Box>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">
                Do services and appointment time availability vary based on staff member?
              </Typography>
              <Typography sx={{ textTransform: 'capitalize' }}>
                {onlineBookingData?.appointmentTimeAvailability}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">
                Do payments need to be collected during booking?
              </Typography>
              <Typography sx={{ textTransform: 'capitalize' }}>{onlineBookingData?.paymentsCollected}</Typography>
            </Box>
          </Box>
        </GridItem>

        {/* Actions items */}
        {showActionItems && ActionItems(false, findAddonId(orderItems, addonName), false, openBusinessAndSalesAgent)}
      </Box>
    </GridItem>
  );
}
