import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";

const {t} = i18n;

export const AdSchedulingIndentedSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.adSchedulingIndented.title'),
  type: 'object',
  required: [],
  properties: {
    helpText1: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.adSchedulingIndented.helpText1'),
    },
    dayOfTheWeek: {
      type: 'string',
      title: t('pages.ausProduct.adSchedulingIndented.dayOfTheWeek'),
      default: data?.dayOfTheWeek || ''
    },
    startTime: {
      type: 'string',
      title: t('pages.ausProduct.adSchedulingIndented.startTime'),
      default: data?.startTime || ''
    },
    endTime: {
      type: 'string',
      title: t('pages.ausProduct.adSchedulingIndented.endTime'),
      default: data?.endTime || ''
    },
  },
});

const textLabelKeys = [
  'helpText1'
];

const textFieldKeys = [
  'dayOfTheWeek',
  'startTime',
  'endTime'
];

const textLabelFields = textLabelKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textLabel'},
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textInputField'},
}), {});

export const AdSchedulingIndentedUISchema = {
  'ui:order': [
    'helpText1',
    'dayOfTheWeek',
    'startTime',
    'endTime'
  ],
  ...textLabelFields,
  ...textFields
};

export const AdSchedulingIndentedCustomValidate = (formData: any, errors, uiSchema) => {
  return errors;
};
