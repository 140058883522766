import { IQFormInput, ValidationProvider } from '@gannettdigital/shared-react-components';
import {
  Button, FormControlLabel, Grid, MenuItem, Select, Typography, useTheme,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { createCapacityHeader, selectProducts } from 'services/adminSlice';

export default function NewCapacityStatus() {
  const products = useSelector(selectProducts);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      product: '', days: 0, weeks: 0, remarks: '',
    },
  });

  const {
    handleSubmit, control,
  } = methods;

  const saveStatus = (data) => {
    dispatch(createCapacityHeader(data));
    navigate('/admin/capacityHeaders');
  };

  const schema = {
    yupValidations: {},
    customValidations: {},
  };

  const arrayRange = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step,
    );

  return (
    <>
      <Typography variant="h2">New Capacity Update</Typography>
      <Grid container>
        <FormProvider {...methods}>
          <ValidationProvider schema={schema}>
            <Grid item xs={8} marginTop={3}>
              <FormControlLabel
                sx={{ marginLeft: 0, alignItems: 'flex-start', width: '100%' }}
                label={<Typography marginBottom={1}>Days to development start</Typography>}
                labelPlacement="top"
                control={(
                  <Controller
                    name="days"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        sx={{ width: '100%' }}
                        onChange={onChange}
                        value={value}
                      >
                        {arrayRange(1, 30, 1).map(num => (
                          <MenuItem key={num} value={num}>
                            <Typography>{num}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8} marginTop={3}>
              <FormControlLabel
                sx={{ marginLeft: 0, alignItems: 'flex-start', width: '100%' }}
                label={<Typography marginBottom={1}>Weeks to site completion</Typography>}
                labelPlacement="top"
                control={(
                  <Controller
                    name="weeks"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        sx={{ width: '100%' }}
                        onChange={onChange}
                        value={value}
                      >
                        {arrayRange(1, 19, 0.5).map(num => (
                          <MenuItem key={num} value={num}>
                            <Typography>{num}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8} marginTop={3}>
              <FormControlLabel
                sx={{ marginLeft: 0, alignItems: 'flex-start', width: '100%' }}
                label={<Typography marginBottom={1}>Product</Typography>}
                labelPlacement="top"
                control={(
                  <Controller
                    name="product"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        sx={{ width: '100%' }}
                        onChange={onChange}
                        value={value}
                      >
                        {products.map(product => (
                          <MenuItem key={product.id} value={product.name}>
                            <Typography>{product.name}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8} marginTop={3}>
              <IQFormInput
                labelText="Remarks"
                id="remarks"
                name="remarks"
                theme={theme}
                schema={schema}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} marginTop={3}>
              <Button variant="contained"
                sx={{ marginRight: 3 }}
                onClick={handleSubmit(saveStatus)}
              >
                <Typography>Save</Typography>
              </Button>
              <Link to="/admin/capacityHeaders">
                <Button variant="contained">
                  <Typography>Back to Capacity Headers</Typography>
                </Button>
              </Link>
            </Grid>
          </ValidationProvider>
        </FormProvider>
      </Grid>
    </>
  );
}
