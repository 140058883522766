/* eslint-disable max-len */
export const operationTypeOptions = [
  {
    label: 'Partnership',
    value: 'partnership',
    tooltip: 'A business structure where two or more individuals or entities share ownership and responsibilities. Different types include general, limited, or limited liability partnerships, each offering varying levels of liability protection and management roles.',
  },
  {
    label: 'Non-profit Corporation',
    value: 'nonProfitCorporation',
    tooltip: 'A corporation operating for purposes other than profit generation, such as charitable, educational, or religious goals. Non-profit corporations enjoy tax exemptions and adhere to specific regulations governing their operations and fund usage.',
  },
  {
    label: 'Limited Liability Corporation',
    value: 'limitedLiabilityCompany',
    tooltip: 'A flexible business structure blending elements of partnerships and corporations. LLCs provide limited liability protection akin to corporations while maintaining tax benefits and operational flexibility similar to partnerships. Management is by members or appointed managers.',
  },
  {
    label: 'Corporation',
    value: 'corporation',
    tooltip: 'A separate legal entity from its shareholders, offering limited liability protection. Corporations can engage in contracts, asset ownership, and legal actions independently. Typically managed by a board of directors, with appointed officers overseeing daily operations.',
  },
  {
    label: 'Co-operative',
    value: 'cooperative',
    tooltip: 'A business owned and operated by members for mutual benefit. Varieties include consumer cooperatives (owned by customers), worker cooperatives (owned and operated by employees), and producer cooperatives (owned and operated by producers).',
  },
];

export const businessIndustryOptions = [
  {
    label: 'Automotive',
    value: 'AUTOMOTIVE',
  },
  {
    label: 'Agriculture',
    value: 'AGRICULTURE',
  },
  {
    label: 'Banking',
    value: 'BANKING',
  },
  {
    label: 'Construction',
    value: 'CONSTRUCTION',
  },
  {
    label: 'Consumer',
    value: 'CONSUMER',
  },
  {
    label: 'Education',
    value: 'EDUCATION',
  },
  {
    label: 'Engineering',
    value: 'ENGINEERING',
  },
  {
    label: 'Energy',
    value: 'ENERGY',
  },
  {
    label: 'Oil and gas',
    value: 'OIL_AND_GAS',
  },
  {
    label: 'Fast moving consumer goods',
    value: 'FAST_MOVING_CONSUMER_GOODS',
  },
  {
    label: 'Financial',
    value: 'FINANCIAL',
  },
  {
    label: 'Fintech',
    value: 'FINTECH',
  },
  {
    label: 'Food and beverage',
    value: 'FOOD_AND_BEVERAGE',
  },
  {
    label: 'Government',
    value: 'GOVERNMENT',
  },
  {
    label: 'Healthcare',
    value: 'HEALTHCARE',
  },
  {
    label: 'Hospitality',
    value: 'HOSPITALITY',
  },
  {
    label: 'Insurance',
    value: 'INSURANCE',
  },
  {
    label: 'Legal',
    value: 'LEGAL',
  },
  {
    label: 'Manufacturing',
    value: 'MANUFACTURING',
  },
  {
    label: 'Media',
    value: 'MEDIA',
  },
  {
    label: 'Online',
    value: 'ONLINE',
  },
  {
    label: 'Professional services',
    value: 'PROFESSIONAL_SERVICES',
  },
  {
    label: 'Raw materials',
    value: 'RAW_MATERIALS',
  },
  {
    label: 'Real estate',
    value: 'REAL_ESTATE',
  },
  {
    label: 'Religion',
    value: 'RELIGION',
  },
  {
    label: 'Retail',
    value: 'RETAIL',
  },
  {
    label: 'Jewelry',
    value: 'JEWELRY',
  },
  {
    label: 'Technology',
    value: 'TECHNOLOGY',
  },
  {
    label: 'Telecommunications',
    value: 'TELECOMMUNICATIONS',
  },
  {
    label: 'Transportation',
    value: 'TRANSPORTATION',
  },
  {
    label: 'Travel',
    value: 'TRAVEL',
  },
  {
    label: 'Electronics',
    value: 'ELECTRONICS',
  },
  {
    label: 'Not for profit',
    value: 'NOT_FOR_PROFIT',
  },
];

export const salesforceToTwilio = {
  'Animals & Pets': 'HEALTHCARE',
  'Apparel / Fashion & Jewelry': 'RETAIL',
  'Arts & Entertainment': '',
  'Attorneys & Legal Services': 'LEGAL',
  'Automotive -- For Sale': 'AUTOMOTIVE',
  'Automotive -- Repair, Service & Parts': 'AUTOMOTIVE',
  'Beauty & Personal Care': 'CONSUMER',
  'Business Opportunities': 'FINANCIAL',
  'Business Services': 'PROFESSIONAL_SERVICES',
  'Career & Employment': 'PROFESSIONAL_SERVICES',
  'Community, Garage Sales & Organizations': 'NOT_FOR_PROFIT',
  'Computers, Telephony & Internet': 'TECHNOLOGY',
  'Dentists & Dental Services': 'HEALTHCARE',
  'Education & Instruction': 'EDUCATION',
  'Electronics & Photo': 'TECHNOLOGY',
  'Farming & Agriculture': 'AGRICULTURE',
  'Finance & Insurance': 'FINANCIAL',
  Furniture: 'RETAIL',
  'Government & Politics': 'GOVERNMENT',
  'Health & Fitness': 'HEALTHCARE',
  'Home & Home Improvement': '',
  'Industrial & Commercial': 'MANUFACTURING',
  'Personal Services (Weddings, Cleaners, etc.)': 'CONSUMER',
  'Physicians & Surgeons': 'HEALTHCARE',
  'Real Estate': 'REAL_ESTATE',
  'Religion & Spirituality': 'RELIGION',
  'Restaurants & Food': 'FOOD_AND_BEVERAGE',
  'Shopping, Collectibles & Gifts (General)': 'RETAIL',
  'Sports & Recreation': 'CONSUMER',
  'Toys & Hobbies': 'CONSUMER',
  Travel: 'TRAVEL',
};

export const businessTypeOptions = [
  'Private',
  'US Non Profit',
  'Public',
  'US Government',
];

export const jobTitleOptions = [
  'Director',
  'GM',
  'VP',
  'CEO',
  'CFO',
  'General Counsel',
  'Other',
];
