import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  createBundle, getBundleItems, getBundles, updateBundleItems,
} from 'services/adminSlice';
import BundleForm from './BundleForm';

export default function NewBundle() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const saveBundle = async (data) => {
    const bundle = {
      id: null,
      active: true,
      name: data.title,
      mainThemeId: data.themeId,
    };
    const res = await dispatch<any>(await createBundle(bundle));
    // build addons
    const currentBundle = res?.payload ?? bundle;
    const updateAddOns = data.bundleAddons.map(({ themeId }) => ({ bundleId: currentBundle.id, themeId, addon: true }));
    const updateProducts = data.bundleProducts.map(
      ({ themeId }) => ({ bundleId: currentBundle.id, themeId, addon: false }),
    );
    const allBundleItems = updateAddOns.concat(updateProducts);
    await dispatch(updateBundleItems({ bundleId: currentBundle.id, resource: allBundleItems }));
    await dispatch(getBundles({}));
    await dispatch(getBundleItems(currentBundle.id));
    navigate('/admin/bundles');
  };

  return (
    <>
      <Typography variant="h2">New Bundle</Typography>
      <BundleForm onSubmit={saveBundle} bundleId={0} />
    </>
  );
}
