/* eslint-disable react/jsx-fragments, react/jsx-no-useless-fragment */
import React from 'react';

import { t } from 'i18next';

import { IQCheckbox, IQTheme } from '@gannettdigital/shared-react-components';
import { FormHelperText, Typography } from '@mui/material';
import styled from '@emotion/styled';

type Checkbox = {
  field: string;
  label: string;
  title: string;
};

const checkboxes :Checkbox[] = [
  {
    field: 'localPickup',
    label: `${t('pages.ecommerce.shipping.localPickup')}`,
    title: '',
  },
  {
    field: 'domestic',
    label: t('pages.ecommerce.shipping.domestic'),
    title: '',
  },
  {
    field: 'international',
    label: t('pages.ecommerce.shipping.international'),
    title: '',
  },
];

type StyledProps = {
  theme: typeof IQTheme;
};

const StyledCheckboxHeading = styled(Typography)<StyledProps>`
  font-family: ${props => props.theme.typography.fontFamily};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  letter-spacing: 0.25px;
  color: ${props => props.theme.palette.text.primary};
  margin-bottom: 12px;
`;

type ShippingPolicyCheckboxesProps = {
  heading: string;
  hasError: boolean;
  errorMessage: string;
};

export const ShippingPolicyCheckboxes = ({ heading, hasError, errorMessage }: ShippingPolicyCheckboxesProps) => {
  const checkboxElements = checkboxes.map(element => (
    <IQCheckbox {...element} />
  ));

  return (
    <React.Fragment>
      <StyledCheckboxHeading theme={IQTheme}>
        {heading}
      </StyledCheckboxHeading>
      {checkboxElements}
      {hasError && (
        <FormHelperText error role="alert">
          {errorMessage}
        </FormHelperText>
      )}
    </React.Fragment>
  );
};
