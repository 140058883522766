export const siteTypeOptions = [{
  label: 'LocaliQ Site',
  value: 'localiqSite',
},
{
  label: 'BYOS',
  value: 'byos',
},
];

export const emptyContact = {
  name: '', email: '', phone: '', ext: '',
};

export const yesNoOptions = [{
  label: 'Yes',
  value: 'yes',
},
{
  label: 'No',
  value: 'no',
},
];

// products with current flow should be included here (not generic)
export const excludedOfferingTypes = [
  'EcommerceMicrosite',
  'LandingPage',
  'ChatProduct',
  'ChatPlatinumProduct',
  'SEOStandard',
  'SEOCustom',
  'SEOBlogStandard',
  'SEOBlogEnhanced',
  'SMMStandard',
  'SMMCustom',
  'DMSNextCustomerCenterByDash',
  'DMSNextCustomerCenter',
  'YouTube',
  'PiQLandingPages',
  'TargetedDisplay',
  'TargetedDisplayLegacy',
  'SearchEngineMarketing',
  'XMO',
  'XMOUpgrade',
  'PromotionsMediaMix'
];
