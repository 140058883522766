import { useEffect, useState } from 'react';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { inputStyles } from 'pages/seo/details/DetailsPageForm';
import {
  IQAddressMapbox, IQFormInput, IQFormSelect, IQTheme, IQThemeV2, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import {
  Alert,
  AlertTitle,
  FormControlLabel, FormHelperText, Grid, InputAdornment, MenuItem, Radio, RadioGroup, Select, TextField, Typography,
} from '@mui/material';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import ErrorText from 'components/errorText/ErrorText';
import i18n from 'i18n/i18n';
import schemaValidate from 'components/schemaValidate';
import GridItem from 'components/grid/GridItem';
import { tooltip } from 'pages/general-questions/form-sections/Tracking';
import NePhoneInput from 'components/NePhoneInputContainer/IQPhoneInputContainer';
import useMapboxService from 'services/MapboxService';
import { FormSelectStyled } from 'pages/locations/address/LocationAddressPage';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrdersContent } from 'services/ordersSlice';
import { getOrderBusiness, selectOrderBusiness } from 'services/businessSlice';
import { getBusinessLocations, selectBusinessLocations, selectProviders } from 'services/businessLocationSlice';
import { useParams } from 'react-router';
import { getClients, selectClients } from 'services/clientsSlice';
import { getCurrentOffer, selectCurrentOffer } from 'services/cefSlice';
import { OrderFlow } from 'shared/constants';
import schema from './CCByDashDetails.schema';
import {
  businessIndustryOptions, businessTypeOptions, jobTitleOptions, operationTypeOptions,
  salesforceToTwilio,
} from './CCByDashConstants';
import { yesNoOptions } from '../solutionStrategy/CCByDashStrategyConstants';

const { t } = i18n;

interface Props {
  product: any
  onBack: any
  onSubmit: any
}

export default function CCByDashDetailsForm(props: Props) {
  const { product } = props;
  const { orderId, orderItemId } = useParams();
  const dispatch = useDispatch();
  const order = useSelector(selectOrdersContent);
  const business = useSelector(selectOrderBusiness);
  const locations = useSelector(selectBusinessLocations);
  const providers = useSelector(selectProviders);
  const clients = useSelector(selectClients);
  const currentOffer = useSelector(selectCurrentOffer);
  const [operationType, setOperationType] = useState(product?.operationType || '');
  const [businessType, setBusinessType] = useState(product?.businessType || '');
  const [addresses, setAddresses] = useState([]);
  const [einRadioValue, setEinRadioValue] = useState(product?.einAvailable || '');

  const isSalesforce = order.orderFlow === OrderFlow.SALES_FORCE;

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      businessFriendlyName: product?.businessFriendlyName || '',
      websiteUrl: product?.websiteUrl || '',
      einAvailable: product?.einAvailable || '',
      ein: product?.ein || '',
      businessIndustry: product?.businessIndustry || '',
      operationType: product?.operationType || '',
      businessType: product?.businessType || '',
      addressLine1: product?.addressLine1 || '',
      addressLine2: product?.addressLine2 || '',
      city: product?.city || '',
      stateProvince: product?.stateProvince || '',
      postalCode: product?.postalCode || '',
      country: product?.country || '',
      primaryContactName: product?.primaryContactName || '',
      jobTitle: product?.jobTitle || '',
      primaryContactEmail: product?.primaryContactEmail || '',
      primaryContactPhone: product?.primaryContactPhone || '',
      primaryContactPhoneExt: product?.primaryContactPhoneExt || '',
    },
  });

  const {
    register,
    watch,
    setValue,
    getValues,
    handleSubmit,
    control,
    setError,
    formState: {
      isValid,
      errors,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
      touchedFields,
    },
  } = methods;

  const {
    getAddresses,
    countryItems,
    twoLettersCountryEquivalent,
    countryNamesConversion,
  } = useMapboxService();

  const country = watch('country');
  const autoCompleteAddress = (address) => {
    setValue('addressLine1', address.address);
    setValue('stateProvince', address.region);
    setValue('city', address.place);
    setValue('postalCode', address.postalCode);
    setValue('country', twoLettersCountryEquivalent[address.country]);
  };

  const businessIndustryEmpty = watch('businessIndustry') === '';
  const jobTitleEmpty = watch('jobTitle') === '';
  const einAvailable = watch('einAvailable') === 'yes';

  useEffect(() => {
    if (touchedFields.businessIndustry && businessIndustryEmpty) {
      setError('businessIndustry', {
        type: 'manual',
        message: t('pages.ccByDash.details.businessIndustryError'),
      }, { shouldFocus: false });
    }

    if (touchedFields.jobTitle && jobTitleEmpty) {
      setError('jobTitle', {
        type: 'manual',
        message: t('pages.ccByDash.details.primaryContactJobError'),
      }, { shouldFocus: false });
    }
  }, [Object.keys(touchedFields).length]);

  useEffect(() => {
    if (orderItemId && isSalesforce) dispatch(getCurrentOffer(orderItemId));
  }, [orderItemId]);

  useEffect(() => {
    if (Object.keys(currentOffer).length > 0 && !product.businessIndustry) {
      const salesforceCategory = currentOffer?.salesForceOfferObject?.advertiserBusinessCategory;
      if (salesforceCategory) setValue('businessIndustry', salesforceToTwilio[salesforceCategory]);
    }
  }, [currentOffer]);

  useEffect(() => {
    if (order?.businessId) {
      dispatch(getOrderBusiness(order.businessId));
      dispatch(getBusinessLocations(order.businessId));
    }
  }, [order]);

  useEffect(() => {
    if (orderId) dispatch(getClients(orderId));
  }, [orderId]);

  const prepopulateValue = (valueToPrepopulate, currentValue, fieldName) => {
    if (valueToPrepopulate && !currentValue) setValue(fieldName, valueToPrepopulate);
  };

  useEffect(() => {
    if (Object.keys(business.content).length > 0) {
      prepopulateValue(business.content?.businessName, product?.businessFriendlyName, 'businessFriendlyName');
    }
  }, [business]);

  const setCountry = (location) => {
    const locationLength = location?.length;
    switch (locationLength) {
      case 2:
        return location;
      case 3:
        return twoLettersCountryEquivalent[location];
      default:
        return countryNamesConversion[location] || 'US';
    }
  };

  const loadLocationValues = (location) => {
    prepopulateValue(location?.locationWebsite, product?.websiteUrl, 'websiteUrl');
    prepopulateValue(location?.businessAddressOne, product?.addressLine1, 'addressLine1');
    prepopulateValue(location?.businessAddressTwo, product?.addressLine1, 'addressLine2');
    prepopulateValue(location?.city, product?.addressLine1, 'city');
    prepopulateValue(location?.stateProvince, product?.stateProvince, 'stateProvince');
    prepopulateValue(location?.postalCode, product?.postalCode, 'postalCode');
    prepopulateValue(setCountry(location?.country), product?.country, 'country');
  };

  useEffect(() => {
    if (locations.length > 0) {
      const location = locations[locations.length - 1];
      loadLocationValues(location);
    } else if (providers.length > 0) {
      const provider = providers[providers.length - 1];
      loadLocationValues(provider);
    }
  }, [locations]);

  useEffect(() => {
    if (clients.length > 0) {
      const client = clients[0];
      prepopulateValue(client?.contact?.clientFullName, product?.primaryContactName, 'primaryContactName');
      prepopulateValue(client?.contactEmailList[0], product?.primaryContactEmail, 'primaryContactEmail');
      prepopulateValue(
        client?.contactPhoneNumberList?.[0]?.phoneNumber,
        product?.primaryContactPhone,
        'primaryContactPhone',
      );
      prepopulateValue(
        client?.contactPhoneNumberList?.[0]?.ext,
        product?.primaryContactPhoneExt,
        'primaryContactPhoneExt',
      );
    }
  }, [clients]);

  const handleFormSubmit = (data) => props.onSubmit(data);

  return (
    <DefaultPageLayout
      disableContinue={!isValid || businessIndustryEmpty || jobTitleEmpty}
      onBack={props.onBack}
      header="Details"
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container xs={9} sx={{ ...inputStyles }}>
            {/* General Business */}
            <Grid item mb={3} xs={12}>
              <Typography variant="h5" component="h2">
                General Business
              </Typography>
            </Grid>
            <Grid item mb={3} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}
                  color={errors.businessFriendlyName ? 'error.main' : 'common.black'}
                >
                  {t('pages.ccByDash.details.businessFriendlyNameLabel')}
                </Typography>
              </Grid>
              <TextField
                hiddenLabel
                required
                name="businessFriendlyName"
                error={!!errors.businessFriendlyName}
                fullWidth
                {...register('businessFriendlyName', {
                  validate: (value) => schemaValidate(value, 'businessFriendlyName', schema),
                })}
              />
              {!errors.businessFriendlyName && (
                <FormHelperText>{t('pages.ccByDash.details.businessFriendlyNameHelper')}</FormHelperText>
              )}
              <ErrorText
                hasError={!!errors.businessFriendlyName}
                errorText={t('pages.ccByDash.details.businessFriendlyNameError')}
              />
            </Grid>
            <Grid item mb={3} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}
                  color={errors.websiteUrl ? 'error.main' : 'common.black'}
                >
                  {t('pages.ccByDash.details.websiteUrlLabel')}
                </Typography>
              </Grid>
              <TextField
                hiddenLabel
                required
                name="websiteUrl"
                error={!!errors.websiteUrl}
                fullWidth
                {...register('websiteUrl', {
                  validate: (value) => schemaValidate(value, 'websiteUrl', schema),
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorText
                hasError={!!errors.websiteUrl}
                errorText={t('pages.ccByDash.details.websiteUrlError')}
              />
            </Grid>
            <Grid mb={2} mt={1} xs={12}>
              <Grid mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}>
                  {t('pages.ccByDash.details.einNotAvailableLabel')}
                </Typography>
                <RadioGroup
                  aria-labelledby="einAvailable"
                  name="einAvailable"
                  value={einRadioValue}
                  onChange={(e) => setEinRadioValue(e.target.value)}
                >
                  <GridItem sizes={[1.3, 1]} marginBottom={0}>
                    {yesNoOptions.map(option => (
                      <FormControlLabel
                        key={option.value}
                        {...register('einAvailable', {
                          validate: (value) => schemaValidate(value, 'einAvailable', schema),
                        })}
                        value={option.value}
                        control={<Radio />}
                        label={(
                          <Typography variant="body1">
                            {option.label}
                          </Typography>
                        )}
                      />
                    ))}
                  </GridItem>
                </RadioGroup>
              </Grid>
              {getValues('einAvailable') === 'no' && (
                <Alert severity="error" sx={{ textAlign: 'left', fontSize: '1.0em', border: '1' }}>
                  <AlertTitle>
                    {t('pages.ccByDash.details.einWarning')}
                  </AlertTitle>
                </Alert>
              )}
            </Grid>
            {einAvailable && (
              <Grid item mb={3} xs={12}>
                <Grid item mb={1}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}
                    color={errors.ein ? 'error.main' : 'common.black'}
                  >
                    {t('pages.ccByDash.details.einLabel')}
                  </Typography>
                </Grid>
                <TextField
                  hiddenLabel
                  required
                  name="ein"
                  error={!!errors.ein}
                  fullWidth
                  {...register('ein', {
                    validate: (value) => schemaValidate(value, 'ein', schema),
                  })}
                />
                <ErrorText
                  hasError={!!errors.ein}
                  errorText={t('pages.ccByDash.details.einError')}
                />
              </Grid>
            )}
            <GridItem sizes={[12]} marginBottom={3}>
              <GridItem marginBottom={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}
                  color={errors.businessIndustry ? IQThemeV2.palette.error.main : '#000000'}
                >
                  {t('pages.ccByDash.details.businessIndustryLabel')}
                </Typography>
                <GridItem marginBottom={0}>
                  <Controller
                    name="businessIndustry"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        label=""
                        displayEmpty
                        labelId="select-label"
                        error={!!errors.businessIndustry}
                        {...field}
                        sx={{
                          width: '100%',
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select Business Industry
                        </MenuItem>
                        {businessIndustryOptions.map(opt => (
                          <MenuItem value={opt.value} key={opt.value}>{opt.label}</MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <ErrorText
                    hasError={!!errors.businessIndustry}
                    errorText={t('pages.ccByDash.details.businessIndustryError')}
                  />
                </GridItem>
              </GridItem>
            </GridItem>
            <Grid item mb={3} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}
                  color={errors.operationType ? 'error.main' : 'common.black'}
                >
                  {t('pages.ccByDash.details.operationTypeLabel')}
                </Typography>
                <RadioGroup
                  aria-labelledby="operation-type"
                  name="operationType"
                  value={operationType}
                  onChange={(e) => setOperationType(e.target.value)}
                >
                  <GridItem sizes={[6, 6]} marginBottom={0}>
                    {operationTypeOptions.slice(0, 2).map(option => (
                      <FormControlLabel
                        key={option.value}
                        {...register('operationType', {
                          validate: (value) => schemaValidate(value, 'operationType', schema),
                        })}
                        value={option.value}
                        control={<Radio />}
                        label={(
                          <Typography variant="body1">
                            {option.label}
                            {tooltip(option.tooltip, <InfoOutlinedIcon />)}
                          </Typography>
                        )}
                      />
                    ))}
                  </GridItem>
                  <GridItem sizes={[6, 6]} marginBottom={0}>
                    {operationTypeOptions.slice(2, 5).map(option => (
                      <FormControlLabel
                        key={option.value}
                        {...register('operationType', {
                          validate: (value) => schemaValidate(value, 'operationType', schema),
                        })}
                        value={option.value}
                        control={<Radio />}
                        label={(
                          <Typography variant="body1">
                            {option.label}
                            {tooltip(option.tooltip, <InfoOutlinedIcon />)}
                          </Typography>
                        )}
                      />
                    ))}
                  </GridItem>
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid item mb={3} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}
                  color={errors.operationType ? 'error.main' : 'common.black'}
                >
                  {t('pages.ccByDash.details.businessTypeLabel')}
                </Typography>
                <RadioGroup
                  aria-labelledby="business-type"
                  name="businessType"
                  value={businessType}
                  onChange={(e) => setBusinessType(e.target.value)}
                >
                  <GridItem sizes={[6, 6]} marginBottom={0}>
                    {businessTypeOptions.slice(0, 2).map(option => (
                      <FormControlLabel
                        key={option}
                        {...register('businessType', {
                          validate: (value) => schemaValidate(value, 'businessType', schema),
                        })}
                        value={option}
                        control={<Radio />}
                        label={(
                          <Typography variant="body1">
                            {option}
                          </Typography>
                        )}
                      />
                    ))}
                  </GridItem>
                  <GridItem sizes={[6, 6]} marginBottom={0}>
                    {businessTypeOptions.slice(2, 4).map(option => (
                      <FormControlLabel
                        key={option}
                        {...register('businessType', {
                          validate: (value) => schemaValidate(value, 'businessType', schema),
                        })}
                        value={option}
                        control={<Radio />}
                        label={(
                          <Typography variant="body1">
                            {option}
                          </Typography>
                        )}
                      />
                    ))}
                  </GridItem>
                </RadioGroup>
              </Grid>
            </Grid>

            {/* Business Address */}
            <Grid item mb={3} mt={3}
              xs={12}
            >
              <Typography variant="h5" component="h2">
                Business Address
              </Typography>
            </Grid>
            <GridItem sizes={[12]}>
              <IQAddressMapbox
                getAddressOptions={async (e) => {
                  const address = await getAddresses(e.query, country);
                  setAddresses(address.data?.suggestions);
                }}
                items={addresses}
                labelText={t('pages.business.location.address.addressLine1')}
                onAutoCompleteSelected={autoCompleteAddress}
                name="addressLine1"
                required
                onAddressChange={() => {}}
                schema={schema}
              />
            </GridItem>
            <GridItem sizes={[12]}>
              <IQFormInput
                id="addressLine2"
                name="addressLine2"
                labelText={t('pages.business.location.address.addressLine2')}
                theme={IQTheme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
            </GridItem>
            <GridItem inlineItems sizes={[6, 6]}>
              <IQFormInput
                id="city"
                name="city"
                labelText={t('pages.business.location.address.city')}
                theme={IQTheme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
              <FormSelectStyled>
                <IQFormInput
                  id="stateProvince"
                  name="stateProvince"
                  labelText={t('pages.business.location.address.state')}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                />
              </FormSelectStyled>
            </GridItem>
            <GridItem inlineItems sizes={[6, 6]}>
              <IQFormInput
                id="postalCode"
                name="postalCode"
                labelText={t('pages.business.location.address.zipCode')}
                theme={IQTheme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
              <FormSelectStyled>
                <IQFormSelect
                  id="country"
                  name="country"
                  labelText={t('pages.business.location.address.country')}
                  items={countryItems}
                  defaultValue="US"
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  required
                  schema={schema}
                />
              </FormSelectStyled>
            </GridItem>

            {/* Business Primary Contact */}
            <Grid item mb={3} mt={3}
              xs={12}
            >
              <Typography variant="h5" component="h2">
                Business Primary Contact
              </Typography>
            </Grid>
            <Grid item mb={3} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}
                  color={errors.primaryContactName ? 'error.main' : 'common.black'}
                >
                  {t('pages.ccByDash.details.primaryContactNameLabel')}
                </Typography>
              </Grid>
              <TextField
                hiddenLabel
                required
                name="primaryContactName"
                error={!!errors.primaryContactName}
                fullWidth
                {...register('primaryContactName', {
                  validate: (value) => schemaValidate(value, 'primaryContactName', schema),
                })}
              />
              <ErrorText
                hasError={!!errors.primaryContactName}
                errorText={t('pages.ccByDash.details.primaryContactNameError')}
              />
            </Grid>
            <GridItem sizes={[12]} marginBottom={3}>
              <GridItem marginBottom={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}
                  color={errors.jobTitle ? IQThemeV2.palette.error.main : '#000000'}
                >
                  {t('pages.ccByDash.details.primaryContactJobLabel')}
                </Typography>
                <GridItem marginBottom={0}>
                  <Controller
                    name="jobTitle"
                    control={control}
                    defaultValue={product?.jobTitle || ''}
                    render={({ field }) => (
                      <Select
                        label=""
                        displayEmpty
                        labelId="select-label"
                        error={!!errors.jobTitle}
                        {...field}
                        sx={{
                          width: '100%',
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select Job Title
                        </MenuItem>
                        {jobTitleOptions.map(opt => (
                          <MenuItem value={opt} key={opt}>{opt}</MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <ErrorText
                    hasError={!!errors.jobTitle}
                    errorText={t('pages.ccByDash.details.primaryContactJobError')}
                  />
                </GridItem>
              </GridItem>
            </GridItem>
            <Grid item mb={3} xs={12}>
              <Typography variant="subtitle2" component="h3" fontSize={16}
                color={errors.primaryContactEmail ? 'error.main' : 'common.black'} mb={1}
              >
                Email *
              </Typography>
              <TextField
                {...register('primaryContactEmail', {
                  validate: (value) => schemaValidate(value, 'primaryContactEmail', schema),
                })}
                hiddenLabel
                required
                name="primaryContactEmail"
                type="email"
                error={!!errors.primaryContactEmail}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorText
                hasError={!!errors.primaryContactEmail}
                errorText={t('pages.ccByDash.details.PrimaryContactEmailError')}
              />
            </Grid>
            <Grid item mb={3} xs={12}>
              <NePhoneInput
                name="primaryContactPhone"
                defaultValue=""
                country="US"
                hasExtension
                extensionOptions={{
                  name: 'primaryContactPhoneExt',
                  defaultValue: '',
                }}
                phoneLabelOptions={{
                  tooltipText: '',
                  labelText: 'Phone Number',
                  hasError: true,
                  theme: IQThemeV2,
                  required: true,
                }}
                hasType={false}
                countryCallingCodeEditable={false}
                international
              />
            </Grid>

          </Grid>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
