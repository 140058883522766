import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import { Urls } from 'navigation/Urls';
import EcommerceType, { EcommerceStateObject } from 'models/EcommerceType';
import { StatusType } from 'models/StatusType';
import BrandingForm from './BrandingForm';

export default function Branding() {
  const prodFunc = useProduct<EcommerceType,
  StatusType<EcommerceStateObject>>(Urls.Branding);

  return (
    <ContentLoader status={prodFunc.product.status} message={prodFunc.product.message}>
      <BrandingForm onBack={prodFunc.onBack} onSubmit={prodFunc.onSubmit} product={prodFunc.product.content?.data} />
    </ContentLoader>
  );
}
