import {
  FormHelperText, Grid, MenuItem, Select, Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import {useEffect, useState} from 'react';
import ErrorText from 'components/errorText/ErrorText';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';

export default function Dropdown(props: any) {
  const { options: { enumOptions }, required } = props;
  const [isTouched, setIsTouched] = useState(false);

  let hasError: boolean;

  if (props.name === 'maximumAge'
    || props.name === 'maximumAgeForSmartWebsite'
    || props.name === 'maximumAgeForSmartRetargeting'
    || props.name === 'maximumAgeForSmartReach'
    || props.name === 'maxAge') {
    hasError = (required || (props?.rawErrors?.length > 0 && isTouched));
  } else {
    hasError = (required && props?.rawErrors?.length > 0 && isTouched);
  }
  useEffect(() => {
    if (!!props?.value) props.onChange(props?.value);
  }, []);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .MuiSelect-select': {
          backgroundColor: 'common.white',
        },
        '& .MuiOutlinedInput-root': {
          width: '100%',
          marginTop: '10px',
        },
      }}
    >
      <Grid item mt={3}>
        <Typography
          fontWeight={600}
          sx={{
            color: hasError ? IQThemeV2.palette.error.main : IQThemeV2.palette.text.primary,
          }}
        >
          {props.label}
        </Typography>
        <Select defaultValue={props.defaultValue} variant="outlined"
            SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
            MenuProps={{ style: { maxHeight: 230,maxWidth:680}, }}
            onChange={(e) => props.onChange(e.target.value)}
            error={hasError}
            onBlur={() => setIsTouched(true)}
            value={props.value}
            renderValue={(selected) => (
              <Typography>{selected}</Typography>
            )}
          >
          <MenuItem value="" disabled>Select an option</MenuItem>
          {enumOptions.filter(opt => opt.value).map(opt => (
            <MenuItem value={opt.value} key={opt.value} sx={{whiteSpace:"break-spaces"}}>{opt.label}</MenuItem>
          ))}
        </Select>
        {(props.placeholder && (props?.rawErrors?.length === 0 || !isTouched)) && (
        <FormHelperText error={false}>{props.placeholder}</FormHelperText>
        )}
        <ErrorText
          hasError={hasError}
          errorText={props.schema.customError
            ? (props.schema.customErrorMessage)
            : (props.errorMessage) || props?.rawErrors?.[0]}
        />
      </Grid>
    </Box>
  );
}
