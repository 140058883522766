import * as yup from 'yup';

const schema = {
  buyDomain: yup.string().url('Please enter a valid URL that includes either http:// or https://').nullable()
    .required('Please enter a valid URL that includes either http:// or https://'),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
