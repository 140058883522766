import { Controller, FormProvider, useForm } from 'react-hook-form';
import { IQFormTextArea, IQInputNumericField, ValidationProvider } from '@gannettdigital/shared-react-components';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import YesNoRadio from 'components/yesNo/YesNoRadio';
import EcommerceType from 'models/EcommerceType';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import React from 'react';
import { generateTooltipTextElement } from 'shared/generateTooltipText';
import { useTheme } from '@mui/material';
import schema from './ProductsDetails.schema';

const tooltipText = generateTooltipTextElement(
  'Orders with 100+ products will be subject to review.',
  { render: false },
);

interface Props {
  product: EcommerceType;
  onSubmit: (data) => any;
  onBack: () => any;
}

export default function ProductsDetailsForm(props: Props) {
  const { product, onSubmit, onBack } = props;

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      productTypesOffered: product?.productTypesOffered,
      numberProductToBeSold: product?.numberProductToBeSold,
      productsChangeBasedSeason: product?.productsChangeBasedSeason,
      seasonalProducts: product?.seasonalProducts,
    },
  });

  const {
    handleSubmit, formState: {
      isValid, isSubmitting, isSubmitSuccessful, isDirty,
    },
  } = methods;

  const theme = useTheme();

  const numericFieldBorderColor = theme.palette.text.primary;

  return (
    <DefaultPageLayout header="Product Details"
      onBack={onBack} onContinue={handleSubmit(onSubmit)}
      disableContinue={!isValid}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <GridContainer sx={{ '[role=group] > button:first-of-type': { pl: '6px' } }}>
            <GridItem sx={{ label: { pb: '8px' }, textarea: { width: 'initial' } }}>
              <Controller
                name="productTypesOffered"
                render={({ field }) => (
                  <IQFormTextArea
                    id="productTypesOffered"
                    name="productTypesOffered"
                    labelText="Product Types Offered *"
                    fontLabelWeight="600"
                    required
                    rowCount={2}
                    fullWidth
                    showError
                    {...field}
                  />
                )}
              />
            </GridItem>
            <GridItem sx={{ fieldset: { borderColor: numericFieldBorderColor } }}>
              <IQInputNumericField
                label="Number of products to be sold"
                name="numberProductToBeSold"
                fullWidth
                required
                defaultValue=""
                disallowNegative
                tooltipText={tooltipText as unknown as string}
                tooltipPlacement="top"
                tooltilPaddingBottom={12}
                tooltipArrow={false}
              />
            </GridItem>
            <YesNoRadio
              label="Products change based on season"
              name="productsChangeBasedSeason"
            >
              <GridItem sx={{ label: { pb: '8px' }, textarea: { width: 'initial' } }}>
                <IQFormTextArea
                  id="seasonalProducts"
                  name="seasonalProducts"
                  labelText="Seasonal Products"
                  fontLabelWeight="600"
                  rowCount={2}
                  fullWidth
                />
              </GridItem>
            </YesNoRadio>
          </GridContainer>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
