import {
  IQButtonRadioContainer,
  IQCheckbox,
  IQFormTextArea,
  IQInputNumericField,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { Grid, styled } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IQButtonRadioProps } from '@gannettdigital/shared-react-components/dist/buttons/IQButtonRadio/IQButtonRadio';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import YesNoRadio from 'components/yesNo/YesNoRadio';
import OnlineBookingType from 'models/OnlineBookingType';
import schema from './OnlineBooking.schema';

const paymentGatewayOptions: IQButtonRadioProps[] = [
  {
    value: 'authorizeNet',
    label: 'Authorize.net',
    field: 'authorizeNet',
    tooltipPlacement: 'top',
    // @ts-ignore
    tooltipText: <p style={{ padding: 4, margin: 0 }}>
      Clients need to register, set up and maintain their own payment
      gateway to collect payments. Authorize.net is an approved gateway.&nbsp;
      <a target="_blank" referrerPolicy="no-referrer" style={{ color: 'white' }}
        href="https://www.authorize.net/" rel="noreferrer"
      >
        View More Information
      </a>
                 </p>,
  },
  {
    value: 'paypalPaymentsPro',
    label: 'PayPal Payments Pro',
    field: 'payPalPaymentsPro',
    tooltipPlacement: 'top',
    // @ts-ignore
    tooltipText: <p style={{ padding: 4, margin: 0 }}>
      Clients need to register, set up and maintain their own payment gateway to
      collect payments. PayPal Payments Pro is an approved gateway.&nbsp;
      <a target="_blank" referrerPolicy="no-referrer" style={{ color: 'white' }}
        href="https://www.paypal.com/us/webapps/mpp/paypal-payments-pro" rel="noreferrer"
      >
        View More Information
      </a>
                 </p>,
  },
  {
    value: 'stripe',
    label: 'Stripe',
    field: 'stripe',
    tooltipPlacement: 'top',
    // @ts-ignore
    tooltipText: <p style={{ padding: 4, margin: 0 }}>
      Clients need to register, set up and maintain their own payment gateway
      to collect payments. Stripe is an approved gateway.&nbsp;
      <a target="_blank" referrerPolicy="no-referrer" style={{ color: 'white' }}
        href="https://stripe.com/" rel="noreferrer"
      >
        View More Information
      </a>
                 </p>,
  },
];

type StyledGridProps = {
  gap?: string;
  mt?: string;
  mb?: string;
  color?: string;
};

// eslint-disable-next-line max-len
const acknowledgementText = 'LOCALiQ is not responsible for setting up or managing the payment gateway on behalf of the client. The client must provide LOCALiQ with API keys to connect the form to their payment processor.';

const GridStyled = styled(Grid)<StyledGridProps>`
  margin-top: ${(props) => props.mt || '24px'};

  .MuiToggleButton-root.Mui-selected { 
    background-color: transparent; 
  };

  .MuiToggleButton-root.Mui-selected:hover { 
    background-color: transparent; 
  };

  .MuiToggleButton-root:hover { 
    background-color: transparent; 
  };

  .MuiTouchRipple-root > * {
    display: none;
  };

  & .MuiToggleButtonGroup-root { 
    gap: 18px; 
  };

  & .MuiFormHelperText-root {
    color: ${props => props.theme.palette.error.main};
  }

  & .MuiCheckbox-root { 
    padding: 0; 
    padding-right: 8px; 
  };

  & .MuiTypography-root { 
    max-width: 90%; 
  };
`;

export interface OnlineBookingFormProps {
  onlineBooking: OnlineBookingType
  onSubmit: (data) => any
  onBack: () => any
}

export default function OnlineBookingForm({
  onlineBooking,
  onSubmit,
  onBack,
}: OnlineBookingFormProps) {
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      servicesBooked: onlineBooking.servicesBooked || '',
      staffMembers: onlineBooking.staffMembers || '',
      appointmentTimeAvailability: onlineBooking.appointmentTimeAvailability || '',
      paymentsCollected: onlineBooking.paymentsCollected || '',
      paymentsGateway: onlineBooking.paymentsGateway || '',
      acknowledge: onlineBooking.acknowledge || '',
      additionalNotes: onlineBooking.additionalNotes || '',
    },
  });
  const {
    handleSubmit,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful,
    },
  } = methods;

  const handleOnSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <DefaultPageLayout
          disableContinue={!isValid}
          onBack={onBack}
          header={t('pages.addons.onlineBooking.title')}
          onContinue={handleSubmit(handleOnSubmit)}
        >
          <Grid container item xs={6}>
            <Grid item width="452px">
              <IQFormTextArea
                id="servicesBooked"
                name="servicesBooked"
                labelText={`${t('pages.addons.onlineBooking.typesBookingServices')} *`}
                fontLabelWeight="600"
                rowCount={2}
                required
                fullWidth
              />
            </Grid>
            <Grid container maxWidth="452px">
              <GridStyled xs={12} sx={{ '& .MuiFormControl-fullWidth > .MuiInputBase-root': { height: '40px' } }}>
                <IQInputNumericField
                  label={t('pages.addons.onlineBooking.numberStaffMembers')}
                  name="staffMembers"
                  fullWidth
                  required
                  defaultValue=""
                  disallowNegative
                />
              </GridStyled>
              <GridStyled xs={12}>
                <YesNoRadio
                  label={`${t('pages.addons.onlineBooking.serviceAndAvailabilityVaries')} *`}
                  name="appointmentTimeAvailability"
                />
              </GridStyled>
              <GridStyled xs={12} mt="16px" mb="-12px">
                <YesNoRadio
                  label={`${t('pages.addons.onlineBooking.paymentCollectedDuringBooking')} *`}
                  name="paymentsCollected"
                >
                  <GridStyled xs={12} mt="17px">
                    <IQButtonRadioContainer
                      label="Select payment gateway *"
                      name="paymentsGateway"
                      fontTitleWeight="600"
                      optionsList={paymentGatewayOptions}
                      inline
                      singleColumn
                    />
                  </GridStyled>
                  <GridStyled xs={12} sx={{ label: { m: 0, mr: '11px' } }}>
                    <IQCheckbox
                      title="Acknowledgement *"
                      label={acknowledgementText}
                      name="acknowledge"
                      field="acknowledge"
                    />
                  </GridStyled>
                </YesNoRadio>
              </GridStyled>
              <GridStyled xs={12}>
                <IQFormTextArea
                  id="additionalNotes"
                  labelText="Additional Notes/Instructions"
                  name="additionalNotes"
                  rowCount={2}
                  fullWidth
                  fontLabelWeight="600"
                  showError
                />
              </GridStyled>
            </Grid>
          </Grid>
        </DefaultPageLayout>
        {/* show the dialog if we changed the field and didn't submit the changes */}
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
