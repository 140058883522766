import React, { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';

import {
  Autocomplete, TextField, Chip, Box, Typography, Checkbox,
} from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InfoIcon from '@mui/icons-material/Info';
import { IQLabelTooltip, IQTheme } from '@gannettdigital/shared-react-components';

const CheckBoxBlankIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const CheckBoxCheckedIcon = <CheckBoxIcon fontSize="small" />;

export interface Props {
  /**
   * Show label
   */
  label: boolean;
  /**
   * Label for the input field
   */
  labelText?: string;
  /**
   * Tool tip for the label
   */
  labelTooltipText?: string;
  /**
   * Where should the tooltip be positioned.
   */

  labelTooltipPlacement?: 'bottom' | 'right' | 'top' | 'left';

  /**
   * Tooltip's bottom padding.
   */

  labelTooltipPaddingBottom?: number;

  /**
   * Required or not
   */
  required?: boolean;
  /**
   * Icon for the label
   */
  labelIcon?: SVGElement;
  /**
   * Values to populate Select
   */
  items: SelectItem[];
  /**
   * Name for the field
   */
  name: string;
  /**
   * Selected values
   */
  values: string[];
  /**
  * If set to true the textbox can contain any arbitrary value.
  */
  freeSolo?: boolean;
  /**
   * Placeholder value
   */
  placeholder?: string;
  /**
   * Enables the checkbox type options
   */
  withCheckboxOptions?: boolean;
  /**
   * Hide clear icon in the input field
   */
  disableClear?: boolean;
  /**
   * Max number fo items to be selected
   */
  maxItems: number;
  supportCustomChips?: boolean;
  requiredErrorMessage?: string;
  clearExtraItems?: boolean;
  onClose?: any;
}

export type SelectItem = { label: string; value: string };
const MAX_DEFAULT = 99;

const IQSelectChip = ({
  values,
  items,
  label,
  name,
  labelIcon,
  labelText,
  labelTooltipText,
  labelTooltipPlacement,
  labelTooltipPaddingBottom,
  required,
  maxItems,
  freeSolo,
  placeholder,
  withCheckboxOptions,
  supportCustomChips = true,
  requiredErrorMessage,
  clearExtraItems,
  onClose,
}: Props) => {
  const maxLimit = maxItems > 0 ? maxItems : MAX_DEFAULT;
  const {
    control, setError, clearErrors, formState: { errors, isDirty },
  } = useFormContext();

  const hasError = !!errors[name];
  const renderErrorText = (errors) => (
    <>
      <InfoIcon sx={{ fontSize: '15px', verticalAlign: 'middle' }} />
      {' '}
      {errors[name]?.message}
    </>
  );
  const errorMessage = hasError ? renderErrorText(errors) : '';

  const [freeItems, setFreeItems] = useState<string[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>(values || []);
  const valueToLabel: Map<string, string> = new Map<string, string>();
  items.forEach((item) => {
    valueToLabel.set(item.value, item.label);
  });

  useEffect(() => {
    setFreeItems(items.map((item) => item.value));
    setSelectedValues(values || []);
  }, [items]);

  const onChangeValues = (
    event: React.SyntheticEvent<Element, Event>,
    value: NonNullable<string> | string[],
    fieldChangeEvent: any,
  ) => {
    if (Array.isArray(value)) {
      // check for max items to be selected, remove the first item
      const tempValue = (value.length > maxLimit && clearExtraItems) ? value.slice(1) : value;
      setSelectedValues(tempValue);
      fieldChangeEvent(tempValue);
    }
  };

  useEffect(() => {
    if (maxLimit) {
      if (selectedValues.length > maxLimit) {
        setError(name, {
          type: 'manual',
          message: `Please review and add up to ${maxLimit} items to continue.`,
        }, { shouldFocus: false });
      }
    } else clearErrors(name);

    if (required) {
      if (required && isDirty && selectedValues.length === 0) {
        setError(name, {
          type: 'manual',
          message: requiredErrorMessage || 'This is a required field.',
        }, { shouldFocus: false });
      }
    } else clearErrors(name);
  }, [selectedValues, required]);

  const getLabelFromItems = (value: string, forTag: boolean) => {
    const labelFound = valueToLabel.get(value);
    if (forTag) {
      return labelFound || value;
    }
    return labelFound || `Add "${value}"`;
  };

  const filterItems = (value: string) => {
    const filteredValues = [];
    items.forEach((item) => {
      if (item.label.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
        filteredValues.push(item.value);
      }
    });
    return filteredValues;
  };

  return (
    <>
      {label && (
        <IQLabelTooltip
          labelText={labelText}
          hasError={hasError}
          theme={IQTheme}
          tooltipText={labelTooltipText}
          tooltipPlacement={labelTooltipPlacement}
          paddingBottom={labelTooltipPaddingBottom}
          required={required}
          Icon={labelIcon}
        />
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Box sx={{ position: 'relative' }}>
            <Autocomplete<string, boolean, boolean, boolean>
              sx={{
                width: '100%', height: '100%', left: 0, top: 0,
              }}
              fullWidth
              multiple
              freeSolo={freeSolo}
              disableClearable
              options={freeItems}
              value={selectedValues}
              disableCloseOnSelect={withCheckboxOptions}
              onChange={(e, v) => onChangeValues(e, v, field.onChange)}
              onClose={onClose}
              filterOptions={(options, params) => {
                const filtered = filterItems(params.inputValue);
                // Suggest the creation of a new value
                if (params.inputValue !== '' && supportCustomChips) {
                  filtered.push(params.inputValue);
                }
                return filtered;
              }}
              getOptionLabel={(option) => getLabelFromItems(option, false)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  {
                    withCheckboxOptions
                      ? (
                        <>
                          <Checkbox
                            icon={CheckBoxBlankIcon}
                            checkedIcon={CheckBoxCheckedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {getLabelFromItems(option, false)}
                        </>
                      ) : option
                  }
                </li>
              )}
              // eslint-disable-next-line max-len
              renderTags={(value: string[], getTagProps) => value.map((option: string, index: number) => (
                <Chip
                  deleteIcon={<ClearIcon />}
                  variant="outlined"
                  sx={{
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    '& .MuiChip-deleteIcon': {
                      color: 'primary.main',
                    },
                  }}
                  label={getLabelFromItems(option, true)}
                  {...getTagProps({ index })}
                />
              ))}
              renderInput={(params) => (
                <>
                  <TextField {...params} placeholder={placeholder} error={hasError}
                    helperText={errorMessage} variant="outlined"
                  />
                  <Typography
                    variant="caption"
                    component="span"
                    // regardless of the theme, this text will always be in gray color
                    sx={{
                      color: 'info.light', position: 'absolute', right: '10px',
                    }}
                  >
                    { maxLimit >= MAX_DEFAULT ? '' : `${selectedValues.length}/${maxLimit}`}
                  </Typography>
                </>
              )}
            />
          </Box>
        )}
      />
    </>
  );
};

export default IQSelectChip;
