import { useDispatch } from 'react-redux';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { setOrderBusiness } from 'services/businessSlice';
import { useParams } from 'react-router-dom';
import { getOrder } from 'services/ordersSlice';
import { Business } from 'models/BusinessType';
import BusinessSearchForm from './BusinessSearchForm';

export default function BusinessSearch() {
  const navigate = useNavigationHandler();
  const dispatch = useDispatch();
  const { orderId } = useParams();

  const onBusinessSelected = async (business: Business) => {
    await dispatch(setOrderBusiness(business));
    await dispatch(getOrder(orderId));
    navigate.to(Urls.BusinessDetail);
  };

  return (
    <BusinessSearchForm onBusinessSelected={onBusinessSelected} />
  );
}
