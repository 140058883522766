import {
  IQFormInput,
  IQFormSelect,
  IQTheme, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import React, {useState} from 'react';
import {FormHelperText, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {yesNoDropdownItems} from "pages/youtube/YouTubeService";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {isReachSiteLiveDropdownItems} from "pages/youtube/websites/WebsitesService";
import YouTubeType from "models/YouTubeType";
import schema from './Websites.schema';
import {FormProvider, useForm} from "react-hook-form";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import {Box} from "@mui/system";

interface Props {
  product: YouTubeType;
  onSubmit: (data: WebsitesPageFormProps) => void;
  onBack: () => void;
}

export type WebsitesPageFormProps = {
  doesHaveWebsite: string;
  isReachSiteLive: string;
  temporaryLink: string;
};

export default function WebsitesPageForm({product, onSubmit, onBack}: Props) {
  const {t} = useTranslation();
  const [doesHaveWebsiteSelectedValue, setDoesHaveWebsiteSelectedValue] = useState(product?.doesHaveWebsite || '');
  const [isReachSiteLiveValue, setIsReachSiteLiveValue] = useState(product?.isReachSiteLive || '');

  const methods = useForm<WebsitesPageFormProps>({
    mode: 'onChange',
    defaultValues: {
      doesHaveWebsite: product?.doesHaveWebsite || '',
      isReachSiteLive: product?.isReachSiteLive || '',
      temporaryLink: product?.temporaryLink || '',
    },
  });

  const handleFormSubmit = (data: WebsitesPageFormProps) => {
    if (data.doesHaveWebsite === 'No') {
      data.temporaryLink = '';
      data.isReachSiteLive = '';
    }
    if (data.isReachSiteLive === 'Yes, the ReachSite is Live.') {
      data.temporaryLink = '';
    }
    onSubmit(data);
  }

  const {
    handleSubmit,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  const handleIsReachSiteLiveDropdownChange = (event) => {
    setIsReachSiteLiveValue(event.target.value);
  }

  const handleDoesHaveWebsiteDropdownChange = (event) => {
    setDoesHaveWebsiteSelectedValue(event.target.value);
  };

  return (
      <DefaultPageLayout
          disableContinue={!isValid}
          onBack={onBack}
          header={t('pages.youtube.websites.header')}
          onContinue={handleSubmit(handleFormSubmit)}
      >
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <Box
                id="mainForm"
                component="form"
                sx={{display: 'flex'}}
                onSubmit={handleSubmit(handleFormSubmit)}
            >
              <GridContainer fullWidth>
                <GridItem sizes={[12]}>
                  <IQFormSelect
                      id="doesHaveWebsite"
                      name="doesHaveWebsite"
                      labelText={t('pages.youtube.websites.doesHaveWebsite')}
                      items={yesNoDropdownItems}
                      defaultValue=""
                      required
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      onChange={handleDoesHaveWebsiteDropdownChange}
                      schema={schema}
                  />
                </GridItem>
                {doesHaveWebsiteSelectedValue === 'Yes' && (
                    <GridItem sizes={[12]}>
                      <IQFormSelect
                          id="isReachSiteLive"
                          name="isReachSiteLive"
                          labelText={t('pages.youtube.websites.isReachSiteLive')}
                          items={isReachSiteLiveDropdownItems}
                          defaultValue=""
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          onChange={handleIsReachSiteLiveDropdownChange}
                          schema={schema}
                      />
                    </GridItem>
                )}
                {isReachSiteLiveValue === 'No, the ReachSite is not live.' && doesHaveWebsiteSelectedValue === 'Yes' && (
                    <GridItem sizes={[12]}>
                      <IQFormInput
                          id="temporaryLink"
                          name="temporaryLink"
                          labelText={t('pages.youtube.websites.temporaryLink')}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          showError
                          schema={schema}
                          adornmentIcon={<InsertLinkIcon/>}
                      />
                      <FormHelperText sx={{
                        mt: '-15px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}>
                        <Typography>{t('pages.youtube.websites.temporaryLinkHelpText')}</Typography>
                      </FormHelperText>
                    </GridItem>
                )}
              </GridContainer>
            </Box>
          </FormProvider>
        </ValidationProvider>
        <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </DefaultPageLayout>
  );
}
