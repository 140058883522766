import { StepperLayout } from 'layouts/StepperLayout';
import { Urls } from 'navigation/Urls';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCustomWebsiteItems, selectCustomWebsiteItemStatus,
  selectCustomWebsiteItems,
} from 'services/customWebsiteSlice';
import { selectActiveProductId } from 'services/navigationSlice';
import CustomWebsiteCrmIntegration from './crm-integration/CustomWebsiteCrmIntegration';
import CustomWebsiteLoginPortal from './login-portal/CustomWebsiteLoginPortal';
import CustomWebsiteEcommerce from './ecommerce/CustomWebsiteEcommerce';
import CustomWebsiteScheduling from './scheduling/CustomWebsiteScheduling';
import CustomWebsiteAdditionalFeatures from './additional-features/CustomWebsiteAdditionalFeatures';

const stepsMap = [
  {
    id: 4,
    name: Urls.CrmIntegration,
    component: ((props) => <CustomWebsiteCrmIntegration {...props} />),
    continueTitle: 'form.buttons.continue',
    title: 'pages.website.custom.crmIntegration.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    id: 5,
    name: Urls.LoginPortal,
    component: ((props) => <CustomWebsiteLoginPortal {...props} />),
    continueTitle: 'form.buttons.continue',
    title: 'pages.website.custom.loginPortal.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    id: 6,
    name: Urls.Ecommerce,
    component: ((props) => <CustomWebsiteEcommerce {...props} />),
    continueTitle: 'form.buttons.continue',
    title: 'pages.website.custom.ecommerce.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    id: 7,
    name: Urls.Scheduling,
    component: ((props) => <CustomWebsiteScheduling {...props} />),
    continueTitle: 'form.buttons.continue',
    title: 'pages.website.custom.scheduling.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    id: 11,
    name: Urls.Additional,
    component: ((props) => <CustomWebsiteAdditionalFeatures {...props} />),
    continueTitle: 'form.buttons.continue',
    title: 'pages.website.custom.additional.title',
    hideContinue: false,
    isCompleted: false,
  },
];

export default function CustomWebsiteFeaturesStepper() {
  const dispatch = useDispatch();
  const websiteId = useSelector(selectActiveProductId);
  const customWebsiteItems = useSelector(selectCustomWebsiteItems);
  const customWebsiteItemStatus = useSelector(selectCustomWebsiteItemStatus);

  const commonItemsIncluded = customWebsiteItemStatus !== 'loading' ? [...customWebsiteItems, 11] : [];
  const stepsWithState = useMemo(
    () => stepsMap.filter(item => commonItemsIncluded.find(id => id === item.id)),
    [customWebsiteItems, commonItemsIncluded],
  );

  useEffect(() => {
    dispatch(getCustomWebsiteItems(websiteId));
  }, [websiteId]);
  return (
    (stepsWithState.length > 0)
    && (
    <StepperLayout steps={stepsWithState} formId="custom-website-features" fullWidth
      continueTo={`${Urls.CustomWebsiteDevelopment}/${Urls.Content}`}
    />
    )
  );
}
