import {
  IQFormInput,
  IQFormSelect,
  IQFormTextArea,
  IQTheme, IQThemeV2,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  doesClientHaveAnotherAccountDropdownItems,
  webEventsDropdownItems,
} from "./ProxyProvisioningService";
import {updateOrAddDataBasedOnSourceId, yesNoDropdownItems} from "pages/youtube/YouTubeService";
import GenericCard from "components/generic-card/GenericCard";
import WebEventFormDialog from "pages/youtube/webevents/WebEvent";
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import YouTubeType from "models/YouTubeType";
import { FormProvider, useForm } from "react-hook-form";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { Box } from "@mui/system";
import schema from './ProxyProvisioning.schema';
import NePhoneInput from "components/NePhoneInputContainer/IQPhoneInputContainer";

interface Props {
  product: YouTubeType;
  onSubmit: (data: ProxyProvisioningPageFormProps) => void;
  onBack: () => void;
}

export type ProxyProvisioningPageFormProps = {
  localAreaCodePreference1: string;
  localAreaCodePreference2: string;
  primaryNumberToBeProvisioned: string;
  doesPrimaryBusinessNumberNeedToForward: string;
  alternativeForwardingNumber: string;
  doesWebsiteHaveCaptchaInstalled: string;
  doesClientHaveAnotherAccount: string;
  doNotProxyWebsite: string;
  webEvents: string;
  webEventFields: {
    sourceId: string;
    webEventValue: string;
    webEventUrl: string;
    webEventName: string;
  }[];
};

export default function ProxyProvisioningPageForm({ product, onSubmit, onBack }: Props) {
  const { t } = useTranslation();
  const [doesPrimaryBusinessNumberNeedToForwardSelectedValue, setDoesPrimaryBusinessNumberNeedToForwardSelectedValue] = useState(product.doesPrimaryBusinessNumberNeedToForward || '');
  const [webEventFields, setWebEventFields] = useState<ProxyProvisioningPageFormProps['webEventFields']>(product.webEventFields || []);

  /** Web Events **/
  const [webEventsFieldsFormDialogOpen, setWebEventsFieldsFormDialogOpen] = useState(false);

  const [selectedWebEventFields, setSelectedWebEventEventFields] = useState<ProxyProvisioningPageFormProps['webEventFields']>([]);

  const [webEventsSelectedValue, setWebEventsSelectedValue] = useState(product?.webEvents || '');

  const webEventFieldLabels = ['webEventName', 'webEventValue'];
  const handleWebEventsFieldsFormDialogOpen = () => setWebEventsFieldsFormDialogOpen(true);

  const handleWebEventsFieldsFormDialogClose = () => {
    setWebEventsFieldsFormDialogOpen(false);
    setSelectedWebEventEventFields([]);
  }

  const methods = useForm<ProxyProvisioningPageFormProps>({
    mode: 'onChange',
    defaultValues: {
      localAreaCodePreference1: product?.localAreaCodePreference1 || '',
      localAreaCodePreference2: product?.localAreaCodePreference2 || '',
      primaryNumberToBeProvisioned: product?.primaryNumberToBeProvisioned || '',
      doesPrimaryBusinessNumberNeedToForward: product?.doesPrimaryBusinessNumberNeedToForward || '',
      alternativeForwardingNumber: product?.alternativeForwardingNumber || '',
      doesWebsiteHaveCaptchaInstalled: product?.doesWebsiteHaveCaptchaInstalled || '',
      doesClientHaveAnotherAccount: product?.doesClientHaveAnotherAccount || '',
      doNotProxyWebsite: product?.doNotProxyWebsite || '',
      webEvents: product?.webEvents || '',
      webEventFields: Array.isArray(product?.webEventFields) && product.webEventFields.length > 0
          ? product.webEventFields
          : [{ sourceId: '', webEventValue: '', webEventUrl: '', webEventName: '' }]
    },
  });

  const handleFormSubmit = (data: ProxyProvisioningPageFormProps) => {
    const formData = {...data, webEventFields};
    if (formData.doesPrimaryBusinessNumberNeedToForward === 'No') {
      formData.alternativeForwardingNumber = '';
    }
    if (formData.webEvents === 'Please adjust web events per best practices') {
      formData.webEventFields = [];
    }
    onSubmit(formData);
  }

  const {
    handleSubmit,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  const handleWebEventFieldsSelection = (selectedWebEventField) => {
    setSelectedWebEventEventFields(selectedWebEventField);
    handleWebEventsFieldsFormDialogOpen();
  };

  const updateWebEvents = (newData) => {
    setWebEventFields(prevData => {
      return updateOrAddDataBasedOnSourceId(prevData, newData);
    });
  };

  const onDeleteWebEventFields = async (webEventField: any) => {
    const updatedWebEvents = webEventFields.filter(
        (webEvent) => webEvent.sourceId !== webEventField.sourceId
    );
    setWebEventFields(updatedWebEvents);
  }

  const handleDoesPrimaryBusinessNumberNeedToForwardDropdownChange = (event) => {
    setDoesPrimaryBusinessNumberNeedToForwardSelectedValue(event.target.value);
  };

  const handleWebEventsDropdownChange = (event) => {
    setWebEventsSelectedValue(event.target.value);
  };

  return (
      <DefaultPageLayout
          disableContinue={!isValid}
          onBack={onBack}
          header={t('pages.youtube.proxyProvisioning.header')}
          onContinue={handleSubmit(handleFormSubmit)}
      >
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <Box
                id="mainForm"
                component="form"
                sx={{ display: 'flex' }}
                onSubmit={handleSubmit(handleFormSubmit)}
            >
              <GridContainer fullWidth>
                <GridItem sizes={[12]}>
                  <IQFormInput
                      id="localAreaCodePreference1"
                      theme={IQTheme}
                      labelText={t('pages.youtube.proxyProvisioning.localAreaCodePreference1')}
                      fontLabelWeight="600"
                      name="localAreaCodePreference1"
                      fullWidth
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormInput
                      id="localAreaCodePreference2"
                      theme={IQTheme}
                      labelText={t('pages.youtube.proxyProvisioning.localAreaCodePreference2')}
                      fontLabelWeight="600"
                      name="localAreaCodePreference2"
                      fullWidth
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <NePhoneInput
                      name="primaryNumberToBeProvisioned"
                      defaultValue=""
                      country="US"
                      hasExtension={false}
                      phoneLabelOptions={{
                        tooltipText: '',
                        labelText:t('pages.youtube.proxyProvisioning.primaryNumberToBeProvisioned'),
                        hasError: true,
                        theme: IQThemeV2,
                        required: true,
                      }}
                      hasType={false}
                      countryCallingCodeEditable={false}
                      international
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormSelect
                      id="doesPrimaryBusinessNumberNeedToForward"
                      name="doesPrimaryBusinessNumberNeedToForward"
                      labelText={t('pages.youtube.proxyProvisioning.doesPrimaryBusinessNumberNeedToForward')}
                      items={yesNoDropdownItems}
                      defaultValue=""
                      theme={IQTheme}
                      onChange={handleDoesPrimaryBusinessNumberNeedToForwardDropdownChange}
                      fontLabelWeight="600"
                      fullWidth
                      showError
                      schema={schema}
                  />
                </GridItem>
                {doesPrimaryBusinessNumberNeedToForwardSelectedValue === 'Yes' && (
                    <GridItem sizes={[12]}>
                      <NePhoneInput
                          name="alternativeForwardingNumber"
                          defaultValue=""
                          country="US"
                          hasExtension={false}
                          phoneLabelOptions={{
                            tooltipText: '',
                            labelText:t('pages.youtube.proxyProvisioning.alternativeForwardingNumber'),
                            hasError: true,
                            theme: IQThemeV2,
                            required: false,
                          }}
                          hasType={false}
                          countryCallingCodeEditable={false}
                          international
                      />
                    </GridItem>
                )}
                <GridItem sizes={[12]}>
                  <IQFormSelect
                      id="doesWebsiteHaveCaptchaInstalled"
                      name="doesWebsiteHaveCaptchaInstalled"
                      labelText={t('pages.youtube.proxyProvisioning.doesWebsiteHaveCaptchaInstalled')}
                      items={yesNoDropdownItems}
                      defaultValue=""
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      showError
                      schema={schema}
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormSelect
                      id="doesClientHaveAnotherAccount"
                      name="doesClientHaveAnotherAccount"
                      labelText={t('pages.youtube.proxyProvisioning.doesClientHaveAnotherAccount')}
                      items={doesClientHaveAnotherAccountDropdownItems}
                      defaultValue=""
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      showError
                      schema={schema}
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormTextArea
                      id="doNotProxyWebsite"
                      labelText={t('pages.youtube.proxyProvisioning.doNotProxyWebsite')}
                      name="doNotProxyWebsite"
                      rowCount={2}
                      fullWidth
                      fontLabelWeight="600"
                      showError
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormSelect
                      id="webEvents"
                      name="webEvents"
                      labelText={t('pages.youtube.proxyProvisioning.webEvents')}
                      items={webEventsDropdownItems}
                      defaultValue=""
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      schema={schema}
                      onChange={handleWebEventsDropdownChange}
                  />
                </GridItem>
                {webEventsSelectedValue === 'Use only my web events' && (
                    <GridItem sizes={[12]}>
                      {Array.isArray(webEventFields) && webEventFields.map((webEventField) => (
                          <GenericCard
                              key={webEventField.sourceId}
                              labels={webEventFieldLabels}
                              data={webEventField}
                              handleDelete={() => onDeleteWebEventFields(webEventField)}
                              onEdit={() => handleWebEventFieldsSelection(webEventField)}
                              onSelect={() => { }}
                          />
                      ))}
                      <WebEventFormDialog
                          open={webEventsFieldsFormDialogOpen}
                          onOpen={handleWebEventsFieldsFormDialogOpen}
                          onClose={handleWebEventsFieldsFormDialogClose}
                          updateWebEvents={updateWebEvents}
                          selectedWebEvents={selectedWebEventFields}
                      />
                    </GridItem>
                )}
              </GridContainer>
            </Box>
          </FormProvider>
        </ValidationProvider>
        <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </DefaultPageLayout>
  );
}
