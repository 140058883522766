import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";
import HeadingTextLabel from "pages/generic-product/details/custom-fields/HeadingTextLabel";

const {t} = i18n;

export const DisplayAndNativeCreativesSchema: RJSFSchema | any = (data) => ({
  title: t('pages.multiMediaCreatives.displayAndNativeCreatives.title'),
  type: 'object',
  required: ['targetedDisplayAdsAreStaticOrAnimated', 'clickThroughUrl', 'adsMainMessageToConvey', 'typeOfImageryOfNativeCreative'],
  properties: {
    targetedDisplayAdsAreStaticOrAnimated: {
      type: 'string',
      title: t('pages.multiMediaCreatives.displayAndNativeCreatives.targetedDisplayAdsAreStaticOrAnimated'),
      default: data?.targetedDisplayAdsAreStaticOrAnimated || '',
      enum: [
        t('pages.multiMediaCreatives.displayAndNativeCreatives.standardStatic'),
        t('pages.multiMediaCreatives.displayAndNativeCreatives.standardAnimated'),
      ],
    },
    clickThroughUrl: {
      type: 'string',
      title: t('pages.multiMediaCreatives.displayAndNativeCreatives.clickThroughUrl'),
      default: data?.clickThroughUrl || '',
    },
    adsMainMessageToConvey: {
      type: 'string',
      title: t('pages.multiMediaCreatives.displayAndNativeCreatives.adsMainMessageToConvey'),
      default: data?.adsMainMessageToConvey || '',
    },
    typeOfImageryOfNativeCreative: {
      type: 'string',
      title: t('pages.multiMediaCreatives.displayAndNativeCreatives.typeOfImageryOfNativeCreative'),
      default: data?.typeOfImageryOfNativeCreative || '',
    },
  },
});

const urlFieldKeys = [
  'clickThroughUrl',
];

const textFieldKeys = [
  'adsMainMessageToConvey',
  'typeOfImageryOfNativeCreative',
];

const urlFields = urlFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'UrlTextWidget'}
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

export const DisplayAndNativeCreativesUISchema = {
  'ui:order': [
    'targetedDisplayAdsAreStaticOrAnimated',
    'clickThroughUrl',
    'adsMainMessageToConvey',
    'typeOfImageryOfNativeCreative',
  ],
  ...urlFields,
  ...textFields,
  targetedDisplayAdsAreStaticOrAnimated: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.multiMediaCreatives.displayAndNativeCreatives.targetedDisplayAdsAreStaticOrAnimatedErrorMsg')}/>
  }
};

export const DisplayAndNativeCreativesCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.clickThroughUrl === '') {
    errors.clickThroughUrl.addError(t('pages.multiMediaCreatives.displayAndNativeCreatives.clickThroughUrlErrorMsg'));
    errors.addError(true);
  }
  if (formData?.adsMainMessageToConvey === '') {
    errors.adsMainMessageToConvey.addError(t('pages.multiMediaCreatives.displayAndNativeCreatives.adsMainMessageToConveyErrorMsg'));
    errors.addError(true);
  }
  if (formData?.typeOfImageryOfNativeCreative === '') {
    errors.typeOfImageryOfNativeCreative.addError(t('pages.multiMediaCreatives.displayAndNativeCreatives.typeOfImageryOfNativeCreativeErrorMsg'));
    errors.addError(true);
  }
  return errors;
};
