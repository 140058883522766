/* eslint-disable max-len */
import i18n from 'i18n/i18n';

import {RJSFSchema} from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import {Grid, Typography} from "@mui/material";
import React from "react";

const {t} = i18n;

export const CampaignDetailsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.campaignDetails.title'),
  type: 'object',
  required: [],
  properties: {
    localIqAccessToClientCmsDetails: {
      type: 'string',
      title: t('pages.ausProduct.campaignDetails.localIqAccessToClientCmsDetails'),
      default: data?.localIqAccessToClientCmsDetails || '',
      enum: [
        '',
        t('pages.ausProduct.campaignDetails.reseller'),
        t('pages.ausProduct.campaignDetails.directShopifyCms'),
        t('pages.ausProduct.campaignDetails.directViaForm'),
      ],
    },
    unsupportedCms: {
      type: 'string',
      title: t('pages.ausProduct.campaignDetails.unsupportedCms'),
      default: data?.unsupportedCms || '',
      enum: [
        '',
        t('pages.ausProduct.campaignDetails.yesRequest'),
        t('pages.generic-translation.noOption'),
      ],
    },
    additionalTrackingCodeImplementedToWebsite: {
      type: 'string',
      title: t('pages.ausProduct.campaignDetails.additionalTrackingCodeImplementedToWebsite'),
      default: data?.additionalTrackingCodeImplementedToWebsite || '',
      enum: [
        '',
        t('pages.ausProduct.campaignDetails.clientWebHardCode'),
        t('pages.ausProduct.campaignDetails.clientWebGTM'),
        t('pages.ausProduct.campaignDetails.reachLocalGTM'),
        t('pages.ausProduct.campaignDetails.reachLocalGTMAccount'),
      ],
      description: t('pages.ausProduct.campaignDetails.outsideOfReachCodeHelpText')
    },
    productCategoriesToFocusOn: {
      type: 'string',
      title: t('pages.ausProduct.campaignDetails.productCategoriesToFocusOn'),
      default: data?.productCategoriesToFocusOn || '',
    },
    productCategoriesToNotAdvertise: {
      type: 'string',
      title: t('pages.ausProduct.campaignDetails.productCategoriesToNotAdvertise'),
      default: data?.productCategoriesToNotAdvertise || '',
      description: t('pages.ausProduct.campaignDetails.impactHelpText')
    },
    currentTargetAudience: {
      type: 'string',
      title: t('pages.ausProduct.campaignDetails.currentTargetAudience'),
      default: data?.currentTargetAudience || '',
    },
    googleShoppingCampaignRunning: {
      type: 'string',
      title: t('pages.ausProduct.campaignDetails.googleShoppingCampaignRunning'),
      default: data?.googleShoppingCampaignRunning || '',
      enum: [
        '',
        t('pages.generic-translation.yesOption'),
        t('pages.generic-translation.noOption'),
      ],
    }
  },
});

const textFieldKeys = [
  'productCategoriesToFocusOn',
  'productCategoriesToNotAdvertise',
  'currentTargetAudience'
];

const dropdownFieldKeys = [
  'localIqAccessToClientCmsDetails',
  'unsupportedCms',
  'additionalTrackingCodeImplementedToWebsite',
  'googleShoppingCampaignRunning',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

export const CampaignDetailsUISchema = {
  'ui:order': ['localIqAccessToClientCmsDetails', 'unsupportedCms', 'additionalTrackingCodeImplementedToWebsite', 'productCategoriesToFocusOn',
  'productCategoriesToNotAdvertise', 'currentTargetAudience', 'googleShoppingCampaignRunning'],
  ...textFields,
  ...dropdownFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CampaignDetailsCustomValidate = (formData: any, errors: any, uiSchema: any) => errors;
