import { Typography, Box, Link } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { Urls } from 'navigation/Urls';
import { setFiles } from 'services/fileUploaderSlice';
import { useDispatch } from 'react-redux';
import { headingStyle } from '../Business/BusinessSection';

export const renderContent = (website, existingContent, contentTones, sitePages, product, navigate, isEditable) => {
  const dispatch = useDispatch();

  return (
    <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Content</Typography>

      <Box>
        {/* New Contet */}
        <Box sx={{ display: 'flex', marginBottom: '10px' }}>
          {editButton(isEditable, () => navigate.to(
            Urls.ContentWriting,
            product.id,
          ))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>New Content</Typography>
        </Box>
        <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">New Page Content</Typography>
              <Typography>{website?.newContentWritingRequired ? 'Yes' : 'No'}</Typography>
            </Box>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Content Tones</Typography>
              {contentTones?.length > 0
                ? (contentTones?.map(opt => (
                  <Typography key={opt.name} style={{ textTransform: 'capitalize' }}>{opt.name}</Typography>
                ))) : (<Typography fontWeight="bold">No Content Tones</Typography>)}
            </Box>
          </Box>
        </GridItem>

        {/* Existing Content */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, async () => {
            await dispatch(setFiles([]));
            navigate.to(
              Urls.ExistingContent,
              product.id,
            );
          })}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Existing Content</Typography>
        </Box>
        <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Port Content</Typography>
              <Typography>{website?.portContentExistingWebsite ? 'Yes' : 'No'}</Typography>
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">URLs to Migrate</Typography>
              {website?.urlsToMigrate?.length
                ? <Typography>{website?.urlsToMigrate}</Typography>
                : <Typography fontWeight="bold">No URLs to Migrate</Typography>}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Number of Pages</Typography>
              {displayNotRequiredField('pages', website?.numberOfPages)}
            </Box>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">URls to Remove</Typography>
              {website?.urlsToRemove?.length
                ? <Typography>{website?.urlsToRemove}</Typography>
                : <Typography fontWeight="bold">No URLs to Remove</Typography>}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Rewrite Existing Website Content</Typography>
              {website?.rewriteExistingContent
                ? <Typography style={{ textTransform: 'capitalize' }}>{website?.rewriteExistingContent}</Typography>
                : <Typography fontWeight="bold">No selection provided</Typography>}
            </Box>
            <Box>
              <Typography sx={headingStyle} color="primary">Assets</Typography>
              {existingContent?.length
                ? existingContent.map((asset, index) => (
                  <Link href={asset.url} target="_blank" rel="noopener noreferrer">
                    <Typography>{`Existing Asset ${index + 1}`}</Typography>
                  </Link>
                ))
                : <Typography fontWeight="bold">No Existing Assets</Typography>}
            </Box>
          </Box>
        </GridItem>

        {/* Age Gate */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () => navigate.to(
            Urls.WebsiteAgeGate,
            product.id,
          ))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Age Gate</Typography>
        </Box>
        <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Content related to specified list</Typography>
              {website?.ageGateContentRelated
                ? <Typography style={{ textTransform: 'capitalize' }}>{website?.ageGateContentRelated}</Typography>
                : <Typography fontWeight="bold">No selection provided</Typography>}
            </Box>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Paid advertising campaigns</Typography>
              {website?.ageGatePaidAdvertising
                ? <Typography style={{ textTransform: 'capitalize' }}>{website?.ageGatePaidAdvertising}</Typography>
                : <Typography fontWeight="bold">No selection provided</Typography>}
            </Box>
          </Box>
        </GridItem>

        {/* Additional Features */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () => navigate.to(
            Urls.AdditionalFeaturesContent,
            product.id,
          ))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Additional Features</Typography>
        </Box>
        <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Additional Features</Typography>
              {website?.testimonial
                ? <Typography>Include testimonials</Typography>
                : <Typography fontWeight="bold">No testimonials</Typography>}
              {website?.blog
                ? <Typography>Include blog</Typography>
                : <Typography fontWeight="bold">No blog</Typography>}
            </Box>
          </Box>
          <Box />
        </GridItem>

        {/* Pages */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () => navigate.to(
            Urls.SitePages,
            product.id,
          ))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Pages</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {sitePages?.map((page, index) => (
            <Box mb={2} key={page.id} sx={{ width: '45%', paddingRight: '5%' }}>
              <Typography sx={headingStyle} color="primary">{`Page ${index + 1}`}</Typography>
              <Typography mb={1}>{`Page Name: ${page?.pageName}`}</Typography>
              {page?.targetMessaging && <Typography mb={1}>{`Target Messaging:\n${page.targetMessaging}`}</Typography>}
              {page?.otherNotes && <Typography mb={1}>{`Other Notes:\n${page.otherNotes}`}</Typography>}
            </Box>
          ))}
          <Box mb={2} sx={{ width: '45%', paddingRight: '5%' }}>
            <Typography sx={headingStyle} color="primary">Additional notes</Typography>
            {displayNotRequiredField('additional notes', website?.sitePagesNotes)}
          </Box>
        </Box>
      </Box>
    </GridItem>
  );
};
