/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import usaIcon from 'icons/countries/usa.png';
import cndIcon from 'icons/countries/cnd.png';
import audIcon from 'icons/countries/aud.png';
import nzlIcon from 'icons/countries/nzl.png';
import { RootState } from './redux/store';

export const allCountries = [
  {
    id: 1,
    name: 'USA',
    title: 'United States',
    icon: usaIcon,
  },
  {
    id: 2,
    name: 'CAN',
    title: 'Canada',
    icon: cndIcon,
  },
  {
    id: 3,
    name: 'AUS',
    title: 'Australia',
    icon: audIcon,
  },
  {
    id: 4,
    name: 'NZL',
    title: 'New Zealand',
    icon: nzlIcon,
  },
];

export interface Country {
  id: number
  name: string
  title: string
  icon: string
}

export interface TopNavMenuState {
  countries: StatusType<Country[]>
  currentCountry: StatusType<Country>
  currentProduct: StatusType<any>
}

const initialState: TopNavMenuState = {
  countries: {
    status: 'idle',
    message: '',
    content: allCountries,
  },
  currentCountry: {
    status: 'idle',
    message: '',
    content: allCountries[0],
  },
  currentProduct: {
    status: 'idle',
    message: '',
    content: {},
  },
};

export const setCountry = createAction<any>('topNavMenu/country/set');
export const setProduct = createAction<any>('topNavMenu/product/set');

export const topNavMenuSlice = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setCountry, (state, action) => {
        state.currentCountry.content = action.payload;
      })
      .addCase(setProduct, (state, action) => {
        state.currentProduct.content = action.payload;
      });
  },
);

export const selectCountries = (state: RootState) => state.topNavMenu.countries.content;
export const selectCurrentCountry = (state: RootState) => state.topNavMenu.currentCountry.content;
export const selectCurrentProduct = (state: RootState) => state.topNavMenu.currentProduct.content;

export default topNavMenuSlice;
