import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { Grid } from '@mui/material';

interface Props {
  status: string | string[],
  message?: string | string[],
  children?: any,
  showLoadingSpinner?: boolean,
}

const isLoading = (status :string | string[]) => {
  if (status === 'loading' || status === 'notStartedLoading') {
    return true;
  }

  if (status instanceof Array && (status.includes('loading') || status.includes('notStartedLoading'))) {
    return true;
  }

  return false;
};

function ContentLoader({
  // Keeping compatibility
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  status, message, children, showLoadingSpinner = true,
}: Props) {
  if (isLoading(status)) {
    if (showLoadingSpinner) {
      return (
        <Grid container justifyContent="center" alignItems="center"
          style={{ height: '80vh' }}
        >
          <Grid item>
            <IQLoadingSpinner />
          </Grid>
        </Grid>

      );
    }
    return null;
  }
  // if (status === 'failed' || (status instanceof Array && status.includes('failed'))) {
  //   navigate.to(Urls.UnexpectedError);
  // }

  return (
    <Grid item xs={12}>
      {children}
    </Grid>
  );
}

export default ContentLoader;
