/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import { RootState } from './redux/store';

export interface GenericFormState {
  activeStep: StatusType<any>
  files: StatusType<any>
}

const initialState: GenericFormState = {
  activeStep: {
    status: 'idle',
    message: '',
    content: 0,
  },
  files: {
    status: 'idle',
    message: '',
    content: {},
  },
};

export const setActiveStep = createAction<number>('GenericForm/setActiveStep');
export const setFiles = createAction<any>('GenericForm/setFiles');

export const GenericFormStateSlice = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setActiveStep, (state, { payload }) => {
        state.activeStep.content = payload;
      })
      .addCase(setFiles, (state, { payload }) => {
        state.files.content = payload;
      });
  },
);

export const selectGenericStepperActiveStep = (state: RootState) => state.genericForm.activeStep.content;
export const selectGenericFormFiles = (state: RootState) => state.genericForm.files.content;

export default GenericFormStateSlice;
