import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";

const {t} = i18n;

export const TrackingSectionSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.trackingSection.title'),
  type: 'object',
  required: ['happyForCorrectFormattedUtmString'],
  properties: {
    isReachcodeBeingUsed: {
      type: 'string',
      title: t('pages.ausProduct.trackingSection.isReachcodeBeingUsed'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
    },
    happyForCorrectFormattedUtmString: {
      type: 'string',
      title: t('pages.ausProduct.trackingSection.happyForCorrectFormattedUtmString'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
    },
  },
});

const dropdownFieldKeys = [
  'isReachcodeBeingUsed',
  'happyForCorrectFormattedUtmString'
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.tracking.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

export const TrackingSectionUISchema = {
  'ui:order': [
    'isReachcodeBeingUsed',
    'happyForCorrectFormattedUtmString'
  ],
  ...dropdownFields,
};

export const TrackingSectionCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.happyForCorrectFormattedUtmString === '') {
    errors.happyForCorrectFormattedUtmString.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
