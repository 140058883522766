/* eslint-disable import/no-extraneous-dependencies */
import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ObjectFieldTemplateProps } from '@rjsf/utils';
import { GridDescription } from 'layouts/DefaultPageLayout';
import { inputStyles } from 'pages/seo/details/DetailsPageForm';
import { Trans } from 'react-i18next';

export function ObjectFieldTemplate(props: ObjectFieldTemplateProps) {
  return (
    <Box sx={{ ...inputStyles }}>
      <Box mb={4}>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom component="div"
            sx={{ color: 'common.black', marginTop: '48px' }}
          >
            {props.title}
          </Typography>
        </Grid>
        {props.description && (
        <GridDescription item xs={12} theme={IQThemeV2}>
          <Trans
            i18nKey={props.description}
            components={{ br: <br /> }}
          />
        </GridDescription>
        )}
      </Box>
      {props.properties.map((element) => (
        <Box key={element.name} className="property-wrapper">{element.content}</Box>
      ))}
    </Box>
  );
}
