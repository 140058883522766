import * as yup from 'yup';
import i18n from 'i18n/i18n';

const { t } = i18n;

const fbUrlRegex = /^(?:(?:http|https):\/\/)?(www\.)?(facebook.com|fb.me|fb.com)\/.+/;
const fbErrorMsg = t('pages.smm.details.facebookUrlErrorMessage');
const instaUrlRegex = /^(?:(?:http|https):\/\/)?(www\.)?(instagr.co|instagram.com)\/.+/;
const instaErrorMsg = t('pages.smm.details.instagramUrlErrorMessage');
const xUrlRegex = /^(?:(?:http|https):\/\/)?(www\.)?(twitter.com|t.co|x.com)\/.+/;
const xErrorMsg = t('pages.smm.details.xUrlErrorMessage');
const gbpUrlRegex = /^(?:(?:http|https):\/\/)?(www\.)?(google.com|goo.gl|g.page)\/.+/;
const gbpErrorMsg = t('pages.smm.details.gbpUrlErrorMessage');
const yelpUrlRegex = /^(?:(?:http|https):\/\/)?(www\.)?(yelp.com\/biz)\/.+/;
const yelpErrorMsg = t('pages.smm.details.yelpUrlErrorMessage');
const httpErrorMsg = t('pages.smm.details.httpErrorMessage');

const schema = {
  campaign: yup.string().required(),
  existingFacebook: yup.string().required(),
  facebookUrl: yup.string().url(httpErrorMsg).matches(fbUrlRegex, fbErrorMsg).required(),
  existingInstagram: yup.string().required(),
  instagramUrl: yup.string().url(httpErrorMsg).matches(instaUrlRegex, instaErrorMsg).required(),
  existingX: yup.string().required(),
  xUrl: yup.string().url(httpErrorMsg).matches(xUrlRegex, xErrorMsg).required(),
  isRepManagementNeeded: yup.string().required(),
  reputationManagement: yup.string().required(),
  gbpUrl: yup.string().url(httpErrorMsg).matches(gbpUrlRegex, gbpErrorMsg).required(),
  yelpUrl: yup.string().url(httpErrorMsg).matches(yelpUrlRegex, yelpErrorMsg).required(),
  campaignContact: yup.string().required(),
  campaignContactName: yup.string().required(),
  campaignContactEmail: yup.string().email('Please enter a valid email in this format: name@example.com').required(),
  campaignContactPhone: yup.string().optional(),
  campaignContactPhoneExtension: yup.string().optional(),
  accessContact: yup.string().required(),
  accessContactName: yup.string().required(),
  accessContactEmail: yup.string().email('Please enter a valid email in this format: name@example.com').required(),
  accessContactPhone: yup.string().optional(),
  accessContactPhoneExtension: yup.string().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
