import {
  Box, Grid, Typography,
} from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { editButton } from 'pages/overview/Overview';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import OrderTracker from 'pages/orders/OrderTracker';
import { GeneralQuestionsSection } from '../generalQuestions/GeneralQuestionsSection';
import { ActionItems } from '../common/ActionItems';

export default function PremiumListingSection({
  product, isEditable, showTracker, openBusinessAndSalesAgent,
}: any) {
  const navigate = useNavigationHandler();

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Status Tracker */}
      {showTracker && (
        <GridItem sizes={[12, 12]} marginBottom={8} sx={{ padding: '40px 0 0', borderBottom: '1px solid #000000' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {editButton(isEditable, () => {})}
            <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Status</Typography>
          </Box>
          <Box>
            <OrderTracker
              key={product?.id}
              status={product?.trackerStatus}
              productName={product?.offeringType !== 'ProductsData' ? product.offeringType : product?.pdOfferingType}
              product={product}
            />
          </Box>
        </GridItem>
      )}

      {/* General Questions */}
      {GeneralQuestionsSection(product.offeringType, product.offeringId, product.id, navigate, isEditable)}

      {/* Actions items */}
      {showTracker
        && ActionItems(false, product?.id, product?.trackerStatus !== 'Completed', openBusinessAndSalesAgent)}
    </Grid>
  );
}
