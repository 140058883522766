import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  clearAdditionalFeaturesContentCompleted, getWebsite,
  putWebsiteAdditionalFeaturesBlog,
  selectAdditionalFeaturesContentCompleted,
  selectWebsites,
} from 'services/websitesSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { Urls } from 'navigation/Urls';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import AdditionalFeaturesContentForm from './AdditionalFeaturesContentForm';

export default function AdditionalFeaturesContent() {
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const history: any = useLocation();
  const dispatch = useDispatch();

  const website = useSelector(selectWebsites);
  const pageCompleted = useSelector(selectAdditionalFeaturesContentCompleted);

  const navigate = useNavigationHandler();
  const topNav = useTopnavMenu(Urls.AdditionalFeaturesContent);

  const onSubmit = (content) => {
    dispatch(putWebsiteAdditionalFeaturesBlog({ websiteId, content, offeringType }));
  };

  const onBack = () => {
    navigate.to(topNav.previousStep());
  };

  // after we receive the completed flag from redux, we move to the next page and clear the flag
  useEffect(() => {
    if (pageCompleted) {
      if (history.state?.previousPath.includes('overview')) {
        navigate.to(Urls.Overview);
      } else navigate.to(topNav.nextStep());
      dispatch(clearAdditionalFeaturesContentCompleted());
    }
  }, [pageCompleted]);

  useEffect(() => {
    if (!website?.content?.id) dispatch(getWebsite({ websiteId, offeringType }));
  }, [websiteId]);

  return (
    <ContentLoader status={website.status} message={website.message}>
      <AdditionalFeaturesContentForm onSubmit={onSubmit} onBack={onBack} website={website.content} />
    </ContentLoader>
  );
}
