import * as yup from 'yup';
import i18n from 'i18n/i18n';

const { t } = i18n;

const schema = {
  businessFriendlyName: yup.string().required(),
  websiteUrl: yup.string().url().required(),
  einAvailable: yup.string().optional(),
  ein: yup.string().required(),
  businessIndustry: yup.string().required(),
  operationType: yup.string().required(),
  businessType: yup.string().required(),
  addressLine1: yup.string().required(t('pages.business.location.address.addressLine1Required')).nullable(),
  addressLine2: yup.string().notRequired().nullable(),
  city: yup.string().required(t('pages.business.location.address.cityRequired')).nullable(),
  stateProvince: yup.string().required(t('pages.business.location.address.stateRequired')).nullable(),
  postalCode: yup.string().required(t('pages.business.location.address.zipCodeRequired')).nullable(),
  country: yup.string().required(t('pages.business.location.address.countryRequired')).nullable(),
  primaryContactName: yup.string().required(),
  jobTitle: yup.string().required(),
  primaryContactEmail: yup.string().email().required(),
  primaryContactPhone: yup.string().required(),
  primaryContactPhoneExt: yup.string().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
