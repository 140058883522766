import { FormProvider, useForm } from 'react-hook-form';
import {
  IQAddressMapbox,
  IQFormInput,
  IQFormSelect,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { createStyles, makeStyles } from '@mui/styles';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import useMapboxService from 'services/MapboxService';
import EcommerceType from 'models/EcommerceType';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import schema from './Contact.schema';
import AdditionalContactForm from './AdditionalContactForm';
import VerificationModal from './VerificationModal';

interface Props {
  product: EcommerceType
  onSubmit: (data) => any
  onBack: () => any
}

const FormSelectStyled = styled('div')`
  label {
    font-weight: 600;
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    selectMenu: {
      left: '-11px',
      '&& .Mui-disabled': {
        padding: 0,
      },
      '&& .Mui-selected': {
        backgroundColor: '#B9E1FF',
      },
    },
    menuList: {
      minWidth: '218px',
      padding: 0,
      border: '1px solid #1665CF',
    },
  }));

export default function ContactForm(props: Props) {
  const { product } = props;
  const pointsOfContact = product?.pointsOfContact ?? {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    emails: [],
    phones: [],
  };
  const [addresses, setAddresses] = useState([]);
  const [openVerificationModal, setOpenVerificationModal] = useState(false);

  const styles = useStyles();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      ...pointsOfContact,
    },
  });

  const {
    setValue, register, getValues, handleSubmit, watch, formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful,
    },
  } = methods;

  const country = watch('country');
  const emails = watch('emails');
  const phones = watch('phones');

  const { getAddresses, countryItems, twoLettersCountryEquivalent } = useMapboxService();

  const onSubmit = async () => {
    setOpenVerificationModal(true);
  };

  const autoCompleteAddress = (address) => {
    setValue('addressLine1', address.address);
    setValue('state', address.region);
    setValue('city', address.place);
    setValue('zipcode', address.postalCode);
    setValue('country', twoLettersCountryEquivalent[address.country]);
  };

  const isContactEmpty = (emails.length === 0) && (phones.length === 0);

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <DefaultPageLayout
          disableContinue={!isValid || isContactEmpty}
          onBack={props.onBack}
          onContinue={handleSubmit(onSubmit)}
          header="Points of Contact" description="Publicly listed points of contact are required
          to operate an online store. One must be a physical contact address and at least
          one additional point of contact."
        >
          <GridContainer>
            <GridItem>
              <IQAddressMapbox
                getAddressOptions={async (e) => {
                  const address = await getAddresses(e.query, country);
                  setAddresses(address.data?.suggestions);
                }}
                items={addresses}
                labelText="Address Line 1 "
                onAutoCompleteSelected={autoCompleteAddress}
                name="addressLine1"
                onAddressChange={() => {}}
                schema={schema}
              />
            </GridItem>
            <GridItem>
              <IQFormInput
                id="addressLine2"
                name="addressLine2"
                labelText="Address Line 2"
                theme={IQTheme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
            </GridItem>
            <GridItem inlineItems>
              <IQFormInput
                id="city"
                name="city"
                labelText="City *"
                theme={IQTheme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
              <IQFormInput
                id="state"
                name="state"
                labelText="State / Region / Province *"
                theme={IQTheme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
            </GridItem>
            <GridItem inlineItems>
              <IQFormInput
                id="zipcode"
                name="zipcode"
                labelText="Zip / Post Code *"
                theme={IQTheme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
              <FormSelectStyled>
                <IQFormSelect
                  id="country"
                  name="country"
                  labelText="Country *"
                  items={countryItems}
                  defaultValue="US"
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                  MenuProps={{
                    classes: {
                      root: styles.selectMenu, list: styles.menuList,
                    },
                  }}
                />
              </FormSelectStyled>
            </GridItem>
            <GridItem>
              <AdditionalContactForm
                register={register}
              />
            </GridItem>
          </GridContainer>
          <VerificationModal
            openModal={openVerificationModal}
            setOpenModal={setOpenVerificationModal}
            onFormSubmit={props.onSubmit}
            formData={getValues()}
          />
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </DefaultPageLayout>
      </FormProvider>
    </ValidationProvider>
  );
}
