import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Grid, useTheme } from '@mui/material';
import {
  IQContentToneContainer,
  IQYesNoFormRadioButtons,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';

import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { S1 } from 'components/labels/S1';
import { H4 } from 'components/labels/H4';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import LandingPageType from 'models/LandingPageType';
import { useEffect } from 'react';
import schema from './LandingContentWriting.schema';

const contentToneListValue = [
  {
    name: 'persuasive',
    label: 'Persuasive',
    desc:
      'Tries to pitch new products and services or get people to take some sort of action '
      + '(charitable organizations often use this to rally people behind their cause).',
  },
  {
    name: 'informative',
    label: 'Informative',
    desc:
      'Either about their company/products or their industry as a whole '
      + '(lawyers, doctors and dentists usually take this approach).',
  },
  {
    name: 'friendly',
    label: 'Friendly & Laid Back',
    desc:
      'Tries to sound engaging and personable to attract new customers '
      + '(might use more informal/casual language or local colloquialisms/slang throughout the site).',
  },
  {
    name: 'credible',
    label: 'Credible & Honest',
    desc:
      'Tries to establish or develop trust with their customer base '
      + '(might talk about ways to find a reputable business in their industry or problems they see with competitors '
      + 'or provide examples of how they provide honest services for their customers).',
  },
  {
    name: 'technical',
    label: 'B2B & Technical',
    desc:
      'Speaking to other businesses who may be clients (usually uses more tech '
      + 'speak and jargon commonly found in their industry that outsiders may not understand).',
  },
  {
    name: 'corporate',
    label: 'Corporate',
    desc:
      'Tries to sound professional and knowledgeable like they’re experts or '
      + 'industry leaders in something.',
  },
  {
    name: 'formal',
    label: 'Formal & Professional',
    desc:
      'Similar to corporate but maybe on a smaller scale '
      + '(watch contractions).',
  },
  {
    name: 'sarcastic',
    label: 'Witty & Sarcastic',
    desc:
      'Not used very often, but can be powerful with branding or '
      + 'trying to sound cool or edgy over your competition, especially if you’re in a niche market.',
  },
  {
    name: 'interactive',
    label: 'Interactive',
    desc:
      'Tries to interact with their customers/community as much as possible '
      + '(might have a very active blog or social media account).',
  },
  {
    name: 'nurturing',
    label: 'Nurturing',
    desc:
      'Tries to be compassionate/sensitive to their customers’ needs and plays '
      + 'to their emotions that they may be experiencing (divorce lawyers, funeral homes, debt lawyers, etc.).',
  },
];

const boldFontWeight = '600';

interface Props {
  product: LandingPageType;
  onBack: () => void;
  onSubmit: (data: LandingPageType) => void;
}

type ContentWritingFormProps = {
  contentTonesSelection: 'yes' | 'no' | null;
  contentTones: string[];
};

const formatContentTonesSelection = (selection: boolean | null) => {
  if (typeof selection !== 'boolean') return null;
  if (selection) return 'yes';
  return 'no';
};

/*
  TODO: Remove rest of hard-coded styles and check why stay on page modal is not showing
*/

export default function LandingContentWritingForm(props: Props) {
  const { product } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  /* Yes and No are uppercase in the localization JSON, but are lowercase as form values */
  const yes = t('form.big-box-labels.yes').toLowerCase();

  const methods = useForm<ContentWritingFormProps>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      contentTonesSelection: formatContentTonesSelection(product?.contentTonesSelection),
      contentTones: product?.contentTones || [],
    },
  });

  const {
    handleSubmit,
    watch,
    setValue,
    unregister,
    formState: {
      isValid, isSubmitSuccessful, isSubmitting, isDirty,
    },
  } = methods;

  const { contentTonesSelection } = watch();

  const onSubmit = (data: ContentWritingFormProps) => {
    const dataToSubmit = { ...data, contentTonesSelection: data.contentTonesSelection === 'yes' };
    props.onSubmit(dataToSubmit);
  };

  useEffect(() => {
    if (contentTonesSelection === 'no') {
      setValue('contentTones', []);
      unregister('contentTones');
    }
  }, [contentTonesSelection]);

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={props.onBack}
      onContinue={handleSubmit(onSubmit)}
      header={t('pages.contentWriting.title')}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container spacing={0}>
            <Grid item xs={12}
              sx={{
                '.MuiFormGroup-root': { pl: '5px' },
                '.MuiRadio-root.Mui-checked': { color: theme.palette.primary.main },
              }}
            >
              <IQYesNoFormRadioButtons
                title={t('pages.contentWriting.radioGroupTitle')}
                field="contentTonesSelection"
                fontTitleWeight={boldFontWeight}
              />
            </Grid>
            {contentTonesSelection === yes && (
            <>
              <Grid item xs={12} sx={{ marginTop: '40px' }}>
                <H4
                  sx={{
                    fontSize: theme.typography.h5,
                    fontWeight: theme.typography.fontWeightMedium,
                  }}
                >
                  {t('pages.contentWriting.preferred')}
                    &nbsp;*
                </H4>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '9px' }}>
                <S1
                  sx={{
                    fontSize: '18px',
                    fontWeight: theme.typography.fontWeightRegular,
                  }}
                >
                  {t('pages.contentWriting.selectUp2')}
                </S1>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '16px' }}>
                <IQContentToneContainer
                  name="contentTones"
                  tonesList={contentToneListValue}
                  disabled={false}
                />
              </Grid>
            </>
            )}
          </Grid>
          {/* show the dialog if we changed the field and didn't submit the changes */}
          <CoNavigationConfirm
            showDialog={
            isDirty && !(isSubmitSuccessful || isSubmitting)
          }
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
