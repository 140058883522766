/* eslint-disable max-len */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';

const { t } = i18n;
const urlValidationParameter = ['https://facebook.com', 'https://fb.me', 'https://fb.com', 'https://www.facebook.com'];
export const SocialAdsWebClickSchema: RJSFSchema | any = (data) => ({
  title: t('pages.socialAds.socialAdsWebClicksSection.SocialAdsWebsiteTitle'),
  type: 'object',
  required: ['facebookProfileUrl', 'landingPageUrl', 'callToActionButton', 'clientWantToFocus',
    'isThisFlightedCampaign'],
  properties: {
    facebookProfileUrl: {
      type: 'string',
      title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickFacebookLabel'),
      default: data?.facebookProfileUrl || '',
      customUrlValidation: true,
      urlValidationParameter,
      urlValidationErrorText: t('pages.smm.details.facebookUrlErrorMessage'),
    },
    landingPageUrl: {
      type: 'string',
      title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickLandingPageLabel'),
      default: data?.landingPageUrl || '',
    },
    callToActionButton: {
      type: 'string',
      title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickCallToActionLabel'),
      default: data?.callToActionButton || '',
      enum: [
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.noButton'),
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.applyNow'),
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.bookNow'),
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.contactUs'),
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.donateNow'),
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.download'),
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.getShowtimes'),
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.learnMore'),
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.listenNow'),
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.requestTime'),
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.seeMenu'),
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.sendMessage'),
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.shopNow'),
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.signUp'),
        t('pages.socialAds.socialAdsWebClicksSection.callToActionOptions.watchNow'),
      ],
    },
    anyBusinessDifferentiators: {
      type: 'string',
      title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickBusinessDifferentiatorsLabel'),
      default: data?.anyBusinessDifferentiators || '',
    },
    socialWebClickTimedEventFlightHeading: {
      type: 'string',
      title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickTimedEventFlightHeading'),
    },
    clientWantToFocus: {
      type: 'string',
      title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickClientWantToFocusLabel'),
      default: data?.clientWantToFocus || '',
      enum: [
        t('pages.socialAds.socialAdsWebClicksSection.socialAddWebClickClientWantToOptions.yes'),
        t('pages.socialAds.socialAdsWebClicksSection.socialAddWebClickClientWantToOptions.no'),
      ],
    },
    isThisFlightedCampaign: {
      type: 'string',
      title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickIsThisFlightedCampaignLabel'),
      default: data?.isThisFlightedCampaign || '',
      enum: [
        t('pages.socialAds.socialAdsWebClicksSection.socialAddWebClickDatesFlightedOptions.yes'),
        t('pages.socialAds.socialAdsWebClicksSection.socialAddWebClickDatesFlightedOptions.no'),
      ],
    },
    automaticPlacementOnAds: {
      type: 'string',
      title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickAutomaticPlacementOnAdsLabel'),
      default: data?.automaticPlacementOnAds || '',
      enum: [
        '',
        t('pages.socialAds.socialAdsWebClicksSection.socialAddWebClickAutomaticPlacementOnAdsOptions.yes'),
        t('pages.socialAds.socialAdsWebClicksSection.socialAddWebClickAutomaticPlacementOnAdsOptions.no'),
      ],
    },
  },
  dependencies: {
    clientWantToFocus: {
      oneOf: [
        {
          properties: {
            clientWantToFocus: {
              enum: ['Yes'],
            },
            detailOfEvent: {
              type: 'string',
              title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickDetailOfEventLabel'),
              default: data?.detailOfEvent || '',
            },
            detailOfEventStartDate: {
              type: 'string',
              title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickStartDateLabel'),
              default: data?.detailOfEventStartDate || '',
            },
            detailOfEventEndDate: {
              type: 'string',
              title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickEndDateLabel'),
              default: data?.detailOfEventEndDate || '',
            },
          },
        },
        {
          properties: {
            clientWantToFocus: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    isThisFlightedCampaign: {
      oneOf: [
        {
          properties: {
            isThisFlightedCampaign: {
              enum: ['Yes'],
            },
            socialAddWebClickDatesFlightedCampaignText: {
              type: 'string',
              title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickDatesFlightedCampaignLabel'),
            },
            flightedCampaignStartDate: {
              type: 'string',
              title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickStartDate1Label'),
              default: data?.flightedCampaignStartDate || '',
            },
            flightedCampaignEndDate: {
              type: 'string',
              title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickEndDate1Label'),
              default: data?.flightedCampaignEndDate || '',
            },
          },
        },
        {
          properties: {
            clientWantToFocus: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    automaticPlacementOnAds: {
      oneOf: [
        {
          properties: {
            automaticPlacementOnAds: {
              enum: ['No'],
            },
            clientWantTheirAdsToAppear: {
              type: 'array',
              title: t('pages.socialAds.socialAdsWebClicksSection.socialAdsWebClicks.socialWebClickClientWantTheirAdsToAppearLabel'),
              items: {
                type: 'string',
                enum: [
                  t('pages.socialAds.socialAdsWebClicksSection.socialAddWebClickClientWantTheirAdsToAppearOptions.mobileFeed'),
                  t('pages.socialAds.socialAdsWebClicksSection.socialAddWebClickClientWantTheirAdsToAppearOptions.desktopFeed'),
                  t('pages.socialAds.socialAdsWebClicksSection.socialAddWebClickClientWantTheirAdsToAppearOptions.instagram'),
                  t('pages.socialAds.socialAdsWebClicksSection.socialAddWebClickClientWantTheirAdsToAppearOptions.facebookAudienceNetwork'),
                  t('pages.socialAds.socialAdsWebClicksSection.socialAddWebClickClientWantTheirAdsToAppearOptions.marketplace'),
                  t('pages.socialAds.socialAdsWebClicksSection.socialAddWebClickClientWantTheirAdsToAppearOptions.storyAds'),
                ],
              },
              default: data?.clientWantTheirAdsToAppear || '',
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            clientWantToFocus: {
              enum: ['Yes'],
            },
          },
        },
      ],
    },
  },
});

const dropdownFieldKeys = [
  'callToActionButton',
  'isThisFlightedCampaign',
  'automaticPlacementOnAds',
  'clientWantToFocus',
];
const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});
const inputFieldKeys = [
  'anyBusinessDifferentiators',
  'detailOfEvent',
];
const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});
const headingFieldKeys = [
  'SocialAdsWebsiteTitle',
  'SocialAdsWebsite',
  'socialWebClickTimedEventFlightHeading',
];
const headingFields = headingFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'headingTextLabel' },
}), {});
const datePickerFieldKeys = [
  'detailOfEventStartDate',
  'detailOfEventEndDate',
  'flightedCampaignStartDate',
  'flightedCampaignEndDate',
];
const datePickerFieldFields = datePickerFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'datePickerField' },
}), {});

const textLabelFieldKeys = [
  'socialAddWebClickDatesFlightedCampaignText',
];
const textLabelFields = textLabelFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textLabel' },
}), {});
const checkBoxFieldKeys = [
  'clientWantTheirAdsToAppear',
];
const checkBoxFields = checkBoxFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'checkboxes' },
}), {});
const urlTextFieldKeys = [
  'landingPageUrl',
  'facebookProfileUrl',
];
const urlTextFields = urlTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'UrlTextWidget' },
}), {});
export const SocialAdsWebClickUIOrderSchemaChange = {
  'ui:order': ['SocialAdsWebsiteTitle', 'facebookProfileUrl', 'socialAddWebClickFacebookHelpText', 'landingPageUrl', 'callToActionButton',
    'anyBusinessDifferentiators', 'socialWebClickTimedEventFlightHeading', 'clientWantToFocus',
    'detailOfEvent', 'detailOfEventStartDate', 'detailOfEventEndDate', 'isThisFlightedCampaign',
    'socialAddWebClickDatesFlightedCampaignText', 'flightedCampaignStartDate',
    'flightedCampaignEndDate', 'automaticPlacementOnAds', 'clientWantTheirAdsToAppear'],
  ...dropdownFields,
  ...inputFields,
  ...headingFields,
  ...datePickerFieldFields,
  ...textLabelFields,
  ...checkBoxFields,
  ...urlTextFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SocialAdsWebClickCustomValidateChange = (formData: any, errors: any, uiSchema: any) => {
  if (formData?.landingPageUrl === '' || !validateUrl(formData?.landingPageUrl)) {
    errors.landingPageUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.facebookProfileUrl === '' || !urlValidationParameter.some(url => formData?.facebookProfileUrl?.startsWith(url))) {
    errors.facebookProfileUrl.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.facebookUrlErrorMessage'));
    errors.addError(true);
  }
  if (new Date(formData?.detailOfEventEndDate) < new Date(formData?.detailOfEventStartDate)) {
    errors.detailOfEventEndDate.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.endDateErrorMessage'));
    errors.addError(true);
  }
  if (new Date(formData?.flightedCampaignEndDate) < new Date(formData?.flightedCampaignStartDate)) {
    errors.flightedCampaignEndDate.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.endDateErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
