/* eslint-disable no-param-reassign */
import { AsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import { PopUpSubmitData } from 'pages/addons/popUp/PopUpPage';
import { ExpandableMenuFormData } from 'pages/addons/expandableMenu/ExpandableMenu';
import DomainsType from 'models/DomainsType';
import AddonsType from 'models/AddonsType';
import PaymentFormsType from 'models/PaymentFormsType';
import OnlineBookingType from 'models/OnlineBookingType';
import ExpandableMenuType from 'models/ExpandableMenuType';
import PopUpType from 'models/popUpType';
import EmailHostingType from 'models/EmailHostingType';
import HostingEmailsType from 'models/HostingEmailsType';
import HipaaFormEmailsType from 'models/HipaaFormEmailsType';
import HipaaFormsType from 'models/HipaaFormsType';
import OrdersItemsType from 'models/OrderItemsType';
import BundlesType from 'models/BundlesType';
import { Urls } from 'navigation/Urls';
import { createAsyncThunkCatchErrors } from '../errorSlice';
import { RootState } from '../redux/store';
import AxiosAPI from '../common/axios';
import { thunkBuilder } from '../common/functions';

export interface AddonsState {
  addons: StatusType<AddonsType>
  paymentForms: StatusType<PaymentFormsType>
  onlineBooking: StatusType<OnlineBookingType>
  expandableMenu: StatusType<ExpandableMenuType>
  popUp: StatusType<PopUpType>
  emailHosting: StatusType<EmailHostingType>
  emailHostingEmails: StatusType<HostingEmailsType>
  hipaaFormDefinitions: StatusType<HipaaFormsType>
  hipaaFormEmails: StatusType<HipaaFormEmailsType>
  bundles: StatusType<BundlesType>
  hipaaFormAdditionalNotes: StatusType<any>
  videoBackgroundAdditionalNotes: StatusType<any>
  completedBuildingMenu: boolean
  addonsCompleted: boolean
  paymentFormsCompleted: boolean
  onlineBookingCompleted: boolean
  expandableMenuCompleted: boolean
  popUpCompleted: boolean
  emailHostingCompleted: boolean
  hipaaFormsCompleted: boolean
  completedUrls: StatusType<any[]>
  masterAddons: StatusType<any[]>
}

const emptyObject = {
  message: '',
  content: {},
  status: 'idle',
};

const emptyArray :StatusType<[]> = {
  message: '',
  content: [],
  status: 'idle',
};

const initialState: AddonsState = {
  addons: emptyObject as StatusType<AddonsType>,
  paymentForms: emptyObject as StatusType<PaymentFormsType>,
  onlineBooking: emptyObject as StatusType<OnlineBookingType>,
  expandableMenu: emptyObject as StatusType<ExpandableMenuType>,
  popUp: emptyObject as StatusType<PopUpType>,
  emailHosting: emptyObject as StatusType<EmailHostingType>,
  emailHostingEmails: emptyArray as StatusType<HostingEmailsType>,
  hipaaFormDefinitions: emptyArray as StatusType<HipaaFormsType>,
  hipaaFormEmails: emptyArray as StatusType<HipaaFormEmailsType>,
  hipaaFormAdditionalNotes: emptyObject as StatusType<any>,
  videoBackgroundAdditionalNotes: emptyObject as StatusType<any>,
  bundles: emptyObject as StatusType<BundlesType>,
  completedBuildingMenu: false,
  addonsCompleted: false,
  paymentFormsCompleted: false,
  onlineBookingCompleted: false,
  expandableMenuCompleted: false,
  popUpCompleted: false,
  emailHostingCompleted: false,
  hipaaFormsCompleted: false,
  completedUrls: emptyArray,
  masterAddons: emptyArray,
};

export const getAddons = createAsyncThunkCatchErrors(
  'addons/get',
  async (orderId: number | string) => {
    try {
      const response = await AxiosAPI.get(`/addon-menu/order/${orderId}`);
      return response.data;
    } catch (e) {
      // @ts-ignore
      const { status } = e.response;
      if (status === 404) return {};
      throw e;
    }
  },
);

export const getMasterAddonDetails = createAsyncThunkCatchErrors(
  'masterAddons/get',
  async (orderItemId: string) => {
    const response = await AxiosAPI.get(`/master-addons/order-item/${orderItemId}`);
    return response.data?.content;
  },
);

export const updateMasterAddonDetails = createAsyncThunkCatchErrors(
  'masterAddons/put',
  async ({ orderItemId, resource } : { orderItemId: number, resource: any }) => {
    const response = await AxiosAPI.put(`/master-addons/${orderItemId}/select-addons/master`, resource);
    return response.data;
  },
);

export const getCompletedWebsiteUrls = createAsyncThunkCatchErrors(
  'completedWebsites/get',
  async (gmaid: string) => {
    const response = await AxiosAPI.get(`/orders/completed_sites/${gmaid}`);
    return response.data;
  },
);

export const putAddons = createAsyncThunkCatchErrors(
  'addons/post',
  async ({ orderId, content } : { orderId: number | string, content: DomainsType }) => {
    const response = await AxiosAPI.put(`/addon-menu/order/${orderId}`, content);
    return response.data;
  },
);

export const getAddonsBundle = createAsyncThunkCatchErrors(
  'addons/getBundle',
  async ({ bundleId } : { bundleId: string }) => {
    const response = await AxiosAPI.get(`/bundles/${bundleId}`);
    return response.data;
  },
);

export const getExpandableMenu = createAsyncThunkCatchErrors(
  'addons/getExpandableMenu',
  async (orderId :string) => {
    const response = await AxiosAPI.get(`/websites/expendable-menus/order/${orderId}`);
    return response.data;
  },
) as AsyncThunk<ExpandableMenuType, string, {}>;

export const putExpandableMenu = createAsyncThunkCatchErrors(
  'addons/putExpandableMenu',
  async ({ orderId, data } : { orderId: string, data: any }) => {
    const response = await AxiosAPI.put(`/websites/expendable-menus/create-update/${orderId}`, data);
    return response.data;
  },
) as AsyncThunk<
ExpandableMenuType,
{ orderId: string, data: ExpandableMenuFormData },
{}
>;

export const getPaymentForms = createAsyncThunkCatchErrors(
  'addons/getPaymentForms',
  async ({ orderId } : { orderId: string }) => {
    const response = await AxiosAPI.get(`/websites/payment-forms/order/${orderId}`);
    return response.data;
  },
);

export const putPaymentForms = createAsyncThunkCatchErrors(
  'addons/putPaymentForms',
  async ({
    orderId,
    content,
  }: {
    orderId: string,
    content: any
  }) => {
    const response = await AxiosAPI.put(
      `/websites/payment-forms/create-update/${orderId}`,
      content,
    );
    return response.data;
  },
);

export const getEmailHosting = createAsyncThunkCatchErrors(
  'addons/getEmailHosting',
  async ({ orderItemId } : { orderItemId: string }) => {
    const response = await AxiosAPI.get(`/email-hosting/order/item/${orderItemId}/`);
    return response.data;
  },
);

export const putEmailHosting = createAsyncThunkCatchErrors(
  'addons/putEmailHosting',
  async ({
    orderItemId,
    content,
  } : {
    orderItemId: string,
    content: any
  }) => {
    const response = await AxiosAPI.put(
      `/email-hosting/order/item/${orderItemId}/`,
      content,
    );
    return response.data;
  },
);

export const getHipaaFormAdditionalNotes = createAsyncThunkCatchErrors(
  'addons/getHipaaFormAdditionalNotes',
  async ({ orderId } : { orderId: string }) => {
    const response = await AxiosAPI.get(`hipaa-forms/order/${orderId}/`);
    return response.data;
  },
);

export const getHeroVideoAdditionalNotes = createAsyncThunkCatchErrors(
  'addons/getHeroVideoAdditionalNotes',
  async ({ orderId } : { orderId: string }) => {
    const response = await AxiosAPI.get(`video-background/order/${orderId}/`);
    return response.data;
  },
);

export const putHipaaFormAdditionalNotes = createAsyncThunkCatchErrors(
  'addons/putHipaaFormAdditionalNotes',
  async ({ orderId, content } : { orderId: string, content: any }) => {
    const response = await AxiosAPI.put(`hipaa-forms/order/${orderId}/`, content);
    return response.data;
  },
);

export const putHeroVideoAdditionalNotes = createAsyncThunkCatchErrors(
  'addons/putHeroVideoAdditionalNotes',
  async ({ orderId, content } : { orderId: string, content: any }) => {
    const response = await AxiosAPI.put(`video-background/order/${orderId}/`, content);
    return response.data;
  },
);

export const getEmailHostingEmails = createAsyncThunkCatchErrors(
  'addons/getEmailHostingEmails',
  async ({ orderId } : { orderId: string }) => {
    const response = await AxiosAPI.get(`/emails/order/${orderId}/`);
    return response.data.content;
  },
);

export const putEmailHostingEmails = createAsyncThunkCatchErrors(
  'addons/putEmailHostingEmails',
  async ({
    orderItemId,
    content,
  } : {
    orderItemId: string,
    content: any
  }) => {
    const response = await AxiosAPI.put(
      `/emails/order/item/${orderItemId}/`,
      content,
    );
    return response.data;
  },
);

export const deleteEmailHostingEmail = createAsyncThunkCatchErrors(
  'addons/deleteEmailHostingEmail',
  async ({ emailId } : { emailId: string }) => {
    const response = await AxiosAPI.delete(`/emails/${emailId}`);
    return response.data;
  },
);

export const getOnlineBooking = createAsyncThunkCatchErrors(
  'addons/getOnlineBooking',
  async ({ orderId } : { orderId: string }) => {
    const response = await AxiosAPI.get(`/online-booking/order/${orderId}`);
    return response.data;
  },
);

export const putOnlineBooking = createAsyncThunkCatchErrors(
  'addons/putOnlineBooking',
  async ({
    orderId,
    content,
  }: {
    orderId: string,
    content: any
  }) => {
    const response = await AxiosAPI.put(
      `/online-booking/order/${orderId}`,
      content,
    );
    return response.data;
  },
);

type PopUpIds = { id: number; orderItemId: number };

const formatDate = (date :Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const adjustedMonth = month < 9 ? `0${month + 1}` : month + 1;
  const day = date.getDate();
  const adjustedDay = day < 10 ? `0${day}` : day;

  return `${year}-${adjustedMonth}-${adjustedDay}`;
};

export const getPopUp = createAsyncThunkCatchErrors('addons/getPopUp', async (orderItemId: string) => {
  const response = await AxiosAPI.get(`/popup/order/item/${orderItemId}/`);
  const { data } = response;
  return data;
}) as AsyncThunk<PopUpType & PopUpIds, string, {}>;

export const putPopUp = createAsyncThunkCatchErrors(
  'addons/putPopUp',
  async ({ orderItemId, popUpData } : { orderItemId: string, popUpData: PopUpSubmitData }) => {
    const response = await AxiosAPI.put(`/popup/order/item/${orderItemId}/`, popUpData, {
      transformRequest: [(data :PopUpSubmitData) =>
        // eslint-disable-next-line func-names
        JSON.stringify(data, function (key, value) {
          if (this[key] instanceof Date) {
            return formatDate(this[key]);
          }

          return value;
        }),
      ],
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const { data } = response;
    return data;
  },
) as AsyncThunk<PopUpType & PopUpIds, { orderItemId: string, popUpData: PopUpSubmitData }, {}>;

export const postEmptyPopUp = createAsyncThunkCatchErrors(
  'addons/postPopUp',
  async (orderId :string) => {
    const popUpData :PopUpSubmitData & PopUpIds = {
      actionDetails: '',
      additionalInfo: '',
      additionalNotes: '',
      button: false,
      contentDesignInformation: '',
      timer: false,
      // @ts-ignore
      date: '',
      email: false,
      firstName: false,
      form: false,
      image: false,
      lastName: false,
      other: false,
      otherText: '',
      phone: false,
      primaryTypeLead: '',
      trigger: '',
      type: '',
      id: null,
      orderItemId: null,
    };

    const response = await AxiosAPI.post(`/popup/order/${orderId}/`, popUpData);
    const { data } = response;
    return data;
  },
) as AsyncThunk<PopUpType & PopUpIds, string, {}>;

export const getHipaaFormDefinitions = createAsyncThunkCatchErrors(
  'addons/getHipaaFormDefinitions',
  async ({ orderId } : { orderId: string }) => {
    const response = await AxiosAPI.get(`/hipaa-forms/definitions/order/${orderId}/`);
    return response.data.content;
  },
);

export const postHipaaFormDefinition = createAsyncThunkCatchErrors(
  'addons/postHipaaFormDefinition',
  async ({ orderId, content } : { orderId: string, content: any }) => {
    const response = await AxiosAPI.post(`/hipaa-forms/definitions/order/${orderId}`, content);
    return response.data;
  },
);

export const putHipaaFormDefinition = createAsyncThunkCatchErrors(
  'addons/putHipaaFormDefinitions',
  async ({ orderId, content } : { orderId: string, content: any }) => {
    const response = await AxiosAPI.put(`/hipaa-forms/definitions/order/${orderId}`, content);
    return response.data;
  },
);

export const deleteHipaaFormDefinition = createAsyncThunkCatchErrors(
  'addons/deleteHipaaFormDefinition',
  async ({ definitionId } : { definitionId: string }) => {
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const response = await AxiosAPI.delete(`/hipaa-forms/definitions/${definitionId}`);
    return definitionId;
  },
);

export const getHipaaFormEmails = createAsyncThunkCatchErrors(
  'addons/getHipaaFormEmails',
  async ({ orderId } : { orderId: string }) => {
    const response = await AxiosAPI.get(`/hipaa-forms/emails/order/${orderId}/`);
    return response.data.content;
  },
);

export const putHipaaFormEmails = createAsyncThunkCatchErrors(
  'addons/putHipaaFormEmails',
  async ({
    orderItemId,
    content,
  } : {
    orderItemId: string,
    content: any
  }) => {
    const response = await AxiosAPI.put(
      `/hipaa-forms/emails/order/item/${orderItemId}/`,
      content,
    );
    return response.data;
  },
);

export const deleteHipaaFormEmail = createAsyncThunkCatchErrors(
  'addons/deleteHipaaFormEmail',
  async ({ emailId } : { emailId: string }) => {
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const response = await AxiosAPI.delete(`/hipaa-forms/emails/${emailId}`);
    return emailId;
  },
);

export const addonsSlice = createSlice({
  name: 'addons',
  initialState,
  reducers: {
    setCompletedBuildingMenu(state) {
      state.completedBuildingMenu = true;
    },
    clearCompletedBuildingMenu(state) {
      state.completedBuildingMenu = false;
    },
    clearAddonsCompleted(state) {
      state.addonsCompleted = false;
    },
    clearPaymentFormsCompleted(state) {
      state.paymentFormsCompleted = false;
    },
    clearOnlineBookingCompleted(state) {
      state.onlineBookingCompleted = false;
    },
    clearExpandableMenuCompleted(state) {
      state.expandableMenuCompleted = false;
    },
    clearPopUpCompleted(state) {
      state.popUpCompleted = false;
    },
    clearEmailHostingCompleted(state) {
      state.emailHostingCompleted = false;
    },
    clearHipaaFormsCompleted(state) {
      state.hipaaFormsCompleted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmailHostingEmails.fulfilled, (state, action) => {
        state.emailHostingEmails.content = action.payload;
      })
      .addCase(getEmailHostingEmails.rejected, (state) => {
        state.emailHostingEmails.content = [];
      });

    thunkBuilder(builder)
      .addCase(getAddons, 'addons')
      .addCase(getCompletedWebsiteUrls, 'completedUrls')
      .addCase(getMasterAddonDetails, 'masterAddons')
      .addCase(putAddons, 'addons', { completeStep: 'addonsCompleted' })
      .addCase(getAddonsBundle, 'bundles')
      .addCase(getPaymentForms, 'paymentForms')
      .addCase(putPaymentForms, 'paymentForms', { completeStep: 'paymentFormsCompleted' })
      .addCase(getOnlineBooking, 'onlineBooking')
      .addCase(putOnlineBooking, 'onlineBooking', { completeStep: 'onlineBookingCompleted' })
      .addCase(getExpandableMenu, 'expandableMenu')
      .addCase(putExpandableMenu, 'expandableMenu', { completeStep: 'expandableMenuCompleted' })
      .addCase(getPopUp, 'popUp')
      .addCase(putPopUp, 'popUp', { completeStep: 'popUpCompleted' })
      .addCase(getEmailHosting, 'emailHosting')
      .addCase(putEmailHosting, 'emailHosting')
      .addCase(putEmailHostingEmails, 'emailHostingEmails', { completeStep: 'emailHostingCompleted' })
      .addCase(getHipaaFormDefinitions, 'hipaaFormDefinitions')
      .addCase(getHipaaFormAdditionalNotes, 'hipaaFormAdditionalNotes')
      .addCase(getHeroVideoAdditionalNotes, 'videoBackgroundAdditionalNotes')
      .addCase(postHipaaFormDefinition, 'hipaaFormDefinitions', {
        contentValueCallback: (payload, content) => [...content, payload],
      })
      .addCase(putHipaaFormDefinition, 'hipaaFormDefinitions', {
        contentValueCallback: (payload, content) => {
          const index = content.findIndex(s => s.id === payload.id);
          content[index] = payload;
          return content;
        },
      })
      .addCase(deleteHipaaFormDefinition, 'hipaaFormDefinitions', {
        contentValueCallback: (payload, content) => content.filter((e) => e.id !== payload),
      })
      .addCase(getHipaaFormEmails, 'hipaaFormEmails')
      .addCase(putHipaaFormEmails, 'hipaaFormEmails', { completeStep: 'hipaaFormsCompleted' })
      .addCase(deleteHipaaFormEmail, 'hipaaFormEmails', {
        contentValueCallback: (payload, content) => content.filter((e) => e.id !== payload),
      });
  },
});

export const {
  clearAddonsCompleted,
  clearCompletedBuildingMenu,
  setCompletedBuildingMenu,
  clearPaymentFormsCompleted,
  clearOnlineBookingCompleted,
  clearExpandableMenuCompleted,
  clearPopUpCompleted,
  clearEmailHostingCompleted,
  clearHipaaFormsCompleted,
} = addonsSlice.actions;

export const selectAddons = (state: RootState) => state.addons.addons;

export const selectAddonSteps = (state: RootState) => {
  const addons = state.addons.addons.content;
  const selectedAddons = [];

  if (addons) {
    Object.entries(addons).forEach(([key, value]) => {
      if (value === true) selectedAddons.push(key);
    });
  }
  return selectedAddons;
};

export const selectedMasterAddonsDetails = (state: RootState) => state.addons.masterAddons;

export const selectCompletedWebsiteUrls = (state: RootState) => state.addons.completedUrls;

export const selectAddonsCompleted = (state: RootState) => state.addons.addonsCompleted;

export const selectCompletedBuildingMenu = (state: RootState) => state.addons.completedBuildingMenu;

export const selectPaymentForms = (state: RootState) => state.addons.paymentForms;

export const selectPaymentFormsCompleted = (state: RootState) => state.addons.paymentFormsCompleted;

export const selectOnlineBooking = (state: RootState) => state.addons.onlineBooking;

export const selectOnlineBookingCompleted = (state: RootState) => state.addons.onlineBookingCompleted;

export const selectExpandableMenu = (state: RootState) => state.addons.expandableMenu;

export const selectExpandableMenuCompleted = (state: RootState) => state.addons.expandableMenuCompleted;

export const selectPopUp = (state: RootState) => state.addons.popUp;

export const selectPopUpOrderItemId = (state: RootState) => {
  const { orderItems } = state.orders;

  if (orderItems.content) {
    const popUp = orderItems.content?.find((item :OrdersItemsType) => item.offeringType === 'Popup');
    if (typeof popUp === 'object') {
      return `${popUp.id}`;
    }
  }

  return null;
};

export const selectPopUpCompleted = (state: RootState) => state.addons.popUpCompleted;

export const selectEmailHosting = (state: RootState) => state.addons.emailHosting;

export const selectEmailHostingCompleted = (state: RootState) => state.addons.emailHostingCompleted;

export const selectEmailHostingOrderItemId = (state: RootState) => {
  const { orderItems } = state.orders;

  if (orderItems.content) {
    const emailHosting = orderItems.content?.find((item :OrdersItemsType) => item.offeringType === 'EmailHosting');
    if (emailHosting) {
      return `${emailHosting.id}`;
    }
  }
  return null;
};

export const selectEmailHostingEmails = (state: RootState) => state.addons.emailHostingEmails;

export const selectHipaaFormDefinitions = (state: RootState) => state.addons.hipaaFormDefinitions;

export const selectHipaaFormAdditionalNotes = (state: RootState) => state.addons.hipaaFormAdditionalNotes;

export const selectVideoBackgroundAdditionalNotes = (state: RootState) => state.addons.videoBackgroundAdditionalNotes;

export const selectHipaaFormEmails = (state: RootState) => state.addons.hipaaFormEmails;

export const selectHipaaFormsCompleted = (state: RootState) => state.addons.hipaaFormsCompleted;

export const selectHipaaFormOrderItemId = (state: RootState) => {
  const { orderItems } = state.orders;

  if (orderItems.content) {
    const hipaaForm = orderItems.content?.find((item :OrdersItemsType) => item.offeringType === 'HipaaForm');
    if (hipaaForm) {
      return `${hipaaForm.id}`;
    }
  }
  return null;
};

export const selectBundleStatus = (state: RootState) => state.addons.bundles.status;

export const selectIncludedBundle = (state: RootState) => {
  const { bundles } = state.addons;
  // TODO get this content from the endpoint
  if (bundles.content) {
    if (bundles.content.name === 'Essential') {
      return {
        emailHosting: true,
        expandableMenus: true,
        videoBackground: true,
      };
    }
    if (bundles.content.name === 'Plus') {
      return {
        emailHosting: true,
        expandableMenus: true,
        videoBackground: true,
        paymentForms: true,
      };
    }
  }
  return null;
};

export default addonsSlice.reducer;
