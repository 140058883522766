import { t } from 'i18next';
import * as yup from 'yup';

const yupSchema = {
  theWorks: yup.boolean(),
  expandedDropdown: yup.boolean(),
  megaMenu: yup.boolean(),
  imagesMenu: yup.boolean(),
  advancedMenu: yup.boolean(),
  dynamicMenu: yup.boolean(),
  other: yup.boolean(),
  otherLayout: yup.string().required(t('pages.website.custom.navigation.otherLayoutRequired')),
};

export default {
  yupValidations: yupSchema,
  customValidations: {},
};
