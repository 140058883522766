import { IQButtonLink, IQThemeV2, ValidationProvider } from '@gannettdigital/shared-react-components';
import {
  Button,
  Grid, TextField, Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import i18n from 'i18n/i18n';
import schemaValidate from 'components/schemaValidate';
import ErrorText from 'components/errorText/ErrorText';
import NePhoneInput from 'components/NePhoneInputContainer/IQPhoneInputContainer';
import schema from './PhoneTracking.schema';

const { t } = i18n;

interface Props {
  addNewLine: (data: any) => void
  closeModal: () => void
  content: any
}

export default function PhoneTrackingForm({ addNewLine, closeModal, content }: Props) {
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      originalNumber: content.originalNumber || '',
      forwardToNumber: content.forwardToNumber || '',
      areaCodePreference: content.areaCodePreference || '',
    },
  });

  const {
    register,
    getValues,
    formState: {
      isValid,
      errors,
    },
  } = methods;

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <Grid container xs={12} sx={{ padding: '20px 20px' }}>
          {/* Original Number */}
          <Grid item mb={3} xs={12}>
            <NePhoneInput
              name="originalNumber"
              defaultValue=""
              country="US"
              hasExtension={false}
              phoneLabelOptions={{
                tooltipText: '',
                labelText: t('pages.ccByDash.solutionStrategy.originalNumberLabel'),
                hasError: true,
                theme: IQThemeV2,
                required: true,
              }}
              hasType={false}
              countryCallingCodeEditable={false}
              international
            />
          </Grid>
          {/* Forward to Number */}
          <Grid item mb={3} xs={12}>
            <NePhoneInput
              name="forwardToNumber"
              defaultValue=""
              country="US"
              hasExtension={false}
              phoneLabelOptions={{
                tooltipText: '',
                labelText: t('pages.ccByDash.solutionStrategy.forwardToNumberLabel'),
                hasError: true,
                theme: IQThemeV2,
                required: true,
              }}
              hasType={false}
              countryCallingCodeEditable={false}
              international
            />
          </Grid>
          {/* Are Code Preference */}
          <Grid item mb={3} xs={12}>
            <Typography variant="subtitle2" component="h3" fontSize={16}
              color={errors.areaCodePreference ? 'error.main' : 'common.black'} mb={1}
            >
              {t('pages.ccByDash.solutionStrategy.areaCodePreferenceLabel')}
            </Typography>
            <TextField
              {...register('areaCodePreference', {
                validate: (value) => schemaValidate(value, 'areaCodePreference', schema),
              })}
              hiddenLabel
              required={false}
              name="areaCodePreference"
              type="text"
              error={!!errors.areaCodePreference}
              fullWidth
              sx={{ '& .MuiOutlinedInput-notchedOutline': { borderColor: IQThemeV2.palette.common.black } }}
            />
            <ErrorText
              hasError={!!errors.areaCodePreference}
              errorText={t('pages.ccByDash.solutionStrategy.areaCodePreferenceError')}
            />
          </Grid>
          {/* Buttons */}
          <Grid item mb={1} mt={4}
            xs={12} sx={{ textAlign: 'center' }}
          >
            <Button variant="contained"
              sx={{ padding: '8px 50px' }}
              onClick={() => addNewLine({ ...content, ...getValues() })}
              disabled={!isValid}
            >
              {t('pages.ccByDash.solutionStrategy.saveTrackingLineLabel')}
            </Button>
          </Grid>
          <Grid item mb={3} xs={12}
            sx={{ textAlign: 'center' }}
          >
            <IQButtonLink onClick={closeModal}>
              Close
            </IQButtonLink>
          </Grid>

        </Grid>
      </FormProvider>
    </ValidationProvider>
  );
}
