import React from 'react';
import {
  Grid, Typography, Checkbox, FormControlLabel,
} from '@mui/material';

export default function CheckboxField(props: any) {
  const { options: { enumOptions } } = props;

  return (
    <Grid item mt={3}>
      <Typography variant="subtitle2" component="h3" fontSize={16}>
        {props.label}
      </Typography>
      {enumOptions.map(option => (
        <FormControlLabel
          key={option.value}
          control={(
            <Checkbox
              checked={props.value.includes(option.value)}
              onChange={(e) => {
                const isChecked = e.target.checked;
                const { value } = option;

                if (isChecked) {
                  props.onChange([...props.value, value]);
                } else {
                  props.onChange(props.value.filter(item => item !== value));
                }
              }}
            />
                )}
          label={option.label}
        />
      ))}
    </Grid>
  );
}
