import * as yup from 'yup';

const schema = {
  productDescriptionByClient: yup.string().required(),
  productImagesProvidedByClient: yup.string().required(),
  numberOfProductsWritten: yup.number()
    .required()
    .nullable()
    .min(0, 'Please enter the number of products that require written content.')
    .typeError('Please enter the number of products that require written content.'),
  contentTones: yup.array()
    .required()
    .min(1, 'Please select at least one item.')
    .max(2, 'Please review and select only two items to continue.')
    .typeError('Please select at least one item.'),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
