export const filterCategory = [
  'Chat',
  'Listings',
  'SEO',
  'SEM',
  'Social',
  'Targeted Email',
  'Targeted Display',
  'Tracking & Reporting',
  'Website',
  'Other',
].sort((a, b) => a.localeCompare(b));

export const statuses = [
  { value: '', description: 'ALL', color: '#0074C2' },
  { value: 'Incomplete', description: 'INCOMPLETE', color: '#BA5012' },
  { value: 'Payment+Pending', description: 'PAYMENT PENDING', color: '#BA5012' },
  { value: 'Off+Track', description: 'OFF TRACK', color: '#C20F1E' },
  { value: 'On+Track', description: 'ON TRACK', color: '#0074C2' },
  { value: 'Fulfilled', description: 'FULFILLED', color: '#008059' },
];

export const colorMap = {
  'Partial Payment': '#BA5012',
  'Payment Pending': '#BA5012',
  Pending: '#BA5012',
  'Payment Verified': '#0074C2',
  'Assignments Pending': '#BA5012',
  'Assignments Made': '#BA5012',
  Incomplete: '#BA5012',
  'On Track': '#0074C2',
  Completed: '#0074C2',
  COMPLETED: '#0074C2',
  'Awaiting Review': '#0074C2',
  'Off Track': '#C20F1E',
  Fulfilled: '#008059',
  default: '#0074C2',
};

export const orderFlowColorMap = {
  'INTERNAL': { bgColor: '#008059', text: 'Manual'},
  'SALESFORCE': { bgColor: '#FF6437', text: 'LocaliQ Orange' },
  'SALESFORCE_BLUE': { bgColor: '#0046FF', text: 'LocaliQ Blue' },
}

export const blackBorderStyles = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#716F6F',
    borderWidth: '2px',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#716F6F',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#716F6F',
    borderWidth: '2px',
  },
}

export const dividerStyles = {
  marginTop: '10px',
  marginBottom: '20px',
  borderBottomWidth: '2px',
  borderColor: '#716F6F',
};