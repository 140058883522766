import {
  IQFormLayout, IQFormTextArea, IQLabelTooltip, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import {
  FormControlLabel,
  Grid, Link, Radio, RadioGroup, Typography, useTheme,
} from '@mui/material';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Urls } from 'navigation/Urls';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import {
  getCustomLandingPage,
  selectDetailsCustomLandingPage,
  setDetails,
} from 'services/customLandingPageSlice';
import GridItem from 'components/grid/GridItem';
import { yesNoOptions } from 'pages/seo/details/DetailsPageConstants';
import schemaValidate from 'components/schemaValidate';
import schema from './Details.schema';

const sheetUrl = 'https://reachsites-client-assets-prd.s3.us-west-2.amazonaws.com'
  + '/uploads/theme_collateral/uploaded_file/26/faqs-landing-pages-updated.pdf';
const faqUrl = 'https://reachsites-client-assets-prd.s3-us-west-2.amazonaws.com'
+ '/uploads/theme_collateral/uploaded_file/25/qrs-landing-pages.pdf';

export default function CustomLandingPageDetails() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { orderItemId } = useParams();
  const navigate = useNavigationHandler();
  const dispatch = useDispatch();
  const details = useSelector(selectDetailsCustomLandingPage);
  const [openToSilverWebsite, setOpenToSilverWebsite] = useState('');
  const history: any = useLocation();
  const isRedirectedFromOverview = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      reason: '',
      openToSilverWebsite: '',
    },
  });

  const { register, reset, formState: { isValid } } = methods;

  useEffect(() => {
    if (orderItemId) {
      dispatch(getCustomLandingPage(orderItemId));
    }
  }, [orderItemId]);

  useEffect(() => {
    if (details) {
      reset({
        reason: details.reason,
        openToSilverWebsite: details.openToSilverWebsite,
      });
      setOpenToSilverWebsite(details.openToSilverWebsite);
    }
  }, [details]);

  const handleContinue = () => {
    const sendData = {
      newSiteReason: methods.getValues('reason'),
    };
    const openToSilverWebsite = methods.getValues('openToSilverWebsite');
    dispatch(setDetails({ orderItemId, sendData, openToSilverWebsite }));
    navigate.to(isRedirectedFromOverview ? Urls.Overview : Urls.LandingCustomDesign);
  };

  const onBack = () => {
    navigate.to(Urls.BusinessLocation);
  };

  return (
    <DefaultPageLayout
      onContinue={handleContinue}
      disableContinue={!isValid}
      onBack={onBack}
      header={t('pages.landing.custom.details.title')}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container sx={{ marginTop: 3 }}>
            <Grid item xs={6}>
              <IQFormTextArea
                id="reason"
                name="reason"
                labelText={t('pages.landing.custom.details.reason')}
                fontLabelWeight="600"
                rowCount={3}
                required
                fullWidth
                showError
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6} sx={{ marginTop: 4 }}>
              <IQFormLayout
                labelText={null}
                fontLabelWeight="bold"
                tooltipPaddingBottom={30}
                showError={false}
              >
                <Grid mb={1}>
                  <IQLabelTooltip
                    labelText={t('pages.landing.custom.details.openToStandardCampaign')}
                    theme={theme}
                    tooltipText={(
                      <Typography color="white">
                        {t('pages.landing.custom.details.pleaseReview')}
                      &nbsp;
                        <Link style={{ color: 'white', textDecoration: 'underline' }} href={sheetUrl}>
                          {t('pages.landing.custom.details.quickReferenceSheet')}
                        </Link>
                      &nbsp;
                        {t('pages.landing.custom.details.and')}
                      &nbsp;
                        <Link style={{ color: 'white', textDecoration: 'underline' }} href={faqUrl}>
                          {t('pages.landing.custom.details.faq')}
                        </Link>
                      &nbsp;
                        {t('pages.landing.custom.details.learnMore')}
                      </Typography>
                  )}
                    hasError={false}
                    paddingBottom={32}
                  />
                  <RadioGroup
                    aria-labelledby="openToSilverWebsite"
                    name="openToSilverWebsite"
                    value={openToSilverWebsite}
                    onChange={(e) => setOpenToSilverWebsite(e.target.value)}
                  >
                    <GridItem sizes={[2, 2]} marginBottom={0}>
                      {yesNoOptions.map(option => (
                        <FormControlLabel
                          key={option.value}
                          {...register('openToSilverWebsite', {
                            validate: (value) => schemaValidate(value, 'openToSilverWebsite', schema),
                          })}
                          value={option.value}
                          control={<Radio />}
                          label={(
                            <Typography variant="body1">
                              {option.label}
                            </Typography>
                        )}
                        />
                      ))}
                    </GridItem>
                  </RadioGroup>
                </Grid>
              </IQFormLayout>
            </Grid>
          </Grid>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
