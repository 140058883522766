import { IQFormTextArea, ValidationProvider } from '@gannettdigital/shared-react-components';
import { Box, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  clearExamplesCompleted, getWebsite,
  putWebsiteExamples,
  selectExamplesCompleted,
  selectWebsites,
} from 'services/websitesSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { Urls } from 'navigation/Urls';
import { selectFilesGetty, selectFilesUploaded } from 'services/fileUploaderSlice';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import schema from './Example.schema';

export default function Examples() {
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const { t } = useTranslation();
  const history: any = useLocation();
  const website = useSelector(selectWebsites);
  const pageCompleted = useSelector(selectExamplesCompleted);
  const { status: filesStatus } = useSelector(selectFilesUploaded);
  const { status: gettyStatus } = useSelector(selectFilesGetty);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      examples: '',
    },
  });
  const {
    handleSubmit,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
    setValue,
  } = methods;
  const navigate = useNavigationHandler();
  const topNav = useTopnavMenu(Urls.Examples);
  const dispatch = useDispatch();

  const onBack = () => {
    navigate.to(topNav.previousStep());
  };

  const onSubmit = (content) => {
    dispatch(putWebsiteExamples({
      websiteId,
      content,
      offeringType,
    }));
  };

  useEffect(() => {
    if (!website?.content?.id) {
      dispatch(getWebsite({
        websiteId,
        offeringType,
      }));
    }
  }, [websiteId]);

  // after we receive the completed flag from redux, we move to the next page and clear the flag
  useEffect(() => {
    if (pageCompleted) {
      dispatch(clearExamplesCompleted());
      if (history.state?.previousPath.includes('overview')) {
        navigate.to(Urls.Overview);
      } else {
        navigate.to(topNav.nextStep());
      }
    }
  }, [pageCompleted]);

  useEffect(() => {
    if (website?.content.examples?.length) setValue('examples', website?.content.examples, { shouldValidate: true });
  }, [website]);

  return (
    <ContentLoader status={[website.status, filesStatus, gettyStatus]} message={website.message}>
      <DefaultPageLayout disableContinue={!isValid}
        onBack={onBack} onContinue={handleSubmit(onSubmit)}
        header={t('pages.products.exampleStep.title')}
      >
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <IQFormTextArea
                    id="examples"
                    name="examples"
                    labelText="Website URLs and Elements"
                    fullWidth
                    subDescription="Provide URLs and specific elements (i.e example.com - forms, design)"
                    tooltipText="Please note, we'll use this as a general
                      guidance and will not be a true representation of our build."
                    rowCount={2}
                    required
                    tooltipPaddingBottom={16}
                  />
                </Grid>
              </Grid>
              {/* show the dialog if we changed the field and didn't submit the changes */}
              <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
              />
            </Box>
          </FormProvider>
        </ValidationProvider>
      </DefaultPageLayout>
    </ContentLoader>
  );
}
