import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import {
  clearPaymentFormsCompleted, getAddons, getMasterAddonDetails,
  getPaymentForms,
  putPaymentForms, selectAddons,
  selectPaymentForms,
  selectPaymentFormsCompleted,
} from 'services/addons/addonsSlice';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { Urls } from 'navigation/Urls';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import PaymentFormsForm from './PaymentFormsForm';

export default function PaymentForms() {
  const { orderId, orderItemId } = useParams();

  const dispatch = useDispatch();

  const paymentForms = useSelector(selectPaymentForms);
  const paymentFormsCompleted = useSelector(selectPaymentFormsCompleted);
  const topNav = useTopnavMenu(Urls.PaymentForms);
  const addons = useSelector(selectAddons);

  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  const navigate = useNavigationHandler();

  const onSubmit = (addons) => {
    dispatch(putPaymentForms({
      orderId, content: addons,
    }));
  };

  const onBack = () => {
    navigate.to(topNav.previousStep());
  };

  useEffect(() => {
    dispatch(getPaymentForms({ orderId }));
  }, [orderId]);

  useEffect(() => {
    if (paymentFormsCompleted) {
      dispatch(clearPaymentFormsCompleted());
      if (isRedirectedFromOverviewPage) return navigate.to(Urls.Overview);
      navigate.to(topNav.nextStep());
    }
  }, [paymentFormsCompleted]);

  useEffect(() => {
    if (!addons?.content?.id) dispatch(getAddons(orderId));
    if (orderItemId) dispatch(getMasterAddonDetails(orderItemId));
  }, [addons, orderItemId]);

  return (
    <ContentLoader status={paymentForms.status} message={paymentForms.message}>
      <PaymentFormsForm onBack={onBack} onSubmit={onSubmit} paymentForm={paymentForms.content} />
    </ContentLoader>
  );
}
