import { Typography, Box } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { camelCaseToFlat } from 'pages/shopping-cart/ShoppingCart';
import { Urls } from 'navigation/Urls';
import { editButton } from 'pages/overview/Overview';
import { headingStyle } from '../Business/BusinessSection';

const formRequirements = (websiteData) => {
  if (websiteData?.customFormRequirements) {
    return websiteData?.customFormRequirements.split(',')
      .map(item => (
        <Typography key={item}>
          {camelCaseToFlat(item) === 'Hipaa Secure' ? 'HIPAA Secure' : camelCaseToFlat(item)}
        </Typography>
      ));
  }
  return null;
};

const mapMenuType = {
  theWorks: 'The Works',
  megaMenu: 'Mega Menu',
  advancedMenu: 'Advanced Menu',
  expandedDropdown: 'Expanded Dropdown',
  imagesMenu: 'Images Menu',
  dynamicMenu: 'Dynamic Menu',
};

const menuTypes = ['theWorks', 'megaMenu', 'advancedMenu', 'expandedDropdown', 'imagesMenu', 'dynamicMenu'];

export const customWebsiteDesignSection = (websiteData, websiteItems, product, navigate, isEditable) => (
  <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
    <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Design</Typography>

    <Box>

      {/* Custom Design */}
      {websiteItems?.filter(item => item?.id?.customWebsiteItems?.name === 'Custom Design')?.length > 0 && (
      <>
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () =>
            navigate.to(
              `${Urls.CustomWebsiteDesignItems}/${Urls.CustomDesign}`,
              product.id,
            ))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Custom Design</Typography>
        </Box>
        <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Design Requirements</Typography>
              <Typography>{websiteData?.customDesignInfo}</Typography>
            </Box>
          </Box>
          <Box />
        </GridItem>
      </>
      )}

      {/* Custom Navigation */}
      {/* eslint-disable-next-line max-len */}
      {websiteItems?.filter(item => item?.id?.customWebsiteItems?.name === 'Custom Navigation Menu')?.length > 0 && (
      <>
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () =>
            navigate.to(
              `${Urls.CustomWebsiteDesignItems}/${Urls.CustomNavigation}`,
              product.id,
            ))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Custom Navigation</Typography>
        </Box>
        <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Menu Layout</Typography>
              <Typography>
                {menuTypes.includes(websiteData?.navigationMenuType)
                  ? mapMenuType[websiteData?.navigationMenuType]
                  : websiteData?.navigationMenuType}
              </Typography>
            </Box>
          </Box>
          <Box />
        </GridItem>
      </>
      )}

      {/* Custom Form */}
      {websiteItems?.filter(item => item?.id?.customWebsiteItems?.name === 'Custom Form')?.length > 0 && (
      <>
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () =>
            navigate.to(
              `${Urls.CustomWebsiteDesignItems}/${Urls.CustomForm}`,
              product.id,
            ))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Custom Form</Typography>
        </Box>
        <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Form Example URLs</Typography>
              <Typography style={{ whiteSpace: 'pre-wrap' }}>{websiteData?.customFormUrls}</Typography>
            </Box>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Form Requirements</Typography>
              {formRequirements(websiteData)}
            </Box>
          </Box>
        </GridItem>
      </>
      )}

    </Box>
  </GridItem>
);
