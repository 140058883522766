import { useEffect, useState } from 'react';
import { locationsTypes } from 'layouts/StepperLayout';
import premiumListingSchema from './PremiumListingsInfo.schema';
import healthcareSchema from './PlHealthcareInfo.schema';
import LocationInfo from './LocationInfo';

const HcDefaultValues = {
  attributes: [],
  shortDescription: '',
  longDescription: '',
  keywords: [],
};

const PlDefaultValues = {
  ...HcDefaultValues,
  sameInfo: false,
};

export default function LocationInfoPage(props: any) {
  const { locationsType } = props;
  const [schema, setSchema] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (locationsType === locationsTypes.PREMIUM_LISTINGS) {
      setDefaultValues(PlDefaultValues);
      setSchema(premiumListingSchema);
    }

    if (locationsType === locationsTypes.HEALTHCARE) {
      setDefaultValues(HcDefaultValues);
      setSchema(healthcareSchema);
    }
  }, [locationsType]);

  useEffect(() => {
    setIsReady(!!schema && defaultValues);
  }, [schema, defaultValues]);

  return isReady && (
    <LocationInfo
      {...props}
      type={locationsType}
      schema={schema}
      defaultValues={defaultValues}
    />
  );
}
