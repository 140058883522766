import {t} from "i18next";

export const genderOptionRadioItems = [
  {field: 'male', label: t('pages.xmo.leadAds.male'), value: "male"},
  {field: 'female', label: t('pages.xmo.leadAds.female'), value: "female"},
  {field: 'both', label: t('pages.xmo.leadAds.both'), value: "both"},
];

export const callToActionDropDown = [
  {value: 'No Button', description: t('pages.xmo.leadAds.noButton')},
  {value: 'Apply Now', description: t('pages.xmo.leadAds.applyNow')},
  {value: 'Download', description: t('pages.xmo.leadAds.download')},
  {value: 'Get Quote', description: t('pages.xmo.leadAds.getQuote')},
  {value: 'Learn More', description: t('pages.xmo.leadAds.learnMore')},
  {value: 'Sign Up', description: t('pages.xmo.leadAds.signUp')},
  {value: 'Subscribe', description: t('pages.xmo.leadAds.subscribe')}
];

export const clientWantTheirAdsToAppearList = [
  {id: 'Mobile News Feed', label: t('pages.xmo.leadAds.mobileNewsFeed'), checked: false},
  {id: 'Facebook Audience Network', label: t('pages.xmo.leadAds.facebookAudienceNetwork'), checked: false},
  {id: 'Desktop News Feed', label: t('pages.xmo.leadAds.desktopNewsFeed'), checked: false},
  {id: 'MarketPlace', label: t('pages.xmo.leadAds.marketPlace'), checked: false},
  {id: 'Instagram', label: t('pages.xmo.leadAds.instagram'), checked: false},
];

export const leadAdsFormContactFieldsList = [
  {id: 'Phone Number', label: t('pages.xmo.leadAds.phoneNumber'), checked: false},
  {id: 'Zip Code', label: t('pages.xmo.leadAds.zipCode'), checked: false},
  {id: 'Email', label: t('pages.xmo.leadAds.email'), checked: false},
];

export const detailTargetingPreferencesList = [
  {
    id: 'Demographic Targeting',
    label: t('pages.xmo.leadAds.demographicTargeting'),
    checked: false,
    tooltipMessage: t('pages.xmo.leadAds.demographicTargetingTooltipMessage')
  },
  {
    id: 'Behavioral Targeting',
    label: t('pages.xmo.leadAds.behavioralTargeting'),
    checked: false,
    tooltipMessage: t('pages.xmo.leadAds.behavioralTargetingTooltipMessage')
  },
  {
    id: 'Interest Targeting',
    label: t('pages.xmo.leadAds.interestTargeting'),
    checked: false,
    tooltipMessage: t('pages.xmo.leadAds.interestTargetingTooltipMessage')
  },
];

export const yesNoList = [
  {value: 'Yes', description: t('pages.xmo.leadAds.yes')},
  {value: 'No', description: t('pages.xmo.leadAds.no')}
];

export const noneCustomList = [
  {value: 'None', description: t('pages.xmo.leadAds.none')},
  {value: 'Custom', description: t('pages.xmo.leadAds.custom')}
]
export const visualStrategyClientWantToEmployList = [
  {value: 'Single Image', description: t('pages.xmo.leadAds.singleImage')},
  {value: 'Multiple Videos', description: t('pages.xmo.leadAds.multipleVideos')},
  {value: 'Single Video', description: t('pages.xmo.leadAds.singleVideo')},
  {value: 'Image & Video Combination', description: t('pages.xmo.leadAds.imageVideoCombination')},
  {value: 'Multiple Images', description: t('pages.xmo.leadAds.multipleImages')}
]

export const clientBeProvidingImagesToUseList = [
  {value: 'Yes - All', description: t('pages.xmo.leadAds.yesAll')},
  {value: 'No', description: t('pages.xmo.leadAds.no')},
  {value: 'Yes - Some', description: t('pages.xmo.leadAds.yesSome')}
]

export const demographicTargetingList = [
  t('pages.xmo.leadAds.demographicHelp1'),
  t('pages.xmo.leadAds.demographicHelp2'),
  t('pages.xmo.leadAds.demographicHelp3'),
  t('pages.xmo.leadAds.demographicHelp4'),
  t('pages.xmo.leadAds.demographicHelp5'),
  t('pages.xmo.leadAds.demographicHelp6'),
  t('pages.xmo.leadAds.demographicHelp7'),
  t('pages.xmo.leadAds.demographicHelp8'),
]
export const behavioralTargetingList = [
  t('pages.xmo.leadAds.behavioralHelp1'),
  t('pages.xmo.leadAds.behavioralHelp2'),
  t('pages.xmo.leadAds.behavioralHelp3'),
  t('pages.xmo.leadAds.behavioralHelp4'),
  t('pages.xmo.leadAds.behavioralHelp5'),
  t('pages.xmo.leadAds.behavioralHelp6'),
  t('pages.xmo.leadAds.behavioralHelp7'),
  t('pages.xmo.leadAds.behavioralHelp8'),
]
export const interestTargetingList = [
  t('pages.xmo.leadAds.interestHelp1'),
  t('pages.xmo.leadAds.interestHelp2'),
  t('pages.xmo.leadAds.interestHelp3'),
  t('pages.xmo.leadAds.interestHelp4'),
  t('pages.xmo.leadAds.interestHelp5'),
  t('pages.xmo.leadAds.interestHelp6'),
  t('pages.xmo.leadAds.interestHelp7'),
  t('pages.xmo.leadAds.interestHelp8'),
  t('pages.xmo.leadAds.interestHelp9'),
]
