import { Urls } from 'navigation/Urls';
import { LocationSteps, isLocationStepCompleted } from 'shared/constants';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentLocation } from 'services/businessLocationSlice';
import { Box } from '@mui/material';
import { StepperLayout } from 'layouts/StepperLayout';
import LocationAddressPage from 'pages/locations/address/LocationAddressPage';
import LocationContactPage from 'pages/locations/contact/LocationContactPage';
import LocationHoursPage from 'pages/locations/hours/LocationHoursPage';
import LocationInfoPage from 'pages/locations/info/LocationInfoPage';
import ListingsPhotosPage from 'pages/locations/photos/listings/ListingsPhotosPage';
import LocationAdditionalPage from 'pages/locations/additional/LocationAdditionalPage';
import PlHealthcareProvider from './provider/PlHealthcareProvider';

const steps = [
  {
    name: Urls.PlHealthcareAddress,
    component: ((props) => <LocationAddressPage {...props} />),
    continueTitle: 'pages.premiumListings.location.healthcare.address.continueButtonLabel',
    title: 'pages.premiumListings.location.healthcare.address.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    name: Urls.PlHealthcareProvider,
    component: ((props) => <PlHealthcareProvider {...props} />),
    continueTitle: 'pages.premiumListings.location.healthcare.provider.continueButtonLabel',
    title: 'pages.premiumListings.location.healthcare.provider.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    name: Urls.PlHealthcareContact,
    component: ((props) => <LocationContactPage {...props} />),
    continueTitle: 'pages.premiumListings.location.healthcare.contact.continueButtonLabel',
    title: 'pages.premiumListings.location.healthcare.contact.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    name: Urls.PlHealthcareHours,
    component: ((props) => <LocationHoursPage {...props} />),
    continueTitle: 'pages.premiumListings.location.healthcare.hours.continueButtonLabel',
    title: 'pages.premiumListings.location.healthcare.hours.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    name: Urls.PlHealthcareInfo,
    component: ((props) => <LocationInfoPage {...props} />),
    continueTitle: 'pages.premiumListings.location.healthcare.info.continueButtonLabel',
    title: 'pages.premiumListings.location.healthcare.info.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    name: Urls.PlHealthcarePhotos,
    component: ((props) => <ListingsPhotosPage {...props} />),
    continueTitle: 'pages.premiumListings.location.healthcare.photos.continueButtonLabel',
    title: 'pages.premiumListings.location.healthcare.photos.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    name: Urls.PlHealthcareAdditional,
    component: ((props) => <LocationAdditionalPage {...props} />),
    continueTitle: 'pages.premiumListings.location.healthcare.additional.continueButtonLabel',
    title: 'pages.premiumListings.location.healthcare.additional.title',
    hideContinue: false,
    isCompleted: false,
  },
];

export default function EditHealthcareLocationStepper() {
  const location = useSelector(selectCurrentLocation);
  const [stepsWtithState] = useState(steps);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (location) {
      if (location && location.completed === true) {
        stepsWtithState[0].isCompleted = true;
        stepsWtithState[1].isCompleted = true;
        stepsWtithState[2].isCompleted = true;
        stepsWtithState[3].isCompleted = true;
        stepsWtithState[4].isCompleted = true;
        stepsWtithState[5].isCompleted = true;
        stepsWtithState[6].isCompleted = true;
      } else {
        stepsWtithState[0].isCompleted = isLocationStepCompleted(LocationSteps.ADDRESS, location.lastCompletedStep);
        stepsWtithState[1].isCompleted = isLocationStepCompleted(LocationSteps.PROVIDER, location.lastCompletedStep);
        stepsWtithState[2].isCompleted = isLocationStepCompleted(LocationSteps.CONTACT, location.lastCompletedStep);
        stepsWtithState[3].isCompleted = isLocationStepCompleted(LocationSteps.HOURS, location.lastCompletedStep);
        stepsWtithState[4].isCompleted = isLocationStepCompleted(LocationSteps.INFO, location.lastCompletedStep);
        stepsWtithState[5].isCompleted = isLocationStepCompleted(LocationSteps.PHOTOS, location.lastCompletedStep);
        stepsWtithState[6].isCompleted = isLocationStepCompleted(
          LocationSteps.ADDITIONAL_INFO,
          location.lastCompletedStep,
        );
      }
    }
    setLoading(false);
  }, [location]);

  return (
    isLoading ? (<Box />) : (<StepperLayout steps={stepsWtithState} formId="healthcare-listings-form" />)
  );
}
