import {
  Button,
  FormControlLabel, Grid, Input, Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getThemeFamilyById, selectCurrentThemeFamily, updateThemeFamily } from 'services/adminSlice';

export default function EditFamily() {
  const themeFamily = useSelector(selectCurrentThemeFamily);
  const { themeId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const methods = useForm({
    mode: 'all',
    defaultValues: { name: '' },
  });
  const {
    setValue, control, handleSubmit,
  } = methods;

  useEffect(() => {
    dispatch(getThemeFamilyById(parseInt(themeId, 10)));
  }, [themeId]);

  useEffect(() => {
    setValue('name', themeFamily?.name);
  }, [themeFamily]);

  const saveThemeFamily = (updated) => {
    dispatch(updateThemeFamily({ name: updated.name, id: themeFamily.id }));
    navigate('/admin/families');
  };

  return (
    <>
      <Typography variant="h2">Edit Theme Family</Typography>
      <Grid container>
        <Grid item xs={12}>
          <FormProvider {...methods}>
            <FormControlLabel
              sx={{ marginLeft: 0 }}
              label={<Typography>Name</Typography>}
              labelPlacement="start"
              control={(
                <Controller name="name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input type="text" onChange={onChange} value={value}
                      sx={{ marginLeft: 3 }}
                    />
                  )}
                />
              )}
            />
          </FormProvider>
        </Grid>
        <Grid item xs={12} marginTop={2}>
          <Button variant="contained"
            sx={{ marginRight: 3 }}
            onClick={handleSubmit(saveThemeFamily)}
          >
            <Typography>Save</Typography>
          </Button>
          <Link to="/admin/families">
            <Button variant="contained">
              <Typography>Back to Families</Typography>
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}
