/* eslint-disable no-unused-vars,@typescript-eslint/no-unused-vars */

import {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { useLocation } from 'react-router';

const initialState = {
  setSaveAndCloseAction: (fn) => {},
  setFormValid: (boolean) => {},
  setIsFormDirty: (boolean) => {},
  saveAndClose: (value) => {},
  isSavingAndClosing: false,
  isFormDirty: false,
  setShowModal: (boolean) => {},
  showModal: true,
};

const SaveAndCloseContext = createContext(initialState);

function SaveAndCloseProvider({ children } : { children: any }) {
  const [saveAndCloseFn, setSaveAndCloseFn] = useState({ fn: () => {} });
  const [formValid, setFormValid] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isSavingAndClosingState, setSavingAndClosingState] = useState(false);
  const navigation = useNavigationHandler();
  const history: any = useLocation();

  const setSaveAndCloseAction = (fn) => {
    setSaveAndCloseFn({ fn });
  };

  const saveAndClose = (value: boolean) => setSavingAndClosingState(value);

  useEffect(() => {
    if (isSavingAndClosingState) {
      // if the form is valid, we save it
      if (formValid && !history.pathname.includes('overview')) saveAndCloseFn.fn();
      navigation.to(Urls.Home); // otherwise, just close the page
      setSavingAndClosingState(false);
    }
  }, [isSavingAndClosingState]);

  const state = useMemo(() => ({
    setSaveAndCloseAction,
    setFormValid,
    setIsFormDirty,
    saveAndClose,
    isSavingAndClosing: isSavingAndClosingState,
    isFormDirty,
    setShowModal,
    showModal,
  }), [saveAndCloseFn, isSavingAndClosingState, isFormDirty, showModal]);

  return (
    <SaveAndCloseContext.Provider value={state}>
      {children}
    </SaveAndCloseContext.Provider>
  );
}

export { SaveAndCloseProvider, SaveAndCloseContext };

export const useSaveAndClose = () => useContext(SaveAndCloseContext);
