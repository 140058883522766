/* eslint-disable max-len */

export const StatusTrackerStages = {
  Website: ['Order Submitted', 'Payment Verified', 'Intake Call', 'Design', 'Development', 'Client Review', 'Site Live'],
  GoldSite: ['Order Submitted', 'Payment Verified', 'Intake Call', 'Design', 'Development', 'Client Review', 'Site Live'],
  LiteSite: ['Order Submitted', 'Payment Verified', 'Intake Call', 'Design', 'Development', 'Client Review', 'Site Live'],
  EcommerceMicrosite: ['Order Submitted', 'Payment Verified', 'Product Catalog', 'Design', 'Development', 'Client Review', 'Page Live'],
  LandingPage: ['Order Submitted', 'Payment Verified', 'Design', 'Development', 'Client Review', 'Page Live'],
  ChatProduct: ['Order Submitted', 'Payment Verified', 'Chat Widget Setup', 'Chat Live'],
  ChatPlatinumProduct: ['Order Submitted', 'Payment Verified', 'Chat Widget Setup', 'Chat Live'],
  PremiumListing: ['Order Submitted', 'Payment Verified', 'Awaiting Review', 'Completed'],
  MasterAddon: ['Order Submitted', 'Payment Verified', 'Intake Call', 'Design', 'Development', 'Client Review', 'Site Live'],
  CustomWebsite: [
    'Order Submitted', 'Payment Verified', 'Intake Call', 'Design', 'Content Layout & Site Development', 'Client Review', 'Site Live'],
  CustomLandingPage: ['Order Submitted', 'Payment Verified', 'Design', 'Development', 'Client Review', 'Site Live'],
  SEOStandard: ['Order Submitted', 'Payment Verified', 'Assignments Made', 'Campaign Live'],
  SEOCustom: ['Order Submitted', 'Payment Verified', 'Assignments Made', 'Campaign Live'],
  SEOBlogStandard: ['Order Submitted', 'Payment Verified', 'Assignments Made', 'Campaign Live'],
  SEOBlogEnhanced: ['Order Submitted', 'Payment Verified', 'Assignments Made', 'Campaign Live'],
  SMMStandard: ['Order Submitted', 'Payment Verified', 'Assignments Made', 'Campaign Live'],
  SMMCustom: ['Order Submitted', 'Payment Verified', 'Assignments Made', 'Campaign Live'],
  GenericProduct: ['Order Submitted', 'Payment Verified', 'Completed'],
  PiQLandingPages: ['Order Submitted', 'Payment Verified', 'Completed'],
};

export const offeringTypesUsed = [
  'PremiumListing',
  'LiteSite',
  'Website',
  'GoldSite',
  'ChatProduct',
  'ChatPlatinumProduct',
  'EcommerceMicrosite',
  'LandingPage',
  'CustomLandingPage',
  'CustomWebsite',
  'MasterAddon',
  'SEOStandard',
  'SEOCustom',
  'SEOBlogStandard',
  'SEOBlogEnhanced',
  'SMMStandard',
  'SMMCustom',
  'DMSNextCustomerCenterByDash',
  'DMSNextCustomerCenter',
  'GenericProduct',
  'PiQLandingPages',
];

export const addonsList = ['emailHosting', 'expandableMenus', 'hipaaForms',
  'onlineBooking', 'paymentForms', 'popups', 'videoBackground'];

export const cartAddonsModal = ['expandableMenus', 'paymentForms', 'popups', 'videoBackground'];
