import * as yup from 'yup';
import i18n from 'i18n/i18n';

const { t } = i18n;

const youtubeUrlRegex = /^(?:(?:http|https):\/\/)?(www\.)?(?!youtube\.com\/live)(youtube.com|youtu.be)\/.+/;

const schema = {
  inventoryTier: yup.string().required(t('pages.xmo.solutionsStrategy.brandAmplifier.inventoryTierError')),
  youtubeVideoUrl: yup.string()
    .required(t('pages.xmo.solutionsStrategy.brandAmplifier.youtubeVideoUrlError'))
    .url(t('pages.xmo.solutionsStrategy.brandAmplifier.youtubeVideoUrlError'))
    .test(
      'is-facebook-url',
      t('pages.xmo.solutionsStrategy.brandAmplifier.youtubeVideoUrlError'),
      value => youtubeUrlRegex.test(value),
    ),
  additionalYoutubeVideoUrls: yup.string().notRequired(),
  specialCategory: yup.string().required(t('pages.xmo.solutionsStrategy.brandAmplifier.specialCategoryError')),
  keywords: yup.string().required(t('pages.xmo.solutionsStrategy.brandAmplifier.keywordsError')),
  pullSiteUrl: yup.string()
    .url('Please enter a valid URL that includes either http:// or https://')
    .required(t('pages.xmo.solutionsStrategy.brandAmplifier.pullSiteUrlError')),
  csmEmail: yup.string().email(t('pages.xmo.solutionsStrategy.brandAmplifier.csmEmailError')).required(),
  websiteHighPriorityUrls: yup.string()
    .required(t('pages.xmo.solutionsStrategy.brandAmplifier.websiteHighPriorityError')),
  websiteFormUrls: yup.string()
    .required(t('pages.xmo.solutionsStrategy.brandAmplifier.websiteFormUrlsError')),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
