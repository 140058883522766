import { ComponentProps } from 'react';

import i18n from 'i18n/i18n';
import { IQButtonRadioContainer } from '@gannettdigital/shared-react-components';

const { t } = i18n;

type Checkbox = {
  title: string;
  field: string;
  label: string;
};

export const checkboxes :Checkbox[] = [
  {
    title: '',
    field: 'additionalDetails.provideEstimateInPerson',
    label: t('pages.chat.scriptDetails.inPerson'),
  },
  {
    title: '',
    field: 'additionalDetails.provideEstimateByPhone',
    label: t('pages.chat.scriptDetails.overThePhone'),
  },
  {
    title: '',
    field: 'additionalDetails.other',
    label: t('pages.chat.scriptDetails.other'),
  },
];

export const optionsEstimatesFree :ComponentProps<typeof IQButtonRadioContainer>['optionsList'] = [
  {
    label: 'Yes',
    field: 'estimatesFree',
    value: 'yes',
  },
  {
    label: 'No, there’s a service fee',
    field: 'estimatesFree',
    value: 'no',
  },
];

export const options :ComponentProps<typeof IQButtonRadioContainer>['optionsList'] = [
  {
    label: t('pages.chat.scriptDetails.standardBusinessHours'),
    field: 'callConnectAvailable',
    value: 'standardBusinessHours',
  },
  {
    label: t('pages.chat.scriptDetails.customCallConnectHours'),
    field: 'callConnectAvailable',
    value: 'customCallConnectHours',
  },
];

export const timezoneList = [
  {
    heading: 'US & CANADA',
    options: [
      {
        label: 'Pacific',
        value: 'US & CANADA - Pacific',
      },
      {
        label: 'Mountain',
        value: 'US & CANADA - Mountain',
      },
      {
        label: 'Central',
        value: 'US & CANADA - Central',
      },
      {
        label: 'Eastern',
        value: 'US & CANADA - Eastern',
      },
      {
        label: 'Atlantic',
        value: 'US & CANADA - Atlantic',
      },
      {
        label: 'Hawaii',
        value: 'US & CANADA - Hawaii',
      },
      {
        label: 'Alaska',
        value: 'US & CANADA - Alaska',
      },
    ],
  },
  {
    heading: 'AUSTRALIA',
    options: [
      {
        label: 'Western',
        value: 'AUSTRALIA - Western',
      },
      {
        label: 'Central',
        value: 'AUSTRALIA - Central',
      },
      {
        label: 'Eastern',
        value: 'AUSTRALIA - Eastern',
      },
    ],
  },
  {
    heading: 'NEW ZEALAND',
    options: [
      {
        label: 'Standard',
        value: 'NEW ZEALAND - Standard',
      },
    ],
  },
];
