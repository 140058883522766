/* eslint-disable import/no-extraneous-dependencies */
import {useTheme} from '@emotion/react';
import {IQSwitch, IQButtonLink, IQCheckbox} from '@gannettdigital/shared-react-components';
import {Typography, Button, Grid} from '@mui/material';
import GridItem from 'components/grid/GridItem';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import {useState, useEffect} from 'react';
import {useFormContext, useFieldArray} from 'react-hook-form';
import {useTranslation, Trans} from 'react-i18next';
import moment from 'moment';
import NeTimePicker from './TimePicker';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Box} from "@mui/system";
import ErrorIcon from "@mui/icons-material/Error";

export const displayHour = (hourValue, fullHoursLabel) => {
  const splitHourValue = hourValue.split(':');
  const numericHour = `${splitHourValue[0]}.${splitHourValue[1]}`;
  const hour = +numericHour * 2;
  if (hour === 24) {
    return fullHoursLabel;
  }

  const ampm = +numericHour < 12 ? 'AM' : 'PM';
  const hoursPart = +numericHour > 12 ? Math.floor(+numericHour) - 12 : Math.floor(+numericHour);

  if (hoursPart === 0 && ampm === 'PM') {
    return `12:${splitHourValue[1]} ${ampm}`;
  }

  return `${hoursPart}:${splitHourValue[1]} ${ampm}`;
};

export default function OperationDayRow({
                                          day,
                                          rowNumber,
                                          closedButtonClicked,
                                          applyToAllButtonClicked
                                        }: {
  day: any,
  rowNumber: any,
  closedButtonClicked: any,
  applyToAllButtonClicked: any
}) {
  const {t} = useTranslation();
  const theme = useTheme();
  const {
    setValue, getValues, trigger, watch, setError, formState: {errors},
  } = useFormContext();
  const [open, setOpen] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [hourToRemove, setHourToRemove] = useState(-1);
  const [lastId, setLastId] = useState(1);
  const [rangeDisplay, setRangeDisplay] = useState('');
  const [isAllDay, setIsAllDay] = useState(false);
  const [hasUserInput, setHasUserInput] = useState(false);
  const [indexTime, setIndexTime] = useState<any>({});
  const [openingTimeError, setOpeningTimeError] = useState<boolean>(false);
  const [hasErrorVar, setHasErrorVar] = useState<boolean>(false);

  const {fields: hours, append: addHours, remove: removeHour} = useFieldArray({
    name: `days.${rowNumber}.hours`,
  });

  useEffect(() => {
    setOpen(day.open);
  }, [day.open]);

  const momentToNumber = (value) => {
    const momentHour = moment(value).hours().toString().length === 1
        ? `0${moment(value).hours()}`
        : moment(value).hours().toString();
    const momentMinutes = moment(value).minutes().toString().length === 1
        ? `0${moment(value).minutes()}`
        : moment(value).minutes().toString();
    const convertedValue = `${momentHour}:${momentMinutes}`;
    return convertedValue;
  };

  const onAddHours = (index: any) => {
    addHours({opensAt: null, closesAt: null, id: lastId + 1});
    setLastId(lastId + 1);
  };

  const deleteHour = () => {
    removeHour(hourToRemove);
    setHourToRemove(-1);
  };

  const cancelRemoveHour = () => {
    setShowRemoveDialog(false);
    setHourToRemove(-1);
  };

  const capitalize = (s) => s[0].toUpperCase() + s.slice(1);

  const onRemoveHour = (index) => {
    setHasErrorVar(false);
    setIndexTime({})
    const data = getValues().days?.at(rowNumber)?.hours?.at(index);
    if (data.opensAt && data.closesAt) {
      setRangeDisplay(
          `${capitalize(day.weekday)}, ${displayHour(data.opensAt, '')} - ${displayHour(data.closesAt, '')}`,
      );
    } else setRangeDisplay(`${capitalize(day.weekday)}`);
    setHourToRemove(index);
    setShowRemoveDialog(true);
    setHasUserInput(true)
  };

  useEffect(() => {
    const isFullday = getValues(`days.${rowNumber}.hours.${0}.allDay`);
    setIsAllDay(isFullday);
    if (isFullday) {
      setValue(`days.${rowNumber}.hours.${0}.opensAt`, '00:00');
      setValue(`days.${rowNumber}.hours.${1}.opensAt`, null);
      setValue(`days.${rowNumber}.hours.${0}.closesAt`, '00:00');
      setValue(`days.${rowNumber}.hours.${1}.closesAt`, null);
      removeHour(1);
    }
  }, [watch(`days.${rowNumber}.hours.${0}.allDay`)]);

  const stringHourToNumber = (hour) => {
    const split = hour.split(':');
    return `${split[0]}.${split[1]}`;
  };

  const convertTimeStringToSeconds = (str: string) => {
    if (str) {
      const [hours, minutes] = str.split(':');
      return parseInt(hours) * 60 + parseInt(minutes);
    }
    return 0;
  };

  const validateClosingHour = (opening, closing, index) => {
    if (opening && closing) {
      const openingNumber = +stringHourToNumber(opening);
      const closingNumber = +stringHourToNumber(closing);

      if (closingNumber <= openingNumber && closingNumber !== 0) {
        setOpeningTimeError(true)
        setError(`days.${rowNumber}.hours.${index}.closesAt`, {
          message: t('pages.business.location.hours.hoursOfOperation.closingHourAfterOpeningHourErrorMessage'),
        });
      } else {
        setOpeningTimeError(false)
        trigger('days');
      }
    }
  };

  const handleChange = (value, index, type) => {
    setHasUserInput(true);
    if (!value) {
      setValue(`days.${rowNumber}.hours.${index}.${type}`, null);
      trigger('days');
      return;
    }
    setValue(`days.${rowNumber}.hours.${index}.${type}`, momentToNumber(value));
    const opening = getValues(`days.${rowNumber}.hours.${index}.opensAt`);
    const closing = getValues(`days.${rowNumber}.hours.${index}.closesAt`);
    validateClosingHour(opening, closing, index);
    if (index > 0) {
      setIndexTime({rowNumber, opening, closing});
      if (day.open && convertTimeStringToSeconds(day.hours[0].closesAt) >= convertTimeStringToSeconds(opening)) {
        setError(`days.${rowNumber}.hours.${index}.opensAt`, {
          message: t('pages.business.location.hours.hoursOfOperation.openingHourAfterClosingHourOfPreviousRowErrorMessage'),
        });
        setHasErrorVar(true);
      } else {
        setHasErrorVar(false);
      }
    }
  };

  const setGridColumns = (index) => {
    if (isAllDay) return [0, 1, 3, 0];
    if (index === 0) return [7, 1, 3, 1];
    return [7, 0, 0, 2];
  };

  const applyToAll = (clickedRowNumber: any) => {
    const allDays = getValues('days');
    const openingTime = getValues(`days.${clickedRowNumber}.hours.${0}.opensAt`);
    const closingTime = getValues(`days.${clickedRowNumber}.hours.${0}.closesAt`);
    const isOpen = true;
    let indexMainTime: any = {};
    let indexMainRowNumber = null;
    allDays.forEach((day, index) => {
      if (clickedRowNumber === index) {
        if (day.hours.length > 1) {
          indexMainTime = day.hours[1]
          indexMainRowNumber = index
        }
      }
    })
    allDays.forEach((day, index, array) => {
      if(day.open){
        day.hours.forEach((hour, hourIndex) => {
          if (openingTime === '00:00' && closingTime === '00:00') {
            setValue(`days.${index}.hours.${0}.allDay`, true);
          } else {
            setValue(`days.${index}.hours.${0}.allDay`, false)
          }
        })
        setValue(`days.${index}.open`, isOpen);
        if (index !== clickedRowNumber) {
          if (array[clickedRowNumber].hours.length === 2 && day.hours.length === 1 && (Object.keys(indexTime).length > 0 || Object.keys(indexMainTime).length > 0)) {
            setValue(`days.${index}.hours`, [...day.hours, {
              opensAt: null,
              closesAt: null,
              id: lastId + 1
            }]);
            setLastId(lastId + 1); // Update the last ID
          } else if (array[clickedRowNumber].hours.length === 1) {
            setValue(`days.${index}.hours`, [{opensAt: null, closesAt: null, allDay: false}]);
          }
        }
        day.hours.forEach((hour, hourIndex) => {
          if (hourIndex === 0) {
            if (openingTime === '00:00' && closingTime === '00:00') {
              setValue(`days.${index}.hours.${hourIndex}.opensAt`, openingTime);
              setValue(`days.${index}.hours.${hourIndex}.closesAt`, closingTime);
              setValue(`days.${index}.hours.${hourIndex}.allDay`, true);
              setIsAllDay(true)
            } else {
              setValue(`days.${index}.hours.${hourIndex}.opensAt`, openingTime);
              setValue(`days.${index}.hours.${hourIndex}.closesAt`, closingTime);
            }
          }
          if (hourIndex === 1) {
            if (indexMainRowNumber === clickedRowNumber && (Object.keys(indexTime).length > 0 || Object.keys(indexMainTime).length > 0)) {
              const openingIndexTime = indexTime.opening || indexMainTime.opensAt;
              const closingIndexTime = indexTime.closing || indexMainTime.closesAt;
              setValue(`days.${index}.hours.${hourIndex}.opensAt`, openingIndexTime);
              setValue(`days.${index}.hours.${hourIndex}.closesAt`, closingIndexTime);
            }
          }
        });
      }else{
        setValue(`days.${index}.open`, false);
      }
    });
    setHasUserInput(false);
    applyToAllButtonClicked();
    // Trigger form re-render
    trigger();
  };

  const openChanged = (isOpen) => {
    closedButtonClicked(rowNumber, isOpen)
    if (!isOpen) trigger(`days.${rowNumber}.hours`);
    setOpen(isOpen);
  };

  return (
      <GridItem marginBottom={0} sizes={[2, 2, 8]} key={day.weekday}
                sx={{
                  marginBottom: '1.5rem',
                  '& input.rc-time-picker-input': {
                    padding: '8px 16px',
                    height: '40px',
                    fontSize: 'inherit',
                    fontFamily: 'Unify Sans',
                    borderRadius: '2px',
                    border: '1px solid #292928',
                    color: '#000000',
                    textTransform: 'uppercase',
                  },
                  '& input.rc-time-picker-input::placeholder': {
                    textTransform: 'none',
                  },
                  '& .rc-time-picker svg': {
                    position: 'absolute',
                    right: '12px',
                    top: '8px',
                  },
                }}
      >
        <Typography sx={{lineHeight: '40px'}}>{capitalize(day.weekday)}</Typography>
        <IQSwitch
            label={open
                ? t('pages.business.location.hours.hoursOfOperation.open')
                : t('pages.business.location.hours.hoursOfOperation.closed')}
            name={`days.${rowNumber}.open`}
            onChange={openChanged}
            unboxed
        />
        {open && (
            hours.map((hour, index) => (
                <GridItem marginBottom={0} sizes={setGridColumns(index)} key={hour.id}
                          sx={{
                            alignItems: 'baseline',
                            marginBottom: `${!isAllDay ? '1rem' : '0'}`,
                            '&>.MuiGrid-item': {paddingLeft: '0'},
                          }}
                >
                  {!isAllDay && (
                      <GridItem marginBottom={0} sizes={[5.5, 1, 5.5]}
                                sx={{alignItems: 'baseline'}}>
                        <NeTimePicker
                            name={`days.${rowNumber}.hours.${index}.opensAt`}
                            placeholder={'hh:mm aa'}
                            defaultValue={null}
                            onChange={value => handleChange(value, index, 'opensAt')}
                            hasError={!!errors?.days?.[rowNumber]?.hours?.[index]?.opensAt}
                            errorText={errors?.days?.[rowNumber]?.hours?.[index]?.opensAt?.message}
                        />
                        <Typography sx={{textAlign: 'center'}}>-</Typography>
                        <NeTimePicker
                            name={`days.${rowNumber}.hours.${index}.closesAt`}
                            placeholder={'hh:mm aa'}
                            defaultValue={null}
                            onChange={value => handleChange(value, index, 'closesAt')}
                            hasError={!!errors?.days?.[rowNumber]?.hours?.[index]?.closesAt}
                            errorText={errors?.days?.[rowNumber]?.hours?.[index]?.closesAt?.message}
                        />
                      </GridItem>
                  )}
                  {!isAllDay && (
                      <>
                        {(index === 0 && hours.length < 2) && (
                            <IQButtonLink
                                startIcon={<AddCircleOutlineIcon/>}
                                onClick={() => onAddHours(index)}>
                              {''}
                            </IQButtonLink>
                        )}
                        {index > 0 && (
                            <Button
                                onClick={() => onRemoveHour(index)}
                                sx={{color: theme.palette.primary.main, marginTop: '-5px'}}
                            >
                              <RemoveCircleOutlineIcon/>
                            </Button>
                        )}
                      </>
                  )}
                  {index === 0 && (
                      <GridItem marginBottom={0} sx={{paddingLeft: '12px', paddingTop: '8px'}}>
                        <IQCheckbox
                            label={t('pages.business.location.hours.hoursOfOperation.24Hrs')}
                            field={`days.${rowNumber}.hours.${index}.allDay`}
                            name={`days.${rowNumber}.hours.${index}.allDay`}
                            onChange={() => setHasUserInput(true)}
                        />
                      </GridItem>
                  )}
                  {hasUserInput && index <= 0 && (
                      <GridItem>
                        <Button
                            disabled={hasErrorVar || openingTimeError}
                            onClick={() => applyToAll(rowNumber)}
                            sx={{
                              color: theme.palette.primary.main,
                            }}
                        >
                          Apply to All
                        </Button>
                      </GridItem>
                  )}
                </GridItem>
            ))
        )}
        {hasErrorVar && Object.keys(errors).length === 0 &&
            <Grid container ml={33}>
              <Grid item xs={12}>
                <Typography
                    sx={{
                      color: '#C20F1E',
                      fontSize: '14px',
                      marginTop: '10px',
                      marginBottom: '15px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    variant="subtitle1"
                >
                  <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <ErrorIcon
                        sx={{
                          color: '#C20F1E',
                          fontSize: '16px',
                          marginRight: '5px'
                        }}
                    />
                    {t('pages.business.location.hours.hoursOfOperation.openingHourAfterClosingHourOfPreviousRowErrorMessage')}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
        }
        <CoModalConfirm open={showRemoveDialog}
                        title={t('pages.business.location.hours.hoursOfOperation.removeHours')}
                        description={(
                            <Trans
                                i18nKey="pages.business.location.hours.hoursOfOperation.removeHoursMessage"
                                values={{range: rangeDisplay}}
                                components={{strong: <strong/>}}
                            />
                        )}
                        confirmText="Remove"
                        handleClose={cancelRemoveHour}
                        handleConfirm={deleteHour}
        />
      </GridItem>
  );
}
