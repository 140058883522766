import * as yup from 'yup';

const schema = {
  customSow: yup.string().required(),
  otherGoals: yup.string().required(),
  competitorSocial: yup.string().optional(),
  clientSchedule: yup.string().optional(),
  topicsToFocus: yup.string().optional(),
  topicsToAvoid: yup.string().optional(),
  additionalNotes: yup.string().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
