/* eslint-disable no-param-reassign,max-len */
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

export const thunkBuilder = (builder: ActionReducerMapBuilder<any>) => ({
  builder,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  addCase: thunkReducerBuilder(builder),
});

const thunkReducerBuilder = (builder: ActionReducerMapBuilder<any>) => (
  fun: any,
  name: string,
  options?: {
    completeStep?: string,
    contentValueCallback?: (payload: any, content: any) => any
  },
) => {
  builder
    .addCase(fun.fulfilled, (state, action) => {
      if (options?.contentValueCallback) state[name].content = options.contentValueCallback(action.payload, state[name].content);
      else state[name].content = action.payload;
      state[name].status = 'idle';
      if (options?.completeStep) state[options.completeStep] = true;
    }).addCase(fun.pending, (state) => {
      state[name].status = 'loading';
    }).addCase(fun.rejected, (state, action) => {
      state[name].status = 'failed';
      // @ts-ignore
      state[name].message = action.error?.message;
    });
  return thunkBuilder(builder);
};

export const isJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
