/* eslint-disable no-param-reassign */
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersList, selectOrdersStatus } from 'services/myOrdersSlice';
import { getUserInfo, selectUserAccess } from 'services/userSlice';
import { getAllProductTypes, selectAllProductTypes } from 'services/offerMappingSlice';
import OrdersTable from './OrdersTable';
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import LeftNavFilter from "pages/orders/LeftNavFilter";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import MyOrderPageFilter from "pages/orders/MyOrderPageFilter";
import debounce from "lodash.debounce";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({children, index, value}: TabPanelProps) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
          <Box paddingTop={4}>
            <Typography>{children}</Typography>
          </Box>
      )}
    </div>
);

export default function MyOrders() {
  const userAccess = useSelector(selectUserAccess);
  let notAuthorized = false;
  const currentUrl = window.location.href;
  const ordersStatus = useSelector(selectOrdersStatus);

  if (currentUrl.includes('not-authorized')) {
    notAuthorized = true;
  }

  const allProductTypes = useSelector(selectAllProductTypes);
  const [currentTab, setCurrentTab] = useState(0);
  const [filterValue, setFilterValue] = useState<any>([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [mailSuccessNotifier, setMailSuccessNotifier] = useState(false);
  const [mailFailureNotifier, setMailFailureNotifier] = useState(false);
  const [postFinalizationBanner, setPostFinalizationBanner] = useState(false);
  const [canSeePunchlist, setCanSeePunchlist] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const queryParams = useLocation();

  const getProductList = () => {
    const searchParams = new URLSearchParams(queryParams.search);
    const typeFilterKeys: string[] = [];
    searchParams.forEach((value, key) => {
      if (key.startsWith('typeFilters')) {
        const filterKey = key.match(/\[(\d+)\]/)?.[1];
        if (filterKey) {
          typeFilterKeys.push(filterKey); // Store the number
        }
      }
    });
    return typeFilterKeys
  };

  const {
    control,
    watch,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: {errors}
  } = useForm({
    defaultValues: {
      productList: [],
      status: '',
      startDate: null,
      endDate: null,
      search: '',
    },
  });

  const setBanner = (mailSuccessNotifier || mailFailureNotifier || postFinalizationBanner) && (
      <Grid item xs={4}
            marginTop={4}
            marginBottom={4}
            sx={{textAlign: 'center', margin: '0 auto'}}
      >
        {mailSuccessNotifier
            && (
                <Alert
                    severity="success"
                    sx={{
                      border: '1px solid #DFF0D8',
                      background: '#DFF0D8',
                      borderRadius: '4px',
                      textAlign: 'center',
                      color: '#3C763D',
                      '& .MuiAlert-icon': {color: '#3C763D'},
                    }}
                >
                  <AlertTitle>Success</AlertTitle>
                  Image Selection Email sent to user successfully!
                </Alert>
            )}
        {mailFailureNotifier
            && (
                <Alert
                    severity="error"
                    sx={{
                      border: '1px solid #F5D5D7',
                      background: '#F5D5D7',
                      borderRadius: '4px',
                      textAlign: 'center',
                      color: '#C20F1E',
                      '& .MuiAlert-icon': {color: '#C20F1E'},
                    }}
                >
                  <AlertTitle>Error</AlertTitle>
                  Unable to send Selection Email to user!
                </Alert>
            )}
        {postFinalizationBanner
            && (
                <Alert
                    severity="success"
                    sx={{
                      border: '1px solid #DFF0D8',
                      background: '#DFF0D8',
                      borderRadius: '4px',
                      textAlign: 'center',
                      color: '#3C763D',
                      '& .MuiAlert-icon': {color: '#3C763D'},
                    }}
                >
                  <AlertTitle>Order Fulfilled</AlertTitle>
                  There may be a delay between order submission and account creation / listings
                  added to Uberall.
                </Alert>
            )}
      </Grid>
  );

  const setOrderType = (tab) => {
    switch (tab) {
      case 1:
        return 'NEON_CUSTOM_ORDERS';
      case 2:
        return 'PUNCHLIST_ORDERS';
      default:
        return 'NEON_ORDERS';
    }
  };

  useMemo(() => {
    if (userAccess.isAdmin || userAccess.canDoFulfillment) {
      setCanSeePunchlist(true);
    }
  }, [userAccess]);

  useEffect(() => {
    setValue('productList', getProductList() || []);
    setValue('status', searchParams.get('status') || '');
    setValue('startDate', searchParams.get('dateFilters[initialDate]') || null);
    setValue('endDate', searchParams.get('dateFilters[finalDate]') || null);
    setValue('search', searchParams.get('searchTerm') || '');
  }, [queryParams]);

  const handleChange = (event: SyntheticEvent, tabIndex: number) => {
    switch (tabIndex) {
      case 1:
        setCurrentTab(1);
        resetParams();
        navigate('/orders/quoteRequests');
        break;
      case 2:
        setCurrentTab(2);
        resetParams();
        navigate('/orders/punchList');
        break;
      default:
        resetParams();
        navigate('/orders');
        setCurrentTab(0);
        break;
    }
  };

  const buildParams = (resetForm = false) => {
    const {productList, startDate, endDate, status, search} = getValues();
    const params: any = {};

    if (resetForm) {
      reset();
      return params;
    } else {
      if (searchParams.get('page')) {
        params.page = searchParams.get('page');
      }

      if (searchParams.get('size')) {
        params.size = searchParams.get('size');
      }

      if (searchParams.get('cron')) {
        params.cron = searchParams.get('cron');
      }

      if (searchParams.get('id')) {
        params.id = searchParams.get('id');
      }

      if (searchParams.get('gmaid')) {
        params.gamid = searchParams.get('gmaid');
      }

      if (searchParams.get('biz')) {
        params.biz = searchParams.get('biz');
      }

      if (startDate && endDate) {
        params['dateFilters[initialDate]'] = startDate;
        params['dateFilters[finalDate]'] = endDate;
      }

      if (status) {
        params.status = status;
      }

      if (search) {
        params.searchTerm = search;
      }

      if (productList?.length > 0) {
        productList.map(type => `typeFilters[${type}]`).forEach(type => {
          params[type] = 'on';
        });
      }

      return params;
    }
  };

  const buildQueryParams = (type, value) => {
    if (value === null) return undefined;
    switch (type) {
      case 'page':
        return `&page=${value}`;
      case 'size':
        return `&size=${value}`;
      case 'dateStart':
        return isValidDate(value) ? `&dateStart=${new Date(value).toISOString()}` : '';
      case 'dateEnd':
        return isValidDate(value) ? `&dateEnd=${new Date(value).toISOString()}` : '';
      case 'search':
        return `&search=%25${value}%25`;
      case 'product':
        return value.length > 0 ? value.map(e => `&selectedProductIds=${e}`).join('') : undefined;
      case 'cron':
        return `&sort=createdAt%2C${value}`;
      case 'id':
        return `&sort=id%2C${value}`;
      case 'gmaid':
        return `&sort=gmaid%2C${value}`;
      case 'biz':
        return `&sort=businessName%2C${value}`;
      case 'status':
        return `&neonOrderStatus=${value}`;
      default:
        return value;
    }
  };

  const buildQuery = (searchParams) => {
    const queryObj = {
      page: buildQueryParams('page', searchParams.get('page') || 0),
      size: buildQueryParams('size', searchParams.get('size') || 10),
      selectedProductIds: filterValue ? buildQueryParams(
          'product',
          filterValue,
      ) : '',
      search: buildQueryParams('search', searchParams.get('searchTerm')),
      dateStart: buildQueryParams('dateStart', searchParams.get('dateFilters[initialDate]')),
      dateEnd: buildQueryParams('dateEnd', searchParams.get('dateFilters[finalDate]')),
      created_at: buildQueryParams('cron', searchParams.get('cron')),
      businessName: buildQueryParams('biz', searchParams.get('biz')),
      id: buildQueryParams('id', searchParams.get('id')),
      gmaid: buildQueryParams('gmaid', searchParams.get('gmaid')),
      neonOrderStatus: buildQueryParams('status', searchParams.get('status')),
    };

    return formatQueryObjIntoString(queryObj);
  };

  const buildCustomQuoteQuery = (searchParams) => {
    const queryObj = {
      page: buildQueryParams('page', searchParams.get('page') || 0),
      size: buildQueryParams('size', searchParams.get('size') || 10),
      created_at: buildQueryParams('cron', searchParams.get('cron')),
      businessName: buildQueryParams('biz', searchParams.get('biz')),
      id: buildQueryParams('id', searchParams.get('id')),
      gmaid: buildQueryParams('gmaid', searchParams.get('gmaid')),
    };

    return formatQueryObjIntoString(queryObj);
  };

  useEffect(() => {
    getUserInfo('me');
    const currentUrl = window.location.href;
    if (currentUrl.includes('success')) {
      setMailSuccessNotifier(true);
      navigate('/orders');
    }

    if (currentUrl.includes('fail')) {
      setMailFailureNotifier(true);
      navigate('/orders');
    }

    if (currentUrl.includes('finalized')) {
      setPostFinalizationBanner(true);
      navigate('/orders');
    }
  }, [window.location.href]);

  useEffect(() => {
    if (location.pathname.endsWith('quoteRequests')) {
      setCurrentTab(1);
    } else if (location.pathname.endsWith('punchList')) {
      setCurrentTab(2);
    }
  }, [location]);

  const isValidDate = (date) => {
    const parsedDate = new Date(date);
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(parsedDate.getTime());
  };

  useEffect(() => {
    dispatch(getAllProductTypes({}));
  }, []);

  useEffect(() => {
    const queryConstruct = buildQuery(searchParams);
    const customQuoteConstruct = buildCustomQuoteQuery(searchParams);
    if (ordersStatus !== 'loading') {
      if (currentTab !== 1) {
        dispatch(getOrdersList({orderType: setOrderType(currentTab), query: queryConstruct}));
      } else {
        dispatch(getOrdersList({orderType: setOrderType(1), query: customQuoteConstruct}));
      }
    }
  }, [searchParams, currentTab]);

  const resetParams = () => {
    setSearchParams({});
  };

  const formatQueryObjIntoString = (queryObj) => {
    const selectedParams = Object.keys(queryObj).reduce((obj, key) => {
      if (queryObj[key] !== undefined) {
        obj[key] = queryObj[key];
      }
      return obj;
    }, {});
    return Object.values(selectedParams).join('');
  };

  const handleDebouncedSearchText = () => {
    setSearchParams(buildParams());
  }

  const debouncedChangeHandler = useCallback(
      debounce(handleDebouncedSearchText, 1000),
      [],
  );

  const handleSearchValueChange = (e: any) => {
    setValue('search', e.target.value);
    debouncedChangeHandler(e);
  }

  const productList = watch('productList') || [];

  useEffect(() => {
    setFilterValue(productList)
  }, [productList]);

  const handleFilterButtonClick = () => {
    setIsFilterOpen(!isFilterOpen);
  }

  const resetButtonHandler = () => {
    setSearchParams(buildParams(true));
    setIsFilterOpen(false);
    window.location.reload();
  }

  const applyButtonHandler = () => {
    setSearchParams(buildParams());
    setIsFilterOpen(false);
  }

  return (
      <Grid container sx={{width: '1500px', margin: 'auto', height: 'auto'}}>
        {setBanner}
        <Grid
            item
            sx={{
              width: isFilterOpen ? '1200px' : '1500px',
              marginLeft: isFilterOpen ? 'auto' : 'initial',
              transition: 'width 0.3s ease, margin-left 0.3s ease',
            }}
        >
          <Grid item xs={12}>
            {(notAuthorized && (
                <Alert severity="error" sx={{textAlign: 'left', fontSize: '1.0em', border: '1'}}>
                  <AlertTitle>Access Denied</AlertTitle>
                  You are not authorized to access that page.
                </Alert>
            ))}
            <Typography variant="h4">My Orders</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
              <Tabs
                  value={currentTab}
                  onChange={handleChange}
                  sx={{
                    '.MuiTab-root': {
                      textTransform: 'none',
                      alignItems: 'flex-start',
                      paddingLeft: 0,
                      paddingRight: 0,
                      minWidth: 0,
                      marginRight: 4,
                    },
                  }}
              >
                <Tab label="Orders"/>
                <Tab label="Quote Requests"/>
                {canSeePunchlist && <Tab label="Punchlist"/>}
              </Tabs>
            </Box>
          </Grid>
          {/* Tab Panels */}
          {currentTab !== 1 &&
              <Grid container mt={5} sx={{alignItems: 'flex-end'}}>
                <Grid item xs={1.5}>
                  <Box
                      mr={2}
                      onClick={handleFilterButtonClick}
                      sx={{
                        backgroundColor: isFilterOpen ? '#0046FF' : '#ffffff',
                        borderRadius: '20px',
                        border: '2px solid #0046FF',
                        color: isFilterOpen ? '#ffffff' : '#0046FF',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '40px',
                        cursor: 'pointer',
                      }}
                  >
                    <FilterAltOutlinedIcon/>
                    <Typography variant="body1" marginLeft={1}>
                      Filter
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={10.5}>
                  <TextField
                      variant="outlined"
                      placeholder="Search orders..."
                      fullWidth
                      onChange={handleSearchValueChange}
                      value={watch('search')}
                      InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon/>
                            </InputAdornment>
                        ),
                        sx: {
                          height: '40px',
                          backgroundColor: '#fff'
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: '45px',
                          '& fieldset': {
                            borderColor: '#716F6F',
                          },
                          '&:hover fieldset': {
                            borderColor: '#716F6F',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#716F6F',
                          },
                        },
                      }}
                  />
                </Grid>
                <Grid item xs={12} marginTop={4}>
                  <MyOrderPageFilter
                      setValue={setValue}
                      watch={watch}
                      reset={reset}
                      searchParams={searchParams}
                      allProductTypes={allProductTypes}
                      setSearchParams={setSearchParams}
                      buildParams={buildParams}
                  />
                </Grid>
                <Grid item xs={12} marginTop={4}>
                  <OrdersTable/>
                </Grid>
              </Grid>
          }
          <Grid item xs={12}>
            <TabPanel value={currentTab} index={1}>
              {currentTab === 1 && <OrdersTable/>}
            </TabPanel>
          </Grid>
        </Grid>
        {/* Drawer implementation */}
        <Drawer
            anchor="left"
            open={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
            sx={{
              '& .MuiDrawer-paper': {
                width: '400px',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }
            }}
        >
          <Grid
              item
              sx={{
                width: '400px',
                marginTop: '50px',
                position: 'relative',
                flexGrow: 1,
              }}
          >
            <Grid
                sx={{
                  backgroundColor: '#fff',
                  padding: '20px',
                  overflowY: 'auto',
                  flexGrow: 1,
                }}
            >
              <Typography variant={"h3"}>
                Filters
              </Typography>
              <IconButton
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    color: '#000',
                    borderRadius: '50%',
                    padding: '4px',
                    zIndex: 1,
                  }}
                  onClick={() => setIsFilterOpen(false)}
              >
                <CloseIcon/>
              </IconButton>
              <Grid mt={6}></Grid>
              <LeftNavFilter
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  setError={setError}
                  clearErrors={clearErrors}
                  errors={errors}
              />
            </Grid>
            {/* Fixed Buttons at the bottom */}
            <Grid
                container
                justifyContent="center"
                sx={{
                  padding: '20px',
                  borderTop: '2px solid #716F6F',
                  backgroundColor: '#fff',
                  position: 'sticky',
                  bottom: 0,
                }}
            >
              <Grid item xs={6} container justifyContent="center">
                <Button
                    onClick={resetButtonHandler}
                    sx={{
                      color: "#0046FF",
                      margin: "10px",
                      backgroundColor: "#ffffff",
                      border: "2px solid #0046FF",
                      fontSize: '16px',
                      padding: "10px 50px",
                      '&:hover': {
                        backgroundColor: '#ffffff',
                        border: "2px solid #0046FF",
                      },
                    }}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item xs={6} container justifyContent="center">
                <Button
                    disabled={Object.keys(errors).length !== 0}
                    onClick={applyButtonHandler}
                    sx={{
                      color: "white",
                      margin: "10px",
                      fontSize: '16px',
                      backgroundColor: "#0046FF",
                      padding: "10px 50px",
                      '&:hover': {
                        backgroundColor: '#0046FF',
                      },
                    }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
      </Grid>
  );
}
