import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IQButtonRadioContainer, ValidationProvider } from '@gannettdigital/shared-react-components';
import { Grid, styled } from '@mui/material';

import { useTopnavMenu } from 'hooks/useTopnavMenu';
import {
  clearDomainsCompleted,
  getDomains,
  putDomain,
  selectDomainsCompleted,
  selectDomainsContent,
  selectDomainsMessage,
  selectDomainsStatus,
} from 'services/domainsSlice';

import { H2 } from 'components/labels/H2';

import CoContinueFooter from 'components/continueFooter/CoContinueFooter';
import CoDomainsPurchase from 'components/domains/CoDomainsPurchase';
import CoDomainsExisting from 'components/domains/CoDomainsExisting';
import ContentLoader from 'components/contentLoader/ContentLoader';

import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useSaveAndClose } from 'context/SaveAndCloseContext';
import { Urls } from 'navigation/Urls';
import { getWebsite, selectWebsites } from 'services/websitesSlice';
import { selectActiveProductId, selectActiveOfferingType } from 'services/navigationSlice';
import DomainsType from 'models/DomainsType';
import { selectOrderFlow, selectOrdersContent } from 'services/ordersSlice';
import { OrderFlow } from 'shared/constants';
import schema from './DomainsPage.schema';

interface FormValues {
  options: string;
}

const GridStyled = styled(Grid)`
  .MuiToggleButton-root.Mui-selected {
    background-color: transparent;
  }
  .MuiToggleButton-root.Mui-selected:hover {
    background-color: transparent;
  }
`;

const emptyDomain = {
  existingDomainUrl: '',
  domainRegistrar: '',
  domainEmail: '',
  registrarUsername: '',
  registrarPassword: '',
};

const optionsList: Pick<React.ComponentProps<typeof IQButtonRadioContainer>, 'optionsList'> = {
  optionsList: [
    { field: 'options', label: 'Purchase a Domain', value: 'Purchase' },
    { field: 'options', label: 'Existing Domain', value: 'Existing' },
  ],
};

function RenderInputContent(props: { formValues: FormValues; domainsContent: DomainsType }) {
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const dispatch = useDispatch();

  const navigate = useNavigationHandler();
  const topnav = useTopnavMenu(Urls.Domains);

  const onBack = () => {
    navigate.to(topnav.previousStep());
  };

  const skip = () => {
    if (props.domainsContent.buyDomain) {
      dispatch(
        putDomain({
          offeringType,
          content: { buyDomain: '', websiteId, id: props.domainsContent.id },
          skip: 'buy',
        }),
      );
    } else {
      dispatch(
        putDomain({
          offeringType,
          content: { ...emptyDomain, websiteId, id: props.domainsContent.id },
          skip: 'existing',
        }),
      );
    }
  };

  switch (props.formValues.options) {
    case 'Purchase':
      return (
        <div>
          <CoDomainsPurchase id={props.domainsContent.id} defaultBuyDomain={props.domainsContent.buyDomain} />
        </div>
      );
    case 'Existing':
      return (
        <div>
          <CoDomainsExisting defaultValue={props.domainsContent} />
        </div>
      );
    case '':
    case null:
      return (
        <CoContinueFooter onBack={onBack} skipAndContinue onContinue={skip}
          continueDisabled={false}
        />
      );
    default:
      return <CoContinueFooter onBack={onBack} skipAndContinue continueDisabled={false} />;
  }
}

const evaluateDefaultValue = (domainsContent: DomainsType) => {
  if (domainsContent.buyDomain) {
    return 'Purchase';
  }
  if (domainsContent.existingDomainUrl) {
    return 'Existing';
  }

  return null;
};

export default function DomainsPage() {
  const { t } = useTranslation();
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const navigate = useNavigationHandler();
  const history: any = useLocation();
  const website = useSelector(selectWebsites);
  const orderFlow = useSelector(selectOrderFlow);
  const orderData = useSelector(selectOrdersContent);

  const isPunchlist = (orderFlow === OrderFlow.PUNCHLIST || orderData.orderFlow === OrderFlow.PUNCHLIST);

  // redux
  const dispatch = useDispatch();
  const domainsStatus = useSelector(selectDomainsStatus);
  const domainsMessage = useSelector(selectDomainsMessage);
  const domainsContent = useSelector(selectDomainsContent);
  const domainsCompleted = useSelector(selectDomainsCompleted);

  const defaultValue = evaluateDefaultValue(domainsContent);

  const methods = useForm({ mode: 'all', defaultValues: { options: defaultValue } });
  const {
    watch, setValue, formState: {
      isValid,
    },
  } = methods;

  const saveAndClose = useSaveAndClose();
  saveAndClose.setFormValid(isValid);

  const isRedirectedFromOverview = history.state?.previousPath.includes('overview');

  useEffect(() => {
    if (domainsCompleted) {
      if (isRedirectedFromOverview) {
        navigate.to(Urls.Overview);
      } else navigate.to(isPunchlist ? Urls.Theme : Urls.StockAssets);
      dispatch(clearDomainsCompleted());
    }
  }, [domainsCompleted]);

  const formValues = {
    options: watch('options'),
  };

  useEffect(() => {
    if (websiteId) {
      if (!website?.content?.id) dispatch(getWebsite({ websiteId, offeringType }));
      dispatch(getDomains({ websiteId, offeringType }));
    }
  }, [websiteId]);

  useEffect(() => {
    if (defaultValue) {
      setValue('options', defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <Grid container>
        <H2>{t('pages.domainsPage.domainsPage')}</H2>
        <Grid item xs={12} sx={{ marginTop: '48px' }}>
          <ContentLoader message={domainsMessage} status={domainsStatus}>
            <ValidationProvider schema={schema}>
              <FormProvider {...methods}>
                <GridStyled
                  item
                  sx={{
                    '& > div > button': { minWidth: 'max-content', maxWidth: 'fit-content' },
                    '& > label': { mb: '16px' },
                    '& > .MuiToggleButtonGroup-root > *': { backgroundColor: 'transparent' },
                    '& > .MuiToggleButtonGroup-root > *:hover': { backgroundColor: 'transparent' },
                    '.MuiTouchRipple-root > *': { display: 'none' },
                  }}
                >
                  <IQButtonRadioContainer
                    optionsList={optionsList.optionsList}
                    name="options"
                    label="Select a domain option"
                    fontTitleWeight="600"
                    inline
                    allowRadioDeselect
                    required={false}
                  />
                </GridStyled>
              </FormProvider>
            </ValidationProvider>
          </ContentLoader>
        </Grid>
      </Grid>
      <RenderInputContent formValues={formValues} domainsContent={domainsContent} />
    </>
  );
}
