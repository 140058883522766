import { Urls } from 'navigation/Urls';
import useProduct from 'hooks/useProduct';
import { GetChatSilverResponse, ChatSilverRequestContentType } from 'models/ChatSilverType';
import { StatusType } from 'models/StatusType';

import ContentLoader from 'components/contentLoader/ContentLoader';
import ChatLocationsForm from './ChatLocationsForm';

export default function ChatLocations() {
  const { product, onSubmit, onBack } = useProduct<ChatSilverRequestContentType,
  StatusType<GetChatSilverResponse>>(Urls.ChatLocations);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <ChatLocationsForm
        product={product.content.data}
        onSubmit={onSubmit}
        onBack={onBack}
      />
    </ContentLoader>
  );
}
