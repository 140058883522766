export const callToActionButtonItems = [
  {value: 'Apply Now', description: 'Apply Now'},
  {value: 'Book Now', description: 'Book Now'},
  {value: 'Buy Tickets', description: 'Buy Tickets'},
  {value: 'Get Now', description: 'Get Now'},
  {value: 'Listen', description: 'Listen'},
  {value: 'More', description: 'More'},
  {value: 'Order Now', description: 'Order Now'},
  {value: 'Play', description: 'Play'},
  {value: 'Shop Now', description: 'Shop Now'},
  {value: 'Showtimes', description: 'Showtimes'},
  {value: 'Sign Up', description: 'Sign Up'},
  {value: 'View', description: 'View'},
  {value: 'Watch', description: 'Watch'},
]

export const geoTargetingTypeItems = [
  {
    value: 'Country',
    label: 'Country',
    field: 'Country',
  },
  {
    value: 'Metros / DMAs',
    label: 'Metros / DMAs',
    field: 'Metros / DMAs',
  },
  {
    value: 'State',
    label: 'State',
    field: 'State',
  },
  {
    value: 'Radius',
    label: 'Radius',
    field: 'Radius',
  },
  {
    value: 'Zip Codes',
    label: 'Zip Codes',
    field: 'Zip Codes',
  },
];