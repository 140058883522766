import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";

const {t} = i18n;

export const TargetingYoutubeSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.youtubeTargeting.title'),
  type: 'object',
  required: [
    'locationTargetingType',
    'demographicTargeting',
  ],
  properties: {
    locationTargetingType: {
      type: 'string',
      title: t('pages.ausProduct.youtubeTargeting.locationTargetingTypeLabel'),
      description: t('pages.ausProduct.youtubeTargeting.locationTargetingTypeDescription'),
      default: data?.locationTargetingType || '',
      enum: [
        '',
        t('pages.ausProduct.youtubeTargeting.nationalOpt'),
        t('pages.ausProduct.youtubeTargeting.stateOpt'),
        t('pages.ausProduct.youtubeTargeting.radiusOpt'),
        t('pages.ausProduct.youtubeTargeting.postcodeOpt'),
        t('pages.ausProduct.youtubeTargeting.cityOpt'),
      ]
    },
    nationalPublisherTargeting: {
      type: 'string',
      title: t('pages.ausProduct.youtubeTargeting.nationalPublisherTargetingLabel'),
      default: data?.nationalPublisherTargeting || '',
      enum: [
        '',
        t('pages.ausProduct.youtubeTargeting.nationalPublisherTargetingOpt1'),
        t('pages.ausProduct.youtubeTargeting.nationalPublisherTargetingOpt2'),
      ]
    },
    demographicTargeting: {
      type: 'string',
      title: t('pages.ausProduct.youtubeTargeting.demographicTargetingLabel'),
      description: t('pages.ausProduct.youtubeTargeting.demographicTargetingDescription'),
      default: data?.demographicTargeting || '',
      enum: [
        '',
        t('pages.ausProduct.youtube.yesOpt'),
        t('pages.ausProduct.youtube.noOpt'),
      ]
    },
    adGroups: {
      type: 'array',
      title: t('pages.ausProduct.youtubeTargeting.adGroupsLabel'),
      description: t('pages.ausProduct.youtubeTargeting.adGroupsDescription'),
      items: {
        type: 'string',
        enum: [
          t('pages.ausProduct.youtubeTargeting.retargetingOpt'),
          t('pages.ausProduct.youtubeTargeting.lookalikeOpt'),
          t('pages.ausProduct.youtubeTargeting.customIntentOpt'),
          t('pages.ausProduct.youtubeTargeting.audienceOpt'),
          t('pages.ausProduct.youtubeTargeting.marketOpt'),
          t('pages.ausProduct.youtubeTargeting.eventOpt'),
          t('pages.ausProduct.youtubeTargeting.otherOpt'),
        ],
      },
      uniqueItems: true,
    },
  },
  dependencies: {
    locationTargetingType: {
      oneOf: [
        {
          properties: {
            locationTargetingType: {
              enum: [t('pages.ausProduct.youtubeTargeting.nationalOpt')],
            },
            cityPublisher: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.cityPublisherDescription'),
            },
            selectedCountry: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.selectedCountryLabel'),
              default: data?.selectedCountry || '',
              enum: [
                '',
                t('pages.ausProduct.youtubeTargeting.australiaOpt'),
                t('pages.ausProduct.youtubeTargeting.newZealandOpt'),
                t('pages.ausProduct.youtubeTargeting.radiusOpt'),
                t('pages.ausProduct.youtubeTargeting.OtherOpt'),
              ]
            },
          },
        },
        {
          properties: {
            locationTargetingType: {
              enum: [t('pages.ausProduct.youtubeTargeting.stateOpt')],
            },
            targetedStates: {
              type: 'array',
              title: t('pages.ausProduct.youtubeTargeting.targetedStatesLabel'),
              description: t('pages.ausProduct.youtubeTargeting.targetedStatesDescription'),
              default: data?.targetedStates || '',
              items: {
                type: 'string',
                enum: [
                  t('pages.ausProduct.youtubeTargeting.australianCapitalTerritoryOpt'),
                  t('pages.ausProduct.youtubeTargeting.newSouthWalesOpt'),
                  t('pages.ausProduct.youtubeTargeting.northernTerritoryOpt'),
                  t('pages.ausProduct.youtubeTargeting.queenslandOpt'),
                  t('pages.ausProduct.youtubeTargeting.southAustraliaOpt'),
                  t('pages.ausProduct.youtubeTargeting.tasmaniaOpt'),
                  t('pages.ausProduct.youtubeTargeting.victoriaOpt'),
                  t('pages.ausProduct.youtubeTargeting.westernAustraliaOpt'),
                  t('pages.ausProduct.youtubeTargeting.northlandOpt'),
                  t('pages.ausProduct.youtubeTargeting.aucklandOpt'),
                  t('pages.ausProduct.youtubeTargeting.bayOfPlentyOpt'),
                  t('pages.ausProduct.youtubeTargeting.canterburyOpt'),
                  t('pages.ausProduct.youtubeTargeting.gisborneOpt'),
                  t('pages.ausProduct.youtubeTargeting.hawkesBayOpt'),
                  t('pages.ausProduct.youtubeTargeting.manawatuWanganuiOpt'),
                  t('pages.ausProduct.youtubeTargeting.marlboroughOpt'),
                  t('pages.ausProduct.youtubeTargeting.nelsonOpt'),
                  t('pages.ausProduct.youtubeTargeting.otagoOpt'),
                  t('pages.ausProduct.youtubeTargeting.southlandOpt'),
                  t('pages.ausProduct.youtubeTargeting.taranakiOpt'),
                  t('pages.ausProduct.youtubeTargeting.tasmanOpt'),
                  t('pages.ausProduct.youtubeTargeting.waikatoOpt'),
                  t('pages.ausProduct.youtubeTargeting.wellingtonOpt'),
                  t('pages.ausProduct.youtubeTargeting.westCoastOpt'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            locationTargetingType: {
              enum: [t('pages.ausProduct.youtubeTargeting.radiusOpt')],
            },
            providedRadius: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.providedRadiusLabel'),
              default: data?.providedRadius || '',
            },
            streetNumber: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.streetNumberLabel'),
              default: data?.streetNumber || '',
            },
            streetName: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.streetNameLabel'),
              default: data?.streetName || '',
            },
            cityOrSuburb: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.cityOrSuburbLabel'),
              default: data?.cityOrSuburb || '',
            },
            stateOrProvince: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.stateOrProvinceLabel'),
              default: data?.stateOrProvince || '',
            },
            postalCode: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.postalCodeLabel'),
              default: data?.postalCode || '',
            },
            country: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.countryLabel'),
              default: data?.country || '',
            },
          },
          required: [
            'providedRadius',
            'streetNumber',
            'streetName',
            'cityOrSuburb',
            'stateOrProvince',
            'postalCode',
            'country',
          ]
        },
        {
          properties: {
            locationTargetingType: {
              enum: [t('pages.ausProduct.youtubeTargeting.postcodeOpt')],
            },
            postcode: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.postcodeLabel'),
              description: t('pages.ausProduct.targetingIndented.postcodeDescription'),
              default: '',
            },
          },
          required: ['postcode']
        },
        {
          properties: {
            locationTargetingType: {
              enum: [t('pages.ausProduct.youtubeTargeting.cityOpt')],
            },
            cityTargetingRequirements: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.cityTargetingRequirements'),
              description: t('pages.ausProduct.targetingIndented.cityTargetingRequirementsDescription'),
              default: data?.cityTargetingRequirements || '',
            },
          },
          required: ['cityTargetingRequirements']
        },
      ],
    },
    demographicTargeting: {
      oneOf: [
        {
          properties: {
            demographicTargeting: {
              enum: [t('pages.ausProduct.youtube.yesOpt')],
            },
            gender: {
              type: 'array',
              title: t('pages.ausProduct.youtubeTargeting.genderLabel'),
              items: {
                type: 'string',
                enum: [
                  t('pages.ausProduct.youtubeTargeting.maleOpt'),
                  t('pages.ausProduct.youtubeTargeting.femaleOpt'),
                ],
              },
              uniqueItems: true,
            },
            Age: {
              type: 'array',
              title: t('pages.ausProduct.youtubeTargeting.agesLabel'),
              items: {
                type: 'string',
                enum: [
                  t('pages.ausProduct.youtubeTargeting.agesOpt1'),
                  t('pages.ausProduct.youtubeTargeting.agesOpt2'),
                  t('pages.ausProduct.youtubeTargeting.agesOpt3'),
                  t('pages.ausProduct.youtubeTargeting.agesOpt4'),
                  t('pages.ausProduct.youtubeTargeting.agesOpt5'),
                  t('pages.ausProduct.youtubeTargeting.agesOpt6'),
                ],
              },
              uniqueItems: true,
            },
            parentalStatus: {
              type: 'array',
              title: t('pages.ausProduct.youtubeTargeting.parentalStatusLabel'),
              items: {
                type: 'string',
                enum: [
                  t('pages.ausProduct.youtubeTargeting.ParentsOpt'),
                  t('pages.ausProduct.youtubeTargeting.NonParentsOpt'),
                ],
              },
              uniqueItems: true,
            },
            householdIncome: {
              type: 'array',
              title: t('pages.ausProduct.youtubeTargeting.householdIncomeLabel'),
              items: {
                type: 'string',
                enum: [
                  t('pages.ausProduct.youtubeTargeting.householdIncomeOpt1'),
                  t('pages.ausProduct.youtubeTargeting.householdIncomeOpt2'),
                  t('pages.ausProduct.youtubeTargeting.householdIncomeOpt3'),
                  t('pages.ausProduct.youtubeTargeting.householdIncomeOpt4'),
                  t('pages.ausProduct.youtubeTargeting.householdIncomeOpt5'),
                  t('pages.ausProduct.youtubeTargeting.householdIncomeOpt6'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
      ],
    },
    adGroups: {
      allOf: [
        {
          if: {
            properties: {
              adGroups: {
                contains: {
                  enum: [
                    t('pages.ausProduct.youtubeTargeting.marketOpt'),
                    t('pages.ausProduct.youtubeTargeting.eventOpt'),
                    t('pages.ausProduct.youtubeTargeting.audienceOpt')
                  ],
                },
              },
            },
          },
          then: {
            properties: {
              targetAudiences: {
                type: 'string',
                title: t('pages.ausProduct.youtubeTargeting.targetAudiencesLabel'),
                default: data?.targetAudiences || '',
              },
              fullListHelpText: {
                type: 'string',
                title: t('pages.ausProduct.youtubeTargeting.fullListHelpText'),
              },
              fullListLink: {
                type: 'string',
                title: t('pages.ausProduct.youtubeTargeting.fullListLinkLabel'),
                enum: [t('pages.ausProduct.youtubeTargeting.fullListLink'), true],
              },
            },
          },
        },
        {
          if: {
            properties: {
              adGroups: {
                contains: {
                  enum: [t('pages.ausProduct.youtubeTargeting.customIntentOpt')],
                },
              },
            },
          },
          then: {
            properties: {
              customIntentAudience: {
                type: 'string',
                title: t('pages.ausProduct.youtubeTargeting.customIntentAudienceLabel'),
                description: t('pages.ausProduct.youtubeTargeting.customIntentAudienceDescription'),
                default: data?.customIntentAudience || '',
              },
            },
            required: ['customIntentAudience']
          },
        },
        {
          if: {
            properties: {
              adGroups: {
                contains: {
                  enum: [t('pages.ausProduct.youtubeTargeting.otherOpt')],
                },
              },
            },
          },
          then: {
            properties: {
              describeYourAudience: {
                type: 'string',
                title: t('pages.ausProduct.youtubeTargeting.describeYourAudienceLabel'),
                description: t('pages.ausProduct.youtubeTargeting.describeYourAudienceDescription'),
                default: data?.describeYourAudience || '',
              },
            },
            required: ['describeYourAudience']
          },
        },
      ],
    },
  }
});

const dropdownFieldKeys = [
  'locationTargetingType',
  'selectedCountry',
  'nationalPublisherTargeting',
  'demographicTargeting',
];

const inputFieldKeys = [
  'streetName',
  'cityOrSuburb',
  'stateOrProvince',
  'country',
  'cityTargetingRequirements',
  'nationalPublisherTargeting',
  'targetAudiences',
  'customIntentAudience',
  'describeYourAudience',
];

const checkBoxFieldKey = [
  'targetedStates',
  'gender',
  'Age',
  'parentalStatus',
  'householdIncome',
  'adGroups',
];
const textLabelKey = [
  'cityPublisher',
  'fullListHelpText',
];

const integerInputFieldKey = [
  'providedRadius',
  'streetNumber',
  'postalCode',
  'postcode',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'dropdown'},
}), {});

const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});
const checkBoxField = checkBoxFieldKey.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'checkBoxField'},
}), {});
const textLabel = textLabelKey.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textLabel'},
}), {});
const integerInputField = integerInputFieldKey.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'integerInputField'},
}), {});

export const TargetingYoutubeUISchema = {
  'ui:order': [
    'locationTargetingType',
    'cityPublisher',
    'selectedCountry',
    'targetedStates',
    'providedRadius',
    'streetNumber',
    'streetName',
    'cityOrSuburb',
    'stateOrProvince',
    'postalCode',
    'country',
    'cityTargetingRequirements',
    'postcode',
    'nationalPublisherTargeting',
    'demographicTargeting',
    'gender',
    'Age',
    'parentalStatus',
    'householdIncome',
    'adGroups',
    'targetAudiences',
    'fullListHelpText',
    'fullListLink',
    'customIntentAudience',
    'describeYourAudience',
  ],
  fullListLink: {
    'ui:widget': 'anchorTextLabel',
  },
  ...inputFields,
  ...dropdownFields,
  ...checkBoxField,
  ...textLabel,
  ...integerInputField,
};

export const TargetingYoutubeCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.locationTargetingType === '') {
    errors.locationTargetingType.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.demographicTargeting === '') {
    errors.demographicTargeting.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.locationTargetingType === t('pages.ausProduct.youtubeTargeting.radiusOpt')) {
    if (formData?.providedRadius === '') {
      errors.providedRadius.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.streetNumber === '') {
      errors.streetNumber.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.streetName === '') {
      errors.streetName.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.cityOrSuburb === '') {
      errors.cityOrSuburb.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.stateOrProvince === '') {
      errors.stateOrProvince.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.postalCode === '') {
      errors.postalCode.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.country === '') {
      errors.country.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.locationTargetingType === t('pages.ausProduct.youtubeTargeting.postcodeOpt')) {
    if (formData?.postcode === '') {
      errors.postcode.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.locationTargetingType === t('pages.ausProduct.youtubeTargeting.cityOpt')) {
    if (formData?.cityTargetingRequirements === '') {
      errors.cityTargetingRequirements.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.adGroups.toString() === t('pages.ausProduct.youtubeTargeting.customIntentOpt')) {
    if (formData?.customIntentAudience === '') {
      errors.customIntentAudience.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.adGroups.toString() === t('pages.ausProduct.youtubeTargeting.otherOpt')) {
    if (formData?.describeYourAudience === '') {
      errors.describeYourAudience.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
  }
  return errors;
};
