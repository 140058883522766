import React from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { Grid } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

import styled from '@emotion/styled';

import { IQLabelTooltip, IQTheme } from '@gannettdigital/shared-react-components';
import { IQPhoneInputContainerProps } from '../IQPhoneInputContainer';
import { IQErrorText } from '../IQErrorText/IQErrorText';
import { getError } from '../IQPhoneExtension/IQPhoneExtension';

type StyledGridItemProps = {
  hasError?: boolean;
};

const StyledGridItem = styled(Grid, { shouldForwardProp: (prop) => prop !== 'hasError' })<StyledGridItemProps>`
  min-width: 180px;

  .MuiGrid-container {
    border-radius: 2px;
    padding: 8px 16px;
    border: 1px solid
      ${(props) => (props.hasError
    ? props.theme.palette.error.main
    : props.theme.palette.text.primary)};
    background: ${(props) => props.theme.palette.common.white};
  }

  input {
    min-width: 100px;
    width: 100%;
    min-height: 24px;
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-style: normal;
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: ${(props) => props.theme.palette.text.primary};
    border: none;
  }

  input:focus-visible {
    border: none;
    outline: none;
  }

  .PhoneInputCountry {
    display: none;
  }
`;

export interface IQPhoneInputProps
  extends Omit<
  IQPhoneInputContainerProps,
  'hasExtension' | 'extensionOptions' | 'deleteButton' | 'deleteCallback'
  > {
  tooltipText?: string;
  countryCallingCodeEditable?: boolean,
  international?: boolean,
}

const IQPhoneInput = ({
  phoneLabelOptions,
  defaultValue,
  country,
  name,
  tooltipText,
  containerStyles,
  countryCallingCodeEditable,
  international,
}: IQPhoneInputProps) => {
  const { errors } = useFormState();
  const { control } = useFormContext();

  const hasError = !!getError(errors, name);
  const errorMessage = (getError(errors, name)?.message as unknown as string)
    || phoneLabelOptions?.errorMessage
    || 'Please enter a valid phone number.';

  const validationRule = (value) => {
    if (phoneLabelOptions?.required === false) {
      return value ? isValidPhoneNumber(`${value}`) : null;
    }
    return isValidPhoneNumber(`${value}`);
  };

  return (
    <Grid
      container
      rowGap={1}
      sx={containerStyles}
    >
      <Grid item xs={12}>
        <IQLabelTooltip
          theme={IQTheme}
          required
          labelText="Phone Number"
          tooltipText={tooltipText}
          {...phoneLabelOptions}
          hasError={hasError}
          arrow={false}
        />
      </Grid>
      <StyledGridItem
        container
        item
        xs={12}
        hasError={hasError}
      >
        <Controller
          name={name}
          defaultValue={defaultValue}
          control={control}
          rules={{
            validate: (value) => validationRule(value),
          }}
          render={({ field }) => (
            <Grid container>
              <Grid item width="30px" paddingTop="3px"
                border={0}
              >
                <LocalPhoneIcon fontSize="small" sx={{ color: '#737373' }} />
              </Grid>
              <Grid item xs>
                {/* @ts-ignore */}
                <PhoneInput
                  smartCaret
                  withCountryCallingCode
                  international={international}
                  countryCallingCodeEditable={countryCallingCodeEditable}
                  country={country}
                  {...field}
                />
              </Grid>
            </Grid>
          )}
        />
      </StyledGridItem>
      {hasError && (
        <Grid item xs={12}>
          <IQErrorText
            hasError={hasError}
            errorMessage={errorMessage}
            alignItems="center"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default IQPhoneInput;
