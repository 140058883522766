import { useTranslation } from 'react-i18next';
import { StepperRootProps } from 'layouts/StepperLayout';
import {
  IQButtonRadioContainer, IQFormLayout, IQInputNumericField, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { Typography, Grid } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useEffect } from 'react';
import { getCustomWebsite, putCustomWebsiteEcomSchedulerInfo, selectCustomWebsite } from 'services/customWebsiteSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { selectActiveProductId } from 'services/navigationSlice';
import ErrorText from 'components/errorText/ErrorText';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import schema from './Ecommerce.schema';

const variationOptions = [
  {
    value: 'yes',
    label: 'Yes',
    field: 'eCommerceVariations',
  },
  {
    value: 'no',
    label: 'No',
    field: 'eCommerceVariations',
  },
];

export default function CustomWebsiteEcommerce(props: StepperRootProps) {
  const { t } = useTranslation();
  const { orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const customWebsite = useSelector(selectCustomWebsite);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      eCommerceProductsAmount: null,
      eCommerceProducts: '',
      eCommerceVariations: '',
    },
  });

  const {
    getValues, handleSubmit, reset, register, formState: {
      isDirty, isSubmitting, isSubmitSuccessful, isValid, errors,
    },
  } = methods;

  const getRadioValue = (value) => (value ? 'yes' : 'no');

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid]);

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsite(websiteId.toString()));
  }, [websiteId]);

  useEffect(() => {
    reset({
      eCommerceProductsAmount: customWebsite.eCommerceProductsAmount,
      eCommerceProducts: customWebsite.eCommerceProducts,
      eCommerceVariations: customWebsite.eCommerceVariations === null
        ? null : getRadioValue(customWebsite.eCommerceVariations),
    });
  }, [customWebsite]);

  const onFormSubmit = () => {
    const data = getValues();
    const content = {
      eCommerceProductsAmount: data.eCommerceProductsAmount,
      eCommerceProducts: data.eCommerceProducts,
      eCommerceVariations: data.eCommerceVariations === 'yes',
      onlineAppointmentSchedulerInfo: customWebsite.onlineAppointmentSchedulerInfo || '',
    };
    dispatch(putCustomWebsiteEcomSchedulerInfo({ orderItemId, content }));
    props.onContinue();
  };

  return (
    <>
      <Typography variant="h2" marginTop={2}>{t('pages.website.custom.ecommerce.title')}</Typography>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container marginTop={5}>
              <Grid item xs={6} marginBottom={3}>
                <IQInputNumericField
                  name="eCommerceProductsAmount"
                  fullWidth
                  label={t('pages.website.custom.ecommerce.numberOfProducts')}
                  fontLabelWeight="600"
                  required
                  tooltipPlacement="top"
                  disallowNegative
                  tooltipText={t('pages.website.custom.ecommerce.numberOfProductsTooltip')}
                  tooltilPaddingBottom={18}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6} marginBottom={3}>
                <NeFormTextArea
                  {...register('eCommerceProducts')}
                  id="eCommerceProducts"
                  name="eCommerceProducts"
                  labelText={t('pages.website.custom.ecommerce.productTypes')}
                  tooltipText={t('pages.website.custom.ecommerce.productTypesTooltip')}
                  tooltipPaddingBottom={18}
                  fullWidth
                  rowCount={3}
                  fontLabelWeight="600"
                />
                <ErrorText
                  hasError={!!errors.eCommerceProducts}
                  errorText={t('pages.website.custom.ecommerce.productTypesRequired')}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <IQFormLayout
                  labelText={t('pages.website.custom.ecommerce.hasVariations')}
                  fontLabelWeight="bold"
                  tooltipText={t('pages.website.custom.ecommerce.hasVariationsTooltip')}
                  tooltipPlacement="top"
                  tooltipPaddingBottom={10}
                  showError={false}
                >
                  <IQButtonRadioContainer
                    singleColumn={false}
                    allowRadioDeselect
                    inline
                    name="eCommerceVariations"
                    optionsList={variationOptions}
                    label=""
                  />
                </IQFormLayout>
              </Grid>
            </Grid>
          </form>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </>
  );
}
