import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import {
  validateSecondLimit
} from "pages/generic-product/details/product-schema/social-ads-schema/ImageryVideoSchema";
import RadioButton from "pages/generic-product/details/custom-fields/RadioButton";

const {t} = i18n;

export const ImageryVideoSchema: RJSFSchema | any = (data) => ({
  title: t('pages.socialAds.solutionStrategy.imageryVideo.title'),
  type: 'object',
  required: [],
  properties: {
    whatTypeOfVisualStrategyDoesYourClientWantToEmploy: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.whatTypeOfVisualStrategyDoesYourClientWantToEmployLabel'),
      description: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.whatTypeOfVisualStrategyDoesYourClientWantToEmployHelperText'),
      default: data?.whatTypeOfVisualStrategyDoesYourClientWantToEmploy || '',
      enum: [
        '',
        'Single Image',
        'Single Video',
        'Multiple Images',
        'Multiple Videos',
        'Image and Video Combination',
      ],
    },
    willTheClientBeProvidingImagesToUse: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.willTheClientBeProvidingImagesToUseLabel'),
      description: t('pages.socialAds.solutionStrategy.imageryVideo.willTheClientBeProvidingImagesToUseHelperText'),
      default: data?.willTheClientBeProvidingImagesToUse || '',
      enum: [
        '',
        'No - Build by LocaliQ',
        'No - Use Stock Images',
        'Yes - Client Provided',
        'Yes - Camera Ready',
      ],
    },
    createdThroughDesigniQATOLOrderLabel: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.createdThroughDesigniQATOLOrderLabel'),
    },
    salesRepLabel: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.salesRepLabel'),
      default:  t('pages.socialAds.solutionStrategy.imageryVideo.other'),
      items: {
        enum: [
          t('pages.socialAds.solutionStrategy.imageryVideo.other'),
        ],
      },
    },
    creativeSizesLabel: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.creativeSizesLabel'),
      default: t('pages.socialAds.solutionStrategy.imageryVideo.fbSnapStory'),
      items: {
        enum: [
          t('pages.socialAds.solutionStrategy.imageryVideo.fbSnapStory'),
        ],
      }
    },
    whereShouldWeGetTheInformationForThisAd: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.whereShouldWeGetTheInformationForThisAdLabel'),
      default: t('pages.socialAds.solutionStrategy.imageryVideo.enterDetailsNextPage'),
      items: {
        enum: [
          t('pages.socialAds.solutionStrategy.imageryVideo.enterDetailsNextPage'),
        ],
      }
    },
    pleaseSelectTheVerticalMarketIndustryForThis: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseSelectTheVerticalMarketIndustryForThisLabel'),
      default: data?.pleaseSelectTheVerticalMarketIndustryForThis || '',
      enum: [
        '',
        'Non-Standard Printing',
        'Auto Dealers',
        'Auto Services',
        'Employment',
        'Grocery',
        'Real Estate',
        'Agriculture',
        'Bars',
        'Boating/Marine',
        'Casinos',
        'Charity',
        'Church',
        'Clothing',
        'Computer Equipment/Service',
        'Education',
        'Entertainment',
        'Events',
        'Financial',
        'Fitness/Wellness',
        'Florist',
        'Funeral Services',
        'Furniture',
        'Government',
        'Healthcare/Medical',
        'Home Builder',
        'Home Repair/Improvement',
        'Hotels',
        'Insurance',
        'Jewelry',
        'Lawn & Garden Centers',
        'Legal',
        'Liquor',
        'Lottery',
        'Professional Services',
        'Restaurants',
        'Salon/Spa/Beauty',
        'Seniors/Assisted Living',
        'Sporting Goods',
        'Sports',
        'Storage Units',
        'Transportation & Logistics',
        'Travel',
        'Other',
      ],
    },
    doesYourAdRequireTheManualPickupOfAnyElementsFromAnotherAd: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.doesYourAdRequireTheManualPickupOfAnyElementsFromAnotherAdLabel'),
      default: data?.doesYourAdRequireTheManualPickupOfAnyElementsFromAnotherAd || '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    pleaseSelectALevelOfCreativityArtisticDiscretion: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseSelectALevelOfCreativityArtisticDiscretionLabel'),
      default: data?.pleaseSelectALevelOfCreativityArtisticDiscretion || '',
      enum: [
        '',
        'Template Based Ad',
        'Some Creative Freedom Needed',
        'Be Creative, Apply Data Informed Design',
      ],
    },
    willYouNeedAStatic1080x1080FacebookInstagramCreative: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouNeedAStatic1080x1080FacebookInstagramCreativeLabel'),
      default: data?.willYouNeedAStatic1080x1080FacebookInstagramCreative || '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    wasThereAnUpsell: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.wasThereAnUpsellLabel'),
      default: data?.wasThereAnUpsell || '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    isThereAnythingElseTheDesignerShouldKnowAboutTheCreativeForThisAd: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.isThereAnythingElseTheDesignerShouldKnowAboutTheCreativeForThisAdLabel'),
      default: data?.isThereAnythingElseTheDesignerShouldKnowAboutTheCreativeForThisAd || '',
    },
    followAutoCoOpGuidelines: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.followAutoCoOpGuidelinesLabel'),
      default: data?.followAutoCoOpGuidelines || '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
  },

  allOf: [
    {
  if: {
  properties: {
    providedClientVideo: {
      "enum": ["No"],
    },
    instagramOrSnapchatStoryVideoCarousel: {
      "enum": ["Yes"],
    },
  },
  required: [
    'providedClientVideo',
    'instagramOrSnapchatStoryVideoCarousel',
  ]
},
  then: {
    properties: {
      whatIsTheLengthOfTheVideoInSecondForStoryVideo1: {
        type: 'string',
            title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheLengthOfTheVideoInSecondTitle'),
      default: data?.whatIsTheLengthOfTheVideoInSecondForStoryVideo1 || '',
      },
      areThereBrandingElementsRequiredForStoryVideo1: {
        type: 'string',
            title: t('pages.socialAds.solutionStrategy.imageryVideo.areThereBrandingElementsRequiredTitle'),
      default: data?.areThereBrandingElementsRequiredForStoryVideo1 || '',
      },
      whatIsTheMainMessageTheVideoShouldConveyForStoryVideo1: {
        type: 'string',
            title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheMainMessageTheVideoShouldConveyTitle'),
      default: data?.whatIsTheMainMessageTheVideoShouldConveyForStoryVideo1 || '',
      },
      BeProvidingAScriptToFollowForStoryVideo1: {
        type: 'string',
            title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeProvidingAScriptToFollowTitle'),
      default: data?.BeProvidingAScriptToFollowForStoryVideo1 || '',
        enum: [
          '',
          'Yes',
          'No'
        ]
      },
      whatIsTheLengthOfTheVideoInSecondForStoryVideo3: {
        type: 'string',
            title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheLengthOfTheVideoInSecondTitle'),
      default: data?.whatIsTheLengthOfTheVideoInSecondForStoryVideo3 || '',
      },
      areThereBrandingElementsRequiredForStoryVideo3: {
        type: 'string',
            title: t('pages.socialAds.solutionStrategy.imageryVideo.areThereBrandingElementsRequiredTitle'),
      default: data?.areThereBrandingElementsRequiredForStoryVideo3 || '',
      },
      whatIsTheMainMessageTheVideoShouldConveyForStoryVideo3: {
        type: 'string',
            title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheMainMessageTheVideoShouldConveyTitle'),
      default: data?.whatIsTheMainMessageTheVideoShouldConveyForStoryVideo3 || '',
      },
      BeProvidingAScriptToFollowForStoryVideo3: {
        type: 'string',
            title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeProvidingAScriptToFollowTitle'),
      default: data?.BeProvidingAScriptToFollowForStoryVideo3 || '',
        enum: [
          '',
          'Yes',
          'No'
        ]
      },
      whatIsTheLengthOfTheVideoInSecondForStoryVideo4: {
        type: 'string',
            title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheLengthOfTheVideoInSecondTitle'),
      default: data?.whatIsTheLengthOfTheVideoInSecondForStoryVideo4 || '',
      },
      areThereBrandingElementsRequiredForStoryVideo4: {
        type: 'string',
            title: t('pages.socialAds.solutionStrategy.imageryVideo.areThereBrandingElementsRequiredTitle'),
      default: data?.areThereBrandingElementsRequiredForStoryVideo4 || '',
      },
      whatIsTheMainMessageTheVideoShouldConveyForStoryVideo4: {
        type: 'string',
            title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheMainMessageTheVideoShouldConveyTitle'),
      default: data?.whatIsTheMainMessageTheVideoShouldConveyForStoryVideo4 || '',
      },
      BeProvidingAScriptToFollowForStoryVideo4: {
        type: 'string',
            title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeProvidingAScriptToFollowTitle'),
      default: data?.BeProvidingAScriptToFollowForStoryVideo4 || '',
        enum: [
          '',
          'Yes',
          'No'
        ]
      },
    },
    dependencies: {
      BeProvidingAScriptToFollowForStoryVideo1: {
        oneOf: [
          {
            properties: {
              BeProvidingAScriptToFollowForStoryVideo1: {
                enum: ['No'],
              },
              BeUploadingAStoryboardToFollowForStoryVideo1: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeUploadingAStoryboardToFollowTitle'),
                default: data?.BeUploadingAStoryboardToFollowForStoryVideo1 || '',
                enum: [
                  '',
                  'Yes',
                  'No'
                ]
              },
            },
          },
        ],
      },
      BeUploadingAStoryboardToFollowForStoryVideo1: {
        oneOf: [
          {
            properties: {
              BeUploadingAStoryboardToFollowForStoryVideo1: {
                enum: ['No'],
              },
              ProvideBasicStoryboardLabelForStoryVideo1: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.provideBasicStoryboardLabel'),
              },
              Screen1ForStoryVideo1: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen1Title'),
                default: data?.Screen1ForStoryVideo1 || '',
              },
              Screen2ForStoryVideo1: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen2Title'),
                default: data?.Screen2ForStoryVideo1 || '',
              },
              Screen3ForStoryVideo1: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen3Title'),
                default: data?.Screen3ForStoryVideo1 || '',
              },
              Screen4ForStoryVideo1: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen4Title'),
                default: data?.Screen4ForStoryVideo1 || '',
              },
              Screen5ForStoryVideo1: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen5Title'),
                default: data?.Screen5ForStoryVideo1 || '',
              },
            },
          }
        ]
      },
      BeProvidingAScriptToFollowForStoryVideo3: {
        oneOf: [
          {
            properties: {
              BeProvidingAScriptToFollowForStoryVideo3: {
                enum: ['No'],
              },
              BeUploadingAStoryboardToFollowForStoryVideo3: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeUploadingAStoryboardToFollowTitle'),
                default: data?.BeUploadingAStoryboardToFollowForStoryVideo3 || '',
                enum: [
                  '',
                  'Yes',
                  'No'
                ]
              },
            },
          },
        ],
      },
      BeUploadingAStoryboardToFollowForStoryVideo3: {
        oneOf: [
          {
            properties: {
              BeUploadingAStoryboardToFollowForStoryVideo3: {
                enum: ['No'],
              },
              ProvideBasicStoryboardLabelForStoryVideo3: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.provideBasicStoryboardLabel'),
              },
              Screen1ForStoryVideo3: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen1Title'),
                default: data?.Screen1ForStoryVideo3 || '',
              },
              Screen2ForStoryVideo3: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen2Title'),
                default: data?.Screen2ForStoryVideo3 || '',
              },
              Screen3ForStoryVideo3: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen3Title'),
                default: data?.Screen3ForStoryVideo3 || '',
              },
              Screen4ForStoryVideo3: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen4Title'),
                default: data?.Screen4ForStoryVideo3 || '',
              },
              Screen5ForStoryVideo3: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen5Title'),
                default: data?.Screen5ForStoryVideo3 || '',
              },
            },
          }
        ]
      },
      BeProvidingAScriptToFollowForStoryVideo4: {
        oneOf: [
          {
            properties: {
              BeProvidingAScriptToFollowForStoryVideo4: {
                enum: ['No'],
              },
              BeUploadingAStoryboardToFollowForStoryVideo4: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeUploadingAStoryboardToFollowTitle'),
                default: data?.BeUploadingAStoryboardToFollowForStoryVideo4 || '',
                enum: [
                  '',
                  'Yes',
                  'No'
                ]
              },
            },
          },
        ],
      },
      BeUploadingAStoryboardToFollowForStoryVideo4: {
        oneOf: [
          {
            properties: {
              BeUploadingAStoryboardToFollowForStoryVideo4: {
                enum: ['No'],
              },
              ProvideBasicStoryboardLabelForStoryVideo4: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.provideBasicStoryboardLabel'),
              },
              Screen1ForStoryVideo4: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen1Title'),
                default: data?.Screen1ForStoryVideo4 || '',
              },
              Screen2ForStoryVideo4: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen2Title'),
                default: data?.Screen2ForStoryVideo4 || '',
              },
              Screen3ForStoryVideo4: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen3Title'),
                default: data?.Screen3ForStoryVideo4 || '',
              },
              Screen4ForStoryVideo4: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen4Title'),
                default: data?.Screen4ForStoryVideo4 || '',
              },
              Screen5ForStoryVideo4: {
                type: 'string',
                title: t('pages.socialAds.solutionStrategy.imageryVideo.screen5Title'),
                default: data?.Screen5ForStoryVideo4 || '',
              },
            },
          }
        ]
      }
    }
  },
}
],

  dependencies: {
    // enum: [
    //   '',
    //   'Single Image',
    //   'Single Video',
    //   'Multiple Images',
    //   'Multiple Videos',
    //   'Image and Video Combination',
    // ],
    whatTypeOfVisualStrategyDoesYourClientWantToEmploy: {
      oneOf: [
        {
          properties: {
            whatTypeOfVisualStrategyDoesYourClientWantToEmploy: {
              enum: [
                'Single Video',
                'Multiple Videos',
              ],
            },
            willYouNeedAStatic1200x628FacebookCreative: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouNeedAStatic1200x628FacebookCreativeLabel'),
              default: 'No',
              enum: [
                '',
                'No',
              ],
            },
            willThisCampaignUtilizeTheFacebookCarouselFormat: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willThisCampaignUtilizeTheFacebookCarouselFormatLabel'),
              description: t('pages.socialAds.solutionStrategy.imageryVideo.willThisCampaignUtilizeTheFacebookCarouselFormatDescription'),
              default: 'No',
              enum: [
                '',
                'No',
              ],
            },
            willTheClientNeedAVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willTheClientNeedAVideoLabel'),
              default: data?.willTheClientNeedAVideo || '',
              enum: [
                '',
                'Yes',
                'No',
              ],
            },
            providedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willTheClientBeProvidingVideoTitle'),
              default: data?.providedClientVideo || '',
              "enum": [
                '',
                "Yes",
                "No"
              ]
            },
          }
        },
        {
          properties: {
            whatTypeOfVisualStrategyDoesYourClientWantToEmploy: {
              enum: [
                'Image and Video Combination',
              ],
            },
            willYouNeedAStatic1200x628FacebookCreative: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouNeedAStatic1200x628FacebookCreativeLabel'),
              default: data?.willYouNeedAStatic1200x628FacebookCreative || '',
              enum: [
                '',
                'Yes',
                'No',
              ],
            },
            willThisCampaignUtilizeTheFacebookCarouselFormat: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willThisCampaignUtilizeTheFacebookCarouselFormatLabel'),
              description: t('pages.socialAds.solutionStrategy.imageryVideo.willThisCampaignUtilizeTheFacebookCarouselFormatDescription'),
              default: data?.willThisCampaignUtilizeTheFacebookCarouselFormat || '',
              enum: [
                '',
                'Yes',
                'No',
              ],
            },
            willTheClientNeedAVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willTheClientNeedAVideoLabel'),
              default: data?.willTheClientNeedAVideo || '',
              enum: [
                '',
                'Yes',
                'No',
              ],
            },
            providedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willTheClientBeProvidingVideoTitle'),
              default: data?.providedClientVideo || '',
              "enum": [
                '',
                "Yes",
                "No"
              ]
            },
          }
        },
        {
          properties: {
            whatTypeOfVisualStrategyDoesYourClientWantToEmploy: {
              enum: [
                'Single Image',
                'Multiple Images',
              ],
            },
            willYouNeedAStatic1200x628FacebookCreative: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouNeedAStatic1200x628FacebookCreativeLabel'),
              default: data?.willYouNeedAStatic1200x628FacebookCreative || '',
              enum: [
                '',
                'Yes',
                'No',
              ],
            },
            willThisCampaignUtilizeTheFacebookCarouselFormat: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willThisCampaignUtilizeTheFacebookCarouselFormatLabel'),
              description: t('pages.socialAds.solutionStrategy.imageryVideo.willThisCampaignUtilizeTheFacebookCarouselFormatDescription'),
              default: data?.willThisCampaignUtilizeTheFacebookCarouselFormat || '',
              enum: [
                '',
                'Yes',
                'No',
              ],
            },
            willTheClientNeedAVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willTheClientNeedAVideoLabel'),
              default: 'No',
              enum: [
                '',
                'No',
              ],
            },
          }
        },
        {
          properties: {
            whatTypeOfVisualStrategyDoesYourClientWantToEmploy: {
              not: {
                enum: [
                  'Single Image',
                  'Multiple Images',
                  'Single Video',
                  'Multiple Videos',
                  'Image and Video Combination'
                ],  // For other options, enable the field
              }
            },
            willYouNeedAStatic1200x628FacebookCreative: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouNeedAStatic1200x628FacebookCreativeLabel'),
              default: data?.willYouNeedAStatic1200x628FacebookCreative || '',
              enum: [
                '',
                'Yes',
                'No',
              ],
            },
            willThisCampaignUtilizeTheFacebookCarouselFormat: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willThisCampaignUtilizeTheFacebookCarouselFormatLabel'),
              description: t('pages.socialAds.solutionStrategy.imageryVideo.willThisCampaignUtilizeTheFacebookCarouselFormatDescription'),
              default: data?.willThisCampaignUtilizeTheFacebookCarouselFormat || '',
              enum: [
                '',
                'Yes',
                'No',
              ],
            },
            willTheClientNeedAVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willTheClientNeedAVideoLabel'),
              default: data?.willTheClientNeedAVideo || '',
              enum: [
                '',
                'Yes',
                'No',
              ],
            },
          }
        },
      ],
    },

    providedClientVideo: {
      oneOf: [
        {
          properties: {
            providedClientVideo: {
              "enum": ["Yes"],
            },
            whatTypeOfWorkNeedsToBeCompletedOnVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatTypeOfWorkNeedsToBeCompletedOnVideoTitle'),
              default: data?.whatTypeOfWorkNeedsToBeCompletedOnVideo || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.imageryVideo.titleCardOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.minorVideoEditingOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.addStaticContentOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.trimTimeframeOptions'),
              ]
            },
          },
        },
        {
          properties: {
            providedClientVideo: {
              enum: ['No'],
            },
            beSupplyingImageToCreateVideoFrom: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeSupplyingImageToCreateVideoFromTitle'),
              default: data?.beSupplyingImageToCreateVideoFrom || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
            requirementsForImagesSelectedLabel: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.requirementsForImagesSelectedLabel'),
            },
            image1ForProvidedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image1Label'),
              default: data?.image1ForProvidedClientVideo || '',
            },
            image2ForProvidedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image2Label'),
              default: data?.image2ForProvidedClientVideo || '',
            },
            image3ForProvidedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image3Label'),
              default: data?.image3ForProvidedClientVideo || '',
            },
            whatIsTheLengthOfTheVideoInSecondForProvidedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheLengthOfTheVideoInSecondTitle'),
              default: data?.whatIsTheLengthOfTheVideoInSecondForProvidedClientVideo || '',
            },
            areThereBrandingElementsRequiredForProvidedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.areThereBrandingElementsRequiredTitle'),
              default: data?.areThereBrandingElementsRequiredForProvidedClientVideo || '',
            },
            whatIsTheMainMessageTheVideoShouldConveyForProvidedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheMainMessageTheVideoShouldConveyTitle'),
              default: data?.whatIsTheMainMessageTheVideoShouldConveyForProvidedClientVideo || '',
            },
            BeProvidingAScriptToFollowForProvidedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeProvidingAScriptToFollowTitle'),
              default: data?.BeProvidingAScriptToFollowForProvidedClientVideo || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
          },
        },
      ],
    },
    BeProvidingAScriptToFollowForProvidedClientVideo: {
      oneOf: [
        {
          properties: {
            BeProvidingAScriptToFollowForProvidedClientVideo: {
              enum: ['No'],
            },
            BeUploadingAStoryboardToFollowForProvidedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeUploadingAStoryboardToFollowTitle'),
              default: data?.BeUploadingAStoryboardToFollowForProvidedClientVideo || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
          },
        },
      ],
    },
    BeUploadingAStoryboardToFollowForProvidedClientVideo: {
      oneOf: [
        {
          properties: {
            BeUploadingAStoryboardToFollowForProvidedClientVideo: {
              enum: ['No'],
            },
            ProvideBasicStoryboardLabelForProvidedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.provideBasicStoryboardLabel'),
            },
            Screen1ForProvidedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen1Title'),
              default: data?.Screen1ForProvidedClientVideo || '',
            },
            Screen2ForProvidedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen2Title'),
              default: data?.Screen2ForProvidedClientVideo || '',
            },
            Screen3ForProvidedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen3Title'),
              default: data?.Screen3ForProvidedClientVideo || '',
            },
            Screen4ForProvidedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen4Title'),
              default: data?.Screen4ForProvidedClientVideo || '',
            },
            Screen5ForProvidedClientVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen5Title'),
              default: data?.Screen5ForProvidedClientVideo || '',
            },
          },
        },
      ],
    },
    willYouNeedAStatic1200x628FacebookCreative: {
      oneOf: [
        {
          properties: {
            willYouNeedAStatic1200x628FacebookCreative: {
              enum: ['Yes'],
            },
            pleaseListOutWhatTypeOfImageryAndContentShouldBeIncludedOnTheCreative: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseListOutWhatTypeOfImageryAndContentShouldBeIncludedOnTheCreativeLabel'),
              description: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseListOutWhatTypeOfImageryAndContentShouldBeIncludedOnTheCreativeDescription'),
              default: data?.pleaseListOutWhatTypeOfImageryAndContentShouldBeIncludedOnTheCreative || '',
            },
          }
        },
      ],
    },
    willYouNeedAStatic1080x1080FacebookInstagramCreative: {
      oneOf: [
        {
          properties: {
            willYouNeedAStatic1080x1080FacebookInstagramCreative: {
              enum: ['Yes'],
            },
            pleaseListOutWhatTypeOfImageryAndContentShouldBeIncludedOnTheFacebookInstagramCreative: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseListOutWhatTypeOfImageryAndContentShouldBeIncludedOnTheFacebookInstagramCreativeLabel'),
              default: data?.pleaseListOutWhatTypeOfImageryAndContentShouldBeIncludedOnTheFacebookInstagramCreative || '',
            },
          }
        },
      ],
    },
    willThisCampaignUtilizeTheFacebookCarouselFormat: {
      oneOf: [
        {
          properties: {
            willThisCampaignUtilizeTheFacebookCarouselFormat: {
              enum: ['Yes'],
            },
            whatTypeOfImageryDoYouNeedOnYour3FacebookCarousel1080x1080creatives: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatTypeOfImageryDoYouNeedOnYour3FacebookCarousel1080x1080CreativesHeading'),
            },
            image1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image1Label'),
              default: data?.image1 || '',
            },
            image2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image2Label'),
              default: data?.image2 || '',
            },
            image3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image3Label'),
              default: data?.image3 || '',
            },
            doYouNeedMoreThan3FacebookCarousel1080x1080Images: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.doYouNeedMoreThan3FacebookCarousel1080x1080ImagesLabel'),
              default: data?.willTheClientNeedAVideo || '',
              enum: [
                '',
                'Yes',
                'No',
              ],
            },
          }
        },
      ],
    },
    doYouNeedMoreThan3FacebookCarousel1080x1080Images: {
      oneOf: [
        {
          properties: {
            doYouNeedMoreThan3FacebookCarousel1080x1080Images: {
              enum: ['Yes'],
            },
            image4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image4Label'),
              default: data?.image4 || '',
            },
            image5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image5Label'),
              default: data?.image5 || '',
            },
            image6: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image6Label'),
              default: data?.image6 || '',
            },
            image7: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image7Label'),
              default: data?.image7 || '',
            },
            image8: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image8Label'),
              default: data?.image8 || '',
            },
            image9: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image9Label'),
              default: data?.image9 || '',
            },
            image10: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image10Label'),
              default: data?.image10 || '',
            },
          }
        },
      ],
    },
    wasThereAnUpsell: {
      oneOf: [
        {
          properties: {
            wasThereAnUpsell: {
              enum: [
                'Yes',
              ],
            },
            wasTheUpsellForFacebookOrInstagramOrSnapchatSingleImage: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.wasTheUpsellForFacebookOrInstagramOrSnapchatSingleImageTitle'),
              default: data?.wasTheUpsellForFacebookOrInstagramOrSnapchatSingleImage || '',
              enum: [
                  '',
                'Yes',
                'No',
              ]
            },
            wasTheUpsellForInstagramOrSnapchatStoryImageCarousel: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.wasTheUpsellForInstagramOrSnapchatStoryImageCarouselTitle'),
              default: data?.wasTheUpsellForInstagramOrSnapchatStoryImageCarousel || '',
              enum: [
                '',
                'Yes',
                'No',
              ]
            }
          },
        },
      ],
    },
    wasTheUpsellForFacebookOrInstagramOrSnapchatSingleImage:{
      oneOf: [
        {
          properties: {
            wasTheUpsellForFacebookOrInstagramOrSnapchatSingleImage: {
              enum: ['Yes']
            },
            pleaseListWhatTypeOfImageryAndContentShouldBeIncluded: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedTitle'),
              default: data?.pleaseListWhatTypeOfImageryAndContentShouldBeIncluded || '',
            },
            whatIsTagline: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTaglineTitle'),
              default: data?.whatIsTagline || '',
            },
            whatLogoShouldBeUsed: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatLogoShouldBeUsedTitle'),
              default: data?.whatLogoShouldBeUsed || '',
            }
          }
        }
      ]
    },
    wasTheUpsellForInstagramOrSnapchatStoryImageCarousel:{
      oneOf: [
        {
          properties: {
            wasTheUpsellForInstagramOrSnapchatStoryImageCarousel: {
              enum: ['Yes']
            },
            Image1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image1Label'),
            },
            pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedTitle'),
              default: data?.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage1 || '',
            },
            whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatLogoShouldBeUsedTitle'),
              default: data?.whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage1 || '',
            },
            whatIsTaglineForInstagramOrSnapchatStoryImage1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTaglineTitle'),
              default: data?.whatIsTaglineForInstagramOrSnapchatStoryImage1 || '',
            },
            Image2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image2Label'),
            },
            pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedTitle'),
              default: data?.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage2 || '',
            },
            whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatLogoShouldBeUsedTitle'),
              default: data?.whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage2 || '',
            },
            whatIsTaglineForInstagramOrSnapchatStoryImage2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTaglineTitle'),
              default: data?.whatIsTaglineForInstagramOrSnapchatStoryImage2 || '',
            },
            Image3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image3Label'),
            },
            pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedTitle'),
              default: data?.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage3 || '',
            },
            whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatLogoShouldBeUsedTitle'),
              default: data?.whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage3 || '',
            },
            whatIsTaglineForInstagramOrSnapchatStoryImage3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTaglineTitle'),
              default: data?.whatIsTaglineForInstagramOrSnapchatStoryImage3 || '',
            },
            doYouNeedMoreThanThreeStoryImageCarouselCreatives: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.doYouNeedMoreThanThreeStoryImageCarouselCreativesTitle'),
              default: data?.doYouNeedMoreThanThreeStoryImageCarouselCreatives || '',
              enum: [
                '',
                'Yes',
                'No',
              ]
            },
          }
        }
      ]
    },
    doYouNeedMoreThanThreeStoryImageCarouselCreatives: {
      oneOf: [
        {
          properties: {
            doYouNeedMoreThanThreeStoryImageCarouselCreatives: {
              enum: ['Yes'],
            },
            Image4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image4Label'),
            },
            pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedTitle'),
              default: data?.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage4 || '',
            },
            whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatLogoShouldBeUsedTitle'),
              default: data?.whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage4 || '',
            },
            whatIsTaglineForInstagramOrSnapchatStoryImage4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTaglineTitle'),
              default: data?.whatIsTaglineForInstagramOrSnapchatStoryImage4 || '',
            },
            Image5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image5Label'),
            },
            pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedTitle'),
              default: data?.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage5 || '',
            },
            whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatLogoShouldBeUsedTitle'),
              default: data?.whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage5 || '',
            },
            whatIsTaglineForInstagramOrSnapchatStoryImage5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTaglineTitle'),
              default: data?.whatIsTaglineForInstagramOrSnapchatStoryImage5 || '',
            },
            Image6: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image6Label'),
            },
            pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage6: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedTitle'),
              default: data?.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage6 || '',
            },
            whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage6: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatLogoShouldBeUsedTitle'),
              default: data?.whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage6 || '',
            },
            whatIsTaglineForInstagramOrSnapchatStoryImage6: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTaglineTitle'),
              default: data?.whatIsTaglineForInstagramOrSnapchatStoryImage6 || '',
            },
            Image7: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image7Label'),
            },
            pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage7: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedTitle'),
              default: data?.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage7 || '',
            },
            whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage7: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatLogoShouldBeUsedTitle'),
              default: data?.whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage7 || '',
            },
            whatIsTaglineForInstagramOrSnapchatStoryImage7: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTaglineTitle'),
              default: data?.whatIsTaglineForInstagramOrSnapchatStoryImage7 || '',
            },
            Image8: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image8Label'),
            },
            pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage8: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedTitle'),
              default: data?.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage8 || '',
            },
            whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage8: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatLogoShouldBeUsedTitle'),
              default: data?.whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage8 || '',
            },
            whatIsTaglineForInstagramOrSnapchatStoryImage8: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTaglineTitle'),
              default: data?.whatIsTaglineForInstagramOrSnapchatStoryImage8 || '',
            },
            Image9: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image9Label'),
            },
            pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage9: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedTitle'),
              default: data?.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage9 || '',
            },
            whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage9: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatLogoShouldBeUsedTitle'),
              default: data?.whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage9 || '',
            },
            whatIsTaglineForInstagramOrSnapchatStoryImage9: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTaglineTitle'),
              default: data?.whatIsTaglineForInstagramOrSnapchatStoryImage9 || '',
            },
            Image10: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image10Label'),
            },
            pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage10: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedTitle'),
              default: data?.pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage10 || '',
            },
            whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage10: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatLogoShouldBeUsedTitle'),
              default: data?.whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage10 || '',
            },
            whatIsTaglineForInstagramOrSnapchatStoryImage10: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTaglineTitle'),
              default: data?.whatIsTaglineForInstagramOrSnapchatStoryImage10 || '',
            },
          },
        },
      ],
    },
    willTheClientNeedAVideo:{
      oneOf:[
        {
          properties: {
            willTheClientNeedAVideo: {
              enum: ['Yes'],
            },
            willYouNeedFacebookOrInstagramVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouNeedFacebookOrInstagramVideoTitle'),
              default: data?.willYouNeedFacebookOrInstagramVideo || '',
              enum: [
                  '',
                  'Yes',
                  'No'
              ]
            },
            instagramOrSnapchatStoryVideoCarousel: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.instagramOrSnapchatStoryVideoCarouselTitle'),
              default: data?.instagramOrSnapchatStoryVideoCarousel || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
          },
        }
      ]
    },
    willYouNeedFacebookOrInstagramVideo: {
      oneOf: [
        {
          properties: {
            willYouNeedFacebookOrInstagramVideo: {
              enum: ['Yes'],
            },
            videoSizeSelected: {
              type: 'array',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.videoSizeSelectedTitle'),
              items: {
                type: 'string',
                enum: [
                  t('pages.socialAds.solutionStrategy.imageryVideo.videoSizeSelectedCheckBoxOptions1'),
                  t('pages.socialAds.solutionStrategy.imageryVideo.videoSizeSelectedCheckBoxOptions2'),
                ]
              },
              uniqueItems: true,
            },
            videoBestPracticesLabel: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.videoBestPracticesLabel'),
            },
            wasThereAnUpsellOfFacebookOrInstagramSingleStoryVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.wasThereAnUpsellOfFacebookOrInstagramSingleStoryVideoTitle'),
              default: data?.wasThereAnUpsellOfFacebookOrInstagramSingleStoryVideo || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
            wasThereAnUpsellOfSnapchatSingleVideo: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.wasThereAnUpsellOfSnapchatSingleVideoTitle'),
              default: data?.wasThereAnUpsellOfSnapchatSingleVideo || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            }
          }
        }
      ],
    },
    instagramOrSnapchatStoryVideoCarousel: {
      oneOf: [
        {
          properties: {
            instagramOrSnapchatStoryVideoCarousel: {
              enum: ['Yes'],
            },
            storyVideo1Label: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.storyVideo1Label'),
            },
            providedClientVideoForStoryVideo1:{
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willTheClientBeProvidingVideoTitle'),
              default: data?.providedClientVideoForStoryVideo1 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
            storyVideo2Label: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.storyVideo2Label'),
            },
            providedClientVideoForStoryVideo2:{
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willTheClientBeProvidingVideoTitle'),
              default: data?.providedClientVideoForStoryVideo2 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
            storyVideo3Label: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.storyVideo3Label'),
            },
            providedClientVideoForStoryVideo3:{
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willTheClientBeProvidingVideoTitle'),
              default: data?.providedClientVideoForStoryVideo3 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
            requireSoundEffectsOrMusic:{
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.requireSoundEffectsOrMusicTitle'),
              default: data?.requireSoundEffectsOrMusic || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
            storyVideo4Label: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.storyVideo4Label'),
            },
            providedClientVideoForStoryVideo4:{
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willTheClientBeProvidingVideoTitle'),
              default: data?.providedClientVideoForStoryVideo4 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
            storyVideo5Label: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.storyVideo5Label'),
            },
            providedClientVideoForStoryVideo5:{
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willTheClientBeProvidingVideoTitle'),
              default: data?.providedClientVideoForStoryVideo5 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
          },
        },
      ],
    },
    providedClientVideoForStoryVideo1: {
      oneOf: [
        {
          properties: {
            providedClientVideoForStoryVideo1: {
              enum: ['Yes'],
            },
            whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatTypeOfWorkNeedsToBeCompletedOnVideoTitle'),
              default: data?.whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo1 || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.imageryVideo.titleCardOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.minorVideoEditingOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.addStaticContentOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.trimTimeframeOptions'),
              ]
            },
            requireSoundEffectsOrMusicForStoryVideo1:{
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.requireSoundEffectsOrMusicTitle'),
              default: data?.requireSoundEffectsOrMusicForStoryVideo1 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
          },
        },
        {
          properties: {
            providedClientVideoForStoryVideo1: {
              enum: ['No'],
            },
            willYouBeSupplyingImageToCreateVideoFromForStoryVideo1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeSupplyingImageToCreateVideoFromTitle'),
              default: data?.willYouBeSupplyingImageToCreateVideoFromForStoryVideo1 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
            requirementsForImagesSelectedLabelForStoryVideo1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.requirementsForImagesSelectedLabel'),
            },
            image1ForStoryVideo1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image1Label'),
              default: data?.image1 || '',
            },
            image2ForStoryVideo1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image2Label'),
              default: data?.image2 || '',
            },
            image3ForStoryVideo1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image3Label'),
              default: data?.image3 || '',
            },
          },
        },
      ],
    },
    requireSoundEffectsOrMusicForStoryVideo1: {
      oneOf: [
        {
          properties: {
            requireSoundEffectsOrMusicForStoryVideo1: {
              enum: ['Yes'],
            },
            describeSoundEffectsOrMusicRequiredForStoryVideo1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.describeSoundEffectsOrMusicRequiredTitle'),
              default: data?.describeSoundEffectsOrMusicRequiredForStoryVideo1 || '',
            },
          },
        },
      ],
    },
    providedClientVideoForStoryVideo2: {
      oneOf: [
        {
          properties: {
            providedClientVideoForStoryVideo2: {
              enum: ['Yes'],
            },
            whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatTypeOfWorkNeedsToBeCompletedOnVideoTitle'),
              default: data?.whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo2 || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.imageryVideo.titleCardOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.minorVideoEditingOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.addStaticContentOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.trimTimeframeOptions'),
              ]
            },
            requireSoundEffectsOrMusicForStoryVideo2:{
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.requireSoundEffectsOrMusicTitle'),
              default: data?.requireSoundEffectsOrMusicForStoryVideo2 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
          },
        },
        {
          properties: {
            providedClientVideoForStoryVideo2: {
              enum: ['No'],
            },
            willYouBeSupplyingImageToCreateVideoFromForStoryVideo2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeSupplyingImageToCreateVideoFromTitle'),
              default: data?.willYouBeSupplyingImageToCreateVideoFromForStoryVideo2 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
            requirementsForImagesSelectedLabelForStoryVideo2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.requirementsForImagesSelectedLabel'),
            },
            image1ForStoryVideo2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image1Label'),
              default: data?.image1ForStoryVideo2 || '',
            },
            image2ForStoryVideo2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image2Label'),
              default: data?.image2ForStoryVideo2 || '',
            },
            image3ForStoryVideo2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image3Label'),
              default: data?.image3ForStoryVideo2 || '',
            },
            whatIsTheLengthOfTheVideoInSecond: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheLengthOfTheVideoInSecondTitle'),
              default: data?.whatIsTheLengthOfTheVideoInSecond || '',
            },
            areThereBrandingElementsRequired: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.areThereBrandingElementsRequiredTitle'),
              default: data?.areThereBrandingElementsRequired || '',
            },
            whatIsTheMainMessageTheVideoShouldConvey: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheMainMessageTheVideoShouldConveyTitle'),
              default: data?.whatIsTheMainMessageTheVideoShouldConvey || '',
            },
            willYouBeProvidingAScriptToFollow: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeProvidingAScriptToFollowTitle'),
              default: data?.willYouBeProvidingAScriptToFollow || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
          },
        },
      ],
    },
    willYouBeProvidingAScriptToFollow: {
      oneOf: [
        {
          properties: {
            willYouBeProvidingAScriptToFollow: {
              enum: ['No'],
            },
            willYouBeUploadingAStoryboardToFollow: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeUploadingAStoryboardToFollowTitle'),
              default: data?.willYouBeUploadingAStoryboardToFollow || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
            provideBasicStoryboardLabel: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.provideBasicStoryboardLabel'),
            },
            screen1: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen1Title'),
              default: data?.screen1 || '',
            },
            screen2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen2Title'),
              default: data?.screen2 || '',
            },
            screen3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen3Title'),
              default: data?.screen3 || '',
            },
            screen4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen4Title'),
              default: data?.screen4 || '',
            },
            screen5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen5Title'),
              default: data?.screen5 || '',
            },
          },
        },
      ],
    },
    requireSoundEffectsOrMusicForStoryVideo2: {
      oneOf: [
        {
          properties: {
            requireSoundEffectsOrMusicForStoryVideo2: {
              enum: ['Yes'],
            },
            describeSoundEffectsOrMusicRequiredForStoryVideo2: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.describeSoundEffectsOrMusicRequiredTitle'),
              default: data?.describeSoundEffectsOrMusicRequiredForStoryVideo2 || '',
            },
          },
        },
      ],
    },
    providedClientVideoForStoryVideo3: {
      oneOf: [
        {
          properties: {
            providedClientVideoForStoryVideo3: {
              enum: ['Yes'],
            },
            whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo3: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatTypeOfWorkNeedsToBeCompletedOnVideoTitle'),
              default: data?.whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo3 || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.imageryVideo.titleCardOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.minorVideoEditingOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.addStaticContentOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.trimTimeframeOptions'),
              ]
            },
          },
        },
      ],
    },
    providedClientVideoForStoryVideo4: {
      oneOf: [
        {
          properties: {
            providedClientVideoForStoryVideo4: {
              enum: ['Yes'],
            },
            whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatTypeOfWorkNeedsToBeCompletedOnVideoTitle'),
              default: data?.whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo4 || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.imageryVideo.titleCardOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.minorVideoEditingOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.addStaticContentOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.trimTimeframeOptions'),
              ]
            },
          },
        },
        {
          properties: {
            providedClientVideoForStoryVideo4: {
              enum: ['No'],
            },
            willYouBeSupplyingImageToCreateVideoFromForStoryVideo4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeSupplyingImageToCreateVideoFromTitle'),
              default: data?.willYouBeSupplyingImageToCreateVideoFromForStoryVideo4 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
            requirementsForImagesSelectedLabelForStoryVideo4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.requirementsForImagesSelectedLabel'),
            },
            image1ForStoryVideo4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image1Label'),
              default: data?.image1ForStoryVideo4 || '',
            },
            image2ForStoryVideo4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image2Label'),
              default: data?.image2ForStoryVideo4 || '',
            },
            image3ForStoryVideo4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image3Label'),
              default: data?.image3ForStoryVideo4 || '',
            },
            requireSoundEffectsOrMusicForStoryVideo4:{
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.requireSoundEffectsOrMusicTitle'),
              default: data?.requireSoundEffectsOrMusicForStoryVideo4 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
          },
        },
      ],
    },
    requireSoundEffectsOrMusicForStoryVideo4: {
      oneOf: [
        {
          properties: {
            requireSoundEffectsOrMusicForStoryVideo4: {
              enum: ['Yes'],
            },
            describeSoundEffectsOrMusicRequiredForStoryVideo4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.describeSoundEffectsOrMusicRequiredTitle'),
              default: data?.describeSoundEffectsOrMusicRequiredForStoryVideo4 || '',
            },
          },
        },
      ],
    },
    providedClientVideoForStoryVideo5: {
      oneOf: [
        {
          properties: {
            providedClientVideoForStoryVideo5: {
              enum: ['Yes'],
            },
            whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatTypeOfWorkNeedsToBeCompletedOnVideoTitle'),
              default: data?.describeSoundEffectsOrMusicRequiredForStoryVideo4 || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.imageryVideo.titleCardOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.minorVideoEditingOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.addStaticContentOptions'),
                t('pages.socialAds.solutionStrategy.imageryVideo.trimTimeframeOptions'),
              ]
            },
          },
        },
        {
          properties: {
            providedClientVideoForStoryVideo5: {
              enum: ['No'],
            },
            willYouBeSupplyingImageToCreateVideoFromForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeSupplyingImageToCreateVideoFromTitle'),
              default: data?.describeSoundEffectsOrMusicRequiredForStoryVideo4 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
            requirementsForImagesSelectedLabelForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.requirementsForImagesSelectedLabel'),
            },
            image1ForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image1Label'),
              default: data?.image1ForStoryVideo5 || '',
            },
            image2ForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image2Label'),
              default: data?.image2ForStoryVideo5 || '',
            },
            image3ForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.image3Label'),
              default: data?.image3ForStoryVideo5 || '',
            },
            whatIsTheLengthOfTheVideoInSecondForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheLengthOfTheVideoInSecondTitle'),
              default: data?.whatIsTheLengthOfTheVideoInSecondForStoryVideo5 || '',
            },
            areThereBrandingElementsRequiredForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.areThereBrandingElementsRequiredTitle'),
              default: data?.areThereBrandingElementsRequiredForStoryVideo5 || '',
            },
            whatIsTheMainMessageTheVideoShouldConveyForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheMainMessageTheVideoShouldConveyTitle'),
              default: data?.whatIsTheMainMessageTheVideoShouldConveyForStoryVideo5 || '',
            },
            willYouBeProvidingAScriptToFollowForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeProvidingAScriptToFollowTitle'),
              default: data?.willYouBeProvidingAScriptToFollowForStoryVideo5 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
          },
        },
      ],
    },
    willYouBeProvidingAScriptToFollowForStoryVideo5: {
      oneOf: [
        {
          properties: {
            willYouBeProvidingAScriptToFollowForStoryVideo5: {
              enum: ['Yes'],
            },
            ProvideBasicStoryboardLabelForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.provideBasicStoryboardLabel'),
            },
            Screen1ForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen1Title'),
              default: data?.Screen1ForStoryVideo5 || '',
            },
            Screen2ForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen2Title'),
              default: data?.Screen2ForStoryVideo5 || '',
            },
            Screen3ForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen3Title'),
              default: data?.Screen3ForStoryVideo5 || '',
            },
            Screen4ForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen4Title'),
              default: data?.Screen4ForStoryVideo5 || '',
            },
            Screen5ForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen5Title'),
              default: data?.Screen5ForStoryVideo5 || '',
            },
            RequireSoundEffectsOrMusicForStoryVideo5:{
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.requireSoundEffectsOrMusicTitle'),
              default: data?.RequireSoundEffectsOrMusicForStoryVideo5 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
          },
        },
        {
          properties: {
            willYouBeProvidingAScriptToFollowForStoryVideo5: {
              enum: ['No'],
            },
            willYouBeUploadingAStoryboardToFollowForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.willYouBeUploadingAStoryboardToFollowTitle'),
              default: data?.willYouBeUploadingAStoryboardToFollowForStoryVideo5 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
            provideBasicStoryboardLabelForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.provideBasicStoryboardLabel'),
            },
            screen1ForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen1Title'),
              default: data?.screen1ForStoryVideo5 || '',
            },
            screen2ForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen2Title'),
              default: data?.screen2ForStoryVideo5 || '',
            },
            screen3ForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen3Title'),
              default: data?.screen3ForStoryVideo5 || '',
            },
            screen4ForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen4Title'),
              default: data?.screen4ForStoryVideo5 || '',
            },
            screen5ForStoryVideo5: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.screen5Title'),
              default: data?.screen5ForStoryVideo5 || '',
            },
            requireSoundEffectsOrMusicForStoryVideo5:{
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.requireSoundEffectsOrMusicTitle'),
              default: data?.requireSoundEffectsOrMusicForStoryVideo5 || '',
              enum: [
                '',
                'Yes',
                'No'
              ]
            },
          },
        },
      ],
    },
    doesYourAdRequireTheManualPickupOfAnyElementsFromAnotherAd: {
      oneOf: [
        {
          properties: {
            doesYourAdRequireTheManualPickupOfAnyElementsFromAnotherAd: {
              enum: ['Yes'],
            },
            whatTheCidDoYouNeedElementsPickedUpFrom: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatTheCidDoYouNeedElementsPickedUpFromLabel'),
              default: data?.whatTheCidDoYouNeedElementsPickedUpFrom || '',
            },
          },
        },
      ],
    },
    pleaseSelectALevelOfCreativityArtisticDiscretion: {
      oneOf: [
        {
          properties: {
            pleaseSelectALevelOfCreativityArtisticDiscretion: {
              enum: ['Template Based Ad'],
            },
            ifThereIsATemplateNameWeShouldReferencePleaseProvideItBelow: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.ifThereIsATemplateNameWeShouldReferencePleaseProvideItBelowLabel'),
              default: data?.ifThereIsATemplateNameWeShouldReferencePleaseProvideItBelow || '',
            },
            ifWeShouldReferenceANonAccountSpecificWikiOrResourcePleaseProvideItBelow: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.ifWeShouldReferenceANonAccountSpecificWikiOrResourcePleaseProvideItBelowLabel'),
              default: data?.ifWeShouldReferenceANonAccountSpecificWikiOrResourcePleaseProvideItBelow || '',
            },
          },
        },
        {
          properties: {
            pleaseSelectALevelOfCreativityArtisticDiscretion: {
              enum: ['Some Creative Freedom Needed'],
            },
            pleaseSelectItemsThatWeShouldFocusOn: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseSelectItemsThatWeShouldFocusOnLabel'),
              default: data?.pleaseSelectItemsThatWeShouldFocusOn || '',
            },
            ifWeShouldReferenceANonAccountSpecificWikiOrResourcePleaseProvideItBelow: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.ifWeShouldReferenceANonAccountSpecificWikiOrResourcePleaseProvideItBelowLabel'),
              default: data?.ifWeShouldReferenceANonAccountSpecificWikiOrResourcePleaseProvideItBelow || '',
            },
          },
        },
        {
          properties: {
            pleaseSelectALevelOfCreativityArtisticDiscretion: {
              enum: ['Be Creative, Apply Data Informed Design'],
            },
            pleaseSelectItemsThatDescribeTheFeelYouWantYourAdToHave: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.pleaseSelectItemsThatDescribeTheFeelYouWantYourAdToHaveLabel'),
              default: data?.pleaseSelectItemsThatDescribeTheFeelYouWantYourAdToHave || '',
            },
            whoIsYourTargetAudience: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whoIsYourTargetAudienceLabel'),
              default: data?.whoIsYourTargetAudience || '',
            },
            whatIsTheGoalOfTheAd: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheGoalOfTheAdLabel'),
              default: data?.whatIsTheGoalOfTheAd || '',
            },
            ifWeShouldReferenceANonAccountSpecificWikiOrResourcePleaseProvideItBelow: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.imageryVideo.ifWeShouldReferenceANonAccountSpecificWikiOrResourcePleaseProvideItBelowLabel'),
              default: data?.ifWeShouldReferenceANonAccountSpecificWikiOrResourcePleaseProvideItBelow || '',
            },
          },
        },
      ],
    },
  },
});

const headingTextLabelKeys = [
  'createdThroughDesigniQATOLOrderLabel',
  'whatTypeOfImageryDoYouNeedOnYour3FacebookCarousel1080x1080creatives',
  'Image1',
  'Image2',
  'Image3',
  'Image4',
  'Image5',
  'Image6',
  'Image7',
  'Image8',
  'Image9',
  'Image10',
  'storyVideo1Label',
  'storyVideo2Label',
  'storyVideo3Label',
  'storyVideo4Label',
  'storyVideo5Label',
  'requirementsForImagesSelectedLabelForStoryVideo1',
  'requirementsForImagesSelectedLabelForStoryVideo2',
  'provideBasicStoryboardLabel',
  'requirementsForImagesSelectedLabelForStoryVideo4',
  'requirementsForImagesSelectedLabelForStoryVideo5',
  'provideBasicStoryboardLabelForStoryVideo5',
  'ProvideBasicStoryboardLabelForStoryVideo5',
  'requirementsForImagesSelectedLabel',
  'ProvideBasicStoryboardLabelForProvidedClientVideo',
  'ProvideBasicStoryboardLabelForStoryVideo1',
  'ProvideBasicStoryboardLabelForStoryVideo3',
  'ProvideBasicStoryboardLabelForStoryVideo4',
];

const radioFieldKeys = [
  'salesRepLabel',
  'creativeSizesLabel',
  'whereShouldWeGetTheInformationForThisAd',
];

const textFieldKeys = [
  'whoIsYourTargetAudience',
  'whatIsTheGoalOfTheAd',
  'whatTheCidDoYouNeedElementsPickedUpFrom',
  'ifWeShouldReferenceANonAccountSpecificWikiOrResourcePleaseProvideItBelow',
  'pleaseListOutWhatTypeOfImageryAndContentShouldBeIncludedOnTheFacebookInstagramCreative',
  'image1',
  'image2',
  'image3',
  'image4',
  'image5',
  'image6',
  'image7',
  'image8',
  'image9',
  'image10',
  'pleaseListWhatTypeOfImageryAndContentShouldBeIncluded',
  'whatIsTagline',
  'whatLogoShouldBeUsed',
  'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage1',
  'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage1',
  'whatIsTaglineForInstagramOrSnapchatStoryImage1',
  'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage2',
  'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage2',
  'whatIsTaglineForInstagramOrSnapchatStoryImage2',
  'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage3',
  'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage3',
  'whatIsTaglineForInstagramOrSnapchatStoryImage3',
  'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage4',
  'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage4',
  'whatIsTaglineForInstagramOrSnapchatStoryImage4',
  'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage5',
  'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage5',
  'whatIsTaglineForInstagramOrSnapchatStoryImage5',
  'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage6',
  'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage6',
  'whatIsTaglineForInstagramOrSnapchatStoryImage6',
  'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage7',
  'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage7',
  'whatIsTaglineForInstagramOrSnapchatStoryImage7',
  'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage8',
  'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage8',
  'whatIsTaglineForInstagramOrSnapchatStoryImage8',
  'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage9',
  'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage9',
  'whatIsTaglineForInstagramOrSnapchatStoryImage9',
  'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage10',
  'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage10',
  'whatIsTaglineForInstagramOrSnapchatStoryImage10',
  'describeSoundEffectsOrMusicRequiredForStoryVideo1',
  'image1ForStoryVideo1',
  'image2ForStoryVideo1',
  'image3ForStoryVideo1',
  'describeSoundEffectsOrMusicRequiredForStoryVideo2',
  'image1ForStoryVideo2',
  'image2ForStoryVideo2',
  'image3ForStoryVideo2',
  'areThereBrandingElementsRequired',
  'whatIsTheMainMessageTheVideoShouldConvey',
  'screen1',
  'screen2',
  'screen3',
  'screen4',
  'screen5',
  'image1ForStoryVideo4',
  'image2ForStoryVideo4',
  'image3ForStoryVideo4',
  'describeSoundEffectsOrMusicRequiredForStoryVideo4',
  'image1ForStoryVideo5',
  'image2ForStoryVideo5',
  'image3ForStoryVideo5',
  'areThereBrandingElementsRequiredForStoryVideo5',
  'whatIsTheMainMessageTheVideoShouldConveyForStoryVideo5',
  'screen1ForStoryVideo5',
  'screen2ForStoryVideo5',
  'screen3ForStoryVideo5',
  'screen4ForStoryVideo5',
  'screen5ForStoryVideo5',
  'Screen1ForStoryVideo5',
  'Screen2ForStoryVideo5',
  'Screen3ForStoryVideo5',
  'Screen4ForStoryVideo5',
  'Screen5ForStoryVideo5',
  'Screen1ForProvidedClientVideo',
  'Screen2ForProvidedClientVideo',
  'Screen3ForProvidedClientVideo',
  'Screen4ForProvidedClientVideo',
  'Screen5ForProvidedClientVideo',
  'image1ForProvidedClientVideo',
  'image2ForProvidedClientVideo',
  'image3ForProvidedClientVideo',
  'areThereBrandingElementsRequiredForProvidedClientVideo',
  'whatIsTheMainMessageTheVideoShouldConveyForProvidedClientVideo',
  'areThereBrandingElementsRequiredForStoryVideo1',
  'whatIsTheMainMessageTheVideoShouldConveyForStoryVideo1',
  'Screen1ForStoryVideo1',
  'Screen2ForStoryVideo1',
  'Screen3ForStoryVideo1',
  'Screen4ForStoryVideo1',
  'Screen5ForStoryVideo1',
  'areThereBrandingElementsRequiredForStoryVideo3',
  'whatIsTheMainMessageTheVideoShouldConveyForStoryVideo3',
  'Screen1ForStoryVideo3',
  'Screen2ForStoryVideo3',
  'Screen3ForStoryVideo3',
  'Screen4ForStoryVideo3',
  'Screen5ForStoryVideo3',
  'areThereBrandingElementsRequiredForStoryVideo4',
  'whatIsTheMainMessageTheVideoShouldConveyForStoryVideo4',
  'Screen1ForStoryVideo4',
  'Screen2ForStoryVideo4',
  'Screen3ForStoryVideo4',
  'Screen4ForStoryVideo4',
  'Screen5ForStoryVideo4',
];

const textAreaFieldKeys = [
  'pleaseSelectItemsThatDescribeTheFeelYouWantYourAdToHave',
  'ifThereIsATemplateNameWeShouldReferencePleaseProvideItBelow',
  'pleaseSelectItemsThatWeShouldFocusOn',
  'isThereAnythingElseTheDesignerShouldKnowAboutTheCreativeForThisAd',
  'pleaseListOutWhatTypeOfImageryAndContentShouldBeIncludedOnTheCreative',
];

const dropdownFieldKeys = [
  'willTheClientBeProvidingImagesToUse',
  'pleaseSelectTheVerticalMarketIndustryForThis',
  'doesYourAdRequireTheManualPickupOfAnyElementsFromAnotherAd',
  'pleaseSelectALevelOfCreativityArtisticDiscretion',
  'followAutoCoOpGuidelines',
  'willYouNeedAStatic1200x628FacebookCreative',
  'willYouNeedAStatic1080x1080FacebookInstagramCreative',
  'willThisCampaignUtilizeTheFacebookCarouselFormat',
  'wasThereAnUpsell',
  'wasTheUpsellForFacebookOrInstagramOrSnapchatSingleImage',
  'willTheClientNeedAVideo',
  'doYouNeedMoreThan3FacebookCarousel1080x1080Images',
  'willYouNeedStatic1200x628FacebookCreative',
  'willYouNeedStatic1080x1080FacebookInstagramCreative',
  'doYouNeedMoreThanThreeStoryImageCarouselCreatives',
  'willYouNeedFacebookOrInstagramVideo',
  'wasThereAnUpsellOfFacebookOrInstagramSingleStoryVideo',
  'wasThereAnUpsellOfSnapchatSingleVideo',

  'providedClientVideo',
  'whatTypeOfWorkNeedsToBeCompletedOnVideo',
  'beSupplyingImageToCreateVideoFrom',
  'BeProvidingAScriptToFollowForProvidedClientVideo',
  'BeUploadingAStoryboardToFollowForProvidedClientVideo',
  'BeProvidingAScriptToFollowForStoryVideo1',
  'BeUploadingAStoryboardToFollowForStoryVideo1',
  'BeProvidingAScriptToFollowForStoryVideo3',
  'BeUploadingAStoryboardToFollowForStoryVideo3',
  'BeProvidingAScriptToFollowForStoryVideo4',
  'BeUploadingAStoryboardToFollowForStoryVideo4',,

  'providedClientVideoForStoryVideo1',
  'whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo1',
  'requireSoundEffectsOrMusicForStoryVideo1',
  'providedClientVideoForStoryVideo2',
  'whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo2',
  'requireSoundEffectsOrMusicForStoryVideo2',
  'providedClientVideoForStoryVideo3',
  'providedClientVideoForStoryVideo4',
  'providedClientVideoForStoryVideo5',
  'requireSoundEffectsOrMusic',
  'willYouBeSupplyingImageToCreateVideoFromForStoryVideo1',
  'willYouBeSupplyingImageToCreateVideoFromForStoryVideo2',
  'willYouBeProvidingAScriptToFollow',
  'willYouBeUploadingAStoryboardToFollow',
  'whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo3',
  'whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo4',
  'willYouBeSupplyingImageToCreateVideoFromForStoryVideo4',
  'requireSoundEffectsOrMusicForStoryVideo4',
  'whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo5',
  'willYouBeSupplyingImageToCreateVideoFromForStoryVideo5',
  'willYouBeProvidingAScriptToFollowForStoryVideo5',
  'willYouBeUploadingAStoryboardToFollowForStoryVideo5',
  'requireSoundEffectsOrMusicForStoryVideo5',
  'RequireSoundEffectsOrMusicForStoryVideo5',

];

const headingTextLabels = headingTextLabelKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textLabel'}
}), {});

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o, [key]: {
    'ui:widget': (props) => <RadioButton {...props} disabled={true}/>,
  },
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'}
}), {});

const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'TextareaWidget'}
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'dropdown'}
}), {});

export const ImageryVideoUISchema = {
  'ui:order': [
    'whatTypeOfVisualStrategyDoesYourClientWantToEmploy',
    'willTheClientBeProvidingImagesToUse',
    'createdThroughDesigniQATOLOrderLabel',
    'salesRepLabel',
    'creativeSizesLabel',
    'whereShouldWeGetTheInformationForThisAd',
    'pleaseSelectTheVerticalMarketIndustryForThis',
    'doesYourAdRequireTheManualPickupOfAnyElementsFromAnotherAd',
    'whatTheCidDoYouNeedElementsPickedUpFrom',
    'pleaseSelectALevelOfCreativityArtisticDiscretion',
    'ifThereIsATemplateNameWeShouldReferencePleaseProvideItBelow',
    'pleaseSelectItemsThatWeShouldFocusOn',
    'pleaseSelectItemsThatDescribeTheFeelYouWantYourAdToHave',
    'whoIsYourTargetAudience',
    'whatIsTheGoalOfTheAd',
    'ifWeShouldReferenceANonAccountSpecificWikiOrResourcePleaseProvideItBelow',
    'willYouNeedAStatic1200x628FacebookCreative',
    'pleaseListOutWhatTypeOfImageryAndContentShouldBeIncludedOnTheCreative',
    'willYouNeedAStatic1080x1080FacebookInstagramCreative',
    'pleaseListOutWhatTypeOfImageryAndContentShouldBeIncludedOnTheFacebookInstagramCreative',
    'willThisCampaignUtilizeTheFacebookCarouselFormat',
    'whatTypeOfImageryDoYouNeedOnYour3FacebookCarousel1080x1080creatives',
    'image1',
    'image2',
    'image3',
    'doYouNeedMoreThan3FacebookCarousel1080x1080Images',
    'image4',
    'image5',
    'image6',
    'image7',
    'image8',
    'image9',
    'image10',
    'wasThereAnUpsell',
    'wasTheUpsellForFacebookOrInstagramOrSnapchatSingleImage',
    'pleaseListWhatTypeOfImageryAndContentShouldBeIncluded',
    'whatIsTagline',
    'whatLogoShouldBeUsed',
    'wasTheUpsellForInstagramOrSnapchatStoryImageCarousel',
    'Image1',
    'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage1',
    'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage1',
    'whatIsTaglineForInstagramOrSnapchatStoryImage1',
    'Image2',
    'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage2',
    'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage2',
    'whatIsTaglineForInstagramOrSnapchatStoryImage2',
    'Image3',
    'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage3',
    'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage3',
    'whatIsTaglineForInstagramOrSnapchatStoryImage3',
    'doYouNeedMoreThanThreeStoryImageCarouselCreatives',
    'Image4',
    'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage4',
    'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage4',
    'whatIsTaglineForInstagramOrSnapchatStoryImage4',
    'Image5',
    'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage5',
    'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage5',
    'whatIsTaglineForInstagramOrSnapchatStoryImage5',
    'Image6',
    'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage6',
    'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage6',
    'whatIsTaglineForInstagramOrSnapchatStoryImage6',
    'Image7',
    'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage7',
    'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage7',
    'whatIsTaglineForInstagramOrSnapchatStoryImage7',
    'Image8',
    'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage8',
    'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage8',
    'whatIsTaglineForInstagramOrSnapchatStoryImage8',
    'Image9',
    'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage9',
    'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage9',
    'whatIsTaglineForInstagramOrSnapchatStoryImage9',
    'Image10',
    'pleaseListWhatTypeOfImageryAndContentShouldBeIncludedForInstagramOrSnapchatStoryImage10',
    'whatLogoShouldBeUsedForInstagramOrSnapchatStoryImage10',
    'whatIsTaglineForInstagramOrSnapchatStoryImage10',
    'willTheClientNeedAVideo',
    'willYouNeedFacebookOrInstagramVideo',
    'videoSizeSelected',
    'videoBestPracticesLabel',
    'wasThereAnUpsellOfFacebookOrInstagramSingleStoryVideo',
    'wasThereAnUpsellOfSnapchatSingleVideo',

    'providedClientVideo',
    'beSupplyingImageToCreateVideoFrom',
    'requirementsForImagesSelectedLabel',
    'image1ForProvidedClientVideo',
    'image2ForProvidedClientVideo',
    'image3ForProvidedClientVideo',
    'whatIsTheLengthOfTheVideoInSecondForProvidedClientVideo',
    'areThereBrandingElementsRequiredForProvidedClientVideo',
    'whatIsTheMainMessageTheVideoShouldConveyForProvidedClientVideo',
    'BeProvidingAScriptToFollowForProvidedClientVideo',

    'BeUploadingAStoryboardToFollowForProvidedClientVideo',
    'ProvideBasicStoryboardLabelForProvidedClientVideo',
    'Screen1ForProvidedClientVideo',
    'Screen2ForProvidedClientVideo',
    'Screen3ForProvidedClientVideo',
    'Screen4ForProvidedClientVideo',
    'Screen5ForProvidedClientVideo',

    'whatTypeOfWorkNeedsToBeCompletedOnVideo',
    'requireSoundEffectsOrMusic',
    'describeSoundEffectsOrMusicRequired',

    'instagramOrSnapchatStoryVideoCarousel',
    'storyVideo1Label',
    'providedClientVideoForStoryVideo1',
    'whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo1',
    'requireSoundEffectsOrMusicForStoryVideo1',
    'describeSoundEffectsOrMusicRequiredForStoryVideo1',
    'willYouBeSupplyingImageToCreateVideoFromForStoryVideo1',
    'requirementsForImagesSelectedLabelForStoryVideo1',
    'image1ForStoryVideo1',
    'image2ForStoryVideo1',
    'image3ForStoryVideo1',
    'whatIsTheLengthOfTheVideoInSecondForStoryVideo1',
    'areThereBrandingElementsRequiredForStoryVideo1',
    'whatIsTheMainMessageTheVideoShouldConveyForStoryVideo1',
    'BeProvidingAScriptToFollowForStoryVideo1',
    'BeUploadingAStoryboardToFollowForStoryVideo1',
    'ProvideBasicStoryboardLabelForStoryVideo1',
    'Screen1ForStoryVideo1',
    'Screen2ForStoryVideo1',
    'Screen3ForStoryVideo1',
    'Screen4ForStoryVideo1',
    'Screen5ForStoryVideo1',

    'storyVideo2Label',
    'providedClientVideoForStoryVideo2',
    'whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo2',
    'requireSoundEffectsOrMusicForStoryVideo2',
    'describeSoundEffectsOrMusicRequiredForStoryVideo2',
    'willYouBeSupplyingImageToCreateVideoFromForStoryVideo2',
    'requirementsForImagesSelectedLabelForStoryVideo2',
    'image1ForStoryVideo2',
    'image2ForStoryVideo2',
    'image3ForStoryVideo2',
    'whatIsTheLengthOfTheVideoInSecond',
    'areThereBrandingElementsRequired',
    'whatIsTheMainMessageTheVideoShouldConvey',
    'willYouBeProvidingAScriptToFollow',
    'willYouBeUploadingAStoryboardToFollow',
    'provideBasicStoryboardLabel',
    'screen1',
    'screen2',
    'screen3',
    'screen4',
    'screen5',

    'storyVideo3Label',
    'providedClientVideoForStoryVideo3',
    'whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo3',
    'requireSoundEffectsOrMusic',
    'whatIsTheLengthOfTheVideoInSecondForStoryVideo3',
    'areThereBrandingElementsRequiredForStoryVideo3',
    'whatIsTheMainMessageTheVideoShouldConveyForStoryVideo3',
    'BeProvidingAScriptToFollowForStoryVideo3',
    'BeUploadingAStoryboardToFollowForStoryVideo3',
    'ProvideBasicStoryboardLabelForStoryVideo3',
    'Screen1ForStoryVideo3',
    'Screen2ForStoryVideo3',
    'Screen3ForStoryVideo3',
    'Screen4ForStoryVideo3',
    'Screen5ForStoryVideo3',

    'storyVideo4Label',
    'providedClientVideoForStoryVideo4',
    'whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo4',
    'willYouBeSupplyingImageToCreateVideoFromForStoryVideo4',
    'requirementsForImagesSelectedLabelForStoryVideo4',
    'image1ForStoryVideo4',
    'image2ForStoryVideo4',
    'image3ForStoryVideo4',
    'requireSoundEffectsOrMusicForStoryVideo4',
    'describeSoundEffectsOrMusicRequiredForStoryVideo4',
    'whatIsTheLengthOfTheVideoInSecondForStoryVideo4',
    'areThereBrandingElementsRequiredForStoryVideo4',
    'whatIsTheMainMessageTheVideoShouldConveyForStoryVideo4',
    'BeProvidingAScriptToFollowForStoryVideo4',
    'BeUploadingAStoryboardToFollowForStoryVideo4',
    'ProvideBasicStoryboardLabelForStoryVideo4',
    'Screen1ForStoryVideo4',
    'Screen2ForStoryVideo4',
    'Screen3ForStoryVideo4',
    'Screen4ForStoryVideo4',
    'Screen5ForStoryVideo4',

    'storyVideo5Label',
    'providedClientVideoForStoryVideo5',
    'whatTypeOfWorkNeedsToBeCompletedOnVideoForStoryVideo5',
    'willYouBeSupplyingImageToCreateVideoFromForStoryVideo5',
    'requirementsForImagesSelectedLabelForStoryVideo5',
    'image1ForStoryVideo5',
    'image2ForStoryVideo5',
    'image3ForStoryVideo5',
    'whatIsTheLengthOfTheVideoInSecondForStoryVideo5',
    'areThereBrandingElementsRequiredForStoryVideo5',
    'whatIsTheMainMessageTheVideoShouldConveyForStoryVideo5',
    'willYouBeProvidingAScriptToFollowForStoryVideo5',
    'willYouBeUploadingAStoryboardToFollowForStoryVideo5',
    'provideBasicStoryboardLabelForStoryVideo5',
    'screen1ForStoryVideo5',
    'screen2ForStoryVideo5',
    'screen3ForStoryVideo5',
    'screen4ForStoryVideo5',
    'screen5ForStoryVideo5',
    'requireSoundEffectsOrMusicForStoryVideo5',
    'ProvideBasicStoryboardLabelForStoryVideo5',
    'Screen1ForStoryVideo5',
    'Screen2ForStoryVideo5',
    'Screen3ForStoryVideo5',
    'Screen4ForStoryVideo5',
    'Screen5ForStoryVideo5',
    'RequireSoundEffectsOrMusicForStoryVideo5',
    'isThereAnythingElseTheDesignerShouldKnowAboutTheCreativeForThisAd',
    'followAutoCoOpGuidelines',
  ],
  whatTypeOfVisualStrategyDoesYourClientWantToEmploy: {
    'ui:widget': 'hidden'
  },

  whatIsTheLengthOfTheVideoInSecondForStoryVideo1: {
    'ui:widget': 'integerInputField',
    'ui:description': t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheLengthOfTheVideoInSecondHelper'),
  },
  whatIsTheLengthOfTheVideoInSecondForStoryVideo3: {
    'ui:widget': 'integerInputField',
    'ui:description': t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheLengthOfTheVideoInSecondHelper'),
  },
  whatIsTheLengthOfTheVideoInSecondForStoryVideo4: {
    'ui:widget': 'integerInputField',
    'ui:description': t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheLengthOfTheVideoInSecondHelper'),
  },
  whatIsTheLengthOfTheVideoInSecondForProvidedClientVideo: {
    'ui:widget': 'integerInputField',
    'ui:description': t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheLengthOfTheVideoInSecondHelper'),
  },
  whatIsTheLengthOfTheVideoInSecondForStoryVideo5: {
    'ui:widget': 'textInputField',
    'ui:placeholder': t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheLengthOfTheVideoInSecondHelper'),
  },
  whatIsTheLengthOfTheVideoInSecond: {
    'ui:widget': 'textInputField',
    'ui:placeholder': t('pages.socialAds.solutionStrategy.imageryVideo.whatIsTheLengthOfTheVideoInSecondHelper'),
  },
  videoBestPracticesLabel: {
    'ui:widget': 'textLabel',
    'ui:description': t('pages.socialAds.solutionStrategy.imageryVideo.videoBestPracticesHelper'),
  },
  videoSizeSelected:{
    'ui:widget': 'checkBoxField',
  },
  wasTheUpsellForInstagramOrSnapchatStoryImageCarousel: {
    'ui:widget': 'dropdown',
    'ui:placeholder': t('pages.socialAds.solutionStrategy.imageryVideo.wasTheUpsellForInstagramOrSnapchatStoryImageCarouselHelper'),
  },
  instagramOrSnapchatStoryVideoCarousel: {
    'ui:widget': 'dropdown',
    'ui:placeholder': t('pages.socialAds.solutionStrategy.imageryVideo.instagramOrSnapchatStoryVideoCarouselHelper1'),
    'ui:description': t('pages.socialAds.solutionStrategy.imageryVideo.instagramOrSnapchatStoryVideoCarouselHelper2'),
  },
  ...textAreaFields,
  ...textFields,
  ...radioFields,
  ...headingTextLabels,
  ...dropdownFields,
};

export const ImageryVideoValidate = (formData: any, errors, uiSchema) => {
  validateSecondLimit(formData, 'whatIsTheLengthOfTheVideoInSecond', 30, errors, t);
  validateSecondLimit(formData, 'whatIsTheLengthOfTheVideoInSecondForProvidedClientVideo', 30, errors, t);
  validateSecondLimit(formData, 'whatIsTheLengthOfTheVideoInSecondForStoryVideo1', 30, errors, t);
  validateSecondLimit(formData, 'whatIsTheLengthOfTheVideoInSecondForStoryVideo3', 30, errors, t);
  validateSecondLimit(formData, 'whatIsTheLengthOfTheVideoInSecondForStoryVideo4', 30, errors, t);
  validateSecondLimit(formData, 'whatIsTheLengthOfTheVideoInSecondForStoryVideo5', 30, errors, t);
  return errors;
};