/* eslint-disable @typescript-eslint/no-unused-vars */
import i18n from 'i18n/i18n';
import {
  SocialAdsCustomValidate,
  SocialAdsSchema,
  SocialAdsUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/SocialAdsSchema';
import {
  FacebookUserDetailsCustomValidate,
  FacebookUserDetailsSchema,
  FacebookUserDetailsUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/FacebookUserDetailsSchema';
import {
  LeadEnhancementCustomValidate,
  LeadEnhancementSchema,
  LeadEnhancementUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/LeadEnhancementSchema';
import {
  FacebookCreativeInstructionsCustomValidate,
  FacebookCreativeInstructionsSchema,
  FacebookCreativeInstructionsUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/FacebookCreativeInstructionsSchema';
import {
  ImageryVideoCustomValidate,
  ImageryVideoSchema,
  ImageryVideoUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/ImageryVideoSchema';
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/NotesAndInstructionsSchema';
import {
  ContactInformationCustomValidate,
  ContactInformationSchema,
  ContactInformationUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/ContactInformationSchema';
import {
  SpecialAdCategoriesCustomValidate,
  SpecialAdCategoriesSchema,
  SpecialAdCategoriesUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/SpecialAdCategoriesSchema';
import {
  WebsitesCustomValidate,
  WebsitesSchema,
  WebsitesUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/WebsitesSchema';
import {
  ProxyOrProvisioningCustomValidate,
  ProxyOrProvisioningSchema,
  ProxyOrProvisioningUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/ProxyOrProvisioningSchema';

const { t } = i18n;

export const SocialAdsLeadAdsSchema = (data) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.specialAdCategories.title'),
      schema: SpecialAdCategoriesSchema(data),
      uiSchema: SpecialAdCategoriesUISchema,
      customValidate: SpecialAdCategoriesCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.websites.title'),
      schema: WebsitesSchema(data),
      uiSchema: WebsitesUISchema,
      customValidate: WebsitesCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.proxyProvisioning.title'),
      schema: ProxyOrProvisioningSchema(data),
      uiSchema: ProxyOrProvisioningUISchema(data),
      customValidate: ProxyOrProvisioningCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.contactInformation.title'),
      schema: ContactInformationSchema(data),
      uiSchema: ContactInformationUISchema,
      customValidate: ContactInformationCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.socialAdsLeadAds.title'),
      schema: SocialAdsSchema(data),
      uiSchema: SocialAdsUISchema,
      customValidate: SocialAdsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.facebookUserDetails.title'),
      schema: FacebookUserDetailsSchema(data),
      uiSchema: FacebookUserDetailsUISchema,
      customValidate: FacebookUserDetailsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.title'),
      schema: LeadEnhancementSchema(data),
      uiSchema: LeadEnhancementUISchema,
      customValidate: LeadEnhancementCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.title'),
      schema: FacebookCreativeInstructionsSchema(data),
      uiSchema: FacebookCreativeInstructionsUISchema,
      customValidate: FacebookCreativeInstructionsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ];
  if (data.isOrange) {
    const indexToInsert = 8;
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.title'),
      schema: ImageryVideoSchema(data),
      uiSchema: ImageryVideoUISchema,
      customValidate: ImageryVideoCustomValidate,
    });
  }
  return schema;
};

export const SocialAdsLeadAdsUISchema = (data) => ({
  'ui:order': [
    ...SpecialAdCategoriesUISchema['ui:order'],
    ...WebsitesUISchema['ui:order'],
    ...ProxyOrProvisioningUISchema(data)['ui:order'],
    ...ContactInformationUISchema['ui:order'],
    ...SocialAdsUISchema['ui:order'],
    ...FacebookUserDetailsUISchema['ui:order'],
    ...LeadEnhancementUISchema['ui:order'],
    ...FacebookCreativeInstructionsUISchema['ui:order'],
    ...ImageryVideoUISchema['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

export const SocialAdsLeadAdsCustomValidate = (formData: any, errors, uiSchema) =>
  // Add custom validation logic here
  errors;
