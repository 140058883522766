/* eslint-disable import/no-extraneous-dependencies */
import {RJSFSchema} from '@rjsf/utils';
import i18n from 'i18n/i18n';
import {validateUrl} from "pages/generic-product/details/custom-fields/UrlTextField";

const {t} = i18n;

export const WebsitesSchema: RJSFSchema | any = (data) => ({
  title: t('pages.socialAds.solutionStrategy.websites.title'),
  type: 'object',
  required:['localiqWebsite'],
  properties: {
    localiqWebsite: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.websites.localiqWebsiteTitle'),
      default: '',
      enum: [
        t('pages.socialAds.solutionStrategy.websites.yesOption'),
        t('pages.socialAds.solutionStrategy.websites.noOption'),
      ]
    },
  },
  dependencies: {
    localiqWebsite:{
      oneOf:[
        {
          properties: {
            localiqWebsite: {
              enum: ['Yes'],
            },
            websiteLive: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.websites.websiteLiveTitle'),
              default: '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.websites.websiteLiveYesOptions'),
                t('pages.socialAds.solutionStrategy.websites.websiteLiveNoOptions'),
              ],
            }
          }
        },
        {
          properties: {
            localiqWebsite: {
              enum: ['No'],
            },
          },
        },
      ]
    },
    websiteLive:{
      oneOf:[
        {
          properties: {
            websiteLive:{
              enum: ['No, the ReachSite is Not live.'],
            },
            temporaryLink:{
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.websites.temporaryLinkTitle'),
              default: '',
            }
          }
        },
        {
          properties: {
            websiteLive: {
              enum: ['Yes, the ReachSite is live.'],
            },
          },
        }
      ]
    }
  }
});

const dropDownKeys = [
  'localiqWebsite',
  'websiteLive',
];

const dropDownFields = dropDownKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

export const WebsitesUISchema = {
  'ui:order': ['localiqWebsite','websiteLive','temporaryLink'],

  temporaryLink: {
    'ui:widget': 'UrlTextWidget',
    'ui:placeholder': t('pages.socialAds.solutionStrategy.websites.temporaryLinkHelper'),
  },
  ...dropDownFields,
};

export const WebsitesCustomValidate = (formData, errors, uiSchema) => {
  if (formData?.localiqWebsite === '') {
    errors.localiqWebsite.addError(t('pages.socialAds.solutionStrategy.websites.localiqWebsiteError'));
    errors.addError(true);
  }
  if (formData?.websiteLive === 'No, the ReachSite is Not live.') {
    if (formData?.temporaryLink !== '' && !validateUrl(formData?.temporaryLink)) {
      errors.temporaryLink.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  return errors;
};