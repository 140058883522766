import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";
import AnchorTextLabel from "pages/generic-product/details/custom-fields/AnchorTextLabel";
import HeadingTextLabel from "pages/generic-product/details/custom-fields/HeadingTextLabel";
import {validateEmail} from "pages/generic-product/details/custom-fields/EmailTextField";
import {validateUrl} from "pages/generic-product/details/custom-fields/UrlTextField";

const {t} = i18n;

export const FacebookUserDetailsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.socialAds.solutionStrategy.facebookUserDetails.title'),
  type: 'object',
  required: [
    'advertiserEmailAddress',
    'isThisAHipaaCampaign',
    'whatContactInformationDoesTheClientWantToCaptureFromTheirFormSubmissions',
    'privacyPolicyUrl'
  ],
  properties: {
    advertiserEmailAddress: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookUserDetails.advertiserEmailAddressLabel'),
      description: t('pages.socialAds.solutionStrategy.facebookUserDetails.advertiserEmailAddressHelperText'),
      default: data?.advertiserEmailAddress || '',
    },
    isThisAHipaaCampaign: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookUserDetails.isThisAHipaaCampaignLabel'),
      default: data?.isThisAHipaaCampaign || '',
      enum: [
        'Yes - HIPAA',
        'No'
      ],
    },
    whatContactInformationDoesTheClientWantToCaptureFromTheirFormSubmissions: {
      type: 'array',
      title: t('pages.socialAds.solutionStrategy.facebookUserDetails.whatContactInformationDoesTheClientWantToCaptureFromTheirFormSubmissionsLabel'),
      description: t('pages.socialAds.solutionStrategy.facebookUserDetails.whatContactInformationDoesTheClientWantToCaptureFromTheirFormSubmissionsHelperText'),
      default: data?.whatContactInformationDoesTheClientWantToCaptureFromTheirFormSubmissions || '',
      items: {
        type: 'string',
        enum: [
          'Phone Number',
          'Email Address',
          'Zip Code',
        ],
      },
      uniqueItems: true,
    },
    note: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookUserDetails.noteLabel'),
    },
    privacyPolicyUrl: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookUserDetails.privacyPolicyUrlLabel'),
      default: data?.privacyPolicyUrl || '',
      description: t('pages.socialAds.solutionStrategy.facebookUserDetails.privacyPolicyUrlHelperText'),
      urlValidationErrorText: t('pages.socialAds.solutionStrategy.facebookUserDetails.privacyPolicyUrlErrorMessage'),
    },
    clientsReferenceAnchorText: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookUserDetails.clientsReferenceAnchorTextLabel'),
      enum: ['https://example.com/', true],
      textAfterAnchor: t('pages.socialAds.solutionStrategy.facebookUserDetails.clientsReferenceAfterAnchorTextLabel'),
    },
    advertiserDisclaimerUrl: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookUserDetails.advertiserDisclaimerUrlLabel'),
      default: data?.advertiserDisclaimerUrl || '',
      description: t('pages.socialAds.solutionStrategy.facebookUserDetails.advertiserDisclaimerUrlHelperText'),
      urlValidationErrorText: t('pages.socialAds.solutionStrategy.facebookUserDetails.advertiserDisclaimerErrorMessage'),
    },
  },
});

const checkboxesFieldKeys = [
  'whatContactInformationDoesTheClientWantToCaptureFromTheirFormSubmissions',
];

const emailTextFieldKeys = [
  'advertiserEmailAddress',
];

const headingTextLabelKeys = [
  'note',
];

const urlTextFieldKeys = [
  'privacyPolicyUrl',
  'advertiserDisclaimerUrl',
];

const anchorFieldKeys = [
  'clientsReferenceAnchorText',
];

const anchorFields = anchorFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => <AnchorTextLabel {...props}
                                             textAfterAnchor={t('pages.socialAds.solutionStrategy.facebookUserDetails.clientsReferenceAfterAnchorTextLabel')}/>
  },
}), {});

const emailFields = emailTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'EmailTextWidget'},
}), {});

const checkboxesFields = checkboxesFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'checkBoxField'}
}), {});

const headingTextLabelsFields = headingTextLabelKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => <HeadingTextLabel {...props} fontSize='17px'/>
  },
}), {});

const urlTextFields = urlTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'UrlTextWidget'}
}), {});

export const FacebookUserDetailsUISchema = {
  'ui:order': [
    'advertiserEmailAddress',
    'isThisAHipaaCampaign',
    'whatContactInformationDoesTheClientWantToCaptureFromTheirFormSubmissions',
    'note',
    'privacyPolicyUrl',
    'clientsReferenceAnchorText',
    'advertiserDisclaimerUrl',
  ],
  ...checkboxesFields,
  ...emailFields,
  ...urlTextFields,
  ...anchorFields,
  ...headingTextLabelsFields,
  isThisAHipaaCampaign: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.socialAds.solutionStrategy.facebookUserDetails.isThisAHipaaCampaignErrorMessage')}/>
  }
}

export const FacebookUserDetailsCustomValidate = (formData: any, errors, uiSchema) => {
  if (!formData?.advertiserEmailAddress || formData?.advertiserEmailAddress.trim() === '') {
    errors.advertiserEmailAddress.addError(t('pages.socialAds.solutionStrategy.facebookUserDetails.advertiserEmailAddressErrorMessage'));
    errors.addError(true);
  }
  if (formData?.whatContactInformationDoesTheClientWantToCaptureFromTheirFormSubmissions.length === 0) {
    errors.whatContactInformationDoesTheClientWantToCaptureFromTheirFormSubmissions.addError(t('pages.socialAds.solutionStrategy.facebookUserDetails.whatContactInformationDoesTheClientWantToCaptureFromTheirFormSubmissionsErrorMessage'));
    errors.addError(true);
  }
  if (formData?.privacyPolicyUrl === '' || !validateUrl(formData?.privacyPolicyUrl)) {
    errors.privacyPolicyUrl.addError(t('pages.socialAds.solutionStrategy.facebookUserDetails.privacyPolicyUrlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.advertiserDisclaimerUrl !== "" && !validateUrl(formData?.advertiserDisclaimerUrl)) {
    errors.advertiserDisclaimerUrl.addError(t('pages.socialAds.solutionStrategy.facebookUserDetails.advertiserDisclaimerErrorMessage'));
    errors.addError(true);
  }
  if (formData?.advertiserEmailAddress !== '' && !validateEmail(formData?.advertiserEmailAddress)) {
    errors.advertiserEmailAddress.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  return errors;
};
