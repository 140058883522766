/* eslint-disable import/no-extraneous-dependencies */

import {t} from "i18next";
import {
  TikTokCustomValidate,
  TikTokSchema,
  TikTokUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TikTokSchema";
import {
  TrackingTikTokCustomValidate,
  TrackingTikTokSchema,
  TrackingTikTokUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TrackingTikTokSchema";
import {
  DemographicTargetingCustomValidate,
  DemographicTargetingSchema,
  DemographicTargetingUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/DemographicTargetingSchema";

export const AusTikTokSchema = (data) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.ausProduct.ausTiktok.title'),
      schema: TikTokSchema(data),
      uiSchema: TikTokUISchema,
      customValidate: TikTokCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.ausTiktok.tracking'),
      schema: TrackingTikTokSchema(data),
      uiSchema: TrackingTikTokUISchema,
      customValidate: TrackingTikTokCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.ausTiktok.demographicTargetingLabel'),
      schema: DemographicTargetingSchema(data),
      uiSchema: DemographicTargetingUISchema,
      customValidate: DemographicTargetingCustomValidate,
    },
  ];

  return schema;
};

export const AusTikTokUISchema= (data) => ({
  'ui:order': [
   ...TikTokUISchema['ui:order'],
   ...TrackingTikTokUISchema['ui:order'],
   ...DemographicTargetingUISchema['ui:order'],
  ],
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AusTikTokCustomValidate = (formData: any, errors, uiSchema) => errors;
