import { IQFormTextArea } from '@gannettdigital/shared-react-components';
import { Box, Theme, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  theme: Theme
  name: string
  id: string
  limit: number
  labelText: string
  tooltipText: string
}

export default function CountdownTextArea({
  id, name, labelText, tooltipText, theme, limit,
}: Props) {
  const [currentValue, setCurrentValue] = useState('');
  const { watch, getValues, formState: { errors } } = useFormContext();

  useEffect(() => {
    setCurrentValue(getValues(id));
  }, [watch(id)]);

  const hasError = useMemo(() => !!errors[id], [watch(id)]);

  return (
    <>
      <IQFormTextArea
        name={name}
        id={id}
        fullWidth
        labelText={labelText}
        tooltipText={tooltipText}
        tooltipPlacement="top"
        tooltipPaddingBottom={18}
        showError
      />
      <Box sx={{ position: 'relative' }}>
        <Typography
          variant="caption"
          component="span"
          sx={{
            color: theme.palette.text.secondary,
            position: 'absolute',
            right: '10px',
            top: hasError ? '-20px' : '5px',
          }}
        >
          { `${currentValue.length}/${limit}`}
        </Typography>
      </Box>
    </>
  );
}
