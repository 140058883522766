import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Grid, useTheme } from '@mui/material';
import {
  IQFileUploader,
  IQFormTextArea,
  IQInputNumber,
  IQYesNoFormRadioButtons,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';

import { useState } from 'react';
import { selectActiveOfferingType } from 'services/navigationSlice';
import { deleteFile, selectFilesUploaded, uploadFiles } from 'services/fileUploaderSlice';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import CoFileList from 'components/fileList/CoFileList';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { H5 } from 'components/labels/H5';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import WebsitesType from 'models/WebsitesType';
import schema from './ExistingContent.schema';

interface Props {
  website: WebsitesType;
  defaultFileList: any[];
  onSubmit: (data) => any;
  onBack: () => any;
}

export type ExistingContentFormProps = {
  portContentExistingYesNo: 'yes' | 'no' | '';
  assets: any[];
  urlsToMigrate: string;
  urlsToRemove: string;
  rewriteExistingContent: string;
  numberOfPages: string;
};

export default function ExistingContentForm(props: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<null | File | number>(null);

  const uploaderType = 'existing-content';

  const offeringType = useSelector(selectActiveOfferingType);

  const { website, defaultFileList } = props;

  const evaluateYesNo = (value) => {
    if (value !== null) {
      if (value) return 'yes';
      return 'no';
    }
    return '';
  };

  const { t } = useTranslation();
  const theme = useTheme();
  const methods = useForm<ExistingContentFormProps>({
    mode: 'all',
    defaultValues: {
      portContentExistingYesNo: evaluateYesNo(website.portContentExistingWebsite),
      assets: defaultFileList || [],
      urlsToMigrate: website.urlsToMigrate,
      numberOfPages: website.numberOfPages,
      urlsToRemove: website.urlsToRemove,
      rewriteExistingContent: website.rewriteExistingContent,
    },
  });
  const {
    handleSubmit,
    watch,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful,
    },
  } = methods;
  const dispatch = useDispatch();

  const filesUploaded = useSelector(selectFilesUploaded);

  const formValues = {
    portContentExistingYesNo: watch('portContentExistingYesNo') || '',
  };

  const onUpdate = (newFiles) => {
    dispatch(
      uploadFiles({
        websiteId: website.id,
        files: newFiles,
        uploaderType,
        offeringType,
      }),
    );
  };

  const onDelete = (file :File | number) => {
    setModalOpen(true);
    setFileToDelete(file);
  };

  const handleModalDelete = (file) => {
    setModalOpen(false);

    if (!file) return;

    dispatch(deleteFile({ websiteId: website.id, file }));
  };

  const onSubmit = (data: ExistingContentFormProps) => props.onSubmit(data);

  const handleRadioChange = () => {};

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <Box
          id="mainForm"
          component="form"
          sx={{ display: 'flex' }}
          onSubmit={handleSubmit(onSubmit)}
          ml={formValues.portContentExistingYesNo === 'yes' ? '8.5px' : ''}
        >
          <DefaultPageLayout
            disableContinue={!isValid || !formValues.portContentExistingYesNo}
            onBack={props.onBack}
            header="Existing Content"
            onContinue={handleSubmit(onSubmit)}
          >
            <Grid
              container
              xs={6}
              spacing={2}
              direction="column"
              justifyContent="flex-start"
            >
              <Grid item
                sx={{
                  '.MuiFormGroup-root': { pl: '5px' },
                  '.MuiRadio-root.Mui-checked': { color: theme.palette.primary.main },
                  '.MuiRadio-root': { mr: 0 },
                }}
              >
                <IQYesNoFormRadioButtons
                  title="Port content and assets from existing website? *"
                  fontTitleWeight="600"
                  field="portContentExistingYesNo"
                />
              </Grid>
              {formValues.portContentExistingYesNo === 'yes' && (
                <>
                  <Grid
                    item
                    sx={{
                      marginTop: '25px',
                      '.MuiTypography-root:first-of-type': { mt: '8px' },
                      'label > span': { display: 'none' },
                    }}
                  >
                    <IQFileUploader
                      name="assets"
                      label="Assets"
                      supportingText="Upload content assets from existing website to include in the new build."
                      imageTypes={['.png', '.jpeg', '.jpg']}
                      videoTypes={[]}
                      documentTypes={['.pdf', '.doc', '.docx']}
                      showRecommendedImageText={false}
                      onSuccess={onUpdate}
                      currentUploadedFiles={filesUploaded.content.length}
                    />
                  </Grid>
                  <ContentLoader status={filesUploaded.status} message={filesUploaded.message}>
                    {filesUploaded.content.filter(file => file.uploaderType === 'existing-content').length > 0
                    && (
                    <Grid item>
                      <CoFileList
                        files={filesUploaded.content.filter(file => file.uploaderType === 'existing-content')}
                        onDelete={onDelete}
                      />
                    </Grid>
                    )}
                  </ContentLoader>
                  <Grid item>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        marginTop: '24px',
                        marginBottom: '24px',
                      }}
                    >
                      <H5>{t('Content Details')}</H5>
                    </Grid>
                    <IQFormTextArea
                      id="urlsToMigrate"
                      labelText="URLs to Migrate"
                      name="urlsToMigrate"
                      rowCount={3}
                      fullWidth
                      fontLabelWeight="600"
                      showError
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      marginTop: '22px',
                      marginBottom: '24px',
                    }}
                  >
                    <IQInputNumber
                      name="numberOfPages"
                      fullWidth
                      label="Number of Pages"
                      fontLabelWeight="600"
                      required={false}
                    />
                  </Grid>
                  <Grid item>
                    <IQFormTextArea
                      id="urlsToRemove"
                      labelText="URLs to Remove"
                      name="urlsToRemove"
                      rowCount={3}
                      fullWidth
                      fontLabelWeight="600"
                      showError
                    />
                  </Grid>
                  <Grid item sx={{
                    mt: '32px',
                    mb: '-8px',
                    '.MuiFormGroup-root': { pl: '5px' },
                    '.MuiRadio-root.Mui-checked': { color: theme.palette.primary.main },
                    '.MuiRadio-root': { mr: 0 },
                  }}
                  >
                    <IQYesNoFormRadioButtons
                      title="Rewrite client's existing website content?"
                      fontTitleWeight="600"
                      field="rewriteExistingContent"
                      onValueChange={handleRadioChange}
                      theme={theme}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </DefaultPageLayout>
          {/* show the dialog if we changed the field and didn't submit the changes */}
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
          {/* Show confirmation modal on asset delete */}
          <CoModalConfirm
            title="Delete Asset"
            description="Are you sure you want to delete this asset?"
            open={modalOpen}
            handleClose={() => setModalOpen(false)}
            handleConfirm={() => handleModalDelete(fileToDelete)}
            confirmText="Delete"
          />
        </Box>
      </FormProvider>
    </ValidationProvider>
  );
}
