import i18n from 'i18n/i18n';
import { RJSFSchema } from '@rjsf/utils';

const { t } = i18n;
const numberOfAdSetsOpt = Array.from({ length: 5 }, (_, i) => (i + 1).toString());

export const AdSetsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.adSet.adSetTitle'),
  type: 'object',
  required: ['numberOfAdSets'],
  properties: {
    campaignFocusListDescription: {
      type: 'string',
      title: t('pages.ausProduct.adSet.campaignFocusListDes'),
    },
    numberOfAdSets: {
      type: 'string',
      title: t('pages.ausProduct.adSet.numberOfAdSetsLabel'),
      description: t('pages.ausProduct.adSet.numberOfAdSetsDescription'),
      default: data?.numberOfAdSets || '',
      enum: ['', ...numberOfAdSetsOpt],
    },
  },
});

export const AdSetsUISchema = {
  'ui:order': [
    'campaignFocusListDescription',
    'numberOfAdSets',
  ],
  campaignFocusListDescription: {
    'ui:widget': 'textLabel',
  },
  numberOfAdSets: {
    'ui:widget': 'dropdown',
  },
};

export const AdSetsCustomValidate = (formData, errors, uiSchema) => {
  if (formData?.numberOfAdSets === '') {
    errors.numberOfAdSets.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return { errors, formData };
};
