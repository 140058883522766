import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  FormControl,
  ListSubheader,
  SelectChangeEvent,
  Box,
  Grid,
  Typography,
  IconButton,
  Divider,
  Chip,
  TextField,
  InputBase,
  InputAdornment,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectAllProductTypes } from 'services/offerMappingSlice';
import { Controller } from 'react-hook-form';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import {
  blackBorderStyles,
  dividerStyles,
  filterCategory,
  statuses
} from "pages/orders/MyOrderPageConstants";


const LeftNavFilter = ({control, setValue, watch, setError, clearErrors, errors,}) => {
  const allProductTypes = useSelector(selectAllProductTypes);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [sortedProductTypes, setSortedProductTypes] = useState([]);
  const [options, setOptions] = useState<{ [key: string]: { name: string; id: number }[] }>({});
  const [selectedStatus, setSelectedStatus] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const minPastDate = new Date('1/1/2017');

  const handleChipClick = (value) => {
    setSelectedStatus(value);
  };

  const productList = watch('productList') || [];
  const status = watch('status');
  const startDate = watch('startDate');
  const endDate = watch('endDate');

  useEffect(() => {
    setSelectedStatus(status);
  }, [status]);

  useEffect(() => {
    const sorted = allProductTypes.slice().sort((a, b) => a.name.localeCompare(b.name));
    setSortedProductTypes(sorted);
  }, [allProductTypes]);

  useEffect(() => {
    const groupProductTypes = () => {
      const groupedOptions = filterCategory.reduce((acc, category) => {
        acc[category] = sortedProductTypes
        .filter(item => item.name.includes(category))
        .map(item => ({id: item.id, name: item.name}));
        return acc;
      }, {});
      // Add 'Other' category for items that do not match any filter categories
      groupedOptions['Other'] = sortedProductTypes
      .filter(item => !filterCategory.some(category => item.name.includes(category)))
      .map(item => ({id: item.id, name: item.name}));

      return groupedOptions;
    };

    setOptions(groupProductTypes());
  }, [sortedProductTypes]);

  useEffect(() => {
    setSelectedOptions(productList || []);
  }, [productList]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: {value},
    } = event;
    setSelectedOptions(value as string[]);
    setValue('productList', value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleParentSelection = (parent) => {
    const allChildrenSelected = options[parent].every(child => selectedOptions.includes(child.id.toString()));
    let newSelected = [];
    if (allChildrenSelected) {
      newSelected = selectedOptions.filter(
          option => !options[parent].some(child => child.id.toString() === option)
      );
    } else {
      newSelected = [
        ...selectedOptions,
        ...options[parent]
        .filter(child => !selectedOptions.includes(child.id.toString()))
        .map(child => child.id.toString())
      ];
    }
    setSelectedOptions(newSelected);
    setValue('productList', newSelected);
  };

  const filteredOptions = Object.keys(options).reduce((acc, parent) => {
    const filteredChildren = options[parent].filter(child =>
        child.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    if (filteredChildren.length > 0) {
      acc[parent] = filteredChildren;
    }
    return acc;
  }, {});

  const handleStartDateChange = (newValue: any) => {
    const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : null;
    setValue('startDate', formattedDate);
    const isEndDateGreater = dayjs(endDate).isAfter(dayjs(formattedDate)) || dayjs(endDate).isSame(dayjs(formattedDate));
    if (newValue) {
      if (!dayjs(newValue).isValid()) {
        setError('startDate', {
          type: 'manual',
          message: 'Please select or enter a valid date format MM/DD/YYYY.',
        });
      } else {
        if (!isEndDateGreater) {
          setError('startDate', {
            type: 'manual',
            message: 'Please select or enter a date before the second date',
          });
        } else {
          clearErrors('startDate')
          clearErrors('endDate')
        }
      }
    } else {
      clearErrors('startDate')
    }
  }

  const handleEndDateChange = (newValue: any) => {
    const formattedDate = newValue ? newValue.format('YYYY-MM-DD') : null;
    setValue('endDate', formattedDate);
    const isEndDateGreater = dayjs(formattedDate).isAfter(dayjs(startDate)) || dayjs(formattedDate).isSame(dayjs(startDate));
    if (newValue) {
      if (!dayjs(newValue).isValid()) {
        setError('endDate', {
          type: 'manual',
          message: 'Please select or enter a valid date format MM/DD/YYYY.',
        });
      } else {
        if (!isEndDateGreater) {
          setError('endDate', {
            type: 'manual',
            message: 'Please select or enter a date after the first date',
          });
        } else {
          clearErrors('startDate')
          clearErrors('endDate')
        }
      }
    } else {
      clearErrors('endDate')
    }
  }

  const handleDelete = (productId: any) => {
    const updatedProductList = productList.filter((item: any) => item !== productId);
    setSelectedOptions(updatedProductList);
    setValue('productList', updatedProductList);
  };

  return (
      <FormControl fullWidth>
        {/* Product List Section*/}
        <Typography variant="h6">Product</Typography>
        <Divider sx={dividerStyles}/>
        <Select
            labelId="dropdown-checkbox-label"
            multiple
            value={selectedOptions}
            onChange={handleChange}
            displayEmpty
            renderValue={() => "Select Products"}
            sx={blackBorderStyles}
            MenuProps={{
              PaperProps: {
                style: {minHeight: 600, maxHeight: 700, width: 250},
              },
            }}
        >
          <Box px={2} py={1}>
            <InputBase
                placeholder="Search Products"
                value={searchQuery}
                onChange={handleSearchChange}
                fullWidth
                sx={{
                  padding: '10px',
                  border: '1px solid #716F6F',
                  borderRadius: '5px',
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon/>
                  </InputAdornment>
                }
            />
          </Box>
          <Box sx={{maxHeight: 500, overflowY: 'auto'}}>
            {Object.keys(filteredOptions).map(parent => (
                <Box key={parent}>
                  <ListSubheader>
                    <Checkbox
                        checked={filteredOptions[parent].every(child => selectedOptions.includes(child.id.toString()))}
                        indeterminate={
                            filteredOptions[parent].some(child => selectedOptions.includes(child.id.toString())) &&
                            !filteredOptions[parent].every(child => selectedOptions.includes(child.id.toString()))
                        }
                        onChange={() => handleParentSelection(parent)}
                    />
                    <span style={{fontWeight: '700', color: '#000'}}>{parent.toUpperCase()}</span>
                  </ListSubheader>
                  <Divider
                      sx={{margin: '10px 30px', borderBottomWidth: '2px', borderColor: '#716F6F'}}
                  />
                  {filteredOptions[parent].map(child => (
                      <MenuItem key={child.id} value={child.id.toString()}>
                        <Checkbox
                            checked={selectedOptions.includes(child.id.toString())}
                            onChange={() => {
                              const newSelected = selectedOptions.includes(child.id.toString())
                                  ? selectedOptions.filter(option => option !== child.id.toString())
                                  : [...selectedOptions, child.id.toString()];

                              setSelectedOptions(newSelected);
                              setValue('productList', newSelected);
                            }}
                            sx={{justifyContent: 'center'}}
                        />
                        <ListItemText
                            primary={child.name}
                            sx={{
                              whiteSpace: 'normal',
                              wordBreak: 'break-word',
                            }}
                        />
                      </MenuItem>
                  ))}
                </Box>
            ))}
          </Box>
        </Select>
        <br/>
        {/* List of Selected Products */}
        {Array.isArray(productList) && productList.length > 0 && productList.map((product: any, index: number) => {
          const selectedProduct = allProductTypes.length > 0 &&
              allProductTypes.find((list: any) => list?.id.toString() === product.toString());

          return (
              <Box key={index}
                   display="flex"
                   alignItems="center"
                   justifyContent="space-between"
                   mb={1}
              >
                <Typography variant="subtitle1">
                  {selectedProduct?.name}
                </Typography>
                <IconButton onClick={() => handleDelete(product)} color="primary">
                  <DeleteIcon/>
                </IconButton>
              </Box>
          );
        })}
        {/* Status Selection */}
        <Typography variant="h6" mt={3}>Status</Typography>
        <Divider sx={dividerStyles}/>
        <Grid item xs={12}>
          <Controller
              name="status"
              control={control}
              render={({field}) => (
                  <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: 1,
                      }}
                  >
                    {statuses.map(status => (
                        <Chip
                            key={status.value}
                            label={
                              <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: '700'
                                  }}
                              >
                                <Box
                                    sx={{
                                      width: '12px',
                                      height: '12px',
                                      borderRadius: '50%',
                                      backgroundColor: status.color,
                                      marginRight: '8px',
                                    }}
                                />
                                {status.description}
                              </Box>
                            }
                            onClick={() => {
                              handleChipClick(status.value);
                              field.onChange(status.value);
                            }}
                            sx={{
                              backgroundColor: '#ffffff',
                              border: selectedStatus === status.value ? '3px solid #0046FF' : '2px solid #716F6F',
                              cursor: 'pointer',
                            }}
                        />
                    ))}
                  </Box>
              )}
          />
        </Grid>
        {/*  Date Range Picker */}
        <Typography variant="h6" mt={4}>
          Date
        </Typography>
        <Divider sx={dividerStyles}/>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <Controller
                name="startDate"
                control={control}
                defaultValue={null}
                render={({field}) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                          label=""
                          value={field.value ? dayjs(field.value) : null}
                          onChange={handleStartDateChange}
                          minDate={minPastDate}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                  margin="normal"
                                  value={field.value ? dayjs(field.value).format('YYYY-MM-DD') : ''}
                                  InputProps={{
                                    ...params.InputProps,
                                    inputProps: {
                                      ...params.inputProps,
                                      placeholder: "From",
                                    },
                                  }}
                                  sx={blackBorderStyles}
                                  error={!!errors.startDate}
                                  helperText={
                                    <Box display="flex" alignItems="center">
                                      {errors.startDate && (
                                          <InfoIcon
                                              sx={{
                                                color: (theme) => theme.palette.error.main,
                                                fontSize: '18px',
                                                marginRight: '4px',
                                              }}
                                          />
                                      )}
                                      {errors.startDate ? errors.startDate.message : ''}
                                    </Box>
                                  }
                              />
                          )}
                      />
                    </LocalizationProvider>
                )}
            />
          </Grid>
          <Grid item xs={12} mt={-2}>
            <Controller
                name="endDate"
                control={control}
                defaultValue={null}
                render={({field}) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                          label=""
                          value={field.value ? dayjs(field.value) : null}
                          onChange={handleEndDateChange}
                          minDate={startDate ? dayjs(startDate) : minPastDate}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                  margin="normal"
                                  value={field.value ? dayjs(field.value).format('YYYY-MM-DD') : ''}
                                  InputProps={{
                                    ...params.InputProps,
                                    inputProps: {
                                      ...params.inputProps,
                                      placeholder: "To",
                                    },
                                  }}
                                  sx={blackBorderStyles}
                                  error={!!errors.endDate}
                                  helperText={
                                    <Box display="flex" alignItems="center">
                                      {errors.endDate &&
                                          <InfoIcon
                                              sx={{
                                                color: (theme) => theme.palette.error.main,
                                                fontSize: '18px',
                                                marginRight: '4px'
                                              }}
                                          />
                                      }
                                      {errors.endDate ? errors.endDate.message : ''}
                                    </Box>
                                  }
                              />
                          )}
                      />
                    </LocalizationProvider>
                )}
            />
          </Grid>
        </Grid>
      </FormControl>
  );
};

export default LeftNavFilter;
