import i18n from 'i18n/i18n';
import * as yup from 'yup';

const { t } = i18n;

const schema = {
  phoneNumbers: yup.array(yup.object({
    phoneType: yup.string().notRequired().nullable(),
    phoneNumber: yup.string().required(),
    ext: yup.string().notRequired().nullable(),
  }))
    .min(1),
  locationWebsite: yup.string().url(t('pages.premiumListings.location.healthcare.contact.details.invalidWebsiteUrl'))
    .notRequired().nullable(),
  locationEmail: yup.string().email(
    t('pages.premiumListings.location.healthcare.contact.details.invalidEmail'),
  ).notRequired().nullable(),
  accounts: yup.array(yup.object({
    name: yup.string(),
    enabled: yup.boolean(),
    url: yup.string().url().optional(),
  })),
  facebookYesNo: yup.string().required(),
  googleYesNo: yup.string().required(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
