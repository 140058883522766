import { t } from "i18next";


export const doesClientHaveAnotherAccountDropdownItems = [
  {value: 'Yes', description: t('pages.targetedDisplayLegacy.proxyProvisioning.doesClientHaveAnotherAccountOptions.yes')},
  {value: 'No', description: t('pages.targetedDisplayLegacy.proxyProvisioning.doesClientHaveAnotherAccountOptions.no')},
];
export const shouldWeImplementUtmStringItems = [
  {value: 'Yes - Use my UTM strings only', description: t('pages.targetedDisplayLegacy.proxyProvisioning.shouldWeImplementUtmStringOptions.yesUseMyUtmStringsOnly')},
  {value: 'Yes - Use UTM strings Best Practices', description: t('pages.targetedDisplayLegacy.proxyProvisioning.shouldWeImplementUtmStringOptions.yesUseUtmStringsBestPractices')},
  {value: 'No - Do Not use UTM strings', description: t('pages.targetedDisplayLegacy.proxyProvisioning.shouldWeImplementUtmStringOptions.noDoNotUseUtmStrings')},
];
export const provisioningProceedWithBreakingTrackingForCaptchaItems = [
  {value: 'Yes', description: t('pages.targetedDisplayLegacy.proxyProvisioning.provisioningProceedWithBreakingTrackingForCaptchaOptions.yes')},
  {value: 'No, please hold campaign until client is able to remove CAPTCHA', description: t('pages.targetedDisplayLegacy.proxyProvisioning.provisioningProceedWithBreakingTrackingForCaptchaOptions.noHoldCampaign')},
];
export const proxyDetailsItems = [
  {value: 'Proxy ALL Pages on the website', description: t('pages.targetedDisplayLegacy.proxyProvisioning.proxyDetailsOptions.proxyAllPages')},
  {value: 'Do not proxy certain pages on the website', description: t('pages.targetedDisplayLegacy.proxyProvisioning.proxyDetailsOptions.doNotProxyCertainPages')},
];
export const webEventsDropdownItems = [
  {
    value: 'Please adjust web events per best practices',
    description: t('pages.targetedDisplayLegacy.proxyProvisioning.webEventsOptions.adjustWebEventsPerBestPractices')
  },
  {value: 'Use only my web events', description: t('pages.targetedDisplayLegacy.proxyProvisioning.webEventsOptions.useOnlyMyWebEvents')},
];