import { t } from 'i18next';
import * as yup from 'yup';

const yupSchema = {
  copyingServicesNeeded: yup.string().required(),
  specialFunctionalityNeeded: yup.string().required(),
  specialFunctionality: yup.string().required(t('pages.landing.custom.development.specialFunctionalityNeededRequired')),
};
export default {
  yupValidations: yupSchema,
  customValidations: {},
};
