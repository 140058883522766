import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";

const {t} = i18n;

export const TargetingIndentedSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.targetingIndented.title'),
  type: 'object',
  required: [
    'campaignTargeting'
  ],
  properties: {
    helpText1: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.targetingIndented.helpText1'),
      margin: 0
    },
    helpText2: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.targetingIndented.helpText2'),
      margin: 0
    },
    campaignTargeting: {
      type: 'string',
      title: t('pages.ausProduct.targetingIndented.campaignTargeting'),
      default: '',
      enum: [
        t('pages.ausProduct.targetingIndented.national'),
        t('pages.ausProduct.targetingIndented.stateOrRegional'),
        t('pages.ausProduct.targetingIndented.radius'),
        t('pages.ausProduct.targetingIndented.postcode'),
        t('pages.ausProduct.targetingIndented.city'),
      ],
    },
    helpText3: {
      type: 'string',
      title: '',
      description: t('pages.ausProduct.targetingIndented.helpText3'),
    },
    helpText4: {
      type: 'string',
      title: '',
      description: t('pages.ausProduct.targetingIndented.helpText4'),
      margin: 0
    },
    optionForNationalPublisherTargeting: {
      type: 'string',
      title: t('pages.ausProduct.targetingIndented.optionForNationalPublisherTargeting'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.targetingIndented.nationalPublisherTargetingOpt1'),
        t('pages.ausProduct.targetingIndented.nationalPublisherTargetingOpt2'),
      ],
    },
  },
  dependencies: {
    campaignTargeting: {
      oneOf: [
        {
          properties: {
            campaignTargeting: {
              enum: [t('pages.ausProduct.targetingIndented.national')],
            },
            selectCountry: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.selectCountry'),
              default: '',
              enum: [
                '',
                t('pages.ausProduct.targetingIndented.australia'),
                t('pages.ausProduct.targetingIndented.newZealand'),
                t('pages.ausProduct.targetingIndented.other')
              ],
            },
          },
        },
        {
          properties: {
            campaignTargeting: {
              enum: [t('pages.ausProduct.targetingIndented.stateOrRegional')],
            },
            statesToTargetLabel: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.statesToTarget')
            },
            statesToTarget: {
              type: 'array',
              title: "",
              items: {
                type: 'string',
                enum: [
                  t('pages.ausProduct.targetingIndented.australianCapitalTerritory'),
                  t('pages.ausProduct.targetingIndented.newSouthWales'),
                  t('pages.ausProduct.targetingIndented.northernTerritory'),
                  t('pages.ausProduct.targetingIndented.queensland'),
                  t('pages.ausProduct.targetingIndented.southAustralia'),
                  t('pages.ausProduct.targetingIndented.tasmania'),
                  t('pages.ausProduct.targetingIndented.victoria'),
                  t('pages.ausProduct.targetingIndented.westernAustralia'),
                  t('pages.ausProduct.targetingIndented.northland'),
                  t('pages.ausProduct.targetingIndented.auckland'),
                  t('pages.ausProduct.targetingIndented.bayOfPlenty'),
                  t('pages.ausProduct.targetingIndented.canterbury'),
                  t('pages.ausProduct.targetingIndented.gisborne'),
                  t('pages.ausProduct.targetingIndented.hawkesBay'),
                  t('pages.ausProduct.targetingIndented.manawatuWanganui'),
                  t('pages.ausProduct.targetingIndented.marlborough'),
                  t('pages.ausProduct.targetingIndented.nelson'),
                  t('pages.ausProduct.targetingIndented.otago'),
                  t('pages.ausProduct.targetingIndented.southland'),
                  t('pages.ausProduct.targetingIndented.taranaki'),
                  t('pages.ausProduct.targetingIndented.tasman'),
                  t('pages.ausProduct.targetingIndented.waikato'),
                  t('pages.ausProduct.targetingIndented.wellington'),
                  t('pages.ausProduct.targetingIndented.westCoast')
                ],
              },
              description: t('pages.ausProduct.targetingIndented.statesToTargetDescription'),
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            campaignTargeting: {
              enum: [t('pages.ausProduct.targetingIndented.radius')],
            },
            provideRadiusInKm: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.provideRadiusInKm'),
              default: ''
            },
            streetNumber: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.streetNumber'),
              default: ''
            },
            streetName: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.streetName'),
              default: ''
            },
            cityOrSuburb: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.cityOrSuburb'),
              default: ''
            },
            stateOrProvince: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.stateOrProvince'),
              default: ''
            },
            postalCode: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.postalCode'),
              default: ''
            },
            country: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.country'),
              default: ''
            },
          },
          required: [
            'provideRadiusInKm',
            'streetNumber',
            'streetName',
            'cityOrSuburb',
            'stateOrProvince',
            'postalCode',
            'country',
          ]
        },
        {
          properties: {
            campaignTargeting: {
              enum: [t('pages.ausProduct.targetingIndented.postcode')],
            },
            postcode: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.postcodeLabel'),
              description: t('pages.ausProduct.targetingIndented.postcodeDescription'),
              default: '',
            },
          },
          required: ['postcode']
        },
        {
          properties: {
            campaignTargeting: {
              enum: [t('pages.ausProduct.targetingIndented.city')],
            },
            cityTargetingRequirements: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.cityTargetingRequirements'),
              description: t('pages.ausProduct.targetingIndented.cityTargetingRequirementsDescription'),
              default: '',
            },
          },
          required: ['cityTargetingRequirements']
        },
      ],
    },
  }
});

const textLabelKeys = [
  'helpText1',
  'helpText2',
  'helpText3',
  'helpText4'
];

const textFieldKeys = [
  'streetNumber',
  'streetName',
  'cityOrSuburb',
  'stateOrProvince',
  'postalCode',
  'country',
  'postcode',
  'cityTargetingRequirements'
];

const dropdownFieldKeys = [
  'campaignTargeting',
  'selectCountry',
  'optionForNationalPublisherTargeting'
];

const checkBoxesFieldKeys = [
  'statesToTarget'
];

const numericFieldKeys = [
  'provideRadiusInKm',
]

const textLabelFields = textLabelKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textLabel'},
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textInputField'},
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.tracking.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

const checkBoxesFields = checkBoxesFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'checkboxes'},
}), {});

const numericFields = numericFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'integerInputField'},
}), {});

export const TargetingIndentedUISchema = {
  'ui:order': [
    'helpText1',
    'helpText2',
    'campaignTargeting',
    'helpText3',
    'helpText4',
    'selectCountry',
    'statesToTargetLabel',
    'statesToTarget',
    'provideRadiusInKm',
    'streetNumber',
    'streetName',
    'cityOrSuburb',
    'stateOrProvince',
    'postalCode',
    'country',
    'postcode',
    'cityTargetingRequirements',
    'optionForNationalPublisherTargeting'
  ],
  statesToTargetLabel: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginTop: '10px',
      fontWeight: '700',
      marginBottom: '5px',
    },
  },
  ...textLabelFields,
  ...textFields,
  ...dropdownFields,
  ...checkBoxesFields,
  ...numericFields
};

export const TargetingIndentedCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.campaignTargeting === "") {
    errors.campaignTargeting.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.campaignTargeting === t('pages.ausProduct.targetingIndented.radius')) {
    if (formData?.provideRadiusInKm === '') {
      errors.provideRadiusInKm.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.streetNumber === '') {
      errors.streetNumber.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.streetName === '') {
      errors.streetName.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.cityOrSuburb === '') {
      errors.cityOrSuburb.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.stateOrProvince === '') {
      errors.stateOrProvince.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.postalCode === '') {
      errors.postalCode.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.country === '') {
      errors.country.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.campaignTargeting === t('pages.ausProduct.targetingIndented.postcode')) {
    if (formData?.postcode === '') {
      errors.postcode.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.campaignTargeting === t('pages.ausProduct.targetingIndented.city')) {
    if (formData?.cityTargetingRequirements === '') {
      errors.cityTargetingRequirements.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
  }
  return errors;
};
