import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getProduct, selectProduct } from 'services/productsSlice';
import ContentLoader from 'components/contentLoader/ContentLoader';
import {
  getBusinessLocationServiceAreas, getBusinessLocations, selectBusinessLocationsWithStatus,
} from 'services/businessLocationSlice';
import { selectOrdersContent } from 'services/ordersSlice';
import { useAppDispatch } from 'services/redux/store';
import DetailsPageForm from './DetailsPageForm';

export default function GenericProductDetailsPage() {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { orderItemId } = useParams();
  const { businessId } = useSelector(selectOrdersContent);
  const businessLocations = useSelector(selectBusinessLocationsWithStatus);
  const product = useSelector(selectProduct);
  const [location, setLocation] = useState(null);
  const [serviceAreas, setServiceAreas] = useState(null);
  const [isLoading, setIsLoading] = useState('loading');

  useEffect(() => {
    dispatch(getProduct({ orderItemId }));
  }, []);

  useEffect(() => {
    if (businessId) {
      appDispatch(getBusinessLocations(businessId)).then((res) => {
        const locations = res.payload;
        if (locations && locations.length > 0) {
          const firstLocation = locations[locations.length - 1];
          setLocation(firstLocation);
        }
      });
    }
  }, [businessId]);

  useEffect(() => {
    if (location) {
      appDispatch(getBusinessLocationServiceAreas(location.id)).then((res) => setServiceAreas(res.payload));
    }
  }, [location]);

  useEffect(() => {
    setIsLoading((product.status !== 'idle' || businessLocations.status !== 'idle')
      ? 'loading' : 'idle');
  }, [location, serviceAreas, product]);

  return (
    <ContentLoader status={isLoading} message={product.message}>
      <DetailsPageForm product={product.content.data} businessData={{ location, serviceAreas }} />
    </ContentLoader>
  );
}
