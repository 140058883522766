export enum Urls {
  Home = 'ourThemes',
  Business = 'businesses',
  BusinessSearch = 'businesses/search',
  BusinessDetail = 'businesses/details',
  BusinessClient = 'businesses/client',
  BusinessCampaign = 'businesses/campaign',
  BusinessEditClient = 'businesses/client/edit',
  BusinessLocation = 'businesses/locations',
  BusinessLocationRoot = 'location',
  BusinessLocationAddress = 'address',
  BusinessLocationContact = 'contact',
  BusinessLocationHours = 'hours',
  BusinessLocationPhotos = 'photos',
  BusinessLocationAdditional = 'additional',
  BusinessAddNew = 'businesses/detailsNew',
  PremiumListingsPhotos = 'photos',
  PremiumListingsRoot = 'premiumlistings',
  PremiumListingsAddress = 'address',
  PremiumListingsContact = 'contact',
  PremiumListingsHours = 'hours',
  PremiumListingsInfo = 'info',
  PremiumListingsAdditional = 'additional',
  PlHealthcareLocations = 'businesses/healthcareLocations',
  PlHealthcareAddress = 'address',
  PlHealthcareProvider = 'provider',
  PlHealthcareContact = 'contact',
  PlHealthcareHours = 'hours',
  PlHealthcareInfo = 'info',
  PlHealthcarePhotos = 'photos',
  PlHealthcareAdditional = 'additional',
  // General Questions
  GeneralQuestions = 'generalQuestions',
  // Domains
  Domains = 'domains',
  // Design
  Theme = 'theme',
  StockAssets = 'existingAssets',
  Examples = 'examples',
  AdditionalFeaturesDesign = 'additionalFeaturesDesign',
  // Content
  ContentWriting = 'contentWriting',
  ExistingContent = 'existingContent',
  WebsiteAgeGate = 'siteAgeGate',
  AdditionalFeaturesContent = 'additionalFeaturesContent',
  SitePages = 'sitePages',
  // Addons
  WebsiteUrl = 'addonsDetails',
  HeroVideo = 'videoBackground',
  HeroVideoGeneralQuestions = 'videoGeneralQuestions',
  EmailHosting = 'emailHosting',
  EmailHostingGeneralQuestions = 'emailGeneralQuestions',
  ExpandableMenus = 'expandableMenus',
  ExpandableMenusGeneralQuestions = 'menuGeneralQuestions',
  OnlineBooking = 'onlineBooking',
  OnlineBookingGeneralQuestions = 'bookingGeneralQuestions',
  PaymentForms = 'paymentForms',
  PaymentFormsGeneralQuestions = 'paymentGeneralQuestions',
  HipaaForms = 'hipaaForms',
  HipaaFormsGeneralQuestions = 'hipaaGeneralQuestions',
  Popups = 'popups',
  PopupsGeneralQuestions = 'popupGeneralQuestions',
  SalesRep = 'salesReps',
  Overview = 'overview',
  UnexpectedError = 'unexpectedError',
  // Campaign
  OrderType = 'orderType',
  Timeline = 'timeline',
  Audience = 'audience',
  Goals = 'goals',
  Tracking = 'tracking',
  LandingDomain = 'landingDomain',
  LandingExistingContent = 'landingExistingContent',
  LandingStockAssets = 'landingStockAssets',
  LandingCustomDetails = 'customLandingDetails',
  LandingCustomDesign = 'customLandingDesign',
  LandingCustomDevelopment = 'customLandingDevelopment',
  LandingCustomAdditionalInfo = 'customLandingAdditionalInfo',
  LandingContentWriting = 'landingContentWriting',
  Info = 'info',
  // Ecommerce urls
  Domain = 'domain',
  Assets = 'stockAssets',
  Branding = 'branding',
  ProductsDetails = 'productsDetails',
  ProductCatalog = 'productCatalog',
  Contact = 'contact',
  Returns = 'returns',
  Shipping = 'shipping',
  AgeGate = 'ageGate',
  AdditionalStoreDetails = 'additionalStoreDetails',
  ProductMoveForward = 'productMoveForward',
  ProductMoveBackward = 'productMoveBackward',
  // Chat urls
  BusinessChat = 'business',
  ScriptDetails = 'scriptDetails',
  ChatLocations = 'locations',
  ChatAdditional = 'additional',
  // Chat platinum urls
  PlatinumBusinessChat = 'platinumBusiness',
  PlatinumScriptDetails = 'platinumScriptDetails',
  PlatinumChatLocations = 'platinumLocations',
  PlatinumChatAdditional = 'platinumAdditional',
  Themes = 'ourThemes',
  Orders = 'orders',
  Carbon = 'carbon',
  Dashboard = 'dashboard',
  Bundles = 'Bundles',
  ShoppingCart = '/shoppingCart',
  CustomLandingPage = '/custom_landing_page',
  // Custom Website
  CustomWebsiteDetails = 'details',
  CustomWebsiteDesign = 'design',
  CustomWebsiteDesignItems = 'designItems',
  CustomWebsiteFeatures = 'features',
  CustomWebsiteFeatureList = 'featureList',
  CustomWebsiteDevelopment = 'development',
  CustomWebsiteAdditional = 'additional',
  CustomWebsiteAdditionalInfo = 'additionalInfo',
  CustomDesign = 'customDesign',
  CustomNavigation = 'customNavigation',
  CustomForm = 'customForm',
  CrmIntegration = 'crmIntegration',
  LoginPortal = 'loginPortal',
  Ecommerce = 'ecommerce',
  Additional = 'additional',
  Scheduling = 'scheduling',
  Content = 'content',
  Applications = 'applications',
  CustomExamples = 'examples',
  Cef = 'cef',
  // SEO
  SeoDetails = 'detail',
  SolutionStrategy = 'solutionStrategy',
  CustomSolutionStrategy = 'customStrategy',
  BlogSolutionStrategy = 'blogSolutionStrategy',
  // SMM
  SMMDetails = 'smmDetails',
  SMMSolutionStrategy = 'smmSolutionStrategy',
  // CC by Dash
  CCByDashDetails = 'dashDetails',
  CCByDashStrategy = 'dashStrategy',
  // Customer Center
  CustomerCenterStrategy = 'ccStrategy',
  // Generic Product
  GenericProductDetails = 'generic-product-details',
  // YouTube Product
  YouTubeTargeting = 'targeting',
  YouTubeWebsites = 'websites',
  YouTubeProxyProvisioning = 'proxy-provisioning',
  YouTubeAdvertising = 'advertising',
  // PiQ Landing Pages
  PiQDomain = 'piqDomain',
  PiQWebsite = 'piqWebsite',
  PiQPageInfo = 'piqPageInfo',
  PiQFormFields = 'piqFormFields',
  PiQTracking = 'piqTracking',
  PiQBackgroundImage = 'piqBackgroundImage',
  PiQAssets = 'piqAssets',
  PiQDesign = 'piqLandingDesign',
  PiQAdditionalInfo = 'piqAdditionalInfo',
  // Punchlist
  Confirmation = 'confirmation',
  Punchlist = 'pl',
  PunchlistSaved = 'punchlistSaved',
  // Targeted Display Product
  TargetedDisplay = 'targeted-display',
  SemDetails = 'sem-details',
  TargetedDisplayLeagacyProxyProvisioning = 'targeted-legacy-proxy-provisioning',
  TargetedDisplayLeagacyClientInformation = 'targeted-legacy-client-information',
  // Xmo
  XmoDetails = 'xmo-details',
  XmoBrandAmplifier = 'xmo-brand-amplifier',
  XmoStrategyGeneral = 'xmo-general',
  XmoStrategyGoal = 'xmo-goal',
  XmoStrategyTargeting = 'xmo-targeting',
  XmoStrategyProxy = 'xmo-proxy-provisioning',
  XmoSem = 'xmo-sem',
  XmoSocialSmartOptimization = 'xmo-social-smart-optimization',
  XmoWebsiteClicks = 'xmo-website-clicks',
  XmoLeadAds = 'xmo-lead-ads',
  XmoRetargeting = 'xmo-retargeting',
  XmoChat = 'xmo-chat',
  XmoTargetedDisplay = 'xmo-targeted-display',
  XmoSnapchat = 'xmo-snapchat',
  XmoYouTube = 'xmo-youtube',
  XmoReach = 'xmo-reach',
  XmoBaTactics = 'xmo-ba-tactics',
  // Promotions Media Mix
  PmmDetails = 'pmm-details',
  PmmStrategyGeneral = 'pmm-general',
  PmmStrategyTargeting = 'pmm-targeting',
  PmmStrategyProxy = 'pmm-proxy-provisioning',
  PmmSem = 'pmm-sem',
  PmmSocialSmartOptimization = 'pmm-social-smart-optimization',
  PmmWebsiteClicks = 'pmm-website-clicks',
  PmmRetargeting = 'pmm-retargeting',
  PmmTargetedDisplay = 'pmm-targeted-display',
  ExternalSubmissionView = 'external-submission-view',
}

export const MenuName = {
  [Urls.Business]: 'Business',
  [Urls.BusinessSearch]: 'Search',
  [Urls.BusinessDetail]: 'Detail',
  [Urls.BusinessClient]: 'Client',
  [Urls.BusinessLocation]: 'Locations',
  [Urls.BusinessLocationAddress]: 'Address',
  [Urls.BusinessLocationContact]: 'Contact',
  [Urls.BusinessLocationHours]: 'Hours',
  [Urls.BusinessLocationPhotos]: 'Photos',
  [Urls.BusinessLocationAdditional]: 'Additional',
  [Urls.GeneralQuestions]: 'General Questions',
  [Urls.Domains]: 'Domains',
  [Urls.Theme]: 'Theme',
  [Urls.StockAssets]: 'Stock Assets',
  [Urls.Examples]: 'Examples',
  [Urls.AdditionalFeaturesDesign]: 'Additional Features Design',
  [Urls.ContentWriting]: 'Content Writing',
  [Urls.ExistingContent]: 'Existing Content',
  [Urls.WebsiteAgeGate]: 'Age Gate',
  [Urls.AdditionalFeaturesContent]: 'Additional Features Content',
  [Urls.SitePages]: 'Site Pages',
  [Urls.WebsiteUrl]: 'Add-ons',
  [Urls.HeroVideo]: 'Video',
  [Urls.EmailHosting]: 'Email',
  [Urls.ExpandableMenus]: 'Menu',
  [Urls.OnlineBooking]: 'Booking',
  [Urls.PaymentForms]: 'Payment',
  [Urls.HipaaForms]: 'HIPAA',
  [Urls.Popups]: 'Pop-Up',
  [Urls.Domain]: 'Domain',
  [Urls.Assets]: 'Stock Assets',
  [Urls.Branding]: 'Branding',
  [Urls.ProductsDetails]: 'Details',
  [Urls.ProductCatalog]: 'Product Catalog',
  [Urls.Returns]: 'Returns',
  [Urls.Shipping]: 'Shipping',
  [Urls.AgeGate]: 'Age Gate',
  [Urls.AdditionalStoreDetails]: 'Additional',
  [Urls.SalesRep]: 'Sales Rep',
  [Urls.Overview]: 'Overview',
  [Urls.OrderType]: 'Order Type',
  [Urls.Timeline]: 'Timeline',
  [Urls.Audience]: 'Audience',
  [Urls.Goals]: 'Goals',
  [Urls.Tracking]: 'Tracking',
  [Urls.LandingDomain]: 'Domain',
  [Urls.LandingExistingContent]: 'Existing Content',
  [Urls.LandingStockAssets]: 'Stock Assets',
  [Urls.Info]: 'Page Info',
  [Urls.LandingContentWriting]: 'Content Writing',
  [Urls.BusinessChat]: 'Business URL',
  [Urls.ScriptDetails]: 'Script Details',
  [Urls.ChatLocations]: 'Locations',
  [Urls.ChatAdditional]: 'Additional',
  [Urls.PlatinumBusinessChat]: 'Business URL',
  [Urls.PlatinumScriptDetails]: 'Script Details',
  [Urls.PlatinumChatLocations]: 'Locations',
  [Urls.PlatinumChatAdditional]: 'Additional',
  [Urls.LandingCustomDetails]: 'Details',
  [Urls.LandingCustomDesign]: 'Design',
  [Urls.LandingCustomDevelopment]: 'Development',
  [Urls.LandingCustomAdditionalInfo]: 'Additional Info',
  [Urls.SeoDetails]: 'Details',
  [Urls.SolutionStrategy]: 'Solution Strategy',
  [Urls.CustomSolutionStrategy]: 'Solution Strategy',
  [Urls.BlogSolutionStrategy]: 'Solution Strategy',
  [Urls.GenericProductDetails]: 'Product Details',
  [Urls.SMMDetails]: 'Details',
  [Urls.SMMSolutionStrategy]: 'Solution Strategy',
  [Urls.CCByDashDetails]: 'Details',
  [Urls.CCByDashStrategy]: 'Solution Strategy',
  [Urls.CustomerCenterStrategy]: 'Solution Strategy',
  [Urls.YouTubeTargeting]: 'Targeting',
  [Urls.YouTubeWebsites]: 'Websites',
  [Urls.YouTubeProxyProvisioning]: 'Proxy/Provisioning',
  [Urls.YouTubeAdvertising]: 'Advertising',
  [Urls.PiQDomain]: 'Domain',
  [Urls.PiQWebsite]: 'Website',
  [Urls.PiQPageInfo]: 'Page Info',
  [Urls.PiQFormFields]: 'Form Fields',
  [Urls.PiQTracking]: 'Tracking',
  [Urls.PiQBackgroundImage]: 'Background Image',
  [Urls.PiQAssets]: 'Assets',
  [Urls.PiQAdditionalInfo]: 'AdditionalInfo',
  [Urls.Confirmation]: 'Confirmation',
  [Urls.Punchlist]: 'Punchlist',
  [Urls.PunchlistSaved]: 'PunchlistSaved',
  [Urls.TargetedDisplay]: 'Targeted Display',
  [Urls.SemDetails]: 'Details',
  [Urls.TargetedDisplayLeagacyProxyProvisioning]: 'Proxy/Provisioning',
  [Urls.TargetedDisplayLeagacyClientInformation]: 'Client Information',
  [Urls.XmoDetails]: 'Details',
  [Urls.XmoBrandAmplifier]: 'Brand Amplifier',
  [Urls.XmoStrategyGeneral]: 'General',
  [Urls.XmoStrategyGoal]: 'Goal',
  [Urls.XmoStrategyTargeting]: 'Targeting',
  [Urls.XmoStrategyProxy]: 'Proxy/Provisioning',
  [Urls.XmoSocialSmartOptimization]: 'Social Smart Optimization',
  [Urls.XmoSem]: 'SEM',
  [Urls.XmoWebsiteClicks]: 'Website Clicks',
  [Urls.XmoLeadAds]: 'Lead Ads',
  [Urls.XmoRetargeting]: 'Retargeting',
  [Urls.XmoChat]: 'Chat',
  [Urls.XmoTargetedDisplay]: 'Targeted Display',
  [Urls.XmoSnapchat]: 'Snapchat',
  [Urls.XmoYouTube]: 'YouTube',
  [Urls.XmoReach]: 'Reach',
  [Urls.XmoBaTactics]: 'Tactics',
  [Urls.PmmDetails]: 'Details',
  [Urls.PmmStrategyGeneral]: 'General',
  [Urls.PmmStrategyTargeting]: 'Targeting',
  [Urls.PmmStrategyProxy]: 'Proxy/Provisioning',
  [Urls.PmmSocialSmartOptimization]: 'Social Smart Optimization',
  [Urls.PmmSem]: 'SEM',
  [Urls.PmmWebsiteClicks]: 'Website Clicks',
  [Urls.PmmRetargeting]: 'Retargeting',
  [Urls.PmmTargetedDisplay]: 'Targeted Display',
};

// urlNavigationFromOverViewToProductPage For Xmo Products
export const urlNavigationFromOverViewToProductPage = {
  // key should be same that stored in database and value should be the url
  generalQuestions: 'generalQuestions',
  details: 'xmo-details',
  solutionStrategy: 'xmo-general',
  solutionStrategyBa: Urls.XmoStrategyGoal,
  sem: 'xmo-sem',
  socialSmartOptimization: 'xmo-social-smart-optimization',
  websiteClicks: 'xmo-website-clicks',
  leadAds: 'xmo-lead-ads',
  retargeting: 'xmo-retargeting',
  chat: 'xmo-chat',
  targetedDisplay: 'xmo-targeted-display',
  snapchat: 'xmo-snapchat',
  youtube: 'xmo-youtube',
  reach: 'xmo-reach',
};

export const urlNavigationFromOverViewToProductPagePmm = {
  // key should be same that stored in database and value should be the url
  generalQuestions: 'generalQuestions',
  details: 'pmm-details',
  solutionStrategy: 'pmm-general',
  sem: 'pmm-sem',
  socialSmartOptimization: 'pmm-social-smart-optimization',
  websiteClicks: 'pmm-website-clicks',
  retargeting: 'pmm-retargeting',
  targetedDisplay: 'pmm-targeted-display',
};

export const canGoToStep = (wantToGo, reachedBefore, menuSteps) => {
  const wantToGoIndex = menuSteps.findIndex((url) => url === wantToGo);
  const reachedIndex = menuSteps.findIndex((url) => url === reachedBefore);
  return wantToGoIndex <= reachedIndex;
};

// eslint-disable-next-line max-len
export const generateUrl = (baseUrl: string, paths: string[]): string => {
  const pathurl = [baseUrl, ...paths].join('/');
  return pathurl.replaceAll('//', '/');
};
