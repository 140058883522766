import {
  IQButtonRadioContainer,
  IQCheckbox,
  IQFormTextArea,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { Grid, styled } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IQButtonRadioProps } from '@gannettdigital/shared-react-components/dist/buttons/IQButtonRadio/IQButtonRadio';
import { generateTooltipTextElement } from 'shared/generateTooltipText';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import PaymentFormsType from 'models/PaymentFormsType';
import schema from './PaymentForms.schema';

interface Props {
  paymentForm: PaymentFormsType;
  onSubmit: (data) => any;
  onBack: () => any;
}

const paymentOptions: IQButtonRadioProps[] = [
  {
    value: 'authorizeNet',
    label: 'Authorize.net',
    field: 'authorizeNet',
    tooltipPlacement: 'top',
    // @ts-ignore
    tooltipText: generateTooltipTextElement(
      'Clients need to register, set up and maintain their own '
      + 'payment gateway to collect payments. Authorize.net is an approved gateway. ',
      {
        render: true,
        href: 'https://www.authorize.net',
        referrerPolicy: 'no-referrer',
        style: { color: 'white' },
        rel: 'noreferrer',
        target: '_blank',
        linkText: 'View More Information',
      },
    ),
    arrow: false,
  },
  {
    value: 'paypalCheckout',
    label: 'Paypal Checkout',
    field: 'paypalCheckout',
    tooltipPlacement: 'top',
    // @ts-ignore
    tooltipText: generateTooltipTextElement(
      'Clients need to register, set up and maintain their own payment '
      + 'gateway to collect payments. Paypal Checkout is an approved gateway. ',
      {
        render: true,
        href: 'https://www.paypal.com/us/business/accept-payments/checkout/integration#standard-checkout',
        referrerPolicy: 'no-referrer',
        style: { color: 'white' },
        rel: 'noreferrer',
        target: '_blank',
        linkText: 'View More Information',
        newLine: true,
      },
    ),
    arrow: false,
  },
  {
    value: 'stripe',
    label: 'Stripe',
    field: 'stripe',
    tooltipPlacement: 'top',
    // @ts-ignore
    tooltipText: generateTooltipTextElement(
      'Clients need to register, set up and maintain their own '
      + 'payment gateway to collect payments. Stripe is an approved gateway. ',
      {
        render: true,
        href: 'https://stripe.com/',
        referrerPolicy: 'no-referrer',
        style: { color: 'white' },
        rel: 'noreferrer',
        target: '_blank',
        linkText: 'View More Information',
      },
    ),
    arrow: false,
  },
  {
    value: 'square',
    label: 'Square gateway payments with Gravity Forms',
    field: 'square',
    tooltipPlacement: 'top',
    // @ts-ignore
    tooltipText: generateTooltipTextElement(
      'Clients need to register, set up and maintain their own '
      + 'payment gateway to collect payments. Square is an approved gateway. ',
      {
        render: true,
        href: 'https://docs.gravityforms.com/using-the-square-add-on/',
        referrerPolicy: 'no-referrer',
        style: { color: 'white' },
        rel: 'noreferrer',
        target: '_blank',
        linkText: 'View More Information',
      },
    ),
    arrow: false,
  },
];

const GridStyled = styled(Grid)`
  margin-top: 24px;

  .MuiToggleButton-root.Mui-selected {
    background-color: transparent;
  }

  .MuiToggleButton-root.Mui-selected:hover {
    background-color: transparent;
  }

  .MuiToggleButton-root:hover {
    background-color: transparent;
  }

  .MuiTouchRipple-root > * {
    display: none;
  }

  & .MuiFormHelperText-root {
    color: ${(props) => props.theme.palette.error.main};
  }

  & .MuiCheckbox-root {
    padding: 0;
    padding-right: 8px;
  }

  & .MuiTypography-root {
    max-width: 90%;
  }
   
  & .MuiFormHelperText-root {
    color: ${props => props.theme.palette.error.main};
  }
`;

export default function PaymentFormsForm(props: Props) {
  const { paymentForm } = props;

  const { t } = useTranslation();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      paymentFormRequirements: paymentForm.paymentFormRequirements ?? '',
      paymentsGateway: paymentForm.paymentsGateway ?? '',
      acknowledge: paymentForm.acknowledge || '',
      additionalNotes: paymentForm.additionalNotes || '',
    },
  });
  const {
    handleSubmit,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful,
    },
  } = methods;

  const onSubmit = (data) => {
    const content = { ...paymentForm, ...data };
    props.onSubmit(content);
  };

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <DefaultPageLayout
          disableContinue={!isValid}
          onBack={props.onBack}
          header={t('pages.addons.paymentForms.title')}
          onContinue={handleSubmit(onSubmit)}
        >
          <Grid container item xs={6}>
            <Grid item width="452px">
              <IQFormTextArea
                id="paymentFormRequirements"
                name="paymentFormRequirements"
                labelText={`${t('pages.addons.paymentForms.paymentFormRequirements')} *`}
                fontLabelWeight="600"
                fullWidth
                showError
              />
            </Grid>
            <GridStyled item xs={12}>
              <IQButtonRadioContainer
                optionsList={paymentOptions}
                label={`${t('pages.addons.paymentForms.paymentType')} *`}
                name="paymentsGateway"
                inline
                singleColumn
              />
            </GridStyled>
            <GridStyled item xs={12}>
              <IQFormTextArea
                id="additionalNotes"
                labelText="Additional Notes/Instructions"
                name="additionalNotes"
                rowCount={2}
                fullWidth
                fontLabelWeight="600"
                showError
              />
            </GridStyled>
            <GridStyled item xs={12} sx={{ label: { m: 0, mr: '11px' }, span: { mb: '18px' } }}>
              <IQCheckbox
                title={t('pages.addons.paymentForms.acknowledgementTitle')}
                label={t('pages.addons.paymentForms.acknowledgement')}
                field="acknowledge"
                name="acknowledge"
                required
              />
            </GridStyled>
          </Grid>
        </DefaultPageLayout>
        {/* show the dialog if we changed the field and didn't submit the changes */}
        <CoNavigationConfirm showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)} />
      </FormProvider>
    </ValidationProvider>
  );
}
