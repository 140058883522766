import ContentLoader from 'components/contentLoader/ContentLoader';
import { Urls } from 'navigation/Urls';
import useProduct from 'hooks/useProduct';
import EcommerceType, { EcommerceStateObject } from 'models/EcommerceType';
import { StatusType } from 'models/StatusType';
import ContactForm from './ContactForm';

export default function Contact() {
  const { product, onBack, onSubmit } = useProduct<EcommerceType,
  StatusType<EcommerceStateObject>>(Urls.Contact);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <ContactForm
        {...{ product: product?.content?.data, onBack, onSubmit }}
      />
    </ContentLoader>
  );
}
