import PersonIcon from '@mui/icons-material/Person';
import {
  IconButton, Menu, MenuItem, useTheme, Select, FormControl, InputLabel,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserAccess,
  selectUserRole,
  setUserRole,
} from 'services/userSlice';
import { Box } from '@mui/system';

const UserMenu = (props: any) => (
  <Menu
    sx={{
      '& .MuiPaper-root': {
        backgroundColor: 'black',
        color: 'white',
        width: '200px',
      },
    }}
    PopoverClasses={{ root: 'custom-popover' }}
    {...props}
  >
    {props.children}
  </Menu>
);

const UserMenuItem = (props: any) => {
  const theme = useTheme();

  return (
    <MenuItem
      sx={{
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      }}
      {...props}
    >
      {props.children}
    </MenuItem>
  );
};

const roles = {
  Admin: 'admin',
  Manager: 'manager',
  Fulfillment: 'fulfillment',
  Carbon: 'carbon',
  Sales: 'sales',
};

export default function CoUserMenu() {
  const userMenuRef = useRef(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState('');
  const userRole = useSelector(selectUserRole);
  const userAccess = useSelector(selectUserAccess);

  const handleMenuOpen = () => setOpen(true);

  const handleMenuClose = () => setOpen(false);

  const logout = () => {
    window.location.href = `${process.env.REACT_APP_COBALT_ROOT}log_out`;
  };

  const handleRoleChange = async (event: any) => {
    const selectedRole = event.target.value;
    setRole(selectedRole);
    dispatch(setUserRole(roles[selectedRole]));
  };

  const getKeyByValue = (value: any) => Object.keys(roles).find(key => roles[key] === value);

  useEffect(() => {
    const role = getKeyByValue(userRole);
    if (userAccess?.isAdmin) {
      setRole('Admin');
    } else {
      setRole(role);
    }
  }, [userRole]);

  return (
    <>
      <IconButton aria-label="user-menu" sx={{ marginLeft: '10px', paddingTop: '14px' }}
        ref={userMenuRef}
        aria-haspopup="true"
        onMouseOver={handleMenuOpen}
        onMouseOut={handleMenuClose}
      >
        <PersonIcon htmlColor="white" />
      </IconButton>
      <UserMenu
        anchorEl={userMenuRef.current}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{ onMouseLeave: handleMenuClose, onMouseOver: handleMenuOpen }}
      >
        {getKeyByValue(userRole)
              && (
              <UserMenuItem>
                {
                  userAccess?.isAdmin
                    ? (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel
                          sx={{
                            color: 'white',
                            marginBottom: '8px',
                            '&.Mui-focused': {
                              color: 'white',
                            },
                            '&.Mui-disabled': {
                              color: 'white',
                            },
                          }}
                        >
                          Log in as
                        </InputLabel>
                        <Select
                          value={role}
                          onChange={handleRoleChange}
                          label="Role"
                          sx={{
                            color: 'white',
                            '& .MuiSelect-select': {
                              color: 'white !important',
                            },
                            '& .MuiSelect-icon': {
                              color: 'white',
                            },
                          }}
                        >
                          {Object.keys(roles).map((roleKey) => (
                            <MenuItem key={roleKey} value={roleKey}>
                              {roleKey}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )
                    : (
                      <Box>
                        Logged in as {getKeyByValue(userRole)}
                      </Box>
                    )
                }
              </UserMenuItem>
              )}
        <UserMenuItem onClick={() => logout()}>Log out</UserMenuItem>
      </UserMenu>
    </>
  );
}
