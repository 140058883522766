/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';

const { t } = i18n;

export const InfographicsSchema: RJSFSchema = {

  title: t('pages.smm.solutionStrategy.title'),
  // eslint-disable-next-line max-len
  pageDescription: t('pages.smm.solutionStrategy.pageDescription'),
  type: 'object',
  required: ['topicsOrKeywordsToPrioritize'],
  properties: {
    topicsOrKeywordsToPrioritize: {
      type: 'string',
      title: t('pages.smm.infographics.infographicTopicsToPrioritizeLabel'),
      default: '',
    },
    topicsOrKeywordsToAvoid: {
      type: 'string',
      title: t('pages.smm.infographics.infographicTopicsToAvoid'),
      default: '',
    },
    additionalNotesOrInstructions: {
      type: 'string',
      title: t('pages.smm.solutionStrategy.additionalNotesLabel'),
      default: '',
    },
  },
};

const textFieldKeys = [
  'topicsOrKeywordsToPrioritize',
  'topicsOrKeywordsToAvoid',
  'additionalNotesOrInstructions',
];

const textFields = textFieldKeys.reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'TextareaWidget' } }), {});

export const InfographicsUISchema = {
  'ui:order': ['topicsOrKeywordsToPrioritize', 'topicsOrKeywordsToAvoid', 'additionalNotesOrInstructions'],
  ...textFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InfographicsCustomValidate = (formData: any, errors) => {
  if (formData?.topicsOrKeywordsToPrioritize === '') {
    errors.topicsOrKeywordsToPrioritize.addError(t('pages.smm.infographics.infographicTopicsToPrioritizeErrorMessage'));
  }
  return errors;
};
