import {
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThemeFeature, createThemeFeature } from 'services/adminSlice';
import FeatureForm from './FeatureForm';

export default function NewFeature() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const saveThemeFeature = async (data) => {
    const feature = {
      title: data.title,
      description: data.description,
      iconFileName: data.iconFileName,
      tiers: data.featureTiers,
    };
    await dispatch(await createThemeFeature(feature));
    navigate('/admin/themeFeatures');
  };

  const emptyFeature: ThemeFeature = {
    id: 0, title: '', description: '', iconFileName: null,
  };

  return (
    <>
      <Typography variant="h2">New Theme Feature</Typography>
      <FeatureForm onSubmit={saveThemeFeature} feature={emptyFeature} />
    </>
  );
}
