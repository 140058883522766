import { t } from "i18next";


export const genderOptionRadioItems = [
    {field: 'male', label:t('pages.xmo.reach.genderOption.male'),value:"male"},
    {field: 'both', label: t('pages.xmo.reach.genderOption.both'),value:"both"},
    {field: 'female', label: t('pages.xmo.reach.genderOption.female'),value:"female"},
  ];
  export const callToActionDropDown = [
    {value: 'No Button', description: t('pages.xmo.reach.callToActionOptions.noButton')},
    {value: 'Apply Now', description: t('pages.xmo.reach.callToActionOptions.applyNow')},
    {value: 'Download', description: t('pages.xmo.reach.callToActionOptions.download')},
    {value: 'Get Quote', description: t('pages.xmo.reach.callToActionOptions.getQuote')},
    {value: 'Learn More', description: t('pages.xmo.reach.callToActionOptions.learnMore')},
    {value: 'Sign Up', description: t('pages.xmo.reach.callToActionOptions.signUp')},
    {value: 'Subscribe', description: t('pages.xmo.reach.callToActionOptions.subscribe')},
  ];
  export const yesNoRadioOptions = [
    {label: t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.yes')},
    {label: t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.no')},
  ];
  export const socialPlatformListOptions = [
    {label: t('pages.xmo.reach.socialPlatformListOptions.mobileFeed')},
    {label: t('pages.xmo.reach.socialPlatformListOptions.desktopFeed')},
    {label: t('pages.xmo.reach.socialPlatformListOptions.instagram')},
    {label: t('pages.xmo.reach.socialPlatformListOptions.facebook')},
    {label: t('pages.xmo.reach.socialPlatformListOptions.marketplace')}
  ];
export const ageDropDown = Array.from({ length: 53 }, (v, k) => k + 13)
  .map(age => ({
    description: age === 65 ? '65 plus' : age.toString(),
    value: age.toString(),
  })
);