/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import { validateEmail } from '../custom-fields/EmailTextField';

const { t } = i18n;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SocialAdsAdEngagementAddOnSchema: RJSFSchema | any = (data) => ({
  // eslint-disable-next-line max-len
  type: 'object',
  properties: {
    solutionStrategyTitle: {
      type: 'string',
      title: t('pages.smm.solutionStrategy.title'),
    },
    campaignName: {
      type: 'string',
      title: t('pages.socialAdsAdEngagement.campaignName'),
      default: '',
    },
    campaignNamingStandards: {
      type: 'string',
      title: t('pages.socialAdsAdEngagement.campaignNamingStandards'),
    },
    coreProductInformation: {
      type: 'string',
      title: t('pages.socialAdsAdEngagement.coreProductInformation'),
    },
    runningSocialRsaCampaign: {
      type: 'string',
      title: t('pages.socialAdsAdEngagement.runningSocialCampaign'),
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    allowEmojis: {
      type: 'string',
      title: t('pages.socialAdsAdEngagement.allowEmojis'),
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    contactInformation: {
      type: 'string',
      title: t('pages.socialAdsAdEngagement.contactInformation'),
    },
    clientContactName: {
      type: 'string',
      title: t('pages.socialAdsAdEngagement.clientContactName'),
      default: '',
    },
    clientContactNumber: {
      type: 'string',
      title: t('pages.socialAdsAdEngagement.clientContactNumber'),
      default: '',
    },
    clientContactEmail: {
      type: 'string',
      title: t('pages.socialAdsAdEngagement.clientContactEmail'),
      default: '',
    },
    bestPersonToGrantFacebookAdminAccess: {
      type: 'string',
      title: t('pages.socialAdsAdEngagement.bestPersonToGrantFacebookAdminAccess'),
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    clientNotifications: {
      type: 'string',
      title: t('pages.socialAdsAdEngagement.leadNotificationsOrWeeklyPerformanceReports'),
      default: '',
      enum: [
        '',
        'Lead Notifications',
        'Weekly Performance Report',
        'Lead Notifications and Weekly Performance Report',
        'None',
      ],
    },
    emailAddressForNotifications: {
      type: 'string',
      title: t('pages.socialAdsAdEngagement.emailAddressThatWillBeNeeded'),
      default: '',
    },
    notesAndInstructions: {
      type: 'string',
      title: t('pages.socialAdsAdEngagement.notesAndInstructions'),
      default: '',
    },
  },
  dependencies: {
    runningSocialRsaCampaign: {
      oneOf: [
        {
          properties: {
            runningSocialRsaCampaign: {
              enum: ['Yes'],
            },
            socialRsaMcid: {
              type: 'string',
              title: t('pages.socialAdsAdEngagement.socialMcid'),
              default: '',
            },
          },
        },
        {
          properties: {
            runningSocialRsaCampaign: {
              enum: ['No'],
            },
            socialRsaCampaignName: {
              type: 'string',
              title: t('pages.socialAdsAdEngagement.socialCampaignName'),
              default: '',
            },
          },
        },
      ],
    },
    bestPersonToGrantFacebookAdminAccess: {
      oneOf: [
        {
          properties: {
            bestPersonToGrantFacebookAdminAccess: {
              enum: ['No'],
            },
            clientEmailForFacebookAdminAccess: {
              type: 'string',
              title: t('pages.socialAdsAdEngagement.clientEmailForFacebookAdminAccess'),
              default: '',
            },
          },
        },
      ],
    },
  },
});

const textAreaKeys = [
  'notesAndInstructions',
];

const dropdownFieldKeys = [
  'runningSocialRsaCampaign',
  'allowEmojis',
  'bestPersonToGrantFacebookAdminAccess',
  'clientNotifications',
];

const textFieldKeys = [
  'clientContactName',
  'socialRsaCampaignName',
  'socialRsaMcid',
];

const textLabelKeys = [
  'coreProductInformation',
  'campaignNamingStandards',
];

const phoneNumberTextKeys = [
  'clientContactNumber',
];

const emailTextKeys = [
  'emailAddressForNotifications',
  'clientEmailForFacebookAdminAccess',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const textAreaFields = textAreaKeys.reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'TextareaWidget' } }), {});

const textLabelFields = textLabelKeys.reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'textLabel' } }), {});

const phoneNumberTextFields = phoneNumberTextKeys
  .reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'phoneNumberTextField' } }), {});

const emailTextFields = emailTextKeys.reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'EmailTextWidget' } }), {});

export const SocialAdsAdEngagementAddOnUISchema = {
  'ui:order': ['solutionStrategyTitle', 'campaignName', 'campaignNamingStandards',
    'coreProductInformation', 'runningSocialRsaCampaign', 'allowEmojis', 'socialRsaMcid',
    'socialRsaCampaignName', 'contactInformation', 'clientContactName', 'clientContactNumber',
    'clientContactEmail', 'bestPersonToGrantFacebookAdminAccess', 'clientEmailForFacebookAdminAccess',
    'clientNotifications', 'emailAddressForNotifications', 'notesAndInstructions'],

  campaignName: {
    'ui:widget': 'textInputField',
    'ui:placeholder': t('pages.socialAdsAdEngagement.campaignNameHelp'),
  },

  clientContactEmail: {
    'ui:widget': 'EmailTextWidget',
    'ui:placeholder': t('pages.socialAdsAdEngagement.emailHelpContext'),
  },

  contactInformation: {
    'ui:widget': 'headingTextLabel',
  },

  solutionStrategyTitle: {
    'ui:widget': 'headingTextLabel',
  },

  ...textAreaFields,
  ...textFields,
  ...dropdownFields,
  ...textLabelFields,
  ...phoneNumberTextFields,
  ...emailTextFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SocialAdsAdEngagementAddOnCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.emailAddressForNotifications !== '' && !validateEmail(formData?.emailAddressForNotifications)) {
    errors.emailAddressForNotifications.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
  }
  if (formData?.clientContactEmail !== '' && !validateEmail(formData?.clientContactEmail)) {
    errors.clientContactEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
  }
  if (formData?.clientEmailForFacebookAdminAccess !== undefined && formData?.clientEmailForFacebookAdminAccess !== ''
      && !validateEmail(formData?.clientEmailForFacebookAdminAccess)) {
    errors.clientEmailForFacebookAdminAccess
      .addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
  }
  return errors;
};
