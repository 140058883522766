/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import { validateEmail } from 'pages/generic-product/details/custom-fields/EmailTextField';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';
import { ProductTypes } from "services/fileUploaderSlice";
import FileUploader from 'pages/generic-product/details/custom-fields/FileUploader';
import {urlValidationParameter} from "pages/generic-product/details/utils/genericValidation";

const { t } = i18n;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const VideoCreativeServiceSchema: RJSFSchema | any = (data) => ({
  // eslint-disable-next-line max-len
  type: 'object',
  required: ['productTypeSelection', 'contactWithTheClientDirectly', 'clientContactFirstName', 'clientContactLastName',
    'clientContactNumber', 'clientContactEmail', 'businessPhysicalAddress', 'city', 'state', 'zipcode', 'websiteUrl',
    'videoFocusType', 'scriptRequirements', 'scriptBulletPoints', 'voiceTalentPreference', 'musicSelection', 'uploader_assets'],
  properties: {
    solutionStrategyTitle: {
      type: 'string',
      title: t('pages.smm.solutionStrategy.title'),
    },
    productTypeSelection: {
      type: 'string',
      title: t('pages.videoCreativeService.productTypeSelection'),
      default: '',
      enum: [
        'Advanced Montage',
        'Full Motion Video Plus',
        'Three Video Bundle',
        'Audio Ad :15',
        'Audio Ad :30',
      ],
    },
    contactWithTheClientDirectly: {
      type: 'string',
      title: t('pages.videoCreativeService.contactWithTheClientDirectly'),
      default: '',
      enum: [
        'Yes - Contact the client directly',
        'No - Gather all client requests from the Sales/Services rep',
      ],
    },
    clientContactFirstName: {
      type: 'string',
      title: t('pages.videoCreativeService.clientContactFirstName'),
      default: '',
    },
    clientContactLastName: {
      type: 'string',
      title: t('pages.videoCreativeService.clientContactLastName'),
      default: '',
    },
    clientContactNumber: {
      type: 'string',
      title: t('pages.videoCreativeService.clientContactNumber'),
      default: '',
    },
    clientContactEmail: {
      type: 'string',
      title: t('pages.videoCreativeService.clientContactEmail'),
      default: '',
    },
    maid: {
      type: 'string',
      title: t('pages.videoCreativeService.maId'),
      default: '',
    },
    businessName: {
      type: 'string',
      title: t('pages.videoCreativeService.businessName'),
      default: '',
    },
    businessPhoneNumber: {
      type: 'string',
      title: t('pages.videoCreativeService.businessPhoneNumber'),
      default: '',
    },
    businessPhysicalAddress: {
      type: 'string',
      title: t('pages.videoCreativeService.businessPhysicalAddress'),
      default: '',
    },
    city: {
      type: 'string',
      title: t('pages.videoCreativeService.city'),
      default: '',
    },
    state: {
      type: 'string',
      title: t('pages.videoCreativeService.state'),
      default: '',
    },
    zipcode: {
      type: 'string',
      title: t('pages.videoCreativeService.zipcode'),
      default: '',
    },
    websiteUrl: {
      type: 'string',
      title: t('pages.videoCreativeService.websiteUrl'),
      default: '',
    },
    facebookPageUrl: {
      type: 'string',
      title: t('pages.videoCreativeService.facebookPageUrl'),
      default: '',
      customUrlValidation: true,
      urlValidationParameter,
      urlValidationErrorText: t('pages.smm.details.facebookUrlErrorMessage'),
    },
    callToAction: {
      type: 'string',
      title: t('pages.videoCreativeService.callToAction'),
      default: '',
    },
    videoFocusType: {
      type: 'string',
      title: t('pages.videoCreativeService.videoFocusType'),
      default: '',
      enum: [
        '',
        'Company Information',
        'Explainer / How-To',
        'Seasonal / Promotion',
        'Other',
      ],
    },
    videoFocusInfo: {
      type: 'string',
      title: t('pages.videoCreativeService.videoFocusInfo'),
      default: '',
      description: t('pages.videoCreativeService.videoFocusInfoHelpText'),
    },
    scriptRequirements: {
      type: 'string',
      title: t('pages.videoCreativeService.scriptRequirements'),
      default: '',
      enum: [
        '',
        'Client Provided Script',
        'Write Script for Me',
      ],
    },
    scriptBulletPoints: {
      type: 'string',
      title: t('pages.videoCreativeService.scriptBulletPoints'),
      default: '',
      description: t('pages.videoCreativeService.scriptBulletPointsHelpText'),
    },
    voiceTalentPreference: {
      type: 'string',
      title: t('pages.videoCreativeService.voiceTalentPreference'),
      default: '',
      enum: [
        '',
        'Male',
        'Female',
        'No Preference',
      ],
    },
    musicSelection: {
      type: 'string',
      title: t('pages.videoCreativeService.musicSelection'),
      default: '',
      enum: [
        '',
        'Easy',
        'Jazzy',
        'High Energy',
        'New Age',
        'Positive',
        'Rock',
        'No Preference',
      ],
    },
    productionNotes: {
      type: 'string',
      title: t('pages.videoCreativeService.productionNotes'),
      default: '',
      description: t('pages.videoCreativeService.productionNotesHelpText'),
    },
    helpText1: {
      type: 'string',
      title: t('pages.videoCreativeService.assets'),
    },
    helpText2: {
      type: 'string',
      title: t('pages.videoCreativeService.assetsUploadedHelpText'),
    },
    uploader_assets: {
      type: 'string',
      title: '',
      default: '',
    },
  },
  dependencies: {
    contactWithTheClientDirectly: {
      oneOf: [
        {
          properties: {
            contactWithTheClientDirectly: {
              enum: ['No - Gather all client requests from the Sales/Services rep'],
            },
            emailForCreativeApprovalNotifications: {
              type: 'string',
              title: t('pages.videoCreativeService.emailForCreativeApprovalNotifications'),
              default: '',
              description: t('pages.videoCreativeService.emailForApprovalNotificationsDescription'),
            },
          },
          required: ['emailForCreativeApprovalNotifications'],
        },
      ],
    },
    scriptRequirements: {
      oneOf: [
        {
          properties: {
            scriptRequirements: {
              enum: ['Client Provided Script'],
            },
            scriptUploaded: {
              type: 'string',
              title: t('pages.videoCreativeService.scriptUploaded'),
              default: '',
              enum: [
                '',
                'Yes',
                'No',
              ],
            },
          },
        },
      ],
    },
  },
});

const textAreaKeys = [
  'videoFocusInfo',
  'scriptBulletPoints',
  'productionNotes',
  'emailForCreativeApprovalNotifications',
];

const dropdownFieldKeys = [
  'videoFocusType',
  'scriptRequirements',
  'scriptUploaded',
  'voiceTalentPreference',
  'musicSelection',
];

const textFieldKeys = [
  'city',
  'state',
  'businessName',
  'maid',
  'clientContactFirstName',
  'clientContactLastName',
  'zipcode',
  'callToAction',
  'businessPhysicalAddress',
];

const textLabelKeys = [
  'assetUploadedDescriptiveMessage',
];

const phoneNumberTextKeys = [
  'clientContactNumber',
  'businessPhoneNumber',
];

const urlTextKeys = [
  'facebookPageUrl',
  'websiteUrl',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'dropdown'},
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

const textAreaFields = textAreaKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'TextareaWidget'}
}), {});

const textLabelFields = textLabelKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textLabel'}
}), {});

const phoneNumberTextFields = phoneNumberTextKeys
.reduce((o, key) => ({...o, [key]: {'ui:widget': 'phoneNumberTextField'}}), {});

const urlTextFields = urlTextKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'UrlTextWidget'}
}), {});

export const VideoCreativeServiceUISchema = {
  'ui:order': ['solutionStrategyTitle', 'productTypeSelection', 'contactWithTheClientDirectly',
    'emailForCreativeApprovalNotifications', 'clientContactFirstName', 'clientContactLastName',
    'clientContactNumber', 'clientContactEmail', 'maid', 'businessName', 'businessPhoneNumber',
    'businessPhysicalAddress', 'city', 'state', 'zipcode', 'websiteUrl', 'facebookPageUrl', 'callToAction',
    'videoFocusType', 'videoFocusInfo', 'scriptRequirements', 'scriptUploaded', 'scriptBulletPoints',
    'voiceTalentPreference', 'musicSelection', 'assetUploadedDescriptiveMessage', 'productionNotes',
    'helpText1', 'helpText2', 'uploader_assets'],

  clientContactEmail: {
    'ui:widget': 'EmailTextWidget',
    'ui:placeholder': t('pages.videoCreativeService.emailHelpContext'),
  },
  productTypeSelection: {
    'ui:widget': (props) => <Dropdown {...props} errorMessage="Please select the product type" />,
  },
  contactWithTheClientDirectly: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select if the video production team should work directly with the client"
      />
    ),
  },
  solutionStrategyTitle: {
    'ui:widget': 'headingTextLabel',
  },
  helpText1: {
    'ui:widget': 'textLabel',
    'ui:style': {
      fontSize: '18px',
      fontWeight: 'bold'
    }
  },
  helpText2: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginTop: '-5px'
    }
  },
  uploader_assets: {
    'ui:widget': (props) => (
        <FileUploader
            {...props}
            uploaderType={ProductTypes.VIDEO_CREATIVE_SERVICE_UPLOADER}
            totalAllowedFiles={10}
            imageTypes={['.png', '.jpg', '.jpeg', '.gif', '.cr2', '.dng']}
            videoTypes={['.mp4']}
            documentTypes={['.pdf', '.docx', '.doc']}
            supportingText=""
        />
    ),
    'ui:style': {
      marginTop: '15px'
    }
  },
  ...textAreaFields,
  ...textFields,
  ...dropdownFields,
  ...textLabelFields,
  ...phoneNumberTextFields,
  ...urlTextFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const VideoCreativeServiceCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.clientContactFirstName === '') {
    errors.clientContactFirstName.addError(t('pages.videoCreativeService.firstNameErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactLastName === '') {
    errors.clientContactLastName.addError(t('pages.videoCreativeService.lastNameErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactEmail === '') {
    errors.clientContactEmail.addError(t('pages.videoCreativeService.clientContactEmailErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactNumber === '') {
    errors.clientContactNumber.addError(t('pages.videoCreativeService.clientContactNumberErrorMessage'));
    errors.addError(true);
  }
  if (formData?.emailForCreativeApprovalNotifications === '') {
    errors.emailForCreativeApprovalNotifications
    .addError(t('pages.videoCreativeService.emailForApprovalNotificationsErrorMessage'));
    errors.addError(true);
  }
  if (formData?.maid === '') {
    errors.maid.addError(t('pages.videoCreativeService.maIdErrorMessage'));
    errors.addError(true);
  }
  if (formData?.businessName === '') {
    errors.businessName.addError(t('pages.videoCreativeService.businessNameErrorMessage'));
    errors.addError(true);
  }
  if (formData?.businessPhoneNumber === '') {
    errors.businessPhoneNumber.addError(t('pages.videoCreativeService.businessPhoneNumberErrorMessage'));
    errors.addError(true);
  }
  if (formData?.videoFocusInfo === '') {
    errors.videoFocusInfo.addError(t('pages.videoCreativeService.videoFocusInfoErrorMessage'));
    errors.addError(true);
  }
  if (formData?.callToAction === '') {
    errors.callToAction.addError(t('pages.videoCreativeService.callToActionErrorMessage'));
    errors.addError(true);
  }
  if (formData?.productionNotes === '') {
    errors.productionNotes.addError(t('pages.videoCreativeService.productionNotesErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactEmail !== '' && !validateEmail(formData?.clientContactEmail)) {
    errors.clientContactEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.facebookPageUrl !== ''
      && !urlValidationParameter.some(url => formData?.facebookPageUrl?.startsWith(url))) {
    errors.facebookPageUrl.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.facebookUrlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.websiteUrl !== '' && !validateUrl(formData?.websiteUrl)) {
    errors.websiteUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.businessPhysicalAddress === '') {
    errors.businessPhysicalAddress.addError(t('pages.videoCreativeService.businessPhysicalAddressErrorMessage'));
    errors.addError(true);
  }
  if (formData?.city === '') {
    errors.city.addError(t('pages.videoCreativeService.cityErrorMessage'));
    errors.addError(true);
  }
  if (formData?.state === '') {
    errors.state.addError(t('pages.videoCreativeService.stateErrorMessage'));
    errors.addError(true);
  }
  if (formData?.zipcode === '') {
    errors.zipcode.addError(t('pages.videoCreativeService.zipcodeErrorMessage'));
    errors.addError(true);
  }
  if (formData?.websiteUrl === '') {
    errors.websiteUrl.addError(t('pages.videoCreativeService.websiteUrlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.videoFocusType === '') {
    errors.videoFocusType.addError(t('pages.videoCreativeService.videoFocusTypeErrorMessage'));
    errors.addError(true);
  }
  if (formData?.scriptRequirements === '') {
    errors.scriptRequirements.addError(t('pages.videoCreativeService.scriptRequirementsErrorMessage'));
    errors.addError(true);
  }
  if (formData?.scriptBulletPoints === '') {
    errors.scriptBulletPoints.addError(t('pages.videoCreativeService.scriptBulletPointsErrorMessage'));
    errors.addError(true);
  }
  if (formData?.voiceTalentPreference === '') {
    errors.voiceTalentPreference.addError(t('pages.videoCreativeService.voiceTalentPreferenceErrorMessage'));
    errors.addError(true);
  }
  if (formData?.musicSelection === '') {
    errors.musicSelection.addError(t('pages.videoCreativeService.musicSelectionErrorMessage'));
    errors.addError(true);
  }
  const files = formData?.uploader_assets;
  if (files) {
    let formatFiles: any = [];
    if (typeof files === "string") {
      formatFiles = JSON.parse(files);
    }
    if (formatFiles.length === 0) {
      errors.uploader_assets.addError(t('pages.promotionsPageCreativesReachLocalBetaOnly.uploaderErrorMessage'));
      errors.addError(true);
    }
  }
  return errors;
};
