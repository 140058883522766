import { useEffect } from 'react';
import {
  Theme, createThemeCollateral, postThemeBenefits, postThemeFeatures, createTheme,
} from 'services/themesSlice';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  getBusinessFunctionalities, getCountries, getMarketCategories, getThemeBenefits,
  getThemeFamilies, getThemeFeatures, selectProducts,
} from 'services/adminSlice';
import { useAppDispatch } from 'services/redux/store';
import ThemeForm from './ThemeForm';

export default function NewTheme() {
  const emptyTheme = {} as Theme;
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const products = useSelector(selectProducts);

  useEffect(() => {
    dispatch(getMarketCategories({}));
    dispatch(getThemeBenefits({}));
    dispatch(getThemeFeatures({}));
    dispatch(getThemeFamilies({}));
    dispatch(getBusinessFunctionalities({}));
    dispatch(getCountries({}));
  }, []);

  const findById = (items, stringId) => items.find(item => item.id === parseInt(stringId, 10));
  const generateContent = (obj) => obj.map(e => e.id);

  const saveTheme = async (data) => {
    const theme = {
      countries: generateContent(data.countryAvailability),
      theme: {
        active: data.available,
        businessFunctionalityId: +data.businessFunctionalityId,
        callToAction: data.callToAction,
        color: data.color,
        compositeScreenshot: data?.compositeScreenshotFile || null,
        description: data.description,
        designStyle: data.designStyle,
        detailsAboveTheFold: data.detailsAbove,
        detailsBelowTheFold: data.detailsBelow,
        familyId: +data.familyId,
        featured: data.featured,
        id: null,
        marketCategoryId: +data.marketCategoryId,
        notes: data.notes,
        product: findById(products, data.productId)?.name,
        screenshotImage: data?.screenshotImageFile || null,
        sortId: data.sort,
        themePreview: data?.themePreviewFile?.toString() || null,
        title: data.title,
        url: data.url,
      },
    };

    await appDispatch(dispatch(createTheme(theme))).then(async (response) => {
      const themeId = response?.payload?.data?.id;
      if (themeId) {
        const newCollaterals = data.collaterals.map(collateral => ({
          themeId,
          label: collateral.label,
          collateralBlurb: collateral.collateralBlurb,
          uploadedFile: collateral.uploadedFile,
        }));

        if (newCollaterals.length > 0) newCollaterals.forEach(c => dispatch(createThemeCollateral(c)));
        await dispatch(postThemeFeatures({ themeId, content: generateContent(data.themeFeatures) }));
        await dispatch(postThemeBenefits({ themeId, content: generateContent(data.themeBenefits) }));
        navigate('/admin/themes');
      }
    });
  };

  return (
    <>
      <Typography variant="h2" mb={4}>New Theme</Typography>
      <ThemeForm onSubmit={saveTheme} themeData={emptyTheme} />
    </>
  );
}
