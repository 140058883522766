import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import GridItem from 'components/grid/GridItem';
import { Urls } from 'navigation/Urls';
import { editButton } from 'pages/overview/Overview';
import { headingStyle } from './BusinessSection';

export const renderClient = (client, index, role, navigate, isEditable) => (
  <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid black' }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {editButton(isEditable, () => navigate.to(`${Urls.BusinessClient}/${client.contact.id}/edit`))}
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{`Client ${index + 1}`}</Typography>
    </Box>
    <Box sx={{ marginTop: '15px' }}>
      <GridItem sizes={[6, 6]}>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Name</Typography>
            <Typography>{client.contact.clientFullName}</Typography>
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Phone Number(s)</Typography>
            {client?.contactPhoneNumberList?.map(phone => (
              <Typography key={phone.id}>
                {`${phone.phoneType}: ${phone.phoneNumber} ${phone?.ext && `ext. ${phone.ext}`}`}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Email</Typography>
            {client?.contactEmailList?.map(email => (
              <Typography key={email}>{email}</Typography>
            ))}
          </Box>
          <Box>
            <Typography sx={headingStyle} color="primary">Role</Typography>
            <Typography>{role}</Typography>
          </Box>
        </Box>
      </GridItem>
    </Box>
  </GridItem>
);
