import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';
import { TopNavStatusEnum } from 'services/TopNavStatusEnum';

export const youTubeMenu: MenuType = ({
  label: 'YouTube',
  url: '',
  status: 'incomplete',
  offeringType: 'YouTube',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
    {
      label: 'Solution Strategy',
      url: '',
      status: 'incomplete',
      subSteps: [
        {
          id: 0,
          url: Urls.YouTubeTargeting,
          name: 'Targeting',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 1,
          url: Urls.YouTubeWebsites,
          name: 'Websites',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 2,
          url: Urls.YouTubeProxyProvisioning,
          name: 'Proxy/Provisioning',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 3,
          url: Urls.YouTubeAdvertising,
          name: 'Advertising',
          status: TopNavStatusEnum.NotStarted,
        },
      ],
    },
  ],
});
