/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AxiosAPI from './common/axios';
import { RootState } from './redux/store';

const initialState: ErrorState = {
  error: {},
  status: 'idle',
  sendErrorComplete: false,
};

export interface ErrorState {
  error: ErrorType
  status: 'idle' | 'loading' | 'failed'
  sendErrorComplete: boolean

}

interface ErrorType {
  applicationName?: string,
  level?: string,
  title?: string,
  contextInformation?: string,
  description?: string,
}

const buildErrorObject = (error, context) : ErrorType => ({
  applicationName: 'Neon',
  level: 'ERROR',
  title: error.message,
  contextInformation: context,
  description: '',
});

export const sendErrorFeedback = createAsyncThunk(
  'errors/put',
  async (feedback: string, { getState }) => {
    // @ts-ignore
    const { errors } = getState();
    const response = await AxiosAPI.put('/errors/ui', { ...errors.error, description: feedback });
    return response.data;
  },
);

const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    logError(state, action) {
      state.error = action.payload;
    },
    clearSendErrorComplete(state) {
      state.sendErrorComplete = false;
    },
  },
  extraReducers: (builder) => builder
    .addCase(sendErrorFeedback.fulfilled, (state, action) => {
      state.status = 'idle';
      state.sendErrorComplete = true;
      state.error = action.payload;
    })
    .addCase(sendErrorFeedback.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(sendErrorFeedback.rejected, (state) => {
      state.status = 'failed';
    }),
});

const { logError } = errorsSlice.actions;

export const catchErrors = fn => (obj, params) => fn(obj, params).catch(error => {
  const state = params.getState();
  const errorObject = buildErrorObject(error, JSON.stringify(state));
  // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
  params && params.dispatch(logError(errorObject));
  return params && params.rejectWithValue(null);
});

export const createAsyncThunkCatchErrors = (typePrefix, payloadCreator) => createAsyncThunk(
  typePrefix,
  // @ts-ignore
  catchErrors(payloadCreator),
);

export const { clearSendErrorComplete } = errorsSlice.actions;

export const selectSendErrorStatus = (state: RootState) => state.errors.status;

export const selectSendErrorComplete = (state: RootState) => state.errors.sendErrorComplete;

const ErrorReducer = errorsSlice.reducer;

export default ErrorReducer;
