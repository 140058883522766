/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import CustomWebsiteType from 'models/CustomWebsiteType';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import AxiosAPI from './common/axios';
import { thunkBuilder } from './common/functions';

export interface CustomWebsiteState {
  customWebsite: StatusType<CustomWebsiteType>
  customWebsiteItems: StatusType<any[]>
  designItems: StatusType<string[]>
  featureList: StatusType<string[]>
}

const initialState: CustomWebsiteState = {
  customWebsite: {
    status: 'idle',
    message: '',
    content: {},
  },
  customWebsiteItems: {
    status: 'idle',
    message: '',
    content: [],
  },
  designItems: {
    status: 'idle',
    message: '',
    content: [],
  },
  featureList: {
    status: 'idle',
    message: '',
    content: [],
  },
};

export const getCustomWebsite = createAsyncThunkCatchErrors(
  'customWebsite/get',
  async (offeringId:string) => {
    const response = await AxiosAPI.get(`/custom-websites/${offeringId}`);
    return response.data;
  },
);

export const putCustomWebsiteDetails = createAsyncThunkCatchErrors(
  'customWebsiteDetails/put',
  async ({ orderItemId, content } :{ orderItemId:string, content: any }) => {
    const response = await AxiosAPI.put(`/custom-websites/${orderItemId}/new-website-info`, content);
    return response.data;
  },
);

export const putCustomWebsiteDesignInfo = createAsyncThunkCatchErrors(
  'customWebsiteDesignInfo/put',
  async ({ orderItemId, content } :{ orderItemId:string, content: any }) => {
    const response = await AxiosAPI.put(`/custom-websites/${orderItemId}/custom-info`, content);
    return response.data;
  },
);

export const putCustomWebsiteCrmLoginInfo = createAsyncThunkCatchErrors(
  'customWebsiteCrmLoginInfo/put',
  async ({ orderItemId, content } :{ orderItemId:string, content: any }) => {
    const response = await AxiosAPI.put(`/custom-websites/${orderItemId}/functionality-info`, content);
    return response.data;
  },
);

export const putCustomWebsiteEcomSchedulerInfo = createAsyncThunkCatchErrors(
  'customWebsiteEcomSchedulerInfo/put',
  async ({ orderItemId, content } :{ orderItemId:string, content: any }) => {
    const response = await AxiosAPI.put(`/custom-websites/${orderItemId}/features`, content);
    return response.data;
  },
);

export const putCustomWebsiteAdditionalFeatures = createAsyncThunkCatchErrors(
  'customWebsiteAdditionalFeatures/put',
  async ({ orderItemId, content } :{ orderItemId:string, content: any }) => {
    const response = await AxiosAPI.put(`/custom-websites/${orderItemId}/other-additional-features`, content);
    return response.data;
  },
);

export const putCustomWebsiteContentPage = createAsyncThunkCatchErrors(
  'customWebsiteContentPage/put',
  async ({ orderItemId, content } :{ orderItemId:string, content: any }) => {
    const response = await AxiosAPI.put(`/custom-websites/${orderItemId}/content`, content);
    return response.data;
  },
);

export const putCustomWebsiteApplications = createAsyncThunkCatchErrors(
  'customWebsiteApplications/put',
  async ({ orderItemId, content } :{ orderItemId:string, content: any }) => {
    const response = await AxiosAPI.put(
      `/custom-websites/${orderItemId}/third-party-applications`,
      content.thirdPartyApplications,
      { headers: { 'Content-Type': 'text/plain' } },
    );
    return response.data;
  },
);

export const putCustomWebsiteReferences = createAsyncThunkCatchErrors(
  'customWebsiteReferences/put',
  async ({ orderItemId, content } :{ orderItemId:string, content: any }) => {
    const response = await AxiosAPI.put(
      `/custom-websites/${orderItemId}/references`,
      content.references,
      { headers: { 'Content-Type': 'text/plain' } },
    );
    return response.data;
  },
);

export const putCustomWebsiteExamples = createAsyncThunkCatchErrors(
  'customWebsiteExamples/put',
  async ({ orderItemId, content } :{ orderItemId:string, content: any }) => {
    const response = await AxiosAPI.put(
      `/custom-websites/${orderItemId}/examples`,
      content.examples,
      { headers: { 'Content-Type': 'text/plain' } },
    );
    return response.data;
  },
);

export const putCustomWebsiteAdditionalInfo = createAsyncThunkCatchErrors(
  'customWebsiteAdditionalInfo/put',
  async ({ orderItemId, content } :{ orderItemId:string, content: any }) => {
    const response = await AxiosAPI.put(`/custom-websites/${orderItemId}/additional-information`, content);
    return response.data;
  },
);

export const getCustomWebsiteItems = createAsyncThunkCatchErrors(
  'customWebsiteItems/get',
  async (offeringId:string) => {
    const response = await AxiosAPI.get(`/custom-websites/items-websites/${offeringId}`);
    return response.data;
  },
);

export const postCustomWebsiteItems = createAsyncThunkCatchErrors(
  'customWebsiteItems/post',
  async ({ offeringId, content } :{ offeringId:string, content: any }) => {
    const response = await AxiosAPI.post(`/custom-websites/items-websites/${offeringId}`, content);
    return response.data;
  },
);

export const setDesignItems = createAction<string[]>('customWebsite/designItems/set');
export const setFeatureList = createAction<string[]>('customWebsite/featureList/set');

export const addressSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder)
      .addCase(getCustomWebsite, 'customWebsite')
      .addCase(getCustomWebsiteItems, 'customWebsiteItems')
      .addCase(postCustomWebsiteItems, 'customWebsiteItems');

    builder
      .addCase(setDesignItems, (state, { payload }) => {
        state.designItems.content = payload;
      })
      .addCase(setFeatureList, (state, { payload }) => {
        state.featureList.content = payload;
      });
  },
);

export const selectCustomWebsite = (state: RootState) => state.customWebsite.customWebsite.content;
export const selectCustomWebsiteItems = (state: RootState) => state.customWebsite.customWebsiteItems
  .content.map(item => item.id.customWebsiteItems.id);
export const selectCustomWebsiteItemStatus = (state: RootState) => state.customWebsite.customWebsiteItems.status;
export const selectDesignItems = (state: RootState) => state.customWebsite.designItems.content;
export const selectFeatureList = (state: RootState) => state.customWebsite.featureList.content;

export default addressSlice;
