import * as yup from 'yup';
import i18n from 'i18n/i18n';
const {t} = i18n;

const schema = yup.object().shape({
  minimumAge: yup.number().positive().required(t('pages.xmo.reach.minimumAgeRequiredError')).nullable()
  .typeError(t('pages.xmo.reach.minimumAgeTypeError')),
  maximumAge: yup.number().positive().required(t('pages.xmo.reach.maximumAgeRequiredError')).nullable()
  .typeError(t('pages.xmo.reach.maximumAgeTypeError'))
  // eslint-disable-next-line func-names
  .test('is-greater-than-minimumAge', t('pages.xmo.reach.maxShouldBeGreaterThanMinimum'), function (value) {
    const { minimumAge } = this.parent;
    if (minimumAge === undefined || value === undefined) {
      return true;
    }
    return value >= minimumAge;
  }),
  gender: yup.string().required().nullable(),
  callToActionButton: yup.string().required(t('pages.xmo.reach.callToActionRequired')).nullable(),
  useAutomaticOrOptimizedPlacement: yup.string().required().nullable(),
});

export default {
  yupValidations: schema,
  customValidations: {},
};