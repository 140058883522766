import * as yup from 'yup';

const schema = {
  additionalFeatures: yup.array(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
