import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import * as yup from 'yup';
import { IQInputEmail } from '@gannettdigital/shared-react-components';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Button, useTheme, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import schema from './LocationCardForm.schema';

interface Props {
  register: any
}

export default function LeadEmailsForm(props: Props) {
  const {
    register,
  } = props;
  const theme = useTheme();

  const { fields: emailsArray, append: appendEmail, remove: removeEmail } = useFieldArray({
    name: 'emails',
  });

  const [openDeleteEmailConfirm, setOpenDeleteEmailConfirm] = useState(false);
  const [funRemoveEmail, setFunRemoveEmail] = useState({ fn: () => {} });

  if (emailsArray.length === 0) {
    appendEmail({ email: '' });
  }

  const addNewEmailRow = () => {
    appendEmail({ email: '' });
  };

  const deleteEmail = (key) => {
    removeEmail(key);
    setOpenDeleteEmailConfirm(false);
  };

  const showDialogDeleteEmail = (funDelete, key) => {
    setFunRemoveEmail({ fn: () => funDelete(key) });
    setOpenDeleteEmailConfirm(true);
  };

  const customValidation = yup.string().email('Please enter a valid email in this format: name@example.com');

  const newEmailRow = (data, key) => (
    <GridItem sizes={(key === 0) ? [12] : [11, 1]} columnSpacing={0}>
      <IQInputEmail
        {...register(
          `emails.${key}.email`,
          { required: 'Please provide chat leads email in the format of name@example.com' },
        )}
        labelText="Leads Email *"
        fontLabelWeight="600"
        theme={theme}
        schema={schema}
        required
        id="leadsEmail"
        field={`emails.${key}.email`}
        tooltipText="This email is where the chat leads will be sent to."
        tooltipPlacement="top"
        tooltipPaddingBottom={16}
        tooltipArrow={false}
        fullWidth
        customValidation={customValidation}
        adornmentIcon={<MailOutlineIcon />}
      />
      {(key !== 0)
      && (
        <GridItem sx={{ position: 'relative', height: '40px' }}>
          <Button onClick={() => showDialogDeleteEmail(deleteEmail, key)} sx={{ position: 'absolute', top: '35px' }}>
            <DeleteIcon />
          </Button>
        </GridItem>
      )}
    </GridItem>
  );

  return (
    <>
      <GridItem marginBottom={2} marginTop={3}>
        <Typography variant="h5" component="h2">
          Lead Notifications
        </Typography>
      </GridItem>
      {emailsArray.map((data, index) => newEmailRow(data, index))}
      <GridContainer fullWidth>
        <GridItem>
          <Button
            onClick={() => addNewEmailRow()}
          >
            +&nbsp;&nbsp;
            <u>Add Leads Email</u>
          </Button>
        </GridItem>
      </GridContainer>
      <CoModalConfirm open={openDeleteEmailConfirm}
        title="Remove Leads Email"
        description="Are you sure you want to remove the leads email?"
        confirmText="Remove"
        handleClose={() => setOpenDeleteEmailConfirm(false)}
        handleConfirm={funRemoveEmail.fn}
      />
    </>
  );
}
