/* eslint-disable max-len */
import i18n from 'i18n/i18n';

import {RJSFSchema} from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import {Grid, Typography} from "@mui/material";
import React from "react";

const {t} = i18n;

export const CampaignAssetsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.campaignAssets.title'),
  type: 'object',
  required: ['requestedCreatives', 'sharedUrlLinksToSocialChannels', 'top3UniqueValuePropositions', 'finalUrl'],
  properties: {
    requestedCreatives: {
      type: 'string',
      title: t('pages.ausProduct.campaignAssets.requestedCreatives'),
      default: '',
      items: {
        enum: [
          t('pages.generic-translation.yesOption'),
          t('pages.generic-translation.noOption'),
        ],
      },
    },
    existingGoogleShoppingCampaign: {
      type: 'string',
      title: t('pages.ausProduct.campaignAssets.existingGoogleShoppingCampaign'),
      default: '',
      items: {
        enum: [
          t('pages.generic-translation.yesOption'),
          t('pages.generic-translation.noOption'),
        ],
      },
      description: t('pages.ausProduct.campaignAssets.existingGoogleShoppingCampaignHelpText')
    },
    sharedUrlLinksToSocialChannels: {
      type: 'string',
      title: t('pages.ausProduct.campaignAssets.sharedUrlLinksToSocialChannels'),
      default: '',
      description: t('pages.ausProduct.campaignAssets.sharedUrlLinksToSocialsHelpText')
    },
    top3UniqueValuePropositions: {
      type: 'string',
      title: t('pages.ausProduct.campaignAssets.top3UniqueValuePropositions'),
      default: '',
      description: t('pages.ausProduct.campaignAssets.top3ValuePropHelpText')
    },
    finalUrl: {
      type: 'string',
      title: t('pages.ausProduct.campaignAssets.finalUrl'),
      default: '',
      description: t('pages.ausProduct.campaignAssets.finalUrlHelpText')
    },
  },
  dependencies: {
    requestedCreatives: {
      oneOf: [
        {
          properties: {
            requestedCreatives: {
              enum: ['Yes'],
            },
            linkToCreativeFolder: {
              type: 'string',
              title: t('pages.ausProduct.campaignAssets.linkToCreativeFolder'),
              default: '',
            },
          },
          required: ['linkToCreativeFolder'],
        },
        {
          properties: {
            requestedCreatives: {
              enum: ['No'],
            },
            requestedCreativesHelpText: {
              type: 'string',
            },
          },
        },
      ],
    },
    existingGoogleShoppingCampaign: {
      oneOf: [
        {
          properties: {
            existingGoogleShoppingCampaign: {
              enum: ['Yes'],
            },
            cidOfShoppingCampaign: {
              type: 'string',
              title: t('pages.ausProduct.campaignAssets.cidOfShoppingCampaign'),
              default: '',
            },
          },
          required: ['cidOfShoppingCampaign'],
        },
      ],
    }
  }
});

const textFieldKeys = [
  'top3UniqueValuePropositions',
  'cidOfShoppingCampaign'
];

const radioFieldKeys = [
  'requestedCreatives',
  'existingGoogleShoppingCampaign',
];

const urlTextKeys = [
  'linkToCreativeFolder',
  'finalUrl',
];

const textAreaKeys = [
  'sharedUrlLinksToSocialChannels'
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'radioButton' },
}), {});

const urlTextFields = urlTextKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'UrlTextWidget' },
}), {});

const textAreaFields = textAreaKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

export const CampaignAssetsUISchema = {
  'ui:order': ['requestedCreatives', 'linkToCreativeFolder', 'requestedCreativesHelpText', 'existingGoogleShoppingCampaign',
  'cidOfShoppingCampaign', 'sharedUrlLinksToSocialChannels', 'top3UniqueValuePropositions', 'finalUrl'],
  requestedCreativesHelpText: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={0}>
          <Typography variant="subtitle2"
                      color={"dimgray"}> {t('pages.ausProduct.campaignAssets.requestedCreativesHelpText')} </Typography>
        </Grid>
    ),
  },
  ...textFields,
  ...radioFields,
  ...urlTextFields,
  ...textAreaFields
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CampaignAssetsCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.requestedCreatives === '') {
    errors.requestedCreatives.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.sharedUrlLinksToSocialChannels === '') {
    errors.sharedUrlLinksToSocialChannels.addError(t('pages.ausProduct.campaignAssets.socialLinksErrorMsg'));
    errors.addError(true);
  }
  if (formData?.existingGoogleShoppingCampaign === 'Yes') {
    if (formData?.cidOfShoppingCampaign === '') {
      errors.cidOfShoppingCampaign.addError(t('pages.ausProduct.campaignAssets.cidOfSCErrorMsg'));
      errors.addError(true);
    }
  }
  if (formData?.requestedCreatives === 'Yes') {
    if (formData?.linkToCreativeFolder === '') {
      errors.linkToCreativeFolder.addError(t('pages.ausProduct.campaignAssets.linkToCFErrorMsg'));
      errors.addError(true);
    }
  }
  if (formData?.top3UniqueValuePropositions === '') {
    errors.top3UniqueValuePropositions.addError(t('pages.ausProduct.campaignAssets.top3PropErrorMsg'));
    errors.addError(true);
  }
  if (formData?.finalUrl === '') {
    errors.finalUrl.addError(t('pages.ausProduct.campaignAssets.finalUrlErrorMsg'));
    errors.addError(true);
  }
  return errors;
};
