import * as yup from 'yup';
import i18n from 'i18n/i18n';

const {t} = i18n;

const schema = {
  // WEBSITE
  clientHaveALocaliqWebsite: yup.string().required(),
  isLocaliqWebsiteLive: yup.string().notRequired(),
  localiqWebsiteUrl: yup.string().url().required(),
  temporaryWebsiteUrl: yup.string().url().required(),

  // CLIENT INFO
  clientFullName: yup.string().required(),
  clientEmail: yup.string().email().required(),
  phoneNumber: yup.string().required(),
  phoneNumberExtension: yup.string().notRequired(),

  // Google Access
  clientHasGoogleAnalyticsAndWantUsToHaveAccessToItToGaugePerformance: yup
    .string()
    .notRequired(),
  isThisTheBestPersonToGrantUsGoogleAnalyticsAccess: yup.string().notRequired(),
  googleAnalyticsContactFullName: yup.string().required(),
  googleAnalyticsEmail: yup.string().email().required(),
  googleAnalyticsPhoneNumber: yup.string().notRequired(),
  googleAnalyticsPhoneNumberExtension: yup.string().notRequired()
};

export default {
  yupValidations: schema,
  customValidations: {},
};
