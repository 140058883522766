import AxiosAPI from "services/common/axios";

export const getSharedData = async (params: any) => {
  try {
    const request = {
      "url": params.url,
      "token": params.token
    }
    const response = await AxiosAPI.post('facebook/accounts/verify-token-and-processing-status', request);
    return response.status;
  } catch (error: any) {
    console.error("Error fetching data:", error);
    if (error === 'Forbidden') {
      return 403;
    }
    return error.response.status;
  }
};

export const storeUserDetails = async (details: any) => {
  try {
    const response = await AxiosAPI.post('facebook/url', details);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};