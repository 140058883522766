export const mapOpportunityOffer = {
  Website: 'website - standard',
  GoldSite: 'website - gold',
  EcommerceMicrosite: 'e-commerce microsite',
  CustomWebsite: 'website - custom',
  LandingPage: 'website - landing page',
  CustomLandingPage: 'website - custom landing page',
  ChatProduct: 'chat',
  ChatPlatinumProduct: 'chat - platinum',
  PremiumListing: 'premium listing',
  MasterAddon: 'master addons',
  SEOStandard: 'seo-standard',
  SEOCustom: 'seo-custom',
  SEOBlogStandard: 'seo-blog-standard',
  SEOBlogEnhanced: 'seo-blog-enhanced',
  SMMStandard: 'smm-standard',
  SMMCustom: 'smm-custom',
  DMSNextCustomerCenterByDash: 'dmsnext-cc-by-dash',
  DMSNextCustomerCenter: 'dmsnext-cc',
  GenericProduct: 'generic-product',
  SEOTopicLocation: 'seo-topic-location',
  SEOContentPages: 'seo-content-pages',
  SMMFacebookPosts: 'smm-facebook-posts',
  SMMInstagram: 'smm-instagram',
  SMMPinterest: 'smm-pinterest',
  SMMX: 'smm-x',
  SMMInfographics: 'smm-infographics',
  GoogleTagManager: 'google-tag-manager',
  PiQLandingPages: 'piq-landing-pages',
  XMO: 'xmo',
  XMOUpgrade: 'xmo-upgrade',
  PromotionsMediaMix: 'promotions-media-mix',
};

export const websiteProducts = [
  'website - standard',
  'website - gold',
  'website - landing page',
];

export const goldBundles = {
  Essential: 'Essential',
  Plus: 'Plus',
  Core: 'Core',
};

export const mapAddons = {
  VideoBackground: 'Video Background',
  ExpandableMenu: 'Expandable Menus',
  Popup: 'Pop-up',
  PaymentForms: 'Payment Forms',
};

export const mapAddonsToCart = {
  'Video Background': 'videoBackground',
  'Expandable Menus': 'expandableMenus',
  'Pop-up': 'popups',
  'Payment Forms': 'paymentForms',
};
