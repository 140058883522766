import { useEffect, useState } from 'react';
import { locationsTypes } from 'layouts/StepperLayout';
import standardSchema from './LocationAdditional.schema';
import premiumListingSchema from './PremiumListingsAdditional.schema';
import healthcareSchema from './PlHealthcareAdditional.schema';
import LocationAdditional from './LocationAdditional';

const baseDefaultValues = {
  services: [],
  brands: [],
  paymentTypes: [],
  languages: [],
  demographic: [],
  competitors: [],
};

const listingsDefaultValues = {
  ...baseDefaultValues,
  fulfilledAdditionalInfo: false,
};

export default function LocationAdditionalPage(props: any) {
  const { locationsType } = props;
  const [schema, setSchema] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (locationsType === locationsTypes.STANDARD) {
      setDefaultValues(baseDefaultValues);
      setSchema(standardSchema);
    }

    if (locationsType === locationsTypes.PREMIUM_LISTINGS) {
      setDefaultValues(listingsDefaultValues);
      setSchema(premiumListingSchema);
    }

    if (locationsType === locationsTypes.HEALTHCARE) {
      setDefaultValues(listingsDefaultValues);
      setSchema(healthcareSchema);
    }
  }, [locationsType]);

  useEffect(() => {
    setIsReady(schema && defaultValues);
  }, [schema, defaultValues]);

  return isReady && (
    <LocationAdditional
      {...props}
      type={locationsType}
      schema={schema}
      defaultValues={defaultValues}
    />
  );
}
