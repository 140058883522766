import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import { Urls } from 'navigation/Urls';
import { useParams } from 'react-router';
import XmoProxyBrandAmplifierForm from './XmoProxyBrandAmplifierForm';
import XmoProxyPageForm from './XmoProxyPageForm';

export default function XmoProxy() {
  const { page } = useParams();
  const { product, onSubmit, onBack } = useProduct(page as Urls);
  const productData = product?.content?.data;
  const variant = productData?.variant;

  return (
    <ContentLoader status={product.status} message={product.message}>
      {variant === 3 ? (
        <XmoProxyBrandAmplifierForm
          productData={productData}
          onSubmit={onSubmit}
          onBack={onBack}
        />
      ) : (
        <XmoProxyPageForm
          productData={productData}
          onSubmit={onSubmit}
          onBack={onBack}
        />
      )}
    </ContentLoader>
  );
}
