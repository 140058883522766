import {
  Button, FormControlLabel, Grid, Input, Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { createThemeFamily } from 'services/adminSlice';

export default function NewFamily() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const methods = useForm({
    mode: 'all',
    defaultValues: { name: '' },
  });
  const {
    control, handleSubmit,
  } = methods;

  const saveThemeFamily = async (family) => {
    await dispatch(await createThemeFamily({ name: family.name }));
    navigate('/admin/families');
  };

  return (
    <>
      <Typography variant="h2">New Family</Typography>
      <Grid container>
        <Grid item xs={12}>
          <FormProvider {...methods}>
            <FormControlLabel
              sx={{ marginLeft: 0 }}
              label={<Typography>Name</Typography>}
              labelPlacement="start"
              control={(
                <Controller name="name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input type="text" onChange={onChange} value={value}
                      sx={{ marginLeft: 3 }}
                    />
                  )}
                />
              )}
            />
          </FormProvider>
        </Grid>
        <Grid item xs={12} marginTop={2}>
          <Button variant="contained"
            sx={{ marginRight: 3 }}
            onClick={handleSubmit(saveThemeFamily)}
          >
            <Typography>Save</Typography>
          </Button>
          <Link to="/admin/families">
            <Button variant="contained">
              <Typography>Back to Families</Typography>
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}
