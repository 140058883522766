import * as yup from 'yup';
import i18n from "i18n/i18n";
import {urlValidationRegex} from "shared/constants";

const {t} = i18n;

const schema = {
  enableSmartChatbotSilver: yup.string().required(),
  leadDeliveryEmails: yup.string().test({
    name: 'emails',
    test: (value) => {
      const emails = value.split(',');
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emails.every((email) => emailRegex.test(email.trim()));
    },
    message: t('pages.xmo.tactics.chatbotSilver.emailErrorMessage'),
  }).required(t('pages.xmo.tactics.chatbotSilver.emailErrorMessage')),
  companyUrl: yup.string().required(t('pages.xmo.tactics.chatbotSilver.urlErrorMessage'))
  .matches(urlValidationRegex, t('pages.socialAds.socialAdsWebClicksSection.inputError.landingPageRequired')),
  localBusinessPhoneNumber: yup.string().required(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
