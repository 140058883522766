import i18n from 'i18n/i18n';

import {
  WebsitePrivacyPolicySchema,
  WebsitePrivacyPolicyUISchema,
  WebsitePrivacyPolicyCustomValidate
} from 'pages/generic-product/details/product-schema/aus-product-schema/WebsitePrivacyPolicySchema';
import {
  TrackingSchema,
  TrackingUISchema,
  TrackingCustomValidate
} from 'pages/generic-product/details/product-schema/aus-product-schema/TrackingSchema';
import {
  YoutubeSchema,
  YoutubeUISchema,
  YoutubeCustomValidate
} from 'pages/generic-product/details/product-schema/aus-product-schema/YoutubeSchema';
import {
  TargetingYoutubeSchema,
  TargetingYoutubeUISchema,
  TargetingYoutubeCustomValidate
} from 'pages/generic-product/details/product-schema/aus-product-schema/TargetingYoutubeSchema';
import {
  AdDetailsSchema,
  AdDetailsCustomValidate,
  AdDetailsUISchema
} from 'pages/generic-product/details/product-schema/aus-product-schema/AdDetailsSchema';
import {
  WebEventsCustomValidate,
  WebEventsSchema,
  WebEventsUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/WebEventsSchema';
import {
  CallTrackingCustomValidate,
  CallTrackingSchema,
  CallTrackingUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/CallTrackingSchema';
import {
  TrackingNumberDetailsCustomValidate,
  TrackingNumberDetailsSchema,
  TrackingNumberDetailsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TrackingNumberDetails";
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/NotesAndInstructionsSchema";

const {t} = i18n;

export const AusYoutubeSchema = (data) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.ausProduct.websitePrivacyPolicy.title'),
      schema: WebsitePrivacyPolicySchema(data),
      uiSchema: WebsitePrivacyPolicyUISchema,
      customValidate: WebsitePrivacyPolicyCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.tracking.title'),
      schema: TrackingSchema(data),
      uiSchema: TrackingUISchema,
      customValidate: TrackingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.youtube.title'),
      schema: YoutubeSchema(data),
      uiSchema: YoutubeUISchema,
      customValidate: YoutubeCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.youtubeTargeting.title'),
      schema: TargetingYoutubeSchema(data),
      uiSchema: TargetingYoutubeUISchema,
      customValidate: TargetingYoutubeCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.adDetails.title'),
      schema: AdDetailsSchema(data),
      uiSchema: AdDetailsUISchema,
      customValidate: AdDetailsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ];

  const trackingRequirements = data.trackingRequirements;
  const indexToInsert = 5;

  if (trackingRequirements === "Call and Web Event Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    });
    schema.splice(indexToInsert + 1, 0, {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    });
    schema.splice(indexToInsert + 2, 0, {
      type: 'object',
      title: t('pages.ausProduct.trackingNumber1Details.title'),
      schema: TrackingNumberDetailsSchema(data),
      uiSchema: TrackingNumberDetailsUISchema(data),
      customValidate: TrackingNumberDetailsCustomValidate,
    });
  } else if (trackingRequirements === "Web Event Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    });
  } else if (trackingRequirements === "Call Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    });
    schema.splice(indexToInsert + 1, 0, {
      type: 'object',
      title: t('pages.ausProduct.trackingNumber1Details.title'),
      schema: TrackingNumberDetailsSchema(data),
      uiSchema: TrackingNumberDetailsUISchema(data),
      customValidate: TrackingNumberDetailsCustomValidate,
    });
  }

  return schema;
};

export const AusYoutubeUISchema = (data) => ({
  'ui:order': [
    ...WebsitePrivacyPolicyUISchema['ui:order'],
    ...TrackingUISchema['ui:order'],
    ...YoutubeUISchema['ui:order'],
    ...TargetingYoutubeUISchema['ui:order'],
    ...AdDetailsUISchema['ui:order'],
    ...WebEventsUISchema(data)['ui:order'],
    ...CallTrackingUISchema['ui:order'],
    ...TrackingNumberDetailsUISchema(data)['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

export const AusYoutubeValidate = (formData: any, errors: any, uiSchema: any) => errors;
