/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import AxiosAPI from './common/axios';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { RootState } from './redux/store';
import { WebsiteOfferingTypes } from './websitesSlice';

export interface PremiumListingsState {
  address: StatusType<any>
  contact: StatusType<any>
  hours: StatusType<any>
  info: StatusType<any>
  logo_attachments: StatusType<any>
  square_logo_attachments: StatusType<any>
  picture_attachments: StatusType<any>
  photos: StatusType<any>
  provider_photo: StatusType<any>
  additional: StatusType<any>
  isHealthcare: StatusType<any>
}

const initialState: PremiumListingsState = {
  address: {
    status: 'idle',
    message: '',
    content: {},
  },
  contact: {
    status: 'idle',
    message: '',
    content: {},
  },
  hours: {
    status: 'idle',
    message: '',
    content: {},
  },
  info: {
    status: 'idle',
    message: '',
    content: {},
  },
  logo_attachments: {
    status: 'idle',
    message: '',
    content: [],
  },
  square_logo_attachments: {
    status: 'idle',
    message: '',
    content: [],
  },
  picture_attachments: {
    status: 'idle',
    message: '',
    content: [],
  },
  provider_photo: {
    status: 'idle',
    message: '',
    content: [],
  },
  photos: {
    status: 'idle',
    message: '',
    content: {},
  },
  additional: {
    status: 'idle',
    message: '',
    content: {},
  },
  isHealthcare: {
    status: 'idle',
    message: '',
    content: false,
  },
};

const FileUploadProductType = {
  websites: 'Website',
  goldsites: 'Gold Site',
  ecommerce_microsites: 'E-Commerce Microsite',
  landing_pages: 'Custom Landing Page Quote',
  premiumlistings: 'Premium Listings',
} as const;

export const uploadPhotos = createAsyncThunkCatchErrors(
  'premiumListings/files/post',
  async ({
    websiteId, files, uploaderType, offeringType,
  }
  : {
    websiteId: string | number,
    files: any,
    uploaderType: string,
    offeringType: WebsiteOfferingTypes
  }) => {
    const uploadedFilesPromises = files.map(async file => {
      const formData = new FormData();
      formData.set('bucketName', process.env.REACT_APP_BUCKET_NAME || '');
      formData.set('directory', `${websiteId}`);
      formData.set('files', file);
      const filesResponse = await AxiosAPI.post(
        '/files/upload',
        formData,
        { headers: { 'Content-type': 'multipart/form-data' } },
      );
      const fileName = Object.keys(filesResponse.data)[0];
      const fileElement = Object.values(filesResponse.data)[0][0];
      const uploadInfo = {
        fileName,
        fileSize: file.size,
        productId: websiteId,
        productType: FileUploadProductType[offeringType],
        uploaderType,
        url: fileElement.url,
      };
      const uploadResponse = await AxiosAPI.post(
        'upload',
        uploadInfo,
      );
      return { ...uploadResponse.data, mediaType: 'images' };
    });
    return Promise.all(uploadedFilesPromises);
  },
);

export const deleteFile = createAsyncThunkCatchErrors(
  'premiumListings/files/delete',
  async ({ websiteId, file, uploaderType } : { websiteId: string | number, file: any, uploaderType: string }) => {
    const splittedUrl = file.url ? file.url.split('/') : [''];
    const fileName = splittedUrl[splittedUrl.length - 1];
    AxiosAPI.delete(`files?bucketName=&directory=${websiteId}&files=${fileName}`);

    const response = await AxiosAPI.delete(`/upload/${file.id}`);
    const id = response.status === 200 ? file.id : null;
    return { id, uploaderType };
  },
);

export const getIsPremiumListingsHealthcare = createAsyncThunkCatchErrors(
  'premiumListings/get/isHealthcare',
  async (orderId: string) => {
    const response = await AxiosAPI.get(`/premium-listings/order/${orderId}/is_healthcare`);
    return response.data;
  },
);

export const setAddress = createAction<any>('premiumListings/address/set');
export const setContact = createAction<any>('premiumListings/contact/set');
export const setHours = createAction<any>('premiumListings/hours/set');
export const setInfo = createAction<any>('premiumListings/info/set');
export const setPhotos = createAction<any>('premiumListings/photos/set');
export const setAddtional = createAction<any>('premiumListings/additional/set');
export const setStandardLogo = createAction<any>('businessLocation/standardLogo/set');
export const setSquareLogo = createAction<any>('businessLocation/squareLogo/set');
export const setLocationPhotos = createAction<any>('businessLocation/locationPhotos/set');
export const setProviderPhoto = createAction<any>('businessLocation/providerPhoto/set');

export const premiumListingsSlice = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setAddress, (state, action) => {
        state.address.content = action.payload;
      })
      .addCase(setContact, (state, action) => {
        state.contact.content = action.payload;
      })
      .addCase(setHours, (state, action) => {
        state.hours.content = action.payload;
      })
      .addCase(setInfo, (state, action) => {
        state.info.content = action.payload;
      })
      .addCase(setAddtional, (state, action) => {
        state.additional.content = action.payload;
      })
      .addCase(setStandardLogo, (state, action) => {
        state.logo_attachments.content = action.payload;
      })
      .addCase(setSquareLogo, (state, action) => {
        state.square_logo_attachments.content = action.payload;
      })
      .addCase(setLocationPhotos, (state, action) => {
        state.picture_attachments.content = action.payload;
      })
      .addCase(setProviderPhoto, (state, action) => {
        state.provider_photo.content = action.payload;
      })
      .addCase(uploadPhotos.pending, (state, action) => {
        const { uploaderType } = action.meta.arg;
        state[uploaderType].status = 'loading';
      })
      .addCase(uploadPhotos.fulfilled, (state, action) => {
        const [{ uploaderType }] = action.payload;
        state[uploaderType].content = _.unionBy(state[uploaderType].content, action.payload, 'id');
        state[uploaderType].status = 'idle';
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        const { id, uploaderType } = action.payload;
        state[uploaderType].content = state[uploaderType].content.filter(f => f.id !== id);
      })
      .addCase(getIsPremiumListingsHealthcare.fulfilled, (state, action) => {
        state.isHealthcare.content = action.payload;
      });
  },
);

export const selectAddress = (state: RootState) => state.premiumListings.address.content;
export const selectContact = (state: RootState) => state.premiumListings.contact.content;
export const selectHours = (state: RootState) => state.premiumListings.hours.content;
export const selectInfo = (state: RootState) => state.premiumListings.info.content;
export const selectPhotos = (state: RootState) => state.premiumListings.photos.content;
export const selectStandardLogo = (state: RootState) => state.premiumListings.logo_attachments;
export const selectSquareLogo = (state: RootState) => state.premiumListings.square_logo_attachments;
export const selectLocationPhotos = (state: RootState) => state.premiumListings.picture_attachments;
export const selectProviderPhoto = (state: RootState) => state.premiumListings.provider_photo;
export const selectAdditional = (state: RootState) => state.premiumListings.additional.content;
export const selectIsPremiumListingsHealthcare = (state: RootState) => state.premiumListings.isHealthcare.content;

export default premiumListingsSlice;
