import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";

const {t} = i18n;
const urlValidationParameter = ['https://facebook.com', 'https://www.facebook.com'];

export const SocialCatalogueCustomFacebookSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.customFacebook.title'),
  type: 'object',
  required: [
    'proposalCreatedForThisClient',
    'purposeOrObjectiveOfTheCampaign',
    'trackingRequirements',
    'clientHaveFacebookPixel'
  ],
  properties: {
    facebookPageUrl: {
      type: 'string',
      title: t('pages.ausProduct.customFacebook.facebookPageUrl'),
      default: '',
      customUrlValidation: true,
      urlValidationParameter,
      urlValidationErrorText: t('pages.ausProduct.customFacebook.facebookPageUrlErrorMessage'),
      description: t('pages.ausProduct.customFacebook.facebookPageUrlDescription'),
    },
    proposalCreatedForThisClient: {
      type: 'string',
      title: t('pages.ausProduct.customFacebook.proposalCreatedForThisClient'),
      default: '',
      enum: [
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
    },
    purposeOrObjectiveOfTheCampaign: {
      type: 'string',
      title: t('pages.ausProduct.customFacebook.purposeOrObjectiveOfTheCampaign'),
      default: data?.purposeOrObjectiveOfTheCampaign || '',
      verticalAlignment: true,
      items: {
        enum: [
          t('pages.ausProduct.socialCatalogueFacebook.catalogueSales'),
          t('pages.ausProduct.socialCatalogueFacebook.dynamicRemarketing'),
          t('pages.ausProduct.socialCatalogueFacebook.both'),
        ],
      },
    },
    trackingRequirements: {
      type: 'string',
      title: t('pages.ausProduct.linkedin.trackingRequirementsLabel'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.linkedin.trackingRequirementsOpt1'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt2'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt3'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt4'),
      ],
      description: t('pages.ausProduct.customFacebook.trackingRequirementsDescription'),
    },
    additionalInformation: {
      type: 'string',
      title: t('pages.ausProduct.customFacebook.additionalInformation'),
      default: '',
      description: t('pages.ausProduct.customFacebook.additionalInformationDescription'),
    },
    clientHaveFacebookPixel: {
      type: 'string',
      title: t('pages.ausProduct.customFacebook.clientHaveFacebookPixel'),
      default: '',
      enum: [
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
      description: t('pages.ausProduct.customFacebook.clientHaveFacebookPixelDescription'),
    },
  },
});

const urlTextFieldKeys = [
  'facebookPageUrl'
]

const textFieldKeys = [
  'additionalInformation'
];

const radioFieldKeys = [
  'purposeOrObjectiveOfTheCampaign'
];

const dropdownFieldKeys = [
  'proposalCreatedForThisClient',
  'trackingRequirements',
  'clientHaveFacebookPixel',
];

const urlTextFields = urlTextFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'UrlTextWidget'},
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.tracking.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textInputField'},
}), {});

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'radioButton'},
}), {});

export const SocialCatalogueCustomFacebookUISchema = {
  'ui:order': [
    'facebookPageUrl',
    'proposalCreatedForThisClient',
    'purposeOrObjectiveOfTheCampaign',
    'trackingRequirements',
    'additionalInformation',
    'clientHaveFacebookPixel',
  ],
  ...urlTextFields,
  ...textFields,
  ...radioFields,
  ...dropdownFields,
};

export const SocialCatalogueCustomFacebookCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.facebookPageUrl !== '' && !urlValidationParameter.some(url => formData?.facebookPageUrl?.startsWith(url))) {
    errors.facebookPageUrl.addError(t('pages.ausProduct.customFacebook.facebookPageUrlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.proposalCreatedForThisClient === '') {
    errors.proposalCreatedForThisClient.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.purposeOrObjectiveOfTheCampaign === '') {
    errors.purposeOrObjectiveOfTheCampaign.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.trackingRequirements === '') {
    errors.trackingRequirements.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientHaveFacebookPixel === '') {
    errors.clientHaveFacebookPixel.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
