import * as yup from 'yup';

const schema = {
  callCapTrackingPhoneNumber: yup.string().required(),
  formFillLeadEmailAddress: yup.string().email().required('Please enter a form fill lead email address.'),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
