/* eslint-disable no-param-reassign */
import {FormProvider, useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import {
  IQFormInput,
  IQFormSelect,
  IQInputNumericField,
  IQTheme,
  IQThemeV2,
  ValidationProvider
} from "@gannettdigital/shared-react-components";
import schema from "./Retargeting.schema";
import GridContainer from "components/grid/GridContainer";
import GridItem from "components/grid/GridItem";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from "@mui/material";
import schemaValidate from "components/schemaValidate";
import {
  callToActionDropdownItems,
  clientWantTheirAdsToAppearCheckboxes,
  daysRetargetFacebookVisitorsDropdownItems,
  genderItems,
  imageVideoItems,
  inventoryItems,
  retargetingItems,
  selectAllLeadAdsFormContactFieldsCheckboxes,
  yesAllItems,
  yesLookaLikesItems,
  yesNoDropdownItems,
  yesNoItems
} from "./SocialAdsRetargetingService";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NeSelectChip from "components/chips/NeSelectChip";
import CoNavigationConfirm from "components/navigation/CoNavigationConfirm";
import ErrorIcon from "@mui/icons-material/Error";
import {useParams} from "react-router";

interface Props {
  product: any;
  onSubmit: (data: any) => void;
  onBack: () => void;
}

export type ThisFormProps = {
  disableSiteRetargeting: string,
  minimumAge: string,
  maximumAge: string,
  gender: string,
  callToAction: string,
  selectAllLeadAdsFormContactFields: string[],
  automaticOrOptimizedPlacementOnAds: string,
  whereClientWantAdsToAppear: string[],
  clientWantEnableWebsiteRetargeting: string
  enableTargetingPeopleVisitedWebsite: string,
  selectPageUrl: string,
  numberOfDaysRetargetFacebookVisitors: string,
  websiteRetargetingOptOutUrl: string,
  enableTargetingPeopleSimilarToWhoLikedFacebook: string,
  wantToRetargetSearchCampaignLeadsOnFacebook: string;
  numberOfDaysToRetarget: string;
  typeOfVisualStrategyClientWantToEmploy: string;
  willClientBeProvidingImagesToUse: string;
  willThisCampaignUtilizeCarouselFormat: string;
  willClientBeProvidingTheirOwnTextCreative: string;
  adHeadLine: string;
  adBodyText: string;
  newsFeedLinkDescription: string;
  inventoryToFeatureInCampaign: string;
  productsOrServicesOrOffersToPromoteInCampaign: string[];
};

export default function RetargetingForm({product, onSubmit, onBack}: Props) {
  const { page } = useParams();

  const isGoalRequired = page.includes("xmo");
  const prodRetargeting = product?.retargeting;

  const {t} = useTranslation();
  const [goal, setGoal] = useState(product?.variant);
  const [isDisableSiteRetargeting, setIsDisableSiteRetargeting] = useState(prodRetargeting?.disableSiteRetargeting || '');
  const [isCampaignGoal, setCampaignGoal] = useState(product?.socialSmartOptimization?.selectCampaignGoalFocus);
  const [leadAdsFormCheckboxes, setLeadAdsFormCheckboxes] = useState(prodRetargeting?.selectAllLeadAdsFormContactFields || []);
  const [isPlacementOnAds, setIsPlacementOnAds] = useState(prodRetargeting?.automaticOrOptimizedPlacementOnAds || '');
  const [whereClientWantsAdsCheckboxes, setWhereClientWantsAdsCheckboxes] = useState(prodRetargeting?.whereClientWantAdsToAppear || []);
  const [isClientWantEnableWebsiteRetargeting, setClientWantEnableWebsiteRetargeting] = useState(prodRetargeting?.clientWantEnableWebsiteRetargeting || '');
  const [isRetargetSearchCampaignOnFB, setIsRetargetSearchCampaignOnFB] = useState(prodRetargeting?.wantToRetargetSearchCampaignLeadsOnFacebook || '');
  const [isNumberOfDaysToRetarget, setIsNumberOfDaysToRetarget] = useState(prodRetargeting?.numberOfDaysToRetarget || '');
  const [isCarousel, setIsCarousel] = useState(prodRetargeting?.willThisCampaignUtilizeCarouselFormat || '');
  const [isClientProvidingTextCreative, setClientProvidingTextCreative] = useState(prodRetargeting?.willClientBeProvidingTheirOwnTextCreative || '');
  const [ageError, setAgeError] = useState<boolean>(false);
  const [adHeadlineValueError, setAdHeadlineValueError] = useState<boolean>(false);
  const [newsfeedLinkDescriptionValueError, setNewsfeedLinkDescriptionValueError] = useState<boolean>(false);

  const methods = useForm<ThisFormProps>({
    mode: 'all',
    defaultValues: {
      disableSiteRetargeting: prodRetargeting?.disableSiteRetargeting || '',
      minimumAge: prodRetargeting?.minimumAge || '',
      maximumAge: prodRetargeting?.maximumAge || '',
      gender: prodRetargeting?.gender || '',
      callToAction: prodRetargeting?.callToAction || '',
      selectAllLeadAdsFormContactFields: prodRetargeting?.selectAllLeadAdsFormContactFields || [],
      automaticOrOptimizedPlacementOnAds: prodRetargeting?.automaticOrOptimizedPlacementOnAds || '',
      whereClientWantAdsToAppear: prodRetargeting?.whereClientWantAdsToAppear || [],
      clientWantEnableWebsiteRetargeting: prodRetargeting?.clientWantEnableWebsiteRetargeting || '',
      enableTargetingPeopleVisitedWebsite: prodRetargeting?.enableTargetingPeopleVisitedWebsite || '',
      selectPageUrl: prodRetargeting?.selectPageUrl || '',
      numberOfDaysRetargetFacebookVisitors: prodRetargeting?.numberOfDaysRetargetFacebookVisitors || '',
      websiteRetargetingOptOutUrl: prodRetargeting?.websiteRetargetingOptOutUrl || '',
      enableTargetingPeopleSimilarToWhoLikedFacebook: prodRetargeting?.enableTargetingPeopleSimilarToWhoLikedFacebook || '',
      wantToRetargetSearchCampaignLeadsOnFacebook: prodRetargeting?.wantToRetargetSearchCampaignLeadsOnFacebook || '',
      numberOfDaysToRetarget: prodRetargeting?.numberOfDaysToRetarget || '',
      typeOfVisualStrategyClientWantToEmploy: prodRetargeting?.typeOfVisualStrategyClientWantToEmploy || '',
      willClientBeProvidingImagesToUse: prodRetargeting?.willClientBeProvidingImagesToUse || '',
      willThisCampaignUtilizeCarouselFormat: prodRetargeting?.willThisCampaignUtilizeCarouselFormat || '',
      willClientBeProvidingTheirOwnTextCreative: prodRetargeting?.willClientBeProvidingTheirOwnTextCreative || '',
      adHeadLine: prodRetargeting?.adHeadLine || '',
      adBodyText: prodRetargeting?.adBodyText || '',
      newsFeedLinkDescription: prodRetargeting?.newsFeedLinkDescription || '',
      inventoryToFeatureInCampaign: prodRetargeting?.inventoryToFeatureInCampaign || '',
      productsOrServicesOrOffersToPromoteInCampaign: prodRetargeting?.productsOrServicesOrOffersToPromoteInCampaign || [],
    },
  });

  const handleFormSubmit = (data) => {
    if (!isGoalRequired  ||(goal == 1 || goal == 3 || (isDisableSiteRetargeting && isDisableSiteRetargeting === 'No'))) {
      if (isCampaignGoal !== "Contacts Ads - Dynamics Ads (Auto Only)") {
        data.inventoryToFeatureInCampaign = null;
      }
      if (data?.willClientBeProvidingTheirOwnTextCreative === 'No') {
        data.adHeadLine = "";
        data.adBodyText = "";
        data.newsFeedLinkDescription = "";
      }
      data.selectAllLeadAdsFormContactFields = leadAdsFormCheckboxes;
      if (data.automaticOrOptimizedPlacementOnAds === 'Yes') {
        data.whereClientWantAdsToAppear = [];
      } else {
        data.whereClientWantAdsToAppear = whereClientWantsAdsCheckboxes;
      }
      if (isClientWantEnableWebsiteRetargeting === 'Entire Website') {
        data.selectPageUrl = null
      }
      if (isRetargetSearchCampaignOnFB === 'No') {
        data.numberOfDaysToRetarget = null
      }
    } else {
      data.minimumAge = null
      data.maximumAge = null
      data.gender = ''
      data.callToAction = ''
      data.selectAllLeadAdsFormContactFields = []
      data.automaticOrOptimizedPlacementOnAds = ''
      data.whereClientWantAdsToAppear = []
      data.clientWantEnableWebsiteRetargeting = ''
      data.enableTargetingPeopleVisitedWebsite = ''
      data.selectPageUrl = ''
      data.numberOfDaysRetargetFacebookVisitors = ''
      data.websiteRetargetingOptOutUrl = ''
      data.enableTargetingPeopleSimilarToWhoLikedFacebook = ''
      data.wantToRetargetSearchCampaignLeadsOnFacebook = ''
      data.numberOfDaysToRetarget = ''
      data.typeOfVisualStrategyClientWantToEmploy = ''
      data.willClientBeProvidingImagesToUse = ''
      data.willThisCampaignUtilizeCarouselFormat = ''
      data.willClientBeProvidingTheirOwnTextCreative = ''
      data.adHeadLine = ''
      data.adBodyText = ''
      data.newsFeedLinkDescription = ''
      data.inventoryToFeatureInCampaign = ''
      data.productsOrServicesOrOffersToPromoteInCampaign = []
    }
    const pageFormData = {
      retargeting: data
    };
    onSubmit(pageFormData)
  };

  const {
    handleSubmit,
    watch,
    register,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  const minimumAge = watch('minimumAge');
  const maximumAge = watch('maximumAge');
  const adHeadlineValue = watch('adHeadLine');
  const newsfeedLinkDescriptionValue = watch('newsFeedLinkDescription');

  useEffect(() => {
    const minAge = parseInt(minimumAge);
    const maxAge = parseInt(maximumAge);

    if (minAge <= 0 || maxAge <= 0 || minAge > maxAge) {
      setAgeError(true);
    } else {
      setAgeError(false);
    }
  }, [minimumAge, maximumAge]);

  const handleSelectAllLeadAdsFormChange = (opt) => {
    if (leadAdsFormCheckboxes.includes(opt)) {
      setLeadAdsFormCheckboxes(leadAdsFormCheckboxes.filter(item => item !== opt));
    } else {
      setLeadAdsFormCheckboxes([...leadAdsFormCheckboxes, opt]);
    }
  };

  const handleWhereClientWantsAdsChange = (opt) => {
    if (whereClientWantsAdsCheckboxes.includes(opt)) {
      setWhereClientWantsAdsCheckboxes(whereClientWantsAdsCheckboxes.filter(item => item !== opt));
    } else {
      setWhereClientWantsAdsCheckboxes([...whereClientWantsAdsCheckboxes, opt]);
    }
  };

  const validateForm = () => {
    let firstCheckboxes = leadAdsFormCheckboxes?.length > 0;
    let secondCheckboxes = false;
    if (isPlacementOnAds === 'No') {
      secondCheckboxes = whereClientWantsAdsCheckboxes?.length > 0;
    } else {
      secondCheckboxes = true;
    }
    return firstCheckboxes && secondCheckboxes;
  };

  const validateNumberFieldForm = () => {
    if (isRetargetSearchCampaignOnFB === 'No' ||
        (isRetargetSearchCampaignOnFB === 'Yes' && isNumberOfDaysToRetarget !== undefined)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isCarousel === 'Yes') {
      setAdHeadlineValueError(adHeadlineValue.length > 60)
      setNewsfeedLinkDescriptionValueError(newsfeedLinkDescriptionValue.length > 60)
    } else if (isCarousel === 'No') {
      setAdHeadlineValueError(adHeadlineValue.length > 75);
      setNewsfeedLinkDescriptionValueError(newsfeedLinkDescriptionValue.length > 250);
    }
  }, [isCarousel, adHeadlineValue, newsfeedLinkDescriptionValue]);

  return (
      <DefaultPageLayout
          disableContinue={isDisableSiteRetargeting === 'Yes' ? false : (
              !isValid ||
              !validateForm() ||
              !validateNumberFieldForm() ||
              ageError ||
              adHeadlineValueError ||
              newsfeedLinkDescriptionValueError
          )}
          onBack={onBack}
          header={t('pages.socialAds.solutionStrategy.smartRetargeting.SmartRetargetingLabel')}
          onContinue={handleSubmit(handleFormSubmit)}
      >
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <Box>
              <GridContainer fullWidth>
                {goal == 2 && (
                    <GridItem sizes={[12]}>
                      <IQFormSelect
                          theme={IQTheme}
                          schema={schema}
                          labelText={t('pages.xmo.tactics.socialAdsRetargeting.disableSiteRetargetingLabel')}
                          placeholder={t('pages.xmo.tactics.socialAdsRetargeting.disableSiteRetargetingPlaceholder')}
                          id="disableSiteRetargeting"
                          name="disableSiteRetargeting"
                          items={yesNoDropdownItems}
                          value={prodRetargeting?.disableSiteRetargeting || ''}
                          onChange={(event) => setIsDisableSiteRetargeting(event.target.value)}
                          fontLabelWeight="600"
                          fullWidth
                      />
                    </GridItem>
                )}
                {(!isGoalRequired || (goal == 1 || goal == 3 || (isDisableSiteRetargeting && isDisableSiteRetargeting === 'No'))) && (
                    <>
                      <GridItem sizes={[12]}>
                        <Typography
                            variant="h6">{t('pages.landing.audience.header')}</Typography>
                      </GridItem>
                      <GridItem sizes={[6, 6]}>
                        <IQInputNumericField
                            theme={IQTheme}
                            schema={schema}
                            fontLabelWeight="600"
                            label={t('pages.xmo.leadAds.minimumAge')}
                            name="minimumAge"
                            disallowNegative
                            required
                            defaultValue={prodRetargeting?.minimumAge || ""}
                            fullWidth
                        />
                        <IQInputNumericField
                            theme={IQTheme}
                            schema={schema}
                            fontLabelWeight="600"
                            label={t('pages.xmo.leadAds.maximumAge')}
                            name="maximumAge"
                            disallowNegative
                            required
                            defaultValue={prodRetargeting?.maximumAge || ""}
                            fullWidth
                        />
                        {ageError &&
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography
                                    sx={{
                                      color: '#C20F1E',
                                      fontSize: '14px',
                                      marginTop: '-15px',
                                      marginBottom: '15px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                    variant="subtitle1"
                                >
                                  <Box sx={{display: 'flex', alignItems: 'center'}}>
                                    <ErrorIcon
                                        sx={{
                                          color: '#C20F1E',
                                          fontSize: '16px',
                                          marginRight: '5px'
                                        }}
                                    />
                                    {t('pages.xmo.leadAds.maximumAgeError')}
                                  </Box>
                                </Typography>
                              </Grid>
                            </Grid>
                        }
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <Typography
                            sx={{fontWeight: 'bold'}}>{t('pages.xmo.tactics.socialAdsRetargeting.genderLabel')}
                          <RadioGroup
                              id='gender'
                              name='gender'
                              defaultValue={prodRetargeting?.gender || ''}
                              row
                          >
                            {genderItems.map(opt => (
                                <FormControlLabel
                                    key={opt.label}
                                    control={<Radio value={opt.label}/>}
                                    label={opt.label}
                                    {...register('gender', {
                                      validate: (value) => schemaValidate(value, 'gender', schema),
                                    })}
                                />
                            ))}
                          </RadioGroup>
                        </Typography>
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <Typography
                            variant="h6">{t('pages.xmo.tactics.socialAdsRetargeting.strategy')}</Typography>
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <IQFormSelect
                            theme={IQTheme}
                            schema={schema}
                            required={true}
                            labelText={t('pages.xmo.tactics.socialAdsRetargeting.callToAction')}
                            placeholder={t('pages.xmo.tactics.socialAdsRetargeting.callToActionSelectLabel')}
                            id="callToAction"
                            name="callToAction"
                            items={callToActionDropdownItems}
                            value={prodRetargeting?.callToAction || ''}
                            fontLabelWeight="600"
                            {...register('callToAction', {
                              validate: (value) => schemaValidate(value, 'callToAction', schema),
                            })}
                            fullWidth
                        />
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <Typography
                            sx={{fontWeight: 'bold'}}>{t('pages.xmo.tactics.socialAdsRetargeting.allLeadAdsFormContactFieldsLabel')}
                        </Typography>
                        {selectAllLeadAdsFormContactFieldsCheckboxes.map((opt) => (
                            <Grid key={opt.label} item xs={12}>
                              <FormControlLabel
                                  control={
                                    <Checkbox
                                        id={`selectAllLeadAdsForm_${opt.label}`}
                                        name={`selectAllLeadAdsForm_${opt.label}`}
                                        checked={leadAdsFormCheckboxes.includes(opt.label)}
                                        onChange={() => handleSelectAllLeadAdsFormChange(opt.label)}
                                        color="primary"
                                        inputProps={{'aria-label': opt.label}}
                                    />
                                  }
                                  label={opt.label}
                              />
                            </Grid>
                        ))}
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <Typography
                            sx={{fontWeight: 'bold'}}>{t('pages.xmo.tactics.socialAdsRetargeting.automaticOrOptimizedPlacement')}
                          <RadioGroup
                              id="automaticOrOptimizedPlacementOnAds"
                              name="automaticOrOptimizedPlacementOnAds"
                              defaultValue={prodRetargeting?.automaticOrOptimizedPlacementOnAds || ''}
                              onChange={(event) => setIsPlacementOnAds(event.target.value)}
                              row
                          >
                            {yesNoItems.map(opt => (
                                <FormControlLabel
                                    key={opt.label}
                                    control={<Radio value={opt.label}/>}
                                    label={opt.label}
                                    {...register('automaticOrOptimizedPlacementOnAds', {
                                      validate: (value) => schemaValidate(value, 'automaticOrOptimizedPlacementOnAds', schema),
                                    })}
                                />
                            ))}
                          </RadioGroup>
                        </Typography>
                      </GridItem>
                      {isPlacementOnAds === 'No' && (
                          <GridItem sizes={[12]}>
                            <Typography
                                sx={{fontWeight: 'bold'}}>{t('pages.xmo.tactics.socialAdsRetargeting.whereDoesTheClientWantTheirAdsToAppearLabel')}
                            </Typography>
                            {clientWantTheirAdsToAppearCheckboxes.map((opt) => (
                                <Grid key={opt.label} item xs={12}>
                                  <FormControlLabel control={
                                    <Checkbox
                                        id={`whereClientWantsAds_${opt.label}`}
                                        name={`whereClientWantsAds_${opt.label}`}
                                        checked={whereClientWantsAdsCheckboxes.includes(opt.label)}
                                        onChange={() => handleWhereClientWantsAdsChange(opt.label)}
                                        color="primary"
                                        inputProps={{'aria-label': opt.label}}
                                    />
                                  } label={
                                    <>
                                      {opt.label}
                                      {opt.label === 'MarketPlace' && (
                                          <Tooltip
                                              title={t('pages.xmo.tactics.socialAdsRetargeting.mobileNewsFeedTooltip')}
                                              placement="top">
                                            <InfoOutlinedIcon color="primary" sx={{
                                              marginLeft: 1,
                                              fontSize: '15px'
                                            }}/>
                                          </Tooltip>
                                      )}
                                    </>
                                  }
                                  />
                                </Grid>
                            ))}
                          </GridItem>
                      )}
                      <GridItem sizes={[12]}>
                        <Typography
                            sx={{fontWeight: 'bold'}}>{t('pages.xmo.tactics.socialAdsRetargeting.clientWantEnableWebsiteRetargetingLabel')}
                          <RadioGroup
                              id="clientWantEnableWebsiteRetargeting"
                              name="clientWantEnableWebsiteRetargeting"
                              defaultValue={prodRetargeting?.clientWantEnableWebsiteRetargeting || ''}
                              onChange={(event) => setClientWantEnableWebsiteRetargeting(event.target.value)}
                              row
                          >
                            {retargetingItems.map(opt => (
                                <FormControlLabel
                                    key={opt.label}
                                    control={<Radio value={opt.label}/>}
                                    label={opt.label}
                                    {...register('clientWantEnableWebsiteRetargeting', {
                                      validate: (value) => schemaValidate(value, 'clientWantEnableWebsiteRetargeting', schema),
                                    })}
                                />
                            ))}
                          </RadioGroup>
                        </Typography>
                      </GridItem>
                      {isClientWantEnableWebsiteRetargeting === 'Select Pages' && (
                          <GridItem sizes={[12]}>
                            <IQFormInput
                                schema={schema}
                                theme={IQTheme}
                                fontLabelWeight="600"
                                id="selectPageUrl"
                                name="selectPageUrl"
                                fullWidth
                                showError
                                labelText={t('pages.xmo.tactics.socialAdsRetargeting.selectPageURLLabel')}
                                subDescription={t('pages.xmo.tactics.socialAdsRetargeting.selectPageURLHelperLabel')}
                                adornmentIcon={<InsertLinkIcon/>}
                            />
                          </GridItem>)}
                      <GridItem sizes={[12]}>
                        <Typography sx={{fontWeight: 'bold'}}>
                          {t('pages.xmo.tactics.socialAdsRetargeting.enableTargetingPeopleTitle')}
                          <Tooltip title={t('pages.xmo.tactics.socialAdsRetargeting.enableTargetingPeopleVisitedWebsiteTooltipLabel')} placement="top">
                            <HelpOutlineIcon color="primary" sx={{marginLeft: 1, fontSize: '15px'}}/>
                          </Tooltip>
                        </Typography>
                        <RadioGroup
                          id="enableTargetingPeopleVisitedWebsite"
                          name="enableTargetingPeopleVisitedWebsite"
                          defaultValue={prodRetargeting?.enableTargetingPeopleVisitedWebsite || ''}
                          row
                          sx={{marginTop: '-20px'}}
                        >
                          {yesLookaLikesItems.map(opt => (
                              <FormControlLabel
                                  key={opt.label}
                                  control={<Radio value={opt.label}/>}
                                  label={opt.label}
                                  {...register('enableTargetingPeopleVisitedWebsite', {
                                    validate: (value) => schemaValidate(value, 'enableTargetingPeopleVisitedWebsite', schema),
                                  })}
                              />
                          ))}
                        </RadioGroup>

                      </GridItem>
                      <GridItem sizes={[12]}>
                        <IQFormSelect
                            theme={IQTheme}
                            schema={schema}
                            required={true}
                            labelText={t('pages.xmo.tactics.socialAdsRetargeting.numberOfDaysRetargetFacebookVisitorsLabel')}
                            placeholder={t('pages.xmo.tactics.socialAdsRetargeting.numberOfDaysRetargetFacebookVisitorsSelectLabel')}
                            id="numberOfDaysRetargetFacebookVisitors"
                            name="numberOfDaysRetargetFacebookVisitors"
                            items={daysRetargetFacebookVisitorsDropdownItems}
                            value={prodRetargeting?.numberOfDaysRetargetFacebookVisitors || ''}
                            fontLabelWeight="600"
                            {...register('numberOfDaysRetargetFacebookVisitors', {
                              validate: (value) => schemaValidate(value, 'numberOfDaysRetargetFacebookVisitors', schema),
                            })}
                            fullWidth
                        />
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            schema={schema}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            id="websiteRetargetingOptOutUrl"
                            name="websiteRetargetingOptOutUrl"
                            fullWidth
                            labelText={t('pages.xmo.tactics.socialAdsRetargeting.websiteRetargetingOptOutUrlLabel')}
                            subDescription={t('pages.xmo.tactics.socialAdsRetargeting.selectPageURLHelperLabel')}
                            adornmentIcon={<InsertLinkIcon/>}
                        />
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <Typography sx={{fontWeight: 'bold'}}>
                          {t('pages.xmo.tactics.socialAdsRetargeting.enableTargetingPeopleLikedFacebookPageTitle')}
                          <Tooltip title={t('pages.xmo.tactics.socialAdsRetargeting.enableTargetingPeopleVisitedWebsiteTooltipLabel')} placement="top">
                            <HelpOutlineIcon color="primary" sx={{marginLeft: 1, fontSize: '15px'}}/>
                          </Tooltip>
                          <RadioGroup
                              id="enableTargetingPeopleSimilarToWhoLikedFacebook"
                              name="enableTargetingPeopleSimilarToWhoLikedFacebook"
                              defaultValue={prodRetargeting?.enableTargetingPeopleSimilarToWhoLikedFacebook || ''}
                              row
                          >
                            {yesLookaLikesItems.map(opt => (
                                <FormControlLabel
                                    key={opt.label}
                                    control={<Radio value={opt.label}/>}
                                    label={opt.label}
                                    {...register('enableTargetingPeopleSimilarToWhoLikedFacebook', {
                                      validate: (value) => schemaValidate(value, 'enableTargetingPeopleSimilarToWhoLikedFacebook', schema),
                                    })}
                                />
                            ))}
                          </RadioGroup>
                        </Typography>
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <Typography
                            sx={{fontWeight: 'bold'}}>{t('pages.xmo.tactics.socialAdsRetargeting.searchCampaignFacebookRetargetingTitle')}
                          <RadioGroup
                              id="wantToRetargetSearchCampaignLeadsOnFacebook"
                              name="wantToRetargetSearchCampaignLeadsOnFacebook"
                              defaultValue={prodRetargeting?.wantToRetargetSearchCampaignLeadsOnFacebook || ''}
                              onChange={(event) => setIsRetargetSearchCampaignOnFB(event.target.value)}
                              row
                          >
                            {yesNoItems.map(opt => (
                                <FormControlLabel
                                    key={opt.label}
                                    control={<Radio value={opt.label}/>}
                                    label={opt.label}
                                    {...register('wantToRetargetSearchCampaignLeadsOnFacebook', {
                                      validate: (value) => schemaValidate(value, 'wantToRetargetSearchCampaignLeadsOnFacebook', schema),
                                    })}
                                />
                            ))}
                          </RadioGroup>
                        </Typography>
                      </GridItem>
                      {isRetargetSearchCampaignOnFB === 'Yes' && (<GridItem sizes={[12]}>
                        <IQFormSelect
                            theme={IQTheme}
                            schema={schema}
                            required={true}
                            labelText={t('pages.xmo.tactics.socialAdsRetargeting.numberOfDaysToRetargetLabel')}
                            placeholder={t('pages.xmo.tactics.socialAdsRetargeting.numberOfDaysRetargetFacebookVisitorsSelectLabel')}
                            id="numberOfDaysToRetarget"
                            name="numberOfDaysToRetarget"
                            items={daysRetargetFacebookVisitorsDropdownItems}
                            value={prodRetargeting?.numberOfDaysToRetarget || ''}
                            onChange={(event) => setIsNumberOfDaysToRetarget(event.target.value)}
                            fontLabelWeight="600"
                            fullWidth
                        />
                      </GridItem>)}
                      <GridItem sizes={[12]}>
                        <Typography
                            variant="h6">{t('pages.xmo.tactics.socialAdsRetargeting.contentCreativeTitle')}</Typography>
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <Typography sx={{fontWeight: 'bold'}}>
                          {t('pages.xmo.tactics.socialAdsRetargeting.whatTypeOfVisualStrategyDoesYourClientWantToEmployLabel')}
                          <RadioGroup
                              id="typeOfVisualStrategyClientWantToEmploy"
                              name="typeOfVisualStrategyClientWantToEmploy"
                              defaultValue={prodRetargeting?.typeOfVisualStrategyClientWantToEmploy || ''}
                          >
                            {imageVideoItems.map(opt => (
                                <FormControlLabel
                                    key={opt.label}
                                    control={<Radio value={opt.label}/>}
                                    label={
                                      <React.Fragment>
                                        {opt.label}
                                        {(opt.label === 'Single Video' || opt.label === 'Multiple Videos') && (
                                            <Tooltip
                                                title={t('pages.xmo.tactics.socialAdsRetargeting.videoTooltip')}
                                                placement="top">
                                              <InfoOutlinedIcon color="primary"
                                                                sx={{
                                                                  marginLeft: 1,
                                                                  fontSize: '15px'
                                                                }}/>
                                            </Tooltip>
                                        )}
                                      </React.Fragment>
                                    }
                                    {...register('typeOfVisualStrategyClientWantToEmploy', {
                                      validate: (value) => schemaValidate(value, 'typeOfVisualStrategyClientWantToEmploy', schema),
                                    })}
                                />
                            ))}
                          </RadioGroup>
                        </Typography>
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <Typography sx={{fontWeight: 'bold'}}>
                          {t('pages.xmo.tactics.socialAdsRetargeting.willTheClientBeProvidingImagesToUseLabel')}
                          <RadioGroup
                              id="willClientBeProvidingImagesToUse"
                              name="willClientBeProvidingImagesToUse"
                              defaultValue={prodRetargeting?.willClientBeProvidingImagesToUse || ''}
                              row
                          >
                            {yesAllItems.map(opt => (
                                <FormControlLabel
                                    key={opt.label}
                                    control={<Radio value={opt.label}/>}
                                    label={opt.label}
                                    {...register('willClientBeProvidingImagesToUse', {
                                      validate: (value) => schemaValidate(value, 'willClientBeProvidingImagesToUse', schema),
                                    })}
                                />
                            ))}
                          </RadioGroup>
                        </Typography>
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <Typography sx={{fontWeight: 'bold'}}>
                          {t('pages.xmo.tactics.socialAdsRetargeting.willThisCampaignUtilizeCarouselFormatLabel')}
                          <Tooltip
                            title={t('pages.xmo.tactics.socialAdsRetargeting.willThisCampaignUtilizeCarouselFormatTooltip')}
                            placement="top">
                            <HelpOutlineIcon color="primary" sx={{marginLeft: 1, fontSize: '15px'}}/>
                          </Tooltip>
                          <RadioGroup
                              id="willThisCampaignUtilizeCarouselFormat"
                              name="willThisCampaignUtilizeCarouselFormat"
                              defaultValue={prodRetargeting?.willThisCampaignUtilizeCarouselFormat || ''}
                              onChange={(event) => setIsCarousel(event.target.value)}
                              row
                          >
                            {yesNoItems.map(opt => (
                                <FormControlLabel
                                    key={opt.label}
                                    control={<Radio value={opt.label}/>}
                                    label={opt.label}
                                    {...register('willThisCampaignUtilizeCarouselFormat', {
                                      validate: (value) => schemaValidate(value, 'willThisCampaignUtilizeCarouselFormat', schema),
                                    })}
                                />
                            ))}
                          </RadioGroup>
                        </Typography>

                      </GridItem>
                      <GridItem sizes={[12]}>
                        <Typography
                            sx={{fontWeight: 'bold'}}>{t('pages.xmo.tactics.socialAdsRetargeting.willTheClientBeProvidingTheirOwnTextCreativeLabel')}
                          <RadioGroup
                              id="willClientBeProvidingTheirOwnTextCreative"
                              name="willClientBeProvidingTheirOwnTextCreative"
                              defaultValue={prodRetargeting?.willClientBeProvidingTheirOwnTextCreative || ''}
                              onChange={(event) => setClientProvidingTextCreative(event.target.value)}
                              row
                          >
                            {yesNoItems.map(opt => (
                                <FormControlLabel
                                    key={opt.label}
                                    control={<Radio value={opt.label}/>}
                                    label={opt.label}
                                    {...register('willClientBeProvidingTheirOwnTextCreative', {
                                      validate: (value) => schemaValidate(value, 'willClientBeProvidingTheirOwnTextCreative', schema),
                                    })}
                                />
                            ))}
                          </RadioGroup>
                        </Typography>
                      </GridItem>
                      {isCarousel !== undefined && isClientProvidingTextCreative === 'Yes' && (
                          <>
                            <GridItem sizes={[12]}>
                              <IQFormInput
                                  id="adHeadLine"
                                  name="adHeadLine"
                                  labelText={t('pages.xmo.tactics.socialAdsRetargeting.adHeadlineLabel')}
                                  theme={IQTheme}
                                  fontLabelWeight="600"
                                  fullWidth
                                  schema={schema}
                                  maxLength={isCarousel === 'No' ? 75 : 60}
                                  subDescription={isCarousel === 'No' ? `${watch('adHeadLine').length}/75` : `${watch('adHeadLine').length}/60`}
                              />
                            </GridItem>
                            {adHeadlineValueError &&
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                          color: '#C20F1E',
                                          fontSize: '14px',
                                          marginTop: '-15px',
                                          marginBottom: '15px',
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                        variant="subtitle1"
                                    >
                                      <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <ErrorIcon
                                            sx={{
                                              color: '#C20F1E',
                                              fontSize: '16px',
                                              marginRight: '5px'
                                            }}
                                        />
                                        {t('pages.xmo.tactics.socialAdsRetargeting.maximumCharacterLimitExceedError')}
                                      </Box>
                                    </Typography>
                                  </Grid>
                                </Grid>
                            }
                            <GridItem sizes={[12]}>
                              <IQFormInput
                                  id="adBodyText"
                                  name="adBodyText"
                                  labelText={t('pages.xmo.tactics.socialAdsRetargeting.adBodyTextLabel')}
                                  theme={IQTheme}
                                  fontLabelWeight="600"
                                  fullWidth
                                  schema={schema}
                                  maxLength={255}
                                  subDescription={`${watch('adBodyText').length}/255`}
                              />
                            </GridItem>
                            <GridItem sizes={[12]}>
                              <IQFormInput
                                  id="newsFeedLinkDescription"
                                  name="newsFeedLinkDescription"
                                  labelText={t('pages.xmo.tactics.socialAdsRetargeting.newsfeedLinkDescriptionLabel')}
                                  theme={IQTheme}
                                  fontLabelWeight="600"
                                  fullWidth
                                  schema={schema}
                                  maxLength={isCarousel === 'No' ? 250 : 60}
                                  subDescription={isCarousel === 'No' ? `${watch('newsFeedLinkDescription').length}/250` : `${watch('newsFeedLinkDescription').length}/60`}
                              />
                            </GridItem>
                            {newsfeedLinkDescriptionValueError &&
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                          color: '#C20F1E',
                                          fontSize: '14px',
                                          marginTop: '-15px',
                                          marginBottom: '15px',
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                        variant="subtitle1"
                                    >
                                      <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <ErrorIcon
                                            sx={{
                                              color: '#C20F1E',
                                              fontSize: '16px',
                                              marginRight: '5px'
                                            }}
                                        />
                                        {t('pages.xmo.tactics.socialAdsRetargeting.maximumCharacterLimitExceedError')}
                                      </Box>
                                    </Typography>
                                  </Grid>
                                </Grid>
                            }
                          </>
                      )}
                      {isCampaignGoal === "Contacts Ads - Dynamics Ads (Auto Only)" && (
                          <>
                            <GridItem sizes={[12]}>
                              <Typography sx={{fontWeight: 'bold'}}>
                                {t('pages.xmo.tactics.socialAdsRetargeting.inventoryToFeatureLabel')}
                                <RadioGroup
                                  id="inventoryToFeatureInCampaign"
                                  name="inventoryToFeatureInCampaign"
                                  defaultValue={prodRetargeting?.inventoryToFeatureInCampaign}
                                  row
                                >
                                  {inventoryItems.map(opt => (
                                      <FormControlLabel
                                          key={opt.label}
                                          control={<Radio value={opt.label}/>}
                                          label={opt.label}
                                          {...register('inventoryToFeatureInCampaign', {
                                            validate: (value) => schemaValidate(value, 'inventoryToFeatureInCampaign', schema),
                                          })}
                                      />
                                  ))}
                                </RadioGroup>
                              </Typography>

                              <Typography variant="caption">
                                {t('pages.xmo.tactics.socialAdsRetargeting.inventoryToFeatureHelperLabel')}
                              </Typography>
                            </GridItem>
                          </>
                      )}
                      <GridItem sizes={[12]}>
                        <NeSelectChip
                            schema={schema}
                            theme={IQThemeV2}
                            freeSolo
                            label
                            labelText={t('pages.xmo.tactics.socialAdsRetargeting.productsOrServicesOrOffersToPromoteInCampaignLabel')}
                            items={[]}
                            name="productsOrServicesOrOffersToPromoteInCampaign"
                            values={prodRetargeting?.productsOrServicesOrOffersToPromoteInCampaign}
                            maxItems={4}
                            hideLimitHelperText
                        />
                      </GridItem>
                    </>
                )}
              </GridContainer>
              <CoNavigationConfirm
                  showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
              />
            </Box>
          </FormProvider>
        </ValidationProvider>
      </DefaultPageLayout>
  );
}
