import {
  IQAddressVerificationModal,
} from '@gannettdigital/shared-react-components';
import { useEffect, useState } from 'react';

interface Props {
  openModal: boolean;
  setOpenModal: (data) => any;
  onFormSubmit: any;
  formData: any;
  suggestedAddresses: any[];
}

const emptyAddressObj = {
  id: '',
  name: '',
  address: '',
  place: '',
  region: '',
  postalCode: '',
  country: '',
  relevance: null,
  matchingText: '',
  matchingPlaceName: '',
  centerPoint: [],
  includeNameInSuggestion: null,
};

export default function VerificationModal(props: Props) {
  const {
    openModal, setOpenModal, onFormSubmit, formData, suggestedAddresses,
  } = props;

  const emptyVerificationAddressesObj = { entered: emptyAddressObj, suggested: emptyAddressObj };
  const [verificationAddresses, setVerificationAddresses] = useState(emptyVerificationAddressesObj);

  const setVerificationModalData = () => {
    const enteredAddress = {
      id: '',
      name: formData.businessAddressOne,
      address: formData.businessAddressOne,
      place: formData.city,
      region: formData.stateProvince,
      postalCode: formData.postalCode,
      country: formData.country,
      relevance: null,
      matchingText: '',
      matchingPlaceName: '',
      centerPoint: [],
      includeNameInSuggestion: null,
    };

    const verificationAddressesObj = {
      entered: enteredAddress,
      suggested: suggestedAddresses?.length > 0 ? suggestedAddresses[0] : emptyAddressObj,
    };

    setVerificationAddresses({ ...verificationAddressesObj });
  };

  useEffect(() => {
    setVerificationModalData();
  }, [suggestedAddresses]);

  const onVerificationSubmit = (selected) => {
    if (selected) {
      const selectedAddress = suggestedAddresses.filter(e => e.id === selected);

      const {
        address, place, region, country, postalCode,
      } = selectedAddress[0];

      const updatedFormData = {
        ...formData,
        ...{
          businessAddressOne: address,
          city: place,
          stateProvince: region,
          postalCode,
          country: country === 'USA' ? 'US' : country,
        },
      };

      onFormSubmit({ addressData: updatedFormData });
    } else onFormSubmit({ addressData: formData });
  };

  return (
    <IQAddressVerificationModal
      open={openModal}
      handleClose={() => setOpenModal(false)}
      onSubmit={selected => onVerificationSubmit(selected)}
      onEdit={() => setOpenModal(false)}
      addresses={verificationAddresses}
    />
  );
}
