import i18n from 'i18n/i18n';
import {
  SocialAdsWithSmartOptimizationTechnologyChildValidate,
  SocialAdsWithSmartOptimizationTechnologyChildSchema,
  SocialAdsWithSmartOptimizationTechnologyChildUISchema,
} from 'pages/generic-product/details/product-schema/SocialAdsWithSmartOptimizationTechnologyChildSchema';
import {
  ImageryVideoValidate,
  ImageryVideoSchema,
  ImageryVideoUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/smart-optimization-schema/ImageryVideoSchema';
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema,
} from 'pages/generic-product/details/product-schema/social-ads-schema/NotesAndInstructionsSchema';

const { t } = i18n;

export const SocialAdsWithSmartOptimizationTechnologySchema = (data) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.smartOptimizationTechnology.pageTitle'),
      schema: SocialAdsWithSmartOptimizationTechnologyChildSchema(data),
      uiSchema: SocialAdsWithSmartOptimizationTechnologyChildUISchema(data),
      customValidate: SocialAdsWithSmartOptimizationTechnologyChildValidate,
    },
    {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ];
  if (data.isOrange) {
    const indexToInsert = 1;
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.socialAds.solutionStrategy.imageryVideo.title'),
      schema: ImageryVideoSchema(data),
      uiSchema: ImageryVideoUISchema,
      customValidate: ImageryVideoValidate,
    });
  }
  return schema;
};

export const SocialAdsWithSmartOptimizationTechnologyUISchema = (data) => ({
  'ui:order': [
    ...SocialAdsWithSmartOptimizationTechnologyChildUISchema(data)['ui:order'],
    ...ImageryVideoUISchema['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SocialAdsWithSmartOptimizationTechnologyValidate = (formData: any, errors: any, uiSchema: any) => errors;
