import * as yup from 'yup';

const schema = {
  ageGateContentRelated: yup.string().required(),
  ageGatePaidAdvertising: yup.string().required(),
  ageGateAcknowledgment: yup.boolean().nullable().required()
    .oneOf([true], 'Please check acknowledgement to continue.'),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
