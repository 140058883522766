import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './redux/store-schedule';

export interface webEventsState {
  webEvent: any;
  webEventFlag: boolean;
}

const initialState: webEventsState = {
  webEvent: [],
  webEventFlag: false
};

const addWebEventSlice = createSlice({
  name: 'webEvents',
  initialState,
  reducers: {
    saveWebEvent(state, action: PayloadAction<any>) {
      state.webEvent = action.payload;
      state.webEventFlag = action.payload;
    },
  },
});

export const {saveWebEvent} = addWebEventSlice.actions;

export const selectWebEvent = (state: RootState) => state.webEvents.webEvent;

export default addWebEventSlice.reducer;
