import i18n from 'i18n/i18n';
import * as yup from 'yup';

const { t } = i18n;

const schema = {
  locationName: yup.string().required(t('pages.premiumListings.location.address.locationNameRequired')),
  businessAddressOne: yup.string().required(t('pages.premiumListings.location.address.addressLine1Required')),
  businessAddressTwo: yup.string().notRequired().nullable(),
  city: yup.string().required(t('pages.premiumListings.location.address.cityRequired')),
  stateProvince: yup.string().required(t('pages.premiumListings.location.address.stateRequired')),
  postalCode: yup.string().required(t('pages.premiumListings.location.address.zipCodeRequired')),
  country: yup.string().required(t('pages.premiumListings.location.address.countryRequired')),
  doesNotServeClients: yup.boolean().notRequired().nullable(),
  serviceAreas: yup.array().notRequired()
    .when('doesNotServeClients', (doesNotServeClients, schema) => {
      if (doesNotServeClients) {
        return schema.min(1, t('pages.premiumListings.location.address.serviceAreaRequired'));
      }

      return schema;
    })
    .max(20, t('pages.premiumListings.location.address.serviceAreaMax')),
  locationIdentifier: yup.string().notRequired().nullable(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
