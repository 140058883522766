import React, { Fragment, useState } from 'react';
import styled from '@emotion/styled';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useController } from 'react-hook-form';
import DescriptionIcon from '@mui/icons-material/Description';
import { IQExpandableTextArea } from '@gannettdigital/shared-react-components';

export interface IQFileStockProps {
  id?: number,
  tag?: string,
  mediaId?: string,
  mediaType?: string,
  fileName?: string,
  fileSize?: number,
  url: string,
  sourceId?: string,
  createdAt?: number,
  updatedAt?: number,
  callToAction?: boolean,
  featured?: boolean,
  hero?: boolean,
  other?: boolean,
  notes?: string,
  additionalDetails?: string,
}

type CheckboxProps = {
  label: string;
  name: string;
  render: boolean;
}[];

type ConditionalCheckboxProps = {
  showCheckboxes?: true;
  checkboxList?: CheckboxProps;
} | {
  showCheckboxes?: false;
  checkboxList?: never;
};

type ConditionalRadioButtonProps = {
  showRadioButtons?: boolean;
  radioButtonList?: CheckboxProps;
  radioGroupName?: string;
  radioGroupDefaultValue?: string;
};

export interface IQStockListDefaultProps {
  files: IQFileStockProps[];
  onDelete?: (file: IQFileStockProps) => void;
  name: string;
  showComments?: boolean;
  returnRadioValue?: (file: IQFileStockProps, value: string) => void;
  [rest: string]: any;
}

type IQStockListProps = ConditionalCheckboxProps &
ConditionalRadioButtonProps &
IQStockListDefaultProps;

const DeleteOutlineIconStyled = styled(DeleteOutlineIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '22px',
  paddingRight: '0',
}));

const DeleteButton = styled('button')`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

const ImageStyled = styled('img')`
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 4px;
  max-width: 100px;
  max-height: 64px;
`;

const VideoStyled = styled('video')`
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 4px;
  max-width: 100px;
  max-height: 64px;
`;

const ListStyled = styled(List)(({ theme }) => ({
  padding: '0',
  '& .MuiListItem-root': {
    justifyContent: 'space-between',
    background: theme.palette.common.white,
    padding: '8px',
  },
  '& .MuiListItemText-root': {
    margin: '0',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '& .MuiListItemText-primary': {
    display: 'inline-block',
    width: '350px',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
  },
  '& .MuiAccordionSummary-root': {
    backgroundColor: 'transparent',
    padding: '0',
    minHeight: '30px',
  },
  '& .MuiAccordionDetails-root': {
    borderTop: '0',
    padding: '0',
  },
  '& .MuiChip-root': {
    // @ts-ignore
    backgroundColor: theme.palette.text.light,
    textTransform: 'uppercase',
    fontSize: '12px',
    maxHeight: '17px',
    padding: '0 1px',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

const IconContainer = styled('span')`
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.palette.secondary.light};
  border-radius: 50%;

  svg{
    font-size: 2rem;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const FileSizeGrid = styled(Grid)`
  font-size: 0.75rem;
  font-family: 'Unify Sans';
  text-align: right;
  color: ${(props) => props.theme.palette.text.secondary};
`;

function getFileName(file: IQFileStockProps) {
  if (file.fileName) return file.fileName;
  /* We have the file name in the url, since we didn't save just the
      we'll ge the name from the url */
  const { url } = file;
  const splittedUrl = url ? url.split('/') : [''];
  return splittedUrl[splittedUrl.length - 1];
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

const checkBoxes = [
  { label: 'CTA', name: 'callToAction', render: true },
  { label: 'Feature', name: 'featured', render: true },
  { label: 'Hero', name: 'hero', render: true },
  { label: 'Other', name: 'other', render: true },
];

const radioButtons = [
  { label: 'Exterior', name: 'exterior', render: true },
  { label: 'Interior', name: 'interior', render: true },
  { label: 'Additional', name: 'additional', render: true },
];

const RenderCheckbox = (
  {
    id, label, name, checked, handleChange,
  }:
  {
    id: string, label: string, name: string, checked: any, handleChange: any
  },
) => (
  <FormControlLabel
    control={(
      <Checkbox
        key={id}
        id={id}
        name={name}
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    )}
    label={label}
  />

);

const RenderRadioButton = (
  {
    id, label, name, checked, handleChange,
  }:
  {
    id: string, label: string, name: string, checked: any,
    handleChange: any,
  },
) => (
  <FormControlLabel
    control={(
      <Radio
        key={id}
        id={id}
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        value={name}
      />
    )}
    label={label}
  />

);

const playVideo = (e) => {
  e.target?.play();
};

const stopVideo = (e) => {
  e.target?.pause();
};

const renderPreview = (file) => {
  if (file.mediaType === 'images') {
    return (
      <Grid container>
        <Grid item xs={12}>
          <ImageStyled src={file.url} alt={file.url} />
        </Grid>
        {file.tag && (
        <Grid item xs={12}>
          <Chip label={file.tag} size="small" color="primary" />
        </Grid>
        )}
      </Grid>
    );
  } if (file.mediaType === 'videos') {
    return (
      <Grid container>
        <Grid item xs={12}>
          <VideoStyled
            muted
            loop
            src={file.url}
            onMouseEnter={playVideo}
            onMouseOut={stopVideo}
          />
        </Grid>
        {file.tag && (
          <Grid item xs={12}>
            <Chip label={file.tag} size="small" color="primary" />
          </Grid>
        )}
      </Grid>
    );
  }
  return (
    <IconContainer>
      <DescriptionIcon />
    </IconContainer>
  );
};

export default function NeStockList({
  showComments = true, showCheckboxes = true, checkboxList = checkBoxes,
  showRadioButtons = false, radioButtonList = radioButtons, radioGroupName, radioGroupDefaultValue,
  returnRadioValue,
  ...props
}: IQStockListProps) {
  const {
    files, onDelete, name,
  } = props;
  const [selections] = useState(files.map((file) => ({ ...file })) || []);
  // const { formState: { errors }, control } = useFormContext();
  const {
    field,
  } = useController({
    name,
    /* control,
    rules: {
      validate: (value) => schemaValidate(value, name, schema, null, null),
    }, */
    defaultValue: selections,
  });

  const onCheck = (file) => (event) => {
    const { checked, name: targetName } = event.target;
    // eslint-disable-next-line max-len
    const index = selections.findIndex((sel) => (file.id && sel.id === file.id) || sel.url === file.url);
    if (index >= 0) selections[index][targetName] = checked;
    if (field?.onChange) field.onChange(selections);
  };

  const onRadioCheck = (file) => (event) => {
    returnRadioValue(file, event.target.value);
    const { name: targetName } = event.target;
    // eslint-disable-next-line max-len
    const index = selections.findIndex((sel) => (file.id && sel.id === file.id) || sel.url === file.url);
    if (index >= 0) selections[index][radioGroupName] = targetName;
    if (field?.onChange) field.onChange(selections);
  };

  const onComment = (file) => (event) => {
    const { value } = event.target;
    // eslint-disable-next-line max-len
    const index = selections.findIndex((sel) => (file.id && sel.id === file.id) || sel.url === file.url);
    if (index >= 0) selections[index].notes = value;
    if (field?.onChange) field.onChange(selections);
  };

  const renderCheckboxes = (file :IQFileStockProps) => checkboxList.map((checkbox) => {
    if (checkbox.render) {
      return (
        <RenderCheckbox
          id={`checkbox-${checkbox}-${file.fileName}`}
          name={checkbox.name}
          label={checkbox.label}
          checked={file?.[checkbox.name]}
          handleChange={onCheck(file)}
        />
      );
    }
    return null;
  });

  const getDefaultValue = (value) => {
    if (value) {
      const parseString = JSON.parse(value);
      return Object.values(parseString)[0];
    }
    return radioGroupDefaultValue;
  };

  const renderRadioButtons = (file :IQFileStockProps) => (
    <RadioGroup name={radioGroupName} row
      defaultValue={getDefaultValue(file?.additionalDetails)}
    >
      {radioButtonList.map((radio) => {
        if (radio.render) {
          return (
            <RenderRadioButton
              key={`radio-${radio.name}-${file.fileName}`}
              id={`radio-${radio}-${file.fileName}`}
              name={radio.name}
              label={radio.label}
              checked={file?.[radio.name]}
              handleChange={onRadioCheck(file)}
            />
          );
        }
        return null;
      })}
    </RadioGroup>
  );

  return (
    <div>
      <ListStyled>
        {selections.map((file, index) => (
          <Fragment key={file.url}>
            <ListItem>
              <Grid container>
                <Grid item width="108px">
                  {renderPreview(file)}
                </Grid>
                <Grid item xs container>
                  <Grid item xs={9}>
                    <ListItemText primary={getFileName(file)} />
                  </Grid>
                  <FileSizeGrid item xs={2}>
                    {/* eslint-disable-next-line max-len */}
                    {(file.fileSize && formatBytes(file.fileSize)) || (file.mediaId && file.mediaId)}
                  </FileSizeGrid>
                  <Grid item xs={1} textAlign="right">
                    <DeleteButton type="button" onClick={() => onDelete(file)}>
                      <DeleteOutlineIconStyled />
                    </DeleteButton>
                  </Grid>
                  {showCheckboxes
                    && (
                    <Grid item xs={12}>
                      {renderCheckboxes(file)}
                    </Grid>
                    )}
                  {showRadioButtons
                    && (
                    <Grid item xs={12}>
                      {renderRadioButtons(file)}
                    </Grid>
                    )}
                  {showComments
                  && (
                  <Grid item xs={12}>
                    <IQExpandableTextArea
                      label="Comments"
                      name="Comments"
                      id={`comments-${file.fileName}`}
                      value={file.notes}
                      defaultOpen={file.notes?.length > 0}
                      onChange={onComment(file)}
                      width="100%"
                    />
                  </Grid>
                  )}
                </Grid>
              </Grid>
            </ListItem>
            {index !== files.length && <Divider />}
          </Fragment>
        ))}
      </ListStyled>
    </div>
  );
}
