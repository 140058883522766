import { ComponentProps, FC, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Grid, styled } from '@mui/material';
import {
  ValidationProvider,
  IQButtonRadioContainer,
  IQTheme,
  IQFormTextArea,
} from '@gannettdigital/shared-react-components';
import { useTranslation } from 'react-i18next';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import GridContainer from 'components/grid/GridContainer';
import schema from './OrderType.schema';

export type OrderTypeFormProps = {
  orderType: string;
  existingLandingPageUrls?: string;
};

interface IProps {
  product: any;
  onSubmit: (data: OrderTypeFormProps) => void;
  onBack: () => void;
}

const BoxStyled = styled(Box)(({ theme }) => ({
  '.MuiFormControl-root.MuiFormControl-fullWidth label': {
    paddingBottom: theme.spacing(1.5),
  },
  hr: {
    marginTop: `${theme.spacing(6)} !important`,
  },
}));

const GridStyled = styled(Grid)(({ theme }) => ({
  '.MuiToggleButton-root.Mui-selected': {
    backgroundColor: 'transparent',
  },
  '.MuiToggleButton-root.Mui-selected:hover': {
    backgroundColor: 'transparent',
  },
  '& > div > button': {
    minWidth: 'max-content',
    maxWidth: 'fit-content',
  },
  '& > div > button:first-child': {
    paddingLeft: 0,
  },
  '& > label': {
    mb: theme.spacing(2),
  },
  '& > .MuiToggleButtonGroup-root > *': {
    backgroundColor: 'transparent',
  },
  '& > .MuiToggleButtonGroup-root > *:hover': {
    backgroundColor: 'transparent',
  },
  '.MuiTouchRipple-root > *': {
    display: 'none',
  },
  marginBottom: theme.spacing(2),
}));

const OrderTypeForm: FC<IProps> = ({ onBack, onSubmit, product }) => {
  const { t } = useTranslation();

  const optionsList: Pick<ComponentProps<typeof IQButtonRadioContainer>, 'optionsList'> = useMemo(() => ({
    optionsList: [
      { value: 'new', label: t('pages.landing.orderType.new'), field: 'new' },
      { value: 'addOn', label: t('pages.landing.orderType.addOn'), field: 'addOn' },
    ],
  }), []);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      orderType: product?.orderType || 'new',
      existingLandingPageUrls: product?.existingLandingPageUrls || '',
    },
  });
  const {
    handleSubmit,
    watch,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful,
    },
  } = methods;

  const orderType = watch('orderType');

  const verifyFields = (data: OrderTypeFormProps): OrderTypeFormProps => {
    if (data.orderType === 'new') {
      return {
        ...data,
        existingLandingPageUrls: '',
      };
    }

    return data;
  };

  const onHandleSubmit = (data: OrderTypeFormProps) => onSubmit(verifyFields(data));

  return (
    <ValidationProvider schema={schema}>
      <BoxStyled>
        <FormProvider {...methods}>
          <DefaultPageLayout
            disableContinue={!isValid}
            onBack={onBack}
            header={t('pages.landing.orderType.header')}
            onContinue={handleSubmit(onHandleSubmit)}
          >
            <Grid container>
              <GridStyled item xs={12}>
                <IQButtonRadioContainer
                  name="orderType"
                  fontTitleWeight="600"
                  label={t('pages.landing.orderType.selector')}
                  theme={IQTheme}
                  optionsList={optionsList.optionsList}
                  allowRadioDeselect={false}
                  required
                  inline
                />
              </GridStyled>
              <GridContainer>
                {orderType === 'addOn' ? (
                  <IQFormTextArea
                    id="existingLandingPageUrls"
                    name="existingLandingPageUrls"
                    labelText={t('pages.landing.orderType.existingLoadingPage')}
                    fontLabelWeight="600"
                    rowCount={2}
                    fullWidth
                  />
                ) : null}
              </GridContainer>
            </Grid>
          </DefaultPageLayout>
          {/* show the dialog if we changed the field and didn't submit the changes */}
          <CoNavigationConfirm showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)} />
        </FormProvider>
      </BoxStyled>
    </ValidationProvider>
  );
};

export default OrderTypeForm;
