/* eslint-disable jsx-a11y/media-has-caption */
import {
  Box, Button, Grid, IconButton,
} from '@mui/material';
import { Children, useState, Fragment } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

interface Props {
  pictures: string[]
}

const NavButton = (props: any) => (
  <IconButton sx={{ color: 'blue', '&:hover': { background: 'transparent' } }} {...props}>
    {props.children}
  </IconButton>
);

export default function CoCarousel({ pictures }: Props) {
  const [current, setCurrent] = useState(pictures[0]);

  const nextImage = () => {
    const currentIndex = pictures.indexOf(current);
    const nextIndex = currentIndex === pictures.length - 1 ? 0 : currentIndex + 1;
    setCurrent(pictures[nextIndex]);
  };

  const previousImage = () => {
    const currentIndex = pictures.indexOf(current);
    const previousIndex = currentIndex === 0 ? pictures.length - 1 : currentIndex - 1;
    setCurrent(pictures[previousIndex]);
  };

  const selectImage = (index) => {
    setCurrent(pictures[index]);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {!current.includes('.mp4') ? (
          <img style={{ height: 'auto', width: '100%' }} src={current} alt={current} />
        ) : (
          <Fragment key={current}>
            <video
              width="100%"
              height="auto"
              controls
              src={current}
            />
          </Fragment>
        )}
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <Box display="flex" flexDirection="row">
          <NavButton onClick={() => previousImage()}>
            <ChevronLeftIcon />
          </NavButton>
          <Box display="flex" flexGrow="1">
            {Children.toArray(pictures.map((pic, index) => (
              <Button onClick={() => selectImage(index)} key={pic} sx={{ background: 'rgba(185,225,255,0.2)' }}>
                {!pic.includes('.mp4') ? (
                  <img src={pic} alt={pic} style={{
                    width: '120px',
                    height: '120px',
                    borderRadius: '5px',
                    border: pic === current ? '2px solid blue' : 'initial',
                  }}
                  />
                ) : (
                  <Box sx={{
                    width: 120,
                    height: 120,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  >
                    <PlayCircleIcon sx={{ fontSize: 40 }} />
                  </Box>
                )}
              </Button>
            )))}
          </Box>
          <NavButton onClick={() => nextImage()}>
            <ChevronRightIcon />
          </NavButton>
        </Box>
      </Grid>
    </Grid>
  );
}
