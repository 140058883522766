import * as yup from 'yup';
import i18n from 'i18n/i18n';

const {t} = i18n;

const schema = yup.object().shape({
  minimumAge: yup.number().required().nullable()
  .typeError(t('pages.generic-translation.minAgeNumberErrorMessage'))
  .test('is-less-than-or-equal-to-99', t('pages.generic-translation.minAgeLimitErrorMessage'), function (value) {
    return value <= 99;
  }),
  maximumAge: yup.number().required().nullable()
  .typeError(t('pages.generic-translation.maxAgeNumberErrorMessage'))
  .test('is-not-greater-than-99', t('pages.generic-translation.maxAgeLimitErrorMessage'), function (value) {
    return value <= 99;
  }),
  gender: yup.string().required(),
  callToActionButton: yup.string().required(t('pages.xmo.tactics.xmoWebsiteClick.callToActionButtonError')).nullable(),
  useAutomaticOrOptimizedPlacement: yup.string().required(),
  visualStrategy: yup.string().required(),
  clientBeProvidingImagesToUse: yup.string().required(),
  campaignUtilizeTheCarouselFormat: yup.string().required(),
  clientBeProvidingTextCreative: yup.string().required(),
  services: yup.array().min(1, t('pages.xmo.tactics.xmoWebsiteClick.pleaseEnterAtLeastOneBusinessCategoryError'))
  .max(4, t('pages.xmo.tactics.xmoWebsiteClick.pleaseReviewAndOnlyError')),
});

export default {
  yupValidations: schema,
  customValidations: {},
};
