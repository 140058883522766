import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getDomains, selectDomainsContent } from 'services/domainsSlice';
import { getWebsite, selectWebsites } from 'services/websitesSlice';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';

const columns: GridColDef[] = [
  {
    field: 'key',
    headerName: 'Key',
    width: 250,
  },
  {
    field: 'value',
    headerName: 'Value',
    width: 800,
  },
];

const RenderAccordion = ({ title, data } : { title: string, data: any[] }) => (
  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <DataGrid
        autoHeight
        rows={data}
        columns={columns}
        pageSizeOptions={[10, 50, 100]}
        disableRowSelectionOnClick
      />
    </AccordionDetails>
  </Accordion>
);

const ContentStyle = styled('div')`
  text-align: center;
  div div {
    margin: 10px
  }
`;

// eslint-disable-next-line max-len
const convertToKeyValue = (object: any) => Object.entries(object).filter(e => e[1] !== null && e[1] !== undefined).map(e => ({ id: e[0], key: e[0], value: e[1] }));

// eslint-disable-next-line max-len
const convertToKeyValueShowEmpty = (object: any) => Object.entries(object).map(e => ({ id: e[0], key: e[0], value: e[1] }));

export default function Summary() {
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const domains = useSelector(selectDomainsContent);
  const website = useSelector(selectWebsites);
  const dispatch = useDispatch();
  const [showEmpty, setShowEmpty] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(convertToKeyValue(domains));
  }, [domains]);

  const handleShowEmptyChange = event => setShowEmpty(event.target.checked);

  const convert = (data) => (showEmpty ? convertToKeyValueShowEmpty(data) : convertToKeyValue(data));

  useEffect(() => {
    dispatch(getDomains(websiteId));
    dispatch(getWebsite({ websiteId, offeringType }));
  }, []);

  return (
    <ContentStyle>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2" component="div" sx={{ flexGrow: 1 }}>
            Summary
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            This page shows Server Data
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={(
              <Switch
                checked={showEmpty}
                onChange={handleShowEmptyChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )}
            label="Show Empty Values"
          />

        </Grid>
        <Grid item xs={12}>
          <RenderAccordion title="Domain" data={convert(domains)} />
        </Grid>
        <Grid item xs={12}>
          <RenderAccordion title="Website" data={convert(website.content)} />
        </Grid>
      </Grid>
    </ContentStyle>
  );
}
