import React from 'react';
import {
  Chip, Stack, Card, CardContent,
  Typography, Divider, Modal, Box, Button,
} from '@mui/material';
import { Theme } from '@emotion/react';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from '@emotion/styled';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';

export interface LocationCardProps {
  locationTypeLabel?: string;
  locationName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipcode: string;
  locationStatus?: string | null;
  handleDelete: any;
  onSelect: any;
  [rest: string]: any;
}

type StyleProps = {
  theme?: Theme;
  error?: boolean;
  locationStatus?: string;
};

const LocationCardStyled = styled(Card) <StyleProps>`
  padding: ${(props) => props.theme.spacing(1, 1, 0, 1)};
  border: 1px solid ${(props) => (props.error
    ? props.theme.palette.error.main : props.theme.palette.action.disabledBackground)};
  margin-bottom: 16px;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
`;

const LocationCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

const ChipStyled = styled(Chip)(() => ({
  height: '18px',
  fontSize: '12px',
  fontWeight: 600,
  padding: '1px',
  textTransform: 'uppercase' as const,
}));

const ChipWrapper = styled(Stack)(({ theme, locationStatus }: StyleProps) => ({
  '.MuiChip-root > .MuiChip-icon': {
    color: `${locationStatus === 'Completed' ? theme.palette.success.dark : '#BA5012'}`,
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(4),
  },
}));

const AddressWrapper = styled(Stack)(({ theme }) => ({
  flexBasis: '40%',
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(6),
    maxWidth: '300px',
    wordWrap: 'break-word',
  },
}));

const VerticalDivider = styled(Divider)(({ theme }) => ({
  height: '24px',
  width: '1px',
  color: `${theme.palette.action.disabledBackground}`,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const HorizontalDivider = styled(Divider)(({ theme }) => ({
  color: `${theme.palette.action.disabledBackground}`,
  paddingTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const LocationCard = ({
  locationTypeLabel,
  locationName,
  addressLine1,
  addressLine2,
  city,
  state,
  zipcode,
  locationStatus,
  handleDelete,
  onSelect,
}: LocationCardProps) => {
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const closeModalNoBackdropClick = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpenModal(false);
  };

  const onDelete = () => {
    handleDelete();
    handleCloseModal();
  };

  return (
    <>
      <LocationCardStyled theme={IQThemeV2} onClick={onSelect}>
        <LocationCardContent>
          <Stack direction="row" sx={{ flexBasis: '35%' }}>
            <Stack>
              <Typography variant="body2"
                sx={{ color: IQThemeV2.palette.text.secondary }}
              >
                {`${locationTypeLabel || 'Location'}:`}
              </Typography>
              {locationName ? (
                <Typography
                  component="div"
                  sx={{
                    fontFamily: 'Unify Sans',
                    fontSize: 16,
                    lineHeight: '24px',
                    fontWeight: 'bold',
                  }}
                >
                  {locationName}
                </Typography>
              ) : (
                <Typography variant="body1" sx={{ color: IQThemeV2.palette.text.secondary }}>
                  Enter Location
                </Typography>
              )}
            </Stack>
          </Stack>
          <AddressWrapper>
            <HorizontalDivider orientation="horizontal" flexItem />
            <Typography variant="body2"
              sx={{ color: IQThemeV2.palette.text.secondary }}
            >
              Address:
            </Typography>
            {addressLine1 ? (
              <>
                <Typography variant="body1">{`${addressLine1}, ${addressLine2 || ''}`}</Typography>
                <Typography variant="body1">{`${city}, ${state} ${zipcode}`}</Typography>
              </>
            )
              : (
                <Typography variant="body1"
                  sx={{ color: IQThemeV2.palette.text.secondary }}
                >
                  Enter Address
                </Typography>
              )}
            <HorizontalDivider orientation="horizontal" flexItem />
          </AddressWrapper>
          <ChipWrapper direction="row" spacing={2} locationStatus={locationStatus}
            sx={{ flexBasis: '20%', flexShrink: 0, flexGrow: 0 }}
          >
            {locationStatus
          && (
            <>
              <VerticalDivider orientation="vertical" flexItem />
              <ChipStyled label={locationStatus}
                icon={<CircleIcon sx={{ fontSize: '10px' }} />} variant="outlined"
              />
            </>
          )}
          </ChipWrapper>
          <Stack>
            <DeleteIcon onClick={(event) => { event.stopPropagation(); handleOpenModal(); }} />
          </Stack>
        </LocationCardContent>
      </LocationCardStyled>
      {openModal && (
      <Modal
        open={openModal}
        onClose={closeModalNoBackdropClick}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          borderRadius: '2px',
          boxShadow: 24,
          padding: '24px 8px 8px 24px',
        }}
        >
          <Typography variant="h5" component="h2" mb={3}>Remove Location</Typography>
          <Typography variant="body1">
            Are you sure you want to remove
            {city ? <strong>{` "${addressLine1} ${addressLine2}, ${city} ${state}, ${zipcode}"?`}</strong> : ' "?'}
          </Typography>
          <Box mt={4} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
            <Button variant="outlined"
              sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button variant="contained" sx={{ borderRadius: '20px', textTransform: 'none' }}
              onClick={onDelete}
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Modal>
      )}
    </>
  );
};

export default LocationCard;
