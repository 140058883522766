import React, { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  Checkbox, FormControlLabel, FormHelperText, useTheme, Box, Tooltip,
} from '@mui/material';
import styled from '@emotion/styled';
import InfoIcon from '@mui/icons-material/Info';
import { HelpOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { IQLabelTooltip, useValidationContext } from '@gannettdigital/shared-react-components';
import schemaValidate from '../schemaValidate';

export interface NeCheckboxProps {
  /**
   * Input label
   */
  label?: string
  /**
   * Input field name
   */
  title?: string

  field: string
  /**
   * Required
   */
  required?: boolean
  /**
   * Shows exception message below checkbox (true/false)
   */
  tooltipText?: string;

  tooltipPlacement?: 'bottom' | 'right' | 'top' | 'left';

  tooltipArrow?: boolean;
  tooltipInline?: boolean;
  hideErrors?: boolean
  tooltipPaddingBottom?: number;
  params?: any
  [rest: string]: any
}

type ErrorProps = {
  error?: string;
};

const NeCheckboxContainer = styled('div') <ErrorProps>`
  & .MuiFormHelperText-root {
    color: ${(props) => props.theme.palette.error.main};
  }
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0 9px;
  align-self: baseline;
`;

const FormControlLabelStyled = styled(FormControlLabel) <ErrorProps>`
  color: ${(props) => (props.error ? props.theme.palette.error.main : props.theme.palette.text.primary)};
  border: 2px solid ${(props) => (props.error ? props.theme.palette.error.main : 'transparent')};
`;

const Title = styled('span')`
  display: block;
  font-family: 'Unify Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => (props.theme.palette.text.primary)};
  margin-bottom: 15px;
`;

const StyledTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/no-children-prop
  <Tooltip children={undefined} title="" {...props}
    classes={{ popper: className }}
  />
))`
  & .MuiTooltip-tooltip {
    max-width: calc(208px - 17px);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    background: ${(props) => props.theme.palette.text.primary};
    border-radius: 4px;
  }

  & .MuiTooltip-tooltipArrow {
    background: ${(props) => props.theme.palette.text.primary};
  }

  & .MuiTooltip-arrow {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

export default function NeCheckbox({
  label,
  title,
  field,
  required = false,
  hideErrors = false,
  tooltipPlacement = 'bottom',
  tooltipText = null,
  tooltipArrow = true,
  tooltipInline = false,
  tooltipPaddingBottom,
  params, ...rest
}: NeCheckboxProps) {
  const theme = useTheme();
  const { schema } = useValidationContext();
  const [customValidation, setCustomValidation] = useState(null);
  const { control } = useFormContext();
  const {
    field: {
      onChange, onBlur, name, value: fieldValue, ref,
    },
  } = useController({
    name: field,
    control,
    rules: {
      validate: (value) => schemaValidate(value, field, schema, params, customValidation),
    },
    defaultValue: rest?.defaultValue || false,
  });
  const { t } = useTranslation();

  const {
    formState: { errors },
    clearErrors,
  } = useFormContext();

  useEffect(() => {
    if (required) {
      setCustomValidation(yup.boolean().oneOf([true], `yup.${field}.required`));
    }

    clearErrors(field);
  }, [required]);

  const doOnChange = (event) => {
    onChange(event);

    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  const doOnBlur = () => {
    onBlur();

    if (rest.onBlur) {
      rest.onBlur();
    }
  };

  const labelWithToolTip = (
    <>
      {label}
      {tooltipInline
                && (
                  <StyledTooltip
                    title={tooltipText}
                    placement={tooltipPlacement}
                    className={undefined}
                  >
                    <HelpOutline sx={{
                      color: theme.palette.primary.main,
                      height: 18,
                      width: 18,
                      marginLeft: theme.spacing(1),
                      position: 'relative',
                      top: 2,
                      cursor: 'pointer',
                    }}
                    />
                  </StyledTooltip>
                )}
    </>
  );

  return (
    <NeCheckboxContainer>
      {tooltipText && title && (
      <Box sx={{ marginBottom: '16px' }}>
        <IQLabelTooltip
          hasError={hideErrors}
          tooltipText={tooltipInline ? '' : tooltipText}
          labelText={title}
          theme={theme}
          tooltipPlacement={tooltipPlacement}
          required={required}
          arrow={tooltipArrow}
          paddingBottom={tooltipPaddingBottom}
        />
      </Box>
      )}
      {!tooltipText && title && (
        <Title>{`${title}${required ? ' *' : ''}`}</Title>
      )}
      <FormControlLabelStyled
        error={errors && errors[field] ? 'true' : undefined}
        control={(
          <StyledCheckbox
            required={errors[field] !== undefined && errors[field] !== null}
            onChange={doOnChange} // send value to hook form
            onBlur={doOnBlur} // notify when input is touched/blur
            value={fieldValue} // input value
            checked={fieldValue} // input value
            name={name} // send down the input name
            inputRef={ref} // send input ref, so we can focus on input when error appear
            onClick={(e) => e.stopPropagation()}
          />
        )}
        label={labelWithToolTip}
      />
      <FormHelperText
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '7px',
        }}
        error={required}
      >
        {!hideErrors && errors[field] && (
        <>
          <InfoIcon sx={{ fontSize: '16px' }} />
          {' '}
          {t(`${errors[field].message}`)}
        </>
        )}
      </FormHelperText>
    </NeCheckboxContainer>
  );
}
