import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import {
  clearAddonsCompleted,
  clearCompletedBuildingMenu,
  getAddons,
  getAddonsBundle,
  putAddons,
  selectAddons,
  selectAddonsCompleted,
  selectBundleStatus,
  selectCompletedBuildingMenu,
  selectIncludedBundle, getMasterAddonDetails, getCompletedWebsiteUrls,
} from 'services/addons/addonsSlice';
import { getOrder, selectOrderItems, selectOrdersContent } from 'services/ordersSlice';
import { Urls } from 'navigation/Urls';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { selectWebsites, WebsiteOfferingTypes } from 'services/websitesSlice';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import { getBusiness, selectBusiness } from 'services/businessSlice';
import AddonsHomeForm from './AddonsHomeForm';

export default function AddonsHome() {
  const {
    orderId, offeringType, websiteId, orderItemId,
  } = useParams();

  const addons = useSelector(selectAddons);
  const website = useSelector(selectWebsites);
  const includedBundle = useSelector(selectIncludedBundle);
  const bundleStatus = useSelector(selectBundleStatus);
  const completedBuildingMenu = useSelector(selectCompletedBuildingMenu);
  const pageCompleted = useSelector(selectAddonsCompleted);
  const orderItems = useSelector(selectOrderItems);
  const dispatch = useDispatch();
  const topNav = useTopnavMenu(Urls.WebsiteUrl);
  const history: any = useLocation();
  const order = useSelector(selectOrdersContent);
  const business = useSelector(selectBusiness);
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [websiteIdState, setWebsiteIdState] = useState(websiteId);

  const navigate = useNavigationHandler();

  const onBack = () => {
    navigate.to(Urls.ProductMoveBackward);
  };

  const onSubmit = (content, websiteId?) => {
    if (websiteId) setWebsiteIdState(websiteId);
    dispatch(putAddons({ orderId, content }));
  };

  useEffect(() => {
    if (!addons?.content?.id) dispatch(getAddons(orderId));
  }, [orderId]);

  useEffect(() => {
    if (offeringType === WebsiteOfferingTypes.GOLD) {
      const bundleId = website?.content?.bundleId;
      if (bundleId) dispatch(getAddonsBundle({ bundleId }));
    }
  }, [offeringType, website]);

  useEffect(() => {
    if (completedBuildingMenu) {
      dispatch(clearCompletedBuildingMenu());
    }
  }, [completedBuildingMenu]);

  useEffect(() => {
    if (orderItemId) dispatch(getMasterAddonDetails(orderItemId));
  }, [addons, orderItemId]);

  useEffect(() => {
    if (!order) dispatch(getOrder(orderId));
    if (order?.id) dispatch(getBusiness(order.businessId));
  }, [order]);

  useEffect(() => {
    if (business?.gmaid) dispatch(getCompletedWebsiteUrls(business.gmaid));
  }, [business]);

  // after we receive the completed flag from redux, we move to the next page and clear the flag
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (pageCompleted) {
      dispatch(clearAddonsCompleted());
      if (isRedirectedFromOverviewPage) return navigate.to(Urls.Overview);
      navigate.to(topNav.nextStep());
    }
  }, [pageCompleted]);

  return (
    <ContentLoader status={[addons.status, bundleStatus]} message={addons.message}>
      <AddonsHomeForm onBack={onBack} onSubmit={onSubmit}
        addons={addons.content}
        included={includedBundle}
        standalone
        orderItems={orderItems.content}
      />
    </ContentLoader>
  );
}
