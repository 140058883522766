import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import {
  Dialog, DialogTitle, IconButton, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import { ReactNode } from 'react';
import i18n from 'i18n/i18n';

const { t } = i18n;

interface Props {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
}

export default function PhoneTracking({ children, isOpen, onClose }: Props) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: IQThemeV2.palette.common.black, opacity: '0.86' },
      }}
    >
      <DialogTitle
        sx={{
          border: '10px solid',
          borderImageSlice: '1',
          borderWidth: '0 0 1px',
          borderImageSource: 'linear-gradient(to right, #0046FF, #F03C4B, #FF6437)',
          margin: '0 20px',
        }}
      >
        <Typography fontWeight="bold" textAlign="center" fontSize={24}>
          {t('pages.ccByDash.solutionStrategy.trackingLineTitle')}
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  );
}
