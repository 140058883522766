/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import ContentTonesType from 'models/ContentTonesType';
import { SiteOfferingTypes } from 'models/SiteOfferingTypes';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { thunkBuilder } from './common/functions';
import ContentTonesEndpoints from './endpoints/ContentTonesEndpoints';

export interface ContentTonesState {
  completed: boolean;
  contentTones: StatusType<ContentTonesType[]>
}

const initialState: ContentTonesState = {
  contentTones: {
    status: 'idle',
    message: '',
    content: [],
  },
  completed: false,
};

export const getContentTones = createAsyncThunkCatchErrors(
  'contentTones/get',
  async ({ websiteId, offeringType } :
  { websiteId: string, offeringType: SiteOfferingTypes }) =>
    ContentTonesEndpoints[offeringType].getContentTones(websiteId),
);

export const putWebsiteContentTones = createAsyncThunkCatchErrors(
  'contentTones/put',
  async ({ websiteId, content, offeringType } :
  { websiteId: string, content: any, offeringType: SiteOfferingTypes }) =>
    ContentTonesEndpoints[offeringType].putContentTones(websiteId, content),
);

const slice = createSlice({
  name: 'contentTones',
  initialState,
  reducers: {
    clearContentTonesCompleted(state) {
      state.completed = false;
    },
  },
  extraReducers:
    (builder) => {
      thunkBuilder(builder)
        .addCase(getContentTones, 'contentTones')
        .addCase(putWebsiteContentTones, 'contentTones', { completeStep: 'completed' });
    },
});

export const { clearContentTonesCompleted } = slice.actions;

export const selectContentTones = (state: RootState) => state.contentTones.contentTones;

export const selectContentTonesCompleted = (state: RootState) => state.contentTones.completed;

export default slice.reducer;
