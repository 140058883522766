/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Trans } from 'react-i18next';
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import {
  IQFormInput,
  IQFormTextArea,
  IQInputEmail,
  IQLoadingSpinner,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import GridContainer from 'components/grid/GridContainer';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import GridItem from 'components/grid/GridItem';
import schemaValidate from 'components/schemaValidate';
import i18n from 'i18n/i18n';
import {
  deleteFile, getFiles, ProductTypes, selectFilesUploaded, uploadFiles,
} from 'services/fileUploaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import IQFileUploader from 'components/fileUploader/NeFileUploader';
import CoFileList from 'components/fileList/CoFileList';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import ErrorText from 'components/errorText/ErrorText';
import schema from './XmoBrandAmplifier.schema';
import {
  inventoryTierItems, keywordsItems, politicalCampaignItems, specialCategoryItems,
} from './XmoBrandAmplifierConstants';

const { t } = i18n;

interface Props {
  product: any;
  onSubmit: (data: any) => void;
  onBack: () => void;
}

export type BrandAmplifierProps = {
  inventoryTier: string,
  specialCategory: string,
  keywords: string,
  pullSiteUrl: string,
  politicalCampaign: string,
  csmEmail: string,
  websiteHighPriorityUrls: string,
  websiteFormUrls: string,
  youtubeVideoUrl: string,
  additionalYoutubeVideoUrls: string,
  clientProvidedKeywords: any[],
  uploads?: any,
};

export default function XmoGeneralPageForm({ product, onSubmit, onBack }: Props) {
  const dispatch = useDispatch();
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const filesUploaded = useSelector(selectFilesUploaded);
  const [inventoryTier, setInventoryTier] = useState(product?.inventoryTier || '');
  const [specialCategoryValue, setSpecialCategoryValue] = useState(product?.specialCategory || '');
  const [keywords, setKeywords] = useState(product?.keywords || '');
  const [politicalCampaign, setPoliticalCampaign] = useState(product?.politicalCampaign || '');
  const [fileUploadedError, setFileUploadedError] = useState('');
  const [uploaderDirty, setUploaderDirty] = useState(false);

  const hasUploadedFiles = filesUploaded.content.length > 0;

  const uploaderType = ProductTypes.CLIENT_PROVIDED_KEYWORDS;
  const megabyte = 1_000_000;
  const defaultFileSize = 256 * megabyte;

  const methods = useForm<BrandAmplifierProps>({
    mode: 'all',
    defaultValues: {
      inventoryTier: product?.inventoryTier || '',
      specialCategory: product?.specialCategory || '',
      keywords: product?.keywords || '',
      pullSiteUrl: product?.pullSiteUrl || '',
      clientProvidedKeywords: product?.clientProvidedKeywords || [],
      politicalCampaign: product?.politicalCampaign || '',
      csmEmail: product?.csmEmail || '',
      websiteHighPriorityUrls: product?.websiteHighPriorityUrls?.replaceAll('\\n', '\n')
        .replaceAll('\\', '') || '',
      websiteFormUrls: product?.websiteFormUrls?.replaceAll('\\n', '\n')
        .replaceAll('\\', '') || '',
      youtubeVideoUrl: product?.youtubeVideoUrl || '',
      additionalYoutubeVideoUrls: product?.additionalYoutubeVideoUrls?.replaceAll('\\n', '\n')
        .replaceAll('\\', '') || '',
    },
  });

  const {
    register, handleSubmit, watch, setValue, formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting, errors,
    },
  } = methods;

  const showYoutubeInfo = [inventoryTier, watch('inventoryTier')].includes('Recommended - All Inclusive');
  const keywordsUrl = watch('keywords') === 'Pulled from Site';
  const keywordsClientProvided = watch('keywords') === 'Client-Provided';

  useEffect(() => {
    if (!showYoutubeInfo) {
      setValue('youtubeVideoUrl', '');
      setValue('additionalYoutubeVideoUrls', '');
    }
  }, [showYoutubeInfo]);

  useEffect(() => {
    dispatch(getFiles({ websiteId, uploaderType, offeringType }));
  }, [websiteId, offeringType]);

  const onUpdate = (newFiles) => {
    dispatch(uploadFiles({
      websiteId, files: newFiles, uploaderType, offeringType,
    }));
  };

  const handleUploaderError = () => {
    setFileUploadedError(filesUploaded.content.length === 0 ? 'Please upload the Client-Provided Keywords file.' : '');
  };

  const onDelete = async (file) => {
    await dispatch(deleteFile({ websiteId, file }));
    setUploaderDirty(true);
  };

  useEffect(() => {
    if (uploaderDirty) handleUploaderError();
  }, [uploaderDirty]);

  const handleFormSubmit = (data: BrandAmplifierProps) => {
    const dataCopy = { ...data };
    delete dataCopy.uploads;
    const pageFormData = {
      solutionStrategy: {
        ...product,
        ...data,
        pullSiteUrl: keywordsUrl ? data.pullSiteUrl : '',
        clientProvidedKeywords: keywordsClientProvided
          ? filesUploaded?.content
            .map(file => ({
              fileName: file.fileName,
              url: file.url,
            }))
          : [],
      },
    };
    onSubmit(pageFormData);
  };

  return (
    <DefaultPageLayout
      disableContinue={!isValid || (keywordsClientProvided && !hasUploadedFiles)}
      onBack={onBack}
      header={t('pages.xmo.solutionsStrategy.brandAmplifier.title')}
      description={(
        <Trans
          i18nKey="pages.xmo.solutionsStrategy.brandAmplifier.description"
          components={{ br: <br /> }}
        />
      )}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <GridContainer fullWidth>
            <GridItem sizes={[12]}>
              <Typography
                variant="h6"
              >
                {t('pages.xmo.solutionsStrategy.brandAmplifier.generalInfo')}
              </Typography>
            </GridItem>

            <GridItem sizes={[12]}>
              <Typography fontWeight={600} variant="subtitle1">
                {t('pages.xmo.solutionsStrategy.brandAmplifier.inventoryTierLabel')}
                <RadioGroup
                  id="inventoryTier"
                  name="inventoryTier"
                  value={inventoryTier}
                  onChange={(e) => setInventoryTier(e.target.value)}
                >
                  {inventoryTierItems.map(opt => (
                    <FormControlLabel
                      key={opt}
                      control={<Radio value={opt} />}
                      label={opt}
                      {...register('inventoryTier', {
                        validate: (value) => schemaValidate(value, 'inventoryTier', schema),
                      })}
                    />
                  ))}
                </RadioGroup>
              </Typography>
            </GridItem>

            <GridItem sizes={[12]}>
              <Typography fontWeight={600} variant="subtitle1">
                {t('pages.xmo.solutionsStrategy.brandAmplifier.specialCategoriesLabel')}
                <RadioGroup
                  id="specialCategory"
                  name="specialCategory"
                  value={specialCategoryValue}
                  onChange={(e) => setSpecialCategoryValue(e.target.value)}
                >
                  <GridItem sizes={[6, 6]} marginBottom={0}>
                    {specialCategoryItems.slice(0, 2).map(opt => (
                      <FormControlLabel
                        key={opt}
                        control={<Radio value={opt} />}
                        label={opt}
                        {...register('specialCategory', {
                          validate: (value) => schemaValidate(value, 'specialCategory', schema),
                        })}
                      />
                    ))}
                  </GridItem>
                  <GridItem sizes={[6, 6]} marginBottom={0}>
                    {specialCategoryItems.slice(2, 4).map(opt => (
                      <FormControlLabel
                        key={opt}
                        control={<Radio value={opt} />}
                        label={opt}
                        {...register('specialCategory', {
                          validate: (value) => schemaValidate(value, 'specialCategory', schema),
                        })}
                      />
                    ))}
                  </GridItem>
                </RadioGroup>
              </Typography>
            </GridItem>

            <GridItem sizes={[12]}>
              <Typography fontWeight={600} variant="subtitle1">
                {t('pages.xmo.solutionsStrategy.brandAmplifier.keywordsLabel')}
                <RadioGroup
                  id="keywords"
                  name="keywords"
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  row
                >
                  {keywordsItems.map(opt => (
                    <FormControlLabel
                      key={opt}
                      control={<Radio value={opt} />}
                      label={opt}
                      {...register('keywords', {
                        validate: (value) => schemaValidate(value, 'keywords', schema),
                      })}
                    />
                  ))}
                </RadioGroup>
              </Typography>
            </GridItem>

            {keywordsClientProvided && (
              <Grid item xs={9} sx={{
                '& > div > div': { padding: 0, display: 'flex', justifyContent: 'flex-start' },
                '& > div > div > *': { marginLeft: '10px' },
              }}
              >
                <IQFileUploader
                  showRecommendedImageText={false}
                  showAcceptedFileTypesText
                  required
                  name="uploads" maxFileSize={defaultFileSize}
                  onSuccess={onUpdate}
                  label={t('pages.xmo.solutionsStrategy.brandAmplifier.clientProvidedKeywordsLabel')}
                  supportingText=""
                  totalAllowedFiles={1}
                  imageTypes={[]}
                  videoTypes={[]}
                  documentTypes={['.png', '.jpeg', '.jpg', '.gif', '.cr2', '.dng', '.pdf', '.docx', '.mp4']}
                  size="md"
                  currentUploadedFiles={filesUploaded.content.length}
                  onBlur={() => handleUploaderError()}
                  requiredError={fileUploadedError}
                />
                <Grid item xs={12} mb={1}
                  mt={0}
                >
                  {filesUploaded.status === 'loading' && <IQLoadingSpinner size={40} />}
                  {filesUploaded.content.length
                    ? <CoFileList files={filesUploaded.content} onDelete={onDelete} />
                    : null}
                </Grid>
              </Grid>
            )}

            {keywordsUrl && (
              <GridItem sizes={[9]}>
                <IQFormInput
                  id="pullSiteUrl"
                  name="pullSiteUrl"
                  labelText={t('pages.xmo.solutionsStrategy.brandAmplifier.pullSiteUrlLabel')}
                  fullWidth
                  theme={IQTheme}
                  fontLabelWeight="600"
                  schema={schema}
                  adornmentIcon={<InsertLinkIcon />}
                  showError
                />
              </GridItem>
            )}

            <GridItem sizes={[12]}>
              <Typography fontWeight={600} variant="subtitle1">
                {t('pages.xmo.solutionsStrategy.brandAmplifier.politicalCampaignLabel')}
                <RadioGroup
                  id="politicalCampaign"
                  name="politicalCampaign"
                  value={politicalCampaign}
                  onChange={(e) => setPoliticalCampaign(e.target.value)}
                  row
                >
                  {politicalCampaignItems.map(opt => (
                    <FormControlLabel
                      key={opt}
                      control={<Radio value={opt} />}
                      label={opt}
                      {...register('politicalCampaign', {
                        validate: (value) => schemaValidate(value, 'politicalCampaign', schema),
                      })}
                    />
                  ))}
                </RadioGroup>
              </Typography>
            </GridItem>

            <GridItem sizes={[9]} sx={{ '>.MuiGrid-root:first-of-type': { marginBottom: 0 } }}>
              <IQInputEmail
                {...register(
                  'csmEmail',
                  {
                    required: {
                      value: true,
                      message: t('pages.xmo.solutionsStrategy.brandAmplifier.csmEmailError'),
                    },
                  },
                )}
                schema={schema}
                theme={IQTheme}
                fontLabelWeight="600"
                field="csmEmail"
                name="csmEmail"
                required
                fullWidth
                adornmentIcon={<MailOutlineIcon />}
                label={t('pages.xmo.solutionsStrategy.brandAmplifier.csmEmailLabel')}
              />
              <FormHelperText>
                {t('pages.xmo.solutionsStrategy.brandAmplifier.csmEmailHelper')}
              </FormHelperText>
            </GridItem>

            {showYoutubeInfo && (
            <>
              <GridItem sizes={[9]} marginTop={2}>
                <Typography
                  variant="h6"
                >
                  {t('pages.xmo.solutionsStrategy.brandAmplifier.youtubeInfoLabel')}
                </Typography>
              </GridItem>
              <GridItem sizes={[9]}>
                <IQFormInput
                  id="youtubeVideoUrl"
                  name="youtubeVideoUrl"
                  labelText={t('pages.xmo.solutionsStrategy.brandAmplifier.youtubeVideoUrlLabel')}
                  fullWidth
                  theme={IQTheme}
                  fontLabelWeight="600"
                  schema={schema}
                  adornmentIcon={<InsertLinkIcon />}
                  showError
                />
              </GridItem>
              <GridItem sizes={[9]} sx={{ '>.MuiGrid-root:first-of-type': { marginBottom: 0 } }}>
                <IQFormTextArea
                  fontLabelWeight="600"
                  id="additionalYoutubeVideoUrls"
                  name="additionalYoutubeVideoUrls"
                  fullWidth
                  showError
                  labelText={t('pages.xmo.solutionsStrategy.brandAmplifier.additionalYoutubeVideoUrlsLabel')}
                />
              </GridItem>
            </>
            )}

            <GridItem sizes={[9]} marginTop={2}>
              <Typography
                variant="h6"
              >
                {t('pages.xmo.solutionsStrategy.brandAmplifier.websiteInfoLabel')}
              </Typography>
            </GridItem>

            <Grid item xs={9} marginBottom={3}>
              <NeFormTextArea
                {...register('websiteHighPriorityUrls')}
                id="websiteHighPriorityUrls"
                name="websiteHighPriorityUrls"
                labelText={t('pages.xmo.solutionsStrategy.brandAmplifier.websiteHighPriorityLabel')}
                tooltipText={t('pages.xmo.solutionsStrategy.brandAmplifier.websiteHighPriorityTooltip')}
                tooltipArrow={false}
                tooltipPlacement="top"
                tooltipPaddingBottom={16}
                fullWidth
                rowCount={3}
                fontLabelWeight="600"
              />
              <ErrorText
                hasError={!!errors.websiteHighPriorityUrls}
                errorText={t('pages.xmo.solutionsStrategy.brandAmplifier.websiteHighPriorityError')}
              />
              <FormHelperText>
                {t('pages.xmo.solutionsStrategy.brandAmplifier.websiteHighPriorityHelper')}
              </FormHelperText>
            </Grid>

            <Grid item xs={9} marginBottom={3}>
              <NeFormTextArea
                {...register('websiteFormUrls')}
                id="websiteFormUrls"
                name="websiteFormUrls"
                labelText={t('pages.xmo.solutionsStrategy.brandAmplifier.websiteFormUrlsLabel')}
                tooltipText={t('pages.xmo.solutionsStrategy.brandAmplifier.websiteFormUrlsTooltip')}
                tooltipArrow={false}
                tooltipPlacement="top"
                tooltipPaddingBottom={16}
                fullWidth
                rowCount={3}
                fontLabelWeight="600"
              />
              <ErrorText
                hasError={!!errors.websiteFormUrls}
                errorText={t('pages.xmo.solutionsStrategy.brandAmplifier.websiteFormUrlsError')}
              />
              <FormHelperText>
                {t('pages.xmo.solutionsStrategy.brandAmplifier.websiteFormUrlsHelper')}
              </FormHelperText>
            </Grid>

          </GridContainer>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
