import {
  Accordion, AccordionDetails, AccordionSummary, Grid, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StepperRootProps } from 'layouts/StepperLayout';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { IQFormLayout, ValidationProvider } from '@gannettdigital/shared-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectCustomWebsite, getCustomWebsite, putCustomWebsiteDesignInfo } from 'services/customWebsiteSlice';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { useAppDispatch } from 'services/redux/store';
import NeCheckbox from 'components/checkbox/NeCheckbox';
import { selectActiveProductId } from 'services/navigationSlice';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import ErrorText from 'components/errorText/ErrorText';
import schema from './Form.schema';

interface RequirementProps {
  title: string
  description: string
  field: 'calculations' | 'instantOrRealtimeQuotes' | 'extensiveAmountOfFields'
  | 'hipaaSecure' | 'stepByStepForm' | 'other' | any
  checked?: boolean
}

const requirements: RequirementProps[] = [
  {
    title: 'pages.website.custom.form.calculations',
    description: 'pages.website.custom.form.calculationsDescription',
    field: 'calculations',
  },
  {
    title: 'pages.website.custom.form.instantQuotes',
    description: 'pages.website.custom.form.instantQuotesDescription',
    field: 'instantOrRealtimeQuotes',
  },
  {
    title: 'pages.website.custom.form.exclusiveAmount',
    description: 'pages.website.custom.form.exclusiveAmountDescription',
    field: 'extensiveAmountOfFields',
  },
  {
    title: 'pages.website.custom.form.hipaa',
    description: 'pages.website.custom.form.hipaaDescription',
    field: 'hipaaSecure',
  },
  {
    title: 'pages.website.custom.form.stepByStep',
    description: 'pages.website.custom.form.stepByStepDescription',
    field: 'stepByStepForm',
  },
  {
    title: 'pages.website.custom.form.other',
    description: null,
    field: 'other',
  },
];

export const RequirementBox = ({
  title, description, field, checked,
}: RequirementProps) =>
  (
    <Accordion sx={{
      borderColor: '#0046FF',
      borderStyle: 'solid',
      borderWidth: checked ? '3px' : '1px',
      backgroundColor: 'white',
      '& .Mui-expanded': !description ? { minHeight: '48px !important', height: '60px' } : {},
      '.MuiAccordion-root': { height: '60px' },
    }}
    >
      <AccordionSummary
        expandIcon={description && <ExpandMoreIcon htmlColor="#0046FF" />}
        sx={{
          '& .MuiTypography-root': { color: 'blue', fontSize: '18px' },
          '& [class*=\'css-\']': { display: 'flex', flexDirection: 'row', alignItems: 'center' },
          '& .MuiSvgIcon-root': { fontSize: '32px' },
          '& .Mui-expanded': !description ? { minHeight: '48px !important', height: '60px', margin: 0 } : {},
          '& .MuiAccordionSummary-content': !description ? { margin: '0', height: '60px' } : {},
        }}
      >
        <NeCheckbox
          title=""
          label={title}
          name={field}
          field={field}
        />
      </AccordionSummary>
      {description && (
        <AccordionDetails sx={{ paddingLeft: '60px', paddingRight: checked ? '12px' : '16px' }}>
          <Typography fontSize="16px">{description}</Typography>
        </AccordionDetails>
      )}
    </Accordion>
  );

export default function CustomWebsiteForm(props: StepperRootProps) {
  const { t } = useTranslation();
  const { orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const customWebsite = useSelector(selectCustomWebsite);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      customFormUrls: '',
      calculations: false,
      instantOrRealtimeQuotes: false,
      extensiveAmountOfFields: false,
      hipaaSecure: false,
      stepByStepForm: false,
      other: false,
      otherRequirements: '',
    },
  });
  const customFormOptions = ['calculations', 'instantOrRealtimeQuotes', 'extensiveAmountOfFields',
    'hipaaSecure', 'stepByStepForm', 'other'];
  const {
    watch, getValues, handleSubmit, setValue, clearErrors, register, formState: {
      errors, isDirty, isSubmitting, isSubmitSuccessful, isValid, touchedFields,
    },
  } = methods;
  const touched = useMemo(
    () => Object.keys(touchedFields).some(key => customFormOptions.includes(key)),
    [watch()],
  );

  const values = useMemo(() => getValues(), [watch()]);
  const { other, otherRequirements } = values;
  const noneSelected = useMemo(() => {
    const {
      calculations, instantOrRealtimeQuotes, extensiveAmountOfFields, hipaaSecure, stepByStepForm, other,
    } = getValues();

    return !(calculations || instantOrRealtimeQuotes || extensiveAmountOfFields || hipaaSecure
      || stepByStepForm || other);
  }, [watch()]);

  const isOtherFilled = useMemo(() => {
    if (other) return !!otherRequirements && isValid;
    clearErrors('otherRequirements');
    return true;
  }, [other, otherRequirements, isValid]);

  useEffect(() => {
    props.onUpdateStatus(getValues(), (Object.keys(errors).length === 0) && !noneSelected && isOtherFilled);
  }, [watch()]);

  useEffect(() => {
    if (customWebsite.customFormRequirements) {
      const selectedRequirements = customWebsite.customFormRequirements.split(',');
      selectedRequirements.forEach(item => {
        setValue(item as any, true);
        if (item === 'other') {
          setValue('otherRequirements', selectedRequirements.at(-1));
          // eslint-disable-next-line no-useless-return
          return;
        }
      });
    }
    setValue('customFormUrls', customWebsite.customFormUrls);
  }, [customWebsite]);

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsite(websiteId.toString()));
  }, [websiteId]);

  const onFormSubmit = async () => {
    const formData = getValues();
    const enabledAttributes = Object.keys(formData).filter(key => formData[key] === true);
    if (enabledAttributes.includes('other') && formData.otherRequirements) {
      enabledAttributes.push(formData.otherRequirements);
    }
    await appDispatch(dispatch(getCustomWebsite(websiteId)))
      .then(async (response) => {
        const content = {
          customNavigationMenu: response.payload.navigationMenuType || '',
          customDesignRequirements: response.payload.customDesignInfo || '',
          customFormRequirements: enabledAttributes.toString(),
          customFormUrls: formData.customFormUrls,
        };
        dispatch(putCustomWebsiteDesignInfo({ orderItemId, content }));
      });
    props.onContinue();
  };

  return (
    <>
      <Typography variant="h2" marginTop={2}>{t('pages.website.custom.form.title')}</Typography>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container marginTop={5}>
              <Grid item xs={6} marginBottom={5}>
                <NeFormTextArea
                  {...register('customFormUrls')}
                  id="customFormUrls"
                  name="customFormUrls"
                  labelText={t('pages.website.custom.form.exampleUrls')}
                  tooltipText={t('pages.website.custom.form.exampleUrlsTooltip')}
                  tooltipPaddingBottom={18}
                  tooltipPlacement="top"
                  fullWidth
                  rowCount={3}
                  fontLabelWeight="600"
                />
                <ErrorText
                  hasError={!!errors.customFormUrls}
                  errorText={t('pages.website.custom.form.exampleUrlsRequired')}
                />
              </Grid>
              <Grid item xs={10} marginBottom={2}>
                <IQFormLayout
                  labelText=""
                  showError={touched && noneSelected}
                  errorMessage={t('pages.website.custom.form.requirementsRequired')}
                  fullWidth
                >
                  <Typography fontWeight="bold" paddingBottom="18px"
                    color={touched && noneSelected ? '#C20F1E' : 'black'}
                  >
                    {t('pages.website.custom.form.requirements')}
                  </Typography>
                  <Grid container>
                    {requirements.map(req => (
                      <Grid item xs={6}
                        paddingRight={2}
                        marginBottom={2}
                      >
                        <RequirementBox
                          title={t(req.title)}
                          description={req.description ? t(req.description) : null}
                          field={req.field}
                          checked={values[req.field]}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </IQFormLayout>
              </Grid>
              {values.other && (
                <Grid item xs={6} marginBottom={3}>
                  <NeFormTextArea
                    {...register('otherRequirements')}
                    id="otherRequirements"
                    name="otherRequirements"
                    labelText={t('pages.website.custom.form.otherRequirements')}
                    fullWidth
                    rowCount={3}
                    fontLabelWeight="600"
                  />
                  <ErrorText
                    hasError={!!errors.otherRequirements}
                    errorText={t('pages.website.custom.form.otherRequirementsRequired')}
                  />
                </Grid>
              )}
            </Grid>
          </form>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </>
  );
}
