import { StepperRootProps } from 'layouts/StepperLayout';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { Typography, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  getCustomWebsite, putCustomWebsiteExamples, selectCustomWebsite,
} from 'services/customWebsiteSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { Urls } from 'navigation/Urls';
import { selectActiveProductId } from 'services/navigationSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import ErrorText from 'components/errorText/ErrorText';
import schema from './Examples.schema';

export default function CustomWebsiteExamples(props: StepperRootProps) {
  const { t } = useTranslation();
  const navigate = useNavigationHandler();
  const { orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const customWebsite = useSelector(selectCustomWebsite);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      examples: '',
    },
  });

  const {
    getValues, handleSubmit, reset, register, formState: {
      isDirty, isSubmitting, isSubmitSuccessful, isValid, errors,
    },
  } = methods;

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid]);

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsite(websiteId.toString()));
  }, [websiteId]);

  useEffect(() => {
    reset({
      examples: customWebsite.examples,
    });
  }, [customWebsite]);

  const onFormSubmit = () => {
    const data = getValues();
    const content = { examples: data.examples };
    dispatch(putCustomWebsiteExamples({ orderItemId, content }));
    navigate.to(Urls.CustomWebsiteAdditionalInfo);
  };

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container marginTop={2}>
            <Grid item xs={6} marginBottom={5}>
              <Typography variant="h2">{t('pages.website.custom.examples.pageTitle')}</Typography>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6} marginBottom={3}>
              <NeFormTextArea
                {...register('examples')}
                id="examples"
                name="examples"
                labelText={t('pages.website.custom.examples.websiteUrls')}
                tooltipText={t('pages.website.custom.examples.websiteUrlsTooltip')}
                tooltipPaddingBottom={18}
                tooltipPlacement="top"
                fullWidth
                rowCount={3}
                fontLabelWeight="600"
              />
              <ErrorText
                hasError={!!errors.examples}
                errorText={t('pages.website.custom.examples.websiteUrlsRequired')}
              />
            </Grid>
          </Grid>
        </form>
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
