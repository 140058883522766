/* eslint-disable import/no-named-as-default */
/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import { AxiosCarbonAPI } from 'services/common/axios';
import store, { RootState } from './redux/store';

export interface Command {
  name: string
  status: 'loading' | 'success' | 'failed'
}

export interface CarbonState {
  commands: StatusType<Command[]>
}

const initialState: CarbonState = {
  commands: {
    status: 'loading',
    message: '',
    content: [],
  },
};

const setSuccess = createAction<any>('carbon/commands/success');
const setFailed = createAction<any>('carbon/commands/failed');

const reportResponse = (res, cmd = '') => {
  if (res === 'success') {
    store.dispatch(setSuccess({ command: cmd, result: res }));
  } else {
    console.log('axios has failed.');
    store.dispatch(setFailed({ command: cmd, result: res }));
  }
};

const changeSitePlan = (siteName, choice) => {
  const annual = 'plan-basic_small-contract-annual-1';
  const sandbox = 'plan-free-preferred-monthly-1';
  let data;

  if (choice === 'upgrade') {
    data = { carbonCommand: { command: `plan:set ${siteName} ${annual}` } };
    console.log(`upgrading ${siteName}`);
  } else {
    data = { carbonCommand: { command: `plan:set ${siteName} ${sandbox}` } };
    console.log(`downgrading ${siteName}`);
  }

  AxiosCarbonAPI.post('/carbon/run-commands', data)
    // eslint-disable-next-line consistent-return
    .then(response => {
      if (JSON.stringify(response.data.toLowerCase()).match('error')) { return reportResponse('failure', choice); }
      if (JSON.stringify(response.status) === '200') { return reportResponse('success', choice); }
    })
    .catch(err => {
      console.log(JSON.stringify(err)); return reportResponse('failure', choice);
    });
};

// eslint-disable-next-line consistent-return
const parseDisableParams = (pluginName, siteName, environment) => {
  console.log('pluginName: ', pluginName);
  if (pluginName.match('connect')) {
    console.log('disabling OKTA');
    return `wp ${siteName}.${environment} -- plugin deactivate wp-saml-auth`;
  }
  if (pluginName.match('config')) {
    console.log('disabling SendGrid');
    return `wp ${siteName}.${environment} -- plugin deactivate sendgrid-email-delivery-simplified wp-mail-smtp`;
  }
  if (pluginName.match('userway')) {
    console.log('disabling Userway');
    return `wp:userway:remove ${siteName}.${environment}`;
  }
};

const unfreezeSite = (siteName) => {
  const data = { carbonCommand: { command: `site:thaw ${siteName}` } };
  AxiosCarbonAPI.post('/carbon/run-commands', data)
    // eslint-disable-next-line consistent-return
    .then(response => {
      if (JSON.stringify(response.data).match('ERROR')) { return reportResponse('failure', 'Unfreeze'); }
      if (JSON.stringify(response.status) === '200') { return reportResponse('success', 'Unfreeze'); }
    })
    .catch(err => { console.log(JSON.stringify(err.response)); return reportResponse('failure', 'Unfreeze'); });
};

const speakToTerminus = (siteName, commands, environment) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const commandKeys = Object.keys(commands);
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const c in commandKeys) {
    const newKey = commandKeys[c];
    // eslint-disable-next-line no-continue
    if (Object.keys(commands[newKey]).length === 0) { continue; }

    let data = {};
    if (commands[newKey].command.match('userway')) {
      data = { carbonCommand: { command: `${commands[newKey].command} ${siteName}.${environment}` } };
    } else {
      data = { carbonCommand: { command: `${commands[newKey].command}:${newKey} ${siteName}.${environment}` } };
    }
    if (commands[newKey].action === 'disable') {
      const pluginName = commands[newKey].command;
      const disableCommand = parseDisableParams(pluginName, siteName, environment);
      data = { carbonCommand: { command: `${disableCommand}` } };
    }

    AxiosCarbonAPI.post('/carbon/run-commands', data)
      // eslint-disable-next-line consistent-return
      .then(response => {
        console.log('axios response is ', response);
        if (JSON.stringify(response.status) === '200') {
          return reportResponse('success', newKey);
        }
      })
      .catch(err => {
        console.log(JSON.stringify(err)); return reportResponse('failure', newKey);
      });
  }
};

export const runCommands = createAction<any>('carbon/commands/run');

export const carbonSlice = createReducer(
  initialState,
  builder => builder
    .addCase(setSuccess, (state, { payload }) => {
      state.commands.status = 'idle';
      state.commands.content = [...state.commands.content]
        .map(obj => {
          if (obj.name.toLowerCase().match(payload.command)) {
            return { ...obj, status: 'success' };
          }
          return obj;
        });

      if (payload.command === 'Unfreeze') {
        state.commands.content = [...state.commands.content]
          .map(obj => ({ ...obj, name: 'Unfreeze', status: 'success' }));
      }
    })
    .addCase(setFailed, (state, { payload }) => {
      state.commands.status = 'failed';
      state.commands.content = [...state.commands.content]
        .map(obj => {
          if (obj.name.toLowerCase().match(payload.command)) {
            return { ...obj, status: 'failed' };
          }
          return obj;
        });

      if (payload.command === 'Unfreeze') {
        state.commands.content = [...state.commands.content]
          .map(obj => ({ ...obj, name: 'Unfreeze', status: 'failed' }));
      }
    })

    .addCase(runCommands, (state, { payload }) => {
      const commandList = [];
      const commandsToRun = { okta: {}, sendgrid: {}, userway: {} };

      if (payload.unfreeze === 'yes') {
        commandList.push('Unfreeze');
        state.commands.content = commandList.map(name => ({ name, status: 'loading' }));
        unfreezeSite(payload.siteName);
        return;
      }
      if (payload.upgrade === 'yes') {
        commandList.push('Upgrade');
        state.commands.content = commandList.map(name => ({ name, status: 'loading' }));
        changeSitePlan(payload.siteName, 'upgrade');
        return;
      }
      if (payload.downgrade === 'yes') {
        commandList.push('Downgrade');
        state.commands.content = commandList.map(name => ({ name, status: 'loading' }));
        changeSitePlan(payload.siteName, 'downgrade');
        return;
      }
      if (payload.okta) {
        commandList.push(`${payload.okta} Okta (${payload.environment})`);
        commandsToRun.okta = { command: 'wp:connect', action: payload.okta };
      }

      if (payload.userway) {
        commandList.push(`${payload.userway} Userway (${payload.environment})`);
        commandsToRun.userway = { command: 'wp:userway:add ', action: payload.userway };
      }

      if (payload.sendgrid) {
        commandList.push(`${payload.sendgrid} SendGrid (${payload.environment})`);
        commandsToRun.sendgrid = { command: 'localiq:wp:config', action: payload.sendgrid };
      }

      speakToTerminus(payload.siteName, commandsToRun, payload.environment);
      state.commands.content = commandList.map(name => ({ name, status: 'loading' }));
    }),
);

export const selectCommands = (state: RootState) => state.carbon.commands;

export default carbonSlice;
