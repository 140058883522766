import * as yup from 'yup';

const schema = {
  offeringType: yup.string(),
  servicesBooked: yup.string().required(),
  staffMembers: yup.number()
    .typeError('Please enter a numerical value')
    .min(0, 'Please select a positive number'),
  appointmentTimeAvailability: yup.string().required('Please select'),
  paymentsCollected: yup.string().required('Please select'),
  paymentsGateway: yup.string().required('Please select'),
  acknowledge: yup.boolean()
    .required('Select Acknowledge')
    .oneOf([true], 'Please check acknowledgement to continue.'),
  additionalNotesInstructions: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {

  },
};
