import * as yup from 'yup';

const schema = {
  services: yup.array().optional(),
  areas: yup.array().optional(),
  keywords: yup.array().max(5, 'Please review and only add up to five items to continue.').optional(),
  competitors: yup.string().optional(),
  strategy: yup.string().optional(),
  additionalNotes: yup.string().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
