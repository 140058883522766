import * as yup from 'yup';

const schema = {
  selectedConversionGoal: yup.string().required('This is a required field.'),
  otherGoalsNotes: yup.string().required('Please enter other primary conversion goal.').nullable(),
  conversionGoal: yup.string().required('Please enter conversion goal details.'),
  additionalGoalsNotes: yup.string().optional(),
  preferredEmailPrimary: yup.string().optional(),
  preferredEmailSecondary: yup.string().optional(),
  secondaryConversionGoal: yup.string().required(),
  otherSecondaryGoalNotes: yup.string().required('Please enter other secondary conversion goal.').nullable(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
