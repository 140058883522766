import i18n from 'i18n/i18n';
import {
  TrackingCustomValidate,
  TrackingSchema, TrackingUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/TrackingSchema';
import {
  TrackingNumberDetailsCustomValidate,
  TrackingNumberDetailsSchema, TrackingNumberDetailsUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/TrackingNumberDetails';
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema, NotesAndInstructionsUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/NotesAndInstructionsSchema';
import {
  WebEventsCustomValidate,
  WebEventsSchema,
  WebEventsUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/WebEventsSchema';
import {
  CallTrackingCustomValidate,
  CallTrackingSchema,
  CallTrackingUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/CallTrackingSchema';
import {
  CustomFacebookCustomValidate,
  CustomFacebookSchema, CustomFacebookUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/CustomFacebookSchema';
import {
  TargetingIndentedCustomValidate,
  TargetingIndentedSchema, TargetingIndentedUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/TargetingIndentedSchema';
import {
  AudiencesIndentedCustomValidate,
  AudiencesIndentedSchema, AudiencesIndentedUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/AudiencesIndentedSchema';
import {
  AdSchedulingIndentedCustomValidate,
  AdSchedulingIndentedSchema, AdSchedulingIndentedUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/AdSchedulingIndentedSchema';
import {
  CustomFacebookMainSectionCustomValidate,
  CustomFacebookMainSectionSchema, CustomFacebookMainSectionUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/CustomFacebookMainSectionSchema';
import {
  TrackingSectionCustomValidate,
  TrackingSectionSchema,
  TrackingSectionUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/TrackingSectionSchema';
import {
  SpecificRequirementsCustomValidate,
  SpecificRequirementsSchema, SpecificRequirementsUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/SpecificRequirementsSchema';
import {
  ObjectiveDetailsCustomValidate,
  ObjectiveDetailsSchema, ObjectiveDetailsUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/ObjectiveDetailsSchema';
import {
  CampaignCustomFacebookSchema,
  CampaignCustomFacebookUISchema,
  CampaignCustomFacebookValidate
} from "pages/generic-product/details/product-schema/aus-product-schema/CampaignCustomFacebookSchema";

const {t} = i18n;

export const AusCustomFacebookSchema = (data) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.ausProduct.customFacebookMainSection.solutionStrategyTitle'),
      schema: CampaignCustomFacebookSchema(data),
      uiSchema: CampaignCustomFacebookUISchema,
      customValidate: CampaignCustomFacebookValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.tracking.title'),
      schema: TrackingSchema(data),
      uiSchema: TrackingUISchema,
      customValidate: TrackingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.customFacebook.title'),
      schema: CustomFacebookSchema(data),
      uiSchema: CustomFacebookUISchema,
      customValidate: CustomFacebookCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.targetingIndented.title'),
      schema: TargetingIndentedSchema(data),
      uiSchema: TargetingIndentedUISchema,
      customValidate: TargetingIndentedCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.audiencesIndented.title'),
      schema: AudiencesIndentedSchema(data, t('pages.ausProduct.audiencesIndented.title')),
      uiSchema: AudiencesIndentedUISchema,
      customValidate: AudiencesIndentedCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.customFacebookMainSection.title'),
      schema: CustomFacebookMainSectionSchema(data),
      uiSchema: CustomFacebookMainSectionUISchema,
      customValidate: CustomFacebookMainSectionCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.trackingSection.title'),
      schema: TrackingSectionSchema(data),
      uiSchema: TrackingSectionUISchema,
      customValidate: TrackingSectionCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.objectiveDetails.title'),
      schema: ObjectiveDetailsSchema(data),
      uiSchema: ObjectiveDetailsUISchema,
      customValidate: ObjectiveDetailsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ];

  const adSchedulingRequirements = data?.requireAdScheduling;
  const happyForCorrectFormattedUtmStringRequirements = data?.happyForCorrectFormattedUtmString;
  const trackingRequirements = data?.trackingRequirements;
  const indexToInsert = 4;

  if (adSchedulingRequirements === 'Yes') {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.adSchedulingIndented.title'),
      schema: AdSchedulingIndentedSchema(data),
      uiSchema: AdSchedulingIndentedUISchema,
      customValidate: AdSchedulingIndentedCustomValidate,
    });
    if (happyForCorrectFormattedUtmStringRequirements === 'No') {
      schema.splice(indexToInsert + 3, 0, {
        type: 'object',
        title: t('pages.ausProduct.specificRequirements.title'),
        schema: SpecificRequirementsSchema(data),
        uiSchema: SpecificRequirementsUISchema,
        customValidate: SpecificRequirementsCustomValidate,
      });
      if (trackingRequirements === 'Call and Web Event Tracking') {
        schema.splice(indexToInsert + 5, 0, {
          type: 'object',
          title: t('pages.ausProduct.webEvents.title'),
          schema: WebEventsSchema(data),
          uiSchema: WebEventsUISchema(data),
          customValidate: WebEventsCustomValidate,
        });
        schema.splice(indexToInsert + 6, 0, {
          type: 'object',
          title: t('pages.ausProduct.callTracking.title'),
          schema: CallTrackingSchema(data),
          uiSchema: CallTrackingUISchema,
          customValidate: CallTrackingCustomValidate,
        });
        schema.splice(indexToInsert + 7, 0, {
          type: 'object',
          title: t('pages.ausProduct.trackingNumber1Details.title'),
          schema: TrackingNumberDetailsSchema(data),
          uiSchema: TrackingNumberDetailsUISchema(data),
          customValidate: TrackingNumberDetailsCustomValidate,
        });
      } else if (trackingRequirements === 'Web Event Tracking') {
        schema.splice(indexToInsert + 5, 0, {
          type: 'object',
          title: t('pages.ausProduct.webEvents.title'),
          schema: WebEventsSchema(data),
          uiSchema: WebEventsUISchema(data),
          customValidate: WebEventsCustomValidate,
        });
      } else if (trackingRequirements === 'Call Tracking') {
        schema.splice(indexToInsert + 5, 0, {
          type: 'object',
          title: t('pages.ausProduct.callTracking.title'),
          schema: CallTrackingSchema(data),
          uiSchema: CallTrackingUISchema,
          customValidate: CallTrackingCustomValidate,
        });
        schema.splice(indexToInsert + 6, 0, {
          type: 'object',
          title: t('pages.ausProduct.trackingNumber1Details.title'),
          schema: TrackingNumberDetailsSchema(data),
          uiSchema: TrackingNumberDetailsUISchema(data),
          customValidate: TrackingNumberDetailsCustomValidate,
        });
      }
    } else if (trackingRequirements === 'Call and Web Event Tracking') {
      schema.splice(indexToInsert + 4, 0, {
        type: 'object',
        title: t('pages.ausProduct.webEvents.title'),
        schema: WebEventsSchema(data),
        uiSchema: WebEventsUISchema(data),
        customValidate: WebEventsCustomValidate,
      });
      schema.splice(indexToInsert + 5, 0, {
        type: 'object',
        title: t('pages.ausProduct.callTracking.title'),
        schema: CallTrackingSchema(data),
        uiSchema: CallTrackingUISchema,
        customValidate: CallTrackingCustomValidate,
      });
      schema.splice(indexToInsert + 6, 0, {
        type: 'object',
        title: t('pages.ausProduct.trackingNumber1Details.title'),
        schema: TrackingNumberDetailsSchema(data),
        uiSchema: TrackingNumberDetailsUISchema(data),
        customValidate: TrackingNumberDetailsCustomValidate,
      });
    } else if (trackingRequirements === 'Web Event Tracking') {
      schema.splice(indexToInsert + 4, 0, {
        type: 'object',
        title: t('pages.ausProduct.webEvents.title'),
        schema: WebEventsSchema(data),
        uiSchema: WebEventsUISchema(data),
        customValidate: WebEventsCustomValidate,
      });
    } else if (trackingRequirements === 'Call Tracking') {
      schema.splice(indexToInsert + 4, 0, {
        type: 'object',
        title: t('pages.ausProduct.callTracking.title'),
        schema: CallTrackingSchema(data),
        uiSchema: CallTrackingUISchema,
        customValidate: CallTrackingCustomValidate,
      });
      schema.splice(indexToInsert + 5, 0, {
        type: 'object',
        title: t('pages.ausProduct.trackingNumber1Details.title'),
        schema: TrackingNumberDetailsSchema(data),
        uiSchema: TrackingNumberDetailsUISchema(data),
        customValidate: TrackingNumberDetailsCustomValidate,
      });
    }
  } else if (happyForCorrectFormattedUtmStringRequirements === 'No') {
    schema.splice(indexToInsert + 2, 0, {
      type: 'object',
      title: t('pages.ausProduct.specificRequirements.title'),
      schema: SpecificRequirementsSchema(data),
      uiSchema: SpecificRequirementsUISchema,
      customValidate: SpecificRequirementsCustomValidate,
    });
    if (trackingRequirements === 'Call and Web Event Tracking') {
      schema.splice(indexToInsert + 4, 0, {
        type: 'object',
        title: t('pages.ausProduct.webEvents.title'),
        schema: WebEventsSchema(data),
        uiSchema: WebEventsUISchema(data),
        customValidate: WebEventsCustomValidate,
      });
      schema.splice(indexToInsert + 5, 0, {
        type: 'object',
        title: t('pages.ausProduct.callTracking.title'),
        schema: CallTrackingSchema(data),
        uiSchema: CallTrackingUISchema,
        customValidate: CallTrackingCustomValidate,
      });
      schema.splice(indexToInsert + 6, 0, {
        type: 'object',
        title: t('pages.ausProduct.trackingNumber1Details.title'),
        schema: TrackingNumberDetailsSchema(data),
        uiSchema: TrackingNumberDetailsUISchema(data),
        customValidate: TrackingNumberDetailsCustomValidate,
      });
    } else if (trackingRequirements === 'Web Event Tracking') {
      schema.splice(indexToInsert + 4, 0, {
        type: 'object',
        title: t('pages.ausProduct.webEvents.title'),
        schema: WebEventsSchema(data),
        uiSchema: WebEventsUISchema(data),
        customValidate: WebEventsCustomValidate,
      });
    } else if (trackingRequirements === 'Call Tracking') {
      schema.splice(indexToInsert + 4, 0, {
        type: 'object',
        title: t('pages.ausProduct.callTracking.title'),
        schema: CallTrackingSchema(data),
        uiSchema: CallTrackingUISchema,
        customValidate: CallTrackingCustomValidate,
      });
      schema.splice(indexToInsert + 5, 0, {
        type: 'object',
        title: t('pages.ausProduct.trackingNumber1Details.title'),
        schema: TrackingNumberDetailsSchema(data),
        uiSchema: TrackingNumberDetailsUISchema(data),
        customValidate: TrackingNumberDetailsCustomValidate,
      });
    }
  } else if (trackingRequirements === 'Call and Web Event Tracking') {
    schema.splice(indexToInsert + 3, 0, {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    });
    schema.splice(indexToInsert + 4, 0, {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    });
    schema.splice(indexToInsert + 5, 0, {
      type: 'object',
      title: t('pages.ausProduct.trackingNumber1Details.title'),
      schema: TrackingNumberDetailsSchema(data),
      uiSchema: TrackingNumberDetailsUISchema(data),
      customValidate: TrackingNumberDetailsCustomValidate,
    });
  } else if (trackingRequirements === 'Web Event Tracking') {
    schema.splice(indexToInsert + 3, 0, {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    });
  } else if (trackingRequirements === 'Call Tracking') {
    schema.splice(indexToInsert + 3, 0, {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    });
    schema.splice(indexToInsert + 4, 0, {
      type: 'object',
      title: t('pages.ausProduct.trackingNumber1Details.title'),
      schema: TrackingNumberDetailsSchema(data),
      uiSchema: TrackingNumberDetailsUISchema(data),
      customValidate: TrackingNumberDetailsCustomValidate,
    });
  }
  return schema;
};
export const AusCustomFacebookUISchema = (data) => ({
  'ui:order': [
    ...CampaignCustomFacebookUISchema['ui:order'],
    ...TrackingUISchema['ui:order'],
    ...CustomFacebookUISchema['ui:order'],
    ...TargetingIndentedUISchema['ui:order'],
    ...AudiencesIndentedUISchema['ui:order'],
    ...AdSchedulingIndentedUISchema['ui:order'],
    ...CustomFacebookMainSectionUISchema['ui:order'],
    ...TrackingSectionUISchema['ui:order'],
    ...SpecificRequirementsUISchema['ui:order'],
    ...ObjectiveDetailsUISchema['ui:order'],
    ...WebEventsUISchema(data)['ui:order'],
    ...CallTrackingUISchema['ui:order'],
    ...TrackingNumberDetailsUISchema(data)['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AusCustomFacebookCustomValidate = (formData: any, errors: any, uiSchema: any) => errors;
