/* eslint-disable react/no-array-index-key */
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import { useParams } from 'react-router';
import { getProduct } from 'services/productsSlice';
import XmoBrandAmplifierSectionExternal from 'pages/overview/sections/xmo/XmoBrandAmplifierSectionExternal';
import { ActionItems } from '../common/ActionItems';

export default function XmoExternalSection({product}: any) {
  const appDispatch = useAppDispatch();
  const {orderId} = useParams();
  const [xmoData, setXmoData] = useState({} as any);

  const getProductData = (orderItemId: any) => {
    appDispatch(getProduct({
      orderItemId,
    })).then((response) => setXmoData(response.payload));
  };

  useEffect(() => {
    if (product) {
      getProductData(product?.id);
    }
  }, [product, orderId]);

  const renderFormFieldsData = () => {
    if (xmoData?.data?.variant === 3) {
      return (
          <XmoBrandAmplifierSectionExternal productData={xmoData?.data}/>
      );
    }
    return null;
  };

  return (
      <Grid container sx={{borderTop: '1px solid #716F6F'}}>
        {renderFormFieldsData()}
        {/* Actions items */}
        {ActionItems(false, product?.id, null, null, false, () => {
        }, null)}
      </Grid>
  );
}
