import {
  AppBar, Button, Container, Toolbar, Typography, useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IQTopNavIcon from 'icons/IQTopNavIcon.svg';
import React, { forwardRef, useEffect, useState } from 'react';
import { Urls } from 'navigation/Urls';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  ThemeFilterType, resetCurrent,
  selectMenuThemeTypes, setFilter, setFilteredThemes,
} from 'services/themesSlice';
import { selectOrdersContent } from 'services/ordersSlice';
import { getCartItemList } from 'services/cartSlice';
import { getUserInfo, selectUserRole } from 'services/userSlice';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { useTranslation } from 'react-i18next';
import { selectCurrentOffer } from 'services/cefSlice';
import CoCart from '../CoCart';

const NavButton = forwardRef((props: any, ref) => {
  const theme = useTheme();

  return (
    <Button sx={{
      color: '#ffffff',
      fontWeight: 'normal',
      zIndex: 100000,
      padding: '0 15px !important',
      height: '100%',
      borderRadius: 0,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      borderBottom: props?.active ? `4px solid ${theme.palette.success.main}` : 'none',
    }}
      ref={ref}
      {...props}
    >
      {props.children}
    </Button>
  );
});

export default function TopNav() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useSelector(selectUserRole);
  const menuThemes = useSelector(selectMenuThemeTypes);
  const currentOrder = useSelector(selectOrdersContent);
  const currentOffer = useSelector(selectCurrentOffer);
  const [isLeaving, setIsLeaving] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');
  const [isExisting, setIsExisting] = useState(false);

  useEffect(() => {
    if (!userRole) dispatch(getUserInfo('me'));
  }, []);

  useEffect(() => {
    if (currentOrder.id) {
      dispatch(getCartItemList(currentOrder.id));
    }
  }, [currentOrder]);

  useEffect(() => {
    if (Object.keys(currentOffer).length > 0) setIsExisting(currentOffer?.incompleteOrders?.length > 0);
  }, [currentOffer]);

  const redirect = async () => {
    setIsExisting(false);
    await dispatch(setFilter({ type: ThemeFilterType.PRODUCT_TYPE, value: '' }));
    const filtered = menuThemes.filter(theme => (theme.featured && theme.active));
    await dispatch(setFilteredThemes(filtered));
    dispatch(resetCurrent({}));
    navigate(`/${redirectTo}`);
  };

  const redirectToMyOrders = async () => {
    setIsExisting(false);
    await dispatch(setFilter({ type: ThemeFilterType.PRODUCT_TYPE, value: '' }));
    const filtered = menuThemes.filter(theme => (theme.featured && theme.active));
    await dispatch(setFilteredThemes(filtered));
    dispatch(resetCurrent({}));
    navigate(`/${Urls.Orders}`);
  };

  const startNewOrder = () => {
    setIsExisting(false);
  };

  const leavingRedirect = (to) => {
    setIsLeaving(true);
    setRedirectTo(to);
  };

  return (
    <AppBar position="fixed"
      sx={{
        backgroundColor: '#000000',
        height: '50px',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Container sx={{
        maxWidth: 'initial !important',
        marginLeft: 0,
        marginRight: 0,
      }}
      >
        <Toolbar
          disableGutters
          sx={{ minHeight: '50px !important', padding: '0 !important', justifyContent: 'space-between' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 !important' }}>
            <Box onClick={() => leavingRedirect(Urls.Themes)}
              sx={{ cursor: 'pointer', padding: '0 !important', width: '126px' }}
            >
              <img src={IQTopNavIcon} alt="IQTopNavIcon" />
            </Box>
            <Box sx={{ padding: '0 !important', marginLeft: '40px', height: '50px' }}>
              <NavButton active>
                Opportunity
              </NavButton>
            </Box>
            <Box sx={{ padding: '0 !important', marginLeft: '20px', height: '50px' }}>
              <NavButton onClick={() => leavingRedirect(Urls.Orders)}>
                My Orders
                <OpenInNewIcon sx={{ fontSize: '12px', marginLeft: '10px' }} />
              </NavButton>
            </Box>
          </Box>
          <Box sx={{ padding: '0 !important', marginLeft: '20px', height: '50px' }}>
            <CoCart />
          </Box>
        </Toolbar>
      </Container>
      {isLeaving && (
        <CoModalConfirm open={isLeaving}
          title={t('pages.opportunity.leaveModal.title')}
          description={(
            <Box>
              <Typography mb={2}>
                {t('pages.opportunity.leaveModal.description')}
              </Typography>
              <Typography>
                <b>Note: </b>
                {t('pages.opportunity.leaveModal.note')}
              </Typography>
            </Box>
          )}
          confirmText={t('pages.opportunity.leaveModal.confirmText')}
          cancelText={t('pages.opportunity.leaveModal.cancelText')}
          handleClose={() => setIsLeaving(false)}
          handleConfirm={redirect}
        />
      )}
      {isExisting && (
        <CoModalConfirm open={isExisting && !window.location.href.includes('shoppingCart')}
          title={t('pages.opportunity.existingOpportunity.title')}
          description={(
            <Typography mb={2}>
              {t('pages.opportunity.existingOpportunity.description')}
            </Typography>
          )}
          confirmText={t('pages.opportunity.existingOpportunity.confirmText')}
          cancelText={t('pages.opportunity.existingOpportunity.cancelText')}
          handleClose={startNewOrder}
          handleConfirm={redirectToMyOrders}
        />
      )}
    </AppBar>
  );
}
