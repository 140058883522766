import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import GridItem from 'components/grid/GridItem';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { Fragment, useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import {
  getBusinessLocationServiceAreas, getLocationSpecialHours, getProvider, getProviderCategories, setSpecialHours,
} from 'services/businessLocationSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { useDispatch } from 'react-redux';
import { headingStyle } from './BusinessSection';

export const renderSocialUrls = (urls) => {
  const socials = [
    {
      title: 'Facebook',
      url: urls?.facebookUrl,
    },
    {
      title: 'Instagram',
      url: urls?.instagramUrl,
    },
    {
      title: 'LinkedIn',
      url: urls?.linkedinUrl,
    },
    {
      title: 'Pinterest',
      url: urls?.pinterestUrl,
    },
    {
      title: 'Twitter',
      url: urls?.twitterUrl,
    },
    {
      title: 'Vimeo',
      url: urls?.vimeoUrl,
    },
    {
      title: 'Youtube',
      url: urls?.youtubeUrl,
    },
  ];

  if (socials.every(social => !social.url)) return <Typography fontWeight="bold">No social media</Typography>;

  return socials.map(social => social.url && (
    <Typography key={social.url}>{`${social.title}: ${social.url}`}</Typography>));
};

const formatLocationHours = (hours: any) => {
  const from = JSON.parse(hours.fromX).from1;
  const to = JSON.parse(hours.toX).to1;

  const from2 = JSON.parse(hours.fromX)?.from2;
  const to2 = JSON.parse(hours.toX)?.to2;

  if (from2 && to2) {
    return (
      <>
        {`${hours.dayOfTheWeek} -- ${from} - ${to}`}
        <br />
        {`${hours.dayOfTheWeek} -- ${from2} - ${to2}`}
      </>
    );
  }
  return `${hours.dayOfTheWeek} -- ${from} - ${to}`;
};

const getBusinessLogo = (location) => {
  const data = JSON.parse(location.logoAttachments || '{"URL": "[]"}');
  const logos = data.URL ? JSON.parse(data.URL) : '';
  return logos?.at(0);
};

const getSquareLogo = (location) => {
  const data = JSON.parse(location.squareLogoAttachments || '{"URL": "[]"}');
  const logos = data.URL ? JSON.parse(data.URL) : '';
  return logos?.at(0);
};

export default function LocationSection({
  location, index, isPlProduct, isHCProduct, locationType, isEditable,
}: any) {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [providerData, setProviderData] = useState({} as any);
  const [providerCategories, setProviderCategories] = useState([]);
  const [specialHoursData, setSpecialHoursData] = useState([]);
  const [serviceAreas, setServiceAreas] = useState([]);
  const navigate = useNavigationHandler();

  useEffect(() => {
    if (location?.id) {
      appDispatch(getBusinessLocationServiceAreas(location.id)).then((response) => setServiceAreas(response.payload));
    }
  }, [location]);

  const getSpecialHours = (id) => {
    appDispatch(getLocationSpecialHours(id)).then((response) => setSpecialHoursData(response.payload));
  };

  useEffect(() => {
    if (location?.id) {
      getSpecialHours(location.id);
      if (location.isProvider && location.providerId) {
        appDispatch(getProvider(location.providerId)).then((response) => setProviderData(response.payload));
        appDispatch(getProviderCategories(location.providerId))
          .then((response) => setProviderCategories(response.payload));
      }
    }
  }, [location]);

  const getLocationNavUrl = (page) => {
    if (isHCProduct) {
      if (locationType === 'provider') {
        return `premiumlistings/healthcare/provider/${location.id.toString()}/${page}`;
      }
      return `premiumlistings/healthcare/location/${location.id.toString()}/${page}`;
    }

    if (isPlProduct) {
      return `premiumlistings/location/${location.id.toString()}/${page}`;
    }
    return `/businesses/location/${location.id.toString()}/${page}`;
  };

  const splitProviderCredentials = (credentials) => {
    if (credentials) {
      const splitCredentials = credentials?.split(',');
      return (
        <Typography>
          {splitCredentials.map((cred) => (
            <Fragment key={cred}>
              {cred}
              <br />
            </Fragment>
          ))}
        </Typography>
      );
    }

    return <Typography fontWeight="bold">No credentials</Typography>;
  };

  const renderInfoSection = () => (
    <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
      <Box>
        <Box mb={2}>
          <Typography sx={headingStyle} color="primary">Attributes</Typography>
          {location?.categoryAttributes?.length
            ? location.categoryAttributes
              .map(att => (
                <Typography key={att.id} mb={1}>
                  {`${att.name}: `}
                  {att.valueType === 'URL'
                    ? <Link href={att.value} target="_blank" rel="noopener noreferrer">{att.value}</Link>
                    : att.value}
                </Typography>
              ))
            : <Typography fontWeight="bold">No attributes</Typography>}
        </Box>
        <Box mb={2}>
          <Typography sx={headingStyle} color="primary">Short Description</Typography>
          {displayNotRequiredField('short description', location?.shortDescription)}
        </Box>
      </Box>
      <Box>
        <Box mb={2}>
          <Typography sx={headingStyle} color="primary">Long Description</Typography>
          {displayNotRequiredField('long description', location?.longDescription)}
        </Box>
        <Box mb={2}>
          <Typography sx={headingStyle} color="primary">Keywords</Typography>
          {location?.keywordsList?.length
            ? location.keywordsList.map(keyword => <Typography key={keyword.id}>{keyword.name}</Typography>)
            : <Typography fontWeight="bold">No keywords</Typography>}
        </Box>
      </Box>
    </GridItem>
  );

  const renderProviderSection = () => (
    <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
      <Box>
        <Box mb={2}>
          <Typography sx={headingStyle} color="primary">Doctor-Specific Categories</Typography>
          {providerCategories?.length
            ? providerCategories.map(cat => <Typography key={cat.id}>{cat.name}</Typography>)
            : <Typography fontWeight="bold">No categories</Typography>}
        </Box>
        <Box mb={2}>
          <Typography sx={headingStyle} color="primary">Practice Name</Typography>
          {displayNotRequiredField('practice name', providerData?.practiceName)}
        </Box>
        <Box mb={2}>
          <Typography sx={headingStyle} color="primary">Provider Name</Typography>
          {(providerData?.firstName && providerData?.lastName)
            ? (
              <Typography sx={{ textTransform: 'capitalize' }}>
                {/* eslint-disable-next-line max-len */}
                {`${providerData?.title && `${providerData.title}.`} ${providerData.firstName}${providerData?.middleName && ` ${providerData?.middleName}`} ${providerData.lastName}`}
              </Typography>
            ) : <Typography fontWeight="bold">No name provided</Typography>}
        </Box>
        <Box mb={2}>
          <Typography sx={headingStyle} color="primary">NPI</Typography>
          {displayNotRequiredField('NPI', providerData?.nationalProviderIdentifier)}
        </Box>
        <Box>
          <Typography sx={headingStyle} color="primary">Credentials/Degrees</Typography>
          {splitProviderCredentials(providerData?.credentials)}
        </Box>
      </Box>
      <Box>
        <Box mb={2}>
          <Typography sx={headingStyle} color="primary">University</Typography>
          {displayNotRequiredField('university', providerData?.university)}
        </Box>
        <Box mb={2}>
          <Typography sx={headingStyle} color="primary">Hospital Affiliations</Typography>
          {displayNotRequiredField('hospital affiliations', providerData?.hospitalAffiliations)}
        </Box>
        <Box>
          <Typography sx={headingStyle} color="primary">Insurances Accepted</Typography>
          {displayNotRequiredField('insurances accepted', providerData?.insurancesAccepted)}
        </Box>
      </Box>
    </GridItem>
  );

  const getFileCategory = (photo) => {
    if (photo?.additionalDetails !== null) {
      const str = JSON.parse(photo?.additionalDetails);
      return `${str?.photoCategory} - `;
    }
    return 'additional - ';
  };

  return (
    <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {isPlProduct && (
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          Premium Listings:
          <br />
          {`Location ${index + 1}`}
        </Typography>
        )}
        {isHCProduct && (
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          Healthcare Listings:
          <br />
          {`${location.isProvider ? 'Provider' : 'Location'} ${index + 1}`}
        </Typography>
        )}
        {(!isPlProduct && !isHCProduct) && (
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          {`Location ${index + 1}`}
        </Typography>
        )}
      </Box>
      {/* Address */}
      <Box>
        <Box sx={{ display: 'flex', marginBottom: '10px' }}>
          {editButton(isEditable, () => navigate.to(getLocationNavUrl(Urls.PremiumListingsAddress)))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Address</Typography>
        </Box>
        <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Location Name</Typography>
              <Typography>{location.locationName}</Typography>
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Address</Typography>
              <Typography>{location.businessAddressOne}</Typography>
              {location?.businessAddressTwo && <Typography>{location?.businessAddressTwo}</Typography>}
              <Typography>{`${location.city}, ${location.stateProvince} ${location.postalCode}`}</Typography>
              <Typography>{location.country}</Typography>
            </Box>
            {(isPlProduct || isHCProduct) && (
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Hide Business Address</Typography>
              <Typography>{location?.doesNotServeClients ? 'Yes' : 'No'}</Typography>
            </Box>
            )}
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Service Area</Typography>
              {serviceAreas?.filter(area => area.locationId === location.id).map(area => (
                <Typography key={area.name}>{area.name}</Typography>
              ))}
              {serviceAreas?.filter(area => area.locationId === location.id).length === 0 && (
              <Typography fontWeight="bold">No service areas</Typography>)}
            </Box>
            {(isPlProduct || isHCProduct) && (
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Location Identifier (Store Number)</Typography>
              {displayNotRequiredField('number provided', location?.locationIdentifier)}
            </Box>
            )}
          </Box>
        </GridItem>

        {/* Provider */}
        {(isHCProduct && location.isProvider) && (
          <Box sx={{ display: 'flex', margin: '10px 0' }}>
            {editButton(isEditable, () => navigate.to(getLocationNavUrl(Urls.PlHealthcareProvider)))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Provider</Typography>
          </Box>
        )}
        {(isHCProduct && location.isProvider) && renderProviderSection()}

        {/* Contact */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () => navigate.to(getLocationNavUrl(Urls.PremiumListingsContact)))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Contact</Typography>
        </Box>
        <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Phone Number(s)</Typography>
              {location.phoneNumbers?.map(phone => (
                <Typography key={phone.id}>
                  {`${phone.phoneType}: ${phone.phoneNumber} ${phone?.ext && `ext. ${phone.ext}`}`}
                </Typography>
              ))}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Business Website</Typography>
              {displayNotRequiredField('business website', location.locationWebsite)}
            </Box>
            {(isPlProduct || isHCProduct) && (
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Business Email</Typography>
              {displayNotRequiredField('business email', location.locationEmail)}
            </Box>
            )}
          </Box>
          <Box>
            {(!isPlProduct && !isHCProduct) && (
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Business Email</Typography>
              {displayNotRequiredField('business email', location.locationEmail)}
            </Box>
            )}
            {(isPlProduct || isHCProduct) && (
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Google My Business url</Typography>
              {displayNotRequiredField('url provided', location?.gmbListingUrl)}
            </Box>
            )}
            {(isPlProduct || isHCProduct) && (
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Facebook page</Typography>
              {displayNotRequiredField('page provided', location?.facebookListingUrl)}
            </Box>
            )}
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Social Media</Typography>
              {renderSocialUrls(location?.socials?.[0])}
            </Box>
          </Box>
        </GridItem>

        {/* Hours */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, async () => {
            await dispatch(setSpecialHours([]));
            navigate.to(getLocationNavUrl(Urls.PremiumListingsHours));
          })}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Hours</Typography>
        </Box>
        <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Hours of Operation</Typography>
              {location.hours?.map(hours => (
                <Typography marginBottom={1} textTransform="capitalize" key={hours.id}>
                  {formatLocationHours(hours)}
                </Typography>
              ))}
            </Box>
            {(isPlProduct || isHCProduct) && (
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Special Hours</Typography>
              {specialHoursData?.length ? specialHoursData?.map(hour => (
                  <>
                    <Typography marginBottom={1} textTransform="capitalize" key={hour.id}>
                      {`${hour.closed ? 'Closed' : 'Open'}: ${hour.dates.split("-")[0]} ${ !hour.closed ? `-- ${hour.specialFromHour1} - ${hour.specialToHour1}` : ''}`}
                    </Typography>
                    {hour.specialFromHour2 !== "" && hour.specialToHour2 !== "" &&
                        <Typography marginBottom={1} textTransform="capitalize" key={hour.id}>
                          {`${hour.closed ? 'Closed' : 'Open'}: ${hour.dates.split("-")[0]} -- ${hour.specialFromHour1} - ${hour.specialToHour1}`}
                        </Typography>
                    }
                  </>
              )) : <Typography fontWeight="bold">No special hours</Typography>}
            </Box>
            )}
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Grand Opening Date</Typography>
              {location?.grandOpeningDate
                ? (<Typography>{new Date(location.grandOpeningDate).toLocaleDateString()}</Typography>)
                : (<Typography fontWeight="bold">No Grand Opening date</Typography>)}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Opening Hours Notes</Typography>
              {displayNotRequiredField('opening hour notes', location?.hoursNotes)}
            </Box>
          </Box>
        </GridItem>

        {/* Info */}
        {(isPlProduct || isHCProduct) && (
          <Box sx={{ display: 'flex', margin: '10px 0' }}>
            {editButton(isEditable, () => navigate.to(getLocationNavUrl(Urls.PremiumListingsInfo)))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Info</Typography>
          </Box>
        )}
        {(isPlProduct || isHCProduct) && renderInfoSection()}

        {/* Photos */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () => navigate.to(getLocationNavUrl(Urls.PremiumListingsPhotos)))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Photos</Typography>
        </Box>
        <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Standard Logo</Typography>
              {getBusinessLogo(location)
                ? (
                  <Link href={getBusinessLogo(location)?.url} target="_blank" rel="noopener noreferrer" sx={{ wordWrap: 'break-word', fontFamily: 'Unify Sans' }}>
                    {getBusinessLogo(location)?.fileName}
                  </Link>
                ) : <Typography fontWeight="bold">No Business Logo</Typography>}
            </Box>
            {(isPlProduct || isHCProduct) && (
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Square Logo</Typography>
                {getSquareLogo(location)
                  ? (
                    <Link href={getSquareLogo(location)?.url} target="_blank" rel="noopener noreferrer" sx={{ wordWrap: 'break-word', fontFamily: 'Unify Sans' }}>
                      {getSquareLogo(location)?.fileName}
                    </Link>
                  ) : <Typography fontWeight="bold">No Square Logo</Typography>}
              </Box>
            )}
            {(isPlProduct || isHCProduct) && (
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Location Photos</Typography>
                {location.pictureAttachmentUploads?.length
                  ? location.pictureAttachmentUploads.map(photo => (
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {getFileCategory(photo)}
                      <Link href={photo.url} key={photo.id} target="_blank" rel="noopener noreferrer"
                        sx={{ wordWrap: 'break-word', fontFamily: 'Unify Sans', display: 'inline-block' }}
                      >
                        <Typography>{photo.fileName}</Typography>
                      </Link>
                    </Typography>
                  ))
                  : <Typography fontWeight="bold">No photos</Typography>}
              </Box>
            )}
          </Box>
          <Box>
            {location?.videos?.length
              ? location.videos.map((video, index) => (
                <Box mb={2} key={video.id}>
                  <Typography sx={headingStyle} color="primary">{`Published Video ${index + 1}`}</Typography>
                  <Typography mb={1}>{`Video Title: ${video.title}`}</Typography>
                  <Typography mb={1}>
                    Video URL:&nbsp;
                    <Link href={video.url} target="_blank" rel="noopener noreferrer">{video.url}</Link>
                  </Typography>
                  <Typography>{`Video Description: ${video.description}`}</Typography>
                </Box>
              )) : <Box />}
          </Box>
        </GridItem>

        {/* Additional */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () => navigate.to(getLocationNavUrl(Urls.PremiumListingsAdditional)))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Additional</Typography>
        </Box>
        <GridItem sizes={[6, 6]}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Services/Products</Typography>
              {location.services?.length
                ? location.services.map(service => (
                  <Typography key={service.name}>{service.name}</Typography>
                ))
                : <Typography fontWeight="bold">No services</Typography>}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Brands</Typography>
              {location.brands?.length
                ? location.brands.map(brand => (
                  <Typography key={brand}>{brand.name}</Typography>
                ))
                : <Typography fontWeight="bold">No brands</Typography>}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Payment Types</Typography>
              {location.payments?.length
                ? location.payments.map(payment => (
                  <Typography key={payment.name}>{payment.name}</Typography>
                ))
                : <Typography fontWeight="bold">No payment types</Typography>}
            </Box>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Languages</Typography>
              {location.languages?.length
                ? location.languages.map(language => (
                  <Typography key={language.name}>{language.name}</Typography>
                ))
                : <Typography fontWeight="bold">No languages</Typography>}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Demographic info</Typography>
              {location.demographics?.length
                ? location.demographics.map(demographic => (
                  <Typography key={demographic.name}>{demographic.name}</Typography>
                ))
                : <Typography fontWeight="bold">No demographics</Typography>}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Competitor types</Typography>
              {location.competitors?.length
                ? location.competitors.map(competitor => (
                  <Typography key={competitor.name}>{competitor.name}</Typography>
                ))
                : <Typography fontWeight="bold">No competitor</Typography>}
            </Box>
          </Box>
          <Box />
        </GridItem>

      </Box>
    </GridItem>
  );
}
