import React, { ComponentProps } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';

import {
  ProductTypes, selectFilesGetty, selectFilesUploaded, setFiles,
} from 'services/fileUploaderSlice';
import WebsitesType from 'models/WebsitesType';

import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';

import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import ContentLoader from 'components/contentLoader/ContentLoader';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import CoFileUploader from 'components/fileUploader/CoFileUploader';
import useUpdateAssets from 'hooks/useUpdateAssets';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import { selectActiveProductId, selectActiveOfferingType } from 'services/navigationSlice';
import schema from '../additional-features-design/AdditionalFeaturesDesign.schema';

interface Props {
  website: WebsitesType;
  onSubmit: () => any;
  onBack: () => any;
  filesGettyList: any[];
}

const AssetsStyled = styled(Grid)`
  font-size: 16px;
  font-weight: 600;
  font-family: Unify Sans;
  margin-bottom: 8px;
`;

const SelectAssetsStyled = styled(Grid)`
  font-size: 16px;
  font-weight: 400;
  font-family: Unify Sans;
  margin-bottom: 16px;
`;

const uploaderType = 'existing-assets';
const productType :ProductTypes = ProductTypes.EXISTING_ASSETS;

const megabyte = 1_000_000;

const fileUploaderOptions :Pick<ComponentProps<typeof CoFileUploader>, 'fileUploaderOptions'>['fileUploaderOptions'] = {
  name: 'uploadedFiles',
  uploaderType,
  showRecommendedImageText: false,
  showAcceptedFileTypesText: true,
  maxFileSize: 256 * megabyte,
  supportingText: '',
  toolTiptext: '',
  label: null,
  imageTypes: ['.png', '.jpeg', '.jpg', '.gif', '.CR2', '.DNG'],
  // @ts-ignore
  videoTypes: ['.mp4'],
  documentTypes: ['.pdf', '.doc', '.docx'],
  size: 'lg',
  directory: uploaderType,
  showComments: true,
  showCheckboxes: true,
};

const gettyUploaderOptions :Pick<
ComponentProps<typeof CoFileUploader>,
'gettyImagesUploaderOptions'>['gettyImagesUploaderOptions'] = {
  name: 'stockList',
  productType: ProductTypes.EXISTING_ASSETS,
  showCheckboxes: true,
  showComments: true,
};

export default function ExistingAssetsForm(props: Props) {
  const {
    orderId, orderItemId,
  } = useParams();
  const dispatch = useDispatch();
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);

  // redux
  const filesUploaded = useSelector(selectFilesUploaded, (prev, curr) => prev.content.length === curr.content.length);
  const filesGetty = useSelector(selectFilesGetty, (prev, curr) => prev.content.length === curr.content.length);

  // react-hook-forms
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      stockList: filesGetty.content || [],
      uploadedFiles: filesUploaded.content || [],
    },
  });
  const {
    handleSubmit, formState: {
      isDirty, isSubmitSuccessful, isSubmitting,
    }, watch,
  } = methods;

  const { triggerAllUpdates } = useUpdateAssets(
    watch,
    fileUploaderOptions.name,
    gettyUploaderOptions.name,
  );

  const onSubmit = async () => {
    await triggerAllUpdates();

    await props.onSubmit();
    dispatch(setFiles([]));
  };

  const skipAndContinue = filesUploaded.content.length + filesGetty.content.length === 0;

  return (
    <DefaultPageLayout disableContinue={false} onContinue={handleSubmit(onSubmit)}
      header="Stock and Existing Assets" onBack={props.onBack}
      skipAndContinue={skipAndContinue}
    >
      <ContentLoader status="idle" message="">
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <Grid container
              item xs={8}
            >
              <AssetsStyled item xs={12}>
                Assets
              </AssetsStyled>
              <SelectAssetsStyled item xs={12}>
                Select stock assets and/or upload your own assets.
                By uploading an asset, you acknowledge that you have the license to use the asset.
              </SelectAssetsStyled>
              <Grid item>
                <CoFileUploader
                  schema={schema}
                  productType={productType}
                  offeringType={offeringType as WebsiteOfferingTypes}
                  orderId={orderId}
                  orderItemId={orderItemId}
                  showFileUploader
                  showGetty
                  productId={websiteId?.toString()}
                  fileUploaderOptions={fileUploaderOptions}
                  gettyImagesUploaderOptions={gettyUploaderOptions}
                  containerStyles={{
                    '& .MuiGrid-item > div > p:first-of-type': { lineHeight: '12px', mb: 0 },
                    '& .MuiGrid-item > div:has(> div)': { width: '374px' },
                    '& .MuiGrid-item > button + div': { width: '374px' },
                    '& ul': { maxWidth: '600px' },
                  }}
                />
              </Grid>
            </Grid>
            {/* show the dialog if we changed the field and didn't submit the changes */}
            <CoNavigationConfirm
              showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </FormProvider>
        </ValidationProvider>
      </ContentLoader>
    </DefaultPageLayout>
  );
}
