import * as yup from 'yup';
import i18n from 'i18n/i18n';
import useNpiValidationService
  from "pages/premium-listings/healthcare/provider/NpiValidationService";

const {validateNpiNumber} = useNpiValidationService();
const {t} = i18n;

const nationalProviderIdentifierError = t(
  'pages.premiumListings.location.healthcare.provider.nationalProviderIdentifierRequired',
);
const npiValidationError = t('pages.premiumListings.location.healthcare.provider.nationalProviderIdentifierInvalidError');

const schema = {
  prePopulate: yup.string().required(t('pages.premiumListings.location.healthcare.provider.prePopulateRequired')),
  providerCategories: yup.array()
    .min(1, t('pages.premiumListings.location.healthcare.provider.providerCategoriesRequired'))
    .max(3, t('pages.premiumListings.location.healthcare.provider.providerCategoriesMax')),
  practiceName: yup.string().notRequired().nullable(),
  title: yup.string().notRequired().nullable(),
  firstName: yup.string().required(t('pages.premiumListings.location.healthcare.provider.firstNameRequired')),
  middleName: yup.string().notRequired().nullable(),
  lastName: yup.string().required(t('pages.premiumListings.location.healthcare.provider.lastNameRequired')),
  nationalProviderIdentifier: yup.string()
  .length(10, nationalProviderIdentifierError)
  .matches(/^\d+$/, nationalProviderIdentifierError)
  .required(nationalProviderIdentifierError)
  .test(
      'validate-npi',
      npiValidationError,
      async (npi): Promise<boolean> => {
        if (npi && npi.length > 8) {
          return await validateNpiNumber(npi);
        }
        return true;
      }
  ),
  credentials: yup.array()
    .min(1, t('pages.premiumListings.location.healthcare.provider.providerCredentialsRequired')),
  university: yup.string().notRequired().nullable(),
  hospitalAffiliations: yup.string().notRequired().nullable(),
  insurancesAccepted: yup.string().notRequired().nullable(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
