/* eslint-disable max-len */
import i18n from 'i18n/i18n';

import {RJSFSchema} from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import {Grid, Typography} from "@mui/material";
import React from "react";

const {t} = i18n;

const validateRequiredFormField = (formData, fieldName, errors) => {
  if (formData?.[fieldName] === '') {
    errors[fieldName].addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
};

export const TargetingSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.xmoUpgradeSocial.targeting.title'),
  type: 'object',
  required: [
    'genderTargeting',
    'minimumAge',
    'maximumAge',
    'targetingInterestCategories',
    'rlCreativeGuidelines',
    'isAdCopySelfWritten',
  ],
  properties: {
    genderTargeting: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.targeting.genderTargetingLabel'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.xmoUpgradeSocial.targeting.genderTargetingOpt1'),
        t('pages.ausProduct.xmoUpgradeSocial.targeting.genderTargetingOpt2'),
        t('pages.ausProduct.xmoUpgradeSocial.targeting.genderTargetingOpt3'),
      ],
    },
    targetAgeRange: {
      type: 'string',
    },
    minimumAge: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.targeting.minimumAgeLabel'),
    },
    maximumAge: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.targeting.maximumAgeLabel'),
    },
    targetingInterestCategories: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.targeting.targetingInterestCategoriesLabel'),
    },
    rlCreativeGuidelines: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.targeting.rlCreativeGuidelinesLabel'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.xmoUpgradeSocial.targeting.yesOpt'),
        t('pages.ausProduct.xmoUpgradeSocial.targeting.noOpt'),
      ],
    },
    isAdCopySelfWritten: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.targeting.isAdCopySelfWrittenLabel'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.xmoUpgradeSocial.targeting.yesOpt'),
        t('pages.ausProduct.xmoUpgradeSocial.targeting.noOpt'),
      ],
    },
    requiredAdPlacements: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.targeting.requiredAdPlacementsLabel'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.xmoUpgradeSocial.targeting.automaticPlacementOpt'),
        t('pages.ausProduct.xmoUpgradeSocial.targeting.manualPlacementOpt'),
      ],
    },
  },
  dependencies: {
    requiredAdPlacements: {
      oneOf: [
        {
          properties: {
            requiredAdPlacements: {
              enum: [t('pages.ausProduct.xmoUpgradeSocial.targeting.manualPlacementOpt')],
            },
            manualAdPlacement: {
              type: 'array',
              title: t('pages.ausProduct.xmoUpgradeSocial.targeting.manualAdPlacementLabel'),
              items: {
                type: 'string',
                enum: [
                  t('pages.ausProduct.xmoUpgradeSocial.targeting.manualAdPlacementOpt1'),
                  t('pages.ausProduct.xmoUpgradeSocial.targeting.manualAdPlacementOpt2'),
                  t('pages.ausProduct.xmoUpgradeSocial.targeting.manualAdPlacementOpt3'),
                  t('pages.ausProduct.xmoUpgradeSocial.targeting.manualAdPlacementOpt4'),
                  t('pages.ausProduct.xmoUpgradeSocial.targeting.manualAdPlacementOpt5'),
                ],
              },
              uniqueItems: true,
              minItems: 1
            },
          },
        },
      ],
    },
  }
});

const dropdownFieldKeys = [
  'genderTargeting',
  'rlCreativeGuidelines',
  'isAdCopySelfWritten',
  'requiredAdPlacements'
];

const inputTextFieldKeys = [
  'targetingInterestCategories'
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.tracking.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

const inputTextField = inputTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': 'textInputField'
  },
}), {});

export const TargetingUISchema = {
  'ui:order': [
    'genderTargeting',
    'targetAgeRange',
    'minimumAge',
    'maximumAge',
    'targetingInterestCategories',
    'rlCreativeGuidelines',
    'isAdCopySelfWritten',
    'requiredAdPlacements',
    'manualAdPlacement',
  ],
  manualAdPlacement: {
    'ui:widget': 'checkBoxField'
  },
  minimumAge: {
    'ui:widget': 'integerInputField',
    'ui:style': {
      marginBottom: '-15px',
    },
  },
  maximumAge: {
    'ui:widget': 'integerInputField',
  },
  targetAgeRange: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography variant="subtitle2"
                      style={{
                        display: 'inline-block',
                        fontSize: '18px'
                      }}> {t('pages.ausProduct.xmoUpgradeSocial.targeting.targetAgeRange')}
          </Typography>
        </Grid>
    ),
    'ui:style': {
      marginBottom: '-15px',
      marginTop: '10px',
    },
  },
  ...dropdownFields,
  ...inputTextField
};

export const TargetingCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.maximumAge !== '' && Number(formData.maximumAge) < Number(formData.minimumAge)) {
    errors.maximumAge.addError(t('pages.ausProduct.xmoUpgradeSocial.targeting.maximumAgeErrorMessage'));
    errors.addError(true);
  }
  validateRequiredFormField(formData, 'genderTargeting', errors,);
  validateRequiredFormField(formData, 'minimumAge', errors,);
  validateRequiredFormField(formData, 'maximumAge', errors,);
  validateRequiredFormField(formData, 'targetingInterestCategories', errors,);
  validateRequiredFormField(formData, 'rlCreativeGuidelines', errors,);
  validateRequiredFormField(formData, 'isAdCopySelfWritten', errors,);
  return errors;
};