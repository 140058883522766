import { Typography } from '@mui/material';
import 'styles/website_fonts.css';

export interface S1Props {
  sx?: object;
  children: any;
}

export function S1({ children, sx }: S1Props) {
  return (
    <Typography variant="subtitle1" gutterBottom component="div"
      sx={{
        color: 'text.primary', fontSize: '1.5rem', weight: 400, ...sx,
      }}
    >
      {children}
    </Typography>
  );
}

export default S1;
