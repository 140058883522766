import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrderBusiness } from 'services/businessSlice';
import { getClients, selectClients } from 'services/clientsSlice';
import { getBusinessRoles, selectBusinessRoles } from 'services/businessRolesSlice';
import { getBusinessLocations, selectBusinessLocations, selectProviders } from 'services/businessLocationSlice';
import { Urls } from 'navigation/Urls';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useParams } from 'react-router';
import { selectOrderFlow, selectOrdersContent } from 'services/ordersSlice';
import { OrderFlow } from 'shared/constants';
import { renderClient } from './BusinessClient';
import LocationSection from './BusinessLocation';

export const headingStyle = {
  fontSize: '12px',
  fontWeight: '600',
  textTransform: 'uppercase',
  letterSpacing: '1px',
};

export default function BusinessSection({
  isPlProduct, isHCProduct, isEditable, review,
}: any) {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const { content } = useSelector(selectOrderBusiness);
  const clients = useSelector(selectClients);
  const businessRoles = useSelector(selectBusinessRoles);
  const locations = useSelector(selectBusinessLocations);
  const providers = useSelector(selectProviders);
  const orderData = useSelector(selectOrdersContent);
  const orderFlow = useSelector(selectOrderFlow);
  const isPunchlist = (orderFlow === OrderFlow.PUNCHLIST || orderData.orderFlow === OrderFlow.PUNCHLIST);

  useEffect(() => {
    dispatch(getClients(orderId));
    dispatch(getBusinessRoles({}));
  }, [orderId]);

  useEffect(() => {
    if (content?.id) dispatch(getBusinessLocations(content.id));
  }, [content]);

  const renderBusinessRole = (id) => businessRoles.filter(role => role.id === id)?.[0]?.name;

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Business Details */}
      <GridItem sizes={[12, 6, 6]} marginBottom={3} sx={{ padding: '20px 0 0' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(Urls.BusinessDetail))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Details</Typography>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Business Name</Typography>
            <Typography>{content.businessName}</Typography>
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">GMAID</Typography>
            {displayNotRequiredField('GMAID', content?.gmaid)}
          </Box>
          <Box>
            <Typography sx={headingStyle} color="primary">MCID</Typography>
            <Typography>{content.mcid || 'No'}</Typography>
          </Box>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Affiliate Order</Typography>
            <Typography>{content.affiliateOrder ? 'Yes' : 'No'}</Typography>
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Business Category</Typography>
            <Typography>{content?.businessCefCategory?.[0]?.categoryName}</Typography>
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Business Subcategory</Typography>
            <Typography>{content?.businessCefCategory?.[0]?.subCategoryName}</Typography>
          </Box>
          {(isPlProduct || isHCProduct) && (
            <Box>
              <Typography sx={headingStyle} color="primary">Listings Business Categories</Typography>
              {React.Children.toArray(content?.businessCategory?.map(cat => (
                <Typography key={cat.name}>{cat.name}</Typography>
              )))}
            </Box>
          )}
        </Box>
      </GridItem>

      {/* Clients */}
      {React.Children.toArray(clients?.map((client, index) => renderClient(
        client,
        index,
        renderBusinessRole(client.contact.businessRoleId),
        navigate,
        isEditable,
      )))}

      {/* Locations */}
      {(!review && (!isPunchlist || (isPunchlist && !isHCProduct)))
      && React.Children.toArray(locations?.map((location, index) => (
        <LocationSection
          key={location.id}
          location={location}
          index={index}
          isPlProduct={isPlProduct}
          isHCProduct={isHCProduct}
          locationType="location"
          isEditable={isEditable}
        />
      )))}

      {/* Providers */}
      {!review && React.Children.toArray(providers?.map((provider, index) => (
        <LocationSection
          key={provider.id}
          location={provider}
          index={index}
          isPlProduct={isPlProduct}
          isHCProduct={isHCProduct}
          locationType="provider"
          isEditable={isEditable}
        />
      )))}
    </Grid>
  );
}
