import * as yup from 'yup';
import {
  IQFormInput, ValidationProvider, IQTheme, IQCheckbox,
} from '@gannettdigital/shared-react-components';
import { FormHelperText, Grid, Typography } from '@mui/material';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NeSelectChip from 'components/chips/NeSelectChip';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBusinessCategories,
  getBusinessCategoriesSug,
  selectBusinessCategoriesSug,
  selectBusinessCategoriesStatus,
  selectBusinessCategoriesSuggestions,
  selectOrderBusiness,
  selectBusinessSelectionType,
  getCefCategoriesByGmaid,
  selectBusinessCefCategories,
  selectRlopBusinessCategories,
  getRlopBusinessCategories,
  selectRlopBusinessSubCategories,
  getRlopBusinessSubCategories,
} from 'services/businessSlice';
import { selectCurrentOffer } from 'services/cefSlice';
import { getBusinessLocations, selectBusinessLocations, selectProviders } from 'services/businessLocationSlice';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import {
  selectIsPartnerFlow, selectOrderFlow, selectOrderItems, selectOrdersContent,
} from 'services/ordersSlice';
import { SaveAndCloseContext } from 'context/SaveAndCloseContext';
import { useLocation } from 'react-router';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { OrderFlow } from 'shared/constants';
import { selectCase } from 'services/punchlistSlice';
import { TitleWithBadge } from 'pages/premium-listings/TitleWithBadge';
import schema, { GMAID_REGEX } from './BusinessDetails.schema';

interface Props {
  onSubmit: (any) => void
  setSelectedBusinessLocations: (any) => void
  newBusiness?: boolean
}

export default function BusinessDetailsForm({ newBusiness, onSubmit, setSelectedBusinessLocations }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigationHandler();
  const history: any = useLocation();
  const saveAndCloseContext = useContext(SaveAndCloseContext);
  const dispatch = useDispatch();
  const { businessId, orderFlow: orderDataFlow } = useSelector(selectOrdersContent);
  const currentBusiness = useSelector(selectOrderBusiness);
  const businessCategories = useSelector(selectBusinessCategoriesSug);
  const status = useSelector(selectBusinessCategoriesStatus);
  const suggestions = useSelector(selectBusinessCategoriesSuggestions);
  const locations = useSelector(selectBusinessLocations);
  const providers = useSelector(selectProviders);
  const businessSelectionType = useSelector(selectBusinessSelectionType);
  const orderItems = useSelector(selectOrderItems);
  const currentOffer = useSelector(selectCurrentOffer);
  const cefCategoriesSug = useSelector(selectBusinessCefCategories);
  const rlopBusinessCategories = useSelector(selectRlopBusinessCategories);
  const rlopBusinessSubCategories = useSelector(selectRlopBusinessSubCategories);
  const caseOpportunity = useSelector(selectCase);

  const [isCustomProduct, setIsCustomProduct] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [cefCategoryOptions, setCefCategoryOptions] = useState([]);
  const [cefSubCategoryOptions, setCefSubCategoryOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>();
  const isPremiumListing = orderItems.content.some(item => item.offeringType === 'PremiumListing');

  const orderFlow = useSelector(selectOrderFlow);
  const isPunchListFlow = (orderFlow === OrderFlow.PUNCHLIST || orderDataFlow === OrderFlow.PUNCHLIST);
  const isPartnerFlow = useSelector(selectIsPartnerFlow);

  const skipBusinessSearch = isPartnerFlow || isPunchListFlow;

  const hasListingsAttributes = useMemo(() => [...locations, ...providers]
    .some(location => location?.categoryAttributes?.length > 0), [locations, providers]);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      businessName: '',
      gmaid: '',
      affiliateOrder: false,
      businessCategories: [],
      category: [],
      subCategory: [],
    },
  });

  const {
    handleSubmit,
    trigger,
    reset,
    setValue,
    formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    },
    register,
    watch,
    getValues,
    resetField,
  } = methods;

  const gmaidWithCountry = useMemo(() => {
    const maid = getValues('gmaid');
    return maid;
  }, [watch('gmaid')]);

  useEffect(() => {
    dispatch(getBusinessCategories({}));
  }, []);

  const prepopulatePunchlist = async () => {
    const punchlistCategories = [{
      label: caseOpportunity?.offer?.businessCategoryName.replaceAll('"', ''),
      value: caseOpportunity?.offer?.businessCategoryId,
    }];
    await setSelectedCategories(punchlistCategories);
    await setCategoryOptions(punchlistCategories);
    reset(formValues => ({
      ...formValues,
      businessCategories: [caseOpportunity?.offer?.businessCategoryId],
    }));
  };

  useEffect(() => {
    if (isPunchListFlow && Object.keys(caseOpportunity)?.length > 0) {
      prepopulatePunchlist();
    }
  }, [isPunchListFlow, caseOpportunity]);

  useEffect(() => {
    const isCustomLandingPage = orderItems.content.filter(o => o.offeringType === 'CustomLandingPage');
    const isCustomWebsite = orderItems.content.filter(o => o.offeringType === 'CustomWebsite');
    if (isCustomLandingPage.length > 0 || isCustomWebsite.length > 0) setIsCustomProduct(true);
  }, [orderItems]);

  useEffect(() => {
    if (businessId && currentBusiness?.content?.businessCategory?.length > 0 && !newBusiness) {
      reset(formValues => ({
        ...formValues,
        businessCategories: currentBusiness.content.businessCategory.map(cat => cat.name),
      }));
      setSelectedCategories(currentBusiness.content.businessCategory.map(cat => ({
        label: cat.name,
        value: cat.categoryId,
      })));
    }

    if (businessId && currentBusiness?.content?.businessCefCategory?.length > 0 && !newBusiness) {
      const cefCategories = currentBusiness.content.businessCefCategory[0];

      reset(formValues => ({
        ...formValues,
        category: [cefCategories?.categoryName],
        subCategory: [cefCategories?.subCategoryName],
      }));
      setSelectedCategory([{
        label: cefCategories?.categoryName,
        value: cefCategories?.categoryId,
      }]);
      setSelectedSubCategory([{
        label: cefCategories?.subCategoryName,
        value: cefCategories?.subCategoryId,
      }]);
    }
  }, [businessId, currentBusiness]);

  useEffect(() => {
    let cefCategoryOption: { label: string; value: number; };
    let cefSubCategoryOption: { label: string; value: number; };
    if (cefCategoriesSug?.gmaid === gmaidWithCountry) {
      cefCategoryOption = {
        label: cefCategoriesSug?.categoryName,
        value: cefCategoriesSug?.categoryId,
      };
      cefSubCategoryOption = {
        label: cefCategoriesSug?.subCategoryName,
        value: cefCategoriesSug?.subCategoryId,
      };
      setCefCategoryOptions(rlopBusinessCategories && rlopBusinessCategories?.length > 0
        ? rlopBusinessCategories.map(cat => ({ label: cat.name, value: cat.id })) : [cefCategoryOption]);
      setCefSubCategoryOptions([...cefSubCategoryOptions, cefSubCategoryOption]);

      if (!businessId) {
        reset(formValues => ({
          ...formValues,
          category: [cefCategoriesSug?.categoryName],
          subCategory: [cefCategoriesSug?.subCategoryName],
        }));
        setSelectedCategory([cefCategoryOption]);
        setSelectedSubCategory([cefSubCategoryOption]);
        setSelectedCategoryId(cefCategoriesSug?.categoryId);
      }
    }
  }, [cefCategoriesSug, businessId]);

  useEffect(() => {
    const categoryVal = getValues('category');
    if (categoryVal.length !== 1) {
      setSelectedCategoryId(undefined);
      resetField('subCategory', { defaultValue: [] });
    } else {
      const selectedCategoryId = categoryVal[0];
      if (selectedCategoryId && typeof selectedCategoryId === 'number') {
        setSelectedCategoryId(selectedCategoryId);
        const rlopSubCategoriesOptions = rlopBusinessSubCategories
          .map(cat => ({ label: cat.name, value: cat.id }));

        setCefSubCategoryOptions(rlopSubCategoriesOptions || []);
      }
    }
  }, [watch('category')]);

  useEffect(() => {
    if (!selectedCategoryId) {
      dispatch(getRlopBusinessCategories(''));
    } else {
      dispatch(getRlopBusinessSubCategories({ query: '', categoryId: selectedCategoryId }));
    }
  }, [selectedCategoryId]);

  useEffect(() => {
    if (rlopBusinessCategories && rlopBusinessCategories.length > 0) {
      const newCefCategories = rlopBusinessCategories?.map(cat => ({ label: cat.name, value: cat.id }));
      const uniqueById = newCefCategories?.filter(cat => cat.value !== undefined);
      setCefCategoryOptions(uniqueById);
    }
  }, [rlopBusinessCategories]);

  useEffect(() => {
    if (rlopBusinessSubCategories && rlopBusinessSubCategories.length > 0) {
      const newSubCategories = rlopBusinessSubCategories?.map(cat => ({ label: cat.name, value: cat.id }));
      const uniqueById = newSubCategories?.filter(cat => cat.value !== undefined);
      setCefSubCategoryOptions(uniqueById);
    }
  }, [rlopBusinessSubCategories]);

  useEffect(() => {
    if (!newBusiness) {
      reset(formValues => ({
        ...formValues,
        businessName: currentBusiness.content.businessName,
        gmaid: currentBusiness.content.gmaid,
        affiliateOrder: currentBusiness.content.affiliateOrder || false,
      }));
      if (currentBusiness?.content?.id) dispatch(getBusinessLocations(currentBusiness.content.id));
    }
  }, [currentBusiness]);

  useEffect(() => {
    if (isCustomProduct) {
      setValue('businessName', currentBusiness.content.businessName || '')
      setValue('gmaid', currentBusiness.content.gmaid || '')
      setValue('affiliateOrder', currentBusiness.content.affiliateOrder || false)
    }
  }, [currentBusiness, isCustomProduct, orderItems]);

  useEffect(() => {
    if (gmaidWithCountry && !isCustomProduct) {
      dispatch(getCefCategoriesByGmaid(gmaidWithCountry));
    }
  }, [gmaidWithCountry]);

  useEffect(() => {
    const newBusinessCategories = businessCategories.content.map(cat => ({ label: cat.name, value: cat.categoryId }));
    const updatedCategoryOptions = [...categoryOptions, ...newBusinessCategories];
    const uniqueById = updatedCategoryOptions?.filter(cat => cat.value !== undefined);
    setCategoryOptions(uniqueById);
  }, [businessCategories]);

  useEffect(() => {
    if (locations && locations.length > 0) {
      setSelectedBusinessLocations(locations);
    } else if (currentBusiness?.content?.businessLocation?.length > 0) {
      setSelectedBusinessLocations(currentBusiness.content.businessLocation);
    }
  }, [locations]);

  const handleFormSubmit = () => {
    const data = getValues();
    onSubmit({
      data,
      cefCategoryOptions,
      cefSubCategoryOptions,
      selectedCategories,
      subCategory: selectedSubCategory,
      shouldNavigateBackOverview: history.state?.previousPath.includes('overview'),
      shouldNavigateBackReview: history.state?.previousPath.includes('review'),
    });
  };

  useEffect(() => {
    saveAndCloseContext.setSaveAndCloseAction(handleFormSubmit);
  }, [selectedCategories]);

  const onBack = () => navigate.to(skipBusinessSearch ? Urls.SalesRep : Urls.BusinessSearch);

  const getSuggestions = async ({ query }) => {
    const categoryName = query;
    dispatch(getBusinessCategoriesSug(categoryName));
  };

  const getRlopBusinessCategoriesSuggestions = async ({ query }) => {
    const categoryName = query;
    dispatch(getRlopBusinessCategories(categoryName));
  };

  const getRlopBusinessSubCategoriesSuggestions = async ({ query }) => {
    const subcategoryName = query;
    dispatch(getRlopBusinessSubCategories({ query: subcategoryName, categoryId: selectedCategoryId }));
  };

  const saveAndUpdateCategories = async () => {
    const newCategoryOptions = suggestions.map(cat => ({ label: cat.name, value: cat.id }));
    if (selectedCategories.length > 0) {
      selectedCategories.forEach(el => {
        const filterCat = newCategoryOptions.filter(cat => cat.value === el.value);
        if (filterCat.length === 0) newCategoryOptions.push(el);
      });
    }
    setCategoryOptions(newCategoryOptions);
  };

  const setCategoryValues = (fieldName, selected, setStateFunc) => {
    const values = getValues(fieldName);
    values?.forEach(val => {
      let matchCat = categoryOptions.find(cat => cat.label === val);
      if (matchCat === undefined) {
        matchCat = categoryOptions.find(cat => cat.value === val);
      }
      const duplicate = selected.find(el => el?.value === matchCat?.value);
      if (matchCat !== undefined && duplicate === undefined) {
        const newSelected = [...selected, matchCat];
        setStateFunc(newSelected);
      }
    });
  };

  useMemo(() => {
    if (suggestions) saveAndUpdateCategories();
  }, [suggestions]);

  useMemo(() => {
    setCategoryValues('businessCategories', selectedCategories, setSelectedCategories);
    setCategoryValues('category', selectedCategory, setSelectedCategory);
    setCategoryValues('subCategory', selectedSubCategory, setSelectedSubCategory);
  }, [watch('businessCategories'), watch('category'), watch('subCategory')]);

  useEffect(() => {
    if (getValues('businessCategories').length > 0) trigger('businessCategories');
  }, [watch('businessCategories')]);

  const gmaidCustomValidation = isCustomProduct
    // eslint-disable-next-line no-confusing-arrow, max-len
    ? yup.lazy(value => !value ? yup.string() : yup.string().matches(GMAID_REGEX, 'Valid GMAID must include the country platform i.e. \'USA_12345\''))
    : yup.string().required(t('pages.business.details.gmaidRequired'))
      .matches(GMAID_REGEX, 'Valid GMAID must include the country platform i.e. \'USA_12345\'');

  return (
    <DefaultPageLayout disableContinue={!isValid} onContinue={handleSubmit(handleFormSubmit)}
      onBack={onBack}
      header={t(newBusiness ? 'pages.business.details.newBusinessTitle' : 'pages.business.details.title')}
    >
      <ContentLoader status={status}>
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <Grid container>
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  rowSpacing={5}
                >
                  <Grid item xs={6}>
                    <IQFormInput
                      fullWidth
                      disabled={(Object.keys(currentOffer)?.length > 0
                        || Object.keys(caseOpportunity)?.length > 0) || isPunchListFlow}
                      theme={IQTheme}
                      {...register('businessName', { required: t('pages.business.details.businessNameRequired') })}
                      name="businessName"
                      id="businessName"
                      labelText={t('pages.business.details.businessNameLabel')}
                      fontLabelWeight="bold"
                      schema={schema}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <IQFormInput
                      fullWidth
                      disabled={(!newBusiness && !businessSelectionType?.isNewBusiness && !isCustomProduct)}
                      theme={IQTheme}
                      {...register('gmaid')}
                      name="gmaid"
                      id="gmaid"
                      labelText={!isCustomProduct ? `${t('pages.business.details.gmaidLabel')} *` : 'GMAID'}
                      fontLabelWeight="bold"
                      schema={schema}
                      customValidation={gmaidCustomValidation}
                    />
                  </Grid>
                  <Grid item>
                    <IQCheckbox
                      title=""
                      label={t('pages.business.details.affiliateOrderLabel')}
                      name="affiliateOrder"
                      field="affiliateOrder"
                    />
                  </Grid>
                  {!isCustomProduct && (
                  <>
                    <Grid item>
                      <NeSelectChip
                        label
                        labelText={t('pages.business.details.businessCategoryLabel')}
                        items={cefCategoryOptions}
                        name="category"
                        values={[]}
                        maxItems={1}
                        hideLimitHelperText
                        disableClear
                        schema={schema}
                        theme={IQTheme}
                        required
                        supportCustomChips={false}
                        getSuggestions={(e) => getRlopBusinessCategoriesSuggestions(e)}
                        onClose={() => trigger('category')}
                      />
                    </Grid>
                    <Grid item>
                      <NeSelectChip
                        label
                        labelText={t('pages.business.details.businessSubcategoryLabel')}
                        items={cefSubCategoryOptions}
                        name="subCategory"
                        values={[]}
                        maxItems={1}
                        disabled={selectedCategoryId === undefined}
                        hideLimitHelperText
                        disableClear
                        schema={schema}
                        theme={IQTheme}
                        required
                        supportCustomChips={false}
                        getSuggestions={(e) => getRlopBusinessSubCategoriesSuggestions(e)}
                        onClose={() => trigger('subCategory')}
                      />
                    </Grid>
                  </>
                  )}
                  {
                    isPremiumListing && (
                    <>
                      <Grid item>
                        <TitleWithBadge
                          title={t('pages.business.details.businessPlCategoriesLabel')}
                          badge={t('pages.premiumListings.badge')}
                          tooltip={t('pages.premiumListings.badgeTooltip')}
                        />
                        <FormHelperText>
                          <Typography>{t('pages.business.details.businessPlCategoriesHelperText')}</Typography>
                        </FormHelperText>
                      </Grid>
                      <Grid item>
                        <NeSelectChip
                          label={false}
                          items={categoryOptions}
                          name="businessCategories"
                          values={selectedCategories}
                          maxItems={5}
                          disableClear
                          schema={schema}
                          theme={IQTheme}
                          required
                          getSuggestions={(e) => getSuggestions(e)}
                          supportCustomChips={false}
                          onClose={() => trigger('businessCategories')}
                          disabled={hasListingsAttributes}
                        />
                        {hasListingsAttributes && (
                          <FormHelperText sx={{ marginTop: '30px' }}>
                            {t('pages.business.details.businessPlCategoriesUpdateHelperText')}
                          </FormHelperText>
                        )}
                      </Grid>
                    </>
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
            <CoNavigationConfirm
              showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </FormProvider>
        </ValidationProvider>
      </ContentLoader>
    </DefaultPageLayout>
  );
}
