import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import {Urls} from 'navigation/Urls';
import XmoSnapchatForm from './XmoSnapchatForm';

export default function XmoSnapchat() {
  const {product, onSubmit, onBack} = useProduct(Urls.XmoSnapchat);

  return (
      <ContentLoader status={product.status} message={product.message}>
        <XmoSnapchatForm
            product={product?.content?.data?.snapchat}
            onSubmit={onSubmit}
            onBack={onBack}
        />
      </ContentLoader>
  );
}
