/* eslint-disable max-len */
import i18n from 'i18n/i18n';

import {RJSFSchema} from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';

const {t} = i18n;

export const TrackingSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.tracking.title'),
  type: 'object',
  required: ['trackingCampaigns', 'googleAnalyticsReportingToolForCampaignSuccess'],
  properties: {
    trackingCampaigns: {
      type: 'string',
      title: t('pages.ausProduct.tracking.trackingCampaignsLabel'),
      default: '',
      enum: [
        t('pages.ausProduct.tracking.reachCode'),
        t('pages.ausProduct.tracking.3pp'),
        t('pages.ausProduct.tracking.noTracking'),
      ],
    },
    googleAnalyticsReportingToolForCampaignSuccess: {
      type: 'string',
      title: t('pages.ausProduct.tracking.googleAnalyticsReportingToolForCampaignSuccessLabel'),
      default: '',
      enum: [
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
    },
  },
  dependencies: {
    trackingCampaigns: {
      oneOf: [
        {
          properties: {
            trackingCampaigns: {
              enum: [t('pages.ausProduct.tracking.reachCode')],
            },
            campaignOn3ppUntilReachcodeIsInstalled: {
              type: 'string',
              title: t('pages.ausProduct.tracking.campaignOn3ppUntilReachcodeIsInstalledLabel'),
              default: '',
              enum: [
                '',
                t('pages.ausProduct.tracking.yes'),
                t('pages.ausProduct.tracking.no'),
              ],
            },
          },
        },
      ],
    },
  }
});

const dropdownFieldKeys = [
  'trackingCampaigns',
  'campaignOn3ppUntilReachcodeIsInstalled',
  'googleAnalyticsReportingToolForCampaignSuccess'
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.tracking.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

export const TrackingUISchema = {
  'ui:order': ['trackingCampaigns', 'campaignOn3ppUntilReachcodeIsInstalled', 'googleAnalyticsReportingToolForCampaignSuccess'],
  ...dropdownFields
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TrackingCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.trackingCampaigns === '') {
    errors.trackingCampaigns.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.googleAnalyticsReportingToolForCampaignSuccess === '') {
    errors.googleAnalyticsReportingToolForCampaignSuccess.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
