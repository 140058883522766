import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import {
  FormHelperText, Grid, TextField, Typography,
} from '@mui/material';
import ErrorText from 'components/errorText/ErrorText';
import { useEffect, useState } from 'react';
import useMapboxService from 'services/MapboxService';
import Autocomplete from '@mui/material/Autocomplete';

export default function AddressField(props: any) {
  const [isTouched, setIsTouched] = useState(!!props.value);
  const [addressData, setAddressData] = useState<string[]>([]);
  const { getServiceAreas } = useMapboxService();

  const getSuggestions = async (input: string) => {
    const data = await getServiceAreas(input, 'US');
    setAddressData(data);
  };

  useEffect(() => {
    if (props.value) {
      getSuggestions(props.value);
    }
  }, [props.value]);

  return (
    <Grid item xs={9} mt={3}>
      <Typography
        fontWeight={600}
        sx={{
          color: props?.rawErrors?.length > 0 && isTouched
            ? IQThemeV2.palette.error.main
            : IQThemeV2.palette.text.primary,
          marginBottom: '10px',
        }}
      >
        {props.label}
      </Typography>
      <Autocomplete
        value={props.value}
        disablePortal
        id="combo-box-demo"
        options={addressData}
        sx={{ width: '100%' }}
        onInputChange={(event, value) => {
          props.onChange(value);
          setIsTouched(true);
          getSuggestions(value);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      {props.placeholder && (props?.rawErrors?.length === 0 || !isTouched) && (
        <FormHelperText error={false}>{props.placeholder}</FormHelperText>
      )}
      <ErrorText
        hasError={props?.rawErrors?.length > 0 && isTouched}
        errorText={props?.rawErrors?.[0]}
      />
    </Grid>
  );
}
