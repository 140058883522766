import {
  FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography,
} from '@mui/material';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import ErrorText from 'components/errorText/ErrorText';
import schemaValidate from 'components/schemaValidate';
import i18n from 'i18n/i18n';
import { reputationManagementOptions, yesNoOptions } from './SMMDetailsPageConstants';
import schema from './SMMDetailsPage.schema';

const { t } = i18n;

export const renderReputationSection = (props) => {
  const {
    reputationManagement,
    setReputationManagement,
    isRepManagementNeeded,
    setIsRepManagementNeeded,
    hasGBP,
    hasYelp,
    register,
    errors,
    isCustom,
  } = props;

  const showRepManagementQuestion = isRepManagementNeeded === 'yes' || !isCustom;

  return (
    <>
      <Grid item mb={4} xs={12}>
        <Typography variant="h5" component="h2">
          {t('pages.smm.details.reputationSectionLabel')}
        </Typography>
      </Grid>
      {isCustom && (
      <Grid item mb={3} xs={12}>
        <Grid mb={1}>
          <Typography variant="subtitle2" component="h3" fontSize={16}>
            {t('pages.smm.details.customRepQuestion')}
          </Typography>
          <RadioGroup
            aria-labelledby="site-live"
            name="isRepManagementNeeded"
            value={isRepManagementNeeded}
            onChange={(e) => setIsRepManagementNeeded(e.target.value)}
          >
            {yesNoOptions.map(option => (
              <FormControlLabel
                key={option.value}
                {...register('isRepManagementNeeded', {
                  validate: (value) => schemaValidate(value, 'isRepManagementNeeded', schema),
                })}
                value={option.value}
                control={(<Radio />)}
                label={(
                  <Typography variant="body1">
                    {option.label}
                  </Typography>
                      )}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
      )}
      { showRepManagementQuestion && (
      <Grid item mb={5} xs={12}>
        <Grid item mb={1}>
          <Typography variant="subtitle2" component="h3" fontSize={16}
            mb={1}
          >
            {t('pages.smm.details.reputationManagementLabel')}
          </Typography>
          <Typography variant="body1" mb={2}>
            {t('pages.smm.details.reputationManagementDescription')}
          </Typography>
          <RadioGroup
            aria-labelledby="reputationManagement"
            name="reputationManagement"
            value={reputationManagement}
            onChange={(e) => setReputationManagement(e.target.value)}
          >
            {reputationManagementOptions.map(option => (
              <FormControlLabel
                key={option.value}
                {...register('reputationManagement', {
                  validate: (value) => schemaValidate(value, 'reputationManagement', schema),
                })}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </Grid>

        {hasGBP && (
        <Grid item mb={3}>
          <Grid item mb={1}>
            <Typography variant="subtitle2" component="h3" fontSize={16}
              color={errors.gbpUrl ? 'error.main' : 'common.black'}
            >
              {t('pages.smm.details.gbpUrlLabel')}
            </Typography>
          </Grid>
          <TextField
            hiddenLabel
            required
            name="gbpUrl"
            error={!!errors.gbpUrl}
            fullWidth
            {...register('gbpUrl', {
              validate: (value) => schemaValidate(value, 'gbpUrl', schema),
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <ErrorText
            hasError={!!errors?.gbpUrl}
            errorText={errors?.gbpUrl?.message || ''}
          />
        </Grid>
        )}

        {hasYelp && (
        <Grid item mb={3}>
          <Grid item mb={1}>
            <Typography variant="subtitle2" component="h3" fontSize={16}
              color={errors.yelpUrl ? 'error.main' : 'common.black'}
            >
              {t('pages.smm.details.yelpUrlLabel')}
            </Typography>
          </Grid>
          <TextField
            hiddenLabel
            required
            name="yelpUrl"
            error={!!errors.yelpUrl}
            fullWidth
            {...register('yelpUrl', {
              validate: (value) => schemaValidate(value, 'yelpUrl', schema),
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <ErrorText
            hasError={!!errors?.yelpUrl}
            errorText={errors?.yelpUrl?.message || ''}
          />
        </Grid>
        )}
      </Grid>
      )}
    </>
  );
};
