import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import TextField from 'pages/generic-product/details/custom-fields/TextField';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';

const { t } = i18n;

export const WebsiteContentPagesSchema: RJSFSchema | any = (data) => ({
  title: t('pages.smm.solutionStrategy.title'),
  type: 'object',
  properties: {
    localiqWebsite: {
      type: 'string',
      title: t('pages.websiteAddOn.localiqWebsiteLabel'),
      default: data?.localiqWebsite || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.websites.yesOption'),
        t('pages.socialAds.solutionStrategy.websites.noOption'),
      ],
    },
    editingNewOrExistingPage: {
      type: 'string',
      title: t('pages.websiteAddOn.editingNewOrExistingPageLabel'),
      description: t('pages.websiteAddOn.editingNewOrExistingPageHelperText'),
      default: data?.editingNewOrExistingPage || '',
      enum: [
        '',
        t('pages.websiteAddOn.editingNewOrExistingPageOpt1'),
        t('pages.websiteAddOn.editingNewOrExistingPageOpt2'),
      ],
    },
    contentType: {
      type: 'string',
      title: t('pages.websiteAddOn.contentTypeLabel'),
      description: t('pages.websiteAddOn.contentTypeHelperText'),
      default: data?.contentType || '',
      enum: [
        '',
        t('pages.websiteAddOn.contentTypeOpt1'),
        t('pages.websiteAddOn.contentTypeOpt2'),
        t('pages.websiteAddOn.contentTypeOpt3'),
        t('pages.websiteAddOn.contentTypeOpt4'),
        t('pages.websiteAddOn.contentTypeOpt5'),
        t('pages.websiteAddOn.contentTypeOpt6'),
      ],
    },
    subjectOrFocusOfTheNewOrExistingPage: {
      type: 'string',
      title: t('pages.websiteAddOn.subjectOrFocusOfTheNewOrExistingPageLabel'),
      default: data?.subjectOrFocusOfTheNewOrExistingPage || '',
    },
    specifyAnyKeywordsToInclude: {
      type: 'string',
      title: t('pages.websiteAddOn.specifyAnyKeywordsToIncludeLabel'),
      description: t('pages.websiteAddOn.specifyAnyKeywordsToIncludeHelperText'),
      default: data?.specifyAnyKeywordsToInclude || '',
    },
    notesAndInstructions: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.notesAndInstructions.notesAndInstructionsLabel'),
      default: data?.notesAndInstructions || '',
    },
  },

  dependencies: {
    localiqWebsite: {
      oneOf: [
        {
          properties: {
            localiqWebsite: {
              enum: ['Yes'],
            },
            websiteLive: {
              type: 'string',
              title: t('pages.websiteAddOn.websiteLiveLabel'),
              default: data?.websiteLive || '',
              enum: [
                '',
                t('pages.socialAds.solutionStrategy.websites.yesOption'),
                t('pages.socialAds.solutionStrategy.websites.noOption'),
              ],
            },
          },
        },
      ],
    },
    websiteLive: {
      oneOf: [
        {
          properties: {
            websiteLive: {
              enum: ['No'],
            },
            temporaryLink: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.websites.temporaryLinkTitle'),
              description: t('pages.socialAds.solutionStrategy.websites.temporaryLinkHelper'),
              default: data?.temporaryLink || '',
            },
          },
        },
      ],
    },
    editingNewOrExistingPage: {
      oneOf: [
        {
          properties: {
            editingNewOrExistingPage: {
              enum: ['New'],
            },
            provideNewContentReferences: {
              type: 'string',
              title: t('pages.websiteAddOn.provideNewContentReferencesLabel'),
              default: data?.provideNewContentReferences || '',
            },
          },
        },
        {
          properties: {
            editingNewOrExistingPage: {
              enum: ['Existing'],
            },
            providedUrl: {
              type: 'string',
              title: t('pages.websiteAddOn.providedUrlLabel'),
              default: data?.providedUrl || '',
            },
          },
        },
      ],
    },
  },
});

const dropDownKeys = [
  'localiqWebsite',
  'websiteLive',
  'editingNewOrExistingPage',
  'contentType',
];

const urlKeys = [
  'temporaryLink',
  'providedUrl',
];

const textFieldKey = [
  'provideNewContentReferences',
  'subjectOrFocusOfTheNewOrExistingPage',
  'specifyAnyKeywordsToInclude',
];

const textAreaFieldKeys = [
  'notesAndInstructions',
];

const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': (props) => <TextField {...props} rows={8} /> },
}), {});

const textField = textFieldKey.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const urlFields = urlKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'UrlTextWidget' },
}), {});

const dropDownFields = dropDownKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

export const WebsiteContentPagesUISchema = {
  'ui:order': [
    'localiqWebsite',
    'websiteLive',
    'temporaryLink',
    'editingNewOrExistingPage',
    'provideNewContentReferences',
    'providedUrl',
    'contentType',
    'subjectOrFocusOfTheNewOrExistingPage',
    'specifyAnyKeywordsToInclude',
    'notesAndInstructions',
  ],

  ...dropDownFields,
  ...urlFields,
  ...textField,
  ...textAreaFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const WebsiteContentPagesValidate = (formData: any, errors, uiSchema) => {
  if (formData?.providedUrl !== undefined && formData?.providedUrl !== '' && !validateUrl(formData?.providedUrl)) {
    errors.providedUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.temporaryLink !== undefined
    && formData?.temporaryLink !== ''
    && !validateUrl(formData?.temporaryLink)) {
    errors.temporaryLink.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
