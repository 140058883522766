import * as yup from 'yup';
import {
  IQButtonRadioContainer, IQFormInput, IQInputEmail, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import IQPhoneInputContainer from 'components/NePhoneInputContainer/IQPhoneInputContainer';
import { Typography, useTheme } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PhoneType } from 'pages/business/client/ClientType';
import { phoneTypes } from 'pages/business/client/ClientData';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSalesAgentById, getSalesAgentTypes, getSalesAgents, selectAgentTypes, selectCurrentAgent, updateAgent,
} from 'services/salesAgentSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { useLocation, useParams } from 'react-router';
import { Country } from 'react-phone-number-input';
import { matchCountryCode } from 'pages/business/client/ClientPhones';
import { selectOrdersContent } from 'services/ordersSlice';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { defaultAgent } from './SalesAgentData';
import schema from './EditSalesAgent.schema';

export default function EditSalesAgent() {
  const navigate = useNavigationHandler();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { orderId, agentId } = useParams();

  const [countryCode, setCountryCode] = useState('US');
  const [agentTypeOptions, setAgentTypeOptions] = useState([]);
  const order = useSelector(selectOrdersContent);
  const agentTypes = useSelector(selectAgentTypes);
  const agent = useSelector(selectCurrentAgent);
  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  useEffect(() => {
    dispatch(getSalesAgentTypes({}));
  }, []);

  useEffect(() => {
    if (agentTypes) {
      setAgentTypeOptions(agentTypes.map(({ id, name }) => ({
        label: name,
        field: name,
        value: id,
      })));
    }
  }, [agentTypes]);

  useEffect(() => {
    if (agentId) dispatch(getSalesAgentById(parseInt(agentId, 10)));
  }, [agentId]);

  useEffect(() => {
    if (order?.country) setCountryCode(matchCountryCode[order.country]);
  }, [order]);

  const methods = useForm({
    mode: 'all',
    defaultValues: defaultAgent,
  });

  const {
    formState: {
      isValid, isSubmitSuccessful, isSubmitting, isDirty,
    }, getValues, reset, handleSubmit, register,
  } = methods;

  useEffect(() => {
    if (agent) {
      const {
        salesAgentName, salesAgentPhoneNumber, salesAgentPhoneNumberType,
        salesAgentPhoneNumberExt, salesAgentEmail, salesAgentTypeId,
      } = agent;

      reset({
        salesAgentName,
        salesAgentPhoneNumber,
        salesAgentPhoneNumberType,
        salesAgentPhoneNumberExt,
        salesAgentEmail,
        salesAgentTypeId,
      });
    }
  }, [agent]);

  // eslint-disable-next-line consistent-return
  const saveAgent = async () => {
    await dispatch(updateAgent({ content: getValues(), id: parseInt(agentId, 10) }));
    await dispatch(getSalesAgents(orderId));
    if (isRedirectedFromOverviewPage) return navigate.to(Urls.Overview);
    return navigate.to(Urls.SalesRep);
  };

  const customValidation = yup.string()
    .email(t('pages.salesAgent.edit.invalidEmail'))
    .required(t('pages.salesAgent.edit.invalidEmail'));

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onContinue={handleSubmit(saveAgent)}
      onBack={saveAgent}
      header={t('pages.salesAgent.edit.salesAgentDetails')}
      continueButtonLabel={t('pages.salesAgent.edit.continueLabel')}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <GridContainer fullWidth>
            <GridItem>
              <Typography fontSize="20px" fontWeight="bold">{t('pages.salesAgent.edit.contactDetails')}</Typography>
            </GridItem>
            <GridItem sizes={[8]}>
              <IQFormInput
                {...register(
                  'salesAgentName',
                  { required: t('pages.salesAgent.edit.nameRequired') },
                )}
                fullWidth
                theme={theme}
                name="salesAgentName"
                id="salesAgentName"
                labelText={t('pages.salesAgent.edit.name')}
                placeholder=""
                fontLabelWeight="bold"
                schema={schema}
              />
            </GridItem>
            <GridItem sizes={[8]}>
              <IQPhoneInputContainer
                name="salesAgentPhoneNumber"
                defaultValue=""
                country={countryCode as Country}
                hasExtension
                extensionOptions={{
                  name: 'salesAgentPhoneNumberExt',
                  defaultValue: '',
                }}
                hasType
                typeOptions={{
                  name: 'salesAgentPhoneNumberType',
                  defaultValue: PhoneType.MOBILE,
                  values: phoneTypes,
                  required: true,
                }}
                countryCallingCodeEditable={false}
                international
              />
            </GridItem>
            <GridItem sizes={[8]}>
              <IQInputEmail
                name="salesAgentEmail"
                field="salesAgentEmail"
                fontLabelWeight="600"
                theme={theme}
                schema={schema}
                required
                label={t('pages.salesAgent.edit.email')}
                fullWidth
                adornmentIcon={<MailOutlineIcon />}
                customValidation={customValidation}
              />
            </GridItem>
            <GridItem marginTop={3}>
              <Typography fontSize="20px" fontWeight="bold">{t('pages.salesAgent.edit.salesAgentType')}</Typography>
            </GridItem>
            <GridItem sizes={[9]} className='salesAgentTypeId'>
              <IQButtonRadioContainer
                label={t('pages.salesAgent.edit.selectSalesAgentType')}
                name="salesAgentTypeId"
                optionsList={agentTypeOptions}
              />
            </GridItem>
          </GridContainer>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting) && isRedirectedFromOverviewPage}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
