import {
  Grid, Typography, RadioGroup, FormControlLabel, Radio, Box, Tooltip,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: 'black !important',
    maxWidth: '350px !important',
    color: 'white !important',
    fontSize: '11px !important',
  },
});

export default function RadioButtonWithTooltip(props: any) {
  const { options: { enumOptions } } = props;
  const toolTipFields = props.schema.tooltip;
  const classes = useStyles();
  return (
    <Grid item mb={5}>
      <Grid>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2" component="h3" fontSize={16}>
            {props.label}
          </Typography>
          {
                toolTipFields.titleToolTip
                && (
                <Tooltip
                  title={toolTipFields.titleToolTipText}
                  classes={{ tooltip: classes.customTooltip }}
                  placement="top"
                >
                  <InfoOutlinedIcon color="primary" sx={{ marginLeft: 1, fontSize: '15px' }} />
                </Tooltip>
                )
            }
        </Box>
      </Grid>
      <Grid>
        <Box display="flex" alignItems="center">
          <RadioGroup
            aria-labelledby="topic-location"
            name={props.name}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          >
            {enumOptions.map(option => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
          {toolTipFields.radioButtonToolTip
            && (
              <Tooltip title={toolTipFields.radioButtonToolTipText}
                classes={{ tooltip: classes.customTooltip }}
                placement="top"
              >
                <InfoOutlinedIcon color="primary" sx={{ marginLeft: -1, fontSize: '15px' }} />
              </Tooltip>
            )}
        </Box>
      </Grid>
    </Grid>
  );
}
