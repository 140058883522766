import {
  Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteThemeBenefit, getThemeBenefits, selectThemeBenefits } from 'services/adminSlice';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

export default function ThemeBenefits() {
  const themeBenefits = useSelector(selectThemeBenefits);
  const dispatch = useDispatch();
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    dispatch(getThemeBenefits({}));
  }, []);

  const deleteBenefit = (id) => {
    dispatch(deleteThemeBenefit(id));
  };

  return (
    <>
      <Typography variant="h2">Listing Theme Benefits</Typography>
      <Grid container>
        <Grid item xs={12} marginTop={1}>
          <Link to="/admin/themeBenefits/new" style={{ marginRight: '10px' }}>
            <Button variant="contained">
              <Typography>New Theme Benefit</Typography>
            </Button>
          </Link>
          <Link to="/admin/dashboard">
            <Button variant="contained">
              <Typography>Admin Dashboard</Typography>
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} marginTop={5}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ borderBottom: '3px solid lightgray' }}>
                  <TableCell><Typography fontWeight="bold">Title</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Description</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Actions</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {themeBenefits.map(benefit => (
                  <TableRow
                    key={benefit.id}
                    sx={{
                      border: 0,
                      '& .MuiTableCell-root': { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Typography>
                        {benefit.title}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {benefit.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Link to={`${benefit.id}/edit`} style={{ marginRight: '10px' }}>
                        <Button variant="contained">
                          <Typography>Edit</Typography>
                        </Button>
                      </Link>
                      <Button variant="contained" color="error"
                        onClick={() => setItemToDelete(benefit)}
                      >
                        <Typography>Destroy</Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DeleteConfirmationModal
            open={!!itemToDelete}
            onClose={() => setItemToDelete(null)}
            onConfirm={() => deleteBenefit(itemToDelete.id)}
            resourceName={itemToDelete?.title} resourceType="Theme Benefit"
          />
        </Grid>
      </Grid>
    </>
  );
}
