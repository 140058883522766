/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import AxiosAPI from './common/axios';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { thunkBuilder } from './common/functions';

export interface CampaignsState {
  orderItems: StatusType<any>
  campaignItems: StatusType<any[]>
  orderItemsCidStatus: StatusType<any[]>
}

const initialState: CampaignsState = {
  orderItems: {
    status: 'idle',
    message: '',
    content: {},
  },
  campaignItems: {
    status: 'idle',
    message: '',
    content: [],
  },
  orderItemsCidStatus: {
    status: 'idle',
    message: '',
    content: [],
  },
};

export const getOrderItemsWithCampaignId = createAsyncThunkCatchErrors(
  'campaign/getItemsWithCampaignId',
  async (orderId: string) => {
    const response = await AxiosAPI.get(`/orders/${orderId}/items?size=20`);
    return response.data;
  },
);

export const getOrderItemsWithCidStatus = createAsyncThunkCatchErrors(
  'campaign/getItemsWithCidStatus',
  async (orderId: string) => {
    const response = await AxiosAPI.get(`/orders/${orderId}/campaigns`);
    return response.data;
  },
);

export const putOrderItemsWithCampaignId = createAsyncThunkCatchErrors(
  'campaign/putItemsWithCampaignId',
  async (resource: any) => {
    const response = await AxiosAPI.put('/orders/items/campaigns', resource);
    return response.data;
  },
);

export const getCampaignIdsWithGmaid = createAsyncThunkCatchErrors(
  'campaign/getCampaigns',
  async (gmaid: string) => {
    const response = await AxiosAPI.get(`/integrations/advertiser/${gmaid}/campaigns`);
    return response.data;
  },
);

export const campaignsSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder)
      .addCase(getOrderItemsWithCampaignId, 'orderItems')
      .addCase(getCampaignIdsWithGmaid, 'campaignItems')
      .addCase(getOrderItemsWithCidStatus, 'orderItemsCidStatus');
  },
);

export const selectOrderItemsWithCampaignId = (state: RootState) => state.campaign.orderItems;
export const selectCampaignItems = (state: RootState) => state.campaign.campaignItems;
export const selectOrderItemsWithCidStatus = (state: RootState) => state.campaign.orderItemsCidStatus.content;

export default campaignsSlice;
