import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Container from '@mui/material/Container';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Grid from '@mui/material/Grid'; // Grid version 2
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { setCurrentProviderCategories, setProvider, setServiceAreas } from 'services/businessLocationSlice';
import {
  getOrder, getOrderItems, selectOrderItems, selectOrdersContent,
} from 'services/ordersSlice';
import scrollModalToTop from 'shared/scrollModalToTop';
import { SaveAndCloseContext } from 'context/SaveAndCloseContext';
import { backgroundDefaultColor } from 'styles/common_styles';
import { selectActiveOfferingType } from 'services/navigationSlice';
import { OrderFlow } from 'shared/constants';

export interface StepperRootProps {
  onContinue?: () => any;
  onBack?: () => any;
  onUpdateStatus?: (values:any, isValid: boolean) => any;
  formId: string;
  locationType?: string;
}

export type ButtonType = 'submit' | 'button' | 'reset';

export const locationsTypes = {
  STANDARD: 'STANDARD',
  PREMIUM_LISTINGS: 'PREMIUM_LISTINGS',
  HEALTHCARE: 'HEALTHCARE',
};

export const StepperLayout = (props: any) => {
  const { t } = useTranslation();
  const { formId } = props;
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const history: any = useLocation();
  const { step } = useParams();
  const orderItems = useSelector(selectOrderItems);
  const order = useSelector(selectOrdersContent);
  const [steps] = React.useState(props.steps);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isValid, setValid] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [showContinueModal, setShowContinueModal] = React.useState(false);
  const [showLeavePageModal, setShowLeavePageModal] = React.useState(false);
  const [requestedTab, setRequestedTab] = React.useState(0);
  const [buttonType, setButtonType] = React.useState<ButtonType>('submit');
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);
  const offeringType = useSelector(selectActiveOfferingType);
  const isOrderCompleted = useSelector(selectOrdersContent)?.completed;
  const ordersContent = useSelector(selectOrdersContent);
  const { orderFlow } = ordersContent;
  const [isPunchlist, setIsPunchlist] = useState(false);
  const saveAndCloseContext = React.useContext(SaveAndCloseContext);

  useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    orderFlow === OrderFlow.PUNCHLIST ? setIsPunchlist(true) : setIsPunchlist(false);
  }, [orderId]);

  const [locationsType, setLocationsType] = React.useState(null);

  useEffect(() => {
    dispatch(getOrderItems(orderId));
  }, []);

  useEffect(() => {
    if (Object.keys(order).length === 0) dispatch(getOrder(orderId));
  }, [order]);

  useEffect(() => {
    if (orderItems.content.length > 0) {
      const premiumListingItems = orderItems.content.filter(item => item.offeringType === 'PremiumListing');
      if (premiumListingItems.length > 0) {
        const isHealthcare = premiumListingItems.filter(item => item.healthcare)?.length > 0;
        setLocationsType(isHealthcare ? locationsTypes.HEALTHCARE : locationsTypes.PREMIUM_LISTINGS);
      } else setLocationsType(locationsTypes.STANDARD);
    }
  }, [orderItems]);

  const totalSteps = () => steps.length;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const allStepsCompleted = () => {
    let completedAll = true;
    steps.forEach((step) => {
      if (!step.isCompleted) {
        completedAll = false;
      }
    });
    return completedAll;
  };
  const navigate = useNavigationHandler();

  const handleNext = () => {
    if (isRedirectedFromOverviewPage) {
      if (saveAndCloseContext.isFormDirty) {
        setShowContinueModal(true);
        return;
      }
      navigate.to(Urls.Overview, undefined, false);
      return;
    }
    if (isLastStep() && allStepsCompleted()) {
      if (props.continueTo) {
        navigate.to(props.continueTo);
        return;
      } if (orderItems?.content.length > 0) {
        navigate.to(Urls.BusinessLocation);
      }
      if (orderItems.content.filter(item => item?.healthcare === true).length > 0) {
        navigate.to(Urls.PlHealthcareLocations);
      } else navigate.to(Urls.BusinessLocation);
    } else {
      const newActiveStep = activeStep + 1;
      setActiveStep(newActiveStep);
      const newActiveStepName = steps[newActiveStep].name;
      navigate.to(`/${newActiveStepName}`);
    }
  };

  const departOrderFlow = () => {
    if (isPunchlist) {
      navigate.to(`${Urls.PunchlistSaved}`);
    } else {
      navigate.to(Urls.Orders, undefined, false);
    }
  };

  const onSaveClicked = () => {
    if (steps[activeStep].name === 'address') {
      setButtonType('button');
      setIsModalOpen(true);
    } else departOrderFlow();
  };

  // Specific edge cases of back navigations in custom quotes
  const customWebsiteBackRedirections = () => {
    if (offeringType && offeringType === 'CustomWebsite') {
      const currentUrl = window.location.href;
      if (step === Urls.Content) return navigate.to(`${Urls.CustomWebsiteFeatureList}/${Urls.Additional}`);
      if (currentUrl.includes(Urls.CustomWebsiteFeatureList)) return navigate.to(Urls.CustomWebsiteFeatures);
      if (currentUrl.includes(Urls.CustomWebsiteDesignItems)) return navigate.to(Urls.CustomWebsiteDesign);
    }
    if (orderItems.content.filter(item => item?.healthcare === true).length > 0) {
      return navigate.to(Urls.PlHealthcareLocations);
    }
    return navigate.to(Urls.BusinessLocation);
  };

  // eslint-disable-next-line consistent-return
  const handleBack = () => {
    if (isRedirectedFromOverviewPage) return navigate.to(Urls.Overview, undefined, false);

    if (activeStep === 0) {
      if (steps[activeStep].name === 'address') {
        if (isOrderCompleted) return navigate.to('review?reviewId=true');
        dispatch(setServiceAreas([]));
      }
      customWebsiteBackRedirections();
    } else {
      if (steps[activeStep].name === 'provider') {
        dispatch(setProvider({}));
        dispatch(setCurrentProviderCategories([]));
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      const newActiveStepName = steps[activeStep - 1].name;
      navigate.to(`/${newActiveStepName}`);
    }
  };

  const handleStepNavigation = (step: number) => {
    setActiveStep(step);
    const newActiveStepName = steps[step].name;
    navigate.to(`/${newActiveStepName}`);
  };

  const handleStep = (step: number) => () => {
    if (isRedirectedFromOverviewPage) {
      setShowLeavePageModal(true);
      setRequestedTab(step);
    } else handleStepNavigation(step);
  };

  const confirmLeaveNavigation = () => {
    handleStepNavigation(requestedTab);
    setShowLeavePageModal(false);
  };

  const handleComplete = () => {
    steps[activeStep].isCompleted = true;
    handleNext();
  };

  useEffect(() => {
    const activeStep = steps.map(e => e.name).indexOf(step);
    if (activeStep !== -1) setActiveStep(activeStep);
    if ((activeStep === totalSteps() - 1) && (offeringType !== 'CustomWebsite') && !isRedirectedFromOverviewPage) {
      setTimeout(() => setActiveStep(0), 50);
    }
  }, [offeringType]);

  const onContinue = () => {
    handleComplete();
  };

  const onBack = () => {
    handleBack();
  };

  const onUpdateStatus = (values: any, isValid: boolean) => {
    setValid(isValid);
  };

  const renderStepContent = () => steps[activeStep].component({
    onContinue, onBack, onUpdateStatus, formId, locationsType,
  });

  const getContinueTitles = () => (isRedirectedFromOverviewPage ? 'Save & Return' : t(steps[activeStep].continueTitle));

  const isHidden = () => steps[activeStep].hideContinue;

  const getTitle = (s) => t(s.title);

  const buildLabelForStep = (index: number, title: string) => {
    if (activeStep === index) {
      return (
        <Typography variant="subtitle1" gutterBottom color="blue"
          sx={{ fontWeight: 'bold' }}
        >
          {title}
        </Typography>
      );
    }
    return (
      <Typography variant="subtitle1" gutterBottom color="black">
        {title}
      </Typography>
    );
  };

  const confirmSaveClose = () => {
    setIsModalOpen(false);
    departOrderFlow();
  };

  const confirmContinueClose = () => {
    setShowContinueModal(false);
    navigate.to(Urls.Overview);
  };

  const modalRef = useRef(null);

  useEffect(() => {
    scrollModalToTop(modalRef);
  }, [steps[activeStep]]);

  const backButtonLabel = isRedirectedFromOverviewPage ? 'Cancel' : 'Back';

  const confirmModalDescription = (
    <div>
      <Typography marginBottom={2}>Are you sure you want to save these changes?</Typography>
      <Typography>
        <b>Note: </b>
        Changes made in the &lsquo;Business Section&lsquo; may impact the accuracy
        of the order so please review the order overview before submitting.
      </Typography>
    </div>
  );

  return (
    <Container>
      <Dialog
        open
        fullScreen
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          '& .MuiDialogContent-root': {
            overflowY: 'unset',
            paddingBottom: '80px',
            height: 'auto',
            backgroundColor: backgroundDefaultColor,
          },
          '& .MuiDialogActions-root': {
            backgroundColor: backgroundDefaultColor,
            paddingBottom: '80px',
            justifyContent: 'center',
          },
          '& .MuiDialogContent-root > .MuiGrid-root': {
            maxWidth: '968px',
            marginLeft: 'auto',
            marginRight: 'auto',
          },
          '& .MuiDialogContent-root > .MuiGrid-root > .MuiGrid-root': {
            paddingLeft: '0',
          },
        }}
      >
        <DialogTitle id="scroll-dialog-title" ref={modalRef}>
          <Grid container>
            <Grid item xs={10}>
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {React.Children.toArray(steps.map((obj, index) => (
                    (index !== activeStep && obj.isCompleted)
                      ? (
                        <Step completed>
                          <StepButton onClick={handleStep(index)}>
                            {buildLabelForStep(index, getTitle(obj))}
                          </StepButton>
                        </Step>
                      )
                      : (
                        <Step>
                          <StepButton onClick={handleStep(index)}>
                            {buildLabelForStep(index, getTitle(obj))}
                          </StepButton>
                        </Step>
                      )
                  )))}
                </Stepper>
              </Box>
            </Grid>
            <Grid item xs={2} justifyContent="flex-end"
              sx={{ textAlign: 'right' }}
            >
              <Button variant="text" onClick={onSaveClicked}
                type={buttonType}
                form={formId}
                sx={{ fontWeight: '600', textDecoration: 'underline' }}
              >
                {offeringType === 'CustomWebsite' ? 'Save & Close' : 'Save Location & Close'}
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers sx={{ height: '100vh' }}>
          <Grid container>
            <Grid item xs={12} md={10}
              lg={props.fullWidth ? 12 : 8} sx={{ pl: '5rem' }}
            >
              {locationsType && renderStepContent()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              p: 1,
              m: 1,
              pl: '5rem',
              pr: '5rem',
              borderRadius: 1,
              maxWidth: '968px',
            }}
          >
            <Button variant="outlined" onClick={onBack} startIcon={<ArrowBackIosIcon />}
              sx={{
                fontSize: '20px',
                padding: '8px 22px',
                backgroundColor: 'common.white',
              }}
            >
              {backButtonLabel}
            </Button>
            {
              !isHidden() && (
              <Button disabled={!isValid} variant="contained" type="submit"
                form={formId}
                endIcon={<ArrowForwardIosIcon />}
                sx={{
                  fontSize: '20px',
                  padding: '8px 22px',
                }}
              >
                {getContinueTitles()}
              </Button>
              )
            }
          </Box>
        </DialogActions>
      </Dialog>
      <CoModalConfirm
        open={isModalOpen}
        title="Are you sure you want to close?"
        description="If address was not verified, changes won't be saved."
        confirmText="Confirm"
        handleClose={() => setIsModalOpen(false)}
        handleConfirm={confirmSaveClose}
      />
      <CoModalConfirm
        open={showContinueModal}
        title="Save Changes"
        description={confirmModalDescription}
        confirmText="Save"
        cancelText="Back"
        handleClose={() => setShowContinueModal(false)}
        handleConfirm={confirmContinueClose}
      />
      <CoModalConfirm title="Leaving page?"
        description="Are you sure you want to leave this page? The information you have entered may not be saved."
        confirmText="Leave page" cancelText="Stay on page"
        open={showLeavePageModal} handleClose={() => setShowLeavePageModal(false)}
        handleConfirm={confirmLeaveNavigation}
      />
    </Container>
  );
};
