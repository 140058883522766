import React, {useEffect, useMemo, useState} from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  Grid,
  IconButton, Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import i18n from 'i18n/i18n';
import {urlValidationRegex} from "shared/constants";
const { t } = i18n;

type GroupAd = {
  adGroupFocus: string;
  adGroupLandingPage: string;
};

const initAdGroupState = () : GroupAd => ({adGroupFocus: '', adGroupLandingPage: ''});

export default function AddAdGroup(props: any) {
  const theme = useTheme();
  const [adGroups, setAdGroups] = useState<GroupAd[]>(props.value || []);
  const [open, setOpen] = useState(false);
  const [adGroup, setAdGroup] = useState<GroupAd>(initAdGroupState());
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const maxReached = useMemo(() => adGroups.length > 9, [adGroups])
  const currentWindowCount = useMemo(() => editIndex!==null ? editIndex+1 : adGroups.length + 1, [editIndex, adGroups]);
  
  const handleClickOpen = (index: number | null = null) => {
    if (index !== null) {
      setAdGroup(adGroups[index]);
      setEditIndex(index);
    } else {
      setAdGroup(initAdGroupState);
      setEditIndex(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsValidUrl(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setAdGroup((prevWebEvent) => ({...prevWebEvent, [name]: value}));
    if (name === 'adGroupLandingPage') {
      setIsValidUrl(validateUrl(value));
    }
  };

  const validateUrl = (url: string) => {
    return urlValidationRegex.test(url);
  };

  const handleSubmit = () => {
    let items = [...adGroups];
    const newItem = {...adGroup};
    const idx = editIndex !== null ? editIndex : items.length;
    items.splice(idx, 1, newItem);
    setAdGroups(items);
    setAdGroup(initAdGroupState());
    handleClose();
  };

  const handleDelete = (index: number) => {
    setAdGroups((prevItem) => prevItem.filter((_, i) => i !== index));
  };

  useEffect(() => {
    props.onChange(adGroups.map(ad => ({...ad})))
  }, [adGroups]);

  return (
      <Grid item xs={9} my={3}>
        <Grid>
          <Box>
            {adGroups && adGroups.map((ad, index) => (
                <Grid key={index}>
                  <Box
                      mb={1}
                      sx={{
                        padding: '10px',
                        display: 'flex',
                        fontSize: '15px',
                        alignItems: 'center',
                        backgroundColor: '#ffffff',
                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
                      }}
                  >
                    <Box sx={{
                      flexGrow: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginRight: '20%'
                    }}>
                      <Box>
                        <Typography variant="h6" sx={{marginBottom: '10px', fontWeight: 'bold'}}>
                          {t('addGroupWidget.title', {count: index + 1})}
                        </Typography>
                        <div style={{margin: 0}}>
                          <span style={{fontWeight: 'bold'}}>{t('addGroupWidget.name')}:</span>
                          <span style={{
                            marginLeft: '5px',
                            wordBreak: 'break-word'
                          }}>{ad.adGroupFocus}</span>
                        </div>
                        <div style={{margin: '10px 0 0 0'}}>
                          <span style={{fontWeight: 'bold'}}>{t('addGroupWidget.url')}:</span>
                          <span style={{
                            marginLeft: '5px',
                            wordBreak: 'break-word'
                          }}>{ad.adGroupLandingPage}</span>
                        </div>
                      </Box>
                    </Box>
                    <IconButton onClick={() => handleClickOpen(index)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleDelete(index)}>
                      <DeleteIcon/>
                    </IconButton>
                  </Box>
                </Grid>
            ))}
            <Box
                sx={{
                  padding: '20px 20px 20px 0',
                  backgroundColor: theme.palette.primary.light,
                }}
            >
              <Button disabled={maxReached} startIcon={<AddIcon/>} onClick={() => handleClickOpen()}>
                {t('addGroupWidget.add')}
              </Button>
            </Box>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{t('addGroupWidget.title', {count: currentWindowCount})}</DialogTitle>
              <DialogContent>
              <TextField
                    margin="dense"
                    name="adGroupFocus"
                    label={t('addGroupWidget.name')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={adGroup.adGroupFocus}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    name="adGroupLandingPage"
                    label={t('addGroupWidget.url')}
                    type="url"
                    fullWidth
                    variant="outlined"
                    value={adGroup.adGroupLandingPage}
                    onChange={handleChange}
                    error={!isValidUrl}
                    helperText={!isValidUrl ? "Please enter a valid URL starting with http:// or https://" : ""}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t('addGroupWidget.cancel')}
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={!isValidUrl}>
                    {t(editIndex === null ? 'addGroupWidget.save' : 'addGroupWidget.update')}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
  );
}