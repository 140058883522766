/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import {
  Business, BusinessCategory, BusinessCefCategory, RlopBusinessCategory,
} from 'models/BusinessType';
import { Attribute } from 'pages/locations/info/AttributeSelector';
import AxiosAPI, { AxiosArgonInternalAPI } from './common/axios';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { thunkBuilder } from './common/functions';

export interface BusinessState {
  business: StatusType<any>
  businessSearch: StatusType<any>
  orderBusiness: StatusType<Business>
  businessCategories: StatusType<BusinessCategory[]>
  businessCefCategories: StatusType<BusinessCefCategory>
  rlopBusinessCategories: StatusType<RlopBusinessCategory[]>
  rlopBusinessSubCategories: StatusType<RlopBusinessCategory[]>
  categoriesSuggestions: StatusType<any>
  attributeSuggestions: StatusType<Attribute[]>
  businessSelectionType: StatusType<any>
  businessServicesProductsSuggestions: StatusType<any>
}

export interface BusinessRequest {
  affiliateOrder: boolean
  businessCategoryOther: string
  businessName: string
  categories: []
  gmaid: string
  orderId: string
  orderSourceId: null
  sourceId: null
}

const initialState: BusinessState = {
  business: {
    status: 'idle',
    message: '',
    content: {},
  },
  businessSearch: {
    status: 'idle',
    message: '',
    content: {
      content: [],
      totalElements: 0,
    },
  },
  orderBusiness: {
    status: 'idle',
    message: '',
    content: {} as Business,
  },
  businessCategories: {
    status: 'idle',
    message: '',
    content: [],
  },
  businessCefCategories: {
    status: 'idle',
    message: '',
    content: {} as BusinessCefCategory,
  },
  rlopBusinessCategories: {
    status: 'idle',
    message: '',
    content: [] as RlopBusinessCategory[],
  },
  rlopBusinessSubCategories: {
    status: 'idle',
    message: '',
    content: [] as RlopBusinessCategory[],
  },
  categoriesSuggestions: {
    status: 'idle',
    message: '',
    content: [],
  },
  attributeSuggestions: {
    status: 'idle',
    message: '',
    content: [],
  },
  businessSelectionType: {
    status: 'idle',
    message: '',
    content: {
      isNewBusiness: false,
    },
  },
  businessServicesProductsSuggestions: {
    status: 'idle',
    message: '',
    content: [],
  },
};

export const getBusiness = createAsyncThunkCatchErrors(
  'business/get',
  async (businessId: string) => {
    const response = await AxiosAPI.get(`/business/${businessId}`);
    return response.data;
  },
);

export const searchBusinesses = createAsyncThunkCatchErrors(
  'business/search',
  async ({
    businessName, country, page = 0, pageSize = 10, sort,
  } :
  { businessName: string, country: string, page: number, pageSize: number, sort?: string }) => {
    const response = await AxiosAPI.get(
      `/business/filterName?country=${country}&nameOrGMAID=${businessName}&page=${page}&size=${pageSize}&sort=${sort}`,
    );
    return response.data;
  },
);

export const searchBusinessesMP = createAsyncThunkCatchErrors(
  'marketplace/business/search',
  async ({
    query, country,
  } :
  { query: string, country: string }) => {
    const response = await AxiosArgonInternalAPI.get(
      `pl-api/marketplace/searchbusinesses?query=${query}&country=${country}`,
    );
    const filteredBusinesses = response.data.filter(b => b.business.country === country);
    const contentObjects = filteredBusinesses.map((b, index) => ({
      id: index,
      gmaid: b.business.gmaid,
      businessName: b.business.name,
      affiliateOrder: false,
      businessCategory: [],
      city: b.business.city,
      stateProvince: b.business.province,
      businessLocation: b.businessLocations,
      selected: false,
    }));
    const shapedResponse = {
      content: contentObjects,
      empty: false,
      first: true,
      last: false,
      number: 0,
      numberOfElements: 10,
      totalElements: 200,
    };
    return shapedResponse;
  },
);

export const getBusinessCategories = createAsyncThunkCatchErrors(
  'business/getCategories',
  async () => {
    const response = await AxiosAPI.get(
      '/business/categories',
    );
    return response.data.content;
  },
);

export const getBusinessCategoriesSug = createAsyncThunkCatchErrors(
  'business/getCategoriesSug',
  async (categoryName: string) => {
    const response = await AxiosArgonInternalAPI.get(
      `/pl-api/business/categories?query=${categoryName}&country=usa&language=english&max=100`,
    );

    const uniqueByName = Object.values(response.data.response.results.results.reduce((acc, item) => {
      if (!acc[item.name]) {
        acc[item.name] = item;
      }
      return acc;
    }, {}));

    return uniqueByName;
  },
);

export const getCategoryAttributes = createAsyncThunkCatchErrors(
  'business/getCategoryAttributes',
  async (locationId: string) => {
    const response = await AxiosAPI.get(`pl/locations/${locationId}/suggest-attributes`);
    return response.data;
  },
);

// save business on state
export const setOrderBusiness = createAction<Business>('business/orderBusiness');

// add business to order - currently after verify search
export const putOrderBusiness = createAsyncThunkCatchErrors(
  'business/putOrderBusiness',
  async ({ orderId, businessId }: { orderId: string, businessId: string }) => {
    const response = await AxiosAPI.put(`/orders/${orderId}/business/${businessId}`, businessId);
    return response.data.content;
  },
);

// get business and update state.orderBusiness
export const getOrderBusiness = createAsyncThunkCatchErrors(
  'business/getOrderBusiness',
  async (businessId: string) => {
    const response = await AxiosAPI.get(`/business/${businessId}`);
    return response.data;
  },
);

// get business by orderId
export const getOrderBusinessByOrderId = createAsyncThunkCatchErrors(
  'business/getOrderBusinessbyOrderId',
  async (orderId: string) => {
    const response = await AxiosAPI.get(`/business/order/${orderId}`);
    return response.data;
  },
);

// add new business
export const addNewBusiness = createAsyncThunkCatchErrors(
  'business/addNewBusiness',
  async (request: BusinessRequest) => {
    const response = await AxiosAPI.post('/business', request);
    return response.data;
  },
);

// update business
export const updateBusiness = createAsyncThunkCatchErrors(
  'business/updateBusiness',
  async ({ id, request }: { id: string, request: BusinessRequest }) => {
    const response = await AxiosAPI.put(`/business/${id}`, request);
    return response.data;
  },
);

export const getCefCategoriesByGmaid = createAsyncThunkCatchErrors(
  'business/getCefCategoriesByGmaid',
  async (gmaid: string) => {
    const response = await AxiosAPI.get(`/integrations/force/categories/${gmaid}`);
    return response.data;
  },
);

export const getRlopBusinessCategories = createAsyncThunkCatchErrors(
  'business/getRlopCategories',
  async (categoryName: string) => {
    const response = await AxiosAPI.get(
      `/business/categories/rlop?query=${categoryName}`,
    );
    return response.data;
  },
);

export const getRlopBusinessSubCategories = createAsyncThunkCatchErrors(
  'business/getRlopSubCategories',
  async ({ query, categoryId }: { query: string; categoryId: number }) => {
    const response = await AxiosAPI.get(
      `/business/categories/rlop/${categoryId}?query=${query}`,
    );
    return response.data;
  },
);

export const getServicesProductsByBusinessCategoryId = createAsyncThunkCatchErrors(
  'business/getServicesProductsByBusinessCategoryId',
  async ({ businessCategoryId, country }: { businessCategoryId: string, country: string }) => {
    const response = await AxiosAPI
      .get(`public/uberall/categoryServices?categoryId=${businessCategoryId}&country=${country}&lang=EN`);
    return response.data.categoryServices;
  },
);

export const setBusinessSelectionType = createAction<any>('business/businessSelectionType');

export const businessSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder)
      .addCase(getBusiness, 'business')
      .addCase(getOrderBusiness, 'orderBusiness')
      .addCase(getOrderBusinessByOrderId, 'orderBusiness')
      .addCase(searchBusinessesMP, 'businessSearch')
      .addCase(getBusinessCategories, 'businessCategories')
      .addCase(getCategoryAttributes, 'attributeSuggestions')
      .addCase(getBusinessCategoriesSug, 'categoriesSuggestions')
      .addCase(getCefCategoriesByGmaid, 'businessCefCategories')
      .addCase(getServicesProductsByBusinessCategoryId, 'businessServicesProductsSuggestions')
      .addCase(getRlopBusinessCategories, 'rlopBusinessCategories')
      .addCase(getRlopBusinessSubCategories, 'rlopBusinessSubCategories');

    builder
      .addCase(setOrderBusiness, (state, action) => {
        state.orderBusiness.content = action.payload;
      })
      .addCase(setBusinessSelectionType, (state, action) => {
        state.businessSelectionType.content = action.payload;
      });
  },
);

export const selectBusiness = (state: RootState) => state.business.business.content;

export const selectBusinessSearch = (state: RootState) => state.business.businessSearch.content;

export const selectBusinessSearchStatus = (state: RootState) => state.business.businessSearch.status;

export const selectOrderBusiness = (state: RootState) => state.business.orderBusiness;

export const selectBusinessCategories = (state: RootState) => state.business.businessCategories;

export const selectCategoryAttributes = (state: RootState) => state.business.attributeSuggestions.content;

export const selectBusinessCategoriesSug = (state: RootState) => state.business.categoriesSuggestions;

export const selectBusinessCategoriesStatus = (state: RootState) => state.business.businessCategories.status;

export const selectBusinessCategoriesSuggestions = (state: RootState) => state.business.categoriesSuggestions.content;

export const selectBusinessSelectionType = (state: RootState) => state.business.businessSelectionType.content;

export const selectBusinessCefCategories = (state: RootState) => state.business.businessCefCategories.content;

// eslint-disable-next-line max-len
export const selectServicesProductsCategoriesSuggestions = (state: RootState) => state.business.businessServicesProductsSuggestions.content;

export const selectRlopBusinessCategories = (state: RootState) => state.business.rlopBusinessCategories.content;

export const selectRlopBusinessSubCategories = (state: RootState) => state.business.rlopBusinessSubCategories.content;

export default businessSlice;
