import i18n from 'i18n/i18n';

import {
  ChatBotCustomValidate,
  ChatBotSchema, ChatBotUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/ChatBotSchema";
import {
  CallConnectCustomValidate,
  CallConnectSchema, CallConnectUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/CallConnect";
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/NotesAndInstructionsSchema";
const { t } = i18n;

export const AusLiveChatSchema = (data) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.ausProduct.ausLiveChat.chatBot.title'),
      schema: ChatBotSchema(data),
      uiSchema: ChatBotUISchema,
      customValidate: ChatBotCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    }
  ];
  const isCallConnectRequired = data.isCallConnectRequired;

  if (isCallConnectRequired === "Yes") {
    schema.splice(1, 0, {
      type: 'object',
      title: t('pages.ausProduct.ausLiveChat.callConnect.title'),
      schema: CallConnectSchema(data),
      uiSchema: CallConnectUISchema,
      customValidate: CallConnectCustomValidate,
    });
  }

  return schema;
};

export const AusLiveChatUISchema = (data) => ({
  'ui:order': [
    ...ChatBotUISchema['ui:order'],
    ...CallConnectUISchema['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

export const AusLiveChatCustomValidate = (formData: any, errors, uiSchema) => errors;