import i18n from 'i18n/i18n';
import * as yup from 'yup';

const { t } = i18n;

const schema = yup.object().shape({
  salesAgentName: yup.string().required(t('pages.salesAgent.edit.nameRequired')),
  salesAgentEmail: yup.string().email(t('pages.salesAgent.edit.invalidEmail'))
    .required(t('pages.salesAgent.edit.invalidEmail')),
  salesAgentPhoneNumber: yup.string().required(),
  salesAgentPhoneNumberType: yup.string().required(),
  salesAgentPhoneNumberExt: yup.string().optional().nullable(),
  salesAgentTypeId: yup.number(),
});

export default {
  yupValidations: schema,
  customValidations: {
  },
};
