import { Box, Typography } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Urls } from 'navigation/Urls';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useEffect, useMemo, useState } from 'react';
import { getGeneralQuestionsByOrderItemId } from 'services/generalQuestionsSlice';
import { useAppDispatch } from 'services/redux/store';
import { tooltip } from 'pages/general-questions/form-sections/Tracking';
import { useSelector } from 'react-redux';
import { selectOrderItemsWithCampaignId, selectOrderItemsWithCidStatus } from 'services/campaignSlice';
import { getCIDStatus } from 'pages/orders/utils';
import {
  selectIsPartnerFlow,
  selectOrderFlow,
  selectOrderItems,
  selectOrdersContent,
} from 'services/ordersSlice';
import { OrderFlow } from 'shared/constants';
import { productsWithTracking } from 'pages/general-questions/GeneralQuestions';
import { TypeOfSaleConstants, TypesOfSale } from 'pages/shopping-cart/Constants';
import { headingStyle } from '../Business/BusinessSection';

const productsWithoutCPL = [
  'ChatProduct',
  'ChatPlatinumProduct',
  'SEOBlogStandard',
  'SEOBlogEnhanced',
  'SEOTopicLocation',
  'SEOContentPages',
  'SMMFacebookPosts',
  'SMMInstagram',
  'SMMX',
  'SMMPinterest',
  'SMMInfographics',
  'PiQLandingPages',
];

export const GeneralQuestionsSection = (offeringType, websiteId, orderItemId, navigate, isEditable) => {
  const appDispatch = useAppDispatch();
  const campaignStatuses = useSelector(selectOrderItemsWithCidStatus);
  const { content: campaignItems } = useSelector(selectOrderItemsWithCampaignId);
  const { content: orderItems } = useSelector(selectOrderItems);
  const orderFlow = useSelector(selectOrderFlow);
  const orderData = useSelector(selectOrdersContent);
  const isSalesForce = orderFlow === OrderFlow.SALES_FORCE;
  const isInternal = orderFlow === OrderFlow.INTERNAL;
  const isPunchlist = (orderFlow === OrderFlow.PUNCHLIST || orderData.orderFlow === OrderFlow.PUNCHLIST);
  const [data, setData] = useState({} as any);
  const [cidStatus, setCidStatus] = useState('');
  const currentOrderItemSaleType = orderItems.filter(item => item.id === orderItemId)?.[0]?.saleType;
  const currentSaleTypeLabel = useMemo(() => TypesOfSale
    .filter(type => type.value === currentOrderItemSaleType)?.[0]?.label, [currentOrderItemSaleType]);
  const isReallocation = useMemo(
    () => currentOrderItemSaleType === TypeOfSaleConstants.BUDGET_REALLOCATION,
    [currentOrderItemSaleType],
  );
  const isRebuild = useMemo(() => currentOrderItemSaleType === TypeOfSaleConstants.REBUILD, [currentOrderItemSaleType]);
  const isExistingCampaign = useMemo(
    () => currentOrderItemSaleType === TypeOfSaleConstants.MODIFICATION,
    [currentOrderItemSaleType],
  );
  const isStandardForm = (!isRebuild && !isExistingCampaign);
  const isPartnerFlow = useSelector(selectIsPartnerFlow);

  useEffect(() => {
    if (orderItemId) {
      appDispatch(getGeneralQuestionsByOrderItemId(orderItemId)).then((response) => setData(response.payload));
    }
  }, [orderItemId]);

  useEffect(() => {
    let status = '';
    if (campaignStatuses.length > 0) {
      status = getCIDStatus(campaignStatuses, orderItemId);
    } else if (campaignItems?.content?.length > 0) {
      const item = campaignItems.content.filter(obj => obj.id === orderItemId);
      status = item[0]?.campaignId;
    }
    setCidStatus(status);
  }, [campaignStatuses, campaignItems]);

  const pendingTooltip = (cidStatus === 'Pending') && (
  <div style={{ marginLeft: 5, display: 'inline' }}>
    {tooltip('Solution is being sent to Campaign Central.', <InfoOutlinedIcon />)}
  </div>
  );

  const renderGeneralSectionDetails = (
    <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {editButton(isEditable, () =>
          navigate.to(Urls.GeneralQuestions, orderItemId))}
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
          {currentSaleTypeLabel || 'General Questions'}
        </Typography>
      </Box>
      <Box>
        {/* Details */}
        <Typography sx={{ fontSize: '18px', margin: '10px 0 20px' }}>Details</Typography>
        {isStandardForm && (
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">CID</Typography>
                <Typography fontWeight="bold">
                  {isPartnerFlow && "CSM to Create CID"}
                  {!isPartnerFlow && cidStatus ? cidStatus : ''}
                  {!isPartnerFlow && pendingTooltip ? pendingTooltip : ''}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Campaign Name</Typography>
                {displayNotRequiredField('campaign name', data?.data?.campaignName)}
              </Box>
              {(isReallocation && data?.data?.budgetReallocationCid) && (
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Budget Reallocation CID</Typography>
                {displayNotRequiredField('budget reallocation CID', data?.data?.budgetReallocationCid)}
              </Box>
              )}
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Campaign Owner</Typography>
                {data?.data?.campaignOwner?.idBusinessUser ? (
                  <Typography>
                    {`${data.data.campaignOwner.firstname} ${data.data.campaignOwner.lastname}`}
                    <br />
                    {data.data.campaignOwner.email.split(';')[0]}
                    <br />
                    {data.data.campaignOwner.priviledge}
                  </Typography>
                ) : <Typography fontWeight="bold">No Campaign Owner provided</Typography>}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Budget</Typography>
                {displayNotRequiredField('budget', data?.data?.budgetOverride ? <> ${data?.data?.budget}
                  <br/>(Budget override was used. Campaign created
                  at ${data?.data?.salesforceMinBudget})</> : data?.data?.budget)
                }
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Initial Cycles</Typography>
                {displayNotRequiredField('initial cycles', data?.data?.initialCycles === 99
                  ? 'Unlimited' : data?.data?.initialCycles)}
              </Box>
            </Box>
          </GridItem>
        )}

        {isRebuild && (
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Most Recent Running CID for Rebuild</Typography>
                {displayNotRequiredField('most recent running CID for rebuild', data?.data?.rebuildCid)}
              </Box>
            </Box>
            <Box />
          </GridItem>
        )}

        {isExistingCampaign && (
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Running MCID</Typography>
                {displayNotRequiredField('running MCID', data?.data?.runningMcid)}
              </Box>
            </Box>
            <Box />
          </GridItem>
        )}

        {/* Date Requests */}
        {isStandardForm && (
        <>
          <Typography sx={{ fontSize: '18px', margin: '20px 0' }}>Date Requests</Typography>
          <GridItem
            sizes={[6, 6]}
            sx={{
              borderBottom: (productsWithTracking.includes(offeringType) || !isPunchlist)
                ? '1px solid #000000' : 'none',
            }}
          >
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Admin Start Date</Typography>
                {displayNotRequiredField('admin start date', data?.data?.adminStartDate)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Auto Renew</Typography>
                {displayNotRequiredField('auto renew', data?.data?.autoRenew)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Requested Admin Start Date</Typography>
                {data?.data?.requestedStart
                  ? <Typography>{data?.data?.requestedStart}</Typography>
                  : <Typography fontWeight="bold">No date provided</Typography>}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Delayed Admin Start Date</Typography>
                {data?.data?.delayedStart
                  ? <Typography>{data?.data?.delayedStart}</Typography>
                  : <Typography fontWeight="bold">No date provided</Typography>}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Payment Date</Typography>
                {displayNotRequiredField('payment date provided', data?.data?.paymentDate)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Requested Payment Date</Typography>
                {data?.data?.requestedPayDate
                  ? <Typography>{data?.data?.requestedPayDate}</Typography>
                  : <Typography fontWeight="bold">No date provided</Typography>}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Delayed Requested Payment Date</Typography>
                {data?.data?.delayedRequestedPayDate
                  ? <Typography>{data?.data?.delayedRequestedPayDate}</Typography>
                  : <Typography fontWeight="bold">No date provided</Typography>}
              </Box>
            </Box>
          </GridItem>
        </>
        )}

        {/* Tracking */}
        {(productsWithTracking.includes(offeringType) && isStandardForm) && (
          <>
            <Typography sx={{ fontSize: '18px', margin: '20px 0' }}>Tracking</Typography>
            <GridItem sizes={[6, 6]} sx={{ borderBottom: !isPunchlist ? '1px solid #000000' : 'none' }}>
              <Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Email (Leads Notification)
                    Preference</Typography>
                  {displayNotRequiredField('email preference', data?.data?.emailPreference)}
                </Box>
                {data?.data?.emailPreference && data?.data?.emailPreference !== "Off - No Emails" &&
                    <Box mb={2}>
                      <Typography sx={headingStyle} color="primary">Email Frequency</Typography>
                      {displayNotRequiredField('email frequency', data?.data?.emailFrequency)}
                    </Box>
                }
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Tracking Method</Typography>
                  {displayNotRequiredField('tracking method', data?.data?.trackingMethod)}
                </Box>
              </Box>
              <Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Average Transaction Value</Typography>
                  {displayNotRequiredField('average transaction value', data?.data?.averageTransactionValue)}
                </Box>
                {!productsWithoutCPL.includes(offeringType) && (
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Cost Per Lead (CPL) Goal</Typography>
                  {displayNotRequiredField('cost per lead goal', data?.data?.costPerLead)}
                </Box>
                )}
              </Box>
            </GridItem>
          </>
        )}

        {/* Payments */}
        {(!isPunchlist && !isExistingCampaign) && (
          <>
            <Typography sx={{ fontSize: '18px', margin: '20px 0' }}>Payments</Typography>
            <GridItem sizes={[6, 6]}>
              <Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Payment Method</Typography>
                  {data?.data?.paymentMethod?.length
                    ? data?.data?.paymentMethod?.map((method) => (
                      <Typography key={method}>{method}</Typography>))
                    : <Typography fontWeight="bold">No payment methods</Typography>}
                </Box>
              </Box>
              <Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Process Payment</Typography>
                  {displayNotRequiredField('process payment', data?.data?.servicesProcessPayment)}
                </Box>
              </Box>
            </GridItem>
          </>
        )}
      </Box>
    </GridItem>
  );

  const renderCampaignId = (
    <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!isPartnerFlow && editButton(isEditable, () => navigate.to(Urls.BusinessCampaign))}
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Campaign</Typography>
      </Box>
      <Box>
        {/* Details */}
        <Typography sx={{ fontSize: '18px', margin: '10px 0 20px' }}>Details</Typography>
        <GridItem sizes={[6, 6]}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">CID</Typography>
              <Typography fontWeight="bold">
                {cidStatus}
              </Typography>
            </Box>
          </Box>
        </GridItem>
      </Box>
    </GridItem>
  );
  return (isSalesForce || isPunchlist || isPartnerFlow || isRebuild || isInternal) ? renderGeneralSectionDetails : renderCampaignId;
};
