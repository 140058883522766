// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import { IQTheme, IQThemeV2 } from '@gannettdigital/shared-react-components';
import { S1 } from '../labels/S1';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 556,
  bgcolor: 'background.paper',
  border: '1px',
  borderStyle: 'solid',
  borderColor: IQTheme.palette.info.main,
  borderRadius: '3px',
  boxShadow: 24,
  p: 4,
  padding: '0 8px 8px 24px',
};

const CoButtonStyled = styled(Button)`
  &.Mui-disabled {
    color: #FFFFFF
  }

  text-transform: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-left: 8px;  
  min-width: 95px;
  height: 40px; 
  border-radius: 50px;
  border: 1px solid #1665cf;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
`;

interface CoModalConfirmProps {
  title: string,
  description: string | React.ReactNode,
  confirmText?: string,
  cancelText?: string,
  showCancel?: boolean,
  open: boolean
  handleClose: () => any
  handleConfirm?: () => any
  className?: string;
  isPunchlist?: boolean
}

export default function CoModalConfirm(props: CoModalConfirmProps) {
  const {
    showCancel = true, isPunchlist = false,
  } = props;

  const confirmClick = () => {
    props.handleConfirm();
    if (!isPunchlist) props.handleClose();
  };

  return (
    <div>
      <Modal
        className={props.className}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: IQTheme.palette.common.black, opacity: '0.86' },
        }}
      >
        <Fade in={props.open}>
          <Grid container sx={style}>
            {!isPunchlist && (
              <Grid item xs={12} textAlign="end">
                <IconButton aria-label="close" onClick={props.handleClose} sx={{ paddingRight: '0' }}>
                  <CloseIcon sx={{ color: IQThemeV2.palette.text.primary, fontSize: '32px' }} />
                </IconButton>
              </Grid>
            )}
            <Grid item xs={12}>
              <S1 sx={{
                fontSize: '18px',
                fontWeight: 700,
                lineHeight: '27px',
                color: '#03334E',
                marginTop: isPunchlist ? '20px' : '0',
              }}
              >
                <strong>{props.title}</strong>
              </S1>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '13px' }}>
              <S1 sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>
                {props.description}
              </S1>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'right', marginTop: '27px' }}>
              {showCancel && (
                <CoButtonStyled
                  variant="outlined"
                  color="primary"
                  onClick={props.handleClose}
                  sx={{ minWidth: '86px !important' }}
                >
                  {props.cancelText || 'Cancel'}
                </CoButtonStyled>
              )}
              {props.confirmText
                && (
                <CoButtonStyled
                  className='co-modal-confirm-confirm-button'
                  variant="contained"
                  color="primary"
                  onClick={confirmClick}
                >
                  {props.confirmText}
                </CoButtonStyled>
                )}
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </div>
  );
}
