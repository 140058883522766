import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import {
  clearHipaaFormsCompleted, getAddons,
  getHipaaFormAdditionalNotes,
  getHipaaFormDefinitions,
  getHipaaFormEmails, getMasterAddonDetails,
  putHipaaFormAdditionalNotes,
  putHipaaFormEmails, selectAddons,
  selectHipaaFormEmails,
  selectHipaaFormOrderItemId,
  selectHipaaFormsCompleted,
} from 'services/addons/addonsSlice';
import { getFiles } from 'services/fileUploaderSlice';
import { getOrderItems } from 'services/ordersSlice';
import { Urls } from 'navigation/Urls';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { selectWebsiteId } from 'services/websitesSlice';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import HipaaFormsForm from './HipaaFormsForm';

export default function HipaaForms() {
  const {
    orderId, offeringType, websiteId: offeringId, orderItemId,
  } = useParams();
  const uploaderType = 'hipaa-form';

  const dispatch = useDispatch();
  const navigate = useNavigationHandler();

  const hipaaFormEmails = useSelector(selectHipaaFormEmails);
  const hipaaFormCompleted = useSelector(selectHipaaFormsCompleted);
  const hipaaItemId = useSelector(selectHipaaFormOrderItemId);
  const websiteId = useSelector(selectWebsiteId);
  const addons = useSelector(selectAddons);
  const topNav = useTopnavMenu(Urls.HipaaForms);

  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  useEffect(() => {
    if (orderId) {
      dispatch(getHipaaFormDefinitions({ orderId }));
      dispatch(getHipaaFormEmails({ orderId }));
      dispatch(getHipaaFormAdditionalNotes({ orderId }));
    }
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (hipaaFormCompleted) {
      dispatch(clearHipaaFormsCompleted());
      if (isRedirectedFromOverviewPage) return navigate.to(Urls.Overview);
      navigate.to(topNav.nextStep());
    }
  }, [hipaaFormCompleted]);

  useEffect(() => {
    const productId = websiteId || offeringId;
    dispatch(getFiles({ websiteId: productId, uploaderType, offeringType }));
  }, [websiteId]);

  useEffect(() => {
    if (!hipaaItemId) {
      dispatch(getOrderItems(orderId));
    }
  }, []);

  useEffect(() => {
    if (!addons?.content?.id) dispatch(getAddons(orderId));
    if (orderItemId) dispatch(getMasterAddonDetails(orderItemId));
  }, [addons, orderItemId]);

  const onSubmit = (data) => {
    dispatch(putHipaaFormEmails({
      orderItemId: hipaaItemId, content: data.emailAccounts,
    }));

    dispatch(putHipaaFormAdditionalNotes({
      orderId, content: { additionalNotes: data.additionalNotes },
    }));
  };

  const onBack = () => {
    navigate.to(topNav.previousStep());
  };

  if (!hipaaItemId) {
    return (
      <ContentLoader
        status="loading"
        message=""
      />
    );
  }

  return (
    <ContentLoader status={[hipaaFormEmails.status]}
      message={[hipaaFormEmails.message]}
    >
      <HipaaFormsForm
        onBack={onBack}
        onSubmit={onSubmit}
        hipaaFormsEmails={hipaaFormEmails.content}
      />
    </ContentLoader>
  );
}
