/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import { validateEmail } from 'pages/generic-product/details/custom-fields/EmailTextField';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';

const { t } = i18n;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RLBrandedContentSchema: RJSFSchema | any = (data) => ({
  // eslint-disable-next-line max-len
  type: 'object',
  required: ['csmName', 'csmEmail', 'customOrClientProvidedContent', 'packagePurchased',
    'brandedContentAddOn', 'totalNumberOfArticlesInCampaign', 'numberOfArticlesPerMonth',
    'targetedStartDate', 'targeting', 'targetUnitedStates', 'cityStateRegionToBeTargeted', 'goalOfTheAd', 'levelOfCreativityOrArtisticDiscretion',
    'typeOfDigitalOnlineAd', 'clickThroughUrl', 'headlineOrBenefitStatement', 'contentOfferOrPromotion'],
  properties: {
    solutionStrategyTitle: {
      type: 'string',
      title: t('pages.smm.solutionStrategy.title'),
    },
    csmName: {
      type: 'string',
      title: t('pages.rlBrandedContent.csmName'),
      default: '',
    },
    csmEmail: {
      type: 'string',
      title: t('pages.rlBrandedContent.csmEmail'),
      default: '',
    },
    customOrClientProvidedContent: {
      type: 'string',
      title: t('pages.rlBrandedContent.customOrClientProvidedContent'),
      default: '',
      enum: [
        'Custom Content',
        'Client Provided Content',
      ],
    },
    packagePurchased: {
      type: 'string',
      title: t('pages.rlBrandedContent.packagePurchased'),
      default: '',
      enum: [
        '$2,500 - Article Basic w/ Geo-Targeting Only, $10 CM',
        '$3,250 - Article Basic w/ Geo & Audience-Targeting Only, $13 PM',
        '$5,000 - Article Plus w/ Geo-Targeting Only, $10 CPM',
        '$6,500 - Article Plus w/ Geo & Audience-Targeting Only, $13 CPM',
        '$5,000 - Infographic w/ Geo-Targeting Only, $10 PM',
        '$6,500 - Infographic w/ Geo & Audience-Targeting Only, $13 CPM',
        '$5,000 - Quiz w/ Geo-Targeting Only, $10 CPM',
        '$6,500 - Quiz Plus w/ Geo & Audience-Targeting Only, $13 CPM',
      ],
    },
    promotionalDetails: {
      type: 'string',
      title: t('pages.rlBrandedContent.promotionalDetails'),
    },
    brandedContentAddOn: {
      type: 'string',
      title: t('pages.rlBrandedContent.brandedContentAddOn'),
      default: '',
      enum: [
        'Social Post/Boost',
        'USA Today dark post',
      ],
    },
    socialPostBoost: {
      type: 'array',
      default: '',
      title: '',
      items: {
        type: 'string',
        enum: [
          'Social Post/Boost',
        ],
      },
      uniqueItems: true,
    },
    totalNumberOfArticlesInCampaign: {
      type: 'string',
      title: t('pages.rlBrandedContent.totalNumberOfArticlesInCampaign'),
      default: '',
    },
    numberOfArticlesPerMonth: {
      type: 'string',
      title: t('pages.rlBrandedContent.numberOfArticlesPerMonth'),
      default: '',
    },
    targetedStartDate: {
      type: 'string',
      title: t('pages.rlBrandedContent.targetedStartDate'),
      default: '',
      description: 'MM/DD/YY',
    },
    targeting: {
      type: 'string',
      title: t('pages.rlBrandedContent.targeting'),
      default: '',
      enum: [
        'Local.com (In-Market)',
        'USAToday.com (Out-Market, Geotargeting)',
      ],
    },
    targetUnitedStates: {
      type: 'string',
      title: t('pages.rlBrandedContent.targetUnitedStates'),
      default: '',
      items: {
        enum: [
          t('pages.generic-translation.yesOption'),
          t('pages.generic-translation.noOption'),
        ],
      },
    },
    cityStateRegionToBeTargeted: {
      type: 'string',
      title: t('pages.rlBrandedContent.cityStateRegionTargeted'),
      default: '',
    },
    goalOfTheAd: {
      type: 'array',
      title: t('pages.rlBrandedContent.goalOfTheAd'),
      items: {
        type: 'string',
        enum: [
          'Get a direct response',
          'Reinforce an existing product brand',
          'Introduce something new',
          'Announce sale/special event',
        ],
      },
      uniqueItems: true,
    },
    levelOfCreativityOrArtisticDiscretion: {
      type: 'string',
      title: t('pages.rlBrandedContent.levelOfCreativityOrArtisticDiscretion'),
      default: '',
      enum: [
        'Follow template or PDF supplied',
        'Some interpretation',
        'Use artistic expression',
      ],
    },
    wikiOrResourceForReference: {
      type: 'string',
      title: t('pages.rlBrandedContent.wikiOrResourceForReference'),
      default: '',
    },
    typeOfDigitalOnlineAd: {
      type: 'string',
      title: t('pages.rlBrandedContent.typeOfDigitalOnlineAd'),
      default: '',
      enum: [
        'Standard Animated',
        'Standard Static',
        'Rich Media',
        'High Impact New Build',
        'High Impact Template',
        'Quiz',
        'Paramount',
      ],
    },
    clickThroughUrl: {
      type: 'string',
      title: t('pages.rlBrandedContent.clickThroughUrl'),
      default: '',
    },
    headlineOrBenefitStatement: {
      type: 'string',
      title: t('pages.rlBrandedContent.headlineOrBenefitStatement'),
      default: '',
    },
    contentOfferOrPromotion: {
      type: 'string',
      title: t('pages.rlBrandedContent.contentOfferOrPromotion'),
      default: '',
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.rlBrandedContent.notesOrInstructions'),
      default: '',
    },
  },
  dependencies: {
    targeting: {
      oneOf: [
        {
          properties: {
            targeting: {
              enum: ['USAToday.com (Out-Market, Geotargeting)'],
            },
            geoDetails: {
              type: 'string',
              title: t('pages.rlBrandedContent.geoDetails'),
              default: '',
            },
          },
        },
      ],
    },
    packagePurchased: {
      oneOf: [
        {
          properties: {
            packagePurchased: {
              enum: ['$2,500 - Article Basic w/ Geo-Targeting Only, $10 CM',
                '$3,250 - Article Basic w/ Geo & Audience-Targeting Only, $13 PM'],
            },
            promotionalDetails: {
              type: 'string',
              title: t('pages.rlBrandedContent.promotionalDetails'),
              description: t('pages.rlBrandedContent.articleBasicDescription'),
            },
          },
        },
        {
          properties: {
            packagePurchased: {
              enum: ['$5,000 - Article Plus w/ Geo-Targeting Only, $10 CPM',
                '$6,500 - Article Plus w/ Geo & Audience-Targeting Only, $13 CPM'],
            },
            promotionalDetails: {
              type: 'string',
              title: t('pages.rlBrandedContent.promotionalDetails'),
              description: t('pages.rlBrandedContent.articlePlusDescription'),
            },
          },
        },
        {
          properties: {
            packagePurchased: {
              enum: ['$5,000 - Infographic w/ Geo-Targeting Only, $10 PM',
                '$6,500 - Infographic w/ Geo & Audience-Targeting Only, $13 CPM'],
            },
            promotionalDetails: {
              type: 'string',
              title: t('pages.rlBrandedContent.promotionalDetails'),
              description: t('pages.rlBrandedContent.infographicPackageDescription'),
            },
          },
        },
        {
          properties: {
            packagePurchased: {
              enum: ['$5,000 - Quiz w/ Geo-Targeting Only, $10 CPM',
                '$6,500 - Quiz Plus w/ Geo & Audience-Targeting Only, $13 CPM'],
            },
            promotionalDetails: {
              type: 'string',
              title: t('pages.rlBrandedContent.promotionalDetails'),
              description: t('pages.rlBrandedContent.quizPlusDescription'),
            },
          },
        },
      ],
    },
  },
});

const textAreaKeys = [
  'contentOfferOrPromotion',
  'notesOrInstructions',
];

const textFieldKeys = [
  'csmName',
  'totalNumberOfArticlesInCampaign',
  'numberOfArticlesPerMonth',
  'cityStateRegionToBeTargeted',
  'geoDetails',
  'wikiOrResourceForReference',
  'headlineOrBenefitStatement',
];

const textLabelKeys = [
  'promotionalDetails',
];

const urlTextKeys = [
  'clickThroughUrl',
];

const checkBoxKeys = [
  'goalOfTheAd',
  'socialPostBoost',
];

const radioKeys = [
  'targetUnitedStates',
];

const dateKeys = [
  'targetedStartDate',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const textAreaFields = textAreaKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const textLabelFields = textLabelKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textLabel' },
}), {});

const urlTextFields = urlTextKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'UrlTextWidget' },
}), {});

const radioFields = radioKeys.reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'radioButton' } }), {});

const checkBoxFields = checkBoxKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'checkBoxField' },
}), {});

const dateFields = dateKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'datePickerField' },
}), {});

export const RLBrandedContentUISchema = {
  'ui:order': ['solutionStrategyTitle', 'csmName', 'csmEmail', 'customOrClientProvidedContent', 'packagePurchased',
    'promotionalDetails', 'brandedContentAddOn', 'socialPostBoost', 'totalNumberOfArticlesInCampaign',
    'numberOfArticlesPerMonth', 'targetedStartDate', 'targeting', 'geoDetails', 'targetUnitedStates',
    'cityStateRegionToBeTargeted', 'goalOfTheAd', 'levelOfCreativityOrArtisticDiscretion', 'wikiOrResourceForReference',
    'typeOfDigitalOnlineAd', 'clickThroughUrl', 'headlineOrBenefitStatement',
    'contentOfferOrPromotion', 'notesOrInstructions'],

  csmEmail: {
    'ui:widget': 'EmailTextWidget',
  },
  customOrClientProvidedContent: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select Custom or Client Provided content"
      />
    ),
  },
  brandedContentAddOn: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select Branded Content Add-On"
      />
    ),
  },
  packagePurchased: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select the type of Package Purchased"
      />
    ),
  },
  targeting: {
    'ui:widget': (props) => <Dropdown {...props} errorMessage="Please select the Targeting type" />,
  },
  levelOfCreativityOrArtisticDiscretion: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select the level of Creativity / Artistic Discretion"
      />
    ),
  },
  typeOfDigitalOnlineAd: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select the type of Digital Online Ad"
      />
    ),
  },
  solutionStrategyTitle: {
    'ui:widget': 'headingTextLabel',
  },

  ...textAreaFields,
  ...textFields,
  ...textLabelFields,
  ...urlTextFields,
  ...radioFields,
  ...checkBoxFields,
  ...dateFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RLBrandedContentCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.csmName === '') {
    errors.csmName.addError(t('pages.rlBrandedContent.csmNameErrorMessage'));
    errors.addError(true);
  }
  if (formData?.csmEmail === '') {
    errors.csmEmail.addError(t('pages.rlBrandedContent.csmEmailErrorMessage'));
    errors.addError(true);
  }
  if (formData?.totalNumberOfArticlesInCampaign === '') {
    errors.totalNumberOfArticlesInCampaign.addError(t('pages.rlBrandedContent.totalNumberOfArticlesInCampaignErrorMessage'));
    errors.addError(true);
  }
  if (formData?.numberOfArticlesPerMonth === '') {
    errors.numberOfArticlesPerMonth.addError(t('pages.rlBrandedContent.numberOfArticlesPerMonthErrorMessage'));
    errors.addError(true);
  }
  if (formData?.cityStateRegionToBeTargeted === '') {
    errors.cityStateRegionToBeTargeted.addError(t('pages.rlBrandedContent.cityStateRegionTargetedErrorMessage'));
    errors.addError(true);
  }
  if (formData?.contentOfferOrPromotion === '') {
    errors.contentOfferOrPromotion.addError(t('pages.rlBrandedContent.contentOfferOrPromotionErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clickThroughUrl === '' || !validateUrl(formData?.clickThroughUrl)) {
    errors.clickThroughUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.goalOfTheAd.length === 0) {
    errors.goalOfTheAd.addError(t('pages.rlBrandedContent.goalOfTheAdErrorMessage'));
    errors.addError(true);
  }
  if (formData?.headlineOrBenefitStatement === '') {
    errors.headlineOrBenefitStatement.addError(t('pages.rlBrandedContent.headlineOrBenefitStatementErrorMessage'));
    errors.addError(true);
  }
  if (formData?.targetedStartDate === '') {
    errors.targetedStartDate.addError(t('pages.rlBrandedContent.targetedStartDateErrorMessage'));
    errors.addError(true);
  }
  if (formData?.csmEmail !== '' && !validateEmail(formData?.csmEmail)) {
    errors.csmEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.targetUnitedStates === '') {
    errors.targetUnitedStates.addError(t('pages.generic-translation.mandatoryRadioButtonErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
