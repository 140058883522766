import {Urls} from 'navigation/Urls';
import useProduct from 'hooks/useProduct';
import {StatusType} from 'models/StatusType';

import ContentLoader from 'components/contentLoader/ContentLoader';
import {GetTargetDisplayResponse, TargetedDisplayLegacyRequestContentType} from "models/TargetedDisplayLegacyType";
import ProxyProvisioningPageForm from "pages/target-display-legacy/proxy-provisioning/ProxyProvisioningPageForm";

export default function ProxyProvisioningTargetedDisplayLeagacy() {
  const {product, onSubmit, onBack} = useProduct<TargetedDisplayLegacyRequestContentType,
      StatusType<GetTargetDisplayResponse>>(Urls.TargetedDisplayLeagacyProxyProvisioning);

  return (
      <ContentLoader status={product.status} message={product.message}>
        <ProxyProvisioningPageForm
            product={product.content.data}
            onSubmit={onSubmit}
            onBack={onBack}
        />
      </ContentLoader>
  );
}
