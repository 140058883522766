import { useEffect, useState } from 'react';
import {
  Box, Typography, Button,
} from '@mui/material';
import { IQLeftNavLogo, IQLoadingSpinner, IQThemeV2 } from '@gannettdigital/shared-react-components';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import TextIconNeon from 'icons/IQLeftNavIconNeon.svg';
import { backgroundDefaultColor } from 'styles/common_styles';
import { selectCase } from 'services/punchlistSlice';
import { Urls } from 'navigation/Urls';
import { OrderFlow } from 'shared/constants';
import {
  addItemToOrder, getOrder, getOrderItems, selectOrderItems, selectOrdersContent, startOrder, updateOrderFlow,
} from 'services/ordersSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { mapOpportunityOffer } from 'pages/salesforce-entry/OpportunityData';
import { useAppDispatch } from 'services/redux/store-schedule';
import { getFilteredProducts } from 'services/themesSlice';
import { searchBusinessesMP, setOrderBusiness } from 'services/businessSlice';
import CoModalConfirm from 'components/modals/CoModalConfirm';

export default function EntryPage() {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const navigateTo = useNavigate();
  const navigate = useNavigationHandler();
  const { caseId } = useParams();
  const currentOrder = useSelector(selectOrdersContent);
  const { content: orderItems } = useSelector(selectOrderItems);
  const caseContent = useSelector(selectCase);
  const [themeId, setThemeId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const isHealthcare = (caseContent?.neonProduct?.neonProductName === 'PremiumListing'
    && caseContent?.offer?.rlOfferName.toLowerCase().includes('healthcare'));

  const getThemes = (product) => {
    appDispatch(getFilteredProducts({
      product,
      country: 'USA',
      businessFunctionalityId: '',
      marketCategoryId: '',
      exclude: '',
    })).then((res) => setThemeId(res.payload.content.map(theme => theme.id)[0]));
  };

  useEffect(() => {
    if (Object.keys(caseContent).length === 0) {
      navigateTo(`/${Urls.Punchlist}/?caseid=${caseId}`);
    } else {
      dispatch(updateOrderFlow(OrderFlow.PUNCHLIST));
      dispatch(getOrderItems(currentOrder.id));
      getThemes(mapOpportunityOffer[caseContent?.neonProduct?.neonProductName]);
      setModalOpen(caseContent?.incompleteOrders?.length > 0);
    }
  }, [caseContent]);

  const addOfferToOrder = async () => {
    const content = {
      themeId,
      sourceId: null,
      productType: mapOpportunityOffer[caseContent?.neonProduct?.neonProductName],
      slug: null,
      tier: null,
      healthcare: isHealthcare,
      salesforceId: caseId || null,
    };

    await dispatch(addItemToOrder({
      orderId: currentOrder.id,
      content,
    }));
  };

  const handleContinue = async (resumeSession = false) => {
    setIsLoading(true);
    const orderId = !resumeSession ? currentOrder.id : caseContent?.incompleteOrders?.[0];
    if (caseContent?.offer?.gmaid) {
      let orderHasBusiness = false;
      if (resumeSession) {
        await appDispatch(getOrder(orderId)).then((res) => {
          orderHasBusiness = !!res?.payload?.businessId;
        });
      }

      if (!orderHasBusiness) {
        await appDispatch(searchBusinessesMP({
          query: caseContent?.offer?.gmaid,
          country: 'USA',
        })).then((res) => {
          const business = res?.payload?.content?.[0];
          if (business) dispatch(setOrderBusiness(business));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        }).catch((err) => setHasError(true));
      }
    }
    if (hasError) return;
    if (orderItems.length === 0 && !resumeSession) await addOfferToOrder();
    if (!resumeSession) await dispatch(startOrder({ orderId, orderFlow: OrderFlow.PUNCHLIST }));
    setIsLoading(false);
    navigate.to(`${orderId}/${Urls.SalesRep}`);
  };

  const newSession = () => setModalOpen(false);

  const resumeSession = async () => {
    await handleContinue(true);
    setModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <Box sx={{
        height: '93vh',
        display: 'flex',
        flexDirection: 'column',
        padding: '65px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: backgroundDefaultColor,
        margin: '15px',
        borderRadius: '5px',
      }}
      >
        <IQLeftNavLogo IQLeftNavIcon={TextIconNeon} />
        <Typography variant="h2" mb={3}>Welcome to the Punch List Form</Typography>
        <Typography variant="body1" mb={4} sx={{ fontSize: '18px' }}>
          Please review the details below and begin.
        </Typography>
        <Typography fontWeight="bold" sx={{ fontSize: '18px' }} mr={2}>Account</Typography>
        <Typography variant="body1" mb={4}>{caseContent?.offer?.accountName?.replaceAll('"', '')}</Typography>
        <Typography fontWeight="bold" sx={{ fontSize: '18px' }} mr={2}>Opportunity</Typography>
        <Typography variant="body1" mb={4}>{caseContent?.offer?.rlOfferName}</Typography>
        <Typography fontWeight="bold" sx={{ fontSize: '18px' }} mr={2}>Product</Typography>
        <Typography variant="body1" mb={4}>{caseContent?.neonProduct?.neonProductName}</Typography>
        <Button
          variant="contained"
          sx={{ padding: '12px 96px' }}
          onClick={() => handleContinue()}
          disabled={((Object.keys(caseContent).length === 0 && !!themeId) || isLoading || hasError)}
        >
          {isLoading && (<Box marginRight={2}><IQLoadingSpinner size={18} /></Box>)}
          <Typography fontSize="18px" fontWeight="bold">Begin</Typography>
        </Button>
        {hasError && (
          <Typography variant="body1" mb={4} mt={4}
            color={IQThemeV2.palette.error.main}
          >
            There is an error with this opportunity. Could not get business information.
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          flexShrink: 0,
          height: '7vh',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          borderTop: '1px solid grey',
        }}
      >
        <Typography variant="body1" sx={{ marginLeft: '15px' }}>
          © 2024 Gannett Co., Inc. All Rights Reserved.
        </Typography>
      </Box>
      <CoModalConfirm open={modalOpen}
        title="Welcome Back"
        description={(
          <>
            <Typography mb={2}>
              Would you like to continue with the previous session or start a new session?
            </Typography>
            <Typography>
              Note: if a new session is started, you will no longer be able to access the previous session.
            </Typography>
          </>
          )}
        confirmText="Resume Session"
        cancelText="New Session"
        handleClose={newSession}
        handleConfirm={resumeSession}
        isPunchlist
      />
    </Box>
  );
}
