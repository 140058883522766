import i18n from 'i18n/i18n';
import * as yup from 'yup';

const { t } = i18n;

const schema = {
  contact: yup.object({
    clientFullName: yup.string().required(t('pages.business.client.edit.fullNameRequired')),
    businessRoleId: yup.string().required(),
  }),
  contactPhoneNumberList: yup.array(yup.object({
    phoneType: yup.string().required(),
    phoneNumber: yup.string().required(),
    extension: yup.string(),
  })).min(1),
  contactEmailList: yup.array(yup.string()
    .email(t('pages.business.client.edit.emailError'))
    .required(t('pages.business.client.edit.emailError')))
    .min(1),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
