import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';

export const smmStandardMenu: MenuType = ({
  label: 'Standard SMM',
  url: '',
  status: 'active',
  offeringType: 'SMMStandard',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
    {
      label: 'Details',
      url: Urls.SMMDetails,
      status: 'incomplete',
    },
    {
      label: 'Solution Strategy',
      url: Urls.SMMSolutionStrategy,
      status: 'incomplete',
    },
  ],
});
