import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import FileUploader from 'pages/generic-product/details/custom-fields/FileUploader';
import { ProductTypes } from 'services/fileUploaderSlice';
import { validateEmail } from 'pages/generic-product/details/custom-fields/EmailTextField';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';
import SchedulerWidget from '../custom-fields/SchedulerWidget';

const { t } = i18n;

export const SMBRetailGCIOnlyResponsiveSiteSchema: RJSFSchema = {
  title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.pageTitle'),
  type: 'object',
  properties: {
    listings: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.listings'),
    },
    clientContactFirstName: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.clientContactFirstName'),
      default: '',
    },
    clientContactLastName: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.clientContactLastName'),
      default: '',
    },
    clientsContactPhone: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.clientsContactPhone'),
      default: '',
    },
    clientsContactEmail: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.clientsContactEmail'),
      default: '',
      description: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.clientsContactEmailHelperText'),
    },
    gmbAccessGranted: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.GMBAccessGranted'),
      default: '',
      description: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.GMBAccessGrantedHelperText'),
      enum: [
        '',
        'No GMB',
        'Client Has Access',
        'Client Does NOT Have Access (Pending Review, Require Manual Verification, or Request Ownership)',
      ],
    },
    businessType: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessType'),
      default: '',
      enum: [
        '',
        'Brick and Mortar',
        'Service Area Business',
        'Service Area Business + Walk-In',
      ],
    },
    businessName: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessName'),
      default: '',
    },
    businessCardInstructions: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessCardInstructions'),
      enum: ['https://support.google.com/business/answer/3038177?hl=en', true],
    },
    listingsBusinessCategory: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.listingsBusinessCategory'),
      default: '',
    },
    businessCategoryGuide: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessCategoryGuide'),
      enum: ['https://pixelcutlabs.com/blog/google-my-business-categories/', true],
    },
    businessPhone: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessPhone'),
      default: '',
    },
    secondaryBusinessPhone: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.secondaryBusinessPhone'),
      default: '',
    },
    businessFaxPhone: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessFaxPhone'),
      default: '',
    },
    businessEmail: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessEmail'),
      default: '',
    },
    leadsEmail: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.leadsEmail'),
      default: '',
      description: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.leadsEmailHelperText'),
    },
    hoursOfOperation: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.hoursOfOperation'),
    },
    businessHours: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessHours'),
    },
    websiteAvailable: {
      type: 'boolean',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.websiteAvailable'),
    },
    businessFeaturedDescription: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessFeaturedDescription'),
      default: '',
      description: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessFeaturedDescriptionHelperText'),
    },
    businessShortDescription: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessShortDescription'),
      default: '',
      enum: [
        '',
        'Featured',
        'Long',
        'Short',
      ],
    },
    businessLongDescription: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessLongDescription'),
      default: '',
    },
    uploader_businessLogo: {
      type: 'string',
      default: '',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessLogo'),
    },
    aiChat: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.aiChat'),
    },
    widgetPlacement: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.widgetPlacement'),
      default: '',
      enum: [
        '',
        'Entire Site',
        'Specific Pages',
      ],
    },
    locationInformation: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.locationInformation'),
      default: '',
    },
    businessCategoryWidgetPlacement: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessCategory'),
      default: '',
    },
    businessSubCategory: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessSubCategory'),
      default: '',
    },
    serviceResponse: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.serviceResponse'),
      default: '',
      description: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.serviceResponseHelperText'),
    },
    listSubSpecialties: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.listSubSpecialties'),
      default: '',
      description: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.listSubSpecialtiesHelperText'),
    },
    clientOfferFreeEstimates: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.clientOfferFreeEstimates'),
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    pricingResponse: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.pricingResponse'),
      default: '',
      description: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.pricingResponseHelperText'),
    },
    appointmentResponse: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.appointmentResponse'),
      default: '',
      description: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.appointmentResponseHelperText'),
    },
    isThisHippaClient: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.isThisHippaClient'),
      default: '',
      enum: [
        '',
        'Yes - HIPAA Client',
        'No',
      ],
    },
    transcriptNotifications: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.transcriptNotifications'),
      default: '',
    },
    monthlyReports: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.monthlyReports'),
      default: '',
    },
    notesInstructions: {
      type: 'string',
      title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.notesInstructions'),
      default: '',
    },
  },
  dependencies: {
    gmbAccessGranted: {
      oneOf: [
        {
          properties: {
            gmbAccessGranted: {
              enum: [
                'Client Has Access',
                'Client Does NOT Have Access (Pending Review, Require Manual Verification, or Request Ownership)',
              ],
            },
            gmbPageLink: {
              type: 'string',
              title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.GMBPageLink'),
              default: '',
            },
          },
        },
      ],
    },
    businessType: {
      oneOf: [
        {
          properties: {
            businessType: {
              enum: ['Brick and Mortar', 'Service Area Business + Walk-In'],
            },
            businessAddress: {
              type: 'string',
              title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.businessAddress'),
              default: '',
            },
          },
        },
      ],
    },
    websiteAvailable: {
      oneOf: [
        {
          properties: {
            websiteAvailable: {
              enum: [true],
            },
            websiteUrl: {
              type: 'string',
              title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.websiteUrl'),
              default: '',
            },
          },
        },
      ],
    },
    widgetPlacement: {
      oneOf: [
        {
          properties: {
            widgetPlacement: {
              enum: ['Specific Pages'],
            },
            destinationUrlOne: {
              type: 'string',
              title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.destinationUrl'),
              default: '',
            },
            destinationUrlTwo: {
              type: 'string',
              title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.destinationUrl'),
              default: '',
            },
            destinationUrlThree: {
              type: 'string',
              title: t('pages.SMBRetailGCIOnlyResponsiveSite.clientInformation.destinationUrl'),
              default: '',
            },
          },
        },
      ],
    },
  },
};

const textFieldKeys = [
  'clientContactFirstName',
  'clientContactLastName',
  'businessName',
  'listingsBusinessCategory',
  'businessLongDescription',
  'businessSubCategory',
  'businessCategoryWidgetPlacement',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const dropdownFieldKeys = [
  'gmbAccessGranted',
  'businessType',
  'businessShortDescription',
  'widgetPlacement',
  'clientOfferFreeEstimates',
  'isThisHippaClient',
];
const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const phoneNumberTextFieldKeys = [
  'clientsContactPhone',
  'businessPhone',
  'secondaryBusinessPhone',
  'businessFaxPhone',
];
const phoneNumberTextFields = phoneNumberTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'phoneNumberTextField' },
}), {});

const textAreaFieldKeys = [
  'businessFeaturedDescription',
  'locationInformation',
  'serviceResponse',
  'listSubSpecialties',
  'pricingResponse',
  'appointmentResponse',
  'transcriptNotifications',
  'monthlyReports',
  'notesInstructions',
];
const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const urlFieldKeys = [
  'gmbPageLink',
  'websiteUrl',
  'destinationUrlOne',
  'destinationUrlTwo',
  'destinationUrlThree',
];
const urlFields = urlFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'UrlTextWidget' },
}), {});

export const SMBRetailGCIOnlyResponsiveSiteUISchema = (data) => ({
  'ui:order': ['listings', 'clientContactFirstName', 'clientContactLastName', 'clientsContactPhone',
    'clientsContactEmail', 'gmbAccessGranted', 'gmbPageLink', 'businessType', 'businessName',
    'businessCardInstructions', 'listingsBusinessCategory', 'businessCategoryGuide', 'businessAddress',
    'businessPhone', 'secondaryBusinessPhone', 'businessFaxPhone', 'businessEmail', 'leadsEmail',
    'hoursOfOperation', 'businessHours', 'schedule', 'websiteAvailable', 'websiteUrl',
    'businessFeaturedDescription', 'businessShortDescription', 'businessLongDescription',
    'uploader_businessLogo', 'aiChat', 'widgetPlacement', 'destinationUrlOne', 'destinationUrlTwo',
    'destinationUrlThree', 'locationInformation', 'businessCategoryWidgetPlacement', 'businessSubCategory',
    'serviceResponse', 'listSubSpecialties', 'clientOfferFreeEstimates', 'pricingResponse', 'appointmentResponse',
    'isThisHippaClient', 'transcriptNotifications', 'monthlyReports', 'notesInstructions',
  ],

  clientsContactPhone: {
    'ui:widget': 'phoneNumberTextField',
  },
  clientsContactEmail: {
    'ui:widget': 'EmailTextWidget',
  },
  businessCardInstructions: {
    'ui:widget': 'anchorTextLabel',
  },
  businessCategoryGuide: {
    'ui:widget': 'anchorTextLabel',
  },
  businessEmail: {
    'ui:widget': 'EmailTextWidget',
  },
  leadsEmail: {
    'ui:widget': 'EmailTextWidget',
  },
  businessHours: {
    'ui:widget': (props) => <SchedulerWidget {...props} data={data} />,
  },
  hoursOfOperation: {
    'ui:widget': 'headingTextLabel',
  },
  listings: {
    'ui:widget': 'headingTextLabel',
  },
  aiChat: {
    'ui:widget': 'headingTextLabel',
  },
  businessAddress: {
    'ui:widget': 'addressField',
  },
  uploader_businessLogo: {
    'ui:widget': (props) => (
      <FileUploader
        {...props}
        uploaderType={ProductTypes.SMB_RETAIL_GCI_ONLY_RESPONSIVE_SITE_UPLOADER}
        totalAllowedFiles={1}
        imageTypes={[]}
        videoTypes={[]}
        documentTypes={['.png', '.jpeg', '.jpg', '.gif']}
        supportingText=""
      />
    ),
    'ui:style': {
      marginTop: '30px',
      marginBottom: '15px',
    },
  },
  ...phoneNumberTextFields,
  ...textFields,
  ...dropdownFields,
  ...textAreaFields,
  ...urlFields,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SMBRetailGCIOnlyResponsiveSiteCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.clientsContactEmail !== '' && !validateEmail(formData?.clientsContactEmail)) {
    errors.clientsContactEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.businessEmail !== '' && !validateEmail(formData?.businessEmail)) {
    errors.businessEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.leadsEmail !== '' && !validateEmail(formData?.leadsEmail)) {
    errors.leadsEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  // eslint-disable-next-line max-len
  if (formData?.gmbAccessGranted === 'Client Has Access, Client Does NOT Have Access (Pending Review, Require Manual Verification, or Request Ownership)') {
    if (formData?.gmbPageLink !== '' && !validateUrl(formData?.gmbPageLink)) {
      errors.gmbPageLink.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.widgetPlacement === 'Specific Pages') {
    if (formData?.destinationUrlOne !== '' && !validateUrl(formData?.destinationUrlOne)) {
      errors.destinationUrlOne.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
    if (formData?.destinationUrlTwo !== '' && !validateUrl(formData?.destinationUrlTwo)) {
      errors.destinationUrlTwo.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
    if (formData?.destinationUrlThree !== '' && !validateUrl(formData?.destinationUrlThree)) {
      errors.destinationUrlThree.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.websiteAvailable === true) {
    if (formData?.websiteUrl !== '' && !validateUrl(formData?.websiteUrl)) {
      errors.websiteUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  return errors;
};
