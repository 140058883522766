import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";
import HeadingTextLabel from "pages/generic-product/details/custom-fields/HeadingTextLabel";

const {t} = i18n;

export const FacebookCreativeInstructionsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.title'),
  type: 'object',
  required: [],
  properties: {
    whatTypeOfVisualStrategyDoesYourClientWantToEmploy: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.whatTypeOfVisualStrategyDoesYourClientWantToEmployLabel'),
      description: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.whatTypeOfVisualStrategyDoesYourClientWantToEmployHelperText'),
      default: data?.whatTypeOfVisualStrategyDoesYourClientWantToEmploy || '',
      enum: [
        '',
        'Single Image',
        'Single Video',
        'Multiple Images',
        'Multiple Videos',
        'Image and Video Combination',
      ],
    },
    willCreativesNeedToLandToUniqueLandingPagesWithinTheCampaignDomain: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.willCreativesNeedToLandToUniqueLandingPagesWithinTheCampaignDomainLabel'),
      default: data?.willCreativesNeedToLandToUniqueLandingPagesWithinTheCampaignDomain || '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    adCopyProvidedTitle: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.adCopyProvidedHeading'),
    },
    adCopyProvidedDescription: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.adCopyProvidedHelperText'),
    },
    willTheClientBeProvidingTheirOwnTextCreative: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.willTheClientBeProvidingTheirOwnTextCreativeLabel'),
      default: data?.willTheClientBeProvidingTheirOwnTextCreative || '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    allowEmojis: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.allowEmojisLabel'),
      default: data?.allowEmojis || '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    whatProductServiceOfferShouldBePromotedInThisCampaign: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.whatProductServiceOfferShouldBePromotedInThisCampaignLabel'),
      default: data?.whatProductServiceOfferShouldBePromotedInThisCampaign || '',
    },
  },
  dependencies: {
    willCreativesNeedToLandToUniqueLandingPagesWithinTheCampaignDomain: {
      oneOf: [
        {
          properties: {
            willCreativesNeedToLandToUniqueLandingPagesWithinTheCampaignDomain: {
              enum: ['Yes'],
            },
            whatUniqueUrlsShouldTheCreativesLandTo: {
              type: 'string',
              description: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.whatUniqueUrlsShouldTheCreativesLandToHelperText'),
              title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.whatUniqueUrlsShouldTheCreativesLandToLabel'),
              default: data?.whatUniqueUrlsShouldTheCreativesLandTo || '',
            },
          },
        },
      ],
    },
    willTheClientBeProvidingTheirOwnTextCreative: {
      oneOf: [
        {
          properties: {
            willTheClientBeProvidingTheirOwnTextCreative: {
              enum: ['Yes'],
            },
            fbMockupHeading: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.fbMockupHeading'),
            },
            pleaseEnterTheAdLinkDescription: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdLinkDescriptionLabel'),
              description: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdLinkDescriptionHelperText'),
              default: data?.pleaseEnterTheAdLinkDescription || '',
            },
            pleaseEnterTheAdHeadline: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdHeadlineLabel'),
              description: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdHeadlineHelperText'),
              default: data?.pleaseEnterTheAdHeadline || '',
            },
            pleaseEnterTheAdText: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdTextLabel'),
              description: t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdTextHelperText'),
              default: data?.pleaseEnterTheAdText || '',
            },

          },
        },
      ],
    },
  },
});

const textAreaFieldKeys = [
  'whatUniqueUrlsShouldTheCreativesLandTo',
];

const textFieldKeys = [
  'whatProductServiceOfferShouldBePromotedInThisCampaign',
  'pleaseEnterTheAdLinkDescription',
  'pleaseEnterTheAdHeadline',
  'pleaseEnterTheAdText',
];

const headingTextLabelKeys = [
  'adCopyProvidedTitle',
  'adCopyProvidedDescription',
  'fbMockupHeading'
];

const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'TextareaWidget'}
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

const headingTextLabels = headingTextLabelKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => <HeadingTextLabel {...props} fontSize='20px'/>
  }
}), {});

export const FacebookCreativeInstructionsUISchema = {
  'ui:order': [
    'whatTypeOfVisualStrategyDoesYourClientWantToEmploy',
    'willCreativesNeedToLandToUniqueLandingPagesWithinTheCampaignDomain',
    'whatUniqueUrlsShouldTheCreativesLandTo',
    'adCopyProvidedTitle',
    'adCopyProvidedDescription',
    'willTheClientBeProvidingTheirOwnTextCreative',
    'allowEmojis',
    'fbMockupHeading',
    'pleaseEnterTheAdLinkDescription',
    'pleaseEnterTheAdHeadline',
    'pleaseEnterTheAdText',
    'whatProductServiceOfferShouldBePromotedInThisCampaign',
  ],
  ...textAreaFields,
  ...textFields,
  ...headingTextLabels,
  whatTypeOfVisualStrategyDoesYourClientWantToEmploy: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeForErrorMessage')}/>
  },
  willCreativesNeedToLandToUniqueLandingPagesWithinTheCampaignDomain: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.doYouWantToIncludeAnIntroSectionOnYourLeadsFormErrorMessage')}/>
  },
  willTheClientBeProvidingTheirOwnTextCreative: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.forTheTextFieldsOnTheThankYouScreenErrorMessage')}/>
  },
  allowEmojis: {
    'ui:widget': (props) => <Dropdown {...props}/>
  },
};

export const FacebookCreativeInstructionsCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.pleaseEnterTheAdLinkDescription &&
      formData?.pleaseEnterTheAdLinkDescription.trim() !== '' &&
      formData?.pleaseEnterTheAdLinkDescription.trim().length > 250) {
    errors.pleaseEnterTheAdLinkDescription.addError(t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdLinkDescriptionErrorText'));
    errors.addError(true);
  }
  if (formData?.pleaseEnterTheAdHeadline &&
      formData?.pleaseEnterTheAdHeadline.trim() !== '' &&
      formData?.pleaseEnterTheAdHeadline.trim().length > 75) {
    errors.pleaseEnterTheAdHeadline.addError(t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdHeadlineErrorText'));
    errors.addError(true);
  }
  if (formData?.pleaseEnterTheAdText &&
      formData?.pleaseEnterTheAdText.trim() !== '' &&
      formData?.pleaseEnterTheAdText.trim().length > 250) {
    errors.pleaseEnterTheAdText.addError(t('pages.socialAds.solutionStrategy.facebookCreativeInstructions.pleaseEnterTheAdTextErrorText'));
    errors.addError(true);
  }
  return errors;
};
