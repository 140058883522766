import { t } from "i18next";

export const yesNoDropdownItems = [
    {value: 'Yes', description: t('pages.targetedDisplayLegacy.yesNo.yes')},
    {value: 'No', description: t('pages.targetedDisplayLegacy.yesNo.no')},
  ];
  
  
  export const updateOrAddDataBasedOnSourceId = (prevData: any[], newData) => {
    // Check if sourceId already exists in the previous data
    const existingIndex = prevData.findIndex(item => item.sourceId === newData.sourceId);
  
    if (existingIndex !== -1) {
      // If sourceId exists, update the existing item
      const updatedData = [...prevData];
      updatedData[existingIndex] = newData;
      return updatedData;
    } else {
      // If sourceId does not exist, add the new data
      return [...prevData, newData];
    }
  }