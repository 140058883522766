import {
  Box, Chip, styled, Tooltip, Typography,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface TitleWithBadgeProps {
  title: string
  badge: string
  tooltip: string
}

const StyledChip = styled(Chip)`
  align-self: center;
  border-radius: 2px;
  background-color: #0074C2;
  height: 16px;
  color: white;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 10px;
  
  .MuiChip-icon {
    color: white !important;
    font-size: 12px;
  }
`;

const StyledTooltip = styled(({ className, ...props }: any) => (
  <Tooltip title="" {...props}
    placement="top"
    classes={{ popper: className }}
  />
))`
  & .MuiTooltip-tooltip {
    max-width: calc(208px - 17px);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    background: ${(props) => props.theme.palette.text.primary};
    border-radius: 4px;
  }

  & .MuiTooltip-tooltipArrow {
    background: ${(props) => props.theme.palette.text.primary};
  }

  & .MuiTooltip-arrow {
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const TitleWithBadge = ({ title, badge, tooltip }: TitleWithBadgeProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
    <Typography variant="h6">{ title }</Typography>
    <StyledTooltip title={tooltip} placement="top">
      <StyledChip
        label={badge}
        icon={<CheckCircleOutlineIcon color="inherit" sx={{ color: 'white' }} />}
        variant="filled"
      />
    </StyledTooltip>
  </Box>
);

export {
  TitleWithBadge,
};
