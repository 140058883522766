import DomainsType from 'models/DomainsType';
import AxiosAPI from '../common/axios';

interface DomainsEndpointsFunctionsType {
  getDomains: (website: string) => any
  postDomains: (websiteId: string, content: DomainsType) => any
  putDomains: (content: DomainsType) => any
}

interface DomainsEndpointsType {
  Website: DomainsEndpointsFunctionsType,
  GoldSite: DomainsEndpointsFunctionsType,
  EcommerceMicrosite: DomainsEndpointsFunctionsType,
}

async function putDomain(contentParam: DomainsType, websiteId: string, endpoint: string) {
  const content = contentParam;
  const { domainEmail } = content;
  delete content.websiteId;
  delete content.domainEmail;
  delete content.id;
  const domainEmails = domainEmail ? [domainEmail] : [''];
  const contentWithEmail = { ...content, domainEmails };
  const response = await AxiosAPI.put(endpoint, contentWithEmail);
  return response.data;
}

const DomainsEndpoints: DomainsEndpointsType = ({
  Website: {
    getDomains: async (websiteId: string) => {
      try {
        const response = await AxiosAPI.get(`/websites/${websiteId}/domains/emails`);
        return { ...response.data?.domain, domainEmail: response.data?.domainEmails[0]?.email || '' };
      } catch (e) {
        // @ts-ignore
        const { status } = e.response;
        if (status >= 400) return {};
        throw e;
      }
    },
    postDomains: async (websiteId: string, contentParam: DomainsType) =>
      putDomain(contentParam, websiteId, `/websites/${websiteId}/domains/emails`),
    putDomains: async (contentParam: DomainsType) =>
      putDomain(contentParam, contentParam.websiteId, `/websites/${contentParam.websiteId}/domains/emails`),
  },
  GoldSite: {
    getDomains: async (websiteId: string) => {
      try {
        const response = await AxiosAPI.get(`/gold-sites/${websiteId}/domains/emails`);
        return { ...response.data?.domain, domainEmail: response.data?.domainEmails[0]?.email || '' };
      } catch (e) {
        // @ts-ignore
        const { status } = e.response;
        if (status >= 400) return {};
        throw e;
      }
    },
    postDomains: async (websiteId: string, contentParam: DomainsType) =>
      putDomain(contentParam, websiteId, `/gold-sites/${websiteId}/domains/emails`),
    putDomains: async (contentParam: DomainsType) =>
      putDomain(contentParam, contentParam.websiteId, `/gold-sites/${contentParam.websiteId}/domains/emails`),
  },
  EcommerceMicrosite: {
    getDomains: async () => {
      const response = await AxiosAPI.get('/product/data/e-commerce/microsite?orderId=15862&orderItemId=28303');
      return response.data;
    },
    postDomains: async (websiteId: string, content: DomainsType) => {
      const response = await
      AxiosAPI.put('/product/data/e-commerce/microsite?orderId=15862&orderItemId=28303', content);
      return response.data;
    },
    putDomains: async (content: DomainsType) => {
      const response = await
      AxiosAPI.put('/product/data/e-commerce/microsite?orderId=15862&orderItemId=28303', content);
      return response.data;
    },
  },
});

export default DomainsEndpoints;
