import OrderEditLayout from 'layouts/OrderEditLayout';
import { Outlet } from 'react-router-dom';

export default function EditClientMenu() {
  return (
    <OrderEditLayout popup>
      <Outlet />
    </OrderEditLayout>
  );
}
