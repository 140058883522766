import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import {Urls} from 'navigation/Urls';
import React from 'react';
import AdvertisingPageForm from "pages/youtube/advertising/AdvertisingPageForm";

export default function XmoYoutube() {
  const {product, onSubmit, onBack} = useProduct(Urls.XmoYouTube);

  const onSubmitHandler = async (content) => {
    const pageFormData = {
      youtube: content
    };
    onSubmit(pageFormData);
  };

  return (
      <ContentLoader status={product.status} message={product.message}>
        <AdvertisingPageForm
            product={product?.content?.data?.youtube}
            onSubmit={onSubmitHandler}
            onBack={onBack}
        />
      </ContentLoader>
  );
}