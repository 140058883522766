import useProduct from 'hooks/useProduct';
import { Urls } from 'navigation/Urls';
import ContentLoader from 'components/contentLoader/ContentLoader';
import CCByDashDetailsForm from './CCByDashDetailsForm';

export default function CCByDashDetails() {
  // eslint-disable-next-line no-caller
  const { product, onSubmit, onBack } = useProduct(Urls.CCByDashDetails);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <CCByDashDetailsForm
        product={product.content.data}
        onSubmit={onSubmit}
        onBack={onBack}
      />
    </ContentLoader>
  );
}
