import { IQCheckbox, IQLoadingSpinner, ValidationProvider } from '@gannettdigital/shared-react-components';
import { useEffect, useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import { Typography } from '@mui/material';
import { FileUploader } from 'components/fileUploader/FileUploaders';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { locationsTypes } from 'layouts/StepperLayout';
import {
  updateLocationLogo, updateLocationPhoto, getBusinessLocation, updateLocationVideos,
  selectCurrentLocation, updateLocationVideoSameForAll,
} from 'services/businessLocationSlice';
import { getOrderBusinessByOrderId, selectOrderBusiness } from 'services/businessSlice';
import {
  ProductTypes, selectFilesUploaded, deleteFile, setFiles, getFiles,
} from 'services/fileUploaderSlice';
import {
  selectLocationPhotos,
  selectPhotos,
  selectProviderPhoto,
  selectSquareLogo,
  selectStandardLogo,
  setLocationPhotos,
  setProviderPhoto,
  setSquareLogo,
  setStandardLogo,
  uploadPhotos,
} from 'services/premiumListingsSlice';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { TitleWithBadge } from 'pages/premium-listings/TitleWithBadge';
import { OrderFlow } from 'shared/constants';
import { selectOrdersContent } from 'services/ordersSlice';
import { locationPhotosOptions, squareLogoOptions, standardLogoOptions } from './fileUploaderOptionsPL';
import PublishedVideos from './PublishedVideos';
import { providerPhotoOptions } from './fileUploaderOptionsHC';

export default function ListingsPhotos(props: any) {
  const { type, schema, defaultValues } = props;
  const { t } = useTranslation();
  const { orderId, locationId } = useParams();
  const dispatch = useDispatch();
  const location = useSelector(selectCurrentLocation);
  const uploadedFiles = useSelector(selectFilesUploaded);
  const photosData = useSelector(selectPhotos);
  const business = useSelector(selectOrderBusiness);
  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);
  const ordersContent = useSelector(selectOrdersContent);
  const { orderFlow } = ordersContent;
  const [isPunchlist, setIsPunchlist] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    orderFlow === OrderFlow.PUNCHLIST ? setIsPunchlist(true) : setIsPunchlist(false);
  }, []);

  const isPl = ([locationsTypes.PREMIUM_LISTINGS, locationsTypes.HEALTHCARE].includes(type) && !location?.isProvider);
  const isHc = (type === locationsTypes.HEALTHCARE && location?.isProvider);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
    },
  });

  const {
    handleSubmit, getValues, setValue, formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    },
  } = methods;

  useEffect(() => {
    if (orderId) dispatch(getOrderBusinessByOrderId(orderId));
  }, [orderId]);

  useEffect(() => {
    dispatch(getBusinessLocation(locationId));
  }, [locationId]);

  useEffect(() => {
    if (business && isPl) {
      setValue('applyStandardLogoToAll', business.content.sameLogoAttachments);
      setValue('applySquareLogoToAll', business.content.sameSquareLogoAttachments);
      setValue('applyLocationPhotosToAll', business.content.samePictureAttachments);
      setValue('applyVideosToAll', business.content.sameBusinessLocationVideos);
    }
  }, [business, isPl]);

  useEffect(() => {
    if (location) {
      setValue('publishedVideos', location.videos?.map(v => ({
        videoUrl: v.url,
        videoTitle: v.title,
        description: v.description,
      })));
      location.videos?.forEach((v, index) => {
        setValue(`publishedVideos.${index}`, {
          videoUrl: v.url,
          videoTitle: v.title,
          description: v.description,
        });
      });
    }
  }, [location]);

  useEffect(() => {
    if (Object.keys(photosData).length > 0 && (isPl)) {
      setValue('standardLogo', photosData.standardLogo);
      photosData.standardLogo?.forEach((logo, index) => {
        setValue(`standardLogo.${index}`, logo);
      });

      setValue('squareLogo', photosData.squareLogo);
      photosData.squareLogo?.forEach((logo, index) => {
        setValue(`squareLogo.${index}`, logo);
      });

      setValue('locationPhotos', photosData.locationPhotos);
      photosData.locationPhotos?.forEach((photo, index) => {
        setValue(`locationPhotos.${index}`, photo);
      });

      if (isPl) {
        setValue('applyStandardLogoToAll', photosData.applyStandardLogoToAll);
        setValue('applySquareLogoToAll', photosData.applySquareLogoToAll);
        setValue('applyLocationPhotosToAll', photosData.applyLocationPhotosToAll);
        setValue('applyVideosToAll', photosData.applyVideosToAll);
      }

      if (isHc) {
        setValue('providerPhoto', photosData.providerPhoto);
        photosData.providerPhoto?.forEach((photo, index) => {
          setValue(`providerPhoto.${index}`, photo);
        });
      }
    }
  }, [photosData, isPl]);

  useEffect(() => {
    const updateAssets = async () => {
      const standardLogo = uploadedFiles.content.filter(
        file => file.uploaderType === 'logo_attachments' && file.productId.toString() === locationId,
      );
      const squareLogo = uploadedFiles.content.filter(
        file => file.uploaderType === 'square_logo_attachments' && file.productId.toString() === locationId,
      );
      const providerPhoto = uploadedFiles.content.filter(
        file => file.uploaderType === 'provider_photo' && file.productId.toString() === locationId,
      );
      const locationPhotos = uploadedFiles.content.filter(
        file => file.uploaderType === 'picture_attachments' && file.productId.toString() === locationId,
      );
      dispatch(setStandardLogo(standardLogo));
      dispatch(setSquareLogo(squareLogo));
      if (isHc) dispatch(setProviderPhoto(providerPhoto));
      await dispatch(setLocationPhotos({ status: 'loading' }));
      dispatch(setLocationPhotos(locationPhotos));
    };

    updateAssets();
  }, [uploadedFiles]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileToRemove, setFileToRemove] = useState({ fileName: '' });
  const standardLogo = useSelector(selectStandardLogo);
  const squareLogo = useSelector(selectSquareLogo);
  const locationPhotos = useSelector(selectLocationPhotos);
  const providerPhoto = useSelector(selectProviderPhoto);
  const [filesWithCategories, setFilesWithCategories] = useState([]);

  const onUploadSuccess = (data) => {
    dispatch(uploadPhotos(data));
  };

  const onFileDelete = (data) => {
    setFileToRemove(data);
    setShowDeleteModal(true);
  };

  const handleCloseRemove = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmRemove = () => {
    dispatch(deleteFile({ websiteId: orderId, file: fileToRemove }));
  };

  const submitLogos = async (data) => {
    const standardLogoString = JSON.stringify(standardLogo.content);
    const squareLogoString = JSON.stringify(squareLogo.content);
    const resource = {
      logoAttachments: standardLogoString,
      sameLogoAttachments: isPl ? data.applyStandardLogoToAll : false,
      sameSquareLogoAttachments: isPl ? data.applySquareLogoToAll : false,
      squareLogoAttachments: squareLogoString,
    };
    await dispatch(updateLocationLogo({ locationId, resource }));
  };

  const findAdditionalDetails = (id) => {
    const data = filesWithCategories?.filter(file => file.id === id);
    if (data?.length > 0) return data[0].additionalDetails;
    const fetchedFiles = uploadedFiles?.content?.filter(file => file.id === id);
    if (fetchedFiles?.length > 0) {
      const file = fetchedFiles[0] as any;
      if (file.additionalDetails !== null) {
        const str = JSON.parse(file.additionalDetails);
        return str.photoCategory;
      }
    }
    return 'additional';
  };

  const submitPhotos = async (data) => {
    if (locationPhotos.content.length > 0) {
      const resource = {
        businessLocationPhotosAttributeRequests: locationPhotos.content.map(photo => ({
          additionalDetails: findAdditionalDetails(photo.id),
          uploadId: photo.id,
        })),
        samePhoto: isPl ? data.applyLocationPhotosToAll : business.content.samePictureAttachments,
      };
      await dispatch(updateLocationPhoto({ locationId, resource }));
    }
  };

  const submitVideos = async (data) => {
    const resource = data.publishedVideos.map(v => ({
      title: v.videoTitle,
      description: v.description,
      videoUrl: v.videoUrl,
    }));
    if (isPl) {
      const sameVideo = JSON.stringify(data.applyVideosToAll);
      await dispatch(updateLocationVideoSameForAll({ locationId, sameVideo }));
    }
    await dispatch(updateLocationVideos({ locationId, resource }));
  };

  const onFormSubmit = async () => {
    const data = getValues();
    await submitPhotos(data);
    await submitVideos(data);
    await submitLogos(data);

    dispatch(setFiles([]));
    props.onContinue();
  };

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid]);

  const [loadingPhotos, setLoadingPhotos] = useState(false);

  const getRadioValue = async (file, value) => {
    const fileShape = {
      ...file,
      additionalDetails: value,
    };
    await delete fileShape.category;
    const index = filesWithCategories.findIndex(el => el.id === fileShape.id);
    const filesCopy = filesWithCategories;
    if (index === -1) {
      await filesCopy.push(fileShape);
    } else filesCopy[index] = fileShape;
    setLoadingPhotos(true);
    await setFilesWithCategories(filesCopy);
    await submitPhotos(getValues());
    await dispatch(getFiles({
      websiteId: locationId,
      uploaderType: locationPhotosOptions.uploaderType,
      offeringType: WebsiteOfferingTypes.BUSINESS_LOCATIONS,
    }));
    setLoadingPhotos(false);
  };

  return (
    <>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)}>
            <GridContainer fullWidth>
              <GridItem marginBottom={0}>
                <Typography variant="overline" marginBottom={0}
                  sx={{ textTransform: 'initial', fontSize: '18px', color: 'text.secondary' }}
                >
                  {isPl ? 'Locations' : 'Provider Listings'}
                </Typography>
              </GridItem>
              <GridItem>
                <Typography variant="h2" marginBottom={2}>
                  {t('pages.premiumListings.location.photos.title')}
                </Typography>
              </GridItem>
              <GridItem sizes={[8]} marginTop={3}>
                <TitleWithBadge
                  title={t('pages.premiumListings.location.photos.logosTitle')}
                  badge={t('pages.premiumListings.badge')}
                  tooltip={t('pages.premiumListings.badgeTooltip')}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <FileUploader
                  showFileUploader
                  schema={schema}
                  productId={locationId}
                  fileUploaderOptions={standardLogoOptions}
                  productType={ProductTypes.PRODUCT_CATALOG}
                  offeringType={WebsiteOfferingTypes.BUSINESS_LOCATIONS}
                  onDelete={onFileDelete}
                  onSuccess={onUploadSuccess}
                  filesUploaded={standardLogo}
                />
              </GridItem>
              {(isPl && !isPunchlist) && (
                <GridItem>
                  <IQCheckbox
                    field="applyStandardLogoToAll"
                    label={t('pages.premiumListings.location.photos.applyStandardLogoToAll')}
                  />
                </GridItem>
              )}
              <GridItem sizes={[12]}>
                <FileUploader
                  showFileUploader
                  schema={schema}
                  productId={locationId}
                  fileUploaderOptions={squareLogoOptions}
                  productType={ProductTypes.PRODUCT_CATALOG}
                  offeringType={WebsiteOfferingTypes.BUSINESS_LOCATIONS}
                  onDelete={onFileDelete}
                  onSuccess={onUploadSuccess}
                  filesUploaded={squareLogo}
                />
              </GridItem>
              {(isPl && !isPunchlist) && (
                <GridItem>
                  <IQCheckbox
                    field="applySquareLogoToAll"
                    label={t('pages.premiumListings.location.photos.applySquareLogoToAll')}
                  />
                </GridItem>
              )}
              <GridItem sizes={[12]} marginTop={3}>
                <TitleWithBadge
                  title={t('pages.premiumListings.location.photos.photosTitle')}
                  badge={t('pages.premiumListings.badge')}
                  tooltip={t('pages.premiumListings.badgeTooltip')}
                />
              </GridItem>
              {isHc && (
                <GridItem sizes={[8]}>
                  <FileUploader
                    showFileUploader
                    schema={schema}
                    productId={locationId}
                    fileUploaderOptions={providerPhotoOptions}
                    productType={ProductTypes.PRODUCT_CATALOG}
                    offeringType={WebsiteOfferingTypes.BUSINESS_LOCATIONS}
                    onDelete={onFileDelete}
                    onSuccess={onUploadSuccess}
                    filesUploaded={providerPhoto}
                  />
                </GridItem>
              )}
              <GridItem sizes={[12]}>
                {!loadingPhotos ? (
                  <FileUploader
                    showFileUploader
                    schema={schema}
                    productId={locationId}
                    fileUploaderOptions={locationPhotosOptions}
                    productType={ProductTypes.PRODUCT_CATALOG}
                    offeringType={WebsiteOfferingTypes.BUSINESS_LOCATIONS}
                    onDelete={onFileDelete}
                    onSuccess={onUploadSuccess}
                    filesUploaded={locationPhotos}
                    getRadioValue={getRadioValue}
                  />
                ) : (
                  <IQLoadingSpinner />
                )}
              </GridItem>
              {(isPl && !isPunchlist) && (
                <GridItem>
                  <IQCheckbox
                    field="applyLocationPhotosToAll"
                    label={t('pages.premiumListings.location.photos.applyLocationPhotosToAll')}
                  />
                </GridItem>
              )}
              <PublishedVideos showApplyAll={isPl} />
            </GridContainer>
          </form>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting) && isRedirectedFromOverviewPage}
          />
        </FormProvider>
      </ValidationProvider>
      <CoModalConfirm
        open={showDeleteModal}
        title={t('pages.premiumListings.location.photos.removeTitle')}
        description={(
          <Trans
            i18nKey="pages.premiumListings.location.photos.removeMessage"
            values={{ name: fileToRemove.fileName }}
            components={{ strong: <strong /> }}
          />
        )}
        cancelText={t('pages.premiumListings.location.photos.removeCancel')}
        confirmText={t('pages.premiumListings.location.photos.removeConfirm')}
        handleClose={handleCloseRemove}
        handleConfirm={handleConfirmRemove}
      />
    </>
  );
}
