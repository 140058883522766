/* eslint-disable max-len */
import i18n from 'i18n/i18n';

import {RJSFSchema} from '@rjsf/utils';
import {validateUrl} from "pages/generic-product/details/custom-fields/UrlTextField";

const urlValidationParameter = ['https://facebook.com', 'https://www.facebook.com'];
const {t} = i18n;

const validateRequiredFormField = (formData, fieldName, errors) => {
  if (formData?.[fieldName] === '') {
    errors[fieldName].addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
};

export const XmoUpgradeSocialFacebookSmartOptimisationSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.title'),
  type: 'object',
  required: [
    'campaignName',
    'primaryLandingPage',
    'facebookPageUrl',
    'mediaBudgetPerCycle',
    'campaignObjectiveSelector',
  ],
  properties: {
    currentSemCid: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.currentSemCidLabel'),
      default: data?.currentSemCid || '',
    },
    campaignName: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.campaignNameLabel'),
      description: t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.campaignNameDes'),
      default: data?.campaignName || '',
    },
    primaryLandingPage: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.primaryLandingPageLabel'),
      default: data?.primaryLandingPage || '',
    },
    facebookPageUrl: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.facebookPageUrlLabel'),
      default: data?.facebookPageUrl || '',
      customUrlValidation: true,
      urlValidationParameter,
      urlValidationErrorText: t('pages.ausProduct.customFacebook.facebookPageUrlErrorMessage'),
      description: t('pages.ausProduct.customFacebook.facebookPageUrlDescription'),
    },
    mediaBudgetPerCycle: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.mediaBudgetPerCycleLabel'),
      description: t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.mediaBudgetPerCycleDes'),
      default: data?.mediaBudgetPerCycle || '',
    },
    campaignObjectiveSelector: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.campaignObjectiveSelectorLabel'),
      default: data?.campaignObjectiveSelector || '',
      enum: [
        '',
        t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.contactOpt'),
        t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.engagementOpt'),
        t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.reachOpt'),
      ],
    },
  },
  dependencies: {
    campaignObjectiveSelector: {
      oneOf: [
        {
          properties: {
            campaignObjectiveSelector: {
              enum: [
                t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.contactOpt'),
                t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.engagementOpt')
              ],
            },
            isFacebookPublisherEnabled: {
              type: 'array',
              title: t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.isFacebookPublisherEnabledLabel'),
              description: t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.isFacebookPublisherEnabledDescription'),
              items: {
                type: 'string',
                enum: [
                  t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.leadGenOpt'),
                  t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.websiteOpt'),
                  t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.retargetingOpt'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            campaignObjectiveSelector: {
              enum: [
                t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.reachOpt')
              ],
            },
            isFacebookPublisherEnabled: {
              type: 'array',
              title: t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.isFacebookPublisherEnabledLabel'),
              description: t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.isFacebookPublisherEnabledDescription'),
              items: {
                type: 'string',
                enum: [
                  t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.reachOptions'),
                  t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.websiteOpt'),
                  t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.retargetingOpt'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
      ],
    },
  }
});

const integerInputFieldKeys = [
  'currentSemCid',
  'mediaBudgetPerCycle',
];

const textInputFieldKeys = [
  'campaignName',
];

const UrlTextFieldKeys = [
  'facebookPageUrl',
  'primaryLandingPage',
];

const integerInputFields = integerInputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'integerInputField'},
}), {});

const textInputFields = textInputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

const UrlTextFields = UrlTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'UrlTextWidget'},
}), {});

export const XmoUpgradeSocialFacebookSmartOptimisationUISchema = {
  'ui:order': [
    'currentSemCid',
    'campaignName',
    'primaryLandingPage',
    'facebookPageUrl',
    'mediaBudgetPerCycle',
    'campaignObjectiveSelector',
    'isFacebookPublisherEnabled',
  ],
  isFacebookPublisherEnabled: {
    'ui:widget': 'checkBoxField'
  },
  campaignObjectiveSelector: {
    'ui:widget': 'dropdown'
  },
  ...integerInputFields,
  ...UrlTextFields,
  ...textInputFields
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const XmoUpgradeSocialFacebookSmartOptimisationCustomValidate = (formData: any, errors, uiSchema) => {
  validateRequiredFormField(formData, 'campaignName', errors,);
  validateRequiredFormField(formData, 'mediaBudgetPerCycle', errors,);
  validateRequiredFormField(formData, 'campaignObjectiveSelector', errors,);

  if (formData?.facebookPageUrl === '' || !urlValidationParameter.some(url => formData?.facebookPageUrl?.startsWith(url))) {
    errors.facebookPageUrl.addError(t('pages.ausProduct.customFacebook.facebookPageUrlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.primaryLandingPage === '' || !validateUrl(formData?.primaryLandingPage)) {
    errors.primaryLandingPage.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
