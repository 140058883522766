/* eslint-disable max-len */
export const campaignGoalsValues = [
  {
    title: 'Brand Awareness',
    description: 'The client\'s brand/business is not very well known, or there is significant room to grow awareness.',
    field: 'brandAwareness',
  },
  {
    title: 'Active Digital Presence',
    description: 'The client needs help managing their social profiles, including regular content posting.',
    field: 'activeDigitalPresence',
  },
  {
    title: 'Community Engagement & Interaction',
    description: 'The client needs help monitoring engagement on their social profiles such as comments, reviews, and direct messages.',
    field: 'communityEngagement',
  },
  {
    title: 'Customer Retention',
    description: 'The client needs our expertise in using social media to effectively convert and maintain relationships with their customers and/or target audience.',
    field: 'customerRetention',
  },
  {
    title: 'Other',
    description: null,
    field: 'other',
  },
];
