import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import { Urls } from 'navigation/Urls';
import XmoBrandAmplifierForm from './XmoBrandAmplifierForm';

export default function XmoBrandAmplifier() {
  const { product, onSubmit, onBack } = useProduct(Urls.XmoBaTactics);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <XmoBrandAmplifierForm
        product={product?.content?.data?.solutionStrategy}
        onSubmit={onSubmit}
        onBack={onBack}
      />
    </ContentLoader>
  );
}
