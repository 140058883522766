import { useTranslation } from 'react-i18next';

export default function DetailsConstants() {
  const { t } = useTranslation();
  return {
    reasonOptions: [
      {
        value: 'Does not have website',
        label: t('pages.website.custom.details.noWebsite'),
        field: 'reason',
      },
      {
        value: 'New functionality, features and content',
        label: t('pages.website.custom.details.newFunctionality'),
        field: 'reason',
      },
      {
        value: 'Rebuild and modernize current website',
        label: t('pages.website.custom.details.rebuild'),
        field: 'reason',
      },
      {
        value: 'other',
        label: t('pages.website.custom.details.otherReason'),
        field: 'reason',
      },
    ],
    budgetRanges: [
      {
        value: 'Less than $3000',
        description: t('pages.website.custom.details.lessThan3000'),
      },
      {
        value: '$3,000 - $5,000',
        description: '$3,000 - $5,000',
      },
      {
        value: '$5,000 - $10,000',
        description: '$5,000 - $10,000',
      },
      {
        value: '$10,000 - $15,000',
        description: '$10,000 - $15,000',
      },
      {
        value: '$20,000 - $30,000',
        description: '$20,000 - $30,000',
      },
      {
        value: '$30,000 - $40,000',
        description: '$30,000 - $40,000',
      },
      {
        value: '$50,000 - $70,000',
        description: '$50,000 - $70,000',
      },
      {
        value: '$70,000 - $100,000',
        description: '$70,000 - $100,000',
      },
    ],
  };
}
