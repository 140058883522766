import {
  Alert, Typography, Button, Box,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { t } from 'i18next';
import { useState } from 'react';

interface Props {
  showAlert: boolean
}

export default function PunchlistPiQBanner({ showAlert }: Props) {
  const theme = useTheme();
  const [alertVisible, setAlertVisible] = useState(showAlert);

  return alertVisible && (
    <Box mb={3} sx={{ width: '150%' }}>
      <Alert variant="outlined" severity="success" icon={<InfoOutlinedIcon />}
        sx={{
          backgroundColor: 'common.white',
          border: '1px solid common.black',
          borderColor: 'common.black',
          color: 'common.black',
          borderRadius: '5px',
          borderLeft: `12px solid ${theme.palette.secondary.main}`,
          '& .MuiAlert-icon': {
            color: theme.palette.secondary.main,
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiAlert-message': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
        }}
      >
        <Typography sx={{ fontSize: '14px' }} marginRight={1}>
          {t('pages.business.location.punchlistPiq.notification')}
        </Typography>
        <Button onClick={() => setAlertVisible(false)}
          disableElevation
          disableRipple
          sx={{ textDecoration: 'underline', backgroundColor: 'transparent', color: theme.palette.text.primary }}
        >
          {t('pages.business.location.punchlistPiq.dismissLabel')}
        </Button>
      </Alert>
    </Box>
  );
}
