import * as yup from 'yup';

const schema = {
  contentTonesSelection: yup.string().required().nullable(),
  contentTones: yup
    .array()
    .required()
    .min(1, 'Please select at least one item.')
    .max(2, 'Please review and select only two items to continue.')
    .typeError('Please select at least one item.'),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
