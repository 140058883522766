import { Urls } from 'navigation/Urls';

import useProduct from 'hooks/useProduct';

import { GetPiQWebsiteResponse, PiQWebsiteRequestContentType } from 'models/PiQWebsiteType';
import { StatusType } from 'models/StatusType';

import ContentLoader from 'components/contentLoader/ContentLoader';
import PiQBackgroundImageForm from './PiQBackgroundImageForm';

export default function PiQBackgroundImage() {
  const { onBack, product, onSubmit } = useProduct<PiQWebsiteRequestContentType,
  StatusType<GetPiQWebsiteResponse>>(Urls.PiQBackgroundImage);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <PiQBackgroundImageForm
        product={product.content.data}
        onSubmit={onSubmit}
        onBack={onBack}
      />
    </ContentLoader>
  );
}
