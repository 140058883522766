import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";
import HeadingTextLabel from "pages/generic-product/details/custom-fields/HeadingTextLabel";
import {validateUrl} from "pages/generic-product/details/custom-fields/UrlTextField";
import RadioButton from "pages/generic-product/details/custom-fields/RadioButton";
import {Grid, Typography} from "@mui/material";
import React from "react";

const {t} = i18n;

export const MultiMediaGeneralAdInformationSchema: RJSFSchema | any = (data) => ({
  title: t('pages.multiMediaCreatives.multiMediaGeneralAdInformation.multimedia'),
  type: 'object',
  required: ['digitalOutputStyle', 'geographicServiceAreaOfBusiness', 'websiteOfBusiness'],
  properties: {
    digitalOutputStyle: {
      type: 'string',
      title: t('pages.multiMediaCreatives.multiMediaGeneralAdInformation.digitalOutputStyle'),
      default: t('pages.multiMediaCreatives.multiMediaGeneralAdInformation.optionsSR'),
      items: {
        enum: [
          t('pages.multiMediaCreatives.multiMediaGeneralAdInformation.optionsSR'),
        ],
      },
    },
    multiMediaGeneralAdInformation: {
      type: 'string',
    },
    brandingElementsRequired: {
      type: 'string',
      title: t('pages.multiMediaCreatives.multiMediaGeneralAdInformation.areThereBrandingElementsRequired'),
      default: data?.brandingElementsRequired || '',
    },
    geographicServiceAreaOfBusiness: {
      type: 'string',
      title: t('pages.multiMediaCreatives.multiMediaGeneralAdInformation.geographicServiceAreaOfBusiness'),
      default: data?.geographicServiceAreaOfBusiness || '',
    },
    websiteOfBusiness: {
      type: 'string',
      title: t('pages.multiMediaCreatives.multiMediaGeneralAdInformation.websiteOfBusiness'),
      default: data?.websiteOfBusiness || '',
    },
  },
});

const textFieldKeys = [
  'brandingElementsRequired',
  'geographicServiceAreaOfBusiness',
];

const radioFieldKeys = [
  'digitalOutputStyle',
];

const urlFieldKeys = [
  'websiteOfBusiness',
];

const urlFields = urlFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'UrlTextWidget'}
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o, [key]: {
    'ui:widget': (props) => <RadioButton {...props} disabled={true}/>,
  },
}), {});

export const MultiMediaGeneralAdInformationUISchema = {
  'ui:order': [
    'digitalOutputStyle',
    'multiMediaGeneralAdInformation',
    'brandingElementsRequired',
    'geographicServiceAreaOfBusiness',
    'websiteOfBusiness',
  ],
  multiMediaGeneralAdInformation: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={5} mb={2}>
          <Typography variant="h3"
                      style={{display: 'inline-block'}}> {t('pages.multiMediaCreatives.multiMediaGeneralAdInformation.title')} </Typography>
        </Grid>
    ),
  },
  ...textFields,
  ...radioFields,
  ...urlFields
};

export const MultiMediaGeneralAdInformationCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.geographicServiceAreaOfBusiness === '') {
    errors.geographicServiceAreaOfBusiness.addError(t('pages.multiMediaCreatives.multiMediaGeneralAdInformation.geographicServiceAreaOfBusinessErrorMsg'));
    errors.addError(true);
  }
  if (formData?.websiteOfBusiness === '') {
    errors.websiteOfBusiness.addError(t('pages.multiMediaCreatives.multiMediaGeneralAdInformation.websiteOfBusinessErrorMsg'));
    errors.addError(true);
  }
  return errors;
};
