/* eslint-disable max-len */
import { Grid, Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import CoCapacityHeader from 'components/capacityHeader/CoCapacityHeader';
import CoLeftNav from 'components/leftNav/CoLeftNav';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getBusinessFunctionalities, getMarketCategories } from 'services/adminSlice';
import { requestNewOrder, updateOrderFlow } from 'services/ordersSlice';
import {
  ThemeFilterType, getMenuProducts, selectFilter, selectMenuThemeTypes,
  selectThemes, setFilter, setFilteredThemes,
} from 'services/themesSlice';
import { selectCurrentCountry } from 'services/topNavMenuSlice';
import { OrderFlow } from 'shared/constants';
import { setOpportunity } from 'services/cefSlice';
import { setOrderBusiness } from 'services/businessSlice';
import { Business } from 'models/BusinessType';
import { useQuery } from './details/ThemeDetails';
import ThemeTile from './ThemeTile';
import { productNameMap } from './details/BundleConstants';

export const underlinedOnHover = {
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};

export const dynamicTile = (product, goToTheme, hasAddToCart = false, addToCart = () => {}) => (
  <ThemeTile themeItem={{
    id: '0',
    screenshotImage: '',
    title: productNameMap[product],
  }}
    goToTheme={() => goToTheme(product)}
    hasAddToCart={hasAddToCart}
    addToCart={addToCart}
  />
);

export default function Themes() {
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const themes = useSelector(selectThemes);
  const selectedCountry = useSelector(selectCurrentCountry);
  const productFilter = useSelector(selectFilter);
  const menuThemes = useSelector(selectMenuThemeTypes);
  const [dynamicTiles, setDynamicTiles] = useState(null);
  const partnerAccountId = useMemo(() => query.get('partnerAccount'), [query]);

  useEffect(() => {
    dispatch(requestNewOrder({}));
    dispatch(getBusinessFunctionalities({}));
    dispatch(getMarketCategories({}));
    dispatch(updateOrderFlow(OrderFlow.INTERNAL));
    dispatch(setOpportunity({}));
    dispatch(setOrderBusiness({} as Business));
  }, []);

  const filterUrlQuery = async (product, marketCategory, serviceType) => {
    if (product) {
      if (product.includes(',')) {
        const products = product.split(',');
        await dispatch(setFilter({
          type: ThemeFilterType.PRODUCT_TYPE,
          value: products.map(e => e.replaceAll(' ', '+')),
        }));
        const allChildren = [];
        products.forEach(type => {
          const filtered = menuThemes.filter(theme => theme.product === type);
          if (filtered.length > 0) filtered.forEach(item => allChildren.push(item));
        });
        dispatch(setFilteredThemes(allChildren));
      } else {
        await dispatch(setFilter({ type: ThemeFilterType.PRODUCT_TYPE, value: product.replaceAll(' ', '+') }));
        const filtered = menuThemes.filter(theme => theme.product === product);
        await dispatch(setFilteredThemes(filtered));
      }
    }

    if (marketCategory) {
      await dispatch(setFilter({ type: ThemeFilterType.MARKET_CATEGORY, value: +marketCategory }));
      const filtered = menuThemes.filter(theme => theme.marketCategory.id === +marketCategory);
      await dispatch(setFilteredThemes(filtered));
    }

    if (serviceType) {
      await dispatch(setFilter({ type: ThemeFilterType.SERVICE_TYPE, value: +serviceType }));
      const filtered = menuThemes.filter(theme => theme.businessFunctionality.id === +serviceType);
      await dispatch(setFilteredThemes(filtered));
    }
  };

  const loadFeaturedThemes = async () => {
    await dispatch(setFilter({ type: ThemeFilterType.PRODUCT_TYPE, value: '' }));
    const filtered = menuThemes.filter(theme => (theme.featured && theme.active));
    await dispatch(setFilteredThemes(filtered));
  };

  useEffect(() => {
    dispatch(getMenuProducts(selectedCountry.name));
  }, [selectedCountry]);

  useEffect(() => {
    const product = query.get('product');
    const marketCategory = query.get('marketCategory');
    const serviceType = query.get('serviceType');
    if (menuThemes.length > 0 && !productFilter.value && (!product && !marketCategory && !serviceType)) {
      loadFeaturedThemes();
    } else {
      filterUrlQuery(product, marketCategory, serviceType);
    }
  }, [menuThemes]);

  const goToTheme = (themeId) => navigate(partnerAccountId
    ? `/ourThemes/detail?id=${themeId}&partnerAccount=${partnerAccountId}`
    : `/ourThemes/detail?id=${themeId}`);
  const goToThemeDynamic = (product) => navigate(partnerAccountId
    ? `/ourThemes/detail?product=${product}&partnerAccount=${partnerAccountId}`
    : `/ourThemes/detail?product=${product}`);
  const productHasTheme = (prod) => themes?.filter(theme => theme.product === prod.replaceAll('+', ' '))?.length > 0;

  useEffect(() => {
    const producstWithTile = [];
    if (Array.isArray(productFilter.value)) {
      productFilter.value.forEach(prod => {
        if (!productHasTheme(prod)) producstWithTile.push(prod);
      });
    } else if (!productHasTheme(productFilter.value.toString())) producstWithTile.push(productFilter.value);
    setDynamicTiles(producstWithTile);
  }, [productFilter.value]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CoLeftNav isSalesforce={false} />
      <Box sx={{ flexGrow: 1, marginRight: 'auto', marginLeft: 'auto' }}>
        <Toolbar />
        <CoCapacityHeader />
        <Grid container spacing={2} sx={{
          marginTop: '100px',
          maxWidth: '1170px',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
        >
          {themes?.map(themeItem => (
            <ThemeTile themeItem={themeItem} goToTheme={goToTheme} />
          ))}
          {dynamicTiles?.map(tile => dynamicTile(tile, goToThemeDynamic))}
        </Grid>
      </Box>
    </Box>
  );
}
