import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import {Urls} from 'navigation/Urls';
import React from 'react';
import XmoReachForm from './XmoReachForm';

export default function XmoReach() {
  const {product, onSubmit, onBack} = useProduct(Urls.XmoReach);

  return (
      <ContentLoader status={product.status} message={product.message}>
        <XmoReachForm
            product={product?.content?.data?.reach}
            onSubmit={onSubmit}
            onBack={onBack}
        />
      </ContentLoader>
  );
}
