export const contentTones = [
  {
    name: 'persuasive',
    label: 'Persuasive',
    desc:
      'Tries to pitch new products and services or get people to take some sort of action '
      + '(charitable organizations often use this to rally people behind their cause).',
  },
  {
    name: 'informative',
    label: 'Informative',
    desc:
      'Either about their company/products or their industry as a whole '
      + '(lawyers, doctors and dentists usually take this approach).',
  },
  {
    name: 'friendly',
    label: 'Friendly & Laid Back',
    desc:
      'Tries to sound engaging and personable to attract new customers '
      + '(might use more informal/casual language or local colloquialisms/slang throughout the site).',
  },
  {
    name: 'credible',
    label: 'Credible & Honest',
    desc:
      'Tries to establish or develop trust with their customer base '
      + '(might talk about ways to find a reputable business in their industry or problems they see with competitors '
      + 'or provide examples of how they provide honest services for their customers).',
  },
  {
    name: 'technical',
    label: 'B2B & Technical',
    desc:
      'Speaking to other businesses who may be clients (usually uses more tech '
      + 'speak and jargon commonly found in their industry that outsiders may not understand).',
  },
  {
    name: 'corporate',
    label: 'Corporate',
    desc:
      'Tries to sound professional and knowledgeable like they’re experts or '
      + 'industry leaders in something.',
  },
  {
    name: 'formal',
    label: 'Formal & Professional',
    desc:
      'Similar to corporate but maybe on a smaller scale '
      + '(watch contractions).',
  },
  {
    name: 'sarcastic',
    label: 'Witty & Sarcastic',
    desc:
      'Not used very often, but can be powerful with branding or '
      + 'trying to sound cool or edgy over your competition, especially if you’re in a niche market.',
  },
  {
    name: 'interactive',
    label: 'Interactive',
    desc:
      'Tries to interact with their customers/community as much as possible '
      + '(might have a very active blog or social media account).',
  },
  {
    name: 'nurturing',
    label: 'Nurturing',
    desc:
      'Tries to be compassionate/sensitive to their customers’ needs and plays '
      + 'to their emotions that they may be experiencing (divorce lawyers, funeral homes, debt lawyers, etc.).',
  },
];
