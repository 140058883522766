import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import {
  clearOnlineBookingCompleted, getAddons, getMasterAddonDetails,
  getOnlineBooking,
  putOnlineBooking, selectAddons,
  selectOnlineBooking,
  selectOnlineBookingCompleted,
} from 'services/addons/addonsSlice';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { Urls } from 'navigation/Urls';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import OnlineBookingForm from './OnlineBookingForm';

export default function OnlineBooking() {
  const { orderId, orderItemId } = useParams();
  const onlineBooking = useSelector(selectOnlineBooking);
  const onlineBookingCompleted = useSelector(selectOnlineBookingCompleted);
  const addons = useSelector(selectAddons);
  const topNav = useTopnavMenu(Urls.OnlineBooking);

  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  const navigate = useNavigationHandler();

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(putOnlineBooking({ orderId, content: data }));
  };

  const onBack = () => {
    navigate.to(topNav.previousStep());
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (onlineBookingCompleted) {
      dispatch(clearOnlineBookingCompleted());
      if (isRedirectedFromOverviewPage) return navigate.to(Urls.Overview);
      navigate.to(topNav.nextStep());
    }
  }, [onlineBookingCompleted]);

  useEffect(() => {
    dispatch(getOnlineBooking({ orderId }));
  }, [orderId]);

  useEffect(() => {
    if (!addons?.content?.id) dispatch(getAddons(orderId));
    if (orderItemId) dispatch(getMasterAddonDetails(orderItemId));
  }, [addons, orderItemId]);

  return (
    <Box sx={{ maxWidth: '100%' }}>
      <ContentLoader status={onlineBooking.status} message={onlineBooking.message}>
        <OnlineBookingForm onBack={onBack} onSubmit={onSubmit} onlineBooking={onlineBooking.content} />
      </ContentLoader>
    </Box>
  );
}
