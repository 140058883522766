import * as yup from 'yup';

const schema = {
  provideEstimatesConsultations: yup.string().required(),
  estimatesConsultationsInformation: yup.string().optional(),
  chatSuggestions: yup.string().optional(),
  minimumServiceFee: yup.string().optional(),
  additionalDetails: yup.object({
    provideEstimateInPerson: yup.boolean().optional(),
    provideEstimateByPhone: yup.boolean().optional(),
    other: yup.boolean().optional(),
    otherDescription: yup.string().optional(),
    provideFreeConsultation: yup.string().optional(),
    callConnectTimezone: yup.string().required(),
    callConnectAvailable: yup.string().required(),
    customCallConnectHours: yup.string().required(),
  }).required(),
  followUpPhone: yup.boolean().optional(),
  followUpEmail: yup.boolean().optional(),
  followUpSms: yup.boolean().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
