import { IQFormInput, IQFormTextArea, ValidationProvider } from '@gannettdigital/shared-react-components';
import {
  Button, FormControlLabel, Grid, MenuItem, Select, Typography, useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ThemeFeature } from 'services/adminSlice';
import { featureIconList } from './FeatureIconList';

interface Props {
  onSubmit: (data: any) => void
  feature: ThemeFeature
}

export default function FeatureForm({ onSubmit, feature }: Props) {
  const theme = useTheme();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      title: '', description: '', iconFileName: null,
    },
  });
  const {
    control, handleSubmit, setValue,
  } = methods;

  useEffect(() => {
    if (feature.id !== 0) {
      setValue('title', feature.title);
      setValue('description', feature.description);
      setValue('iconFileName', feature.iconFileName);
    }
  }, [feature]);

  const schema = {
    yupValidations: {},
    customValidations: {},
  };

  return (
    <Grid container>
      <FormProvider {...methods}>
        <ValidationProvider schema={schema}>
          <Grid item xs={8} marginTop={3}>
            <IQFormInput
              labelText="Title"
              id="title"
              name="title"
              theme={theme}
              schema={schema}
              fullWidth
            />
          </Grid>
          <Grid item xs={8} marginTop={3}>
            <IQFormTextArea
              id="description"
              name="description"
              labelText="Description"
              fullWidth
            />
          </Grid>
          <Grid item xs={8} marginTop={3}>
            <FormControlLabel
              sx={{ marginLeft: 0, alignItems: 'flex-start' }}
              label={<Typography marginBottom={2}>Icon Filename</Typography>}
              labelPlacement="top"
              control={(
                <Controller
                  control={control}
                  name="iconFileName"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      onChange={onChange}
                      value={value}
                    >
                      {featureIconList.map(icon => (
                        <MenuItem key={icon.id} value={icon.filename}>
                          <img
                            src={`/assets/features/${icon.filename}`}
                            alt={icon.filename}
                            style={{ width: '25px', height: '25px' }}
                          />
                          <Typography fontWeight="bold" marginLeft={2}>{icon.filename}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              )}
            />
          </Grid>
        </ValidationProvider>
      </FormProvider>
      <Grid item xs={12} marginTop={3}>
        <Button variant="contained"
          sx={{ marginRight: 3 }}
          onClick={handleSubmit(onSubmit)}
        >
          <Typography>Save</Typography>
        </Button>
        <Link to="/admin/themeFeatures">
          <Button variant="contained">
            <Typography>Back to Theme Features</Typography>
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}
