import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';
import { TopNavStatusEnum } from '../TopNavStatusEnum';

export const websiteMenu:MenuType = ({
  label: 'Website - Silver',
  url: '',
  status: 'incomplete',
  offeringType: 'Website',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
    {
      label: 'Domain',
      url: Urls.Domains,
      status: 'incomplete',
    },
    {
      label: 'Design',
      url: '',
      status: 'incomplete',
      subSteps: [
        {
          id: 0,
          url: Urls.Theme,
          name: 'Theme',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 1,
          url: Urls.StockAssets,
          name: 'Existing Assets',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 2,
          url: Urls.Examples,
          name: 'Examples',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 3,
          url: Urls.AdditionalFeaturesDesign,
          name: 'Additional Features',
          status: TopNavStatusEnum.NotStarted,
        },
      ],
    },
    {
      label: 'Content',
      url: '',
      status: 'incomplete',
      subSteps: [
        {
          id: 0,
          url: Urls.ContentWriting,
          name: 'Content Writing',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 1,
          url: Urls.ExistingContent,
          name: 'Existing Content',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 2,
          url: Urls.WebsiteAgeGate,
          name: 'Age Gate',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 3,
          url: Urls.AdditionalFeaturesContent,
          name: 'Additional Features',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 4,
          url: Urls.SitePages,
          name: 'Pages',
          status: TopNavStatusEnum.NotStarted,
        },
      ],
    },
  ],
});
