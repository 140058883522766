import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Box, Grid } from '@mui/material';
import {
  IQButtonRadioContainer,
  IQFormTextArea,
  IQInputEmail,
  IQLabelTooltip,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';

import { t } from 'i18next';

import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';

import LandingPageType from 'models/LandingPageType';
import schema from './Goals.schema';

import { options } from './radioOptions';

type StyledRadioContainerProps = {
  hasError: boolean;
  theme: typeof IQTheme;
};

const StyledRadioContainer = styled(
  Grid,
  { shouldForwardProp: prop => prop !== 'hasError' },
)<StyledRadioContainerProps>`
  margin-bottom: 24px;
  
  label { 
    margin-bottom: 8px; 
    color: ${props => props.hasError && props.theme.palette.error.main};
  };
  
  button:hover { 
    background: none; 
  };
  
  button:focus { 
    background: none; 
  };
  
  button > * { 
    background: none; 
  };
  
  button.Mui-selected { 
    background: none;
  };
  
  button.Mui-selected:hover { 
    background: none; 
  };
  
  button.Mui-selected:focus { 
    background: none; 
  };
  
  .MuiTouchRipple-root > * { 
    display: none; 
  };
  
  [role=group] { 
    gap: 4px; 
  };
  
  .MuiFormControl-root > label {
    display: none;
  };
`;

const StyledTextboxContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  
  & > div:first-of-type {
    margin-bottom: 24px;
  }
`;

interface Props {
  product: LandingPageType;
  onSubmit: (data: GoalsFormProps) => void;
  onBack: () => void;
}

export type GoalsFormProps = {
  conversionGoal: string;
  additionalGoalsNotes: string;
  selectedConversionGoal: 'formSubmissions' | 'phoneCalls' | 'clickThruToWebsite' | 'otherGoals' | '';
  otherGoalsNotes?: string;
  secondaryConversionGoal: string;
  otherSecondaryGoalNotes?: string;
  preferredEmailPrimary?: string;
  preferredEmailSecondary?: string;
};

const evaluateSelectedConversionGoal = (product: LandingPageType) => {
  if (product?.formSubmissions) return 'formSubmissions';
  if (product?.phoneCalls) return 'phoneCalls';
  if (product?.clickThruToWebsite) return 'clickThruToWebsite';
  if (product?.otherGoals) return 'otherGoals';
  return '';
};

const generateSubmitData = (data: GoalsFormProps) => {
  const initialSubmitData = {
    formSubmissions: false,
    phoneCalls: false,
    clickThruToWebsite: false,
    otherGoals: false,
  };

  initialSubmitData[data.selectedConversionGoal] = true;

  return { ...data, ...initialSubmitData, selectedConversionGoal: null };
};

export default function GoalsForm(props: Props) {
  const { product } = props;

  const methods = useForm<GoalsFormProps>({
    mode: 'all',
    defaultValues: {
      conversionGoal: product?.conversionGoal || '',
      otherGoalsNotes: product?.otherGoalsNotes || '',
      otherSecondaryGoalNotes: product?.otherSecondaryGoalNotes || '',
      selectedConversionGoal: evaluateSelectedConversionGoal(props.product),
      additionalGoalsNotes: product?.additionalGoalsNotes || '',
      secondaryConversionGoal: product?.secondaryConversionGoal || '',
      preferredEmailPrimary: product?.preferredEmailPrimary || '',
      preferredEmailSecondary: product?.preferredEmailSecondary || '',
    },
  });

  const {
    handleSubmit,
    watch,
    trigger,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful, errors,
    },
  } = methods;

  const primaryGoal = watch('selectedConversionGoal');
  const secondaryGoal = watch('secondaryConversionGoal');

  const other = primaryGoal === 'otherGoals';
  const showSecondaryConversionGoal = primaryGoal !== '';
  const otherSecondary = secondaryGoal === 'otherGoals';
  const showPreferredEmailPrimary = primaryGoal === 'formSubmissions';
  const showPreferredEmailSecondary = secondaryGoal === 'formSubmissions';

  const otherConversionGoalError = !!errors.otherGoalsNotes;
  const otherSecondaryGoalError = !!errors.otherSecondaryGoalNotes;

  const handleOtherGoalChange = (e) => {
    if (e.target.value.length === 0) {
      trigger('otherGoalsNotes');
    }
  }

  const handleSecondaryOtherGoalChange = (e) => {
    if (e.target.value.length === 0) {
      trigger('otherSecondaryGoalNotes');
    }
  }

  const handleConversionGoalChange = (e) => {
    if (e.target.value.length === 0) {
      trigger('conversionGoal');
    }
  }

  const onSubmit = (data: GoalsFormProps) => {
    const dataToSubmit = generateSubmitData(data);
    if (!other) {
      dataToSubmit.otherGoalsNotes = '';
    }
    if (!otherSecondary) {
      dataToSubmit.otherSecondaryGoalNotes = '';
    }
    if (!showPreferredEmailPrimary) {
      dataToSubmit.preferredEmailPrimary = '';
    }
    if (!showPreferredEmailSecondary) {
      dataToSubmit.preferredEmailSecondary = '';
    }
    props.onSubmit(dataToSubmit);
  };

  const optionsForSecondaryGoal = options.filter(option => option.value !== primaryGoal);

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={props.onBack}
      header={t('pages.landing.goals.header')}
      onContinue={handleSubmit(onSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <StyledRadioContainer
              item
              hasError={otherConversionGoalError}
              theme={IQTheme}
            >
              <IQButtonRadioContainer
                label={t('pages.landing.goals.radioContainerLabel')}
                name="selectedConversionGoal"
                optionsList={options}
                singleColumn
                required
              />
              {showPreferredEmailPrimary
                && (
                  <Grid>
                    <Grid item xs={6} mt="24px"
                      mb="8px"
                    >
                      <IQLabelTooltip
                        labelText={t('pages.landing.goals.preferredEmailLabel')}
                        theme={IQTheme}
                        hasError={false}
                        required={false}
                        tooltipText=""
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <IQInputEmail
                        labelText={t('pages.landing.goals.preferredEmailLabel')}
                        fontLabelWeight="600"
                        theme={IQTheme}
                        schema={schema}
                        label={t('pages.landing.goals.preferredEmailLabel')}
                        field="preferredEmailPrimary"
                        fullWidth
                        adornmentIcon={<MailOutlineIcon />}
                      />
                    </Grid>
                  </Grid>
                )}
              {other && (
              <Grid item xs={6} pl="42px">
                <IQFormTextArea
                  id="other-conversion-goal"
                  name="otherGoalsNotes"
                  showError
                  fullWidth
                  onBlur={handleOtherGoalChange}
                  rowCount={2}
                />
              </Grid>
              )}
            </StyledRadioContainer>
            {showSecondaryConversionGoal
              && (
              <StyledRadioContainer
                item
                hasError={otherSecondaryGoalError}
                theme={IQTheme}
              >
                <IQButtonRadioContainer
                  label={t('pages.landing.goals.secondRadioContainerLabel')}
                  name="secondaryConversionGoal"
                  optionsList={optionsForSecondaryGoal}
                  singleColumn
                  required
                />
                {otherSecondary && !other && (
                <Grid item xs={6} pl="42px">
                  <IQFormTextArea
                    id="other-conversion-goal-second"
                    name="otherSecondaryGoalNotes"
                    showError
                    fullWidth
                    onBlur={handleSecondaryOtherGoalChange}
                    rowCount={2}
                  />
                </Grid>
                )}
                {showPreferredEmailSecondary && !showPreferredEmailPrimary
                && (
                  <Grid>
                    <Grid item xs={6} mt="24px"
                      mb="8px"
                    >
                      <IQLabelTooltip
                        labelText={t('pages.landing.goals.preferredEmailLabel')}
                        theme={IQTheme}
                        hasError={false}
                        required={false}
                        tooltipText=""
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <IQInputEmail
                        labelText={t('pages.landing.goals.preferredEmailLabel')}
                        fontLabelWeight="600"
                        theme={IQTheme}
                        schema={schema}
                        label={t('pages.landing.goals.preferredEmailLabel')}
                        field="preferredEmailSecondary"
                        fullWidth
                        adornmentIcon={<MailOutlineIcon />}
                      />
                    </Grid>
                  </Grid>
                )}
              </StyledRadioContainer>
              )}
            <StyledTextboxContainer item xs={6}>
              <IQFormTextArea
                id="conversion-goal-details"
                labelText={t('pages.landing.goals.goalDetailsLabel')}
                name="conversionGoal"
                fontLabelWeight="600"
                onBlur={handleConversionGoalChange}
                rowCount={2}
                showError
                spaceBottomMultiplier={1}
              />
              <IQFormTextArea
                id="additional-notes"
                labelText={t('pages.landing.goals.additionalNotesLabel')}
                name="additionalGoalsNotes"
                fontLabelWeight="600"
                rowCount={2}
                spaceBottomMultiplier={1}
              />
            </StyledTextboxContainer>
            {/* show the dialog if we changed the field and didn't submit the changes */}
            <CoNavigationConfirm
              showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </Box>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
