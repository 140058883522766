import { UseFormWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import {
  deleteFile,
  deleteGettyAsset,
  getFiles,
  getGettyAssets,
  ProductTypes,
  selectFilesGetty,
  selectFilesUploaded,
  updateFiles,
  updateGettyAssets,
  updateStoreFilesUploaded,
  updateStoreGettyUploaded,
} from '../services/fileUploaderSlice';

export type UseUpdateAssetsReturn = {
  triggerGettyUpdate: () => void;
  triggerFileUpdate: () => void;
  triggerAllUpdates: () => void;
  updateFileStore: () => void;
  updateGettyStore: () => void;
  updateAllAssetsStore: () => void;
  deleteFiles: () => void;
  deleteGettyFiles: () => void;
  deleteAllAssets: () => void;
};

const useUpdateAssets = (
  watch: UseFormWatch<any>,
  fileUploaderName?: string,
  gettyUploaderName?: string,
  websiteId?: string,
  orderId?: string,
  productType?: ProductTypes,
  offeringType?: WebsiteOfferingTypes,
  orderItemId?: string,
): UseUpdateAssetsReturn => {
  const dispatch = useDispatch();

  const gettyFiles = useSelector(selectFilesGetty).content;
  const files = useSelector(selectFilesUploaded).content;

  const gettyFormData = watch(gettyUploaderName);
  const fileFormData = watch(fileUploaderName);

  useEffect(() => {
    if (productType) {
      dispatch(getGettyAssets({ orderId, orderItemId, productType }));
      dispatch(getFiles({ websiteId, uploaderType: productType, offeringType }));
    }
  }, [productType]);

  const triggerGettyUpdate = () => {
    const concattedGetty = gettyFiles.map(file => {
      const formFile = gettyFormData?.find(x => x.id === file.id);
      return { ...file, ...formFile };
    });
    dispatch(updateGettyAssets({ orderId, files: concattedGetty }));
  };

  const triggerFileUpdate = () => {
    const concattedFiles = files.map(file => {
      const formFile = fileFormData?.find(x => x.id === file.id);
      return { ...file, ...formFile };
    });
    dispatch(updateFiles({ files: concattedFiles }));
  };

  const updateFileStore = () => {
    const concattedFiles = files.map(file => {
      const formFile = fileFormData?.find(x => x.id === file.id);
      return { ...file, ...formFile };
    });
    dispatch(updateStoreFilesUploaded(concattedFiles));
  };

  const updateGettyStore = () => {
    const concattedGetty = gettyFiles.map(file => {
      const formFile = gettyFormData?.find(x => x.id === file.id);
      return { ...file, ...formFile };
    });
    dispatch(updateStoreGettyUploaded(concattedGetty));
  };

  const updateAllAssetsStore = () => {
    if (fileUploaderName) {
      updateFileStore();
    }
    if (gettyUploaderName) {
      updateGettyStore();
    }
  };

  const triggerAllUpdates = () => {
    triggerGettyUpdate();
    triggerFileUpdate();
  };

  const deleteFiles = () => {
    if (productType) {
      const filteredFiles = files.filter(item => item.uploaderType === productType);
      const concattedFiles = filteredFiles.map(file => {
        const formFile = fileFormData?.find(x => x.id === file.id);
        return { ...file, ...formFile };
      });
      concattedFiles.forEach(file => {
        dispatch(deleteFile({ websiteId, file }));
      });
      updateFileStore();
    }
  };

  const deleteGettyFiles = () => {
    const concattedGetty = gettyFiles.map(file => {
      const formFile = gettyFormData?.find(x => x.id === file.id);
      return { ...file, ...formFile };
    });
    concattedGetty.forEach(file => {
      dispatch(deleteGettyAsset({ data: file }));
    });
    updateGettyStore();
  };

  const deleteAllAssets = () => {
    deleteFiles();
    deleteGettyFiles();
  };

  return {
    triggerGettyUpdate,
    triggerFileUpdate,
    triggerAllUpdates,
    updateFileStore,
    updateGettyStore,
    updateAllAssetsStore,
    deleteFiles,
    deleteGettyFiles,
    deleteAllAssets,
  };
};

export default useUpdateAssets;
