/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import { Client } from 'pages/business/client/ClientType';
import AxiosAPI from './common/axios';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { RootState } from './redux/store';
import { thunkBuilder } from './common/functions';

export interface CurrentClient {
  client: Client
}

export interface ClientsState {
  clients: StatusType<Client[]>
  currentClient: StatusType<CurrentClient>
}

const initialState: ClientsState = {
  clients: {
    status: 'idle',
    message: '',
    content: [],
  },
  currentClient: {
    status: 'idle',
    message: '',
    content: { client: null },
  },
};

export const getClients = createAsyncThunkCatchErrors(
  'clients/get',
  async (orderId: string) => {
    const response = await AxiosAPI.get(`/clients/email&phone-number?orderId=${orderId}`);
    return response.data;
  },
);

export const getClientById = createAsyncThunkCatchErrors(
  'client/get',
  async (id: string) => {
    const response = await AxiosAPI.get(`/clients/${id}/full-view`);
    const data = { client: response.data };
    return data;
  },
);

export const createClient = createAsyncThunkCatchErrors(
  'clients/create',
  async ({ orderId, contact } : { orderId: string, contact: any }) => {
    const response = await AxiosAPI.put(
      `/clients/email&phone-number?orderId=${orderId}`,
      contact,
    );
    return response.data;
  },
);

export const editClient = createAsyncThunkCatchErrors(
  'clients/updateClient',
  async ({ contactId, orderId, contact } : { contactId: string, orderId: string, contact: any }) => {
    const response = await AxiosAPI.put(
      `/clients/email&phone-number?contactId=${contactId}&orderId=${orderId}`,
      contact,
    );
    return response.data;
  },
);

export const deleteClient = createAsyncThunkCatchErrors(
  'clients/delete',
  async (id: string) => {
    const response = await AxiosAPI.delete(`/clients/${id}`);
    return response.data;
  },
);

export const setClients = createAction<Client[]>('clients/set');
export const setCurrentClient = createAction<CurrentClient>('clients/current/set');

export const clientsSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder)
      .addCase(getClients, 'clients')
      .addCase(getClientById, 'currentClient');

    builder
      .addCase(setClients, (state, action) => {
        state.clients.content = action.payload;
      })
      .addCase(setCurrentClient, (state, action) => {
        state.currentClient.content = action.payload;
      });
  },
);

export const selectClients = (state: RootState) => state.clients.clients.content;
export const selectCurrentClient = (state: RootState) => state.clients.currentClient.content;

export default clientsSlice;
