import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import {Urls} from 'navigation/Urls';
import {useParams} from "react-router";
import TargetedDisplayForm from './TargetedDisplayForm';

export default function TargetedDisplay() {
  const {page} = useParams();
  const {product, onSubmit, onBack} = useProduct(page as Urls);

  return (
      <ContentLoader status={product.status} message={product.message}>
        <TargetedDisplayForm
            product={product?.content?.data && Object.keys(product.content.data).includes('targetedDisplay') && product.content.data.targetedDisplay[0]}
            onSubmit={onSubmit}
            onBack={onBack}
        />
      </ContentLoader>
  );
}
