import {Urls} from 'navigation/Urls';
import useProduct from 'hooks/useProduct';
import {StatusType} from 'models/StatusType';

import ContentLoader from 'components/contentLoader/ContentLoader';
import DetailsPageForm from "./DetailsPageForm";
import { SEMDetailProps, GetSEMDetailResponse } from 'models/SEMDetailProps';

export default function SemDetails() {
  const {product, onSubmit, onBack} = useProduct<SEMDetailProps,
      StatusType<GetSEMDetailResponse>>(Urls.SemDetails);
  return (
    <ContentLoader status={product.status} message={product.message}>
      <DetailsPageForm
          product={product.content?.data}
          onSubmit={onSubmit}
          onBack={onBack}
      />
    </ContentLoader>
  );
}
