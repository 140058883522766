import { useTheme } from '@emotion/react';
import { IQButtonRadioContainer, IQFormInput, ValidationProvider } from '@gannettdigital/shared-react-components';
import {
  Box, Button, Divider, Grid, Typography,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import CarbonLogo from 'icons/logos/carbon-logomark.png';
import CoTopNav from 'components/topNav/CoTopNav';
import { runCommands } from 'services/carbonSlice';
import schema from './Carbon.schema';
import CommandModal from './CommandModal';
import ConfirmationModal from './ConfirmationModal';

const createYesNoOptions = (field: string) => (
  [
    {
      value: 'yes',
      label: 'Yes',
      field,
    },
    {
      value: 'no',
      label: 'No',
      field,
    },
  ]
);

const createEnableDisableOptions = (field) => [
  {
    value: 'enable',
    label: 'Enable',
    field,
  },
  {
    value: 'disable',
    label: 'Disable',
    field,
  },
];

const environmentOptions = [
  {
    value: 'dev',
    label: 'Development',
    field: 'environment',
  },
  {
    value: 'test',
    label: 'Testing',
    field: 'environment',
  },
  {
    value: 'live',
    label: 'Live',
    field: 'environment',
  },
];

export default function Carbon() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState<'upgrade' | 'downgrade'>(null);
  const [serviceSelected, setServiceSelected] = useState(false);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      siteName: '',
      unfreeze: 'no',
      upgrade: 'no',
      downgrade: 'no',
      okta: null,
      userway: null,
      sendgrid: null,
      environment: null,
    },
  });
  const {
    reset, getValues, setValue, watch, formState: { isValid },
  } = methods;

  const disableAll = useMemo(() => {
    const values = getValues();
    return values.unfreeze === 'yes' || values.upgrade === 'yes' || values.downgrade === 'yes';
  }, [watch()]);

  const unfreezeSelected = useMemo(() => getValues('unfreeze') === 'yes', [watch()]);
  const upgradeSelected = useMemo(() => getValues('upgrade') === 'yes', [watch()]);
  const downgradeSelected = useMemo(() => getValues('downgrade') === 'yes', [watch()]);

  const oktaSelected = useMemo(() => getValues('okta') !== null && getValues('okta') !== '', [watch()]);
  const sendgridSelected = useMemo(() => getValues('sendgrid') !== null && getValues('sendgrid') !== '', [watch()]);
  const userwaySelected = useMemo(() => getValues('userway') !== null && getValues('userway') !== '', [watch()]);

  const environmentSelected = useMemo(() => (getValues('environment') !== null
                                        && getValues('environment') !== ''), [watch()]);

  const generalCommandSelected = useMemo(
    () => unfreezeSelected || upgradeSelected || downgradeSelected,
    [watch()],
  );

  useEffect(() => {
    if (sendgridSelected || oktaSelected || userwaySelected) {
      setServiceSelected(true);
    } else {
      setServiceSelected(false);
    }
  }, [sendgridSelected, oktaSelected, userwaySelected]);

  useEffect(() => {
    const clear = () => {
      setValue('okta', null);
      setValue('userway', null);
      setValue('sendgrid', null);
      setValue('environment', null);
    };
    const subscription = watch((value, { name, type }) => {
      if (name === 'unfreeze' && value.unfreeze === 'yes') {
        clear();
        setValue('upgrade', null);
        setValue('downgrade', null);
      } else if (name === 'upgrade' && value.upgrade === 'yes') {
        clear();
        setValue('unfreeze', null);
        setValue('downgrade', null);
      } else if (name === 'downgrade' && value.downgrade === 'yes') {
        clear();
        setValue('unfreeze', null);
        setValue('upgrade', null);
      } else if (name === 'unfreeze' && value.unfreeze === 'no' && type === 'change') {
        setValue('upgrade', 'no');
        setValue('downgrade', 'no');
      } else if (name === 'upgrade' && value.upgrade === 'no' && type === 'change') {
        clear();
        setValue('unfreeze', 'no');
        setValue('downgrade', 'no');
      } else if (name === 'downgrade' && value.downgrade === 'no' && type === 'change') {
        clear();
        setValue('unfreeze', 'no');
        setValue('upgrade', 'no');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = () => {
    const data = getValues();
    if (data.upgrade === 'yes') {
      setConfirmationAction('upgrade');
      setShowConfirmation(true);
    } else if (data.downgrade === 'yes') {
      setConfirmationAction('downgrade');
      setShowConfirmation(true);
    } else {
      setShowModal(true);
      dispatch(runCommands(data));
    }
  };

  const onActionConfirmed = () => {
    setShowConfirmation(false);
    setShowModal(true);
    dispatch(runCommands(getValues()));
  };

  const runAnotherCommand = () => {
    setShowModal(false);
    reset();
  };

  const isRunCommandDisabled = () => {
    if (generalCommandSelected) {
      return !isValid;
    }
    return (!isValid || !serviceSelected || !environmentSelected);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CoTopNav />
      <Box>
        <Grid container sx={{ margin: '100px auto 150px', width: '700px' }}>
          <Grid item xs={12}
            display="flex" flexDirection="column" alignItems="center"
          >
            <img src={CarbonLogo} alt="CarbonLogo"
              style={{ height: '75px', width: '75px', marginBottom: '20px' }}
            />
            <Typography variant="h2" marginBottom={5}>Carbon</Typography>
          </Grid>
          <ValidationProvider schema={schema}>
            <FormProvider {...methods}>
              <Grid item xs={12} marginBottom={5}>
                <IQFormInput
                  fontLabelWeight="bold"
                  labelText="Site Name *"
                  id="siteName"
                  name="siteName"
                  theme={theme}
                  schema={schema}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} marginBottom={3}>
                <Typography variant="h4">Select Command</Typography>
              </Grid>
              <Grid item xs={6} marginBottom={2}>
                <IQButtonRadioContainer
                  disabled={downgradeSelected || upgradeSelected}
                  inline
                  name="unfreeze"
                  optionsList={createYesNoOptions('unfreeze')}
                  label="&nbsp;&nbsp;&nbsp;Unfreeze"
                  allowRadioDeselect
                  required={false}
                />
              </Grid>
              <Grid item xs={6} marginBottom={2}>
                <IQButtonRadioContainer
                  disabled={disableAll}
                  inline
                  name="okta"
                  optionsList={createEnableDisableOptions('okta')}
                  label="&nbsp;&nbsp;&nbsp;Okta"
                  allowRadioDeselect
                />
              </Grid>
              <Grid item xs={6} marginBottom={2}>
                <IQButtonRadioContainer
                  disabled={unfreezeSelected || downgradeSelected}
                  inline
                  name="upgrade"
                  optionsList={createYesNoOptions('upgrade')}
                  label="&nbsp;&nbsp;&nbsp;Upgrade"
                  allowRadioDeselect
                  required={false}
                />
              </Grid>
              <Grid item xs={6} marginBottom={2}>
                <IQButtonRadioContainer
                  disabled={disableAll}
                  inline
                  name="userway"
                  optionsList={createEnableDisableOptions('userway')}
                  label="&nbsp;&nbsp;&nbsp;Userway"
                  allowRadioDeselect
                />
              </Grid>
              <Grid item xs={6} marginBottom={2}>
                <IQButtonRadioContainer
                  disabled={unfreezeSelected || upgradeSelected}
                  inline
                  name="downgrade"
                  optionsList={createYesNoOptions('downgrade')}
                  label="&nbsp;&nbsp;&nbsp;Downgrade"
                  allowRadioDeselect
                  required={false}
                />
              </Grid>
              <Grid item xs={6} marginBottom={2}>
                <IQButtonRadioContainer
                  disabled={disableAll}
                  allowRadioDeselect
                  inline
                  name="sendgrid"
                  optionsList={createEnableDisableOptions('sendgrid')}
                  label="&nbsp;&nbsp;&nbsp;SendGrid"
                />
              </Grid>
              <Grid item xs={12}
                marginTop={5}
                marginBottom={3}
              >
                <Typography variant="h4">Select Environment</Typography>
              </Grid>
              <Grid item xs={6} marginBottom={8}>
                <IQButtonRadioContainer
                  disabled={disableAll}
                  allowRadioDeselect
                  name="environment"
                  optionsList={environmentOptions}
                  label="&nbsp;&nbsp;&nbsp;Environment"
                  singleColumn
                  required={false}
                />
              </Grid>
              <Grid item xs={12} marginBottom={3}>
                <Divider />
              </Grid>
              <Grid item xs={12}
                display="flex" flexDirection="row" justifyContent="space-between"
              >
                <Button variant="outlined" color="primary" onClick={() => reset()}>Reset</Button>
                <Button variant="contained" color="primary"
                  disabled={isRunCommandDisabled()}
                  onClick={onSubmit}
                >
                  Run Command
                </Button>
              </Grid>
            </FormProvider>
          </ValidationProvider>
        </Grid>
      </Box>
      <CommandModal siteName={getValues('siteName')} open={showModal} onClose={() => setShowModal(false)}
        onRunAnother={() => runAnotherCommand()}
      />
      <ConfirmationModal
        open={showConfirmation}
        action={confirmationAction}
        onClose={() => setShowConfirmation(false)}
        onContinue={onActionConfirmed}
      />
    </Box>
  );
}
