import * as yup from 'yup';

const schema = {
  title: yup.string().required('This is a required field.'),
  description: yup.string().required('This is a required field.'),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
