import {
  IQButtonLink, IQFileUploader, IQLoadingSpinner, IQThemeV2, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { FormProvider, useForm } from 'react-hook-form';
import {
  FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography,
} from '@mui/material';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { Trans } from 'react-i18next';
import i18n from 'i18n/i18n';
import React, { useEffect, useState } from 'react';
import schemaValidate from 'components/schemaValidate';
import { inputStyles } from 'pages/seo/details/DetailsPageForm';
import ErrorText from 'components/errorText/ErrorText';
import {
  FileUploadProductType,
  ProductTypes, deleteFile, getFiles, selectFilesUploaded, uploadFiles,
} from 'services/fileUploaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import CoFileList from 'components/fileList/CoFileList';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import GridItem from 'components/grid/GridItem';
import SocialMediaAccounts from 'pages/locations/contact/SocialMediaAccounts';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import {
  socialMediaAccounts, yesNoOptions,
} from 'pages/dms-next/cc-by-dash/solutionStrategy/CCByDashStrategyConstants';
import TrackingLineCard from 'pages/dms-next/cc-by-dash/solutionStrategy/phone-tracking/TrackingLineCard';
import PhoneTracking from 'pages/dms-next/cc-by-dash/solutionStrategy/phone-tracking/PhoneTracking';
import PhoneTrackingForm from 'pages/dms-next/cc-by-dash/solutionStrategy/phone-tracking/PhoneTrackingForm';
import { selectOrdersContent } from 'services/ordersSlice';
import { getBusinessLocations, selectBusinessLocations } from 'services/businessLocationSlice';
import schema from './CustomerCenterStrategy.schema';

const { t } = i18n;

interface Props {
  product: any
  onBack: any
  onSubmit: any
}

const emptyTrackingLine = {
  originalNumber: '',
  forwardToNumber: '',
  areaCodePreference: '',
};

export default function CustomerCenterStrategyForm(props: Props) {
  const { product } = props;
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const dispatch = useDispatch();
  const filesUploaded = useSelector(selectFilesUploaded);
  const order = useSelector(selectOrdersContent);
  const locations = useSelector(selectBusinessLocations);
  const [files, setFiles] = useState([]);
  const [phoneTracking, setPhoneTracking] = useState(product?.phoneTracking || '');
  const [linkSocialMedia, setLinkSocialMedia] = useState(product?.linkSocialMedia || '');
  const [isTrackingLineOpen, setIsTrackingLineOpen] = useState(false);
  const [trackingLines, setTrackingLines] = useState(product?.trackingLines || []);
  const [trackingLine, setTrackingLine] = useState(emptyTrackingLine);
  const [trackingLineError, setTrackingLineError] = useState(false);

  const uploaderType = ProductTypes.EXISTING_CONTACT_LIST;
  const megabyte = 1_000_000;
  const defaultFileSize = 256 * megabyte;

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      phoneTracking: product?.phoneTracking || '',
      trackingLines: product?.trackingLines || [],
      edgeLeadEmail: product?.edgeLeadEmail || '',
      linkSocialMedia: product?.linkSocialMedia || '',
      accounts: product?.accounts || socialMediaAccounts,
      additionalNotes: product?.additionalNotes || '',
    },
  });

  const {
    register,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: {
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
      isValid,
      errors,
    },
  } = methods;

  const showPhoneTracking = watch('phoneTracking') === 'yes';
  const showSocialMediaAccounts = watch('linkSocialMedia') === 'yes';
  const noAccounts = watch('accounts').filter(account => account.enabled).length === 0;
  const getSocial = (name, location) => location?.socials?.[0]?.[name] || '';

  useEffect(() => {
    if (order?.businessId) {
      dispatch(getBusinessLocations(order.businessId));
    }
  }, [order]);

  useEffect(() => {
    if (locations.length > 0 && (!product.accounts || product?.linkSocialMedia === '')) {
      const location = locations[locations.length - 1];

      const facebookUrl = getSocial('facebookUrl', location);
      const instagramUrl = getSocial('instagramUrl', location);
      const twitterUrl = getSocial('twitterUrl', location);
      const youtubeUrl = getSocial('youtubeUrl', location);

      const hasSocials = (facebookUrl || instagramUrl || twitterUrl || youtubeUrl);
      if (hasSocials && showSocialMediaAccounts) {
        const socials = [
          {
            name: 'Facebook',
            enabled: !!facebookUrl,
            url: facebookUrl || '',
            applyToAll: false,
          },
          {
            name: 'Twitter',
            enabled: !!twitterUrl,
            url: twitterUrl || '',
            applyToAll: false,
          },
          {
            name: 'Instagram',
            enabled: !!instagramUrl,
            url: instagramUrl || '',
            applyToAll: false,
          },
          {
            name: 'YouTube',
            enabled: !!youtubeUrl,
            url: youtubeUrl || '',
            applyToAll: false,
          },
        ];

        setValue('accounts', socials);
        socials?.forEach((account, index) => setValue(`accounts.${index}`, account));
      }
    }
  }, [locations, showSocialMediaAccounts]);

  useEffect(() => {
    if (!showSocialMediaAccounts) setValue('accounts', socialMediaAccounts);
  }, [showSocialMediaAccounts]);

  useEffect(() => {
    if (!showPhoneTracking) setTrackingLines([]);
  }, [showPhoneTracking]);

  useEffect(() => {
    const filteredFiles = filesUploaded.content
      .filter(file => file.productType === FileUploadProductType.DMSNextCustomerCenter);
    setFiles(filteredFiles);
  }, [filesUploaded]);

  const onUpdate = (newFiles) => {
    dispatch(uploadFiles({
      websiteId, files: newFiles, uploaderType, offeringType,
    }));
  };

  const onDelete = (file) => {
    dispatch(deleteFile({ websiteId, file }));
  };

  useEffect(() => {
    dispatch(getFiles({ websiteId, uploaderType, offeringType }));
  }, [websiteId, offeringType]);

  const openModal = (content) => {
    setIsTrackingLineOpen(true);
    setTrackingLine(content);
  };

  const closeModal = () => {
    setIsTrackingLineOpen(false);
    setTrackingLine(emptyTrackingLine);
  };

  const addTrackingLine = (data) => {
    setIsTrackingLineOpen(false);
    const linesCopy = [...trackingLines];
    if (!data.id) {
      linesCopy.push({ ...data, id: uuidv4() });
    } else {
      const itemIndex = trackingLines.findIndex(line => line.id === data.id);
      linesCopy[itemIndex] = data;
    }
    setTrackingLines(linesCopy);
    setTrackingLineError(false);
  };

  const deleteTrackingLine = (id) => {
    const itemIndex = trackingLines.findIndex(line => line.id === id);
    if (itemIndex !== -1) {
      const linesCopy = [...trackingLines];
      linesCopy.splice(itemIndex, 1);
      setTrackingLines(linesCopy);
      if (linesCopy.length === 0) setTrackingLineError(true);
    }
  };

  const editTrackingLine = (id) => {
    setIsTrackingLineOpen(true);
    const itemToEdit = trackingLines.filter(line => line.id === id)?.[0];
    if (itemToEdit) setTrackingLine(itemToEdit);
  };

  useEffect(() => {
    setValue('trackingLines', trackingLines);
  }, [trackingLines]);

  const validateForm = () => {
    if (getValues('phoneTracking') === '' || (showPhoneTracking && trackingLines.length === 0)) return true;
    if (showSocialMediaAccounts && noAccounts) return true;
    return !isValid;
  };

  const handleFormSubmit = (data) => props.onSubmit(data);

  return (
    <DefaultPageLayout
      disableContinue={validateForm()}
      onBack={props.onBack}
      onContinue={handleSubmit(handleFormSubmit)}
      header="Solution Strategy"
      description={(
        <Trans
          i18nKey="pages.customerCenter.solutionStrategy.pageDescription"
          components={{ br: <br /> }}
        />
        )}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container xs={9} sx={{ ...inputStyles }}>
            {/* Phone Tracking */}
            <Grid item mb={1} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}>
                  {t('pages.customerCenter.solutionStrategy.phoneTrackingLabel')}
                </Typography>
                <RadioGroup
                  aria-labelledby="phone-tracking"
                  name="phoneTracking"
                  value={phoneTracking}
                  onChange={(e) => setPhoneTracking(e.target.value)}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  {yesNoOptions.map(option => (
                    <FormControlLabel
                      key={option.value}
                      {...register('phoneTracking', {
                        validate: (value) => schemaValidate(value, 'phoneTracking', schema),
                      })}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
            {/* Phone Tracking Options */}
            {showPhoneTracking && (
            <>
              {React.Children.toArray(trackingLines.map((line, index) => (
                <Grid item xs={12} mb={3}>
                  <TrackingLineCard
                    onEdit={editTrackingLine}
                    onDelete={deleteTrackingLine}
                    index={index}
                    data={line}
                  />
                </Grid>
              )))}
              <Grid item xs={12} mb={1}
                sx={{
                  padding: '10px 0',
                  backgroundColor: IQThemeV2.palette.primary.light,
                }}
              >
                <IQButtonLink
                  startIcon={<AddIcon />}
                  onClick={() => openModal(emptyTrackingLine)}
                >
                  Add a Tracking Line
                </IQButtonLink>
              </Grid>
              <Grid xs={12} mb={3}>
                <ErrorText
                  hasError={trackingLineError}
                  errorText="Please add at least one tracking line."
                />
              </Grid>
              <PhoneTracking
                isOpen={isTrackingLineOpen}
                onClose={closeModal}
              >
                <PhoneTrackingForm
                  addNewLine={addTrackingLine}
                  closeModal={closeModal}
                  content={trackingLine}
                />
              </PhoneTracking>
            </>
            )}
            {/* Edge Lead Notification Email */}
            <Grid item mb={3} xs={12}>
              <Typography variant="subtitle2" component="h3" fontSize={16}
                color={errors.edgeLeadEmail ? 'error.main' : 'common.black'} mb={1}
              >
                {t('pages.customerCenter.solutionStrategy.edgeLeadEmailLabel')}
              </Typography>
              <TextField
                {...register('edgeLeadEmail', {
                  validate: (value) => schemaValidate(value, 'edgeLeadEmail', schema),
                })}
                hiddenLabel
                required={false}
                name="edgeLeadEmail"
                type="email"
                error={!!errors.edgeLeadEmail}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorText
                hasError={!!errors.edgeLeadEmail}
                errorText="Please enter a valid email in this format: name@example.com."
              />
            </Grid>
            {/* Existing Content */}
            <Grid item xs={12} sx={{
              '& > div > div': { padding: 0, display: 'flex', justifyContent: 'flex-start' },
              '& > div > div > *': { marginLeft: '10px' },
            }}
            >
              <IQFileUploader
                showRecommendedImageText={false}
                showAcceptedFileTypesText={false}
                required={false}
                name="uploads" maxFileSize={defaultFileSize}
                onSuccess={onUpdate}
                label={t('pages.customerCenter.solutionStrategy.existingContentLabel')}
                supportingText=""
                totalAllowedFiles={1}
                videoTypes={[]}
                documentTypes={['.pdf', '.docx', '.doc', '.csv', '.xlsx']}
                size="md"
                currentUploadedFiles={files.length}
              />
            </Grid>
            <Grid item xs={12} mb={1}
              mt={0}
            >
              {filesUploaded.status === 'loading' && <IQLoadingSpinner size={40} />}
              {files.length
                ? <CoFileList files={files} onDelete={onDelete} />
                : null }
            </Grid>
            {/* Link Social Media */}
            <Grid item mb={3} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}>
                  {t('pages.customerCenter.solutionStrategy.linkSocialMediaLabel')}
                </Typography>
                <RadioGroup
                  aria-labelledby="link-social-media"
                  name="linkSocialMedia"
                  value={linkSocialMedia}
                  onChange={(e) => setLinkSocialMedia(e.target.value)}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  {yesNoOptions.map(option => (
                    <FormControlLabel
                      key={option.value}
                      {...register('linkSocialMedia', {
                        validate: (value) => schemaValidate(value, 'linkSocialMedia', schema),
                      })}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
            {/* Social Media Accounts */}
            {showSocialMediaAccounts && (
              <GridItem sizes={[12]}>
                <SocialMediaAccounts
                  showApplyToAll={false}
                  showSectionLabels={false}
                />
              </GridItem>
            )}
            {/* Additional Notes */}
            <Grid item xs={12} mb={3}>
              <NeFormTextArea
                {...register('additionalNotes')}
                id="additionalNotes"
                name="additionalNotes"
                labelText={t('pages.customerCenter.solutionStrategy.additionalNotesLabel')}
                fullWidth
                rowCount={2}
                fontLabelWeight="600"
              />
            </Grid>

          </Grid>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
