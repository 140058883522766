import { Grid, SxProps } from '@mui/material';
import { ReactNode } from 'react';
import styled from '@emotion/styled';

interface Props {
  fullWidth?: boolean;
  children?: ReactNode;
  className?: string;
  sx?: SxProps;
  rowSpacing?: number;
}

const StyledGrid = styled(Grid)`
  .MuiInputBase-root {
    background: ${props => props.theme.palette.common.white};
  }
`;

export default function GridContainer(props: Props) {
  const {
    children, fullWidth, className, sx, rowSpacing,
  } = props;
  return (
    <StyledGrid container item xs={12}
      md={fullWidth ? 12 : 6}
      rowSpacing={rowSpacing || 0}
      {...{ className, sx }}
    >
      {children}
    </StyledGrid>
  );
}
