import { t } from 'i18next';
import * as yup from 'yup';

const yupSchema = {
  loginPortalUserType: yup.array().min(1, t('pages.website.custom.loginPortal.loginUserTypeRequired')),
  loginPortalInfo: yup.string().required(t('pages.website.custom.loginPortal.featuresRequired')),
};

export default {
  yupValidations: yupSchema,
  customValidations: {},
};
