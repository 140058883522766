import { FormProvider, useForm } from 'react-hook-form';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import i18n from 'i18n/i18n';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import GridItem from 'components/grid/GridItem';
import {
  RadioGroup, FormControlLabel, Radio, Grid, Typography,
  Link,
} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { yesNoOptions } from 'pages/seo/details/DetailsPageConstants';
import { useState } from 'react';
import { inputStyles } from 'pages/seo/details/DetailsPageForm';
import schemaValidate from 'components/schemaValidate';
import { tooltip } from 'pages/general-questions/form-sections/Tracking';
import schema from './PiQFormFields.schema';
import { PiQFormFieldsTextAreas } from './PiQFormFieldsTextAreas';

const { t } = i18n;

interface Props {
  product: any;
  onSubmit: (data) => void;
  onBack: () => void;
}

export default function PiQFormFieldsForm({ product, onSubmit, onBack }: Props) {
  const [addressFieldsRequired, setAddressFieldsRequired] = useState(product?.addressFieldsRequired || '');
  const [additionalCommentsRequired,
    setAdditionalCommentsRequired] = useState(product?.additionalCommentsRequired || '');

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      addressFieldsRequired: product?.addressFieldsRequired || '',
      additionalCommentsRequired: product?.additionalCommentsRequired || '',
      qualifyingQuestionOne: product?.qualifyingQuestionOne || '',
      qualifyingQuestionOneOptions: product?.qualifyingQuestionOneOptions || '',
      qualifyingQuestionTwo: product?.qualifyingQuestionTwo || '',
      qualifyingQuestionTwoOptions: product?.qualifyingQuestionTwoOptions || '',
      thankYouMessage: product?.thankYouMessage || '',
    },
  });

  const handleFormSubmit = (data) => onSubmit(data);

  const {
    register,
    handleSubmit,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={onBack}
      header={t('pages.piq.formFields.header')}
      onContinue={handleSubmit(handleFormSubmit)}
      description={(
        <Typography>
          Example of landing page can be seen
          {' '}
          <Link href="https://piqtemplate.pagedemo.co/" target="_blank">here</Link>
          .
        </Typography>
      )}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container xs={12} sx={{ ...inputStyles }}>
            <Grid item mb={3} xs={9}>
              <Grid item mb={1}>
                <Typography variant="subtitle2"
                  component="h2"
                  fontSize={18}
                  fontWeight={600}
                >
                  {t('pages.piq.formFields.subHeadOne')}
                </Typography>
              </Grid>
              <Grid item mb={1}>
                <Typography variant="subtitle2" fontWeight="normal" component="h3"
                  fontSize={16}
                >
                  {t('pages.piq.formFields.subHeadTwo')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item mb={3} xs={12}>
              <Grid mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}
                  mb={1}
                >
                  {t('pages.piq.formFields.addressFieldsRequiredLabel')}
                  {tooltip(
                    t('pages.piq.formFields.addressFieldsRequiredToolTip'),
                    <HelpOutlineOutlinedIcon />,
                    '200px',
                  )}
                </Typography>
                <RadioGroup
                  aria-labelledby="addressFieldsRequired"
                  name="addressFieldsRequired"
                  value={addressFieldsRequired}
                  onChange={(e) => setAddressFieldsRequired(e.target.value)}
                >
                  {yesNoOptions.map(option => (
                    <FormControlLabel
                      key={option.value}
                      {...register('addressFieldsRequired', {
                        validate: (value) => schemaValidate(value, 'addressFieldsRequired', schema),
                      })}
                      value={option.value}
                      control={<Radio />}
                      label={(
                        <Typography variant="body1">
                          {option.label}
                        </Typography>
                            )}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid item mb={3} xs={12}>
              <Typography variant="subtitle2" component="h3" fontSize={16}>
                {t('pages.piq.formFields.additionalCommentsFieldsRequiredLabel')}
              </Typography>
              <RadioGroup
                aria-labelledby="addtionalCommentsRequired"
                name="additionalCommentsRequired"
                value={additionalCommentsRequired}
                onChange={(e) => setAdditionalCommentsRequired(e.target.value)}
              >
                <GridItem sizes={[1, 2]} marginBottom={0}>
                  {yesNoOptions.map(option => (
                    <FormControlLabel
                      key={option.value}
                      {...register('additionalCommentsRequired', {
                        validate: (value) => schemaValidate(value, 'additionalCommentsRequired', schema),
                      })}
                      value={option.value}
                      control={<Radio />}
                      label={(
                        <Typography variant="body1">
                          {option.label}
                        </Typography>
                        )}
                    />
                  ))}
                </GridItem>
              </RadioGroup>
            </Grid>
            <Grid container xs={12} sx={{ ...inputStyles }}>
              <Grid item mb={3} xs={12}>
                <PiQFormFieldsTextAreas register={register} />
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
