import * as yup from 'yup';

const schema = {
  phoneTracking: yup.string().required(),
  edgeLeadEmail: yup.string().email().optional(),
  linkSocialMedia: yup.string().optional(),
  accounts: yup.array(yup.object({
    name: yup.string().notRequired(),
    enabled: yup.boolean().notRequired(),
    url: yup.string().url().notRequired(),
    applyToAll: yup.boolean().notRequired(),
  })).notRequired(),
  activeChatWidget: yup.string().required(),
  smsWidgetColor: yup.string().required(),
  localAreaCode1: yup.string().required(),
  localAreaCode2: yup.string().optional(),
  smsLeadEmailList: yup.array(yup.string().email().optional()).notRequired(),
  smsLeadPhone: yup.string().optional(),
  additionalNotes: yup.string().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
