import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  useTheme,
} from '@mui/material';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import { IQTheme } from '@gannettdigital/shared-react-components';

export interface CoModalPageCardProps {
  title: string
  isOpen: boolean
  onClose: () => void
}

const CoModalPageCard: FC<CoModalPageCardProps> = ({
  title, children, isOpen, onClose,
}) => {
  const theme = useTheme();

  // region Styled Components
  const DialogTitleStyled = styled(DialogTitle)`
  text-align: center;
  font-family: 'Unify Sans', serif;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  color: ${theme.palette.common.black};
  margin-top: 8px;
  margin-bottom: 16px;
`;

  const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 12px;
  top: 18px;
`;

  const Modal = styled(Dialog)`
    & .MuiPaper-root {
      overflow-x: hidden;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 234px;
        height: 234px;
        top: -110px;
        left: -130px;
        z-index: 0;
        margin-top: 34px;
        border: 25px solid #D7D7D780;
        border-radius: 50%;
      }
      &:before {
        content: "";
        position: absolute;
        width: 127px;
        height: 127px;
        right: -100px;
        z-index: 0;
        margin-top: 34px;
        border: 15px solid #D7D7D780;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
      }
   }
`;

  const ModalInnerContainer = styled('div')`
    z-index: 1;
`;

  const DividerStyled = styled(Divider)`
  background: linear-gradient(to right, ${theme.palette.success.main}, ${theme.palette.secondary.main});
  height: 1px;
  margin-left: 1em;
  margin-right: 1em;
`;

  const ButtonStyled = styled(Button)`
  color: ${theme.palette.primary.main};
  font-family: 'Unify Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-decoration-line: underline;
  text-transform: none;
  margin: 0 auto;
  padding-top: 2px;
`;

  // endregion

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: IQTheme.palette.common.black, opacity: '0.86' },
      }}
    >
      <ModalInnerContainer>
        <DialogTitleStyled>{title}</DialogTitleStyled>
        <IconButtonStyled onClick={onClose}>
          <CloseIcon style={{ color: '#1D1D1D' }} />
        </IconButtonStyled>
        <DividerStyled />
        <DialogContent sx={{ paddingBottom: 0 }}>
          {children}
        </DialogContent>
        <DialogActions style={{ paddingTop: 3, paddingBottom: 21 }}>
          <ButtonStyled onClick={onClose}>Close</ButtonStyled>
        </DialogActions>
      </ModalInnerContainer>
    </Modal>
  );
};

export default CoModalPageCard;
