import { Fragment, useEffect, useState } from 'react';
import { Typography, Box, Link } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { useParams } from 'react-router';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { useAppDispatch } from 'services/redux/store';
import { getHipaaFormAdditionalNotes, getHipaaFormDefinitions, getHipaaFormEmails } from 'services/addons/addonsSlice';
import { ProductTypes, getFiles } from 'services/fileUploaderSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useSelector } from 'react-redux';
import { selectOrderItems } from 'services/ordersSlice';
import { renderStatusPill } from 'pages/orders/OrdersTable';
import { headingStyle } from '../Business/BusinessSection';
import { ActionItems } from '../common/ActionItems';
import { findAddonId } from './MasterAddonSection';

export default function HipaaFormsSection({
  product, isEditable, showActionItems, openBusinessAndSalesAgent,
}:any) {
  const appDispatch = useAppDispatch();
  const { orderId } = useParams();
  const orderItems = useSelector(selectOrderItems);
  const navigate = useNavigationHandler();
  const [hipaaFormsData, setHipaaFormsData] = useState([]);
  const [hipaaFormsEmails, setHipaaFormsEmails] = useState([]);
  const [hipaaContent, setHipaaContent] = useState({} as any);
  const [assets, setAssets] = useState([]);
  const [addonStatus, setAddonStatus] = useState('');
  const addonName = 'hipaaForm';

  const getAssets = () => {
    appDispatch(getFiles({
      websiteId: product?.offeringId,
      uploaderType: ProductTypes.HIPAA_FORM,
      offeringType: 'MasterAddon',
    })).then((response) => setAssets(response.payload));
  };

  useEffect(() => {
    if (orderId) {
      appDispatch(getHipaaFormDefinitions({ orderId })).then((response) => setHipaaFormsData(response.payload));
      appDispatch(getHipaaFormEmails({ orderId })).then((response) => setHipaaFormsEmails(response.payload));
      appDispatch(getHipaaFormAdditionalNotes({ orderId })).then((response) => setHipaaContent(response.payload));
    }
  }, [orderId]);

  useEffect(() => {
    if (product) getAssets();
  }, [product]);

  useEffect(() => {
    if (orderItems?.content?.length && addonName) {
      const currentAddon = orderItems.content
        .filter((item) => item.offeringType?.toLowerCase() === addonName?.toLowerCase());
      if (currentAddon.length > 0) setAddonStatus(currentAddon[0].status);
    }
  }, [orderItems]);

  return (
    <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
        HIPAA Forms
        {!isEditable && renderStatusPill(addonStatus)}
      </Typography>

      <Box>

        {/* HIPAA Forms Details */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () =>
            navigate.to(Urls.HipaaForms, product.id))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>HIPAA Forms Details</Typography>
        </Box>
        <GridItem sizes={[6, 6]}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">HIPAA Admin Emails</Typography>
              {hipaaFormsEmails?.map(email => (
                <Typography key={email.id}>{email.email}</Typography>
              ))}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">HIPAA Forms</Typography>
              {hipaaFormsData?.length > 0
                ? hipaaFormsData.map((obj, index) => (
                  <Fragment key={obj.id}>
                    <Typography fontWeight="bold">{`Form ${index + 1}`}</Typography>
                    <Typography>{obj?.title}</Typography>
                    <Typography mb={2}>{obj?.description}</Typography>
                  </Fragment>
                )) : <Typography fontWeight="bold">No Forms</Typography>}
            </Box>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">HIPAA Uploads</Typography>
              {assets?.length
                ? assets.map((asset, index) => (
                  <Link href={asset.url} key={asset.url}>
                    <Typography>{`Asset ${index + 1}`}</Typography>
                  </Link>
                ))
                : <Typography fontWeight="bold">No uploads</Typography>}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Additional Notes/Instructions</Typography>
              {displayNotRequiredField('additional notes/instructions', hipaaContent?.additionalNotes)}
            </Box>
          </Box>
        </GridItem>

        {/* Actions items */}
        {showActionItems && ActionItems(false, findAddonId(orderItems, addonName), false, openBusinessAndSalesAgent)}
      </Box>
    </GridItem>
  );
}
