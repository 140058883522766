/* eslint-disable react/jsx-fragments, react/jsx-no-useless-fragment */
import React, { ComponentProps } from 'react';

import { IQFormTextArea } from '@gannettdigital/shared-react-components';
import { Grid } from '@mui/material';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';

import { t } from 'i18next';

type TextAreaProps = ComponentProps<typeof IQFormTextArea>;

const textAreas :TextAreaProps[] = [
  {
    name: 'qualifyingQuestionOne',
    labelText: t('pages.piq.formFields.qualifyingQuestionOne'),
    id: 'questionOne',
    fontLabelWeight: '600',
    fullWidth: true,
    tooltipText: t('pages.piq.formFields.qualifyingQuestionOneToolTipText'),
  },
  {
    name: 'qualifyingQuestionOneOptions',
    labelText: t('pages.piq.formFields.qualifyingQuestionOneOptions'),
    id: 'questionOneOptions',
    fontLabelWeight: '600',
    fullWidth: true,
    tooltipText: t('pages.piq.formFields.qualifyingQuestionOneOptionsToolTip'),
  },
  {
    name: 'qualifyingQuestionTwo',
    labelText: t('pages.piq.formFields.qualifyingQuestionTwo'),
    id: 'questionTwo',
    fontLabelWeight: '600',
    fullWidth: true,
    tooltipText: t('pages.piq.formFields.qualifyingQuestionTwoToolTip'),
  },
  {
    name: 'qualifyingQuestionTwoOptions',
    labelText: t('pages.piq.formFields.qualifyingQuestionTwoOptions'),
    id: 'questionTwoOptions',
    fontLabelWeight: '600',
    fullWidth: true,
    tooltipText: t('pages.piq.formFields.qualifyingQuestionTwoToolTipText'),
  },
  {
    name: 'thankYouMessage',
    labelText: t('pages.piq.formFields.thankYouMessage'),
    id: 'thankYouMessage',
    fontLabelWeight: '600',
    fullWidth: true,
    tooltipText: t('pages.piq.formFields.thankYouMessageToolTipText'),
  },
];

export const PiQFormFieldsTextAreas = ({ register }: any) => {
  const textareaElements = textAreas.map(textArea => (
    <Grid
      item
      xs={8}
      sx={{ label: { pb: '7px' }, marginTop: '25px' }}
    >
      <NeFormTextArea
        {...register(textArea.name)}
        rowCount={2}
        tooltipPlacement="top"
        tooltipArrow={false}
        {...textArea}
      />
    </Grid>
  ));

  return (
    <React.Fragment>
      {textareaElements}
    </React.Fragment>
  );
};
