import { t } from 'i18next';
import * as yup from 'yup';

const yupSchema = {
  crmIntegrationName: yup.string().required(t('pages.website.custom.crmIntegration.productNameRequired')),
  crmIntegrationInfo: yup.string().required(t('pages.website.custom.crmIntegration.integrationRequired')),
};

export default {
  yupValidations: yupSchema,
  customValidations: {},
};
