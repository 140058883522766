import * as yup from 'yup';

const schema = {
  addressOne: yup.string().nullable()
    .required('Please enter Address Line 1.'),
  city: yup.string().nullable()
    .required('Please enter city.'),
  stateProvinceRegion: yup.string().nullable()
    .required('Please enter state, province, or region.'),
  country: yup.string().nullable()
    .required('Please select country.'),
  zipcode: yup.string().nullable()
    .required('Please enter Zip / Postal Code.'),
  chatListedSupportEmail: yup.string().email('Please enter a valid email in this format: name@example.com')
    .required('This field is required.'),
  emails: yup.array().min(1),
  // serviceAreas: yup.array().max(20, 'Please review and add up to 20 items to continue.'),
  serviceAreas: yup.array()
    .min(1, 'This field is required')
    .max(20, 'max 20'),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
