import { FormProvider, useForm } from 'react-hook-form';
import {
  Box, Grid, Link, TextField, Typography,
} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import PiOWebsiteType from 'models/PiQWebsiteType';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { t } from 'i18next';
import { inputStyles } from 'pages/seo/details/DetailsPageForm';
import schemaValidate from 'components/schemaValidate';
import ErrorText from 'components/errorText/ErrorText';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import { tooltip } from 'pages/general-questions/form-sections/Tracking';
import schema from './PiQPageInfo.schema';

interface Props {
  product: PiOWebsiteType;
  onSubmit: (data :PiQPageInfoProps) => void;
  onBack: () => void;
}

export type PiQPageInfoProps = {
  headline: string;
  description: string;
  callToActions: string;
};

export default function PiQPageInfoForm(props: Props) {
  const { product } = props;

  const methods = useForm<PiQPageInfoProps>({
    mode: 'all',
    defaultValues: {
      headline: product?.headline || '',
      description: product?.description || '',
      callToActions: product?.callToActions || '',
    },
  });
  const {
    handleSubmit,
    register,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful, errors,
    },
  } = methods;

  const onSubmit = (data: PiQPageInfoProps) => props.onSubmit(data);

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={props.onBack}
      header={t('pages.piq.pageInfo.header')}
      onContinue={handleSubmit(onSubmit)}
      description={(
        <Typography>
          Example of landing page can be seen
          {' '}
          <Link href="https://piqtemplate.pagedemo.co/" target="_blank">here</Link>
          .
        </Typography>
      )}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="pageInfoForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container xs={12} sx={{ ...inputStyles }}>
              <Grid item mb={3} xs={9}>
                <Grid item mb={1}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}
                    color={errors.headline ? 'error.main' : 'common.black'}
                  >
                    Headline *
                    {tooltip(t('pages.piq.pageInfo.headlineTooltip'), <HelpOutlineOutlinedIcon />, '150px')}
                  </Typography>
                </Grid>
                <TextField
                  hiddenLabel
                  required
                  name="headline"
                  error={!!errors.headline}
                  fullWidth
                  {...register('headline', {
                    validate: (value) => schemaValidate(value, 'headline', schema),
                  })}
                />
                <ErrorText
                  hasError={!!errors.headline}
                  errorText="Please enter a headline."
                />
              </Grid>
              <Grid item mb={3} xs={9}>
                <NeFormTextArea
                  {...register('description')}
                  id="description"
                  name="description"
                  labelText="Description *"
                  fullWidth
                  rowCount={2}
                  fontLabelWeight="600"
                  tooltipText={t('pages.piq.pageInfo.descriptionTooltip')}
                  tooltipPaddingBottom={16}
                  tooltipArrow={false}
                  tooltipPlacement="top"
                />
                <ErrorText
                  hasError={!!errors.description}
                  errorText="Please enter a description."
                />
              </Grid>
              <Grid item mb={3} xs={9}>
                <NeFormTextArea
                  {...register('callToActions')}
                  id="callToActions"
                  name="callToActions"
                  labelText="Call-to-Actions *"
                  fullWidth
                  rowCount={2}
                  fontLabelWeight="600"
                  tooltipText={t('pages.piq.pageInfo.callToActionsTooltip')}
                  tooltipPaddingBottom={16}
                  tooltipArrow={false}
                  tooltipPlacement="top"
                />
                <ErrorText
                  hasError={!!errors.callToActions}
                  errorText="Please enter call-to-actions."
                />
              </Grid>
              <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
              />
            </Grid>
          </Box>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
