import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';

export const chatSilverMenu: MenuType = ({
  label: 'Smart Chatbot Silver',
  url: '',
  status: 'active',
  offeringType: 'ChatProduct',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
    {
      label: 'Business URL',
      url: Urls.BusinessChat,
      status: 'incomplete',
    },
    {
      label: 'Script Details',
      url: Urls.ScriptDetails,
      status: 'incomplete',
    },
    {
      label: 'Locations',
      url: Urls.ChatLocations,
      status: 'incomplete',
    },
    {
      label: 'Additional',
      url: Urls.ChatAdditional,
      status: 'incomplete',
    },
  ],
});
