import { t } from 'i18next';

export function validateAgeRange(formData, errors) {
  if (formData.maximumAge > 99) {
    errors.maximumAge.addError(t('pages.generic-translation.maxAgeLimitErrorMessage'));
    errors.addError(true);
  }
  if (formData.minimumAge > 99) {
    errors.minimumAge.addError(t('pages.generic-translation.minAgeLimitErrorMessage'));
    errors.addError(true);
  }
  if (formData?.maximumAge < formData?.minimumAge) {
    errors.maximumAge.addError(t('pages.generic-translation.maxAgeGreaterThanMinAgeErrorMessage'));
    errors.addError(true);
  }
  if (formData.maximumAge <= 0) {
    errors.maximumAge.addError(t('pages.generic-translation.maxAgePositiveErrorMessage'));
    errors.addError(true);
  }
  if (formData.minimumAge <= 0) {
    errors.minimumAge.addError(t('pages.generic-translation.minAgePositiveErrorMessage'));
    errors.addError(true);
  }
}

export const urlValidationParameter = ['https://facebook.com/', 'https://facebook.com', 'https://fb.me/',
  'https://fb.me', 'https://fb.com/', 'https://fb.com', 'https://www.facebook.com/', 'https://www.facebook.com'];
