import * as yup from 'yup';

const schema = {
  services: yup.array(yup.string()).notRequired(),
  brands: yup.array(yup.string()).notRequired(),
  paymentTypes: yup.array(yup.string()).notRequired(),
  languages: yup.array(yup.string()).notRequired(),
  demographic: yup.array(yup.string()).notRequired(),
  competitors: yup.array(yup.string()).notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
