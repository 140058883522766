import * as yup from 'yup';
import i18n from 'i18n/i18n';

const {t} = i18n;

const closesAtSchema = yup.string().nullable().when(['opensAt'], {
  is: (opensAt) => opensAt != null,
  then: yup.string().required().typeError(t('pages.business.location.hours.hoursOfOperation.closingHourErrorMessage')),
});

const date = new Date();
date.setDate(date.getDate() - 1);

const parseTimeString = (timeString: any) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
};

const schema = yup.object().shape({
  days: yup.array(yup.object({
    weekday: yup.string(),
    open: yup.boolean(),
    hours: yup.array(yup.object({
      allDay: yup.boolean().notRequired(),
      opensAt: yup.string().nullable(),
      closesAt: yup.string().nullable(),
    }))
    .when(['open'], {
      is: (open) => open,
      then: yup.array(yup.object({
        allDay: yup.boolean().notRequired(),
        opensAt: yup.string().typeError(t('pages.business.location.hours.hoursOfOperation.openingHourErrorMessage')),
        closesAt: closesAtSchema,
      })),
    }),
  }))
  .test({
    name: 'oneOpenDay',
    message: t('pages.business.location.hours.hoursOfOperation.noHoursErrorMessage'),
    test: days => !!days.find(day => day.open === true),
  }),
  openingHourNotes: yup.string().notRequired().nullable(),
  specialHours: yup.array(yup.object().shape({
    date: yup.date().typeError(
        t('pages.business.location.hours.grandOpeningFormat'),
    ),
    allDay: yup.boolean(),
    closed: yup.boolean(),
    secondTimeSlot: yup.boolean(),
    specialFromHour1: yup.string().when('closed', {
      is: true,
      then: yup.string()
      .typeError(t('pages.business.location.hours.hoursOfOperation.openingHourErrorMessage'))
      .required(t('pages.business.location.hours.hoursOfOperation.openingHourErrorMessage')),
    }),
    specialToHour1: yup.string().when('closed', {
      is: true,
      then: yup.string()
      .typeError(t('pages.business.location.hours.hoursOfOperation.closingHourErrorMessage'))
      .required(t('pages.business.location.hours.hoursOfOperation.closingHourErrorMessage'))
      .test('is-after-specialFromHour1', t('pages.business.location.hours.hoursOfOperation.closingHourAfterOpeningHourErrorMessage'), function (value) {
        const {specialFromHour1, allDay} = this.parent;
        if (allDay) return true;
        if (specialFromHour1 === '00:00' && value === '00:00') return true;
        if (!value || !specialFromHour1) return true;
        return parseTimeString(value) > parseTimeString(specialFromHour1);
      })
    }),
    specialFromHour2: yup.string().when(['closed', 'secondTimeSlot'], {
      is: (closed, secondTimeSlot) => closed && secondTimeSlot,
      then: yup.string()
      .typeError(t('pages.business.location.hours.hoursOfOperation.openingHourErrorMessage'))
      .required(t('pages.business.location.hours.hoursOfOperation.openingHourErrorMessage'))
      .test('is-after-specialToHour1', t('pages.business.location.hours.hoursOfOperation.openingHourAfterClosingHourOfPreviousRowErrorMessage'), function (value) {
        const {specialToHour1, secondTimeSlot} = this.parent;
        if (!secondTimeSlot) return true;
        if (!value || !specialToHour1) return true;
        return parseTimeString(value) > parseTimeString(specialToHour1);
      }),
    }),
    specialToHour2: yup.string().when(['closed', 'secondTimeSlot'], {
      is: (closed, secondTimeSlot) => closed && secondTimeSlot,
      then: yup.string()
      .typeError(t('pages.business.location.hours.hoursOfOperation.closingHourErrorMessage'))
      .required(t('pages.business.location.hours.hoursOfOperation.closingHourErrorMessage'))
      .test('is-after-specialFromHour2', t('pages.business.location.hours.hoursOfOperation.closingHourAfterOpeningHourErrorMessage'), function (value) {
        const {specialFromHour2, secondTimeSlot} = this.parent;
        if (!secondTimeSlot) return true;
        if (!value || !specialFromHour2) return true;
        return parseTimeString(value) > parseTimeString(specialFromHour2);
      }),
    }),
    dateFrom: yup.date()
    .min(date, t('pages.business.location.hours.hoursOfOperation.dateFromEmptyErrorMessage'))
    .typeError(t('pages.business.location.hours.grandOpeningFormat')).required(),
  })).notRequired(),
  applyHoursToAll: yup.boolean().notRequired(),
  grandOpeningDate: yup.date().notRequired().nullable(),
});

export default {
  yupValidations: schema,
  customValidations: {},
};
