import i18n from 'i18n/i18n';

import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/NotesAndInstructionsSchema";
import {
  TrackingCustomValidate,
  TrackingSchema,
  TrackingUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TrackingSchema";
import {
  XmoUpgradeSocialFacebookSmartOptimisationCustomValidate,
  XmoUpgradeSocialFacebookSmartOptimisationSchema,
  XmoUpgradeSocialFacebookSmartOptimisationUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/xmo-social-upgrade-schema/XmoUpgradeSocialFacebookSmartOptimisationSchema";
import {
  AdSetsCustomValidate,
  AdSetsSchema,
  AdSetsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/AdSetsSchema";

import {
  TargetingCustomValidate,
  TargetingSchema,
  TargetingUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/xmo-social-upgrade-schema/TargetingSchema";
import {
  TrackingSocialUpgradeCustomValidate,
  TrackingSocialUpgradeSchema,
  TrackingSocialUpgradeUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/xmo-social-upgrade-schema/TrackingSocialUpgradeSchema";
import {
  TargetingSocialUpgradeCustomValidate,
  TargetingSocialUpgradeSchema,
  TargetingSocialUpgradeUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/xmo-social-upgrade-schema/TargetingSocialUpgradeSchema";
import {
  AddAdSetsCustomValidate,
  AddAdSetsSchema,
  AddAdSetsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/AddAdSetsSchema";
import {
  WebEventsCustomValidate,
  WebEventsSchema,
  WebEventsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/WebEventsSchema";
import {
  CallTrackingCustomValidate,
  CallTrackingSchema,
  CallTrackingUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/CallTrackingSchema";
import {
  TrackingNumberDetailsCustomValidate,
  TrackingNumberDetailsSchema,
  TrackingNumberDetailsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TrackingNumberDetails";

const {t} = i18n;

export const AusXmoSocialUpgradeSchema = (data) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.ausProduct.tracking.title'),
      schema: TrackingSchema(data),
      uiSchema: TrackingUISchema,
      customValidate: TrackingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.xmoUpgradeSocial.facebookSmartOptimisation.title'),
      schema: XmoUpgradeSocialFacebookSmartOptimisationSchema(data),
      uiSchema: XmoUpgradeSocialFacebookSmartOptimisationUISchema,
      customValidate: XmoUpgradeSocialFacebookSmartOptimisationCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.adSet.adSetTitle'),
      schema: AdSetsSchema(data),
      uiSchema: AdSetsUISchema,
      customValidate: AdSetsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.adSet.addAdSetTitle'),
      schema: AddAdSetsSchema(data),
      uiSchema: AddAdSetsUISchema(data),
      customValidate: AddAdSetsCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.xmoUpgradeSocial.targeting.title'),
      schema: TargetingSchema(data),
      uiSchema: TargetingUISchema,
      customValidate: TargetingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.xmoUpgradeSocial.tracking.title'),
      schema: TrackingSocialUpgradeSchema(data),
      uiSchema: TrackingSocialUpgradeUISchema,
      customValidate: TrackingSocialUpgradeCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.xmoUpgradeSocial.xmoUpgradeSocialTargeting.title'),
      schema: TargetingSocialUpgradeSchema(data),
      uiSchema: TargetingSocialUpgradeUISchema,
      customValidate: TargetingSocialUpgradeCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ];

  const trackingRequirements = data.trackingRequirements;
  const indexToInsert = 7;

  if (trackingRequirements === "Call and Web Event Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    });
    schema.splice(indexToInsert + 1, 0, {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    });
    schema.splice(indexToInsert + 2, 0, {
      type: 'object',
      title: t('pages.ausProduct.trackingNumber1Details.title'),
      schema: TrackingNumberDetailsSchema(data),
      uiSchema: TrackingNumberDetailsUISchema(data),
      customValidate: TrackingNumberDetailsCustomValidate,
    });
  } else if (trackingRequirements === "Web Event Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    });
  } else if (trackingRequirements === "Call Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    });
    schema.splice(indexToInsert + 1, 0, {
      type: 'object',
      title: t('pages.ausProduct.trackingNumber1Details.title'),
      schema: TrackingNumberDetailsSchema(data),
      uiSchema: TrackingNumberDetailsUISchema(data),
      customValidate: TrackingNumberDetailsCustomValidate,
    });
  }

  return schema;
};
export const AusXmoSocialUpgradeUISchema = (data) => ({
  'ui:order': [
    ...TrackingUISchema['ui:order'],
    ...XmoUpgradeSocialFacebookSmartOptimisationUISchema['ui:order'],
    ...AdSetsUISchema['ui:order'],
    ...AddAdSetsUISchema(data)['ui:order'],
    ...TargetingUISchema['ui:order'],
    ...TrackingSocialUpgradeUISchema['ui:order'],
    ...TargetingSocialUpgradeUISchema['ui:order'],
    ...WebEventsUISchema(data)['ui:order'],
    ...CallTrackingUISchema['ui:order'],
    ...TrackingNumberDetailsUISchema(data)['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

export const AusXmoSocialUpgradeValidate = (formData: any, errors: any, uiSchema: any) => errors;
