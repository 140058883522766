import * as yup from 'yup';

const schema = {
  currentWebsite: yup.string().url('Please enter a valid URL that includes either http:// or https://').nullable(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
