/* eslint-disable max-len */
import i18n from 'i18n/i18n';
import { RJSFSchema } from '@rjsf/utils';
import FileUploader from 'pages/generic-product/details/custom-fields/FileUploader';
import { ProductTypes } from 'services/fileUploaderSlice';

const { t } = i18n;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const promotionsPageAndCreativesReachLocalBetaOnlySchema: RJSFSchema | any = (data) => ({
  title: t('pages.promotionsPageCreativesReachLocalBetaOnly.title'),
  type: 'object',
  required: ['nameAndEmailOfCSM', 'clientWebsiteOnUSAToday', 'monthlyAmountAndTCV', 'nameOfPromotion', 'startDate', 'endDate', 'callToAction',
    'linkToAdvertiser', 'promotionTeamToDesignContestLandingPage', 'prizeDetails', 'prizeValue', 'needHelpWithPrizeFulfilment', 'specialOfferInfoAndPromotionsFromAdvertiser',
    'advertiserClientQuestions', 'bounceBackOfferLinkOrThankYouCoupon', 'advertiserClientLikeToReceiveEmailNotification', 'extraChancesToWin', 'preScheduleEmailDate',
    'targetingZipcodes', 'campaignIncludePaidTargetedEmails', 'promotionalDigitalAdsContest', 'uploader_allImageFiles', 'campaignIDOrCaseLabel'],
  properties: {
    linkOfSalesforceOpportunity: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.linkOfSalesforceOpportunityLabel'),
      default: '',
    },
    nameAndEmailOfCSM: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.nameAndEmailOfCSMLabel'),
      default: '',
    },
    clientWebsiteOnUSAToday: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.clientWebsiteOnUSATodayLabel'),
      enum: [
        t('pages.promotionsPageCreativesReachLocalBetaOnly.clientWebsiteOnUSATodayOptions.usaToday'),
        t('pages.promotionsPageCreativesReachLocalBetaOnly.clientWebsiteOnUSATodayOptions.advertiserURL'),
      ],
      description: t('pages.promotionsPageCreativesReachLocalBetaOnly.clientWebsiteOnUSATodayHelpText'),
    },
    monthlyAmountAndTCV: {
      type: 'integer',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.monthlyAmountAndTCVLabel'),
      default: '',
    },
    nameOfPromotion: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.nameOfPromotionLabel'),
      default: '',
    },
    startDate: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.startDateLabel'),
      default: '',
      description: t('pages.promotionsPageCreativesReachLocalBetaOnly.dateHelpText'),
    },
    endDate: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.endDateLabel'),
      default: '',
      description: t('pages.promotionsPageCreativesReachLocalBetaOnly.dateHelpText'),
    },
    callToAction: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.callToActionLabel'),
      default: '',
      description: t('pages.promotionsPageCreativesReachLocalBetaOnly.callToActionHelpText'),
    },
    linkToAdvertiser: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.linkToAdvertiserLabel'),
      default: '',
      description: t('pages.promotionsPageCreativesReachLocalBetaOnly.linkToAdvertiserHelpText'),
    },
    promotionTeamToDesignContestLandingPage: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionTeamToDesignContestLandingPageLabel'),
      default: '',
      enum: [
        '',
        t('form.big-box-labels.yes'),
        t('form.big-box-labels.no'),
      ],
      description: t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionTeamToDesignContestLandingPageHelpText'),
    },
    prizeDetails: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.prizeDetailsLabel'),
      default: '',
    },
    prizeValue: {
      type: 'integer',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.prizeValueLabel'),
      default: '',
      description: t('pages.promotionsPageCreativesReachLocalBetaOnly.prizeValueHelpText'),
    },
    needHelpWithPrizeFulfilment: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.needHelpWithPrizeFulfilmentLabel'),
      default: '',
      enum: [
        t('form.big-box-labels.yes'),
        t('form.big-box-labels.no'),
      ],
      description: t('pages.promotionsPageCreativesReachLocalBetaOnly.needHelpWithPrizeFulfilmentHelpText'),
    },
    specialOfferInfoAndPromotionsFromAdvertiser: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.specialOfferInfoAndPromotionsFromAdvertiserLabel'),
      default: '',
    },
    advertiserClientQuestions: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.advertiserClientQuestionsLabel'),
      default: '',
      description: t('pages.promotionsPageCreativesReachLocalBetaOnly.advertiserClientQuestionsHelpText'),
    },
    bounceBackOfferLinkOrThankYouCoupon: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.bounceBackOfferLinkOrThankYouCouponLabel'),
      default: '',
      description: t('pages.promotionsPageCreativesReachLocalBetaOnly.bounceBackOfferLinkOrThankYouCouponHelpText'),
    },
    promotionsPageCreativesReachLocalBetaOnlyDescription1: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionsPageCreativesReachLocalBetaOnlyDescription1Label'),
    },
    advertiserClientLikeToReceiveEmailNotification: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.advertiserClientLikeToReceiveEmailNotificationLabel'),
      default: '',
      description: t('pages.promotionsPageCreativesReachLocalBetaOnly.advertiserClientLikeToReceiveEmailNotificationHelpText'),
    },
    extraChancesToWin: {
      type: 'array',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinLabelLabel'),
      items: {
        type: 'string',
        enum: [
          t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.shareWithFriends'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.offersFromSponsors'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.watchVideo'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.multipleChoiceQuestions'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.linkToDownloadMobileApp'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.linkSponsorsWebsite'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.instagramFacebook'),
        ],
      },
      uniqueItems: true,
    },
    preScheduleEmailDate: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.preScheduleEmailDateLabel'),
      default: '',
    },
    targetingZipcodes: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.targetingZipcodesLabel'),
      default: '',
    },
    campaignIncludePaidTargetedEmails: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.campaignIncludePaidTargetedEmailsLabel'),
      default: '',
      items: {
        enum: [
          t('form.big-box-labels.yes'),
          t('form.big-box-labels.no'),
        ],
      },
      description: t('pages.promotionsPageCreativesReachLocalBetaOnly.campaignIncludePaidTargetedEmailsHelpText'),
    },
    promotionalDigitalAdsContest: {
      type: 'array',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestLabel'),
      items: {
        type: 'string',
        enum: [
          t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptions.Leaderboard'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptions.mediumRectangle'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptions.300By600'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptions.160By600'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptions.250By250'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptions.200By200'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptions.468By60'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptions.staticBillboard'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptions.paramountLandscape'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptions.facebookStaticImage'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptions.facebookCarouselImage'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptions.instagramStaticImage'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptions.twitterInStreamImage'),
          t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptions.snapchat'),
        ],
      },
      uniqueItems: true,
      description: t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestHelpText'),
    },
    uploader_allImageFiles: {
      type: 'string',
      title: '',
      default: '',
    },
    promotionsPageCreativesReachLocalBetaOnlyDescription2: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionsPageCreativesReachLocalBetaOnlyDescription2Label'),
    },
    notesAndInstructions: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.notesAndInstructionsLabel'),
      default: '',
    },
    campaignIDOrCaseLabel: {
      type: 'string',
      title: t('pages.promotionsPageCreativesReachLocalBetaOnly.campaignIDOrCaseLabel'),
      default: '',
    },
  },
  allOf: [
    {
      if: {
        properties: {
          extraChancesToWin: {
            type: 'array',
            contains: {
              enum: [
                t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.watchVideo'),
                t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.multipleChoiceQuestions'),
                t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.linkToDownloadMobileApp'),
                t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.linkSponsorsWebsite'),
                t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.instagramFacebook'),
              ],
            },
          },
        },
      },
      then: {
        properties: {
          extraChanceInformation: {
            type: 'string',
            title: t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChanceInformationLabel'),
            default: '',
            description: t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChanceInformationHelpText'),
          },
        },
        required: ['extraChanceInformation'],
      },
    },
  ],
  dependencies: {
    clientWebsiteOnUSAToday: {
      oneOf: [
        {
          properties: {
            clientWebsiteOnUSAToday: {
              enum: [t('pages.promotionsPageCreativesReachLocalBetaOnly.clientWebsiteOnUSATodayOptions.advertiserURL')],
            },
            advertiserURL: {
              type: 'string',
              title: t('pages.promotionsPageCreativesReachLocalBetaOnly.advertiserURLLabel'),
              default: '',
            },
          },
          required: ['advertiserURL'],
        },
        {
          properties: {
            clientWebsiteOnUSAToday: {
              enum: [t('pages.promotionsPageCreativesReachLocalBetaOnly.clientWebsiteOnUSATodayOptions.usaToday')],
            },
          },
        },
      ],
    },
  },
});

const textInputFieldKeys = [
  'linkOfSalesforceOpportunity',
  'nameAndEmailOfCSM',
  'nameOfPromotion',
  'callToAction',
  'prizeDetails',
  'specialOfferInfoAndPromotionsFromAdvertiser',
  'bounceBackOfferLinkOrThankYouCoupon',
  'advertiserClientLikeToReceiveEmailNotification',
  'extraChanceInformation',
  'campaignIDOrCaseLabel',
];

const textInputFields = textInputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const textAreaFieldKeys = [
  'linkToAdvertiser',
  'extraChanceInformation',
  'advertiserClientQuestions',
  'targetingZipcodes',
  'notesAndInstructions',
];

const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const radioFieldKeys = [
  'campaignIncludePaidTargetedEmails',
];

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'radioButton' },
}), {});

const dateKeys = [
  'startDate',
  'endDate',
  'preScheduleEmailDate',
];

const dateFields = dateKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'datePickerField' },
}), {});

const dropDownKeys = [
  'clientWebsiteOnUSAToday',
  'promotionTeamToDesignContestLandingPage',
  'needHelpWithPrizeFulfilment',
];

const dropDownFields = dropDownKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const integerInputFieldKeys = [
  'monthlyAmountAndTCV',
  'prizeValue',

];

const integerInputField = integerInputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'integerInputField' },
}), {});

const checkBoxesFieldKeys = ['extraChancesToWin', 'promotionalDigitalAdsContest'];

const checkBoxesFields = checkBoxesFieldKeys.reduce(
  (o, key) => ({
    ...o,
    [key]: { 'ui:widget': 'checkBoxField' },
  }),
  {},
);
const urlTextFields = {
  advertiserURL: {},
};
Object.keys(urlTextFields).forEach((key) => {
  urlTextFields[key]['ui:widget'] = 'UrlTextWidget';
});

export const promotionsPageAndCreativesReachLocalBetaOnlyUISchema = {
  'ui:order': [
    'linkOfSalesforceOpportunity', 'nameAndEmailOfCSM', 'clientWebsiteOnUSAToday',
    'advertiserURL', 'monthlyAmountAndTCV', 'nameOfPromotion', 'startDate', 'endDate', 'callToAction',
    'linkToAdvertiser', 'promotionTeamToDesignContestLandingPage', 'prizeDetails', 'prizeValue',
    'needHelpWithPrizeFulfilment', 'specialOfferInfoAndPromotionsFromAdvertiser',
    'advertiserClientQuestions', 'bounceBackOfferLinkOrThankYouCoupon',
    'promotionsPageCreativesReachLocalBetaOnlyDescription1', 'advertiserClientLikeToReceiveEmailNotification',
    'extraChancesToWin', 'extraChanceInformation', 'preScheduleEmailDate', 'targetingZipcodes',
    'campaignIncludePaidTargetedEmails', 'promotionalDigitalAdsContest', 'uploader_allImageFiles',
    'promotionsPageCreativesReachLocalBetaOnlyDescription2', 'campaignIDOrCaseLabel', 'notesAndInstructions',
  ],
  promotionsPageCreativesReachLocalBetaOnlyDescription1: {
    'ui:widget': 'textLabel',
    'ui:style': {
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  promotionsPageCreativesReachLocalBetaOnlyDescription2: {
    'ui:widget': 'textLabel',
    'ui:style': {
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
    },
  },
  uploader_allImageFiles: {
    'ui:widget': (props) => (
      <FileUploader
        {...props}
        uploaderType={ProductTypes.PROMOTION_PAGE_AND_CREATIVES_UPLOADER}
        label={t('pages.promotionsPageCreativesReachLocalBetaOnly.uploadLabel')}
        totalAllowedFiles={10}
        imageTypes={['.pdf', '.jpg', '.jpeg', '.svg', '.png']}
        videoTypes={[]}
        documentTypes={[]}
        supportingText={t('pages.promotionsPageCreativesReachLocalBetaOnly.uploadHelpText')}
      />
    ),
    'ui:style': {
      marginTop: '20px',
    },
  },
  ...textInputFields,
  ...radioFields,
  ...textAreaFields,
  ...dateFields,
  ...dropDownFields,
  ...integerInputField,
  ...checkBoxesFields,
  ...urlTextFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const promotionsPageAndCreativesReachLocalBetaOnlyCustomValidate = (formData: any, errors, uiSchema) => {
  if (new Date(formData?.endDate).getTime() < new Date(formData?.startDate).getTime()) {
    errors.endDate.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.endDateErrorMessage'));
    errors.addError(true);
  }
  if (formData?.extraChancesToWin.includes(
    t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.watchVideo'),
    t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.multipleChoiceQuestions'),
    t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.linkToDownloadMobileApp'),
    t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.linkSponsorsWebsite'),
    t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinOptions.instagramFacebook'),
  )) {
    if (formData?.extraChanceInformation === '') {
      errors.extraChanceInformation
        .addError(t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChanceInformationErrorMessage'));
      errors.addError(true);
    }
  }
  if (new Date(formData?.preScheduleEmailDate).getTime() <= new Date(formData?.startDate).getTime()) {
    errors.preScheduleEmailDate.addError(t('pages.promotionsPageCreativesReachLocalBetaOnly.preScheduleEmailDateError'));
    errors.addError(true);
  }
  if (formData?.extraChancesToWin.length === 0) {
    errors.extraChancesToWin.addError(t('pages.promotionsPageCreativesReachLocalBetaOnly.extraChancesToWinErrorMessage'));
    errors.addError(true);
  }
  if (formData?.promotionalDigitalAdsContest.length === 0) {
    errors.promotionalDigitalAdsContest.addError(t('pages.promotionsPageCreativesReachLocalBetaOnly.promotionalDigitalAdsContestOptionsErrorMessage'));
    errors.addError(true);
  }
  if (formData?.campaignIncludePaidTargetedEmails === '') {
    errors.campaignIncludePaidTargetedEmails.addError(t('pages.promotionsPageCreativesReachLocalBetaOnly.campaignIncludePaidTargetedEmailsErrorMessage'));
    errors.addError(true);
  }
  // custom validation for required uploader field
  const files = formData.uploader_allImageFiles;
  if (files) {
    let formatFiles: any = [];
    if (typeof files === "string") {
      formatFiles = JSON.parse(files);
    }
    if (formatFiles.length === 0) {
      errors.uploader_allImageFiles.addError(t('pages.promotionsPageCreativesReachLocalBetaOnly.uploaderErrorMessage'));
      errors.addError(true);
    }
  }
  return errors;
};
