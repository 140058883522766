import i18n from 'i18n/i18n';
import { RJSFSchema } from "@rjsf/utils";

const { t } = i18n;

export const ChatBotSchema: RJSFSchema | any = (data) => ({
    title: t('pages.ausProduct.ausLiveChat.chatBot.title'),
    type: 'object',
    required: ['budget', 'initialCycles', 'delayedStartNeeded'],
    properties: {
        budget: {
            type: 'string',
            title: t('pages.ausProduct.ausLiveChat.chatBot.budgetLabel'),
            default: data?.budget || '',
            enum: [
                "",
                t('pages.ausProduct.ausLiveChat.chatBot.budgetOptions.standard'),
                t('pages.ausProduct.ausLiveChat.chatBot.budgetOptions.hippa'),
            ],
        },
        initialCycles: {
            type: 'string',
            title: t('pages.ausProduct.ausLiveChat.chatBot.initialCyclesLabel'),
            default: data?.initialCycles || '',
        },
        delayedStartNeeded: {
            type: 'string',
            title: t('pages.ausProduct.ausLiveChat.chatBot.delayedStartNeededLabel'),
            default: data?.delayedStartNeeded || '',
            enum: [
                "",
                t('pages.ausProduct.ausLiveChat.chatBot.delayedStartNeededOptions.yesDelay'),
                t('pages.ausProduct.ausLiveChat.chatBot.delayedStartNeededOptions.yesPending'),
                t('pages.ausProduct.ausLiveChat.chatBot.delayedStartNeededOptions.siteLaunchNo'),
            ],
        },
        leadDeliveryEmailAddress: {
            type: 'string',
            title: t('pages.ausProduct.ausLiveChat.chatBot.leadDeliveryEmailAddressessLabel'),
            description: t('pages.ausProduct.ausLiveChat.chatBot.leadDeliveryEmailAddressessDescription')
        },
        isCallConnectRequired: {
            type: 'string',
            title: t('pages.ausProduct.ausLiveChat.isCallConnectRequiredLabel'),
            default: data?.isCallConnectRequired || '',
            enum: [
                "",
                t('pages.ausProduct.ausLiveChat.yes'),
                t('pages.ausProduct.ausLiveChat.no'),
            ],
        },
    },
    dependencies: {
        delayedStartNeeded: {
            oneOf: [
                {
                    properties: {
                        delayedStartNeeded: {
                            enum: [t('pages.ausProduct.ausLiveChat.chatBot.delayedStartNeededOptions.yesDelay'), t('pages.ausProduct.ausLiveChat.chatBot.delayedStartNeededOptions.yesPending')],
                        },
                        requestStartDate: {
                            type: 'string',
                            title: t('pages.ausProduct.ausLiveChat.chatBot.requestedStartDateLabel'),
                            default: data?.requestStartDate || '',
                        },
                    },
                },
                {
                    properties: {
                        delayedStartNeeded: {
                            enum: [t('pages.ausProduct.ausLiveChat.chatBot.delayedStartNeededOptions.siteLaunchNo')],
                        },
                    },
                },
            ],
        },
    }
});


const dropdownFieldKeys = [
    'budget', 'delayedStartNeeded', 'isCallConnectRequired'
];
const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
    ...o,
    [key]: { 'ui:widget': 'dropdown' },
}), {});

const datePickerFieldKeys = [
    'requestStartDate',
];
const datePickerFieldFields = datePickerFieldKeys.reduce((o, key) => ({
    ...o,
    [key]: { 'ui:widget': 'datePickerField' },
}), {});

const textAreaFieldKeys = [
    'leadDeliveryEmailAddress',
];
const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
    ...o, [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const numericFieldKeys = [
    'initialCycles',
]
const numericFields = numericFieldKeys.reduce((o, key) => ({
    ...o,
    [key]: {'ui:widget': 'integerInputField'},
}), {});

export const ChatBotUISchema = {
    'ui:order': ['budget', 'initialCycles', 'delayedStartNeeded', 'requestStartDate', 'leadDeliveryEmailAddress', 'isCallConnectRequired'],
    ...dropdownFields,
    ...numericFields,
    ...datePickerFieldFields,
    ...textAreaFields
};

export const ChatBotCustomValidate = (formData: any, errors, uiSchema) => {
    if (formData?.budget === '') {
        errors.budget.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
        errors.addError(true);
    }
    if (formData?.initialCycles === '') {
        errors.initialCycles.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
        errors.addError(true);
    }
    if (formData?.delayedStartNeeded === '') {
        errors.delayedStartNeeded.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
        errors.addError(true);
    }
    return errors;
};
