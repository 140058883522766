import { t } from 'i18next';
import * as yup from 'yup';

const yupSchema = {
  designReferenceType: yup.string().required(),
  landingPageUrl: yup.string().url().required(t('pages.landing.custom.design.exampleLandingPageUrlRequired')),
  stockAssets: yup.string().required(),
  mockupAttachments: yup.array(yup.string()).required()
    .min(1, t('pages.landing.custom.design.mockupAttachmentsRequired')),
  otherReference: yup.string().required(t('pages.landing.custom.design.otherReferencesRequired')),
  otherAssets: yup.string().required(t('pages.landing.custom.design.otherAssetsRequired')),
};

export default {
  yupValidations: yupSchema,
  customValidations: {},
};
