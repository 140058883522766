import {
  Box,
  Button, Grid, Paper, Stack, Typography, Link as UrlLink,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  selectThemes, resetCurrent, deleteTheme, setImageUrl, getFilteredProducts,
} from 'services/themesSlice';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { getThemeFamilies, selectThemeFamilies } from 'services/adminSlice';
import { selectCurrentCountry } from 'services/topNavMenuSlice';
import { backgroundDefaultColor } from 'styles/common_styles';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

export const checkUrlProtocol = (url) => {
  if (url && url.substring(0, 8) !== 'https://') return `https://${url}`;
  return url;
};

const columns: GridColDef[] = [
  {
    field: 'imageUrl',
    headerName: 'Preview',
    width: 150,
    renderCell: (params) => (
      <img src={params.value} alt={params.row.title} style={{ width: '125px' }} />
    ),
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 300,
    renderCell: (params) => (
      <Stack spacing={0}>
        <Typography variant="subtitle2">
          {params.value}
        </Typography>
        <UrlLink href={checkUrlProtocol(params.row.url)}>
          {params.row.url}
        </UrlLink>
        <Typography variant="caption">
          {params.row.family}
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'marketCategory',
    headerName: 'Market Category',
    width: 200,
  },
  {
    field: 'productType',
    headerName: 'Product Type',
    width: 200,
  },
  {
    field: 'functionality',
    headerName: 'Functionality',
    width: 200,
  },
  {
    field: 'active',
    headerName: 'Active',
    width: 75,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    renderCell: (params) => {
      const [itemToDelete, setItemToDelete] = useState('');
      const selectedCountry = useSelector(selectCurrentCountry);
      const dispatch = useDispatch();
      return (
        <Box sx={{ width: '100%', maxWidth: 500 }}>
          <Link to={`${params.row.id}/edit`} style={{ marginRight: '10px' }}>
            <Button size="small">
              <Typography>Edit</Typography>
            </Button>
          </Link>
          <Button color="error"
            onClick={() => setItemToDelete(params.row.title)}
          >
            <Typography>Destroy</Typography>
          </Button>
          <DeleteConfirmationModal
            open={!!itemToDelete}
            onClose={() => setItemToDelete('')}
            onConfirm={async () => {
              await dispatch(deleteTheme(params.row.id));
              dispatch(dispatch(getFilteredProducts({
                product: '',
                country: selectedCountry.name,
                businessFunctionalityId: '',
                marketCategoryId: '',
                exclude: '',
              })));
            }}
            resourceName={itemToDelete} resourceType="Theme"
          />
        </Box>
      );
    },
  },
];

export default function AdminThemes() {
  const themes = useSelector(selectThemes);
  const dispatch = useDispatch();
  const themeFamilies = useSelector(selectThemeFamilies);
  const selectedCountry = useSelector(selectCurrentCountry);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    dispatch(resetCurrent({}));
    dispatch(getThemeFamilies({}));
  }, []);

  useEffect(() => {
    dispatch(dispatch(getFilteredProducts({
      product: '',
      country: selectedCountry.name,
      businessFunctionalityId: '',
      marketCategoryId: '',
      exclude: '',
    })));
  }, [selectedCountry]);

  const getFamilyName = (id) => {
    if (themeFamilies.length > 0) return themeFamilies.filter(family => family.id === id)[0].name;
    return '';
  };

  useEffect(() => {
    const tempRows = [];
    themes?.forEach(theme => {
      tempRows.push({
        id: theme.id,
        // eslint-disable-next-line max-len
        imageUrl: setImageUrl(theme.id, theme.screenshotImage, 'screenshot_image'),
        title: theme.title,
        family: `Family: ${theme.familyId ? getFamilyName(theme.familyId) : ''}`,
        url: theme.url,
        marketCategory: theme.marketCategory ? theme.marketCategory.name : 'n/a',
        productType: theme.product,
        functionality: theme.businessFunctionality ? theme.businessFunctionality.name : 'n/a',
        active: theme.active,
        slug: theme.slug,
      });
    });
    setRows(tempRows);
  }, [themes, themeFamilies]);

  return (
    <Paper elevation={0} sx={{ backgroundColor: backgroundDefaultColor }}>
      <Typography variant="h2">ReachSites Themes</Typography>
      <Grid container>
        <Grid item xs={12} marginTop={1}>
          <Link to="/admin/themes/new" style={{ marginRight: '10px' }}>
            <Button variant="contained">
              <Typography>New Theme</Typography>
            </Button>
          </Link>
          <Link to="/admin/dashboard">
            <Button variant="contained">
              <Typography>Admin Dashboard</Typography>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 10, width: '100%' }}>
        <DataGrid
          autoHeight
          rows={rows}
          rowHeight={100}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
        />
      </Box>
    </Paper>
  );
}
