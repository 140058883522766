import { useEffect, useState } from 'react';
import { locationsTypes } from 'layouts/StepperLayout';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderBusinessByOrderId, selectOrderBusiness } from 'services/businessSlice';
import premiumListingSchema from './PremiumListingsPhotos.schema';
import healthcareSchema from './PlHealthcarePhotos.schema';
import ListingsPhotos from './ListingsPhotos';

const HcDefaultValues = {
  standardLogo: [],
  squareLogo: [],
  locationPhotos: [],
  publishedVideos: [],
  providerPhoto: [],
};

const PlDefaultValues = (business) => ({
  standardLogo: [],
  applyStandardLogoToAll: business?.content.sameLogoAttachments || false,
  squareLogo: [],
  applySquareLogoToAll: business?.content.sameSquareLogoAttachments || false,
  locationPhotos: [],
  applyLocationPhotosToAll: business?.content.samePictureAttachments || false,
  publishedVideos: [],
  applyVideosToAll: business?.content.sameBusinessLocationVideos || false,
});

export default function ListingsPhotosPage(props: any) {
  const { locationsType } = props;
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const business = useSelector(selectOrderBusiness);
  const [schema, setSchema] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (orderId) dispatch(getOrderBusinessByOrderId(orderId));
  }, [orderId]);

  useEffect(() => {
    if (Object.keys(business?.content).length > 0) {
      if (locationsType === locationsTypes.PREMIUM_LISTINGS) {
        setDefaultValues(PlDefaultValues(business));
        setSchema(premiumListingSchema);
      }

      if (locationsType === locationsTypes.HEALTHCARE) {
        setDefaultValues(HcDefaultValues);
        setSchema(healthcareSchema);
      }
    }
  }, [locationsType, business]);

  useEffect(() => {
    setIsReady(!!schema && defaultValues && Object.keys(business?.content).length > 0);
  }, [schema, defaultValues, business]);

  return isReady && (
    <ListingsPhotos
      {...props}
      type={locationsType}
      schema={schema}
      defaultValues={defaultValues}
    />
  );
}
