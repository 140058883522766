import { Urls } from 'navigation/Urls';
import useProduct from 'hooks/useProduct';
import { StatusType } from 'models/StatusType';
import { GetPiQTrackingResponse, PiQWebsiteRequestContentType } from 'models/PiQWebsiteType';

import ContentLoader from 'components/contentLoader/ContentLoader';
import PiQTrackingForm from './PiQTrackingForm';

export default function PiQTracking() {
  const { product, onSubmit, onBack } = useProduct<PiQWebsiteRequestContentType,
  StatusType<GetPiQTrackingResponse>>(Urls.PiQTracking); // any

  return (
    <ContentLoader status={product.status} message={product.message}>
      <PiQTrackingForm
        product={product.content.data}
        onSubmit={onSubmit}
        onBack={onBack}
      />
    </ContentLoader>
  );
}
