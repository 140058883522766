import { Grid, Typography } from '@mui/material';

export default function TextLabel(props: any) {
  const marginTop = props?.schema?.margin === 0 ? 0 : 2;

  return (
    <Grid item xs={9} mt={marginTop}>
      <Typography sx={{fontFamily: 'Unify Sans'}} variant={props.schema.variant || ""}>
        {props.label}
        {props.schema && props.schema.rawDescription ? props.schema.rawDescription : ''}
      </Typography>
    </Grid>
  );
}
