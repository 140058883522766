/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import AxiosAPI from './common/axios';

export interface CartItem {
  deliveryEstimateWeeks?: string | number;
  id: number
  name: string
  productType: string
  estimateDelivery?: Date | number
  thumbnailUrl: string
  addOns?: string[]
  descriptionAbove?: string
  description?: string
  productName: string
  themeId: number
  themeName: string
  masterAddons?: any
  orderItem: any
  healthcare?: boolean
  offeringType?: any;
  saleType?: any;
}

export interface Addon {
  id: number,
  emailHosting: boolean,
  expandableMenus: boolean,
  hipaaForms: boolean,
  videoBackground: boolean,
  onlineBooking: boolean,
  paymentForms: boolean,
  popups: boolean,
  themeId: number
  orderItemId: number,
}

export interface CartState {
  cartItems: StatusType<CartItem[]>
  addons: StatusType<Addon>
  addonsUpdated: boolean
  thumbnailUrl: string
  themeId: number
}

const initialState: CartState = {
  cartItems: {
    status: 'idle',
    message: '',
    content: [],
  },
  addons: {
    status: 'idle',
    message: '',
    content: {} as Addon,
  },
  addonsUpdated: false,
  thumbnailUrl: '',
  themeId: null,
};

export const getCartItemList = createAsyncThunkCatchErrors(
  'cartItems/list/get',
  async (orderId: string) => {
    const response = await AxiosAPI.get(`/orders/cart/${orderId}`);
    return response.data;
  },
);

export const updateCartAddonsSelection = createAsyncThunkCatchErrors(
  'addons/cartSelection/put',
  async ({ orderItemId, resource } : { orderItemId: number, resource: any }) => {
    const response = await AxiosAPI.put(`/master-addons/${orderItemId}/select-addons`, resource);
    return response.data;
  },
);

export const removeCartItem = createAsyncThunkCatchErrors(
  'cart/items/delete',
  async (orderItemId: number) => {
    const response = await AxiosAPI.delete(`/orders/items/${orderItemId}`);
    return response.data;
  },
);

export const addCartItem = createAction<CartItem>('cart/items/add');
export const updateCartAddons = createAction<Addon>('cart/addons/update');

export const cartSlice = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(getCartItemList.fulfilled, (state, { payload }) => {
        state.cartItems.content = payload;
        if (payload.filter(obj => obj.masterAddons !== null).length > 0) {
          const [cartItem] = payload.filter(obj => obj.masterAddons !== null);
          state.addons.content = cartItem.masterAddons;
        }
      })
      .addCase(addCartItem, (state, action) => {
        if (state.cartItems.content.some(item => item.productType === action.payload.productType)) {
          state.cartItems.status = 'failed';
          state.cartItems.message = 'You can have a maximum of 1 website order in the cart';
        } else {
          state.cartItems.status = 'idle';
          state.cartItems.message = '';
          state.cartItems.content.push(action.payload);
        }
      })
      .addCase(removeCartItem.fulfilled, (state, { payload }) => {
        const updatedItems = [...state.cartItems.content];
        const goldSiteItems = updatedItems.filter(obj => (obj.orderItem.offeringType === 'GoldSite'
                                                            && obj.orderItem.bundleId !== null));
        if (goldSiteItems.length > 0 && goldSiteItems[0].orderItem.id === payload.id) {
          const bundleId = goldSiteItems.at(0)?.orderItem.bundleId;
          const unremovedItems = updatedItems.filter(obj => obj.orderItem.bundleId !== bundleId);
          state.cartItems.content = unremovedItems;
        } else {
          const index = updatedItems.findIndex(obj => obj.orderItem.id === payload.id);
          updatedItems.splice(index, 1);
          state.cartItems.content = updatedItems;
        }
        state.cartItems.status = 'idle';
        state.cartItems.message = '';
      })
      .addCase(updateCartAddonsSelection.fulfilled, (state) => {
        state.addonsUpdated = true;
      })
      .addCase(updateCartAddons, (state, { payload }) => {
        state.addons.content = { ...state.addons.content, ...payload };
      });
  },
);

export const selectCartItems = (state: RootState) => state.cart.cartItems;
export const selecteCartAddons = (state: RootState) => state.cart.addons.content;
export const selectedAddonsUpdated = (state: RootState) => state.cart.addonsUpdated;

export default cartSlice;
