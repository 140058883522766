import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  IQFormInput, IQFormTextArea, IQTheme, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';

import schema from 'pages/ecommerce/store-policies/shipping-policy/ShippingPolicy.schema';
import { t } from 'i18next';
import EcommerceType from 'models/EcommerceType';

import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { ShippingPolicyCheckboxes } from 'pages/ecommerce/store-policies/shipping-policy/ShippingPolicyElements';

import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import YesNoRadio from 'components/yesNo/YesNoRadio';

const StyledCheckboxContainer = styled(GridItem, { shouldForwardProp: prop => prop !== 'checkboxError' })<
{ className: string; checkboxError?: boolean; }>`
  & > div > span {
    display: none;
  }

  & > div > label {
    padding-right: 11px;
    border-color: ${props => (props.checkboxError ? props.theme.palette.error.main : 'transparent')};
    margin-left: 0;
  }
  
  & > div > label > span {
    padding-left: 0;
  }
  
  & > div > label > span:last-of-type {
    color: ${props => (props.checkboxError ? props.theme.palette.error.main : 'inherit')};
  }
`;

interface Props {
  onBack: () => void;
  onSubmit: (data :PolicyData) => void;
  product: EcommerceType;
}

export type PolicyData = {
  localPickup: boolean;
  domestic: boolean;
  international: boolean;
  shippingRates: string | null;
  servicesOffered: string | null;
  thirdPartyShipper: 'yes' | 'no' | string | null;
  thirdPartyShipping: string | null;
  shippingAdditionalNotes: string | null;
};

export default function ShippingPolicyForm({ onBack, onSubmit: onFormSubmit, product } :Props) {
  const [checkboxError, setCheckboxError] = useState(false);

  const methods = useForm<PolicyData>({
    mode: 'all',
    defaultValues: {
      localPickup: product?.localPickup || false,
      domestic: product?.domestic || false,
      international: product?.international || false,
      shippingAdditionalNotes: product?.shippingAdditionalNotes || '',
      thirdPartyShipper: product?.thirdPartyShipper || null,
      servicesOffered: product?.servicesOffered || '',
      shippingRates: product?.shippingRates || '',
      thirdPartyShipping: product?.thirdPartyShipping || '',
    },
  });
  const {
    handleSubmit,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful, dirtyFields, touchedFields,
    },
    watch,
  } = methods;

  const {
    thirdPartyShipper, domestic, international, localPickup,
  } = watch();

  const onSubmit = (data: PolicyData) => {
    const dataToSubmit = data;

    if (thirdPartyShipper === 'no') {
      dataToSubmit.thirdPartyShipping = '';
    }

    onFormSubmit(dataToSubmit);
  };

  const validCheckboxes = domestic || international || localPickup;

  useEffect(() => {
    const { domestic: domesticDirty, international: internationalDirty, localPickup: localPickupDirty } = dirtyFields;
    const {
      domestic: domesticTouched,
      international: internationalTouched,
      localPickup: localPickupTouched,
    } = touchedFields;

    const areFieldsTouched = domesticTouched || internationalTouched || localPickupTouched;
    const areFieldsDirty = domesticDirty || internationalDirty || localPickupDirty;

    if ((areFieldsDirty || areFieldsTouched) && !validCheckboxes) {
      setCheckboxError(true);
    }

    return () => setCheckboxError(false);
  }, [domestic, international, localPickup]);

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <DefaultPageLayout
          disableContinue={!isValid || !validCheckboxes}
          onBack={onBack}
          header={t('pages.ecommerce.shipping.heading')}
          onContinue={handleSubmit(onSubmit)}
        >
          <GridContainer>
            <StyledCheckboxContainer className="checkbox-container" checkboxError={checkboxError}>
              <ShippingPolicyCheckboxes
                heading={t('pages.ecommerce.shipping.checkboxContainerLabel')}
                hasError={checkboxError}
                errorMessage={t('pages.ecommerce.shipping.checkboxDeselectError')}
              />
            </StyledCheckboxContainer>
            <GridItem>
              <IQFormTextArea
                id="shippingRates"
                name="shippingRates"
                labelText={t('pages.ecommerce.shipping.shippingRates')}
                fontLabelWeight="600"
                rowCount={2}
                fullWidth
              />
            </GridItem>
            <GridItem>
              <IQFormTextArea
                id="servicesOffered"
                name="servicesOffered"
                labelText={t('pages.ecommerce.shipping.servicesOffered')}
                fontLabelWeight="600"
                rowCount={2}
                fullWidth
              />
            </GridItem>
            <YesNoRadio
              label={t('pages.ecommerce.shipping.integrateThirdPartyShipping')}
              name="thirdPartyShipper"
              shouldClearValues={false}
            >
              <GridItem>
                <IQFormInput
                  id="third-party-shipping"
                  labelText={t('pages.ecommerce.shipping.thirdPartyShipping')}
                  fontLabelWeight="600"
                  name="thirdPartyShipping"
                  theme={IQTheme}
                  fullWidth
                />
              </GridItem>
            </YesNoRadio>
            <GridItem>
              <IQFormTextArea
                name="shippingAdditionalNotes"
                id="shippingAdditionalNotes"
                labelText={t('pages.ecommerce.shipping.additionalNotes')}
                fontLabelWeight="600"
                fullWidth
              />
            </GridItem>
          </GridContainer>
        </DefaultPageLayout>
        {/* show the dialog if we changed the field and didn't submit the changes */}
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
