import { FormProvider, useForm } from 'react-hook-form';
import { IQFormTextArea, IQTheme, ValidationProvider } from '@gannettdigital/shared-react-components';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import YesNoRadio from 'components/yesNo/YesNoRadio';
import CoFileUploader, { OnlyFileUploader } from 'components/fileUploader/CoFileUploader';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import { ProductTypes } from 'services/fileUploaderSlice';
import EcommerceType from 'models/EcommerceType';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import React from 'react';
import NeSelectChip from 'components/chips/NeSelectChip';
import { selectActiveProductId } from 'services/navigationSlice';
import { useSelector } from 'react-redux';
import schema from './Returns.schema';

interface Props {
  product: EcommerceType;
  onSubmit: (data) => any;
  onBack: () => any;
}

const megabyte = 1_000_000;

const fileUploaderProps :OnlyFileUploader['fileUploaderOptions'] = {
  label: 'Files',
  name: 'files',
  showComments: false,
  showCheckboxes: false,
  maxFileSize: 256 * megabyte,
  imageTypes: ['.jpg', '.jpeg', '.png', '.pdf'],
  videoTypes: [],
  documentTypes: [],
  directory: 'ecommerce-returns-refunds',
  showRecommendedImageText: false,
  uploaderType: ProductTypes.RETURNS_REFUNDS,
  supportingText: 'Upload refund or return policy.',
};

export default function ReturnsForm(props: Props) {
  const websiteId = useSelector(selectActiveProductId);
  const { product, onSubmit, onBack } = props;

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      returnRefundPolicy: product?.returnRefundPolicy || '',
      excludeIneligibleProducts: product?.excludeIneligibleProducts || [],
      businessPaysReturnShipping: product?.businessPaysReturnShipping || '',
      additionalNotesReturns: product?.additionalNotesReturns || '',
    },
  });

  const {
    handleSubmit, formState: { isSubmitSuccessful, isSubmitting, isDirty },
  } = methods;

  return (
    <DefaultPageLayout header="Return and Refund Policy"
      onBack={onBack} onContinue={handleSubmit(onSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <GridContainer>
            <GridItem>
              <IQFormTextArea
                id="returnRefundPolicy"
                name="returnRefundPolicy"
                labelText="Return and Refund Policy"
                tooltipText="This include any relevant details
                  (i.e. number of days to return, requirements for return, etc.)"
                tooltipPaddingBottom={16}
                tooltipPlacement="top"
                fontLabelWeight="600"
                rowCount={2}
                fullWidth
              />
            </GridItem>
            <GridItem>
              <NeSelectChip label labelText="Excluded or Ineligible Products" items={[]}
                name="excludeIneligibleProducts" placeholder="Type and enter to add an item"
                values={product?.excludeIneligibleProducts} maxItems={0} freeSolo
                disableClear required={false} schema={schema}
                theme={IQTheme}
              />
            </GridItem>
            <GridItem>
              <YesNoRadio
                label="Business pays return shipping"
                name="businessPaysReturnShipping"
              />
            </GridItem>
            <GridItem>
              <CoFileUploader
                offeringType={WebsiteOfferingTypes.ECOMM}
                schema={schema}
                fileUploaderOptions={fileUploaderProps}
                showFileUploader
                productType={ProductTypes.RETURNS_REFUNDS}
                productId={websiteId.toString()}
                containerStyles={{
                  mt: '24px',
                  '.MuiList-root': { mt: '46px' },
                  '.MuiGrid-container': { alignItems: 'center' },
                  '.MuiListItem-gutters > .MuiGrid-container': { gap: '16px' },
                  '.MuiGrid-container > .MuiGrid-item': { width: '56px' },
                  '.MuiGrid-item img': { width: '56px', height: '56px' },
                }}
              />
            </GridItem>
            <GridItem>
              <IQFormTextArea
                id="additionalNotesReturns"
                name="additionalNotesReturns"
                labelText="Additional Notes"
                fontLabelWeight="600"
                rowCount={2}
                fullWidth
              />
            </GridItem>
          </GridContainer>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
