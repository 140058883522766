import i18n from 'i18n/i18n';
import { RJSFSchema } from '@rjsf/utils';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';
const { t } = i18n;

export const CampaignCreativeSchema: RJSFSchema | any = (data) => ({
    title: t('pages.ausProduct.ausPerformanceMax.campaignCreative.title'),
    type: 'object',
    required: ['requestedCreative', 'clientSocialChanelsUrlLink'],
    properties: {
        requestedCreative: {
            type: 'string',
            title: t('pages.ausProduct.ausPerformanceMax.campaignCreative.requestedCreativeLabel'),
            default: data?.requestedCreative || '',
            items: {
                enum: [
                    t('pages.ausProduct.ausPerformanceMax.campaignCreative.requestedCreativeOptions.yes'),
                    t('pages.ausProduct.ausPerformanceMax.campaignCreative.requestedCreativeOptions.no'),
                ],
            },
        },
        clientSocialChanelsUrlLink: {
            type: 'string',
            title: t('pages.ausProduct.ausPerformanceMax.campaignCreative.clientSocialChannelsUrlLinkLabel'),
            default: data?.clientSocialChanelsUrlLink || '',
            description: t('pages.ausProduct.ausPerformanceMax.campaignCreative.urlLinksToAccessCreativeSocialLabel')
        },
        finalUrl: {
            type: 'string',
            title: t('pages.ausProduct.ausPerformanceMax.campaignCreative.finalUrlLabel'),
            default: data?.finalUrl || '',
            description:  t('pages.ausProduct.ausPerformanceMax.campaignCreative.linkToRedirectUsersAfterAdsClickLabel')
        },
    },
    dependencies: {
        requestedCreative: {
            oneOf: [
                {
                    properties: {
                        requestedCreative: {
                            enum: [t('pages.ausProduct.ausPerformanceMax.campaignCreative.requestedCreativeOptions.yes')],
                        },
                        urlLinkToCreateFolder: {
                            type: 'string',
                            title: t('pages.ausProduct.ausPerformanceMax.campaignCreative.urlLinkToCreateFolderLabel'),
                            default: '',
                        },
                    },
                },
                {
                    properties: {
                        requestedCreative: {
                            enum: [t('pages.ausProduct.ausPerformanceMax.campaignCreative.requestedCreativeOptions.no')],
                        },
                        requestFromYourClient: {
                            type: 'string',
                            title: '',
                            description: t('pages.ausProduct.ausPerformanceMax.campaignCreative.requestFromYourClientLabel'),
                        },
                    },
                },
            ],
        },
    }
});


const urlTextFields = {
    urlLinkToCreateFolder: {},
    finalUrl: {},
};

Object.keys(urlTextFields).forEach((key) => {
    urlTextFields[key]['ui:widget'] = 'UrlTextWidget';
});

const radioFieldKeys = [
    'requestedCreative',
];

const radioFields = radioFieldKeys.reduce((o, key) => ({
    ...o, [key]: { 'ui:widget': 'radioButton' },
}), {});

const textLabelFieldKeys = [
    'requestFromYourClient'
];

const textLabelFields = textLabelFieldKeys.reduce((o, key) => ({
    ...o,
    [key]: { 'ui:widget': 'textLabel' },
}), {});

const textAreaFieldKeys = [
    'clientSocialChanelsUrlLink',
];

const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
    ...o, [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

export const CampaignCreativeUISchema = {
    'ui:order': ['requestedCreative', 'urlLinkToCreateFolder', 'requestFromYourClient', 'clientSocialChanelsUrlLink', 'finalUrl'],
    ...urlTextFields,
    ...radioFields,
    ...textLabelFields,
    ...textAreaFields
};

export const CampaignCreativeCustomValidate = (formData: any, errors, uiSchema) => {
    if(formData?.requestedCreative === t('pages.ausProduct.ausPerformanceMax.campaignCreative.requestedCreativeOptions.yes')){
        if (formData?.urlLinkToCreateFolder === '' || !validateUrl(formData?.urlLinkToCreateFolder)) {
            errors.urlLinkToCreateFolder.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
            errors.addError(true);
        }
    }
    if (formData?.requestedCreative === '') {
        errors.requestedCreative.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
        errors.addError(true);
    }
    if (formData?.finalUrl === '' || !validateUrl(formData?.finalUrl)) {
        errors.finalUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
        errors.addError(true);
    }
    if (formData?.clientSocialChanelsUrlLink === '') {
        errors.clientSocialChanelsUrlLink.addError(t('pages.ausProduct.ausPerformanceMax.requiredError'));
        errors.addError(true);
    }

    return errors;
};
