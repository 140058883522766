import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSitePages, selectSitePages } from 'services/sitePagesSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import {
  clearSitePagesCompleted, getWebsite,
  putWebsiteSitePagesNotes,
  selectSitePagesCompleted,
  selectWebsites,
} from 'services/websitesSlice';
import { Urls } from 'navigation/Urls';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import SitePagesForm from './SitePagesForm';

export default function SitePages() {
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const history: any = useLocation();
  const dispatch = useDispatch();
  const website = useSelector(selectWebsites);
  const sitePagesContent = useSelector(selectSitePages);
  const pageCompleted = useSelector(selectSitePagesCompleted);
  const navigate = useNavigationHandler();
  const topNav = useTopnavMenu(Urls.SitePages);

  const onBack = () => {
    navigate.to(topNav.previousStep());
  };

  const onSubmit = (content) => {
    dispatch(putWebsiteSitePagesNotes({ websiteId, content, offeringType }));
  };

  useEffect(() => {
    dispatch(getSitePages({ websiteId, offeringType }));
    if (!website?.content?.id) dispatch(getWebsite({ websiteId, offeringType }));
  }, [websiteId]);

  useEffect(() => {
    if (pageCompleted) {
      if (history.state?.previousPath.includes('overview')) {
        navigate.to(Urls.Overview);
      } else navigate.to(topNav.nextStep());
      dispatch(clearSitePagesCompleted());
    }
  }, [pageCompleted]);

  return (
    <SitePagesForm sitePages={sitePagesContent} website={website}
      onBack={onBack} onSubmit={onSubmit}
    />
  );
}
