import { FormProvider, useForm } from 'react-hook-form';
import {
  IQFormInput, IQTheme, IQThemeV2, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import i18n from 'i18n/i18n';
import { Grid } from '@mui/material';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import PiQWebsiteType from 'models/PiQWebsiteType';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NePhoneInput from 'components/NePhoneInputContainer/IQPhoneInputContainer';
import schema from './PiQTracking.schema';

const { t } = i18n;

interface Props {
  product: PiQWebsiteType;
  onSubmit: (data: PiQTrackingFormProps) => void;
  onBack: () => void;
}

export type PiQTrackingFormProps = {
  callCapTrackingPhoneNumber: string;
  formFillLeadEmailAddress: string;
  phoneRequired: boolean;
};

export default function PiQTrackingForm({ product, onSubmit, onBack }: Props) {
  const methods = useForm<PiQTrackingFormProps>({
    mode: 'all',
    defaultValues: {
      callCapTrackingPhoneNumber: product?.callCapTrackingPhoneNumber || '',
      formFillLeadEmailAddress: product?.formFillLeadEmailAddress || '',
    },
  });

  const handleFormSubmit = (data: PiQTrackingFormProps) => onSubmit(data);

  const {
    handleSubmit,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={onBack}
      header={t('pages.piq.tracking.header')}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid item mb={3} xs={6}>
            <NePhoneInput
              name="callCapTrackingPhoneNumber"
              defaultValue=""
              country="US"
              hasType={false}
              countryCallingCodeEditable={false}
              international
              phoneLabelOptions={{
                tooltipText: '',
                labelText: t('pages.piq.tracking.phoneLabel'),
                hasError: true,
                theme: IQThemeV2,
                required: true,
                errorMessage: t('pages.piq.tracking.phoneError'),
              }}
            />
          </Grid>
          <Grid container
            xs={6}
            sx={{ label: { pb: '7px' }, marginTop: '25px' }}
          >
            <IQFormInput
              id="formFillLeadEmailAddress"
              theme={IQTheme}
              labelText={t('pages.piq.tracking.emailLabel')}
              fontLabelWeight="600"
              name="formFillLeadEmailAddress"
              fullWidth
              schema={schema}
              adornmentIcon={<MailOutlineIcon />}
            />
          </Grid>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
