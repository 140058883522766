import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import {Grid, Typography} from "@mui/material";
import React from "react";

const {t} = i18n;

export const WebsitePrivacyPolicySchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.websitePrivacyPolicy.title'),
  type: 'object',
  required: [
    'websitePrivacyPolicy',
  ],
  properties: {
    websitePrivacyPolicy: {
      type: 'string',
      title: t('pages.ausProduct.websitePrivacyPolicy.websitePrivacyPolicyLabel'),
      default: data?.websitePrivacyPolicy || '',
    },
    privacyPolicyAnchorText: {
      type: 'string',
    },
  },
});

const inputFieldKeys = [
  'websitePrivacyPolicy'
];

const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});
export const WebsitePrivacyPolicyUISchema = {
  'ui:order': [
    'websitePrivacyPolicy',
    'privacyPolicyAnchorText'
  ],

  privacyPolicyAnchorText: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography fontSize={14}>
            {t('pages.ausProduct.websitePrivacyPolicy.privacyPolicyAnchorTextBeforeLinkNz')}
            <a href={t('pages.ausProduct.websitePrivacyPolicy.privacyPolicyAnchorTextLinAus')} target="_blank"
               rel="noreferrer">
              <Typography component="span" color="primary" fontSize={14}>
                {t('pages.ausProduct.websitePrivacyPolicy.privacyPolicyAnchorTextLinAusLabel')}
              </Typography>
            </a>
            {t('pages.ausProduct.websitePrivacyPolicy.privacyPolicyAnchorTextAfterLinkNz')}
            <a href={t('pages.ausProduct.websitePrivacyPolicy.privacyPolicyAnchorTextLinkNz')} target="_blank"
               rel="noreferrer">
              <Typography component="span" color="primary" fontSize={14}>
                {t('pages.ausProduct.websitePrivacyPolicy.privacyPolicyAnchorTextLinkNzLabel')}
              </Typography>
            </a>
            )
          </Typography>
        </Grid>
    ),
  },
  ...inputFields,
};

export const WebsitePrivacyPolicyCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.websitePrivacyPolicy === '') {
    errors.websitePrivacyPolicy.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
