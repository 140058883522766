import {
  Box, Collapse, FormControlLabel, IconButton, Radio, RadioGroup, Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import { SMMOptions, XMOOptions, XMOUpgradeOptions } from '../ThemeConstants';

const radioStyles = {
  '&.Mui-disabled.Mui-checked': {
    color: IQThemeV2.palette.primary.main,
  },
  '&.Mui-disabled:not(.Mui-checked) ~.MuiTypography-root': {
    color: IQThemeV2.palette.action.disabledBackground,
  },
};

const EnabledItem = ({ text }: { text: string }) => (
  <Box display="flex" flexDirection="row" alignItems="center"
    marginY={1}
  >
    <DoneIcon htmlColor="#00C806" />
    <Typography marginLeft={1}>{text}</Typography>
  </Box>
);

// PL/HC
const healthcareFeatures = [
  'Add up to 9 Location Listings and Providers Listings',
  'Providers submitted to Doctor.com suite of directories',
];

const healthcareRequirements = ['Providers require NPI number'];

export const renderPL = (props) => props.theme.product === 'premium listing' && (
  <RadioGroup name="bundleId" defaultValue={props.isHealthcare ? 2 : 1}>
    <FormControlLabel
      onClick={() => { if (!props.opportunityId) { props.setValue('bundleId', 1); props.setExpandedIndex(1); } }}
      key={1} value={1} control={<Radio disabled={!!props.opportunityId} sx={radioStyles} />}
      label={(
        <Typography fontSize="20px" fontWeight="500"
          display="flex" alignItems="center" justifyContent="space-between"
          flexGrow={1} sx={{ color: '#142160' }}
        >
          Premium Listings 1-9
        </Typography>
          )}
    />
    {(props.selectedCountry.name === 'USA')
    && (
    <FormControlLabel
      onClick={() => { if (!props.opportunityId) { props.setValue('bundleId', 2); props.setExpandedIndex(2); } }}
      key={2} value={2} control={<Radio disabled={!!props.opportunityId} sx={radioStyles} />}
      label={(
        <Typography fontSize="20px" fontWeight="500"
          display="flex" alignItems="center" justifyContent="space-between"
          flexGrow={1} sx={{ color: '#142160' }}
        >
          Healthcare 1-9
          <IconButton sx={{ color: `${IQThemeV2.palette.primary.main}` }} disabled={!!props.opportunityId}>
            { props.expandedIndex === 2 ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
          </IconButton>
        </Typography>
          )}
    />
    )}
    <Collapse in={props.expandedIndex === 2} sx={{ paddingLeft: 4 }}>
      {props.healthcareFeatures?.length > 0 && (
      <>
        <Typography marginTop={2} fontWeight="bold">Features</Typography>
        {healthcareFeatures.map(feature => (
          <Typography key={feature} marginTop={1}>{feature}</Typography>
        ))}
      </>
      )}
      {healthcareRequirements?.length > 0 && (
      <>
        <Typography marginTop={2} fontWeight="bold">Requirements</Typography>
        {healthcareRequirements.map(requirement => (
          <Typography key={requirement} marginTop={1}>{requirement}</Typography>
        ))}
      </>
      )}
    </Collapse>
  </RadioGroup>
);

// Radio Options
const renderOptions = (opt, props, isXmo = false) => (
  <>
    <FormControlLabel
      onClick={() => {
        if (!props.opportunityId || isXmo) {
          props.reset({
            bundleId: opt.value,
            addons: [...Array(10)].map(() => ({ selected: false })),
          });
          if (opt?.included) props.setExpandedIndex(opt.value);
        }
      }}
      key={opt.value} value={opt.value} control={
        <Radio disabled={(!!props.opportunityId && !isXmo)} sx={props.radioStyles} />
      }
      label={(
        <Typography fontSize="20px" fontWeight="500"
          display="flex" alignItems="center" justifyContent="space-between"
          flexGrow={1} sx={{ color: '#142160' }}
        >
          {opt.label}
          {opt?.included && (
            <IconButton sx={{ color: `${IQThemeV2.palette.primary.main}` }} disabled={!!props.opportunityId}>
              {props.expandedIndex === opt.value ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )}
        </Typography>
    )}
    />
    <Collapse in={props.expandedIndex === opt.value} sx={{ paddingLeft: 4 }}>
      <Typography marginTop={2} fontWeight="bold">Included</Typography>
      {opt?.included?.map(feature => (<EnabledItem text={feature} key={feature} />))}

      {opt?.plus?.length > 0 && (
        <>
          <Typography marginTop={2} fontWeight="bold">PLUS</Typography>
          {opt.plus.map(feature => (<EnabledItem text={feature} key={feature} />))}
        </>
      )}
    </Collapse>
  </>
);

export const renderSMM = (props) => props.theme.product === 'smm-standard' && (
  <RadioGroup name="bundleId" defaultValue={props.smmRadioValue || 1}>
    {SMMOptions.map(opt => renderOptions(opt, props))}
  </RadioGroup>
);

export const renderXMO = (props) => props.theme.product === 'xmo' && (
  <RadioGroup name="bundleId" defaultValue={props.xmoRadioValue || 1}>
    {XMOOptions.map(opt => renderOptions(opt, props, true))}
  </RadioGroup>
);

export const renderXMOUpgrade = (props) => props.theme.product === 'xmo-upgrade' && (
  <RadioGroup name="bundleId" defaultValue={props.xmoRadioValue || 1}>
    {XMOUpgradeOptions.map(opt => renderOptions(opt, props, true))}
  </RadioGroup>
);
