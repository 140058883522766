/* eslint-disable import/no-extraneous-dependencies */
import {IQThemeV2} from '@gannettdigital/shared-react-components';
import {FormHelperText, Grid, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import moment from 'moment';
import ErrorText from "components/errorText/ErrorText";

export default function DatePickerField(props: any) {
  const [isTouched, setIsTouched] = useState(!!props.value);
  const hasError = props?.rawErrors?.length > 0 && isTouched

  return (
    <Grid item xs={9} mt={3}>
      <Typography
        fontWeight={600}
        mb={1}
        sx={{
          color: hasError
            ? IQThemeV2.palette.error.main
            : IQThemeV2.palette.text.primary,
        }}
      >
        {props.label}
      </Typography>
      <TextField
        error={hasError}
        id={props.id}
        type="date"
        required={props.required}
        disabled={props.disabled || props.readonly}
        value={props.value}
        onChange={(e) => {
          setIsTouched(true);
          props.onChange(e.target.value);
        }}
        inputProps={{ min: `${moment().format('YYYY-MM-DD')}` }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {(props.placeholder && (props?.rawErrors?.length === 0 || !isTouched)) && (
          <FormHelperText error={false}>{props.placeholder}</FormHelperText>
      )}
      <ErrorText
          hasError={hasError}
          errorText={props.schema.customError
              ? (props.schema.customErrorMessage)
              : (props.errorMessage) || props?.rawErrors?.[0]}
      />
    </Grid>
  );
}
