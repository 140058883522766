import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMarketCategoryById, selectCurrentMarketCategory, updateMarketCategory,
} from 'services/adminSlice';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import CategoryForm from './CategoryForm';

export default function EditCategory() {
  const currentCategory = useSelector(selectCurrentMarketCategory);
  const { categoryId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMarketCategoryById(parseInt(categoryId, 10)));
  }, [categoryId]);

  const updateCategory = async (data) => {
    await dispatch(await updateMarketCategory({
      name: data.name,
      notes: data.notes,
      active: data.active,
      id: parseInt(categoryId, 10),
    }));
    navigate('/admin/marketCategories');
  };

  return (
    <>
      <Typography variant="h2">Edit Market Category</Typography>
      <CategoryForm onSubmit={updateCategory} category={currentCategory} />
    </>
  );
}
