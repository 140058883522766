/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import {
  BusinessFunctionality, MarketCategory, ThemeFamily,
  ThemeFeature,
} from './adminSlice';
import AxiosAPI from './common/axios';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { RootState } from './redux/store';

export interface ThemeVariant {
  name: string
  imageUrl: string
  previewUrl: string
  url: string
}

export interface ThemeBenefit {
  title: string
  description: string
}

export interface Collateral {
  label: string
  collateralBlurb: string
  uploadedFile: string
  themeId?: number | string
  id?: number | string
}

interface Country {
  id: number
  name: string
}

export interface Theme {
  id: number
  title: string
  imageUrl?: string
  screenshotImage?: string
  compositeScreenshot?: string
  themePreview?: string
  sortId?: number
  url?: string
  family?: ThemeFamily
  familyId?: number
  features?: ThemeFeature[]
  benefits?: ThemeBenefit[]
  functionality?: BusinessFunctionality
  businessFunctionalityId?: number
  product?: string
  marketCategoryId?: number
  marketCategory?: MarketCategory,
  serviceType?: number
  variants?: ThemeVariant[]
  description?: string
  detailsAboveTheFold?: string
  detailsBelowTheFold?: string
  pictures?: string[]
  collaterals?: Collateral[]
  designStyle?: string
  color?: string
  notes?: string
  callToAction?: string
  active?: boolean
  featured?: boolean
  countryAvailability?: Country[]
  tier?: string
  hasVisibleTier?: boolean
  businessFunctionality?: any
  slug?: string
}

export interface Addon {
  id: number
  name: string
  description?: string
  exampleUrl?: string
  imageUrl?: string
  productType?: string
}

export interface Product {
  id: number
  name: string
  description?: string
  exampleUrl?: string
  imageUrl?: string
  productType?: string
}

export interface Bundle {
  id: number
  name: string
  addons: Addon[]
  products: Product[]
  features: string[]
  requirements: string[]
}

export enum ThemeFilterType {
  PRODUCT_TYPE,
  MARKET_CATEGORY,
  SERVICE_TYPE,
}

export interface ThemeFilter {
  type: ThemeFilterType
  value: string | string[] | number
}

export interface OrderDeliveryDetails {
  estimatedDelivery: number | Date
  startTime: number | Date
  developmentTime: number
}

export interface ThemesSliceState {
  filter: StatusType<ThemeFilter>
  deliveryDetails: StatusType<OrderDeliveryDetails>
  themesList: StatusType<Theme[]>
  currentTheme: StatusType<Theme>
  currentThemeBenefits: StatusType<any>
  currentThemeCollaterals: StatusType<any>
  currentThemeFeatures: StatusType<any>
  currentThemePictures: StatusType<any>
  currentThemeVariants: StatusType<Theme[]>
  themeBundles: StatusType<Bundle[]>
  themeAddons: StatusType<Addon[]>
  allAddons: StatusType<Addon[]>
  menuThemeTypes: StatusType<any[]>
}

const allAddonsData = [
  {
    id: 1,
    name: 'Video Background',
    description: 'Add a video background to the hero section of a website\'s homepage.',
    exampleUrl: 'https://hagar3.localiq.site/',
    imageUrl: 'video.svg',
    productType: 'add-ons',
  },
  {
    id: 3,
    name: 'Expandable Menus',
    // eslint-disable-next-line max-len
    description: 'Expandable menus support images, tabs and columns to improve the visual experience and organize pages on large sites.',
    exampleUrl: 'https://cambridge4.localiq.site/',
    imageUrl: 'menu-icon.svg',
    productType: 'add-ons',
  },
  {
    id: 5,
    name: 'Payment Forms',
    // eslint-disable-next-line max-len
    description: 'Allows businesses to collect simple payments online, such as paying for an invoice or a basic service. Integrates with Stripe, Authorize.net or Paypal Payments Pro.',
    exampleUrl: 'https://parker4.localiq.site/appointment-payment/',
    imageUrl: 'payment-icon.svg',
    productType: 'add-ons',
  },
  {
    id: 7,
    name: 'Pop-up',
    // eslint-disable-next-line max-len
    description: 'Provide a pop-up window for users to be notified of promotions and events, click on call-to-actions, or enter information to generate leads.',
    exampleUrl: 'https://euclid3.localiq.site/pop-up/',
    imageUrl: '',
    productType: 'add-ons',
  },
];

export const assetsBasePath = 'https://reachsites-client-assets-prd.s3-us-west-2.amazonaws.com/uploads/theme';
// eslint-disable-next-line max-len
export const collateralsBasePath = 'https://reachsites-client-assets-prd.s3-us-west-2.amazonaws.com/uploads/theme_collateral/uploaded_file';

const addWeeks = (date, weeks) => {
  const dateCopy = new Date(date);
  dateCopy.setDate(dateCopy.getDate() + 7 * weeks);
  return dateCopy.getTime();
};

export const removeQuotes = (string) => {
  if (string) {
    if (string[0] === '"') return JSON.parse(string).replace('---\n- ', '');
    return string;
  }
  return '';
};

export const setImageUrl = (id, imageString, type) => {
  if (imageString) {
    if (imageString.includes('http')) return imageString;
    return `${assetsBasePath}/${type}/${id}/${removeQuotes(imageString)}`;
  }
  return '/assets/cb-image-logomark.jpg';
};

const removeExtraCardsAndSpaces = (string) => string
  .replaceAll('---\n- ', '')
  .replaceAll('\n- ', ',')
  .replaceAll('\n', '');

export const convertPreviewString = (string) => {
  if (string.includes('\"---\\n- ')) {
    const firstSplit = string.split('\"---\\n- ');
    const [secondSplit] = firstSplit[1].split('\\n\'');
    const [result] = secondSplit.split('\\n\"');
    return result.split(',');
  }
  if (string.substring(0, 8) === 'https://') return string.split(',');
  if (string[0] === '"') return removeExtraCardsAndSpaces(JSON.parse(string)).split(',');
  if (string[0] === '-') return removeExtraCardsAndSpaces(string).split(',');
  return null;
};

export const setCollateralUrl = (id, str) => {
  if (str) {
    if (str.includes('http')) return str;
    return `${collateralsBasePath}/${id}/${removeQuotes(str)}`;
  }
  return null;
};

const initialState: ThemesSliceState = {
  filter: {
    status: 'idle',
    message: '',
    content: {
      type: ThemeFilterType.PRODUCT_TYPE,
      value: '',
    },
  },
  deliveryDetails: {
    status: 'idle',
    message: '',
    content: {
      startTime: new Date().getTime(),
      estimatedDelivery: addWeeks(new Date(), 4),
      developmentTime: 4,
    },
  },
  themesList: {
    status: 'idle',
    message: '',
    content: [],
  },
  currentTheme: {
    status: 'idle',
    message: '',
    content: {} as Theme,
  },
  currentThemeBenefits: {
    status: 'idle',
    message: '',
    content: {
      idList: [],
      benefitsContent: [],
    },
  },
  currentThemeCollaterals: {
    status: 'idle',
    message: '',
    content: {},
  },
  currentThemeFeatures: {
    status: 'idle',
    message: '',
    content: {
      idList: [],
      featuresContent: [],
    },
  },
  currentThemePictures: {
    status: 'idle',
    message: '',
    content: [],
  },
  currentThemeVariants: {
    status: 'idle',
    message: '',
    content: [],
  },
  themeBundles: {
    status: 'idle',
    message: '',
    content: [],
  },
  themeAddons: {
    status: 'idle',
    message: '',
    content: [],
  },
  allAddons: {
    status: 'idle',
    message: '',
    content: allAddonsData,
  },
  menuThemeTypes: {
    status: 'idle',
    message: '',
    content: [],
  },
};

const addWeeksToDate = (dateObj, numberOfWeeks) => {
  dateObj.setDate(dateObj.getDate() + numberOfWeeks * 7);
  return dateObj.getTime();
};

export const getFilteredProducts = createAsyncThunkCatchErrors(
  'themes/filteredProducts/list',
  async ({
    product, country, businessFunctionalityId, marketCategoryId, exclude, active,
  } : {
    product: string,
    country: string,
    businessFunctionalityId: number,
    marketCategoryId: number,
    exclude: string,
    active: boolean,
  }) => {
    // eslint-disable-next-line max-len
    const response = await AxiosAPI.get(`/themes/product?product=${product}&country3Code=${country}&businessFunctionalityId=${businessFunctionalityId}&marketCategoryId=${marketCategoryId}&excludeProduct=${exclude}${active !== undefined && `&active=${active}`}`);
    const results = { content: response.data };
    return results;
  },
);

export const getMenuProducts = createAsyncThunkCatchErrors(
  'themes/menuProducts/list',
  async (country: string) => {
    // eslint-disable-next-line max-len
    const response = await AxiosAPI.get(`/themes/product?product=&country3Code=${country}&excludeProduct=add-ons&active=true`);
    const results = { content: response.data };
    return results;
  },
);

export const getThemesByFamily = createAsyncThunkCatchErrors(
  'themes/listByFamily',
  async (familyId: number) => {
    const response = await AxiosAPI.get(`/themes/product?familyId=${familyId}`);
    const results = { content: response.data };
    return results;
  },
);

export const createTheme = createAsyncThunkCatchErrors(
  'themes/create',
  async (request: any) => AxiosAPI.post('/themes', request),
);

export const updateTheme = createAsyncThunkCatchErrors(
  'themes/update',
  async (request: any) => AxiosAPI.put('/themes', request),
);

export const deleteTheme = createAsyncThunkCatchErrors(
  'themes/delete',
  async (id: number) => AxiosAPI.delete(`/themes/${id}`),
);

export const getCapacityHeaderByProductType = createAsyncThunkCatchErrors(
  'themes/capacityHeader/get',
  async (productType: string) => {
    const response = await AxiosAPI.get(`/capacity-header/product/${productType}`);
    return response.data;
  },
);

export const getThemeById = createAsyncThunkCatchErrors(
  'themes/getById',
  async (themeId: number) => {
    const response = await AxiosAPI.get(`/themes/${themeId}`);
    return response.data;
  },
);

// collaterals
export const getThemeCollaterals = createAsyncThunkCatchErrors(
  'themes/collaterals/get',
  async (themeId: number) => {
    const response = await AxiosAPI.get(`/themes/${themeId}/collaterals`);
    return response.data;
  },
);

export const deleteThemeCollateralById = createAsyncThunkCatchErrors(
  'themes/collateral/delete',
  async (id: number) => AxiosAPI.delete(`/themecollaterals/${id}`),
);

export const updateThemeCollateral = createAsyncThunkCatchErrors(
  'themes/collateral/update',
  async (content: any) => AxiosAPI.put('/themecollaterals', content),
);

export const createThemeCollateral = createAsyncThunkCatchErrors(
  'themes/collateral/create',
  async (content: any) => AxiosAPI.post('/themecollaterals', content),
);

// features
export const getThemeFeaturesId = createAsyncThunkCatchErrors(
  'themes/featuresId/get',
  async (themeId: number) => {
    const response = await AxiosAPI.get(`/themes/${themeId}/features`);
    return response.data;
  },
);

export const getThemeFeature = createAsyncThunkCatchErrors(
  'themes/feature/get',
  async (featureId: number) => {
    const response = await AxiosAPI.get(`/themefeatures/${featureId}`);
    return response.data;
  },
);

export const postThemeFeatures = createAsyncThunkCatchErrors(
  'theme/features/create',
  // eslint-disable-next-line max-len
  async ({ themeId, content } : { themeId: number, content: any }) => AxiosAPI.post(`/themes/${themeId}/features`, content),
);

// benefits
export const getThemeBenefitsId = createAsyncThunkCatchErrors(
  'themes/benefitsId/get',
  async (themeId: number) => {
    const response = await AxiosAPI.get(`/themes/${themeId}/benefits`);
    return response.data;
  },
);

export const getThemeBenefit = createAsyncThunkCatchErrors(
  'theme/benefit/get',
  async (benefitId: number) => {
    const response = await AxiosAPI.get(`/themebenefits/${benefitId}`);
    return response.data;
  },
);

export const postThemeBenefits = createAsyncThunkCatchErrors(
  'theme/benefits/create',
  // eslint-disable-next-line max-len
  async ({ themeId, content } : { themeId: number, content: any }) => AxiosAPI.post(`/themes/${themeId}/benefits`, content),
);

const getBundleContent = async (bundle) => {
  const response = await AxiosAPI.get(`/bundles/items/bundle/${bundle.id}?size=1000`);
  const bundleContent = await Promise.all(response.data.content.map(async item => {
    const itemContent = await AxiosAPI.get(`/themes/${item.themeId}`);
    return itemContent.data;
  }));

  return {
    ...bundle,
    content: bundleContent,
  };
};

export const getThemeBundles = createAsyncThunkCatchErrors(
  'theme/bundles/get',
  async (themeId: number) => {
    const response = await AxiosAPI.get(`/bundles/theme/${themeId}`);
    const bundles = await Promise.all(response.data.content.map(async (bundle) => {
      const result = await getBundleContent(bundle);
      return result;
    }));

    return bundles;
  },
);

export const getAddons = createAsyncThunkCatchErrors(
  'theme/addons/list',
  async () => {
    const response = await AxiosAPI.get('/themes/product?product=add-ons');
    return response.data;
  },
);

export const updateAddons = createAsyncThunkCatchErrors(
  'theme/addons/update',
  async ({ masterAddonThemeId, resource } : { masterAddonThemeId: number, resource: any }) => {
    const response = await AxiosAPI.put(`/master-addons/${masterAddonThemeId}/select-addons/master`, resource);
    return response.data;
  },
);

export const setFilteredThemes = createAction<any>('themes/listFiltered/set');
export const setFilter = createAction<ThemeFilter>('themes/filter/set');
export const resetCurrent = createAction<any>('current/reset');
export const getTheme = createAction<number>('themes/byId');
export const getBundles = createAction<number>('themes/bundles');

const sortUniqueResults = (state, payload) => {
  const concatArr = [payload].concat(state);
  const unique = concatArr.reduce((accumulator, current) => {
    if (!accumulator.find((item) => item.id === current.id)) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
  const order = unique.sort((a, b) => a.id - b.id);
  return order;
};

export const themesSlice = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setFilteredThemes, (state, action) => {
        state.themesList.content = action.payload;
        state.themesList.status = 'idle';
      })
      .addCase(getMenuProducts.fulfilled, (state, action) => {
        const themes = action.payload.content;
        state.menuThemeTypes.content = themes.slice().sort((a, b) => a.sortId - b.sortId);
      })
      .addCase(getFilteredProducts.fulfilled, (state, action) => {
        const themes = action.payload.content;
        state.themesList.content = themes.slice().sort((a, b) => a.sortId - b.sortId);
        state.themesList.status = 'idle';
      })
      .addCase(getThemesByFamily.fulfilled, (state, action) => {
        const themes = action.payload.content;
        state.currentThemeVariants.content = themes.slice().sort((a, b) => a.sortId - b.sortId);
      })
      .addCase(setFilter, (state, { payload }) => {
        state.filter.content = payload;
      })
      .addCase(getTheme, (state, { payload }) => {
        const selectedTheme = state.themesList.content.find(theme => theme.id === payload);
        state.currentTheme.content = selectedTheme;
      })
      .addCase(getThemeById.fulfilled, (state, { payload }) => {
        state.currentTheme.content = payload;
      })
      .addCase(getThemeCollaterals.fulfilled, (state, { payload }) => {
        state.currentThemeCollaterals.content = payload;
      })
      .addCase(getThemeFeaturesId.fulfilled, (state, { payload }) => {
        state.currentThemeFeatures.content.idList = payload;
      })
      .addCase(getThemeFeature.fulfilled, (state, { payload }) => {
        const order = sortUniqueResults(state.currentThemeFeatures.content.featuresContent, payload);
        state.currentThemeFeatures.content.featuresContent = order;
      })
      .addCase(getThemeBenefitsId.fulfilled, (state, { payload }) => {
        state.currentThemeBenefits.content.idList = payload;
      })
      .addCase(getThemeBenefit.fulfilled, (state, { payload }) => {
        const order = sortUniqueResults(state.currentThemeBenefits.content.benefitsContent, payload);
        state.currentThemeBenefits.content.benefitsContent = order;
      })
      .addCase(getThemeBundles.fulfilled, (state, { payload }) => {
        state.themeBundles.content = payload.map(bundle => {
          const products = bundle.content.filter(item => item.product !== 'add-ons');
          const addons = bundle.content.filter(item => item.product === 'add-ons');
          delete bundle.content;
          return {
            ...bundle,
            features: [],
            requirements: [],
            products: products.map(prod => ({
              id: prod.id,
              name: prod.title,
              description: prod.description,
              exampleUrl: prod.url,
              imageUrl: prod.screenshotImage,
              productType: prod.product,
            })),
            addons: addons.map(addon => ({
              id: addon.id,
              name: addon.title,
              description: addon.description,
              exampleUrl: addon.url,
              imageUrl: addon.screenshotImage,
              productType: addon.product,
            })),
          };
        });
      })
      .addCase(resetCurrent, (state, { payload }) => {
        state.currentTheme.content = payload;
        state.currentThemeFeatures.content = { idList: [], featuresContent: [] };
        state.currentThemeBenefits.content = { idList: [], benefitsContent: [] };
        state.currentThemeVariants.content = [];
        state.themeBundles.content = [];
      })
      .addCase(getCapacityHeaderByProductType.fulfilled, (state, action) => {
        const response = action.payload;
        const date = new Date();
        date.setDate(date.getDate() + response.days);
        const content = {
          startTime: date.getTime(),
          estimatedDelivery: addWeeksToDate(date, response.weeks),
          developmentTime: response.weeks,
        };
        response.content = content;
        state.deliveryDetails = response;
      })
      .addCase(getAddons.fulfilled, (state, { payload }) => {
        state.allAddons.content = payload.map(e => ({
          id: e.id,
          name: e.title,
          description: e.description,
          exampleUrl: e.url,
          imageUrl: e.screenshotImage,
          productType: e.product,
        }));
      });
  },
);

export const selectFilter = (state: RootState) => state.themes.filter.content;
export const selectDeliveryDetails = (state: RootState) => state.themes.deliveryDetails.content;
export const selectThemes = (state: RootState) => state.themes.themesList.content;
export const selectCurrentTheme = (state: RootState) => state.themes.currentTheme.content;
export const selectCurrentThemeBenefits = (state: RootState) => state.themes.currentThemeBenefits.content;
export const selectCurrentThemeCollaterals = (state: RootState) => state.themes.currentThemeCollaterals.content;
export const selectCurrentThemeFeatures = (state: RootState) => state.themes.currentThemeFeatures.content;
export const selectCurrentThemePictures = (state: RootState) => state.themes.currentThemePictures.content;
export const selectCurrentThemeVariants = (state: RootState) => state.themes.currentThemeVariants.content;
export const selectThemeBundles = (state: RootState) => state.themes.themeBundles.content;
export const selectThemeAddons = (state: RootState) => state.themes.themeAddons.content;
export const selectAllAddons = (state: RootState) => state.themes.allAddons.content;
export const selectMenuThemeTypes = (state: RootState) => state.themes.menuThemeTypes.content;

export default themesSlice;
