import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import { FormHelperText, Grid, TextField, Typography } from '@mui/material';
import ErrorText from 'components/errorText/ErrorText';
import React from 'react';
import { useState } from 'react';

interface DecimalInputFieldProps {
  id: string;
  name: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  rawErrors?: string[];
  disabled?: boolean;
  required?: boolean;
  precision?: number; // Number of decimal places allowed
}

export default function DecimalInputField(props: DecimalInputFieldProps) {

  const [isTouched, setIsTouched] = useState(!!props.value);

  const handleInputChange = (value: string) => {
    // Allow only numbers and decimal point
    const decimalRegex = new RegExp(`^\\d*(\\.\\d{0,${props.precision || 2}})?$`);
    if (decimalRegex.test(value)) {
      props.onChange(value);
    }
  };

  const showError = isTouched && (props?.rawErrors?.length > 0);

  return (
      <Grid item xs={9} mt={3}>
        <Typography
            fontWeight={600}
            sx={{
              color: (props?.rawErrors?.length > 0 && isTouched)
                  ? IQThemeV2.palette.error.main
                  : IQThemeV2.palette.text.primary,
            }}
        >
          {props.label}
        </Typography>
        <TextField
            id={props.id}
            name={props.name}
            disabled={props.disabled}
            fullWidth
            error={props?.rawErrors?.length > 0 && isTouched}
            required={props.required}
            onBlur={() => setIsTouched(true)}
            style={{ marginTop: '10px' }}
            value={props.value}
            onChange={(e) => handleInputChange(e.target.value)}
            inputProps={{
              inputMode: 'decimal',
              pattern: `\\d*(\\.\\d{0,${props.precision || 2}})?`,
              onKeyDown: (e) => {
                // Allow only numeric keys, decimal point, backspace, delete, arrow keys, and navigation keys
                if (
                    !/^[0-9.]$/.test(e.key) &&
                    !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'].includes(e.key)
                ) {
                  e.preventDefault();
                }
              },
            }}
        />
        {props?.placeholder && !showError &&
            <FormHelperText error={false}>{props?.placeholder}</FormHelperText>}
        <ErrorText hasError={showError}
                   errorText={showError ? (props?.rawErrors?.[0]) : ''}
        />
      </Grid>
  );
}
