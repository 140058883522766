import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';

export const customerCenterMenu: MenuType = ({
  label: 'Customer Center',
  url: '',
  status: 'incomplete',
  offeringType: 'DMSNextCustomerCenter',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
    {
      label: 'Solution Strategy',
      url: Urls.CustomerCenterStrategy,
      status: 'incomplete',
    },
  ],
});
