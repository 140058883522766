/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import { ProductTypes } from 'services/fileUploaderSlice';
import FileUploader from '../custom-fields/FileUploader';

const { t } = i18n;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CustomReportingSchema: RJSFSchema | any = (data) => ({
  title: t('pages.custom.reporting.pageTitle'),
  // eslint-disable-next-line max-len
  description: '',
  type: 'object',
  required: [],
  properties: {
    signedAndUploadedCustomSOW: {
      type: 'string',
      title: t('pages.custom.reporting.confirmDropdownLabel'),
      default: '',
      enum: [
        '',
        t('form.big-box-labels.yes'),
        t('pages.custom.reporting.noOption'),
      ],
    },
    uploader_file: {
      type: 'string',
      title: t('pages.custom.reporting.completeLabel'),
      default: '',
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.custom.reporting.notesLabel'),
      default: '',
    },
  },
});

const textFieldKeys = [
  'notesOrInstructions',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

export const CustomReportingUISchema = {
  'ui:order': ['signedAndUploadedCustomSOW', 'uploader_file', 'notesOrInstructions'],
  signedAndUploadedCustomSOW: {
    'ui:widget': 'dropdown',
  },
  uploader_file: {
    'ui:widget': (props) => (
      <FileUploader
        {...props}
        uploaderType={ProductTypes.CUSTOM_REPORTING_UPLOADER}
        totalAllowedFiles={1}
        imageTypes={[]}
        videoTypes={[]}
        documentTypes={['.pdf']}
        supportingText=""
      />
    ),
    'ui:style': {
      marginTop: '30px',
      marginBottom: '15px',
    },
  },
  ...textFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CustomReportingCustomValidate = (formData: any, errors: any, uiSchema: any) => errors;
