import React, { ComponentProps } from 'react';
import { Country } from 'react-phone-number-input';

import { Grid, SxProps } from '@mui/material';
import { IQLabelTooltip } from '@gannettdigital/shared-react-components';

import IQPhoneInput from './IQPhoneInput/IQPhoneInput';
import { IQPhoneExtension } from './IQPhoneExtension/IQPhoneExtension';
import { IQPhoneType } from './IQPhoneType/IQPhoneType';

export interface IQPhoneInputContainerProps {
  /**
   * Name of the phone input field
   */
  name: string;
  /**
   * Default country - affects validation and default country calling code (ex. +1).
   * Cannot be invalid.
   */
  country: Country;
  /**
   * Props to be passed to the phone input's label component
   */
  phoneLabelOptions?: ComponentProps<typeof IQLabelTooltip> | any;
  /**
   * Props to be passed to the phone input's label component
   */
  extLabelOptions?: ComponentProps<typeof IQLabelTooltip>;
  /**
   * Props to be passed to the phone input's label component
   */
  typeLabelOptions?: ComponentProps<typeof IQLabelTooltip>;
  /**
   * Default value for the phone input
   */
  defaultValue?: string;
  /**
   * Whether the component should render an extension numeric field
   */
  hasExtension?: boolean;
  /**
   * Options to pass to the extension component
   */
  extensionOptions?: ExtensionOptions;
  /**
   * Whether the component should render a type dropdown
   */
  hasType?: boolean;
  /**
   * Options to pass to the type component
   */
  typeOptions?: TypeOptions;
  /**
   * Styles to be passed to the root container element
   */
  containerStyles?: SxProps;
  countryCallingCodeEditable?: boolean;
  international?: boolean;
}

type ExtensionOptions = {
  name: string;
  defaultValue: string;
};

type PhoneTypeOption = {
  description: string;
  value: string;
};

type TypeOptions = {
  name: string;
  defaultValue: string;
  values: PhoneTypeOption[];
  required: boolean;
};

const phoneInputSize = (hasType, hasExtension) => {
  if (hasType) return 6;
  if (!hasType && !hasExtension) return 12;
  return 9;
};

const IQPhoneInputContainer = ({
  phoneLabelOptions,
  extLabelOptions,
  typeLabelOptions,
  name,
  defaultValue,
  country = 'US',
  hasExtension,
  extensionOptions,
  hasType,
  typeOptions,
  containerStyles,
  international,
  countryCallingCodeEditable,
}: IQPhoneInputContainerProps) => (
  <Grid
    container
    columnSpacing={2}
    sx={{
      ...containerStyles,
    }}
  >
    { hasType && (
      <Grid item xs={3}>
        <IQPhoneType
          typeLabelOptions={typeLabelOptions}
          typeOptions={typeOptions}
          hasType={hasType}
        />
      </Grid>
    ) }
    <Grid item xs={phoneInputSize(hasType, hasExtension)}>
      <IQPhoneInput
        phoneLabelOptions={phoneLabelOptions}
        country={country}
        name={name}
        defaultValue={defaultValue}
        international={international}
        countryCallingCodeEditable={countryCallingCodeEditable}
      />
    </Grid>
    <Grid item xs={3}>
      <IQPhoneExtension
        hasExtension={hasExtension}
        extLabelOptions={extLabelOptions}
        extensionOptions={extensionOptions}
      />
    </Grid>
  </Grid>
);

export default IQPhoneInputContainer;
