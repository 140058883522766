import * as yup from 'yup';

const schema = {
  examples: yup.string().required(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
