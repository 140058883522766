import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";

const {t} = i18n;

export const YoutubeSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.youtube.title'),
  type: 'object',
  required: [
    'trackingRequirements',
  ],
  properties: {
    campaignName: {
      type: 'string',
      title: t('pages.ausProduct.youtube.campaignNameLabel'),
      description: t('pages.ausProduct.youtube.campaignNameDescription'),
      default: data?.campaignName || '',
    },
    campaignNameReportingLinks: {
      type: 'string',
      title: t('pages.ausProduct.youtube.campaignNameReportingLinkDescription'),
      enum: [t('pages.ausProduct.youtube.campaignNameReportingLinks'), true]
    },
    budget: {
      type: 'string',
      title: t('pages.ausProduct.youtube.budgetLabel'),
      description: t('pages.ausProduct.youtube.budgetLabelDescription'),
      default: data?.budget || '',
    },
    initialCycles: {
      type: 'string',
      title: t('pages.ausProduct.youtube.initialCyclesLabel'),
      default: data?.initialCycles || '',
    },

    adSchedule: {
      type: 'string',
      title: t('pages.ausProduct.youtube.adScheduleLabel'),
      default: data?.adSchedule || '',
      enum: [
        '',
        t('pages.ausProduct.youtube.yesOpt'),
        t('pages.ausProduct.youtube.noOpt'),
      ]
    },
    weekdayStartTime: {
      type: 'string',
      title: t('pages.ausProduct.youtube.weekdayStartTimeLabel'),
      default: data?.weekdayStartTime || '',
    },
    weekdayEndTime: {
      type: 'string',
      title: t('pages.ausProduct.youtube.weekdayEndTimeLabel'),
      default: data?.weekdayEndTime || '',
    },
    weekendStartTime: {
      type: 'string',
      title: t('pages.ausProduct.youtube.weekendStartTimeLabel'),
      default: data?.weekendStartTime || '',
    },
    weekendEndTime: {
      type: 'string',
      title: t('pages.ausProduct.youtube.weekendEndTimeLabel'),
      default: data?.weekendEndTime || '',
    },
    trackingRequirements: {
      type: 'string',
      title: t('pages.ausProduct.linkedin.trackingRequirementsLabel'),
      default: data?.trackingRequirements || '',
      enum: [
        '',
        t('pages.ausProduct.linkedin.trackingRequirementsOpt1'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt2'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt3'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt4'),
      ],
    },
    utmStringCreationCorrectlyFormatted: {
      type: 'string',
      title: t('pages.ausProduct.youtube.utmStringCreationCorrectlyFormattedLabel'),
      default: data?.utmStringCreationCorrectlyFormatted || '',
      enum: [
        '',
        t('pages.ausProduct.youtube.yesRecommendedOpt'),
        t('pages.ausProduct.youtube.noOpt'),
      ],
    },
    buildComments: {
      type: 'string',
      title: t('pages.ausProduct.youtube.buildCommentsLabel'),
      description: t('pages.ausProduct.youtube.buildCommentsDescription'),
      default: data?.buildComments || '',
    },
  },
  dependencies: {
    utmStringCreationCorrectlyFormatted: {
      oneOf: [
        {
          properties: {
            utmStringCreationCorrectlyFormatted: {
              enum: [t('pages.ausProduct.youtube.noOpt')],
            },
            utmStringCreationCorrectlyFormattedDescription: {
              type: 'string',
              title: t('pages.ausProduct.youtube.utmStringCreationCorrectlyFormattedDescription'),
            },
            source: {
              type: 'string',
              title: t('pages.ausProduct.youtube.sourceLabel'),
              default: data?.source || '',
            },
            medium: {
              type: 'string',
              title: t('pages.ausProduct.youtube.mediumLabel'),
              default: data?.medium || '',
            },
            campaignNameForUtmStringCreationCorrectlyFormatted: {
              type: 'string',
              title: t('pages.ausProduct.youtube.campaignNameForUtmStringCreationCorrectlyFormattedLabel'),
              default: data?.campaignNameForUtmStringCreationCorrectlyFormatted || '',
            },
          },
          required: [
            'source',
            'medium',
            'campaignNameForUtmStringCreationCorrectlyFormatted'
          ],
        },
      ],
    },
  }
});

const dropdownFieldKeys = [
  'adSchedule',
  'trackingRequirements',
  'utmStringCreationCorrectlyFormatted',
];

const inputFieldKeys = [
  'campaignName',
  'source',
  'medium',
  'campaignNameForUtmStringCreationCorrectlyFormatted',
  'buildComments',
];

const timeTextFieldKeys = [
  'weekdayStartTime',
  'weekdayEndTime',
  'weekendStartTime',
  'weekendEndTime',
];

const integerInputFieldKeys = [
  'budget',
  'initialCycles',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'dropdown'},
}), {});

const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});
const timeTextField = timeTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'timePickerField'},
}), {});
const integerInputField = integerInputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'integerInputField'},
}), {});

export const YoutubeUISchema = {
  'ui:order': [
    'campaignName',
    'campaignNameReportingLinks',
    'budget',
    'initialCycles',
    'adSchedule',
    'weekdayStartTime',
    'weekdayEndTime',
    'weekendStartTime',
    'weekendEndTime',
    'trackingRequirements',
    'utmStringCreationCorrectlyFormatted',
    'utmStringCreationCorrectlyFormattedDescription',
    'source',
    'medium',
    'campaignNameForUtmStringCreationCorrectlyFormatted',
    'buildComments',
  ],
  campaignNameReportingLinks: {
    'ui:widget': 'anchorTextLabel',
    'ui:style': {
      marginTop: '-15px',
    },
  },
  utmStringCreationCorrectlyFormattedDescription: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '-15px',
    },
  },

  ...inputFields,
  ...dropdownFields,
  ...timeTextField,
  ...integerInputField,
};
export const YoutubeCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.weekdayEndTime < formData?.weekdayStartTime) {
    errors.weekdayEndTime.addError(t('pages.ausProduct.youtube.timeErrorMessage'));
    errors.addError(true);
  }
  if (formData?.weekendEndTime < formData?.weekendStartTime) {
    errors.weekendEndTime.addError(t('pages.ausProduct.youtube.timeErrorMessage'));
    errors.addError(true);
  }
  if (formData?.trackingRequirements === '') {
    errors.trackingRequirements.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.utmStringCreationCorrectlyFormatted === t('pages.ausProduct.youtube.noOpt')) {
    if (formData?.source === '') {
      errors.source.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.medium === '') {
      errors.medium.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.campaignNameForUtmStringCreationCorrectlyFormatted === '') {
      errors.campaignNameForUtmStringCreationCorrectlyFormatted.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
  }
  return errors;
};
