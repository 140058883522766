import { IQButtonLink, IQThemeV2 } from '@gannettdigital/shared-react-components';
import { Button, Grid, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import IQPhoneInputContainer from 'components/NePhoneInputContainer/IQPhoneInputContainer';
import { Country } from 'react-phone-number-input';
import { PhoneType } from './ClientType';

interface Props {
  getValues: any
  phoneTypes: any[]
  addPhoneButtonLabel: string
  removePhoneDialogTitle: string
  fieldName: string
  country?: string
}

export const defaultNumber = { phoneType: PhoneType.BUSINESS, phoneNumber: '', ext: '' };

export const matchCountryCode = {
  USA: 'US',
  CAN: 'CA',
  AUS: 'AU',
  NZL: 'NZ',
};

export default function ClientPhones({
  getValues, addPhoneButtonLabel, removePhoneDialogTitle, phoneTypes, fieldName, country,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [showDeletePhoneDialog, setShowDeletePhoneDialog] = useState(false);
  const [phoneToDelete, setPhoneToDelete] = useState({ phone: defaultNumber, index: -1 });

  const { fields: phonesArray, append: appendPhone, remove: removePhone } = useFieldArray({
    name: fieldName,
  });

  const onPhoneAdd = () => {
    appendPhone(defaultNumber);
  };

  const onPhoneDelete = (index) => {
    const phone = getValues(fieldName)[index];
    setShowDeletePhoneDialog(true);
    setPhoneToDelete({ phone, index });
  };

  const confirmPhoneDelete = () => {
    removePhone(phoneToDelete.index);
    setShowDeletePhoneDialog(false);
  };

  const cancelPhoneDelete = () => {
    setShowDeletePhoneDialog(false);
    setPhoneToDelete({ phone: defaultNumber, index: -1 });
  };

  const typeLabelOpts = {
    tooltipText: '',
    labelText: 'Type',
    hasError: false,
    theme: IQThemeV2,
    required: true,
  };

  const phoneRow = (phone, key) => (
    <Grid item xs={12} display="flex"
      flexDirection="row" key={key}
    >
      <Grid item xs={key === 0 ? 12 : 11}>
        {country !== '' && (
        <IQPhoneInputContainer
          name={`${fieldName}.${key}.phoneNumber`}
          defaultValue={phone.phoneNumber}
          country={country as Country}
          hasExtension
          extensionOptions={{
            name: `${fieldName}.${key}.ext`,
            defaultValue: phone.ext || null,
          }}
          hasType={key >= 0}
          typeLabelOptions={typeLabelOpts}
          typeOptions={{
            name: `${fieldName}.${key}.phoneType`,
            defaultValue: PhoneType.BUSINESS,
            required: phone.phoneNumber !== '',
            values: phoneTypes,
          }}
          countryCallingCodeEditable={false}
          international
        />
        )}
      </Grid>
      { key !== 0 && (
        <Grid item xs={1}
          display="flex"
          alignItems="flex-end"
        >
          <Button
            onClick={() => onPhoneDelete(key)}
            sx={{ color: theme.palette.primary.main }}
          >
            <DeleteIcon />
          </Button>
        </Grid>
      ) }
    </Grid>
  );

  return (
    <>
      { phonesArray.map((phone, index) => phoneRow(phone, index)) }
      <Grid item xs={12}>
        <IQButtonLink startIcon={<AddIcon />} onClick={onPhoneAdd}>
          {addPhoneButtonLabel}
        </IQButtonLink>
      </Grid>
      <CoModalConfirm open={showDeletePhoneDialog}
        title={t(removePhoneDialogTitle, { type: phoneToDelete.phone.phoneType })}
        description={(
          <Trans
            i18nKey="pages.business.client.removeModal.numberMessage"
            values={{ type: phoneToDelete.phone.phoneType.toLowerCase(), number: phoneToDelete.phone.phoneNumber }}
            components={{ strong: <strong /> }}
          />
        )}
        confirmText="Remove"
        handleClose={cancelPhoneDelete}
        handleConfirm={confirmPhoneDelete}
      />
    </>
  );
}
