import { t } from 'i18next';
import * as yup from 'yup';

const yupSchema = {
  examples: yup.string().required(t('pages.website.custom.examples.websiteUrlsRequired')),
};

export default {
  yupValidations: yupSchema,
  customValidations: {},
};
