/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box, Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo } from 'react';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { SaveAndCloseContext } from 'context/SaveAndCloseContext';
import { useLocation } from 'react-router';
import { Urls } from 'navigation/Urls';
import { CoButtonBack } from '../buttons/CoButtonBack';
import { CoButtonContinue } from '../buttons/CoButtonContinue';

interface Props {
  continueDisabled: boolean,
  backDisabled?: boolean,
  skipAndContinue?: boolean,
  // eslint-disable-next-line no-unused-vars
  onContinue?: (Event) => any,
  // eslint-disable-next-line no-unused-vars
  onBack?: (Event) => any
}

export default function CoContinueFooter(props: Props) {
  const { t } = useTranslation();

  const navigate = useNavigationHandler();
  const history: any = useLocation();

  const saveAndCloseContext = useContext(SaveAndCloseContext);
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  const backButtonClick = (event) => {
    if (isRedirectedFromOverviewPage) return navigate.to(Urls.Overview, undefined, false);
    if (props.onBack) {
      return props.onBack(event);
    }
    return navigate.back();
  };

  // the save and close will act like the continue, but sending the user to the home page
  useEffect(() => {
    saveAndCloseContext.setSaveAndCloseAction(
      props.onContinue,
    );
  }, [props.continueDisabled]);

  const isSkipandContinue = props.skipAndContinue ? t('form.buttons.skipAndContinue') : t('form.buttons.continue');

  const continueButtonLabel = isRedirectedFromOverviewPage ? 'Save & Return' : isSkipandContinue;

  const backButtonLabel = isRedirectedFromOverviewPage ? 'Cancel' : t('form.buttons.back');

  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider sx={{ marginTop: '64px !important', marginBottom: '40px !important' }} />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <CoButtonBack label={backButtonLabel} disabled={props.backDisabled} onClick={backButtonClick}
            sx={{ float: 'left' }}
          />
          <CoButtonContinue label={continueButtonLabel}
            disabled={props.continueDisabled}
            onClick={(e) => { navigate.clearDefaultNavigation(); props.onContinue && props.onContinue(e); }}
            sx={{ float: 'right', minWidth: 'fit-content' }} type="submit"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
