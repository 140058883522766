import {
  Box, Grid, Link, Typography,
} from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { useAppDispatch } from 'services/redux/store';
import { useEffect, useState } from 'react';
import { getCustomWebsite, getCustomWebsiteItems } from 'services/customWebsiteSlice';
import { ProductTypes, getFiles } from 'services/fileUploaderSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import OrderTracker from 'pages/orders/OrderTracker';
import { headingStyle } from '../Business/BusinessSection';
import { customWebsiteDesignSection } from './CustomWebsiteDesignSection';
import { customWebsiteFeaturesSection } from './CustomWebsiteFeaturesSection';
import { customWebsiteDevelopmentSection } from './CustomWebsiteDevelopmentSection';

export default function CustomWebsiteSection({ product, isEditable, showTracker }: any) {
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const [websiteData, setWebsiteData] = useState({} as any);
  const [websiteItems, setWebsiteItems] = useState([]);

  const [existingAssets, setExistingAssets] = useState([]);
  const [additionalInfoAssets, setAdditionalInfoAssets] = useState([]);

  const getData = async () => {
    appDispatch(getCustomWebsite(product?.offeringId)).then((response) => setWebsiteData(response.payload));
    appDispatch(getCustomWebsiteItems(product?.offeringId)).then((response) => setWebsiteItems(response.payload));

    await appDispatch(getFiles({
      websiteId: product.offeringId,
      uploaderType: ProductTypes.ADDITIONAL_FEATURES,
      offeringType: 'CustomWebsite',
    })).then((response) => setExistingAssets(response.payload));

    await appDispatch(getFiles({
      websiteId: product.offeringId,
      uploaderType: ProductTypes.ADDITIONAL_INFORMATION,
      offeringType: 'CustomWebsite',
    })).then((response) => setAdditionalInfoAssets(response.payload));
  };

  useEffect(() => {
    if (product?.id) getData();
  }, [product]);

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Status Tracker */}
      {showTracker && (
      <GridItem sizes={[12, 12]} marginBottom={8} sx={{ padding: '40px 0 0', borderBottom: '1px solid #000000' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => {})}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Status</Typography>
        </Box>
        <Box>
          <OrderTracker
            key={product?.id}
            status={product?.trackerStatus}
            productName={product?.offeringType}
            product={product}
          />
        </Box>
      </GridItem>
      )}

      {/* Details */}
      <GridItem sizes={[12, 6, 6]} marginBottom={3} sx={{ padding: '20px 0 0' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () =>
            navigate.to(
              Urls.CustomWebsiteDetails,
              product.id,
            ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Details</Typography>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>New Website Reason</Typography>
            <Typography>{websiteData?.newWebsiteReason}</Typography>
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>Open to LocaliQ Website</Typography>
            <Typography>{websiteData?.openToLocaliqProduct ? 'Yes' : 'No'}</Typography>
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>Budget Range</Typography>
            <Typography>{websiteData?.budget}</Typography>
          </Box>
        </Box>
      </GridItem>

      {/* Design */}
      {customWebsiteDesignSection(websiteData, websiteItems, product, navigate, isEditable)}

      {/* Features */}
      {customWebsiteFeaturesSection(websiteData, websiteItems, existingAssets, product, navigate, isEditable)}

      {/* Development */}
      {customWebsiteDevelopmentSection(websiteData, product, navigate, isEditable)}

      {/* Additional */}
      <GridItem sizes={[12, 6, 6]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () =>
            navigate.to(
              Urls.CustomWebsiteAdditionalInfo,
              product.id,
            ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Additional</Typography>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>Additional Info</Typography>
            {displayNotRequiredField('additional info', websiteData?.additionalInfo)}
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>Additional Info Attachments</Typography>
            {additionalInfoAssets?.length
              ? additionalInfoAssets.map((asset, index) => (
                <Link href={asset.url} key={asset.url} target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography>{`Asset ${index + 1}`}</Typography>
                </Link>
              )) : <Typography fontWeight="bold">No attachments</Typography>}
          </Box>
        </Box>
      </GridItem>
    </Grid>
  );
}
