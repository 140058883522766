import React from 'react';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const convertStringToFlat = (str) => str?.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());

export function getNameFromUrl(name: string): string {
  const splittedByUnderscore = name.split('_');
  const finalString = splittedByUnderscore
    .reduce((previous: string, current: string) => (`${previous} ${capitalizeFirstLetter(current)}`), '');
  return finalString.slice(1);
}

// export function createMenuItems(menuItemFromArgon: LeftMenuArgonUrls): MenuStepType[] {
//   return Object.entries(menuItemFromArgon).map(menuItem => ({
//     label: getNameFromUrl(menuItem[0]),
//     url: menuItem[1].url,
//     status: 'incomplete',
//     offeringType: '',
//   }));
// }

export const adpatNewLinesInString = (str: string) => {
  const result = str.split('\n').map((subStr: string) => (
    <>
      {subStr}
      <br />
    </>
  ));
  return result;
};
