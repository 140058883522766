import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";

const {t} = i18n;

export const CampaignCustomFacebookSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.customFacebookMainSection.solutionStrategyTitle'),
  type: 'object',
  required: ['campaignName'],
  properties: {
    helpText1: {
      type: 'string',
      title: t('pages.ausProduct.customFacebookMainSection.helpText1'),
    },
    helpText2: {
      type: 'string',
      title: t('pages.ausProduct.customFacebookMainSection.helpText2'),
    },
    campaignName: {
      type: 'string',
      title: t('pages.ausProduct.customFacebookMainSection.campaignNameLabel'),
      description: t('pages.ausProduct.customFacebookMainSection.campaignNameDescriptions'),
      default: data?.campaignName || '',
    }
  },
});

const textFieldKeys = [
  'campaignName',
];

const textLabelKeys = [
  'helpText1',
  'helpText2',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

const textLabelFields = textLabelKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textLabel'},
}), {});

export const CampaignCustomFacebookUISchema = {
  'ui:order': ['helpText1','helpText2','campaignName'],

  ...textFields,
  ...textLabelFields
};

export const CampaignCustomFacebookValidate = (formData: any, errors, uiSchema) => {
  if (formData?.campaignName === '') {
    errors.campaignName.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
