/* eslint-disable react/jsx-fragments, react/jsx-no-useless-fragment */
import React, { ComponentProps } from 'react';

import { IQFormTextArea } from '@gannettdigital/shared-react-components';
import { Grid } from '@mui/material';

import { t } from 'i18next';

type TextAreaProps = ComponentProps<typeof IQFormTextArea>;

const textAreas :TextAreaProps[] = [
  {
    name: 'targetMessaging',
    labelText: t('pages.landing.pageInfo.targetMessaging'),
    id: 'target-messaging',
    fontLabelWeight: '600',
    fullWidth: true,
  },
  {
    name: 'featuredOffers',
    labelText: t('pages.landing.pageInfo.featuredOffers'),
    id: 'featured-offers',
    fontLabelWeight: '600',
    fullWidth: true,
  },
  {
    name: 'otherNotes',
    labelText: t('pages.landing.pageInfo.otherNotes'),
    id: 'other-notes',
    fontLabelWeight: '600',
    fullWidth: true,
  },
];

export const LandingPageInfoTextareas = () => {
  const textareaElements = textAreas.map(textArea => (
    <Grid
      item
      xs={6}
      sx={{ label: { pb: '7px' } }}
    >
      <IQFormTextArea rowCount={2} {...textArea} />
    </Grid>
  ));

  return (
    <React.Fragment>
      {textareaElements}
    </React.Fragment>
  );
};
