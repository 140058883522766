import i18n from 'i18n/i18n';
import * as yup from 'yup';

const { t } = i18n;

const schema = {
  title: yup.string().required(t('pages.themes.themeCreateEdit.titleError')),
  familyId: yup.string().required(t('pages.themes.themeCreateEdit.familyError')),
  screenshotImage: yup.string().nullable(),
  screenshotImageFile: yup.string().nullable(),
  compositeScreenshot: yup.string().nullable(),
  compositeScreenshotFile: yup.string().nullable(),
  themePreview: yup.string().nullable(),
  themePreviewFile: yup.string().nullable(),
  url: yup.string().nullable(),
  sort: yup.number().nullable(),
  marketCategoryId: yup.string().required(),
  businessFunctionalityId: yup.string().required(),
  productId: yup.string().required(),
  description: yup.string().nullable(),
  detailsAbove: yup.string().nullable(),
  detailsBelow: yup.string().nullable(),
  designStyle: yup.string().nullable(),
  color: yup.string().nullable(),
  notes: yup.string().nullable(),
  callToAction: yup.string().nullable(),
  themeFeatures: yup.array(),
  themeBenefits: yup.array(),
  collaterals: yup.array(),
  countryAvailability: yup.array().required().min(1, 'Please add at least one country.'),
  available: yup.boolean().optional(),
  featured: yup.boolean().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
