import * as yup from 'yup';

const schema = {
  themeDesign: yup.string().required(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
