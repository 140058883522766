/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled';
import React, { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import '../../styles/website_fonts.css';
import { IQFormLayout, useValidationContext } from '@gannettdigital/shared-react-components';
import schemaValidate from '../schemaValidate';

export interface NeFormTextAreaProps {
  /**
   * Unique ID of this component
   */
  id: string,

  /**
   * Label for the input field
   */
  labelText?: string,

  /**
   * Name field used in the react hook form and yup schema
   */
  name: string,

  /**
   * Descriptive text displayed as a note between the input and the labelText
   */
  supportText?: string,

  /**
   * Descriptive text displayed as a note below the input
   */
  subDescription?: string,

  /**
   * Tooltip text, if present a standard ? icon will be used with tooltip functionality.
   */
  tooltipText?: any,
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right',
  tooltipArrow?: boolean;

  /**
   * Placeholder text for the input
   */
  placeholder?: string,

  /**
   * Whether this field should be disabled
   */
  disabled?: boolean,

  /**
   * The number of rows to start the text area with
   */
  rowCount?: number,

  /**
   * function triggered when the field loses focus
   */
  onBlur?: any,

  /**
  * fullWidth field for input field
  */
  fullWidth?: boolean,
  required?: boolean,
  showError?: boolean,
  params?: any,
  fontLabelWeight?: string,
  customValidation?: any;

  tooltipPaddingBottom?: number;

  spaceBottomMultiplier?: number;

  /**
   * Allows for any additional props you want attached to
   * the textarea html element
   *
   */
}

export const StyledTextArea = styled('textarea') <NeFormTextAreaProps>`
  font-family: 'Unify Sans';
  background-color: ${(props) => props.theme.palette.common.white};
  border: 1px solid ${(props) => (props.showError ? props.theme.palette.error.main : props.theme.palette.text.primary)};
  border-color: ${(props) => (props.disabled ? props.theme.palette.action.disabledBackground : props.showError
    ? props.theme.palette.error.main : props.theme.palette.text.primary)};
  border-radius: 2px;
  color: ${(props) => (props.disabled ? props.theme.palette.action.disabledBackground
    : props.theme.palette.text.primary)};
  font-size: 16px;
  padding: ${(props) => props.theme.spacing(1, 2, 2, 2)};
  resize: vertical;
  height:  ${(props) => (props.rowCount ? props.rowCount * 12 : 36)};
  /* Padding of 16px and 2px of border */
  width: calc(100% - 36px);

  *, *:focus, *:hover {
    outline:none;
  }

  &::placeholder {
    color: ${(props) => (props.disabled ? props.theme.palette.action.disabledBackground
    : props.theme.palette.text.secondary)};
  };

  &:focus-within {
    outline: none;
    border-width: 1px;
    border-color: ${(props) => (props.showError ? props.theme.palette.error.main : props.theme.palette.primary.main)};
  },
  // Hide placeholder on focus
  '&:focus::-webkit-input-placeholder': { color: transparent };
  '&:focus:-moz-placeholder': { color: transparent }; /* FF 4-18 */
  '&:focus::-moz-placeholder': { color: transparent }; /* FF 19+ */
  '&:focus:-ms-input-placeholder': { color: transparent }; /* IE 10+ */
`;

/**
 * Component for a basic text area input field
 *
 * See below prop types for param info

*/
export const NeFormTextArea = forwardRef<HTMLTextAreaElement, NeFormTextAreaProps>(({
  id,
  labelText,
  name,
  supportText = '',
  subDescription = '',
  tooltipText = null,
  placeholder = '',
  disabled = false,
  rowCount = 3,
  fullWidth = false,
  showError = false,
  params,
  customValidation,
  fontLabelWeight,
  required = false,
  onBlur,
  tooltipPlacement = 'bottom',
  tooltipArrow = true,
  tooltipPaddingBottom,
  spaceBottomMultiplier,
  ...props
}, ref) => {
  const {
    register,
    formState: {
      errors,
    },
  } = useFormContext();

  const { schema } = useValidationContext();

  const getErrors = (errors: any, field: string): any =>
    field.split('.').reduce((acc, el) => (acc ? acc[el] : acc), errors);

  return (
    <IQFormLayout
      fullWidth={fullWidth}
      showError={!!getErrors(errors, name) && showError}
      errorMessage={getErrors(errors, name)?.message}
      labelText={labelText}
      supportText={supportText}
      tooltipText={tooltipText}
      tooltipPlacement={tooltipPlacement}
      tooltipArrow={tooltipArrow}
      fontLabelWeight={fontLabelWeight}
      subDescription={subDescription}
      required={required}
      tooltipPaddingBottom={tooltipPaddingBottom}
      spaceBottomMultiplier={spaceBottomMultiplier}
    >
      <StyledTextArea
        {...register(name, {
          validate: (value) => schemaValidate(value, name, schema, params, customValidation),
        })}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        fullWidth={fullWidth}
        rows={rowCount}
        onBlur={onBlur}
        ref={ref}
        showError={!!getErrors(errors, name)}
        {...props}
      />
    </IQFormLayout>
  );
});

export default NeFormTextArea;
