import * as yup from 'yup';
import {urlValidationRegex} from "shared/constants";
import {t} from "i18next";

export const cefObjectCreatorSchema = yup.object().shape({
  opportunityId: yup.string().required(t('cefObjectCreator.opportunityIdErrorMessage')),
  accountId: yup.string().required(t('cefObjectCreator.accountIdErrorMessage')),
  name: yup.string().required(t('cefObjectCreator.cefNameErrorMessage')),
  campaignEntryFormURL: yup
  .string()
  .matches(urlValidationRegex, t('cefObjectCreator.cefUrlError'))
  .required(t('cefObjectCreator.cefUrlErrorMessage')),
});