import * as yup from 'yup';

const schema = {
  addressLine1: yup.string().nullable()
    .required('Please enter address street name and number.'),
  city: yup.string().nullable()
    .required('Please enter city.'),
  state: yup.string().nullable()
    .required('Please select state.'),
  country: yup.string().nullable()
    .required('Please select country.'),
  zipcode: yup.string().nullable()
    .required('Please enter zip/postal code.'),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
