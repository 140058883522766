// eslint-disable-next-line import/no-extraneous-dependencies
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';
import SchedulerWidget from '../custom-fields/SchedulerWidget';

const { t } = i18n;

export const YelpSchema: RJSFSchema | any = (data) => ({
  title: t('pages.yelp.solutionStrategy.solutionStrategy'),
  type: 'object',
  properties: {
    campaignName: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.campaignNameLabel'),
      default: '',
    },
    websiteLabel: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.websites'),
    },
    clientWebsite: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.clientHaveLocaliqWebsite'),
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    proxyProvisioningLabel: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.proxyProvisioningLabel'),
    },
    localAreaPreferenceOne: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.localAreaPreferenceOne'),
      default: '',
    },
    localAreaPreferenceTwo: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.localAreaPreferenceTwo'),
      default: '',
    },
    captchaInstalled: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.captchaInstalled'),
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    captchaPiece: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.captchaPiece'),
      default: '',
      enum: [
        '',
        'Yes',
        'No, please hold campaign until client is able to remove CAPTCHA',
      ],
    },
    anotherAccountMAID: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.anotherAccountMAID'),
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    yelpAdvertising: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.yelpAdvertising'),
    },
    advertiserBusinessAddress: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.advertiserBusinessAddress'),
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    businessName: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.businessName'),
      default: '',
      description: t('pages.yelp.solutionStrategy.businessNameNote'),
    },
    yelpBusinessProfileUrl: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.yelpBusinessProfileUrl'),
      default: '',
      description: t('pages.yelp.solutionStrategy.yelpBusinessProfileUrlNote'),
    },
    callToActionPreferences: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.callToActionPreferences'),
      default: '',
      enum: [
        '',
        'Make the Call to Actions for me',
        'Review my Call to Actions and improve if needed',
        'ONLY use my Call to Actions',
      ],
    },
    selectBusinessCategories: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.businessCategory'),
    },
    selectBusinessCategoryOne: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.businessCategory'),
      default: '',
      enum: [
        '',
        'Active',
        'African',
        'Airports',
        'Arabian',
        'Arts',
        'Arts and Crafts',
        'Auto',
        'Auto Repair',
        'Bars',
        'Beauty Services',
        'Belgian',
        'Bicycles',
        'Brazilian',
        'Counseling & Mental Health',
        'Cafes',
        'Caribbean',
        'Chinese',
        'Dental Hygienists',
        'Dentists',
        'Diagnostic Services',
        'Diving',
        'Donburi',
        'Education',
        'Estate Planning',
        'Event Services',
        'Farms',
        'Fashion',
        'Festivals',
        'Financial Services',
        'Fitness',
        'Flowers',
        'Food',
        'French',
        'German',
        'Gourmet',
        'Hair',
        'Hair Removal',
        'Health',
        'Home and Garden',
        'Home Services',
        'Hotels',
        'Hotels Travel',
        'Insurance',
        'tallan',
        'IT Services',
        'Japanese',
        'Japanese Sweets',
        'Junk Removal & Hauling',
        'Latin',
        'Lawyers',
        'Legal Services',
        'Local Flavor',
        'Local Services',
        'Malaysian',
        'Mass Media',
        'Medical Centers',
        'Media',
        'Mediterranean',
        'Mexican',
        'Mideastern',
        'Movie Theaters',
        'Museums',
        'Music Instrument Services',
        'Night Life',
        'Nonprofit',
        'Other Salons',
        'Parks',
        'Pets',
        'Pet Services',
        'Pet Store',
        'Photographers',
        'Physicians',
        'Plumbing',
        'Polish',
        'Portuguese',
        'Professional',
        'Public Services Government',
        'Real Estate',
        'Real Estate Services',
        'Religious Organizations',
        'Restaurants',
        'Shopping',
        'Social Clubs',
        'Spanish',
        'Specialty Schools',
        'Sporting Goods',
        'Tanning',
        'Tasting Classes',
        'Traditional Chinese Medicine',
        'Tours',
        'Transport',
        'Travel Services',
        'Turkish',
        'Wineries',
      ],
    },
    selectBusinessCategoryTwo: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.businessCategory'),
      default: '',
      enum: [
        '',
        'Active',
        'African',
        'Airports',
        'Arabian',
        'Arts',
        'Arts and Crafts',
        'Auto',
        'Auto Repair',
        'Bars',
        'Beauty Services',
        'Belgian',
        'Bicycles',
        'Brazilian',
        'Counseling & Mental Health',
        'Cafes',
        'Caribbean',
        'Chinese',
        'Dental Hygienists',
        'Dentists',
        'Diagnostic Services',
        'Diving',
        'Donburi',
        'Education',
        'Estate Planning',
        'Event Services',
        'Farms',
        'Fashion',
        'Festivals',
        'Financial Services',
        'Fitness',
        'Flowers',
        'Food',
        'French',
        'German',
        'Gourmet',
        'Hair',
        'Hair Removal',
        'Health',
        'Home and Garden',
        'Home Services',
        'Hotels',
        'Hotels Travel',
        'Insurance',
        'tallan',
        'IT Services',
        'Japanese',
        'Japanese Sweets',
        'Junk Removal & Hauling',
        'Latin',
        'Lawyers',
        'Legal Services',
        'Local Flavor',
        'Local Services',
        'Malaysian',
        'Mass Media',
        'Medical Centers',
        'Media',
        'Mediterranean',
        'Mexican',
        'Mideastern',
        'Movie Theaters',
        'Museums',
        'Music Instrument Services',
        'Night Life',
        'Nonprofit',
        'Other Salons',
        'Parks',
        'Pets',
        'Pet Services',
        'Pet Store',
        'Photographers',
        'Physicians',
        'Plumbing',
        'Polish',
        'Portuguese',
        'Professional',
        'Public Services Government',
        'Real Estate',
        'Real Estate Services',
        'Religious Organizations',
        'Restaurants',
        'Shopping',
        'Social Clubs',
        'Spanish',
        'Specialty Schools',
        'Sporting Goods',
        'Tanning',
        'Tasting Classes',
        'Traditional Chinese Medicine',
        'Tours',
        'Transport',
        'Travel Services',
        'Turkish',
        'Wineries',
      ],
    },
    selectBusinessCategoryThree: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.businessCategory'),
      default: '',
      enum: [
        '',
        'Active',
        'African',
        'Airports',
        'Arabian',
        'Arts',
        'Arts and Crafts',
        'Auto',
        'Auto Repair',
        'Bars',
        'Beauty Services',
        'Belgian',
        'Bicycles',
        'Brazilian',
        'Counseling & Mental Health',
        'Cafes',
        'Caribbean',
        'Chinese',
        'Dental Hygienists',
        'Dentists',
        'Diagnostic Services',
        'Diving',
        'Donburi',
        'Education',
        'Estate Planning',
        'Event Services',
        'Farms',
        'Fashion',
        'Festivals',
        'Financial Services',
        'Fitness',
        'Flowers',
        'Food',
        'French',
        'German',
        'Gourmet',
        'Hair',
        'Hair Removal',
        'Health',
        'Home and Garden',
        'Home Services',
        'Hotels',
        'Hotels Travel',
        'Insurance',
        'tallan',
        'IT Services',
        'Japanese',
        'Japanese Sweets',
        'Junk Removal & Hauling',
        'Latin',
        'Lawyers',
        'Legal Services',
        'Local Flavor',
        'Local Services',
        'Malaysian',
        'Mass Media',
        'Medical Centers',
        'Media',
        'Mediterranean',
        'Mexican',
        'Mideastern',
        'Movie Theaters',
        'Museums',
        'Music Instrument Services',
        'Night Life',
        'Nonprofit',
        'Other Salons',
        'Parks',
        'Pets',
        'Pet Services',
        'Pet Store',
        'Photographers',
        'Physicians',
        'Plumbing',
        'Polish',
        'Portuguese',
        'Professional',
        'Public Services Government',
        'Real Estate',
        'Real Estate Services',
        'Religious Organizations',
        'Restaurants',
        'Shopping',
        'Social Clubs',
        'Spanish',
        'Specialty Schools',
        'Sporting Goods',
        'Tanning',
        'Tasting Classes',
        'Traditional Chinese Medicine',
        'Tours',
        'Transport',
        'Travel Services',
        'Turkish',
        'Wineries',
      ],
    },
    hoursOfOperationLabel: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.hoursOfOperationLabel'),
    },
    businessHours: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.businessHours'),
    },
    notesInstructions: {
      type: 'string',
      title: t('pages.yelp.solutionStrategy.notesInstructions'),
      default: '',
    },
  },

  dependencies: {
    clientWebsite: {
      oneOf: [
        {
          properties: {
            clientWebsite: {
              enum: ['Yes'],
            },
            isWebsiteLive: {
              type: 'string',
              title: t('pages.yelp.solutionStrategy.isWebsiteLive'),
              default: '',
              enum: [
                'Yes',
                'No',
              ],
            },
          },
        },
      ],
    },
    isWebsiteLive: {
      oneOf: [
        {
          properties: {
            isWebsiteLive: {
              enum: ['No'],
            },
            provideTemporaryLink: {
              type: 'string',
              title: t('pages.yelp.solutionStrategy.provideTemporaryLink'),
              default: '',
            },
          },
          required: ['provideTemporaryLink'],
        },
      ],
    },
    advertiserBusinessAddress: {
      oneOf: [
        {
          properties: {
            advertiserBusinessAddress: {
              enum: ['No'],
            },
            listAddress: {
              type: 'string',
              title: t('pages.yelp.solutionStrategy.listAddress'),
              default: data?.listAddress,
              description: t('pages.yelp.solutionStrategy.addressNote'),
            },
          },
        },
      ],
    },
    callToActionPreferences: {
      oneOf: [
        {
          properties: {
            callToActionPreferences: {
              enum: ['Review my Call to Actions and improve if needed', 'ONLY use my Call to Actions'],
            },
            desktopCallToActionButtonUrl: {
              type: 'string',
              title: t('pages.yelp.solutionStrategy.desktopCallToActionButtonUrl'),
              default: '',
              enum: [
                'Book Now',
                'Buy Tickets',
                'Check Availability',
                'Contact Us',
                'Enroll Now',
                'Get Offer',
                'Get Quote',
                'Join Now',
                'Learn More',
                'Order Online',
                'Print Coupon',
                'Reserve Now',
                'Schedule Appointment',
                'View Now',
              ],
            },
            desktopCallToActionDescriptionText: {
              type: 'string',
              title: t('pages.yelp.solutionStrategy.desktopCallToActionDescriptionText'),
              default: '',
              description: t('pages.yelp.solutionStrategy.noSpecialCharacters'),
            },
            mobileDeviceCallToActionLinkText: {
              type: 'string',
              title: t('pages.yelp.solutionStrategy.mobileDeviceCallToActionLinkText'),
              default: '',
              description: t('pages.yelp.solutionStrategy.noSpecialCharacters'),
            },
            mobileDeviceCallToActionDescriptionText: {
              type: 'string',
              title: t('pages.yelp.solutionStrategy.mobileDeviceCallToActionDescriptionText'),
              default: '',
              description: t('pages.yelp.solutionStrategy.noSpecialCharacters'),
            },
          },
        },
      ],
    },

  },
});

const textFieldKeys = [
  'campaignName',
  'localAreaPreferenceOne',
  'localAreaPreferenceTwo',
  'businessName',
  'desktopCallToActionDescriptionText',
  'mobileDeviceCallToActionLinkText',
  'mobileDeviceCallToActionDescriptionText',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const dropdownFieldKeys = [
  'clientWebsite',
  'captchaInstalled',
  'captchaPiece',
  'anotherAccountMAID',
  'advertiserBusinessAddress',
  'callToActionPreferences',
  'selectBusinessCategoryOne',
  'selectBusinessCategoryTwo',
  'selectBusinessCategoryThree',
  'isWebsiteLive',
  'desktopCallToActionButtonUrl',
];
const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const headingFieldKeys = [
  'websiteLabel',
  'proxyProvisioningLabel',
  'yelpAdvertising',
  'selectBusinessCategories',
  'hoursOfOperationLabel',
];
const headingFields = headingFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'headingTextLabel' },
}), {});

export const YelpUISchema = (data) => ({
  'ui:order': ['campaignName', 'websiteLabel', 'clientWebsite', 'isWebsiteLive',
    'provideTemporaryLink', 'proxyProvisioningLabel', 'localAreaPreferenceOne',
    'localAreaPreferenceTwo', 'captchaInstalled', 'captchaPiece', 'anotherAccountMAID',
    'yelpAdvertising', 'advertiserBusinessAddress', 'listAddress', 'businessName',
    'yelpBusinessProfileUrl', 'callToActionPreferences', 'selectBusinessCategories',
    'desktopCallToActionButtonUrl', 'desktopCallToActionDescriptionText',
    'mobileDeviceCallToActionLinkText', 'mobileDeviceCallToActionDescriptionText',
    'selectBusinessCategoryOne', 'selectBusinessCategoryTwo', 'selectBusinessCategoryThree',
    'hoursOfOperationLabel', 'businessHours', 'schedule', 'notesInstructions'],
  yelpBusinessProfileUrl: {
    'ui:widget': 'UrlTextWidget',
  },
  provideTemporaryLink: {
    'ui:widget': 'UrlTextWidget',
  },
  listAddress: {
    'ui:widget': 'addressField',
  },
  businessHours: {
    'ui:widget': (props) => <SchedulerWidget {...props} data={data} />,
  },
  notesInstructions: {
    'ui:widget': 'TextareaWidget',
  },

  ...headingFields,
  ...textFields,
  ...dropdownFields,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const YelpCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.yelpBusinessProfileUrl !== '' && !validateUrl(formData?.yelpBusinessProfileUrl)) {
    errors.yelpBusinessProfileUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.isWebsiteLive === 'No') {
    if (formData?.provideTemporaryLink === '' || !validateUrl(formData?.provideTemporaryLink)) {
      errors.provideTemporaryLink.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  return errors;
};
