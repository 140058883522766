import {Urls} from "navigation/Urls";

export const pmmVariantsConstants = {
    label: 'Solution Strategy',
    items: [
      Urls.GeneralQuestions,
      Urls.PmmDetails,
      '',
      Urls.PmmSem,
      Urls.PmmSocialSmartOptimization,
      Urls.PmmWebsiteClicks,
      Urls.PmmRetargeting,
      Urls.PmmTargetedDisplay,
    ],
};