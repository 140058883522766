import { createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import AxiosAPI, { AxiosCobaltAPI } from './common/axios';
import { thunkBuilder } from './common/functions';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { RootState } from './redux/store';

export interface ClientStockAssetsState {
  sharedUrl: StatusType<any>
}

const initialState: ClientStockAssetsState = {
  sharedUrl: {
    status: 'idle',
    message: '',
    content: {},
  },
};

export const getDetailsFromShortUrl = createAsyncThunkCatchErrors(
  'stockAssets/sharedUrl/get',
  async (request) => {
    const response = await AxiosAPI.post('/public/token/shared/', request);
    return response.data;
  },
);

export const createSharedUrl = createAsyncThunkCatchErrors(
  'stockAssets/sharedUrl/create',
  async (request) => {
    const response = await AxiosAPI.post('/integrations/shared/', request);
    return response.data;
  },
);

export const sendConfirmationEmail = createAsyncThunkCatchErrors(
  'stockAssets/mail/salesAgent',
  async (request) => {
    const response = await AxiosAPI.post('/public/shared/email', request);
    return response.data;
  },
);

export const updateWrikeForOrderItem = createAsyncThunkCatchErrors(
  'updateWrike/orderItem/get',
  async (orderItemId) => {
    const response = await AxiosCobaltAPI.get(`/update_wrike/order_item/${orderItemId}`);
    return response.data;
  },
);

const clientStockAssetsSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder)
      .addCase(getDetailsFromShortUrl, 'sharedUrl');
  },
);

export const selectSharedUrl = (state: RootState) => state.clientStockAssets.sharedUrl.content;

export default clientStockAssetsSlice;
