import React, { useEffect, useState } from 'react';
import i18n from 'i18n/i18n';
import { RJSFSchema } from '@rjsf/utils';
import AdSet from "pages/generic-product/details/custom-fields/AdSet";

const { t } = i18n;
const numberOfAdSetsOpt = Array.from({ length: 5 }, (_, i) => (i + 1).toString());

export const AddAdSetsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.adSet.addAdSetTitle'),
  type: 'object',
  required: [],
  properties: {
    adSet: {
      type: "array",
      items: {
        type: "object",
        properties: {
          focus: { type: "string" },
          landingPage: { type: "string" },
          uniqueTargeting: { type: "string" },
        },
      },
    },
  },
});

export const AddAdSetsUISchema= (data) => ({
  'ui:order': [
    'adSet',
    'focus',
    'landingPage',
    'uniqueTargeting',
  ],
  adSet: {
    'ui:widget': (props) => {
      return (
          <AdSet
              {...props}
              data={data}
          />
      );
    },
  },
});

export const AddAdSetsCustomValidate = (formData, errors, uiSchema) => {
  return { errors, formData };
};
