import {
  Grid, IconButton, Link, Typography,
} from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box } from '@mui/system';
import { Collateral, setCollateralUrl } from 'services/themesSlice';

interface Props {
  collaterals: Collateral[]
}

const CollateralRow = ({ collateral }: { collateral: Collateral }) => (
  <Box paddingX={5} paddingY={3}
    sx={{
      backgroundColor: 'white',
      borderBottom: '1px solid lightgray',
      '&:nth-of-type(even)': {
        backgroundColor: 'rgba(185,225,255,0.2)',
      },
      '&:first-of-type': {
        borderRadius: '10px 10px 0 0',
      },
      '&:last-of-type': {
        borderBottom: 'none',
      },
    }}
  >
    <Grid container>
      <Grid item xs={10}>
        <Typography fontWeight="bold">{collateral.label}</Typography>
        <Typography>{collateral.collateralBlurb}</Typography>
      </Grid>
      <Grid item xs={2} display="flex"
        justifyContent="flex-end"
      >
        <IconButton sx={{ color: '#0000ff', '&:hover': { backgroundColor: 'transparent' } }}
          onClick={() => { window.location.href = collateral.uploadedFile; }}
        >
          <FileDownloadOutlinedIcon fontSize="large" />
          <Link href={setCollateralUrl(collateral.id, collateral.uploadedFile)}
            fontSize="15px" fontWeight="bold"
            sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
          >
            <Typography fontWeight="bold">Download</Typography>
          </Link>
        </IconButton>
      </Grid>
    </Grid>
  </Box>
);

export default function CollateralSection({ collaterals }: Props) {
  return (
    <Box marginBottom={2} sx={{ border: '2px solid lightgray', borderRadius: '10px' }}>
      {collaterals.map(collateral => <CollateralRow collateral={collateral} key={collateral.label} />)}
    </Box>
  );
}
