import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import {Box} from "@mui/system";
import React, {useEffect, useState} from "react";
import {
  IQLeftNavLogo
} from "@gannettdigital/shared-react-components";
import IQLeftNavIconNeon from "icons/IQLeftNavIconNeon.svg";
import {useParams} from "react-router-dom";
import {t} from "i18next";
import {
  storeSmsOptInDetails,
  verifySmsOptInUser
} from "pages/public-url/sms-opt-in-webpage/SmsOptInWebpageApi";
import ContentLoader from "components/contentLoader/ContentLoader";

export default function SmsOptInWebpage() {
  const params: any = useParams();
  const [accessAvailable, setAccessAvailable] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    const formattedInput = input.replace(/[^\d\s()-]/g, '');
    setPhoneNumber(formattedInput);
    validatePhoneNumber(formattedInput);
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    setError(!phoneRegex.test(number));
  };

  const validateAccessRequest = async () => {
    setLoader(true);
    const responseData = await verifySmsOptInUser(params);
    if (responseData && responseData?.id !== null) {
      setAccessAvailable(true);
    } else {
      setErrorMessage(t('smsOptIn.invalidPageAccessRequest'))
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000)
  }

  useEffect(() => {
    validateAccessRequest()
  }, [params]);

  const handleOnSubmitButton = async () => {
    try {
      setLoader(true);
      const contactId = params.url.split('-')[0];
      const requestBody = {
        "contactId": contactId,
        "mobilePhone": `+1${phoneNumber}`,
        "smsOptIn": checkboxChecked,
      }
      const responseData = await storeSmsOptInDetails(requestBody);
      if (!responseData) {
        setErrorMessage(t('smsOptIn.requestNotSuccessfulErrorMessage'))
      } else {
        if (responseData?.statusCode === 200) {
          setSuccessMessage(responseData?.message)
          setErrorMessage("")
        } else {
          setSuccessMessage("")
          setErrorMessage(responseData?.message)
        }
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setSuccessMessage("")
      setErrorMessage(t('smsOptIn.requestNotSuccessfulErrorMessage'))
    }
  }

  return (
      <ContentLoader status={loader ? 'loading' : 'idle'}>
        {successMessage &&
            <Alert severity="success"
                   sx={{
                     mb: 2,
                     backgroundColor: (theme) => theme.palette.success.dark,
                     color: 'white'
                   }}
            >
              {successMessage}
            </Alert>
        }
        {errorMessage &&
            <Alert severity="info"
                   sx={{
                     mb: 2,
                     backgroundColor: (theme) => theme.palette.error.main,
                     color: 'white'
                   }}
            >
              {errorMessage}
            </Alert>
        }
        {!successMessage && !errorMessage && accessAvailable &&
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="80vh"
            >
              <Grid
                  container
                  direction="column"
                  spacing={2}
                  p={3}
                  pl={1}
                  bgcolor="white"
                  boxShadow={3}
                  borderRadius={2}
                  maxWidth={450}
              >
                <Grid item sx={{textAlign: "center"}}>
                  <IQLeftNavLogo IQLeftNavIcon={IQLeftNavIconNeon}/>
                </Grid>
                <Grid item>
                  <Typography variant="body1" gutterBottom sx={{fontWeight: "bold"}}>
                    {t('smsOptIn.phoneNumberLabel')}
                  </Typography>
                </Grid>
                <Grid item width="100%">
                  <TextField
                      fullWidth
                      variant="outlined"
                      type="tel"
                      value={phoneNumber}
                      onChange={handlePhoneChange}
                      error={error}
                      helperText={error ? t('smsOptIn.phoneNumberErrorMessage') : ""}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                      }}
                      inputProps={{
                        maxLength: 15,
                      }}
                  />
                </Grid>
                <Grid item width="100%" sx={{textAlign: "center"}}>
                  <FormControlLabel
                      control={<Checkbox/>}
                      sx={{fontWeight: "bold"}}
                      label={
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {t('smsOptIn.smsNotificationsLabel')}
                        </Typography>
                      }
                      onChange={(e: any) => setCheckboxChecked(e.target.checked)}
                  />
                </Grid>
                <Grid item sx={{textAlign: "center"}}>
                  <Typography variant="body1" gutterBottom>
                    {t('smsOptIn.smsNotificationsDescription')}
                  </Typography>
                </Grid>
                <Grid item width="100%">
                  <Box display="flex" justifyContent="center" mt={2}>
                    <Button
                        onClick={handleOnSubmitButton}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={error || phoneNumber.length < 10}
                        sx={{
                          '&.Mui-disabled': {
                            color: 'white',
                          }
                        }}
                    >
                      {t('smsOptIn.submitButtonLabel')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
        }
      </ContentLoader>
  )
}