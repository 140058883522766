import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";

const {t} = i18n;

const validateRequiredFormField = (formData, fieldName, errors) => {
  if (formData?.[fieldName] === '') {
    errors[fieldName].addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
};
export const TargetingSocialUpgradeSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.xmoUpgradeSocial.xmoUpgradeSocialTargeting.title'),
  type: 'object',
  required: [
    'locationTargetingType'
  ],
  properties: {
    noteDescriptions: {
      type: 'string',
      title: t('pages.ausProduct.xmoUpgradeSocial.xmoUpgradeSocialTargeting.noteDescriptions'),
    },
    locationTargetingType: {
      type: 'string',
      title: t('pages.ausProduct.youtubeTargeting.locationTargetingTypeLabel'),
      description: t('pages.ausProduct.youtubeTargeting.locationTargetingTypeDescription'),
      default: data?.locationTargetingType || '',
      enum: [
        '',
        t('pages.ausProduct.youtubeTargeting.nationalOpt'),
        t('pages.ausProduct.youtubeTargeting.stateOpt'),
        t('pages.ausProduct.youtubeTargeting.radiusOpt'),
        t('pages.ausProduct.youtubeTargeting.postcodeOpt'),
        t('pages.ausProduct.youtubeTargeting.cityOpt'),
      ]
    },
    nationalPublisherTargeting: {
      type: 'string',
      title: t('pages.ausProduct.youtubeTargeting.nationalPublisherTargetingLabel'),
      default: data?.nationalPublisherTargeting || '',
      enum: [
        '',
        t('pages.ausProduct.youtubeTargeting.nationalPublisherTargetingOpt1'),
        t('pages.ausProduct.youtubeTargeting.nationalPublisherTargetingOpt2'),
      ]
    },
  },
  dependencies: {
    locationTargetingType: {
      oneOf: [
        {
          properties: {
            locationTargetingType: {
              enum: [t('pages.ausProduct.youtubeTargeting.nationalOpt')],
            },
            cityPublisher: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.cityPublisherDescription'),
            },
            selectedCountry: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.selectedCountryLabel'),
              default: data?.selectedCountry || '',
              enum: [
                '',
                t('pages.ausProduct.youtubeTargeting.australiaOpt'),
                t('pages.ausProduct.youtubeTargeting.newZealandOpt'),
                t('pages.ausProduct.youtubeTargeting.OtherOpt'),
              ]
            },
          },
        },
        {
          properties: {
            locationTargetingType: {
              enum: [t('pages.ausProduct.youtubeTargeting.stateOpt')],
            },
            targetedStates: {
              type: 'array',
              title: t('pages.ausProduct.youtubeTargeting.targetedStatesLabel'),
              description: t('pages.ausProduct.youtubeTargeting.targetedStatesDescription'),
              items: {
                type: 'string',
                enum: [
                  t('pages.ausProduct.youtubeTargeting.australianCapitalTerritoryOpt'),
                  t('pages.ausProduct.youtubeTargeting.newSouthWalesOpt'),
                  t('pages.ausProduct.youtubeTargeting.northernTerritoryOpt'),
                  t('pages.ausProduct.youtubeTargeting.queenslandOpt'),
                  t('pages.ausProduct.youtubeTargeting.southAustraliaOpt'),
                  t('pages.ausProduct.youtubeTargeting.tasmaniaOpt'),
                  t('pages.ausProduct.youtubeTargeting.victoriaOpt'),
                  t('pages.ausProduct.youtubeTargeting.westernAustraliaOpt'),
                  t('pages.ausProduct.youtubeTargeting.northlandOpt'),
                  t('pages.ausProduct.youtubeTargeting.aucklandOpt'),
                  t('pages.ausProduct.youtubeTargeting.bayOfPlentyOpt'),
                  t('pages.ausProduct.youtubeTargeting.canterburyOpt'),
                  t('pages.ausProduct.youtubeTargeting.gisborneOpt'),
                  t('pages.ausProduct.youtubeTargeting.hawkesBayOpt'),
                  t('pages.ausProduct.youtubeTargeting.manawatuWanganuiOpt'),
                  t('pages.ausProduct.youtubeTargeting.marlboroughOpt'),
                  t('pages.ausProduct.youtubeTargeting.nelsonOpt'),
                  t('pages.ausProduct.youtubeTargeting.otagoOpt'),
                  t('pages.ausProduct.youtubeTargeting.southlandOpt'),
                  t('pages.ausProduct.youtubeTargeting.taranakiOpt'),
                  t('pages.ausProduct.youtubeTargeting.tasmanOpt'),
                  t('pages.ausProduct.youtubeTargeting.waikatoOpt'),
                  t('pages.ausProduct.youtubeTargeting.wellingtonOpt'),
                  t('pages.ausProduct.youtubeTargeting.westCoastOpt'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            locationTargetingType: {
              enum: [t('pages.ausProduct.youtubeTargeting.radiusOpt')],
            },
            providedRadius: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.providedRadiusLabel'),
              default: data?.providedRadius || '',
            },
            streetNumber: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.streetNumberLabel'),
              default: data?.streetNumber || '',
            },
            streetName: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.streetNameLabel'),
              default: data?.streetName || '',
            },
            cityOrSuburb: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.cityOrSuburbLabel'),
              default: data?.cityOrSuburb || '',
            },
            stateOrProvince: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.stateOrProvinceLabel'),
              default: data?.stateOrProvince || '',
            },
            postalCode: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.postalCodeLabel'),
              default: data?.postalCode || '',
            },
            country: {
              type: 'string',
              title: t('pages.ausProduct.youtubeTargeting.countryLabel'),
              default: data?.country || '',
            },
          },
          required: [
            'providedRadius',
            'streetNumber',
            'streetName',
            'cityOrSuburb',
            'stateOrProvince',
            'postalCode',
            'country',
          ]
        },
        {
          properties: {
            locationTargetingType: {
              enum: [t('pages.ausProduct.youtubeTargeting.postcodeOpt')],
            },
            postcode: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.postcodeLabel'),
              description: t('pages.ausProduct.targetingIndented.postcodeDescription'),
              default: '',
            },
          },
          required: ['postcode']
        },
        {
          properties: {
            locationTargetingType: {
              enum: [t('pages.ausProduct.youtubeTargeting.cityOpt')],
            },
            cityTargetingRequirements: {
              type: 'string',
              title: t('pages.ausProduct.targetingIndented.cityTargetingRequirements'),
              description: t('pages.ausProduct.targetingIndented.cityTargetingRequirementsDescription'),
              default: data?.cityTargetingRequirements || '',
            },
          },
          required: ['cityTargetingRequirements']
        },
      ],
    },
  }
});

const dropdownFieldKeys = [
  'locationTargetingType',
  'selectedCountry',
  'nationalPublisherTargeting',
];

const inputFieldKeys = [
  'streetName',
  'cityOrSuburb',
  'stateOrProvince',
  'country',
  'cityTargetingRequirements',
  'nationalPublisherTargeting',
  'targetAudiences',
  'customIntentAudience',
  'describeYourAudience',
];

const checkBoxFieldKey = [
  'targetedStates',
];
const textLabelKey = [
  'cityPublisher',
  'fullListHelpText',
];

const integerInputFieldKey = [
  'providedRadius',
  'streetNumber',
  'postalCode',
  'postcode',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'dropdown'},
}), {});

const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});
const checkBoxField = checkBoxFieldKey.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'checkBoxField'},
}), {});
const textLabel = textLabelKey.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textLabel'},
}), {});
const integerInputField = integerInputFieldKey.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'integerInputField'},
}), {});

export const TargetingSocialUpgradeUISchema = {
  'ui:order': [
    'noteDescriptions',
    'locationTargetingType',
    'cityPublisher',
    'selectedCountry',
    'targetedStates',
    'providedRadius',
    'streetNumber',
    'streetName',
    'cityOrSuburb',
    'stateOrProvince',
    'postalCode',
    'country',
    'cityTargetingRequirements',
    'postcode',
    'nationalPublisherTargeting',
  ],
  fullListLink: {
    'ui:widget': 'anchorTextLabel',
  },
  noteDescriptions: {
    'ui:widget': 'textLabel',
  },
  ...inputFields,
  ...dropdownFields,
  ...checkBoxField,
  ...textLabel,
  ...integerInputField,
};

export const TargetingSocialUpgradeCustomValidate = (formData: any, errors, uiSchema) => {
  validateRequiredFormField(formData, 'locationTargetingType', errors,);

  if (formData?.locationTargetingType === t('pages.ausProduct.youtubeTargeting.radiusOpt')) {
    validateRequiredFormField(formData, 'providedRadius', errors,);
    validateRequiredFormField(formData, 'streetNumber', errors,);
    validateRequiredFormField(formData, 'streetName', errors,);
    validateRequiredFormField(formData, 'cityOrSuburb', errors,);
    validateRequiredFormField(formData, 'stateOrProvince', errors,);
    validateRequiredFormField(formData, 'postalCode', errors,);
    validateRequiredFormField(formData, 'country', errors,);
  }

  if (formData?.locationTargetingType === t('pages.ausProduct.youtubeTargeting.postcodeOpt')) {
    validateRequiredFormField(formData, 'postcode', errors,);
  }

  if (formData?.locationTargetingType === t('pages.ausProduct.youtubeTargeting.cityOpt')) {
    validateRequiredFormField(formData, 'cityTargetingRequirements', errors,);
  }
  return errors;
};
