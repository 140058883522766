import React, { useMemo, useState } from 'react';
import { IQTopNavStepsContainer } from '@gannettdigital/shared-react-components';
import { useSelector } from 'react-redux';
import {
  selectActiveOfferingType,
  selectNavigationMenu, selectNavMenuBuilt,
} from 'services/navigationSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { useLocation } from 'react-router';
import { selectWebsiteId } from 'services/websitesSlice';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { useParams } from 'react-router-dom';

interface Props {
  menu?: string
}

const getCurrentTopNavSteps = (menu, offeringType, orderItemId, page) => {
  let menuIndex;
  if (offeringType && orderItemId) {
    menuIndex = menu.findIndex(step => step.offeringType === offeringType && step.builtFor === Number(orderItemId));
  }

  let index = -1;
  if (menuIndex >= 0 && menu[menuIndex]?.subSteps) {
    menu[menuIndex]?.subSteps.forEach((step, i) => {
      if (step?.subSteps && index < 0) {
        if (step.subSteps.findIndex(s => s.url === page) >= 0) {
          index = i;
        }
      }
    });
  }

  return menu[menuIndex]?.subSteps[index]?.subSteps || [];
};

export default function TopnavMenu(props: Props) {
  const { orderItemId } = useParams();
  const { menu } = props;
  const offeringType = useSelector(selectActiveOfferingType);
  const menuSteps = useSelector(selectNavigationMenu);
  const menuBuilt = useSelector(selectNavMenuBuilt);
  const steps = useMemo(() => (menuBuilt
    ? getCurrentTopNavSteps(menuSteps, offeringType, orderItemId, menu) : []), [menuSteps, offeringType, menu]);

  const websiteId = useSelector(selectWebsiteId);
  // const currentIndex = useSelector(selectTopnavMenuCurrentStep(menu));
  // const canGoToStep = useSelector(selectTopnavMenuCanGoToStep(menu));
  const currentIndex = 0;
  const canGoToStep = 0;
  const navigate = useNavigationHandler();
  const history: any = useLocation();
  const [showLeavePageModal, setShowLeavePageModal] = useState(false);
  const [selectedStep, setSelectedStep] = useState(currentIndex);

  const websiteParam = offeringType === 'websites' || offeringType === 'goldsites' ? websiteId : null;

  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  const navigateToStep = async (index: number) => {
    await setSelectedStep(index);
    if (canGoToStep >= index) {
      if (!isRedirectedFromOverviewPage) navigate.to(steps[index].url, websiteParam);
      else setShowLeavePageModal(true);
    }
  };

  if (!menuBuilt) return <ContentLoader status="loading" message="loading" />;

  if (steps) {
    return (
      <>
        <IQTopNavStepsContainer
          selectStep={navigateToStep}
          currentStep={currentIndex}
          steps={steps}
        />
        <CoModalConfirm title="Leaving page?"
          description="Are you sure you want to leave this page? The information you have entered may not be saved."
          confirmText="Leave page" cancelText="Stay on page"
          open={showLeavePageModal} handleClose={() => setShowLeavePageModal(false)}
          handleConfirm={() => navigate.to(steps[selectedStep].url, websiteParam)}
        />
      </>
    );
  }
  return null;
}
