/* eslint-disable react/jsx-fragments */
import React, { ComponentProps } from 'react';
import { YesNo } from 'pages/ecommerce/products/product-catalog/ProductCatalogForm';

import { IQContentToneContainer, IQInputNumericField, IQTheme } from '@gannettdigital/shared-react-components';
import { Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';

import { t } from 'i18next';

type StyledNumericContainerProps = {
  theme: typeof IQTheme;
};

type StyledHeadingProps = StyledNumericContainerProps;

const StyledNumericContainer = styled(Grid)<StyledNumericContainerProps>`
  margin-top: 19px;
  
  label {
    padding-bottom: 8px;
  };
  
  .MuiInputBase-root {
    max-height: 40px;
    border: 1px solid ${props => props.theme.palette.text.primary};
  };
  
  fieldset {
    border: none;
  };
`;

const StyledContentTonesHeading = styled(Typography)<StyledHeadingProps>`
  font-family: ${props => props.theme.typography.fontFamily};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  line-height: 20px;
  letter-spacing: 0.15px;
  margin: 32px 0 16px 0;
  color: ${props => props.theme.palette.info.dark};
`;

const StyledContentTonesSubHeading = styled(Typography)<StyledHeadingProps>`
  font-family: ${props => props.theme.typography.fontFamily};
  font-weight: ${props => props.theme.typography.fontWeightRegular};
  color: ${props => props.theme.palette.text.primary};
  margin-bottom: 6px;
`;

interface Props {
  productDescriptionByClient: YesNo;
  numericFieldProps: NumericFieldProps;
  contentTonesProps: ContentTonesProps;
}

export type NumericFieldProps = ComponentProps<typeof IQInputNumericField>;
export type ContentTonesProps = ComponentProps<typeof IQContentToneContainer>;

export default function ProductDescription({
  productDescriptionByClient,
  numericFieldProps,
  contentTonesProps,
}
:Props) {
  if (productDescriptionByClient === 'yes' || !productDescriptionByClient) return null;

  return (
    <React.Fragment>
      <StyledNumericContainer
        item
        theme={IQTheme}
        xs={6}
      >
        <IQInputNumericField {...numericFieldProps} fullWidth />
      </StyledNumericContainer>
      <Grid
        item
        mb="34px"
        xs={12}
        sx={{
          '& > .MuiGrid-container > .MuiGrid-item': { flexGrow: 1.5, maxWidth: 'none' },
          '& > .MuiGrid-container:first-of-type': { width: '103%' },
        }}
      >
        <StyledContentTonesHeading theme={IQTheme}>
          {t('pages.ecommerce.productCatalog.contentToneHeading')}
        </StyledContentTonesHeading>
        <StyledContentTonesSubHeading theme={IQTheme}>
          {t('pages.ecommerce.productCatalog.contentToneSubHeading')}
        </StyledContentTonesSubHeading>
        <IQContentToneContainer {...contentTonesProps} />
      </Grid>
    </React.Fragment>
  );
}
