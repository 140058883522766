import {
  Button, Card, CardActions, CardContent, Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import { H5 } from 'components/labels/H5';
import { Text } from 'components/labels/Text';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { useState } from 'react';

export interface Props {
  onEdit?: (arg?: unknown) => unknown
  onDelete?: (arg?: unknown) => unknown
  index: number
  data: any
}

export const CoPageCardButton = styled(Button)`
    &.Mui-disabled {
      color: #FFFFFF
    }
    font-weight: 400;
    text-transform: none;
    font-size: 14px;
    line-height: 18px;
    padding: 0 30px 0 0;
    min-width: 0;
    font-family: 'Unify Sans';
    
    svg.MuiSvgIcon-root {
      font-size: 12px;
    }
  `;

export const CoPageCardHeadingStyles = {
  fontWeight: '600',
  opacity: '0.8',
  marginRight: '16px',
};

const styles = makeStyles(
  {
    card: {
      marginTop: '24px',
    },
    innerGrid: {
      marginTop: '9px',
    },
    button: {
      textTransform: 'none',
      padding: '0 20px 0 0',
      minWidth: 0,
    },
    cardContent: {
      paddingTop: '24px',
      paddingRight: '24px',
      paddingLeft: '24px',
      paddingBottom: '3px',
    },
    cardActions: {
      paddingBottom: '24px',
      paddingRight: '24px',
      paddingLeft: '24px',
    },
    boxStyles: {
      minWidth: 275,
      border: `1px solid ${IQThemeV2.palette.common.black}`,
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
      borderRadius: '10px',
    },
  },
);

export default function TrackingLineCard(props: Props) {
  const { t } = useTranslation();
  const [trackingLineModal, setTrackingLineModal] = useState(false);

  const style = styles();

  const deleteModal = () => setTrackingLineModal(true);

  return (
    <Card className={`${style.card} ${style.boxStyles}`} variant="outlined">
      <CardContent className={style.cardContent}>
        <Grid container>
          <Grid item xs={12}>
            <H5>
              {`Tracking Line ${props.index + 1}`}
            </H5>
          </Grid>
          <Grid item xs={3.5} className={style.innerGrid}>
            <Text style={{ ...CoPageCardHeadingStyles }}>
              {t('pages.ccByDash.solutionStrategy.originalNumberLabel')}
              :
            </Text>
          </Grid>
          <Grid item xs={8.5} className={style.innerGrid}>
            <Text>
              {props.data.originalNumber}
            </Text>
          </Grid>
          <Grid item xs={3.5} className={style.innerGrid}>
            <Text style={{ ...CoPageCardHeadingStyles }}>
              {t('pages.ccByDash.solutionStrategy.forwardToNumberLabel')}
              :
            </Text>
          </Grid>
          <Grid item xs={8.5} className={style.innerGrid}>
            <Text>
              {props.data.forwardToNumber}
            </Text>
          </Grid>
          <Grid item xs={3.5} className={style.innerGrid}>
            <Text style={{ ...CoPageCardHeadingStyles }}>
              {t('pages.ccByDash.solutionStrategy.areaCodePreferenceCardLabel')}
              :
            </Text>
          </Grid>
          <Grid item xs={8.5} className={style.innerGrid}>
            <Text>
              {props.data.areaCodePreference}
            </Text>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={style.cardActions}>
        <CoPageCardButton name="edit" endIcon={<ArrowForwardIosIcon />}
          onClick={() => props.onEdit(props.data.id)}
        >
          {t('components.pageCard.buttons.edit')}
        </CoPageCardButton>
        <CoPageCardButton name="delete"
          onClick={() => deleteModal()}
        >
          {t('components.pageCard.buttons.remove')}
        </CoPageCardButton>
      </CardActions>
      <CoModalConfirm open={trackingLineModal}
        title="Remove Tracking Line?"
        description="Are you sure you want to remove the tracking line?"
        confirmText="Remove"
        handleClose={() => setTrackingLineModal(false)}
        handleConfirm={() => props.onDelete(props.data.id)}
      />
    </Card>
  );
}
