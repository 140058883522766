/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import {
  IQAddressMapbox,
  IQCheckbox,
  IQFormInput,
  IQFormSelect,
  IQLabelTooltip,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import {
  Box, FormHelperText, Typography, useTheme,
} from '@mui/material';
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NeSelectChip from 'components/chips/NeSelectChip';
import useMapboxService from 'services/MapboxService';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  selectCurrentLocation,
  updateSingleBusinessLocation,
  getBusinessLocation,
  getBusinessLocationServiceAreas,
  selectBusinessLocationServiceAreas,
  updateBusinessLocationServiceAreas,
  setServiceAreas,
  selectBusinessLocations,
  copyLocation,
  getBusinessLocations,
} from 'services/businessLocationSlice';
import { getOrderBusinessByOrderId } from 'services/businessSlice';
import { matchCountryCode } from 'pages/business/client/ClientPhones';
import { getOrder, selectOrderItems, selectOrdersContent } from 'services/ordersSlice';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { savePlaceDetail, selectPlaceDetail } from 'services/placeDetailSlice';
import { locationsTypes } from 'layouts/StepperLayout';
import { TitleWithBadge } from 'pages/premium-listings/TitleWithBadge';
import { OrderFlow } from 'shared/constants';
import { checkKeyDown } from 'shared/sharedFunctions';
import VerificationModal from './VerificationModal';
import PunchlistPiQBanner from '../PunchlistPiQBanner';

const FormSelectStyled = styled('div')`
  label {
    font-weight: 600;
  }
`;

export default function LocationAddress(props: any) {
  const { type, schema, pointsOfContact } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderId, locationId } = useParams();
  const location = useSelector(selectCurrentLocation);
  const serviceAreaPlaces = useSelector(selectBusinessLocationServiceAreas);
  const [addresses, setAddresses] = useState([]);
  const [places, setPlaces] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [locationItems, setLocationItems] = useState([]);
  const [openVerificationModal, setOpenVerificationModal] = useState(false);
  const [suggestedAddresses, setSuggestedAddresses] = useState([]);
  const [isLocationListEmpty, setIsLocationListEmpty] = useState(false);
  const order = useSelector(selectOrdersContent);
  const { content: orderItems } = useSelector(selectOrderItems);
  const locations = useSelector(selectBusinessLocations);
  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);
  const placeDetails = useSelector(selectPlaceDetail);
  const ordersContent = useSelector(selectOrdersContent);
  const { orderFlow } = ordersContent;
  const isPunchlist = useMemo(() => orderFlow === OrderFlow.PUNCHLIST, [orderFlow]);
  const hasPiQ = useMemo(() => orderItems.some(item => item.pdOfferingType === 'PiQLandingPages'), [orderItems]);

  const isStandard = type === locationsTypes.STANDARD;
  const isPl = ([locationsTypes.PREMIUM_LISTINGS, locationsTypes.HEALTHCARE].includes(type) && !location?.isProvider);
  const isHc = (type === locationsTypes.HEALTHCARE && location?.isProvider);

  const { businessId } = order;

  const {
    countryItems,
    twoLettersCountryEquivalent,
    countryNamesConversion,
    getServiceAreas,
    validateAddress,
    getPlaceDetails,
  } = useMapboxService();

  const calCountry = (location) => {
    const defCountry = matchCountryCode[order?.country] || 'US';
    if (!location) return defCountry;
    const locationLength = location?.length;
    switch (locationLength) {
      case 2:
        return location;
      case 3:
        return twoLettersCountryEquivalent[location];
      default:
        return countryNamesConversion[location] || 'US';
    }
  };

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      ...pointsOfContact,
    },
  });

  const {
    handleSubmit, watch, setValue, getValues, reset, clearErrors, formState: {
      errors, isValid, isDirty, isSubmitSuccessful, isSubmitting,
    },
  } = methods;

  const formData = getValues();
  const country = watch('country');
  const autoCompleteAddress = async (address) => {
    if (address.id !== undefined) {
      const formValues = getValues();
      const placeDetail = await getPlaceDetails(address.id);
      reset({
        ...formValues,
        businessAddressOne: placeDetail.data.addressLine1,
        businessAddressTwo: placeDetail.data.addressLine2,
        stateProvince: placeDetail.data.state,
        city: placeDetail.data.city,
        postalCode: placeDetail.data.zipCode,
        country: placeDetail.data.country,
      });
    }
  };

  const autoCompleteLocation = async (address) => {
    if (address.id !== undefined) {
      const formValues = getValues();
      const placeDetail = await getPlaceDetails(address.id);
      dispatch(savePlaceDetail({
        placeDetail: placeDetail.data,
        addressPagePlaceId: placeDetail.data.placeId,
        hoursPagePlaceId: placeDetails.placeDetail ? placeDetails.placeDetail.placeId : '',
      }));
      reset({
        ...formValues,
        locationName: placeDetail.data.businessName,
        businessAddressOne: placeDetail.data.addressLine1,
        businessAddressTwo: placeDetail.data.addressLine2,
        stateProvince: placeDetail.data.state,
        city: placeDetail.data.city,
        postalCode: placeDetail.data.zipCode,
        country: placeDetail.data.country,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getOrder(orderId));
      dispatch(getOrderBusinessByOrderId(orderId));
      await dispatch(getBusinessLocationServiceAreas(locationId));
      dispatch(getBusinessLocation(locationId));
    };
    fetchData();
  }, [locationId, orderId]);

  useEffect(() => {
    if (location) {
      const locationCountry = calCountry(location?.country);
      const standardFields = {
        locationName: location.locationName,
        businessAddressOne: location.businessAddressOne,
        businessAddressTwo: location.businessAddressTwo,
        stateProvince: location.stateProvince,
        city: location.city,
        postalCode: location.postalCode,
        country: locationCountry,
        serviceAreas: serviceAreaPlaces?.content?.map(s => s.name) || [],
      };
      const listingsFields = {
        doesNotServeClients: location.doesNotServeClients,
        locationIdentifier: location.locationIdentifier,
      };
      if (isStandard) {
        reset(standardFields);
      } else {
        reset({
          ...standardFields,
          ...listingsFields,
        });
      }
      setSelectedPlaces(serviceAreaPlaces?.content?.map(s => ({
        label: s.name,
        value: s.placeId,
      })));
    }
  }, [location, reset]);

  const getSuggestions = async (e) => {
    const address = await getServiceAreas(e.query, country);
    const newServiceAreas = [...address];
    if (selectedPlaces.length > 0) {
      selectedPlaces.forEach(el => {
        const filterArea = newServiceAreas.filter(s => s.value === el.value);
        if (filterArea.length === 0) newServiceAreas.push(el);
      });
    }
    setPlaces(newServiceAreas);
  };

  useEffect(() => {
    const values = getValues('serviceAreas');
    values?.forEach(val => {
      let matchPlace = places.find(place => place.label === val);
      if (matchPlace === undefined) {
        matchPlace = places.find(place => place.value === val);
      }
      const duplicate = selectedPlaces.find(el => el?.value === matchPlace?.value);
      if (matchPlace !== undefined && duplicate === undefined) {
        const newSelected = [...selectedPlaces, matchPlace];
        setSelectedPlaces(newSelected);
      }
    });
  }, [watch('serviceAreas')]);

  const setSubmitResource = (standardResource, plResource, hcResource) => {
    if (isPl) return plResource;
    if (isHc) return hcResource;
    return standardResource;
  };

  const onSubmit = async ({ addressData }) => {
    const standardResource = {
      businessAddressOne: addressData.businessAddressOne,
      businessAddressTwo: addressData.businessAddressTwo,
      businessId: location.businessId,
      city: addressData.city,
      country: addressData.country,
      id: locationId,
      locationName: addressData.locationName,
      postalCode: addressData.postalCode,
      sourceId: null,
      stateProvince: addressData.stateProvince,
    };
    const plResource = {
      ...standardResource,
      locationIdentifier: getValues()?.locationIdentifier,
      doesNotServeClients: addressData.doesNotServeClients,
    };
    const hcResource = {
      ...standardResource,
      locationIdentifier: getValues()?.locationIdentifier,
      provider: true,
    };

    const resource = setSubmitResource(standardResource, plResource, hcResource);

    const requestPlaces = [];
    if (addressData.serviceAreas.length !== selectedPlaces.length) {
      addressData.serviceAreas.forEach((place) => {
        const item = selectedPlaces.find(e => e.label === place);
        if (item !== undefined) {
          requestPlaces.push(item);
        } else {
          const item = selectedPlaces.find(e => e.value === place);
          if (item !== undefined) {
            requestPlaces.push(item);
          }
        }
      });
      const businessLocationServiceAreaList = requestPlaces.map(s => ({ name: s.label, placeId: s.value }));
      await dispatch(updateBusinessLocationServiceAreas({ locationId, businessLocationServiceAreaList }));
    } else {
      const businessLocationServiceAreaList = selectedPlaces.map(s => ({ name: s.label, placeId: s.value }));
      await dispatch(updateBusinessLocationServiceAreas({ locationId, businessLocationServiceAreaList }));
    }

    await dispatch(updateSingleBusinessLocation(resource));
    await dispatch(setServiceAreas([]));
    props.onContinue();
  };

  const addressFormObj = {
    address: formData.businessAddressOne,
    address2: formData.businessAddressTwo,
    city: formData.city,
    state: formData.stateProvince,
    postalCode: formData.postalCode,
    country: formData.country,
  };

  const locationObj = {
    address: location.businessAddressOne,
    address2: location.businessAddressTwo,
    city: location.city,
    state: location.stateProvince,
    postalCode: location.postalCode,
    country: location.country,
  };

  const isAddressChanged = !(_.isEqual(locationObj, addressFormObj));

  const getSuggestedAddresses = async () => {
    const response = await validateAddress(addressFormObj);
    let suggestions = response.data?.suggestions;
    if ((suggestions && suggestions.length > 0) && isAddressChanged) {
      setOpenVerificationModal(true);
      suggestions = suggestions.sort((a, b) => b.relevance - a.relevance);
      setSuggestedAddresses(suggestions);
    } else {
      onSubmit({ addressData: { ...formData, businessAddressTwo: getValues('businessAddressTwo') } });
    }
  };

  const onFormSubmit = () => {
    getSuggestedAddresses();
  };

  const addressFormatter = (addresses) => addresses.map((address) => {
    const name = address.label.split(',');
    const formattedAddress = {
      id: address.value,
      address: name[0].trim(),
      country: '',
    };
    if (name.length > 1) {
      formattedAddress.address += `, ${name[1].trim()}`;
      if (name.length > 2) {
        formattedAddress.country = name.slice(2).map(item => item.trim()).join(', ');
      }
    }
    return formattedAddress;
  });

  // Premium Listings code
  const sabOnly = useMemo(
    () => getValues().doesNotServeClients,
    [watch('doesNotServeClients')],
  );

  useEffect(() => {
    const checkFormValid = () => {
      if (isPl && sabOnly && getValues('serviceAreas').length === 0) {
        return false;
      }

      return isValid;
    };
    props.onUpdateStatus(getValues(), checkFormValid());
  }, [isValid, sabOnly, watch('serviceAreas')]);

  // healthcare code
  useEffect(() => {
    if (businessId && isHc) dispatch(getBusinessLocations(businessId));
  }, [businessId, isHc]);

  useEffect(() => {
    if (isHc) {
      const locationsCopy = JSON.parse(JSON.stringify(locations));
      const mappedItems = locationsCopy.filter(e => e.completed === true)
        .map(({ id, locationName }) => ({ value: id as any, description: locationName }));
      if (locations.filter(e => e.completed === true).length > 0) {
        mappedItems.push(
          {
            value: -1,
            description: t('pages.premiumListings.location.healthcare.address.doNotPrePopulate'),
          },
        );
        setValue('prePopulate', -1);
      } else {
        mappedItems.push(
          {
            value: 'doNot',
            description: t('pages.premiumListings.location.healthcare.address.prePopulateNoLocationsPlaceholder'),
          },
        );
        clearErrors('prePopulate');
        setValue('prePopulate', 'doNot');
      }
      setLocationItems(mappedItems);
    }
  }, [locations, isHc]);

  useEffect(() => {
    if (isHc) {
      if (location.businessAddressOne && locations.length > 0) {
        const prePopulatedLocation = locations.filter(loc => loc.businessAddressOne === location.businessAddressOne);
        if (prePopulatedLocation.length > 0) {
          setValue('prePopulate', prePopulatedLocation[0].id.toString());
        } else setValue('prePopulate', '-1');
      }

      if (locations.length === 0 && location.businessAddressOne) {
        setValue('prePopulate', '-1');
      }
    }
  }, [watch('prePopulate'), locations, location, isHc]);

  useEffect(() => {
    if (isHc) {
      const res = serviceAreaPlaces.content.map(x => ({
        label: x.name,
        value: x.placeId,
      }));
      setPlaces(res);
      setSelectedPlaces(res);
      const resValues = res.map(x => x.value);
      setServiceAreas(resValues);
      setValue('serviceAreas', resValues || []);
    }
  }, [serviceAreaPlaces]);

  const loadProvider = (provider) => {
    setValue('businessAddressOne', provider.businessAddressOne);
    setValue('businessAddressTwo', provider.businessAddressTwo);
    setValue('city', provider.city);
    setValue('stateProvince', provider.stateProvince);
    setValue('postalCode', provider.postalCode);
    setValue('country', provider.country);
    setValue('serviceAreas', provider.serviceAreas?.content?.map(s => s.name) || []);
  };

  const prePopulateLocationChanged = (id) => {
    if (id !== -1) {
      const location = locations.find(loc => loc.id === id);
      loadProvider(location);
      dispatch(copyLocation({ locationId, targetId: id }));
      setTimeout(() => {
        dispatch(getBusinessLocationServiceAreas(id));
      }, 1000);
    }
  };

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid, errors.prePopulate]);

  return (
    <>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          {(isPunchlist && hasPiQ) && (<PunchlistPiQBanner showAlert />)}
          <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
            <GridContainer fullWidth>
              <GridItem marginBottom={0}>
                <Typography variant="overline" marginBottom={0}
                  sx={{ textTransform: 'initial', fontSize: '18px', color: 'text.secondary' }}
                >
                  {isHc ? 'Provider Listing' : 'Locations'}
                </Typography>
              </GridItem>
              <GridItem>
                <Typography variant="h2" marginBottom={3}>{t('pages.business.location.address.title')}</Typography>
                {isStandard ? (
                  <Typography variant="h6">{t('pages.business.location.address.addressDetails')}</Typography>
                ) : (
                  <TitleWithBadge
                    title={isPl
                      ? t('pages.premiumListings.location.address.addressDetails')
                      : t('pages.premiumListings.location.healthcare.address.providerDetails')}
                    badge={t('pages.premiumListings.badge')}
                    tooltip={t('pages.premiumListings.badgeTooltip')}
                  />
                )}
              </GridItem>

              {/* Location name */}
              <GridItem sizes={[12]} marginBottom={0}>
                <IQAddressMapbox
                  getAddressOptions={async (e) => {
                    const address = await getServiceAreas(e.query, country).then((data: any) => {
                      if (data?.length === 0) {
                        setIsLocationListEmpty(true);
                      } else {
                        setIsLocationListEmpty(false);
                      }
                      return data;
                    });
                    const formattedAddressList = addressFormatter(address);
                    setAddresses(formattedAddressList);
                  }}
                  items={addresses}
                  labelText={isHc
                    ? t('pages.premiumListings.location.healthcare.address.providerName')
                    : t('pages.business.location.address.locationName')}
                  onAutoCompleteSelected={autoCompleteLocation}
                  id="locationName"
                  name="locationName"
                  required
                  onAddressChange={() => {}}
                  schema={schema}
                  tooltipText={isStandard
                    ? ''
                    : (
                      <Trans
                        i18nKey={isHc
                          ? 'pages.premiumListings.location.healthcare.address.providerNameTooltip'
                          : 'pages.premiumListings.location.address.locationNameTooltip'}
                        components={{ br: <br /> }}
                      />
                    ) as any}
                  tooltipPlacement="top"
                />
              </GridItem>
              <GridItem>
                {isLocationListEmpty
                && (getValues('locationName') === undefined || getValues('locationName')?.length > 0)
                    && (
                    <FormHelperText style={{
                      color: '#C20F1E', fontSize: '14px', marginTop: '0', marginBottom: '5px',
                    }}
                    >
                      Location not in List
                    </FormHelperText>
                    )}
                <FormHelperText style={{ marginBottom: '0', marginTop: '-25px' }}>
                  If you do not see the business in the dropdown, the business might not have an
                  established Google Business Profile. You will need to manually enter the business
                  information below.
                </FormHelperText>
              </GridItem>

              {/* Healthcare prepopulate locations */}
              {(isHc && !isPunchlist) && (
                <>
                  <GridItem sizes={[12]}>
                    <IQFormSelect
                      id="prePopulate"
                      name="prePopulate"
                      labelText={t('pages.premiumListings.location.healthcare.address.prePopulate')}
                      placeholder={t('pages.premiumListings.location.healthcare.address.prePopulatePlaceholder')}
                      tooltipText={t('pages.premiumListings.location.healthcare.address.prePopulateTooltip')}
                      tooltipPaddingBottom={18}
                      tooltipPlacement="top"
                      items={locationItems}
                      theme={theme}
                      fontLabelWeight="600"
                      fullWidth
                      required
                      schema={schema}
                      disabled={locations.filter(e => e.completed === true).length === 0}
                      onChange={(event) => prePopulateLocationChanged(event.target.value)}
                    />
                  </GridItem>
                  <GridItem>
                    <Box sx={{ minHeight: '20px' }} />
                  </GridItem>
                  <GridItem>
                    <TitleWithBadge
                      title={t('pages.premiumListings.location.healthcare.address.addressDetails')}
                      badge={t('pages.premiumListings.badge')}
                      tooltip={t('pages.premiumListings.badgeTooltip')}
                    />
                  </GridItem>
                </>
              )}

              {/* Business Address Line 1 */}
              <GridItem sizes={[12]}>
                <IQAddressMapbox
                  getAddressOptions={async (e) => {
                    const address = await getServiceAreas(e.query, country);
                    const formattedAddressList = addressFormatter(address);
                    setAddresses(formattedAddressList);
                  }}
                  items={addresses}
                  labelText={t('pages.business.location.address.addressLine1')}
                  onAutoCompleteSelected={autoCompleteAddress}
                  name="businessAddressOne"
                  required
                  onAddressChange={() => {}}
                  schema={schema}
                  tooltipPlacement="top"
                  tooltipText={isStandard ? '' : t('pages.premiumListings.location.address.addressLine1Tooltip')}
                />
              </GridItem>

              {/* Business Address Line 2 */}
              <GridItem sizes={[12]}>
                <IQFormInput
                  id="businessAddressTwo"
                  name="businessAddressTwo"
                  labelText={t('pages.business.location.address.addressLine2')}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                  tooltipText={isStandard ? '' : t('pages.premiumListings.location.address.addressLine2Tooltip')}
                  tooltipPaddingBottom={18}
                  tooltipPlacement="top"
                  tooltipArrow={false}
                />
              </GridItem>

              {/* City / State */}
              <GridItem inlineItems sizes={[6, 6]}>
                <IQFormInput
                  id="city"
                  name="city"
                  labelText={t('pages.business.location.address.city')}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                />
                <FormSelectStyled>
                  <IQFormInput
                    id="stateProvince"
                    name="stateProvince"
                    labelText={t('pages.business.location.address.state')}
                    theme={IQTheme}
                    fontLabelWeight="600"
                    fullWidth
                    schema={schema}
                  />
                </FormSelectStyled>
              </GridItem>

              {/* Postal Code / Country */}
              <GridItem inlineItems sizes={[6, 6]}>
                <IQFormInput
                  id="postalCode"
                  name="postalCode"
                  labelText={t('pages.business.location.address.zipCode')}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                  tooltipText={isStandard
                    ? ''
                    : (
                      <Trans
                        i18nKey="pages.premiumListings.location.address.zipCodeTooltip"
                        components={{ br: <br /> }}
                      />
                    ) as any}
                  tooltipPaddingBottom={18}
                  tooltipPlacement="top"
                  tooltipArrow={false}
                />
                <FormSelectStyled>
                  <IQFormSelect
                    id="country"
                    name="country"
                    labelText={t('pages.business.location.address.country')}
                    items={countryItems}
                    defaultValue="US"
                    theme={IQTheme}
                    fontLabelWeight="600"
                    fullWidth
                    required
                    schema={schema}
                  />
                </FormSelectStyled>
              </GridItem>

              {/* Premium Listings Does Not Serve Clients */}
              {isPl && (
              <GridItem columnSpacing={0}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  '& label': { marginRight: '0' },
                }}
                >
                  <IQCheckbox
                    label={t('pages.premiumListings.location.address.hideAddress')}
                    tooltipText={t('pages.premiumListings.location.address.hideAddressTooltip')}
                    tooltipPaddingBottom={18}
                    tooltipPlacement="top"
                    tooltipArrow={false}
                    field="doesNotServeClients"
                    name="doesNotServeClients"
                  />
                  <IQLabelTooltip
                    labelText=""
                    tooltipText={t('pages.premiumListings.location.address.hideAddressTooltip')}
                    tooltipPlacement="top"
                    arrow={false}
                    hasError={false}
                    theme={IQTheme}
                    required={false}
                  />
                </Box>
              </GridItem>
              )}

              <GridItem>
                <Box sx={{ minHeight: '20px' }} />
              </GridItem>

              <GridItem>
                {isStandard ? (
                  <Typography variant="h6">{t('pages.business.location.address.locationDetails')}</Typography>
                ) : (
                  <TitleWithBadge
                    title={t('pages.premiumListings.location.address.locationDetails')}
                    badge={t('pages.premiumListings.badge')}
                    tooltip={t('pages.premiumListings.badgeTooltip')}
                  />
                )}
              </GridItem>

              {/* Service Areas */}
              <GridItem sizes={[12]}>
                <NeSelectChip label labelText={t('pages.business.location.address.serviceArea')}
                  items={places}
                  name="serviceAreas"
                  placeholder={t('pages.business.location.address.serviceAreaPlaceholder')}
                  values={[]}
                  maxItems={20} freeSolo={false}
                  disableClear required={isPl ? sabOnly : false} schema={schema}
                  theme={IQTheme}
                  getSuggestions={(e) => getSuggestions(e)}
                  supportCustomChips={false}
                  labelTooltipText={isStandard
                    ? ''
                    : (
                      <Trans
                        i18nKey="pages.premiumListings.location.address.serviceAreaTooltip"
                        components={{ br: <br /> }}
                      />
                    )}
                  tooltipPlacement="top"
                />
              </GridItem>

              {/* Listings Location Identifier */}
              {(isPl || isHc) && (
                <GridItem sizes={[12]}>
                  <IQFormInput
                    id="locationIdentifier"
                    name="locationIdentifier"
                    labelText={t('pages.premiumListings.location.address.locationIdentifier')}
                    tooltipText={t('pages.premiumListings.location.address.locationIdentifierTooltip')}
                    tooltipPaddingBottom={18}
                    tooltipPlacement="top"
                    tooltipArrow={false}
                    theme={IQTheme}
                    fontLabelWeight="600"
                    fullWidth
                    schema={schema}
                  />
                </GridItem>
              )}

            </GridContainer>
          </form>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting) && isRedirectedFromOverviewPage}
          />
        </FormProvider>
      </ValidationProvider>
      <VerificationModal
        openModal={openVerificationModal}
        setOpenModal={setOpenVerificationModal}
        onFormSubmit={onSubmit}
        formData={getValues()}
        suggestedAddresses={suggestedAddresses}
      />
    </>
  );
}
