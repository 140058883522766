import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Badge, Box, Button, IconButton, Popover, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeCartItem, selectCartItems, selecteCartAddons, updateCartAddons, updateCartAddonsSelection,
} from 'services/cartSlice';
import { useLocation, useNavigate, useParams } from 'react-router';
import { setImageUrl } from 'services/themesSlice';
import { SEOProductNames, SMMProductNames, getProductName } from 'pages/shopping-cart/ShoppingCart';
import { AddonsObjList } from 'pages/shopping-cart/Constants';
import {
  getOrderItems, removeOrderItem, selectOrderItems, selectOrdersContent,
} from 'services/ordersSlice';
import { Urls } from 'navigation/Urls';
import { mapAddons, mapAddonsToCart } from 'pages/salesforce-entry/OpportunityData';
import { displayThemeName } from 'pages/shopping-cart/CartItemRow';
import { useQuery } from 'pages/themes/details/ThemeDetails';

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
    padding: '30px',
    borderRadius: '8px',
    width: '320px',
  },
}));

export default function CoCart() {
  const query = useQuery();
  const cartItems = useSelector(selectCartItems);
  const cartAddons = useSelector(selecteCartAddons);
  const currentOrder = useSelector(selectOrdersContent);
  const popoverRef = useRef(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { opportunityId } = useParams();
  const { pathname } = useLocation();
  const { content: orderItems } = useSelector(selectOrderItems);
  const [isOpportunity, setIsOpportunity] = useState(false);
  const [filteredCartItems, setFilteredCartItems] = useState([]);

  const isBlue = useMemo(() => query.get('isBlue'), [query]);
  const partnerAccountId = useMemo(() => query.get('partnerAccount'), [query]);
  const checkForBlue = `?isBlue=${isBlue}`;

  const cartSummary = useMemo(() => {
    if (isOpportunity && !pathname.includes('shoppingCart') && filteredCartItems.length > 0) setOpen(true);
    if (filteredCartItems.length === 0) {
      return 'Shopping cart is empty';
    }

    if (filteredCartItems.length === 1) {
      return '1 item added to Cart';
    }

    return `${filteredCartItems.length} items added to Cart`;
  }, [filteredCartItems]);

  const handlePopoverOpen = () => setOpen(true);

  useEffect(() => {
    if (pathname.includes('cef')) {
      setIsOpportunity(true);
    }
  }, [pathname]);

  const handlePopoverClose = () => setOpen(false);

  const addonState = (neonProduct, name) => (mapAddons[neonProduct] === name
    ? false : (cartAddons[mapAddonsToCart[name]] || false));

  const deleteItem = async (item) => {
    if (item?.productType === 'Addon' && isOpportunity) {
      const masterAddonItem = cartItems?.content?.filter(item => item?.productName === 'MasterAddon');
      const resource = {
        emailHosting: addonState(item?.productName, 'Email Hosting'),
        expandableMenus: addonState(item?.productName, 'Expandable Menus'),
        hipaaForms: addonState(item?.productName, 'HIPAA Forms'),
        onlineBooking: addonState(item?.productName, 'Online Booking'),
        paymentForms: addonState(item?.productName, 'Payment Forms'),
        popups: addonState(item?.productName, 'Pop-up'),
        videoBackground: addonState(item?.productName, 'Video Background'),
      };

      await dispatch(updateCartAddonsSelection({ orderItemId: masterAddonItem?.[0]?.orderItem?.id, resource }));
      await dispatch(updateCartAddons(resource as any));
      dispatch(getOrderItems(currentOrder.id));
      return;
    }

    if (item?.productType === 'Addon') {
      dispatch(removeOrderItem(item.id));
    } else {
      dispatch(removeCartItem(item.orderItem?.id));
    }
  };

  const goToCart = () => {
    if (!opportunityId) {
      navigate(partnerAccountId ? `/shoppingCart?partnerAccount=${partnerAccountId}` : '/shoppingCart');
    } else navigate(`/shoppingCart/${Urls.Cef}/${opportunityId}${checkForBlue}`);
  };

  const getImageUrl = (item) => {
    if (item?.productType === 'Addon') {
      return `/assets/addons/${item.thumbnailUrl}`;
    }
    return setImageUrl(item.themeId, item.thumbnailUrl, 'composite_screenshot');
  };

  useMemo(() => {
    if (isOpportunity) {
      const nonAddonItems = cartItems.content.filter(obj => obj.productName !== 'MasterAddon');
      let addonItems = AddonsObjList.filter(obj => orderItems.map(o => o.offeringType).includes(obj.productName));
      addonItems = addonItems.map(obj => Object.assign(obj, orderItems.find(o => o.offeringType === obj.productName)));
      setFilteredCartItems([...nonAddonItems, ...addonItems]);
    } else {
      setFilteredCartItems(cartItems.content);
    }
  }, [cartItems, orderItems, isOpportunity]);

  return (
    <>
      <IconButton aria-label="cart" sx={{ marginLeft: '10px', paddingTop: '14px' }}
        ref={popoverRef}
        aria-haspopup="true"
        onMouseOver={handlePopoverOpen}
        onMouseOut={handlePopoverClose}
        onClick={goToCart}
      >
        <Badge
          color="primary"
          badgeContent={filteredCartItems.length}
          showZero sx={{
            fontSize: '5px',
            '& .MuiBadge-badge': {
              fontSize: '8px',
              minWidth: '10px',
              width: '10px',
              height: '10px',
              marginTop: '2px',
              marginRight: '5px',
              color: 'white',
            },
          }}
        >
          <ShoppingCartIcon htmlColor="white" fontSize="small" />
        </Badge>
      </IconButton>
      <Popover
        sx={{
          pointerEvents: 'none',
        }}
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={open}
        anchorEl={popoverRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        PaperProps={{ onMouseOver: handlePopoverOpen, onMouseLeave: handlePopoverClose }}
      >
        <Box sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '10px',
        }}
        >
          <CheckIcon htmlColor="green" />
          <Typography fontSize="12px" marginLeft="10px">{cartSummary}</Typography>
        </Box>
        {filteredCartItems.map((item) => (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            borderBottom: 'solid 2px #d7d7d7',
            paddingBottom: '20px',
            paddingTop: '20px',
          }}
            key={item.productName}
          >
            <img alt={item.name} src={getImageUrl(item)}
              style={{ width: '72px', height: '72px' }}
            />
            {[...SEOProductNames, ...SMMProductNames].includes(item.productName) ? (
              <Box sx={{ marginLeft: '10px', flexGrow: '1' }}>
                <Typography fontWeight="bold">
                  {getProductName(item)}
                </Typography>
                <Typography>
                  {displayThemeName(item)}
                </Typography>
              </Box>
            ) : (
              <Typography fontWeight="bold" marginLeft="10px" flexGrow="1">
                {getProductName(item)}
              </Typography>
            )}
            <IconButton sx={{ paddingTop: 0 }} onClick={() => deleteItem(item)}>
              <DeleteOutlineIcon color="primary" />
            </IconButton>
          </Box>
        ))}
        {cartItems.content.length > 0 && (
          <Button variant="outlined" sx={{ marginTop: '20px', width: '100%' }}
            onClick={goToCart}
          >
            View Cart
          </Button>
        )}
      </Popover>
    </>
  );
}
