import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IQBigBoxMultipleSelect, ValidationProvider } from '@gannettdigital/shared-react-components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { fetchDataForPut, updateFeatures } from 'shared/pages/features';
import WebsitesType from 'models/WebsitesType';

import animation from 'icons/animation.svg';
import parallax from 'icons/parallax.svg';
import photoGallery from 'icons/photo-gallery.svg';

import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import schema from './AdditionalFeaturesDesign.schema';

const features = [
  {
    id: '0',
    name: 'parallax',
    label: 'Parallax',
    subDescription: 'Free',
    svgIcon: parallax,
    toolTipIcon: InfoOutlinedIcon,
    tooltipText: 'When a user scrolls down the page, parallax background images move at a slower pace '
      + 'than the rest of the content, making the page feel more interactive.',
  },
  {
    id: '1',
    name: 'animation',
    label: 'Animation',
    subDescription: 'Free',
    svgIcon: animation,
    toolTipIcon: InfoOutlinedIcon,
    tooltipText: 'Site animations allow text and images to appear when a user scrolls down the page. '
      + 'These can make websites seem more engaging and modern.',
  },
  {
    id: '2',
    name: 'photoGallery',
    label: 'Photo Gallery',
    subDescription: 'Free',
    svgIcon: photoGallery,
    toolTipIcon: InfoOutlinedIcon,
    tooltipText: 'Photo galleries allow clients to showcase photos of their work in various formats, '
      + 'including albums, slideshows, and other layouts.',
  },
];

interface Props {
  website: WebsitesType
  onSubmit: (data) => any
  onBack: () => any
}

export default function AdditionalFeaturesDesignForm(props: Props) {
  const { t } = useTranslation();

  const { website } = props;

  // react-hook-forms
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      additionalFeatures: updateFeatures(website, features),
    },
  });
  const {
    watch, handleSubmit, formState: {
      isDirty, isSubmitting, isSubmitSuccessful,
    },
  } = methods;

  const onSubmit = (data) => {
    const content = fetchDataForPut(data.additionalFeatures, features);
    props.onSubmit(content);
  };

  const additionalFeatures = watch('additionalFeatures');

  const skipAndContinue = !additionalFeatures.filter(feature => feature.checked).length;

  return (
    <DefaultPageLayout
      disableContinue={false}
      onContinue={handleSubmit(onSubmit)}
      onBack={props.onBack}
      header={t('pages.additionalFeaturesDesign.additionalFeatures')}
      description={t('pages.additionalFeaturesDesign.selectMany')}
      skipAndContinue={skipAndContinue}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <IQBigBoxMultipleSelect
            optionsList={features}
            name="additionalFeatures"
          />
        </FormProvider>
        {/* show the dialog if we changed the field and didn't submit the changes */}
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
