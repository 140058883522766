import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import { IQTheme, ValidationProvider } from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';

import { useParams } from 'react-router';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import { DefaultPageLayout } from '../../../layouts/DefaultPageLayout';
import CoNavigationConfirm from '../../../components/navigation/CoNavigationConfirm';
import BasicInfo from './form-sections/BasicInfo';
import Features from './form-sections/Features';

import { StatusType } from '../../../models/StatusType';
import PopUpType from '../../../models/popUpType';

import schema from './PopUp.schema';
import { PopUpSubmitData } from './PopUpPage';

import useUpdateAssets from '../../../hooks/useUpdateAssets';
import { ProductTypes, setFiles } from '../../../services/fileUploaderSlice';

interface Props {
  onSubmit: (data: FormData) => void;
  onBack: () => void;
  popUp: StatusType<Partial<PopUpType>>['content'];
}

const StyledSubHeading = styled(Typography)(
  ({ theme, mb }: { theme: typeof IQTheme, mb?: string }) => `
  margin-bottom: ${mb || '24px'};
  color: ${theme.palette.common.black};
`,
);

const StyledGridItem = styled(Grid)`
  max-height: 112px;
  max-width: 452px !important;

  div {
    display: grid;
    grid-template-columns: repeat(2, 218px);
    gap: 0 20px;
  }

  label {
    padding-bottom: 0;
    height: 25px;
    size: 20px;
    line-height: 25px;
    margin-bottom: 5px;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }

  button {
    max-height: 32px;
    min-width: max-content;
    margin-top: 8px;
    gap: 8px;
    padding: 4px 8px 4px 4px;
  }
`;

const defaultState = {
  type: '',
  trigger: '',
  primaryTypeLead: '',
  button: false,
  actionDetails: '',
  form: false,
  firstName: false,
  lastName: false,
  email: false,
  phone: false,
  other: false,
  otherText: '',
  image: false,
  timer: false,
  date: '',
  contentDesignInformation: '',
  additionalNotes: '',
  additionalInfo: '',
  formError: '',
  popupImages: [],
  popupGettyImages: [],
};

export type FormData = PopUpSubmitData & { formError: string, popupImages: [] | File[], popupGettyImages: [] | File[] };

const PopUpForm = ({ onSubmit: onFormSubmit, onBack, popUp }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      ...defaultState,
      ...popUp,
      date: popUp.date ? new Date(popUp.date) : '',
    },
  });

  const {
    handleSubmit,
    watch,
    setError,
    clearErrors,
    setValue,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful, errors,
    },
  } = methods;

  const formValues = watch();

  const { triggerAllUpdates, deleteAllAssets } = useUpdateAssets(
    /* Due to current component composition,
       the names are hardcoded and need to
       be changed if necessary
    */
    watch,
    'popupImages',
    'popupGettyImages',
    websiteId?.toString(),
    orderId,
    ProductTypes.POP_UP,
    offeringType as WebsiteOfferingTypes,
  );

  const onSubmit = async (data: FormData | any) => {
    if (data.image) {
      await triggerAllUpdates();
    } else {
      deleteAllAssets();
    }

    await onFormSubmit(data);
    dispatch(setFiles([]));
  };

  useEffect(() => {
    if (popUp.date) {
      methods.trigger('date');
    }
  }, []);

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <Box id="mainForm" component="form" sx={{ display: 'flex', pl: '80px' }}
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <DefaultPageLayout
            disableContinue={
              !isValid
              || (formValues.form && !!errors.formError)
            }
            onBack={onBack}
            header={t('pages.addons.popUp.title')}
            onContinue={handleSubmit(onSubmit)}
          >
            <BasicInfo StyledSubHeading={StyledSubHeading} StyledGridItem={StyledGridItem} theme={IQTheme} />
            <Features
              clearErrors={clearErrors}
              setError={setError}
              errors={errors}
              StyledSubHeading={StyledSubHeading}
              theme={IQTheme}
              formValues={formValues}
              setValue={setValue}
            />
          </DefaultPageLayout>

          {/* show the dialog if we changed the field and didn't submit the changes */}
          <CoNavigationConfirm showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)} />
        </Box>
      </FormProvider>
    </ValidationProvider>
  );
};

export default PopUpForm;
