import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';

export const premiumListingsMenu: MenuType = ({
  label: 'Premium Listings',
  url: '',
  status: 'incomplete',
  offeringType: 'PremiumListing',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
  ],
});
