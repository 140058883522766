import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";

const {t} = i18n;

export const CampaignLinkedinSchema: RJSFSchema | any = (data) => ({
  title: t('Details'),
  type: 'object',
  required: [],
  properties: {
    campaignName: {
      type: 'string',
      title: t('pages.ausProduct.ausLiveChat.chatBot.campaignNameLabel'),
      description: t('pages.ausProduct.ausLiveChat.chatBot.campaignNameDesc'),
      default: data?.campaignName || '',
    }
  },
});

const textFieldKeys = [
  'campaignName',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

export const CampaignLinkedinUISchema = {
  'ui:order': ['campaignName'],

  ...textFields
};

export const CampaignLinkedinCustomValidate = (formData: any, errors, uiSchema) => {
  return errors;
};
