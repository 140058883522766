import { Button, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import {
  IQFormInput, IQFormTextArea, IQTheme, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';
import HipaaFormDefinitionType from 'models/HipaaFormDefinitionType';
import schema from './HipaaFormCard.schema';

export const HipaaFormCardButtonStyled = styled(Button)`
  text-transform: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;  
  width: 135px;
  height: 40px;
  
  &.Mui-disabled {
    color: #FFFFFF;
  }
`;

const InputStyled = styled('div')`
  input {
    padding: 0 16px;
    width: 574px;
  }
  .MuiFormControl-root {
    gap: 0;
    label {
      margin-bottom: 8px;
    }
  }
`;

const styles = makeStyles(
  {
    gridContainer: {
      padding: '0 80px 0 80px',
    },
    grid: {
      marginTop: '12px',
      marginBottom: '12px',
    },
  },
);

export interface HipaaFormCardType {
  hipaaFormCard: HipaaFormDefinitionType,
  onSave?: (pageCardType: HipaaFormDefinitionType) => unknown
}

export default function HipaaFormCard(props: HipaaFormCardType) {
  const { t } = useTranslation();

  const style = styles();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      title: props.hipaaFormCard.title,
      description: props.hipaaFormCard.description,
    },
  });
  const {
    handleSubmit, setValue, register, reset, formState: { isValid },
  } = methods;

  const onSubmit = (data) => {
    props.onSave({ ...props.hipaaFormCard, ...data });
    reset();
  };

  useEffect(() => {
    setValue('title', props.hipaaFormCard.title);
    setValue('description', props.hipaaFormCard.description);
  }, [props.hipaaFormCard]);

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container width="768px" className={style.gridContainer}>
            <Grid item xs={12} className={style.grid}>
              <InputStyled>
                <IQFormInput
                  id="title"
                  name="title"
                  labelText={`${t('pages.addons.hipaaForms.formCardTitle')} *`}
                  fontLabelWeight="600"
                  fullWidth
                  theme={IQTheme}
                  schema={schema}
                />
              </InputStyled>
            </Grid>
            <Grid item xs={12} className={style.grid}>
              <InputStyled>
                <IQFormTextArea
                  {...register('description', { required: 'This is a required field.' })}
                  id="description"
                  name="description"
                  labelText={`${t('pages.addons.hipaaForms.formCardData')} *`}
                  fontLabelWeight="600"
                  rowCount={4}
                  fullWidth
                  showError
                />
              </InputStyled>
            </Grid>
            <Grid item xs={12} className={style.grid}
              style={{ textAlign: 'center', marginTop: 12 }}
            >
              <HipaaFormCardButtonStyled name="save" type="submit" variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}
              >
                {t('pages.addons.hipaaForms.saveButton')}
              </HipaaFormCardButtonStyled>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </ValidationProvider>
  );
}
