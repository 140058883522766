import { ComponentProps } from 'react';

import { t } from 'i18next';

import { IQButtonRadioContainer } from '@gannettdigital/shared-react-components';

export const options :ComponentProps<typeof IQButtonRadioContainer>['optionsList'] = [
  {
    label: t('pages.landing.goals.formSubmissions'),
    field: 'conversionGoal',
    value: 'formSubmissions',
  },
  {
    label: t('pages.landing.goals.phoneCalls'),
    field: 'conversionGoal',
    value: 'phoneCalls',
  },
  {
    label: t('pages.landing.goals.clickThroughToWebsite'),
    field: 'conversionGoal',
    value: 'clickThruToWebsite',
  },
  {
    label: t('pages.landing.goals.other'),
    field: 'conversionGoal',
    value: 'otherGoals',
  },
];
