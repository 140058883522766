import {
  Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteThemeFamily, selectThemeFamilies, getThemeFamilies } from 'services/adminSlice';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

export default function ThemeFamilies() {
  const themeFamilies = useSelector(selectThemeFamilies);
  const dispatch = useDispatch();
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    dispatch(getThemeFamilies({}));
  }, []);

  const deleteTheme = (id) => {
    dispatch(deleteThemeFamily(id));
  };

  return (
    <>
      <Typography variant="h2">Listing Families</Typography>
      <Grid container>
        <Grid item xs={12} marginTop={1}>
          <Link to="/admin/families/new" style={{ marginRight: '10px' }}>
            <Button variant="contained">
              <Typography>New Family</Typography>
            </Button>
          </Link>
          <Link to="/admin/dashboard">
            <Button variant="contained">
              <Typography>Admin Dashboard</Typography>
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} marginTop={5}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ borderBottom: '3px solid lightgray' }}>
                  <TableCell><Typography fontWeight="bold">Name</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Actions</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {themeFamilies.map(family => (
                  <TableRow
                    key={family.id}
                    sx={{
                      border: 0,
                      '& .MuiTableCell-root': { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Typography>
                        {family.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Link to={`${family.id}/edit`} style={{ marginRight: '10px' }}>
                        <Button variant="contained">
                          <Typography>Edit</Typography>
                        </Button>
                      </Link>
                      <Button variant="contained" color="error"
                        onClick={() => setItemToDelete(family)}
                      >
                        <Typography>Destroy</Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DeleteConfirmationModal
            open={!!itemToDelete}
            onClose={() => setItemToDelete(null)}
            onConfirm={() => deleteTheme(itemToDelete?.id)}
            resourceName={itemToDelete?.name} resourceType="Theme Family"
          />
        </Grid>
      </Grid>
    </>
  );
}
