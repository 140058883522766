/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Box, FormHelperText, Typography, useTheme,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { IQButtonLink } from '@gannettdigital/shared-react-components';

export interface IQDraggableListProps {
  id: any;
  items: any;
  addItems?: boolean;
  handleAddItem?: any;
  infoText?: string;
  addButtonLabel?: string;
  buttonDisabled?: boolean;
  standalone?: boolean;
  enableDrag?: boolean;
  draggingFrom?: string;
  droppableAreaCopy?: string;
}

const InfoComponent = ({ infoText }: any) => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginTop: '8px',
    '& p': { marginTop: '0' },
  }}
  >
    <InfoOutlinedIcon color="primary" sx={{ width: '15px', height: '15px' }} />
    <FormHelperText component="p">{infoText}</FormHelperText>
  </Box>
);

const getRenderItem = (items, theme) => (provided, snapshot, rubric) => (
  <Box
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    sx={{
      '& .MuiPaper-root': {
        backgroundColor: snapshot.isDragging ? theme.palette.primary.light : 'background.paper',
        border: snapshot.isDragging
          ? `1px solid ${theme.palette.primary.main}`
          : `border: 1px solid ${theme.palette.action.disabledBackground}`,
        borderRadius: '8px',
      },
      '& .MuiPaper-root:hover': { backgroundColor: theme.palette.primary.light },
    }}
    ref={provided.innerRef}
  >
    {items[rubric.source.index].element}
  </Box>
);

const IQDraggableList = ({
  id,
  items,
  addItems = false,
  handleAddItem,
  infoText,
  addButtonLabel,
  buttonDisabled = false,
  standalone = false,
  enableDrag,
  droppableAreaCopy = 'Drag item here',
  draggingFrom = '',
}: IQDraggableListProps) => {
  const theme = useTheme();
  const [itemList, setItemList] = useState([]);
  const renderItem = getRenderItem(itemList, theme);

  useEffect(() => {
    setItemList(items);
  }, [items]);

  const emptyList = useMemo(() => itemList.length === 0, [itemList]);

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    const updatedList = [...itemList];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setItemList(updatedList);
  };

  const renderButton = () => (
    <Box sx={{
      backgroundColor: `${emptyList ? 'primary.light' : 'transparent'}`,
      padding: `${emptyList ? '0 16px' : '0'}`,
      marginBottom: '10px',
      '& .MuiButtonBase-root': {
        padding: '10px 10px',
      },
    }}
    >
      <IQButtonLink
        startIcon={<AddOutlinedIcon />}
        onClick={handleAddItem}
        disabled={buttonDisabled}
      >
        {addButtonLabel || 'Add Item' }
      </IQButtonLink>
    </Box>
  );

  const renderDropArea = () => (
    <Droppable droppableId={id} renderClone={renderItem}>
      {(provided) => (
        <Box
          {...provided.droppableProps}
          ref={provided.innerRef}
          mb={0}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            minHeight: `${enableDrag ? '120px' : 'auto'}`,
            borderRadius: `${enableDrag ? '8px' : '0'}`,
            backgroundColor: `${enableDrag ? 'primary.light' : 'transparent'}`,
            '& >.MuiBox-root:not(:last-child)': { marginBottom: '16px' },
            '& .MuiPaper-root': { marginBottom: '0' },
          }}
        >
          {(enableDrag && draggingFrom !== id) && (
          <Box
            className="placeholder"
            sx={{
              height: '120px',
              width: '920px',
              background: '#F5F8FF',
              border: `1px dashed ${theme.palette.primary.main}`,
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography>{droppableAreaCopy}</Typography>
          </Box>
          )}
          {itemList.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
              {renderItem}
            </Draggable>
          ))}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );

  return (
    <>
      {standalone && renderDropArea()}
      {!standalone && (
        <DragDropContext onDragEnd={handleDrop}>
          {renderDropArea()}
        </DragDropContext>
      )}
      <Box
        sx={{
          display: 'flex', flexDirection: emptyList ? 'column-reverse' : 'column',
        }}
      >
        {infoText && <InfoComponent infoText={infoText} />}
        {addItems && renderButton()}
      </Box>
    </>
  );
};

export default IQDraggableList;
