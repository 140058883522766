import { Urls } from 'navigation/Urls';

import { GetLandingPageResponse, LandingPageRequestContentType } from 'models/LandingPageType';
import { StatusType } from 'models/StatusType';

import useProduct from 'hooks/useProduct';

import ContentLoader from 'components/contentLoader/ContentLoader';
import TimelineForm from 'pages/landing-pages/campaign/timeline/TimelineForm';

export default function Timeline() {
  const { onBack, product, onSubmit } = useProduct<LandingPageRequestContentType,
  StatusType<GetLandingPageResponse>>(Urls.Timeline);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <TimelineForm
        onSubmit={onSubmit}
        onBack={onBack}
        product={product.content.data}
      />
    </ContentLoader>
  );
}
