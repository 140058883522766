import { Urls } from 'navigation/Urls';
import useProduct from 'hooks/useProduct';
import ContentLoader from 'components/contentLoader/ContentLoader';
import BlogSolutionStrategyForm from './BlogSolutionStrategyForm';

export default function BlogSolutionStrategy() {
  const { product, onSubmit, onBack } = useProduct(Urls.BlogSolutionStrategy);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <BlogSolutionStrategyForm
        product={product.content.data}
        onSubmit={onSubmit}
        onBack={onBack}
      />
    </ContentLoader>
  );
}
