import { Typography } from '@mui/material';
import 'styles/website_fonts.css';

export interface TextProps {
  children: any;
  style?: object;
}

export function Text(props: TextProps) {
  return (
    <Typography gutterBottom component="div"
      sx={{ color: 'text.primary', fontSize: '16px', weight: 400 }} style={props.style}
    >
      {props.children}
    </Typography>
  );
}

export default Text;
