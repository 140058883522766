import { Urls } from 'navigation/Urls';

import useProduct from 'hooks/useProduct';
import EcommerceType, { EcommerceStateObject } from 'models/EcommerceType';
import { StatusType } from 'models/StatusType';

import ContentLoader from 'components/contentLoader/ContentLoader';
import ProductCatalogForm from 'pages/ecommerce/products/product-catalog/ProductCatalogForm';

const ProductCatalog = () => {
  const { product, onBack, onSubmit } = useProduct<EcommerceType,
  StatusType<EcommerceStateObject>>(Urls.ProductCatalog);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <ProductCatalogForm onSubmit={onSubmit} onBack={onBack} product={product.content.data} />
    </ContentLoader>
  );
};

export default ProductCatalog;
