import * as yup from 'yup';

const schema = {
  pageName: yup.string().required('This is a required field.'),
  targetMessaging: yup.string(),
  otherNotes: yup.string(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
