import IQTopNavIcon from 'icons/IQTopNavIcon.svg';

const Maintenance = () => (
  <div>
    <div className="maintenance-background-bar">
      <img className="maintenance-logo" src={IQTopNavIcon} alt="IQTopNavIcon" />
    </div>
    <div>
      <div className="maintenance">
        <h1>Application in maintenance.</h1>
      </div>
    </div>
  </div>
);

export default Maintenance;
