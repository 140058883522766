import * as yup from 'yup';

const schema = {
  subdomain: yup.string()
    .required('Please enter subdomain.')
    .nullable()
    .test(
      'is-www',
      'Subdomain cannot be “www.” Please enter a valid subdomain.',
      (value: string) => !value.toLowerCase().includes('www'),
    ),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
