/* eslint-disable react/jsx-closing-tag-location */
import {
  IQBigBoxCheckboxProps,
  IQFormInput,
  IQFormSelect,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { FormProvider, useForm } from 'react-hook-form';
import {
  FormControlLabel, Grid, Radio, RadioGroup, Typography,
} from '@mui/material';
import * as yup from 'yup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { fetchDataForPut, updateFeatures } from 'shared/pages/features';
import emailHosting from 'icons/email-hosting.svg';
import heroVideo from 'icons/hero-video.svg';
import menu from 'icons/menu.svg';
import onlineBooking from 'icons/online-booking.svg';
import payment from 'icons/payment.svg';
import form from 'icons/form.svg';
import popup from 'icons/popup.svg';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import AddonsType from 'models/AddonsType';
import { Urls } from 'navigation/Urls';
import OrdersItemsType from 'models/OrderItemsType';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectedMasterAddonsDetails, selectCompletedWebsiteUrls,
  updateMasterAddonDetails,
} from 'services/addons/addonsSlice';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import GridItem from 'components/grid/GridItem';
import { defaultInputStyles } from 'pages/general-questions/GeneralQuestionsForm';
import schemaValidate from 'components/schemaValidate';
import ErrorText from 'components/errorText/ErrorText';
import { AddonsFunctions } from './AddonsFunctions';
import schema from './Addons.schema';

// Make sure the name of the properties match what is expected to be saved in the backend
const features: IQBigBoxCheckboxProps[] = [
  {
    id: '0',
    name: Urls.HeroVideo,
    label: 'Hero Video',
    svgIcon: heroVideo,
    toolTipIcon: InfoOutlinedIcon,
    tooltipPlacement: 'top',
    // @ts-ignore
    tooltipText: <p style={{ padding: 4, margin: 0 }}>
      Add a hero video background to the hero section of a website&apos;s homepage.&nbsp;
      <a target="_blank" referrerPolicy="no-referrer" style={{ color: 'white' }}
        href="https://hagar3.localiq.site/" rel="noreferrer"
      >
        View Example
      </a>
    </p>,
  },
  {
    id: '1',
    name: Urls.EmailHosting,
    label: 'Email Hosting',
    svgIcon: emailHosting,
    toolTipIcon: InfoOutlinedIcon,
    tooltipPlacement: 'top',
    tooltipText: <p style={{ padding: 4, margin: 0 }}>
      Create professional, personalized emails utilizing the client&apos;s
      domain (e.g. sales@clientdomain.com). Emails are accessible via an online portal
      desktop email app, and mobile devices.
    </p>,
  },
  {
    id: '2',
    name: Urls.ExpandableMenus,
    label: 'Menu',
    svgIcon: menu,
    toolTipIcon: InfoOutlinedIcon,
    tooltipPlacement: 'top',
    // @ts-ignore
    tooltipText: <p style={{ padding: 4, margin: 0 }}>
      Expandable menu support images, tabs and columns to improve the visual
      experience and organize pages on large sites.&nbsp;
      <a target="_blank" referrerPolicy="no-referrer"
        style={{ color: 'white' }} href="https://cambridge4.localiq.site/" rel="noreferrer"
      >
        View Example
      </a>
    </p>,
  },
  {
    id: '3',
    name: Urls.OnlineBooking,
    label: 'Online Booking',
    svgIcon: onlineBooking,
    toolTipIcon: InfoOutlinedIcon,
    tooltipPlacement: 'top',
    // @ts-ignore
    tooltipText: <p style={{ padding: 4, margin: 0 }}>
      Provide a simple booking widget for users to schedule an online appointment or
      service. Intergrate with Google or Outlook for an additional fee.&nbsp;
      <a target="_blank" referrerPolicy="no-referrer"
        style={{ color: 'white' }} href="https://addison3.localiq.site/" rel="noreferrer"
      >
        View Example
      </a>
    </p>,
  },
  {
    id: '4',
    name: Urls.PaymentForms,
    label: 'Payment Forms',
    svgIcon: payment,
    toolTipIcon: InfoOutlinedIcon,
    tooltipPlacement: 'top',
    // @ts-ignore
    tooltipText: <p style={{ padding: 4, margin: 0 }}>
      Allows businesses to collect simple payments online, such as paying for an invoice or a
      basic service. Integrates with Stripe, Authorize.net or Paypal Payments Pro.&nbsp;
      <a target="_blank" referrerPolicy="no-referrer" style={{ color: 'white' }}
        href="https://parker4.localiq.site/appointment-payment/" rel="noreferrer"
      >
        View Example
      </a>
    </p>,
  },
  {
    id: '5',
    name: Urls.HipaaForms,
    label: 'HIPAA Forms',
    svgIcon: form,
    toolTipIcon: InfoOutlinedIcon,
    tooltipPlacement: 'top',
    tooltipText: <p style={{ padding: 4, margin: 0 }}>
      For medical or dental professionals, comply with HIPAA regulations by
      utilizing secure information collection forms.
    </p>,
  },
  {
    id: '6',
    name: Urls.Popups,
    label: 'Pop-Up',
    svgIcon: popup,
    toolTipIcon: InfoOutlinedIcon,
    tooltipPlacement: 'top',
    // @ts-ignore
    tooltipText: <p style={{ padding: 4, margin: 0 }}>
      Provide a pop-up window for users to be notified of promotions and events,
      click on call-to-actions, or enter information to generate leads.&nbsp;
      <a target="_blank" referrerPolicy="no-referrer" style={{ color: 'white' }}
        href="https://euclid3.localiq.site/pop-up/" rel="noreferrer"
      >
        View Example
      </a>
    </p>,
  },
];

interface Props {
  addons: AddonsType
  included?: AddonsType
  onSubmit: (data, websiteId?) => any
  onBack: () => any
  standalone?: boolean
  orderItems?: OrdersItemsType[]
}

export default function AddonsHomeForm(props: Props) {
  const dispatch = useDispatch();
  const { content: completedUrls } = useSelector(selectCompletedWebsiteUrls);
  const { content: masterAddons } = useSelector(selectedMasterAddonsDetails);
  const [localiqWebsite, setLocaliqWebsite] = useState('');
  const [localiqWebsiteLive, setLocaliqWebsiteLive] = useState('');

  const { orderItemId } = useParams();
  const {
    addons, standalone, included,
  } = props;

  let updatedFeatures = updateFeatures(addons, features);

  // check if we have at least one pre-selected feature
  const numberSelected = updatedFeatures.reduce((prev, f) => (f.checked ? prev + 1 : prev), 0);

  if (numberSelected) {
    updatedFeatures = updatedFeatures.map(f => ({
      ...f,
      subDescription: f.checked ? 'Selected' : 'Available',
      backgroundColor: f.checked && '#EBF4FF',
    }));
  }

  if (included) {
    updatedFeatures = updatedFeatures.map(f => ({
      ...f,
      subDescription: included[f.name] ? 'Included' : f.subDescription,
      backgroundColor: included[f.name] && '#EBF4FF',
    }));
  }

  // react-hook-forms
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      addons: updatedFeatures,
      localiqWebsite: masterAddons.at(0)?.localiqWebsite || null,
      localiqWebsiteLive: masterAddons.at(0)?.localiqWebsiteLive || null,
      businessWebsiteUrl: masterAddons.at(0)?.businessWebsiteUrl || '',
      manualInputUrl: masterAddons.at(0)?.manualInputUrl || '',
      temporaryWebsiteUrl: masterAddons.at(0)?.temporaryWebsiteUrl || '',
    },
  });
  const {
    handleSubmit, setValue, trigger, getValues, watch, unregister, register, formState: {
      errors, isDirty, isValid, isSubmitting, isSubmitSuccessful,
    },
  } = methods;

  const onSubmit = (data) => {
    const content = fetchDataForPut(data.addons, features);
    if (standalone) {
      dispatch(updateMasterAddonDetails({
        orderItemId,
        resource: {
          businessWebsiteUrl: data.businessWebsiteUrl,
          localiqWebsite: data.localiqWebsite,
          localiqWebsiteLive: data.localiqWebsiteLive,
          temporaryWebsiteUrl: data.temporaryWebsiteUrl,
          manualInputUrl: data.manualInputUrl,
        },
      }));
    }
    props.onSubmit(content);
  };

  useEffect(() => {
    if (masterAddons.length > 0) {
      setValue('businessWebsiteUrl', masterAddons.at(0)?.businessWebsiteUrl);
      setValue('localiqWebsite', masterAddons.at(0)?.localiqWebsite);
      setLocaliqWebsite(masterAddons.at(0)?.localiqWebsite);
      setLocaliqWebsiteLive(masterAddons.at(0)?.localiqWebsiteLive);
      setValue('localiqWebsiteLive', masterAddons.at(0)?.localiqWebsiteLive);
      setValue('manualInputUrl', masterAddons.at(0)?.manualInputUrl);
      setValue('temporaryWebsiteUrl', masterAddons.at(0)?.temporaryWebsiteUrl);
      trigger();
    }
  }, [masterAddons]);

  const conditionalSchema = () => {
    if (standalone) {
      const addonsSchema = {
        ...schema.yupValidations,
        addons: yup.array().min(1, 'Please select at least one addon to continue.'),
      };
      return {
        yupValidations: addonsSchema,
        customValidations: {
        },
      };
    }
    return schema;
  };

  const website = watch('localiqWebsite');
  const websiteLive = watch('localiqWebsiteLive');

  useEffect(() => {
    if (website === 'No') {
      unregister(['localiqWebsiteLive', 'businessWebsiteUrl', 'manualInputUrl', 'temporaryWebsiteUrl']);
      setValue('localiqWebsiteLive', '');
      setValue('businessWebsiteUrl', null);
      setValue('temporaryWebsiteUrl', '');
      setValue('manualInputUrl', '');
    }
  }, [website]);

  useEffect(() => {
    if (websiteLive === 'Yes') {
      unregister('temporaryWebsiteUrl');
      setValue('temporaryWebsiteUrl', '');
    } else if (websiteLive === 'No') {
      unregister(['businessWebsiteUrl', 'manualInputUrl']);
      setValue('businessWebsiteUrl', null);
      setValue('manualInputUrl', '');
    }
  }, [websiteLive]);

  useEffect(() => {
    if (getValues('businessWebsiteUrl') === 'manual') {
      unregister('manualInputUrl');
      setValue('manualInputUrl', '');
    }
  }, [watch('businessWebsiteUrl')]);

  const hasErrorInWebsiteUrl = !!errors?.businessWebsiteUrl;

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onContinue={handleSubmit(onSubmit)}
      onBack={props.onBack}
      header="Details"
    >
      <ValidationProvider schema={conditionalSchema()}>
        <FormProvider {...methods}>
          <Grid container sx={{ ...defaultInputStyles }}>

            <GridItem marginBottom={1}>
              <Typography variant="subtitle2" component="h3" fontSize={16}>
                Does this client have a LocaliQ Website? *
              </Typography>
              <RadioGroup
                aria-labelledby="localiqWebsite"
                name="localiqWebsite"
                value={localiqWebsite}
                onChange={(e) => setLocaliqWebsite(e.target.value)}
                row
              >
                {['Yes', 'No'].map(opt => (
                  <FormControlLabel
                    key={opt}
                    control={<Radio value={opt} />}
                    label={opt}
                    {...register('localiqWebsite', {
                      validate: (value) => schemaValidate(value, 'localiqWebsite', schema),
                    })}
                  />
                ))}
              </RadioGroup>
            </GridItem>

            {website === 'Yes' && (
              <GridItem marginBottom={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}>
                  Is the LocaliQ website live? *
                </Typography>
                <RadioGroup
                  aria-labelledby="localiqWebsiteLive"
                  name="localiqWebsiteLive"
                  value={localiqWebsiteLive}
                  onChange={(e) => setLocaliqWebsiteLive(e.target.value)}
                  row
                >
                  {['Yes', 'No'].map(opt => (
                    <FormControlLabel
                      key={opt}
                      control={<Radio value={opt} />}
                      label={opt}
                      {...register('localiqWebsiteLive', {
                        validate: (value) => schemaValidate(value, 'localiqWebsiteLive', schema),
                      })}
                    />
                  ))}
                </RadioGroup>
              </GridItem>
            )}

            {websiteLive === 'Yes' && (
              <>
                {/* Website Url Dropdown */}
                <GridItem marginBottom={3}>
                  <Grid item xs={6}>
                    <IQFormSelect
                      name="businessWebsiteUrl"
                      fontLabelWeight="600"
                      items={[{
                        value: 'manual',
                        description: 'Live website not listed. Enter manually.',
                      }, ...AddonsFunctions.getItems(completedUrls)]}
                      labelText="Website URL *"
                      placeholder="Select a completed website"
                      fullWidth
                      showError={false}
                    />
                    <ErrorText
                      hasError={hasErrorInWebsiteUrl}
                      errorText="This is a required field."
                    />
                  </Grid>
                </GridItem>

                {/* Website URL Text Field */}
                {getValues('businessWebsiteUrl') === 'manual' && (
                  <GridItem marginBottom={3}>
                    <Grid item xs={6}>
                      <IQFormInput
                        id="manualInputUrl"
                        theme={IQTheme}
                        labelText="Website URL *"
                        fontLabelWeight="600"
                        name="manualInputUrl"
                        fullWidth
                        adornmentIcon={<InsertLinkIcon />}
                        customValidation={schema.yupValidations.manualInputUrl}
                      />
                    </Grid>
                  </GridItem>
                )}
              </>
            )}

            {/* Temporary Website URL */}
            {websiteLive === 'No' && (
              <GridItem marginBottom={3}>
                <Grid item xs={6}>
                  <IQFormInput
                    id="temporaryWebsiteUrl"
                    theme={IQTheme}
                    labelText="Temporary Website URL *"
                    fontLabelWeight="600"
                    name="temporaryWebsiteUrl"
                    fullWidth
                    adornmentIcon={<InsertLinkIcon />}
                    customValidation={schema.yupValidations.temporaryWebsiteUrl}
                  />
                </Grid>
              </GridItem>
            )}
          </Grid>
        </FormProvider>
        {/* show the dialog if we changed the field and didn't submit the changes */}
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
