import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  IQAddressVerificationModal,
} from '@gannettdigital/shared-react-components';
import { useEffect, useState } from 'react';
import useMapboxService from 'services/MapboxService';
import { putProduct } from 'services/productsSlice';
import { selectActiveOfferingType } from 'services/navigationSlice';

interface Props {
  openModal: boolean;
  locations: any;
  location: any;
  setOpenModal: (data) => any
  formData: any;
}

const emptyAddressObj = {
  id: '',
  name: '',
  address: '',
  place: '',
  region: '',
  postalCode: '',
  country: '',
  relevance: null,
  matchingText: '',
  matchingPlaceName: '',
  centerPoint: [],
  includeNameInSuggestion: null,
};

const emptyVerificationAddressesObj = { entered: emptyAddressObj, suggested: emptyAddressObj };

export default function VerificationModal(props: Props) {
  const {
    openModal, setOpenModal, formData, locations, location,
  } = props;

  const offeringType = useSelector(selectActiveOfferingType);

  const { orderId, orderItemId } = useParams();
  const dispatch = useDispatch();

  const { validateAddress, twoLettersCountryEquivalent } = useMapboxService();

  const [suggestedAddresses, setSuggestedAddresses] = useState([]);
  const [verificationAddresses, setVerificationAddresses] = useState(emptyVerificationAddressesObj);

  const updatedLocationsArray = (formObject) => locations.map(item =>
    ((item.locationId === location.locationId ? { ...item, ...formObject } : item)));

  const onVerificationSubmit = (selected) => {
    let revisedData;
    if (selected) {
      const selectedAddress = suggestedAddresses.filter(e => e.id === selected);

      const {
        address, place, region, country, postalCode,
      } = selectedAddress[0];

      const updatedFormData = {
        ...formData,
        ...{
          addressOne: address,
          city: place,
          stateProvinceRegion: region,
          zipcode: postalCode,
          country: twoLettersCountryEquivalent[country],
        },
      };
      revisedData = updatedLocationsArray(updatedFormData);
    } else {
      revisedData = updatedLocationsArray(formData);
    }

    dispatch(putProduct({
      orderItemId, content: { locations: revisedData },
    }));
  };

  const addressObj = {
    address: formData.addressOne,
    address2: formData.addressTwo,
    city: formData.city,
    state: formData.stateProvinceRegion,
    postalCode: formData.zipcode,
    country: formData.country,
  };

  const setVerificationModalData = () => {
    const enteredAddress = {
      id: '',
      name: formData.addressOne,
      address: formData.addressOne,
      place: formData.city,
      region: formData.stateProvinceRegion,
      postalCode: formData.zipcode,
      country: formData.country,
      relevance: null,
      matchingText: '',
      matchingPlaceName: '',
      centerPoint: [],
      includeNameInSuggestion: null,
    };

    const verificationAddressesObj = {
      entered: enteredAddress,
      suggested: suggestedAddresses?.length > 0 ? suggestedAddresses[suggestedAddresses.length - 1] : emptyAddressObj,
    };

    setVerificationAddresses({ ...verificationAddressesObj });
  };

  useEffect(() => {
    const getSuggestions = async () => {
      const response = await validateAddress(addressObj);
      const suggestions = response.data?.suggestions;
      setSuggestedAddresses(suggestions);
    };

    if (openModal) {
      getSuggestions();
    }
  }, [openModal]);

  useEffect(() => {
    setVerificationModalData();
  }, [suggestedAddresses]);

  return (
    <IQAddressVerificationModal
      open={openModal}
      handleClose={() => setOpenModal(false)}
      onSubmit={selected => onVerificationSubmit(selected)}
      onEdit={() => setOpenModal(false)}
      addresses={verificationAddresses}
    />
  );
}
