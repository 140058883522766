import i18n from 'i18n/i18n';
import {
  MultiMediaGeneralAdInformationCustomValidate,
  MultiMediaGeneralAdInformationSchema, MultiMediaGeneralAdInformationUISchema
} from "pages/generic-product/details/product-schema/multi-media/MultiMediaGeneralAdInformationSchema";
import {
  DisplayAndNativeCreativesCustomValidate,
  DisplayAndNativeCreativesSchema, DisplayAndNativeCreativesUISchema
} from "pages/generic-product/details/product-schema/multi-media/DisplayAndNativeCreativesSchema";
import {
  FacebookInstagramCreativesCustomValidate,
  FacebookInstagramCreativesSchema, FacebookInstagramCreativesUISchema
} from "pages/generic-product/details/product-schema/multi-media/FacebookInstagramCreativesSchema";
import {
  EblastCustomValidate,
  EblastSchema,
  EblastUISchema
} from "pages/generic-product/details/product-schema/multi-media/EblastSchema";
import {
  MontageMotionGraphicCustomValidate,
  MontageMotionGraphicSchema, MontageMotionGraphicUISchema
} from "pages/generic-product/details/product-schema/multi-media/MontageMotionGraphicSchema";

const {t} = i18n;

export const MultiMediaCreativesSchema = (data) => {
  return [
    {
      type: 'object',
      title: t('pages.multiMediaCreatives.multiMediaGeneralAdInformation.title'),
      schema: MultiMediaGeneralAdInformationSchema(data),
      uiSchema: MultiMediaGeneralAdInformationUISchema,
      customValidate: MultiMediaGeneralAdInformationCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.multiMediaCreatives.displayAndNativeCreatives.title'),
      schema: DisplayAndNativeCreativesSchema(data),
      uiSchema: DisplayAndNativeCreativesUISchema,
      customValidate: DisplayAndNativeCreativesCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.multiMediaCreatives.facebookInstagramCreatives.title'),
      schema: FacebookInstagramCreativesSchema(data),
      uiSchema: FacebookInstagramCreativesUISchema,
      customValidate: FacebookInstagramCreativesCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.multiMediaCreatives.eblast.title'),
      schema: EblastSchema(data),
      uiSchema: EblastUISchema,
      customValidate: EblastCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.multiMediaCreatives.montageMotionGraphic.title'),
      schema: MontageMotionGraphicSchema(data),
      uiSchema: MontageMotionGraphicUISchema,
      customValidate: MontageMotionGraphicCustomValidate,
    },
  ];
};

export const MultiMediaCreativesUISchema = (data) => ({
  'ui:order': [
    ...MultiMediaGeneralAdInformationUISchema['ui:order'],
    ...DisplayAndNativeCreativesUISchema['ui:order'],
    ...FacebookInstagramCreativesUISchema['ui:order'],
    ...EblastUISchema['ui:order'],
    ...MontageMotionGraphicUISchema['ui:order'],
  ],
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const MultiMediaCreativesCustomValidate = (formData: any, errors: any, uiSchema: any) => errors;
