import React, {useEffect, useState} from "react";
import {Box} from "@mui/system";
import {Alert, Button, Grid, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import ContentLoader from 'components/contentLoader/ContentLoader';
import {
  getSharedData,
  storeUserDetails
} from "pages/admin/facebook-url/FacebookUrlApi";
import {t} from "i18next";

export default function FacebookUrl() {
  const navigate = useNavigate();
  const [url, setUrl] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(true);
  const [accessAvailable, setAccessAvailable] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const params: any = useParams();

  const validateAccessRequest = async () => {
    try {
      setLoader(true);
      const responseStatusCode = await getSharedData(params);
      if (responseStatusCode && responseStatusCode === 200) {
        setAccessAvailable(true);
      } else if (responseStatusCode && responseStatusCode === 409) {
        setTimeout(() => {
          navigate('/public/fb/facebook_url/how_to_grant_access')
        }, 2000)
      } else {
        setErrorMessage(t('facebookUrl.invalidUrlErrorMessage'));
      }
    } catch (error) {
      setErrorMessage(t('facebookUrl.invalidUrlErrorMessage'));
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 2000)
    }
  }

  useEffect(() => {
    validateAccessRequest()
  }, [params]);

  const handleUrlChange = (event) => {
    const inputUrl = event.target.value;
    setUrl(inputUrl);
    setIsValid(inputUrl.startsWith("https://www.facebook.com/"));
  };

  const handleOnSubmitButton = async () => {
    try {
      setAccessAvailable(false);
      setLoader(true);
      const accountId = params.url.split('-')[0];
      const requestBody = {
        "accountId": accountId,
        "facebookUrl": url
      }
      const responseData = await storeUserDetails(requestBody)
      if (responseData?.statusCode === 204) {
        setErrorMessage("")
        navigate('/public/fb/facebook_url/how_to_grant_access')
      } else {
        setErrorMessage(t('facebookUrl.errorMessage'))
        setAccessAvailable(true);
      }
    } catch (error) {
      setErrorMessage(t('facebookUrl.errorMessage'))
      setAccessAvailable(true);
    } finally {
      setLoader(false);
    }
  }

  return (
      <>
        {errorMessage && !loader &&
            <Alert severity="info"
                   sx={{mb: 2, backgroundColor: '#C20F1E', color: 'white'}}>{errorMessage}</Alert>
        }
        {loader && <ContentLoader status={'loading'}/>}
        {accessAvailable && !loader &&
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="80vh"
            >
              <Grid
                  container
                  direction="column"
                  spacing={2}
                  p={3}
                  pl={{xs: 3, md: 1}}
                  bgcolor="white"
                  boxShadow={3}
                  borderRadius={2}
                  maxWidth={600}
              >
                <Grid item>
                  <Typography fontWeight={600} variant="h4" gutterBottom>
                    {t('facebookUrl.facebookPageAccessRequestTitle')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" gutterBottom>
                    {t('facebookUrl.pleaseEnterYourFacebookPageUrlTitle')}
                  </Typography>
                </Grid>
                <Grid item width="100%">
                  <TextField
                      fullWidth
                      label={t('facebookUrl.facebookUrlLabel')}
                      variant="outlined"
                      placeholder={t('facebookUrl.facebookUrlPlaceholder')}
                      value={url}
                      onChange={handleUrlChange}
                      error={!isValid && url !== ""}
                      helperText={!isValid && url !== "" ? t('facebookUrl.helperText') : ""}
                  />
                </Grid>
                <Grid item width="100%">
                  <Box display="flex" justifyContent="center" mt={2}>
                    <Button
                        onClick={handleOnSubmitButton}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!isValid || url === ""}
                        sx={{
                          '&.Mui-disabled': {
                            color: 'white',
                          }
                        }}
                    >
                      {t('facebookUrl.sendAccessRequestButtonLabel')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
        }
      </>
  );
}