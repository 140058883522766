import i18n from "i18n/i18n";
const { t } = i18n;

export const countryList = [
    {
        value: t("pages.xmo.targetedDisplay.campaignInfo.countryOptions.USA"),
        description: t("pages.xmo.targetedDisplay.campaignInfo.countryOptions.USA")
    },
    {
        value: t("pages.xmo.targetedDisplay.campaignInfo.countryOptions.Canada"),
        description: t("pages.xmo.targetedDisplay.campaignInfo.countryOptions.Canada")
    }
];

export const targetingTypeList = [
    {
        value: t("pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.country"),
        description: t("pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.country")
    },
    {
        value: t("pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.state"),
        description: t("pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.state")
    },
    {
        value: t("pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.metro"),
        description: t("pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.metro")
    },
    {
        value: t("pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.city"),
        description: t("pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.city")
    },
    {
        value: t("pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.radius"),
        description: t("pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.radius")
    },
    {
        value: t("pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.zip"),
        description: t("pages.xmo.targetedDisplay.campaignInfo.targetingTypeOptions.zip")
    }
];

export const campaignGoalsList = [
    {
        value: t("pages.xmo.targetedDisplay.campaignInfo.campaignGoalsOptions.branding"),
        description: t("pages.xmo.targetedDisplay.campaignInfo.campaignGoalsOptions.branding")
    },
    {
        value: t("pages.xmo.targetedDisplay.campaignInfo.campaignGoalsOptions.footTraffic"),
        description: t("pages.xmo.targetedDisplay.campaignInfo.campaignGoalsOptions.footTraffic")
    },
    {
        value: t("pages.xmo.targetedDisplay.campaignInfo.campaignGoalsOptions.targetedWebsiteVisitors"),
        description: t("pages.xmo.targetedDisplay.campaignInfo.campaignGoalsOptions.targetedWebsiteVisitors")
    },
    {
        value: t("pages.xmo.targetedDisplay.campaignInfo.campaignGoalsOptions.formSubmissions"),
        description: t("pages.xmo.targetedDisplay.campaignInfo.campaignGoalsOptions.formSubmissions")
    },
    {
        value: t("pages.xmo.targetedDisplay.campaignInfo.campaignGoalsOptions.highPriorityPageViews"),
        description: t("pages.xmo.targetedDisplay.campaignInfo.campaignGoalsOptions.highPriorityPageViews")
    },
];

export const yesNoList = [
    { value: 'Yes', description: t('form.big-box-labels.yes') },
    { value: 'No', description: t('form.big-box-labels.no') },
];

export const flightedCampaignList = [
    {
        value: t('pages.targetedDisplay.solutionStrategy.flightedCampaignOptions.yesOption'),
        description: t('pages.targetedDisplay.solutionStrategy.flightedCampaignOptions.yesOption')
    },
    {
        value: t('pages.targetedDisplay.solutionStrategy.flightedCampaignOptions.noOption'),
        description: t('pages.targetedDisplay.solutionStrategy.flightedCampaignOptions.noOption')
    },
];