import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import {Urls} from 'navigation/Urls';
import LeadAdsForm from './LeadAdsForm';
import LeadAdsFormDynamic  from '../dynamic/XmoDynamicForm'

export default function LeadAds() {
  const {product, onSubmit, onBack} = useProduct(Urls.XmoLeadAds);

  return (
          <ContentLoader status={product.status} message={product.message}>
          {
          (product?.content?.data?.socialSmartOptimization?.selectCampaignGoalFocus == 'Contacts Ads - Dynamics Ads (Auto Only)') ? (
            <LeadAdsFormDynamic
              product={product?.content?.data?.leadAds}
              onSubmit={onSubmit}
              onBack={onBack}
            />
          ) : (
            <LeadAdsForm
            product={product?.content?.data?.leadAds}
            onSubmit={onSubmit}
            onBack={onBack}
            />
          )
      }
        </ContentLoader>
  );
}
