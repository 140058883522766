import i18n from 'i18n/i18n';

import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/NotesAndInstructionsSchema";
import {
  CampaignCustomValidate,
  CampaignSchema,
  CampaignUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/CampaignSchema";
import {
  PremiumListings1To9CustomValidate,
  PremiumListings1To9Schema,
  PremiumListings1To9UISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/PremiumListings1To9Schema";

const {t} = i18n;

export const AusPremiumListings1To9Schema = (data) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.ausProduct.ausLiveChat.chatBot.campaignTitle'),
      schema: CampaignSchema(data),
      uiSchema: CampaignUISchema,
      customValidate: CampaignCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.premiumListing.title'),
      schema: PremiumListings1To9Schema(data),
      uiSchema: PremiumListings1To9UISchema,
      customValidate: PremiumListings1To9CustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ];

  return schema;
};

export const AusPremiumListings1To9UISchema = (data) => ({
  'ui:order': [
    ...CampaignUISchema['ui:order'],
    ...PremiumListings1To9UISchema['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

export const AusPremiumListings1To9Validate = (formData: any, errors: any, uiSchema: any) => errors;
