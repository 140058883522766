/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';

const { t } = i18n;

export const SeoContentPagesSchema: RJSFSchema = {
  title: t('pages.seoAddons.contentPages.pageTitle'),
  // eslint-disable-next-line max-len
  description: 'pages.seoAddons.contentPages.pageDescription',
  type: 'object',
  required: ['topicsToPrioritize'],
  properties: {
    topicsToPrioritize: {
      type: 'string',
      title: t('pages.seoAddons.contentPages.topicsToPrioritizeLabel'),
      default: '',
    },
    topicsToAvoid: {
      type: 'string',
      title: t('pages.seoAddons.contentPages.topicsToAvoidLabel'),
      default: '',
    },
    additionalNotes: {
      type: 'string',
      title: t('pages.seoAddons.contentPages.additionalNotesLabel'),
      default: '',
    },
  },
};

const textFieldKeys = [
  'topicsToPrioritize',
  'topicsToAvoid',
  'additionalNotes',
];

const textFields = textFieldKeys.reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'TextareaWidget' } }), {});

export const SeoContentPagesUISchema = {
  ...textFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SEOContentPagesCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.topicsToPrioritize === '') {
    errors.topicsToPrioritize.addError(t('pages.seoAddons.contentPages.topicsToPrioritizeError'));
  }
  return errors;
};
