import { ComponentProps } from 'react';
import { useFormContext } from 'react-hook-form';

import { IQFormInput, IQTheme } from '@gannettdigital/shared-react-components';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';

type StyledGridProps = {
  theme: typeof IQTheme;
  hasError?: boolean;
  marginTop?: string;
  gap?: string;
  domainFieldsMaxWidth?: string;
  extensionFieldMaxWidth?: string;
  spanMarginBottom?: string;
  letterSpacing?: string;
};

// const propsToIgnore = [
//   'marginTop',
//   'gap',
//   'domainFieldsMaxWidth',
//   'extensionFieldsMaxWidth',
//   'spanMarginBottom',
//   'letterSpacing',
// ];

const StyledGrid = styled(Grid)<StyledGridProps>`
  display: flex;
  align-items: end;
  gap: ${props => props.gap || '8px'};
  margin-top: ${props => props.marginTop || '52px'};
  margin-bottom: ${props => (props.hasError ? '44px' : 0)};
  
  .MuiGrid-item {
    display: flex;
  }
  
  span {
    margin-bottom: ${props => props.spanMarginBottom || '8px'};
    font-family: ${props => props.theme.typography.fontFamily};
    font-weight: ${props => props.theme.typography.fontWeightBold};
    letter-spacing: ${props => props.letterSpacing || '0.25px'};
  };
`;

interface Props {
  subDomainOptions: FieldOptions;
  domainOptions: FieldOptions;
  extensionOptions: FieldOptions;
  guidingText?: string;
  separator?: string;
  styles?: Omit<StyledGridProps, 'theme'>;
  xs?: number;
}

type FieldOptions = Omit<ComponentProps<typeof IQFormInput>, 'theme'>;

export default function CoDomainField({
  domainOptions,
  subDomainOptions,
  extensionOptions,
  guidingText = 'https://',
  separator = '.',
  styles,
  xs = 12,
} :Props) {
  const { formState: { errors } } = useFormContext();

  const hasError = !!errors[domainOptions.name] || !!errors[subDomainOptions.name] || !!errors[extensionOptions.name];

  return (
    <StyledGrid
      theme={IQTheme}
      container
      hasError={hasError}
      xs={xs}
      {...styles}
    >
      <Grid item>
        <span>{guidingText}</span>
      </Grid>
      <Grid item xs={3.5}>
        <IQFormInput {...subDomainOptions} fullWidth theme={IQTheme} />
      </Grid>
      <Grid item>
        <span>{separator}</span>
      </Grid>
      <Grid item xs={3.5}>
        <IQFormInput {...domainOptions} fullWidth theme={IQTheme} />
      </Grid>
      <Grid item>
        <span>{separator}</span>
      </Grid>
      <Grid item xs={1.5}>
        <IQFormInput {...extensionOptions} fullWidth theme={IQTheme} />
      </Grid>
    </StyledGrid>
  );
}
