import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThemeBenefit, createThemeBenefit } from 'services/adminSlice';
import BenefitForm from './BenefitForm';

export default function NewBenefit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const saveThemeBenefit = async (benefit) => {
    await dispatch(await createThemeBenefit(benefit));
    navigate('/admin/themeBenefits');
  };

  const emptyBenefit: ThemeBenefit = { id: 0, title: '', description: '' };

  return (
    <>
      <Typography variant="h2">New Benefits</Typography>
      <BenefitForm onSubmit={saveThemeBenefit} benefit={emptyBenefit} />
    </>
  );
}
