import { FormProvider, useForm } from 'react-hook-form';

import ChatSilverType from 'models/ChatSilverType';
import { IQFormInput, IQTheme, ValidationProvider } from '@gannettdigital/shared-react-components';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import schema from 'pages/chat/business/BusinessPage.schema';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Box from '@mui/material/Box';

import i18n from 'i18n/i18n';
import { Grid } from '@mui/material';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';

const { t } = i18n;

interface Props {
  product: ChatSilverType;
  onSubmit: (data: BusinessFormProps) => void;
  onBack: () => void;
}

export type BusinessFormProps = {
  businessUrl: string;
};

export default function BusinessPageForm({ product, onSubmit, onBack }: Props) {
  const methods = useForm<BusinessFormProps>({
    mode: 'onChange',
    defaultValues: {
      businessUrl: product?.businessUrl || '',
    },
  });

  const handleFormSubmit = (data: BusinessFormProps) => onSubmit(data);

  const {
    handleSubmit,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <Box
          id="mainForm"
          component="form"
          sx={{ display: 'flex' }}
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <DefaultPageLayout
            disableContinue={!isValid}
            onBack={onBack}
            header={t('pages.chat.businessUrl.header')}
            onContinue={handleSubmit(handleFormSubmit)}
          >
            <Grid container xs={6}>
              <IQFormInput
                id="businessUrl"
                theme={IQTheme}
                labelText="Business URL *"
                fontLabelWeight="600"
                name="businessUrl"
                fullWidth
                customValidation={schema.yupValidations.businessUrl}
                adornmentIcon={<InsertLinkIcon />}
              />
            </Grid>
          </DefaultPageLayout>
        </Box>
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
