import { Box } from '@mui/material';
import { Outlet } from 'react-router';
import TopNav from 'components/topNav/opportunity/TopNav';
import { backgroundDefaultColor } from 'styles/common_styles';

export default function OpportunityMenu() {
  return (
    <Box sx={{ backgroundColor: backgroundDefaultColor, minHeight: '100vh' }}>
      <TopNav />
      <Outlet />
    </Box>
  );
}
