import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';

export const seoBlogEnhancedMenu: MenuType = (
  {
    label: 'Blog Content (Enhanced)',
    url: '',
    status: 'incomplete',
    offeringType: 'SEOBlogEnhanced',
    subSteps: [
      {
        label: 'General Questions',
        url: Urls.GeneralQuestions,
        status: 'incomplete',
      },
      {
        label: 'Solution Strategy',
        url: Urls.BlogSolutionStrategy,
        status: 'incomplete',
      },
    ],
  });
