import {
  IQCheckbox,
} from '@gannettdigital/shared-react-components';
import {
  Typography,
} from '@mui/material';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import { useTranslation } from 'react-i18next';
import WeekOperationHours from './WeekOperationHours';

export default function HoursOfOperation({ isPunchlist = false }: any) {
  const { t } = useTranslation();

  return (
    <GridContainer fullWidth>
      <GridItem>
        <Typography variant="h6">{t('pages.business.location.hours.hoursOfOperation.title')}</Typography>
      </GridItem>
      <WeekOperationHours />
      {!isPunchlist
        && (
        <GridItem marginTop={3}>
          <IQCheckbox
            label={t('pages.business.location.hours.hoursOfOperation.applyHoursToAll')}
            field="applyHoursToAll"
            name="applyHoursToAll"
          />
        </GridItem>
        )}
    </GridContainer>
  );
}
