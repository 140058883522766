import { IQCheckbox, IQFormLayout, ValidationProvider } from '@gannettdigital/shared-react-components';
import { Grid, Typography } from '@mui/material';
import { StepperRootProps } from 'layouts/StepperLayout';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectCustomWebsite, putCustomWebsiteDesignInfo, getCustomWebsite } from 'services/customWebsiteSlice';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { useAppDispatch } from 'services/redux/store';
import { selectActiveProductId } from 'services/navigationSlice';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import ErrorText from 'components/errorText/ErrorText';
import schema from './Navigation.schema';

const layoutOptions = [
  {
    label: 'The Works',
    field: 'theWorks',
  },
  {
    label: 'Expanded Dropdown',
    field: 'expandedDropdown',
  },
  {
    label: 'Mega Menu',
    field: 'megaMenu',
  },
  {
    label: 'Images Menu',
    field: 'imagesMenu',
  },
  {
    label: 'Advanced Menu',
    field: 'advancedMenu',
  },
  {
    label: 'Dynamic Menu',
    field: 'dynamicMenu',
  },
  {
    label: 'Other',
    field: 'other',
  },
];

const defaultFormValues = {
  theWorks: false,
  expandedDropdown: false,
  megaMenu: false,
  imagesMenu: false,
  advancedMenu: false,
  dynamicMenu: false,
  other: false,
  otherLayout: '',
};

export default function CustomWebsiteNavigation(props: StepperRootProps) {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const { orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const customWebsite = useSelector(selectCustomWebsite);

  const methods = useForm({
    mode: 'all',
    defaultValues: defaultFormValues,
  });

  const {
    getValues, handleSubmit, watch, reset, setValue, register, formState: {
      isDirty, isSubmitting, isSubmitSuccessful, touchedFields, errors,
    },
  } = methods;
  const touched = useMemo(() => Object.keys(touchedFields).length > 0, [touchedFields]);

  const otherSelected = useMemo(() => getValues('other') === true, [watch('other')]);
  const noneSelected = useMemo(() => {
    const {
      theWorks, expandedDropdown, megaMenu, imagesMenu, advancedMenu, dynamicMenu, other, otherLayout,
    } = getValues();

    return !(theWorks || expandedDropdown || megaMenu || imagesMenu || advancedMenu
       || dynamicMenu || (other && otherLayout));
  }, [watch()]);

  useEffect(() => {
    props.onUpdateStatus(getValues(), !noneSelected);
  }, [noneSelected]);

  useEffect(() => {
    reset({ ...defaultFormValues });
    if (customWebsite.navigationMenuType
      && !Object.keys(defaultFormValues).includes(customWebsite.navigationMenuType)) {
      setValue('other', true);
      setValue('otherLayout', customWebsite.navigationMenuType);
    } else {
      setValue(customWebsite.navigationMenuType as any, true);
    }
  }, [customWebsite]);

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsite(websiteId.toString()));
  }, [websiteId]);

  const onFormSubmit = async () => {
    const formData = getValues();
    let enabledAttribute = Object.keys(formData).filter(key => formData[key]).at(0);
    enabledAttribute = enabledAttribute === 'other' ? formData.otherLayout : enabledAttribute;
    await appDispatch(dispatch(getCustomWebsite(websiteId)))
      .then(async (response) => {
        const content = {
          customNavigationMenu: enabledAttribute,
          customDesignRequirements: response.payload.customDesignInfo || '',
          customFormRequirements: response.payload.customFormRequirements || '',
          customFormUrls: response.payload.customFormUrls || '',
        };
        dispatch(putCustomWebsiteDesignInfo({ orderItemId, content }));
      });
    props.onContinue();
  };

  const onSelected = (menu) => {
    reset({ ...defaultFormValues });
    setValue(menu, true);
  };

  const renderOptions = (options) => options.map(option => (
    <Grid item xs={6} marginBottom={1}
      key={option.field}
    >
      <IQCheckbox
        title=""
        label={option.label}
        name={option.field}
        field={option.field}
        onChange={() => onSelected(option.field)}
      />
    </Grid>
  ));

  return (
    <>
      <Typography variant="h2" marginTop={2}>{t('pages.website.custom.navigation.title')}</Typography>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container marginTop={3}>
              <Grid item xs={8}>
                <Typography fontWeight="bold" fontSize="16px" marginBottom={1}
                  sx={touched && noneSelected && { color: '#C20F1E' }}
                >
                  {t('pages.website.custom.navigation.menuLayout')}
                  &nbsp;
                  <a target="_blank" href="https://cambridge4.localiq.site/"
                    style={{ color: touched && noneSelected ? '#C20F1E' : 'black' }} rel="noreferrer"
                  >
                    View Demo
                  </a>
                  &nbsp;*
                </Typography>
                <IQFormLayout
                  labelText=""
                  fontLabelWeight="bold"
                  tooltipPaddingBottom={18}
                  showError={touched && noneSelected && !otherSelected}
                  errorMessage={t('pages.website.custom.navigation.selectionRequired')}
                  fullWidth
                >
                  <Grid container>
                    {renderOptions(layoutOptions)}
                  </Grid>
                </IQFormLayout>
              </Grid>
              {otherSelected && (
                <Grid item xs={5}>
                  <NeFormTextArea
                    {...register('otherLayout')}
                    id="otherLayout"
                    name="otherLayout"
                    labelText=""
                    fullWidth
                    rowCount={3}
                    fontLabelWeight="600"
                  />
                  <ErrorText
                    hasError={!!errors.otherLayout}
                    errorText="This is a required field."
                  />
                </Grid>
              )}
            </Grid>
          </form>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </>
  );
}
