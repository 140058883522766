import { useEffect, useState } from 'react';
import { locationsTypes } from 'layouts/StepperLayout';
import { styled } from '@mui/material';
import LocationAddress from './LocationAddress';
import standardSchema from './LocationAddress.schema';
import premiumListingSchema from './PremiumListingsAddress.schema';
import healthcareSchema from './PlHealthcareAddress.schema';

const basePointsOfContact = {
  locationName: '',
  businessAddressOne: '',
  businessAddressTwo: '',
  city: '',
  stateProvince: '',
  postalCode: '',
  country: '',
  serviceAreas: [],
};

const listingsPointsOfContact = {
  ...basePointsOfContact,
  doesNotServeClients: false,
  locationIdentifier: '',
};

export const FormSelectStyled = styled('div')`
  label {
    font-weight: 600;
  }
`;

export default function LocationAddressPage(props: any) {
  const { locationsType } = props;
  const [schema, setSchema] = useState(null);
  const [pointsOfContact, setPointsOfContact] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (locationsType === locationsTypes.STANDARD) {
      setSchema(standardSchema);
      setPointsOfContact(basePointsOfContact);
    }

    if (locationsType === locationsTypes.PREMIUM_LISTINGS) {
      setSchema(premiumListingSchema);
      setPointsOfContact(listingsPointsOfContact);
    }

    if (locationsType === locationsTypes.HEALTHCARE) {
      setSchema(healthcareSchema);
      setPointsOfContact({ ...listingsPointsOfContact, prePopulate: 'doNot' });
    }
  }, [locationsType]);

  useEffect(() => {
    setIsReady(schema && pointsOfContact);
  }, [schema, pointsOfContact]);

  return isReady && (
    <LocationAddress
      {...props}
      type={locationsType}
      schema={schema}
      pointsOfContact={pointsOfContact}
    />
  );
}
