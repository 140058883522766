/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import { Box, Grid, Typography } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import { useParams } from 'react-router';
import { getProduct } from 'services/productsSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import OrderTracker from 'pages/orders/OrderTracker';
import { getFiles, ProductTypes } from 'services/fileUploaderSlice';
import { camelCaseToFlat } from 'pages/shopping-cart/ShoppingCart';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { displayAssets } from 'pages/overview/sections/sites/SitesSection';
import moment from 'moment';
import { getOrderItemSchema } from 'pages/generic-product/details/utils/functions';
import i18n from 'i18n/i18n';
import { GeneralQuestionsSection } from '../generalQuestions/GeneralQuestionsSection';
import { ActionItems } from '../common/ActionItems';
import { headingStyle } from '../Business/BusinessSection';
import {normalizeNewLines} from "shared/constants";

const { t } = i18n;

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square
                  {...props}
    />
))(() => ({
  backgroundColor: 'transparent',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
}));

interface DayDetails {
  open: boolean;
  openTime: string;
  closeTime: string;
}

// Logic to Render Targeted Email Budget Details Part
const extractBudgetValueFromFieldValue = (value: any) => {
  let extractValue = 0;
  if (value.includes('$')) {
    const match = value.match(/\$(\d+)/);
    extractValue = match ? parseInt(match[1], 10) : 0;
  } else {
    // Regular expression to match a number with optional decimal places
    const match = value.match(/[\d]*\.?\d+/);

    // If a match is found, parse it as a float; otherwise, default to 0
    extractValue = match ? parseFloat(match[0]) : 0;
  }
  return extractValue;
};

const budgetGeneratorUiComponentForTargetedEmailProduct = (sortedProductData: any) => {
  let totalBudgetForTargetedEmailProduct = 0;
  const keysToCheckForBudgetForTargetedEmailProduct = [
    'coreBudget',
    'reminderRedropBudget',
    'postalFileBudget',
    'targetedEmailPackageMatchAndFill',
    'targetedEmailPackageCreative'];
  const isAnyKeyPresentForBudgetForTargetedEmailProduct = keysToCheckForBudgetForTargetedEmailProduct
  .some(key => Object.keys(sortedProductData).includes(key));
  return (isAnyKeyPresentForBudgetForTargetedEmailProduct && (
      <Grid container>
        <Grid item xs={12} mt={2}
              sx={{borderTop: '1px solid #000'}}
        >
          <Box mb={2} mt={2}>
            <Typography sx={headingStyle} color="primary">Total Costs</Typography>
          </Box>
        </Grid>
        {
          Object.keys(sortedProductData).map((key) => {
            if (key === 'coreBudget'
                || key === 'reminderRedropBudget'
                || key === 'postalFileBudget'
                || key === 'targetedEmailPackageCreative'
                || key === 'targetedEmailPackageMatchAndFill') {
              const formattedKey = camelCaseToFlat(key);
              let extractNumber = 0;
              if (Array.isArray(sortedProductData[key])) {
                extractNumber = extractBudgetValueFromFieldValue(sortedProductData[key][0]);
              } else {
                extractNumber = extractBudgetValueFromFieldValue(sortedProductData[key]);
              }
              totalBudgetForTargetedEmailProduct += extractNumber;
              return (
                  <Grid item xs={12} key={key}>
                    <Box key={key} mb={2}>
                      <Box sx={{
                        ...headingStyle,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      >
                        <Box component="span">{formattedKey}</Box>
                        <Box component="span">
                          {`$${extractNumber.toFixed(2)}`}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
              );
            }
            return null;
          })
        }
        <Grid item xs={12} sx={{borderTop: '1px solid #000'}}>
          <Box mb={2} mt={2}>
            <Typography color="primary" sx={{
              ...headingStyle,
              display: 'flex',
              justifyContent: 'space-between',
            }}
            >
              <Box component="span">Total</Box>
              <Box component="span">
                {`$${totalBudgetForTargetedEmailProduct.toFixed(2)}`}
              </Box>
            </Typography>
          </Box>
        </Grid>
      </Grid>
  ));
};

export default function GenericProductSection({product, isEditable, showTracker}: any) {
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const {orderId} = useParams();
  const [productData, setProductData] = useState({} as any);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [existingAssets, setExistingAssets] = useState([]);
  const [currentUISchema, setCurrentUISchema] = useState(null);

  const handleEditClick = (offeringType: string, productId: string) => {
    let url: Urls;
    switch (offeringType) {
      case 'YouTube':
        url = Urls.YouTubeTargeting;
        break;
      case 'TargetedDisplay':
        url = Urls.TargetedDisplay;
        break;
      case 'TargetedDisplayLegacy':
        url = Urls.TargetedDisplayLeagacyProxyProvisioning;
        break;
      case 'SearchEngineMarketing':
        url = Urls.SemDetails;
        break;
      default:
        url = Urls.GenericProductDetails;
    }
    navigate.to(url, productId);
  };

  const getProductData = (orderItemId) => {
    appDispatch(getProduct({
      orderItemId,
    })).then((response) => {
      setProductData(response.payload.data);
    });
  };

  const getAssets = (offeringId, offeringType) => {
    appDispatch(getFiles({
      websiteId: offeringId,
      uploaderType: ProductTypes.SOW_DOCUMENT,
      offeringType,
    })).then((response) => setExistingAssets(response.payload));
  };

  const sortProductData = (uiSchema: any, productData: any) => {
    let sortedProductData = {};

    // Sort productData based on uiSchema['ui:order']
    if (uiSchema && uiSchema['ui:order']) {
      uiSchema['ui:order'].forEach((key: string) => {
        if (productData[key] !== undefined) {
          if (Array.isArray(productData[key]) && productData[key].length === 0) {
            sortedProductData[key] = '';
          } else {
            sortedProductData[key] = productData[key];
          }
        }
      });
    } else {
      sortedProductData = {...productData};
    }

    // Process additional properties in productData
    Object.keys(productData).forEach((key) => {
      if (sortedProductData[key] !== '' && key.toLowerCase().endsWith('date')) {
        sortedProductData[key] = moment(sortedProductData[key]).format('MM/DD/YY');
      }
    });

    return sortedProductData;
  };

  useEffect(() => {
    if (product) {
      getProductData(product?.id);
      getAssets(product?.offeringId, product.pdOfferingType);
      getOrderItemSchema({pdOfferingType: product.pdOfferingType}, () => {
      }, setCurrentUISchema, () => {
      }, {});
    }
  }, [product, orderId]);

  const renderObjectDetails = (data, isFirstLevel = true) => {
    if (data instanceof Object && Object.keys(data).length === 0) {
      return (
          <Box mb={2}>
            <Typography sx={headingStyle}
                        color="primary"
            >
              {camelCaseToFlat(t('pages.xmo.overview.noDataAvailable')).toUpperCase()}
            </Typography>
          </Box>
      );
    }
    const sortedProductData = sortProductData(currentUISchema, data);

    const objectDetails = [];
    const arrayOfObjectDetails = [];
    const arrayOfArraysDetails = [];
    const plainArrayDetails = [];

    // Separate object and array details
    Object.keys(sortedProductData).forEach((key) => {
      if (offeringType === "SearchEngineMarketing" && key === "adGroups") {
        arrayOfObjectDetails.push({key, value: sortedProductData[key]});
      } else {
        if (typeof sortedProductData[key] === 'object' && !Array.isArray(sortedProductData[key])) {
          objectDetails.push({key, value: sortedProductData[key]});
        } else if (Array.isArray(sortedProductData[key])) {
          if (sortedProductData[key].length > 0 && typeof sortedProductData[key][0] === 'object'
              && !Array.isArray(sortedProductData[key][0])) {
            arrayOfObjectDetails.push({key, value: sortedProductData[key]});
          } else if (sortedProductData[key].every(item => Array.isArray(item)
              && !item.some(innerItem => typeof innerItem === 'object'))) {
            arrayOfArraysDetails.push({key, value: sortedProductData[key]});
          } else {
            plainArrayDetails.push({key, value: sortedProductData[key]});
          }
        }
      }
    });

    return (
        <>
          {/* Render an array of arrays details */}
          {arrayOfArraysDetails.map(({key, value}, index) => (
              <Box key={index} mb={2}>
                <Typography sx={headingStyle}
                            color="primary"
                >
                  {camelCaseToFlat(key).toUpperCase()}
                </Typography>
                <Typography>{value && value.map(subArray => subArray.join(', '))}</Typography>
              </Box>
          ))}

          {/* Render object details */}
          {objectDetails.map(({key, value}) => (
              <Box mb={2} key={key}>
                <GridItem sizes={[12, 12]} marginBottom={3} sx={{
                  padding: isFirstLevel ? '20px 0 0' : '0',
                  borderTop: isFirstLevel ? '0px solid black' : '0',
                }}
                >
                  <Box sx={{display: 'flex', alignItems: 'center'}}>
                    {renderObjectDetails(value, false)}
                  </Box>
                </GridItem>
              </Box>
          ))}

          {/* Render non-object and non-array details */}
          <Grid container>
            {Object.keys(sortedProductData).map((key, index, productArray) => {
              if (
                  key !== 'sourceId' && !key.includes('uploader_')
                  && !objectDetails.some(detail => detail.key === key)
                  && !arrayOfObjectDetails.some(detail => detail.key === key)
                  && !plainArrayDetails.some(detail => detail.key === key)
                  && !arrayOfArraysDetails.some(detail => detail.key === key)
              ) {
                const formattedKey = camelCaseToFlat(key);

                return (
                    !(offeringType === "SearchEngineMarketing" && (key === "adGroups" || key === "primaryAdGroupFocus" || key === "primaryAdGroupLandingPage")) &&
                    <Grid item xs={productArray.length === 1 ? 12 : 6} key={key}>
                      <Box key={key} mb={2} mr={2}>
                        <Typography sx={headingStyle} color="primary">{formattedKey}</Typography>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {(typeof sortedProductData[key] === 'boolean')
                            ? (sortedProductData[key] ? 'Yes' : 'No')
                            : displayNotRequiredField(formattedKey, normalizeNewLines(sortedProductData[key]))}
                      </Box>
                    </Grid>
                );
              }
              return null;
            })}
          </Grid>

          {/* Render plain arrays details */}
          <Grid container spacing={2}>
            {plainArrayDetails.map(({key, value}, index) => (
                <Grid item xs={6} key={key}>
                  <Box key={index} mb={2}>
                    <Typography sx={headingStyle} color="primary">
                      {camelCaseToFlat(key).toUpperCase()}
                    </Typography>
                    <Typography>{value.join(', ')}</Typography>
                  </Box>
                </Grid>
            ))}
          </Grid>

          {/* Render array of objects details */}
          {arrayOfObjectDetails.map(({key, value}, index) => {
            if (key.includes('schedule')) {
              const formattedKey = camelCaseToFlat(key);
              let isOpenDayAvailable = false;
              const dayOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
              const sortedEntries = Object.entries(value[0])
              .sort((a, b) => dayOrder.indexOf(a[0]) - dayOrder.indexOf(b[0]));
              return (
                  <Grid item xs={6} key={key}>
                    <Box key={key} mb={2}>
                      <Typography sx={headingStyle} color="primary">{formattedKey}</Typography>
                      {sortedEntries.map(([day, details]) => {
                        const detail = details as DayDetails;
                        if (detail.open) {
                          isOpenDayAvailable = true;
                          return (
                              <div key={day}>
                                <Typography>
                                  {day}
                                  {' '}
                                  {detail.openTime !== '' ? `-- ${detail.openTime}` : '-'}
                                  {' '}
                                  -
                                  {' '}
                                  {detail.closeTime !== '' ? detail.closeTime : '-'}
                                </Typography>
                              </div>
                          );
                        }
                      })}
                      {!isOpenDayAvailable ? (
                          <Typography>{`No ${camelCaseToFlat(key)}`}</Typography>
                      ) : ''}
                    </Box>
                  </Grid>
              );
            }
            if (key.includes('uploader_')) {
              const formattedKey = camelCaseToFlat(key.replace('uploader_', ''));
              return (
                  <Grid item xs={6} key={key}>
                    <Box key={key} mb={2}>
                      <Typography sx={headingStyle} color="primary">{formattedKey}</Typography>
                      {displayAssets(data[key], 'Uploaded File')}
                    </Box>
                  </Grid>
              );
            }
            return (
                <Box key={index} my={2}>
                  <Typography sx={headingStyle} color="primary">
                    {camelCaseToFlat(key).toUpperCase()}
                  </Typography>
                  {offeringType === "SearchEngineMarketing" && key === "adGroups" &&
                      <Grid container mt={2} mx={3}>
                        {Object.keys(sortedProductData).map((key) => {
                          if (key === "primaryAdGroupFocus" || key === "primaryAdGroupLandingPage") {
                            return (
                                <Grid item xs={6} key={key}>
                                  <Box key={key} mb={2} mr={2}>
                                    <Typography sx={headingStyle}
                                                color="primary">{camelCaseToFlat(key)}</Typography>
                                    {typeof sortedProductData[key] === 'boolean'
                                        ? (sortedProductData[key] ? 'Yes' : 'No')
                                        : displayNotRequiredField(camelCaseToFlat(key), normalizeNewLines(sortedProductData[key]))
                                    }
                                  </Box>
                                </Grid>
                            )
                          }
                        })
                        }
                      </Grid>
                  }
                  <Box sx={{paddingLeft: '15px'}}>
                    {value && value.map((item, idx, valueArr) => (
                        <Accordion key={idx} defaultExpanded>
                          <AccordionSummary aria-controls={`panel${index}-${idx}-content`}
                                            id={`panel${index}-${idx}-header`}
                          >
                            <Typography>
                              {`${camelCaseToFlat(key)} `}
                              {valueArr.length !== 1 && idx + 1}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box sx={{paddingLeft: '15px'}}>
                              {renderObjectDetails(item, false)}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                    ))}
                  </Box>
                </Box>
            );
          })}
          {
            // Render Targeted Email Budget Details Part
            budgetGeneratorUiComponentForTargetedEmailProduct(sortedProductData)
          }
        </>
    );
  };

  const offeringType = product.offeringType === 'ProductsData' ? product.pdOfferingType : product.offeringType;
  const status = product?.trackerStatus === 'Completed' ? 'Seo Live' : product?.trackerStatus;

  const renderProductData = () => (
      <GridItem sizes={[12, 12]} marginBottom={3}
                sx={{padding: '20px 0 0', borderTop: '1px solid #000000'}}>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          {editButton(isEditable, () => handleEditClick(offeringType, product.id))}
          <Typography sx={{fontSize: '16px', fontWeight: '600'}}>Solution Strategy</Typography>
        </Box>
        <Box sx={{paddingTop: '10px'}}>
          {renderObjectDetails(productData)}
        </Box>
      </GridItem>
  );

  return (
      <Grid container sx={{borderTop: '1px solid #716F6F'}}>
        {/* Status Tracker */}
        {showTracker && (
            <>
              <Grid item xs={12} sx={{padding: '20px 0px 60px 20px'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                  {editButton(isEditable, () => {
                  })}
                  <Typography sx={{fontSize: '16px', fontWeight: '600'}}>Order Status</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}
                    sx={{padding: '0px 0px 60px 20px', borderBottom: '1px solid #000000'}}
              >
                <Box>
                  <OrderTracker
                      key={product?.id}
                      status={status}
                      productName={product?.pdOfferingType}
                      product={product}
                  />
                </Box>
              </Grid>
            </>
        )}

        {/* General Questions */}
        <Grid item xs={12}>
          {GeneralQuestionsSection(offeringType, product.offeringId, product.id, navigate, isEditable)}
        </Grid>

        {/* Details */}
        {!product.isRebuild && renderProductData()}

        {/* Actions items */}
        {showTracker && ActionItems(false, product?.id, offeringType, productData)}
      </Grid>
  );
}
