export default function IconLogo() {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/assets/icon-logo.svg`}
      alt="Logo"
      style={{
        margin: 'auto',
        maxHeight: '50px',
      }}
    />
  );
}
