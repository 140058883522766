import { ComponentProps } from 'react';
import { useParams } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  IQFormTextArea,
  IQTheme,
  IQYesNoFormRadioButtons,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { Box, Grid, Typography } from '@mui/material';

import styled from '@emotion/styled';

import { WebsiteOfferingTypes } from 'services/websitesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import schema from './ExpandableMenu.schema';
import ExpandableMenuType from '../../../models/ExpandableMenuType';
import { ExpandableMenuFormData } from './ExpandableMenu';

import { DefaultPageLayout } from '../../../layouts/DefaultPageLayout';
import CoNavigationConfirm from '../../../components/navigation/CoNavigationConfirm';
import CoFileUploader from '../../../components/fileUploader/CoFileUploader';
import useUpdateAssets from '../../../hooks/useUpdateAssets';
import { ProductTypes, setFiles } from '../../../services/fileUploaderSlice';

interface Props {
  expandableMenu: ExpandableMenuType;
  onSubmit: (data :ExpandableMenuFormData) => void;
  onBack: () => void;
}

type SubmitData = ExpandableMenuFormData & { images: [] | File[], gettyImages: [] | File[] };

const uploaderType = 'expandable-menu';
const directory = 'addons-expandable-menu';

const StyledTypography = styled(Typography)(({ theme, heading } : { theme: typeof IQTheme, heading?: boolean }) => `
  color: ${theme.palette.text.primary};
  font-family: ${theme.typography.fontFamily};
  font-style: normal;
  font-weight: ${heading ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  margin-bottom: ${heading ? '8px' : '16px'};
  margin-top: ${heading ? '27px' : 0};
`);

const megabyte = 1_000_000;
const defaultMaxFileSize = 20 * megabyte;
const defaultImageTypes = ['.jpg', '.jpeg', '.png', '.JPG', '.JPEG', '.PNG'];

const defaultFileUploaderOptions :Pick<ComponentProps<typeof CoFileUploader>, 'fileUploaderOptions'> = {
  fileUploaderOptions: {
    directory,
    uploaderType,
    label: '',
    supportingText: '',
    name: 'images',
    showAcceptedFileTypesText: true,
    showRecommendedImageText: false,
    maxFileSize: defaultMaxFileSize,
    imageTypes: defaultImageTypes,
    videoTypes: [],
    documentTypes: [],
    size: 'lg',
    showCheckboxes: false,
  },
};

const defaultGettyOptions :Pick<ComponentProps<typeof CoFileUploader>, 'gettyImagesUploaderOptions'> = {
  gettyImagesUploaderOptions: {
    name: 'gettyImages', productType: ProductTypes.EXPANDABLE_MENU, displayOnly: 'images', showCheckboxes: false,
  },
};

export default function ExpandableMenuForm(props :Props) {
  const { orderId, orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const dispatch = useDispatch();
  const { expandableMenu } = props;

  const { includeImages, menuRequirements, additionalNotes } = expandableMenu;

  const { t } = useTranslation();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      includeImages,
      menuRequirements,
      images: [],
      gettyImages: [],
      additionalNotes,
    },
  });
  const {
    handleSubmit,
    watch,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful,
    },
  } = methods;

  const formValues = {
    includeImages: watch('includeImages') || '',
  };

  const { triggerAllUpdates, deleteAllAssets } = useUpdateAssets(
    watch,
    defaultFileUploaderOptions.fileUploaderOptions.name,
    defaultGettyOptions.gettyImagesUploaderOptions.name,
    websiteId?.toString(),
    orderId,
    ProductTypes.EXPANDABLE_MENU,
    offeringType as WebsiteOfferingTypes,
    orderItemId,
  );

  const onSubmit = async (data :SubmitData) => {
    const { gettyImages, images, ...submitData } = data;

    if (submitData.includeImages === 'yes') {
      await triggerAllUpdates();
    } else {
      deleteAllAssets();
    }

    await props.onSubmit(submitData);
    dispatch(setFiles([]));
  };

  const includeImagesOptions = {
    title: `${t('pages.addons.expandableMenu.includeImages')} *`,
    field: 'includeImages',
    fontTitleWeight: '600',
  };

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <Box id="mainForm" component="form" sx={{ display: 'flex', pl: '80px' }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <DefaultPageLayout
            disableContinue={!isValid}
            onBack={props.onBack}
            header={t('pages.addons.expandableMenu.title')}
            onContinue={handleSubmit(onSubmit)}
          >
            <Grid container xs={6} minWidth="608px">
              <Grid item xs={12}
                sx={{
                  mb: '24px',
                  '& > .MuiFormControl-root > div': { pl: '5px' },
                  '& .MuiFormControlLabel-root .MuiRadio-root': { mr: 0 },
                }}
              >
                <IQYesNoFormRadioButtons {...includeImagesOptions} />
              </Grid>
              {formValues.includeImages === 'yes' && (
                <Grid item xs={12} sx={{ maxWidth: '608px' }}>
                  <StyledTypography theme={IQTheme} heading>
                    Assets
                  </StyledTypography>
                  <StyledTypography theme={IQTheme}>
                    {t('pages.addons.expandableMenu.assetsLabel')}
                  </StyledTypography>
                  <CoFileUploader
                    orderId={orderId}
                    orderItemId={orderItemId}
                    productId={websiteId?.toString()}
                    productType={ProductTypes.EXPANDABLE_MENU}
                    offeringType={offeringType as WebsiteOfferingTypes}
                    schema={schema}
                    showGetty
                    showFileUploader
                    fileUploaderOptions={defaultFileUploaderOptions.fileUploaderOptions}
                    gettyImagesUploaderOptions={defaultGettyOptions.gettyImagesUploaderOptions}
                    containerStyles={{
                      '& > div:first-of-type > div': { width: '374px' },
                      '& > div:first-child': { mb: '24px' },
                      '& > div:last-child': { mb: '46px' },
                      '& > div > button': { width: '218px' },
                      '.MuiAccordionDetails-root': { minHeight: '64px' },
                      '.MuiAccordionDetails-root > textarea': { minHeight: '64px', minWidth: '100%', maxWidth: '100%' },
                      '.MuiCollapse-root': { maxWidth: '476px' },
                    }}
                  />
                </Grid>
              )}
              <Grid item minWidth="100%" mb={5}
                sx={{ '& > div': { gap: '8px' }, '& > div > p': { mt: 0 } }}
              >
                <IQFormTextArea
                  id="menuRequirements"
                  labelText={`${t('pages.addons.expandableMenu.requestsOrRequirements')} *`}
                  name="menuRequirements"
                  rowCount={2}
                  fullWidth
                  fontLabelWeight="600"
                  showError
                />
              </Grid>

              <Grid item minWidth="100%">
                <IQFormTextArea
                  id="additionalNotes"
                  labelText="Additional Notes/Instructions"
                  name="additionalNotes"
                  rowCount={2}
                  fullWidth
                  fontLabelWeight="600"
                  showError
                />
              </Grid>
            </Grid>
          </DefaultPageLayout>
          {/* show the dialog if we changed the field and didn't submit the changes */}
          <CoNavigationConfirm showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)} />
        </Box>
      </FormProvider>
    </ValidationProvider>
  );
}
