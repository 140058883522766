import { Box } from '@mui/material';
import CoTopNav from 'components/topNav/CoTopNav';
import { Outlet, useParams } from 'react-router';
import TopNav from 'components/topNav/opportunity/TopNav';
import { backgroundDefaultColor } from 'styles/common_styles';
import { useQuery } from 'pages/themes/details/ThemeDetails';
import { useMemo } from 'react';

export default function ThemesMenu() {
  const { opportunityId } = useParams();
  const query = useQuery();
  const partnerAccount = useMemo(() => query.get('partnerAccount'), [query]);

  return (
    partnerAccount
      ? (
        <Box sx={{ backgroundColor: backgroundDefaultColor, minHeight: '100vh', height: 'auto' }}>
          <TopNav />
          <Outlet />
        </Box>
      ) : (
        <Box sx={{ backgroundColor: backgroundDefaultColor, minHeight: '100vh' }}>
          {!opportunityId ? <CoTopNav /> : <TopNav />}
          <Outlet />
        </Box>
      )
  );
}
