import { Urls } from 'navigation/Urls';
import useProduct from 'hooks/useProduct';
import { GetLandingPageResponse, LandingPageRequestContentType } from 'models/LandingPageType';
import { StatusType } from 'models/StatusType';

import ContentLoader from 'components/contentLoader/ContentLoader';
import DomainForm from './DomainForm';

export default function Domain() {
  const { onBack, product, onSubmit } = useProduct<LandingPageRequestContentType,
  StatusType<GetLandingPageResponse>>(Urls.LandingDomain);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <DomainForm
        onSubmit={onSubmit}
        onBack={onBack}
        domain={product.content.data}
      />
    </ContentLoader>
  );
}
