import * as yup from 'yup';

const schema = {
  domainSelection: yup.string().required(),
  subdomain: yup.string()
    .required('Please enter subdomain.')
    .nullable()
    .test(
      'is-www',
      'Subdomain cannot be “www.” Please enter a valid subdomain.',
      (value: string) => !value.toLowerCase().includes('www'),
    ),
  primaryDomain: yup.string().required('Please enter domain.').nullable(),
  tld: yup.string().required('Please enter extension.').nullable(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
