import AxiosAPI from "services/common/axios";

export const getSalesAgentDetails = async ({id, isBlue}) => {
  try {
    // Api to get sales agent data from Salesforce
    const response = await AxiosAPI.get(`/integrations/force/user/${id}?isBlue=${isBlue}`);
    return response.data;
  } catch (error: any) {
    console.error("Error fetching data:", error);
  }
};