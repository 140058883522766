import { StepperLayout } from 'layouts/StepperLayout';
import { Urls } from 'navigation/Urls';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomWebsiteItems, selectCustomWebsiteItems } from 'services/customWebsiteSlice';
import { selectActiveProductId } from 'services/navigationSlice';
import CustomWebsiteDesign from './design/CustomWebsiteDesign';
import CustomWebsiteNavigation from './navigation/CustomWebsiteNavigation';
import CustomWebsiteForm from './form/CustomWebsiteForm';

const stepsMap = [
  {
    id: 1,
    name: Urls.CustomDesign,
    component: ((props) => <CustomWebsiteDesign {...props} />),
    continueTitle: 'form.buttons.continue',
    title: 'pages.website.custom.design.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    id: 2,
    name: Urls.CustomNavigation,
    component: ((props) => <CustomWebsiteNavigation {...props} />),
    continueTitle: 'form.buttons.continue',
    title: 'pages.website.custom.navigation.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    id: 3,
    name: Urls.CustomForm,
    component: ((props) => <CustomWebsiteForm {...props} />),
    continueTitle: 'form.buttons.continue',
    title: 'pages.website.custom.form.title',
    hideContinue: false,
    isCompleted: false,
  },
];

export default function CustomWebsiteDesignStepper() {
  const dispatch = useDispatch();
  const websiteId = useSelector(selectActiveProductId);
  const customWebsiteItems = useSelector(selectCustomWebsiteItems) as any;
  const stepsWithState = useMemo(
    () => stepsMap.filter(item => customWebsiteItems.find(id => id === item.id)),
    [customWebsiteItems],
  );

  useEffect(() => {
    dispatch(getCustomWebsiteItems(websiteId));
  }, [websiteId]);

  return (
    (stepsWithState.length > 0)
    && (
    <StepperLayout steps={stepsWithState} formId="custom-website-design" fullWidth
      continueTo={Urls.CustomWebsiteFeatures}
    />
    )
  );
}
