const validateCustom = async (
  customValidation,
  customFieldYup,
  value,
  params,
) => {
  if (customValidation) {
    return customValidation(value, params)
      .then((v) => {
        if (v) return v;
        if (customFieldYup) {
          return customFieldYup
            .validate(v)
            .then(() => null)
            .catch((err) => err.message);
        }
        return null;
      })
      .catch((err) => err.message);
  }
  if (customFieldYup) {
    return customFieldYup
      .validate(value)
      .then(() => null)
      .catch((err) => err.message);
  }

  return null;
};

const validate = async (value, key, schema, params, customFieldYup) => {
  if (schema && schema.yupValidations && schema.yupValidations[key]) {
    return schema.yupValidations[key]
      .validate(value)
      .then((v) => validateCustom(
        schema.customValidations[key],
        customFieldYup,
        v,
        params,
      ))
      .catch((err) => err.message);
  }

  return validateCustom(
    schema?.customValidations[key],
    customFieldYup,
    value,
    params,
  );
};

export default validate;
