/* eslint-disable no-param-reassign */
import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import SitePagesType from 'models/SitePagesType';
import AxiosAPI from './common/axios';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import SitesEndpoints from './endpoints/SitesEndpoints';

export interface SitePagesState {
  sitePages: StatusType<SitePagesType[]>
}

const initialState: SitePagesState = {
  sitePages: {
    status: 'idle',
    message: '',
    content: [],
  },
};

export const getSitePages = createAsyncThunkCatchErrors(
  'sitePages/get',
  async ({ websiteId, offeringType }: { websiteId: string, offeringType: string }) =>
    SitesEndpoints[offeringType].getSitePages(websiteId),
);

export const postSitePage = createAsyncThunkCatchErrors(
  'sitePages/post',
  async ({ websiteId, content, offeringType }
  : { websiteId: string, content: SitePagesType, offeringType: string }) => {
    // eslint-disable-next-line no-param-reassign
    delete content.id;
    return SitesEndpoints[offeringType].postSitePage(websiteId, content);
  },
);

export const putSitePage = createAsyncThunkCatchErrors(
  'sitePages/put',
  async ({ offeringType, content } :{ offeringType: string, content: SitePagesType }) =>
    SitesEndpoints[offeringType].putSitePage(content),
);

export const putSitePageNotes = createAsyncThunkCatchErrors(
  'sitePages/putNotes',
  async (content: string) => {
    const response = await AxiosAPI.put('/websites/pages', content);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const deleteSitePage = createAsyncThunkCatchErrors(
  'sitePages/delete',
  async ({ offeringType, content }:{ offeringType: string, content: SitePagesType }) =>
    SitesEndpoints[offeringType].deleteSitePage(content),
);

export const sitePagesSlice = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(getSitePages.fulfilled, (state, action) => {
        state.sitePages.content = action.payload;
      })
      .addCase(postSitePage.fulfilled, (state, action) => {
        // @ts-ignore
        state.sitePages.content.push(action.payload);
      })
      .addCase(putSitePage.fulfilled, (state, action) => {
        const index = state.sitePages.content.findIndex(s => s.id === action.payload.id);
        state.sitePages.content[index] = action.payload;
      })
      .addCase(deleteSitePage.fulfilled, (state, action) => {
        // @ts-ignore
        // eslint-disable-next-line eqeqeq
        state.sitePages.content = state.sitePages.content.filter((e) => e.id != action.payload);
      })
      .addMatcher(
        isAnyOf(getSitePages.fulfilled, postSitePage.fulfilled, putSitePage.fulfilled, deleteSitePage.fulfilled),
        (state) => {
          state.sitePages.status = 'idle';
        },
      )
      .addMatcher(
        isAnyOf(getSitePages.pending, postSitePage.pending, putSitePage.pending, deleteSitePage.pending),
        (state) => {
          state.sitePages.status = 'loading';
        },
      )
      .addMatcher(
        isAnyOf(getSitePages.rejected, postSitePage.rejected, putSitePage.rejected, deleteSitePage.rejected),
        (state, action) => {
          state.sitePages.status = 'failed';
          state.sitePages.message = action.error?.message;
        },
      );
  },
);

export const selectSitePages = (state: RootState) => state.sitePages.sitePages;

export default sitePagesSlice;
