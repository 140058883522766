import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IQFormInput, IQTheme, ValidationProvider } from '@gannettdigital/shared-react-components';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { Grid } from '@mui/material';

import styled from '@emotion/styled';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import { Urls } from 'navigation/Urls';
import { postDomain, putDomain } from 'services/domainsSlice';
import { useSaveAndClose } from 'context/SaveAndCloseContext';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import schema from './DomainsPurchase.schema';

import CoContinueFooter from '../continueFooter/CoContinueFooter';
import { FormContainerStyled } from './FormContainerStyled';
import CoNavigationConfirm from '../navigation/CoNavigationConfirm';

const StyledFormContainer = styled(FormContainerStyled)`
  margin-top: 40px;
`;
interface CoDomainsPurchaseProps {
  defaultBuyDomain?: string,
  id?: string | number
}

export default function CoDomainsPurchase(props: CoDomainsPurchaseProps) {
  const { id, defaultBuyDomain } = props;

  const { t } = useTranslation();

  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);

  const dispatch = useDispatch();

  const navigate = useNavigationHandler();
  const topnav = useTopnavMenu(Urls.Domains);

  // react-hook-forms
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      buyDomain: defaultBuyDomain || '',
    },
  });
  const {
    handleSubmit, formState, formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful,
    }, setValue,
  } = methods;

  const saveAndClose = useSaveAndClose();
  saveAndClose.setFormValid(isValid);

  const onSubmit = (data) => {
    if (id) dispatch(putDomain({ offeringType, content: { id, websiteId, ...data } }));
    else dispatch(postDomain({ offeringType, websiteId, content: data }));
  };

  const onBack = () => {
    navigate.to(topnav.previousStep());
  };

  useEffect(() => {
    setValue('buyDomain', defaultBuyDomain);
  }, []);

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledFormContainer>
            <Grid container justifyContent="left" flexDirection="column"
              sx={{ '& > div:first-of-type': { mt: 0 } }}
            >
              <Grid item xs={6} sx={{ '& label': { mb: '16px' } }}>
                <IQFormInput
                  id="buyDomain"
                  theme={IQTheme}
                  labelText={t('components.domains.domainUrl')}
                  fontLabelWeight="600"
                  name="buyDomain"
                  fullWidth
                  customValidation={schema.yupValidations.buyDomain}
                  adornmentIcon={<InsertLinkIcon />}
                  tooltipText={t('components.domains.goDaddy')}
                  tooltipPlacement="top"
                />
              </Grid>
            </Grid>
          </StyledFormContainer>
          <CoContinueFooter onBack={onBack} onContinue={handleSubmit(onSubmit)} continueDisabled={!formState.isValid} />
        </form>
      </FormProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </ValidationProvider>
  );
}
