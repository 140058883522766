import { StepperLayout } from 'layouts/StepperLayout';
import { Urls } from 'navigation/Urls';
import { useState } from 'react';
import CustomWebsiteContent from './content/CustomWebsiteContent';
import CustomWebsiteApplications from './applications/CustomWebsiteApplications';
import CustomWebsiteExamples from './examples/CustomWebsiteExamples';

const steps = [
  {
    name: Urls.Content,
    component: ((props) => <CustomWebsiteContent {...props} />),
    continueTitle: 'form.buttons.continue',
    title: 'pages.website.custom.content.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    name: Urls.Applications,
    component: ((props) => <CustomWebsiteApplications {...props} />),
    continueTitle: 'form.buttons.continue',
    title: 'pages.website.custom.applications.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    name: Urls.CustomExamples,
    component: ((props) => <CustomWebsiteExamples {...props} />),
    continueTitle: 'form.buttons.continue',
    title: 'pages.website.custom.examples.title',
    hideContinue: false,
    isCompleted: false,
  },
];

export default function CustomWebsiteDevelopmentStepper() {
  const [stepsWithState] = useState(steps);

  return (
    <StepperLayout steps={stepsWithState} formId="custom-website-development" fullWidth
      continueTo={Urls.CustomWebsiteAdditionalInfo}
    />
  );
}
