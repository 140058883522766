import i18n from 'i18n/i18n';
import * as yup from 'yup';

const { t } = i18n;

const schema = {
  blogPostForm: yup.string().required(),
  topicsToPrioritize: yup.string().required(t('pages.seo.blogSolutionStrategy.topicsToPrioritizeError')),
  topicsToAvoid: yup.string().optional(),
  additionalNotes: yup.string().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
