import { Urls } from 'navigation/Urls';

import useProduct from 'hooks/useProduct';

import { GetLandingPageResponse, LandingPageRequestContentType } from 'models/LandingPageType';
import { StatusType } from 'models/StatusType';

import ContentLoader from 'components/contentLoader/ContentLoader';
import LandingContentWritingForm from './LandingContentWritingForm';

export default function LandingContentWriting() {
  const { onBack, onSubmit, product } = useProduct<LandingPageRequestContentType,
  StatusType<GetLandingPageResponse>>(Urls.LandingContentWriting);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <LandingContentWritingForm
        product={product.content.data}
        onBack={onBack}
        onSubmit={onSubmit}
      />
    </ContentLoader>
  );
}
