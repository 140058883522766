import { IQButtonLink, IQCheckbox } from '@gannettdigital/shared-react-components';
import { Box, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Trans, useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import React, { useState } from 'react';
import GridItem from 'components/grid/GridItem';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { TitleWithBadge } from '../../../premium-listings/TitleWithBadge';
import VideoCard from './VideoCard';
import PublishedVideoForm from './PublishedVideoForm';
import isPunchlist from './ListingsPhotos';

interface PublishedVideosProps {
  showApplyAll?: boolean
}

export default function PublishedVideos({ showApplyAll = true }: PublishedVideosProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [videoToUpdate, setVideoToUpdate] = useState({ data: {}, index: -1 });
  const [videoToRemove, setVideoToRemove] = useState({ data: {}, index: -1 });

  const {
    fields: videos, append: appendVideo, remove: removeVideo, update: updateVideo,
  } = useFieldArray({ name: 'publishedVideos' });

  const addPublishedVideo = () => {
    setShowVideoModal(true);
  };

  const onClose = () => {
    setShowVideoModal(false);
    setVideoToUpdate({ data: {}, index: -1 });
  };

  const onVideoSubmit = (data, videoIndex) => {
    setShowVideoModal(false);

    if (videoIndex > -1) {
      updateVideo(videoIndex, data);
    } else {
      appendVideo(data);
    }
    setVideoToUpdate({ data: {}, index: -1 });
  };

  const onRemoveVideo = (data, index) => {
    setVideoToRemove({ data, index });
    setShowRemoveDialog(true);
  };

  const onEditVideo = (video, index) => {
    setVideoToUpdate({ data: video, index });
    setShowVideoModal(true);
  };

  const deleteVideo = () => {
    removeVideo(videoToRemove.index);
    setShowRemoveDialog(false);
    setVideoToRemove({ data: {}, index: -1 });
  };

  const cancelRemoveVideo = () => {
    setShowRemoveDialog(false);
    setVideoToRemove({ data: {}, index: -1 });
  };

  return (
    <>
      <GridItem sizes={[12]} marginTop={3}>
        <TitleWithBadge
          title={t('pages.premiumListings.location.photos.publishedVideos.title')}
          badge={t('pages.premiumListings.badge')}
          tooltip={t('pages.premiumListings.badgeTooltip')}
        />
      </GridItem>
      {React.Children.toArray(
        videos.map((vid: any, index: number) => (
          <div>
            <VideoCard
              video={vid}
              onEdit={() => onEditVideo(vid, index)}
              onRemove={() => onRemoveVideo(vid, index)}
            />
            {showVideoModal && videoToUpdate.index === index && (
            <PublishedVideoForm
              videos={videos}
              video={videoToUpdate}
              isOpen={showVideoModal}
              onClose={onClose}
              onSubmit={onVideoSubmit}
            />
            )}
          </div>
        )),
      )}
      {showVideoModal && (Object.keys(videoToUpdate.data).length === 0)
      && (
      <PublishedVideoForm
        videos={videos}
        video={videoToUpdate}
        isOpen={showVideoModal}
        onClose={onClose}
        onSubmit={onVideoSubmit}
      />
      )}
      <GridItem sizes={[12]}>
        <Box
          sx={{
            padding: '10px 0',
            backgroundColor: theme.palette.primary.light,
          }}
        >
          <IQButtonLink startIcon={<AddIcon />} onClick={addPublishedVideo}>
            {t('pages.premiumListings.location.photos.publishedVideos.add')}
          </IQButtonLink>
        </Box>
      </GridItem>
      { (showApplyAll && !isPunchlist) && (
        <GridItem sizes={[12]}>
          <IQCheckbox
            field="applyVideosToAll"
            label={t('pages.premiumListings.location.photos.publishedVideos.applyVideosToAll')}
          />
        </GridItem>
      )}
      <CoModalConfirm open={showRemoveDialog}
        title={t('pages.premiumListings.location.photos.publishedVideos.removeTitle')}
        description={(
          <Trans
            i18nKey="pages.premiumListings.location.photos.publishedVideos.removeMessage"
            values={{ video: (videoToRemove.data as any).videoTitle || (videoToRemove.data as any).videoUrl }}
            components={{ strong: <strong /> }}
          />
        )}
        confirmText={t('pages.premiumListings.location.photos.publishedVideos.confirmRemove')}
        handleClose={cancelRemoveVideo}
        handleConfirm={deleteVideo}
      />
    </>
  );
}
