import useProduct from 'hooks/useProduct';
import { Urls } from 'navigation/Urls';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveOfferingType } from 'services/navigationSlice';
import {
  selectOrdersContent, selectOrderFlow, selectOrderItems, selectOrdersStatus,
} from 'services/ordersSlice';
import { OrderFlow } from 'shared/constants';
import { useEffect, useMemo } from 'react';
import { getCase, selectCase, selectCaseStatus } from 'services/punchlistSlice';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { TypeOfSaleConstants } from 'pages/shopping-cart/Constants';
import GeneralQuestionsForm from './GeneralQuestionsForm';

interface Props {
  hasTracking?: boolean
}

export const productsWithTracking = [
  'ChatProduct',
  'ChatPlatinumProduct',
  'MasterAddon',
  'SEOBlogStandard',
  'SEOBlogEnhanced',
  'SEOTopicLocation',
  'SEOContentPages',
  'DMSNextCustomerCenterByDash',
  'DMSNextCustomerCenter',
  'SMMFacebookPosts',
  'SMMInstagram',
  'SMMX',
  'SMMPinterest',
  'SMMInfographics',
  'PiQLandingPages',
  'ShoppingAds',
  'Yelp',
  'CustomReporting',
  'XMO',
  'XMOUpgrade',
  'SocialAdsLeadAdswithDetailedTargeting',
  'SocialAdsLeadAds',
  'SocialAdsSmartOptimization',
  'SocialAdsCustom',
  'SocialAdsWebsiteClicks',
  'SocialAdsWebsiteClicksWithDetailedTargeting',
  'PromotionsMediaMix',
  'SearchEngineMarketing',
  'CustomTracking',
  'LocalServiceAds',
  'TargetedDisplay',
  'YouTube',
  'SEOStandard',
  'SEOCustom',
  'SiteRetargetingWithLookalikesGDN',
  'TargetedDisplayLegacy',
];

export default function GeneralQuestions(props: Props) {
  const { page, orderItemId } = useParams();
  const dispatch = useDispatch();
  const offeringType = useSelector(selectActiveOfferingType);
  const { product, onBack } = useProduct(page as Urls);
  const orderData = useSelector(selectOrdersContent);
  const orderStatus = useSelector(selectOrdersStatus);
  const orderItems = useSelector(selectOrderItems);
  const orderFlow = useSelector(selectOrderFlow);
  const caseOpportunity = useSelector(selectCase);
  const caseStatus = useSelector(selectCaseStatus);
  const isPunchlist = (orderFlow === OrderFlow.PUNCHLIST || orderData.orderFlow === OrderFlow.PUNCHLIST);
  const typeOfSale = useMemo(() => orderItems.content
    .filter(item => Number(item.id) === Number(orderItemId))?.[0]?.saleType, [orderItems]);

  const hasTracking = () => {
    if (props.hasTracking) return props.hasTracking;
    if (productsWithTracking.includes(offeringType)) return true;
    return false;
  };

  useEffect(() => {
    if (isPunchlist) {
      const itemsWithCaseId = orderItems?.content?.filter(item => item?.salesForceOfferId);
      if (itemsWithCaseId?.length > 0 && Object.keys(caseOpportunity).length === 0) {
        dispatch(getCase(itemsWithCaseId[0].salesForceOfferId));
      }
    }
  }, [orderItems, isPunchlist]);

  return typeOfSale !== undefined && (
    <ContentLoader status={[caseStatus, orderItems.status, orderStatus]}>
      <GeneralQuestionsForm
        product={product.content.data}
        onBack={onBack}
        hasTracking={hasTracking()}
        topNavUrl={page}
        isExistingCampaign={typeOfSale === TypeOfSaleConstants.MODIFICATION}
      />
    </ContentLoader>
  );
}
