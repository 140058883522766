import { RJSFSchema } from "@rjsf/utils";
import i18n from "i18n/i18n";
const { t } = i18n;



export const SEMKeywordSchema: RJSFSchema | any = (data) => ({
  type: "object",
  title: t("pages.socialAds.sem-keywords.title"),
  required: [
    "enableRemarketingListsForSearchAds",
    "keywordListCreation",
    "accessToPreviousAdWordsAccount",
    "flightedCampaign",
  ],
  properties: {
    generalHeading: {
      type: "string",
      title: t("pages.socialAds.sem-keywords.general.headingLabel"),
    },
    enableRemarketingListsForSearchAds: {
      type: "string",
      title: t(
        "pages.socialAds.sem-keywords.general.enableRemarketingListsForSearchAdsLabel"
      ),
      default: "",
      items: {
        enum: [
          t('pages.generic-translation.yesOption'),
          t('pages.generic-translation.noOption'),
        ],
      },
      description: t(
        "pages.socialAds.sem-keywords.general.enableRemarketingListsForSearchAdsHelperText"
      ),
    },
    flightedCampaign: {
      type: "string",
      title: t("pages.socialAds.sem-keywords.general.flightedCampaignLabel"),
      default: "",
      items: {
        enum: [
          t('pages.generic-translation.yesOption'),
          t('pages.generic-translation.noOption'),
        ],
      },    },
    kpiValue: {
      type: "string",
      title: t("pages.socialAds.sem-keywords.general.kpiValueLabel")
    },
    accessToPreviousAdWordsAccount: {
      type: "string",
      title: t(
        "pages.socialAds.sem-keywords.general.accessToPreviousAdWordsAccountLabel"
      ),
      default: "",
      items: {
        enum: [
          t('pages.generic-translation.yesOption'),
          t('pages.generic-translation.noOption'),
        ],
      },
    },

    keywordHeading: {
      type: "string",
      title: t("pages.socialAds.sem-keywords.keyword.headingLabel"),
    },
    keywordListCreation: {
      type: "string",
      title: t("pages.socialAds.sem-keywords.keyword.keywordListCreationLabel"),
      description: t(
        "pages.socialAds.sem-keywords.keyword.keywordListCreationHelperText"
      ),
      default: data?.keywordListCreation || "",
      items: {
        enum: [
          t("pages.socialAds.sem-keywords.keyword.keywordOptions.useBestPractices"),
          t("pages.socialAds.sem-keywords.keyword.keywordOptions.useMyKeyword"),
          t("pages.socialAds.sem-keywords.keyword.keywordOptions.useOnlyMyKeyword"),
        ],
      }
    },
    excludeFollowingKeywords: {
      type: "string",
      title: t(
        "pages.socialAds.sem-keywords.keyword.excludeFollowingKeywordsLabel"
      ),
      description: t(
        "pages.socialAds.sem-keywords.keyword.followingKeywordsHelperText"
      ),
    },
    includeFollowingNegativeKeywords: {
      type: "string",
      title: t(
        "pages.socialAds.sem-keywords.keyword.includeFollowingNegativeKeywordsLabel"
      ),
      description: t(
        "pages.socialAds.sem-keywords.keyword.followingKeywordsHelperText"
      ),
    },
    excludeGeoKeywords: {
      type: "string",
      title: t("pages.socialAds.sem-keywords.keyword.excludeGeoKeywordsLabel"),
      description: t(
        "pages.socialAds.sem-keywords.keyword.excludeGeoKeywordsHelperText"
      ),
    },
    additionalGeoKeywords: {
      type: "string",
      title: t(
        "pages.socialAds.sem-keywords.keyword.additionalGeoKeywordsLabel"
      ),
      description: t(
        "pages.socialAds.sem-keywords.keyword.additionalGeoKeywordsHelperText"
      ),
    }
  },
  dependencies: {
    flightedCampaign: {
      oneOf: [
        {
          properties: {
            flightedCampaign: {
              enum: ["Yes"],
            },
            flightStartDate: {
              type: "string",
              title: t(
                "pages.socialAds.sem-keywords.general.flightStartDateLabel"
              ),
              default: data?.flightStartDate || "",
            },
            flightEndDate: {
              type: "string",
              title: t(
                "pages.socialAds.sem-keywords.general.flightEndDateLabel"
              ),
              default: data?.flightEndDate || "",
            },
          },
        },
      ],
    },
    accessToPreviousAdWordsAccount: {
      oneOf: [
        {
          properties: {
            accessToPreviousAdWordsAccount: {
              enum: ["Yes"],
            },
            googleCustomerId: {
              type: "string",
              title: t(
                "pages.socialAds.sem-keywords.general.googleCustomerIdLabel"
              ),
              tooltip: t("pages.socialAds.sem-keywords.general.googleCustomerIdTooltip"),
              description: t(
                "pages.socialAds.sem-keywords.general.googleCustomerIdHelperText"
              ),
            },
          },
        },
      ],
    }
  },
});

export const SEMKeywordUISchema = {
  "ui:order": [
    "generalHeading",
    "enableRemarketingListsForSearchAds",
    "flightedCampaign",
    "flightStartDate",
    "flightEndDate",
    "kpiValue",
    "accessToPreviousAdWordsAccount",
    "googleCustomerId",
    "keywordHeading",
    "keywordListCreation",
    "excludeFollowingKeywords",
    "includeFollowingNegativeKeywords",
    "additionalGeoKeywords",
    "excludeGeoKeywords"
  ],
  generalHeading: { "ui:widget": "headingTextLabel" },
  enableRemarketingListsForSearchAds: { "ui:widget": "radioButton" },
  flightedCampaign: { "ui:widget": "radioButton" },
  flightStartDate: { "ui:widget": "datePickerField" },
  flightEndDate: { "ui:widget": "datePickerField" },
  kpiValue: { "ui:widget": "TextareaWidget" },
  accessToPreviousAdWordsAccount: { "ui:widget": "radioButton" },
  googleCustomerId: { "ui:widget": "textInputField" },

  keywordHeading: { "ui:widget": "headingTextLabel" },
  keywordListCreation: { "ui:widget": "radioButton" },
  excludeFollowingKeywords: { "ui:widget": "TextareaWidget" },
  includeFollowingNegativeKeywords: { "ui:widget": "TextareaWidget" },
  additionalGeoKeywords: { "ui:widget": "TextareaWidget" },
  excludeGeoKeywords: { "ui:widget": "TextareaWidget" }
};

export const SEMKeywordCustomValidate = (formData, errors, uiSchema) => {
  if (formData.flightedCampaign == "Yes") {
    if (formData.flightStartDate && formData.flightStartDate.trim().length < 1) {
      errors.addError(true);
      errors.flightStartDate.addError(
        t("pages.socialAds.sem-keywords.general.flightDateErrorMessage")
      );
    }
    if (formData.flightEndDate && formData.flightEndDate.trim().length < 1) {
      errors.addError(true);
      errors.flightEndDate.addError(
        t("pages.socialAds.sem-keywords.general.flightDateErrorMessage")
      );
    }
    if(formData.flightEndDate && formData.flightEndDate.trim().length > 1 && formData.flightStartDate && formData.flightStartDate.trim().length > 1) {
      const start = new Date(formData.flightStartDate.trim());
      const end = new Date(formData.flightEndDate.trim());
      if(start.getTime() > end.getTime()) {
        errors.addError(true);
        errors.flightEndDate.addError(t("pages.socialAds.sem-keywords.general.flightEndDateErrorMessage"));
      }
    }

  }
  if (formData.accessToPreviousAdWordsAccount == "Yes") {
    if (formData.googleCustomerId && formData.googleCustomerId.trim().length < 1) {
      errors.addError(true);
      errors.googleCustomerId.addError(
        t("pages.socialAds.sem-keywords.general.googleCustomerIdErrorMessage")
      );
    }
  }

  if(formData.additionalGeoKeywords && formData.additionalGeoKeywords.trim().length > 0) {
    const additionalGeoKeywords = formData.additionalGeoKeywords.trim().split(/[\,,\n]/);
    if(additionalGeoKeywords.length > 10) {
      errors.addError(true);
      errors.additionalGeoKeywords.addError(
        t("pages.socialAds.sem-keywords.keyword.additionalGeoKeywordsMaxError")
      );
    }
  }
  if (formData?.flightedCampaign === '') {
    errors.flightedCampaign.addError(t('pages.generic-translation.mandatoryRadioButtonErrorMessage'));
    errors.addError(true);
  }
  if (formData?.enableRemarketingListsForSearchAds === '') {
    errors.enableRemarketingListsForSearchAds.addError(t('pages.generic-translation.mandatoryRadioButtonErrorMessage'));
    errors.addError(true);
  }
  if (formData?.accessToPreviousAdWordsAccount === '') {
    errors.accessToPreviousAdWordsAccount.addError(t('pages.generic-translation.mandatoryRadioButtonErrorMessage'));
    errors.addError(true);
  }
  if (formData?.keywordListCreation === '') {
    errors.keywordListCreation.addError(t('pages.generic-translation.mandatoryRadioButtonErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
