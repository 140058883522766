/* eslint-disable max-len */
import {
  Box, Accordion, AccordionSummary, Typography, AccordionDetails, Grid, IconButton, Button,
} from '@mui/material';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import React, {
  Children, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  accordionStyles, getSectionTitle,
} from 'pages/overview/Overview';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrder,
  getOrderItems,
  selectOrderItems,
} from 'services/ordersSlice';
import { getOrderBusinessByOrderId } from 'services/businessSlice';
import { getOrderDetails, selectOrderDetails } from 'services/myOrdersSlice';
import {
  getOrderItemsWithCampaignId,
  getOrderItemsWithCidStatus,
} from 'services/campaignSlice';
import XmoExternalSection from 'pages/overview/sections/xmo/XmoExternalSection';

export default function ExternalSubmissionView() {
  const { orderId, productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderItems = useSelector(selectOrderItems);
  const orderDetails = useSelector(selectOrderDetails);
  const [sections, setSections] = useState([]);
  const [expandAll, setExpandAll] = useState(false);
  const expandedAccordionRef = useRef(null);

  const getOrderData = async () => {
    await dispatch(getOrderDetails(orderId));
    dispatch(getOrder(orderId));
    dispatch(getOrderBusinessByOrderId(orderId));
    dispatch(getOrderItems(orderId));
    dispatch(getOrderItemsWithCampaignId(orderId));
    dispatch(getOrderItemsWithCidStatus(orderId));
  };

  useEffect(() => {
    getOrderData();
  }, [orderId]);

  const scrollToExpandedAccordion = () => {
    if (expandedAccordionRef.current) {
      expandedAccordionRef.current.scrollIntoView({
        block: 'start',
      });
    }
  };

  useEffect(() => {
    if (orderItems && orderDetails) {
      const xmoProducts = orderItems.content.filter(item => item.pdOfferingType === 'XMO');
      if (xmoProducts.length > 0) {
        const products = xmoProducts.map(item => ({
          title: item.pdOfferingType,
          isOpen: true,
          ...item,
          props: {
            product: item,
          },
        }));
        setSections(products);
      } else {
        navigate(`/order/${orderId}`);
      }
    }
    scrollToExpandedAccordion();
  }, [orderItems]);

  const toggleAllSections = () => {
    setExpandAll(!expandAll);
    const copy = [...sections].map(section => ({ ...section, isOpen: !expandAll }));
    setSections(copy);
  };

  const handleChange = (index) => {
    const copy = [...sections];
    copy[index].isOpen = !sections[index].isOpen;
    setSections(copy);
  };

  return (
    <Grid container sx={{ width: '1300px', margin: 'auto' }}>
      <Grid item xs={12}>
        <IconButton onClick={() => navigate(`/order/${orderId}`)} sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          textDecoration: 'none',
          color: '#000000',
          paddingLeft: 0,
          '&:hover': {
            background: 'transparent',
          },
        }}
        >
          <ChevronLeftIcon fontSize="large" />
          <Typography>Order Summary</Typography>
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Typography variant="h1" marginTop={2} marginBottom={0}>External Submission View</Typography>
          <Button onClick={toggleAllSections} sx={{ alignSelf: 'flex-end', textDecoration: 'underline' }}>
            {expandAll ? ' Collapse All' : 'Expand All'}
          </Button>
          {Children.toArray(sections?.map((section, index) => (
            <Accordion
              expanded={sections[index]?.isOpen}
              onChange={() => handleChange(index)}
              disableGutters
              sx={accordionStyles}
              ref={sections[index]?.isOpen ? expandedAccordionRef : null}
            >
              <AccordionSummary expandIcon={<ExpandCircleDownOutlinedIcon color="primary" />}>
                <Typography variant="h5" marginRight={1}>
                  {getSectionTitle(section)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {sections[index]?.isOpen && (<XmoExternalSection {...section.props} />)}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )))}
        </Box>
      </Grid>
    </Grid>
  );
}
