/* eslint-disable no-param-reassign */
import axios from 'axios';
import stringToHash from 'shared/hash';
import { Cookies } from 'react-cookie';
const ARGON_API_EXTERNAL_ROOT = process.env.REACT_APP_BACKEND_ROOT;
const ARGON_API_INTERNAL_ROOT = `${process.env.REACT_APP_GATEWAY_ROOT}internal/`;
const COBALT_API_ROOT = `${process.env.REACT_APP_COBALT_ROOT}api/v1/`;

const withCredentialsValue = process.env.NODE_ENV !== 'development';

const AxiosAPI = axios.create({
  withCredentials: withCredentialsValue,
  baseURL: ARGON_API_EXTERNAL_ROOT,
  // @ts-ignore
  timeout: (process.env.REACT_APP_AXIOS_TIMEOUT || 60) * 1000,
});

export const AxiosCarbonAPI = axios.create({
  withCredentials: withCredentialsValue,
  baseURL: ARGON_API_EXTERNAL_ROOT,
  // @ts-ignore
  timeout: (300) * 1000,
});

export const AxiosAPITextHeader = axios.create({
  withCredentials: withCredentialsValue,
  baseURL: ARGON_API_EXTERNAL_ROOT,
    headers: {
      'Content-Type': 'text/plain'
    },

  // @ts-ignore
  timeout: (process.env.REACT_APP_AXIOS_TIMEOUT || 60) * 1000,

});

export const AxiosArgonInternalAPI = axios.create({
  withCredentials: withCredentialsValue,
  baseURL: ARGON_API_INTERNAL_ROOT,
  // @ts-ignore
  timeout: (process.env.REACT_APP_AXIOS_TIMEOUT || 60) * 1000,
});

AxiosAPI.interceptors.request.use(
  (config) => {
    if (process.env.REACT_APP_ACCESS_TOKEN) {
      // TODO fix the ACCESS TOKEN
      config.headers.Authorization = process.env.REACT_APP_ACCESS_TOKEN;
    }

    config.headers.correlationId = stringToHash(config.headers.Authorization);
    config.headers['application-source'] = 'cobalt';

    return config;
  },
  (error) => Promise.reject(error),
);

AxiosAPI.interceptors.response.use((response) => response, (error) => {
  if (!error.response) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(error && error.response);
  }
  const { response } = error;
  if (response.status === 401) {
    // persist current url
    window.localStorage.setItem("neon_redirect", window.location.href);
    setTimeout(()=> { window.location.href = `${process.env.REACT_APP_COBALT_ROOT}`;}, 1000);
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('Token Expired');
  } if (response.status === 403) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('Forbidden');
  }
  return Promise.reject(error);
});

export const AxiosCobaltAPI = axios.create({
  baseURL: COBALT_API_ROOT,
  // @ts-ignore
  timeout: (process.env.REACT_APP_AXIOS_TIMEOUT || 60) * 1000,
  headers: { Authorization: `Bearer ${process.env.REACT_APP_COBALT_APP_API_KEY}` },
});

export default AxiosAPI;
