import { useDispatch, useSelector } from 'react-redux';
import { selectOrdersContent } from 'services/ordersSlice';
import React, { useEffect } from 'react';
import { getBusinessLocations } from 'services/businessLocationSlice';
import LocationsPageForm from './LocationsPageForm';

export default function LocationsPage() {
  const dispatch = useDispatch();
  const { businessId } = useSelector(selectOrdersContent);

  useEffect(() => {
    if (businessId) dispatch(getBusinessLocations(businessId));
  }, [businessId]);

  return (
    <LocationsPageForm />
  );
}
