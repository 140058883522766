import {
  Box, Button, Modal, Typography,
} from '@mui/material';
import { Stack } from '@mui/system';

interface Props {
  action: 'upgrade' | 'downgrade'
  open: boolean
  onClose: () => void
  onContinue: () => void
}

const contentMap = {
  upgrade: {
    title: 'Upgrade Site Plan',
    content: 'Are you sure you want to upgrade this site to basic? '
    + 'Remember to set up the domain after upgrading the site.',
  },
  downgrade: {
    title: 'Downgrade Site Plan',
    content: 'Are you sure you want to downgrade this site to sandbox (free plan)? '
    + 'This will disconnect the domain and take the client site offline. '
    + 'The domain information will have to be re-entered in order to upgrade again.',
  },
};

export default function ConfirmationModal({
  open, onClose, onContinue, action,
}: Props) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ margin: 'auto' }}
    >
      <Box sx={{
        background: 'white',
        margin: 'auto',
        marginTop: '80px',
        padding: '70px 40px 90px',
        width: '500px',
        borderRadius: '8px',
      }}
      >
        <Typography fontSize="22px" fontWeight="bold">{contentMap[action]?.title}</Typography>
        <Typography marginTop={2}>{contentMap[action]?.content}</Typography>
        <Stack direction="row-reverse" marginTop={2}>
          <Button variant="contained" color="primary" sx={{ marginLeft: 2 }}
            onClick={onContinue}
          >
            <Typography>Yes, Continue</Typography>
          </Button>
          <Button variant="outlined" color="primary" onClick={onClose}>
            <Typography>Cancel</Typography>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
