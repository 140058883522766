import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import {
  ValidationProvider, IQInputNumericField, IQYesNoFormRadioButtons,
} from '@gannettdigital/shared-react-components';
import { Typography, Grid } from '@mui/material';
import { StepperRootProps } from 'layouts/StepperLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  selectCustomWebsite, getCustomWebsite, putCustomWebsiteContentPage,
} from 'services/customWebsiteSlice';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { selectActiveProductId } from 'services/navigationSlice';
import schema from './Content.schema';

export default function CustomWebsiteContent(props: StepperRootProps) {
  const { t } = useTranslation();
  const { orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const customWebsite = useSelector(selectCustomWebsite);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      totalNumPages: null,
      copyrightNeeded: '',
      copyrightPagesAmount: null,
    },
  });

  const {
    getValues, handleSubmit, reset, watch, setValue, formState: {
      isDirty, isSubmitting, isSubmitSuccessful, isValid,
    },
  } = methods;

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid]);

  const getRadioValue = (value) => (value ? 'yes' : 'no');

  const copyrightNeeded = useMemo(
    () => {
      if (getValues('copyrightNeeded') === 'no') setValue('copyrightPagesAmount', null);
      return getValues('copyrightNeeded') === 'yes';
    },
    [watch('copyrightNeeded')],
  );

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsite(websiteId.toString()));
  }, [websiteId]);

  useEffect(() => {
    reset({
      totalNumPages: customWebsite.numberOfPages === 0 ? null : customWebsite.numberOfPages,
      copyrightNeeded: customWebsite.copyrightNeeded === null ? null : getRadioValue(customWebsite.copyrightNeeded),
      copyrightPagesAmount: customWebsite.copyrightPagesAmount === 0 ? '' : customWebsite.copyrightPagesAmount,
    });
  }, [customWebsite]);

  const onFormSubmit = () => {
    const data = getValues();
    const content = {
      totalNumPages: data.totalNumPages ? data.totalNumPages : 0,
      copyrightNeeded: data.copyrightNeeded,
      copyrightPagesAmount: data.copyrightPagesAmount ? data.copyrightPagesAmount : 0,
    };
    dispatch(putCustomWebsiteContentPage({ orderItemId, content }));
    props.onContinue();
  };

  return (
    <>
      <Typography variant="h2" marginTop={2}>{t('pages.website.custom.content.title')}</Typography>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container marginTop={5}>
              <Grid item xs={6} marginBottom={3}>
                <IQInputNumericField
                  name="totalNumPages"
                  fullWidth
                  disallowNegative
                  label={t('pages.website.custom.content.numberOfSitePages')}
                  fontLabelWeight="600"
                  required
                  defaultValue=""
                  tooltipText={t('pages.website.custom.content.numberOfSitePagesTooltip')}
                  tooltilPaddingBottom={18}
                  tooltipPlacement="top"
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6} marginBottom={3}>
                <IQYesNoFormRadioButtons
                  title={t('pages.website.custom.content.copywritingServicesNeeded')}
                  fontTitleWeight="bold"
                  field="copyrightNeeded"
                />
              </Grid>
              <Grid item xs={6} />
              {copyrightNeeded && (
                <Grid item xs={6}>
                  <IQInputNumericField
                    name="copyrightPagesAmount"
                    fullWidth
                    disallowNegative
                    defaultValue=""
                    label={t('pages.website.custom.content.numberOfContentPages')}
                    fontLabelWeight="600"
                    required
                  />
                </Grid>
              )}
            </Grid>
          </form>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </>
  );
}
