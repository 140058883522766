import React, { useState } from 'react';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import {
  FormHelperText, Grid, InputAdornment, TextField, Typography,
} from '@mui/material';
import ErrorText from 'components/errorText/ErrorText';
import EmailIcon from '@mui/icons-material/Email';
import * as Yup from 'yup';
import i18n from 'i18n/i18n';

const { t } = i18n;

export const validateEmail = (email) => String(email)
  .toLowerCase()
  .match(
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

export default function EmailTextField(props: any) {
  const [isTouched, setIsTouched] = useState(!!props.value);
  const [validationError, setValidationError] = useState('');

  const emailSchema = Yup.object().shape({
    email: props.required
      ? Yup.string().required('Email is required')
        .email(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'))
      : Yup.string()
        .email(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'))
        .notRequired(),
  });

  const handleChange = (newValue) => {
    setIsTouched(true);
    emailSchema
      .validate({ email: newValue })
      .then(() => {
        setValidationError('');
      })
      .catch((error) => {
        setValidationError(error.message);
      });
    props.onChange(newValue);
  };

  const hasRawErrors = props?.rawErrors?.length > 0;

  return (
    <Grid item xs={9} mt={3}>
      <Typography
        fontWeight={600}
        sx={{
          color: validationError || (hasRawErrors && isTouched)
            ? IQThemeV2.palette.error.main
            : IQThemeV2.palette.text.primary,
        }}
      >
        {props.label}
      </Typography>
      <TextField
        sx={{ mt: 1 }}
        id={props.id}
        disabled={props.disabled}
        required={props.required}
        name={props.name}
        type="email"
        error={!!validationError || (hasRawErrors && isTouched)}
        value={props.value}
        onChange={(e) => handleChange(e.target.value)}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />
      {props.placeholder && (!hasRawErrors || !isTouched) && (
        <FormHelperText error={false}>{props.placeholder}</FormHelperText>
      )}
      <ErrorText
        hasError={!!validationError || (hasRawErrors && isTouched)}
        errorText={validationError || props?.rawErrors?.[0]}
      />
    </Grid>
  );
}
