import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  getBundleItems, getBundles, updateBundle, updateBundleItems,
} from 'services/adminSlice';
import BundleForm from './BundleForm';

export default function EditBundle() {
  const { bundleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const saveBundle = async (data) => {
    const bundle = {
      id: bundleId,
      active: true,
      name: data.title,
      mainThemeId: data.themeId,
    };
    // build addons
    const updateAddOns = data.bundleAddons.map(({ themeId }) => ({ bundleId, themeId, addon: true }));
    const updateProducts = data.bundleProducts.map(({ themeId }) => ({ bundleId, themeId, addon: false }));
    const allBundleItems = updateAddOns.concat(updateProducts);
    await dispatch(updateBundle({bundle, allBundleItems}));
    await dispatch(updateBundleItems({bundleId: bundle.id, resource: allBundleItems}));
    await dispatch(getBundles({}));
    await dispatch(getBundleItems(bundle.id));
    navigate('/admin/bundles');
  };

  return (
    <>
      <Typography variant="h2">Edit Bundle</Typography>
      <BundleForm onSubmit={saveBundle} bundleId={parseInt(bundleId, 10)} />
    </>
  );
}
