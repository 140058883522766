import * as yup from 'yup';

const schema = {
  template: yup.string().required(),
  brandingNotes: yup.string(),
  designNotes: yup.string(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
