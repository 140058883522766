import {
  Avatar,
  Button, Menu, MenuItem, Typography, useTheme,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCountries, selectCurrentCountry, setCountry,
} from 'services/topNavMenuSlice';
import { ThemeFilterType, setFilter } from 'services/themesSlice';
import { requestNewOrder, selectOrdersContent, setOrderCountry } from 'services/ordersSlice';

const CountryMenu = (props: any) => {
  const theme = useTheme();

  return (
    <Menu
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: theme.palette.text.primary,
          color: 'white',
          width: '200px',
          marginTop: '12px',
        },
      }}
      PopoverClasses={{ root: 'custom-popover' }}
      {...props}
    >
      {props.children}
    </Menu>
  );
};

const CountryMenuItem = (props: any) => (
  <MenuItem
    sx={{
      '&:hover': {
        backgroundColor: 'gray',
      },
      fontSize: 14,
    }}
    {...props}
  >
    {props.children}
  </MenuItem>
);

export default function CoCountrySelector() {
  const order = useSelector(selectOrdersContent);
  const countries = useSelector(selectCountries);
  const country = useSelector(selectCurrentCountry);
  const countryButtonRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const setCountryRequest = async (orderId, country) => {
    await dispatch(setOrderCountry({ orderId, country }));
    await dispatch(requestNewOrder({}));
  };

  const handleMenuOpen = () => setMenuOpen(true);

  const handleMenuClose = () => setMenuOpen(false);

  const selectCountry = async (c) => {
    dispatch(setCountry(c));
    dispatch(setFilter({ type: ThemeFilterType.PRODUCT_TYPE, value: '' }));
    setCountryRequest(order.id, c.name);
  };

  return (
    <>
      <Button
        sx={{ color: 'white', zIndex: '100000', paddingBottom: '0 !important' }}
        ref={countryButtonRef} onMouseOver={handleMenuOpen} onMouseOut={handleMenuClose}
      >
        <Typography variant="body2">{country.title}</Typography>
        <Avatar
          alt={country.name}
          src={country.icon}
          sx={{
            width: 24, height: 24, paddingRight: '0 !important', marginLeft: 2,
          }}
        />
        <ArrowDropDownIcon />
      </Button>
      <CountryMenu
        anchorEl={countryButtonRef.current}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{ onMouseLeave: handleMenuClose, onMouseOver: handleMenuOpen }}
      >
        {countries.map(c => (
          <CountryMenuItem key={c.name} onClick={() => selectCountry(c)}>{c.title}</CountryMenuItem>
        ))}
      </CountryMenu>
    </>
  );
}
