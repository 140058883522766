export const campaignOptions = [{
  label: 'Instagram',
  value: 'instagram',
},
{
  label: 'X (Formerly Twitter)',
  value: 'x',
},
];

export const existingFacebookOptions = [{
  label: 'Yes',
  value: 'yes',
},
{
  label: 'No - We can assist the client in creating their Business Page.',
  value: 'no',
}];

export const yesNoOptions = [{
  label: 'Yes',
  value: 'yes',
},
{
  label: 'No',
  value: 'no',
}];

export const reputationManagementOptions = [{
  label: 'GBP',
  value: 'gbp',
},
{
  label: 'Yelp',
  value: 'yelp',
}];
