import {
  IQButtonLink,
  IQFormInput,
  IQTheme, IQThemeV2, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import React, {useEffect} from 'react';
import {Dialog, DialogContent, DialogTitle, Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';
import GridItem from 'components/grid/GridItem';
import schema from './TrackingLine.schema';
import {FormProvider, useForm} from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";

import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {v4 as uuidv4} from 'uuid';
import CoNavigationConfirm from "components/navigation/CoNavigationConfirm";
import GridContainer from "components/grid/GridContainer";
import NePhoneInput from "components/NePhoneInputContainer/IQPhoneInputContainer";

export default function TrackingLineFormDialog({
                                                 open,
                                                 onOpen,
                                                 onClose,
                                                 updateTrackingLine,
                                                 selectedTrackingLine
                                               }) {
  const {t} = useTranslation();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      originalNumber: '',
      forwardToNumber: '',
      areaCodePreference: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
    setValue,
  } = methods;

  useEffect(() => {
    if (selectedTrackingLine) {
      // Populate form fields with selectedTrackingLine data
      setValue("originalNumber", selectedTrackingLine.originalNumber);
      setValue("forwardToNumber", selectedTrackingLine.forwardToNumber);
      setValue("areaCodePreference", selectedTrackingLine.areaCodePreference);
    }
  }, [selectedTrackingLine]);

  const handleFormSubmit = async (data) => {

    let {sourceId} = selectedTrackingLine || {};

    if (!sourceId) {
      // Generate a unique sourceId if it does not exist
      sourceId = uuidv4();
    }

    // Add life events with sourceId
    updateTrackingLine({...data, sourceId});

    // Close the form
    handleClose();
  };

  const handleOpen = () => {
    // Reset the form state
    reset({
      originalNumber: '',
      forwardToNumber: '',
      areaCodePreference: '',
    });
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  return (
      <>
        <Grid sx={{
          marginTop: -4
        }}>
          {<IQButtonLink startIcon={<AddIcon/>} onClick={() => handleOpen()}>
            {t('pages.xmo.solutionsStrategy.trackingLine.addATrackingLineTitle')}
          </IQButtonLink>}
        </Grid>
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
          <DialogTitle>{t('pages.xmo.solutionsStrategy.trackingLine.trackingLineHeader')}</DialogTitle>
          <DialogContent>
            <ValidationProvider schema={schema}>
              <FormProvider {...methods}>
                <form>
                  <GridContainer fullWidth>
                    <GridItem sizes={[12]}>
                      <NePhoneInput
                          name="originalNumber"
                          defaultValue=""
                          country="US"
                          phoneLabelOptions={{
                            tooltipText: '',
                            labelText: t('pages.xmo.solutionsStrategy.trackingLine.originalNumber'),
                            hasError: true,
                            theme: IQThemeV2,
                            required: true,
                          }}
                          hasType={false}
                          countryCallingCodeEditable={false}
                          international
                      />
                    </GridItem>
                    <GridItem sizes={[12]}>
                      <NePhoneInput
                          name="forwardToNumber"
                          defaultValue=""
                          country="US"
                          phoneLabelOptions={{
                            tooltipText: '',
                            labelText: t('pages.xmo.solutionsStrategy.trackingLine.forwardToNumber'),
                            hasError: true,
                            theme: IQThemeV2,
                            required: true,
                          }}
                          hasType={false}
                          countryCallingCodeEditable={false}
                          international
                      />
                    </GridItem>
                    <GridItem sizes={[12]}>
                      <IQFormInput
                          id="areaCodePreference"
                          name="areaCodePreference"
                          labelText={t('pages.xmo.solutionsStrategy.trackingLine.areaCodePreference')}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          schema={schema}
                      />
                    </GridItem>
                  </GridContainer>
                </form>
                <CoNavigationConfirm
                    showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
                />
              </FormProvider>
            </ValidationProvider>
          </DialogContent>
          <DialogActions>
            <Button
                onClick={handleClose}>{t('pages.youtube.advertising.adGroupOptions.CloseBtn')}</Button>
            <Button disabled={!isValid}
                    onClick={handleSubmit(handleFormSubmit)}>{t("pages.youtube.advertising.adGroupOptions.submitBtn")}</Button>
          </DialogActions>
        </Dialog>
      </>
  );
}
