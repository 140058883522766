import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  clearPopUpCompleted, getAddons, getMasterAddonDetails,
  getPopUp,
  putPopUp, selectAddons,
  selectPopUp,
  selectPopUpCompleted,
  selectPopUpOrderItemId,
} from 'services/addons/addonsSlice';

import { getOrderItems } from 'services/ordersSlice';

import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';

import ContentLoader from 'components/contentLoader/ContentLoader';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import PopUpForm, { FormData } from './PopUpForm';

export type PopUpSubmitData = {
  actionDetails: string;
  additionalInfo: string;
  additionalNotes: string;
  button: boolean;
  contentDesignInformation: string;
  timer: boolean;
  date: Date | string;
  email: boolean;
  firstName: boolean;
  form: boolean;
  image: boolean;
  lastName: boolean;
  other: boolean;
  otherText: string;
  phone: boolean;
  primaryTypeLead: string;
  trigger: string;
  type: string;
};

const PopUpPage = () => {
  const navigate = useNavigationHandler();
  const { orderId, orderItemId } = useParams();

  const popUp = useSelector(selectPopUp);
  const pageCompleted = useSelector(selectPopUpCompleted);
  const popupItemId = useSelector(selectPopUpOrderItemId);
  const addons = useSelector(selectAddons);
  const topNav = useTopnavMenu(Urls.Popups);

  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  const dispatch = useDispatch();
  const onBack = () => {
    navigate.to(topNav.previousStep());
  };

  const onSubmit = (data: FormData) => {
    // delete internal formError prop and images arrays
    const {
      formError, popupImages, popupGettyImages, ...submitData
    } = data;
    dispatch(putPopUp({ orderItemId: popupItemId, popUpData: submitData }));
  };

  useEffect(() => {
    if (!popupItemId) {
      dispatch(getOrderItems(orderId));
    }
  }, [orderId]);

  useEffect(() => {
    if (popupItemId) {
      dispatch(getPopUp(popupItemId));
    }
  }, [popupItemId]);

  useEffect(() => {
    if (!addons?.content?.id) dispatch(getAddons(orderId));
    if (orderItemId) dispatch(getMasterAddonDetails(orderItemId));
  }, [addons, orderItemId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (pageCompleted) {
      dispatch(clearPopUpCompleted());
      if (isRedirectedFromOverviewPage) return navigate.to(Urls.Overview);
      navigate.to(topNav.nextStep());
    }
  }, [pageCompleted]);

  const status = popUp.status === 'idle' && !popupItemId ? 'loading' : popUp.status;

  return (
    <ContentLoader status={status} message={popUp.message}>
      <PopUpForm onSubmit={onSubmit} onBack={onBack} popUp={popUp.content} />
    </ContentLoader>
  );
};

export default PopUpPage;
