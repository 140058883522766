/* eslint-disable import/no-extraneous-dependencies */
import {useValidationContext} from '@gannettdigital/shared-react-components';
import {Box, FormControl} from '@mui/material';
import schemaValidate from 'components/schemaValidate';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import {useEffect} from 'react';
import {useController, useFormContext} from 'react-hook-form';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorText from 'components/errorText/ErrorText';

const FixedTimePickerComponent: any = TimePicker;

interface TimePickerProps {
  defaultValue?: any,
  name?: any,
  placeholder?: any,
  onChange?: any,
  params?: any,
  hasError?: boolean,
  errorText?: string,
}

export default function NeTimePicker({
                                       defaultValue, name, placeholder, onChange, params,
                                       hasError = false, errorText = 'There is an error',
                                     }: TimePickerProps) {
  const {schema} = useValidationContext();
  const {control} = useFormContext();
  const {
    field,
  } = useController({
    name,
    control,
    rules: {validate: (value) => schemaValidate(value, name, schema, params, null)},
    defaultValue: defaultValue || null,
  });

  useEffect(() => {
    const allWithClass = Array.from(
        document.getElementsByClassName('rc-time-picker-input'),
    );
    allWithClass.forEach(e => e.setAttribute('autocomplete', 'off'));
  }, []);

  return (
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <FormControl>
          <FixedTimePickerComponent
              name={field.name}
              placeholder={placeholder}
              defaultValue={defaultValue}
              format="hh:mm A"
              value={field.value ? moment(field.value, ['HH:mm']) : null}
              minuteStep={1}
              use12Hours
              allowEmpty={false}
              showSecond={false}
              onChange={onChange}
              inputIcon={<AccessTimeIcon/>}
          />
        </FormControl>
        <ErrorText hasError={hasError} errorText={errorText}/>
      </Box>
  );
}
