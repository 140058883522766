import { StepperRootProps } from 'layouts/StepperLayout';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { IQFormTextArea, ValidationProvider } from '@gannettdigital/shared-react-components';
import {
  getCustomWebsite, putCustomWebsiteEcomSchedulerInfo, putCustomWebsiteReferences, selectCustomWebsite,
} from 'services/customWebsiteSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { useAppDispatch } from 'services/redux/store';
import { selectActiveProductId } from 'services/navigationSlice';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import ErrorText from 'components/errorText/ErrorText';
import schema from './Scheduling.schema';

export default function CustomWebsiteScheduling(props: StepperRootProps) {
  const { t } = useTranslation();
  const { orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const customWebsite = useSelector(selectCustomWebsite);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      onlineAppointmentSchedulerInfo: '',
      additionalInformation: '',
    },
  });

  const {
    getValues, handleSubmit, reset, register, formState: {
      isDirty, isSubmitting, isSubmitSuccessful, isValid, errors,
    },
  } = methods;

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid]);

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsite(websiteId.toString()));
  }, [websiteId]);

  useEffect(() => {
    reset({
      onlineAppointmentSchedulerInfo: customWebsite.onlineAppointmentSchedulerInfo,
      additionalInformation: customWebsite.references,
    });
  }, [customWebsite]);

  const onFormSubmit = async () => {
    const data = getValues();
    await appDispatch(dispatch(getCustomWebsite(websiteId)))
      .then(async (response) => {
        const content = {
          eCommerceProductsAmount: response.payload.eCommerceProductsAmount || 0,
          eCommerceProducts: response.payload.eCommerceProducts || '',
          eCommerceVariations: response.payload.eCommerceVariations || 0,
          onlineAppointmentSchedulerInfo: data.onlineAppointmentSchedulerInfo,
        };
        dispatch(putCustomWebsiteEcomSchedulerInfo({ orderItemId, content }));
      });

    if (data.additionalInformation) {
      dispatch(putCustomWebsiteReferences({ orderItemId, content: { references: data.additionalInformation } }));
    }
    props.onContinue();
  };

  return (
    <>
      <Typography variant="h2" marginTop={2}>{t('pages.website.custom.scheduling.title')}</Typography>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container marginTop={5}>
              <Grid item xs={6} marginBottom={3}>
                <NeFormTextArea
                  {...register('onlineAppointmentSchedulerInfo')}
                  id="onlineAppointmentSchedulerInfo"
                  name="onlineAppointmentSchedulerInfo"
                  labelText={t('pages.website.custom.scheduling.schedulingFields')}
                  tooltipText={t('pages.website.custom.scheduling.schedulingFieldsTooltip')}
                  tooltipPaddingBottom={18}
                  tooltipPlacement="top"
                  fullWidth
                  rowCount={3}
                  fontLabelWeight="600"
                />
                <ErrorText
                  hasError={!!errors.onlineAppointmentSchedulerInfo}
                  errorText={t('pages.website.custom.scheduling.schedulingFieldsRequired')}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6} marginBottom={3}>
                <IQFormTextArea
                  id="additionalInformation"
                  name="additionalInformation"
                  labelText={t('pages.website.custom.scheduling.additionalInformation')}
                  fontLabelWeight="bold"
                  rowCount={3}
                  fullWidth
                />
              </Grid>
            </Grid>
          </form>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </>
  );
}
