import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from './redux/store-schedule';

export interface placeDetailState {
  placeDetail: any;
  addressPagePlaceId: string;
  hoursPagePlaceId: string;
}

const initialState: placeDetailState = {
  placeDetail: [],
  addressPagePlaceId: '',
  hoursPagePlaceId: ''
};

const placeDetailSlice = createSlice({
  name: 'placeDetails',
  initialState,
  reducers: {
    savePlaceDetail(state, action: PayloadAction<any>) {
      state.placeDetail = action.payload;
      state.addressPagePlaceId = action.payload;
      state.hoursPagePlaceId = action.payload;
    },
  },
});

export const {savePlaceDetail} = placeDetailSlice.actions;

export const selectPlaceDetail = (state: RootState) => state.placeDetails.placeDetail;

export default placeDetailSlice.reducer;
