import {IQButtonLink, IQLabelTooltip} from '@gannettdigital/shared-react-components';
import {useTheme} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {Trans, useTranslation} from 'react-i18next';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {useState} from 'react';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import SpecialHoursPicker from './SpecialHoursPicker';

export default function SpecialHours() {
  const {t} = useTranslation();
  const theme = useTheme();
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [hourToRemove, setHourToRemove] = useState(-1);

  const {fields: days, append: addDay, remove: removeDay} = useFieldArray({
    name: 'specialHours',
  });
  const {getValues, setValue} = useFormContext();

  const onAddHours = () => {
    addDay({
      dateFrom: '',
      dateTo: '',
      specialFromHour1: '',
      specialToHour1: '',
      specialFromHour2: '',
      specialToHour2: '',
      allDay: false,
      secondTimeSlot: false,
      closed: true,
    });
  };

  const onRemoveDay = (index) => {
    setHourToRemove(index);
    setShowRemoveDialog(true);
  };

  const deleteHour = () => {
    removeDay(hourToRemove);
    setHourToRemove(-1);
    const newHours = getValues('specialHours');
    newHours.forEach((hour, index) => {
      setValue(`specialHours.${index}`, newHours[index]);
      setValue(`specialHours.${index}.date`, newHours[index].date);
    });
  };

  const cancelRemoveHour = () => {
    setShowRemoveDialog(false);
    setHourToRemove(-1);
  };

  return (
      <GridItem>
        <GridContainer fullWidth>
          <GridItem>
            <IQLabelTooltip
                labelText={t('pages.premiumListings.location.hours.specialHours.title')}
                tooltipText={t('pages.premiumListings.location.hours.specialHours.tooltip')}
                tooltipPlacement="top"
                hasError={false}
                required={false}
                theme={theme}
            />
          </GridItem>
          <GridItem>
            {days.map((day, index) => {
              const key = index;
              return (<SpecialHoursPicker rowNumber={index} onRemoveDay={onRemoveDay} key={key}/>);
            })}
            <CoModalConfirm open={showRemoveDialog}
                            title={t('pages.premiumListings.location.hours.specialHours.removeHours')}
                            description={(
                                <Trans
                                    i18nKey="pages.business.location.hours.hoursOfOperation.removeHoursMessage"
                                    values={{range: ''}}
                                    components={{strong: <strong/>}}
                                />
                            )}
                            confirmText="Remove"
                            handleClose={cancelRemoveHour}
                            handleConfirm={deleteHour}
            />
          </GridItem>
          <GridContainer sx={{marginTop: '-10px'}}>
            <IQButtonLink startIcon={<AddIcon/>} onClick={onAddHours}>
              {t('pages.premiumListings.location.hours.specialHours.addSpecialHours')}
            </IQButtonLink>
          </GridContainer>
        </GridContainer>
      </GridItem>
  );
}
