import { Paper, Typography } from '@mui/material';

export default function ProductUnavailable() {
  return (
      <Paper
          elevation={3}
          sx={{
            width: '100%',
            padding: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px'
          }}
      >
        <Typography variant="h4" mb={2}>Product Unavailable</Typography>
        <Typography>
          This offer does not have an associated order form, please contact operations support.
        </Typography>
      </Paper>
  );
}
