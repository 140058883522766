import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { Grid, MenuItem } from '@mui/material';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import WebsitesType from 'models/WebsitesType';
import { FormProvider, useForm } from 'react-hook-form';
import i18n from 'i18n/i18n';
import NeDropdown from 'components/dropdown/NeDropdown';
import { useAppDispatch } from 'services/redux/store';
import { useEffect, useState } from 'react';
import { getFilteredProducts } from 'services/themesSlice';
import { useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { putProduct } from 'services/productsSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { setWebsiteThemeId } from 'services/ordersSlice';
import { getWebsite } from 'services/websitesSlice';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import schema from './ThemeSelection.schema';
import { mapProduct } from './ThemeSelection';

const { t } = i18n;

interface Props {
  data: any
  product: string
  website: WebsitesType
  onBack: () => void
}

export default function ThemeSelectionForm(props: Props) {
  const { product, data } = props;
  const { orderItemId } = useParams();
  const history: any = useLocation();
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const topNav = useTopnavMenu(Urls.Theme);
  const offeringType = useSelector(selectActiveOfferingType);
  const websiteId = useSelector(selectActiveProductId);
  const [themes, setThemes] = useState([]);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      themeDesign: data?.themeDesign || '',
    },
  });

  const {
    register,
    handleSubmit,
    formState: {
      isDirty,
      isValid,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  useEffect(() => {
    if (product) {
      appDispatch(getFilteredProducts({
        product,
        country: 'USA',
        businessFunctionalityId: '',
        marketCategoryId: '',
        exclude: '',
      })).then((res) => setThemes(res.payload.content.map(theme => ({
        id: theme.id,
        title: theme.title,
      }))));
    }
  }, [product]);

  const onSubmit = async (content) => {
    if (product === mapProduct.LandingPage) {
      dispatch(putProduct({
        orderItemId,
        content: {
          ...content,
          themeId: themes.filter(theme => theme.title === content.themeDesign)[0].id,
        },
      }));
    }

    if ([mapProduct.Website, mapProduct.GoldSite].includes(product)) {
      await dispatch(setWebsiteThemeId({
        orderItemId,
        content: {
          themeId: themes.filter(theme => theme.title === content.themeDesign)[0].id,
        },
      }));
      dispatch(getWebsite({ websiteId, offeringType }));
    }

    if (history.state?.previousPath.includes('overview')) {
      navigate.to(Urls.Overview);
    } else {
      navigate.to(topNav.nextStep());
    }
  };

  const headerTitle = product === mapProduct.LandingPage
    ? t('pages.themePage.titleLandingPages')
    : t('pages.themePage.titleWebsite');

  return (
    <DefaultPageLayout
      header={headerTitle}
      onContinue={handleSubmit(onSubmit)}
      onBack={props.onBack}
      disableContinue={!isValid}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container item xs={8}>
            <Grid item mb={3} xs={12}>
              <Grid item mb={1}>
                <NeDropdown
                  name="themeDesign"
                  label={t('pages.themePage.themeDesignLabel')}
                  {...register('themeDesign', { required: 'This is required' })}
                  required
                  defaultValue={data?.themeDesign || ''}
                >
                  {themes.map(opt => (
                    <MenuItem value={opt.title} key={opt.id}>{opt.title}</MenuItem>
                  ))}
                </NeDropdown>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
