/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { Trans, useTranslation } from 'react-i18next';
import {
  FormControlLabel, Link, Radio, RadioGroup, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  IQFormInput, IQFormTextArea, IQInputEmail, IQTheme, IQThemeV2, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { FormProvider, useForm } from 'react-hook-form';
import schemaValidate from 'components/schemaValidate';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import NePhoneInput from 'components/NePhoneInputContainer/IQPhoneInputContainer';
import {
  ProxyProvisioningPageFormProps,
} from 'pages/youtube/proxy-provisioning/ProxyProvisioningPageForm';
import GenericCard from 'components/generic-card/GenericCard';
import WebEventFormDialog from 'pages/youtube/webevents/WebEvent';
import { updateOrAddDataBasedOnSourceId } from 'pages/youtube/YouTubeService';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { tooltip } from 'pages/general-questions/form-sections/Tracking';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import schema from './XmoProxyBrandAmplifier.schema';
import { normalizeNewLines } from "shared/constants";

interface Props {
  productData: any;
  onSubmit: (data: any) => void;
  onBack: () => void;
}

type DetailsFormProps = {
  advertiserEmail: string,
  localAreaCodePreference1: string,
  localAreaCodePreference2: string,
  businessNameForCallVerification: string,
  businessWebsiteUrl: string,
  isCaptchaInstalled: string,
  trackingForTheCaptchaPiece: string,
  clientHaveAnotherMaidAccount: string,
  proxyTheFollowingAreas: string,
  webEventType: string,
  webEventFields: {
    sourceId: string;
    webEventValue: string;
    webEventUrl: string;
    webEventName: string;
  }[],
  facebookWebsiteUrl:string,
  clientFullName: string,
  clientEmail: string,
  clientPhoneNumber: string,
  extension: string,
  isPersonToGrantFbAdminAccess: string,
  isEmailForSocialMockupApproval: string,
  facebookAdminAccessEmail: string,
  socialMockupApprovalEmail: string,
};

export default function XmoProxyBrandAmplifierForm({ productData, onSubmit, onBack }: Props) {
  const { t } = useTranslation();
  const prodXmoStepDetails = productData?.details;
  const product = productData?.solutionStrategy;
  const [isCaptchaInstalledValue, setIsCaptchaInstalledValue] = useState(product?.isCaptchaInstalled || '');
  const [isPersonToGrantFbAdminAccess, setIsPersonToGrantFbAdminAccess] = useState(product?.isPersonToGrantFbAdminAccess || '');
  const [isEmailForSocialMockupApproval, setIsEmailForSocialMockupApproval] = useState(product?.isEmailForSocialMockupApproval || '');

  /** Web Events State Management Logic and Various Operations * */
  const [webEventFields, setWebEventFields] = useState<ProxyProvisioningPageFormProps['webEventFields']>(product.webEventFields || []);
  const [webEventsFieldsFormDialogOpen, setWebEventsFieldsFormDialogOpen] = useState(false);
  const [selectedWebEventFields, setSelectedWebEventEventFields] = useState<ProxyProvisioningPageFormProps['webEventFields']>([]);
  const [webEventsSelectedValue, setWebEventsSelectedValue] = useState(product?.webEventType || '');
  const webEventFieldLabels = ['webEventName', 'webEventValue', 'webEventUrl'];

  const mmrCodeReferenceUrl = 'https://gannett.sharepoint.com/:w:/s/TheSource/EXlaLcJ8StRNtXMBKR1FbWgBWP0K_posivJmdPjdShbaXA?e=65Ar66';

  const methods = useForm<DetailsFormProps>({
    mode: 'onChange',
    defaultValues: {
      advertiserEmail: product?.advertiserEmail || '',
      localAreaCodePreference1: product?.localAreaCodePreference1 || '',
      localAreaCodePreference2: product?.localAreaCodePreference2 || '',
      businessNameForCallVerification: product?.businessNameForCallVerification || '',
      businessWebsiteUrl: product?.businessWebsiteUrl || '',
      isCaptchaInstalled: product?.isCaptchaInstalled || '',
      clientHaveAnotherMaidAccount: product?.clientHaveAnotherMaidAccount || '',
      trackingForTheCaptchaPiece: product?.trackingForTheCaptchaPiece || '',
      proxyTheFollowingAreas: normalizeNewLines(product?.proxyTheFollowingAreas) || '',
      webEventType: product?.webEventType || '',
      facebookWebsiteUrl: product?.businessWebsiteUrl || '',
      clientFullName: product?.clientFullName || '',
      clientEmail: product?.clientEmail || '',
      clientPhoneNumber: product?.clientPhoneNumber || '',
      extension: product?.extension || '',
      isPersonToGrantFbAdminAccess: product?.isPersonToGrantFbAdminAccess || '',
      isEmailForSocialMockupApproval: product?.isEmailForSocialMockupApproval || '',
      facebookAdminAccessEmail: product?.facebookAdminAccessEmail || '',
      socialMockupApprovalEmail: product?.socialMockupApprovalEmail || '',
      webEventFields: Array.isArray(product?.webEventFields) && product.webEventFields.length > 0
        ? product.webEventFields
        : webEventFields,
    },
  });

  const {
    register, handleSubmit, setValue, watch,
    formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    },
  } = methods;

  const onDeleteWebEventFields = async (webEventField: any) => {
    const updatedWebEvents = webEventFields.filter(
      (webEvent) => webEvent.sourceId !== webEventField.sourceId,
    );
    setWebEventFields(updatedWebEvents);
  };

  const handleWebEventsFieldsFormDialogOpen = () => setWebEventsFieldsFormDialogOpen(true);

  const handleWebEventFieldsSelection = (selectedWebEventField) => {
    setSelectedWebEventEventFields(selectedWebEventField);
    handleWebEventsFieldsFormDialogOpen();
  };

  const handleWebEventsFieldsFormDialogClose = () => {
    setWebEventsFieldsFormDialogOpen(false);
    setSelectedWebEventEventFields([]);
  };

  const updateWebEvents = (newData) => {
    setWebEventFields(prevData => updateOrAddDataBasedOnSourceId(prevData, newData));
  };

  const handleIsCaptchaInstalledRadioChange = (event) => {
    setIsCaptchaInstalledValue(event.target.value);
    if (event.target.value === 'No') {
      setValue('trackingForTheCaptchaPiece', '');
    }
  };

  /** Generic Logic * */
  const handleFormSubmit = (data: DetailsFormProps) => {
    if (isCaptchaInstalledValue === 'No') {
      data.trackingForTheCaptchaPiece = '';
    }
    if (webEventsSelectedValue === 'Use only my web events') {
      data.webEventFields = webEventFields;
    } else {
      data.webEventFields = [];
    }

    const pageFormData = {
      solutionStrategy: {
        ...product, ...data,
      },
    };
    onSubmit(pageFormData);
  };

  const handleIsPersonToGrantFbAdminAccessChange = (e) => {
    setIsPersonToGrantFbAdminAccess(e.target.value);
    if (isPersonToGrantFbAdminAccess === 'Yes') {
      setValue('facebookAdminAccessEmail', '');
    }
  };

  const handleIsEmailForSocialMockupApprovalChange = (e) => {
    setIsEmailForSocialMockupApproval(e.target.value);
    if (isEmailForSocialMockupApproval === 'Yes') {
      setValue('socialMockupApprovalEmail', '');
    }
  };

  useEffect(() => {
    if (prodXmoStepDetails.clientHaveLocalIqWebsite === 'Yes') {
      setValue('isCaptchaInstalled', '');
      setValue('trackingForTheCaptchaPiece', '');
      setValue('clientHaveAnotherMaidAccount', '');
      setValue('proxyTheFollowingAreas', '');
    }
  }, [prodXmoStepDetails.clientHaveLocalIqWebsite]);

  const renderWebsiteCaptchaFields = () => prodXmoStepDetails.clientHaveLocalIqWebsite === 'No'
          && (
          <GridContainer fullWidth>
            <GridItem sizes={[12]}>
              <Typography
                fontWeight={600}
                variant="subtitle1"
              >
                {t('pages.xmo.solutionsStrategy.proxy.isCaptchaInstalled')}
                <RadioGroup
                  id="isCaptchaInstalled"
                  name="isCaptchaInstalled"
                  defaultValue={isCaptchaInstalledValue}
                  onChange={handleIsCaptchaInstalledRadioChange}
                  row
                >
                  {[t('pages.xmo.solutionsStrategy.inputValues.yesInputValue'),
                    t('pages.xmo.solutionsStrategy.inputValues.noInputValue')].map(opt => (
                      <FormControlLabel
                        key={opt}
                        control={<Radio value={opt} />}
                        label={opt}
                        {...register('isCaptchaInstalled', {
                          validate: (value) => schemaValidate(value, 'isCaptchaInstalled', schema),
                        })}
                      />
                  ))}
                </RadioGroup>
              </Typography>
            </GridItem>

            {isCaptchaInstalledValue === t('pages.xmo.solutionsStrategy.inputValues.yesInputValue')
                && (
                <GridItem sizes={[12]}>
                  <Typography
                    fontWeight={600}
                    variant="subtitle1"
                  >
                    {t('pages.xmo.solutionsStrategy.proxy.trackingForTheCaptchaPiece')}
                    <RadioGroup
                      id="trackingForTheCaptchaPiece"
                      name="trackingForTheCaptchaPiece"
                      defaultValue={watch('trackingForTheCaptchaPiece') || ''}
                      row
                    >
                      {[t('pages.xmo.solutionsStrategy.inputValues.yesInputValue'),
                        t('pages.xmo.solutionsStrategy.inputValues.holdCampaignForCaptcha')].map(opt => (
                          <FormControlLabel
                            key={opt}
                            control={<Radio value={opt} />}
                            label={opt}
                            {...register('trackingForTheCaptchaPiece', {
                              validate: (value) => schemaValidate(value, 'trackingForTheCaptchaPiece', schema),
                            })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>
                )}

            <GridItem sizes={[12]}>
              <Typography
                fontWeight={600}
                variant="subtitle1"
              >
                {t('pages.xmo.solutionsStrategy.proxy.clientHaveAnotherMaidAccount')}
                {tooltip(<Trans
                  i18nKey="pages.xmo.solutionsStrategy.proxy.anotherMaidAccountTooltip"
                  values={{ url: mmrCodeReferenceUrl }}
                  components={{
                    a: <Link
                      style={{ textDecorationColor: IQThemeV2.palette.common.white }}
                      color="inherit"
                      underline="always"
                      href={mmrCodeReferenceUrl}
                      target="_blank" rel="noopener noreferrer"
                    />,
                  }}
                /> as unknown as string, <HelpOutlineOutlinedIcon />)}
                <RadioGroup
                  id="clientHaveAnotherMaidAccount"
                  name="clientHaveAnotherMaidAccount"
                  defaultValue={product?.clientHaveAnotherMaidAccount || ''}
                  row
                >
                  {[t('pages.xmo.solutionsStrategy.inputValues.useMmrScript'),
                    t('pages.xmo.solutionsStrategy.inputValues.noInputValue')].map(opt => (
                      <FormControlLabel
                        key={opt}
                        control={<Radio value={opt} />}
                        label={opt}
                        {...register('clientHaveAnotherMaidAccount', {
                          validate: (value) => schemaValidate(value, 'clientHaveAnotherMaidAccount', schema),
                        })}
                      />
                  ))}
                </RadioGroup>
              </Typography>
            </GridItem>
            <GridItem sizes={[12]}>
              <IQFormTextArea
                fontLabelWeight="600"
                id="proxyTheFollowingAreas"
                name="proxyTheFollowingAreas"
                fullWidth
                showError
                tooltipText={t('pages.xmo.solutionsStrategy.proxy.doNotProxyTooltip')}
                tooltipPlacement="top"
                tooltipPaddingBottom={16}
                labelText={t('pages.xmo.solutionsStrategy.proxy.proxyTheFollowingAreas')}
              />
            </GridItem>
          </GridContainer>
          );

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={onBack}
      header={t('pages.xmo.solutionsStrategy.proxy.proxyTitle')}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={() => {
              handleSubmit(handleFormSubmit);
            }}
          >
            <GridContainer fullWidth>
              <GridItem sizes={[12]}>
                <Typography
                  variant="h6"
                >
                  {t('pages.xmo.solutionsStrategy.general.proxyEmailTrackingTitle')}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQInputEmail
                  schema={schema}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  field="advertiserEmail"
                  name="advertiserEmail"
                  required
                  fullWidth
                  tooltipText={t('pages.xmo.solutionsStrategy.general.advertiserEmailTooltip')}
                  tooltipPaddingBottom={16}
                  tooltipPlacement="top"
                  adornmentIcon={<MailOutlineIcon />}
                  label={t('pages.xmo.solutionsStrategy.general.advertiserEmail')}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                  schema={schema}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  id="localAreaCodePreference1"
                  name="localAreaCodePreference1"
                  fullWidth
                  showError
                  labelText={t('pages.xmo.solutionsStrategy.proxy.localAreaCodePreference1')}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                  schema={schema}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  id="localAreaCodePreference2"
                  name="localAreaCodePreference2"
                  fullWidth
                  showError
                  labelText={t('pages.xmo.solutionsStrategy.proxy.localAreaCodePreference2')}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                  theme={IQTheme}
                  fontLabelWeight="600"
                  id="businessNameForCallVerification"
                  name="businessNameForCallVerification"
                  fullWidth
                  showError
                  schema={schema}
                  labelText={t('pages.xmo.solutionsStrategy.proxy.businessNameForCallVerification')}
                  tooltipText={t('pages.xmo.solutionsStrategy.proxy.businessNameForCallVerificationTooltip')}
                  tooltipPlacement="right"
                  tooltipPaddingBottom={14}
                />
              </GridItem>

              <GridItem sizes={[12]} marginTop={3}>
                <Typography
                  variant="h6"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.websiteTrackingTitle')}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                  id="businessWebsiteUrl"
                  name="businessWebsiteUrl"
                  labelText={t('pages.xmo.solutionsStrategy.proxy.businessWebsiteUrlLabel')}
                  fullWidth
                  theme={IQTheme}
                  fontLabelWeight="600"
                  schema={schema}
                  adornmentIcon={<InsertLinkIcon />}
                  customValidation={schema.yupValidations.businessWebsiteUrl}
                  showError
                />
              </GridItem>
              {renderWebsiteCaptchaFields()}

              <GridItem sizes={[12]} marginTop={3}>
                <Typography
                  variant="h6"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.cvtTitle')}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.webEventType')}
                  <RadioGroup
                    id="webEventType"
                    name="webEventType"
                    defaultValue={product?.webEventType || ''}
                    onChange={(e: any) => setWebEventsSelectedValue(e.target.value)}
                    row
                  >
                    {[t('pages.xmo.solutionsStrategy.inputValues.webEventsBestPractices'),
                      t('pages.xmo.solutionsStrategy.inputValues.useOnlyMyWebEvents')].map(opt => (
                        <FormControlLabel
                          key={opt}
                          control={<Radio value={opt} />}
                          label={opt}
                          {...register('webEventType', {
                            validate: (value) => schemaValidate(value, 'webEventType', schema),
                          })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              {webEventsSelectedValue === t('pages.xmo.solutionsStrategy.inputValues.useOnlyMyWebEvents') && (
              <GridItem sizes={[12]}>
                {Array.isArray(webEventFields) && webEventFields.map((webEventField) => (
                  <GenericCard
                    key={webEventField.sourceId}
                    labels={webEventFieldLabels}
                    data={webEventField}
                    handleDelete={() => onDeleteWebEventFields(webEventField)}
                    onEdit={() => handleWebEventFieldsSelection(webEventField)}
                    onSelect={() => {
                    }}
                  />
                ))}
                <WebEventFormDialog
                  open={webEventsFieldsFormDialogOpen}
                  onOpen={handleWebEventsFieldsFormDialogOpen}
                  onClose={handleWebEventsFieldsFormDialogClose}
                  updateWebEvents={updateWebEvents}
                  selectedWebEvents={selectedWebEventFields}
                />
              </GridItem>
              )}
              <GridItem sizes={[12]}>
                <IQFormInput
                  id="facebookWebsiteUrl"
                  name="facebookWebsiteUrl"
                  labelText={t('pages.xmo.solutionsStrategy.proxy.facebookUrlLabel')}
                  fullWidth
                  theme={IQTheme}
                  fontLabelWeight="600"
                  schema={schema}
                  adornmentIcon={<InsertLinkIcon />}
                  showError
                />
              </GridItem>
              <GridItem sizes={[12]} marginTop={3}>
                <Typography variant="h6">
                  {t('pages.xmo.solutionsStrategy.proxy.contactInfoTitle')}
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                  id="clientFullName"
                  name="clientFullName"
                  labelText={t('pages.xmo.solutionsStrategy.proxy.clientFullNameLabel')}
                  fullWidth
                  theme={IQTheme}
                  fontLabelWeight="600"
                  schema={schema}
                  showError
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <IQInputEmail
                  field="clientEmail"
                  name="clientEmail"
                  label={t('pages.xmo.solutionsStrategy.proxy.emailLabel')}
                  fullWidth
                  theme={IQTheme}
                  fontLabelWeight="600"
                  schema={schema}
                  adornmentIcon={<MailOutlineIcon />}
                  showError
                  required
                />
              </GridItem>

              <GridItem sizes={[12]}>
                <NePhoneInput
                  name="clientPhoneNumber"
                  defaultValue={product?.clientPhoneNumber || ''}
                  country="US"
                  hasExtension
                  phoneLabelOptions={{
                    tooltipText: '',
                    labelText: t('pages.xmo.solutionsStrategy.proxy.phoneLabel'),
                    hasError: true,
                    theme: IQThemeV2,
                    required: true,
                  }}
                  hasType={false}
                  countryCallingCodeEditable={false}
                  international
                  extensionOptions={{
                    name: 'extension',
                    defaultValue: product?.extension || '',
                  }}
                />
              </GridItem>
              <GridItem sizes={[12]}>
                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.bestEmailForSocialMockupApproval')}
                  <RadioGroup
                    id="isEmailForSocialMockupApproval"
                    name="isEmailForSocialMockupApproval"
                    defaultValue={watch('isEmailForSocialMockupApproval') || ''}
                    onChange={handleIsEmailForSocialMockupApprovalChange}
                    row
                  >
                    {[t('pages.xmo.solutionsStrategy.inputValues.yesInputValue'),
                      t('pages.xmo.solutionsStrategy.inputValues.noInputValue')].map(opt => (
                        <FormControlLabel
                          key={opt}
                          control={<Radio value={opt} />}
                          label={opt}
                          {...register('isEmailForSocialMockupApproval', {
                            validate: (value) => schemaValidate(
                              value,
                              'isEmailForSocialMockupApproval',
                              schema,
                            ),
                          })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              {watch('isEmailForSocialMockupApproval') === 'No' && (
              <GridItem sizes={[12]}>
                <IQInputEmail
                  schema={schema}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  field="socialMockupApprovalEmail"
                  name="socialMockupApprovalEmail"
                  required
                  fullWidth
                  adornmentIcon={<MailOutlineIcon />}
                  label={t('pages.xmo.solutionsStrategy.proxy.socialMockupApprovalEmailLabel')}
                />
              </GridItem>
              )}
              <GridItem sizes={[12]}>
                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {t('pages.xmo.solutionsStrategy.proxy.bestPersonToGrantFacebookAdminAccess')}
                  <RadioGroup
                    id="isPersonToGrantFbAdminAccess"
                    name="isPersonToGrantFbAdminAccess"
                    defaultValue={watch('isPersonToGrantFbAdminAccess') || ''}
                    onChange={handleIsPersonToGrantFbAdminAccessChange}
                    row
                  >
                    {[t('pages.xmo.solutionsStrategy.inputValues.yesInputValue'),
                      t('pages.xmo.solutionsStrategy.inputValues.noInputValue')].map(opt => (
                        <FormControlLabel
                          key={opt}
                          control={<Radio value={opt} />}
                          label={opt}
                          {...register('isPersonToGrantFbAdminAccess', {
                            validate: (value) => schemaValidate(
                              value,
                              'isPersonToGrantFbAdminAccess',
                              schema,
                            ),
                          })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              {watch('isPersonToGrantFbAdminAccess') === 'No' && (
              <GridItem sizes={[12]}>
                <IQInputEmail
                  schema={schema}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  field="facebookAdminAccessEmail"
                  name="facebookAdminAccessEmail"
                  required
                  fullWidth
                  adornmentIcon={<MailOutlineIcon />}
                  label={t('pages.xmo.solutionsStrategy.proxy.facebookAdminAccessEmailLabel')}
                />
              </GridItem>
              )}
            </GridContainer>
          </Box>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
