import * as yup from 'yup';

const schema = {
  orderTypes: yup.string().required(),
  landingPageUrl: yup.string(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
