import { IQTheme, ValidationProvider } from '@gannettdigital/shared-react-components';
import { Grid, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StepperRootProps } from 'layouts/StepperLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectCustomWebsite, getCustomWebsite, putCustomWebsiteCrmLoginInfo } from 'services/customWebsiteSlice';
import NeSelectChip from 'components/chips/NeSelectChip';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { useAppDispatch } from 'services/redux/store';
import { selectActiveProductId } from 'services/navigationSlice';
import ErrorText from 'components/errorText/ErrorText';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import schema from './LoginPortal.schema';

export default function CustomWebsiteLoginPortal(props: StepperRootProps) {
  const { t } = useTranslation();
  const { orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const customWebsite = useSelector(selectCustomWebsite);
  const userTypeValues = useMemo(() => ((
    customWebsite.loginPortalUserType !== null && customWebsite.loginPortalUserType?.length > 0)
    ? customWebsite.loginPortalUserType.split(',') : []), [customWebsite]);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      loginPortalUserType: [],
      loginPortalInfo: '',
    },
  });

  const {
    getValues, handleSubmit, setValue, register, trigger, formState: {
      isDirty, isSubmitting, isSubmitSuccessful, isValid, errors,
    },
  } = methods;

  useMemo(() => {
    setValue('loginPortalUserType', userTypeValues);
    setValue('loginPortalInfo', customWebsite.loginPortalInfo);
  }, [customWebsite, userTypeValues]);

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid]);

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsite(websiteId.toString()));
  }, [websiteId]);

  const onFormSubmit = async () => {
    const data = getValues();
    await appDispatch(dispatch(getCustomWebsite(websiteId)))
      .then(async (response) => {
        const content = {
          crmIntegrationName: response.payload.crmIntegrationName || '',
          crmIntegrationInfo: response.payload.crmIntegrationInfo || '',
          loginPortalUserType: data.loginPortalUserType.toString(),
          loginPortalInfo: data.loginPortalInfo,
        };
        dispatch(putCustomWebsiteCrmLoginInfo({ orderItemId, content }));
      });
    props.onContinue();
  };

  return (
    <>
      <Typography variant="h2" marginTop={2}>{t('pages.website.custom.loginPortal.pageTitle')}</Typography>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container marginTop={5}>
              <Grid item xs={6} marginBottom={3}
                sx={{
                  '.MuiFormControl-root.MuiTextField-root': {
                    '.MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#292928',
                    },
                  },
                }}
              >
                <NeSelectChip
                  freeSolo
                  label
                  labelText={t('pages.website.custom.loginPortal.loginUserType')}
                  labelTooltipText={t('pages.website.custom.loginPortal.loginUserTypeTooltip')}
                  items={[]}
                  values={userTypeValues}
                  maxItems={0}
                  required
                  name="loginPortalUserType"
                  placeholder={t('pages.landing.audience.chipPlaceholder')}
                  disableClear
                  schema={schema}
                  theme={IQTheme}
                  onClose={() => trigger('loginPortalUserType')}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <NeFormTextArea
                  {...register('loginPortalInfo')}
                  id="loginPortalInfo"
                  name="loginPortalInfo"
                  labelText={t('pages.website.custom.loginPortal.features')}
                  tooltipText={t('pages.website.custom.loginPortal.featuresTooltip')}
                  tooltipPaddingBottom={18}
                  fullWidth
                  rowCount={3}
                  fontLabelWeight="600"
                />
                <ErrorText
                  hasError={!!errors.loginPortalInfo}
                  errorText={t('pages.website.custom.loginPortal.featuresRequired')}
                />
              </Grid>
            </Grid>
          </form>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </>
  );
}
