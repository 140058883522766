import { IQFormInput, ValidationProvider } from '@gannettdigital/shared-react-components';
import {
  Button, Grid, Typography,
  useTheme,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { updateCampaignId } from 'services/adminSlice';
import { useAppDispatch } from 'services/redux/store';
import schema from './Cid.schema';

export default function ManageCids() {
  const theme = useTheme();
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const [invalidOrderItem, setInvalidOrderItem] = useState(false);

  const methods = useForm({
    mode: 'all',
    defaultValues: { itemId: '', campaignId: '' },
  });

  const {
    formState: {
      isValid,
    },
    getValues,
    setError,
  } = methods;

  const updateCid = () => {
    const data = getValues();
    appDispatch(updateCampaignId({
      itemCampaigns: [{ itemId: data?.itemId, campaignId: data?.campaignId }],
    })).then((response) => {
      const orderId = response.payload?.[0]?.orderId;
      if (orderId) {
        navigate(`/order/${orderId}/product/${data.itemId}`);
      } else {
        setInvalidOrderItem(true);
      }
    });
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (invalidOrderItem) {
      return setError('itemId', { message: 'Invalid OID.' });
    }
  }, [invalidOrderItem]);

  const clearInvalidOrderItemStatus = () => { setInvalidOrderItem(false); };

  return (
    <>
      <Typography variant="h2" marginLeft={28} marginTop={12}>Edit CID</Typography>
      <Grid container marginLeft={20}>
        <ValidationProvider schema={schema}>
          <Grid item xs={12}>
            <FormProvider {...methods}>
              <Box marginTop={10} marginLeft={20}>
                <IQFormInput
                  fontLabelWeight="bold"
                  labelText="OID"
                  id="itemId"
                  theme={theme}
                  name="itemId"
                  schema={schema}
                  onKeyDown={clearInvalidOrderItemStatus}
                  maxLength={18}
                />
              </Box>
              <Box marginTop={2} marginLeft={20}>
                <IQFormInput
                  fontLabelWeight="bold"
                  labelText="CID"
                  id="campaignId"
                  theme={theme}
                  name="campaignId"
                  schema={schema}
                  maxLength={18}
                />
              </Box>
            </FormProvider>
          </Grid>
          <Grid item xs={12}
            marginLeft={20}
            marginTop={10}
          >
            <Button variant="contained"
              sx={{ marginRight: 3 }}
              disabled={!isValid}
              onClick={updateCid}
            >
              <Typography>Save</Typography>
            </Button>
            <br />
            <br />
            <Link to="/admin/dashboard">
              <Button variant="contained">
                <Typography>Back to Dashboard</Typography>
              </Button>
            </Link>
          </Grid>
        </ValidationProvider>
      </Grid>
    </>
  );
}
