import { Urls } from 'navigation/Urls';

import useProduct from 'hooks/useProduct';

import { StatusType } from 'models/StatusType';
import { GetLandingPageResponse, LandingPageRequestContentType } from 'models/LandingPageType';

import ContentLoader from 'components/contentLoader/ContentLoader';
import TrackingForm from './TrackingForm';

export default function Tracking() {
  const { onBack, onSubmit, product } = useProduct<LandingPageRequestContentType,
  StatusType<GetLandingPageResponse>>(Urls.Tracking);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <TrackingForm
        onSubmit={onSubmit}
        onBack={onBack}
        product={product.content.data}
      />
    </ContentLoader>
  );
}
