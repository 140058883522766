import React, { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IQLabelTooltip, IQThemeV2 } from '@gannettdigital/shared-react-components';
import { Box, Select, Typography } from '@mui/material';

interface Option {
  label: string;
  value: string;
}

export interface Props {
  label?: string;
  name: string;
  labelTooltipText?: string;
  required?: boolean;
  defaultValue?: string;
  children: ReactNode;
  values?: Option[];
}

export default function NeDropdown({
  label,
  name,
  labelTooltipText,
  required,
  defaultValue,
  children,
  values,
}: Props) {
  const {
    control,
    formState: {
      errors,
    },
  } = useFormContext();

  const hasError = !!errors[name];

  const getSelectedLabel = (selected) => {
    if (values) {
      const filterValue = values.filter(item => item.value === selected);
      return filterValue[0].label;
    }

    return selected;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .MuiSelect-select': {
          backgroundColor: 'common.white',
          borderColor: 'text.primary',
        },
      }}
    >
      {label && (
        <IQLabelTooltip
          labelText={label}
          hasError={hasError}
          theme={IQThemeV2}
          tooltipText={labelTooltipText}
          required={required}
          paddingBottom={16}
        />
      )}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <Select defaultValue={defaultValue} variant="outlined"
            SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
            MenuProps={{
              style: { maxHeight: 230 },
              PaperProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    display: 'flex !important',
                    justifyContent: 'flex-start !important',
                    padding: '6px 16px',
                  },
                },
              },
            }}
            onChange={onChange}
            renderValue={(selected) => (
              <Typography>{getSelectedLabel(selected)}</Typography>
            )}
          >
            {children}
          </Select>
        )}
      />
    </Box>
  );
}
