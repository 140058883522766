import { FormProvider, useForm } from 'react-hook-form';

import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import Box from '@mui/material/Box';

import i18n from 'i18n/i18n';
import {
  Grid, Typography,
} from '@mui/material';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import PiQWebsiteType from 'models/PiQWebsiteType';
import { inputStyles } from 'pages/seo/details/DetailsPageForm';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import schema from './PiQAdditionalInfo.schema';

const { t } = i18n;

interface Props {
  product: PiQWebsiteType;
  onSubmit: (data: PiQAdditionalInfoFormProps) => void;
  onBack: () => void;
}

export type PiQAdditionalInfoFormProps = {
  additionalInfo: string;
};

export default function PiQAdditionalInfoForm({ product, onSubmit, onBack }: Props) {
  const methods = useForm<PiQAdditionalInfoFormProps>({
    mode: 'onChange',
    defaultValues: {
      additionalInfo: product?.additionalInfo || '',
    },
  });

  const handleFormSubmit = (data: PiQAdditionalInfoFormProps) => onSubmit(data);

  const {
    handleSubmit,
    register,
    watch,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
      errors,
    },
  } = methods;

  const skipAndContinue = watch('additionalInfo') === '';

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={onBack}
      header={t('pages.piq.additionalInfo.header')}
      description={t('pages.piq.additionalInfo.description')}
      onContinue={handleSubmit(handleFormSubmit)}
      skipAndContinue={skipAndContinue}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Grid container xs={12} sx={{ ...inputStyles }}>
              <Grid item mb={3} xs={9}>
                <Grid item mb={1}>
                  <Typography
                    variant="subtitle2"
                    component="h3"
                    fontSize={16}
                    marginTop={3}
                    color={errors.additionalInfo ? 'error.main' : 'common.black'}
                  >
                    Additional Notes
                  </Typography>
                </Grid>
                <NeFormTextArea
                  {...register('additionalInfo')}
                  id="additionalInfo"
                  name="additionalInfo"
                  fullWidth
                  rowCount={2}
                  fontLabelWeight="600"
                />
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
