import { t } from 'i18next';
import * as yup from 'yup';

const yupSchema = {
  onlineAppointmentSchedulerInfo: yup.string().required(t('pages.website.custom.scheduling.schedulingFieldsRequired')),
  additionalInformation: yup.string().optional().nullable(),
};

export default {
  yupValidations: yupSchema,
  customValidations: {},
};
