import AxiosAPI from '../common/axios';

interface ContentTonesEndpointsFunctionsType {
  getContentTones: (website: string) => any
  putContentTones: (websiteId: string, content: any) => any
}

interface ContentTonesEndpointsType {
  Website: ContentTonesEndpointsFunctionsType,
  GoldSite: ContentTonesEndpointsFunctionsType,
}

const ContentTonesEndpoints: ContentTonesEndpointsType = ({
  Website: {
    getContentTones: async (websiteId: string) => {
      const response = await AxiosAPI.get(`/websites/${websiteId}/content-tones`);
      return response.data.content;
    },
    putContentTones: async (websiteId: string, content: any) => {
      const response = await AxiosAPI.put(`/websites/${websiteId}/content-tones`, content);
      return response.data;
    },
  },
  GoldSite: {
    getContentTones: async (websiteId: string) => {
      const response = await AxiosAPI.get(`/gold-sites/${websiteId}/content-tones`);
      return response.data.content;
    },
    putContentTones: async (websiteId: string, content: any) => {
      const response = await AxiosAPI.put(`/gold-sites/${websiteId}/content-tones`, content);
      return response.data;
    },
  },
});

export default ContentTonesEndpoints;
