import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import {
  Autocomplete, TextField, Box, Checkbox, useTheme, Divider, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { IQLabelTooltip } from '@gannettdigital/shared-react-components';

const CheckBoxBlankIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const CheckBoxCheckedIcon = <CheckBoxIcon fontSize="small" />;

export interface Props {
  label: boolean;
  labelText?: string;
  labelTooltipText?: string;
  required?: boolean;
  labelIcon?: SVGElement;
  items: Attribute[];
  name: string;
  values: Attribute[];
  placeholder?: string;
  disableClear?: boolean;
  onAttributeAdded?: (attribute) => void
}

export enum AttributeType {
  URL = 'URL',
  BOOL = 'BOOL',
  ENUM = 'ENUM',
  REPEATED_ENUM = 'REPEATED_ENUM',
}

export type Attribute = {
  displayName: string,
  id: string,
  valueType: AttributeType,
  category: string,
  value?: any,
  valueList?: any[],
};

const AttributeSelector = ({
  values,
  items,
  label,
  name,
  labelIcon,
  labelText,
  labelTooltipText,
  required,
  placeholder,
  onAttributeAdded,
}: Props) => {
  const { control } = useFormContext();
  const theme = useTheme();

  const onChangeValues = (
    value,
    field: any,
  ) => {
    const oldValue = field.value;
    const newItem = value.find(item => !oldValue.find(old => old.id === item.id));
    if (Array.isArray(value)) {
      field.onChange(value);
    }

    if (onAttributeAdded) {
      onAttributeAdded(newItem);
    }
  };

  const filterItems = (value: string) => {
    const filteredValues = [];
    items.forEach((item) => {
      if (item.displayName.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
        filteredValues.push(item);
      }
    });
    return filteredValues;
  };

  const customClasses = makeStyles({
    option: {
      '&[aria-selected="true"]': {
        backgroundColor: 'white !important',
      },
    },
    inputRoot: {
      padding: '5px 15px !important',
      border: `1px solid ${theme.palette.text.primary}`,
    },
  })();

  return (
    <>
      {label && (
        <IQLabelTooltip
          labelText={labelText}
          hasError={false}
          theme={theme}
          tooltipText={labelTooltipText}
          tooltipPlacement="top"
          required={required}
          Icon={labelIcon}
          paddingBottom={18}
        />
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Box sx={{ position: 'relative' }}>
            <Autocomplete
              sx={{
                width: '100%', height: '100%', left: 0, top: 0,
              }}
              classes={{
                option: customClasses.option,
                inputRoot: customClasses.inputRoot,
              }}
              fullWidth
              multiple
              disableClearable
              options={items?.slice().sort((a, b) => a.category?.toString().localeCompare(b.category?.toString()))}
              groupBy={(option) => option.category}
              value={values}
              disableCloseOnSelect
              onChange={(e, v) => onChangeValues(v, field)}
              filterOptions={(options, params) => filterItems(params.inputValue)}
              getOptionLabel={(option) => (option as Attribute).displayName}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id} style={{ backgroundColor: 'white !important' }}>
                  <Checkbox
                    icon={CheckBoxBlankIcon}
                    checkedIcon={CheckBoxCheckedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.displayName}
                </li>
              )}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField {...params} placeholder={placeholder} variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (<SearchIcon />),
                    disableUnderline: true,
                  }}
                />
              )}
              renderGroup={({ group, children, key }) => (
                <li key={key}>
                  <Typography
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="12px"
                    color="#292928"
                    sx={{ marginTop: '13px', marginLeft: '17px' }}
                  >
                    {group}
                  </Typography>
                  {children}
                  <Divider sx={{ marginLeft: '15px', marginRight: '15px', marginBottom: '19px' }} />
                </li>
              )}
            />
          </Box>
        )}
      />
    </>
  );
};

export default AttributeSelector;
