import { CSSProperties, HTMLAttributeReferrerPolicy } from 'react';
import { styled } from '@mui/material';

type ParagraphProps = {
  padding?: string;
  margin?: string;
  position?: string;
  zIndex?: number;
  scale?: number;
  borderRadius?: string;
  background?: string;
  maxWidth?: string;
  maxHeight?: string;
  spanScale?: string;
  spanHeight?: string;
  spanWidth?: string;
  spanDisplay?: string;
  spanLetterSpacing?: string;
  spanMargin?: string;
  spanFlexDirection?: string;
  top?: string;
};

const StyledParagraph = styled('p')<ParagraphProps>`
  padding: ${props => props.padding || 0};
  margin: ${props => props.margin || 0};
  position: ${props => props.position || 'relative'};
  z-index: ${props => props.zIndex || '10'};
  scale: ${props => props.scale || '1.1'};
  border-radius: ${props => props.borderRadius || '4px'};
  background: ${(props) => props.theme.palette.text.primary};
  max-width: ${props => props.maxWidth || '208px'};
  max-height: ${props => props.maxHeight || '98px'};
  top: ${props => props.top || 0};

  span {
    height: ${props => props.spanHeight || '100%'};
    width: ${props => props.spanWidth || '100%'};
    scale: ${props => props.spanScale || '0.9'};
    display: ${props => props.spanDisplay || 'block'};
    letter-spacing: ${props => props.spanLetterSpacing || '0.4px'};
    margin: ${props => props.spanMargin || '-2px'};
  }

  & > .tooltip-link-new-line {
    display: flex;
    flex-direction: column;
  }
`;

type LinkOptions =
  | {
    render: false;
    linkText?: never;
    href?: never;
    target?: never;
    referrerPolicy?: never;
    style?: never;
    rel?: never;
  }
  | {
    render: true;
    linkText: string;
    href: string;
    target: string;
    referrerPolicy: HTMLAttributeReferrerPolicy;
    style: Record<string, string | number>;
    rel: string;
    newLine?: boolean;
  };

export const generateTooltipTextElement = (
  text: string,
  linkOptions: LinkOptions,
  styles?: Omit<ParagraphProps, 'theme'>,
) => {
  const { render } = linkOptions;

  if (render) {
    const {
      render, linkText, newLine, ...options
    } = linkOptions;

    const className = newLine ? 'tooltip-link-new-line' : '';

    return (
      <StyledParagraph {...styles}>
        <span className={className}>
          {text}
          {render && (
          <a
            tabIndex={0}
            role="link"
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            {...options}
          >
            {linkText}
          </a>
          )}
        </span>
      </StyledParagraph>
    );
  }

  return (
    <StyledParagraph {...styles}>
      <span>{text}</span>
    </StyledParagraph>
  );
};

export const generateTooltipOrderedListElement = (
  text: string,
  listHeading: string,
  listItems: string[],
  containerStyles?: ParagraphProps,
  listStyles?: CSSProperties,
) => {
  const listItemElements = listItems.map((element, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <li key={index}>
      {element}
    </li>
  ));

  return (
    <StyledParagraph {...containerStyles}>
      {text}
      <br />
      <br />
      {listHeading}
      <ul style={listStyles}>
        {listItemElements}
      </ul>
    </StyledParagraph>
  );
};
