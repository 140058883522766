/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import FileUploader from 'pages/generic-product/details/custom-fields/FileUploader';
import { ProductTypes } from 'services/fileUploaderSlice';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';

const { t } = i18n;

const urlValidationParameter = [
  'https://www.linkedin.com',
  'https://www.linked.in',
  'https://linkedin.com',
  'https://linked.in',
];
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CustomSolutionLinkedinSchema: RJSFSchema | any = (data) => ({
  title: t('pages.custom.solutions.linkedin.pageTitle'),
  // eslint-disable-next-line max-len
  type: 'object',
  required: [
    'proposalForm',
    'landingPageUrl',
    'keyDifferentiators',
    'clientFocus',
    'clientProvidingCreative',
  ],
  properties: {
    proposalForm: {
      type: 'string',
      title: t('pages.custom.solutions.common.proposalFormLabel'),
      default: '',
      items: {
        enum: [
          t('form.big-box-labels.yes'),
          t('pages.custom.solutions.common.noOption'),
        ],
      },
    },
    landingPageUrl: {
      type: 'string',
      title: t('pages.custom.solutions.common.landingPageURLLabel'),
      default: '',
    },
    keyDifferentiators: {
      type: 'string',
      title: t('pages.custom.solutions.common.keyDifferentiatorsRadioLabel'),
      default: '',
      items: {
        enum: [
          t('form.big-box-labels.yes'),
          t('pages.custom.solutions.common.noOption'),
        ],
      },
    },
    clientFocus: {
      type: 'string',
      title: t('pages.custom.solutions.common.clientFocusLabel'),
      default: '',
      enum: [
        t('form.big-box-labels.yes'),
        t('pages.custom.solutions.common.noOption'),
      ],
    },
    clientHaveALinkedinCompanyPage: {
      type: 'string',
      title: t('pages.custom.solutions.linkedin.clientHaveALinkedinCompanyPageLabel'),
      default: '',
      items: {
        enum: [
          t('form.big-box-labels.yes'),
          t('pages.custom.solutions.common.noOption'),
        ],
      },
    },
    campaignObjective: {
      type: 'string',
      title: t('pages.custom.solutions.linkedin.campaignObjectiveLabel'),
      enum: [
        t('pages.custom.solutions.linkedin.campaignObjectiveLabelOptions.brandAwareness'),
        t('pages.custom.solutions.linkedin.campaignObjectiveLabelOptions.websiteVisits'),
        t('pages.custom.solutions.linkedin.campaignObjectiveLabelOptions.engagement'),
        t('pages.custom.solutions.linkedin.campaignObjectiveLabelOptions.videoViews'),
        t('pages.custom.solutions.linkedin.campaignObjectiveLabelOptions.leadGeneration'),
        t('pages.custom.solutions.linkedin.campaignObjectiveLabelOptions.websiteConversions'),
        t('pages.custom.solutions.linkedin.campaignObjectiveLabelOptions.jobApplicants'),
      ],
    },
    geoTargetingType: {
      type: 'string',
      title: t('pages.custom.solutions.common.geoTargetingTypeLabel'),
      enum: [
        t('pages.custom.solutions.common.countiesOption'),
        t('pages.custom.solutions.common.metrosOption'),
        t('pages.custom.solutions.common.statesOption'),
        t('pages.custom.solutions.common.countryOption'),
      ],
    },
    geoTargetingDetails: {
      type: 'string',
      title: t('pages.custom.solutions.common.geoTargetingDetailsLabel'),
      default: '',
    },
    ageRange: {
      type: 'array',
      title: t('pages.custom.solutions.common.ageRangeLabel'),
      items: {
        type: 'string',
        enum: [
          t('pages.custom.solutions.common.ageRangeOptions.18to24'),
          t('pages.custom.solutions.common.ageRangeOptions.25to34'),
          t('pages.custom.solutions.common.ageRangeOptions.35to54'),
          t('pages.custom.solutions.common.ageRangeOptions.55plus'),
        ],
      },
      uniqueItems: true,
    },
    gender: {
      type: 'array',
      title: t('pages.custom.solutions.common.genderLabel'),
      items: {
        type: 'string',
        enum: [
          t('pages.custom.solutions.common.maleLabel'),
          t('pages.custom.solutions.common.femaleLabel'),
        ],
      },
      uniqueItems: true,
    },
    visualStrategy: {
      type: 'string',
      title: t('pages.custom.solutions.linkedin.visualStrategyLabel'),
      enum: [
        t('pages.custom.solutions.linkedin.visualStrategyOptions.singleImage'),
        t('pages.custom.solutions.linkedin.visualStrategyOptions.singleVideo'),
      ],
    },
    clientProvidingTextCreative: {
      type: 'string',
      title: t('pages.custom.solutions.linkedin.clientProvidingTextCreativeLabel'),
      default: '',
      items: {
        enum: [
          t('form.big-box-labels.yes'),
          t('pages.custom.solutions.common.noOption'),
        ],
      },
    },
    productOrServiceOrOfferToBePromoted: {
      type: 'string',
      title: t(
        'pages.custom.solutions.linkedin.productOrServiceOrOfferToBePromotedLabel',
      ),
      default: '',
    },
    brandGuidelines: {
      type: 'string',
      title: t('pages.custom.solutions.linkedin.brandGuidelinesLabel'),
      default: '',
      items: {
        enum: [
          t('form.big-box-labels.yes'),
          t('pages.custom.solutions.common.noOption'),
        ],
      },
      description: t('pages.custom.solutions.linkedin.brandGuidelinesHelperLabel'),
    },
    detailedTargetingHeader: {
      type: 'string',
      title: t('pages.custom.solutions.linkedin.detailedTargetingHeaderLabel'),
      description: t('pages.custom.solutions.linkedin.detailedTargetingHeaderHelperLabel'),
    },
    targetingPreferences: {
      type: 'string',
      title: t('pages.custom.solutions.linkedin.targetingPreferencesLabel'),
      enum: [
        t('pages.custom.solutions.linkedin.targetingPreferencesOptions.companyIndustries'),
        t('pages.custom.solutions.linkedin.targetingPreferencesOptions.companySize'),
        t('pages.custom.solutions.linkedin.targetingPreferencesOptions.degrees'),
        t('pages.custom.solutions.linkedin.targetingPreferencesOptions.fieldsOfStudy'),
        t('pages.custom.solutions.linkedin.targetingPreferencesOptions.memberSchools'),
        t('pages.custom.solutions.linkedin.targetingPreferencesOptions.jobFunctions'),
        t('pages.custom.solutions.linkedin.targetingPreferencesOptions.jobSeniorities'),
        t('pages.custom.solutions.linkedin.targetingPreferencesOptions.jobTitles'),
        t('pages.custom.solutions.linkedin.targetingPreferencesOptions.memberSkills'),
        t('pages.custom.solutions.linkedin.targetingPreferencesOptions.yoe'),
        t('pages.custom.solutions.linkedin.targetingPreferencesOptions.memberGroups'),
        t('pages.custom.solutions.linkedin.targetingPreferencesOptions.memberInterests'),
        t('pages.custom.solutions.linkedin.targetingPreferencesOptions.memberTraits'),
      ],
    },
    clientProvidingCreative: {
      type: 'string',
      title: t('pages.custom.solutions.common.clientProvidingCreativeRadioLabel'),
      default: '',
      description: t('pages.custom.solutions.common.clientProvidingCreativeRadioHelperLabel'),
      items: {
        enum: [
          t('pages.custom.solutions.common.noOption'),
          t('pages.custom.solutions.common.yesAllLabel'),
          t('pages.custom.solutions.common.yesSomeLabel'),
        ],
      },
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.custom.solutions.common.notesInstructionsLabel'),
      default: '',
    },
  },
  dependencies: {
    keyDifferentiators: {
      oneOf: [
        {
          properties: {
            keyDifferentiators: {
              enum: ['Yes'],
            },
            keyDifferentiatorsHighlight: {
              type: 'string',
              title: t('pages.custom.solutions.common.keyDifferentiatorsLabel'),
              default: '',
            },
          },
        },
        {
          properties: {
            keyDifferentiators: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    clientFocus: {
      oneOf: [
        {
          properties: {
            clientFocus: {
              enum: ['Yes'],
            },
            detailsOfEvent: {
              type: 'string',
              title: t('pages.custom.solutions.common.detailsOfEventLabel'),
              default: '',
            },
            startDate: {
              type: 'string',
              title: t('pages.custom.solutions.common.startDateLabel'),
              default: '',
            },
            endDate: {
              type: 'string',
              title: t('pages.custom.solutions.common.endDateLabel'),
              format: 'date',
            },
          },
        },
        {
          properties: {
            clientFocus: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    clientHaveALinkedinCompanyPage: {
      oneOf: [
        {
          properties: {
            clientHaveALinkedinCompanyPage: {
              enum: ['Yes'],
            },
            linkedinUrl: {
              type: 'string',
              title: t('pages.custom.solutions.linkedin.linkedinUrlLabel'),
              default: '',
              customUrlValidation: true,
              urlValidationParameter,
              urlValidationErrorText: t('pages.custom.solutions.linkedin.linkedinInvalidUrl'),
            },
          },
          required: ['linkedinUrl'],
        },
        {
          properties: {
            clientHaveALinkedinCompanyPage: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    clientProvidingCreative: {
      oneOf: [
        {
          properties: {
            clientProvidingCreative: {
              enum: ['No'],
            },
          },
        },
        {
          properties: {
            clientProvidingCreative: {
              enum: ['Yes - All'],
            },
            uploader_allCreativeFile: {
              type: 'string',
              title: '',
              default: '',
            },
          },
          required: ['uploader_allCreativeFile'],
        },
        {
          properties: {
            clientProvidingCreative: {
              enum: ['Yes - Some'],
            },
            uploader_someCreativeFile: {
              type: 'string',
              title: '',
              default: '',
            },
          },
          required: ['uploader_someCreativeFile'],
        },
      ],
    },
    clientProvidingTextCreative: {
      oneOf: [
        {
          properties: {
            clientProvidingTextCreative: {
              enum: ['No'],
            },
          },
        },
        {
          properties: {
            clientProvidingTextCreative: {
              enum: ['Yes'],
            },
            mainText: {
              type: 'string',
              title: t('pages.custom.solutions.linkedin.mainTextLabel'),
              default: '',
            },
            headline: {
              type: 'string',
              title: t('pages.custom.solutions.linkedin.headlineLabel'),
              default: '',
            },
            description: {
              type: 'string',
              title: t('pages.custom.solutions.linkedin.descriptionLabel'),
              default: '',
            },
          },
          required: ['mainText', 'headline', 'description'],
        },
      ],
    },
  },
});

const textFields = {
  geoTargetingDetails: {},
  mainText: {
    'ui:placeholder': t('pages.custom.solutions.linkedin.mainTextHelperLabel'),
  },
  headline: {
    'ui:placeholder': t('pages.custom.solutions.linkedin.headlineHelperLabel'),
  },
  description: {
    'ui:placeholder': t('pages.custom.solutions.linkedin.descriptionHelperLabel'),
  },
  detailsOfEvent: {
    'ui:placeholder': t('pages.custom.solutions.common.detailsOfEventLabel'),
  },
};
Object.keys(textFields).forEach((key) => {
  textFields[key]['ui:widget'] = 'textInputField';
});

const textareaFields = {
  keyDifferentiatorsHighlight: {},
  notesOrInstructions: {},
  productOrServiceOrOfferToBePromoted: {
    'ui:placeholder': t('pages.custom.solutions.linkedin.productOrServiceOrOfferToBePromotedHelperLabel'),
  },
};
Object.keys(textareaFields).forEach((key) => {
  textareaFields[key]['ui:widget'] = 'TextareaWidget';
});

const radioFields = {
  proposalForm: {},
  keyDifferentiators: {},
  clientHaveALinkedinCompanyPage: {},
  clientProvidingTextCreative: {},
  brandGuidelines: {},
  clientProvidingCreative: {},
};
Object.keys(radioFields).forEach((key) => {
  radioFields[key]['ui:widget'] = 'radioButton';
});

const checkBoxesFieldKeys = ['ageRange', 'gender'];

const checkBoxesFields = checkBoxesFieldKeys.reduce(
  (o, key) => ({
    ...o,
    [key]: { 'ui:widget': 'checkBoxField' },
  }),
  {},
);

const dropDownFields = {
  clientFocus: {
    'ui:placeholder': t('pages.custom.solutions.common.flightedCampaignHelperLabel'),
  },
  geoTargetingType: {},
  campaignObjective: {},
  visualStrategy: {
    'ui:placeholder': t('pages.custom.solutions.linkedin.visualStrategyHelperLabel'),
  },
  targetingPreferences: {},
};
Object.keys(dropDownFields).forEach((key) => {
  dropDownFields[key]['ui:widget'] = 'dropdown';
});

const urlTextFields = {
  landingPageUrl: {
    'ui:placeholder': t('pages.custom.solutions.common.landingPageURLHelperLabel'),
  },
  linkedinUrl: {},
};
Object.keys(urlTextFields).forEach((key) => {
  urlTextFields[key]['ui:widget'] = 'UrlTextWidget';
});

const datePickerField = {
  startDate: {},
  endDate: {},
};
Object.keys(datePickerField).forEach((key) => {
  datePickerField[key]['ui:widget'] = 'datePickerField';
});

const fileUploaderFields = {
  uploader_allCreativeFile: {
    uploaderType: ProductTypes.CUSTOM_SOLUTIONS_LINKEDIN_UPLOADER_1,
    totalAllowedFiles: 1,
    label: 'Upload Client Creative',
    documentTypes: ['.pdf'],
    videoTypes: ['.mp4'],
    imageTypes: ['.png', '.jpg', '.jpeg', '.svg'],
  },
  uploader_someCreativeFile: {
    uploaderType: ProductTypes.CUSTOM_SOLUTIONS_LINKEDIN_UPLOADER_2,
    totalAllowedFiles: 1,
    label: 'Upload Client Creative',
    documentTypes: ['.pdf'],
    videoTypes: ['.mp4'],
    imageTypes: ['.png', '.jpg', '.jpeg', '.svg'],
  },
};
Object.keys(fileUploaderFields).forEach((key) => {
  const {
    uploaderType,
    totalAllowedFiles,
    supportingText,
    label,
    documentTypes,
    videoTypes,
    imageTypes,
  } = fileUploaderFields[key];

  fileUploaderFields[key]['ui:style'] = {
    paddingTop: '2%',
  };

  // eslint-disable-next-line func-names
  fileUploaderFields[key]['ui:widget'] = function (props) {
    return (
      <FileUploader
        {...props}
        label={label}
        videoTypes={videoTypes}
        imageTypes={imageTypes}
        documentTypes={documentTypes}
        uploaderType={uploaderType}
        totalAllowedFiles={totalAllowedFiles}
        supportingText={supportingText ?? ''}
      />
    );
  };
});

export const CustomSolutionLinkedinUISchema = {
  'ui:order': [
    'proposalForm',
    'landingPageUrl',
    'keyDifferentiators',
    'keyDifferentiatorsHighlight',
    'clientFocus',
    'detailsOfEvent',
    'startDate',
    'endDate',
    'clientHaveALinkedinCompanyPage',
    'linkedinUrl',
    'campaignObjective',
    'geoTargetingType',
    'geoTargetingDetails',
    'ageRange',
    'gender',
    'visualStrategy',
    'clientProvidingTextCreative',
    'mainText',
    'headline',
    'description',
    'productOrServiceOrOfferToBePromoted',
    'brandGuidelines',
    'detailedTargetingHeader',
    'targetingPreferences',
    'clientProvidingCreative',
    'uploader_someCreativeFile',
    'uploader_allCreativeFile',
    'notesOrInstructions',
  ],

  detailedTargetingHeader: {
    'ui:widget': 'headingTextLabel',
  },

  ...textFields,
  ...textareaFields,
  ...dropDownFields,
  ...radioFields,
  ...urlTextFields,
  ...checkBoxesFields,
  ...datePickerField,
  ...fileUploaderFields,
};

export const CustomSolutionLinkedinValidate = (
  formData: any,
  errors: any,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  uiSchema: any,
) => {
  if (formData?.proposalForm === '') {
    errors.proposalForm.addError(
      t('pages.custom.solutions.common.proposalFormError'),
    );
    errors.addError(true);
  }
  if (formData?.landingPageUrl === '' || !validateUrl(formData?.landingPageUrl)) {
    errors.landingPageUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.keyDifferentiators === '') {
    errors.keyDifferentiators.addError(
      t('pages.custom.solutions.common.keyDifferentiatorsRadioError'),
    );
    errors.addError(true);
  }
  if (formData?.clientProvidingCreative === '') {
    errors.clientProvidingCreative.addError(
      t('pages.custom.solutions.common.clientProvidingCreativeError'),
    );
    errors.addError(true);
  }
  if (formData?.clientHaveALinkedinCompanyPage === 'Yes') {
    if (formData.linkedinUrl === '' || !urlValidationParameter.some(url => formData?.linkedinUrl?.startsWith(url))) {
      errors.linkedinUrl
        .addError('Please enter a valid url that begins with https://linkedin.com or https://linked.in.');
      errors.addError(true);
    }
  }
  if (new Date(formData?.endDate) < new Date(formData?.startDate)) {
    errors.endDate.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.endDateErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
