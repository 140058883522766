import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MarketCategory, createMarketCategory } from 'services/adminSlice';
import CategoryForm from './CategoryForm';

export default function NewCategory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const saveMarketCategory = async (category) => {
    await dispatch(await createMarketCategory({
      name: category.name,
      notes: category.notes,
      active: category.active,
    }));
    navigate('/admin/marketCategories');
  };

  const emptyCategory: MarketCategory = {
    id: 0, name: '', notes: '', themeCount: 0, active: false,
  };

  return (
    <>
      <Typography variant="h2">New Category</Typography>
      <CategoryForm onSubmit={saveMarketCategory} category={emptyCategory} />
    </>
  );
}
