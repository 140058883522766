import * as yup from 'yup';

const schema = {
  standardLogo: yup.array().notRequired(),
  applyStandardLogoToAll: yup.boolean().notRequired().nullable(),
  squareLogo: yup.array().notRequired(),
  applySquareLogoToAll: yup.boolean().notRequired().nullable(),
  applyVideosToAll: yup.boolean().notRequired().nullable(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
