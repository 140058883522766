import i18n from 'i18n/i18n';
import * as yup from 'yup';

const { t } = i18n;
export const GMAID_REGEX = /(USA|CAN|AUS|NZL)_\d{3,}/i;

const schema = {
  businessName: yup.string().required(t('pages.business.details.businessNameRequired')),
  gmaid: yup.string(),
  // gmaid: yup.string().required(t('pages.business.details.gmaidRequired'))
  // .matches(GMAID_REGEX, 'Valid GMAID must include the country platform i.e. \'USA_12345\''),
  affiliateOrder: yup.boolean().notRequired().nullable(),
  businessCategories: yup.array()
    .min(1, t('pages.business.details.businessCategoriesMin'))
    .max(5, t('pages.business.details.businessCategoriesMax')),
  category: yup.array()
    .min(1, t('pages.business.details.businessCategoriesMin'))
    .max(1, t('pages.business.details.businessSubCategoriesMax')),
  subCategory: yup.array()
    .min(1, t('pages.business.details.businessSubCategoriesMin'))
    .max(1, t('pages.business.details.businessSubCategoriesMax')),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
