/* eslint-disable consistent-return */
import { seoBlogProducts, seoProducts, smmProducts } from '../ThemeConstants';
import { siteMap } from './BundleConstants';

export const checkCartValidations = ({
  navigate, cartItems, redirectToCart, item, opportunityId,
}) => {
  const isPremiumListing = item.product === 'premium listing';
  const isMasterAddon = item.product === 'master addons';
  const mapCartItems = cartItems.content.map(item => item.productName);

  if (['website - gold', 'website - standard'].includes(item.product)
      && (mapCartItems.includes('Website') || mapCartItems.includes('GoldSite'))) {
    return navigate(redirectToCart, {
      state: { message: 'Oops! User can have a maximum of 1 Website order in the cart' },
    });
  }

  if (isPremiumListing && (cartItems.content.filter(e => e.themeId === item.id).length > 0
  || mapCartItems.includes(siteMap[item.product]))) {
    return navigate(redirectToCart, {
      state: { message: 'Oops! User can have a maximum of 1 Premium Listing order in the cart' },
    });
  }

  if (item.product === 'chat' && mapCartItems.includes(siteMap[item.product])) {
    return navigate(redirectToCart, {
      state: { message: 'Oops! User can have a maximum of 1 Smart Chatbot Silver order in the cart' },
    });
  }

  if (item.product === 'chat - platinum' && mapCartItems.includes(siteMap[item.product])) {
    return navigate(redirectToCart, {
      state: { message: 'Oops! User can have a maximum of 1 Chat Platinum order in the cart' },
    });
  }

  if (seoProducts.includes(item.product)
      && (mapCartItems.includes('SEOStandard') || mapCartItems.includes('SEOCustom'))) {
    return navigate(redirectToCart, {
      state: { message: 'Oops! User can have a maximum of 1 SEO order in the cart' },
    });
  }

  if (seoBlogProducts.includes(item.product)
      && (mapCartItems.includes('SEOBlogStandard') || mapCartItems.includes('SEOBlogEnhanced'))) {
    return navigate(redirectToCart, {
      state: { message: 'Oops! User can have a maximum of 1 Blog Content order in the cart' },
    });
  }

  if (smmProducts.includes(item.product)
      && (mapCartItems.includes('SMMStandard') || mapCartItems.includes('SMMCustom'))) {
    return navigate(redirectToCart, {
      state: { message: 'Oops! User can have a maximum of 1 SMM order in the cart' },
    });
  }

  if (isMasterAddon && mapCartItems.includes(siteMap[item.product]) && !opportunityId) {
    return navigate(redirectToCart, {
      state: { message: 'Please use the edit button on the Add-ons item to select additional Add-ons' },
    });
  }

  return false;
};
