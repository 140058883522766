import {
  Button, Card, CardActions, CardContent, Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import HipaaFormDefinitionType from 'models/HipaaFormDefinitionType';
import { S1 } from '../labels/S1';
import { Text } from '../labels/Text';

export interface HipaaFormCardType {
  onEdit?: (arg?: unknown) => unknown,
  onDelete?: (arg?: unknown) => unknown,
  isFirst: boolean
}

export const HipaaFormCardButton = styled(Button)`
  &.Mui-disabled {
    color: #FFFFFF
  }
  font-weight: 400;
  text-transform: none;
  font-size: 14px;
  line-height: 18px;
  padding: 0 40px 0 0;
  min-width: 0;
  
  svg.MuiSvgIcon-root {
    font-size: 12px;
  }
`;

export const HipaaFormCardHeadingStyles = {
  fontWeight: '600',
  opacity: '0.8',
  fontSize: '16px',
  marginRight: '16px',
  marginLeft: '4.14px',
};

const styles = makeStyles(
  {
    card: {
      marginTop: '24px',
    },
    cardFirst: {
      marginTop: '2px',
    },
    innerGrid: {
      marginTop: '10px',
    },
    button: {
      textTransform: 'none',
      padding: '0 20px 0 0',
      minWidth: 0,
    },
    cardContent: {
      paddingTop: '24px',
      paddingRight: '24px',
      paddingLeft: '24px',
    },
    cardActions: {
      paddingBottom: '24px',
      paddingRight: '24px',
      paddingLeft: '24px',
    },
  },
);

export default function HipaaFormCard(props: HipaaFormCardType & HipaaFormDefinitionType) {
  const { t } = useTranslation();

  const style = styles();

  return (
    <Card sx={{ minWidth: 275 }} className={props.isFirst ? style.cardFirst : style.card} variant="outlined">
      <CardContent className={style.cardContent}>
        <Grid container>
          <Grid item xs={12}>
            <S1 sx={{ fontSize: '18px', fontWeight: 600 }}>
              {props.title}
            </S1>
          </Grid>
          <Grid item xs={3.5} className={style.innerGrid}>
            <S1 sx={{ ...HipaaFormCardHeadingStyles }}>
              {t('pages.addons.hipaaForms.dataCollected')}
              :
            </S1>
          </Grid>
          <Grid item xs={8.5} className={style.innerGrid}>
            <Text>
              <pre style={{
                fontFamily: 'Unify Sans', margin: 0, color: '#404040', whiteSpace: 'pre-wrap',
              }}
              >
                {props.description}
              </pre>
            </Text>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={style.cardActions}>
        <HipaaFormCardButton name="edit" endIcon={<ArrowForwardIosIcon />}
          onClick={props.onEdit}
        >
          {t('components.pageCard.buttons.edit')}
        </HipaaFormCardButton>
        <HipaaFormCardButton name="delete"
          onClick={props.onDelete}
        >
          {t('components.pageCard.buttons.remove')}
        </HipaaFormCardButton>
      </CardActions>
    </Card>
  );
}
