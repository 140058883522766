import React, {useEffect, useMemo, useState} from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import i18n from 'i18n/i18n';
const { t } = i18n;

type AdAssetGroup = {
  productWantToFocus: string;
  keySearchThemeLikeToFocus: string;
  idealCustomer: string;
  ageRangeOfYourTargetAudience: string;
};

const initAssetGroupState = () : AdAssetGroup => ({productWantToFocus: '',keySearchThemeLikeToFocus: '',idealCustomer: '',ageRangeOfYourTargetAudience: ''});

const validateInput = (assetGroup: AdAssetGroup) => {
  const errors = {
    productWantToFocus: '',
    keySearchThemeLikeToFocus: '',
    idealCustomer: '',
    ageRangeOfYourTargetAudience: '',
  };

  if (!assetGroup.productWantToFocus) {
    errors.productWantToFocus = t('pages.ausProduct.tracking.requiredFieldErrorMessage');
  }

  if (!assetGroup.keySearchThemeLikeToFocus) {
    errors.keySearchThemeLikeToFocus = t('pages.ausProduct.tracking.requiredFieldErrorMessage');
  }

  if (!assetGroup.idealCustomer) {
    errors.idealCustomer = t('pages.ausProduct.tracking.requiredFieldErrorMessage');
  }

  if (!assetGroup.ageRangeOfYourTargetAudience) {
    errors.ageRangeOfYourTargetAudience = t('pages.ausProduct.tracking.requiredFieldErrorMessage');
  }

  return errors;
};

const textStyle: React.CSSProperties = {
  marginLeft: '5px',
  wordBreak: 'break-word'
};

export default function AddAssetGroup(props: any) {
  const [errors, setErrors] = useState({
    productWantToFocus: '',
    keySearchThemeLikeToFocus: '',
    idealCustomer: '',
    ageRangeOfYourTargetAudience: '',
  });
  const [isValid, setIsValid] = useState(false);
  const theme = useTheme();
  const [assetGroups, setAssetGroups] = useState<AdAssetGroup[]>(props.value || []);
  const [open, setOpen] = useState(false);
  const [assetGroup, setAssetGroup] = useState<AdAssetGroup>(initAssetGroupState());
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const maxReached = useMemo(() => assetGroups.length > 9, [assetGroups])
  const currentWindowCount = useMemo(() => editIndex!==null ? editIndex+1 : assetGroups.length + 1, [editIndex, assetGroups]);
  
  const handleClickOpen = (index: number | null = null) => {
    if (index !== null) {
      setAssetGroup(assetGroups[index]);
      setEditIndex(index);
    } else {
      setAssetGroup(initAssetGroupState);
      setEditIndex(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsValid(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setAssetGroup((prevWebEvent) => ({...prevWebEvent, [name]: value}));
  };

  const handleSubmit = () => {
    let items = [...assetGroups];
    const newItem = {...assetGroup};
    const idx = editIndex !== null ? editIndex : items.length;
    items.splice(idx, 1, newItem);
    setAssetGroups(items);
    setAssetGroup(initAssetGroupState());
    handleClose();
  };

  const handleDelete = (index: number) => {
    setAssetGroups((prevItem) => prevItem.filter((_, i) => i !== index));
  };

  useEffect(() => {
    props.onChange(assetGroups.map(asset => ({...asset})))
  }, [assetGroups]);

  useEffect(() => {
    const errors = validateInput(assetGroup);
    setErrors(errors);
    setIsValid(Object.values(errors).every((error) => error === ''));
  }, [assetGroup]);

  return (
      <Grid item xs={9} my={3}>
        <Grid>
          <Box>
            {assetGroups && assetGroups.map((asset, index) => (
                <Grid key={index}>
                  <Box
                      mb={1}
                      sx={{
                        padding: '10px',
                        display: 'flex',
                        fontSize: '15px',
                        alignItems: 'center',
                        backgroundColor: '#ffffff',
                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
                      }}
                  >
                    <Box sx={{
                      flexGrow: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginRight: '20%'
                    }}>
                      <Box>
                        <Typography variant="h6" sx={{marginBottom: '10px', fontWeight: 'bold'}}>
                          {t('assetGroupWidget.title', {count: index + 1})}
                        </Typography>
                        <div style={{margin: 0}}>
                          <span style={{fontWeight: 'bold'}}>{t('assetGroupWidget.productWantToFocus')}:</span>
                          <span style={textStyle}>{asset.productWantToFocus}</span>
                        </div>
                        <div style={{marginTop: '10px'}}>
                          <span style={{fontWeight: 'bold'}}>{t('assetGroupWidget.keySearchThemeLikeToFocus')}:</span>
                          <span style={textStyle}>{asset.keySearchThemeLikeToFocus}</span>
                        </div>
                        <div style={{marginTop: '10px'}}>
                          <span style={{fontWeight: 'bold'}}>{t('assetGroupWidget.idealCustomer')}:</span>
                          <span style={textStyle}>{asset.idealCustomer}</span>
                        </div>
                        <div style={{marginTop: '10px'}}>
                          <span style={{fontWeight: 'bold'}}>{t('assetGroupWidget.ageRangeOfYourTargetAudience')}:</span>
                          <span style={textStyle}>{asset.ageRangeOfYourTargetAudience}</span>
                        </div>
                      </Box>
                    </Box>
                    <IconButton onClick={() => handleClickOpen(index)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleDelete(index)}>
                      <DeleteIcon/>
                    </IconButton>
                  </Box>
                </Grid>
            ))}
            <Box
                sx={{
                  padding: '20px 20px 20px 0',
                  backgroundColor: theme.palette.primary.light,
                }}
            >
              <Button disabled={maxReached} startIcon={<AddIcon/>} onClick={() => handleClickOpen()}>
                {t('assetGroupWidget.add')}
              </Button>
            </Box>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{t('assetGroupWidget.title', {count: currentWindowCount})}</DialogTitle>
              <DialogContent>
                   <TextField
                    margin="dense"
                    name="productWantToFocus"
                    label={t('pages.ausProduct.ausPerformanceMax.assetGroup.productWantToFocus')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={assetGroup.productWantToFocus}
                    onChange={handleChange}
                />
                   <TextField
                    margin="dense"
                    name="keySearchThemeLikeToFocus"
                    label={t('pages.ausProduct.ausPerformanceMax.assetGroup.keySearchThemeLikeToFocus')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={assetGroup.keySearchThemeLikeToFocus}
                    onChange={handleChange}
                />
                    <TextField
                    margin="dense"
                    name="idealCustomer"
                    label={t('pages.ausProduct.ausPerformanceMax.assetGroup.idealCustomer')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={assetGroup.idealCustomer}
                    onChange={handleChange}
                />
                <Typography variant="h6" sx={{ color: '#716F6F',fontSize: "0.875rem",fontWeight: "600",lineHeight: "1.57" }}>{t('pages.ausProduct.ausPerformanceMax.assetGroup.userSearchesForUrgentPlumbing')}</Typography>
                    <TextField
                    margin="dense"
                    name="ageRangeOfYourTargetAudience"
                    label={t('pages.ausProduct.ausPerformanceMax.assetGroup.ageRangeOfYourTargetAudience')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={assetGroup.ageRangeOfYourTargetAudience}
                    onChange={handleChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                {t('assetGroupWidget.cancel')}
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={!isValid}>
                {t(editIndex === null ? 'assetGroupWidget.save' : 'assetGroupWidget.update')}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
  );
}