import React from 'react';

import { Grid } from '@mui/material';

import styled from '@emotion/styled';

import { IQLabelTooltip, IQTheme, IQFormSelect } from '@gannettdigital/shared-react-components';
import { IQPhoneInputContainerProps } from '../IQPhoneInputContainer';

const StyledGridItem = styled(Grid)`

  label {
    display: none;
  }

  .MuiInputBase-root {
    padding-left: 16px;
    gap: 16px;
    min-height: 44px;
    background: ${(props) => props.theme.palette.common.white};
    border-radius: 2px;
  }

  .MuiInputAdornment-root {
    display: none;
  }
`;

export interface TypeElementProps
  extends Omit<
  IQPhoneInputContainerProps,
  | 'name'
  | 'defaultValue'
  | 'country'
  | 'deleteButton'
  | 'deleteCallback'
  | 'containerStyles'
  > {}

export const IQPhoneType = ({
  typeLabelOptions,
  typeOptions,
}: TypeElementProps) => {
  const {
    defaultValue, name, values, required,
  } = typeOptions;

  return (
    <Grid rowGap={1} container>
      <Grid item xs={12}>
        <IQLabelTooltip
          theme={IQTheme}
          required={required}
          labelText="Type"
          {...typeLabelOptions}
        />
      </Grid>
      <StyledGridItem item xs={12}>
        <IQFormSelect
          fullWidth
          name={name}
          items={values}
          labelText=""
          defaultValue={defaultValue}
        />
      </StyledGridItem>
    </Grid>
  );
};
