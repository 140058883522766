export const doesClientHaveAnotherAccountDropdownItems = [
  {value: 'Yes', description: 'Yes, Please use the MMR script'},
  {value: 'No', description: 'No'},
];

export const webEventsDropdownItems = [
  {
    value: 'Please adjust web events per best practices',
    description: 'Please adjust web events per best practices'
  },
  {value: 'Use only my web events', description: 'Use only my web events'},
];