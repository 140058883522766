/* eslint-disable max-len */
import i18n from 'i18n/i18n';

import {RJSFSchema} from '@rjsf/utils';

const {t} = i18n;
const trackingNumbers = Array.from({length: 6}, (_, i) => (i < 5 ? (i + 1).toString() : '6+'));
export const CallTrackingSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.callTracking.title'),
  type: 'object',
  required: [
    'totalTrackingNumbersRequired',
    'isCallRecordingRequired',
    'complexRouting',
    'numbersNotToTrack',
  ],
  properties: {
    totalTrackingNumbersRequired: {
      type: 'string',
      title: t('pages.ausProduct.callTracking.totalTrackingNumbersRequiredLabel'),
      description: t('pages.ausProduct.callTracking.totalTrackingNumbersRequiredHelpText'),
      default: data?.totalTrackingNumbersRequired || '',
      enum: ['', ...trackingNumbers],
    },
    isCallRecordingRequired: {
      type: 'string',
      title: t('pages.ausProduct.callTracking.isCallRecordingRequiredLabel'),
      description: t('pages.ausProduct.callTracking.isCallRecordingRequiredHelpText'),
      default: data?.isCallRecordingRequired || '',
      enum: [
        '',
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
    },
    complexRouting: {
      type: 'string',
      title: t('pages.ausProduct.callTracking.complexRoutingLabel'),
      description: t('pages.ausProduct.callTracking.complexRoutingHelpText'),
      default: data?.complexRouting || '',
      enum: [
        '',
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
    },
    numbersNotToTrack: {
      type: 'string',
      title: t('pages.ausProduct.callTracking.numbersNotToTrackLabel'),
      default: data?.numbersNotToTrack || '',
      enum: [
        '',
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
    },
  },
  dependencies: {
    numbersNotToTrack: {
      oneOf: [
        {
          properties: {
            numbersNotToTrack: {
              enum: ['Yes'],
            },
            enteredNumber: {
              type: 'string',
              title: t('pages.ausProduct.callTracking.enteredNumberLabel'),
              default: data?.enteredNumber || '',
            },
          },
        },
      ],
    },
  }
});

const dropdownFieldKeys = [
  'totalTrackingNumbersRequired',
  'isCallRecordingRequired',
  'complexRouting',
  'numbersNotToTrack',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'dropdown'},
}), {});

export const CallTrackingUISchema = {
  'ui:order': [
    'totalTrackingNumbersRequired',
    'isCallRecordingRequired',
    'complexRouting',
    'numbersNotToTrack',
    'enteredNumber',
  ],
  enteredNumber: {
    'ui:widget': 'integerInputField'
  },
  ...dropdownFields
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CallTrackingCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.totalTrackingNumbersRequired === '') {
    errors.totalTrackingNumbersRequired.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.isCallRecordingRequired === '') {
    errors.isCallRecordingRequired.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.complexRouting === '') {
    errors.complexRouting.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.numbersNotToTrack === '') {
    errors.numbersNotToTrack.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
