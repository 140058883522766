import {
  AppBar,
  Box, Container, Toolbar, Typography,
} from '@mui/material';
import IQTopNavIcon from 'icons/IQTopNavIcon.svg';
import { backgroundDefaultColor } from 'styles/common_styles';
import Check from 'icons/check.svg';
import { Urls } from 'navigation/Urls';
import { useNavigate } from 'react-router';

export default function punchListSaved() {
  const navigate = useNavigate();
  return (

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <AppBar position="fixed"
        sx={{
          backgroundColor: 'black',
          height: '50px',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Container sx={{
          maxWidth: 'initial !important',
          marginLeft: 0,
          marginRight: 0,
        }}
        >
          <Toolbar
            disableGutters
            sx={{ minHeight: '50px !important', padding: '0 !important', justifyContent: 'space-between' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 !important' }}>
              <Box onClick={() => navigate(`/${Urls.Themes}`)}
                sx={{ cursor: 'pointer', padding: '0 !important', width: '126px' }}
              >
                <img src={IQTopNavIcon} alt="IQTopNavIcon" />
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        sx={{
          height: '93vh',
          display: 'flex',
          flexDirection: 'column',
          padding: '65px',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: backgroundDefaultColor,
          margin: '15px',
          borderRadius: '5px',
        }}
      >
        <img src={Check} alt="check" style={{ paddingBottom: '45px' }} />
        <Typography variant="h2" mb={3}>The punch list has been successfully saved.</Typography>
        <Typography variant="h2" mb={3} sx={{ fontSize: '1em' }}>
          All data entered has been saved. To return to the form,
          please locate the link in your email to access a previous session, or to start a new session.
        </Typography>
      </Box>
      <Box
        sx={{
          flexShrink: 0,
          height: '7vh',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          borderTop: '1px solid grey',
        }}
      >
        <Typography variant="body1" sx={{ marginLeft: '15px' }}>
          © 2024 Gannett Co., Inc. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
}
