import {Urls} from 'navigation/Urls';
import {MenuType} from 'services/navigationSlice';
import TopNavStatusEnum from "services/TopNavStatusEnum";

export const targetedDisplayLegacyMenu: MenuType = ({
  label: 'Targeted Display Legacy',
  url: '',
  status: 'active',
  offeringType: 'TargetedDisplayLegacy',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
    {
      label: 'Solution Strategy',
      url: '',
      status: 'incomplete',
      subSteps: [
        {
          id: 0,
          url: Urls.TargetedDisplayLeagacyProxyProvisioning,
          name: 'Proxy/Provisioning',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 1,
          url: Urls.TargetedDisplayLeagacyClientInformation,
          name: 'Client Information',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 2,
          url: Urls.TargetedDisplay,
          name: 'Campaign Info',
          status: TopNavStatusEnum.NotStarted,
        },
      ],
    },
  ],
});
