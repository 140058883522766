import { Typography, Box } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { Urls } from 'navigation/Urls';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { headingStyle } from '../Business/BusinessSection';

export const customWebsiteDevelopmentSection = (websiteData, product, navigate, isEditable) => (
  <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
    <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Development</Typography>

    <Box>
      {/* Content */}
      <Box sx={{ display: 'flex', marginBottom: '10px' }}>
        {editButton(isEditable, () =>
          navigate.to(
            `${Urls.CustomWebsiteDevelopment}/${Urls.Content}`,
            product.id,
          ))}
        <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Content</Typography>
      </Box>
      <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Total Number of Site Pages</Typography>
            <Typography>{websiteData?.numberOfPages}</Typography>
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Copyrighting needed?</Typography>
            <Typography>{websiteData?.copyrightNeeded ? 'Yes' : 'No'}</Typography>
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Number of Pages for New Content</Typography>
            <Typography>{websiteData?.copyrightPagesAmount}</Typography>
          </Box>
        </Box>
      </GridItem>

      {/* Applications */}
      <Box sx={{ display: 'flex', margin: '10px 0' }}>
        {editButton(isEditable, () =>
          navigate.to(
            `${Urls.CustomWebsiteDevelopment}/${Urls.Applications}`,
            product.id,
          ))}
        <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Applications</Typography>
      </Box>
      <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Are third party applications needed</Typography>
            <Typography>{websiteData?.thirdPartyApplications ? 'Yes' : 'No'}</Typography>
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Third-Party Application Name and URLs</Typography>
            {displayNotRequiredField('application name and URLs', websiteData?.thirdPartyApplications)}
          </Box>
        </Box>
      </GridItem>

      {/* Examples */}
      <Box sx={{ display: 'flex', margin: '10px 0' }}>
        {editButton(isEditable, () =>
          navigate.to(
            `${Urls.CustomWebsiteDevelopment}/${Urls.CustomExamples}`,
            product.id,
          ))}
        <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Examples</Typography>
      </Box>
      <GridItem sizes={[6, 6]}>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Website References</Typography>
            <Typography>{websiteData?.examples}</Typography>
          </Box>
        </Box>
        <Box />
      </GridItem>

    </Box>
  </GridItem>
);
