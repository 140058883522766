import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from "@mui/icons-material/Add";
import {
  IQButtonLink,
  IQFormInput,
  IQTheme,
  ValidationProvider
} from "@gannettdigital/shared-react-components";
import {DialogTitle} from "@mui/material";
import schema
  from "pages/youtube/advertising/ad-group-options/market-audience/InMarketAudienceFields.schema";
import {FormProvider, useForm} from "react-hook-form";
import GridItem from "components/grid/GridItem";
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from "react-i18next";
import CoNavigationConfirm from "components/navigation/CoNavigationConfirm";
import GridContainer from "components/grid/GridContainer";

export default function InMarketAudienceFormDialog({
                                                     open,
                                                     onOpen,
                                                     onClose,
                                                     updateInMarketAudiences,
                                                     selectedInMarketAudiences
                                                   }) {

  const {t} = useTranslation();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      inMarketAudience: '',
      category: '',
      subCategory: '',
      specifics: '',
      details: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
    setValue,
  } = methods;

  useEffect(() => {
    if (selectedInMarketAudiences) {
      // Populate form fields with selectedInMarketAudiences data
      setValue("inMarketAudience", selectedInMarketAudiences.inMarketAudience);
      setValue("category", selectedInMarketAudiences.category);
      setValue("subCategory", selectedInMarketAudiences.subCategory);
      setValue("specifics", selectedInMarketAudiences.specifics);
      setValue("details", selectedInMarketAudiences.details);
    }
  }, [selectedInMarketAudiences]);

  const handleFormSubmit = async (data) => {
    let {sourceId} = selectedInMarketAudiences || {};

    if (!sourceId) {
      // Generate a unique sourceId if it does not exist
      sourceId = uuidv4();
    }
    // update In-Market Audiences with sourceId
    updateInMarketAudiences({...data, sourceId});

    // Close the form
    handleClose();
  };

  const handleOpen = () => {
    // Reset the form state
    reset({
      inMarketAudience: '',
      category: '',
      subCategory: '',
      specifics: '',
      details: '',
    });
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  return (
      <>
        {<IQButtonLink startIcon={<AddIcon/>} onClick={() => handleOpen()}>
          {t('pages.youtube.advertising.adGroupOptions.inMarket.addIconLabel')}
        </IQButtonLink>}
          <Dialog
              open={open}
              onClose={handleClose}
              maxWidth="lg"
              fullWidth
          >
            <DialogTitle>{t('pages.youtube.advertising.adGroupOptions.inMarket.header')}</DialogTitle>
            <DialogContent>
              <ValidationProvider schema={schema}>
                <FormProvider {...methods}>
                  <form>
                    <GridContainer fullWidth>
                      <GridItem inlineItems sizes={[6, 6]}>
                        <IQFormInput
                            id="inMarketAudience"
                            theme={IQTheme}
                            labelText={t('pages.youtube.advertising.adGroupOptions.inMarket.inMarketAudience')}
                            fontLabelWeight="600"
                            name="inMarketAudience"
                            fullWidth
                        />
                        <IQFormInput
                            id="category"
                            theme={IQTheme}
                            labelText={t('pages.youtube.advertising.adGroupOptions.inMarket.category')}
                            fontLabelWeight="600"
                            name="category"
                            fullWidth
                        />
                      </GridItem>
                      <GridItem inlineItems sizes={[6, 6]}>
                        <IQFormInput
                            id="subCategory"
                            theme={IQTheme}
                            labelText={t('pages.youtube.advertising.adGroupOptions.inMarket.subCategory')}
                            fontLabelWeight="600"
                            name="subCategory"
                            fullWidth
                        />
                        <IQFormInput
                            id="specifics"
                            theme={IQTheme}
                            labelText={t('pages.youtube.advertising.adGroupOptions.inMarket.specifics')}
                            fontLabelWeight="600"
                            name="specifics"
                            fullWidth
                        />
                      </GridItem>
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="details"
                            theme={IQTheme}
                            labelText={t('pages.youtube.advertising.adGroupOptions.inMarket.details')}
                            fontLabelWeight="600"
                            name="details"
                            fullWidth
                        />
                      </GridItem>
                    </GridContainer>
                    <DialogActions>
                      <Button
                          onClick={handleClose}>{t("pages.youtube.advertising.adGroupOptions.CloseBtn")}</Button>
                      <Button disabled={!isValid}
                              onClick={handleSubmit(handleFormSubmit)}>{t("pages.youtube.advertising.adGroupOptions.submitBtn")}</Button>
                    </DialogActions>
                  </form>
                  <CoNavigationConfirm
                      showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
                  />
                </FormProvider>
              </ValidationProvider>
            </DialogContent>
          </Dialog>
      </>
  );
}