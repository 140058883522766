/* eslint-disable import/no-extraneous-dependencies */
import {RJSFSchema} from '@rjsf/utils';
import i18n from 'i18n/i18n';
import {validateUrl} from "pages/generic-product/details/custom-fields/UrlTextField";

const {t} = i18n;

export const TikTokSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.ausTiktok.title'),
  // eslint-disable-next-line max-len
  type: 'object',
  required: ['campaignObjectives'],
  properties: {
    helpText1: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.ausTiktok.helpText1Label'),
      margin: 0
    },
    helpText2: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.ausTiktok.helpText2Label'),
      margin: 0
    },
    helpText3: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.ausTiktok.helpText3Label'),
      margin: 0
    },
    helpText4: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.ausTiktok.helpText4Label'),
      margin: 0
    },
    helpText5: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.ausTiktok.helpText5Label'),
      margin: 0
    },
    campaignObjectives: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.campaignObjectivesLabel'),
      default: data?.campaignObjectives || '',
      items: {
        enum: [
          `${t('pages.ausProduct.ausTiktok.reach')}`,
          `${t('pages.ausProduct.ausTiktok.clickOrTraffic')}`,
          `${t('pages.ausProduct.ausTiktok.videoViews')}`,
          `${t('pages.ausProduct.ausTiktok.communityInteractions')}`,
        ],
      },
    },
  },
  dependencies: {
    campaignObjectives: {
      oneOf: [
        {
          properties: {
            campaignObjectives: {
              enum: [`${t('pages.ausProduct.ausTiktok.clickOrTraffic')}`],
            },
            clientsWebsiteUrl: {
              type: 'string',
              title: t('pages.ausProduct.ausTiktok.clientsWebsiteUrlLabel'),
              default: data?.clientsWebsiteUrl || '',
            },
          },
        },
      ],
    },
  },
});

const radioFieldKeys = [
  'campaignObjectives',
];

const urlTextFieldKeys = [
  'clientsWebsiteUrl', 'tiktokPageUrl',
]

const textLabelKeys = [
  'helpText1', 'helpText2', 'helpText3', 'helpText4', 'helpText5'
];

const textLabelFields = textLabelKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textLabel'},
}), {});

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'radioButton'},
}), {});

const urlTextFields = urlTextFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'UrlTextWidget'},
}), {});

export const TikTokUISchema = {
  'ui:order': [
    'helpText1',
    'helpText2',
    'helpText3',
    'helpText4',
    'helpText5',
    'campaignObjectives',
    'clientsWebsiteUrl'
  ],
  ...radioFields,
  ...textLabelFields,
  ...urlTextFields
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TikTokCustomValidate = (formData: any, errors, uiSchema: any) => {
  if (formData?.campaignObjectives === `${t('pages.ausProduct.ausTiktok.clickOrTraffic')}`) {
    if (formData?.clientsWebsiteUrl !== undefined && formData?.clientsWebsiteUrl !== ''
        && !validateUrl(formData?.clientsWebsiteUrl)) {
      errors.clientsWebsiteUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.campaignObjectives === '') {
    errors.campaignObjectives.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
