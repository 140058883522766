import * as yup from 'yup';

const schema = {
  subType: yup.string().required().nullable(),
  subTypeTier: yup.string().required().nullable(),
  customSow: yup.string().required().nullable(),
  competitors: yup.string().optional().nullable(),
  additionalNotes: yup.string().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
