import i18n from "i18n/i18n";

const {t} = i18n;
export const genderOptionRadioItems = [
  {field: 'male', label: t('pages.xmo.reach.genderOption.male'), value: "male"},
  {field: 'female', label: t('pages.xmo.reach.genderOption.female'), value: "female"},
  {field: 'both', label: t('pages.xmo.reach.genderOption.both'), value: "both"},
];

export const callToActionDropDown = [
  {
    value: 'No Button',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownNoButton')
  },
  {
    value: 'Apply Now',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownApplyNow')
  },
  {
    value: 'Book Now',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownBookNow')
  },
  {
    value: 'Contact Us',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownContactUs')
  },
  {
    value: 'Donate Now',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownDonateNow')
  },
  {
    value: 'Download',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownDownload')
  },
  {
    value: 'Get Showtimes',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownGetShowTimes')
  },
  {
    value: 'Learn More',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownLearnMore')
  },
  {
    value: 'Listen Now',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownListenNow')
  },
  {
    value: 'Request Time',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownRequestTime')
  },
  {
    value: 'See Menu',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownSeeMenu')
  },
  {
    value: 'Send Message',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownSendMessage')
  },
  {
    value: 'Shop Now',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownShopNow')
  },
  {
    value: 'Sign Up',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownSignUp')
  },
  {
    value: 'Watch More',
    description: t('pages.xmo.tactics.xmoWebsiteClick.callToActionDropDownWatchMore')
  },
];

export const yesNoRadioOptions = [
  {label: t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.yes')},
  {label: t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.no')},
];

export const socialPlatformListOptions = [
  {label: t('pages.xmo.reach.socialPlatformListOptions.mobileFeed')},
  {label: t('pages.xmo.reach.socialPlatformListOptions.desktopFeed')},
  {label: t('pages.xmo.reach.socialPlatformListOptions.instagram')},
  {label: t('pages.xmo.reach.socialPlatformListOptions.facebook')},
  {label: t('pages.xmo.reach.socialPlatformListOptions.marketplace')}
];
export const visualStrategyListOptions = [
  {label: t('pages.xmo.tactics.xmoWebsiteClick.singleImageRadioOption')},
  {label: t('pages.xmo.tactics.xmoWebsiteClick.multipleVideosRadioOption')},
  {label: t('pages.xmo.tactics.xmoWebsiteClick.singleVideoRadioOption')},
  {label: t('pages.xmo.tactics.xmoWebsiteClick.imageVideoRadioOption')},
  {label: t('pages.xmo.tactics.xmoWebsiteClick.multipleImageRadioOption')},
];

export const clientBeProvidingImagesToUseListOptions = [
  {label: t('pages.xmo.tactics.xmoWebsiteClick.yesAllRadioOption')},
  {label: t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.no')},
  {label: t('pages.xmo.tactics.xmoWebsiteClick.yesSomeImageRadioOption')},
];