import {
  Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  deleteBundle, selectBundles, getBundles, setCurrentBundleByPayload,
} from 'services/adminSlice';
import { useState, useEffect, Children } from 'react';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

export default function Bundles() {
  const bundles = useSelector(selectBundles);
  const dispatch = useDispatch();
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    dispatch(getBundles({}));
    dispatch(setCurrentBundleByPayload({
      id: 0, name: '', mainThemeId: 0, active: false,
    }));
  }, []);

  const removeBundle = (id) => {
    dispatch(deleteBundle(id));
  };

  return (
    <>
      <Typography variant="h2">ReachSites Bundles</Typography>
      <Grid container>
        <Grid item xs={12} marginTop={1}>
          <Link to="/admin/bundles/new" style={{ marginRight: '10px' }}>
            <Button variant="contained">
              <Typography>New Bundle</Typography>
            </Button>
          </Link>
          <Link to="/admin/dashboard">
            <Button variant="contained">
              <Typography>Admin Dashboard</Typography>
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} marginTop={5}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ borderBottom: '3px solid lightgray' }}>
                  <TableCell><Typography fontWeight="bold">Main Product</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Title</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Appended Products</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Appended Add-ons</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Active</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">&nbsp;</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bundles.map(bundle => (
                  <TableRow
                    key={bundle.id}
                    sx={{
                      border: 0,
                      '& .MuiTableCell-root': { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Typography>
                        {bundle.mainThemeName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {bundle.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <ul>
                        {Children.toArray(bundle.addedProducts?.split(',').map(product => (
                          <li><Typography>{product}</Typography></li>
                        )))}
                      </ul>
                    </TableCell>
                    <TableCell>
                      <ul>
                        {Children.toArray(bundle.addedAddOns?.split(',').map(addon => (
                          <li><Typography>{addon}</Typography></li>
                        )))}
                      </ul>
                    </TableCell>
                    <TableCell>
                      {`${bundle.active}`}
                    </TableCell>
                    <TableCell>
                      <Link to={`${bundle.id}/edit`} style={{ marginRight: '10px' }}>
                        <Button variant="contained">
                          <Typography>Edit</Typography>
                        </Button>
                      </Link>
                      <Button variant="contained" color="error"
                        onClick={() => setItemToDelete(bundle)}
                      >
                        <Typography>Destroy</Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DeleteConfirmationModal
            open={!!itemToDelete}
            onClose={() => setItemToDelete(null)}
            onConfirm={() => removeBundle(itemToDelete.id)}
            resourceName={itemToDelete?.name} resourceType="Bundle"
          />
        </Grid>
      </Grid>
    </>
  );
}
