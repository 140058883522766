import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import {
  FormHelperText, Grid, TextField, Typography,
} from '@mui/material';
import ErrorText from 'components/errorText/ErrorText';
import { useState } from 'react';

export default function IntegerInputField(props: any) {
  const [isTouched, setIsTouched] = useState(!!props.value);

  const handleInputChange = (value: string) => {
    // Allow only integers by removing non-numeric characters
    const intValue = value.replace(/\D/g, '');

    // Call the parent onChange with the integer value
    props.onChange(intValue);
  };
  const showError = isTouched && (props?.rawErrors?.length > 0);

  return (
    <Grid item xs={9} mt={3}>
      <Typography
        fontWeight={600}
        sx={{
          color: (props?.rawErrors?.length > 0 && isTouched)
            ? IQThemeV2.palette.error.main : IQThemeV2.palette.text.primary,
        }}
      >
        {props.label}
      </Typography>
      <TextField
        id={props.id}
        name={props.name}
        disabled={props.disabled}
        fullWidth
        error={props?.rawErrors?.length > 0 && isTouched}
        required={props.required}
        onBlur={() => setIsTouched(true)}
        style={{ marginTop: '10px' }}
        value={props.value}
        onChange={(e) => handleInputChange(e.target.value)}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          onKeyDown: (e) => {
            // Allow only numeric keys, backspace, delete, arrow keys, and navigation keys
            if (
              !/^\d*$/.test(e.key)
                    && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'].includes(e.key)
            ) {
              e.preventDefault();
            }
          },
        }}
      />
      {props?.placeholder && !showError
            && <FormHelperText error={false}>{props?.placeholder}</FormHelperText>}
      <ErrorText hasError={showError}
        errorText={showError ? (props?.rawErrors?.[0]) : ''}
      />
    </Grid>
  );
}
