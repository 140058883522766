import React, {useEffect, useMemo, useState} from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  Grid,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import i18n from 'i18n/i18n';
import { urlValidationRegex } from 'shared/constants';
const { t } = i18n;

type AdSitelink = {
  sitelinkUrl: string;
};

const initSitelinkState = () : AdSitelink => ({ sitelinkUrl: ''});

export default function AddClickThroughUrl(props: any) {
  const theme = useTheme();
  const [sitelinks, setSitelinks] = useState<AdSitelink[]>(props.value || []);
  const [open, setOpen] = useState(false);
  const [sitelink, setSitelink] = useState<AdSitelink>(initSitelinkState());
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const maxReached = useMemo(() => sitelinks.length > props.schema.maximumValue - 1, [sitelinks])
  const currentWindowCount = useMemo(() => editIndex!==null ? editIndex+1 : sitelinks.length + 1, [editIndex, sitelinks]);

  const handleClickOpen = (index: number | null = null) => {
    if (index !== null) {
      setSitelink(sitelinks[index]);
      setEditIndex(index);
    } else {
      setSitelink(initSitelinkState);
      setEditIndex(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsValidUrl(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setSitelink((prevWebEvent) => ({...prevWebEvent, [name]: value}));
    if (name === 'sitelinkUrl') {
      setIsValidUrl(validateUrl(value));
    }
  };

  const validateUrl = (url: string) => {
    return urlValidationRegex.test(url);
  };

  const handleSubmit = () => {
    let items = [...sitelinks];
    const newItem = {...sitelink};
    const idx = editIndex !== null ? editIndex : items.length;
    items.splice(idx, 1, newItem);
    setSitelinks(items);
    setSitelink(initSitelinkState());
    handleClose();
  };

  const handleDelete = (index: number) => {
    setSitelinks((prevItem) => prevItem.filter((_, i) => i !== index));
  };

  useEffect(() => {
    props.onChange(sitelinks.map(addUrl => ({...addUrl})))
  }, [sitelinks]);

  return (
      <Grid item xs={9} my={3}>
        <Grid>
          <Box>
            {sitelinks && sitelinks.map((ad, index) => (
                <Grid key={index}>
                  <Box
                      mb={1}
                      sx={{
                        padding: '10px',
                        display: 'flex',
                        fontSize: '15px',
                        alignItems: 'center',
                        backgroundColor: '#ffffff',
                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
                      }}
                  >
                    <Box sx={{
                      flexGrow: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginRight: '20%'
                    }}>
                      <Box>
                        <p style={{margin: 0, color: '#716F6F'}}>{t('clickThroughUrlWidget.url')}:</p>
                        <p style={{margin: 0}}>{ad.sitelinkUrl}</p>
                      </Box>
                    </Box>
                    <IconButton onClick={() => handleClickOpen(index)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleDelete(index)}>
                      <DeleteIcon/>
                    </IconButton>
                  </Box>
                </Grid>
            ))}
            <Box
                sx={{
                  padding: '20px 20px 20px 0',
                  backgroundColor: theme.palette.primary.light,
                }}
            >
              <Button disabled={maxReached} startIcon={<AddIcon/>} onClick={() => handleClickOpen()}>
                {t('clickThroughUrlWidget.add')}
              </Button>
            </Box>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{t('clickThroughUrlWidget.title', {count: currentWindowCount})}</DialogTitle>
              <DialogContent>
                <TextField
                    margin="dense"
                    name="sitelinkUrl"
                    label={t('clickThroughUrlWidget.url')}
                    type="url"
                    fullWidth
                    variant="outlined"
                    value={sitelink.sitelinkUrl}
                    onChange={handleChange}
                    error={!isValidUrl}
                    helperText={!isValidUrl ? "Please enter a valid URL" : ""}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t('clickThroughUrlWidget.cancel')}
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={!isValidUrl}>
                  {t(editIndex === null ? 'clickThroughUrlWidget.save' : 'clickThroughUrlWidget.update')}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
  );
}