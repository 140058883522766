export const inventoryTierItems = [
  'Recommended - All Inclusive',
  'Representative - Excludes YouTube, CTV/OTT',
  'Essential - Excludes YouTube, Pre-Roll Video, CTV/OTT',
];

export const specialCategoryItems = [
  'Yes - Career & Employment',
  'Yes - Housing, Real Estate',
  'Yes - Auto, Finance, Credit',
  'No - None of the Above',
];

export const keywordsItems = [
  'Pulled from Site',
  'Client-Provided',
];

export const politicalCampaignItems = [
  'Yes',
  'No',
];

export const fieldsOrder = [
  'inventoryTier',
  'youtubeVideoUrl',
  'additionalYoutubeVideoUrls',
  'specialCategory',
  'keywords',
  'pullSiteUrl',
  'clientProvidedKeywords',
  'politicalCampaign',
  'csmEmail',
  'websiteHighPriorityUrls',
  'websiteFormUrls',
];
