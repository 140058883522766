import { ComponentProps, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import LandingPageType from 'models/LandingPageType';
import { IQButtonRadioContainer, ValidationProvider } from '@gannettdigital/shared-react-components';
import { Box, Grid } from '@mui/material';
import styled from '@emotion/styled';

import { generateTooltipOrderedListElement, generateTooltipTextElement } from 'shared/generateTooltipText';

import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import schema from 'pages/landing-pages/domain/Domain.schema';

import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import CoDomainField from 'components/domain-field/CoDomainField';
import i18n from 'i18n/i18n';

const { t } = i18n;

interface Props {
  onSubmit: (data: DomainFormProps) => void;
  onBack: () => void;
  domain: LandingPageType;
}

export type DomainFormProps = {
  domainSelection: 'localiqProvidedDomain' | 'clientProvidedDomain' | string;
  subdomain: string;
  primaryDomain: string;
  tld: string;
};

type CoDomainFieldProps = ComponentProps<typeof CoDomainField>;

export const NoBackgroundRadioContainer = styled(Grid)`
  [role=group] > button { 
    max-width: max-content; 
  };
  
  .MuiButtonBase-root { 
    background: transparent;
  };
  
  .MuiButtonBase-root:hover { 
    background: transparent;
  };
  
  .MuiButtonBase-root:focus { 
    background: transparent;
  };
  
  .MuiButtonBase-root.Mui-selected { 
    background: transparent;
  };
  
  .MuiButtonBase-root.Mui-selected:hover { 
    background: transparent;
  };
  
  .MuiButtonBase-root.Mui-selected:focus { 
    background: transparent;
  };
  
  .MuiTouchRipple-root > * { 
    display: none;
  };
  
  [role=alert] { 
    position: absolute;
    top: 100%;
  };
`;

const domainOptions :CoDomainFieldProps['domainOptions'] = {
  schema,
  id: 'domain',
  name: 'primaryDomain',
  labelText: t('pages.landing.domain.domainLabel'),
  fontLabelWeight: '600',
  fullWidth: false,
  showError: true,
};

const subDomainOptions :CoDomainFieldProps['subDomainOptions'] = {
  schema,
  id: 'sub-domain',
  name: 'subdomain',
  labelText: t('pages.landing.domain.subDomainLabel'),
  fontLabelWeight: '600',
  fullWidth: false,
  showError: true,
  tooltipPlacement: 'top',
  // TODO: Refactor tooltips
  // @ts-ignore
  tooltipText: generateTooltipOrderedListElement(
    t('pages.landing.domain.domainTooltip'),
    t('pages.landing.domain.domainTooltipListHeading'),
    ['Learn', 'Discover', 'Go', 'Campaign', 'Buy', 'Find', '[City_name]', '[Service]'],
    {
      padding: '4px 0 0 8px', maxHeight: 'fit-content', top: '5px',
    },
    { paddingLeft: '18px', marginTop: 0, paddingBottom: '4px' },
  ),
  tooltipPaddingBottom: 20,
  // @ts-ignore
  tooltipPlacement: 'top',
  tooltipArrow: false,
};

const extensionOptions :CoDomainFieldProps['extensionOptions'] = {
  schema,
  id: 'extension',
  name: 'tld',
  labelText: t('pages.landing.domain.extensionLabel'),
  fontLabelWeight: '600',
  fullWidth: false,
  showError: true,
};

const radioOptions :Pick<ComponentProps<typeof IQButtonRadioContainer>, 'optionsList'>['optionsList'] = [
  {
    field: 'domainSelection',
    name: 'localiqProvidedDomain',
    label: t('pages.landing.domain.radioOptionLocaliqDomain'),
    value: 'localiqProvidedDomain',
    // TODO: Refactor
    // @ts-ignore
    tooltipText: generateTooltipTextElement(
      t('pages.landing.domain.radioTooltip'),
      { render: false },
      {
        padding: '4px 0 4px 0',
        margin: '0 2px 0 0',
        spanWidth: '194px',
        borderRadius: '50%',
        top: '-1px',
        maxHeight: '40px',
      },
    ),
    arrow: false,
    tooltipPlacement: 'top',
  },
  {
    field: 'domainSelection',
    name: 'clientProvidedDomain',
    label: t('pages.landing.domain.radioOptionClientDomain'),
    value: 'clientProvidedDomain',
  },
];

export default function DomainForm({ domain, onSubmit, onBack } :Props) {
  const methods = useForm<DomainFormProps>({
    mode: 'all',
    defaultValues: {
      domainSelection: domain?.domainSelection || 'localiqProvidedDomain',
      primaryDomain: domain?.primaryDomain || '',
      subdomain: domain?.subdomain || '',
      tld: domain?.tld || '',
    },
  });

  const {
    handleSubmit,
    setValue,
    unregister,
    formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    }, watch,
  } = methods;

  const handleFormSubmit = (data: DomainFormProps) => onSubmit(data);

  const currentDomainOption = watch('domainSelection');

  const showDomainField = currentDomainOption === 'clientProvidedDomain';

  useEffect(() => {
    if (currentDomainOption === 'localiqProvidedDomain') {
      setValue('primaryDomain', '');
      setValue('subdomain', '');
      setValue('tld', '');
      unregister(['primaryDomain', 'subdomain', 'tld']);
    }
  }, [currentDomainOption]);

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={onBack}
      header={t('pages.landing.domain.header')}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <NoBackgroundRadioContainer>
              <IQButtonRadioContainer
                name="domainSelection"
                optionsList={radioOptions}
                label={t('pages.landing.domain.radioHeading')}
              />
              {showDomainField && (
              <CoDomainField
                subDomainOptions={subDomainOptions}
                domainOptions={domainOptions}
                extensionOptions={extensionOptions}
              />
              )}
            </NoBackgroundRadioContainer>
            <CoNavigationConfirm showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)} />
          </Box>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
