/* eslint-disable max-len */
import i18n from 'i18n/i18n';

import {RJSFSchema} from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';

const {t} = i18n;

export const CampaignLandingPagesSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.ausCampaignLandingPages.title'),
  type: 'object',
  required: [
    'CampaignToRunAlongsideLandingPages',
    'additionalNotes'
  ],
  properties: {
    numberOfLandingPages: {
      type: 'string',
      title: t('pages.ausProduct.ausCampaignLandingPages.numberOfLandingPages'),
      default: '',
    },
    CampaignToRunAlongsideLandingPages: {
      type: 'string',
      title: t('pages.ausProduct.ausCampaignLandingPages.CampaignToRunAlongsideLandingPages'),
      default: '',
    },
    clientHappyToGoLiveSubDomain: {
      type: 'string',
      title: t('pages.ausProduct.ausCampaignLandingPages.clientHappyToGoLiveSubDomain'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
      description: t('pages.ausProduct.ausCampaignLandingPages.helpText1'),
    },
    additionalNotes: {
      type: 'string',
      title: t('pages.ausProduct.ausCampaignLandingPages.additionalNotes'),
      default: '',
      description: t('pages.ausProduct.ausCampaignLandingPages.helpText2'),
    },
    helpText3: {
      type: 'string',
      title: t('pages.ausProduct.ausCampaignLandingPages.helpText3'),
    },
    helpText4: {
      type: 'string',
      title: t('pages.ausProduct.ausCampaignLandingPages.helpText4'),
      enum: [t('pages.ausProduct.ausCampaignLandingPages.helpText4Link'), true]
    },
    helpText5: {
      type: 'string',
      title: t('pages.ausProduct.ausCampaignLandingPages.helpText5'),
    },
  },
});

const inputFieldKeys = [
  'numberOfLandingPages',
  'CampaignToRunAlongsideLandingPages',
];

const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

export const CampaignLandingPagesUISchema = {
  'ui:order': [
    'numberOfLandingPages',
    'CampaignToRunAlongsideLandingPages',
    'clientHappyToGoLiveSubDomain',
    'additionalNotes',
    'helpText3',
    'helpText4',
    'helpText5'
  ],
  clientHappyToGoLiveSubDomain: {
    'ui:widget': (props) => <Dropdown{...props}/>,
  },
  additionalNotes: {
    'ui:widget': 'TextareaWidget',
  },
  helpText3: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginTop: '25px',
    },
  },
  helpText4: {
    'ui:widget': 'anchorTextLabel',
  },
  helpText5: {
    'ui:widget': 'textLabel',
  },
  ...inputFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CampaignLandingPagesCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.CampaignToRunAlongsideLandingPages === '') {
    errors.CampaignToRunAlongsideLandingPages.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.additionalNotes === '') {
    errors.additionalNotes.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
