import React, { useEffect, useState } from 'react';
import {
  Button, Typography, Modal, Pagination, Box, useTheme,
} from '@mui/material';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FormProvider, useForm } from 'react-hook-form';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import schema from './IQGettyImageModal.schema';
import IQAssetsGrid from './IQAssetsGrid';
import {
  IQFormInput,
  IQFormSelect,
  IQLoadingSpinner,
  ValidationProvider
} from "@gannettdigital/shared-react-components";

interface IItem {
  id: string;
  title: string;
  uri: string;
}

interface IData {
  content: IItem[];
  pageNumber: number;
  pageSize: number;
  totalPages: number;
}

export interface IQAssetsModalProps {
  data: IData;
  defaultSelectedItems: IItem[];
  onSubmit: (_selectedItems: IItem[]) => void;
  onSearch: ({ keyword, filter }: { keyword: string; filter: string }) => void;
  onPageChange: ({ keyword, filter }: { keyword: string; filter: string }, page: number) => void;
  onChangeFilter?: ({ keyword, filter }: { keyword: string; filter: string }) => void;
  isLoading: boolean;
  displayOnly?: 'images' | 'videos';
  open: boolean,
  handleClose: (event) => any;
  resetSelectionOnClose?: boolean;
  showFilterDropdown?: boolean;
  descriptionText?: string;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '70vh',
  padding: '24px 24px 80px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
};

const ModalTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.info.dark};
  text-align: center;
  margin-bottom: 30px;

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, ${({ theme }) => theme.palette.success.main} 7%, ${({ theme }) => theme.palette.secondary.main} 78%);
    margin-top: 30px;
    display: block;
  }
`;

const ModalLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
`;

const PaginationStyled = styled(Pagination)`
  position: absolute;
  bottom: 20px;
  transform: translateX(-50%);
  left: 50%;
  width: 100%;

  & .MuiPagination-ul {
    justify-content: center;
  }

  & .MuiPaginationItem-root {
    color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 50%;
    min-width: 40px;
    height: 40px;
  }

  & .Mui-selected {
    border: 1px solid ${({ theme }) => theme.palette.text.secondary};
    color:  ${({ theme }) => theme.palette.text.primary};
  }

  & .MuiPaginationItem-ellipsis {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const FormContainer = styled('div')<{ isLoading: boolean }>`
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'default')};
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-end;
`;

const FormFilters = styled('div')`
  display: grid;
  grid-template-columns: 3fr 1.5fr 1fr;
  align-items: flex-end;
  gap: 16px;
`;

const FormSubmit = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const InputContainer = styled('div')`
  label {
    padding-bottom: ${(props) => props.theme.spacing(1)};
  }
`;

const SelectContainer = styled('div')<{ isHidden: boolean }>`
  ${({ isHidden }) => isHidden
    && css`
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      clip-path: inset(50%);
      border: 0;
    `}
`;

const AssetsSelected = styled('span')`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 16px;
  width: max-content;
`;

const SearchButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  background: transparent;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 14px;
  padding: 6.8px 20px;
  cursor: pointer;
  text-transform: unset;
`;

const CloseIconStyled = styled(CloseIcon)`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

const SubmitButton = styled(Button)`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${(props) => props.theme.palette.common.white};
  font-size: 14px;
  padding: 7.8px 28px;
  cursor: pointer;
  text-transform: unset;
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%);

  &:hover {
    background: ${({ theme }) => theme.palette.primary.main};
  }
`;

const menuItems = [
  {
    value: 'images',
    description: 'Images',
  },
  {
    value: 'videos',
    description: 'Videos',
  },
];

export const IQAssetsModal = ({
                                data,
                                defaultSelectedItems,
                                displayOnly,
                                isLoading,
                                onPageChange,
                                onSearch,
                                onSubmit,
                                onChangeFilter,
                                open,
                                handleClose,
                                descriptionText,
                                resetSelectionOnClose = true,
                                showFilterDropdown = true,
                              }: IQAssetsModalProps) => {
  const [selectedAssets, setSelectedAssets] = useState<IItem[]>(defaultSelectedItems);
  const [keyword, setKeyword] = useState<string>('');
  const theme = useTheme();
  const methods = useForm({
    mode: 'all',
    defaultValues: { keyword: '', filter: displayOnly || menuItems[0].value },
  });
  const { watch, setValue, resetField } = methods;
  const formValues = {
    keyword: watch('keyword') || '',
    filter: watch('filter') || '',
  };
  const { content, totalPages, pageNumber } = data;

  const handleSelect = (item: IItem) => {
    setSelectedAssets((prev) => {
      const isAlreadySelected = prev.map(({ id }) => id).includes(item.id);
      if (isAlreadySelected) return prev.filter(({ id }) => id !== item.id);
      return [...prev, item];
    });
  };

  const handleSubmit = () => {
    onSubmit(selectedAssets);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
          <SpinnerContainer>
            <IQLoadingSpinner />
          </SpinnerContainer>
      );
    }

    if (formValues.filter === menuItems[0].value) {
      return (
          <IQAssetsGrid
              data={content}
              selectedAssets={selectedAssets}
              handleSelect={handleSelect}
              columnCount={4}
              assetType="images"
          />
      );
    }
    return (
        <IQAssetsGrid
            data={content}
            selectedAssets={selectedAssets}
            handleSelect={handleSelect}
            columnCount={3}
            assetType="videos"
        />
    );
  };

  const renderTitle = () => {
    if (displayOnly === 'images') {
      return (
          <ModalTitle id="modal-modal-title" variant="h4">
            Select photos
          </ModalTitle>
      );
    }
    if (displayOnly === 'videos') {
      return (
          <ModalTitle id="modal-modal-title" variant="h4">
            Select videos
          </ModalTitle>
      );
    }
    return (
        <ModalTitle id="modal-modal-title" variant="h4">
          Select photos and videos
        </ModalTitle>
    );
  };

  const handleOnSearch = () => {
    // The keyword is saved in the state before the search
    setKeyword(formValues.keyword);
    onSearch(formValues);
  };

  const handleOnPageChange = (_, page: number) => {
    // Verify if the keyword in the input changed since the last search
    if(formValues.keyword !== keyword) {
      // Rectify search keyword before change the page
      setValue('keyword', keyword);
      onPageChange({...formValues, keyword}, page);
    } else {
      onPageChange(formValues, page);
    }
  };

  useEffect(() => () => {
    if (resetSelectionOnClose) {
      setSelectedAssets([]);
      setValue('keyword', '');

      if (formValues.filter) {
        resetField('filter');
      }
    }
  }, [open]);

  const labelText = displayOnly || 'images or videos';
  const filterMenuItems = displayOnly
      ? menuItems.filter((item) => item.value === formValues.filter)
      : menuItems;

  return (
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIconStyled onClick={handleClose} />
          <Box>
            {renderTitle()}
            <ModalLabel>
              {descriptionText || `Please work with your client to select 10-12 ${labelText} they would like to utilize on their website.`}
            </ModalLabel>
          </Box>
          <FormContainer isLoading={isLoading}>
            <ValidationProvider schema={schema}>
              <FormProvider {...methods}>
                <FormFilters>
                  <InputContainer>
                    <IQFormInput
                        adornmentIcon={<SearchIcon />}
                        id="getty-assets-input"
                        labelText="Keyword"
                        fontLabelWeight="600"
                        name="keyword"
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            handleOnSearch();
                          }
                        }}
                        theme={theme}
                    />
                  </InputContainer>
                  <SelectContainer isHidden={!showFilterDropdown}>
                    <IQFormSelect
                        fullWidth
                        spaceBottomMultiplier={1}
                        labelText="Filter"
                        fontLabelWeight="600"
                        items={filterMenuItems}
                        name="filter"
                        disabled={filterMenuItems.length === 1}
                    />
                  </SelectContainer>
                  <SearchButton type="button" onClick={handleOnSearch}>
                    Search
                  </SearchButton>
                </FormFilters>
                <FormSubmit>
                  <AssetsSelected>
                    {selectedAssets.length}
                    {' '}
                    Selected
                  </AssetsSelected>
                  <SubmitButton
                      onClick={handleSubmit}
                      startIcon={<CheckIcon sx={{ fontSize: '17px' }} />}
                      type="button"
                  >
                    Done
                  </SubmitButton>
                </FormSubmit>
              </FormProvider>
            </ValidationProvider>
          </FormContainer>
          {renderContent()}
          <PaginationStyled
              count={totalPages}
              disabled={isLoading}
              page={pageNumber}
              {...(onPageChange && { onChange: handleOnPageChange})}
          />
        </Box>
      </Modal>
  );
};

export default IQAssetsModal;