import {
  Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCapacityHeaders, selectCapacityStatuses } from 'services/adminSlice';

export default function CapacityHeaders() {
  const capacityStatuses = useSelector(selectCapacityStatuses);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCapacityHeaders({}));
  }, []);

  const formatDate = (value: Date) => {
    const dateValue = new Date(value);
    // eslint-disable-next-line max-len
    return `${dateValue.toLocaleString('default', { month: 'long' })} ${dateValue.getDate()}, ${dateValue.getFullYear()}`;
  };

  return (
    <>
      <Typography variant="h2">Website Fulfillment Capacity Status</Typography>
      <Grid container>
        <Grid item xs={12} marginTop={1}>
          <Link to="/admin/capacityHeaders/new" style={{ marginRight: '10px' }}>
            <Button variant="contained">
              <Typography>New Status</Typography>
            </Button>
          </Link>
          <Link to="/admin/dashboard">
            <Button variant="contained">
              <Typography>Admin Dashboard</Typography>
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} marginTop={5}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ borderBottom: '3px solid lightgray' }}>
                  <TableCell><Typography fontWeight="bold">ID</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Product</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Last Update</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Number of Days to Start</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Weeks to Complete</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Comments / Remarks</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {capacityStatuses.map(status => (
                  <TableRow
                    key={status.id}
                    sx={{
                      border: 0,
                      '& .MuiTableCell-root': { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Typography>
                        {status.id}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {status.product}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatDate(status.updatedAt)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {status.days}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {status.weeks}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {status.remarks}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
