import ContentLoader from 'components/contentLoader/ContentLoader';
import { useDispatch, useSelector } from 'react-redux';
import { getWebsite, selectWebsites } from 'services/websitesSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import { Urls } from 'navigation/Urls';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch } from 'services/redux/store-schedule';
import { getProduct } from 'services/productsSlice';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import { getThemeById } from 'services/themesSlice';
import ThemeSelectionForm from './ThemeSelectionForm';

export const mapProduct = {
  Website: 'website - standard',
  GoldSite: 'website - gold',
  LandingPage: 'website - landing page',
};

export default function ThemeSelection() {
  const { orderItemId } = useParams();
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const topNav = useTopnavMenu(Urls.Theme);
  const offeringType = useSelector(selectActiveOfferingType);
  const websiteId = useSelector(selectActiveProductId);
  const website = useSelector(selectWebsites);
  const [data, setData] = useState({});
  const [status, setStatus] = useState('notStartedLoading');

  const onBack = () => {
    navigate.to(topNav.previousStep());
  };

  useEffect(() => {
    if (offeringType) {
      if (offeringType === 'LandingPage') {
        appDispatch(getProduct({ orderItemId })).then((res) => {
          setData(res.payload?.data);
          setStatus('idle');
        });
      } else if (websiteId && !website?.content?.id) dispatch(getWebsite({ websiteId, offeringType }));
    }
  }, [offeringType, websiteId]);

  useEffect(() => {
    if (website?.content?.id) {
      appDispatch(getThemeById(website.content.themeId)).then((res) => {
        setData({ themeDesign: res.payload.title });
        setStatus('idle');
      });
    }
  }, [website]);

  return (
    <ContentLoader status={[website.status, status]} message={website.message}>
      <ThemeSelectionForm
        website={website.content}
        onBack={onBack}
        product={mapProduct[offeringType]}
        data={data}
      />
    </ContentLoader>
  );
}
