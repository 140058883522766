import * as yup from 'yup';
import i18n from 'i18n/i18n';

const {t} = i18n;

const schema = {
  originalNumber: yup.string().required(t('pages.xmo.solutionsStrategy.trackingLine.originalNumberErrorMessage')),
  forwardToNumber: yup.string().required(t('pages.xmo.solutionsStrategy.trackingLine.forwardToNumberErrorMessage')),
  areaCodePreference: yup.string().required(t('pages.xmo.solutionsStrategy.trackingLine.areaCodePreferenceErrorMessage')),
};

export default {
  yupValidations: schema,
  customValidations: {},
};