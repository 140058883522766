// for testing maintenance mode updates
const formatDateAndTime = (date) => {
  const formattedDate = Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZoneName: 'short',
    formatMatcher: 'best fit',
  }).format(date);
  return formattedDate;
};

export default formatDateAndTime;
