import React, {useState} from "react";
import {Box} from "@mui/system";
import {Alert, Button, Grid, Typography} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import GridContainer from "components/grid/GridContainer";
import GridItem from "components/grid/GridItem";
import {IQFormInput, IQTheme} from "@gannettdigital/shared-react-components";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {createCEFObject} from "pages/admin/cef-object-creator/CefObjectCreatorApi";
import {cefObjectCreatorSchema} from "pages/admin/cef-object-creator/CefObjectCreatorSchema";
import ContentLoader from "components/contentLoader/ContentLoader";
import {t} from "i18next";

export default function CefObjectCreator() {
  const [loader, setLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const methods = useForm({
    defaultValues: {
      opportunityId: '',
      accountId: '',
      name: '',
      campaignEntryFormURL: '',
    },
    mode: 'onChange',
    resolver: yupResolver(cefObjectCreatorSchema),
  });

  const {
    handleSubmit,
    trigger,
    reset,
    formState: {isValid, isSubmitting, isDirty},
  } = methods;

  const onSubmit = async (data) => {
    setLoader(true);
    const response = await createCEFObject(data);
    setLoader(false);
    if (response?.status === 200) {
      setSuccessMessage(response?.data);
      setErrorMessage("");
      reset();
    } else {
      setSuccessMessage("");
      setErrorMessage(response?.data);
    }
  };

  const handleBlur = async (fieldName: any) => {
    await trigger(fieldName);
  };

  return (
      <ContentLoader status={loader ? 'loading' : 'idle'}>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="80vh"
        >
          <Grid
              container
              direction="column"
              spacing={2}
              p={3}
              bgcolor="white"
              boxShadow={3}
              borderRadius={2}
              maxWidth={600}
          >
            {successMessage &&
                <Alert severity="success"
                       sx={{
                         mb: 2,
                         backgroundColor: (theme) => theme.palette.success.dark,
                         color: 'white'
                       }}
                >
                  {successMessage}
                </Alert>
            }
            {errorMessage &&
                <Alert severity="info"
                       sx={{
                         mb: 2,
                         backgroundColor: (theme) => theme.palette.error.main,
                         color: 'white'
                       }}
                >
                  {errorMessage}
                </Alert>
            }
            <Grid item mb={5}>
              <Typography
                  sx={{textAlign: "center"}}
                  fontWeight={600}
                  variant="h4"
                  gutterBottom
              >
                {t('cefObjectCreator.cefObjectCreatorPageTitle')}
              </Typography>
            </Grid>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <GridContainer fullWidth>
                  <GridItem sizes={[12]}>
                    <IQFormInput
                        theme={IQTheme}
                        fullWidth
                        fontLabelWeight="600"
                        name="opportunityId"
                        id="opportunityId"
                        labelText={t('cefObjectCreator.opportunityIdLabel')}
                        onBlur={() => handleBlur("opportunityId")}
                    />
                  </GridItem>
                  <GridItem sizes={[12]}>
                    <IQFormInput
                        theme={IQTheme}
                        fullWidth
                        fontLabelWeight="600"
                        name="accountId"
                        id="accountId"
                        labelText={t('cefObjectCreator.accountIdLabel')}
                        onBlur={() => handleBlur("accountId")}
                    />
                  </GridItem>
                  <GridItem sizes={[12]}>
                    <IQFormInput
                        theme={IQTheme}
                        fullWidth
                        fontLabelWeight="600"
                        name="name"
                        id="name"
                        labelText={t('cefObjectCreator.cefNameLabel')}
                        onBlur={() => handleBlur("name")}
                    />
                  </GridItem>
                  <GridItem sizes={[12]}>
                    <IQFormInput
                        theme={IQTheme}
                        fullWidth
                        fontLabelWeight="600"
                        name="campaignEntryFormURL"
                        id="campaignEntryFormURL"
                        labelText={t('cefObjectCreator.cefUrlLabel')}
                        adornmentIcon={<InsertLinkIcon/>}
                        onBlur={() => handleBlur("campaignEntryFormURL")}
                    />
                  </GridItem>
                  <GridItem sizes={[12]}
                            sx={{display: "flex", justifyContent: "center", marginBottom: 0}}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!isValid || !isDirty || isSubmitting}
                        sx={{
                          '&.Mui-disabled': {
                            color: 'white',
                          },
                        }}
                    >
                      {t('cefObjectCreator.submitButtonLabel')}
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            </FormProvider>
          </Grid>
        </Box>
      </ContentLoader>
  );
}