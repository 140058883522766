/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import AxiosAPI from './common/axios';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { thunkBuilder } from './common/functions';

export interface CefState {
  opportunity: StatusType<any>
  currentOffer: StatusType<any>
  currentOpportunityTab: StatusType<number>
  opportunityContact: StatusType<any>
  notesAndAttachments: StatusType<any>
  partnerAccount: StatusType<any>
  campaignBusinessUsers: StatusType<any>
}

const initialState: CefState = {
  opportunity: {
    status: 'idle',
    message: '',
    content: {},
  },
  currentOffer: {
    status: 'idle',
    message: '',
    content: {},
  },
  currentOpportunityTab: {
    status: 'idle',
    message: '',
    content: 0,
  },
  opportunityContact: {
    status: 'idle',
    message: '',
    content: {},
  },
  notesAndAttachments: {
    status: 'idle',
    message: '',
    content: {},
  },
  partnerAccount: {
    status: 'idle',
    message: '',
    content: {},
  },
  campaignBusinessUsers: {
    status: 'idle',
    message: '',
    content: [],
  },
};

export const getOpportunity = createAsyncThunkCatchErrors(
  'cef/getOpportunity',
  async (opportunityId: string | number) => {
    const response = await AxiosAPI.get(`/integrations/force/opportunity/${opportunityId}`);
    return response.data;
  },
);

export const getCaseDetails = createAsyncThunkCatchErrors(
  'cef/getCaseDetails',
  async (caseId: string | number) => {
    const response = await AxiosAPI.get(`/integrations/force/blue/${caseId}`);
    return response.data;
  },
);

export const getCurrentOffer = createAsyncThunkCatchErrors(
  'cef/currentOffer',
  async (orderItemId: string | number) => {
    const response = await AxiosAPI.get(`/orders/items/${orderItemId}/salesforce_offer_details`);
    return response.data;
  },
);

export const getSalesforceContact = createAsyncThunkCatchErrors(
  'cef/getSalesforceContact',
  async ({ opportunityId, isBlue } : { opportunityId: string, isBlue: boolean }) => {
    const response = await AxiosAPI.get(`/integrations/force/contacts/${opportunityId}?isBlue=${isBlue}`);
    return response.data;
  },
);

export const getNotesAndAttachments = createAsyncThunkCatchErrors(
  'cef/getNotesAndAttachments',
  async (orderItemId: number | string) => {
    const response = await AxiosAPI.get(`/integrations/force/orderitem/${orderItemId}/attachments`);
    return response.data;
  },
);

export const getPartnerAccountDetails = createAsyncThunkCatchErrors(
  'cef/getPartnerAccountDetails',
  async (accountId: string | number) => {
    const response = await AxiosAPI.get(`/integrations/force/account/${accountId}`);
    return response.data;
  },
);

export const getCampaignBusinessUsers = createAsyncThunkCatchErrors(
  'generalQuestions/getCampaignBusinessUsers',
  async (gmaid: string) => {
    const response = await AxiosAPI.get(`/business-users?globalMasterAdvertiserId=${gmaid}`);
    return response.data;
  },
);

export const setCurrentOpportunityTab = createAction<number>('cef/setCurrentTab');
export const setOpportunity = createAction<any>('cef/setOpportunity');

export const cefSlice = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setCurrentOpportunityTab, (state, { payload }) => {
        state.currentOpportunityTab.content = payload;
      })
      .addCase(setOpportunity, (state, { payload }) => {
        state.opportunity.content = payload;
      });

    thunkBuilder(builder)
      .addCase(getOpportunity, 'opportunity')
      .addCase(getCaseDetails, 'opportunity')
      .addCase(getCurrentOffer, 'currentOffer')
      .addCase(getSalesforceContact, 'opportunityContact')
      .addCase(getNotesAndAttachments, 'notesAndAttachments')
      .addCase(getPartnerAccountDetails, 'partnerAccount')
      .addCase(getCampaignBusinessUsers, 'campaignBusinessUsers');
  },
);

export const selectOpportunity = (state: RootState) => state.cef.opportunity.content;
export const selectOpportunityStatus = (state: RootState) => state.cef.opportunity.status;
export const selectCurrentOffer = (state: RootState) => state.cef.currentOffer.content;
export const selectCurrentOpportunityTab = (state: RootState) => state.cef.currentOpportunityTab.content;
export const selectOpportunityContact = (state: RootState) => state.cef.opportunityContact.content;
export const selectNotesAndAttachments = (state: RootState) => state.cef.notesAndAttachments.content;
export const selectErrorMessage = (state: RootState) => state.cef.opportunity.content.errorMessage;
export const selectPartnerAccountDetails = (state: RootState) => state.cef.partnerAccount.content;
export const selectCampaignBusinessUsers = (state: RootState) => state.cef.campaignBusinessUsers.content;

export default cefSlice;
