import OrderEditLayout from 'layouts/OrderEditLayout';
import { Outlet } from 'react-router-dom';

export default function GenericProductDetailsMenu() {
  return (
    <OrderEditLayout>
      <Outlet />
    </OrderEditLayout>
  );
}
