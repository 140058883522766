import { createEpicMiddleware } from 'redux-observable';
import {
  Action, applyMiddleware, configureStore, ThunkAction,
} from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { useDispatch } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './root-reducer';
import rootEpic from './root-epic';

export const history = createBrowserHistory();

const vanillaPromise = store => next => action => {
  if (typeof action.then !== 'function') {
    return next(action);
  }
  return Promise.resolve(action).then(store.dispatch);
};

const epicMiddleware = createEpicMiddleware({
  dependencies: {
    history,
  },
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const store = configureStore({
  reducer: persistedReducer,
  preloadedState: {},
  enhancers: [applyMiddleware(epicMiddleware, vanillaPromise)],
});

const persistor = persistStore(store);

epicMiddleware.run(rootEpic);

export { store, persistor };

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;