import { IQFormInput, ValidationProvider } from '@gannettdigital/shared-react-components';
import {
  Autocomplete,
  Button, Chip, FormControlLabel, Grid, MenuItem, Select, TextField, Typography, useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import {
  getBundleById, selectCurrentBundleProducts,
  selectCurrentBundle, selectCurrentBundleAddOns,
  getAllAddOns, getAllGoldThemes, getAllThemes, selectAllAddOns,
  selectAllThemes, selectAllGoldThemes, getBundleItems,
} from 'services/adminSlice';

interface Props {
  onSubmit: (data: any) => void
  bundleId: number
}

const schemaValidation = {
  title: yup.string().required('Field is required'),
  themeId: yup.number().required('Is required').positive().integer()
    .min(1, 'Must select from the list'),
};

export default function BundleForm({ bundleId, onSubmit }: Props) {
  const theme = useTheme();
  const allThemes = useSelector(selectAllThemes);
  const allAddOns = useSelector(selectAllAddOns);
  const allGoldThemes = useSelector(selectAllGoldThemes);
  const currentBundle = useSelector(selectCurrentBundle);
  const currentBundleProducts = useSelector(selectCurrentBundleProducts);
  const currentBundleAddOns = useSelector(selectCurrentBundleAddOns);
  const dispatch = useDispatch();

  useEffect(() => {
    if (bundleId > 0) {
      dispatch(getBundleById(bundleId));
    }
  }, [bundleId]);

  const methods = useForm({
    defaultValues: {
      title: null, themeId: null, bundleAddons: [], bundleProducts: [],
    },
  });

  const {
    control, handleSubmit, setValue, watch, formState: { isValid },
  } = methods;
  const themeIdValue = watch('themeId');

  useEffect(() => {
    dispatch(getAllAddOns({}));
    dispatch(getAllGoldThemes({}));
    dispatch(getAllThemes({}));
  }, []);

  useEffect(() => {
    dispatch(getBundleItems(currentBundle.id));
  }, [currentBundle]);

  useEffect(() => {
    if (currentBundle.id !== 0) {
      setValue('title', currentBundle.name);
      setValue('themeId', currentBundle.mainThemeId);
      setValue('bundleAddons', currentBundleAddOns);
      setValue('bundleProducts', currentBundleProducts);
    }
  }, [currentBundle, currentBundleAddOns, currentBundleProducts]);

  const schema = {
    yupValidations: schemaValidation,
    customValidations: {},
  };

  const getAddOnLabel = (id) => {
    const item = allAddOns.find(i => i.themeId === id);
    return item ? item.label : 'n/a';
  };

  const getProductLabel = (id) => {
    const item = allThemes.find(i => i.themeId === id);
    return item ? item.label : 'n/a';
  };

  return (
    <Grid container>
      <FormProvider {...methods}>
        <ValidationProvider schema={schema}>
          <Grid item xs={8} marginTop={3}>
            <IQFormInput
              labelText="Name"
              id="title"
              name="title"
              theme={theme}
              schema={schema}
              fullWidth
              showError
            />
          </Grid>
          <Grid item xs={8} marginTop={3}>
            <FormControlLabel
              sx={{ marginLeft: 0, alignItems: 'flex-start', width: '100%' }}
              label={<Typography marginBottom={1}>Main Theme</Typography>}
              labelPlacement="top"
              control={(
                <Controller
                  name="themeId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      sx={{ width: '100%' }}
                      onChange={onChange}
                      value={value}
                    >
                      {allGoldThemes.map(theme => (
                        <MenuItem key={theme.themeId} value={theme.themeId}>
                          <Typography>{theme.label}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={8} marginTop={3}>
            <FormControlLabel
              sx={{ marginLeft: 0, alignItems: 'flex-start', width: '100%' }}
              label={<Typography marginBottom={2}>Bundle Addons</Typography>}
              labelPlacement="top"
              control={(
                <Controller
                  name="bundleAddons"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      sx={{ width: '100%' }}
                      multiple
                      options={allAddOns}
                      getOptionLabel={(option) => option.label || ''}
                      onChange={(event, value) => onChange(value)}
                      isOptionEqualToValue={(option, val) => option.themeId === val.themeId}
                      value={value}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip key={option.themeId} variant="outlined" label={getAddOnLabel(option.themeId)}
                            {...getTagProps({ index })}
                          />
                        ))}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                        />
                      )}
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          {getAddOnLabel(option.themeId)}
                        </li>
                      )}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={8} marginTop={3}>
            <FormControlLabel
              sx={{ marginLeft: 0, alignItems: 'flex-start', width: '100%' }}
              label={<Typography marginBottom={2}>Bundle Products</Typography>}
              labelPlacement="top"
              control={(
                <Controller
                  name="bundleProducts"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      sx={{ width: '100%' }}
                      multiple
                      options={allThemes}
                      getOptionLabel={(option) => option.label || ''}
                      onChange={(event, value) => onChange(value)}
                      isOptionEqualToValue={(option, val) => option.themeId === val.themeId}
                      value={value}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip key={option.themeId} variant="outlined" label={getProductLabel(option.themeId)}
                            {...getTagProps({ index })}
                          />
                        ))}
                      renderOption={(props, option, index) => {
                        const key = `listItem-${index}-${option.themeId}`;
                        return (
                          <li {...props} key={key}>
                            {getProductLabel(option.themeId)}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </ValidationProvider>
      </FormProvider>
      <Grid item xs={12} marginTop={3}>
        <Button variant="contained"
          disabled={!isValid || themeIdValue < 1}
          sx={{ marginRight: 3 }}
          onClick={handleSubmit(onSubmit)}
        >
          <Typography>Save</Typography>
        </Button>
        <Link to="/admin/bundles">
          <Button variant="contained">
            <Typography>Back to Bundles</Typography>
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}
