import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridRowId,
  GridRowModel,
} from '@mui/x-data-grid';
import CoModalPageCard from 'components/pageCard/CoModalPageCard';
import OfferEditForm from 'pages/admin/manage_offers/OfferEditForm';
import { useEffect, useState } from 'react';
import {
  Alert, AlertTitle, IconButton, Paper, Typography, Box,
} from '@mui/material';
import { backgroundDefaultColor } from 'styles/common_styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  createOrUpdateProductToOfferMapping, deleteProductToOfferMapping,
  getAllOfferMappings,
  getAllProductTypes,
  getAllRLOffers,
  selectAllOfferMappings,
  selectAllProductTypes,
  selectAllRLOffers,
} from 'services/offerMappingSlice';
import { selectCurrentCountry } from 'services/topNavMenuSlice';
import { useAppDispatch } from 'services/redux/store-schedule';

const defaultAlert = {
  show: false,
  title: '',
  message: '',
};

export default function ManageRLOffers() {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [rows, setRows] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [activeOffer, setActiveOffer] = useState(null);
  const [alert, setAlert] = useState(defaultAlert);
  const allProductTypes = useSelector(selectAllProductTypes);
  const allRLOffers = useSelector(selectAllRLOffers);
  const allMappings = useSelector(selectAllOfferMappings);
  const selectedCountry = useSelector(selectCurrentCountry);

  const handleEdit = (selectedOffer: GridRowModel) => {
    setActiveOffer(selectedOffer);
    setOpenEditModal(true);
  };

  const handleDelete = (id: GridRowId) => {
    appDispatch(deleteProductToOfferMapping(id))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(res => dispatch(getAllOfferMappings({ countryId: selectedCountry?.id })));
  };

  const closeEditModal = () => setOpenEditModal(false);

  const updateRecord = (data) => {
    const newData = { ...data };
    newData.countryId = selectedCountry?.id || 1;

    const isDuplicate = allMappings
      .filter(mapping => (mapping.offerId === newData.offerId && mapping.neonProductName === newData.offeringType))
      .length > 0;
    if (isDuplicate) {
      setAlert({
        show: true,
        title: 'Offer Mapping already exists',
        message: 'Try again with different product and offer.',
      });
      closeEditModal();
      return;
    }
    appDispatch(createOrUpdateProductToOfferMapping(newData))
      .then(res => {
        const success = res.meta.requestStatus === 'fulfilled';
        setAlert({
          show: true,
          title: success
            ? 'Success'
            : 'Something went wrong',
          message: success
            ? 'Offer mapping created/updated successfully.'
            : 'Offer mapping could not be created/updated.',
        });
      });
    closeEditModal();
  };

  useEffect(() => {
    if (alert.show) {
      setTimeout(() => setAlert(defaultAlert), 5000);
    }
  }, [alert]);

  const handleNewClick = () => {
    const newObj = {
      neonProductName: null,
      offerId: null,
    };
    setActiveOffer(newObj);
    setOpenEditModal(true);
  };

  const customToolbar = () => (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleNewClick}>
        Add New Offer Mapping
      </Button>
    </GridToolbarContainer>
  );

  useEffect(() => {
    dispatch(getAllProductTypes({}));
    dispatch(getAllOfferMappings({ countryId: selectedCountry?.id }));
  }, [allRLOffers, selectedCountry]);

  useEffect(() => {
    if (selectedCountry?.id) {
      dispatch(getAllRLOffers({ countryId: selectedCountry?.id }));
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (allMappings.length > 0 && allProductTypes.length > 0) {
      const productNameMap = Object.fromEntries(allProductTypes.map(p => [p.offeringType, p.name]));

      const offerMappings = allMappings.slice().map(item => ({
        ...item,
        productName: productNameMap[item.neonProductName] || item.neonProductName,
      }));
      setRows(offerMappings);
    }
  }, [allMappings, allProductTypes]);

  const columns: GridColDef[] = [
    {
      field: 'productName',
      headerName: 'Product Type',
      width: 250,
    },
    {
      field: 'name',
      headerName: 'RL Offer Name',
      width: 550,
    }, {
      field: 'offerId',
      headerName: 'RL Offer Id',
      width: 220,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleEdit(params.row)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),

    },
  ];

  return (
    <Paper elevation={0} sx={{ backgroundColor: backgroundDefaultColor }}>
      {alert.show && (
        <Box
          marginBottom={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Alert
            severity={alert.title === 'Success' ? 'success' : 'error'}
            sx={{
              border: alert.title === 'Success' ? '1px solid #DFF0D8' : '1px solid #F5D5D7',
              background: alert.title === 'Success' ? '#DFF0D8' : '#F5D5D7',
              borderRadius: '4px',
              textAlign: 'center',
              color: alert.title === 'Success' ? '#3C763D' : '#C20F1E',
              padding: '10px 80px',
              '& .MuiAlert-icon': { color: alert.title === 'Success' ? '#3C763D' : '#C20F1E', marginRight: '40px' },
            }}
          >
            <AlertTitle>{alert.title}</AlertTitle>
            {alert.message}
          </Alert>
        </Box>
      )}
      <Typography variant="h2" mb={3}>Manage RL Offers</Typography>
      <Box
        sx={{
          height: '50%',
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          slots={{
            toolbar: customToolbar,
          }}
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
        />

        <CoModalPageCard title="Product offer mapping" isOpen={openEditModal} onClose={closeEditModal}>
          <OfferEditForm offer={activeOffer} onSave={updateRecord} offers={allRLOffers}
            productTypes={allProductTypes}
          />
        </CoModalPageCard>
      </Box>
    </Paper>
  );
}
