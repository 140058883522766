/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import { validateEmail } from 'pages/generic-product/details/custom-fields/EmailTextField';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';

const { t } = i18n;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DisplayCreativeServiceSchema: RJSFSchema | any = (data) => ({
  title: t('pages.smm.solutionStrategy.title'),
  // eslint-disable-next-line max-len
  type: 'object',
  required: ['reachOutForReview', 'creativePackage', 'salesTeam', 'automotiveCoOpGuidelines'],
  properties: {
    reachOutForReview: {
      type: 'string',
      title: t('pages.displayCreativeService.reachOutForReview'),
      default: '',
      enum: [
        'Client',
        'Sales Rep',
        'Client & Sales Rep',
      ],
    },
    creativePackage: {
      type: 'string',
      title: t('pages.displayCreativeService.creativePackage'),
      default: '',
      items: {
        enum: [
          t('pages.displayCreativeService.creativeOption1'),
          t('pages.displayCreativeService.creativeOption2'),
          t('pages.displayCreativeService.creativeOption3'),
          t('pages.displayCreativeService.creativeOption4'),
        ],
      },
    },
    salesTeam: {
      type: 'string',
      title: t('pages.displayCreativeService.salesTeam'),
      default: t('pages.displayCreativeService.other'),
      items: {
        enum: [
          t('pages.displayCreativeService.other'),
        ],
      },
      description: t('pages.displayCreativeService.servicesOnly'),
    },
    verticalMarketOrIndustry: {
      type: 'string',
      title: t('pages.displayCreativeService.verticalMarketOrIndustry'),
      default: '',
      enum: [
        '',
        'Non-Standard Printing',
        'Auto Dealers',
        'Auto Services',
        'Employment',
        'Grocery',
        'Real Estate',
        'Agriculture',
        'Bars',
        'Boating/Marine',
        'Casinos',
        'Charity',
        'Church',
        'Clothing',
        'Computer Equipment/Service',
        'Education',
        'Entertainment',
        'Events',
        'Financial',
        'Fitness/Wellness',
        'Florist',
        'Funeral Services',
        'Furniture',
        'Government',
        'Healthcare/Medical',
        'Home Builder',
        'Home Repair/Improvement',
        'Hotels',
        'Insurance',
        'Jewelry',
        'Lawn & Garden Centers',
        'Legal',
        'Liquor',
        'Lottery',
        'Professional Services',
        'Restaurants',
        'Salon/Spa/Beauty',
        'Seniors/Assisted Living',
        'Sporting Goods',
        'Sports',
        'Storage Units',
        'Transportation & Logistics',
        'Travel',
        'Other',
      ],
    },
    manualPickupOfAnyElements: {
      type: 'string',
      title: t('pages.displayCreativeService.manualPickupOfAnyElements'),
      default: '',
      items: {
        enum: [
          t('pages.generic-translation.yesOption'),
          t('pages.generic-translation.noOption'),
        ],
      },
    },
    levelOfCreativityOrArtisticDiscretion: {
      type: 'string',
      title: t('pages.displayCreativeService.levelOfCreativityOrArtisticDiscretion'),
      default: '',
      enum: [
        '',
        'Template Based Ad',
        'Some Creative Freedom Needed',
        'Be Creative, Apply Data Informed Design',
      ],
    },
    templateNameForReference: {
      type: 'string',
      title: t('pages.displayCreativeService.templateNameForReference'),
      default: '',
    },
    itemsToFocus: {
      type: 'string',
      title: t('pages.displayCreativeService.itemsToFocus'),
      default: '',
    },
    itemsToDescribeTheAdFeel: {
      type: 'string',
      title: t('pages.displayCreativeService.itemsToDescribeTheAdFeel'),
      default: '',
      enum: [
        '',
        'Modern and stylish',
        'High-end and exclusive',
        'Whimsical and fun',
        'Rugged and strong',
        'Delicate and fancy',
        'Friendly and inviting',
        'Minimal and modest',
      ],
    },
    targetAudience: {
      type: 'string',
      title: t('pages.displayCreativeService.targetAudience'),
      default: '',
    },
    goalOfTheAd: {
      type: 'string',
      title: t('pages.displayCreativeService.goalOfTheAd'),
      default: '',
    },
    automotiveCoOpGuidelines: {
      type: 'string',
      title: t('pages.displayCreativeService.automotiveCoOpGuidelines'),
      default: '',
      items: {
        enum: [
          t('pages.generic-translation.yesOption'),
          t('pages.generic-translation.noOption'),
        ],
      },
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.displayCreativeService.notesOrInstructions'),
      default: '',
    },
  },
  dependencies: {
    reachOutForReview: {
      oneOf: [
        {
          properties: {
            reachOutForReview: {
              enum: ['Sales Rep'],
            },
            emailForCreativeApprovalNotifications: {
              type: 'string',
              title: t('pages.displayCreativeService.emailForCreativeApprovalNotifications'),
              default: '',
              description: t('pages.displayCreativeService.emailForApprovalNotificationsDescription'),
            },
          },
          required: ['emailForCreativeApprovalNotifications'],
        },
        {
          properties: {
            reachOutForReview: {
              enum: ['Client', 'Client & Sales Rep'],
            },
            clientContactFirstName: {
              type: 'string',
              title: t('pages.displayCreativeService.clientContactFirstName'),
            },
            clientContactLastName: {
              type: 'string',
              title: t('pages.displayCreativeService.clientContactLastName'),
            },
            clientContactPhone: {
              type: 'string',
              title: t('pages.displayCreativeService.clientContactPhone'),
            },
            clientContactEmail: {
              type: 'string',
              title: t('pages.displayCreativeService.clientContactEmail'),
            },
            bestNumberToReachYou: {
              type: 'string',
              title: t('pages.displayCreativeService.bestNumberToReachYou'),
            },
          },
          required: ['clientContactFirstName', 'clientContactLastName', 'clientContactPhone', 'clientContactEmail'],
        },
      ],
    },
    creativePackage: {
      oneOf: [
        {
          properties: {
            creativePackage: {
              enum: ['Premium'],
            },
            creativePackageDescription: {
              type: 'string',
              title: t('pages.displayCreativeService.creativePackageDescriptionPremium'),
            },
            wikiOrResourceForReference: {
              type: 'string',
              title: t('pages.displayCreativeService.wikiOrResourceForReference'),
              default: '',
            },
            staticOrAnimated: {
              type: 'string',
              title: t('pages.displayCreativeService.staticOrAnimated'),
              default: '',
              enum: [
                'Static',
                'Animated',
              ],
              description: t('pages.displayCreativeService.staticOrAnimatedDescription'),
            },
            clickThroughUrl: {
              type: 'string',
              title: t('pages.displayCreativeService.clickThroughUrl'),
              default: '',
            },
            typeOfImagery: {
              type: 'string',
              title: t('pages.displayCreativeService.typeOfImagery'),
              default: '',
            },
            describeImageryAndMessage: {
              type: 'string',
              title: t('pages.displayCreativeService.describeImageryAndMessage'),
              default: '',
            },
          },
          required: ['staticOrAnimated', 'clickThroughUrl', 'typeOfImagery', 'describeImageryAndMessage'],
        },
        {
          properties: {
            creativePackage: {
              enum: ['Standard'],
            },
            creativePackageDescription: {
              type: 'string',
              title: t('pages.displayCreativeService.creativePackageDescriptionStandard'),
            },
            wikiOrResourceForReference: {
              type: 'string',
              title: t('pages.displayCreativeService.wikiOrResourceForReference'),
              default: '',
            },
            staticOrAnimated: {
              type: 'string',
              title: t('pages.displayCreativeService.staticOrAnimated'),
              default: '',
              enum: [
                'Static',
                'Animated',
              ],
              description: t('pages.displayCreativeService.staticOrAnimatedDescription'),
            },
            clickThroughUrl: {
              type: 'string',
              title: t('pages.displayCreativeService.clickThroughUrl'),
              default: '',
            },
            typeOfImagery: {
              type: 'string',
              title: t('pages.displayCreativeService.typeOfImagery'),
              default: '',
            },
            describeImageryAndMessage: {
              type: 'string',
              title: t('pages.displayCreativeService.describeImageryAndMessage'),
              default: '',
            },
          },
          required: ['staticOrAnimated', 'clickThroughUrl', 'typeOfImagery', 'describeImageryAndMessage'],
        },
        {
          properties: {
            creativePackage: {
              enum: ['Dual Message'],
            },
            imageryUsedForCreativePackageA: {
              type: 'string',
              title: t('pages.displayCreativeService.imageryUsedForCreativePackageA'),
              default: '',
            },
            messageOrPromotionUsedForCreativePackageA: {
              type: 'string',
              title: t('pages.displayCreativeService.messageOrPromotionUsedForCreativePackageA'),
              default: '',
            },
            clickThroughForCreativePackageA: {
              type: 'string',
              title: t('pages.displayCreativeService.clickThroughForCreativePackageA'),
              default: '',
            },
            imageryUsedForCreativePackageB: {
              type: 'string',
              title: t('pages.displayCreativeService.imageryUsedForCreativePackageB'),
              default: '',
            },
            messageOrPromotionUsedForCreativePackageB: {
              type: 'string',
              title: t('pages.displayCreativeService.messageOrPromotionUsedForCreativePackageB'),
              default: '',
            },
            clickThroughForCreativePackageB: {
              type: 'string',
              title: t('pages.displayCreativeService.clickThroughForCreativePackageB'),
              default: '',
            },
          },
        },
        {
          properties: {
            creativePackage: {
              enum: ['Performance Max (PMAX) Pilot ONLY'],
            },
            wikiOrResourceForReference: {
              type: 'string',
              title: t('pages.displayCreativeService.wikiOrResourceForReference'),
              default: '',
            },
            staticOrAnimated: {
              type: 'string',
              title: t('pages.displayCreativeService.staticOrAnimated'),
              default: '',
              enum: [
                'Static',
                'Animated',
              ],
              description: t('pages.displayCreativeService.staticOrAnimatedDescription'),
            },
            clickThroughUrl: {
              type: 'string',
              title: t('pages.displayCreativeService.clickThroughUrl'),
              default: '',
            },
            typeOfImagery: {
              type: 'string',
              title: t('pages.displayCreativeService.typeOfImagery'),
              default: '',
            },
            describeImageryAndMessage: {
              type: 'string',
              title: t('pages.displayCreativeService.describeImageryAndMessage'),
              default: '',
            },
          },
          required: ['staticOrAnimated', 'clickThroughUrl', 'typeOfImagery', 'describeImageryAndMessage'],
        },
      ],
    },
  },
});

const textAreaKeys = [
  'templateNameForReference',
  'itemsToFocus',
  'describeImageryAndMessage',
  'notesOrInstructions',
  'emailForCreativeApprovalNotifications',
];

const dropdownFieldKeys = [
  'verticalMarketOrIndustry',
  'levelOfCreativityOrArtisticDiscretion',
  'itemsToDescribeTheAdFeel',
];

const textFieldKeys = [
  'targetAudience',
  'goalOfTheAd',
  'wikiOrResourceForReference',
  'typeOfImagery',
  'clientContactFirstName',
  'clientContactLastName',
  'imageryUsedForCreativePackageA',
  'messageOrPromotionUsedForCreativePackageA',
  'imageryUsedForCreativePackageB',
  'messageOrPromotionUsedForCreativePackageB',
];

const radioFieldKeys = [
  'creativePackage',
  'salesTeam',
  'manualPickupOfAnyElements',
  'automotiveCoOpGuidelines',
];

const textLabelKeys = [
  'creativePackageDescription',
];

const phoneNumberTextKeys = [
  'clientContactPhone',
  'bestNumberToReachYou',
];

const urlTextKeys = [
  'clickThroughUrl',
  'clickThroughForCreativePackageA',
  'clickThroughForCreativePackageB',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'radioButton' },
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const textAreaFields = textAreaKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const textLabelFields = textLabelKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textLabel' },
}), {});

const phoneNumberTextFields = phoneNumberTextKeys
  .reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'phoneNumberTextField' } }), {});

const urlTextFields = urlTextKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'UrlTextWidget' },
}), {});

export const DisplayCreativeServiceUISchema = {
  'ui:order': ['reachOutForReview', 'clientContactFirstName', 'clientContactLastName', 'clientContactPhone',
    'clientContactEmail', 'bestNumberToReachYou', 'emailForCreativeApprovalNotifications', 'creativePackage',
    'creativePackageDescription', 'salesTeam', 'verticalMarketOrIndustry', 'manualPickupOfAnyElements',
    'levelOfCreativityOrArtisticDiscretion', 'templateNameForReference', 'itemsToFocus', 'itemsToDescribeTheAdFeel',
    'targetAudience', 'goalOfTheAd', 'imageryUsedForCreativePackageA', 'messageOrPromotionUsedForCreativePackageA',
    'clickThroughForCreativePackageA', 'imageryUsedForCreativePackageB', 'messageOrPromotionUsedForCreativePackageB',
    'clickThroughForCreativePackageB', 'wikiOrResourceForReference', 'staticOrAnimated', 'clickThroughUrl',
    'typeOfImagery', 'describeImageryAndMessage', 'automotiveCoOpGuidelines', 'notesOrInstructions'],

  clientContactEmail: {
    'ui:widget': 'EmailTextWidget',
    'ui:placeholder': t('pages.displayCreativeService.emailHelpContext'),
  },

  reachOutForReview: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select who should be reached out for review/corrections/approvals"
      />
    ),
  },

  staticOrAnimated: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select if your Display ads should be Static or Animated"
      />
    ),
  },

  ...textAreaFields,
  ...textFields,
  ...dropdownFields,
  ...radioFields,
  ...textLabelFields,
  ...phoneNumberTextFields,
  ...urlTextFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DisplayCreativeServiceCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.salesTeam === '') {
    errors.salesTeam.addError(t('pages.displayCreativeService.salesTeamErrorMessage'));
    errors.addError(true);
  }
  if (['Client', 'Client & Sales Rep'].includes(formData?.reachOutForReview)) {
    if (formData?.reachOutForReview) {
      if (formData?.clientContactFirstName === '') {
        errors.clientContactFirstName.addError(t('pages.displayCreativeService.firstNameErrorMessage'));
        errors.addError(true);
      }
    }
    if (formData?.clientContactLastName === '') {
      errors.clientContactLastName.addError(t('pages.displayCreativeService.lastNameErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.reachOutForReview === 'Sales Rep') {
    if (formData?.emailForCreativeApprovalNotifications === '') {
      errors.emailForCreativeApprovalNotifications
        .addError(t('pages.displayCreativeService.emailForApprovalNotificationsErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.creativePackage === 'Premium'
    || formData?.creativePackage === 'Standard'
    || formData?.creativePackage === 'Performance Max (PMAX) Pilot ONLY') {
    if (formData?.clickThroughUrl === '' || !validateUrl(formData?.clickThroughUrl)) {
      errors.clickThroughUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.creativePackage === 'Dual Message') {
    if (formData?.clickThroughForCreativePackageA !== '' && !validateUrl(formData?.clickThroughForCreativePackageA)) {
      errors.clickThroughForCreativePackageA.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
    if (formData?.clickThroughForCreativePackageB !== '' && !validateUrl(formData?.clickThroughForCreativePackageB)) {
      errors.clickThroughForCreativePackageB.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.typeOfImagery === '') {
    errors.typeOfImagery.addError(t('pages.displayCreativeService.typeOfImageryErrorMessage'));
    errors.addError(true);
  }
  if (formData?.describeImageryAndMessage === '') {
    errors.describeImageryAndMessage.addError(t('pages.displayCreativeService.describeImageryErrorMessage'));
    errors.addError(true);
  }
  if (formData?.automotiveCoOpGuidelines === '') {
    errors.automotiveCoOpGuidelines.addError(t('pages.displayCreativeService.automotiveCoOpGuidelinesErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactEmail === '') {
    errors.clientContactEmail.addError(t('pages.videoCreativeService.clientContactEmailErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactEmail !== ''
    && !validateEmail(formData?.clientContactEmail)
    && formData?.reachOutForReview !== 'Sales Rep') {
    errors?.clientContactEmail?.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.creativePackage === '') {
    errors.creativePackage.addError(t('pages.generic-translation.mandatoryRadioButtonErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
