export const dummyLanguages = [
  { label: 'Amharic', value: 'Amharic' },
  { label: 'Arabic', value: 'Arabic' },
  { label: 'Basque', value: 'Basque' },
  { label: 'Bengali', value: 'Bengali' },
  { label: 'Bulgarian', value: 'Bulgarian' },
  { label: 'Catalan', value: 'Catalan' },
  { label: 'Cherokee', value: 'Cherokee' },
  { label: 'Chinese (PRC)', value: 'Chinese (PRC)' },
  { label: 'Croatian', value: 'Croatian' },
  { label: 'Czech', value: 'Czech' },
  { label: 'Danish', value: 'Danish' },
  { label: 'Dutch', value: 'Dutch' },
  { label: 'English (UK)', value: 'English (UK)' },
  { label: 'English (US)', value: 'English (US)' },
  { label: 'Estonian', value: 'Estonian' },
  { label: 'Filipino', value: 'Filipino' },
  { label: 'Finnish', value: 'Finnish' },
  { label: 'French', value: 'French' },
  { label: 'German', value: 'German' },
  { label: 'Greek', value: 'Greek' },
  { label: 'Gujarati', value: 'Gujarati' },
  { label: 'Hebrew', value: 'Hebrew' },
  { label: 'Hindi', value: 'Hindi' },
  { label: 'Hungarian', value: 'Hungarian' },
  { label: 'Icelandic', value: 'Icelandic' },
  { label: 'Indonesian', value: 'Indonesian' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'Kannada', value: 'Kannada' },
  { label: 'Korean', value: 'Korean' },
  { label: 'Latvian', value: 'Latvian' },
  { label: 'Lithuanian', value: 'Lithuanian' },
  { label: 'Malay', value: 'Malay' },
  { label: 'Malayalam', value: 'Malayalam' },
  { label: 'Marathi', value: 'Marathi' },
  { label: 'Norwegian', value: 'Norwegian' },
  { label: 'Polish', value: 'Polish' },
  { label: 'Portuguese (Brazil)', value: 'Portuguese (Brazil)' },
  { label: 'Portuguese (Portugal)', value: 'Portuguese (Portugal)' },
  { label: 'Romanian', value: 'Romanian' },
  { label: 'Russian', value: 'Russian' },
  { label: 'Serbian', value: 'Serbian' },
  { label: 'Slovak', value: 'Slovak' },
  { label: 'Slovenian', value: 'Slovenian' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'Swahili', value: 'Swahili' },
  { label: 'Swedish', value: 'Swedish' },
  { label: 'Tamil', value: 'Tamil' },
  { label: 'Telugu', value: 'Telugu' },
  { label: 'Thai', value: 'Thai' },
  { label: 'Chinese (Taiwan)', value: 'Chinese (Taiwan)' },
  { label: 'Turkish', value: 'Turkish' },
  { label: 'Urdu', value: 'Urdu' },
  { label: 'Ukrainian', value: 'Ukrainian' },
  { label: 'Vietlabelse', value: 'Vietlabelse' },
  { label: 'Welsh', value: 'Welsh' },
];

export const dummyPaymentTypes = [
  { label: '2CO', value: '2CO' },
  { label: 'Alipay', value: 'Alipay' },
  { label: 'Amazon', value: 'Amazon' },
  { label: 'Amex', value: 'Amex' },
  { label: 'Amex Direct Debit', value: 'AmixDirectDebit' },
  { label: 'Visa', value: 'Visa' },
  { label: 'Mastercard', value: 'Mastercard' },
];
