import {Urls} from 'navigation/Urls';
import useProduct from 'hooks/useProduct';
import {StatusType} from 'models/StatusType';

import ContentLoader from 'components/contentLoader/ContentLoader';
import TargetingPageForm from "pages/youtube/targeting/TargetingPageForm";
import {GetYouTubeResponse, YouTubeRequestContentType} from "models/YouTubeType";
import React from 'react';

export default function Targeting() {
  const {product, onSubmit, onBack} = useProduct<YouTubeRequestContentType,
      StatusType<GetYouTubeResponse>>(Urls.YouTubeTargeting);

  return (
      <ContentLoader status={product.status} message={product.message}>
        <TargetingPageForm
            product={product.content.data}
            onSubmit={onSubmit}
            onBack={onBack}
        />
      </ContentLoader>
  );
}
