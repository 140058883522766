import {t} from "i18next";

export const landscapeImageItems = [
  t('pages.ausProduct.demandGenDetails.landscapeImageDescription1'),
  t('pages.ausProduct.demandGenDetails.landscapeImageDescription2'),
  t('pages.ausProduct.demandGenDetails.imageDescriptionCommon'),
];

export const squareImageItems = [
  t('pages.ausProduct.demandGenDetails.squareImageDescription'),
  t('pages.ausProduct.demandGenDetails.imageDescriptionCommon'),
];

export const portraitImageItems = [
  t('pages.ausProduct.demandGenDetails.portraitImageDescription'),
  t('pages.ausProduct.demandGenDetails.imageDescriptionCommon'),
];