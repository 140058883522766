import {
  Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteThemeFeature, selectThemeFeatures, getThemeFeatures } from 'services/adminSlice';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

export default function ThemeFeatures() {
  const themeFeatures = useSelector(selectThemeFeatures);
  const dispatch = useDispatch();
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    dispatch(getThemeFeatures({}));
  }, []);

  const deleteFeature = (id) => {
    dispatch(deleteThemeFeature(id));
  };

  return (
    <>
      <Typography variant="h2">Listing Theme Features</Typography>
      <Grid container>
        <Grid item xs={12} marginTop={1}>
          <Link to="/admin/themeFeatures/new" style={{ marginRight: '10px' }}>
            <Button variant="contained">
              <Typography>New Theme Feature</Typography>
            </Button>
          </Link>
          <Link to="/admin/dashboard">
            <Button variant="contained">
              <Typography>Admin Dashboard</Typography>
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} marginTop={5}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ borderBottom: '3px solid lightgray' }}>
                  <TableCell><Typography fontWeight="bold">Title</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Description</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Icon Filename</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Icon</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Actions</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {themeFeatures.map(feature => (
                  <TableRow
                    key={feature.id}
                    sx={{
                      border: 0,
                      '& .MuiTableCell-root': { border: 0 },
                    }}
                  >
                    <TableCell><Typography>{feature.title}</Typography></TableCell>
                    <TableCell><Typography>{feature.description}</Typography></TableCell>
                    <TableCell><Typography>{feature.iconFileName}</Typography></TableCell>
                    <TableCell>
                      <img
                        src={`/assets/features/${feature.iconFileName}`}
                        alt={feature.iconFileName}
                        style={{ width: '25px', height: '25px' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Link to={`${feature.id}/edit`} style={{ marginRight: '10px' }}>
                        <Button variant="contained">
                          <Typography>Edit</Typography>
                        </Button>
                      </Link>
                      <Button variant="contained" color="error"
                        onClick={() => setItemToDelete(feature)}
                      >
                        <Typography>Destroy</Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DeleteConfirmationModal
            open={!!itemToDelete}
            onClose={() => setItemToDelete(null)}
            onConfirm={() => deleteFeature(itemToDelete.id)}
            resourceName={itemToDelete?.title} resourceType="Theme Feature"
          />
        </Grid>
      </Grid>
    </>
  );
}
