/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';

const { t } = i18n;

export const SeoTopicLocationSchema: RJSFSchema | any = (data) => ({
  title: t('pages.seoAddons.topicLocation.pageTitle'),
  // eslint-disable-next-line max-len
  description: 'pages.seoAddons.topicLocation.pageDescription',
  type: 'object',
  required: ['topicLocation'],
  properties: {
    topicLocation: {
      type: 'string',
      title: t('pages.seoAddons.topicLocation.topicLocationLabel'),
      default: '',
      items: {
        enum: [
          'Topic',
          'Location',
        ],
      },
    },
    additionalNotes: {
      type: 'string',
      title: t('pages.seoAddons.topicLocation.additionalNotesLabel'),
      default: '',
    },
  },
  dependencies: {
    topicLocation: {
      oneOf: [
        {
          properties: {
            topicLocation: {
              enum: ['Location'],
            },
            locationsToPrioritize: {
              type: 'string',
              title: t('pages.seoAddons.topicLocation.locationsToPrioritizeLabel'),
              default: data?.serviceAreas?.map(area => area.name).join('\n') || '',
            },
            locationsToAvoid: {
              type: 'string',
              title: t('pages.seoAddons.topicLocation.locationsToAvoidLabel'),
              default: '',
            },
          },
          required: ['topicLocation', 'locationsToPrioritize'],
        },
        {
          properties: {
            topicLocation: {
              enum: ['Topic'],
            },
            topicsToPrioritize: {
              type: 'string',
              title: t('pages.seoAddons.topicLocation.topicsToPrioritizeLabel'),
              default: '',
            },
            topicsToAvoid: {
              type: 'string',
              title: t('pages.seoAddons.topicLocation.topicsToAvoidLabel'),
              default: '',
            },
          },
          required: ['topicLocation', 'topicsToPrioritize'],
        },
      ],
    },
  },
});

const textFieldKeys = [
  'topicsToPrioritize',
  'topicsToAvoid',
  'locationsToAvoid',
  'additionalNotes',
];

const textFields = textFieldKeys.reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'TextareaWidget' } }), {});

export const SeoTopicLocationUISchema = {
  'ui:order': ['topicLocation', 'topicsToPrioritize', 'topicsToAvoid',
    'locationsToPrioritize', 'locationsToAvoid', 'additionalNotes'],
  topicLocation: {
    'ui:widget': 'radioButton',
  },
  locationsToPrioritize: {
    'ui:widget': 'TextareaWidget',
    'ui:placeholder': t('pages.seoAddons.topicLocation.locationsToPrioritizeSupportText'),
  },
  ...textFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SeoTopicLocationCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.topicLocation === 'Topic' && formData?.topicsToPrioritize === '') {
    errors.topicsToPrioritize.addError(t('pages.seoAddons.topicLocation.topicsToPrioritizeError'));
    errors.addError(true);
  }
  if (formData?.topicLocation === 'Location' && formData?.locationsToPrioritize === '') {
    errors.locationsToPrioritize.addError(t('pages.seoAddons.topicLocation.locationsToPrioritizeError'));
    errors.addError(true);
  }
  if (formData?.topicLocation === '') {
    errors.topicLocation.addError(t('pages.generic-translation.mandatoryRadioButtonErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
