import { ComponentProps } from 'react';

import i18n from 'i18n/i18n';
import { IQButtonRadioContainer } from '@gannettdigital/shared-react-components';
import { Client, ClientStatus, PhoneType } from './ClientType';

const { t } = i18n;

export const defaultClient: Client = {
  contact: {
    id: null,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    cellphoneNumber: null,
    email: '',
    businessRoleId: null,
    clientFullName: '',
    phoneNumberType: null,
    sourceId: null,
  },
  contactPhoneNumberList: [{ phoneNumber: '', phoneType: PhoneType.MOBILE, ext: '' }],
  contactEmailList: [''],
  status: ClientStatus.NOT_STARTED,
};

export const businessRoles: ComponentProps<typeof IQButtonRadioContainer>['optionsList'] = [
  {
    label: t('pages.business.client.edit.roles.owner'),
    field: 'businessRole',
    value: 'owner',
  },
  {
    label: t('pages.business.client.edit.roles.marketing'),
    field: 'businessRole',
    value: 'marketing',
  },
  {
    label: t('pages.business.client.edit.roles.excutive'),
    field: 'businessRole',
    value: 'excutive',
  },
  {
    label: t('pages.business.client.edit.roles.businessDevelopment'),
    field: 'businessRole',
    value: 'businessDevelopment',
  },
  {
    label: t('pages.business.client.edit.roles.partner'),
    field: 'businessRole',
    value: 'partner',
  },
  {
    label: t('pages.business.client.edit.roles.sales'),
    field: 'businessRole',
    value: 'sales',
  },
  {
    label: t('pages.business.client.edit.roles.officeManager'),
    field: 'businessRole',
    value: 'officeManager',
  },
  {
    label: t('pages.business.client.edit.roles.other'),
    field: 'businessRole',
    value: 'other',
  },
];

export const phoneTypes = Object.keys(PhoneType).map(key => ({
  description: PhoneType[key],
  value: PhoneType[key],
}));
