import {
  Button, FormControlLabel, Grid, MenuItem, Select, Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getCurrentMaintenanceMode, getMaintenanceModes, toggleMaintenaceMode } from 'services/maintenanceModeSlice';
import { selectUserEmail } from 'services/userSlice';

export default function NewStatus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userEmail = useSelector(selectUserEmail);
  const maintenanceMode = getCurrentMaintenanceMode();
  const methods = useForm({
    mode: 'all',
    defaultValues: { status: 'OFF' },
  });
  const {
    setValue, control, handleSubmit,
  } = methods;

  useEffect(() => {
    setValue('status', maintenanceMode);
  }, [maintenanceMode]);

  const onSave = ({ status }) => {
    const newStatus = status === 'OFF' ? 0 : 1;
    dispatch(toggleMaintenaceMode({ id: 0, maintenanceMode: newStatus, user: userEmail }));
    dispatch(getMaintenanceModes(null));
    navigate('/admin/maintenanceMode');
  };

  return (
    <>
      <Typography variant="h2">New Status</Typography>
      <FormProvider {...methods}>
        <Grid container>
          <Grid item xs={13} marginTop={2}>
            <FormControlLabel
              sx={{ marginLeft: 0 }}
              label={<Typography>Status</Typography>}
              labelPlacement="start"
              control={(
                <Controller name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select value={value} onChange={onChange} label="Status"
                      sx={{ marginLeft: 3, width: '100px' }}
                    >
                      <MenuItem value="OFF">OFF</MenuItem>
                      <MenuItem value="ON">ON</MenuItem>
                    </Select>
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} marginTop={2}>
            <Button variant="contained" onClick={handleSubmit(onSave)}>
              <Typography>Save</Typography>
            </Button>
            <Button variant="contained" sx={{ marginLeft: 2 }}
              onClick={() => navigate('/admin/maintenanceMode')}
            >
              <Typography>Back to Maintenance Mode</Typography>
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
