/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18next';

const { t } = i18n;

export const SMBRetailGCIOnly20PageSiteSchema: RJSFSchema | any = () => ({
  title: t('pages.smb.solutionStrategy.SMBRetailGCIOnly20PageSite.title'),
  type: 'object',
  required: [],
  properties: {
    doesTheClientWantLeadNotificationsAndOrWeeklyPerformanceReports: {
      type: 'string',
      title: t('pages.smb.solutionStrategy.SMBRetailGCIOnly20PageSite.doesTheClientWantLeadNotificationsAndOrWeeklyPerformanceReportsLabel'),
      default: '',
      enum: [
        '',
        'Lead Notifications',
        'Weekly Performance Report',
        'Lead Notifications + Weekly Performance Report',
        'None',
      ],
    },
    separateEmailAddressesWithAComma: {
      type: 'string',
      title: t('pages.smb.solutionStrategy.SMBRetailGCIOnly20PageSite.separateEmailAddressesWithACommaLabel'),
      default: '',
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.smb.solutionStrategy.SMBRetailGCIOnly20PageSite.notesOrInstructionsLabel'),
      default: '',
    },
  },
});

const textAreaKeys = [
  'separateEmailAddressesWithAComma',
  'notesOrInstructions',
];

const textAreaFields = textAreaKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

export const SMBRetailGCIOnly20PageSiteUISchema = {
  'ui:order': ['doesTheClientWantLeadNotificationsAndOrWeeklyPerformanceReports', 'separateEmailAddressesWithAComma', 'notesOrInstructions'],
  doesTheClientWantLeadNotificationsAndOrWeeklyPerformanceReports: {
    'ui:widget': 'dropdown',
  },
  ...textAreaFields,
};

export const SMBRetailGCIOnly20PageSiteCustomValidate = (formData: any, errors: any, uiSchema: any) => errors;
