import { Typography } from '@mui/material';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import React, { ReactFragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import SocialMediaRow from './socialMediaRow';

interface SocialMediaAccountsProps {
  title?: ReactFragment
  showApplyToAll?: boolean
  showSectionLabels?: boolean
}

export default function SocialMediaAccounts({
  title, showApplyToAll = true, showSectionLabels = true,
}: SocialMediaAccountsProps) {
  const { t } = useTranslation();
  const { fields: accounts } = useFieldArray({
    name: 'accounts',
  });

  return (
    <GridContainer fullWidth>
      {showSectionLabels && (
        <>
          <GridItem>
            {title}
          </GridItem>
          <GridItem>
            <Typography variant="h5">
              {t('pages.business.location.contact.details.socialMediaAccounts.description')}
            </Typography>
          </GridItem>
        </>
      )}
      {React.Children.toArray(accounts.map((account, index) => (
        <SocialMediaRow account={account} rowNumber={index} showApplyToAll={showApplyToAll} />
      )))}
    </GridContainer>
  );
}
