import { PhoneType } from 'pages/business/client/ClientType';
import { SocialMediaAccount } from './ContactType';

export const phoneTypes = [
  {
    value: PhoneType.BUSINESS,
    description: PhoneType.BUSINESS,
  },
  {
    value: PhoneType.MOBILE,
    description: PhoneType.MOBILE,
  },
  {
    value: PhoneType.FAX,
    description: PhoneType.FAX,
  },
];

export const socialMediaAccounts: SocialMediaAccount[] = [
  {
    name: 'Facebook',
    enabled: false,
    applyToAll: false,
  },
  {
    name: 'Twitter',
    enabled: false,
    applyToAll: false,
  },
  {
    name: 'Instagram',
    enabled: false,
    applyToAll: false,
  },
  {
    name: 'YouTube',
    enabled: false,
    applyToAll: false,
  },
  {
    name: 'Vimeo',
    enabled: false,
    applyToAll: false,
  },
  {
    name: 'LinkedIn',
    enabled: false,
    applyToAll: false,
  },
  {
    name: 'Pinterest',
    enabled: false,
    applyToAll: false,
  },
];

export const socialMediaAccountsPL: SocialMediaAccount[] = [
  {
    name: 'Twitter',
    enabled: false,
    applyToAll: false,
  },
  {
    name: 'Instagram',
    enabled: false,
    applyToAll: false,
  },
  {
    name: 'YouTube',
    enabled: false,
    applyToAll: false,
  },
  {
    name: 'Vimeo',
    enabled: false,
    applyToAll: false,
  },
  {
    name: 'LinkedIn',
    enabled: false,
    applyToAll: false,
  },
  {
    name: 'Pinterest',
    enabled: false,
    applyToAll: false,
  },
];
