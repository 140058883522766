import * as yup from 'yup';

const schema = {
  lifeEventsAudience: yup.string().notRequired(),
  category: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
