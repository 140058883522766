import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';

import { FileUploadDirectories, ProductTypes } from 'services/fileUploaderSlice';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import { t } from 'i18next';

import { IQYesNoFormRadioButtons, ValidationProvider } from '@gannettdigital/shared-react-components';
import { Box, Grid } from '@mui/material';
import styled from '@emotion/styled';

import schema from 'pages/ecommerce/products/product-catalog/ProductCatalog.schema';
import useUpdateAssets from 'hooks/useUpdateAssets';
import EcommerceType from 'models/EcommerceType';

import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import CoFileUploader, { AllUploaders } from 'components/fileUploader/CoFileUploader';

import ProductDescription, {
  ContentTonesProps,
  NumericFieldProps,
} from 'pages/ecommerce/products/product-catalog/ProductDescription';
import { contentTones } from 'pages/ecommerce/products/product-catalog/contentTones';
import { useSelector } from 'react-redux';
import { selectActiveProductId } from 'services/navigationSlice';

interface Props {
  product: EcommerceType;
  onSubmit: (data :EcommerceType) => void;
  onBack: () => void;
}

export type YesNo = 'yes' | 'no';

type ProductCatalogData = {
  productDescriptionByClient: string;
  productImagesProvidedByClient: string;
  numberOfProductsWritten: string;
  contentTones: EcommerceType['contentTones'];
  files: File[];
};

const RadioContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  
  & > .MuiFormControl-root:nth-child(2) {
    margin-top: 43px;
  };
  
  [role=radiogroup] {
    padding-left: 5px;
  };
  
  [role=group] > button {
    max-width: max-content;
  };

  .MuiButtonBase-root {
    background: transparent;
  };

  .MuiButtonBase-root:hover {
    background: transparent;
  };

  .MuiButtonBase-root:focus {
    background: transparent;
  };

  .MuiButtonBase-root.Mui-selected {
    background: transparent;
  };

  .MuiButtonBase-root.Mui-selected:hover {
    background: transparent;
  };

  .MuiButtonBase-root.Mui-selected:focus {
    background: transparent;
  };

  .MuiTouchRipple-root > * {
    display: none;
  };
`;

const numericFieldProps :NumericFieldProps = {
  name: 'numberOfProductsWritten',
  label: t('pages.ecommerce.productCatalog.numberOfProducts'),
  required: true,
  defaultValue: '',
};

const contentTonesProps :ContentTonesProps = {
  name: 'contentTones',
  disabled: false,
  tonesList: contentTones,
};

const megabyte = 1_000_000;

const fileUploaderProps :AllUploaders['fileUploaderOptions'] = {
  name: 'files',
  directory: FileUploadDirectories.ECOMMERCE,
  label: t('pages.ecommerce.productCatalog.uploaderHeading'),
  supportingText: t('pages.ecommerce.productCatalog.uploaderSubHeading'),
  showRecommendedImageText: false,
  showCheckboxes: false,
  showComments: true,
  imageTypes: ['.png', '.jpg', '.jpeg', '.gif', '.CR2', '.DNG'],
  videoTypes: ['.mp4'],
  documentTypes: ['.pdf', '.doc', '.docx'],
  maxFileSize: 256 * megabyte,
  uploaderType: ProductTypes.PRODUCT_CATALOG,
};

export default function ProductCatalogForm(props :Props) {
  const { onSubmit: onFormSubmit, onBack, product } = props;

  const { orderId, orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);

  const methods = useForm<ProductCatalogData>({
    mode: 'all',
    defaultValues: {
      files: [],
      numberOfProductsWritten: product?.numberOfProductsWritten || null,
      productDescriptionByClient: product?.productDescriptionByClient || null,
      productImagesProvidedByClient: product?.productImagesProvidedByClient || null,
      contentTones: product?.contentTones || [],
    },
  });

  const {
    handleSubmit, formState: {
      isDirty, isSubmitting, isSubmitSuccessful, isValid,
    },
    watch, unregister, setValue,
  } = methods;

  const { productDescriptionByClient, productImagesProvidedByClient, files } = watch();

  const { triggerAllUpdates, deleteAllAssets } = useUpdateAssets(
    watch,
    fileUploaderProps.name,
    undefined,
    websiteId.toString(),
    orderId,
    ProductTypes.PRODUCT_CATALOG,
    WebsiteOfferingTypes.ECOMM,
    orderItemId,
  );

  const onSubmit = (data: ProductCatalogData) => {
    if (data.productImagesProvidedByClient === 'yes') {
      triggerAllUpdates();
    } else {
      deleteAllAssets();
    }
    const dataToSubmit = {
      ...data,
      numberOfProductsWritten: `${data.numberOfProductsWritten || null}`,
      contentTones: data.contentTones || [],
      files: null,
    };
    onFormSubmit(dataToSubmit);
  };

  useEffect(() => {
    if (productDescriptionByClient === 'yes') {
      setValue('numberOfProductsWritten', null);
      setValue('contentTones', []);
      unregister(['numberOfProductsWritten', 'contentTones']);
    }
  }, [productDescriptionByClient]);

  useEffect(() => {
    if (productImagesProvidedByClient === 'no' && files.length > 0) {
      triggerAllUpdates();
    }
  }, [productImagesProvidedByClient]);

  return (
    <ValidationProvider schema={schema}>
      <Box
        id="mainForm"
        component="form"
        sx={{ display: 'flex' }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <DefaultPageLayout
          onContinue={handleSubmit(onSubmit)}
          header={t('pages.ecommerce.productCatalog.heading')}
          onBack={onBack}
          disableContinue={!isValid}
        >
          <FormProvider {...methods}>
            <RadioContainer container>
              <IQYesNoFormRadioButtons
                title={t('pages.ecommerce.productCatalog.productDescription')}
                field="productDescriptionByClient"
                fontTitleWeight="600"
              />
              <ProductDescription
                productDescriptionByClient={productDescriptionByClient as YesNo}
                numericFieldProps={numericFieldProps}
                contentTonesProps={contentTonesProps}
              />
              <IQYesNoFormRadioButtons
                title={t('pages.ecommerce.productCatalog.productImages')}
                field="productImagesProvidedByClient"
                fontTitleWeight="600"
              />
              {productImagesProvidedByClient === 'yes' && (
              <CoFileUploader
                schema={schema}
                productId={websiteId.toString()}
                productType={ProductTypes.PRODUCT_CATALOG}
                showFileUploader
                fileUploaderOptions={fileUploaderProps}
                offeringType={WebsiteOfferingTypes.ECOMM}
                containerStyles={{
                  mt: '44px',
                  xs: 6.2,
                  '.MuiList-root': { mt: '46px' },
                  'div > label > span': { display: 'none' },
                }}
              />
              )}
            </RadioContainer>
            {/* show the dialog if we changed the field and didn't submit the changes */}
            <CoNavigationConfirm
              showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </FormProvider>
        </DefaultPageLayout>
      </Box>
    </ValidationProvider>
  );
}
