import { Urls } from 'navigation/Urls';
import ContentLoader from 'components/contentLoader/ContentLoader';

import useProduct from 'hooks/useProduct';
import { GetLandingPageResponse, LandingPageRequestContentType } from 'models/LandingPageType';
import { StatusType } from 'models/StatusType';
import GoalsForm from './GoalsForm';

export default function Goals() {
  const { onBack, product, onSubmit } = useProduct<LandingPageRequestContentType,
  StatusType<GetLandingPageResponse>>(Urls.Goals);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <GoalsForm
        onSubmit={onSubmit}
        onBack={onBack}
        product={product.content.data}
      />
    </ContentLoader>
  );
}
