import * as yup from 'yup';

const today = new Date();
today.setDate(today.getDate() - 1);

const schema = {
  goLiveDate: yup.date()
    .typeError('Please select or enter a valid date format: MM/DD/YYYY.')
    .min(today, 'Please select or enter a valid date as of today or a future date. ')
    .nullable(),
  endDate: yup.date()
    .typeError('Please select or enter a valid date format: MM/DD/YYYY.')
    .min(today, 'Please select or enter a valid date as of today or a future date. ')
    .nullable(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
