import {
  IQFormInput, IQFormSelect, IQFormTextArea, IQLoadingSpinner, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import {
  Autocomplete,
  Button, FormControlLabel, Grid, IconButton, Input, TextField, Typography, useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import NeCheckbox from 'components/checkbox/NeCheckbox';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { productNameMap } from 'pages/themes/details/BundleConstants';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  selectBusinessFunctionalities, selectMarketCategories, selectProducts, selectThemeFamilies, selectThemeFeatures,
  selectThemeBenefits,
  selectCountries,
  selectCurrentCountries,
} from 'services/adminSlice';
import { uploadFilesAdmin } from 'services/fileUploaderSlice';
import { useAppDispatch } from 'services/redux/store';
import {
  Theme, convertPreviewString, deleteThemeCollateralById, selectCurrentThemeBenefits,
  selectCurrentThemeCollaterals, selectCurrentThemeFeatures, setImageUrl,
} from 'services/themesSlice';
import ErrorText from 'components/errorText/ErrorText';
import i18n from 'i18n/i18n';
import schema from './Theme.schema';

const { t } = i18n;

interface Props {
  onSubmit: (data: any) => void
  themeData: Theme
}

const autocompleteStyle = {
  width: '100%',
  backgroundColor: 'white',
  border: '1px solid #292928',
  '& .MuiOutlinedInput-notchedOutline': { border: 0 },
};

export default function ThemeForm({
  onSubmit, themeData,
}: Props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [screenshotUrl, setScreenshotUrl] = useState('');
  const [screenshotFile, setScreenshotFile] = useState(null);
  const [compositeScreenshotUrl, setCompositeScreenshotUrl] = useState('');
  const [compositeFile, setCompositeFile] = useState(null);
  const [themePreviewUrls, setThemePreviewUrls] = useState([]);
  const [previewUploaderDisabled, setPreviewUploaderDisabled] = useState(false);
  const [previewFile, setPreviewFile] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const idNameExtractor = (item: { id: number, name: string }) => ({
    value: `${item.id}`,
    description: item.name,
  });
  const themeFamilies = useSelector(selectThemeFamilies);
  const themeFamilyItems = useMemo(
    () => themeFamilies.map(idNameExtractor),
    [themeFamilies],
  );

  const marketCategories = useSelector(selectMarketCategories);
  const marketCategoryItems = useMemo(
    () => marketCategories.map(idNameExtractor),
    [marketCategories],
  );

  const businessFunctionalities = useSelector(selectBusinessFunctionalities);
  const businessFunctionalityItems = useMemo(
    () => businessFunctionalities.map(idNameExtractor),
    [businessFunctionalities],
  );

  const products = useSelector(selectProducts);
  // const sortedProducts = products.sort();
  const productItems = useMemo(
    () => products.map((item => ({ value: `${item.id}`, description: productNameMap[item.name] }))),
    [products],
  );

  const themeFeatures = useSelector(selectThemeFeatures);
  const themeBenefits = useSelector(selectThemeBenefits);
  const countries = useSelector(selectCountries);
  const currentThemeFeatures = useSelector(selectCurrentThemeFeatures);
  const currentThemeBenefits = useSelector(selectCurrentThemeBenefits);
  const currentThemeCollaterals = useSelector(selectCurrentThemeCollaterals);
  const currentCountries = useSelector(selectCurrentCountries);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      title: themeData?.title || '',
      familyId: themeData?.familyId?.toString() || '',
      screenshotImage: null,
      screenshotImageFile: null,
      compositeScreenshot: null,
      compositeScreenshotFile: null,
      themePreview: '',
      themePreviewFile: null,
      url: themeData?.url || '',
      sort: themeData?.sortId || 0,
      marketCategoryId: themeData?.marketCategoryId?.toString() || '',
      businessFunctionalityId: themeData?.businessFunctionalityId?.toString() || '',
      productId: '',
      description: themeData?.description || '',
      detailsAbove: themeData?.detailsAboveTheFold || '',
      detailsBelow: themeData?.detailsBelowTheFold || '',
      designStyle: themeData?.designStyle || '',
      color: themeData?.color || '',
      notes: themeData?.notes || '',
      callToAction: themeData?.callToAction || '',
      themeFeatures: [],
      themeBenefits: [],
      collaterals: [{ label: '', collateralBlurb: '', uploadedFile: '' }],
      countryAvailability: [],
      available: themeData?.active || false,
      featured: themeData?.featured || false,
    },
  });

  const {
    control, handleSubmit, setValue, getValues, watch,
    formState: { isValid, errors },
  } = methods;

  const collaterals = useMemo(() => getValues('collaterals'), [watch('collaterals')]);
  const countryValues = useMemo(() => getValues('countryAvailability'), [watch('countryAvailability')]);

  const addCollateral = () => {
    const existing = getValues('collaterals');
    existing.push({ label: '', collateralBlurb: '', uploadedFile: '' });
    setValue('collaterals', existing);
  };

  const removeCollateral = async (collateral, index) => {
    if (collateral?.id) dispatch(deleteThemeCollateralById(collateral.id));
    const existing = getValues('collaterals');
    existing.splice(index, 1);
    setValue('collaterals', existing);
  };

  const readFile = (file: any, type: any, onSuccess: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      onSuccess(reader.result as string);
    });
    reader.readAsDataURL(file);
    if (type === 'screenshot') setScreenshotFile(file);
    if (type === 'composite') setCompositeFile(file);
  };

  const uploadFile = async (file, type, formValue) => {
    setIsSaveDisabled(true);
    if (file) {
      await appDispatch(dispatch(uploadFilesAdmin({
        directory: `uploads/theme/${type}`, files: [file],
      }))).then(async (response) => {
        const fileUrl = Object.values(response.payload[0].data)[0][0].url;
        setValue(formValue, fileUrl);
      });
    }
    setIsSaveDisabled(false);
  };

  useEffect(() => {
    uploadFile(screenshotFile, 'screenshot_image', 'screenshotImageFile');
  }, [screenshotFile]);

  useEffect(() => {
    uploadFile(compositeFile, 'composite_screenshot', 'compositeScreenshotFile');
  }, [compositeFile]);

  useEffect(() => {
    if (previewFile && previewFile.length > 0) {
      setIsSaveDisabled(true);
      appDispatch(dispatch(uploadFilesAdmin({
        directory: 'uploads/theme/theme_preview', files: previewFile,
      }))).then(async (response) => {
        const fileUrls = response.payload.map(item => Object.values(item.data)[0][0].url);
        await setThemePreviewUrls(fileUrls);
        setValue('themePreviewFile', fileUrls);
        setIsSaveDisabled(false);
      });
    }
  }, [previewFile]);

  useEffect(() => {
    if (themeData.id) {
      if (themeData?.screenshotImage) {
        setScreenshotUrl(setImageUrl(themeData.id, themeData.screenshotImage, 'screenshot_image'));
      }
      if (themeData?.compositeScreenshot) {
        setCompositeScreenshotUrl(setImageUrl(themeData.id, themeData.compositeScreenshot, 'composite_screenshot'));
      }
      if (themeData?.themePreview) {
        const filesList = convertPreviewString(themeData.themePreview);
        setThemePreviewUrls(filesList?.map(file => setImageUrl(themeData.id, file, 'theme_preview')) || []);
      }
      setValue('productId', products.filter(p => p.name === themeData.product)[0].id.toString());
    }
  }, [themeData]);

  useEffect(() => {
    if (currentThemeFeatures?.featuresContent?.length > 0 && themeData.id) {
      setValue('themeFeatures', currentThemeFeatures.featuresContent);
    }
  }, [currentThemeFeatures, themeData]);

  useEffect(() => {
    if (currentThemeBenefits?.benefitsContent?.length > 0 && themeData.id) {
      setValue('themeBenefits', currentThemeBenefits.benefitsContent);
    }
  }, [currentThemeBenefits, themeData]);

  useEffect(() => {
    if (currentCountries?.length > 0 && themeData.id && countries.length > 0) {
      const countryValues = [];
      currentCountries.forEach((curr: any) => {
        const existingCountry = countries.filter(country => country.id.toString() === curr.toString());
        if (existingCountry?.length > 0) countryValues.push(existingCountry[0]);
      });
      setValue('countryAvailability', countryValues);
    }
  }, [currentCountries, countries, themeData]);

  useEffect(() => {
    if (currentThemeCollaterals?.length > 0 && themeData.id) {
      setValue('collaterals', currentThemeCollaterals.map(e => ({
        label: e.label,
        collateralBlurb: e.collateralBlurb,
        uploadedFile: e.uploadedFile,
        themeId: e.themeId,
        id: e.id,
      })));
      themeData.collaterals?.forEach((collateral, index) => {
        setValue(`collaterals.${index}.label`, collateral.label);
        setValue(`collaterals.${index}.collateralBlurb`, collateral.collateralBlurb);
        setValue(`collaterals.${index}.uploadedFile`, collateral.uploadedFile);
      });
    }
  }, [currentThemeCollaterals, themeData]);

  const deleteUpload = (url) => {
    const index = themePreviewUrls.findIndex((file) => file === url);
    if (index !== -1) {
      const prevCopy = [...themePreviewUrls];
      prevCopy.splice(index, 1);
      setThemePreviewUrls(prevCopy);
      setValue('themePreviewFile', prevCopy);
    }
  };

  return (
    <Grid container>
      <FormProvider {...methods}>
        <ValidationProvider schema={schema}>
          <Grid item xs={6} marginTop={3}>
            <IQFormInput
              labelText="Title *"
              id="title"
              name="title"
              theme={theme}
              schema={schema}
              fontLabelWeight="600"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <IQFormSelect
              items={themeFamilyItems}
              labelText="Family Name *"
              fontLabelWeight="600"
              name="familyId"
              fullWidth
              sx={{ '&.MuiOutlinedInput-root': { backgroundColor: 'white' } }}
              showError={false}
            />
            <ErrorText hasError={!!errors.familyId} errorText={t('pages.themes.themeCreateEdit.familyError')} />
          </Grid>
          <Grid item xs={12} marginTop={4}>
            <Typography fontWeight="bold">Screenshot Image</Typography>
            {screenshotUrl && (
              <img src={screenshotUrl} alt={screenshotUrl} style={{ display: 'block', width: '25%' }} />
            )}
            <Controller
              control={control}
              name="screenshotImage"
              render={({ field: { value, onChange, ...field } }) => (
                <Input
                  {...field}
                  value={value}
                  onChange={(event) => {
                    onChange(event.target.value);
                    readFile((event.target as any).files[0], 'screenshot', url => setScreenshotUrl(url));
                  }}
                  type="file"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} marginTop={4}>
            <Typography fontWeight="bold">Composite Screenshot</Typography>
            {compositeScreenshotUrl && (
              <img
                src={compositeScreenshotUrl}
                alt={compositeScreenshotUrl}
                style={{ display: 'block', width: '25%' }}
              />
            )}
            <Controller
              control={control}
              name="compositeScreenshot"
              render={({ field: { value, onChange, ...field } }) => (
                <Input
                  {...field}
                  value={value}
                  onChange={(event) => {
                    onChange(event.target.value);
                    readFile((event.target as any).files[0], 'composite', url => setCompositeScreenshotUrl(url));
                  }}
                  type="file"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} marginTop={4}>
            <Typography fontWeight="bold">Theme Preview</Typography>
            {themePreviewUrls?.length > 0 && (
              <ul style={{ listStyleType: 'none', padding: '0' }}>
                {themePreviewUrls.map((url) => (
                  <li key={url} style={{ marginBottom: '20px', display: 'flex' }}>
                    {!url.includes('.mp4') ? (
                      <img
                        src={url}
                        alt={url}
                        style={{ display: 'block', width: '25%' }}
                      />
                    ) : (
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      <video width="25%" height="auto" controls
                        style={{ display: 'block' }}
                      >
                        <source src={url} type="video/mp4" />
                      </video>
                    )}
                    <IconButton
                      aria-label="delete"
                      onClick={() => deleteUpload(url)}
                      style={{ margin: '10px' }}
                      color="primary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </li>
                ))}
              </ul>
            )}
            <Controller
              control={control}
              name="themePreview"
              render={({ field: { value, onChange, ...field } }) => (
                <Input
                  {...field}
                  value={value}
                  onChange={(event) => {
                    onChange(event.target.value);
                    const files = (event.target as any).files as FileList;
                    setPreviewUploaderDisabled(true);
                    const previews = [];
                    Array.from(files).forEach(file => {
                      readFile(file, 'preview', url => previews.push(url));
                    });
                    setPreviewFile(Array.from(files));
                    setPreviewUploaderDisabled(false);
                  }}
                  type="file"
                  disabled={previewUploaderDisabled}
                  inputProps={{ multiple: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} marginTop={3}>
            <FormControlLabel
              sx={{ marginLeft: 0, alignItems: 'flex-start', width: '100%' }}
              label={<Typography marginBottom={1} fontWeight="bold">Sort</Typography>}
              labelPlacement="top"
              control={(
                <Controller
                  name="sort"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField id="sort" type="number" onChange={onChange}
                      sx={autocompleteStyle}
                      value={value}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <IQFormInput
              labelText="URL"
              id="url"
              name="url"
              theme={theme}
              schema={schema}
              fullWidth
              fontLabelWeight="600"
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <IQFormSelect
              items={marketCategoryItems}
              labelText="Market Category Name *"
              fontLabelWeight="600"
              name="marketCategoryId"
              sx={{ '&.MuiOutlinedInput-root': { backgroundColor: 'white' } }}
              fullWidth
              showError={false}
            />
            <ErrorText hasError={!!errors.marketCategoryId}
              errorText={t('pages.themes.themeCreateEdit.marketCategoryError')}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <IQFormSelect
              items={businessFunctionalityItems}
              labelText="Business Functionality *"
              fontLabelWeight="600"
              name="businessFunctionalityId"
              sx={{ '&.MuiOutlinedInput-root': { backgroundColor: 'white' } }}
              fullWidth
              showError={false}
            />
            <ErrorText hasError={!!errors.businessFunctionalityId}
              errorText={t('pages.themes.themeCreateEdit.businessFunctionalityError')}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <IQFormSelect
              items={productItems.sort()}
              labelText="Product *"
              fontLabelWeight="600"
              name="productId"
              sx={{ '&.MuiOutlinedInput-root': { backgroundColor: 'white' } }}
              fullWidth
              showError={false}
            />
            <ErrorText hasError={!!errors.productId}
              errorText={t('pages.themes.themeCreateEdit.productError')}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <IQFormTextArea
              id="description"
              name="description"
              labelText="Description"
              fontLabelWeight="600"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <IQFormTextArea
              id="detailsAbove"
              name="detailsAbove"
              labelText="Details Above the Fold"
              fontLabelWeight="600"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <IQFormTextArea
              id="detailsBelow"
              name="detailsBelow"
              labelText="Details Below the Fold"
              fontLabelWeight="600"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <IQFormInput
              labelText="Design Style"
              fontLabelWeight="600"
              id="designStyle"
              name="designStyle"
              theme={theme}
              schema={schema}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <IQFormInput
              labelText="Color"
              fontLabelWeight="600"
              id="color"
              name="color"
              theme={theme}
              schema={schema}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <IQFormTextArea
              id="notes"
              name="notes"
              labelText="Notes"
              fontLabelWeight="600"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <IQFormInput
              labelText="Call to Action"
              fontLabelWeight="600"
              id="callToAction"
              name="callToAction"
              theme={theme}
              schema={schema}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <FormControlLabel
              sx={{ marginLeft: 0, alignItems: 'flex-start', width: '100%' }}
              label={<Typography marginBottom={2} fontWeight="bold">Product Features</Typography>}
              labelPlacement="top"
              control={(
                <Controller
                  name="themeFeatures"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      sx={autocompleteStyle}
                      multiple
                      options={themeFeatures}
                      getOptionLabel={(option) => option.title}
                      onChange={(event, value) => onChange(value)}
                      isOptionEqualToValue={(option, val) => option.id === val.id}
                      value={value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <FormControlLabel
              sx={{ marginLeft: 0, alignItems: 'flex-start', width: '100%' }}
              label={<Typography marginBottom={2} fontWeight="bold">Product Benefits</Typography>}
              labelPlacement="top"
              control={(
                <Controller
                  name="themeBenefits"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      sx={autocompleteStyle}
                      multiple
                      options={themeBenefits}
                      getOptionLabel={(option) => option.title}
                      onChange={(event, value) => onChange(value)}
                      isOptionEqualToValue={(option, val) => option.id === val.id}
                      value={value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}
            marginBottom={3}
          >
            <Grid container>
              <Grid item xs={12} marginTop={3}>
                <Typography fontWeight="bold">Collaterals</Typography>
              </Grid>
              <Grid item xs={12}>
                {collaterals.map((collateral, index) => (
                  <Grid container>
                    <Grid item xs={12} marginTop={3}
                      paddingLeft={3}
                    >
                      <IQFormInput
                        labelText="Label"
                        fontLabelWeight="600"
                        id={`collaterals.${index}.label`}
                        name={`collaterals.${index}.label`}
                        theme={theme}
                        schema={schema}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} marginTop={3}
                      paddingLeft={3}
                    >
                      <IQFormInput
                        labelText="Collateral Description"
                        fontLabelWeight="600"
                        id={`collaterals.${index}.collateralBlurb`}
                        name={`collaterals.${index}.collateralBlurb`}
                        theme={theme}
                        schema={schema}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} marginTop={3}
                      paddingLeft={3}
                    >
                      <Typography fontWeight="600">File to Upload</Typography>
                      <Controller
                        control={control}
                        name={`collaterals.${index}.uploadedFile`}
                        render={({ field: { value, onChange, ...field } }) => (
                          <Input
                            {...field}
                            value={undefined}
                            onChange={(event) => {
                              onChange(event.target.value);
                              const collateralFile = (event.target as any).files[0];
                              uploadFile(collateralFile, 'collateral', `collaterals.${index}.uploadedFile`);
                            }}
                            type="file"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} marginTop={2}>
                      <Button variant="text" onClick={() => removeCollateral(collateral, index)}>
                        Remove this Collateral
                      </Button>
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Button variant="text" onClick={addCollateral}>Add Collateral</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} marginTop={3}>
            <FormControlLabel
              sx={{ marginLeft: 0, alignItems: 'flex-start', width: '100%' }}
              label={<Typography marginBottom={2} fontWeight="bold">Country Availability *</Typography>}
              labelPlacement="top"
              control={(
                <Controller
                  name="countryAvailability"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      sx={autocompleteStyle}
                      multiple
                      options={countries}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => onChange(value)}
                      isOptionEqualToValue={(option, val) => option.id === val.id}
                      value={value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} marginTop={3}>
            <Typography fontWeight="bold">Visibility</Typography>
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <NeCheckbox
              label="Available for purchase"
              name="available"
              field="available"
            />
          </Grid>
          <Grid item xs={12} marginTop={3}>
            <Typography fontWeight="bold">Featured Theme</Typography>
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <NeCheckbox
              label="Featured theme — show in main Theme Gallery"
              name="featured"
              field="featured"
            />
          </Grid>

          <Grid item xs={12} marginTop={10}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              '& div:first-of-type': { display: 'inline-block', marginRight: '20px' },
            }}
          >
            <Link to="/admin/themes">
              <Button variant="contained" disabled={isSaveDisabled}
                startIcon={<ChevronLeftIcon />}
                sx={{ width: '220px', padding: '15px', marginRight: 3 }}
              >
                <Typography fontSize="16px">Back to Themes</Typography>
              </Button>
            </Link>
            <Button variant="contained"
              sx={{ width: '220px', padding: '15px' }}
              onClick={handleSubmit(onSubmit)}
              disabled={isSaveDisabled || !isValid || countryValues.length === 0}
            >
              {isSaveDisabled && <IQLoadingSpinner size={30} />}
              <Typography fontSize="16px">Save</Typography>
            </Button>
          </Grid>
        </ValidationProvider>
      </FormProvider>
    </Grid>
  );
}
