import {
  Box, Button, Grid, Link, Typography,
} from '@mui/material';
import { checkUrlProtocol } from 'pages/admin/themes/AdminThemes';
import { mapAddons } from 'pages/salesforce-entry/OpportunityData';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Theme, selectCurrentThemeVariants, setImageUrl } from 'services/themesSlice';
import { AddonsObjList } from 'pages/shopping-cart/Constants';
import { useQuery } from './ThemeDetails';
import {
  dmsNextProducts, seoAddons, seoBlogProducts, seoProducts, smmAddons, smmProducts,
} from '../ThemeConstants';

interface Props {
  theme: Theme
  setSelectedVariant: any
  noVariants?: boolean
}

interface ThemeThumbnailProps {
  name: string
  imageUrl: string
  onClick?: () => void
}

const ThemeThumbnail = ({ name, imageUrl, onClick }: ThemeThumbnailProps) => (
  <Box sx={{ width: '150px' }} onClick={onClick}>
    <img style={{ height: '100px', width: '100px', borderRadius: '5px' }}
      src={imageUrl} alt={name}
    />
    <Typography>{name}</Typography>
  </Box>
);

export default function ThemeVariants({ theme, setSelectedVariant, noVariants = false }: Props) {
  const query = useQuery();
  const currentThemeVariants = useSelector(selectCurrentThemeVariants);
  const [currentVariant, setCurrentVariant] = useState<any>({});

  const noVariantsProducts = [
    'master addons',
    ...seoProducts,
    ...seoBlogProducts,
    ...seoAddons,
    ...smmProducts,
    ...smmAddons,
    ...dmsNextProducts,
    'xmo',
  ];

  useEffect(() => {
    setCurrentVariant((currentThemeVariants?.length && theme.product === 'website - standard')
      ? currentThemeVariants[0] : theme);
  }, [currentThemeVariants]);

  const updateVariant = (variant) => {
    setCurrentVariant(variant);
    setSelectedVariant(variant);
  };

  const getProductName = () => {
    const addonName = query.get('addon');
    if (addonName) return mapAddons[addonName];
    return currentVariant.title || theme.title;
  };

  const getImageUrl = (item) => {
    const addonName = query.get('addon');
    if (item?.product === 'master addons' && addonName) {
      const addon = AddonsObjList.filter(e => e.productName === addonName);
      if (addon.length > 0) return `/assets/addons/${addon[0].imageUrl}`;
    }
    return setImageUrl(item.id, item.compositeScreenshot, 'composite_screenshot');
  };

  return (
    <>
      <Typography variant="h2" className='product-name'>
        {getProductName()}
      </Typography>
      <Grid container
        sx={{
          border: '1px solid lightgrey',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          marginTop: 2,
        }}
      >
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <img
            src={getImageUrl(currentVariant)}
            alt={currentVariant.title} style={{
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              maxWidth: '100%',
              height: theme.product !== 'master addons' ? '460px' : 'auto',
            }}
          />
        </Grid>
        {(!noVariants && !noVariantsProducts.includes(theme.product)) && (
        <>
          <Grid item xs={9} sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 4,
            borderTop: '1px solid lightgrey',
            paddingBottom: 4,
            paddingLeft: 6,
          }}
          >
            {(currentThemeVariants?.length && theme.product === 'website - standard') && currentThemeVariants.map(
              variant => (
                <ThemeThumbnail
                  key={variant.title}
                  name={variant.title}
                  imageUrl={setImageUrl(variant.id, variant.compositeScreenshot, 'composite_screenshot')}
                  onClick={() => updateVariant(variant)}
                />
              ),
            )}
            {(!currentThemeVariants?.length || theme.product !== 'website - standard') && (
            <ThemeThumbnail
              key={currentVariant.title}
              name={currentVariant.title}
              imageUrl={setImageUrl(currentVariant.id, currentVariant.compositeScreenshot, 'composite_screenshot')}
            />
            )}
          </Grid>
          <Grid item xs={3} sx={{
            borderTop: '1px solid lightgrey',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            {currentVariant.url && (
              <Link href={checkUrlProtocol(currentVariant.url)} target="_blank">
                <Button variant="outlined">View Demo</Button>
              </Link>
            )}
          </Grid>
        </>
        )}
      </Grid>
    </>
  );
}
