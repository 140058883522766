import { Button } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getPartnerAccountDetails, selectCurrentOffer } from "services/cefSlice";
import { useEffect, useState } from "react";

export default function CsvFileGenerator({productData}: { productData: any }) {
  const dispatch = useDispatch();
  const currentOffer = useSelector(selectCurrentOffer);
  const [accountDetails, setAccountDetails] = useState<any>(null);

  const getAccountDetailsData = async () => {
    if (currentOffer?.salesForceOfferObject?.accountId) {
      const accountDetailsData: any = await dispatch(getPartnerAccountDetails(currentOffer?.salesForceOfferObject?.accountId));
      if (accountDetailsData) {
        setAccountDetails(accountDetailsData?.payload)
      }
    }
  }

  useEffect(() => {
    getAccountDetailsData();
  }, [currentOffer]);

  const camelCaseToFlat = (str: string) =>
      str?.replace(/([A-Z])/g, ' $1').replace(/^./, (char) => char.toUpperCase());

  const formatSchedule = (schedule: Array<{ [day: string]: any }>) => {
    const daysOrder = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return daysOrder
    .map(day => {
      const daySchedule = schedule[0]?.[day];
      if (daySchedule && daySchedule.open) {
        return `${day.toUpperCase()}: ${daySchedule.openTime || "00:00"} - ${daySchedule.closeTime || "00:00"}`;
      } else {
        return `${day.toUpperCase()}: ---`;
      }
    })
    .join(" ; ");
  };

  const flattenData = (data: any) => {
    const flattenedList = {...data};
    if (data.schedule) {
      flattenedList["Business Hours"] = formatSchedule(data.schedule);
      delete flattenedList.schedule;
    }
    return flattenedList;
  };

  const generateCustomFieldsData = (data: any) => {
    return {
      accountId: '',
      businessName: data?.fullBusinessName || '',
      businessPhone: data?.businessPhoneNumber || '',
      businessWebsite: data?.businessWebsite || '',
      inviteUserToAccount: '',
      primaryContactFirstName: data?.businessOwnerFirstName || '',
      primaryContactLastName: data?.businessOwnerLastName || '',
      businessCategories: data?.category || '',
      foundingYear: data?.businessFoundedYear || '',
      languagesSpoken: data?.languagesSpoken || '',
      streetAddress: accountDetails?.advertiserAddress1 || '',
      city: accountDetails?.advertiserCity,
      region: accountDetails?.advertiserState || '',
      postalCode: accountDetails?.accountPostalCode || '',
      country: accountDetails?.advertiserCountry || '',
      physicalLocation: '',
      serviceAreas: '',
      serviceTypes: '',
      businessHours: data?.schedule ? formatSchedule(data?.schedule) : '',
      marketingOptIn: ''
    };
  };

  const convertToCSV = (data: Array<{ [key: string]: any }>): string => {
    if (data.length === 0) return "";
    const headers = Object.keys(data[0]).map(camelCaseToFlat);
    const rows = data.map(row =>
        Object.keys(row)
        .map(field => JSON.stringify(row[field] || ""))
        .join(",")
    );
    return [headers.join(","), ...rows].join("\n");
  };

  const handleCsvFileDownloadClick = () => {
    const flattenedData = [generateCustomFieldsData(productData)];
    const csvData = convertToCSV(flattenedData);
    const blob = new Blob([csvData], {type: "text/csv;charset=utf-8;"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "LSA_file.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
      <Button
          variant="outlined"
          startIcon={<FileDownloadOutlinedIcon/>}
          sx={{marginRight: '10px', background: '#ffffff'}}
          onClick={handleCsvFileDownloadClick}
          disabled={!accountDetails}
      >
        Download CSV
      </Button>
  );
}
