import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from "@mui/icons-material/Add";
import {
  IQButtonLink,
  IQFormInput,
  IQTheme,
  ValidationProvider
} from "@gannettdigital/shared-react-components";
import {DialogTitle} from "@mui/material";
import schema from "pages/youtube/advertising/ad-group-options/life-events/LifeEventsFields.schema";
import {FormProvider, useForm} from "react-hook-form";
import GridItem from "components/grid/GridItem";
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from "react-i18next";
import CoNavigationConfirm from "components/navigation/CoNavigationConfirm";
import GridContainer from "components/grid/GridContainer";

export default function LifeEventsFieldsFormDialog({
                                                     open,
                                                     onOpen,
                                                     onClose,
                                                     updateLifeEvents,
                                                     selectedLifeEvent
                                                   }) {

  const {t} = useTranslation();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      lifeEventsAudience: '',
      category: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
    setValue,
  } = methods;

  useEffect(() => {
    if (selectedLifeEvent) {
      // Populate form fields with selectedLifeEvent data
      setValue("lifeEventsAudience", selectedLifeEvent.lifeEventsAudience);
      setValue("category", selectedLifeEvent.category);
    }
  }, [selectedLifeEvent]);

  const handleFormSubmit = async (data) => {

    let {sourceId} = selectedLifeEvent || {};

    if (!sourceId) {
      // Generate a unique sourceId if it does not exist
      sourceId = uuidv4();
    }

    // Add life events with sourceId
    updateLifeEvents({...data, sourceId});

    // Close the form
    handleClose();
  };

  const handleOpen = () => {
    // Reset the form state
    reset({
      lifeEventsAudience: '',
      category: ''
    });
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  return (
      <>
        {<IQButtonLink startIcon={<AddIcon/>} onClick={() => handleOpen()}>
          {t('pages.youtube.advertising.adGroupOptions.lifeEvents.addIconLabel')}
        </IQButtonLink>}
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth
        >
          <DialogTitle>{t('pages.youtube.advertising.adGroupOptions.lifeEvents.header')}</DialogTitle>
          <DialogContent>
            <ValidationProvider schema={schema}>
              <FormProvider {...methods}>
                <form>
                  <GridContainer fullWidth>
                    <GridItem inlineItems sizes={[6, 6]}>
                      <IQFormInput
                          id="lifeEventsAudience"
                          theme={IQTheme}
                          labelText={t('pages.youtube.advertising.adGroupOptions.lifeEvents.lifeEventsAudience')}
                          fontLabelWeight="600"
                          name="lifeEventsAudience"
                          fullWidth
                      />
                      <IQFormInput
                          id="category"
                          theme={IQTheme}
                          labelText={t('pages.youtube.advertising.adGroupOptions.lifeEvents.category')}
                          fontLabelWeight="600"
                          name="category"
                          fullWidth
                      />
                    </GridItem>
                  </GridContainer>
                  <DialogActions>
                    <Button
                        onClick={handleClose}>{t("pages.youtube.advertising.adGroupOptions.CloseBtn")}</Button>
                    <Button disabled={!isValid}
                            onClick={handleSubmit(handleFormSubmit)}>{t("pages.youtube.advertising.adGroupOptions.submitBtn")}</Button>
                  </DialogActions>
                </form>
                <CoNavigationConfirm
                    showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
                />
              </FormProvider>
            </ValidationProvider>
          </DialogContent>
        </Dialog>
      </>
  );
}