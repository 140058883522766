/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import FileUploader from 'pages/generic-product/details/custom-fields/FileUploader';
import { ProductTypes } from 'services/fileUploaderSlice';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';
import { validateAgeRange } from 'pages/generic-product/details/utils/genericValidation';

const { t } = i18n;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CustomSolutionPandoraSchema: RJSFSchema | any = (data) => ({
  title: t('pages.custom.solutions.pandora.pageTitle'),

  type: 'object',
  // eslint-disable-next-line max-len
  required: ['proposalForm', 'landingPageUrl', 'highlightKeyDifferentiators', 'focusOnTimedEvent',
    'gender', 'clientProvidingCreative'],
  properties: {
    proposalForm: {
      type: 'string',
      title: t('pages.custom.solutions.common.proposalFormLabel'),
      default: '',
      items: {
        enum: [
          t('form.big-box-labels.yes'),
          t('pages.custom.solutions.common.noOption'),
        ],
      },
    },
    landingPageUrl: {
      type: 'string',
      title: t('pages.custom.solutions.common.landingPageURLLabel'),
      default: '',
    },
    highlightKeyDifferentiators: {
      type: 'string',
      title: t('pages.custom.solutions.common.keyDifferentiatorsRadioLabel'),
      default: '',
      items: {
        enum: [
          t('form.big-box-labels.yes'),
          t('pages.custom.solutions.common.noOption'),
        ],
      },
    },
    focusOnTimedEvent: {
      type: 'string',
      title: t('pages.custom.solutions.common.clientFocusLabel'),
      enum: [
        t('form.big-box-labels.yes'),
        t('pages.custom.solutions.common.noOption'),
      ],
    },
    geoTargetingType: {
      type: 'string',
      title: t('pages.custom.solutions.common.geoTargetingTypeLabel'),
      default: '',
      enum: [
        '',
        t('pages.custom.solutions.common.countryOption'),
        t('pages.custom.solutions.common.statesOption'),
        t('pages.custom.solutions.common.zipCodesOption'),
        t('pages.custom.solutions.common.citiesOption'),
      ],
    },
    geoTargetingDetails: {
      type: 'string',
      title: t('pages.custom.solutions.common.geoTargetingDetailsLabel'),
      default: '',
    },
    age: {
      type: 'array',
      title: t('pages.custom.solutions.pandora.AgeLabel'),
      default: '',
      items: {
        type: 'string',
        enum: [
          t('pages.custom.solutions.pandora.AgeValue18-24Label'),
          t('pages.custom.solutions.pandora.AgeValue25-34Label'),
          t('pages.custom.solutions.pandora.AgeValue35-44Label'),
          t('pages.custom.solutions.pandora.AgeValue45-54Label'),
          t('pages.custom.solutions.pandora.AgeValue55-64Label'),
        ],
      },
      uniqueItems: true,
    },
    clientProvideAudioCreative: {
      type: 'string',
      title: t('pages.custom.solutions.pandora.clientLevel'),
      default: '',
      enum: [
        '',
        t('pages.custom.solutions.pandora.yesAttachedLevel'),
        t('pages.custom.solutions.pandora.noPurchasing'),
      ],
    },
    minimumAge: {
      type: 'integer',
      title: t('pages.custom.solutions.common.minAgeLabel'),
    },
    maximumAge: {
      type: 'integer',
      title: t('pages.custom.solutions.common.maxAgeLabel'),
    },
    gender: {
      type: 'array',
      title: t('pages.custom.solutions.common.genderLabel'),
      items: {
        type: 'string',
        enum: [
          t('pages.custom.solutions.common.maleLabel'),
          t('pages.custom.solutions.common.femaleLabel'),
        ],
      },
      uniqueItems: true,
      minItems: 1,
    },
    targetingPreferences: {
      type: 'string',
      title: t('pages.custom.solutions.common.targetingPreferencesLabel'),
      default: '',
      items: {
        enum: [
          t('pages.custom.solutions.common.interestsOption'),
          t('pages.custom.solutions.common.realTimeContextsOption'),
          t('pages.custom.solutions.common.genresOption'),
        ],
      },
    },
    clientProvidingCreative: {
      type: 'string',
      title: t('pages.custom.solutions.common.clientProvidingCreativeRadioLabel'),
      description: t('pages.custom.solutions.common.clientProvidingCreativeRadioHelperLabel'),
      default: '',
      items: {
        enum: [
          t('pages.custom.solutions.common.noOption'),
          t('pages.custom.solutions.common.yesAllLabel'),
          t('pages.custom.solutions.common.yesSomeLabel'),
        ],
      },
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.custom.solutions.common.notesInstructionsLabel'),
      default: '',
    },
  },

  dependencies: {
    highlightKeyDifferentiators: {
      oneOf: [
        {
          properties: {
            highlightKeyDifferentiators: {
              enum: ['Yes'],
            },
            keyDifferentiatorsToHighlight: {
              type: 'string',
              title: t('pages.custom.solutions.common.keyDifferentiatorsLabel'),
            },
          },
        },
        {
          properties: {
            highlightKeyDifferentiators: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    focusOnTimedEvent: {
      oneOf: [
        {
          properties: {
            focusOnTimedEvent: {
              enum: ['Yes'],
            },
            detailsOfEvent: {
              type: 'string',
              title: t('pages.custom.solutions.common.detailsOfEventLabel'),
              default: '',
            },
            startDate: {
              type: 'string',
              title: t('pages.custom.solutions.common.startDateLabel'),
              default: '',
            },
            endDate: {
              type: 'string',
              title: t('pages.custom.solutions.common.endDateLabel'),
              default: '',
            },
          },
        },
        {
          properties: {
            focusOnTimedEvent: {
              enum: ['No'],
            },
          },
        },
      ],
    },
    targetingPreferences: {
      oneOf: [
        {
          properties: {
            targetingPreferences: {
              enum: ['Interests'],
            },
            targetingPreferencesLabel: {
              type: 'string',
              title: t('pages.custom.solutions.common.selectAllLabel'),
            },
            targetingPreferencesInterests: {
              type: 'array',
              title: '',
              items: {
                type: 'string',
                enum: [
                  t('pages.custom.solutions.common.businessOption'),
                  t('pages.custom.solutions.common.comedyOption'),
                  t('pages.custom.solutions.common.commutingOption'),
                  t('pages.custom.solutions.common.cookingOption'),
                  t('pages.custom.solutions.common.cultureSocietyOption'),
                  t('pages.custom.solutions.common.educationOption'),
                  t('pages.custom.solutions.common.fitnessOption'),
                  t('pages.custom.solutions.common.gamingOption'),
                  t('pages.custom.solutions.common.healthLifestyleOption'),
                  t('pages.custom.solutions.common.inCarOption'),
                  t('pages.custom.solutions.common.parentingOption'),
                  t('pages.custom.solutions.common.partyingOption'),
                  t('pages.custom.solutions.common.podcastsOption'),
                  t('pages.custom.solutions.common.runningOption'),
                  t('pages.custom.solutions.common.studyingOption'),
                  t('pages.custom.solutions.common.techOption'),
                  t('pages.custom.solutions.common.theaterOption'),
                  t('pages.custom.solutions.common.travelOption'),
                  t('pages.custom.solutions.common.tvFilmOption'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            targetingPreferences: {
              enum: ['Real-time Contexts'],
            },
            targetingPreferencesLabel: {
              type: 'string',
              title: t('pages.custom.solutions.common.selectAllLabel'),
            },
            targetingPreferencesRealtimeContexts: {
              type: 'array',
              title: '',
              items: {
                type: 'string',
                enum: [
                  t('pages.custom.solutions.common.chillOption'),
                  t('pages.custom.solutions.common.cookingOption'),
                  t('pages.custom.solutions.common.dinnerOption'),
                  t('pages.custom.solutions.common.focusOption'),
                  t('pages.custom.solutions.common.gamingOption'),
                  t('pages.custom.solutions.common.holidaysOption'),
                  t('pages.custom.solutions.common.partyOption'),
                  t('pages.custom.solutions.common.travelOption'),
                  t('pages.custom.solutions.common.workoutOption'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            targetingPreferences: {
              enum: ['Genres'],
            },
            targetingPreferencesLabel: {
              type: 'string',
              title: t('pages.custom.solutions.common.selectAllLabel'),
            },
            targetingPreferencesGenres: {
              type: 'array',
              title: '',
              items: {
                type: 'string',
                enum: [
                  t('pages.custom.solutions.common.alternativeOption'),
                  t('pages.custom.solutions.common.bluesOption'),
                  t('pages.custom.solutions.common.christianOption'),
                  t('pages.custom.solutions.common.classicalOption'),
                  t('pages.custom.solutions.common.countryOption'),
                  t('pages.custom.solutions.common.easyListeningOption'),
                  t('pages.custom.solutions.common.edmOption'),
                  t('pages.custom.solutions.common.electronicaOption'),
                  t('pages.custom.solutions.common.folkOption'),
                  t('pages.custom.solutions.common.funkOption'),
                  t('pages.custom.solutions.common.hipHopOption'),
                  t('pages.custom.solutions.common.holidayOption'),
                  t('pages.custom.solutions.common.houseOption'),
                  t('pages.custom.solutions.common.indieRockOption'),
                  t('pages.custom.solutions.common.jazzOption'),
                  t('pages.custom.solutions.common.latinOption'),
                  t('pages.custom.solutions.common.metalOption'),
                  t('pages.custom.solutions.common.newAgeOption'),
                  t('pages.custom.solutions.common.popOption'),
                  t('pages.custom.solutions.common.punkOption'),
                  t('pages.custom.solutions.common.reggaeOption'),
                  t('pages.custom.solutions.common.rnbOption'),
                  t('pages.custom.solutions.common.rockOption'),
                  t('pages.custom.solutions.common.soundTrackOption'),
                  t('pages.custom.solutions.common.spokenAudioOption'),
                  t('pages.custom.solutions.common.traditionalOption'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
      ],
    },
    clientProvidingCreative: {
      oneOf: [
        {
          properties: {
            clientProvidingCreative: {
              enum: ['No'],
            },
          },
        },
        {
          properties: {
            clientProvidingCreative: {
              enum: ['Yes - All'],
            },
            uploader_allCreativeFile: {
              type: 'string',
              title: '',
              default: '',
            },
          },
          required: ['uploader_allCreativeFile'],
        },
        {
          properties: {
            clientProvidingCreative: {
              enum: ['Yes - Some'],
            },
            uploader_someCreativeFile: {
              type: 'string',
              title: '',
              default: '',
            },
          },
          required: ['uploader_someCreativeFile'],
        },
      ],
    },
  },
});

const textInputFieldKeys = [
  'geoTargetingDetails',
];

const textInputFields = textInputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const textFieldKeys = [
  'detailsOfEvent',
  'keyDifferentiatorsToHighlight',
  'notesOrInstructions',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const radioFieldKeys = [
  'proposalForm',
  'highlightKeyDifferentiators',
  'targetingPreferences',
  'clientProvidingCreative',
];

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'radioButton' },
}), {});

const dateKeys = [
  'startDate',
  'endDate',
];

const dateFields = dateKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'datePickerField' },
}), {});

const dropDownKeys = [
  'clientProvideAudioCreative',
];

const dropDownFields = dropDownKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const integerInputFieldKeys = [
  'minimumAge',
  'maximumAge',
];

const integerInputField = integerInputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'integerInputField' },
}), {});

const checkBoxesFieldKeys = [
  'targetingPreferencesInterests',
  'targetingPreferencesRealtimeContexts',
  'targetingPreferencesGenres',
];

const checkBoxesFields = checkBoxesFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'checkboxes' },
}), {});

export const CustomSolutionPandoraUISchema = {
  'ui:order': [
    'proposalForm', 'landingPageUrl', 'highlightKeyDifferentiators', 'keyDifferentiatorsToHighlight',
    'focusOnTimedEvent', 'detailsOfEvent', 'startDate', 'endDate', 'geoTargetingType',
    'geoTargetingDetails', 'age', 'clientProvideAudioCreative', 'minimumAge', 'maximumAge', 'gender',
    'targetingPreferences', 'targetingPreferencesLabel', 'targetingPreferencesInterests',
    'targetingPreferencesRealtimeContexts', 'targetingPreferencesGenres', 'clientProvidingCreative',
    'uploader_allCreativeFile', 'uploader_someCreativeFile', 'notesOrInstructions',
  ],

  targetingPreferencesLabel: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '8px',
    },
  },
  landingPageUrl: {
    'ui:widget': 'UrlTextWidget',
    'ui:placeholder': t('pages.custom.solutions.common.landingPageURLHelperLabel'),
  },
  focusOnTimedEvent: {
    'ui:widget': 'dropdown',
    'ui:placeholder': t('pages.custom.solutions.common.flightedCampaignHelperLabel'),
  },
  geoTargetingType: {
    'ui:widget': 'dropdown',
    'ui:style': {
      marginTop: '12px',
    },
  },
  age: {
    'ui:widget': 'checkBoxField',
    'ui:help': t('pages.custom.solutions.common.checkAllLabel'),
  },
  gender: {
    'ui:widget': 'checkBoxField',
    'ui:description': t('pages.custom.solutions.common.checkAllLabel'),
  },
  uploader_allCreativeFile: {
    'ui:style': {
      marginTop: '16px',
    },
    'ui:widget': (props) => (
      <FileUploader
        {...props}
        uploaderType={ProductTypes.CUSTOM_SOLUTIONS_PANDORA_UPLOADER_1}
        totalAllowedFiles={1}
        imageTypes={[]}
        videoTypes={[]}
        documentTypes={['.pdf']}
        supportingText=""
      />
    ),
  },
  uploader_someCreativeFile: {
    'ui:style': {
      marginTop: '16px',
    },
    'ui:widget': (props) => (
      <FileUploader
        {...props}
        uploaderType={ProductTypes.CUSTOM_SOLUTIONS_PANDORA_UPLOADER_2}
        totalAllowedFiles={1}
        imageTypes={[]}
        videoTypes={[]}
        documentTypes={['.pdf']}
        supportingText=""
      />
    ),
  },
  ...textInputFields,
  ...radioFields,
  ...textFields,
  ...dateFields,
  ...dropDownFields,
  ...integerInputField,
  ...checkBoxesFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CustomSolutionPandoraValidate = (formData: any, errors: any, uiSchema: any) => {
  if (formData?.proposalForm === '') {
    errors.proposalForm.addError(t('pages.custom.solutions.common.proposalFormError'));
    errors.addError(true);
  }
  if (formData?.clientProvidingCreative === '') {
    errors.clientProvidingCreative.addError(t('pages.custom.solutions.common.clientProvidingCreativeError'));
    errors.addError(true);
  }
  if (formData?.keyDifferentiators === '') {
    errors.keyDifferentiators.addError(t('pages.custom.solutions.common.keyDifferentiatorsRadioError'));
    errors.addError(true);
  }
  if (formData?.landingPageUrl === '' || !validateUrl(formData?.landingPageUrl)) {
    errors.landingPageUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  if (new Date(formData?.endDate) < new Date(formData?.startDate)) {
    errors.endDate.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.endDateErrorMessage'));
    errors.addError(true);
  }
  validateAgeRange(formData, errors);
  return errors;
};
