import { useSelector } from 'react-redux';
import { selectThemes } from 'services/themesSlice';
import { selectProductTypesList } from 'services/productsSlice';
import SolutionContent from './SolutionContent';

interface CustomTabPanelProps {
  index: number
  value: number | string
  data: any
  animationDir: 'left' | 'right'
  moveToNextTab: () => void
  isBlue?: boolean
}

export const CustomTabPanel = (props: CustomTabPanelProps) => {
  const {
    value, index, data, animationDir, moveToNextTab, isBlue,
  } = props;
  const themes = useSelector(selectThemes);
  const productTypes = useSelector(selectProductTypesList);
  const ecomType = {
    code: 'e-commerce microsite',
    offeringType: 'EcommerceMicrosite',
  };

  const filterProductType = (product) => [...productTypes, ecomType]
    .filter(type => type.offeringType === product)?.[0]?.code;
  const mapProduct = (product, bundle) => (filterProductType(bundle === 'MasterAddon' ? bundle : product));
  const solutionProducts = data?.neonProducts?.map(product => mapProduct(product?.neonProductName, product?.bundle));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: '100%' }}
    >
      {(value === index) && (
        <SolutionContent
          offer={data}
          title={data?.forceOffer?.rlOfferName || data?.neonProducts?.[0]?.name}
          products={solutionProducts}
          moveToNextTab={moveToNextTab}
          themes={themes}
          animationDir={animationDir}
          isBlue={isBlue}
        />
      )}
    </div>
  );
};
