import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { createStyles, makeStyles } from '@mui/styles';
import { IQFormSelect, IQFormTextArea, ValidationProvider } from '@gannettdigital/shared-react-components';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import styled from '@emotion/styled';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import EcommerceType from 'models/EcommerceType';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { generateTooltipTextElement } from 'shared/generateTooltipText';
import schema from './Branding.schema';

interface Props {
  product: EcommerceType;
  onSubmit: (data) => any;
  onBack: () => any;
}

const FormSelectStyled = styled('div')`
  label {
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 8px;
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    selectMenu: {
      left: '-11px',
      '&& .Mui-disabled': {
        padding: 0,
      },
      '&& .Mui-selected': {
        backgroundColor: '#B9E1FF',
      },
    },
    menuList: {
      minWidth: '450px',
      padding: 0,
      border: '1px solid #1665CF',
    },
  }));

export default function BrandingForm(props: Props) {
  const { product, onSubmit, onBack } = props;
  const [openChangeConfirm, setOpenChangeConfirm] = useState(false);
  const [funConfirmSwitch, setFunConfirmSwitch] = useState({ fn: () => {} });

  const templateItems = [
    { value: 'oliver', description: 'Oliver' },
    { value: 'logan', description: 'Logan' },
  ];

  const styles = useStyles();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      selectTemplate: product?.selectTemplate || 'oliver',
      brandingNotes: product?.brandingNotes || '',
      designNotes: product?.designNotes || '',
    },
  });

  const {
    watch, setValue, control, handleSubmit,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
  } = methods;

  const formValues = {
    selectTemplate: watch('selectTemplate'),
  };

  const changeTemplate = (value) => {
    setValue('selectTemplate', value);
    setOpenChangeConfirm(false);
  };

  const showDialogSwitchTemplate = (funSwitch, value) => {
    setFunConfirmSwitch({ fn: () => funSwitch(value) });
    setOpenChangeConfirm(true);
  };

  const switchToTemplateName = formValues.selectTemplate === 'oliver' ? 'Logan' : 'Oliver';

  return (
    <DefaultPageLayout header="Branding" description="Please provide additional notes if applicable."
      onBack={onBack} onContinue={handleSubmit(onSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <GridContainer>
            <GridItem>
              <FormSelectStyled>
                <Controller
                  control={control}
                  name="selectTemplate"
                  render={({ field }) => (
                    <IQFormSelect
                      {...field}
                      name="selectTemplate"
                      items={templateItems}
                      labelText="Selected Template *"
                      MenuProps={{
                        classes: {
                          root: styles.selectMenu, list: styles.menuList,
                        },
                        disableScrollLock: true,
                      }}
                      fullWidth
                      onChange={(e) => {
                        showDialogSwitchTemplate(changeTemplate, e.target.value);
                        field.onChange(field.value);
                      }}
                    />
                  )}
                />
              </FormSelectStyled>
            </GridItem>
            <GridItem>
              <IQFormTextArea
                id="brandingNotes"
                name="brandingNotes"
                labelText="Branding Notes"
                tooltipText={generateTooltipTextElement('This includes font types and colors.', { render: false })}
                tooltipPaddingBottom={12.5}
                tooltipArrow={false}
                tooltipPlacement="top"
                fontLabelWeight="600"
                rowCount={2}
                fullWidth
              />
            </GridItem>
            <GridItem sx={{ label: { pb: '8px' } }}>
              <IQFormTextArea
                id="designNotes"
                name="designNotes"
                labelText="Design Notes"
                fontLabelWeight="600"
                rowCount={2}
                fullWidth
              />
            </GridItem>
          </GridContainer>
          <CoModalConfirm open={openChangeConfirm}
            title="Switch Template"
            description={`Please confirm you would like to switch to ${switchToTemplateName} template.`}
            confirmText="Confirm"
            handleClose={() => setOpenChangeConfirm(false)}
            handleConfirm={funConfirmSwitch.fn}
          />
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
