/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import AxiosAPI from './common/axios';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { thunkBuilder } from './common/functions';

export interface PunchlistState {
  case: StatusType<any>
}

const initialState: PunchlistState = {
  case: {
    status: 'idle',
    message: '',
    content: {},
  },
};

export const getCase = createAsyncThunkCatchErrors(
  'punchlist/getCase',
  async (caseId: string | number) => {
    const response = await AxiosAPI.get(`/integrations/force/case/${caseId}`);
    return response.data;
  },
);

export const setCase = createAction<any>('punchlist/setCase');

export const punchlistSlice = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(setCase, (state, { payload }) => {
        state.case.content = payload;
      });

    thunkBuilder(builder)
      .addCase(getCase, 'case');
  },
);

export const selectCase = (state: RootState) => state.punchlist.case.content;
export const selectCaseStatus = (state: RootState) => state.punchlist.case.status;
export const selectErrorMessage = (state: RootState) => state.punchlist.case.content.errorMessage;

export default punchlistSlice;
