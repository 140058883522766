import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';
import { TopNavStatusEnum } from '../TopNavStatusEnum';

export const ecommerceMenu: MenuType = ({
  label: 'e-Commerce',
  url: '',
  status: 'incomplete',
  offeringType: 'EcommerceMicrosite',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
    {
      label: 'Domain',
      url: Urls.Domain,
      status: 'incomplete',
    },
    {
      label: 'Design',
      url: '',
      status: 'incomplete',
      subSteps: [
        {
          id: 0,
          url: Urls.Assets,
          name: 'Stock Assets',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 1,
          url: Urls.Branding,
          name: 'Branding',
          status: TopNavStatusEnum.NotStarted,
        },
      ],
    },
    {
      label: 'Products',
      url: '',
      status: 'incomplete',
      subSteps: [
        {
          id: 0,
          url: Urls.ProductsDetails,
          name: 'Details',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 1,
          url: Urls.ProductCatalog,
          name: 'Product Catalog',
          status: TopNavStatusEnum.NotStarted,
        },
      ],
    },
    {
      label: 'Store Policies',
      url: '',
      status: 'incomplete',
      subSteps: [
        {
          id: 0,
          url: Urls.Contact,
          name: 'Contact',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 1,
          url: Urls.Returns,
          name: 'Returns',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 2,
          url: Urls.Shipping,
          name: 'Shipping',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 3,
          url: Urls.AgeGate,
          name: 'Age Gate',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 4,
          url: Urls.AdditionalStoreDetails,
          name: 'Additional',
          status: TopNavStatusEnum.NotStarted,
        },
      ],
    },
  ],
});
