import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const CoButtonContinueStyled = styled(Button)`
  &.Mui-disabled {
    color: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  }
  text-transform: none;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;  
  width: 181px;
  height: 60px; 
  font-family: 'Unify Sans';
`;

export interface CoButtonContinueProps {
  /**
   * Button label
   */
  label: string

  /**
   * Default value
   */
  disabled? : boolean

  [rest: string]: any

}

export function CoButtonContinue({
  disabled = false,
  label = 'Continue',
  ...rest
}: CoButtonContinueProps) {
  return (
    <CoButtonContinueStyled
      sx={{ }}
      variant="contained"
      color="primary"
      size="large"
      disabled={disabled}
      endIcon={<ArrowForwardIosIcon />}
      {...rest}
    >
      {label}
    </CoButtonContinueStyled>
  );
}

export default CoButtonContinue;
