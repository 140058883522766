import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  getFiles,
  getGettyAssets,
  ProductTypes,
  selectFilesGetty,
  submitExistingAssets,
} from 'services/fileUploaderSlice';
import {
  clearExistingAssetsCompleted,
  getWebsite,
  selectExistingAssetsCompleted,
  selectWebsites,
} from 'services/websitesSlice';

import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import { Urls } from 'navigation/Urls';

import ContentLoader from 'components/contentLoader/ContentLoader';
import { selectActiveProductId, selectActiveOfferingType } from 'services/navigationSlice';
import ExistingAssetsForm from './ExistingAssetsForm';

export default function ExistingAssets() {
  const uploaderType = 'existing-assets';
  const productType :ProductTypes = ProductTypes.EXISTING_ASSETS;
  const history: any = useLocation();
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);

  const {
    orderId, orderItemId,
  } = useParams();

  // redux
  const dispatch = useDispatch();
  const pageCompleted = useSelector(selectExistingAssetsCompleted);

  const website = useSelector(selectWebsites);
  const filesGetty = useSelector(selectFilesGetty, (prev, curr) => {
    if (prev.status === curr.status) {
      return true;
    }

    return prev.message === curr.message;
  });

  const navigate = useNavigationHandler();
  const topNav = useTopnavMenu(Urls.StockAssets);

  const onBack = () => {
    navigate.to(topNav.previousStep());
  };

  const onSubmit = () => {
    dispatch(submitExistingAssets());
  };

  useEffect(() => {
    dispatch(getGettyAssets({ orderId, orderItemId, productType }));
  }, [orderId]);

  useEffect(() => {
    if (!website?.content?.id) dispatch(getWebsite({ websiteId, offeringType }));
    if (websiteId !== null) dispatch(getFiles({ websiteId, uploaderType, offeringType }));
  }, [websiteId]);

  // after we receive the completed flag from redux, we move to the next page and clear the flag
  useEffect(() => {
    if (pageCompleted) {
      if (history.state?.previousPath.includes('overview')) {
        navigate.to(Urls.Overview);
      } else navigate.to(topNav.nextStep());
      dispatch(clearExistingAssetsCompleted());
    }
  }, [pageCompleted]);

  // @ts-ignore
  return (
    <ContentLoader status={[website.status, filesGetty.status]} message={[website.message, filesGetty.message]}>
      <ExistingAssetsForm
        website={website.content}
        onSubmit={onSubmit}
        onBack={onBack}
        filesGettyList={filesGetty.content.map(f => ({ ...f, tag: 'stock' }))}
      />
    </ContentLoader>
  );
}
