import {
  Button, Card, Chip, Divider, Grid, Typography, useTheme,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface ColumnProps {
  value: string
  title: string
  placeholder: string
}

interface Props {
  textColumns: ColumnProps[]
  status: string
  onDelete: () => void
  onSelect: () => void
}

const TextColumn = (props: ColumnProps) => {
  const { value, title, placeholder } = props;
  const theme = useTheme();

  return (
    <Grid item xs={3}>
      <Grid container direction="column">
        <Grid item>
          <Typography color={theme.palette.text.secondary}>{title}</Typography>
        </Grid>
        <Grid item>
          <Typography color={value ? theme.palette.text.primary : theme.palette.text.secondary}>
            {value || placeholder}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function NeStatusCard(props: Props) {
  const {
    textColumns, status, onDelete, onSelect,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const statusColorMap = {
    complete: theme.palette.success.dark,
    review: theme.palette.warning.dark,
    notStarted: theme.palette.primary.main,
  };

  return (
    <Card className='ne-status-card' sx={{ margin: '15px 0', padding: '25px', cursor: 'pointer' }} onClick={onSelect}>
      <Grid container direction="row">
        {React.Children.toArray(textColumns.map((colProps) => <TextColumn key={colProps.title} {...colProps} />))}
        <Grid item xs={3}>
          <Grid container direction="row">
            <Grid item xs={2}>
              <Divider orientation="vertical" sx={{ width: '1px' }} />
            </Grid>
            <Grid item xs={8} display="flex"
              alignItems="center"
            >
              <Chip
                sx={{ fontWeight: 'bold' }}
                label={t(`components.statusCard.status.${status}`)}
                icon={<CircleIcon sx={{ color: `${statusColorMap[status]} !important` }} />}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={2} display="flex"
              alignItems="center"
            >
              <Button
                className='ne-status-card-delete-button'
                onClick={(event) => { event.stopPropagation(); onDelete(); }}
                sx={{ color: theme.palette.text.primary }}
              >
                <DeleteIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
