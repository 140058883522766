import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";
import {validateUrl} from "pages/generic-product/details/custom-fields/UrlTextField";

const {t} = i18n;
const urlValidationParameter = ['https://facebook.com', 'https://www.facebook.com'];

export const CustomFacebookSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.customFacebook.title'),
  type: 'object',
  required: [
    'campaignResellerAdvertiserWorth$4000',
    'proposalCreatedForThisClient',
    'purposeOrObjectiveOfTheCampaign',
    'trackingRequirements',
    'clientHaveFacebookPixel'
  ],
  properties: {
    FacebookPageUrl: {
      type: 'string',
      title: t('pages.ausProduct.customFacebook.facebookPageUrl'),
      default: '',
      customUrlValidation: true,
      urlValidationParameter,
      urlValidationErrorText: t('pages.ausProduct.customFacebook.facebookPageUrlErrorMessage'),
      description: t('pages.ausProduct.customFacebook.facebookPageUrlDescription'),
    },
    campaignResellerAdvertiserWorth$4000: {
      type: 'string',
      title: t('pages.ausProduct.customFacebook.campaignResellerAdvertiserWorth$4000'),
      default: '',
      enum: [
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
      description: t('pages.ausProduct.customFacebook.campaignResellerAdvertiserWorth$4000Description'),
    },
    proposalCreatedForThisClient: {
      type: 'string',
      title: t('pages.ausProduct.customFacebook.proposalCreatedForThisClient'),
      default: '',
      enum: [
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
    },
    purposeOrObjectiveOfTheCampaign: {
      type: 'string',
      title: t('pages.ausProduct.customFacebook.purposeOrObjectiveOfTheCampaign'),
      default: data?.purposeOrObjectiveOfTheCampaign || '',
      verticalAlignment: true,
      items: {
        enum: [
          t('pages.ausProduct.customFacebook.awareness'),
          t('pages.ausProduct.customFacebook.traffic'),
          t('pages.ausProduct.customFacebook.engagement'),
          t('pages.ausProduct.customFacebook.appPromotion'),
          t('pages.ausProduct.customFacebook.leads'),
          t('pages.ausProduct.customFacebook.sales'),
        ],
      },
    },
    trackingRequirements: {
      type: 'string',
      title: t('pages.ausProduct.linkedin.trackingRequirementsLabel'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.linkedin.trackingRequirementsOpt1'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt2'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt3'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt4'),
      ],
      description: t('pages.ausProduct.customFacebook.trackingRequirementsDescription'),
    },
    additionalInformation: {
      type: 'string',
      title: t('pages.ausProduct.customFacebook.additionalInformation'),
      default: '',
      description: t('pages.ausProduct.customFacebook.additionalInformationDescription'),
    },
    clientHaveFacebookPixel: {
      type: 'string',
      title: t('pages.ausProduct.customFacebook.clientHaveFacebookPixel'),
      default: '',
      enum: [
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
      description: t('pages.ausProduct.customFacebook.clientHaveFacebookPixelDescription'),
    },
    seasonalTacticalOrFlightedCampaign: {
      type: 'string',
      title: t('pages.ausProduct.customFacebook.seasonalTacticalOrFlightedCampaign'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
      description: t('pages.ausProduct.customFacebook.seasonalTacticalOrFlightedCampaignDescription'),
    },
  },
});

const urlTextFieldKeys = [
  'FacebookPageUrl'
]

const textFieldKeys = [
  'additionalInformation'
];

const radioFieldKeys = [
  'purposeOrObjectiveOfTheCampaign'
];

const dropdownFieldKeys = [
  'campaignResellerAdvertiserWorth$4000',
  'proposalCreatedForThisClient',
  'trackingRequirements',
  'clientHaveFacebookPixel',
  'seasonalTacticalOrFlightedCampaign'
];

const urlTextFields = urlTextFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'UrlTextWidget'},
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.tracking.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textInputField'},
}), {});

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'radioButton'},
}), {});

export const CustomFacebookUISchema = {
  'ui:order': [
    'FacebookPageUrl',
    'campaignResellerAdvertiserWorth$4000',
    'proposalCreatedForThisClient',
    'purposeOrObjectiveOfTheCampaign',
    'trackingRequirements',
    'additionalInformation',
    'clientHaveFacebookPixel',
    'seasonalTacticalOrFlightedCampaign'
  ],
  ...urlTextFields,
  ...textFields,
  ...radioFields,
  ...dropdownFields,
};

export const CustomFacebookCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.FacebookPageUrl !== '' && !urlValidationParameter.some(url => formData?.FacebookPageUrl?.startsWith(url))) {
    errors.FacebookPageUrl.addError(t('pages.ausProduct.customFacebook.facebookPageUrlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.campaignResellerAdvertiserWorth$4000 === '') {
    errors.campaignResellerAdvertiserWorth$4000.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.proposalCreatedForThisClient === '') {
    errors.proposalCreatedForThisClient.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.purposeOrObjectiveOfTheCampaign === '') {
    errors.purposeOrObjectiveOfTheCampaign.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.trackingRequirements === '') {
    errors.trackingRequirements.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientHaveFacebookPixel === '') {
    errors.clientHaveFacebookPixel.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
