import AxiosAPI from '../common/axios';

interface ProductsEndpointsFunctionsType<TGetReturn = {}, TPutReturn = {}> {
  getSite: (orderItemId?: string) => Promise<TGetReturn>;
  putSite?: (orderItemId: string, content: any, flush?: boolean) => Promise<TPutReturn>;
}

const ProductsEndpoints: ProductsEndpointsFunctionsType = ({
  getSite: async (orderItemId: string) => {
    const response = await AxiosAPI
      .get(`/product/data/item/${orderItemId}`);
    return {
      ...response.data,
      data: JSON.parse(response.data.data),
    };
  },
  putSite: async (orderItemId: string, content: any, flush?: boolean) => {
    const response = await AxiosAPI
      .put(`/product/data/item/${orderItemId}?flush=${flush || false}`, content);
    return response.data;
  },
});

export default ProductsEndpoints;
