import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";
import HeadingTextLabel from "pages/generic-product/details/custom-fields/HeadingTextLabel";
import {validateUrl} from "pages/generic-product/details/custom-fields/UrlTextField";
import {Grid, Typography} from "@mui/material";
import React from "react";

const {t} = i18n;
const urlValidationParameter = ['https://www.facebook.com', 'https://fb.me', 'https://fb.com'];

export const MontageMotionGraphicSchema: RJSFSchema | any = (data) => ({
  title: t('pages.multiMediaCreatives.montageMotionGraphic.title'),
  type: 'object',
  required: ['videoToWorkFromSupplied', 'isThisBrandAmplifier'],
  properties: {
    videoToWorkFromSupplied: {
      type: 'string',
      title: t('pages.multiMediaCreatives.montageMotionGraphic.videoToWorkFromSupplied'),
      default: data?.videoToWorkFromSupplied || '',
      enum: [
        t('pages.generic-translation.yesOption'),
        t('pages.generic-translation.noOption'),
      ],
    },
    isThisBrandAmplifier: {
      type: 'string',
      title: t('pages.multiMediaCreatives.montageMotionGraphic.isThisBrandAmplifier'),
      default: data?.isThisBrandAmplifier || '',
      enum: [
        t('pages.generic-translation.yesOption'),
        t('pages.generic-translation.noOption'),
      ],
    },
  },
  allOf: [
    {
      if: {
        properties: {
          typeOfWorkNeedsToBeCompletedOnVideo: {
            type: 'array',
            contains: {
              enum: [
                t('pages.multiMediaCreatives.montageMotionGraphic.addVO'),
              ],
            },
          },
        },
      },
      then: {
        properties: {
          copyToBeUsedForVoiceover: {
            type: 'string',
            title: t('pages.multiMediaCreatives.montageMotionGraphic.provideCopyToBeUsedForVoiceover'),
            default: data?.copyToBeUsedForVoiceover || '',
          },
        },
      },
    },
  ],
  dependencies: {
    videoToWorkFromSupplied: {
      oneOf: [
        {
          properties: {
            videoToWorkFromSupplied: {
              enum: ['Yes'],
            },
            typeOfWorkNeedsToBeCompletedOnVideo: {
              type: 'array',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.typeOfWorkNeedsToBeCompletedOnVideo'),
              items: {
                type: 'string',
                enum: [
                  t('pages.multiMediaCreatives.montageMotionGraphic.titleCard'),
                  t('pages.multiMediaCreatives.montageMotionGraphic.minorVid'),
                  t('pages.multiMediaCreatives.montageMotionGraphic.addStatic'),
                  t('pages.multiMediaCreatives.montageMotionGraphic.trimTFrame'),
                  t('pages.multiMediaCreatives.montageMotionGraphic.addVO'),
                ],
              },
              uniqueItems: true,
            },
          },
          required: ['typeOfWorkNeedsToBeCompletedOnVideo'],
        },
        {
          properties: {
            videoToWorkFromSupplied: {
              enum: ['No'],
            },
            videoFocusType: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.videoFocusType'),
              default: data?.videoFocusType || '',
              enum: [
                t('pages.multiMediaCreatives.montageMotionGraphic.companyInfo'),
                t('pages.multiMediaCreatives.montageMotionGraphic.explainer'),
                t('pages.multiMediaCreatives.montageMotionGraphic.howTo'),
                t('pages.multiMediaCreatives.montageMotionGraphic.seasonal'),
                t('pages.multiMediaCreatives.montageMotionGraphic.promotion'),
                t('pages.multiMediaCreatives.montageMotionGraphic.other'),
              ],
            },
            videoCanBeMax15SecsForVoiceOver: {
              type: 'string',
            },
            scene1: {
              type: 'string',
            },
            imageryForScene1: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.imageryForScene1'),
              default: data?.imageryForScene1 || '',
            },
            copyTextSpokenDuringScene1: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.copyTextSpokenDuringScene1'),
              default: data?.copyTextSpokenDuringScene1 || '',
            },
            scene2: {
              type: 'string',
            },
            imageryForScene2: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.imageryForScene2'),
              default: data?.imageryForScene2 || '',
            },
            copyTextSpokenDuringScene2: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.copyTextSpokenDuringScene2'),
              default: data?.copyTextSpokenDuringScene2 || '',
            },
            scene3: {
              type: 'string',
            },
            imageryForScene3: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.imageryForScene3'),
              default: data?.imageryForScene3 || '',
            },
            copyTextSpokenDuringScene3: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.copyTextSpokenDuringScene3'),
              default: data?.copyTextSpokenDuringScene3 || '',
            },
            companyInfoDisplayedOnTheVideo: {
              type: 'string',
            },
            businessName: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.businessName'),
              default: data?.businessName || '',
            },
            businessPhoneNumber: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.businessPhoneNumber'),
              default: data?.businessPhoneNumber || '',
            },
            businessPhysicalAddress: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.businessPhysicalAddress'),
              default: data?.businessPhysicalAddress || '',
            },
            websiteUrl: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.websiteUrl'),
              default: data?.websiteUrl || '',
            },
            facebookPageUrl: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.facebookPageUrl'),
              default: data?.facebookPageUrl || '',
              customUrlValidation: true,
              urlValidationParameter,
              urlValidationErrorText: t('pages.smm.details.facebookUrlErrorMessage'),
            },
            callToActionFinalWordsDisplayed: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.callToActionFinalWordsDisplayed'),
              default: data?.callToActionFinalWordsDisplayed || '',
            },
            textDisclaimerNeedToBePlaced: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.textDisclaimerNeedToBePlaced'),
              default: data?.textDisclaimerNeedToBePlaced || '',
              enum: [
                '',
                t('pages.generic-translation.yesOption'),
                t('pages.generic-translation.noOption'),
              ],
            },
            voiceTalentPreference: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.voiceTalentPreference'),
              default: data?.voiceTalentPreference || '',
              enum: [
                t('pages.multiMediaCreatives.montageMotionGraphic.male'),
                t('pages.multiMediaCreatives.montageMotionGraphic.female'),
                t('pages.multiMediaCreatives.montageMotionGraphic.noPreference'),
              ],
            },
            styleOrToneForTheVoiceover: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.styleOrToneForTheVoiceover'),
              default: data?.styleOrToneForTheVoiceover || '',
              enum: [
                '',
                t('pages.multiMediaCreatives.montageMotionGraphic.informative'),
                t('pages.multiMediaCreatives.montageMotionGraphic.formal'),
                t('pages.multiMediaCreatives.montageMotionGraphic.informal'),
                t('pages.multiMediaCreatives.montageMotionGraphic.friendly'),
                t('pages.multiMediaCreatives.montageMotionGraphic.enthusiastic'),
                t('pages.multiMediaCreatives.montageMotionGraphic.aggressive'),
                t('pages.multiMediaCreatives.montageMotionGraphic.optimistic'),
                t('pages.multiMediaCreatives.montageMotionGraphic.professional'),
                t('pages.multiMediaCreatives.montageMotionGraphic.youthful'),
              ],
            },
            wordsInAParticularWay: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.needWordsInAParticularWay'),
              default: data?.wordsInAParticularWay || '',
            },
            doSpecificKeywordsNeedEmphasis: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.doSpecificKeywordsNeedEmphasis'),
              default: data?.doSpecificKeywordsNeedEmphasis || '',
            },
            wordsThatNeedAPauseOrSpecificPace: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.wordsThatNeedAPauseOrSpecificPace'),
              default: data?.wordsThatNeedAPauseOrSpecificPace || '',
            },
            typeOfBackgroundMusicRequired: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.typeOfBackgroundMusicRequired'),
              default: data?.typeOfBackgroundMusicRequired || '',
            },
            viewMusicGenres: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.viewMusicGenres'),
            },
          },
          required: ['videoFocusType', 'imageryForScene1', 'copyTextSpokenDuringScene1', 'imageryForScene2', 'copyTextSpokenDuringScene2',
            'imageryForScene3', 'copyTextSpokenDuringScene3', 'voiceTalentPreference', 'typeOfBackgroundMusicRequired'],
        }
      ],
    },
    videoFocusType: {
      oneOf: [
        {
          properties: {
            videoFocusType: {
              enum: [t('pages.multiMediaCreatives.montageMotionGraphic.other')],
            },
            describeOtherVideoFocusType: {
              type: 'string',
              title: t('pages.multiMediaCreatives.montageMotionGraphic.describeOtherVideoFocusType'),
              default: data?.describeOtherVideoFocusType || '',
            },
          },
        },
      ],
    }
  }
});

const textFieldKeys = [
  'imageryForScene1',
  'imageryForScene2',
  'imageryForScene3',
  'copyTextSpokenDuringScene1',
  'copyTextSpokenDuringScene2',
  'copyTextSpokenDuringScene3',
  'businessName',
  'callToActionFinalWordsDisplayed',
  'wordsInAParticularWay',
  'doSpecificKeywordsNeedEmphasis',
  'wordsThatNeedAPauseOrSpecificPace',
  'typeOfBackgroundMusicRequired',
  'describeOtherVideoFocusType',
];

const dropdownFieldKeys = [
  'videoToWorkFromSupplied',
  'styleOrToneForTheVoiceover',
];

const checkboxFieldKeys = [
  'typeOfWorkNeedsToBeCompletedOnVideo',
];

const textAreaKeys = [
  'copyToBeUsedForVoiceover',
];

const phoneNumberKeys = [
  'businessPhoneNumber',
];

const urlTextKeys = [
  'facebookPageUrl',
  'websiteUrl',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const checkboxFields = checkboxFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'checkBoxField' },
}), {});

const textAreaFields = textAreaKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const phoneNumberFields = phoneNumberKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'phoneNumberTextField' },
}), {});

const urlTextFields = urlTextKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'UrlTextWidget' },
}), {});

export const MontageMotionGraphicUISchema = {
  'ui:order': [
    'videoToWorkFromSupplied', 'typeOfWorkNeedsToBeCompletedOnVideo', 'copyToBeUsedForVoiceover', 'videoFocusType', 'describeOtherVideoFocusType',
    'videoCanBeMax15SecsForVoiceOver', 'scene1', 'imageryForScene1', 'copyTextSpokenDuringScene1', 'scene2', 'imageryForScene2', 'copyTextSpokenDuringScene2',
    'scene3', 'imageryForScene3', 'copyTextSpokenDuringScene3', 'companyInfoDisplayedOnTheVideo', 'businessName', 'businessPhoneNumber',
    'businessPhysicalAddress', 'websiteUrl', 'facebookPageUrl', 'callToActionFinalWordsDisplayed', 'textDisclaimerNeedToBePlaced',
    'voiceTalentPreference', 'styleOrToneForTheVoiceover',   'wordsInAParticularWay', 'doSpecificKeywordsNeedEmphasis',
    'wordsThatNeedAPauseOrSpecificPace', 'typeOfBackgroundMusicRequired', 'viewMusicGenres', 'isThisBrandAmplifier'
  ],
  videoToWorkFromSupplied: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.multiMediaCreatives.montageMotionGraphic.videoToWorkFromSuppliedErrorMsg')}/>
  },
  isThisBrandAmplifier: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.multiMediaCreatives.montageMotionGraphic.isThisBrandAmplifierErrorMsg')}/>
  },
  videoFocusType: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.multiMediaCreatives.montageMotionGraphic.videoFocusTypeErrorMsg')}/>
  },
  voiceTalentPreference: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.multiMediaCreatives.montageMotionGraphic.voiceTalentPreferenceErrorMsg')}/>
  },
  businessPhysicalAddress: { "ui:widget": "addressField" },
  videoCanBeMax15SecsForVoiceOver: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={3}>
          <Typography fontSize={16} variant="subtitle1"
                      style={{display: 'inline-block'}}> {t('pages.multiMediaCreatives.montageMotionGraphic.videoCanBeMax15SecsForVoiceOver')} </Typography>
        </Grid>
    ),
  },
  scene1: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={3} mb={-2}>
          <Typography fontSize={22} variant="h6"
                      style={{display: 'inline-block'}}> {t('pages.multiMediaCreatives.montageMotionGraphic.scene1')} </Typography>
        </Grid>
    ),
  },
  scene2: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={3} mb={-2}>
          <Typography fontSize={22} variant="h6"
                      style={{display: 'inline-block'}}> {t('pages.multiMediaCreatives.montageMotionGraphic.scene2')} </Typography>
        </Grid>
    ),
  },
  scene3: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={3} mb={-2}>
          <Typography fontSize={22} variant="h6"
                      style={{display: 'inline-block'}}> {t('pages.multiMediaCreatives.montageMotionGraphic.scene3')} </Typography>
        </Grid>
    ),
  },
  companyInfoDisplayedOnTheVideo: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={3} mb={-2}>
          <Typography fontSize={22} variant="h6"
                      style={{display: 'inline-block'}}> {t('pages.multiMediaCreatives.montageMotionGraphic.companyInfoDisplayedOnTheVideo')} </Typography>
        </Grid>
    ),
  },
  viewMusicGenres: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography fontSize={14}>
            <a href={t('pages.multiMediaCreatives.montageMotionGraphic.viewMusicGenresLink')} target="_blank"
               rel="noreferrer">
              <Typography component="span" color="primary" fontSize={14}>
                {t('pages.multiMediaCreatives.montageMotionGraphic.viewMusicGenres')}
              </Typography>
            </a>
          </Typography>
        </Grid>
    ),
  },
  textDisclaimerNeedToBePlaced: {
    'ui:widget': (props) => <Dropdown {...props}/>,
    'ui:style': {
      marginBottom: '20px',
    }
  },
  ...checkboxFields,
  ...textFields,
  ...dropdownFields,
  ...textAreaFields,
  ...phoneNumberFields,
  ...urlTextFields,
};

export const MontageMotionGraphicCustomValidate = (formData: any, errors, uiSchema) => {
  if ((formData?.videoToWorkFromSupplied === 'Yes')){
    if (formData?.typeOfWorkNeedsToBeCompletedOnVideo?.includes(
        t('pages.multiMediaCreatives.montageMotionGraphic.titleCard'),
        t('pages.multiMediaCreatives.montageMotionGraphic.minorVid'),
        t('pages.multiMediaCreatives.montageMotionGraphic.addStatic'),
        t('pages.multiMediaCreatives.montageMotionGraphic.trimTFrame'),
    )) {
      if (formData?.copyToBeUsedForVoiceover === '') {
        errors.copyToBeUsedForVoiceover
        .addError(t('pages.multiMediaCreatives.montageMotionGraphic.typeOfWorkErrorMsg'));
        errors.addError(true);
      }
    }
    if (formData?.typeOfWorkNeedsToBeCompletedOnVideo?.length === 0) {
      errors.typeOfWorkNeedsToBeCompletedOnVideo.addError(t('pages.multiMediaCreatives.montageMotionGraphic.typeOfWorkErrorMsg'));
      errors.addError(true);
    }
  }

  if ((formData?.videoToWorkFromSupplied === 'No')){
    if (formData?.facebookPageUrl !== ''
        && !urlValidationParameter.some(url => formData?.facebookPageUrl?.startsWith(url))) {
      errors.facebookPageUrl.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.facebookUrlErrorMessage'));
      errors.addError(true);
    }
    if (formData?.websiteUrl !== '' && !validateUrl(formData?.websiteUrl)) {
      errors.websiteUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
    if (formData?.typeOfBackgroundMusicRequired === '') {
      errors.typeOfBackgroundMusicRequired.addError(t('pages.multiMediaCreatives.montageMotionGraphic.typeOfBackgroundMusicRequiredErrorMsg'));
      errors.addError(true);
    }
    if (formData?.imageryForScene1 === '') {
      errors.imageryForScene1.addError(t('pages.multiMediaCreatives.montageMotionGraphic.imageryForSceneErrorMsg'));
      errors.addError(true);
    }
    if (formData?.imageryForScene2 === '') {
      errors.imageryForScene2.addError(t('pages.multiMediaCreatives.montageMotionGraphic.imageryForSceneErrorMsg'));
      errors.addError(true);
    }
    if (formData?.imageryForScene3 === '') {
      errors.imageryForScene3.addError(t('pages.multiMediaCreatives.montageMotionGraphic.imageryForSceneErrorMsg'));
      errors.addError(true);
    }
    if (formData?.copyTextSpokenDuringScene1 === '') {
      errors.copyTextSpokenDuringScene1.addError(t('pages.multiMediaCreatives.montageMotionGraphic.copyTextSpokenDuringSceneErrorMsg'));
      errors.addError(true);
    }
    if (formData?.copyTextSpokenDuringScene2 === '') {
      errors.copyTextSpokenDuringScene2.addError(t('pages.multiMediaCreatives.montageMotionGraphic.copyTextSpokenDuringSceneErrorMsg'));
      errors.addError(true);
    }
    if (formData?.copyTextSpokenDuringScene3 === '') {
      errors.copyTextSpokenDuringScene3.addError(t('pages.multiMediaCreatives.montageMotionGraphic.copyTextSpokenDuringSceneErrorMsg'));
      errors.addError(true);
    }
  }
  return errors;
};