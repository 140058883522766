import { Typography, Box } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { headingStyle } from '../Business/BusinessSection';

export const renderChatLocation = (
  location,
  navigate,
  product,
  offeringType,
  url,
  isEditable,
) => (
  <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid black' }}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {editButton(isEditable, () => navigate.to(
        url,
        product.id,
      ))}
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{location?.chatListedLocationName}</Typography>
    </Box>
    <Box sx={{ marginTop: '15px' }}>
      <GridItem sizes={[6, 6]}>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Chat Listed Business Address</Typography>
            <Typography>{location?.addressOne}</Typography>
            {location?.addressTwo && <Typography>{location?.addressTwo}</Typography>}
            <Typography>{`${location?.city}, ${location?.stateProvinceRegion} ${location?.zipcode}`}</Typography>
            <Typography>{location?.country}</Typography>
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Do Not Disclose Address</Typography>
            <Typography>{location?.doNotDisclose ? 'Yes' : 'No'}</Typography>
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Chat Listed Support Email Address</Typography>
            {displayNotRequiredField('email', location?.chatListedSupportEmail)}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Service Areas</Typography>
            {location?.serviceAreas?.length
              ? location.serviceAreas.map(area => (
                <Typography key={area.value}>{area.label}</Typography>
              )) : <Typography fontWeight="bold">No service areas</Typography>}
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Service Area Notes</Typography>
            {location?.serviceAreaNotes
              ? (location.serviceAreaNotes
                .replaceAll('\\n', '_br_')
                .replaceAll('\\', '')
                .split('_br_').map(item => <Typography>{item}</Typography>)
              ) : <Typography fontWeight="bold">No service area notes</Typography>}
          </Box>
          {location?.chatListedHoursOfOperation && (
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Location Hours of Operation</Typography>
              {Array.isArray(location?.chatListedHoursOfOperation)
                ? location.chatListedHoursOfOperation?.map(hours => (
                  <Typography>{`${hours.dayOfWeek}: From ${hours.from}-${hours.to}`}</Typography>))
                : (location.chatListedHoursOfOperation
                  .replaceAll('\\n', '_br_')
                  .replaceAll('\\', '')
                  .split('_br_').map(item => <Typography>{item}</Typography>))}
            </Box>
          )}
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Chat Email Leads</Typography>
            {location?.emails?.length
              ? location.emails.map(email => <Typography key={email.email}>{email.email}</Typography>)
              : <Typography fontWeight="bold">No emails</Typography>}
          </Box>
        </Box>
      </GridItem>
    </Box>
  </GridItem>
);
