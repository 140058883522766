import { Children, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import schemaValidate from 'components/schemaValidate';
import i18n from 'i18n/i18n';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { tooltip } from 'pages/general-questions/form-sections/Tracking';
import ErrorText from 'components/errorText/ErrorText';
import NeCheckbox from 'components/checkbox/NeCheckbox';
import { getBusinessLocations, selectBusinessLocations, selectProviders } from 'services/businessLocationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrdersContent } from 'services/ordersSlice';
import { useParams } from 'react-router';
import { getClients, selectClients } from 'services/clientsSlice';
import { getNotesAndAttachments, selectNotesAndAttachments } from 'services/cefSlice';
import schema from './DetailsPage.schema';
import ContactForm from './ContactForm';
import { emptyContact, siteTypeOptions, yesNoOptions } from './DetailsPageConstants';

const { t } = i18n;
interface Props {
  product: any; // product type to be created
  onSubmit: (data) => void;
  onBack: () => void;
}

export const inputStyles = {
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'common.white',
    height: '40px',
    '& fieldset': {
      borderColor: 'info.dark',
      borderRadius: '2px',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'primary.main',
    },
  },
  '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
    height: 'auto',
  },
  '& .MuiFormGroup-root[role=radiogroup] .MuiFormControlLabel-root': {
    width: 'fit-content',
    display: 'flex',
  },
  '& .MuiFormGroup-root': {
    flexDirection: 'row',
  },
};

export default function DetailsPageForm(props: Props) {
  const { product } = props;
  const { orderId, orderItemId } = useParams();
  const dispatch = useDispatch();
  const [siteType, setSiteType] = useState(product?.siteType || '');
  const [mainContact, setMainContact] = useState(product?.mainContact || '');
  const [accessContact, setAccessContact] = useState(product?.accessContact || '');
  const [isSiteLive, setIsSiteLive] = useState(product?.isSiteLive || '');
  const [isContactInfoAvailable, setIsContactInfoAvailable] = useState(product?.isContactInfoAvailable || '');
  const [clientOptions, setClientOptions] = useState([]);
  const { businessId } = useSelector(selectOrdersContent);
  const locations = useSelector(selectBusinessLocations);
  const providers = useSelector(selectProviders);
  const clients = useSelector(selectClients);
  const notesAndAttachments = useSelector(selectNotesAndAttachments);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      websiteUrl: product?.websiteUrl || '',
      siteType: product?.siteType || '',
      mainContact: product?.mainContact || '',
      accessContact: product?.accessContact || '',
      isSiteLive: product?.isSiteLive || '',
      byosAcknowledgement: product?.byosAcknowledgement || false,
      isContactInfoAvailable: product?.isContactInfoAvailable || '',
      developerName: product?.developerName || '',
      developerEmail: product?.developerEmail || '',
      developerPhone: product?.developerPhone || '',
      developerPhoneExtension: product?.developerPhoneExtension || '',
      mainContactName: product?.mainContactName || '',
      mainContactEmail: product?.mainContactEmail || '',
      mainContactPhone: product?.mainContactPhone || '',
      mainContactPhoneExtension: product?.mainContactPhoneExtension || '',
      accessContactName: product?.accessContactName || '',
      accessContactEmail: product?.accessContactEmail || '',
      accessContactPhone: product?.accessContactPhone || '',
      accessContactPhoneExtension: product?.accessContactPhoneExtension || '',
    },
  });

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState: {
      isValid,
      errors,
      isDirty,
      isSubmitting,
      isSubmitSuccessful,
    },
  } = methods;

  const isLocaliqSiteType = watch('siteType') === 'localiqSite';
  const isByosSiteType = watch('siteType') === 'byos';
  const isDevContactInfoAvailable = watch('isContactInfoAvailable') === 'yes';
  const isMainContactOther = watch('mainContact') === 'other';
  const isAccessContactOther = watch('accessContact') === 'other';

  useEffect(() => {
    dispatch(getClients(orderId));
    dispatch(getNotesAndAttachments(orderItemId));
  }, []);

  useEffect(() => {
    if (businessId) dispatch(getBusinessLocations(businessId));
  }, [businessId]);

  useEffect(() => {
    if (!product?.websiteUrl) {
      if (locations.length > 0 && locations?.[locations.length - 1].locationWebsite) {
        setValue('websiteUrl', locations[locations.length - 1].locationWebsite);
      } else if (providers.length > 0 && providers?.[providers.length - 1].locationWebsite) {
        setValue('websiteUrl', providers[providers.length - 1].locationWebsite);
      }
    }
  }, [locations, providers]);

  useEffect(() => {
    if (clients.length > 0) {
      const formatClients = clients.map(client => ({
        // eslint-disable-next-line max-len
        label: `${client.contact.clientFullName}\nMobile: ${client.contactPhoneNumberList[0].phoneNumber}${client.contactPhoneNumberList[0].ext
          && ` ext.${client.contactPhoneNumberList[0].ext}`}\n${client.contact.email}`,
        value: client.contact.id.toString(),
      }));

      setClientOptions([...formatClients, {
        label: 'Other',
        value: 'other',
      }]);
    }
  }, [clients]);

  useEffect(() => {
    if (!isDevContactInfoAvailable) {
      setValue('developerName', '');
      setValue('developerEmail', '');
      setValue('developerPhone', '');
      setValue('developerPhoneExtension', '');
    }
  }, [isDevContactInfoAvailable]);

  const setContact = (type, {
    name, email, phone, ext,
  } = emptyContact) => {
    setValue(`${type}ContactName` as any, name);
    setValue(`${type}ContactEmail` as any, email);
    setValue(`${type}ContactPhone` as any, phone);
    setValue(`${type}ContactPhoneExtension` as any, ext);
  };

  const checkFilteredContact = (field, isOther, contactType) => {
    const contact = getValues(field);
    if (contact && !isOther && clients.length > 0) {
      const selectedClient = clients.filter(client => client.contact.id.toString() === contact)[0];
      setContact(contactType, {
        name: selectedClient.contact.clientFullName,
        email: selectedClient.contact.email,
        phone: selectedClient.contactPhoneNumberList[0].phoneNumber,
        ext: selectedClient.contactPhoneNumberList[0].ext,
      });
    } else if (isOther && product[field] === 'other') {
      setContact(contactType, {
        name: product[`${contactType}ContactName`],
        email: product[`${contactType}ContactEmail`],
        phone: product[`${contactType}ContactPhone`],
        ext: product[`${contactType}ContactPhoneExtension`],
      });
    } else setContact(contactType);
  };

  useEffect(() => {
    checkFilteredContact('mainContact', isMainContactOther, 'main');
  }, [watch('mainContact'), product, clients]);

  useEffect(() => {
    checkFilteredContact('accessContact', isAccessContactOther, 'access');
  }, [watch('accessContact'), clients]);

  const handleFormSubmit = (data) => {
    props.onSubmit({ ...data, notesAndAttachments });
  };

  // eslint-disable-next-line consistent-return
  const getTooltipForNoOption = (option, name) => {
    if (option.value === 'no' && name === 'isSiteLive') {
      return tooltip(t('pages.seo.details.siteLiveTooltip'), <InfoOutlinedIcon />);
    }

    if (option.value === 'no' && name === 'isContactInfoAvailable') {
      return tooltip(t('pages.seo.details.contactInfoTooltip'), <InfoOutlinedIcon />);
    }
  };

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={props.onBack}
      header="Details"
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container xs={9} sx={{ ...inputStyles }}>
            <Grid item mb={3} xs={12}>
              <Typography variant="h5" component="h2">
                Website
              </Typography>
            </Grid>
            <Grid item mb={3} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}
                  color={errors.websiteUrl ? 'error.main' : 'common.black'}
                >
                  {t('pages.seo.details.websiteUrl.label')}
                </Typography>
              </Grid>
              <TextField
                hiddenLabel
                required
                name="websiteUrl"
                error={!!errors.websiteUrl}
                fullWidth
                {...register('websiteUrl', {
                  validate: (value) => schemaValidate(value, 'websiteUrl', schema),
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorText
                hasError={!!errors.websiteUrl}
                errorText={t('pages.seo.details.websiteUrl.error')}
              />
            </Grid>
            <Grid item mb={3} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}>
                  {t('pages.seo.details.siteType')}
                </Typography>
                <RadioGroup
                  aria-labelledby="site-type"
                  name="siteType"
                  value={siteType}
                  onChange={(e) => setSiteType(e.target.value)}
                >
                  {siteTypeOptions.map(option => (
                    <FormControlLabel
                      key={option.value}
                      {...register('siteType', {
                        validate: (value) => schemaValidate(value, 'siteType', schema),
                      })}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
            {
              isLocaliqSiteType && (
              <Grid item mb={3} xs={12}>
                <Grid mb={1}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}>
                    {t('pages.seo.details.siteLive')}
                  </Typography>
                  <RadioGroup
                    aria-labelledby="site-live"
                    name="isSiteLive"
                    value={isSiteLive}
                    onChange={(e) => setIsSiteLive(e.target.value)}
                  >
                    {yesNoOptions.map(option => (
                      <FormControlLabel
                        key={option.value}
                        {...register('isSiteLive', {
                          validate: (value) => schemaValidate(value, 'isSiteLive', schema),
                        })}
                        value={option.value}
                        control={(<Radio />)}
                        label={(
                          <Typography variant="body1">
                            {option.label}
                            {getTooltipForNoOption(option, 'isSiteLive')}
                          </Typography>
                      )}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
              </Grid>
              )
            }
            {
              isByosSiteType
              && (
                <>
                  <Grid mb={3}>
                    <Typography variant="h6" component="h2">
                      BYOS
                    </Typography>
                  </Grid>
                  {notesAndAttachments?.hasAttachments && (
                    <Grid item xs={12} mb={3}>
                      <Typography sx={{ display: 'block' }}>
                        <Link href={notesAndAttachments?.viewUrl} target="_blank">
                          {notesAndAttachments?.attachmentTitle}
                        </Link>
                      </Typography>
                    </Grid>
                  )}
                  <Grid mb={3}>
                    <Typography variant="subtitle2" component="h3" fontSize={16}
                      color={errors.byosAcknowledgement ? 'error.main' : 'common.black'} mb={1}
                    >
                      {t('pages.seo.details.acknowledgement.label')}
                    </Typography>
                    <NeCheckbox
                      label={t('pages.seo.details.acknowledgement.note')}
                      name="byosAcknowledgement"
                      field="byosAcknowledgement"
                      required
                    />
                  </Grid>
                  <Grid item mb={3} xs={12}>
                    <Grid mb={1}>
                      <Typography variant="subtitle2" component="h3" fontSize={16}
                        mb={1}
                      >
                        {t('pages.seo.details.contactInfoLabel')}
                      </Typography>
                      <RadioGroup
                        aria-labelledby="isContactInfoAvailable"
                        name="isContactInfoAvailable"
                        value={isContactInfoAvailable}
                        onChange={(e) => setIsContactInfoAvailable(e.target.value)}
                      >
                        {yesNoOptions.map(option => (
                          <FormControlLabel
                            key={option.value}
                            {...register('isContactInfoAvailable', {
                              validate: (value) => schemaValidate(value, 'isContactInfoAvailable', schema),
                            })}
                            value={option.value}
                            control={<Radio />}
                            label={(
                              <Typography variant="body1">
                                {option.label}
                                {getTooltipForNoOption(option, 'isContactInfoAvailable')}
                              </Typography>
                      )}
                          />
                        ))}
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </>
              )
            }
            {
              isByosSiteType && isDevContactInfoAvailable
              && (
              <ContactForm
                name="developerName"
                email="developerEmail"
                phone="developerPhone"
                phoneExtension="developerPhoneExtension"
                register={register}
                errors={errors}
                schema={schema}
                phoneRequired={false}
              />
              )
            }
            <Grid item mb={3} xs={12}>
              <Typography variant="h5" component="h2">
                Contact Info
              </Typography>
            </Grid>
            <Grid item mb={3} xs={12}
              sx={{
                '& .MuiFormGroup-root[role=radiogroup] .MuiFormControlLabel-root': {
                  width: 'fit-content',
                  display: 'flex',
                  alignItems: 'flex-start',
                },
              }}
            >
              <Grid mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}>
                  {t('pages.seo.details.mainContactLabel')}
                </Typography>
                <RadioGroup
                  aria-labelledby="main-contact"
                  name="mainContact"
                  value={mainContact}
                  onChange={(e) => setMainContact(e.target.value)}
                  sx={{ width: '100%' }}
                >
                  {clientOptions.map(option => (
                    <FormControlLabel
                      key={option.value}
                      {...register('mainContact', {
                        validate: (value) => schemaValidate(value, 'mainContact', schema),
                      })}
                      value={option.value}
                      control={<Radio />}
                      sx={{
                        width: '50% !important',
                        marginRight: '0 !important',
                        '@media (max-width: 780px)': {
                          width: '100% !important',
                        },
                      }}
                      label={(
                        <Typography paragraph style={{ marginTop: '8px' }}>
                          {Children.toArray(option.label.split('\n').map((line) => (
                            <>
                              {line}
                              {line !== 'Other' ? <br /> : null}
                            </>
                          )))}
                        </Typography>
                      )}
                    />
                  ))}
                </RadioGroup>
              </Grid>
              {
                isMainContactOther && (
                  <ContactForm
                    name="mainContactName"
                    email="mainContactEmail"
                    phone="mainContactPhone"
                    phoneExtension="mainContactPhoneExtension"
                    register={register}
                    errors={errors}
                    schema={schema}
                    phoneRequired
                  />
                )
              }
            </Grid>
            <Grid marginBottom={3}
              sx={{
                '& .MuiFormGroup-root[role=radiogroup] .MuiFormControlLabel-root': {
                  width: 'fit-content',
                  display: 'flex',
                  alignItems: 'flex-start',
                },
              }}
            >
              <Grid mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}>
                  {t('pages.seo.details.accessContactLabel')}
                </Typography>
                <RadioGroup
                  aria-labelledby="access-contact"
                  name="accessContact"
                  value={accessContact}
                  onChange={(e) => setAccessContact(e.target.value)}
                  sx={{ width: '100' }}
                >
                  {clientOptions.map(option => (
                    <FormControlLabel
                      key={option.value}
                      {...register('accessContact', {
                        validate: (value) => schemaValidate(value, 'accessContact', schema),
                      })}
                      value={option.value}
                      control={<Radio />}
                      sx={{
                        width: '50% !important',
                        marginRight: '0 !important',
                        '@media (max-width: 780px)': {
                          width: '100% !important',
                        },
                      }}
                      label={(
                        <Typography paragraph style={{ marginTop: '8px' }}>
                          {Children.toArray(option.label.split('\n').map((line) => (
                            <>
                              {line}
                              {line !== 'Other' ? <br /> : null}
                            </>
                          )))}
                        </Typography>
                      )}
                    />
                  ))}
                </RadioGroup>
              </Grid>
              {
                isAccessContactOther && (
                  <ContactForm
                    name="accessContactName"
                    email="accessContactEmail"
                    phone="accessContactPhone"
                    phoneExtension="accessContactPhoneExtension"
                    register={register}
                    errors={errors}
                    schema={schema}
                    phoneRequired
                  />
                )
              }
            </Grid>
          </Grid>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
