import React from 'react';

import { IQButtonSaveAndClose, IQTopNavContainer } from '@gannettdigital/shared-react-components';
import { Box } from '@mui/material';

import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { backgroundDefaultColor } from 'styles/common_styles';

interface Props {
  children?: any
  popup?: boolean
  toggleMenu?: boolean
}

export default function TopnavMenuContainer(props: Props) {
  const navigate = useNavigationHandler();

  const handleSaveAndClose = (): void => {
    navigate.saveAndClose();
  };

  const widthFactor = props.toggleMenu ? '123px' : '260px';

  return (
    <Box sx={{
      display: 'flex',
      position: 'fixed',
      width: props.popup ? '100%' : `calc(100% - ${widthFactor})`,
      zIndex: '100',
      minHeight: '64px',
      maxHeight: '65px',
      '.MuiContainer-root': { maxWidth: '100%', minHeight: '64px' },
      '.MuiContainer-root > div': { maxWidth: '100%', minHeight: '64px !important' },
      '& header': { backgroundColor: `${backgroundDefaultColor} !important` },
    }}
    >
      <IQTopNavContainer>
        <Box sx={{
          flexGrow: 1,
          display: {
            xs: 'block',
            sm: 'flex',
          },
          maxWidth: {
            xs: '320px',
            sm: '920px',
          },
          justifyContent: 'flex-start',
          mt: '14px',
          button: { fontWeight: 400, fontSize: '14px', lineHeight: '18px' },
          mx: 'auto',
          '.MuiTabs-root': { maxWidth: '100%' },
          '.MuiTabs-root > .MuiTabs-scroller': { ml: '-40px' },
        }}
        >
          {props.children}
        </Box>
        <Box sx={{
          flexGrow: 0,
          padding: '12px',
          button: {
            fontWeight: 400, textTransform: 'none', fontSize: '16px', lineHeight: '24px',
          },
          right: '32px',
          position: 'absolute',
        }}
        >
          <IQButtonSaveAndClose handleSaveAndCloseClick={handleSaveAndClose} />
        </Box>
      </IQTopNavContainer>
    </Box>
  );
}
