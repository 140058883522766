import { FormProvider, useForm } from 'react-hook-form';
import { IQFormTextArea, ValidationProvider } from '@gannettdigital/shared-react-components';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import YesNoRadio from 'components/yesNo/YesNoRadio';
import EcommerceType from 'models/EcommerceType';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import React, { useEffect } from 'react';
import schema from './AdditionalStoreDetails.schema';

interface Props {
  product: EcommerceType;
  onSubmit: (data) => any;
  onBack: () => any;
}

export default function AdditionalStoreDetailsForm(props: Props) {
  const { product, onSubmit, onBack } = props;

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      uniqueTaxSituationsYesNo: product?.uniqueTaxSituationsYesNo,
      uniqueTaxSituations: product?.uniqueTaxSituations,
      taxInformation: product?.taxInformation,
      onlineGiftCardsForSale: product?.onlineGiftCardsForSale,
      giftCardsNotes: product?.giftCardsNotes,
      additionalNotesStoreDetails: product?.additionalNotesStoreDetails,
    },
  });

  const {
    handleSubmit, watch, getValues, setValue, unregister, formState: { isDirty, isSubmitSuccessful, isSubmitting },
  } = methods;

  useEffect(() => {
    if (getValues('uniqueTaxSituationsYesNo') === 'no') {
      unregister('uniqueTaxSituations');
      setValue('uniqueTaxSituations', '');
    }
  }, [watch('uniqueTaxSituationsYesNo')]);

  useEffect(() => {
    if (getValues('onlineGiftCardsForSale') === 'no') {
      unregister('giftCardsNotes');
      setValue('giftCardsNotes', '');
    }
  }, [watch('onlineGiftCardsForSale')]);

  return (
    <DefaultPageLayout header="Additional Store Details"
      onBack={onBack} onContinue={handleSubmit(onSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <GridContainer>
            <YesNoRadio
              label="Unique tax situations"
              name="uniqueTaxSituationsYesNo"
              shouldClearValues={false}
            >
              <GridItem>
                <IQFormTextArea
                  id="uniqueTaxSituations"
                  name="uniqueTaxSituations"
                  labelText="Unique Tax Situations"
                  fontLabelWeight="600"
                  rowCount={2}
                  fullWidth
                />
              </GridItem>
            </YesNoRadio>
            <GridItem>
              <IQFormTextArea
                id="taxInformation"
                name="taxInformation"
                labelText="Tax Information"
                fontLabelWeight="600"
                rowCount={2}
                fullWidth
              />
            </GridItem>
            <YesNoRadio
              label="Online gift cards for sale"
              name="onlineGiftCardsForSale"
              shouldClearValues={false}
            >
              <GridItem>
                <IQFormTextArea
                  id="giftCardsNotes"
                  name="giftCardsNotes"
                  labelText="Gift Card Notes"
                  fontLabelWeight="600"
                  rowCount={2}
                  fullWidth
                />
              </GridItem>
            </YesNoRadio>
            <GridItem>
              <IQFormTextArea
                id="additionalNotesStoreDetails"
                name="additionalNotesStoreDetails"
                labelText="Additional Notes"
                fontLabelWeight="600"
                rowCount={2}
                fullWidth
              />
            </GridItem>
          </GridContainer>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
