import * as yup from 'yup';

const schema = {
  localiqWebsite: yup.string().required(),
  localiqWebsiteLive: yup.string().required(),
  businessWebsiteUrl: yup.string().required('This is a required field.').nullable(),
  manualInputUrl: yup.string().url('Please enter a valid URL that includes either http:// or https://')
    .required('This is a required field.'),
  temporaryWebsiteUrl: yup.string().url('Please enter a valid URL that includes either http:// or https://')
    .required('This is a required field.'),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
