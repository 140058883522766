import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { Grid, Typography } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { StepperRootProps } from 'layouts/StepperLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCustomWebsite, putCustomWebsiteDesignInfo, selectCustomWebsite } from 'services/customWebsiteSlice';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { selectActiveProductId } from 'services/navigationSlice';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import ErrorText from 'components/errorText/ErrorText';
import schema from './Design.schema';

export default function CustomWebsiteDesign(props: StepperRootProps) {
  const { t } = useTranslation();
  const { orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const customWebsite = useSelector(selectCustomWebsite);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      customDesignRequirements: '',
    },
  });

  const {
    getValues, handleSubmit, reset, register, formState: {
      isDirty, isSubmitting, isSubmitSuccessful, isValid, errors,
    },
  } = methods;

  useEffect(() => {
    reset({
      customDesignRequirements: customWebsite.customDesignInfo,
    });
  }, [customWebsite]);

  const onFormSubmit = () => {
    const data = getValues();
    const content = {
      customDesignRequirements: data.customDesignRequirements,
      customFormRequirements: customWebsite.customFormRequirements || '',
      customFormUrls: customWebsite.customFormUrls || '',
      customNavigationMenu: customWebsite.navigationMenuType || '',
    };
    dispatch(putCustomWebsiteDesignInfo({ orderItemId, content }));
    props.onContinue();
  };

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid]);

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsite(websiteId.toString()));
  }, [websiteId]);

  return (
    <>
      <Typography variant="h2" marginTop={2}>{t('pages.website.custom.design.title')}</Typography>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container marginTop={3}>
              <Grid item xs={6}>
                <NeFormTextArea
                  {...register('customDesignRequirements')}
                  id="customDesignRequirements"
                  name="customDesignRequirements"
                  labelText={t('pages.website.custom.design.requirements')}
                  tooltipText={t('pages.website.custom.design.requirementsTooltip')}
                  tooltipPaddingBottom={18}
                  tooltipPlacement="top"
                  fullWidth
                  rowCount={3}
                  fontLabelWeight="600"
                />
                <ErrorText
                  hasError={!!errors.customDesignRequirements}
                  errorText={t('pages.website.custom.design.requirementsRequired')}
                />
              </Grid>
            </Grid>
          </form>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </>
  );
}
