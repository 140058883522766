import React from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

import { Grid, Input } from '@mui/material';

import styled from '@emotion/styled';

import { IQLabelTooltip, IQTheme } from '@gannettdigital/shared-react-components';
import { IQPhoneInputContainerProps } from '../IQPhoneInputContainer';

import { IQErrorText } from '../IQErrorText/IQErrorText';

// eslint-disable-next-line max-len
export const getError = (errors: any, name: string): any => name.split('.').reduce((acc, el) => (acc ? acc[el] : acc), errors);

type StyledGridItemProps = {
  hasError?: boolean;
};

const StyledGridItem = styled(Grid, { shouldForwardProp: (prop) => prop !== 'hasError' })<StyledGridItemProps>`

  min-width: 80px;

  .MuiInputBase-root {
    padding: 8px 16px;
    gap: 16px;
    min-height: 44px;
    background: ${(props) => props.theme.palette.common.white};
    border: 1px solid
      ${(props) => (props.hasError
    ? props.theme.palette.error.main
    : props.theme.palette.text.primary)};
    border-radius: 2px;
  }

  .MuiInputBase-root::before,
  .MuiInputBase-root::after {
    display: none;
  }

  input {
    padding: 0;
    gap: 16px;
    min-height: 24px;
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    font-size: 16px;
  }
`;

export interface ExtensionElementProps
  extends Omit<
  IQPhoneInputContainerProps,
  | 'name'
  | 'defaultValue'
  | 'country'
  | 'deleteButton'
  | 'deleteCallback'
  | 'containerStyles'
  > {}

export const IQPhoneExtension = ({
  extLabelOptions,
  extensionOptions,
  hasExtension,
}: ExtensionElementProps) => {
  if (hasExtension) {
    const { defaultValue, name } = extensionOptions;
    const { errors } = useFormState();
    const { control } = useFormContext();

    const hasExtensionError = !!getError(errors, name);
    const errorMessage = (getError(errors, name)?.message as unknown as string)
      || 'Please enter numerical values only.';

    return (
      <Grid rowGap={1} container>
        <Grid item xs={12}>
          <IQLabelTooltip
            theme={IQTheme}
            required={false}
            labelText="Extension"
            {...extLabelOptions}
            hasError={hasExtensionError}
          />
        </Grid>
        <StyledGridItem item xs={12} hasError={hasExtensionError}>
          <Controller
            name={name}
            defaultValue={defaultValue}
            control={control}
            rules={{ validate: (value) => !Number.isNaN(Number(value)) }}
            render={({ field }) => <Input {...field} />}
          />
        </StyledGridItem>
        {hasExtensionError && (
          <Grid item xs={12}>
            <IQErrorText
              hasError={hasExtensionError}
              errorMessage={errorMessage}
              alignItems="flex-start"
            />
          </Grid>
        )}
      </Grid>
    );
  }
  return null;
};
