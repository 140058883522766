import { createReducer } from '@reduxjs/toolkit';
import AxiosAPI from './common/axios';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { RootState } from './redux/store';
import { thunkBuilder } from './common/functions';

const initialState: any = {
  businessRoles: {
    status: 'idle',
    message: '',
    content: [],
  },
};

export const getBusinessRoles = createAsyncThunkCatchErrors(
  'businessRoles/get',
  async () => {
    const response = await AxiosAPI.get('/businessRoles');
    return response.data.content;
  },
);

export const BusinessRolesSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder)
      .addCase(getBusinessRoles, 'businessRoles');
  },
);

export const selectBusinessRoles = (state: RootState) => state.businessRoles.businessRoles.content;

export default BusinessRolesSlice;
