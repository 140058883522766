import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';
import { TopNavStatusEnum } from '../TopNavStatusEnum';

export const piqLandingPagesMenu: MenuType = ({
  label: 'PerformanceiQ Attribution Landing Page',
  url: '',
  status: 'incomplete',
  offeringType: 'PiQLandingPages',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
    {
      label: 'Domain',
      url: Urls.PiQDomain,
      status: 'incomplete',
    },
    {
      label: 'Content',
      url: '',
      status: 'incomplete',
      subSteps: [
        {
          id: 0,
          url: Urls.PiQWebsite,
          name: 'Website',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 1,
          url: Urls.PiQPageInfo,
          name: 'Page Info',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 2,
          url: Urls.PiQFormFields,
          name: 'Form Fields',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 3,
          url: Urls.PiQTracking,
          name: 'Tracking',
          status: TopNavStatusEnum.NotStarted,
        },
      ],
    },
    {
      label: 'Design',
      url: '',
      status: 'incomplete',
      subSteps: [
        {
          id: 0,
          url: Urls.PiQBackgroundImage,
          name: 'Background Image',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 1,
          url: Urls.PiQAssets,
          name: 'Assets',
          status: TopNavStatusEnum.NotStarted,
        },
      ],
    },
    {
      label: 'Additional',
      url: Urls.PiQAdditionalInfo,
      status: 'incomplete',
    },
  ],
});
