/* eslint-disable max-len */
import i18n from 'i18n/i18n';
import {RJSFSchema} from '@rjsf/utils';
import TrackingNumberDetails
  from "pages/generic-product/details/custom-fields/TrackingNumberDetails";

const {t} = i18n;

export const TrackingNumberDetailsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.trackingNumber1Details.title'),
  type: 'object',
  properties: {
    trackingNumberDetails: {
      title: t("pages.customTracking.solutionStrategy.webEventsLabel"),
      type: "array",
      default: data?.tracking || [],
      items: {
        type: "object",
        properties: {
          phoneNumberOnWebsiteToBeMasked: {type: "string"},
          destinationOrTerminationNumber: {type: "string"},
          trackingNumberType: {type: "string"},
          tollFreeNumberFeesBeenCharged: {type: "string"},
          regionForTrackingNumber: {type: "string"},
        },
      },
    },
  },

});

export const TrackingNumberDetailsUISchema = (data) => ({
  'ui:order': [
    'trackingNumberDetails',
    'phoneNumberOnWebsiteToBeMasked',
    'destinationOrTerminationNumber',
    'trackingNumberType',
    'tollFreeNumberFeesBeenCharged',
    'regionForTrackingNumber',
  ],
  trackingNumberDetails: {"ui:widget": (props) => <TrackingNumberDetails {...props} data={data}/>},
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TrackingNumberDetailsCustomValidate = (formData: any, errors, uiSchema) => {
  formData?.trackingNumberDetails?.forEach((item) => {
    if (
        !item.phoneNumberOnWebsiteToBeMasked ||
        !item.destinationOrTerminationNumber ||
        !item.trackingNumberType ||
        !item.tollFreeNumberFeesBeenCharged ||
        !item.regionForTrackingNumber
    ) {
      errors.trackingNumberDetails.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
  })
  return errors;
};
