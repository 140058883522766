import i18n from 'i18n/i18n';
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/NotesAndInstructionsSchema";
import {
  CampaignLandingPagesCustomValidate,
  CampaignLandingPagesSchema,
  CampaignLandingPagesUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/CampaignLandingPages";

const {t} = i18n;

export const AusCampaignLandingPagesSchema = (data) => {
  return [
    {
      type: 'object',
      title: t('pages.ausProduct.ausCampaignLandingPages.title'),
      schema: CampaignLandingPagesSchema(data),
      uiSchema: CampaignLandingPagesUISchema,
      customValidate: CampaignLandingPagesCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ];
};

export const AusCampaignLandingPagesUISchema = (data) => ({
  'ui:order': [
    ...CampaignLandingPagesUISchema['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AusCampaignLandingPagesCustomValidate = (formData: any, errors: any, uiSchema: any) => errors;
