import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import { Urls } from 'navigation/Urls';
import EcommerceType, { EcommerceStateObject } from 'models/EcommerceType';
import { StatusType } from 'models/StatusType';
import ProductsDetailsForm from './ProductsDetailsForm';

export default function ProductsDetails() {
  const { product, onBack, onSubmit } = useProduct<EcommerceType,
  StatusType<EcommerceStateObject>>(Urls.ProductsDetails);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <ProductsDetailsForm onBack={onBack} onSubmit={onSubmit} product={product.content?.data} />
    </ContentLoader>
  );
}
