import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import {validateUrl} from "pages/generic-product/details/custom-fields/UrlTextField";
import {validateEmail} from "pages/generic-product/details/custom-fields/EmailTextField";

const {t} = i18n;

export const SitePageSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.site.title'),
  type: 'object',
  required: [ 'clientContactName', 'clientContactEmail', 'clientContactNumber', 'currentWebSiteUrl', 'consultationCallDateOption1', 'consultationCallTimeOption1', 'consultationCallDateOption2', 'consultationCallTimeOption2',
    'wordPressWebsiteAuOrNz' ],
  properties: {
    clientContactName: {
      type: 'string',
      title: t('pages.ausProduct.site.clientContactNameLabel'),
      default: data?.clientContactName || '',
      description: t('pages.ausProduct.site.clientContactNameHelpText'),
    },
    clientContactEmail: {
      type: 'string',
      title: t('pages.ausProduct.site.clientContactEmailLabel'),
      default: data?.clientContactEmail || '',
      description: t('pages.ausProduct.site.clientContactEmailHelpText'),
    },
    clientContactNumber: {
      type: 'string',
      title: t('pages.ausProduct.site.clientContactNumberLabel'),
      default: data?.clientContactNumber || '',
      description: t('pages.ausProduct.site.clientContactNumberHelpText'),
    },
    currentWebSiteUrl: {
      type: 'string',
      title: t('pages.ausProduct.site.currentWebSiteUrlLabel'),
      default: data?.currentWebSiteUrl || '',
    },
    consultationCallDateOption1: {
      type: 'string',
      title: t('pages.ausProduct.site.consultationCallDateOption1Label'),
      default: data?.consultationCallDateOption1 || '',
      description: t('pages.ausProduct.site.consultationCallDateOption1HelpText'),
    },
    consultationCallTimeOption1: {
      type: 'string',
      title: t('pages.ausProduct.site.consultationCallTimeOption1Label'),
      default: data?.consultationCallTimeOption1 || '',
      description: t('pages.ausProduct.site.consultationCallTimeOption1HelpText'),
    },
    consultationCallDateOption2: {
      type: 'string',
      title: t('pages.ausProduct.site.consultationCallDateOption2Label'),
      default: data?.consultationCallDateOption2 || '',
    },
    consultationCallTimeOption2: {
      type: 'string',
      title: t('pages.ausProduct.site.consultationCallTimeOption2Label'),
      default: data?.consultationCallTimeOption2 || '',
    },
    wordPressWebsiteAuOrNz: {
      type: 'string',
      title: t('pages.ausProduct.site.wordPressWebsiteAuOrNzLabel'),
      default: data?.wordPressWebsiteAuOrNz || '',
      items: {
        enum: [
          t('pages.ausProduct.site.wordPressWebsiteAuOrNzOptions.wordPressWebsiteAuOrNz'),
          t('pages.ausProduct.site.wordPressWebsiteAuOrNzOptions.wordPressWebsiteMonthlyServiceAuOrNz'),
        ],
      },
      description: t('pages.ausProduct.site.wordPressWebsiteAuOrNzHelpText'),
    },
    selectAllAddOns: {
      type: 'array',
      title: t('pages.ausProduct.site.selectAllAddOnsLabel'),
      items: {
        type: 'string',
        enum: [
          t('pages.ausProduct.site.selectAllAddOnsOptions.wordPressWebsiteAdditionalPage'),
          t('pages.ausProduct.site.selectAllAddOnsOptions.wordPressWebsiteBookAppointments'),
          t('pages.ausProduct.site.selectAllAddOnsOptions.wordPressWebsiteSmallOnlineShopTenProducts'),
          t('pages.ausProduct.site.selectAllAddOnsOptions.wordPressWebsiteAdditionalProductImportPerTwentyFiveItems'),
          t('pages.ausProduct.site.selectAllAddOnsOptions.wordPressWebsiteAdditionalDevelopmentOrSupport'),
          t('pages.ausProduct.site.selectAllAddOnsOptions.wordPressWebsitePackOfThreeMailboxes'),
        ],
      },
      uniqueItems: true,
      description: t('pages.ausProduct.site.selectAllAddOnsHelpText'),
    },
    additionalDevelopmentOrSupportHoursRequiredText: {
      type: 'string',
      title: t('pages.ausProduct.site.additionalDevelopmentOrSupportHoursRequiredHelpText2'),
      enum: [
        t('pages.ausProduct.site.additionalDevelopmentOrSupportHoursRequiredHelpTextLink')
      ],
    },
    notes: {
      type: 'string',
      title: t('pages.ausProduct.site.notes'),
      default: data?.notes || '',
      description: t('pages.ausProduct.site.notesHelpText')
    }
  },
  allOf: [
    {
      if: {
        properties: {
          selectAllAddOns: {
            type: 'array',
            contains: {
              enum: [
                t('pages.ausProduct.site.selectAllAddOnsOptions.wordPressWebsiteAdditionalProductImportPerTwentyFiveItems'),
              ]
            }
          }
        }
      },
      then: {
        properties: {
          noOfAdditionalProductsImportedPerTwentyFive: {
            type: 'integer',
            title: t('pages.ausProduct.site.noOfAdditionalProductsImportedPerTwentyFiveLabel'),
            default: '',
          },
        },
        required: [ 'noOfAdditionalProductsImportedPerTwentyFive' ],
      }
    },
    {
      if: {
        properties: {
          selectAllAddOns: {
            type: 'array',
            contains: {
              enum: [
                t('pages.ausProduct.site.selectAllAddOnsOptions.wordPressWebsiteAdditionalDevelopmentOrSupport'),
              ]
            }
          }
        }
      },
      then: {
        properties: {
          additionalDevelopmentOrSupportHoursRequired: {
            type: 'string',
            title: t('pages.ausProduct.site.additionalDevelopmentOrSupportHoursRequiredLabel'),
            default: '',
            description: t('pages.ausProduct.site.additionalDevelopmentOrSupportHoursRequiredLabel'),
          },
        },
      }
    }
  ]
});

const textInputFieldKeys = [
  'clientContactName',
  'additionalDevelopmentOrSupportHoursRequired',
  'notes'
];

const textInputFields = textInputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

const radioFieldKeys = [
  'wordPressWebsiteAuOrNz',
];

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'radioButton'},
}), {});

const dateKeys = [
  'consultationCallDateOption1',
  'consultationCallDateOption2',
];

const dateFields = dateKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'datePickerField'},
}), {});

const timeTextFieldKeys = [
  'consultationCallTimeOption1',
  'consultationCallTimeOption2',
];

const timeTextFields = timeTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'timePickerField'},
}), {});

const checkBoxesFieldKeys = [
  'selectAllAddOns',
];

const checkBoxesFields = checkBoxesFieldKeys.reduce(
    (o, key) => ({
      ...o,
      [key]: {'ui:widget': 'checkBoxField'},
    }),
    {},
);
const urlTextFieldsKeys = [
  'currentWebSiteUrl'
];

const urlTextFields = urlTextFieldsKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'UrlTextWidget'},
}), {});

const emailTextFieldKeys = [
  'clientContactEmail',
];

const emailTextFields = emailTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'EmailTextWidget'},
}), {});


const phoneNumberTextFieldKeys = [
  'clientContactNumber',
];
const phoneNumberTextFields = phoneNumberTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'phoneNumberTextField'},
}), {});

const integerInputFieldKeys = [
  'noOfAdditionalProductsImportedPerTwentyFive',
];

const integerInputField = integerInputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'integerInputField'},
}), {});

export const SitePageUISchema = {
  'ui:order': [ 'clientContactName', 'clientContactEmail', 'clientContactNumber', 'currentWebSiteUrl', 'consultationCallDateOption1', 'consultationCallTimeOption1', 'consultationCallDateOption2', 'consultationCallTimeOption2',
    'wordPressWebsiteAuOrNz', 'selectAllAddOns', 'noOfAdditionalProductsImportedPerTwentyFive', 'additionalDevelopmentOrSupportHoursRequired', 'additionalDevelopmentOrSupportHoursRequiredText', 'notes' ],
  additionalDevelopmentOrSupportHoursRequiredText: {
    'ui:widget': 'anchorTextLabel',
    'ui:style': {
      marginTop: '15px',
    },
  },
  ...textInputFields,
  ...radioFields,
  ...dateFields,
  ...checkBoxesFields,
  ...urlTextFields,
  ...timeTextFields,
  ...emailTextFields,
  ...phoneNumberTextFields,
  ...integerInputField
};

export const SitePageCustomValidationSchema = (formData: any, errors, uiSchema) => {
  if (formData?.clientContactName == ''){
    errors.clientContactName.addError(t('pages.ausProduct.site.clientContactNameErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactEmail == '' && !validateEmail(formData?.clientContactEmail)){
    errors.clientContactEmail.addError(t('pages.ausProduct.site.clientContactEmailErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactNumber == ''){
    errors.clientContactNumber.addError(t('pages.ausProduct.site.clientContactNumberErrorMessage'));
    errors.addError(true);
  }
  if (formData?.currentWebSiteUrl == '' && !validateUrl(formData?.currentWebSiteUrl)){
    errors.currentWebSiteUrl.addError(t('pages.ausProduct.site.currentWebSiteUrlErrorMessage'));
    errors.addError(true);
  }
  if (formData?.consultationCallDateOption1 == ''){
    errors.consultationCallDateOption1.addError(t('pages.ausProduct.site.consultationCallDateOption1ErrorMessage'));
    errors.addError(true);
  }
  if (formData?.consultationCallDateOption2 == ''){
    errors.consultationCallDateOption2.addError(t('pages.ausProduct.site.consultationCallDateOption2ErrorMessage'));
    errors.addError(true);
  }
  if (new Date(formData?.consultationCallDateOption2).getTime() < new Date(formData?.consultationCallDateOption1).getTime()){
    errors.consultationCallDateOption2.addError(t('pages.ausProduct.site.consultationCallDateOption2BeforeOption1ErrorMessage'));
    errors.addError(true);
  }
  if (formData?.consultationCallTimeOption1 == ''){
    errors.consultationCallTimeOption1.addError(t('pages.ausProduct.site.consultationCallTimeOption1ErrorMessage'));
    errors.addError(true);
  }
  if (formData?.consultationCallTimeOption2 == ''){
    errors.consultationCallTimeOption2.addError(t('pages.ausProduct.site.consultationCallTimeOption2ErrorMessage'));
    errors.addError(true);
  }
  if (formData?.wordPressWebsiteAuOrNz == ''){
    errors.wordPressWebsiteAuOrNz.addError(t('pages.ausProduct.site.wordPressWebsiteAuOrNzErrorMessage'));
    errors.addError(true);
  }
  if (formData?.selectAllAddOns == t('pages.ausProduct.site.selectAllAddOnsOptions.wordPressWebsiteAdditionalProductImportPerTwentyFiveItems') && formData?.noOfAdditionalProductsImportedPerTwentyFive == ''){
    errors.noOfAdditionalProductsImportedPerTwentyFive.addError(t('pages.ausProduct.site.noOfAdditionalProductsImportedPerTwentyFiveErrorMessage'));
    errors.addError(true);
  }
  if (formData?.notes == ''){
    errors.notes.addError(t('pages.ausProduct.site.notesErrorMessage'));
    errors.addError(true);
  }

  return errors;
};