import { Box, Grid, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import crmIcon from 'icons/crm.svg';
import loginPortalIcon from 'icons/login.svg';
import ecommerceIcon from 'icons/ecommerce.svg';
import schedulingIcon from 'icons/online-booking.svg';
import realEstateIcon from 'icons/realestate.svg';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { getCustomWebsiteItems, postCustomWebsiteItems, selectCustomWebsiteItems } from 'services/customWebsiteSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { Urls } from 'navigation/Urls';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import NeBigBoxMultipleSelect from 'components/bigBoxMultipleSelect/NeBigBoxMultipleSelect';
import { selectActiveProductId } from 'services/navigationSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import schema from './Features.schema';
import { DesignItemsList } from '../design-items/CustomWebsiteDesignItems';

export const FeatureItemList = () => {
  const { t } = useTranslation();
  return [
    {
      checked: false,
      id: '4',
      name: 'crmIntegration',
      label: t('pages.website.custom.features.crmIntegration'),
      svgIcon: crmIcon,
      toolTipIcon: InfoOutlinedIcon,
      tooltipPlacement: 'top' as any,
      tooltipText:
  <Typography fontSize="12px">
    {t('pages.website.custom.features.crmIntegrationTooltip')}
  </Typography>,
    },
    {
      checked: false,
      id: '5',
      name: 'loginPortal',
      label: t('pages.website.custom.features.loginPortal'),
      svgIcon: loginPortalIcon,
      toolTipIcon: InfoOutlinedIcon,
      tooltipPlacement: 'top' as any,
      tooltipText:
  <Typography fontSize="12px">
    {t('pages.website.custom.features.loginPortalTooltip')}
  </Typography>,
    },
    {
      checked: false,
      id: '6',
      name: 'ecommerce',
      label: t('pages.website.custom.features.ecommerce'),
      svgIcon: ecommerceIcon,
      toolTipIcon: InfoOutlinedIcon,
      tooltipPlacement: 'top' as any,
      tooltipText:
  <Typography fontSize="12px">
    {t('pages.website.custom.features.ecommerceTooltip')}
  </Typography>,
    },
    {
      checked: false,
      id: '7',
      name: 'scheduling',
      label: t('pages.website.custom.features.scheduling'),
      svgIcon: schedulingIcon,
      toolTipIcon: InfoOutlinedIcon,
      tooltipPlacement: 'top' as any,
      tooltipText:
  <Typography fontSize="12px">
    {t('pages.website.custom.features.schedulingTooltip')}
  &nbsp;
    <a target="_blank" href="https://addison3.localiq.site/" style={{ color: 'white' }}
      rel="noreferrer"
    >
      {t('pages.website.custom.features.viewExample')}
    </a>
  </Typography>,
    },
    {
      checked: false,
      id: '9',
      name: 'realEstate',
      label: t('pages.website.custom.features.realEstate'),
      svgIcon: realEstateIcon,
      toolTipIcon: InfoOutlinedIcon,
      tooltipPlacement: 'top' as any,
      tooltipText:
  <Typography fontSize="12px">
    {t('pages.website.custom.features.realEstateTooltip')}
  &nbsp;
    <a target="_blank" href="https://neptune.localiq.site/listings/" style={{ color: 'white' }}
      rel="noreferrer"
    >
      {t('pages.website.custom.features.viewExample')}
    </a>
  </Typography>,
    },
  ];
};

export default function CustomWebsiteFeaturesList() {
  const { t } = useTranslation();
  const navigate = useNavigationHandler();
  const dispatch = useDispatch();
  const websiteId = useSelector(selectActiveProductId);
  const customWebsiteItems = useSelector(selectCustomWebsiteItems);
  const featureItems = FeatureItemList();
  const designItems = DesignItemsList();
  const [options, setOptions] = useState(featureItems);
  const designItemIds = designItems.map(obj => Number(obj.id));
  const selectedDesignIds = designItemIds.filter(id => customWebsiteItems.find(e => id === e));

  const methods = useForm({
    mode: 'all',
  });

  const {
    getValues, reset, watch, handleSubmit,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
  } = methods;

  const backNavigationRoute = () => {
    const designViewSteps = selectedDesignIds.filter(id => id !== 8);
    if (designViewSteps.length > 0) {
      const lastStepObj = designItems.find(obj => designViewSteps.at(-1) === Number(obj.id));
      return `${Urls.CustomWebsiteDesignItems}/${lastStepObj.name}`;
    }
    return `${Urls.CustomWebsiteDesign}`;
  };

  const selectedItems = useMemo(() => getValues('features')?.filter(item => item.checked), [watch()]);

  const handleContinue = async () => {
    const { features } = getValues();
    const customWebsiteItemIds = features.filter(item => item.checked).map(item => Number(item.id));
    const totalSelection = selectedDesignIds.concat(customWebsiteItemIds);

    const itemNames = features.filter(item => item.checked).map(({ name }) => name);
    await dispatch(postCustomWebsiteItems({
      offeringId: websiteId.toString(),
      content: { customWebsiteItemIds: totalSelection },
    }));
    if (itemNames[0] === 'realEstate' || itemNames[0] === undefined) {
      navigate.to(`${Urls.CustomWebsiteFeatureList}/${Urls.Additional}`);
    } else {
      navigate.to(`${Urls.CustomWebsiteFeatureList}/${itemNames[0]}`);
    }
  };

  useEffect(() => {
    if (customWebsiteItems.length > 0) {
      const updatedFeatureItems = [];
      featureItems.forEach(obj => {
        const item = customWebsiteItems.find(id => Number(obj.id) === Number(id));
        // eslint-disable-next-line no-param-reassign
        if (item) obj.checked = true;
        updatedFeatureItems.push(obj);
      });
      setOptions(updatedFeatureItems);
      reset({ features: updatedFeatureItems });
    }
  }, [customWebsiteItems]);

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsiteItems(websiteId.toString()));
  }, [websiteId]);

  return (
    <DefaultPageLayout
      onContinue={handleSubmit(handleContinue)}
      onBack={() => navigate.to(backNavigationRoute())}
      header={t('pages.website.custom.features.title')}
      continueButtonLabel={t(selectedItems?.length === 0 ? 'form.buttons.skipAndContinue' : 'form.buttons.continue')}
    >
      <Grid container>
        <Grid item xs={6}>
          <Typography fontSize="18px">{t('pages.website.custom.features.description')}</Typography>
        </Grid>
        <Grid item xs={12} marginTop={5}>
          <ValidationProvider schema={schema}>
            <FormProvider {...methods}>
              <Box sx={{
                '& div[alt="icon checkbox"] svg path': { fill: 'transparent !important' },
                '& div[height="80"]:first-of-type svg path': { fill: 'currentColor !important' },
                '& div[alt="icon checkbox"] > div': { display: 'flex !important' },
                '& .MuiFormControlLabel-root': { paddingRight: '10px !important' },
              }}
              >
                <NeBigBoxMultipleSelect
                  optionsList={options}
                  name="features"
                />
              </Box>
              <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
              />
            </FormProvider>
          </ValidationProvider>
        </Grid>
      </Grid>
    </DefaultPageLayout>
  );
}
