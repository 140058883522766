import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";

const {t} = i18n;

export const AdDetailsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.adDetails.title'),
  type: 'object',
  properties: {
    adVideoLink: {
      type: 'string',
      title: t('pages.ausProduct.adDetails.adVideoLink'),
      description: t('pages.ausProduct.adDetails.adVideoLinkDescription'),
      default: data?.adVideoLink || '',
    },
    additionalYouTubeVideoLinks: {
      type: 'string',
      title: t('pages.ausProduct.adDetails.additionalYouTubeVideoLinks'),
      description: t('pages.ausProduct.adDetails.additionalYouTubeVideoLinksDescription'),
      default: data?.additionalYouTubeVideoLinks || '',
    },
  advertiserWebsite: {
      type: 'string',
      title: t('pages.ausProduct.adDetails.advertiserWebsite'),
      default: data?.advertiserWebsite || '',
    },
    subUrlSpecific: {
      type: 'string',
      title: t('pages.ausProduct.adDetails.subURLSpecific'),
      description: t('pages.ausProduct.adDetails.subURLSpecificDescription'),
      default: data?.subUrlSpecific || '',
    },
    forInformationHowToCreateChannelAndUploadVideo: {
      type: 'string',
      title: t('pages.ausProduct.adDetails.forInformationHowToCreateChannelAndUploadVideo'),
    },
    forInformationHowToCreateChannelAndUploadVideoLink: {
      type: 'string',
      title: t('pages.ausProduct.adDetails.forInformationHowToCreateChannelAndUploadVideoLinkTitle'),
      enum: [t('pages.ausProduct.adDetails.forInformationHowToCreateChannelAndUploadVideoLink'), true],
    },
    companionBannerAd: {
      type: 'string',
      title: t('pages.ausProduct.adDetails.companionBannerAdLabel'),
      default: data?.companionBannerAd || '',
      enum: [
        '',
        t('pages.ausProduct.youtube.yesOpt'),
        t('pages.ausProduct.youtube.noOpt'),
      ],
    },
    additionalNotes: {
      type: 'string',
      title: t('pages.ausProduct.adDetails.additionalNotesLabel'),
      description: t('pages.ausProduct.adDetails.additionalNotesDescription'),
      default: data?.additionalNotes || '',
    },
  },
  dependencies: {
    companionBannerAd: {
      oneOf: [
        {
          properties: {
            companionBannerAd: {
              enum: [t('pages.ausProduct.youtube.yesOpt')],
            },
            adInstructions: {
              type: 'string',
              title: t('pages.ausProduct.adDetails.adInstructions'),
            },
            videoAdCreative: {
              type: 'string',
              title: t('pages.ausProduct.adDetails.videoAdCreative'),
            },
            dimensions: {
              type: 'string',
              title: t('pages.ausProduct.adDetails.dimensions'),
            },
            adSize: {
              type: 'string',
              title: t('pages.ausProduct.adDetails.adSize'),
            },
          },
        },
      ],
    },
  }
});

const dropdownFieldKeys = [
  'companionBannerAd'
];

const inputFieldKeys = [
  'additionalNotes'
];

const urlTextFieldsKeys = [
  'adVideoLink',
  'advertiserWebsite',
  'subUrlSpecific',
];

const textLabelKey = [
  'forInformationHowToCreateChannelAndUploadVideo',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'dropdown'},
}), {});

const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

const urlTextFields = urlTextFieldsKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'UrlTextWidget'},
}), {});

const textLabel = textLabelKey.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textLabel'},
}), {});

export const AdDetailsUISchema = {
  'ui:order': [
    'adVideoLink',
    'additionalYouTubeVideoLinks',
    'advertiserWebsite',
    'subUrlSpecific',
    'forInformationHowToCreateChannelAndUploadVideo',
    'forInformationHowToCreateChannelAndUploadVideoLink',
    'companionBannerAd',
    'adInstructions',
    'videoAdCreative',
    'dimensions',
    'adSize',
    'additionalNotes',
  ],
  forInformationHowToCreateChannelAndUploadVideoLink: {
    'ui:widget': 'anchorTextLabel',
    'ui:style': {
      marginTop: '-12px',
    },
  },
  additionalYouTubeVideoLinks: {
    'ui:widget': 'TextareaWidget',
  },
  adInstructions: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '-12px',
    },
  },
  videoAdCreative: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginTop: '-12px',
    },
  },
  dimensions: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginTop: '-10px',
    },
  },
  adSize: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginTop: '-10px',
    },
  },

  ...inputFields,
  ...dropdownFields,
  ...urlTextFields,
  ...textLabel,
};

export const AdDetailsCustomValidate = (formData: any, errors, uiSchema) => {
  return errors;
};
