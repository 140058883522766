import { Box, Toolbar } from '@mui/material';
import CoTopNav from 'components/topNav/CoTopNav';
import { Outlet } from 'react-router';
import { backgroundDefaultColor } from 'styles/common_styles';

export default function AdminMenu() {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundColor: backgroundDefaultColor,
    }}
    >
      <CoTopNav />
      <Box sx={{
        width: '100%', marginX: 4, marginTop: 5, marginBottom: 10,
      }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
