/* eslint-disable max-len */
import i18n from 'i18n/i18n';

import { RJSFSchema } from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import { validateEmail } from 'pages/generic-product/details/custom-fields/EmailTextField';

const { t } = i18n;

export const ContactInformationSchema: RJSFSchema | any = (data) => ({
  title: t('pages.socialAds.solutionStrategy.contactInformation.title'),
  type: 'object',
  required: [
    'clientContactName',
    'clientContactNumber',
    'clientContactEmail',
    'isThisTheSameEmailForCreativeApproval',
    'isThisTheBestPersonToGrantUsFacebookAdminAccess',
    'doesTheClientWantLeadNotificationsAndWeeklyPerformanceReports',
  ],
  properties: {
    clientContactName: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.contactInformation.clientContactNameLabel'),
      default: data?.clientContactName || '',
    },
    clientContactNumber: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.contactInformation.clientContactNumberLabel'),
      default: data?.clientContactNumber || '',
    },
    clientContactEmail: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.contactInformation.clientContactEmailLabel'),
      default: data?.clientContactEmail || '',
    },
    bestNumberToReachYou: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.contactInformation.bestNumberToReachYouLabel'),
      default: data?.bestNumberToReachYou || '',
    },
    isThisTheSameEmailForCreativeApproval: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.contactInformation.isThisTheSameEmailForCreativeApprovalLabel'),
      default: data?.isThisTheSameEmailForCreativeApproval || '',
      enum: [
        'Yes',
        'No',
      ],
    },
    isThisTheBestPersonToGrantUsFacebookAdminAccess: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.contactInformation.isThisTheBestPersonToGrantUsFacebookAdminAccessLabel'),
      default: data?.isThisTheBestPersonToGrantUsFacebookAdminAccess || '',
      enum: [
        'Yes',
        'No',
      ],
    },
    doesTheClientWantLeadNotificationsAndWeeklyPerformanceReports: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.contactInformation.doesTheClientWantLeadNotificationsAndWeeklyPerformanceReportsLabel'),
      default: data?.doesTheClientWantLeadNotificationsAndWeeklyPerformanceReports || '',
      enum: [
        'Lead Notifications',
        'Weekly Performance Report',
        'Lead Notifications + Weekly Performance Report',
        'None',
      ],
    },
  },
  dependencies: {
    isThisTheSameEmailForCreativeApproval: {
      oneOf: [
        {
          properties: {
            isThisTheSameEmailForCreativeApproval: {
              enum: ['No'],
            },
            clientEmailForCreativeApproval: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.contactInformation.clientEmailForCreativeApprovalLabel'),
              default: data?.clientEmailForCreativeApproval || '',
            },
          },
        },
      ],
    },
    isThisTheBestPersonToGrantUsFacebookAdminAccess: {
      oneOf: [
        {
          properties: {
            isThisTheBestPersonToGrantUsFacebookAdminAccess: {
              enum: ['No'],
            },
            clientEmailForFacebookAdminAccess: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.contactInformation.clientEmailForFacebookAdminAccessLabel'),
              default: data?.clientEmailForFacebookAdminAccess || '',
            },
          },
        },
      ],
    },
    doesTheClientWantLeadNotificationsAndWeeklyPerformanceReports: {
      oneOf: [
        {
          properties: {
            doesTheClientWantLeadNotificationsAndWeeklyPerformanceReports: {
              enum: [
                'Lead Notifications',
                'Weekly Performance Report',
                'Lead Notifications + Weekly Performance Report',
              ],
            },
            emailAddressThatWillNeedToReceiveReachEdgeLeadNotificationAndWeeklyPerformanceReportEmails: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.contactInformation.emailAddressThatWillNeedToReceiveReachEdgeLeadNotificationAndWeeklyPerformanceReportEmailsLabel'),
              default: data?.emailAddressThatWillNeedToReceiveReachEdgeLeadNotificationAndWeeklyPerformanceReportEmails || '',
            },
          },
        },
      ],
    },
  },
});

const textFieldKeys = [
  'clientContactName',
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const textAreaFieldKeys = [];

const phoneNumberTextFieldKeys = [
  'clientContactNumber',
  'bestNumberToReachYou',
];

const emailTextFieldKeys = [
  'clientContactEmail',
  'clientEmailForCreativeApproval',
  'clientEmailForFacebookAdminAccess',
  'emailAddressThatWillNeedToReceiveReachEdgeLeadNotificationAndWeeklyPerformanceReportEmails',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const textAreaFields = {}; // since no keys found in the textAreaFieldKeys
// const textAreaFields = textAreaFieldKeys.reduce((o, key) => ({
//   ...o,
//   [key]: {'ui:widget': 'TextareaWidget'}
// }), {});

const emailTextFields = emailTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'EmailTextWidget' },
}), {});

const phoneNumberTextFields = phoneNumberTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'phoneNumberTextField' },
}), {});

export const ContactInformationUISchema = {
  'ui:order': [
    'clientContactName',
    'clientContactNumber',
    'clientContactEmail',
    'bestNumberToReachYou',
    'isThisTheSameEmailForCreativeApproval',
    'clientEmailForCreativeApproval',
    'isThisTheBestPersonToGrantUsFacebookAdminAccess',
    'clientEmailForFacebookAdminAccess',
    'doesTheClientWantLeadNotificationsAndWeeklyPerformanceReports',
    'emailAddressThatWillNeedToReceiveReachEdgeLeadNotificationAndWeeklyPerformanceReportEmails',
  ],
  ...textAreaFields,
  ...textFields,
  ...emailTextFields,
  ...phoneNumberTextFields,
  isThisTheSameEmailForCreativeApproval: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage={t('pages.socialAds.solutionStrategy.contactInformation.isThisTheSameEmailForCreativeApprovalErrorMessage')}
      />
    ),
  },
  isThisTheBestPersonToGrantUsFacebookAdminAccess: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage={t('pages.socialAds.solutionStrategy.contactInformation.isThisTheBestPersonToGrantUsFacebookAdminAccessErrorMessage')}
      />
    ),
  },
  doesTheClientWantLeadNotificationsAndWeeklyPerformanceReports: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage={t('pages.socialAds.solutionStrategy.contactInformation.doesTheClientWantLeadNotificationsAndWeeklyPerformanceReportsErrorMessage')}
      />
    ),
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ContactInformationCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.clientContactName === '') {
    errors.clientContactName.addError(t('pages.socialAds.solutionStrategy.contactInformation.clientContactNameErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactEmail !== '' && !validateEmail(formData?.clientContactEmail)) {
    errors.clientContactEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.clientEmailForCreativeApproval !== undefined && formData?.clientEmailForCreativeApproval !== '' && !validateEmail(formData?.clientEmailForCreativeApproval)) {
    errors.clientEmailForCreativeApproval.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.clientEmailForFacebookAdminAccess !== undefined && formData?.clientEmailForFacebookAdminAccess !== '' && !validateEmail(formData?.clientEmailForFacebookAdminAccess)) {
    errors.clientEmailForFacebookAdminAccess.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.emailAddressThatWillNeedToReceiveReachEdgeLeadNotificationAndWeeklyPerformanceReportEmails !== undefined && formData?.emailAddressThatWillNeedToReceiveReachEdgeLeadNotificationAndWeeklyPerformanceReportEmails !== '' && !validateEmail(formData?.emailAddressThatWillNeedToReceiveReachEdgeLeadNotificationAndWeeklyPerformanceReportEmails)) {
    errors.emailAddressThatWillNeedToReceiveReachEdgeLeadNotificationAndWeeklyPerformanceReportEmails.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.clientContactNumber === '' || formData?.clientContactNumber === undefined) {
    errors.clientContactNumber.addError(t('pages.socialAds.solutionStrategy.contactInformation.clientContactNumberErrorMessage'));
    errors.addError(true);
  }

  return errors;
};
