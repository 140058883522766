import { useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import OrderReview from './OrderReview';
import OrderSummary from './OrderSummary';

export default function OrderReviewMenu() {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('reviewId') ? <OrderReview /> : <OrderSummary />;

  return (
    <Grid container sx={{ width: '1300px', margin: 'auto' }}>
      {page}
    </Grid>
  );
}
