import * as yup from 'yup';

const yupSchema = {
  features: yup.string(),
};

export default {
  yupValidations: yupSchema,
  customValidations: {},
};
