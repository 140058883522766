import i18n from 'i18n/i18n';
import { RJSFSchema } from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import FileUploader from 'pages/generic-product/details/custom-fields/FileUploader';
import { ProductTypes } from 'services/fileUploaderSlice';

const { t } = i18n;

export const VideoCreativeServiceSchema: RJSFSchema | any = (data) => ({
    title: t('pages.ausProduct.ausVideoCreativeService.videoCreativeService.title'),
    type: 'object',
    properties: {
        videoBundle: {
            type: 'string',
            title: t('pages.ausProduct.ausVideoCreativeService.videoCreativeService.videoBundleLabel'),
            enum: [
                '',
                t('pages.ausProduct.ausVideoCreativeService.videoCreativeService.videoBundleOptions.fullMotionVideo'),
                t('pages.ausProduct.ausVideoCreativeService.videoCreativeService.videoBundleOptions.fullMotionVideoAndSocialEdit'),
            ],
            description: t('pages.ausProduct.ausVideoCreativeService.videoCreativeService.videoBundleDescription'),
        },
        uploader_TemplateUploadFile: {
            type: 'string',
            title: t('pages.ausProduct.ausVideoCreativeService.videoCreativeService.templateUploadFileLabel'),
            description: (
                <p>
                    {t('pages.ausProduct.ausVideoCreativeService.videoCreativeService.templateUploadFileDescriptionPart1')}
                    <a
                        href={t('pages.ausProduct.ausVideoCreativeService.videoCreativeService.templateUploadFileDescriptionPart3')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('pages.ausProduct.ausVideoCreativeService.videoCreativeService.templateUploadFileDescriptionPart3')}
                    </a>
                    {t('pages.ausProduct.ausVideoCreativeService.videoCreativeService.templateUploadFileDescriptionPart2')}
                </p>
            ),
        },
    },
});

const dropdownFieldKeys = [
    'videoBundle',
];
const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
    ...o,
    [key]: {
        'ui:widget': (props) => (
            <Dropdown
                {...props}
                errorMessage={t('pages.ausProduct.ausLiveChat.requiredFieldErrorMessage')}
            />
        ),
    },
}), {});

export const VideoCreativeServiceUISchema = {
    'ui:order': ['videoBundle', 'uploader_TemplateUploadFile'],
    uploader_TemplateUploadFile: {
        'ui:style': {
            marginTop: '16px',
        },
        'ui:widget': (props) => (
            <FileUploader
                {...props}
                uploaderType={ProductTypes.VIDEO_CREATIVE_SERVICE_UPLOADER_FILE}
                totalAllowedFiles={1}
                imageTypes={[]}
                videoTypes={[]}
                documentTypes={['.pdf', '.doc', '.docx']}
                supportingText=""
                file={props.value ? props.value : null}
            />
        ),
    },
    ...dropdownFields
};

export const VideoCreativeServiceCustomValidate = (formData: any, errors, uiSchema) => {
    return errors;
};
