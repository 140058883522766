/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';

const { t } = i18n;

export const PremiumListings10PlusPhysicianDirectoriesSchema: RJSFSchema | any = (
  data,
) => ({
  // eslint-disable-next-line max-len
  type: 'object',
  title: t('pages.premiumListingsPhysicianDirectories.pageTitle'),
  required: [],
  properties: {
    clientContactFirstName: {
      type: 'string',
      title: t(
        'pages.premiumListingsPhysicianDirectories.clientContactFirstName',
      ),
      default: data?.clientContactFirstName ?? '',
    },
    clientContactLastName: {
      type: 'string',
      title: t(
        'pages.premiumListingsPhysicianDirectories.clientContactLastName',
      ),
      default: data?.clientContactLastName ?? '',
    },
    clientContactPhone: {
      type: 'string',
      title: t('pages.premiumListingsPhysicianDirectories.clientContactPhone'),
      default: data?.clientContactPhone ?? '',
    },
    clientContactEmail: {
      type: 'string',
      title: t('pages.premiumListingsPhysicianDirectories.clientContactEmail'),
      description: t(
        'pages.premiumListingsPhysicianDirectories.clientsContactEmailHelperText',
      ),
      default: data?.clientContactEmail ?? '',
    },
    bestNumberToReachYou: {
      type: 'string',
      title: t(
        'pages.premiumListingsPhysicianDirectories.bestNumberToReachYou',
      ),
      default: data?.bestNumberToReachYou ?? '',
    },
    physicianListingsAreIncludedInTheClientBudget: {
      type: 'string',
      title: t(
        'pages.premiumListingsPhysicianDirectories.physicianListingsAreIncludedInTheClientBudget',
      ),
      description: t(
        'pages.premiumListingsPhysicianDirectories.physicianListingsAreIncludedInTheClientBudgetHelperText',
      ),
      default: data?.physicianListingsAreIncludedInTheClientBudget ?? '',
    },
    alreadyHaveAnActivePremiumTenPlusListingCampaign: {
      type: 'string',
      title: t('pages.premiumListingsPhysicianDirectories.alreadyHaveAnActivePremiumTenPlusListingCampaign'),
      default: data?.alreadyHaveAnActivePremiumTenPlusListingCampaign ?? '',
      enum: ['', 'Yes', 'No'],
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.displayCreativeService.notesOrInstructions'),
      default: data?.notesOrInstructions ?? '',
    },
  },
  dependencies: {
    alreadyHaveAnActivePremiumTenPlusListingCampaign: {
      oneOf: [
        {
          properties: {
            alreadyHaveAnActivePremiumTenPlusListingCampaign: {
              enum: ['Yes'],
            },
            thePremiumListingsTenPlusMcid: {
              type: 'string',
              title: t(
                'pages.premiumListingsPhysicianDirectories.thePremiumListingsTenPlusMcid',
              ),
              default: data?.thePremiumListingsTenPlusMcid ?? '',
            },
          },
        },
      ],
    },
  },
});

const textFields = [
  'clientContactFirstName',
  'clientContactLastName',
  'physicianListingsAreIncludedInTheClientBudget',
  'thePremiumListingsTenPlusMcid',
].reduce((p, c) => {
  // eslint-disable-next-line no-param-reassign
  p[c] = { 'ui:widget': 'textInputField' };
  return p;
}, {});

const phoneFields = ['clientContactPhone', 'bestNumberToReachYou']
  .reduce((p, c) => {
    // eslint-disable-next-line no-param-reassign
    p[c] = { 'ui:widget': 'phoneNumberTextField' };
    return p;
  }, {});

export const PremiumListings10PlusPhysicianDirectoriesUISchema = {
  'ui:order': [
    'clientContactFirstName',
    'clientContactLastName',
    'clientContactPhone',
    'clientContactEmail',
    'bestNumberToReachYou',
    'physicianListingsAreIncludedInTheClientBudget',
    'alreadyHaveAnActivePremiumTenPlusListingCampaign',
    'thePremiumListingsTenPlusMcid',
    'notesOrInstructions',
  ],

  ...textFields,
  ...phoneFields,
  clientContactEmail: { 'ui:widget': 'EmailTextWidget' },
  alreadyHaveAnActivePremiumTenPlusListingCampaign: { 'ui:widget': 'dropdown' },
  notesOrInstructions: { 'ui:widget': 'TextareaWidget' },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PremiumListings10PlusPhysicianDirectoriesCustomValidate = (formData: any, errors, uiSchema) => errors;
