import { useEffect, useState } from 'react';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import {
  FormControlLabel, Grid, Radio, RadioGroup, Typography,
} from '@mui/material';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { inputStyles } from 'pages/seo/details/DetailsPageForm';
import { FormProvider, useForm } from 'react-hook-form';
import schemaValidate from 'components/schemaValidate';
import i18n from 'i18n/i18n';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getClients, selectClients } from 'services/clientsSlice';
import { emptyContact } from 'pages/seo/details/DetailsPageConstants';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import schema from './SMMDetailsPage.schema';
import { campaignOptions } from './SMMDetailsPageConstants';
import { renderSocialFields } from './SocialFields';
import { renderContactInfo } from './ContactInfo';
import { renderReputationSection } from './Reputation';

const { t } = i18n;

interface Props {
  product: any; // product type to be created
  onSubmit: (data) => void;
  onBack: () => void;
}

export default function SmmDetailsPageForm(props: Props) {
  const { product } = props;
  const { orderId, offeringType } = useParams();
  const dispatch = useDispatch();
  const [campaign, setCampaign] = useState(product?.campaign || '');
  const [existingFacebook, setExistingFacebook] = useState(product?.existingFacebook || '');
  const [existingInstagram, setExistingInstagram] = useState(product?.existingInstagram || '');
  const [existingX, setExistingX] = useState(product?.existingX || '');
  const [reputationManagement, setReputationManagement] = useState(product?.reputationManagement || '');
  const [isRepManagementNeeded, setIsRepManagementNeeded] = useState(product?.isRepManagementNeeded || '');
  const [campaignContact, setCampaignContact] = useState(product?.campaignContact || '');
  const [accessContact, setAccessContact] = useState(product?.accessContact || '');
  const [clientOptions, setClientOptions] = useState([]);
  const clients = useSelector(selectClients);
  const isCustom = offeringType === 'smm-custom';

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      campaign: product?.campaign || '',
      existingFacebook: product?.existingFacebook || '',
      facebookUrl: product?.facebookUrl || '',
      existingInstagram: product?.existingInstagram || '',
      instagramUrl: product?.instagramUrl || '',
      existingX: product?.existingX || '',
      xUrl: product?.xUrl || '',
      reputationManagement: product?.reputationManagement || '',
      isRepManagementNeeded: product?.isRepManagementNeeded || '',
      gbpUrl: product?.gbpUrl || '',
      yelpUrl: product?.yelpUrl || '',
      campaignContact: product?.campaignContact || '',
      campaignContactName: product?.campaignContactName || '',
      campaignContactEmail: product?.campaignContactEmail || '',
      campaignContactPhone: product?.campaignContactPhone || '',
      campaignContactPhoneExtension: product?.campaignContactPhoneExtension || '',
      accessContact: product?.accessContact || '',
      accessContactName: product?.accessContactName || '',
      accessContactEmail: product?.accessContactEmail || '',
      accessContactPhone: product?.accessContactPhone || '',
      accessContactPhoneExtension: product?.accessContactPhoneExtension || '',
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: {
      isValid,
      errors,
      isDirty,
      isSubmitting,
      isSubmitSuccessful,
    },
  } = methods;

  const hasFacebook = watch('existingFacebook') === 'yes';
  const includeInstagram = watch('campaign') === 'instagram';
  const hasInstagram = watch('existingInstagram') === 'yes';
  const includeX = watch('campaign') === 'x';
  const hasX = watch('existingX') === 'yes';
  const hasGBP = watch('reputationManagement') === 'gbp';
  const hasYelp = watch('reputationManagement') === 'yelp';
  const isCampaignContactOther = watch('campaignContact') === 'other';
  const isAccessContactOther = watch('accessContact') === 'other';

  useEffect(() => {
    if (!hasFacebook) setValue('facebookUrl', '');
  }, [hasFacebook]);

  useEffect(() => {
    if (!includeInstagram) setValue('existingInstagram', '');
    if (!hasInstagram) setValue('instagramUrl', '');
  }, [includeInstagram, hasInstagram]);

  useEffect(() => {
    if (!includeX) setValue('existingX', '');
    if (!hasX) setValue('xUrl', '');
  }, [includeX, hasX]);

  useEffect(() => {
    if (isRepManagementNeeded === 'no') {
      setValue('reputationManagement', '');
      setReputationManagement('');
    }

    const value = getValues('reputationManagement');
    if ((isRepManagementNeeded === 'no') || value === 'gbp') setValue('yelpUrl', '');
    if ((isRepManagementNeeded === 'no') || value === 'yelp') setValue('gbpUrl', '');
  }, [watch('reputationManagement'), isRepManagementNeeded]);

  useEffect(() => {
    dispatch(getClients(orderId));
  }, []);

  useEffect(() => {
    if (clients.length > 0) {
      const formatClients = clients.map(client => ({
        // eslint-disable-next-line max-len
        label: `${client.contact.clientFullName}\nMobile: ${client.contactPhoneNumberList[0].phoneNumber}${client.contactPhoneNumberList[0].ext
          && ` ext.${client.contactPhoneNumberList[0].ext}`}\n${client.contact.email}`,
        value: client.contact.id.toString(),
      }));

      setClientOptions([...formatClients, {
        label: 'Other',
        value: 'other',
      }]);
    }
  }, [clients]);

  const setContact = (type, {
    name, email, phone, ext,
  } = emptyContact) => {
    setValue(`${type}ContactName` as any, name);
    setValue(`${type}ContactEmail` as any, email);
    setValue(`${type}ContactPhone` as any, phone);
    setValue(`${type}ContactPhoneExtension` as any, ext);
  };

  const checkFilteredContact = (field, isOther, contactType) => {
    const contact = getValues(field);
    if (contact && !isOther && clients.length > 0) {
      const selectedClient = clients.filter(client => client.contact.id.toString() === contact)[0];
      setContact(contactType, {
        name: selectedClient.contact.clientFullName,
        email: selectedClient.contact.email,
        phone: selectedClient.contactPhoneNumberList[0].phoneNumber,
        ext: selectedClient.contactPhoneNumberList[0].ext,
      });
    } else if (isOther && product[field] === 'other') {
      setContact(contactType, {
        name: product[`${contactType}ContactName`],
        email: product[`${contactType}ContactEmail`],
        phone: product[`${contactType}ContactPhone`],
        ext: product[`${contactType}ContactPhoneExtension`],
      });
    } else setContact(contactType);
  };

  useEffect(() => {
    checkFilteredContact('campaignContact', isCampaignContactOther, 'campaign');
  }, [watch('campaignContact'), product, clients]);

  useEffect(() => {
    checkFilteredContact('accessContact', isAccessContactOther, 'access');
  }, [watch('accessContact'), clients]);

  const handleFormSubmit = (data) => {
    props.onSubmit(data);
  };

  const showRepManagement = [2, 3].includes(product?.variant) || isCustom;

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={props.onBack}
      header="Details"
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container xs={9} sx={{ ...inputStyles }}>
            {/* Campaign */}
            <Grid item mb={4} xs={12}>
              <Typography variant="h5" component="h2">
                {t('pages.smm.details.campaignSectionLabel')}
              </Typography>
            </Grid>
            <Grid item mb={5} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}>
                  {t('pages.smm.details.campaignLabel')}
                </Typography>
                <RadioGroup
                  aria-labelledby="campaign"
                  name="campaign"
                  value={campaign}
                  onChange={(e) => setCampaign(e.target.value)}
                >
                  {campaignOptions.map(option => (
                    <FormControlLabel
                      key={option.value}
                      {...register('campaign', {
                        validate: (value) => schemaValidate(value, 'campaign', schema),
                      })}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>

            {/* Social Media */}
            <Grid item mb={4} xs={12}>
              <Typography variant="h5" component="h2">
                {t('pages.smm.details.socialMediaSectionLabel')}
              </Typography>
            </Grid>
            <Grid item mb={5} xs={12}>
              {/* Facebook */}
              {renderSocialFields({
                existingSocialFieldName: 'existingFacebook',
                existingSocialUrlFieldName: 'facebookUrl',
                includeSocial: true,
                hasSocial: hasFacebook,
                existingSocial: existingFacebook,
                setExistingSocial: setExistingFacebook,
                register,
                errors,
              })}
              {/* Instagram */}
              {renderSocialFields({
                existingSocialFieldName: 'existingInstagram',
                existingSocialUrlFieldName: 'instagramUrl',
                includeSocial: includeInstagram,
                hasSocial: hasInstagram,
                existingSocial: existingInstagram,
                setExistingSocial: setExistingInstagram,
                register,
                errors,
              })}
              {/* X */}
              {renderSocialFields({
                existingSocialFieldName: 'existingX',
                existingSocialUrlFieldName: 'xUrl',
                includeSocial: includeX,
                hasSocial: hasX,
                existingSocial: existingX,
                setExistingSocial: setExistingX,
                register,
                errors,
              })}
            </Grid>

            {/* Reputation Management */}
            {showRepManagement && renderReputationSection({
              reputationManagement,
              setReputationManagement,
              isRepManagementNeeded,
              setIsRepManagementNeeded,
              hasGBP,
              hasYelp,
              register,
              errors,
              isCustom,
            })}

            {/* Contact Info */}
            {renderContactInfo({
              campaignContact,
              setCampaignContact,
              isCampaignContactOther,
              accessContact,
              setAccessContact,
              isAccessContactOther,
              clientOptions,
              register,
              errors,
            })}
          </Grid>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
