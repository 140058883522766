export const uploadContainerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100px',
  border: '2px dashed #e0e0e0',
  borderRadius: '10px',
  transition: 'border 0.3s ease',
  backgroundColor: '#f9f9f9',
  position: 'relative' as 'relative',
};

export const buttonStyle = (hoverEnable: boolean): React.CSSProperties => ({
  cursor: 'pointer',
  color: '#ffffff',
  padding: '10px 20px',
  borderRadius: '5px',
  marginRight: '10px',
  transition: 'background-color 0.3s ease',
  backgroundColor: hoverEnable ? '#0056b3' : '#007bff',
});

export const fileNameStyle: React.CSSProperties = {
  fontSize: '16px',
  color: '#555555',
};
