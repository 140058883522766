/* eslint-disable max-len */
import i18n from 'i18n/i18n';

import {RJSFSchema} from '@rjsf/utils';
import {Grid, Typography} from "@mui/material";
import React from "react";
import {
  landscapeImageItems, portraitImageItems, squareImageItems
} from "pages/generic-product/details/product-schema/aus-product-schema/AusProductService";

const {t} = i18n;

export const DemandGenDetailsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.demandGenDetails.title'),
  type: 'object',
  required: ['websitePrivacyPolicy'],
  properties: {
    attentionDmc: {
      type: 'string',
    },
    landscapeImage: {
      type: 'string',
    },
    squareImage: {
      type: 'string',
    },
    portraitImage: {
      type: 'string',
    },
    bestPractice: {
      type: 'string',
    },
    note: {
      type: 'string',
    },
    websitePrivacyPolicy: {
      type: 'string',
      title: t('pages.ausProduct.demandGenDetails.websitePrivacyPolicy'),
      default: '',
    },
    privacyPolicyAnchorText: {
      type: 'string',
    },
    campaignName: {
      type: 'string',
      title: t('pages.ausProduct.demandGenDetails.campaignName'),
      default: '',
      description: t('pages.ausProduct.demandGenDetails.campaignNameHelpText'),
    },
  },
});

const textFieldKeys = [
  'websitePrivacyPolicy',
  'campaignName'
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

export const DemandGenDetailsUISchema = {
  'ui:order': ['attentionDmc', 'landscapeImage', 'squareImage', 'portraitImage', 'bestPractice',
    'note', 'websitePrivacyPolicy', 'privacyPolicyAnchorText', 'campaignName'],

  attentionDmc: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography variant="subtitle2"
                      fontStyle={"italic"}> {t('pages.ausProduct.demandGenDetails.attentionDmcHeading')} </Typography>
          <Typography fontSize={14}
                      fontStyle={"italic"}> {t('pages.ausProduct.demandGenDetails.attentionDmcDescription')} </Typography>
        </Grid>
    ),
  },
  landscapeImage: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography variant="subtitle2"> {t('pages.ausProduct.demandGenDetails.landscapeImageHeading')} </Typography>
          <Typography fontSize={14} component="ul">
            {landscapeImageItems.map((item, index) => (
                <Typography key={index} fontSize={14} marginLeft={-7} component="ul">◦ {item}</Typography>
            ))}
          </Typography>
        </Grid>
    ),
  },
  squareImage: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography variant="subtitle2"> {t('pages.ausProduct.demandGenDetails.squareImageHeading')} </Typography>
          <Typography fontSize={14} component="ul">
            {squareImageItems.map((item, index) => (
                <Typography key={index} fontSize={14} marginLeft={-7} component="ul">◦ {item}</Typography>
            ))}
          </Typography>
        </Grid>
    ),
  },
  portraitImage: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography variant="subtitle2"> {t('pages.ausProduct.demandGenDetails.portraitImageHeading')} </Typography>
          <Typography fontSize={14} component="ul">
            {portraitImageItems.map((item, index) => (
                <Typography key={index} fontSize={14} marginLeft={-7} component="ul">◦ {item}</Typography>
            ))}
          </Typography>
        </Grid>
    ),
  },
  bestPractice: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography variant="subtitle2" fontStyle={"italic"}
                      style={{display: 'inline-block'}}> {t('pages.ausProduct.demandGenDetails.bestPracticeHeading')} </Typography>
          <Typography fontSize={14} marginLeft={1} fontStyle={"italic"}
                      style={{display: 'inline-block'}}> {t('pages.ausProduct.demandGenDetails.bestPracticeDescription')} </Typography>
        </Grid>
    ),
  },
  note: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography variant="subtitle2"
                      style={{display: 'inline-block'}}> {t('pages.ausProduct.demandGenDetails.noteHeading')} </Typography>
          <Typography fontSize={14} marginLeft={1}
                      style={{display: 'inline-block'}}> {t('pages.ausProduct.demandGenDetails.noteDescription')} </Typography>
        </Grid>
    ),
  },
  privacyPolicyAnchorText: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography fontSize={14}>
            {t('pages.ausProduct.demandGenDetails.privacyPolicyAnchorTextBeforeLinkNz')}
            <a href={t('pages.ausProduct.demandGenDetails.privacyPolicyAnchorTextLinkNz')} target="_blank"
               rel="noreferrer">
              <Typography component="span" color="primary" fontSize={14}>
                {t('pages.ausProduct.demandGenDetails.privacyPolicyAnchorTextLinkNz')}
              </Typography>
            </a>
            {t('pages.ausProduct.demandGenDetails.privacyPolicyAnchorTextAfterLinkNz')}
            <a href={t('pages.ausProduct.demandGenDetails.privacyPolicyAnchorTextLinAus')} target="_blank"
               rel="noreferrer">
              <Typography component="span" color="primary" fontSize={14}>
                {t('pages.ausProduct.demandGenDetails.privacyPolicyAnchorTextLinAus')}
              </Typography>
            </a>
            )
          </Typography>
        </Grid>
    ),
  },
  ...textFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DemandGenDetailsCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.websitePrivacyPolicy === '') {
    errors.websitePrivacyPolicy.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
