import { t } from 'i18next';
import * as yup from 'yup';

const yupSchema = {
  totalNumPages: yup.number()
    .typeError('Please enter a numerical value.')
    .min(0, t('pages.website.custom.content.numberOfSitePagesNegative'))
    .required(t('pages.website.custom.content.numberOfSitePagesRequired')),
  copyrightNeeded: yup.string().required(),
  copyrightPagesAmount: yup.number()
    .typeError('Please enter a numerical value.')
    .min(0, t('pages.website.custom.content.numberOfContentPagesNegative'))
    .required(t('pages.website.custom.content.numberOfContentPagesRequired')),
};

export default {
  yupValidations: yupSchema,
  customValidations: {},
};
