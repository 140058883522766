import {Box} from "@mui/system";
import {FormHelperText, Grid, Typography} from "@mui/material";
import {t} from "i18next";

export default function ThankYou() {
  return (
      <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="80vh"
      >
        <Grid
            container
            direction="column"
            alignItems="center"
            spacing={1}
            p={3}
            pl={{xs: 3, md: 1}}
            bgcolor="white"
            boxShadow={3}
            borderRadius={2}
            maxWidth={600}
        >
          <Grid item>
            <Typography fontWeight={600} variant="h4" gutterBottom>
              {t('facebookUrl.thankYouLabel')}
            </Typography>
          </Grid>
          <Grid item>
            <FormHelperText error={false}>
              {t('facebookUrl.youAreAllSetLabel')}
            </FormHelperText>
          </Grid>
        </Grid>
      </Box>
  );
}