export const specialties = [
  {
    id: 1,
    specialyName: 'Acupuncturist (Alternative Medicine)',
    subSpecialtyName: 'General',
  },
  {
    id: 2,
    specialyName: 'Addiction Medicine Specialist',
    subSpecialtyName: 'General',
  },
  {
    id: 3,
    specialyName: 'Adolescent Medicine Specialist',
    subSpecialtyName: 'General',
  },
  {
    id: 4,
    specialyName: 'Allergist-Immunologist',
    subSpecialtyName: 'General',
  },
  {
    id: 5,
    specialyName: 'Allergist-Immunologist',
    subSpecialtyName: 'Allergy',
  },
  {
    id: 6,
    specialyName: 'Allergist-Immunologist',
    subSpecialtyName: 'Clinical & Laboratory Immunology',
  },
  {
    id: 7,
    specialyName: 'Anaplastologist',
    subSpecialtyName: 'General',
  },
  {
    id: 8,
    specialyName: 'Anesthesiologist',
    subSpecialtyName: 'General',
  },
  {
    id: 9,
    specialyName: 'Anesthesiologist',
    subSpecialtyName: 'Addiction Medicine',
  },
  {
    id: 10,
    specialyName: 'Anesthesiologist',
    subSpecialtyName: 'Critical Care Medicine',
  },
  {
    id: 11,
    specialyName: 'Anesthesiologist',
    subSpecialtyName: 'Hospice & Palliative Medicine',
  },
  {
    id: 12,
    specialyName: 'Anesthesiologist',
    subSpecialtyName: 'Pain Medicine',
  },
  {
    id: 13,
    specialyName: 'Anesthesiologist',
    subSpecialtyName: 'Pediatric Anesthesiologist',
  },
  {
    id: 14,
    specialyName: 'Art Therapist',
    subSpecialtyName: 'General',
  },
  {
    id: 15,
    specialyName: 'Audiologist',
    subSpecialtyName: 'General',
  },
  {
    id: 16,
    specialyName: 'Audiologist',
    subSpecialtyName: 'Assistive Technology Practitioner',
  },
  {
    id: 17,
    specialyName: 'Audiologist',
    subSpecialtyName: 'Assistive Technology Supplier',
  },
  {
    id: 18,
    specialyName: 'Audiologist',
    subSpecialtyName: 'Hearing Aid Fitter',
  },
  {
    id: 19,
    specialyName: 'Bariatrician (Weight Loss Specialist)',
    subSpecialtyName: 'Bariatric Medicine (Bariatrician)',
  },
  {
    id: 20,
    specialyName: 'Cardiologist (Heart Specialist)',
    subSpecialtyName: 'General',
  },
  {
    id: 21,
    specialyName: 'Cardiologist (Heart Specialist)',
    subSpecialtyName: 'Clinical Cardiac Electrophysiology',
  },
  {
    id: 22,
    specialyName: 'Certified Nurse Midwife',
    subSpecialtyName: 'General',
  },
  {
    id: 23,
    specialyName: 'Certified Respiratory Therapist (CRT)',
    subSpecialtyName: 'General',
  },
  {
    id: 24,
    specialyName: 'Certified Respiratory Therapist (CRT)',
    subSpecialtyName: 'Critical Care',
  },
  {
    id: 25,
    specialyName: 'Certified Respiratory Therapist (CRT)',
    subSpecialtyName: 'Emergency Care',
  },
  {
    id: 26,
    specialyName: 'Certified Respiratory Therapist (CRT)',
    subSpecialtyName: 'Educational',
  },
  {
    id: 27,
    specialyName: 'Certified Respiratory Therapist (CRT)',
    subSpecialtyName: 'Geriatric Care',
  },
  {
    id: 28,
    specialyName: 'Certified Respiratory Therapist (CRT)',
    subSpecialtyName: 'General Care',
  },
  {
    id: 29,
    specialyName: 'Certified Respiratory Therapist (CRT)',
    subSpecialtyName: 'Home Health',
  },
  {
    id: 30,
    specialyName: 'Certified Respiratory Therapist (CRT)',
    subSpecialtyName: 'Pulmonary Diagnostics',
  },
  {
    id: 31,
    specialyName: 'Certified Respiratory Therapist (CRT)',
    subSpecialtyName: 'Pulmonary Rehabilitation',
  },
  {
    id: 32,
    specialyName: 'Certified Respiratory Therapist (CRT)',
    subSpecialtyName: 'Pulmonary Function Technology',
  },
  {
    id: 33,
    specialyName: 'Certified Respiratory Therapist (CRT)',
    subSpecialtyName: 'Palliative/Hospice',
  },
  {
    id: 34,
    specialyName: 'Certified Respiratory Therapist (CRT)',
    subSpecialtyName: 'Neonatal/Pediatrics',
  },
  {
    id: 35,
    specialyName: 'Certified Respiratory Therapist (CRT)',
    subSpecialtyName: 'Patient Transport',
  },
  {
    id: 36,
    specialyName: 'Certified Respiratory Therapist (CRT)',
    subSpecialtyName: 'SNF/Subacute Care',
  },
  {
    id: 37,
    specialyName: 'Chiropractor',
    subSpecialtyName: 'General',
  },
  {
    id: 38,
    specialyName: 'Chiropractor',
    subSpecialtyName: 'Independent Medical Examination',
  },
  {
    id: 39,
    specialyName: 'Chiropractor',
    subSpecialtyName: 'Internist',
  },
  {
    id: 40,
    specialyName: 'Chiropractor',
    subSpecialtyName: 'Neurology',
  },
  {
    id: 41,
    specialyName: 'Chiropractor',
    subSpecialtyName: 'Nutrition',
  },
  {
    id: 42,
    specialyName: 'Chiropractor',
    subSpecialtyName: 'Pediatric',
  },
  {
    id: 43,
    specialyName: 'Chiropractor',
    subSpecialtyName: 'Radiology',
  },
  {
    id: 44,
    specialyName: 'Chiropractor',
    subSpecialtyName: 'Rehabilitation',
  },
  {
    id: 45,
    specialyName: 'Chiropractor',
    subSpecialtyName: 'Sports Medicine',
  },
  {
    id: 46,
    specialyName: 'Chiropractor',
    subSpecialtyName: 'Thermography',
  },
  {
    id: 47,
    specialyName: 'Chiropractor',
    subSpecialtyName: 'Occupational Medicine',
  },
  {
    id: 48,
    specialyName: 'Chiropractor',
    subSpecialtyName: 'Orthopedic',
  },
  {
    id: 50,
    specialyName: 'Clinical / Laboratory Immunologist',
    subSpecialtyName: 'General',
  },
  {
    id: 51,
    specialyName: 'Clinical Neuropsychologist',
    subSpecialtyName: 'General',
  },
  {
    id: 52,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'General',
  },
  {
    id: 53,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Acute Care',
  },
  {
    id: 54,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Adult Health',
  },
  {
    id: 55,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Critical Care Medicine',
  },
  {
    id: 56,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Community Health/Public Health',
  },
  {
    id: 57,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Chronic Care',
  },
  {
    id: 58,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Emergency',
  },
  {
    id: 59,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Ethics',
  },
  {
    id: 60,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Family Health',
  },
  {
    id: 61,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Gerontology',
  },
  {
    id: 62,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Home Health',
  },
  {
    id: 63,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Holistic',
  },
  {
    id: 64,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Informatics',
  },
  {
    id: 65,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Long-Term Care',
  },
  {
    id: 66,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Medical-Surgical',
  },
  {
    id: 67,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Neonatal',
  },
  {
    id: 68,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Neuroscience',
  },
  {
    id: 69,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Pediatrics',
  },
  {
    id: 70,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Psych/Mental Health, Child & Adolescent',
  },
  {
    id: 71,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Psych/Mental Health',
  },
  {
    id: 72,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Psych/Mental Health, Adult',
  },
  {
    id: 73,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Psych/Mental Health, Child & Family',
  },
  {
    id: 74,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Psych/Mental Health, Chronically Ill',
  },
  {
    id: 75,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Psych/Mental Health, Community',
  },
  {
    id: 76,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Psych/Mental Health, Geropsychiatric',
  },
  {
    id: 77,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Perinatal',
  },
  {
    id: 78,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Perioperative',
  },
  {
    id: 79,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Rehabilitation',
  },
  {
    id: 80,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'School',
  },
  {
    id: 81,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Transplantation',
  },
  {
    id: 82,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Women\'s Health',
  },
  {
    id: 83,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Occupational Health',
  },
  {
    id: 84,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Oncology',
  },
  {
    id: 85,
    specialyName: 'Clinical Nurse Specialist (CNS)',
    subSpecialtyName: 'Oncology, Pediatrics',
  },
  {
    id: 86,
    specialyName: 'Clinical Pharmacologist',
    subSpecialtyName: 'General',
  },
  {
    id: 87,
    specialyName: 'Colon & Rectal Surgeon (Proctologist)',
    subSpecialtyName: 'General',
  },
  {
    id: 88,
    specialyName: 'Counselor',
    subSpecialtyName: 'General',
  },
  {
    id: 89,
    specialyName: 'Counselor',
    subSpecialtyName: 'Addiction & Substance Use Disorder',
  },
  {
    id: 90,
    specialyName: 'Counselor',
    subSpecialtyName: 'Mental Health',
  },
  {
    id: 91,
    specialyName: 'Counselor',
    subSpecialtyName: 'Pastoral',
  },
  {
    id: 92,
    specialyName: 'Counselor',
    subSpecialtyName: 'Professional',
  },
  {
    id: 93,
    specialyName: 'Counselor',
    subSpecialtyName: 'School',
  },
  {
    id: 94,
    specialyName: 'Critical Care Specialist',
    subSpecialtyName: 'Doctor of Critical Care Medicine',
  },
  {
    id: 95,
    specialyName: 'Dance Therapist',
    subSpecialtyName: 'General',
  },
  {
    id: 96,
    specialyName: 'Dentist',
    subSpecialtyName: 'General',
  },
  {
    id: 97,
    specialyName: 'Dentist',
    subSpecialtyName: 'Dental Public Health',
  },
  {
    id: 98,
    specialyName: 'Dentist',
    subSpecialtyName: 'Endodontics (Endodontist)',
  },
  {
    id: 99,
    specialyName: 'Dentist',
    subSpecialtyName: 'Primary Dental Care',
  },
  {
    id: 100,
    specialyName: 'Dentist',
    subSpecialtyName: 'Oral and Maxillofacial Pathology',
  },
  {
    id: 101,
    specialyName: 'Dentist',
    subSpecialtyName: 'Pediatric Dentist',
  },
  {
    id: 102,
    specialyName: 'Dentist',
    subSpecialtyName: 'Periodontics (Periodontist)',
  },
  {
    id: 103,
    specialyName: 'Dentist',
    subSpecialtyName: 'Prosthodontics (Prosthodontist)',
  },
  {
    id: 104,
    specialyName: 'Dentist',
    subSpecialtyName: 'Oral & Maxillofacial Surgery',
  },
  {
    id: 105,
    specialyName: 'Dentist',
    subSpecialtyName: 'Oral & Maxillofacial Radiology',
  },
  {
    id: 106,
    specialyName: 'Dentist',
    subSpecialtyName: 'Orthodontics & Dentofacial Orthopedics (Orthodontist)',
  },
  {
    id: 112,
    specialyName: 'Dentist',
    subSpecialtyName: 'Dentist - Dentist Anesthesiologist',
  },
  {
    id: 113,
    specialyName: 'Denturist (Dentures Specialist)',
    subSpecialtyName: 'General',
  },
  {
    id: 114,
    specialyName: 'Dermatologist (Skin Specialist)',
    subSpecialtyName: 'General',
  },
  {
    id: 115,
    specialyName: 'Dermatologist (Skin Specialist)',
    subSpecialtyName: 'MOHS-Micrographic Surgery',
  },
  {
    id: 116,
    specialyName: 'Dermatologist (Skin Specialist)',
    subSpecialtyName: 'Dermatopathology',
  },
  {
    id: 117,
    specialyName: 'Dermatologist (Skin Specialist)',
    subSpecialtyName: 'Clinical & Laboratory Dermatological Immunology',
  },
  {
    id: 118,
    specialyName: 'Dermatologist (Skin Specialist)',
    subSpecialtyName: 'Pediatric Dermatology',
  },
  {
    id: 119,
    specialyName: 'Dermatologist (Skin Specialist)',
    subSpecialtyName: 'Procedural Dermatologist',
  },
  {
    id: 122,
    specialyName: 'Developmental Therapist',
    subSpecialtyName: 'General',
  },
  {
    id: 123,
    specialyName: 'Dietary Manager',
    subSpecialtyName: 'General',
  },
  {
    id: 124,
    specialyName: 'Emergency Doctor',
    subSpecialtyName: 'General',
  },
  {
    id: 125,
    specialyName: 'Emergency Doctor',
    subSpecialtyName: 'Emergency Medical Services (EMS)',
  },
  {
    id: 126,
    specialyName: 'Emergency Doctor',
    subSpecialtyName: 'Undersea & Hyperbaric Medicine',
  },
  {
    id: 127,
    specialyName: 'Emergency Doctor',
    subSpecialtyName: 'Hospice & Palliative Medicine',
  },
  {
    id: 128,
    specialyName: 'Emergency Doctor',
    subSpecialtyName: 'Pediatric Emergency Medicine',
  },
  {
    id: 129,
    specialyName: 'Emergency Doctor',
    subSpecialtyName: 'Sports Medicine',
  },
  {
    id: 130,
    specialyName: 'Emergency Doctor',
    subSpecialtyName: 'Medical Toxicology',
  },
  {
    id: 131,
    specialyName: 'Endocrinologist (Diabetes & Hormones Specialist)',
    subSpecialtyName: 'General',
  },
  {
    id: 132,
    specialyName: 'Family Doctor',
    subSpecialtyName: 'General',
  },
  {
    id: 133,
    specialyName: 'Family Doctor',
    subSpecialtyName: 'Adolescent Medicine',
  },
  {
    id: 134,
    specialyName: 'Family Doctor',
    subSpecialtyName: 'Addiction Medicine',
  },
  {
    id: 135,
    specialyName: 'Family Doctor',
    subSpecialtyName: 'Adult Medicine',
  },
  {
    id: 136,
    specialyName: 'Family Doctor',
    subSpecialtyName: 'Bariatric Medicine',
  },
  {
    id: 137,
    specialyName: 'Family Doctor',
    subSpecialtyName: 'Geriatric Medicine',
  },
  {
    id: 138,
    specialyName: 'Family Doctor',
    subSpecialtyName: 'Hospice & Palliative Medicine',
  },
  {
    id: 139,
    specialyName: 'Family Doctor',
    subSpecialtyName: 'Sports Medicine',
  },
  {
    id: 141,
    specialyName: 'Family Doctor',
    subSpecialtyName: 'Family Medicine - Sleep Medicine',
  },
  {
    id: 142,
    specialyName: 'Gastroenterologist (Stomach & Bowel Specialist)',
    subSpecialtyName: 'General',
  },
  {
    id: 143,
    specialyName: 'General Practice Doctor (General Practitioner)',
    subSpecialtyName: 'General',
  },
  {
    id: 147,
    specialyName: 'Geriatric Medicine Doctor',
    subSpecialtyName: 'General',
  },
  {
    id: 148,
    specialyName: 'Hearing Instrument Specialist',
    subSpecialtyName: 'General',
  },
  {
    id: 149,
    specialyName: 'Hematology / Oncology Specialist',
    subSpecialtyName: 'General',
  },
  {
    id: 150,
    specialyName: 'Hematology / Oncology Specialist',
    subSpecialtyName: 'Oncologist (Cancer)',
  },
  {
    id: 151,
    specialyName: 'Hematology / Oncology Specialist',
    subSpecialtyName: 'Hematologist (Blood)',
  },
  {
    id: 152,
    specialyName: 'Hepatologist (Liver, Gallbladder & Pancreas)',
    subSpecialtyName: 'General',
  },
  {
    id: 153,
    specialyName: 'Homeopath (Alternative Medicine)',
    subSpecialtyName: 'General',
  },
  {
    id: 154,
    specialyName: 'Hospice & Palliative Medicine Specialist',
    subSpecialtyName: 'General',
  },
  {
    id: 155,
    specialyName: 'Hospice & Palliative Surgeon',
    subSpecialtyName: 'General',
  },
  {
    id: 156,
    specialyName: 'Hospitalist',
    subSpecialtyName: 'General',
  },
  {
    id: 157,
    specialyName: 'Independent Medical Examiner',
    subSpecialtyName: 'General',
  },
  {
    id: 158,
    specialyName: 'Infectious Disease Specialist',
    subSpecialtyName: 'General',
  },
  {
    id: 159,
    specialyName: 'Internist',
    subSpecialtyName: 'General',
  },
  {
    id: 161,
    specialyName: 'Internist',
    subSpecialtyName: 'Sports Medicine',
  },
  {
    id: 162,
    specialyName: 'Internist',
    subSpecialtyName: 'Internal Medicine - Advanced Heart Failure and Transplant Cardiology',
  },
  {
    id: 163,
    specialyName: 'Internist',
    subSpecialtyName: 'Internal Medicine - Allergy & Immunology',
  },
  {
    id: 164,
    specialyName: 'Internist',
    subSpecialtyName: 'Internal Medicine - Hypertension Specialist',
  },
  {
    id: 165,
    specialyName: 'Internist',
    subSpecialtyName: 'Internal Medicine - Adult Congenital Heart Disease',
  },
  {
    id: 166,
    specialyName: 'Interventional Cardiologist',
    subSpecialtyName: 'General',
  },
  {
    id: 167,
    specialyName: 'Kinesiotherapist',
    subSpecialtyName: 'General',
  },
  {
    id: 168,
    specialyName: 'Legal Medicine Specialist',
    subSpecialtyName: 'General',
  },
  {
    id: 169,
    specialyName: 'Licensed Physchiatric Technician (LPT)',
    subSpecialtyName: 'General',
  },
  {
    id: 170,
    specialyName: 'Licensed Practical Nurse (LPN)',
    subSpecialtyName: 'General',
  },
  {
    id: 172,
    specialyName: 'Licensed Vocational Nurse (LVN)',
    subSpecialtyName: 'General',
  },
  {
    id: 173,
    specialyName: 'Magnetic Resonance Imaging (MRI) Specialist',
    subSpecialtyName: 'General',
  },
  {
    id: 174,
    specialyName: 'Marriage & Family Therapist',
    subSpecialtyName: 'General',
  },
  {
    id: 175,
    specialyName: 'Massage Therapist',
    subSpecialtyName: 'Licensed Massage Therapist',
  },
  {
    id: 176,
    specialyName: 'Mechanotherapist',
    subSpecialtyName: 'General',
  },
  {
    id: 177,
    specialyName: 'Medical Geneticist (Genetic & Hereditary Disease Specialist)',
    subSpecialtyName: 'Clinical Cytogenetics',
  },
  {
    id: 178,
    specialyName: 'Medical Geneticist (Genetic & Hereditary Disease Specialist)',
    subSpecialtyName: 'Clinical Biochemical Genetics',
  },
  {
    id: 179,
    specialyName: 'Medical Geneticist (Genetic & Hereditary Disease Specialist)',
    subSpecialtyName: 'Clinical Molecular Genetics',
  },
  {
    id: 180,
    specialyName: 'Medical Geneticist',
    subSpecialtyName: 'Clinical Genetics',
  },
  {
    id: 181,
    specialyName: 'Medical Geneticist',
    subSpecialtyName: 'Medical Genetics',
  },
  {
    id: 182,
    specialyName: 'Medical Geneticist',
    subSpecialtyName: 'Molecular Genetic Pathology',
  },
  {
    id: 183,
    specialyName: 'Medical Geneticist',
    subSpecialtyName: 'Medical Genetics, Ph.D. Medical Genetics',
  },
  {
    id: 184,
    specialyName: 'Medical Geneticist',
    subSpecialtyName: 'Genetic Counselor, MS',
  },
  {
    id: 185,
    specialyName: 'Music Therapist',
    subSpecialtyName: 'General',
  },
  {
    id: 186,
    specialyName: 'Naprapath (Alternative Medicine)',
    subSpecialtyName: 'General',
  },
  {
    id: 187,
    specialyName: 'Naturopath',
    subSpecialtyName: 'General',
  },
  {
    id: 188,
    specialyName: 'Nephrologist (Kidney Specialist)',
    subSpecialtyName: 'General',
  },
  {
    id: 189,
    specialyName: 'Neurological Surgeon',
    subSpecialtyName: 'General',
  },
  {
    id: 190,
    specialyName: 'Neuromusculoskeletal Medicine Specialist',
    subSpecialtyName: 'Osteopathic Manual Manipulation',
  },
  {
    id: 191,
    specialyName: 'Neuromusculoskeletal Medicine Specialist',
    subSpecialtyName: 'Sports Medicine',
  },
  {
    id: 192,
    specialyName: 'Nuclear Medicine Specialist',
    subSpecialtyName: 'General',
  },
  {
    id: 193,
    specialyName: 'Nuclear Medicine Specialist',
    subSpecialtyName: 'Nuclear Cardiology',
  },
  {
    id: 194,
    specialyName: 'Nuclear Medicine Specialist',
    subSpecialtyName: 'Nuclear Imaging & Therapy',
  },
  {
    id: 195,
    specialyName: 'Nuclear Medicine Specialist',
    subSpecialtyName: 'In Vivo & In Vitro Nuclear Medicine',
  },
  {
    id: 196,
    specialyName: 'Nurse Anesthetist',
    subSpecialtyName: 'General',
  },
  {
    id: 197,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'General',
  },
  {
    id: 198,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Acute Care',
  },
  {
    id: 199,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Adult Health',
  },
  {
    id: 200,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Critical Care Medicine',
  },
  {
    id: 201,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Community Health',
  },
  {
    id: 202,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Family',
  },
  {
    id: 203,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Gerontology',
  },
  {
    id: 204,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Neonatal',
  },
  {
    id: 205,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Neonatal, Critical Care',
  },
  {
    id: 206,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Pediatrics',
  },
  {
    id: 207,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Pediatrics, Critical Care',
  },
  {
    id: 208,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Psych/Mental Health',
  },
  {
    id: 209,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Perinatal',
  },
  {
    id: 210,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Primary Care',
  },
  {
    id: 211,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'School',
  },
  {
    id: 212,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Women\'s Health',
  },
  {
    id: 213,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Obstetrics & Gynecology',
  },
  {
    id: 214,
    specialyName: 'Nurse Practitioner (NP)',
    subSpecialtyName: 'Occupational Health',
  },
  {
    id: 216,
    specialyName: 'Nutritionist',
    subSpecialtyName: 'General',
  },
  {
    id: 217,
    specialyName: 'Nutritionist',
    subSpecialtyName: 'Nutritionist - Nutrition, Education',
  },
  {
    id: 218,
    specialyName: 'Nutritionist',
    subSpecialtyName: 'Dietetic Technician, Registered',
  },
  {
    id: 219,
    specialyName: 'Obstetrician / Gynecologist (OBGYN)',
    subSpecialtyName: 'General',
  },
  {
    id: 220,
    specialyName: 'Obstetrician / Gynecologist (OBGYN)',
    subSpecialtyName: 'Bariatric Medicine',
  },
  {
    id: 221,
    specialyName: 'Obstetrician / Gynecologist (OBGYN)',
    subSpecialtyName: 'Critical Care Medicine',
  },
  {
    id: 222,
    specialyName: 'Obstetrician / Gynecologist (OBGYN)',
    subSpecialtyName: 'Reproductive Endocrinology',
  },
  {
    id: 223,
    specialyName: 'Obstetrician / Gynecologist (OBGYN)',
    subSpecialtyName: 'Gynecology',
  },
  {
    id: 224,
    specialyName: 'Obstetrician / Gynecologist (OBGYN)',
    subSpecialtyName: 'Hospice & Palliative Medicine',
  },
  {
    id: 225,
    specialyName: 'Obstetrician / Gynecologist (OBGYN)',
    subSpecialtyName: 'Maternal & Fetal Medicine',
  },
  {
    id: 226,
    specialyName: 'Obstetrician / Gynecologist (OBGYN)',
    subSpecialtyName: 'Obstetrics',
  },
  {
    id: 227,
    specialyName: 'Obstetrician / Gynecologist (OBGYN)',
    subSpecialtyName: 'Gynecologic Oncology',
  },
  {
    id: 229,
    specialyName: 'Obstetrician / Gynecologist (OBGYN)',
    subSpecialtyName: 'Obstetrics & Gynecology - Female Pelvic Medicine and Reconstructive Surgery',
  },
  {
    id: 232,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'General',
  },
  {
    id: 233,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Ergonomics',
  },
  {
    id: 234,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Hand Specialist',
  },
  {
    id: 235,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Human Factors',
  },
  {
    id: 236,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Neurorehabilitation',
  },
  {
    id: 237,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Pediatrics',
  },
  {
    id: 238,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Driving and Community Mobility',
  },
  {
    id: 239,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Environmental Modification',
  },
  {
    id: 240,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Feeding, Eating & Swallowing',
  },
  {
    id: 241,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Gerontology',
  },
  {
    id: 242,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Low Vision',
  },
  {
    id: 243,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Mental Health',
  },
  {
    id: 244,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Physical Rehabilitation',
  },
  {
    id: 245,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Occupational Therapy Assistant',
  },
  {
    id: 246,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Occupational Therapy Assistant - Environmental Modification',
  },
  {
    id: 247,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Occupational Therapy Assistant - Feeding, Eating & Swallowing',
  },
  {
    id: 248,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Occupational Therapy Assistant - Low Vision',
  },
  {
    id: 249,
    specialyName: 'Occupational Therapist (OT)',
    subSpecialtyName: 'Occupational Therapy Assistant - Driving and Community Mobility',
  },
  {
    id: 250,
    specialyName: 'Ophthalmologist (Eye Doctor)',
    subSpecialtyName: 'General',
  },
  {
    id: 251,
    specialyName: 'Ophthalmologist (Eye Doctor)',
    subSpecialtyName: 'Ophthalmology - Glaucoma Specialist',
  },
  {
    id: 252,
    specialyName: 'Ophthalmologist (Eye Doctor)',
    subSpecialtyName: 'Ophthalmology - Retina Specialist',
  },
  {
    id: 253,
    specialyName: 'Ophthalmologist (Eye Doctor)',
    subSpecialtyName: 'Ophthalmology - Uveitis and Ocular Inflammatory Disease',
  },
  {
    id: 254,
    specialyName: 'Ophthalmologist (Eye Doctor)',
    subSpecialtyName: 'Ophthalmology - Neuro-ophthalmology',
  },
  {
    id: 255,
    specialyName: 'Ophthalmologist (Eye Doctor)',
    subSpecialtyName: 'Ophthalmology - Pediatric Ophthalmology and Strabismus Specialist',
  },
  {
    id: 256,
    specialyName: 'Ophthalmologist (Eye Doctor)',
    subSpecialtyName: 'Ophthalmology - Cornea and External Diseases Specialist',
  },
  {
    id: 257,
    specialyName: 'Ophthalmologist (Eye Doctor)',
    subSpecialtyName: 'Ophthalmology - Ophthalmic Plastic and Reconstructive Surgery',
  },
  {
    id: 258,
    specialyName: 'Optometrist (Eye & Vision Specialist)',
    subSpecialtyName: 'General',
  },
  {
    id: 259,
    specialyName: 'Optometrist (Eye & Vision Specialist)',
    subSpecialtyName: 'Corneal and Contact Management',
  },
  {
    id: 260,
    specialyName: 'Optometrist (Eye & Vision Specialist)',
    subSpecialtyName: 'Low Vision Rehabilitation',
  },
  {
    id: 261,
    specialyName: 'Optometrist (Eye & Vision Specialist)',
    subSpecialtyName: 'Pediatrics',
  },
  {
    id: 262,
    specialyName: 'Optometrist (Eye & Vision Specialist)',
    subSpecialtyName: 'Sports Vision',
  },
  {
    id: 263,
    specialyName: 'Optometrist (Eye & Vision Specialist)',
    subSpecialtyName: 'Vision Therapy',
  },
  {
    id: 264,
    specialyName: 'Optometrist (Eye & Vision Specialist)',
    subSpecialtyName: 'Occupational Vision',
  },
  {
    id: 265,
    specialyName: 'Orthopedic Surgeon',
    subSpecialtyName: 'General',
  },
  {
    id: 266,
    specialyName: 'Orthopedic Surgeon',
    subSpecialtyName: 'Hand Surgery',
  },
  {
    id: 267,
    specialyName: 'Orthopedic Surgeon',
    subSpecialtyName: 'Surgery of the Spine',
  },
  {
    id: 268,
    specialyName: 'Orthopedic Surgeon',
    subSpecialtyName: 'Foot & Ankle Surgery',
  },
  {
    id: 269,
    specialyName: 'Orthopedic Surgeon',
    subSpecialtyName: 'Sports Medicine',
  },
  {
    id: 270,
    specialyName: 'Orthopedic Surgeon',
    subSpecialtyName: 'Adult Reconstructive Orthopaedic Surgery',
  },
  {
    id: 271,
    specialyName: 'Orthopedic Surgeon',
    subSpecialtyName: 'Pediatric Orthopaedic Surgery',
  },
  {
    id: 272,
    specialyName: 'Orthopedic Surgeon',
    subSpecialtyName: 'Orthopaedic Trauma',
  },
  {
    id: 275,
    specialyName: 'Orthotics/Prosthetics Fitter',
    subSpecialtyName: 'General',
  },
  {
    id: 276,
    specialyName: 'Orthotist',
    subSpecialtyName: 'General',
  },
  {
    id: 277,
    specialyName: 'Otolaryngologist (Ear, Nose & Throat - ENT)',
    subSpecialtyName: 'General',
  },
  {
    id: 278,
    specialyName: 'Otolaryngologist (Ear, Nose & Throat - ENT)',
    subSpecialtyName: 'Pediatric Otolaryngology',
  },
  {
    id: 279,
    specialyName: 'Otolaryngologist (Ear, Nose & Throat - ENT)',
    subSpecialtyName: 'Sleep Medicine',
  },
  {
    id: 280,
    specialyName: 'Otolaryngologist (Ear, Nose & Throat - ENT)',
    subSpecialtyName: 'Facial Plastic Surgery',
  },
  {
    id: 281,
    specialyName: 'Otolaryngologist (Ear, Nose & Throat - ENT)',
    subSpecialtyName: 'Head & Neck Oncology Surgery',
  },
  {
    id: 282,
    specialyName: 'Otolaryngologist (Ear, Nose & Throat - ENT)',
    subSpecialtyName: 'Otolaryngic Allergy',
  },
  {
    id: 283,
    specialyName: 'Otolaryngologist (Ear, Nose & Throat - ENT)',
    subSpecialtyName: 'Otology & Neurotology',
  },
  {
    id: 284,
    specialyName: 'Otolaryngologist (Ear, Nose & Throat - ENT)',
    subSpecialtyName: 'Facial Plastic & Reconstructive Surgery',
  },
  {
    id: 292,
    specialyName: 'Pain Medicine Specialist',
    subSpecialtyName: 'General',
  },
  {
    id: 293,
    specialyName: 'Pain Medicine Specialist',
    subSpecialtyName: 'Pain Medicine - Interventional Pain Medicine',
  },
  {
    id: 294,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Blood Banking & Transfusion Medicine',
  },
  {
    id: 295,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Clinical Pathology',
  },
  {
    id: 296,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Cytopathology',
  },
  {
    id: 297,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Dermatopathology',
  },
  {
    id: 298,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Forensic Pathology',
  },
  {
    id: 299,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Hematology',
  },
  {
    id: 300,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Immunopathology',
  },
  {
    id: 301,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Medical Microbiology',
  },
  {
    id: 302,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Neuropathology',
  },
  {
    id: 303,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Molecular Genetic Pathology',
  },
  {
    id: 304,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Anatomic Pathology',
  },
  {
    id: 305,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Anatomic Pathology & Clinical Pathology',
  },
  {
    id: 306,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Chemical Pathology',
  },
  {
    id: 307,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Clinical Pathology/Laboratory Medicine',
  },
  {
    id: 308,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Pediatric Pathology',
  },
  {
    id: 309,
    specialyName: 'Pathologist',
    subSpecialtyName: 'Pathology - Clinical Informatics',
  },
  {
    id: 310,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'General',
  },
  {
    id: 311,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Adolescent Medicine',
  },
  {
    id: 312,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Hospice & Palliative Medicine',
  },
  {
    id: 313,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Clinical & Laboratory Immunology',
  },
  {
    id: 314,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Neonatal-Perinatal Medicine',
  },
  {
    id: 315,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Developmental - Behavioral Pediatrics',
  },
  {
    id: 316,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Neurodevelopmental Disabilities',
  },
  {
    id: 317,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Pediatric Allergy & Immunology',
  },
  {
    id: 318,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Pediatric Cardiology',
  },
  {
    id: 319,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Pediatric Critical Care Medicine',
  },
  {
    id: 320,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Pediatric Emergency Medicine',
  },
  {
    id: 321,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Pediatric Endocrinology',
  },
  {
    id: 322,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Pediatric Gastroenterology',
  },
  {
    id: 323,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Pediatric Hematology-Oncology',
  },
  {
    id: 324,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Pediatric Infectious Diseases',
  },
  {
    id: 325,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Pediatric Nephrology',
  },
  {
    id: 326,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Pediatric Pulmonology',
  },
  {
    id: 327,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Pediatric Rheumatology',
  },
  {
    id: 328,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Sports Medicine',
  },
  {
    id: 329,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Sleep Medicine',
  },
  {
    id: 330,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Medical Toxicology',
  },
  {
    id: 331,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Pediatric Transplant Hepatology',
  },
  {
    id: 332,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Pediatrics - Obesity Medicine',
  },
  {
    id: 333,
    specialyName: 'Pediatrician (Kids / Children Specialist)',
    subSpecialtyName: 'Pediatrics - Child Abuse Pediatrics',
  },
  {
    id: 334,
    specialyName: 'Phlebologist (Vein Specialist)',
    subSpecialtyName: 'General',
  },
  {
    id: 335,
    specialyName: 'Physical Therapist (PT)',
    subSpecialtyName: 'General',
  },
  {
    id: 336,
    specialyName: 'Physical Therapist (PT)',
    subSpecialtyName: 'Cardiopulmonary',
  },
  {
    id: 337,
    specialyName: 'Physical Therapist (PT)',
    subSpecialtyName: 'Ergonomics',
  },
  {
    id: 338,
    specialyName: 'Physical Therapist (PT)',
    subSpecialtyName: 'Clinical Electrophysiology',
  },
  {
    id: 339,
    specialyName: 'Physical Therapist (PT)',
    subSpecialtyName: 'Geriatrics',
  },
  {
    id: 340,
    specialyName: 'Physical Therapist (PT)',
    subSpecialtyName: 'Hand',
  },
  {
    id: 341,
    specialyName: 'Physical Therapist (PT)',
    subSpecialtyName: 'Human Factors',
  },
  {
    id: 342,
    specialyName: 'Physical Therapist (PT)',
    subSpecialtyName: 'Neurology',
  },
  {
    id: 343,
    specialyName: 'Physical Therapist (PT)',
    subSpecialtyName: 'Pediatrics',
  },
  {
    id: 344,
    specialyName: 'Physical Therapist (PT)',
    subSpecialtyName: 'Sports',
  },
  {
    id: 345,
    specialyName: 'Physical Therapist (PT)',
    subSpecialtyName: 'Orthopedic',
  },
  {
    id: 347,
    specialyName: 'Physical Therapist (PT)',
    subSpecialtyName: 'Physical Therapy Assistant',
  },
  {
    id: 348,
    specialyName: 'Physician Assistant (PA)',
    subSpecialtyName: 'General',
  },
  {
    id: 349,
    specialyName: 'Physician Assistant (PA)',
    subSpecialtyName: 'Medical',
  },
  {
    id: 350,
    specialyName: 'Physician Assistant (PA)',
    subSpecialtyName: 'Surgical',
  },
  {
    id: 352,
    specialyName: 'Plastic, Reconstructive, and Cosmetic Surgeon',
    subSpecialtyName: 'General',
  },
  {
    id: 353,
    specialyName: 'Plastic, Reconstructive, and Cosmetic Surgeon',
    subSpecialtyName: 'Head and Neck Surgery Specialist',
  },
  {
    id: 354,
    specialyName: 'Plastic, Reconstructive, and Cosmetic Surgeon',
    subSpecialtyName: 'Hand Surgery Specialist',
  },
  {
    id: 355,
    specialyName: 'Plastic, Reconstructive, and Cosmetic Surgeon',
    subSpecialtyName: 'Plastic Surgery - Plastic Surgery Within the Head and Neck',
  },
  {
    id: 356,
    specialyName: 'Podiatrist (Foot Specialist)',
    subSpecialtyName: 'Podiatrist',
  },
  {
    id: 357,
    specialyName: 'Podiatrist (Foot Specialist)',
    subSpecialtyName: 'General Practice',
  },
  {
    id: 358,
    specialyName: 'Podiatrist (Foot Specialist)',
    subSpecialtyName: 'Public Medicine',
  },
  {
    id: 359,
    specialyName: 'Podiatrist (Foot Specialist)',
    subSpecialtyName: 'Primary Podiatric Medicine',
  },
  {
    id: 360,
    specialyName: 'Podiatrist (Foot Specialist)',
    subSpecialtyName: 'Radiology',
  },
  {
    id: 361,
    specialyName: 'Podiatrist (Foot Specialist)',
    subSpecialtyName: 'Sports Medicine',
  },
  {
    id: 362,
    specialyName: 'Podiatrist (Foot Specialist)',
    subSpecialtyName: 'Foot & Ankle Surgery',
  },
  {
    id: 363,
    specialyName: 'Podiatrist (Foot Specialist)',
    subSpecialtyName: 'Foot Surgery',
  },
  {
    id: 364,
    specialyName: 'Podiatrist (Foot Specialist)',
    subSpecialtyName: 'Assistant, Podiatric',
  },
  {
    id: 365,
    specialyName: 'Poetry Therapist',
    subSpecialtyName: 'General',
  },
  {
    id: 366,
    specialyName: 'Preventive Medicine Specialist',
    subSpecialtyName: 'Aerospace Medicine',
  },
  {
    id: 367,
    specialyName: 'Preventive Medicine Specialist',
    subSpecialtyName: 'Undersea and Hyperbaric Medicine',
  },
  {
    id: 368,
    specialyName: 'Preventive Medicine Specialist',
    subSpecialtyName: 'Preventive Medicine/Occupational Environmental Medicine',
  },
  {
    id: 369,
    specialyName: 'Preventive Medicine Specialist',
    subSpecialtyName: 'Public Health & General Preventive Medicine',
  },
  {
    id: 370,
    specialyName: 'Preventive Medicine Specialist',
    subSpecialtyName: 'Sports Medicine',
  },
  {
    id: 371,
    specialyName: 'Preventive Medicine Specialist',
    subSpecialtyName: 'Medical Toxicology',
  },
  {
    id: 372,
    specialyName: 'Preventive Medicine Specialist',
    subSpecialtyName: 'Occupational Medicine',
  },
  {
    id: 373,
    specialyName: 'Preventive Medicine Specialist',
    subSpecialtyName: 'Preventive Medicine - Obesity Medicine',
  },
  {
    id: 374,
    specialyName: 'Preventive Medicine Specialist',
    subSpecialtyName: 'Preventive Medicine - Clinical Informatics',
  },
  {
    id: 375,
    specialyName: 'Preventive Medicine Specialist',
    subSpecialtyName: 'Preventive Medicine - Addiction Medicine',
  },
  {
    id: 376,
    specialyName: 'Prosthetist',
    subSpecialtyName: 'General',
  },
  {
    id: 377,
    specialyName: 'Psychiatrist',
    subSpecialtyName: 'Forensic Psychiatry',
  },
  {
    id: 378,
    specialyName: 'Psychiatrist',
    subSpecialtyName: 'Psychosomatic Medicine',
  },
  {
    id: 379,
    specialyName: 'Psychiatrist',
    subSpecialtyName: 'General',
  },
  {
    id: 380,
    specialyName: 'Psychiatrist',
    subSpecialtyName: 'Addiction Psychiatry',
  },
  {
    id: 381,
    specialyName: 'Psychiatrist',
    subSpecialtyName: 'Child & Adolescent Psychiatry',
  },
  {
    id: 382,
    specialyName: 'Psychiatrist',
    subSpecialtyName: 'Geriatric Psychiatry',
  },
  {
    id: 383,
    specialyName: 'Neurologist',
    subSpecialtyName: 'Diagnostic Neuroimaging',
  },
  {
    id: 384,
    specialyName: 'Neurologist',
    subSpecialtyName: 'Neuromuscular Medicine',
  },
  {
    id: 385,
    specialyName: 'Neurologist',
    subSpecialtyName: 'General',
  },
  {
    id: 386,
    specialyName: 'Neurologist',
    subSpecialtyName: 'Special Qualifications in Child Neurology',
  },
  {
    id: 387,
    specialyName: 'Neurologist',
    subSpecialtyName: 'Neurodevelopmental Disabilities',
  },
  {
    id: 388,
    specialyName: 'Neurologist',
    subSpecialtyName: 'Pain Medicine',
  },
  {
    id: 389,
    specialyName: 'Neurologist',
    subSpecialtyName: 'Vascular Neurology',
  },
  {
    id: 390,
    specialyName: 'Psychoanalyst',
    subSpecialtyName: 'General',
  },
  {
    id: 391,
    specialyName: 'Psychologist',
    subSpecialtyName: 'General',
  },
  {
    id: 392,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Addiction & Substance Use Disorders',
  },
  {
    id: 393,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Adult Development & Aging',
  },
  {
    id: 394,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Cognitive & Behavioral',
  },
  {
    id: 395,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Clinical',
  },
  {
    id: 396,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Counseling',
  },
  {
    id: 397,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Clinical Child & Adolescent',
  },
  {
    id: 398,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Educational',
  },
  {
    id: 399,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Exercise & Sports',
  },
  {
    id: 400,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Family',
  },
  {
    id: 401,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Forensic',
  },
  {
    id: 402,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Health',
  },
  {
    id: 403,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Health Service',
  },
  {
    id: 404,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Men & Masculinity',
  },
  {
    id: 405,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Mental Retardation & Developmental Disabilities',
  },
  {
    id: 406,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Medical (Prescribing)',
  },
  {
    id: 407,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Psychoanalysis',
  },
  {
    id: 408,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Psychotherapy',
  },
  {
    id: 409,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Group Psychotherapy',
  },
  {
    id: 410,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Rehabilitation',
  },
  {
    id: 411,
    specialyName: 'Psychologist',
    subSpecialtyName: 'School',
  },
  {
    id: 412,
    specialyName: 'Psychologist',
    subSpecialtyName: 'Women',
  },
  {
    id: 414,
    specialyName: 'Pulmonologist (Lung Specialist)',
    subSpecialtyName: 'General',
  },
  {
    id: 415,
    specialyName: 'Radiologist',
    subSpecialtyName: 'Body Imaging',
  },
  {
    id: 416,
    specialyName: 'Radiologist',
    subSpecialtyName: 'Diagnostic Neuroimaging',
  },
  {
    id: 417,
    specialyName: 'Radiologist',
    subSpecialtyName: 'Hospice & Palliative Medicine',
  },
  {
    id: 418,
    specialyName: 'Radiologist',
    subSpecialtyName: 'Neuroradiology',
  },
  {
    id: 419,
    specialyName: 'Radiologist',
    subSpecialtyName: 'Nuclear Radiology',
  },
  {
    id: 420,
    specialyName: 'Radiologist',
    subSpecialtyName: 'Pediatric Radiology',
  },
  {
    id: 421,
    specialyName: 'Radiologist',
    subSpecialtyName: 'Radiation Oncology',
  },
  {
    id: 422,
    specialyName: 'Radiologist',
    subSpecialtyName: 'Diagnostic Radiology',
  },
  {
    id: 423,
    specialyName: 'Radiologist',
    subSpecialtyName: 'Therapeutic Radiology',
  },
  {
    id: 424,
    specialyName: 'Radiologist',
    subSpecialtyName: 'Vascular & Interventional Radiology',
  },
  {
    id: 425,
    specialyName: 'Radiologist',
    subSpecialtyName: 'Radiological Physics',
  },
  {
    id: 426,
    specialyName: 'Radiologist',
    subSpecialtyName: 'Diagnostic Ultrasound',
  },
  {
    id: 428,
    specialyName: 'Recreation Therapist',
    subSpecialtyName: 'General',
  },
  {
    id: 429,
    specialyName: 'Reflexologist',
    subSpecialtyName: 'General',
  },
  {
    id: 430,
    specialyName: 'Dietitian',
    subSpecialtyName: 'General',
  },
  {
    id: 431,
    specialyName: 'Dietitian',
    subSpecialtyName: 'Pediatric Nutrition',
  },
  {
    id: 432,
    specialyName: 'Dietitian',
    subSpecialtyName: 'Renal Nutrition',
  },
  {
    id: 433,
    specialyName: 'Dietitian',
    subSpecialtyName: 'Metabolic Nutrition',
  },
  {
    id: 434,
    specialyName: 'Dietitian',
    subSpecialtyName: 'Gerontological Nutrition',
  },
  {
    id: 435,
    specialyName: 'Dietitian',
    subSpecialtyName: 'Obesity and Weight Management Nutrition',
  },
  {
    id: 436,
    specialyName: 'Dietitian',
    subSpecialtyName: 'Oncology Nutrition',
  },
  {
    id: 437,
    specialyName: 'Dietitian',
    subSpecialtyName: 'Pediatric Critical Care Nutrition',
  },
  {
    id: 438,
    specialyName: 'Dietitian',
    subSpecialtyName: 'Sports Dietetics Nutrotion',
  },
  {
    id: 439,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'General',
  },
  {
    id: 440,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Addiction & Substance Use Disorder',
  },
  {
    id: 441,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Administrator',
  },
  {
    id: 442,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Critical Care Medicine',
  },
  {
    id: 443,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Case Management',
  },
  {
    id: 444,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'College Health',
  },
  {
    id: 445,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Community Health',
  },
  {
    id: 446,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Continuing Education/Staff Development',
  },
  {
    id: 447,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Continence Care',
  },
  {
    id: 448,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Cardiac Rehabilitation',
  },
  {
    id: 449,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Diabetes Education',
  },
  {
    id: 450,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Peritoneal Dialysis',
  },
  {
    id: 451,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Emergency',
  },
  {
    id: 452,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Enterostomal Therapy',
  },
  {
    id: 453,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Flight',
  },
  {
    id: 454,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'General Practice',
  },
  {
    id: 455,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Gastroenterology',
  },
  {
    id: 456,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Gerontology',
  },
  {
    id: 457,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Home Health',
  },
  {
    id: 458,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Hemodialysis',
  },
  {
    id: 459,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Hospice',
  },
  {
    id: 460,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Infusion Therapy',
  },
  {
    id: 461,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Infection Control',
  },
  {
    id: 462,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Lactation Consultant',
  },
  {
    id: 463,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Maternal Newborn',
  },
  {
    id: 464,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Medical-Surgical',
  },
  {
    id: 465,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'NMT',
  },
  {
    id: 466,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Neonatal Intensive Care',
  },
  {
    id: 467,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Neonatal, Low-Risk',
  },
  {
    id: 468,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Nephrology',
  },
  {
    id: 469,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Neuroscience',
  },
  {
    id: 470,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Nutrition Support',
  },
  {
    id: 471,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Pain Management',
  },
  {
    id: 472,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Pediatrics',
  },
  {
    id: 473,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Pediatric Oncology',
  },
  {
    id: 474,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Psych/Mental Health, Child & Adolescent',
  },
  {
    id: 475,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Psych/Mental Health',
  },
  {
    id: 476,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Psych/Mental Health, Adult',
  },
  {
    id: 477,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Perinatal',
  },
  {
    id: 478,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Ambulatory Care',
  },
  {
    id: 479,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Registered Nurse First Assistant',
  },
  {
    id: 480,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Rehabilitation',
  },
  {
    id: 481,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Reproductive Endocrinology/Fertility',
  },
  {
    id: 482,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Plastic Surgery',
  },
  {
    id: 483,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'School',
  },
  {
    id: 484,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Urology',
  },
  {
    id: 485,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Wound Care',
  },
  {
    id: 486,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Women\'s Health Care, Ambulatory',
  },
  {
    id: 487,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Obstetric, High-Risk',
  },
  {
    id: 488,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Obstetric, Inpatient',
  },
  {
    id: 489,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Occupational Health',
  },
  {
    id: 490,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Oncology',
  },
  {
    id: 491,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Otorhinolaryngology & Head-Neck',
  },
  {
    id: 492,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Orthopedic',
  },
  {
    id: 493,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Ophthalmic',
  },
  {
    id: 494,
    specialyName: 'Registered Nurse (RN)',
    subSpecialtyName: 'Ostomy Care',
  },
  {
    id: 495,
    specialyName: 'Respiratory Therapist',
    subSpecialtyName: 'General',
  },
  {
    id: 496,
    specialyName: 'Respiratory Therapist',
    subSpecialtyName: 'Critical Care',
  },
  {
    id: 497,
    specialyName: 'Respiratory Therapist',
    subSpecialtyName: 'Emergency Care',
  },
  {
    id: 498,
    specialyName: 'Respiratory Therapist',
    subSpecialtyName: 'Educational',
  },
  {
    id: 499,
    specialyName: 'Respiratory Therapist',
    subSpecialtyName: 'Geriatric Care',
  },
  {
    id: 500,
    specialyName: 'Respiratory Therapist',
    subSpecialtyName: 'General Care',
  },
  {
    id: 501,
    specialyName: 'Respiratory Therapist',
    subSpecialtyName: 'Home Health',
  },
  {
    id: 502,
    specialyName: 'Respiratory Therapist',
    subSpecialtyName: 'Pulmonary Diagnostics',
  },
  {
    id: 503,
    specialyName: 'Respiratory Therapist',
    subSpecialtyName: 'Pulmonary Rehabilitation',
  },
  {
    id: 504,
    specialyName: 'Respiratory Therapist',
    subSpecialtyName: 'Pulmonary Function Technology',
  },
  {
    id: 505,
    specialyName: 'Respiratory Therapist',
    subSpecialtyName: 'Palliative & Hospice',
  },
  {
    id: 506,
    specialyName: 'Respiratory Therapist',
    subSpecialtyName: 'Neonatal/Pediatrics',
  },
  {
    id: 507,
    specialyName: 'Respiratory Therapist',
    subSpecialtyName: 'Patient Transport',
  },
  {
    id: 508,
    specialyName: 'Respiratory Therapist',
    subSpecialtyName: 'SNF/Subacute Care',
  },
  {
    id: 509,
    specialyName: 'Rehabilitation Counselor',
    subSpecialtyName: 'General',
  },
  {
    id: 510,
    specialyName: 'Rehabilitation Counselor',
    subSpecialtyName: 'Assistive Technology Practitioner',
  },
  {
    id: 511,
    specialyName: 'Rehabilitation Counselor',
    subSpecialtyName: 'Assistive Technology Supplier',
  },
  {
    id: 512,
    specialyName: 'Rehabilitation Counselor',
    subSpecialtyName: 'Orientation and Mobility Training Provider',
  },
  {
    id: 513,
    specialyName: 'Rheumatologist (Joints & Arthritis Specialist)',
    subSpecialtyName: 'General',
  },
  {
    id: 514,
    specialyName: 'Sleep Medicine Specialist',
    subSpecialtyName: 'General',
  },
  {
    id: 515,
    specialyName: 'Speech-Language Pathologist',
    subSpecialtyName: 'General',
  },
  {
    id: 516,
    specialyName: 'Sports Medicine Specialist',
    subSpecialtyName: 'General',
  },
  {
    id: 518,
    specialyName: 'Surgeon',
    subSpecialtyName: 'General',
  },
  {
    id: 519,
    specialyName: 'Surgeon',
    subSpecialtyName: 'Oral & Maxillofacial Surgery',
  },
  {
    id: 520,
    specialyName: 'Surgeon',
    subSpecialtyName: 'Pediatric Surgery',
  },
  {
    id: 521,
    specialyName: 'Surgeon',
    subSpecialtyName: 'Surgical Oncology',
  },
  {
    id: 522,
    specialyName: 'Surgeon',
    subSpecialtyName: 'Surgical Critical Care',
  },
  {
    id: 523,
    specialyName: 'Surgeon',
    subSpecialtyName: 'Vascular Surgery',
  },
  {
    id: 524,
    specialyName: 'Surgeon',
    subSpecialtyName: 'Trauma Surgery',
  },
  {
    id: 525,
    specialyName: 'Surgeon',
    subSpecialtyName: 'Surgery of the Hand',
  },
  {
    id: 529,
    specialyName: 'Thoracic Surgeon (Cardiothoracic Vascular Surgeon)',
    subSpecialtyName: 'General',
  },
  {
    id: 530,
    specialyName: 'Transplant Hepatologist',
    subSpecialtyName: 'General',
  },
  {
    id: 531,
    specialyName: 'Transplant Surgeon',
    subSpecialtyName: 'General',
  },
  {
    id: 532,
    specialyName: 'Urologist',
    subSpecialtyName: 'General',
  },
  {
    id: 533,
    specialyName: 'Urologist',
    subSpecialtyName: 'Pediatric Urology',
  },
  {
    id: 534,
    specialyName: 'Urologist',
    subSpecialtyName: 'Urology - Female Pelvic Medicine and Reconstructive Surgery',
  },
  {
    id: 535,
    specialyName: 'Physiatrist (Physical Medicine & Rehabilitation)',
    subSpecialtyName: 'General',
  },
  {
    id: 536,
    specialyName: 'Physiatrist (Physical Medicine & Rehabilitation)',
    subSpecialtyName: 'Hospice and Palliative Medicine',
  },
  {
    id: 537,
    specialyName: 'Physiatrist (Physical Medicine & Rehabilitation)',
    subSpecialtyName: 'Neuromuscular Medicine',
  },
  {
    id: 538,
    specialyName: 'Physiatrist (Physical Medicine & Rehabilitation)',
    subSpecialtyName: 'Spinal Cord Injury Medicine',
  },
  {
    id: 539,
    specialyName: 'Physiatrist (Physical Medicine & Rehabilitation)',
    subSpecialtyName: 'Pediatric Rehabilitation Medicine',
  },
  {
    id: 540,
    specialyName: 'Physiatrist (Physical Medicine & Rehabilitation)',
    subSpecialtyName: 'Pain Medicine',
  },
  {
    id: 541,
    specialyName: 'Physiatrist (Physical Medicine & Rehabilitation)',
    subSpecialtyName: 'Sports Medicine',
  },
  {
    id: 542,
    specialyName: 'Physiatrist (Physical Medicine & Rehabilitation)',
    subSpecialtyName: 'Physical Medicine & Rehabilitation - Brain Injury Medicine',
  },
  {
    id: 543,
    specialyName: 'Social Worker',
    subSpecialtyName: 'General',
  },
  {
    id: 544,
    specialyName: 'Social Worker',
    subSpecialtyName: 'Clinical',
  },
  {
    id: 545,
    specialyName: 'Social Worker',
    subSpecialtyName: 'School',
  },
  {
    id: 546,
    specialyName: 'Psychiatrist / Neurologist',
    subSpecialtyName: 'Addiction Medicine',
  },
  {
    id: 547,
    specialyName: 'Psychiatrist / Neurologist',
    subSpecialtyName: 'Bariatric Medicine',
  },
  {
    id: 548,
    specialyName: 'Psychiatrist / Neurologist',
    subSpecialtyName: 'Hospice & Palliative Medicine',
  },
  {
    id: 549,
    specialyName: 'Psychiatrist / Neurologist',
    subSpecialtyName: 'Clinical Neurophysiology',
  },
  {
    id: 550,
    specialyName: 'Psychiatrist / Neurologist',
    subSpecialtyName: 'Sports Medicine',
  },
  {
    id: 551,
    specialyName: 'Psychiatrist / Neurologist',
    subSpecialtyName: 'Sleep Medicine',
  },
  {
    id: 552,
    specialyName: 'Psychiatrist / Neurologist',
    subSpecialtyName: 'Psychiatry & Neurology - Neurocritical Care',
  },
  {
    id: 553,
    specialyName: 'Psychiatrist / Neurologist',
    subSpecialtyName: 'Psychiatry & Neurology - Behavioral Neurology & Neuropsychiatry',
  },
  {
    id: 554,
    specialyName: 'Psychiatrist / Neurologist',
    subSpecialtyName: 'Psychiatry & Neurology - Brain Injury Medicine',
  },
  {
    id: 555,
    specialyName: 'Pharmacist Clinician (PhC)/ Clinical Pharmacist',
    subSpecialtyName: 'Pharmacist Clinician (PhC)/ Clinical Pharmacy Specialist',
  },
  {
    id: 556,
    specialyName: 'Pharmacotherapist',
    subSpecialtyName: 'Pharmacotherapy',
  },
  {
    id: 559,
    specialyName: 'Other Doctors',
    subSpecialtyName: 'Specialist',
  },
  {
    id: 563,
    specialyName: 'Pediatric Ophthalmology',
    subSpecialtyName: 'Pediatric Ophthalmology',
  },
  {
    id: 564,
    specialyName: 'Behavioral Health & Social Service Providers',
    subSpecialtyName: 'Clinical Neuropsychologist - Clinical',
  },
  {
    id: 565,
    specialyName: 'Behavioral Health & Social Service Providers',
    subSpecialtyName: 'Behavioral Analyst',
  },
  {
    id: 566,
    specialyName: 'Behavioral Health & Social Service Providers',
    subSpecialtyName: 'Assistant Behavior Analyst',
  },
  {
    id: 567,
    specialyName: 'Behavioral Health & Social Service Providers',
    subSpecialtyName: 'Behavior Technician',
  },
  {
    id: 568,
    specialyName: 'Dental Providers',
    subSpecialtyName: 'Dental Hygienist',
  },
  {
    id: 569,
    specialyName: 'Dental Providers',
    subSpecialtyName: 'Dental Therapist',
  },
  {
    id: 570,
    specialyName: 'Dental Providers',
    subSpecialtyName: 'Advanced Practice Dental Therapist',
  },
  {
    id: 571,
    specialyName: 'Dental Providers',
    subSpecialtyName: 'Oral Medicinist',
  },
  {
    id: 572,
    specialyName: 'Dental Providers',
    subSpecialtyName: 'Dental Assistant',
  },
  {
    id: 573,
    specialyName: 'Dental Providers',
    subSpecialtyName: 'Dental Laboratory Technician',
  },
  {
    id: 574,
    specialyName: 'Dental Providers',
    subSpecialtyName: 'Pain Orofacial',
  },
  {
    id: 575,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Midwife',
  },
  {
    id: 576,
    specialyName: 'Allopathic & Osteopathic Physicians',
    subSpecialtyName: 'Electrodiagnostic Medicine',
  },
  {
    id: 582,
    specialyName: 'Marijuana Doctor',
    subSpecialtyName: 'General',
  },
  {
    id: 583,
    specialyName: 'Obstetrician / Gynecologist (OBGYN)',
    subSpecialtyName: 'Obstetrics & Gynecology - Complex Family Planning',
  },
  {
    id: 584,
    specialyName: 'Pharmacist',
    subSpecialtyName: 'General',
  },
  {
    id: 585,
    specialyName: 'Pharmacist',
    subSpecialtyName: 'Pharmacist - Critical Care',
  },
  {
    id: 586,
    specialyName: 'Pharmacist',
    subSpecialtyName: 'Pharmacist - General Practice',
  },
  {
    id: 587,
    specialyName: 'Pharmacist',
    subSpecialtyName: 'Pharmacist - Geriatric',
  },
  {
    id: 588,
    specialyName: 'Pharmacist',
    subSpecialtyName: 'Pharmacist - Nuclear',
  },
  {
    id: 589,
    specialyName: 'Pharmacist',
    subSpecialtyName: 'Pharmacist - Nutrition Support',
  },
  {
    id: 590,
    specialyName: 'Pharmacist',
    subSpecialtyName: 'Pharmacist - Pediatrics',
  },
  {
    id: 591,
    specialyName: 'Pharmacist',
    subSpecialtyName: 'Pharmacist - Psychiatric',
  },
  {
    id: 592,
    specialyName: 'Pharmacist',
    subSpecialtyName: 'Pharmacist - Ambulatory Care',
  },
  {
    id: 593,
    specialyName: 'Pharmacist',
    subSpecialtyName: 'Pharmacist - Oncology',
  },
  {
    id: 594,
    specialyName: 'Pharmacist',
    subSpecialtyName: 'Pharmacy Technician',
  },
  {
    id: 595,
    specialyName: 'Student',
    subSpecialtyName: 'Student',
  },
  {
    id: 596,
    specialyName: 'Behavioral Health & Social Service Providers',
    subSpecialtyName: 'Drama Therapist',
  },
  {
    id: 597,
    specialyName: 'Emergency Medical Service Providers',
    subSpecialtyName: 'Personal Emergency Response Attendant',
  },
  {
    id: 598,
    specialyName: 'Emergency Medical Service Providers',
    subSpecialtyName: 'Emergency Medical Technician, Paramedic',
  },
  {
    id: 599,
    specialyName: 'Emergency Medical Service Providers',
    subSpecialtyName: 'Emergency Medical Technician, Intermediate',
  },
  {
    id: 600,
    specialyName: 'Emergency Medical Service Providers',
    subSpecialtyName: 'Emergency Medical Technician, Basic',
  },
  {
    id: 601,
    specialyName: 'Eye and Vision Services Providers',
    subSpecialtyName: 'Technician/Technologist',
  },
  {
    id: 602,
    specialyName: 'Eye and Vision Services Providers',
    subSpecialtyName: 'Technician/Technologist - Contact Lens',
  },
  {
    id: 603,
    specialyName: 'Eye and Vision Services Providers',
    subSpecialtyName: 'Technician/Technologist - Contact Lens Fitter',
  },
  {
    id: 604,
    specialyName: 'Eye and Vision Services Providers',
    subSpecialtyName: 'Technician/Technologist - Ophthalmic',
  },
  {
    id: 605,
    specialyName: 'Eye and Vision Services Providers',
    subSpecialtyName: 'Technician/Technologist - Ophthalmic Assistant',
  },
  {
    id: 606,
    specialyName: 'Eye and Vision Services Providers',
    subSpecialtyName: 'Technician/Technologist - Optometric Assistant',
  },
  {
    id: 607,
    specialyName: 'Eye and Vision Services Providers',
    subSpecialtyName: 'Technician/Technologist - Optometric Technician',
  },
  {
    id: 608,
    specialyName: 'Eye and Vision Services Providers',
    subSpecialtyName: 'Technician/Technologist - Ocularist',
  },
  {
    id: 609,
    specialyName: 'Eye and Vision Services Providers',
    subSpecialtyName: 'Technician/Technologist - Optician',
  },
  {
    id: 610,
    specialyName: 'Eye and Vision Services Providers',
    subSpecialtyName: 'Technician/Technologist - Orthoptist',
  },
  {
    id: 611,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Military Health Care Provider',
  },
  {
    id: 612,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Military Health Care Provider - Independent Duty Corpsman',
  },
  {
    id: 613,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Military Health Care Provider - Independent Duty Medical Technicians',
  },
  {
    id: 614,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Case Manager/Care Coordinator',
  },
  {
    id: 615,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Interpreter',
  },
  {
    id: 616,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Contractor',
  },
  {
    id: 617,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Contractor - Home Modifications',
  },
  {
    id: 618,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Contractor - Vehicle Modifications',
  },
  {
    id: 619,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Driver',
  },
  {
    id: 620,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Community Health Worker',
  },
  {
    id: 621,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Legal Medicine',
  },
  {
    id: 622,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Sleep Specialist, PhD',
  },
  {
    id: 623,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Meals',
  },
  {
    id: 624,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Specialist - Graphics Designer',
  },
  {
    id: 625,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Specialist - Prosthetics Case Management',
  },
  {
    id: 626,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Specialist - Research Study',
  },
  {
    id: 627,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Specialist - Research Data Abstracter/Coder',
  },
  {
    id: 628,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Health Educator',
  },
  {
    id: 629,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Veterinarian',
  },
  {
    id: 630,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Veterinarian - Medical Research',
  },
  {
    id: 631,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Lactation Consultant, Non-RN',
  },
  {
    id: 632,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Clinical Ethicist',
  },
  {
    id: 633,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Midwife, Lay',
  },
  {
    id: 634,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Peer Specialist',
  },
  {
    id: 635,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Funeral Director',
  },
  {
    id: 636,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Lodging',
  },
  {
    id: 637,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Prevention Professional',
  },
  {
    id: 638,
    specialyName: 'Other Service Providers',
    subSpecialtyName: 'Health & Wellness Coach',
  },
  {
    id: 639,
    specialyName: 'Group',
    subSpecialtyName: 'Multi-Specialty',
  },
  {
    id: 640,
    specialyName: 'Group',
    subSpecialtyName: 'Single Specialty',
  },
  {
    id: 641,
    specialyName: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers',
    subSpecialtyName: 'Mastectomy Fitter',
  },
  {
    id: 642,
    specialyName: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers',
    subSpecialtyName: 'Pedorthist',
  },
  {
    id: 643,
    specialyName: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers',
    subSpecialtyName: 'Clinical Exercise Physiologist',
  },
  {
    id: 644,
    specialyName: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers',
    subSpecialtyName: 'Rehabilitation Practitioner',
  },
  {
    id: 645,
    specialyName: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers',
    subSpecialtyName: 'Specialist/Technologist',
  },
  {
    id: 646,
    specialyName: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers',
    subSpecialtyName: 'Specialist/Technologist - Athletic Trainer',
  },
  {
    id: 647,
    specialyName: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers',
    subSpecialtyName: 'Specialist/Technologist - Rehabilitation, Blind',
  },
  {
    id: 648,
    specialyName: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers',
    subSpecialtyName: 'Pulmonary Function Technologist',
  },
  {
    id: 649,
    specialyName: 'Respiratory, Developmental, Rehabilitative and Restorative Service Providers',
    subSpecialtyName: 'Recreational Therapist Assistant',
  },
  {
    id: 650,
    specialyName: 'Speech, Language and Hearing Service Providers',
    subSpecialtyName: 'Specialist/Technologist',
  },
  {
    id: 651,
    specialyName: 'Speech, Language and Hearing Service Providers',
    subSpecialtyName: 'Specialist/Technologist - Audiology Assistant',
  },
  {
    id: 652,
    specialyName: 'Speech, Language and Hearing Service Providers',
    subSpecialtyName: 'Specialist/Technologist - Speech-Language Assistant',
  },
  {
    id: 653,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Perfusionist',
  },
  {
    id: 654,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiology Practitioner Assistant',
  },
  {
    id: 655,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Pathology',
  },
  {
    id: 656,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Pathology - Blood Banking',
  },
  {
    id: 657,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Pathology - Chemistry',
  },
  {
    id: 658,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Pathology - Cytotechnology',
  },
  {
    id: 659,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Pathology - Hematology',
  },
  {
    id: 660,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Pathology - Hemapheresis Practitioner',
  },
  {
    id: 661,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Pathology - Histology',
  },
  {
    id: 662,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Pathology - Immunology',
  },
  {
    id: 663,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Pathology - Laboratory Management',
  },
  {
    id: 664,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Pathology - Laboratory Management, Diplomate',
  },
  {
    id: 665,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Pathology - Medical Technologist',
  },
  {
    id: 666,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Pathology - Microbiology',
  },
  {
    id: 667,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Technician, Pathology',
  },
  {
    id: 668,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Technician, Pathology - Histology',
  },
  {
    id: 669,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Technician, Pathology - Medical Laboratory',
  },
  {
    id: 670,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Technician, Pathology - Phlebotomy',
  },
  {
    id: 671,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Technician, Cardiology',
  },
  {
    id: 672,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Cardiovascular',
  },
  {
    id: 673,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Cardiovascular - Cardiovascular Invasive Specialist',
  },
  {
    id: 674,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Cardiovascular - Vascular Specialist',
  },
  {
    id: 675,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Cardiovascular - Sonography',
  },
  {
    id: 676,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Health Info',
  },
  {
    id: 677,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Health Info - Coding Specialist, Hospital Based',
  },
  {
    id: 678,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Health Info - Coding Specialist, Physician Office Based',
  },
  {
    id: 679,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Spec/Tech, Health Info - Registered Record Administrator',
  },
  {
    id: 680,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other',
  },
  {
    id: 681,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other - Art, Medical',
  },
  {
    id: 682,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other - Biomedical Engineering',
  },
  {
    id: 683,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other - Biomedical Photographer',
  },
  {
    id: 684,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other - Biochemist',
  },
  {
    id: 685,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other - Biostatistician',
  },
  {
    id: 686,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other - Surgical Assistant',
  },
  {
    id: 687,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other - EEG',
  },
  {
    id: 688,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other - Electroneurodiagnostic',
  },
  {
    id: 689,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other - Graphics Methods',
  },
  {
    id: 690,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other - Geneticist, Medical (PhD)',
  },
  {
    id: 691,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other - Illustration, Medical',
  },
  {
    id: 692,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other - Nephrology',
  },
  {
    id: 693,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other - Surgical Technologist',
  },
  {
    id: 694,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Specialist/Technologist, Other - Orthopedic Assistant',
  },
  {
    id: 695,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Technician, Health Information',
  },
  {
    id: 696,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Technician, Health Information - Assistant Record Technician',
  },
  {
    id: 697,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiologic Technologist',
  },
  {
    id: 698,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiologic Technologist - Bone Densitometry',
  },
  {
    id: 699,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiologic Technologist - Cardiovascular-Interventional Technology',
  },
  {
    id: 700,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiologic Technologist - Cardiac-Interventional Technology',
  },
  {
    id: 701,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiologic Technologist - Computed Tomography',
  },
  {
    id: 702,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiologic Technologist - Radiography',
  },
  {
    id: 703,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiologic Technologist - Magnetic Resonance Imaging',
  },
  {
    id: 704,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiologic Technologist - Mammography',
  },
  {
    id: 705,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiologic Technologist - Nuclear Medicine Technology',
  },
  {
    id: 706,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiologic Technologist - Quality Management',
  },
  {
    id: 707,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiologic Technologist - Radiation Therapy',
  },
  {
    id: 708,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiologic Technologist - Sonography',
  },
  {
    id: 709,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiologic Technologist - Vascular Sonography',
  },
  {
    id: 710,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Radiologic Technologist - Vascular-Interventional Technology',
  },
  {
    id: 711,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Technician, Other',
  },
  {
    id: 712,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Technician, Other - Biomedical Engineering',
  },
  {
    id: 713,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Technician, Other - Darkroom',
  },
  {
    id: 714,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Technician, Other - EEG',
  },
  {
    id: 715,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Technician, Other - Renal Dialysis',
  },
  {
    id: 716,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Technician, Other - Veterinary',
  },
  {
    id: 717,
    specialyName: 'Technologists, Technicians & Other Technical Service Providers',
    subSpecialtyName: 'Pathology - Clinical Laboratory Director, Non-physician',
  },
  {
    id: 718,
    specialyName: 'Physician Assistants & Advanced Practice Nursing Providers',
    subSpecialtyName: 'Anesthesiologist Assistant',
  },
  {
    id: 719,
    specialyName: 'Physician Assistants & Advanced Practice Nursing Providers',
    subSpecialtyName: 'Transportation Services',
  },
  {
    id: 720,
    specialyName: 'Nursing Service Related Providers',
    subSpecialtyName: 'Chore Provider',
  },
  {
    id: 721,
    specialyName: 'Nursing Service Related Providers',
    subSpecialtyName: 'Adult Companion',
  },
  {
    id: 722,
    specialyName: 'Nursing Service Related Providers',
    subSpecialtyName: 'Day Training/Habilitation Specialist',
  },
  {
    id: 723,
    specialyName: 'Nursing Service Related Providers',
    subSpecialtyName: 'Technician',
  },
  {
    id: 724,
    specialyName: 'Nursing Service Related Providers',
    subSpecialtyName: 'Technician - Attendant Care Provider',
  },
  {
    id: 725,
    specialyName: 'Nursing Service Related Providers',
    subSpecialtyName: 'Technician - Personal Care Attendant',
  },
  {
    id: 726,
    specialyName: 'Nursing Service Related Providers',
    subSpecialtyName: 'Doula',
  },
  {
    id: 727,
    specialyName: 'Nursing Service Related Providers',
    subSpecialtyName: 'Religious Nonmedical Practitioner',
  },
  {
    id: 728,
    specialyName: 'Nursing Service Related Providers',
    subSpecialtyName: 'Religious Nonmedical Nursing Personnel',
  },
  {
    id: 729,
    specialyName: 'Nursing Service Related Providers',
    subSpecialtyName: 'Home Health Aide',
  },
  {
    id: 730,
    specialyName: 'Nursing Service Related Providers',
    subSpecialtyName: 'Nursing Home Administrator',
  },
  {
    id: 731,
    specialyName: 'Nursing Service Related Providers',
    subSpecialtyName: 'Homemaker',
  },
  {
    id: 732,
    specialyName: 'Nursing Service Related Providers',
    subSpecialtyName: 'Nurse\'s Aide',
  },
];

export const titles = [
  {
    value: 'dr',
    description: 'Dr.',
  },
  {
    value: 'mr',
    description: 'Mr.',
  },
  {
    value: 'mrs',
    description: 'Mrs.',
  },
  {
    value: 'ms',
    description: 'Ms.',
  },
];

export const credentials = [
  'DDS', 'DMD', 'DO', 'DPM', 'FACC', 'FACD', 'FACS', 'FNP', 'MD', 'MMS', 'NP', 'OD', 'PA', 'PT',
];
