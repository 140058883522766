import { useDispatch, useSelector } from 'react-redux';
import {
  clearProductUpdateCompleted,
  getProduct,
  putProduct,
  selectProduct,
  selectProductUpdateCompleted,
  setProductUpdateCompleted,
} from 'services/productsSlice';
import { useEffect } from 'react';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useParams } from 'react-router-dom';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import { Urls } from 'navigation/Urls';

/**
 * @type TPut - type of the PUT product content property
 * @type TProd - type of product in state
 */

export default function useProduct<TPut = any, TProd = any>(currentStep: Urls, beforeSubmit?: () => any) {
  const { orderItemId } = useParams();
  const topnav = useTopnavMenu(currentStep);
  const product = useSelector(selectProduct) as unknown as TProd;
  const completed = useSelector(selectProductUpdateCompleted(currentStep));
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();

  const clearCompleted = () => {
    dispatch(clearProductUpdateCompleted({ step: currentStep }));
  };

  const onBack = () => {
    navigate.to(topnav.previousStep());
  };

  const onSubmit = (content: TPut) => {
    if (!orderItemId) {
      // eslint-disable-next-line no-alert
      alert('Error: no orderItem available in this order.');
      return;
    }
    if (beforeSubmit) beforeSubmit();
    if (content) {
      dispatch(putProduct<TPut>({
        orderItemId, content, completeStep: currentStep,
      }));
    } else {
      dispatch(setProductUpdateCompleted({ step: currentStep }));
    }
  };

  useEffect(() => {
    if (completed) {
      clearCompleted();
      /*
        This part was commented out specifically for YouTube, where we have steppers.
        On clicking edit, we want the user to follow all the steppers instead of redirecting
        to the overview page after clicking save.
      */
      /* if (history.state?.previousPath.includes('overview')) {
        navigate.to(Urls.Overview);
      } else */navigate.to(topnav.nextStep());
    }
  }, [completed]);

  useEffect(() => {
    if (orderItemId) dispatch(getProduct<never>({ orderItemId }));
  }, [orderItemId]);

  return { product, onBack, onSubmit };
}
