import React, { useEffect } from 'react';
import {
  IQButtonRadioContainer,
  IQFormInput,
  IQInputEmail,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { Grid, styled } from '@mui/material';
import schema from 'pages/ecommerce/domain/DomainPage.schema';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSaveAndClose } from 'context/SaveAndCloseContext';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import { H5 } from 'components/labels/H5';
import EcommerceType from 'models/EcommerceType';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { generateTooltipTextElement } from 'shared/generateTooltipText';

interface Props {
  product: EcommerceType;
  onSubmit: (data) => any;
  onBack: () => any;
}

interface FormValues {
  domainOption: string;
  buyDomain: string;
  existingDomainUrl: string;
  domainRegistrar: string;
  registrarUsername: string;
  registrarPassword: string;
  domainEmail: string;
}

const optionsList: Pick<React.ComponentProps<typeof IQButtonRadioContainer>, 'optionsList'> = {
  optionsList: [
    { field: 'options', label: 'Purchase a Domain', value: 'Purchase' },
    { field: 'options', label: 'Existing Domain', value: 'Existing' },
  ],
};

const GridStyled = styled(Grid)`
  .MuiToggleButton-root.Mui-selected {
    background-color: transparent;
  }
  .MuiToggleButton-root.Mui-selected:hover {
    background-color: transparent;
  }
`;

export default function DomainPageForm(props: Props) {
  const { product } = props;
  const { t } = useTranslation();

  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      domainOption: product?.domainOption || null,
      buyDomain: product?.buyDomain || '',
      existingDomainUrl: product?.existingDomainUrl || '',
      domainEmail: product?.domainEmail || '',
      domainRegistrar: product?.domainRegistrar || '',
      registrarUsername: product?.registrarUsername || '',
      registrarPassword: product?.registrarPassword || '',
    },
  });
  const {
    watch, handleSubmit, formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    }, unregister,
  } = methods;

  const saveAndClose = useSaveAndClose();
  saveAndClose.setFormValid(isValid);

  const formValues = {
    domainOption: watch('domainOption'),
  };

  const verifyFields = (content: FormValues) => {
    if (content.domainOption !== 'Purchase' && content.domainOption !== 'Existing') {
      return {
        domainOption: '',
        buyDomain: null,
        existingDomainUrl: null,
        domainRegistrar: null,
        registrarUsername: null,
        registrarPassword: null,
        domainEmail: null,
      };
    }
    if (content.domainOption === 'Purchase') {
      return {
        ...content,
        existingDomainUrl: null,
        domainRegistrar: null,
        registrarUsername: null,
        registrarPassword: null,
        domainEmail: null,
      };
    }
    if (content.domainOption === 'Existing') {
      return {
        ...content,
        buyDomain: null,
      };
    }
    return content;
  };

  const onSubmit = (content) => {
    props.onSubmit(verifyFields(content));
  };

  const skipAndContinue = formValues.domainOption === null || formValues.domainOption === '';

  const renderContent = () => {
    switch (formValues.domainOption) {
      case 'Purchase':
        return (
          <GridItem>
            <IQFormInput
              id="buyDomain"
              theme={IQTheme}
              labelText={t('components.domains.domainUrl')}
              fontLabelWeight="600"
              name="buyDomain"
              fullWidth
              customValidation={schema.yupValidations.buyDomain}
              adornmentIcon={<InsertLinkIcon />}
              // eslint-disable-next-line max-len
              tooltipText={generateTooltipTextElement(t('components.domains.goDaddy'), { render: false }) as unknown as string}
              tooltipPlacement="top"
              tooltipPaddingBottom={18}
            />
          </GridItem>
        );
      case 'Existing':
        return (
          <>
            <GridItem>
              <H5>{t('components.domains.provideDomainDetails')}</H5>
            </GridItem>
            <GridItem>
              <IQFormInput
                id="existingDomainUrl"
                theme={IQTheme}
                labelText={t('components.domains.existingDomainUrl')}
                fontLabelWeight="600"
                name="existingDomainUrl"
                fullWidth
                customValidation={schema.yupValidations.existingDomainUrl}
                adornmentIcon={<InsertLinkIcon />}
              />
            </GridItem>
            <GridItem>
              <IQFormInput
                labelText={t('components.domains.domainRegistrar')}
                fontLabelWeight="600"
                id="domainRegistrar"
                name="domainRegistrar"
                theme={IQTheme}
                schema={schema}
                fullWidth
              />
            </GridItem>
            <GridItem>
              <IQFormInput
                labelText={t('components.domains.domainUsername')}
                fontLabelWeight="600"
                id="registrarUsername"
                name="registrarUsername"
                theme={IQTheme}
                schema={schema}
                fullWidth
              />
            </GridItem>
            <GridItem>
              <IQFormInput
                labelText={t('components.domains.domainPassword')}
                fontLabelWeight="600"
                id="registrarPassword"
                name="registrarPassword"
                obscureValue
                theme={IQTheme}
                schema={schema}
                fullWidth
              />
            </GridItem>
            <GridItem>
              <IQInputEmail
                labelText={`${t('components.domains.domainEmail')}`}
                fontLabelWeight="600"
                theme={IQTheme}
                schema={schema}
                label={t('components.domains.domainEmail')}
                field="domainEmail"
                fullWidth
                adornmentIcon={<MailOutlineIcon />}
              />
            </GridItem>
          </>
        );
      case '':
        return null;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (formValues.domainOption === 'Purchase') {
      unregister(['existingDomainUrl', 'domainRegistrar', 'registrarPassword', 'registrarUsername', 'domainEmail']);
    }

    if (formValues.domainOption === 'Existing') {
      unregister('buyDomain');
    }
  }, [formValues.domainOption]);

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onContinue={handleSubmit(onSubmit)}
      onBack={props.onBack}
      header={t('pages.domainPage.domainPage')}
      skipAndContinue={skipAndContinue}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container>
            <GridStyled
              item
              sx={{
                '& > div > button': { minWidth: 'max-content', maxWidth: 'fit-content' },
                '& > label': { mb: '16px' },
                '& > .MuiToggleButtonGroup-root > *': { backgroundColor: 'transparent' },
                '& > .MuiToggleButtonGroup-root > *:hover': { backgroundColor: 'transparent' },
                '.MuiTouchRipple-root > *': { display: 'none' },
                '[role=group] > button:first-of-type': { pl: '6px' },
                marginBottom: 5,
              }}
              xs={12}
            >
              <IQButtonRadioContainer
                optionsList={optionsList.optionsList}
                name="domainOption"
                label="Select a domain option"
                fontTitleWeight="600"
                inline
                allowRadioDeselect
                required={false}
              />
            </GridStyled>
            <GridContainer>
              {renderContent()}
            </GridContainer>
          </Grid>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
