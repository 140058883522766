import * as yup from 'yup';
import i18n from 'i18n/i18n';

const { t } = i18n;

const schema = {
  locationName: yup.string().required(t('pages.business.location.address.locationNameRequired')).nullable(),
  businessAddressOne: yup.string().required(t('pages.business.location.address.addressLine1Required')).nullable(),
  businessAddressTwo: yup.string().notRequired().nullable(),
  city: yup.string().required(t('pages.business.location.address.cityRequired')).nullable(),
  stateProvince: yup.string().required(t('pages.business.location.address.stateRequired')).nullable(),
  postalCode: yup.string().required(t('pages.business.location.address.zipCodeRequired')).nullable(),
  country: yup.string().required(t('pages.business.location.address.countryRequired')).nullable(),
  serviceAreas: yup.array().notRequired()
    .max(20, t('pages.business.location.address.serviceAreaMax')),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
