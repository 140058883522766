import useTheme from '@mui/material/styles/useTheme';
import { IQFormInput, IQYesNoFormRadioButtons, IQFormSelect } from '@gannettdigital/shared-react-components';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import NeSelectChipCheckboxes from 'components/chips/NeSelectChipCheckboxes';
import ErrorText from 'components/errorText/ErrorText';
import { Attribute, AttributeType } from './AttributeSelector';

export const BuildInput = ({ attribute, index } : { attribute: Attribute, index: number }) => {
  const theme = useTheme();
  const { formState: { errors, touchedFields }, register } = useFormContext();
  const [creditCards, setCreditCards] = useState([]);
  const inputId = `attributes.${index}.value`;
  const label = `${attribute.displayName} *`;

  useEffect(() => {
    const setCreditCardsItems = async () => {
      if (attribute.valueList !== undefined
        && attribute.valueType === AttributeType.REPEATED_ENUM
        && attribute.displayName === 'Credit cards') {
        await setCreditCards(attribute.valueList.map(e => ({ label: e.description, value: e.value })));
      }
    };
    setCreditCardsItems();
  }, [attribute]);

  const error = errors?.attributes?.[index]?.value;
  const isTouched = touchedFields?.attributes?.[index]?.value;

  switch (attribute.valueType) {
    case AttributeType.URL:
      return (
        <>
          <IQFormInput
            id={inputId}
            name={inputId}
            {...register(inputId)}
            labelText={label}
            fontLabelWeight="bold"
            fullWidth
            adornmentIcon={<InsertLinkIcon />}
            directErrorReference={
              (error && isTouched) && ((errors as any).attributes as any[])?.at(index)?.value as any
            }
            showError={false}
            theme={theme}
          />
          <ErrorText
            hasError={error && isTouched}
            errorText={
              (error?.type === 'required' || error?.type === 'typeError')
                ? `${attribute.displayName} is a required field`
                : error?.message
            }
          />
        </>
      );
    case AttributeType.BOOL:
      return (
        <IQYesNoFormRadioButtons
          field={inputId}
          title={label}
          fontTitleWeight="bold"
          defaultValue="yes"
        />
      );
    case AttributeType.ENUM:
      return (
        <IQFormSelect
          fullWidth
          name={inputId}
          labelText={label}
          items={attribute.valueList}
          fontLabelWeight="bold"
        />
      );
    case AttributeType.REPEATED_ENUM:
      return (
        <NeSelectChipCheckboxes name={inputId}
          values={attribute.value}
          label
          labelText={attribute.displayName}
          placeholder=""
          withCheckboxOptions
          maxItems={0} items={creditCards}
          required
          labelTooltipPaddingBottom={18}
          supportCustomChips={false}
        />
      );
    default:
      return (
        <IQFormInput
          fontLabelWeight="bold"
          name={inputId}
          id={inputId}
          theme={theme}
          fullWidth
          labelText={label}
        />
      );
  }
};
