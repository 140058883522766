import * as yup from 'yup';

const schema = {
  pageName: yup.string().required('Please enter a landing page name').nullable(),
  targetMessaging: yup.string().optional().nullable(),
  featuredOffers: yup.string().optional().nullable(),
  otherNotes: yup.string().optional().nullable(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
