import AxiosAPI from "services/common/axios";

export const getAllData = async (currentPage: number, pageSize: number) => {
  try {
    const response = await AxiosAPI.get('gsc-index/get-all', {
      params: {
        page: currentPage,
        size: pageSize * 2,
        sort: "id,desc",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getRecordsByAccount = async (currentPage: number, pageSize: number, displayAccount: string) => {
  try {
    const response = await AxiosAPI.get('gsc-index/get-records-by-account', {
      params: {
        page: currentPage,
        size: pageSize * 2,
        sort: "id,desc",
        accountId: displayAccount
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getAllAccounts = async () => {
  try {
    const response = await AxiosAPI.get('/gsc-index/get-all-accounts');
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const saveData = async (data: any[]) => {
  try {
    await AxiosAPI.post('gsc-index/save', data);
  } catch (error) {
    console.error("Error saving data:", error);
  }
};

export const handleRowSubmit = async (id: String) => {
  try {
    await AxiosAPI.get(`gsc-index/send-for-indexing/${id}`);
  } catch (error) {
    console.error('Error:', error);
  }
};

export const filterData = async (field: string, value: any) => {
  try {
    const response = await AxiosAPI.get(`gsc-index/filter`, {
      params: {
        field: field,
        value: value || ''
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
