import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SetFieldValue } from 'react-hook-form';
import * as yup from 'yup';
import { IQInputEmail } from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from 'react-i18next';
import { Button, Grid, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteHipaaFormEmail } from 'services/addons/addonsSlice';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import HipaaFormEmailsType from 'models/HipaaFormEmailsType';
import schema from './HipaaForms.schema';

interface Props {
  setValue: SetFieldValue<any>
  emailData: HipaaFormEmailsType
  setEmailData: (data) => any
  // eslint-disable-next-line no-empty-pattern
  register: any
  unregister: (name) => any
  trigger: any
}

const ButtonStyled = styled(Button)`
  top: 50%;
`;

const EmailUserStyled = styled('div')`
  .MuiFormControl-root {
    gap: 0;
    label {
      margin-bottom: 8px;
    }
  }
`;

const AddEmailButton = styled(Button)`
  &.Mui-disabled {
    color: #FFFFFF
  }
  font-weight: 600;
  text-transform: none;
  font-size: 14px;
  line-height: 18px;
  padding: 0 40px 0 0;
  margin-left: 24px;
  min-width: 0;
`;

export default function AdminEmailForm(props: Props) {
  const {
    setValue, emailData, setEmailData, register, trigger, unregister,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const emptyObj = {
    id: '',
    email: '',
  };

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [funRemoveEmail, setFunRemoveEmail] = useState({ fn: () => {} });

  const customValidation = yup.string().email('Please enter a valid email in this format: name@example.com');

  const updateEmailData = (attr, key, value) => {
    setEmailData(emailData.map((obj, i) => {
      if (i === key) {
        return { ...obj, [`${attr}`]: value };
      }
      return obj;
    }));
  };

  const firstRow = (data) => {
    setValue('email-0', data.email);
    return (
      <Grid container key={0} xs={12}
        sx={{ marginTop: '24px' }}
      >
        <Grid item xs>
          <EmailUserStyled>
            <IQInputEmail
              {...register('email-0', { required: 'Please enter a valid email in this format: name@example.com' })}
              labelText={`${t('pages.addons.emailHosting.emailTitle')} *`}
              fontLabelWeight="600"
              theme={theme}
              schema={schema}
              required
              customValidation={customValidation}
              label={t('pages.addons.emailHosting.emailTitle')}
              field="email-0"
              fullWidth
              adornmentIcon={<MailOutlineIcon />}
              onChange={(e) => updateEmailData('email', 0, e.target.value)}
            />
          </EmailUserStyled>
        </Grid>
      </Grid>
    );
  };

  const addNewRow = () => {
    setEmailData(prevState => [...prevState, emptyObj]);
  };

  const deleteEmail = (data, key) => {
    if (emailData[0].email) {
      trigger('email-0');
    }
    if (data.id === '') {
      setEmailData(emailData => {
        const updatedRows = emailData.filter((el, index) => index !== key);
        return updatedRows;
      });
    } else {
      setEmailData(emailData => {
        const updatedRows = emailData.filter((el) => Number(el.id) !== data.id);
        return updatedRows;
      });
      dispatch(deleteHipaaFormEmail({ emailId: data.id }));
    }
    setValue(`email-${key}`, '');
    unregister(`email-${key}`);
    setOpenDeleteConfirm(false);
  };

  const showDialogDeleteEmail = (funDelete, data, key) => {
    setFunRemoveEmail({ fn: () => funDelete(data, key) });
    setOpenDeleteConfirm(true);
  };

  const newRow = (data, key) => {
    setValue(`email-${key}`, data.email);
    return (
      <Grid container key={key} xs={12}
        sx={{ marginTop: '24px', position: 'relative' }}
      >
        <Grid item xs={11.2}>
          <EmailUserStyled>
            <IQInputEmail
              {...register(`email-${key}`, { required: 'Please enter a valid email in this format: name@example.com' })}
              labelText={`${t('pages.addons.emailHosting.emailTitle')} *`}
              fontLabelWeight="600"
              theme={theme}
              schema={schema}
              required
              customValidation={customValidation}
              label={t('pages.addons.emailHosting.emailTitle')}
              field={`email-${key}`}
              fullWidth
              adornmentIcon={<MailOutlineIcon />}
              onChange={(e) => updateEmailData('email', key, e.target.value)}
            />
          </EmailUserStyled>
        </Grid>
        <Grid item xs={0.8} sx={{ position: 'absolute', top: '42px', right: '10px' }}>
          <ButtonStyled onClick={() => showDialogDeleteEmail(deleteEmail, data, key)}>
            <DeleteIcon />
          </ButtonStyled>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {emailData.map((data, index) => ((index === 0) ? firstRow(data) : newRow(data, index)))}
      <Grid container xs={12}>
        <Grid item xs={12} sx={{ marginTop: '24px', marginBottom: '24px' }}>
          <AddEmailButton
            onClick={() => addNewRow()}
          >
            +&nbsp;&nbsp;
            <u>Add Email</u>
          </AddEmailButton>
        </Grid>
      </Grid>
      <CoModalConfirm open={openDeleteConfirm}
        title="Remove Email Account?"
        description="Are you sure you want to remove the email account ?"
        confirmText="Remove"
        handleClose={() => setOpenDeleteConfirm(false)}
        handleConfirm={funRemoveEmail.fn}
      />
    </>
  );
}
