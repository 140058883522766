/* eslint-disable no-param-reassign */
import {
  AsyncThunk,
  createAction,
  createSlice,
  Dispatch,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import _ from 'lodash';
import { StatusType } from 'models/StatusType';
import { FileUploaderType } from 'models/FileUploaderType';
import AxiosAPI from './common/axios';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { setExistingAssetsCompleted, WebsiteOfferingTypes } from './websitesSlice';

export interface FilesState {
  files: StatusType<FileUploaderType[]>
  filesGetty: StatusType<ImgItem[]> & { filesToRemove: any[] }
  getty: GettyImages
  totalFiles: StatusType<FileUploaderType[]>
}

export interface GettyImages {
  totalElements: number,
  content: ImgItem[],
  pageNumber: number,
  pageSize: number,
  totalPages: number,
  loading: boolean,
}

export interface ImgItem {
  mediaId?: any;
  id: string;
  title: string;
  uri: string;
  url?: string
}

export enum ProductTypes {
  HERO_VIDEO = 'hero-video',
  HIPAA_FORM = 'hipaa-form',
  POP_UP = 'popups',
  EXPANDABLE_MENU = 'expandable-menu',
  EXISTING_ASSETS = 'existing-assets',
  RETURNS_REFUNDS = 'return_policy_documents',
  PRODUCT_CATALOG = 'product-catalog',
  EXISTING_CONTENT = 'existing-content',
  ADDITIONAL_FEATURES = 'additional-features',
  ADDITIONAL_INFORMATION = 'additional-information',
  SOW_DOCUMENT = 'sow_document',
  PIQ_DOCUMENT = 'piq-document',
  PIQ_ASSET = 'piq-asset',
  EXISTING_CONTACT_LIST = 'existing-contact-list',
  TARGETED_DISPLAY_GEO_FENCE_ADDRESS_LIST = 'targeted-display-geo-fence-address-list',
  TARGETED_DISPLAY_GEO_FENCE_ADDRESS_LIST_1 = 'targeted-display-geo-fence-address-list-1',
  TARGETED_DISPLAY_EVENT_AUDIENCE_RETARGETING_LIST = 'targeted-display-event-Audience-Retargeting-List',
  TARGETED_DISPLAY_ZTV_LIST = 'targeted-display-ztv-list',
  TARGETED_DISPLAY_SEARCH_RETARGETING_URLS = 'targeted-display-search-retargeting-urls',
  TARGETED_DISPLAY_KEY_LIST = 'targeted-display-key-list',
  CUSTOM_REPORTING_UPLOADER = 'custom_reporting_uploader',
  CUSTOM_SOLUTIONS_SPOTIFY_UPLOADER_1 = 'custom_solutions_spotify_uploader_1',
  CUSTOM_SOLUTIONS_SPOTIFY_UPLOADER_2 = 'custom_solutions_spotify_uploader_2',
  CUSTOM_SOLUTIONS_SNAPCHAT_UPLOADER_1 = 'custom_solutions_snapchat_uploader_1',
  CUSTOM_SOLUTIONS_SNAPCHAT_UPLOADER_2 = 'custom_solutions_snapchat_uploader_2',
  CUSTOM_SOLUTIONS_PANDORA_UPLOADER_1 = 'custom_solutions_pandora_uploader_1',
  CUSTOM_SOLUTIONS_PANDORA_UPLOADER_2 = 'custom_solutions_pandora_uploader_2',
  CUSTOM_SOLUTIONS_LINKEDIN_UPLOADER_1 = 'custom_solutions_linkedin_uploader_1',
  CUSTOM_SOLUTIONS_LINKEDIN_UPLOADER_2 = 'custom_solutions_linkedin_uploader_2',
  CUSTOM_SOLUTIONS_TIKTOK_UPLOADER_1 = 'custom_solutions_tiktok_uploader_1',
  CUSTOM_SOLUTIONS_TIKTOK_UPLOADER_2 = 'custom_solutions_tiktok_uploader_2',
  CUSTOM_SOLUTIONS_TWITTER_UPLOADER_1 = 'custom_solutions_twitter_uploader_1',
  CUSTOM_SOLUTIONS_TWITTER_UPLOADER_2 = 'custom_solutions_twitter_uploader_2',
  SMB_RETAIL_GCI_ONLY_SMART_LISTINGS_UPLOADER = 'smb_retail_gci_only_smart_listings_uploader',
  SMB_RETAIL_GCI_ONLY_VISIBLE_PRESENCE_UPLOADER = 'smb_retail_gci_only_visible_presence_uploader',
  SMB_RETAIL_GCI_ONLY_RESPONSIVE_SITE_UPLOADER = 'smb_retail_gci_only_responsive_site_uploader',
  TARGETED_EMAIL_UPLOADER = 'targeted_email_uploader',
  CLIENT_UPLOADER_FILE = 'clients-uploder-file',
  CLIENT_IMPORT_ATTACH_FILE = 'clients-import-attach-file',
  CLIENT_YEXT_ATTACH_FILE = 'clients-yext-attach-file',
  ORDER_BRIEF_ATTACH_FILE = 'order-brief-attach-file',
  ORDER_MOCKUP_ATTACH_FILE = 'order-mockup-attach-file',
  PROMOTION_PAGE_AND_CREATIVES_UPLOADER = 'promotions_page_and_creatives_uploader',
  VIDEO_CREATIVE_SERVICE_UPLOADER_FILE = 'video-creative-service-uploader-file',
  CLIENT_PROVIDED_KEYWORDS = 'client-provided-keywords',
  VIDEO_CREATIVE_SERVICE_UPLOADER = 'video-creative-service-uploader',
}

export const FileUploadProductType = {
  Website: 'Website',
  GoldSite: 'Gold Site',
  EcommerceMicrosite: 'E-Commerce Microsite',
  LandingPage: 'Custom Landing Page Quote',
  PremiumListing: 'Premium Listings',
  businessLocation: 'Business::Location',
  MasterAddon: 'MasterAddon',
  CustomWebsite: 'Website',
  SEOCustom: 'SEOCustom',
  SEOStandard: 'SMMCustom',
  DMSNextCustomerCenterByDash: 'CC By Dash',
  DMSNextCustomerCenter: 'Customer Center',
  CustomReporting: 'Custom Reporting',
  CustomSolutionsSpotify: 'Custom Solutions - Spotify',
  CustomSolutionsSnapchat: 'Custom Solutions - Snapchat',
  CustomSolutionsPandora: 'Custom Solutions - Pandora',
  CustomSolutionsLinkedin: 'Custom Solutions - Linkedin',
  CustomSolutionsTiktok: 'Custom Solutions - Tiktok',
  SMBRetailGCIOnlySmartListings: 'SMB Retail GCI Only - SmartListings',
  SMBRetailGCIOnlyVisiblePresence: 'SMB Retail GCI Only - Visible Presence',
  SearchEngineMarketing: 'Search Engine Marketing',
  PiQLandingPages: 'PiQLandingPages',
  TargetedDisplay: 'Targeted Display',
  SMBRetailGCIOnlyResponsiveSite: 'SMB Retail GCI Only - Responsive Site',
  TargetedEmail: 'Targeted Email',
  PremiumListings10Plus: 'Premium Listings 10 Plus',
  DisplayCreativeServiceCustom: 'Display Creative Service - Custom',
  TargetedDisplayLegacy: 'Targeted Display Legacy',
  CustomSolutionsTwitter: 'Custom Solutions - Twitter',
  XMO: 'XMO',
  XMOUpgrade: 'XMO Upgrade',
  PromotionsPageAndCreativesReachLocalBetaOnly: 'Promotions Page And Creatives (ReachLocal Beta ONLY)',
  PromotionsMediaMix: 'Promotions Media Mix',
  AUSVideoCreativeService: 'AUS Video Creative Service',
  VideoCreativeServicePackage: 'Video Creative Service Package'
} as const;
export enum FileUploadDirectories {
  ECOMMERCE = 'ecommerce_microsite',
  LANDING = 'landing_page',
}

const gettyInitialState = ({
  totalElements: 0,
  content: [],
  pageNumber: 1,
  pageSize: 5,
  totalPages: 0,
  loading: false,
});

const initialState: FilesState = {
  files: {
    status: 'idle',
    message: '',
    content: [],
  },
  filesGetty: {
    status: 'idle',
    content: [],
    filesToRemove: [],
    message: '',
  },
  getty: gettyInitialState,
  totalFiles: {
    status: 'idle',
    message: '',
    content: [],
  },
};

function getFileExtension(filename: string) {
  const splittedFileName = filename ? filename.split('.') : [''];
  return splittedFileName[splittedFileName.length - 1];
}

function getMediaType(filename: string) {
  const fileExtension = getFileExtension(filename);
  if (['jpg', 'jpeg', 'png', 'gif', 'cr2', 'dng'].includes(fileExtension)) return 'images';
  if (['mp4'].includes(fileExtension)) return 'videos';
  return 'others';
}

export const deleteFile = createAsyncThunkCatchErrors(
  'fileUploader/delete',
  async ({ websiteId, file } : { websiteId: string | number, file: any }) => {
    /* We have the file name in the url, since we didn't save just the
      we'll ge the name from the url */
    const splittedUrl = file.url ? file.url.split('/') : [''];
    const fileName = splittedUrl[splittedUrl.length - 1];
    AxiosAPI.delete(`files?bucketName=&directory=${websiteId}&files=${fileName}`);

    const response = await AxiosAPI.delete(`/upload/${file.id}`);
    if (response.status === 200) return file.id;
    return null;
  },
);

export const getFiles = createAsyncThunkCatchErrors(
  'fileUploader/get',
  async ({ websiteId, uploaderType, offeringType } :
  { websiteId: number | string, uploaderType: string, offeringType: WebsiteOfferingTypes }) => {
    // TODO get the right endpoint
    // eslint-disable-next-line max-len
    const response = await AxiosAPI.get(`/upload/get-uploads-by?productId=${websiteId}&productType=${FileUploadProductType[offeringType]}&uploaderType=${uploaderType}`);
    return response.data.map(data => ({ ...data, mediaType: getMediaType(data.fileName) }));
  },
);

export const uploadFiles = createAsyncThunkCatchErrors(
  'fileUploader/post',
  async ({
    websiteId, files, uploaderType, offeringType,
  }
  : {
    websiteId: string | number,
    files: any,
    uploaderType: string,
    offeringType: WebsiteOfferingTypes
  }) => {
    const uploadedFilesPromises = files.map(async file => {
      const formData = new FormData();
      formData.set('bucketName', process.env.REACT_APP_BUCKET_NAME || '');
      formData.set('directory', `${websiteId}`);
      formData.set('files', file);
      const filesResponse = await AxiosAPI.post(
        '/files/upload',
        formData,
        { headers: { 'Content-type': 'multipart/form-data' } },
      );
      const fileName = Object.keys(filesResponse.data)[0];
      const fileElement = Object.values(filesResponse.data)[0][0];
      const uploadInfo = {
        fileName,
        fileSize: file.size,
        productId: websiteId,
        productType: FileUploadProductType[offeringType],
        uploaderType,
        url: fileElement.url,
      };
      const uploadResponse = await AxiosAPI.post(
        'upload',
        uploadInfo,
      );
      return { ...uploadResponse.data, mediaType: getMediaType(fileName) };
    });
    return Promise.all(uploadedFilesPromises);
  },
);

export const uploadFilesAdmin = createAsyncThunkCatchErrors(
  'fileUploaderAdmin/post',
  async ({ directory, files } : { directory: string | number, files: any }) => {
    const uploadedFilesPromises = files.map(async file => {
      const formData = new FormData();
      formData.set('bucketName', process.env.REACT_APP_BUCKET_NAME || '');
      formData.set('directory', `${directory}`);
      formData.set('files', file);
      const filesResponse = await AxiosAPI.post(
        '/files/upload',
        formData,
        { headers: { 'Content-type': 'multipart/form-data' } },
      );
      return filesResponse;
    });
    return Promise.all(uploadedFilesPromises);
  },
);

export const updateFiles = createAsyncThunkCatchErrors(
  'fileUploader/updateFiles',
  async ({ files } : { files: any }) => {
    const uploadedFilesPromises = files.map(async file => {
      const {
        callToAction, featured, hero, other, notes,
      } = file;
      const response = await AxiosAPI.put(`/upload/${file.id}`, {
        callToAction, featured, hero, other, notes,
      });
      return response.data;
    });
    return Promise.all(uploadedFilesPromises);
  },
);

export const gettyImages = createAsyncThunkCatchErrors(
  'fileUploader/gettyImages',
  async ({ page, size, phrase } : { page: number, size: number, phrase: string }) => {
    // eslint-disable-next-line max-len
    const response = await AxiosAPI.get(`/media/getty/images?page=${page}&phrase=${phrase}&size=${size}`);
    const content = response.data?.content?.map(ct => ({ ...ct, mediaType: 'images' }));
    return { ...response.data, content };
    // return response.data
  },
);

export const gettyVideos = createAsyncThunkCatchErrors(
  'fileUploader/gettyImages',
  async ({ page, size, phrase } : { page: number, size: number, phrase: string }) => {
    // eslint-disable-next-line max-len
    const response = await AxiosAPI.get(`/media/getty/videos?page=${page}&phrase=${phrase}&size=${size}`);
    const content = response.data?.content?.map(ct => ({ ...ct, mediaType: 'videos' }));
    return { ...response.data, content };
  },
);

export const deleteGettyAsset = createAsyncThunkCatchErrors(
  'fileUploader/addGettyFileForRemoval',
  async ({ data }: { data: any }) => {
    const response = await AxiosAPI.delete(`/media/id/${data.id}`);
    if (response.status === 200) return data;
    return {};
  },
);

export const loadGettyAssets = createAsyncThunkCatchErrors(
  'fileUploader/loadGettyFiles',
  async ({
    orderId, orderItemId, data, productType,
  } : { orderId: string, orderItemId: string, data: any[], productType: ProductTypes }, { getState }) => {
    // filters already selected files
    const getty = getState().fileUploader.filesGetty.content || [];
    const gettyIds = getty.map(g => g.mediaId);
    const dataFiltered = data.filter((d) => !gettyIds.includes(d.id));

    const promises = dataFiltered.map(async (d) => {
      const response = await AxiosAPI.post(`/media/order/id/${orderId}/item/${orderItemId}`, {
        mediaId: d.id,
        url: d.uri,
        mediaType: d.mediaType,
        productType,
      });
      return response.data;
    });
    return Promise.all(promises);
  },
) as AsyncThunk<any[], { orderId: string, orderItemId: string, data: any[], productType: ProductTypes }, {}>;

export const getGettyAssets = createAsyncThunkCatchErrors(
  'fileUploader/getGettyFiles',
  async ({ orderId, orderItemId, productType }:{ orderId: string, orderItemId: string, productType: ProductTypes }) => {
    const response = await AxiosAPI.get(`/media/order/id/${orderId}/item/${orderItemId}/product-type/${productType}`);
    return response.data;
  },
) as AsyncThunk<any, { orderId: string, orderItemId: string, productType: ProductTypes }, {}>;

export const updateGettyAssets = createAsyncThunkCatchErrors(
  'fileUploader/updateGettyFiles',
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  async ({ orderId, files }: { orderId: string, files: any[] }, payloadCreator) => {
    const postFilesPromises = files.map(async file => {
      const { id } = file;
      const response = await AxiosAPI.put(`/media/id/${id}`, {
        callToAction: file.callToAction,
        featured: file.featured,
        hero: file.hero,
        other: file.other,
        notes: file.notes,
      });
      return response.data;
    });
    return Promise.all(postFilesPromises);
  },
);

export const setFiles = createAction<any>('fileUploader/files/set');

export const fileUploaderSlice = createSlice({
  name: 'websites',
  initialState,
  reducers: {
    loadGettyFiles(state, action: PayloadAction<{ data: any[] }>) {
      // @ts-ignore
      state.filesGetty.content = action.payload.data.map(d => ({ mediaId: d.id, url: d.uri }));
    },
    updateStoreFilesGetty(state, action: PayloadAction<{ data: any[] }>) {
      state.filesGetty.content = action.payload.data;
    },
    updateStoreFilesUploaded(state, action: PayloadAction<{ data: any[] }>) {
      state.files.content = action.payload.data;
    },
  },
  extraReducers:
    (builder) => {
      builder
        .addCase(getFiles.fulfilled, (state, action) => {
          const concatArr = action.payload.concat(state.files.content);
          const unique = concatArr.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.id === current.id)) {
              accumulator.push(current);
            }
            return accumulator;
          }, []);
          state.files.content = unique;
          state.totalFiles.content = concatArr;
        })
        .addCase(setFiles, (state, action) => {
          state.files.content = action.payload;
        })
        .addCase(uploadFiles.fulfilled, (state, action) => {
          // @ts-ignore
          state.files.content = _.unionBy(state.files.content, action.payload, 'id');
        })
        .addCase(deleteFile.fulfilled, (state, action) => {
          // @ts-ignore
          state.files.content = state.files.content.filter(f => f.id !== action.payload);
        })
        .addCase(gettyImages.fulfilled, (state, action) => {
          state.getty = { ...action.payload, loading: false };
        })
        .addCase(gettyImages.pending, (state) => {
          state.getty.loading = true;
        })
        .addCase(loadGettyAssets.fulfilled, (state, action) => {
          state.filesGetty.content = [...state.filesGetty.content, ...action.payload];
          state.getty = gettyInitialState;
          state.filesGetty.status = 'idle';
        })
        .addCase(deleteGettyAsset.fulfilled, (state, action) => {
          state.filesGetty.content = state.filesGetty.content.filter(i => i.id !== action.payload.id);
          state.filesGetty.status = 'idle';
        })
        .addCase(getGettyAssets.fulfilled, (state, action) => {
          state.filesGetty.content = action.payload;
          state.filesGetty.status = 'idle';
        })
        .addCase(updateGettyAssets.fulfilled, (state, action) => {
          state.filesGetty.content = action.payload;
          state.filesGetty.filesToRemove = [];
          state.filesGetty.status = 'idle';
        })
        .addMatcher(
          isAnyOf(getFiles.fulfilled, uploadFiles.fulfilled, deleteFile.fulfilled),
          (state) => {
            state.files.status = 'idle';
          },
        )
        .addMatcher(
          isAnyOf(getFiles.pending, uploadFiles.pending, deleteFile.pending),
          (state) => {
            state.files.status = 'loading';
          },
        )
        .addMatcher(
          isAnyOf(
            getGettyAssets.pending,
            updateGettyAssets.pending,
            loadGettyAssets.pending,
            deleteGettyAsset.pending,
          ),
          (state) => {
            state.filesGetty.status = 'loading';
          },
        )
        .addMatcher(
          isAnyOf(
            getGettyAssets.rejected,
            updateGettyAssets.rejected,
            loadGettyAssets.rejected,
            deleteGettyAsset.rejected,
          ),
          (state, action) => {
            state.filesGetty.status = 'failed';
            state.filesGetty.message = action.error?.message;
          },
        )
        .addMatcher(
          isAnyOf(getFiles.rejected, uploadFiles.rejected, deleteFile.rejected),
          (state, action) => {
            state.files.status = 'failed';
            state.files.message = action.error?.message;
          },
        );
    },
});

export const submitExistingAssets = () => (dispatch) => {
  // NOTE: Removed due to action being handled by useUpdateAssets hook in the page itself
  // Leaving previous implementation for reference

  // dispatch(updateGettyAssets({ orderId, files: content.stockList }));
  // dispatch(updateFiles({ files: content.uploadedFiles }));
  dispatch(setExistingAssetsCompleted());
};

export const updateAssetsListStore = (content) => (dispatch) => {
  dispatch(fileUploaderSlice.actions.updateStoreFilesGetty({ data: content.stockList }));
  dispatch(fileUploaderSlice.actions.updateStoreFilesUploaded({ data: content.uploadedFiles }));
};

export const updateStoreFilesUploaded = (data: File[]) => (dispatch: Dispatch) => {
  dispatch(fileUploaderSlice.actions.updateStoreFilesUploaded({ data }));
};

export const updateStoreGettyUploaded = (data: File[]) => (dispatch: Dispatch) => {
  dispatch(fileUploaderSlice.actions.updateStoreFilesGetty({ data }));
};

export const selectFilesUploaded = (state: RootState) => state.fileUploader.files;

export const selectFilesGetty = (state: RootState) => state.fileUploader.filesGetty;

export const selectGettyImages = (state: RootState) => state.fileUploader.getty;

export const selectTotalFilesUploaded = (state: RootState) => state.fileUploader.totalFiles;

export default fileUploaderSlice.reducer;
