import { Button, Grid } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';
import { IQFormTextArea, ValidationProvider } from '@gannettdigital/shared-react-components';
import { FormProvider, useForm } from 'react-hook-form';
import SitePagesType from 'models/SitePagesType';
import { deleteSitePage, postSitePage, putSitePage } from 'services/sitePagesSlice';
import CoModalPageCard from 'components/pageCard/CoModalPageCard';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoPageCardForm from 'components/pageCard/CoPageCardForm';
import CoPageCard from 'components/pageCard/CoPageCard';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import WebsitesType from 'models/WebsitesType';
import { StatusType } from 'models/StatusType';
import ContentLoader from 'components/contentLoader/ContentLoader';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { selectActiveOfferingType } from 'services/navigationSlice';
import schema from './SitePages.schema';

const CoPageCardButton = styled(Button)`
  &.Mui-disabled {
    color: #FFFFFF
  }
  font-weight: 600;
  text-transform: none;
  font-size: 14px;
  line-height: 18px;
  padding: 0 40px 0 0;
  margin-left: 24px;
  min-width: 0;
`;

const styles = makeStyles(
  {
    button: {
      textTransform: 'none',
    },
    addPageWhenEmpty: {
      position: 'relative',
      backgroundColor: '#EBF4FF',
      height: '56px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '3px',
      borderColor: '#D7D7D7',
      margin: '2px 0 35px 0',
    },
    centeredButton: {
      margin: 0,
      marginLeft: '24px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
);

const emptySitePage = {
  pageName: '',
  targetMessaging: '',
  otherNotes: '',
  // case is a new card, we will add it to the list
  id: null,
};

interface Props {
  website: StatusType<WebsitesType>,
  sitePages: StatusType<SitePagesType[]>,
  onSubmit: (data) => any
  onBack: () => any
}

export default function SitePagesForm(props: Props) {
  const { t } = useTranslation();

  const offeringType = useSelector(selectActiveOfferingType);

  const { website, sitePages } = props;

  const sitePagesContentSorted = [].concat(sitePages.content).sort((a, b) => a.updatedAt - b.updatedAt);

  const { content: websiteContent, status: websiteStatus, message: websiteMessage } = website;

  const style = styles();

  const [flagOpenModal, setFlagOpenModal] = useState(false);
  const [flagOpenModalConfirm, setFlagOpenModalConfirm] = useState(false);
  const [pageCardEdit, setPageCardEdit] = useState(emptySitePage);
  const [pageCardRemove, setPageCardRemove] = useState(emptySitePage);
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      additionalNotes: websiteContent?.sitePagesNotes,
    },
  });
  const {
    handleSubmit,
    setValue,
    formState: {
      isDirty, isSubmitting, isSubmitSuccessful,
    },
  } = methods;

  useEffect(() => {
    setValue('additionalNotes', websiteContent?.sitePagesNotes);
  }, [websiteContent]);

  const openModal = (content) => {
    setPageCardEdit(content);
    setFlagOpenModal(true);
  };

  const openModalConfirm = (content) => {
    setPageCardRemove(content);
    setFlagOpenModalConfirm(true);
  };

  const closeModal = () => setFlagOpenModal(false);

  const savePage = async (content: SitePagesType) => {
    if (!content.id) {
      await dispatch(postSitePage({ websiteId: websiteContent.id, content, offeringType }));
    } else {
      await dispatch(putSitePage({ content, offeringType }));
    }
    closeModal();
  };

  const removePage = () => {
    dispatch(deleteSitePage({ offeringType, content: pageCardRemove }));
  };

  const onBack = () => {
    props.onBack();
  };

  const onSubmit = (data) => {
    props.onSubmit(data.additionalNotes);
  };

  return (
    <>
      <DefaultPageLayout
        // @ts-ignore
        onContinue={handleSubmit(onSubmit)}
        onBack={onBack}
        disableContinue={!sitePages.content.length}
        header={t('pages.sitePages.sitePages')}
        description={t('pages.sitePages.mustEnterPages')}
      >
        <Grid container>
          <ContentLoader status={sitePages.status} message={sitePages.message}>
            {sitePagesContentSorted.map((content, index) => (
              <Grid item xs={8} key={content.id}>
                <CoPageCard
                  pageName={content.pageName}
                  targetMessaging={content.targetMessaging}
                  otherNotes={content.otherNotes}
                  onEdit={() => openModal(content)}
                  onDelete={() => openModalConfirm(content)}
                  isFirst={index === 0}
                />
              </Grid>
            ))}

            <Grid item xs={8} sx={{ margin: '35px 0 35px 0' }}
              className={!sitePages.content?.length && style.addPageWhenEmpty}
            >
              <CoPageCardButton
                onClick={() => openModal(emptySitePage)}
                className={!sitePages.content?.length && style.centeredButton}
              >
                +&nbsp;&nbsp;
                <u>{t('pages.sitePages.addPageButton')}</u>
              </CoPageCardButton>
            </Grid>
            <CoModalPageCard title="Site Pages" isOpen={flagOpenModal} onClose={closeModal}>
              <CoPageCardForm pageCard={pageCardEdit} onSave={savePage} />
            </CoModalPageCard>
          </ContentLoader>

          <ContentLoader status={websiteStatus} showLoadingSpinner={false} message={websiteMessage}>
            <Grid item xs={8} sx={{ marginTop: '12px' }}>
              <ValidationProvider schema={schema}>
                <FormProvider {...methods}>
                  <IQFormTextArea
                    labelText={t('components.pageCard.labels.additionalNotes')}
                    fontLabelWeight="600"
                    name="additionalNotes"
                    id="additionalNotes"
                    fullWidth
                    rowCount={2}
                  />
                </FormProvider>
              </ValidationProvider>
            </Grid>
          </ContentLoader>

        </Grid>

        <CoModalConfirm open={flagOpenModalConfirm}
          title="Remove Site Page?"
          description="Are you sure you want to remove this Site Page?"
          confirmText="Remove"
          handleClose={() => setFlagOpenModalConfirm(false)}
          handleConfirm={removePage}
        />
      </DefaultPageLayout>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </>
  );
}
