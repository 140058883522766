import * as yup from 'yup';
import i18n from 'i18n/i18n';
import {urlValidationRegex} from "shared/constants";

const {t} = i18n;

const schema = {
  webEventValue: yup.string().notRequired(),
  webEventUrl: yup.string()
  .notRequired()
  .matches(
      urlValidationRegex,
      t('pages.youtube.webEvents.webEventUrlErrorMsg')
  ),
  webEventName: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};