import * as yup from 'yup';

const schema = {
  standardLogo: yup.array().notRequired().nullable(),
  squareLogo: yup.array().notRequired().nullable(),
  locationPhotos: yup.array().notRequired().nullable(),
  providerPhoto: yup.array().notRequired().nullable(),
  publishedVideos: yup.array().notRequired().nullable(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
