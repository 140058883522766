import React, {useEffect, useMemo, useState} from 'react';
import {
  IQFormLayout, useValidationContext,
} from '@gannettdigital/shared-react-components';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import OperationDayRow from './OperationDayRow';

export default function WeekOperationHours() {
  const {schema} = useValidationContext();
  const {t} = useTranslation();
  const {
    formState: {errors}, getValues, watch, setError, getFieldState,
  } = useFormContext();
  const {fields: days} = useFieldArray({
    name: 'days',
  });

  const applyToAllButtonClicked = () => {
    return
  }

  const closedButtonClicked = (rowNumber: any, isOpen: any) => {
    days.forEach((day, index) => {
      if (index === rowNumber) {
        day['open'] = isOpen;
      }
    });
  }

  const hasError = useMemo(
      () => errors?.days?.type === 'oneOpenDay',
      [errors?.days?.type],
  );

  const validate = () => {
    schema.yupValidations.validate(getValues())
    .catch(({
              path, type, message, value,
            }) => {
      if (type === 'oneOpenDay') {
        setError(path, {type, message});
        for (let index = 0; index < value.length; index++) {
          setError(`days.${index}.open`, {type, message});
        }
      }
    });
  };

  useEffect(() => {
    const subscription = watch(() => {
      if (getFieldState('days').isDirty) {
        return validate();
      }
      return null;
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    days
  }, [closedButtonClicked, applyToAllButtonClicked]);

  return (
      <IQFormLayout
          fullWidth
          showError={hasError}
          errorMessage={(errors as any)?.days?.message}
          fontLabelWeight="bold"
          labelText={t('pages.business.location.hours.hoursOfOperation.description')}
      >
        {React.Children.toArray(
            days.map((day, index) => (
                    <OperationDayRow
                        day={day}
                        rowNumber={index}
                        closedButtonClicked={closedButtonClicked}
                        applyToAllButtonClicked={applyToAllButtonClicked}
                    />
                )
            ))}
      </IQFormLayout>
  );
}
