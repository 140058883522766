import * as yup from 'yup';
import { useEffect } from 'react';
import { useTheme } from '@emotion/react';
import {
  IQLeftNavContainer, IQLeftNavLogo, IQCartOverlay, ValidationProvider, IQInputEmail, IQThemeV2,
} from '@gannettdigital/shared-react-components';
import { Box } from '@mui/system';
import IQLeftNavIconNeon from 'icons/IQLeftNavIconNeon.svg';
import { RootStyle, MainBodyStyle, ContainerStyle } from 'layouts/OrderEditLayout';
import { drawerWidth } from 'layouts/navBars/OrderNavBar';
import TopnavMenuContainer from 'layouts/topnav/TopnavMenuContainer';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createSharedUrl } from 'services/clientStockAssetsSlice';
import { useAppDispatch } from 'services/redux/store';
import { getClients, selectClients } from 'services/clientsSlice';
import schema from './StockAssets.schema';

export default function StockAssetsInvite() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { orderId, orderItemId } = useParams();
  const clients = useSelector(selectClients);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });

  const {
    getValues, setValue, trigger,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (clients.length === 0) {
      dispatch(getClients(orderId));
    } else {
      setValue('email', clients[0].contactEmailList[0]);
      trigger();
    }
  }, [clients]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = getValues();
    const request = {
      emailRecipient: data.email,
      orderId,
      orderItemId,
      originalUrl: window.location.href,
    };

    await appDispatch(dispatch(createSharedUrl(request)))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then(res => navigate('/orders/mail/success'))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch(err => navigate('/orders/mail/fail'));
  };

  const customValidation = yup.string()
    .email('Please enter a valid email in this form name@example.com')
    .required('Please enter an email to continue');

  return (
    <RootStyle theme={theme} popup={false}>
      <Box>
        <IQLeftNavContainer>
          <Box sx={{
            display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between',
          }}
          >
            <div>
              <IQLeftNavLogo IQLeftNavIcon={IQLeftNavIconNeon} />
            </div>
            <IQCartOverlay items={[]} />
          </Box>
        </IQLeftNavContainer>
      </Box>
      <Box sx={{ height: '100vh' }}>
        <TopnavMenuContainer popup={false} />
        <MainBodyStyle theme={theme} drawerWidth={drawerWidth} style={{ marginTop: '0' }}>
          <ContainerStyle maxWidth={false} theme={theme} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '530px !important',
            minHeight: '100vh',
          }}
          >
            <ValidationProvider schema={schema}>
              <FormProvider {...methods}>
                <Grid container justifyContent="center" gap={4}>
                  <h1>Have a Client Add Stock Media</h1>
                  <IQInputEmail
                    labelText="Email"
                    fontLabelWeight="600"
                    theme={IQThemeV2}
                    schema={schema}
                    label="Email"
                    field="email"
                    fullWidth
                    adornmentIcon={<MailOutlineIcon />}
                    customValidation={customValidation}
                  />
                  <Button
                    variant="contained"
                    disabled={!isValid}
                    sx={{ padding: '8px 22px' }}
                    onClick={(e) => handleSubmit(e)}
                  >
                    <Typography fontSize={22}>Continue</Typography>
                    <ChevronRightIcon fontSize="large" />
                  </Button>
                </Grid>
              </FormProvider>
            </ValidationProvider>
          </ContainerStyle>
        </MainBodyStyle>
      </Box>
    </RootStyle>
  );
}
