export interface SalesAgent {
  id?: string
  salesAgentEmail: string,
  salesAgentName: string,
  salesAgentPhoneNumber: string,
  salesAgentPhoneNumberExt: string,
  salesAgentPhoneNumberType: string,
  salesAgentTypeId?: number,
  role?: string
}

export const defaultAgent: SalesAgent = {
  salesAgentName: '',
  salesAgentEmail: '',
  salesAgentPhoneNumber: '',
  salesAgentPhoneNumberExt: '',
  salesAgentPhoneNumberType: 'Mobile',
};
