/*
 * Update the check field of features based on what is selected in the website object
 */
export const updateFeatures = (website, features) => {
  const featuresNames = features.map((f) => f.name);
  const updatedFeatures = features;
  if (website) {
    featuresNames.forEach((featName) => {
      if (website[featName]) {
        const index = features.findIndex((i) => i.name === featName);
        Object.assign(updatedFeatures[index], { checked: true });
      } else {
        const index = features.findIndex((i) => i.name === featName);
        Object.assign(updatedFeatures[index], { checked: false });
      }
    });
  }
  return updatedFeatures;
};

/*
AdditionalStoreDetails an object in the form of (example):
{
  blog: true
  testimonial: false
}
 */
export const fetchDataForPut = (data, features) => {
  const featuresUpdated = data;
  const fieldsToUpdate = {};
  // get the name of features we selected
  const selectedFeatures = featuresUpdated.filter(f => f.checked).map((f) => f.name);
  // set the selected ones to true
  selectedFeatures.forEach((f) => { fieldsToUpdate[f] = true; });
  // set the not selected to false
  const notSelectedFeatures = features.map((f) => f.name).filter((f) => !selectedFeatures.includes(f));
  notSelectedFeatures.forEach((f) => { fieldsToUpdate[f] = false; });
  return fieldsToUpdate;
};
