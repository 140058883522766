import OrderEditLayout from 'layouts/OrderEditLayout';
import { useParams } from 'react-router';
import { ProductPageRouteMapping } from 'pages/ProductPageRouteMapping';
import UnexpectedError from 'pages/error/UnexpectedError';
import BusinessDetails from './business/details/BusinessDetails';

export default function ProductPageRouter() {
  const { page, businessPage } = useParams();

  let url = page;
  if (businessPage) {
    url = `businesses/${businessPage}`;
  }

  const Component = ProductPageRouteMapping[url] || UnexpectedError;

  return (
    <OrderEditLayout menu={url}>
      {businessPage === 'detailsNew'
        ? <BusinessDetails newBusiness />
        : <Component />}
    </OrderEditLayout>
  );
}
