import { useDispatch, useSelector } from 'react-redux';
import { selectFilesGetty, selectFilesUploaded } from 'services/fileUploaderSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { Urls } from 'navigation/Urls';
import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import {
  getAddons,
  getHeroVideoAdditionalNotes, getMasterAddonDetails, putHeroVideoAdditionalNotes, selectAddons,
} from 'services/addons/addonsSlice';
import HeroVideoForm from './HeroVideoForm';

export default function HeroVideoPage() {
  const dispatch = useDispatch();
  const { orderId, orderItemId } = useParams();
  const navigate = useNavigationHandler();
  const addons = useSelector(selectAddons);

  const topNav = useTopnavMenu(Urls.HeroVideo);
  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  // redux
  const { status: fileStatus, message: fileMessage } = useSelector(selectFilesUploaded, (prev, curr) => {
    if (prev.status === curr.status) {
      return true;
    }

    return prev.message === curr.message;
  });

  const { status: gettyStatus, message: gettyMessage } = useSelector(selectFilesGetty, (prev, curr) => {
    if (prev.status === curr.status) {
      return true;
    }

    return prev.message === curr.message;
  });

  useEffect(() => {
    if (orderId) {
      dispatch(getHeroVideoAdditionalNotes({ orderId }));
    }
  }, []);

  // eslint-disable-next-line consistent-return
  const onSubmit = (data) => {
    dispatch(putHeroVideoAdditionalNotes({
      orderId, content: { additionalNotes: data.additionalNotes },
    }));

    if (isRedirectedFromOverviewPage) return navigate.to(Urls.Overview);
    navigate.to(topNav.nextStep());
  };

  const onBack = () => {
    navigate.to(topNav.previousStep());
  };

  useEffect(() => {
    if (!addons?.content?.id) dispatch(getAddons(orderId));
    if (orderItemId) dispatch(getMasterAddonDetails(orderItemId));
  }, [addons, orderItemId]);

  return (
    <ContentLoader
      status={[fileStatus, gettyStatus]}
      message={[fileMessage, gettyMessage]}
    >
      <HeroVideoForm
        onSubmit={onSubmit}
        onBack={onBack}
        heroVideo={{}}
      />
    </ContentLoader>
  );
}
