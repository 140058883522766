import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';

export const semMenu: MenuType = ({
  label: 'Search Engine Marketing',
  url: '',
  status: 'incomplete',
  offeringType: 'SearchEngineMarketing',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
    {
      label: 'Details',
      url: Urls.SemDetails,
      status: 'incomplete',
    },
    {
      label: 'Solution Strategy',
      url: Urls.GenericProductDetails,
      status: 'incomplete',
    },
  ],
});
