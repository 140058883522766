import * as yup from 'yup';

const schema = {
  captureCode: yup.boolean().optional(),
  facebookPixels: yup.boolean().optional(),
  google: yup.boolean().optional(),
  otherTracking: yup.boolean().optional(),
  additionalTrackingNotes: yup.string().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
