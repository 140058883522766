import {
  FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography,
} from '@mui/material';
import { tooltip } from 'pages/general-questions/form-sections/Tracking';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import i18n from 'i18n/i18n';
import schemaValidate from 'components/schemaValidate';
import ErrorText from 'components/errorText/ErrorText';
import schema from './SMMDetailsPage.schema';
import { existingFacebookOptions, yesNoOptions } from './SMMDetailsPageConstants';

const { t } = i18n;

export const renderSocialFields = (props) => {
  const {
    existingSocialFieldName,
    existingSocialUrlFieldName,
    includeSocial,
    hasSocial,
    existingSocial,
    setExistingSocial,
    register,
    errors,
  } = props;

  const social = {
    existingFacebook: 'Facebook',
    existingInstagram: 'Instagram',
    existingX: 'X',
  };

  const radioOptions = () => ((social[existingSocialFieldName] === 'Facebook')
    ? existingFacebookOptions : yesNoOptions);

  const hasError = !!errors[existingSocialUrlFieldName];
  const errorMessage = hasError ? errors[existingSocialUrlFieldName]?.message : '';

  return (
    <Grid item mb={3}>
      {includeSocial && (
      <Grid item mb={1}>
        <Typography variant="subtitle2" component="h3" fontSize={16}>
          {t(`pages.smm.details.existing${social[existingSocialFieldName]}Label`)}
          {tooltip(
            t(`pages.smm.details.existing${social[existingSocialFieldName]}Tooltip`),
            <InfoOutlinedIcon />,
            '200px',
          )}
        </Typography>
        <RadioGroup
          aria-labelledby={existingSocialFieldName}
          name={existingSocialFieldName}
          value={existingSocial}
          onChange={(e) => setExistingSocial(e.target.value)}
        >
          {radioOptions().map(option => (
            <FormControlLabel
              key={option.value}
              {...register(existingSocialFieldName, {
                validate: (value) => schemaValidate(value, existingSocialFieldName, schema),
              })}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </Grid>
      )}

      {(includeSocial && hasSocial) && (
      <Grid item mb={3}>
        <Grid item mb={1}>
          <Typography variant="subtitle2" component="h3" fontSize={16}
            color={errors[existingSocialUrlFieldName] ? 'error.main' : 'common.black'}
          >
            {t(`pages.smm.details.existing${social[existingSocialFieldName]}UrlLabel`)}
          </Typography>
        </Grid>
        <TextField
          hiddenLabel
          required
          name={existingSocialUrlFieldName}
          error={hasError}
          fullWidth
          {...register(existingSocialUrlFieldName, {
            validate: (value) => schemaValidate(value, existingSocialUrlFieldName, schema),
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <ErrorText
          hasError={hasError}
          errorText={errorMessage}
        />
      </Grid>
      )}
    </Grid>
  );
};
