import * as yup from 'yup';

const schema = {
  logo: yup.string().nullable(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
