import React from 'react';

type FileUploaderContextType = {
  gettyUploaderName: string | undefined;
  fileUploaderName: string | undefined;
};

export const FileUploaderContext = React.createContext<FileUploaderContextType>({
  gettyUploaderName: null,
  fileUploaderName: null,
});
FileUploaderContext.displayName = 'File Uploaders Context';
