import * as yup from 'yup';

const schema = {
  id: yup.string().required(),
  label: yup.string().required(),
  subDescription: yup.string(),
  checked: yup.boolean(),
  disabled: yup.boolean(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
