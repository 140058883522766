import AxiosAPI from 'services/common/axios';

export default function useNpiValidationService() {
  return {
    validateNpiNumber:
      async (npiNumber: string) => AxiosAPI.get(
        `/integrations/npi/${npiNumber}/valid`,
      ).then(results => results.data),
  };
}
