import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";
import {Grid, Typography} from "@mui/material";

const {t} = i18n;

export const TargetingSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.targeting.title'),
  type: 'object',
  required: ['locationTargetingType', 'demographicTargeting'],
  properties: {
    targetingNote1: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.targeting.targetingNote1'),
    },
    targetingNote2: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.targeting.targetingNote2'),
    },
    locationTargetingType: {
      type: 'string',
      title: t('pages.ausProduct.targeting.locationTargetingType'),
      default: data?.locationTargetingType || '',
      enum: [
        `${t('pages.ausProduct.targeting.national')}`,
        `${t('pages.ausProduct.targeting.stateRegional')}`,
        `${t('pages.ausProduct.targeting.radius')}`,
        `${t('pages.ausProduct.targeting.postcode')}`,
        `${t('pages.ausProduct.targeting.city')}`,
      ]
    },
    targetingNote3: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.targeting.targetingNote3'),
    },
    nationalPublisherTargeting: {
      type: 'string',
      title: t('pages.ausProduct.targeting.nationalPublisherTargeting'),
      default: data?.nationalPublisherTargeting || '',
      enum: [
        "",
        `${t('pages.ausProduct.targeting.nationalPublisherBestPractice')}`,
        `${t('pages.ausProduct.targeting.nationalPublisherSameAsCity')}`,
      ]
    },
    demographicTargeting: {
      type: 'string',
      title: t('pages.ausProduct.targeting.demographicTargeting'),
      default: data?.nationalPublisherTargeting || '',
      enum: [
        "",
        `${t('pages.ausProduct.targeting.yes')}`,
        `${t('pages.ausProduct.targeting.no')}`,
      ]
    },
    targetingNote8: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.targeting.targetingNote8'),
    },
    adGroups: {
      type: 'array',
      title: t('pages.ausProduct.targeting.adGroups'),
      default: data?.adGroups || '',
      items: {
        type: 'string',
        enum: [
          `${t('pages.ausProduct.targeting.retargeting')}`,
          `${t('pages.ausProduct.targeting.lookalike')}`,
          `${t('pages.ausProduct.targeting.customIntent')}`,
          `${t('pages.ausProduct.targeting.affinityAudience')}`,
          `${t('pages.ausProduct.targeting.inMarket')}`,
          `${t('pages.ausProduct.targeting.lifeEvent')}`,
          `${t('pages.ausProduct.targeting.otherCSM')}`,
        ],
      },
      uniqueItems: true,
    },
    targetingNote9: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.targeting.targetingNote9'),
    },
    adScheduling: {
      type: 'string',
      title: t('pages.ausProduct.targeting.adScheduling'),
      default: data?.nationalPublisherTargeting || '',
      enum: [
        "",
        `${t('pages.ausProduct.targeting.yes')}`,
        `${t('pages.ausProduct.targeting.no')}`,
      ]
    },
    areYouHappy: {
      type: 'string',
      title: t('pages.ausProduct.targeting.areYouHappy'),
      default: data?.areYouHappy || t('pages.ausProduct.targeting.yes'),
      enum: [
        "",
        `${t('pages.ausProduct.targeting.yes')}`,
        `${t('pages.ausProduct.targeting.no')}`,
      ]
    },
    targetingNote13: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.targeting.targetingNote13'),
    },
    buildComments: {
      type: 'string',
      title: t('pages.ausProduct.targeting.buildComments'),
      default: data?.source || '',
    },
    targetingNote14: {
      type: 'string',
      title: "",
      description: t('pages.ausProduct.targeting.targetingNote14'),
    },
  },
  dependencies: {
    locationTargetingType: {
      oneOf: [
        {
          properties: {
            locationTargetingType: {
              enum: [`${t('pages.ausProduct.targeting.national')}`],
            },
            targetingNote4: {
              type: 'string',
              title: "",
              description: t('pages.ausProduct.targeting.targetingNote4'),
            },
            selectCountry: {
              type: 'string',
              title: t('pages.ausProduct.targeting.selectCountry'),
              default: data?.selectCountry || '',
              enum: [
                "",
                `${t('pages.ausProduct.targeting.australia')}`,
                `${t('pages.ausProduct.targeting.newZealand')}`,
                `${t('pages.ausProduct.targeting.other')}`,
              ]
            },
          },
        },
        {
          properties: {
            locationTargetingType: {
              enum: [`${t('pages.ausProduct.targeting.stateRegional')}`],
            },
            statesToTarget: {
              type: 'array',
              title: t('pages.ausProduct.targeting.statesToTarget'),
              default: data?.statesToTarget || '',
              items: {
                type: 'string',
                enum: [
                  `${t('pages.ausProduct.targeting.australianCapitalTerritory')}`,
                  `${t('pages.ausProduct.targeting.newSouthWales')}`,
                  `${t('pages.ausProduct.targeting.northernTerritory')}`,
                  `${t('pages.ausProduct.targeting.queensland')}`,
                  `${t('pages.ausProduct.targeting.southAustralia')}`,
                  `${t('pages.ausProduct.targeting.tasmania')}`,
                  `${t('pages.ausProduct.targeting.victoria')}`,
                  `${t('pages.ausProduct.targeting.westernAustralia')}`,
                  `${t('pages.ausProduct.targeting.northland')}`,
                  `${t('pages.ausProduct.targeting.auckland')}`,
                  `${t('pages.ausProduct.targeting.bayOfPlenty')}`,
                  `${t('pages.ausProduct.targeting.canterbury')}`,
                  `${t('pages.ausProduct.targeting.gisborne')}`,
                  `${t('pages.ausProduct.targeting.hawkesBay')}`,
                  `${t('pages.ausProduct.targeting.manawatuWanganui')}`,
                  `${t('pages.ausProduct.targeting.marlborough')}`,
                  `${t('pages.ausProduct.targeting.nelson')}`,
                  `${t('pages.ausProduct.targeting.otago')}`,
                  `${t('pages.ausProduct.targeting.southland')}`,
                  `${t('pages.ausProduct.targeting.taranaki')}`,
                  `${t('pages.ausProduct.targeting.tasman')}`,
                  `${t('pages.ausProduct.targeting.waikato')}`,
                  `${t('pages.ausProduct.targeting.wellington')}`,
                  `${t('pages.ausProduct.targeting.westCoast')}`,
                ],
              },
              uniqueItems: true,
            },
            targetingNote5: {
              type: 'string',
              title: "",
              description: t('pages.ausProduct.targeting.targetingNote5'),
            },
          }
        },
        {
          properties: {
            locationTargetingType: {
              enum: [`${t('pages.ausProduct.targeting.radius')}`],
            },
            provideRadiusInKM: {
              type: 'string',
              title: t('pages.ausProduct.targeting.provideRadiusInKM'),
              default: data?.provideRadiusInKM || '',
            },
            addressCentralPointOfRadius: {
              type: 'string',
              title: t('pages.ausProduct.targeting.addressCentralPointOfRadius'),
              variant: "h4"
            },
            streetNumber: {
              type: 'string',
              title: t('pages.ausProduct.targeting.streetNumber'),
              default: data?.streetNumber || '',
            },
            streetName: {
              type: 'string',
              title: t('pages.ausProduct.targeting.streetName'),
              default: data?.streetName || '',
            },
            citySuburb: {
              type: 'string',
              title: t('pages.ausProduct.targeting.citySuburb'),
              default: data?.citySuburb || '',
            },
            stateProvince: {
              type: 'string',
              title: t('pages.ausProduct.targeting.stateProvince'),
              default: data?.stateProvince || '',
            },
            postalCode: {
              type: 'string',
              title: t('pages.ausProduct.targeting.postalCode'),
              default: data?.postalCode || '',
            },
            country: {
              type: 'string',
              title: t('pages.ausProduct.targeting.country'),
              default: data?.country || '',
            },
          }
        },
        {
          properties: {
            locationTargetingType: {
              enum: [`${t('pages.ausProduct.targeting.city')}`],
            },
            cityTargetingRequirements: {
              type: 'string',
              title: t('pages.ausProduct.targeting.cityTargetingRequirements'),
              default: data?.cityTargetingRequirements || '',
            },
            targetingNote6: {
              type: 'string',
              title: "",
              description: t('pages.ausProduct.targeting.targetingNote6'),
            },
          }
        },
        {
          properties: {
            locationTargetingType: {
              enum: [`${t('pages.ausProduct.targeting.postcode')}`],
            },
            postCode: {
              type: 'string',
              title: t('pages.ausProduct.targeting.postcodeMandatory'),
              default: data?.postCode || '',
            },
            targetingNote7: {
              type: 'string',
              title: "",
              description: t('pages.ausProduct.targeting.targetingNote7'),
            },
          }
        },
      ]
    },
    demographicTargeting: {
      oneOf: [
        {
          properties: {
            demographicTargeting: {
              enum: [`${t('pages.ausProduct.targeting.yes')}`]
            },
            gender: {
              type: 'array',
              title: t('pages.ausProduct.targeting.gender'),
              default: data?.gender || '',
              items: {
                type: 'string',
                enum: [
                  `${t('pages.ausProduct.targeting.male')}`,
                  `${t('pages.ausProduct.targeting.female')}`,
                ],
              },
              uniqueItems: true,
            },
            ages: {
              type: 'array',
              title: t('pages.ausProduct.targeting.ages'),
              default: data?.ages || '',
              items: {
                type: 'string',
                enum: [
                  `${t('pages.ausProduct.targeting.age18_24')}`,
                  `${t('pages.ausProduct.targeting.age25_34')}`,
                  `${t('pages.ausProduct.targeting.age35_44')}`,
                  `${t('pages.ausProduct.targeting.age45_54')}`,
                  `${t('pages.ausProduct.targeting.age55_64')}`,
                  `${t('pages.ausProduct.targeting.age65Plus')}`,
                ],
              },
              uniqueItems: true,
            },
            parentalStatus: {
              type: 'array',
              title: t('pages.ausProduct.targeting.parentalStatus'),
              default: data?.parentalStatus || '',
              items: {
                type: 'string',
                enum: [
                  `${t('pages.ausProduct.targeting.parents')}`,
                  `${t('pages.ausProduct.targeting.nonParents')}`,
                ],
              },
              uniqueItems: true,
            },
            householdIncome: {
              type: 'array',
              title: t('pages.ausProduct.targeting.householdIncome'),
              default: data?.householdIncome || '',
              items: {
                type: 'string',
                enum: [
                  `${t('pages.ausProduct.targeting.lower50')}`,
                  `${t('pages.ausProduct.targeting.top50_40')}`,
                  `${t('pages.ausProduct.targeting.top40_30')}`,
                  `${t('pages.ausProduct.targeting.top30_20')}`,
                  `${t('pages.ausProduct.targeting.top20_10')}`,
                  `${t('pages.ausProduct.targeting.top10')}`,
                ],
              },
              uniqueItems: true,
            },
          }
        }
      ]
    },
    adScheduling: {
      oneOf: [
        {
          properties: {
            adScheduling: {
              enum: [`${t('pages.ausProduct.targeting.yes')}`]
            },
            adSchedulingInfo: {
              type: 'string',
              title: t('pages.ausProduct.targeting.adSchedulingInfo'),
              default: data?.adSchedulingInfo || '',
            },
            targetingNote12: {
              type: 'string',
              title: "",
              description: t('pages.ausProduct.targeting.targetingNote12'),
            },
          }
        }
      ]
    },
    areYouHappy: {
      oneOf: [
        {
          properties: {
            areYouHappy: {
              enum: [`${t('pages.ausProduct.targeting.no')}`]
            },
            source: {
              type: 'string',
              title: t('pages.ausProduct.targeting.source'),
              default: data?.source || '',
            },
            medium: {
              type: 'string',
              title: t('pages.ausProduct.targeting.medium'),
              default: data?.medium || '',
            },
            campaignName: {
              type: 'string',
              title: t('pages.ausProduct.targeting.campaignName'),
              default: data?.campaignName || '',
            },

          }
        }
      ]
    }
  },
  allOf: [
    {
      if: {
        properties: {
          adGroups: {
            type: 'array',
            contains: {
              enum: [
                `${t('pages.ausProduct.targeting.affinityAudience')}`,
                `${t('pages.ausProduct.targeting.inMarket')}`,
                `${t('pages.ausProduct.targeting.lifeEvent')}`
              ]
            }
          },
        }
      },
      then: {
        properties: {
          specifyAudiencesYouWouldLikeToTarget: {
            type: 'string',
            title: t('pages.ausProduct.targeting.specifyAudiencesYouWouldLikeToTarget'),
            default: data?.specifyAudiencesYouWouldLikeToTarget || '',
          },
          linkForTheFullList: {
            type: 'string'
          }
        }
      }
    },
    {
      if: {
        properties: {
          adGroups: {
            type: 'array',
            contains: {
              enum: [
                `${t('pages.ausProduct.targeting.customIntent')}`
              ]
            }
          },
        }
      },
      then: {
        properties: {
          customIntentAudience: {
            type: 'string',
            title: t('pages.ausProduct.targeting.customIntentAudience'),
            default: data?.customIntentAudience || '',
          },
          targetingNote10: {
            type: 'string',
            title: "",
            description: t('pages.ausProduct.targeting.targetingNote10'),
          },
        },
        required: ['customIntentAudience']
      }
    },
    {
      if: {
        properties: {
          adGroups: {
            type: 'array',
            contains: {
              enum: [
                `${t('pages.ausProduct.targeting.otherCSM')}`,
              ]
            }
          },
        }
      },
      then: {
        properties: {
          targetingNote11: {
            type: 'string',
            title: "",
            description: t('pages.ausProduct.targeting.targetingNote11'),
          },
        },
      }
    }
  ]
});

const textLabelKeys = [
  'targetingNote1', 'targetingNote2', 'targetingNote3', 'targetingNote4', 'targetingNote5', 'addressCentralPointOfRadius',
  'targetingNote6', 'targetingNote7', 'targetingNote8', 'targetingNote9', 'targetingNote10', 'targetingNote11', 'targetingNote12',
  'targetingNote13', 'targetingNote14'
];

const dropdownFieldKeys = [
  'locationTargetingType', 'selectCountry', 'nationalPublisherTargeting', 'demographicTargeting', 'adScheduling', 'areYouHappy'
];

const checkboxesFieldKeys = [
  'statesToTarget', 'gender', 'ages', 'parentalStatus', 'householdIncome', 'adGroups'
];

const textFieldKeys = [
  'streetNumber', 'streetName', 'citySuburb', 'stateProvince', 'country', 'cityTargetingRequirements', 'postCode',
  'specifyAudiencesYouWouldLikeToTarget', 'customIntentAudience', 'adSchedulingInfo', 'source', 'medium', 'campaignName',
  'buildComments'
]

const numericFieldKeys = [
  'provideRadiusInKM', 'postalCode'
]

const textLabelFields = textLabelKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textLabel'}
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.targeting.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

const checkboxesFields = checkboxesFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'checkBoxField'}
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'textInputField'},
}), {});

const numericFields = numericFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'integerInputField'},
}), {});

export const TargetingUISchema = {
  'ui:order': [
    'targetingNote1', 'targetingNote2', 'locationTargetingType', 'targetingNote3', 'targetingNote4',
    'selectCountry', 'statesToTarget', 'targetingNote5', 'provideRadiusInKM', 'addressCentralPointOfRadius',
    'streetNumber', 'streetName', 'citySuburb', 'stateProvince', 'postalCode', 'country', 'cityTargetingRequirements',
    'targetingNote6', 'postCode', 'targetingNote7', 'nationalPublisherTargeting', 'demographicTargeting',
    'targetingNote8', 'gender', 'ages', 'parentalStatus', 'householdIncome', 'adGroups', 'targetingNote9',
    'specifyAudiencesYouWouldLikeToTarget', 'linkForTheFullList', 'customIntentAudience', 'targetingNote10',
    'targetingNote11', 'adScheduling', 'adSchedulingInfo', 'targetingNote12', 'areYouHappy', 'source', 'medium',
    'campaignName', 'targetingNote13', 'buildComments', 'targetingNote14'
  ],
  linkForTheFullList: {
    'ui:widget': (props) => (
        <Grid item xs={9} mt={1}>
          <Typography>
            {t('pages.ausProduct.targeting.clickForTheFullList')}
          </Typography>
          (<a href={t('pages.ausProduct.targeting.linkForTheFullList')}
              target="_blank"
              rel="noreferrer">
          <Typography component="span" color="primary">
            {t('pages.ausProduct.targeting.linkForTheFullList')}
          </Typography>
        </a>)
        </Grid>
    ),
  },
  ...textLabelFields,
  ...dropdownFields,
  ...checkboxesFields,
  ...textFields,
  ...numericFields,
};

export const TargetingCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.locationTargetingType === '') {
    errors.locationTargetingType.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.locationTargetingType === 'Radius') {
    if (formData?.provideRadiusInKM === '') {
      errors.provideRadiusInKM.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.streetNumber === '') {
      errors.streetNumber.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.streetName === '') {
      errors.streetName.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.citySuburb === '') {
      errors.citySuburb.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.stateProvince === '') {
      errors.stateProvince.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.postalCode === '') {
      errors.postalCode.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }

    if (formData?.country === '') {
      errors.country.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.locationTargetingType === 'City') {
    if (formData?.cityTargetingRequirements === '') {
      errors.cityTargetingRequirements.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.locationTargetingType === 'Postcode') {
    if (formData?.postCode === '') {
      errors.postCode.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.demographicTargeting === '') {
    errors.demographicTargeting.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.adScheduling === '') {
    errors.adScheduling.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.areYouHappy === 'No') {
    if (formData?.source === '') {
      errors.source.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.medium === '') {
      errors.medium.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
    if (formData?.campaignName === '') {
      errors.campaignName.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
      errors.addError(true);
    }
  }
  return errors;
};