/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { createAsyncThunkCatchErrors } from 'services/errorSlice';
import AxiosAPI from 'services/common/axios';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'services/redux/store';
import { StatusType } from 'models/StatusType';

export interface CustomLandingPageState {
  details: StatusType<any>
  design: StatusType<any>
  development: StatusType<any>
  additional: StatusType<any>
}

const initialState: CustomLandingPageState = {
  details: {
    status: 'idle',
    message: '',
    content: {
      reason: '',
      openToSilverWebsite: '',
    },
  },
  development: {
    status: 'idle',
    message: '',
    content: {
      copyRequirement: '',
      specialFunctionality: '',
    },
  },
  design: {
    status: 'idle',
    message: '',
    content: {
      referenceUrl: '',
      designReferenceType: '',
      otherReference: '',
      assets: '',
      otherAssets: '',
      imageryRequirement: '',
    },
  },
  additional: {
    status: 'idle',
    message: '',
    content: {
      additionalInfo: '',
      attachments: '',
    },
  },
};

export const getCustomLandingPage = createAsyncThunkCatchErrors(
  'customLanding/get',
  async (orderItemId : number | string) => AxiosAPI.get(`/custom-landing-pages/${orderItemId}`),
);

export const setDetails = createAsyncThunkCatchErrors(
  'customLanding/setDetails',
  async ({ orderItemId, sendData, openToSilverWebsite }) => {
    const reasonResponse = await AxiosAPI.put(`custom-landing-pages/${orderItemId}/new-site-reason`, sendData);
    const booleanSilver = openToSilverWebsite === 'yes';
    const openToSilverResponse = await AxiosAPI.put(`/custom-landing-pages/${orderItemId}/open-to-silver-website/${booleanSilver}`);
    console.log(openToSilverResponse);

    return reasonResponse;
  },
);

export const setDesign = createAsyncThunkCatchErrors(
  'customLanding/setDesign',
  async ({ orderItemId, designReferenceData, assetsReferenceData }) => {
    const referenceResponse = await AxiosAPI.put(`custom-landing-pages/${orderItemId}/design-reference`, designReferenceData);
    const assetsReferenceResponse = await AxiosAPI.put(`/custom-landing-pages/${orderItemId}/design-image`, assetsReferenceData);
    console.log(assetsReferenceResponse);
    return referenceResponse;
  },
);

export const setDevelopment = createAsyncThunkCatchErrors(
  'customLanding/setDevelopment',
  async ({
    orderItemId, specialFuncData, copyingServiceNeededData,
  }) => {
    const referenceResponse = await AxiosAPI.put(`/custom-landing-pages/${orderItemId}/development-functionality-details`, specialFuncData);
    const copyrightNeededResponse = await AxiosAPI.put(`/custom-landing-pages/${orderItemId}/development-copy-details`, copyingServiceNeededData);
    console.log(copyrightNeededResponse);

    return referenceResponse;
  },
);

export const setAdditional = createAsyncThunkCatchErrors(
  'customLanding/setDevelopment',
  async ({ orderItemId, addDetailsData }) => {
    const referenceResponse = await AxiosAPI.put(`/custom-landing-pages/${orderItemId}/additional-details`, addDetailsData);

    return referenceResponse;
  },
);

const assetsFormat = (ref, type) => {
  if (ref) {
    const refObj = JSON.parse(ref);
    const mapRef = refObj.map(r => JSON.parse(r.url));
    return mapRef.filter(r => r.uploaderType === type);
  }
  return '';
};

export const customLandingPageSlice = createSlice({
  name: 'customLandingPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomLandingPage.fulfilled, (state, action) => {
        const {
          newSiteReason, openToSilverWebsite, additionalInfo, referenceUrl, otherReference, designReferenceType,
          copyRequirement, specialFunctionality, attachments, imageryRequirement, designReference,
        } = action.payload.data;

        state.details.content = {
          reason: newSiteReason,
          openToSilverWebsite: openToSilverWebsite ? 'yes' : 'no',
        };

        state.design.content = {
          referenceUrl,
          otherReference,
          designReferenceType,
          assets: '',
          otherAssets: '',
          designReference: assetsFormat(designReference, 'mockupAttachments'),
          imageryRequirement,
        };

        state.development.content = {
          copyRequirement,
          specialFunctionality,
        };

        state.additional.content = {
          additionalInfo,
          attachments: assetsFormat(attachments, 'additionalInfoAssets'),
        };
      });
  },
});

export const selectCustomLandingPage = (state: RootState) => state.customLandingPage;
export const selectDetailsCustomLandingPage = (state: RootState) => state.customLandingPage.details.content;
export const selectDevelopmentCustomLadingPage = (state: RootState) => state.customLandingPage.development.content;
export const selectDesignCustomLandingPage = (state: RootState) => state.customLandingPage.design.content;
export const selectAdditionalCustomLandingPage = (state: RootState) => state.customLandingPage.additional.content;

export default customLandingPageSlice.reducer;
