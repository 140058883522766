export const siteTypeOptions = [{
  label: 'LocaliQ Site',
  value: 'localiqSite',
},
{
  label: 'BYOS',
  value: 'byos',
},
];

export const emptyContact = {
  name: '', email: '', phone: '', ext: '',
};

export const yesNoOptions = [{
  label: 'Yes',
  value: 'yes',
},
{
  label: 'No',
  value: 'no',
},
];
