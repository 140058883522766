export const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  .map(weekday => ({
    weekday,
    open: false,
    hours: [
      {
        allDay: false,
        opensAt: null,
        closesAt: null,
      },
    ],
  }));
