/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './redux/store-schedule';

export interface SchedulerState {
  schedule: any;
  scheduleFlag: boolean;
}

const initialState: SchedulerState = {
  schedule: [],
  scheduleFlag: false,
};

const schedulerSlice = createSlice({
  name: 'scheduler',
  initialState,
  reducers: {
    saveSchedule(state, action: PayloadAction<any>) {
      state.schedule = action.payload;
      state.scheduleFlag = action.payload;
    },
  },
});

export const { saveSchedule } = schedulerSlice.actions;

export const selectSchedule = (state: RootState) => state.scheduler.schedule;

export default schedulerSlice.reducer;
