import {
  Box, Grid, Typography,
} from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch } from 'services/redux/store';
import { getProduct } from 'services/productsSlice';
import {
  ProductTypes, getFiles, getGettyAssets, setFiles,
} from 'services/fileUploaderSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { Urls } from 'navigation/Urls';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import OrderTracker from 'pages/orders/OrderTracker';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrderFlow, selectOrdersContent } from 'services/ordersSlice';
import { OrderFlow } from 'shared/constants';
import { getThemeById } from 'services/themesSlice';
import { headingStyle } from '../Business/BusinessSection';
import { ActionItems } from '../common/ActionItems';
import { displayAssets } from '../sites/SitesSection';
import { GeneralQuestionsSection } from '../generalQuestions/GeneralQuestionsSection';

const mapOrderType = {
  addOn: 'Add-On Landing Page',
  new: 'New Landing Page',
};

export const formatDate = (date :Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const adjustedMonth = month < 9 ? `0${month + 1}` : month + 1;
  const day = date.getDate();
  const adjustedDay = day < 10 ? `0${day}` : day;

  return `${year}-${adjustedMonth}-${adjustedDay}`;
};

export default function CampaignLandingSection({
  product, isEditable, showTracker, openBusinessAndSalesAgent,
}: any) {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const orderFlow = useSelector(selectOrderFlow);
  const orderData = useSelector(selectOrdersContent);
  const [landingPagesData, setLandingPagesData] = useState({} as any);
  const [existingAssets, setExistingAssets] = useState([]);
  const [existingContent, setExistingContent] = useState([]);
  const [stockAssets, setStockAssets] = useState([]);
  const [themeSelection, setThemeSelection] = useState('');

  const isPunchlist = (orderFlow === OrderFlow.PUNCHLIST || orderData.orderFlow === OrderFlow.PUNCHLIST);

  const getLandingData = () => {
    appDispatch(getProduct({
      orderItemId: product?.id,
    })).then((response) => setLandingPagesData(response.payload));
  };

  const getAssets = () => {
    appDispatch(getGettyAssets({ orderId, orderItemId: product?.id, productType: ProductTypes.EXISTING_ASSETS }))
      .then((response) => setStockAssets(response.payload));

    appDispatch(getFiles({
      websiteId: product?.offeringId,
      uploaderType: ProductTypes.EXISTING_ASSETS,
      offeringType: product?.pdOfferingType,
    }))
      .then((response) => setExistingAssets(response.payload));

    appDispatch(getFiles({
      websiteId: product?.offeringId,
      uploaderType: ProductTypes.EXISTING_CONTENT,
      offeringType: product?.pdOfferingType,
    }))
      .then((response) => setExistingContent(response.payload));
  };

  useEffect(() => {
    if (orderId && product) {
      getLandingData();
      getAssets();
    }
  }, [orderId, product]);

  useEffect(() => {
    if (isPunchlist && landingPagesData?.data?.themeId) {
      appDispatch(getThemeById(landingPagesData.data.themeId)).then((res) => setThemeSelection(res.payload.title));
    }
  }, [isPunchlist, landingPagesData]);

  const status = product?.trackerStatus === 'Completed' ? 'Page Live' : product?.trackerStatus;

  const renderProductData = () => (
    <>
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Campaign</Typography>

        <Box>
          {/* Order Type */}
          <Box sx={{ display: 'flex', marginBottom: '10px' }}>
            {editButton(isEditable, () => navigate.to(
              Urls.OrderType,
              product.id,
            ))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Order Type</Typography>
          </Box>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Landing Page Order Type</Typography>
                {displayNotRequiredField('type', mapOrderType[landingPagesData?.data?.orderType])}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Existing Landing Page URLs</Typography>
                <Typography>
                  {displayNotRequiredField('URLs', landingPagesData?.data?.existingLandingPageUrls)}
                </Typography>
              </Box>
            </Box>
          </GridItem>

          {/* Timeline */}
          <Box sx={{ display: 'flex', margin: '10px 0' }}>
            {editButton(isEditable, () => navigate.to(
              Urls.Timeline,
              product.id,
            ))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Timeline</Typography>
          </Box>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Campaign Go-Live Date</Typography>
                <Typography>
                  {displayNotRequiredField('date', formatDate(new Date(landingPagesData?.data?.goLiveDate)))}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Campaign End Date</Typography>
                <Typography>
                  {displayNotRequiredField('date', formatDate(new Date(landingPagesData?.data?.endDate)))}
                </Typography>
              </Box>
            </Box>
          </GridItem>

          {/* Audience */}
          <Box sx={{ display: 'flex', margin: '10px 0' }}>
            {editButton(isEditable, () => navigate.to(
              Urls.Audience,
              product.id,
            ))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Audience</Typography>
          </Box>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Channels to drive traffic</Typography>
                {landingPagesData?.data?.searchEngineMarketing && (
                  <Typography>Search Engine Marketing</Typography>
                )}
                {landingPagesData?.data?.socialAds && (
                  <Typography>Social Ads</Typography>
                )}
                {landingPagesData?.data?.displayAds && (
                  <Typography>Display Ads</Typography>
                )}
                {landingPagesData?.data?.otherChannelsDescription && (
                  <Typography>{landingPagesData.data.otherChannelsDescription}</Typography>
                )}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Campaign Focuses</Typography>
                {landingPagesData?.data?.campaignFocuses?.length
                  ? landingPagesData.data.campaignFocuses.map(focus => <Typography key={focus}>{focus}</Typography>)
                  : <Typography fontWeight="bold">No campaign focuses</Typography>}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Target Audiences</Typography>
                {landingPagesData?.data?.targetGeographies?.length
                  ? landingPagesData.data.targetGeographies
                    .map(focus => <Typography key={focus} sx={{ textTransform: 'capitalize' }}>{focus}</Typography>)
                  : <Typography fontWeight="bold">No targets</Typography>}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Additional Notes</Typography>
                {displayNotRequiredField('additional notes', landingPagesData?.data?.additionalAudienceNotes)}
              </Box>
            </Box>
          </GridItem>

          {/* Goals */}
          <Box sx={{ display: 'flex', margin: '10px 0' }}>
            {editButton(isEditable, () => navigate.to(
              Urls.Goals,
              product.id,
            ))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Goals</Typography>
          </Box>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Primary Conversion Goal</Typography>
                <Typography>
                  {landingPagesData?.data?.formSubmissions && 'Form Submissions'}
                  {landingPagesData?.data?.phoneCalls && 'Phone Calls'}
                  {landingPagesData?.data?.clickThruToWebsite && 'Click Thru to Website'}
                  {landingPagesData?.data?.otherGoals && 'Other'}
                </Typography>
              </Box>
              {landingPagesData?.data?.preferredEmailPrimary && (
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Primary Preferred Email</Typography>
                {displayNotRequiredField('notes', landingPagesData?.data?.preferredEmailPrimary)}
              </Box>
              )}
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Conversion Goal Details</Typography>
                <Typography>{landingPagesData?.data?.conversionGoal}</Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Secondary Conversion Goal</Typography>
                <Typography>
                  {landingPagesData?.data?.secondaryConversionGoal === 'formSubmissions' && 'Form Submissions'}
                  {landingPagesData?.data?.secondaryConversionGoal === 'phoneCalls' && 'Phone Calls'}
                  {landingPagesData?.data?.secondaryConversionGoal === 'clickThruToWebsite' && 'Click Thru to Website'}
                  {landingPagesData?.data?.secondaryConversionGoal === 'otherGoals' && 'Other'}
                </Typography>
              </Box>
              {landingPagesData?.data?.otherGoalsNotes && (
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Other Goals Notes</Typography>
                {displayNotRequiredField('notes', landingPagesData?.data?.otherGoalsNotes)}
              </Box>
              )}
              {landingPagesData?.data?.otherSecondaryGoalNotes && (
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Secondary Other Goals Notes</Typography>
                {displayNotRequiredField('notes', landingPagesData?.data?.otherSecondaryGoalNotes)}
              </Box>
              )}
              {landingPagesData?.data?.preferredEmailSecondary && (
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Secondary Preferred Email</Typography>
                {displayNotRequiredField('notes', landingPagesData?.data?.preferredEmailSecondary)}
              </Box>
              )}
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Additional Notes</Typography>
                {displayNotRequiredField('additional notes', landingPagesData?.data?.additionalGoalsNotes)}
              </Box>
            </Box>
          </GridItem>

          {/* Tracking */}
          <Box sx={{ display: 'flex', margin: '10px 0' }}>
            {editButton(isEditable, () => navigate.to(
              Urls.Tracking,
              product.id,
            ))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Tracking</Typography>
          </Box>
          <GridItem sizes={[6, 6]}>
            <Box sx={{ wordWrap: 'break-word' }}>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Capture Code</Typography>
                {displayNotRequiredField('capture code', landingPagesData?.data?.captureCodeText)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Facebook Pixels</Typography>
                {displayNotRequiredField('facebook pixels', landingPagesData?.data?.facebookPixelsText)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Google Analytics</Typography>
                {displayNotRequiredField('google analytics', landingPagesData?.data?.googleAnalyticsText)}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Other</Typography>
                {displayNotRequiredField('other channels', landingPagesData?.data?.otherChannelsText)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Additional Tracking Notes</Typography>
                {displayNotRequiredField('additional notes', landingPagesData?.data?.additionalTrackingNotes)}
              </Box>
            </Box>
          </GridItem>

        </Box>
      </GridItem>

      {/* Domain */}
      <GridItem sizes={[12, 6, 6]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(
            Urls.LandingDomain,
            product.id,
          ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Domain</Typography>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>Domain Option</Typography>
            <Typography>
              {landingPagesData?.data?.domainSelection !== 'clientProvidedDomain'
                ? 'LocaliQ-Provided Domain'
                : 'Client-Provided Domain'}
            </Typography>
          </Box>
        </Box>
        <Box>
          {landingPagesData?.data?.domainSelection === 'clientProvidedDomain' && (
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary" marginTop={2}>Client-Provided Domain</Typography>
              <Typography>
                {/* eslint-disable-next-line max-len */}
                {`https://${landingPagesData?.data?.subdomain}.${landingPagesData?.data?.primaryDomain}.${landingPagesData?.data?.tld}`}
              </Typography>
            </Box>
          )}
        </Box>
      </GridItem>

      {/* Content */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Content</Typography>

        <Box>
          {/* Page Info */}
          <Box sx={{ display: 'flex', margin: '10px 0' }}>
            {editButton(isEditable, () => navigate.to(
              Urls.Info,
              product.id,
            ))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Page Info</Typography>
          </Box>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Landing Page Name</Typography>
                <Typography>{landingPagesData?.data?.pageName}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Target Messaging</Typography>
                {displayNotRequiredField('target messaging', landingPagesData?.data?.targetMessaging)}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Featured Offers</Typography>
                {displayNotRequiredField('featured offers', landingPagesData?.data?.featuredOffers)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Other Notes</Typography>
                {displayNotRequiredField('other notes', landingPagesData?.data?.otherNotes)}
              </Box>
            </Box>
          </GridItem>

          {/* Content Writing */}
          <Box sx={{ display: 'flex', margin: '10px 0' }}>
            {editButton(isEditable, () => navigate.to(
              Urls.LandingContentWriting,
              product.id,
            ))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Content Writing</Typography>
          </Box>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">New Page Content</Typography>
                <Typography>{landingPagesData?.data?.contentTonesSelection ? 'Yes' : 'No'}</Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Content Tone</Typography>
                {(landingPagesData?.data?.contentTonesSelection && landingPagesData?.data?.contentTones?.length > 0)
                  ? (landingPagesData.data.contentTones
                    .map(opt => <Typography key={opt} sx={{ textTransform: 'capitalize' }}>{opt}</Typography>))
                  : (<Typography fontWeight="bold">No Content Tones</Typography>)}
              </Box>
            </Box>
          </GridItem>

          {/* Existing Content */}
          <Box sx={{ display: 'flex', margin: '10px 0' }}>
            {editButton(isEditable, () => navigate.to(
              Urls.LandingExistingContent,
              product.id,
            ))}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Existing Content</Typography>
          </Box>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Does the client have an existing website?</Typography>
                <Typography>{landingPagesData?.data?.existingWebsiteSelection ? 'Yes' : 'No'}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Existing Website URL</Typography>
                {displayNotRequiredField('information provided', landingPagesData?.data?.existingWebsiteUrl)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Does the client have existing landing pages?</Typography>
                <Typography>{landingPagesData?.data?.existingLandingPagesSelection ? 'Yes' : 'No'}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Existing Landing Page URLs</Typography>
                {(landingPagesData?.data?.urlsToMigrate)
                  ? (
                    <Typography>
                      {landingPagesData.data.urlsToMigrate}
                    </Typography>
                  ) : <Typography fontWeight="bold">No URLs</Typography>}
              </Box>
            </Box>
            <Box>
              <Box>
                <Typography sx={headingStyle} color="primary">Assets</Typography>
                {displayAssets(existingContent, 'Existing')}
              </Box>
            </Box>
          </GridItem>

        </Box>
      </GridItem>

      {/* Design */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Design</Typography>
        <Box>
          {/* Theme Selection */}
          {(isPunchlist && themeSelection) && (
            <>
              <Box sx={{ display: 'flex', margin: '10px 0' }}>
                {editButton(isEditable, () => navigate.to(
                  Urls.Theme,
                  product.id,
                ))}
                <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Theme</Typography>
              </Box>
              <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
                <Box>
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">Theme</Typography>
                    <Typography>{themeSelection}</Typography>
                  </Box>
                </Box>
                <Box />
              </GridItem>
            </>
          )}

          {/* Stock Assets */}
          <Box sx={{ display: 'flex', margin: '10px 0' }}>
            {editButton(isEditable, async () => {
              await dispatch(setFiles([]));
              navigate.to(
                Urls.LandingStockAssets,
                product.id,
              );
            })}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Stock Assets</Typography>
          </Box>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary" marginTop={2}>Stock Assets</Typography>
                {displayAssets(stockAssets, 'Stock')}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary" marginTop={2}>Existing Assets</Typography>
                {displayAssets(existingAssets, 'Existing')}
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>
    </>
  );

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Status Tracker */}
      {showTracker && (
      <GridItem sizes={[12, 12]} marginBottom={8} sx={{ padding: '40px 0 0', borderBottom: '1px solid #000000' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => {})}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Status</Typography>
        </Box>
        <Box>
          <OrderTracker
            key={product?.id}
            status={status}
            productName={product?.pdOfferingType}
            product={product}
          />
        </Box>
      </GridItem>
      )}

      {GeneralQuestionsSection(
        product.offeringType === 'ProductsData' ? product.pdOfferingType : product.offeringType,
        product.offeringId,
        product.id,
        navigate,
        isEditable,
      )}

      {!product.isRebuild && renderProductData()}

      {/* Actions items */}
      {showTracker && ActionItems(true, product?.id, false, openBusinessAndSalesAgent)}
    </Grid>
  );
}
