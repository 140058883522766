import {Urls} from 'navigation/Urls';
import useProduct from 'hooks/useProduct';
import {StatusType} from 'models/StatusType';

import ContentLoader from 'components/contentLoader/ContentLoader';
import {GetYouTubeResponse, YouTubeRequestContentType} from "models/YouTubeType";
import ProxyProvisioningPageForm from "pages/youtube/proxy-provisioning/ProxyProvisioningPageForm";

export default function ProxyProvisioning() {
  const {product, onSubmit, onBack} = useProduct<YouTubeRequestContentType,
      StatusType<GetYouTubeResponse>>(Urls.YouTubeProxyProvisioning);

  return (
      <ContentLoader status={product.status} message={product.message}>
        <ProxyProvisioningPageForm
            product={product.content.data}
            onSubmit={onSubmit}
            onBack={onBack}
        />
      </ContentLoader>
  );
}
