export const serializeDate = (date: Date): string | null =>
  (date ? `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}` : null);

export const deserializeDate = (date: string): Date | null => {
  if (!date) {
    return null;
  }

  const dateParts = date.split('/').map(num => parseInt(num, 10));
  return new Date(dateParts[2], dateParts[0] - 1, dateParts[1]);
};
