import { ComponentProps } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Box, Grid, Typography,
} from '@mui/material';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import PiOWebsiteType from 'models/PiQWebsiteType';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { t } from 'i18next';
import { inputStyles } from 'pages/seo/details/DetailsPageForm';
import { useSelector } from 'react-redux';
import ErrorText from 'components/errorText/ErrorText';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import {
  ProductTypes,
  selectFilesUploaded,
} from 'services/fileUploaderSlice';
import CoFileUploader from 'components/fileUploader/CoFileUploader';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import useUpdateAssets from 'hooks/useUpdateAssets';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import schema from './PiQBackgroundImage.schema';

interface Props {
  product: PiOWebsiteType;
  onSubmit: (data :PiQBackgroundImageProps) => void;
  onBack: () => void;
}

export type PiQBackgroundImageProps = {
  backgroundImagePreference: string;
  backgroundImageAsset: string;
};

export default function PiQBackgroundImageForm(props: Props) {
  const { product } = props;
  const websiteId = useSelector(selectActiveProductId);
  const files = useSelector(selectFilesUploaded);

  const methods = useForm<PiQBackgroundImageProps>({
    mode: 'all',
    defaultValues: {
      backgroundImagePreference: product?.backgroundImagePreference || '',
      backgroundImageAsset: product?.backgroundImageAsset || '',
    },
  });

  const {
    watch,
    handleSubmit,
    register,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful, errors,
    },
  } = methods;

  const hasUploads = files?.content?.filter(file => file.uploaderType === ProductTypes.PIQ_DOCUMENT).length > 0;
  const skipAndContinue = ((watch('backgroundImagePreference')) === '' && !hasUploads);

  const uploaderType = ProductTypes.PIQ_DOCUMENT;
  const megabyte = 1_000_000;
  const offeringType = useSelector(selectActiveOfferingType);
  const productType :ProductTypes = ProductTypes.PIQ_DOCUMENT;

  const fileUploaderOptions :Pick<ComponentProps<typeof CoFileUploader>,
  'fileUploaderOptions'>['fileUploaderOptions'] = {
    name: 'uploadedFiles',
    uploaderType,
    showRecommendedImageText: true,
    recommendedImgText: 'Should not exceed 1600 x 900px',
    showAcceptedFileTypesText: true,
    maxFileSize: 256 * megabyte,
    supportingText: '',
    toolTiptext: '',
    label: null,
    imageTypes: ['.png', '.jpeg', '.jpg', '.gif', '.CR2', '.DNG'],
    videoTypes: [],
    documentTypes: [],
    size: 'md',
    directory: uploaderType,
    showComments: true,
    showCheckboxes: true,
  };

  const { triggerAllUpdates } = useUpdateAssets(
    watch,
    fileUploaderOptions.name,
  );

  const onSubmit = async (data: PiQBackgroundImageProps) => {
    await triggerAllUpdates();
    await props.onSubmit(data);
  };

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={props.onBack}
      header={t('pages.piq.backgroundImage.header')}
      onContinue={handleSubmit(onSubmit)}
      skipAndContinue={skipAndContinue}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="backgroundImageForm"
            component="form"
            sx={{ display: 'flex' }}
          >
            <Grid container xs={12} sx={{ ...inputStyles }}>
              <Grid item mb={3} xs={9}>
                <Grid item mb={1}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}
                    color={errors.backgroundImagePreference ? 'error.main' : 'common.black'}
                  >
                    Background Image Preference
                  </Typography>
                </Grid>
                <NeFormTextArea
                  {...register('backgroundImagePreference')}
                  id="backgroundImagePreference"
                  name="backgroundImagePreference"
                  fullWidth
                  rowCount={2}
                  fontLabelWeight="600"
                />
                <ErrorText
                  hasError={!!errors.backgroundImagePreference}
                  errorText="Bad file format -- please consult list below."
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item mb={1}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}>
                    Background Image Asset
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  {(productType && websiteId) && (
                    <CoFileUploader
                      schema={schema}
                      productType={productType}
                      offeringType={offeringType as WebsiteOfferingTypes}
                      showFileUploader
                      productId={websiteId?.toString()}
                      fileUploaderOptions={fileUploaderOptions}
                      containerStyles={{
                        '& .dropzone': {
                          padding: '0 10px',
                          justifyContent: 'flex-start',
                          '& > p': {
                            marginLeft: '20px',
                          },
                        },
                      }}
                    />
                  )}
                </Grid>
              </Grid>
              <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
              />
            </Grid>
          </Box>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
