import * as yup from 'yup';

const schema = {
  searchEngineMarketing: yup.boolean().optional(),
  socialAds: yup.boolean().optional(),
  displayAds: yup.boolean().optional(),
  otherChannels: yup.boolean().optional(),
  otherChannelsDescription: yup.string().required('Please enter other channel details.'),
  campaignFocuses: yup.array().min(1, 'Please enter campaign focuses.')
    .max(6, 'Please review and only add up to six items to continue.'),
  targetGeographies: yup.array().min(1, 'Please enter target audience.')
    .max(6, 'Please review and only add up to six items to continue.'),
  additionalAudienceNotes: yup.string().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
