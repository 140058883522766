import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearAdditionalFeaturesDesignCompleted, getWebsite,
  putWebsiteAdditionalFeatures,
  selectAdditionalFeaturesDesignCompleted,
  selectWebsites,
} from 'services/websitesSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { Urls } from 'navigation/Urls';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import AdditionalFeaturesDesignForm from './AdditionalFeaturesDesignForm';

export default function AdditionalFeaturesDesign() {
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const history: any = useLocation();
  const website = useSelector(selectWebsites);
  const pageCompleted = useSelector(selectAdditionalFeaturesDesignCompleted);
  const dispatch = useDispatch();

  const navigate = useNavigationHandler();
  const topNav = useTopnavMenu(Urls.AdditionalFeaturesDesign);

  const onBack = () => {
    navigate.to(topNav.previousStep());
  };

  const onSubmit = (content) => {
    dispatch(putWebsiteAdditionalFeatures({ websiteId: website.content.id, content, offeringType }));
  };

  // after we receive the completed flag from redux, we move to the next page and clear the flag
  useEffect(() => {
    if (pageCompleted) {
      if (history.state?.previousPath.includes('overview')) {
        navigate.to(Urls.Overview);
      } else navigate.to(topNav.nextStep());
      dispatch(clearAdditionalFeaturesDesignCompleted());
    }
  }, [pageCompleted]);

  useEffect(() => {
    if (!website?.content?.id) dispatch(getWebsite({ websiteId, offeringType }));
  }, [websiteId]);

  return (
    <ContentLoader status={website.status} message={website.message}>
      <AdditionalFeaturesDesignForm onBack={onBack} onSubmit={onSubmit} website={website.content} />
    </ContentLoader>
  );
}
