import {
  Children, useEffect, useMemo, useState,
} from 'react';
import {
  FormControlLabel, Grid, Radio, RadioGroup, Typography,
} from '@mui/material';
import { IQLoadingSpinner, ValidationProvider } from '@gannettdigital/shared-react-components';
import IQFileUploader from 'components/fileUploader/NeFileUploader';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { inputStyles } from 'pages/seo/details/DetailsPageForm';
import { FormProvider, useForm } from 'react-hook-form';
import i18n from 'i18n/i18n';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import { getBusinessLocations, selectBusinessLocations, selectProviders } from 'services/businessLocationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrdersContent } from 'services/ordersSlice';
import { RequirementBox } from 'pages/websites/custom/form/CustomWebsiteForm';
import GridItem from 'components/grid/GridItem';
import { tooltip } from 'pages/general-questions/form-sections/Tracking';
import schemaValidate from 'components/schemaValidate';
import {
  ProductTypes, deleteFile, getFiles, selectFilesUploaded, uploadFiles,
} from 'services/fileUploaderSlice';
import CoFileList from 'components/fileList/CoFileList';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { Trans } from 'react-i18next';
import ErrorText from 'components/errorText/ErrorText';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import schema from './SMMStrategyPage.schema';
import { campaignGoalsValues } from './SMMSolutionStrategyConstants';
import { yesNoOptions } from '../details/SMMDetailsPageConstants';

const { t } = i18n;

interface Props {
  product: any; // product type to be created
  onSubmit: (data) => void;
  onBack: () => void;
}

export default function SmmStrategyPageForm(props: Props) {
  const { product } = props;
  const dispatch = useDispatch();
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const { businessId } = useSelector(selectOrdersContent);
  const locations = useSelector(selectBusinessLocations);
  const providers = useSelector(selectProviders);
  const filesUploaded = useSelector(selectFilesUploaded);
  const [customSow, setCustomSow] = useState(product?.customSow || '');
  const [fileUploadedError, setFileUploadedError] = useState('');
  const [uploaderDirty, setUploaderDirty] = useState(false);
  const isCustom = offeringType === 'smm-custom';

  const uploaderType = ProductTypes.SOW_DOCUMENT;
  const megabyte = 1_000_000;
  const defaultFileSize = 256 * megabyte;

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      customSow: product?.customSow || '',
      brandAwareness: product?.brandAwareness || false,
      activeDigitalPresence: product?.activeDigitalPresence || false,
      communityEngagement: product?.communityEngagement || false,
      customerRetention: product?.customerRetention || false,
      other: product?.other || false,
      otherGoals: product?.otherGoals || '',
      competitorSocial: product?.competitorSocial || ' ',
      clientSchedule: product?.clientSchedule || '',
      topicsToFocus: product?.topicsToFocus || '',
      topicsToAvoid: product?.topicsToAvoid || '',
      additionalNotes: product?.additionalNotes || '',
    },
  });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    formState: {
      errors,
      isValid,
      isDirty,
      isSubmitting,
      isSubmitSuccessful,
    },
  } = methods;

  const values = useMemo(() => getValues(), [watch()]);

  useEffect(() => {
    if (!values.other) setValue('otherGoals', '');
  }, [values.other]);

  useEffect(() => {
    if (businessId) dispatch(getBusinessLocations(businessId));
  }, [businessId]);

  const onUpdate = (newFiles) => {
    dispatch(uploadFiles({
      websiteId, files: newFiles, uploaderType, offeringType,
    }));
  };

  const handleUploaderError = () => {
    setFileUploadedError(filesUploaded.content.length === 0 ? 'Please upload the signed Custom SOW to continue.' : '');
  };

  const onDelete = async (file) => {
    await dispatch(deleteFile({ websiteId, file }));
    setUploaderDirty(true);
  };

  useEffect(() => {
    if (uploaderDirty) handleUploaderError();
  }, [uploaderDirty]);

  useEffect(() => {
    dispatch(getFiles({ websiteId, uploaderType, offeringType }));
  }, [websiteId]);

  const prepopulateFields = (listings) => {
    const { competitors } = listings[listings.length - 1];
    if (!('competitorSocial' in product)) setValue('competitorSocial', competitors.map(comp => comp.name).join('\n'));
  };

  useEffect(() => {
    if (locations.length > 0) prepopulateFields(locations);
    else if (providers.length > 0) prepopulateFields(providers);
  }, [locations, providers]);

  const handleFormSubmit = (data) => props.onSubmit(data);

  const hasUploadedFiles = filesUploaded.content.length > 0;

  // eslint-disable-next-line consistent-return
  const getTooltipForNoOption = (option) => {
    if (option.value === 'no') {
      return tooltip(t('pages.smm.solutionStrategy.customSowNoTooltip'), <InfoOutlinedIcon />);
    }
  };

  const disableContinue = !isValid || (isCustom && !hasUploadedFiles);

  return (
    <DefaultPageLayout
      disableContinue={disableContinue}
      onBack={props.onBack}
      header={t('pages.smm.solutionStrategy.title')}
      description={(
        <Trans
          i18nKey="pages.smm.solutionStrategy.description"
          components={{ br: <br /> }}
        />
      )}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container xs={12} sx={{ ...inputStyles }}>
            {isCustom
            && (
            <>
              <Grid item mb={3} xs={12}>
                <Grid mb={1}>
                  <Typography variant="subtitle2" component="h3" fontSize={16}>
                    {t('pages.smm.solutionStrategy.customSowLabel')}
                    {tooltip(t('pages.smm.solutionStrategy.customSowTooltip'), <HelpOutlineOutlinedIcon />)}
                  </Typography>
                  <RadioGroup
                    aria-labelledby="customsow"
                    name="customSow"
                    value={customSow}
                    onChange={(e) => setCustomSow(e.target.value)}
                  >
                    <GridItem sizes={[1, 2]} marginBottom={0}>
                      {yesNoOptions.map(option => (
                        <FormControlLabel
                          key={option.value}
                          {...register('customSow', {
                            validate: (value) => schemaValidate(value, 'customSow', schema),
                          })}
                          value={option.value}
                          control={<Radio />}
                          label={(
                            <Typography variant="body1">
                              {option.label}
                              {getTooltipForNoOption(option)}
                            </Typography>
                        )}
                        />
                      ))}
                    </GridItem>
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid item xs={9} sx={{
                '& > div > div': { padding: 0, display: 'flex', justifyContent: 'flex-start' },
                '& > div > div > *': { marginLeft: '10px' },
              }}
              >
                <IQFileUploader
                  showRecommendedImageText={false}
                  showAcceptedFileTypesText
                  required
                  name="uploads" maxFileSize={defaultFileSize}
                  onSuccess={onUpdate}
                  label="Upload Custom SOW"
                  supportingText=""
                  totalAllowedFiles={1}
                  imageTypes={[]}
                  videoTypes={[]}
                  documentTypes={['.pdf', '.docx']}
                  size="md"
                  currentUploadedFiles={filesUploaded.content.length}
                  onBlur={() => handleUploaderError()}
                  requiredError={fileUploadedError}
                />
              </Grid>
              <Grid item xs={9} mb={1}
                mt={0}
              >
                {filesUploaded.status === 'loading' && <IQLoadingSpinner size={40} />}
                {filesUploaded.content.length
                  ? <CoFileList files={filesUploaded.content} onDelete={onDelete} />
                  : null}
              </Grid>
            </>
            )}
            <Grid item xs={11} mb={3}
              sx={{ marginTop: '16px' }}
            >
              <Typography variant="subtitle2" component="h3" fontSize={16}
                mb={1}
              >
                {t('pages.smm.solutionStrategy.campaignGoals')}
              </Typography>
              <Grid container>
                {Children.toArray(campaignGoalsValues.map(req => (
                  <Grid item xs={5.5}
                    paddingRight={2}
                    marginBottom={2}
                  >
                    <RequirementBox
                      title={req.title}
                      description={req.description ? req.description : null}
                      field={req.field}
                      checked={values[req.field]}
                    />
                  </Grid>
                )))}
              </Grid>
              {values.other && (
                <Grid item xs={11} mb={3}>
                  <NeFormTextArea
                    {...register('otherGoals')}
                    id="otherGoals"
                    name="otherGoals"
                    labelText={t('pages.smm.solutionStrategy.otherCampaignGoals')}
                    fullWidth
                    rowCount={2}
                    fontLabelWeight="600"
                    required
                  />
                  <ErrorText
                    hasError={!!errors.otherGoals}
                    errorText={t('pages.smm.solutionStrategy.otherGoalsError')}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={10} mb={3}>
              <NeFormTextArea
                {...register('competitorSocial')}
                id="competitorSocial"
                name="competitorSocial"
                labelText={t('pages.smm.solutionStrategy.competitorSocialLabel')}
                fullWidth
                rowCount={2}
                fontLabelWeight="600"
              />
            </Grid>
            <Grid item xs={10} mb={3}>
              <NeFormTextArea
                {...register('clientSchedule')}
                id="clientSchedule"
                name="clientSchedule"
                labelText={t('pages.smm.solutionStrategy.clientScheduleLabel')}
                fullWidth
                rowCount={2}
                fontLabelWeight="600"
              />
            </Grid>
            <Grid item xs={10} mb={3}>
              <NeFormTextArea
                {...register('topicsToFocus')}
                id="topicsToFocus"
                name="topicsToFocus"
                labelText={t('pages.smm.solutionStrategy.topicsToFocusLabel')}
                fullWidth
                rowCount={2}
                fontLabelWeight="600"
              />
            </Grid>
            <Grid item xs={10} mb={3}>
              <NeFormTextArea
                {...register('topicsToAvoid')}
                id="topicsToAvoid"
                name="topicsToAvoid"
                labelText={t('pages.smm.solutionStrategy.topicsToAvoid')}
                fullWidth
                rowCount={2}
                fontLabelWeight="600"
              />
            </Grid>
            <Grid item xs={10} mb={3}>
              <NeFormTextArea
                {...register('additionalNotes')}
                id="additionalNotes"
                name="additionalNotes"
                labelText={t('pages.smm.solutionStrategy.additionalNotesLabel')}
                fullWidth
                rowCount={2}
                fontLabelWeight="600"
              />
            </Grid>
          </Grid>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
