import { Urls } from 'navigation/Urls';
import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import OrderTypeForm from './OrderTypeForm';

export default function OrderType() {
  const { product, onBack, onSubmit } = useProduct(Urls.OrderType);

  return (
    <ContentLoader status={'product.status'} message={product.message}>
      <OrderTypeForm onSubmit={onSubmit} onBack={onBack} product={product.content?.data} />
    </ContentLoader>
  );
}
