import {useTheme} from '@emotion/react';
import {
  IQButtonLink,
  IQCheckbox,
  IQDatePicker, IQSwitch, IQThemeV2,
} from '@gannettdigital/shared-react-components';
import {Button, Typography} from '@mui/material';
import {useEffect, useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import GridItem from 'components/grid/GridItem';
import ErrorText from 'components/errorText/ErrorText';
import NeTimePicker from "pages/locations/hours/TimePicker";
import moment from "moment/moment";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

interface SpecialHoursPickerProps {
  rowNumber: number
  onRemoveDay: (index: number) => void
}

export default function SpecialHoursPicker({rowNumber, onRemoveDay}: SpecialHoursPickerProps) {
  const {t} = useTranslation();
  const theme = useTheme();
  const {
    getValues, setValue, trigger, watch, setError, formState: {errors},
  } = useFormContext();
  const [addIconOn, setAddIconOn] = useState(false);

  const closed = useMemo(
      () => getValues(`specialHours.${rowNumber}.closed`),
      [watch(`specialHours.${rowNumber}.closed`)],
  );

  const allDay = useMemo(
      () => getValues(`specialHours.${rowNumber}.allDay`),
      [watch(`specialHours.${rowNumber}.allDay`)],
  );

  useEffect(() => {
    if (allDay) {
      setValue(`specialHours.${rowNumber}.specialFromHour1`, '00:00');
      setValue(`specialHours.${rowNumber}.specialToHour1`, '00:00');
      setValue(`specialHours.${rowNumber}.specialFromHour2`, '');
      setValue(`specialHours.${rowNumber}.specialToHour2`, '');
      setValue(`specialHours.${rowNumber}.secondTimeSlot`, false);
      trigger('specialHours');
    }
  }, [allDay]);

  const specialFromHour1Val: any = watch(`specialHours.${rowNumber}.specialFromHour1`);
  const specialToHour1Val: any = watch(`specialHours.${rowNumber}.specialToHour1`);
  const specialFromHour2Val: any = watch(`specialHours.${rowNumber}.specialFromHour2`);
  const specialToHour2Val: any = watch(`specialHours.${rowNumber}.specialToHour2`);

  useEffect(() => {
    if (specialFromHour2Val !== '' && specialToHour2Val !== '') {
      setAddIconOn(true)
    }
    if (specialFromHour1Val === "NaN:NaN") {
      setValue(`specialHours.${rowNumber}.specialFromHour1`, '');
    }
    if (specialToHour1Val === "NaN:NaN") {
      setValue(`specialHours.${rowNumber}.specialToHour1`, '');
    }
    if (specialFromHour2Val === "NaN:NaN") {
      setValue(`specialHours.${rowNumber}.specialFromHour2`, '');
    }
    if (specialToHour2Val === "NaN:NaN") {
      setValue(`specialHours.${rowNumber}.specialToHour2`, '');
    }
  }, [specialFromHour1Val, specialToHour1Val, specialFromHour2Val, specialToHour2Val]);

  const momentToNumber = (value) => {
    const momentHour = moment(value).hours().toString().length === 1
        ? `0${moment(value).hours()}`
        : moment(value).hours().toString();
    const momentMinutes = moment(value).minutes().toString().length === 1
        ? `0${moment(value).minutes()}`
        : moment(value).minutes().toString();
    const convertedValue = `${momentHour}:${momentMinutes}`;
    return convertedValue;
  };

  const convertTimeStringToSeconds = (str: string) => {
    if (str) {
      const [hours, minutes] = str.split(':');
      return parseInt(hours) * 60 + parseInt(minutes);
    }
    return 0;
  };

  const handleChange = (value: any, type: any) => {
    setValue(`specialHours.${rowNumber}.${type}`, momentToNumber(value));
    trigger('specialHours');
  }

  const onRemoveHour = () => {
    trigger('specialHours');
    setValue(`specialHours.${rowNumber}.specialFromHour2`, '');
    setValue(`specialHours.${rowNumber}.specialToHour2`, '');
  }

  const setGridStyle = () => {
    if (allDay) {
      return [3, 1, 2, 2]
    } else if (!closed) {
      return [3, 1, 1]
    } else {
      return [3, 1.5, 5, 2, 0.5]
    }
  }

  const onClickAddHandle = () => {
    setAddIconOn(true);
    setValue(`specialHours.${rowNumber}.secondTimeSlot`, true);
  }

  const onClickRemoveHandle = () => {
    onRemoveHour();
    setAddIconOn(false);
    setValue(`specialHours.${rowNumber}.secondTimeSlot`, false);
    trigger('specialHours');
  }

  return (
      <GridItem marginBottom={0} sizes={setGridStyle()} key={rowNumber}
                sx={{
                  marginBottom: '1.5rem',
                  '& input.rc-time-picker-input': {
                    padding: '8px 16px',
                    height: '40px',
                    fontSize: 'inherit',
                    fontFamily: 'Unify Sans',
                    borderRadius: '2px',
                    border: '1px solid #292928',
                    color: '#000000',
                    textTransform: 'uppercase',
                  },
                  '& input.rc-time-picker-input::placeholder': {
                    textTransform: 'none',
                  },
                  '& .rc-time-picker svg': {
                    position: 'absolute',
                    right: '12px',
                    top: '8px',
                  },
                }}
      >
        <GridItem marginBottom={0}>
          <IQDatePicker
              id={`specialHours.${rowNumber}.dateFrom`}
              name={`specialHours.${rowNumber}.dateFrom`}
              labelText=""
              fullWidth
              theme={IQThemeV2}
              hasError={!!errors?.specialHours?.[rowNumber]?.dateFrom}
          />
          <ErrorText
              hasError={!!errors?.specialHours?.[rowNumber]?.dateFrom}
              errorText={errors?.specialHours?.[rowNumber]?.dateFrom?.message}
          />
        </GridItem>
        <IQSwitch
            label={closed
                ? t('pages.business.location.hours.hoursOfOperation.open')
                : t('pages.business.location.hours.hoursOfOperation.closed')}
            name={`specialHours.${rowNumber}.closed`}
            unboxed
        />
        {
            !allDay && closed &&
            <>
              <GridItem marginBottom={0} sizes={[5.2, 0.5, 5.2, 0.5]}>
                <NeTimePicker
                    name={`specialHours.${rowNumber}.specialFromHour1`}
                    placeholder={'hh:mm aa'}
                    defaultValue={null}
                    onChange={value => handleChange(value, 'specialFromHour1')}
                    hasError={!!errors?.specialHours?.[rowNumber]?.specialFromHour1}
                    errorText={errors?.specialHours?.[rowNumber]?.specialFromHour1?.message}
                />
                <Typography sx={{textAlign: 'center'}}>-</Typography>
                <NeTimePicker
                    name={`specialHours.${rowNumber}.specialToHour1`}
                    placeholder={'hh:mm aa'}
                    defaultValue={null}
                    onChange={value => handleChange(value, 'specialToHour1')}
                    hasError={!!errors?.specialHours?.[rowNumber]?.specialToHour1}
                    errorText={errors?.specialHours?.[rowNumber]?.specialToHour1?.message}
                />
                {
                    !addIconOn &&
                    <IQButtonLink
                        startIcon={<AddCircleOutlineIcon/>}
                        onClick={onClickAddHandle}
                    >
                      {''}
                    </IQButtonLink>
                }
              </GridItem>
              {
                  addIconOn &&
                  <GridItem marginBottom={0} sizes={[5.2, 0.5, 5.2, 0.5]} marginTop={2}>
                    <NeTimePicker
                        name={`specialHours.${rowNumber}.specialFromHour2`}
                        placeholder={'hh:mm aa'}
                        defaultValue={null}
                        onChange={value => handleChange(value, 'specialFromHour2')}
                        hasError={!!errors?.specialHours?.[rowNumber]?.specialFromHour2}
                        errorText={errors?.specialHours?.[rowNumber]?.specialFromHour2?.message}
                    />
                    <Typography sx={{textAlign: 'center'}}>-</Typography>
                    <NeTimePicker
                        name={`specialHours.${rowNumber}.specialToHour2`}
                        placeholder={'hh:mm aa'}
                        defaultValue={null}
                        onChange={value => handleChange(value, 'specialToHour2')}
                        hasError={!!errors?.specialHours?.[rowNumber]?.specialToHour2}
                        errorText={errors?.specialHours?.[rowNumber]?.specialToHour2?.message}
                    />
                    <Button
                        onClick={onClickRemoveHandle}
                        sx={{color: theme.palette.primary.main, marginTop: '-5px'}}
                    >
                      <RemoveCircleOutlineIcon/>
                    </Button>
                  </GridItem>
              }
            </>
        }
        {
            closed &&
            <GridItem marginBottom={0}
                      sx={{paddingLeft: '12px', paddingTop: '8px', marginLeft: '15px'}}>
              <IQCheckbox
                  label={t('pages.business.location.hours.hoursOfOperation.24Hrs')}
                  field={`specialHours.${rowNumber}.allDay`}
                  name={`specialHours.${rowNumber}.allDay`}
              />
            </GridItem>
        }
        <Button
            onClick={() => onRemoveDay(rowNumber)}
            sx={{color: theme.palette.primary.main, alignSelf: 'left'}}
        >
          <DeleteIcon/>
        </Button>
      </GridItem>
  );
}
