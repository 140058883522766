/* eslint-disable import/no-extraneous-dependencies */
import {RJSFSchema} from "@rjsf/utils";
import i18n from "i18n/i18n";

const {t} = i18n;

const validateRequiredFormField = (formData, fieldName, errors) => {
  if (formData?.[fieldName] === '') {
    errors[fieldName].addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
};

const radiusOptions = () => {
  let x = [];
  let prev = 10;
  while (prev <= 100) {
    x.push(prev);
    prev = prev + 5;
  }
  return x.map((y) => y.toString());
};

export const TargetingSchema: RJSFSchema | any = (data) => ({
  type: "object",
  title: t("pages.socialAds.solutionStrategy.targeting.title"),
  required: ["targetingType"],
  properties: {
    targetingType: {
      type: "string",
      title: t("pages.socialAds.solutionStrategy.targeting.targetingTypeLabel"),
      default: data?.targetingType || "",
      enum: [
        '',
        "Radius",
        "City (not available in CAN)",
        "DMA",
        "National",
        "Multi-Radius",
        "ZIP/Postal Code",
        "State",
      ],
    },
  },
  dependencies: {
    targetingType: {
      oneOf: [
        {
          properties: {
            targetingType: {
              enum: ["City (not available in CAN)", "ZIP/Postal Code", "State"],
            },
            additonalTargetingDetails: {
              type: "string",
              title: t("pages.socialAds.solutionStrategy.targeting.additionalTargetingDetailsLabel"),
              default: data?.additonalTargetingDetails ?? "",
            }
          },
        },
        {
          properties: {
            targetingType: {
              enum: ["Radius", "Multi-Radius"],
            },
            additonalTargetingDetails: {
              type: "string",
              title: t("pages.socialAds.solutionStrategy.targeting.additionalTargetingDetailsLabel"),
              default: data?.additonalTargetingDetails ?? "",
            },
            advertiserSalesforceBusinessAddress: {
              type: "string",
              title: t("pages.socialAds.solutionStrategy.targeting.advertiserSalesforceBusinessAddressLabel"),
              default: '',
              items: {
                enum: [
                  t('pages.generic-translation.yesOption'),
                  t('pages.generic-translation.noOption'),
                ],
              }
            },
            radiusMileOrKilometerDistance: {
              type: "string",
              title: t("pages.socialAds.solutionStrategy.targeting.radiusMileOrKilometerDistanceLabel"),
              default: data?.radiusMileOrKilometerDistance || '',
              enum: [
                '',
                ...radiusOptions()
              ],
            },
          },
          required: ["radiusMileOrKilometerDistance", "advertiserSalesforceBusinessAddress"],
        },
        {
          properties: {
            targetingType: {
              enum: ["DMA"],
            },
            areasHeading: {
              type: "string",
              title: t("pages.socialAds.solutionStrategy.targeting.areasHeadingabel"),
            },
            areasToIncludeThatAreNotInTargetArea: {
              type: "string",
              title: t("pages.socialAds.solutionStrategy.targeting.areasToIncludeThatAreNotInTargetAreaLabel"),
              default: data?.areasToIncludeThatAreNotInTargetArea || "",
            },
            areasToExcludeThatAreNotInTargetArea: {
              type: "string",
              title: t("pages.socialAds.solutionStrategy.targeting.areasToExcludeThatAreNotInTargetAreaLabel"),
              default: data?.areasToExcludeThatAreNotInTargetArea || "",
            },
          },
        },
      ],
    },
    advertiserSalesforceBusinessAddress: {
      oneOf: [
        {
          properties: {
            advertiserSalesforceBusinessAddress: {
              enum: ["No"],
            },
            businessAddressHeading: {
              type: "string",
              title: t("pages.socialAds.solutionStrategy.targeting.businessAddressHeadingLabel"),
            },
            businessAddress: {
              type: "string",
              title: t("pages.socialAds.solutionStrategy.targeting.businessAddressLabel"),
              default: '',
            },
            areasHeading: {
              type: "string",
              title: t("pages.socialAds.solutionStrategy.targeting.areasHeadingabel"),
            },
            areasToIncludeThatAreNotInTargetArea: {
              type: "string",
              title: t("pages.socialAds.solutionStrategy.targeting.areasToIncludeThatAreNotInTargetAreaLabel"),
              default: data?.areasToIncludeThatAreNotInTargetArea || "",
            },
            areasToExcludeThatAreNotInTargetArea: {
              type: "string",
              title: t("pages.socialAds.solutionStrategy.targeting.areasToExcludeThatAreNotInTargetAreaLabel"),
              default: data?.areasToExcludeThatAreNotInTargetArea || "",
            },
          },
          required: ["businessAddress"],
        },
      ],
    },
  },
});

const radioFieldKeys = ["advertiserSalesforceBusinessAddress"];

const radioFieldFields = radioFieldKeys.reduce(
    (o, key) => ({
      ...o,
      [key]: {"ui:widget": "radioButton"},
    }),
    {}
);

const dropdownFieldKeys = ["targetingType", "radiusMileOrKilometerDistance"];

const dropdownFields = dropdownFieldKeys.reduce(
    (o, key) => ({
      ...o,
      [key]: {"ui:widget": "dropdown"},
    }),
    {}
);

const inputFieldKeys = [
  "additonalTargetingDetails",
  "areasToIncludeThatAreNotInTargetArea",
  "areasToExcludeThatAreNotInTargetArea",
];

const inputFields = inputFieldKeys.reduce(
    (o, key) => ({
      ...o,
      [key]: {"ui:widget": "textInputField"},
    }),
    {}
);

export const TargetingUISchema = {
  "ui:order": [
    "targetingType",
    "additonalTargetingDetails",
    "radiusMileOrKilometerDistance",
    "advertiserSalesforceBusinessAddress",
    "businessAddressHeading",
    "businessAddress",
    "areasHeading",
    "areasToIncludeThatAreNotInTargetArea",
    "areasToExcludeThatAreNotInTargetArea",
  ],
  businessAddressHeading: {"ui:widget": "headingTextLabel"},
  businessAddress: {"ui:widget": "addressField"},
  areasHeading: {"ui:widget": "headingTextLabel"},
  ...dropdownFields,
  ...inputFields,
  ...radioFieldFields,
};

export const TargetingCustomValidate = (formData, errors, uiSchema) => {
  validateRequiredFormField(formData, 'targetingType', errors);
  if (formData?.targetingType == "Radius" || formData?.targetingType == "Multi-Radius") {
    validateRequiredFormField(formData, 'advertiserSalesforceBusinessAddress', errors);
    validateRequiredFormField(formData, 'radiusMileOrKilometerDistance', errors);
  }
  if (formData?.advertiserSalesforceBusinessAddress === "No") {
    if (formData?.businessAddress?.trim().length < 1) {
      errors.addError(true);
      errors.businessAddress.addError(t("pages.socialAds.solutionStrategy.targeting.businessAddressError"));
    }
  }
  return errors;
};
