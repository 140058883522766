import { createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import AxiosAPI from './common/axios';
import { thunkBuilder } from './common/functions';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { RootState } from './redux/store';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface recentCarbonCommandsState {
  recentCarbonCommandsData: StatusType<any[]>
}

const initialState: recentCarbonCommandsState = {
  recentCarbonCommandsData: {
    status: 'idle',
    message: '',
    content: [],
  },
};

/*
export interface CarbonRequest {
  id: number
  userId: string
  siteDomain: string
  command: string
  response: string
  status: string
  retries: number
}
*/

export const getRecentCarbonCommands = createAsyncThunkCatchErrors(
  'recentCarbonCommands/get',
  async () => {
    const response = await AxiosAPI.get('/maintenance/carbon_commands');
    return response.data;
  },
);

const recentCarbonCommandsSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder)
      .addCase(getRecentCarbonCommands, 'recentCarbonCommandsData');
  },
);

/* export const addNewCommand = createAsyncThunkCatchErrors(
  'maintenance/addCarbonCommand',
  async (request: CarbonRequest) => {
    console.log('adding new command: ', request);
    const response = await AxiosAPI.post('/maintenance/carbon_commands', request);
    return response.data;
  },
);
*/

// eslint-disable-next-line max-len
export const selectRecentCarbonCommands = (state: RootState) => state.recentCarbonCommands.recentCarbonCommandsData.content;

export default recentCarbonCommandsSlice;
