/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import AxiosAPI from './common/axios';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { thunkBuilder } from './common/functions';

export interface ChatLocationsState {
  locations: StatusType<any>;
}

const initialState: ChatLocationsState = {
  locations: {
    status: 'idle',
    message: '',
    content: {},
  },
};

export const getBusinessLocationsForChat = createAsyncThunkCatchErrors(
  'businessLocations/get',
  async ({ chatType, orderItemId }: { chatType: string, orderItemId: string }) => {
    const response = await AxiosAPI.get(`/product/data/chat/${chatType}/default?orderItemId=${orderItemId}`);
    return response.data;
  },
);

export const chatLocationsSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder).addCase(getBusinessLocationsForChat, 'locations');
  },
);

export const selectBusinessLocations = (state: RootState) => state.chatLocations.locations?.content?.locations;

export default chatLocationsSlice;
