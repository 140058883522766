import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';
import { TopNavStatusEnum } from 'services/TopNavStatusEnum';

export const promotionsMediaMixMenu: MenuType = ({
  label: 'Promotions Media Mix',
  url: '',
  status: 'incomplete',
  offeringType: 'PromotionsMediaMix',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
    {
      label: 'Details',
      url: Urls.PmmDetails,
      status: 'incomplete',
    },
    {
      label: 'Solution Strategy',
      url: '',
      status: 'incomplete',
      subSteps: [
        {
          id: 0,
          url: Urls.PmmStrategyGeneral,
          name: 'General',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 1,
          url: Urls.PmmStrategyTargeting,
          name: 'Targeting',
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 2,
          url: Urls.PmmStrategyProxy,
          name: 'Proxy/Provisioning',
          status: TopNavStatusEnum.NotStarted,
        },
      ],
    },
    {
      label: 'SEM',
      url: Urls.PmmSem,
      status: 'incomplete',
    },
    {
      label: 'Social Smart Optimization',
      url: Urls.PmmSocialSmartOptimization,
      status: 'incomplete',
    },
    {
      label: 'Website Clicks',
      url: Urls.PmmWebsiteClicks,
      status: 'incomplete',
    },
    {
      label: 'Retargeting',
      url: Urls.PmmRetargeting,
      status: 'incomplete',
    },
    {
      label: 'Targeted Display',
      url: Urls.PmmTargetedDisplay,
      status: 'incomplete',
    },
  ],
});
