import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderItems, selectOrderItems } from 'services/ordersSlice';
import { sortOrderItems } from 'services/menus';
import {
  getCampaignIdsWithGmaid, getOrderItemsWithCidStatus,
  putOrderItemsWithCampaignId, selectCampaignItems,
} from 'services/campaignSlice';
import { Children, useEffect } from 'react';
import {
  Button, DialogContent, Divider, Typography, Dialog, DialogTitle, IconButton, useTheme, DialogActions, Backdrop,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CampaignIdSelect from 'pages/business/campaignIds/CampaignIdSelect';
import { AddonsOfferingTypes, MapOfferingTypesToProductLabel } from 'pages/business/campaignIds/CampaignIds';
import { getOrderBusinessByOrderId, selectOrderBusiness } from 'services/businessSlice';
import { useAppDispatch } from 'services/redux/store';
import { useParams } from 'react-router';

interface Props {
  open: boolean
  closeModal: (data) => any
  setBannerType: (data) => any
}

export default function CampaignModal({ open, closeModal, setBannerType }: Props) {
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const { content: business } = useSelector(selectOrderBusiness);
  const { content: orderItems } = useSelector(selectOrderItems);
  const { content: campaignItems } = useSelector(selectCampaignItems);
  const theme = useTheme();

  const methods = useForm({
    mode: 'all',
  });

  const {
    register,
    trigger,
    getValues,
    reset,
    formState: {errors},
  } = methods;

  const onSubmit = () => {
    const formData = getValues();
    const resource = { itemCampaigns: [] };
    const masterAddon = orderItems.find(item => item.offeringType === 'MasterAddon');

    if (masterAddon) {
      const addonItems = orderItems.filter(el => AddonsOfferingTypes.includes(el.offeringType));
      const firstAddonId = addonItems.at(0).id;
      resource.itemCampaigns.push(
        { campaignId: formData[firstAddonId], itemId: Number(masterAddon.id) },
      );
    }

    Object.keys(formData).forEach(value => resource.itemCampaigns.push(
      { campaignId: formData[value], itemId: Number(value) },
    ));

    appDispatch(dispatch(putOrderItemsWithCampaignId(resource))).then(() => {
      dispatch(getOrderItemsWithCidStatus(orderId));
      dispatch(getOrderItems(orderId));
    });
    closeModal(false);
    setBannerType('SUCCESS');
  };

  let orderData = (orderItems?.length > 0) ? sortOrderItems(orderItems, true) : [];
  orderData = orderData.filter(item => item.offeringType !== 'MasterAddon');

  const getOfferingType = (obj, pdName = false) => (obj.offeringType === 'ProductsData'
    ? (pdName ? obj.pdName : obj.pdOfferingType)
    : obj.offeringType);

  const handleClose = () => closeModal(false);

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderBusinessByOrderId(orderId));
    }
  }, [orderId]);

  useEffect(() => {
    if (business.gmaid) {
      dispatch(getCampaignIdsWithGmaid(business.gmaid));
    }
  }, [business]);

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  const isFormValid = () => {
    const formData = getValues();
    return Object.keys(formData).some(key => formData[key] && formData[key] !== '');
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { backgroundColor: theme.palette.common.black, opacity: 0.9 },
      }}
    >
      <DialogTitle textAlign="center">Select CID</DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: '12px',
          top: '18px',
        }}
      >
        <CloseIcon style={{ color: '#1D1D1D' }} />
      </IconButton>
      <Divider sx={{
        background: `linear-gradient(to right, ${theme.palette.success.main}, ${theme.palette.secondary.main})`,
        height: '1px',
        marginLeft: '1em',
        marginRight: '1em',
      }}
      />
      <DialogContent sx={{ paddingBottom: 0 }}>
        <FormProvider {...methods}>
          <Typography fontWeight="bold" sx={{ textAlign: 'center', marginBottom: '25px' }}>
            After creating the campaign in Campaign Central, please select the new CID.
          </Typography>
          {Children.toArray(orderData.map((obj) => (
            <CampaignIdSelect
              key={obj.id}
              label={MapOfferingTypesToProductLabel[getOfferingType(obj)] || getOfferingType(obj, true)}
              name={obj.id.toString()}
              data={campaignItems}
              offeringType={getOfferingType(obj)}
              register={register}
              trigger={trigger}
              errors={errors}
              value={obj?.campaignId}
            />
          )))}
        </FormProvider>
      </DialogContent>
      <DialogActions style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 3,
        paddingBottom: 21,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <Button variant="contained" onClick={onSubmit} disabled={!isFormValid()}
          sx={{ marginBottom: '25px' }}
        >
          <Typography fontSize="16px" fontWeight="bold">Confirm</Typography>
        </Button>
        <Button onClick={handleClose}>Back to Summary</Button>
      </DialogActions>
    </Dialog>
  );
}
