/* eslint-disable max-len */
import i18n from 'i18n/i18n';
import { RJSFSchema } from '@rjsf/utils';
import AddWebEvents from 'pages/generic-product/details/custom-fields/AddWebEvents';

const { t } = i18n;

export const WebEventsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.webEvents.title'),
  type: 'object',
  required: [],
  properties: {
    webEvents: {
      type: 'string',
      title: t('pages.ausProduct.webEvents.title'),
      default: '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.proxyProvisioning.webEventsOptions.adjustWebEvents'),
        t('pages.socialAds.solutionStrategy.proxyProvisioning.webEventsOptions.useMyWebEventsOnly'),
      ],
    },
  },
  dependencies: {
    webEvents: {
      oneOf: [
        {
          properties: {
            webEvents: {
              enum: ['Use only my web events'],
            },
            webEventItems: {
              type: 'string',
              items: {
                type: 'object',
              },
              title: 'Web Events',
              default: data?.webEventItems || '',
            },
          },
        },
        {
          properties: {
            webEvents: {
              enum: ['Please adjust web events per best practice'],
            },
          },
        },
      ],
    },
  },
});

const dropdownFieldKeys = [
  'webEvents',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

export const WebEventsUISchema = (data) => ({
  'ui:order': [
    'webEvents',
    'webEventItems',
    'webEventName', 'value', 'webEventUrl',
  ],
  webEventItems: {
    'ui:widget': (props) => <AddWebEvents {...props} data={data} />,
  },
  ...dropdownFields,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const WebEventsCustomValidate = (formData: any, errors, uiSchema) => errors;
