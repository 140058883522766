import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";
import HeadingTextLabel from "pages/generic-product/details/custom-fields/HeadingTextLabel";

const {t} = i18n;

export const FacebookInstagramCreativesSchema: RJSFSchema | any = (data) => ({
  title: t('pages.multiMediaCreatives.facebookInstagramCreatives.title'),
  type: 'object',
  required: ['typeOfImageryToIncludeOnTheFeed', 'image1', 'image2', 'image3', 'image4'],
  properties: {
    typeOfImageryToIncludeOnTheFeed: {
      type: 'string',
      title: t('pages.multiMediaCreatives.facebookInstagramCreatives.typeOfImageryToIncludeOnTheFeed'),
      default: data?.typeOfImageryToIncludeOnTheFeed || '',
    },
    typeOfImageryFor4FacebookCarousel: {
      type: 'string',
      title: t('pages.multiMediaCreatives.facebookInstagramCreatives.typeOfImageryFor4FacebookCarousel'),
    },
    image1: {
      type: 'string',
      title: t('pages.multiMediaCreatives.facebookInstagramCreatives.image1'),
      default: data?.image1 || '',
    },
    copyTextLogoOverlayUsedOnImage1: {
      type: 'string',
      title: t('pages.multiMediaCreatives.facebookInstagramCreatives.copyTextLogoOverlayUsedOnImage1'),
      default: data?.copyTextLogoOverlayUsedOnImage1 || '',
    },
    image2: {
      type: 'string',
      title: t('pages.multiMediaCreatives.facebookInstagramCreatives.image2'),
      default: data?.image2 || '',
    },
    copyTextLogoOverlayUsedOnImage2: {
      type: 'string',
      title: t('pages.multiMediaCreatives.facebookInstagramCreatives.copyTextLogoOverlayUsedOnImage2'),
      default: data?.copyTextLogoOverlayUsedOnImage2 || '',
    },
    image3: {
      type: 'string',
      title: t('pages.multiMediaCreatives.facebookInstagramCreatives.image3'),
      default: data?.image3 || '',
    },
    copyTextLogoOverlayUsedOnImage3: {
      type: 'string',
      title: t('pages.multiMediaCreatives.facebookInstagramCreatives.copyTextLogoOverlayUsedOnImage3'),
      default: data?.copyTextLogoOverlayUsedOnImage3 || '',
    },
    image4: {
      type: 'string',
      title: t('pages.multiMediaCreatives.facebookInstagramCreatives.image4'),
      default: data?.image4 || '',
    },
    copyTextLogoOverlayUsedOnImage4: {
      type: 'string',
      title: t('pages.multiMediaCreatives.facebookInstagramCreatives.copyTextLogoOverlayUsedOnImage4'),
      default: data?.copyTextLogoOverlayUsedOnImage4 || '',
    },
    pmaxSameImageryAsFacebookInstagram: {
      type: 'string',
      title: t('pages.multiMediaCreatives.facebookInstagramCreatives.shouldPmaxUseSameImageryAsFacebookInstagram'),
      default: data?.pmaxSameImageryAsFacebookInstagram || '',
      enum: [
        '',
        t('pages.generic-translation.yesOption'),
        t('pages.generic-translation.noOption'),
      ],
    },
  },
  dependencies: {
    pmaxSameImageryAsFacebookInstagram: {
      oneOf: [
        {
          properties: {
            pmaxSameImageryAsFacebookInstagram: {
              enum: ['No'],
            },
            pmaxImage1: {
              type: 'string',
              title: t('pages.multiMediaCreatives.facebookInstagramCreatives.pmaxImage1'),
              default: data?.pmaxImage1 || '',
            },
            pmaxImage2: {
              type: 'string',
              title: t('pages.multiMediaCreatives.facebookInstagramCreatives.pmaxImage2'),
              default: data?.pmaxImage2 || '',
            },
            pmaxImage3: {
              type: 'string',
              title: t('pages.multiMediaCreatives.facebookInstagramCreatives.pmaxImage3'),
              default: data?.pmaxImage3 || '',
            },
            pmaxImage4: {
              type: 'string',
              title: t('pages.multiMediaCreatives.facebookInstagramCreatives.pmaxImage4'),
              default: data?.pmaxImage4 || '',
            },
          },
        },
      ],
    },
  }
});

const textFieldKeys = [
  'typeOfImageryToIncludeOnTheFeed',
  'image1',
  'copyTextLogoOverlayUsedOnImage1',
  'image2',
  'copyTextLogoOverlayUsedOnImage2',
  'image3',
  'copyTextLogoOverlayUsedOnImage3',
  'image4',
  'copyTextLogoOverlayUsedOnImage4',
  'pmaxImage1',
  'pmaxImage2',
  'pmaxImage3',
  'pmaxImage4',
];

const textLabelKeys = [
  'typeOfImageryFor4FacebookCarousel',
];

const dropdownFieldKeys = [
  'pmaxSameImageryAsFacebookInstagram',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

const textLabelFields = textLabelKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textLabel' },
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

export const FacebookInstagramCreativesUISchema = {
  'ui:order': [
    'typeOfImageryToIncludeOnTheFeed', 'typeOfImageryFor4FacebookCarousel', 'image1', 'copyTextLogoOverlayUsedOnImage1', 'image2',
    'copyTextLogoOverlayUsedOnImage2', 'image3', 'copyTextLogoOverlayUsedOnImage3', 'image4', 'copyTextLogoOverlayUsedOnImage4',
    'pmaxSameImageryAsFacebookInstagram', 'pmaxImage1', 'pmaxImage2', 'pmaxImage3', 'pmaxImage4',
  ],
  ...textFields,
  ...textLabelFields,
  ...dropdownFields,
};

export const FacebookInstagramCreativesCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.typeOfImageryToIncludeOnTheFeed === '') {
    errors.typeOfImageryToIncludeOnTheFeed.addError(t('pages.multiMediaCreatives.facebookInstagramCreatives.typeOfImageryToIncludeOnTheFeedErrorMsg'));
    errors.addError(true);
  }
  if (formData?.image1 === '') {
    errors.image1.addError(t('pages.multiMediaCreatives.facebookInstagramCreatives.imageErrorMsg'));
    errors.addError(true);
  }
  if (formData?.image2 === '') {
    errors.image2.addError(t('pages.multiMediaCreatives.facebookInstagramCreatives.imageErrorMsg'));
    errors.addError(true);
  }
  if (formData?.image3 === '') {
    errors.image3.addError(t('pages.multiMediaCreatives.facebookInstagramCreatives.imageErrorMsg'));
    errors.addError(true);
  }
  if (formData?.image4 === '') {
    errors.image4.addError(t('pages.multiMediaCreatives.facebookInstagramCreatives.imageErrorMsg'));
    errors.addError(true);
  }
  return errors;
};
