import { t } from 'i18next';
import * as yup from 'yup';

const yupSchema = {
  applicationsNeeded: yup.string().required(),
  applicationNames: yup.string().required(t('pages.website.custom.applications.applicationNamesRequired')),
};

export default {
  yupValidations: yupSchema,
  customValidations: {},
};
