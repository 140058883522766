import {Box} from "@mui/system";
import {Button, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router";

export default function HowToGrantAccess() {
  const navigate = useNavigate();
  return (
      <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
      >
        <Grid
            container
            direction="column"
            spacing={2}
            p={3}
            pl={{xs: 3, md: 1}}
            bgcolor="white"
            boxShadow={3}
            borderRadius={2}
            maxWidth={800}
        >
          <Grid item>
            <Typography fontWeight={600} variant="h3" gutterBottom>
              How To Grant Access
            </Typography>
            <Typography variant="body1" gutterBottom>
              There are multiple ways to grant access, depending on the type of Facebook Page your
              Business currently has.
            </Typography>
            <Typography fontWeight={600} variant="h6" gutterBottom>
              New Page Experience:
            </Typography>
            <Typography variant="body1" gutterBottom>
              The business Facebook page is a New Page Experience. The admins to the business
              manager can accept our request on the page or grant us partnership access via Facebook
              business manager.
            </Typography>
            <Typography variant="body1" gutterBottom>
              <a href={'https://www.facebook.com/localiq/settings/?tab=admin_roles'}
                 target={'_blank'}>https://www.facebook.com/localiq/settings/?tab=admin_roles</a>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <ol style={{padding: '0 0 0 20px'}}>
                <li>Go to the business Facebook page, ensure the profile image top right is your
                  business page logo. If not, click 'See all profiles' and switch into the business
                  profile.
                </li>
                <li>Click on the profile image top right.</li>
                <li>Click 'Settings & Privacy'.</li>
                <li>Click 'Settings'.</li>
                <li>Click 'New Pages Experience' top left.</li>
                <li>In Settings, click 'Page access' on the top of the lefthand sidebar.</li>
                <li>Scroll down to Pending Partner Requests and click the request from ReachLocal.
                </li>
                <li>Follow the requested steps.</li>
                <li>Click 'Save'.</li>
                <li>Re-enter your password.</li>
                <li>If you do not see our pending request and you have confirmed that it has been
                  sent:
                </li>
                <li>Under 'Business account access' you will see the Business Manager account that
                  owns the Facebook page.
                </li>
                <li>Go to <a href={'https://business.facebook.com/settings'}
                             target={'_blank'}>https://business.facebook.com/settings</a> and click
                  the same business manager that owns the page.
                </li>
                <li>Go to 'Requests' (bottom left) and follow the prompts to accept the request from
                  ReachLocal.
                </li>
              </ol>
            </Typography>
            <Typography fontWeight={600} variant="h6" gutterBottom>
              Classic page owned by a FB BM:
            </Typography>
            <Typography variant="body1" gutterBottom>
              <ol style={{padding: '0 0 0 20px'}}>
                <li>Go to the business Facebook page.</li>
                <li>Click 'Settings'.</li>
                <li>In Settings, click 'Page Roles' on the lefthand sidebar.</li>
                <li>Scroll down to Pending Partner Requests and click the request from ReachLocal.
                </li>
                <li>Follow the requested steps.</li>
                <li>Click 'Save'.</li>
                <li>Re-enter your password.</li>
              </ol>
            </Typography>
            <Typography fontWeight={600} variant="h6" gutterBottom>
              If You Do Not See The Request:
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you do not see the business manager that owns the page at <a
                href={'https://business.facebook.com/settings'}
                target={'_blank'}>https://business.facebook.com/settings</a>
              <br/>
              <br/>
              go to Page Management History under Page Access. The page owner could be anyone with a
              previous role on the page, a previous employee, another current employee, or the
              person
              that created the Instagram.
              <br/>
              <br/>
              Once you find the admin to the business manager, they can grant access this way:
              <br/>
              <br/>
              <a href={'https://www.youtube.com/watch?v=tF6qYrApK18&t=58s'}
                 target={'_blank'}>https://www.youtube.com/watch?v=tF6qYrApK18&t=58s</a>
              <br/>
              <br/>
              <Typography fontWeight={600} variant="h6" gutterBottom>
                Other Resources:
              </Typography>
              Grant ReachLocal partnership access to the Facebook page by following this walkthrough
              video:
              <br/>
              <br/>
              <a href={'https://www.youtube.com/watch?v=tF6qYrApK18&t=58s'}
                 target={'_blank'}>https://www.youtube.com/watch?v=tF6qYrApK18&t=58s</a>
              <br/>
              <br/>
              For further assistance, watch this more in-depth walkthrough video:
              <br/>
              <br/>
              <a href={'https://www.youtube.com/watch?v=F52SUG-hW3g'}
                 target={'_blank'}>https://www.youtube.com/watch?v=F52SUG-hW3g</a>
            </Typography>
          </Grid>
          <Grid item width="100%">
            <Box display="flex" justifyContent="center">
              <Button onClick={() => navigate('/public/fb/facebook_url/thank_you')}
                      variant="contained"
                      color="primary" size="large">
                Got It
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
  );
}
