import CoModalConfirm from 'components/modals/CoModalConfirm';

interface Props {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  resourceType: string
  resourceName: string
}

export default function DeleteConfirmationModal({
  open, onConfirm, onClose, resourceType, resourceName,
}: Props) {
  return (
    <CoModalConfirm
      open={open}
      title={`Destroy ${resourceType}`}
      description={`Are you sure you want to destroy "${resourceName}"`}
      confirmText="Destroy"
      handleClose={onClose}
      handleConfirm={onConfirm}
    />
  );
}
