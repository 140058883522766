import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";
import HeadingTextLabel from "pages/generic-product/details/custom-fields/HeadingTextLabel";

const {t} = i18n;

export const LeadEnhancementSchema: RJSFSchema | any = (data) => ({
  title: t('pages.socialAds.solutionStrategy.leadEnhancement.title'),
  type: 'object',
  required: [
    'wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeFor',
    'doYouWantToIncludeAnIntroSectionOnYourLeadsForm',
    'forTheTextFieldsOnTheThankYouScreen'
  ],
  properties: {
    wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeFor: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeForLabel'),
      default: data?.wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeFor || '',
      enum: [
        'More Volume of Leads',
        'Higher Intent Leads',
      ],
    },
    introSectionOnLeadFormHeading: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.introSectionOnLeadFormHeading'),
    },
    doYouWantToIncludeAnIntroSectionOnYourLeadsForm: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.doYouWantToIncludeAnIntroSectionOnYourLeadsFormLabel'),
      default: data?.doYouWantToIncludeAnIntroSectionOnYourLeadsForm || '',
      enum: [
        'Yes - Include intro card',
        'No',
      ],
    },
    customConfigurableQuestionsOptionalHeading: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.customConfigurableQuestionsOptionalHeading'),
    },
    question1: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.question1Label'),
      description: t('pages.socialAds.solutionStrategy.leadEnhancement.questionHelperText'),
      default: data?.question1 || '',
    },
    answerToQuestion1: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion1Label'),
      description: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion1HelperText'),
      default: data?.answerToQuestion1 || '',
    },
    question2: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.question2Label'),
      description: t('pages.socialAds.solutionStrategy.leadEnhancement.questionHelperText'),
      default: data?.question2 || '',
    },
    answerToQuestion2: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion2Label'),
      description: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion1HelperText'),
      default: data?.answerToQuestion2 || '',
    },
    question3: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.question3Label'),
      description: t('pages.socialAds.solutionStrategy.leadEnhancement.questionHelperText'),
      default: data?.question3 || '',
    },
    answerToQuestion3: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion3Label'),
      description: t('pages.socialAds.solutionStrategy.leadEnhancement.answerToQuestion1HelperText'),
      default: data?.answerToQuestion3 || '',
    },
    helperText: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.helperText'),
    },
    thankYouSectionOnLeadFormHeading: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.thankYouSectionOnLeadFormHeading'),
    },
    forTheTextFieldsOnTheThankYouScreen: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.forTheTextFieldsOnTheThankYouScreenLabel'),
      default: data?.forTheTextFieldsOnTheThankYouScreen || '',
      enum: [
        t('pages.socialAds.solutionStrategy.leadEnhancement.forTheTextFieldsOnTheThankYouScreenOptions.writeMyText'),
        t('pages.socialAds.solutionStrategy.leadEnhancement.forTheTextFieldsOnTheThankYouScreenOptions.boilerMessage'),
        t('pages.socialAds.solutionStrategy.leadEnhancement.forTheTextFieldsOnTheThankYouScreenOptions.provideText'),
      ],
    },
    callToActionPostLeadSubmissionDoesTheAdvertiserPreferToDirectClientsTo: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.leadEnhancement.callToActionPostLeadSubmissionDoesTheAdvertiserPreferToDirectClientsToLabel'),
      default: data?.callToActionPostLeadSubmissionDoesTheAdvertiserPreferToDirectClientsTo || '',
      enum: [
        '',
        'Landing Page (Website)',
        'Call Now',
      ],
    },
  },
});

const textFieldKeys = [
  'question1',
  'answerToQuestion1',
  'question2',
  'answerToQuestion2',
  'question3',
  'answerToQuestion3',
];

const headingTextLabelKeys = [
  'introSectionOnLeadFormHeading',
  'customConfigurableQuestionsOptionalHeading',
  'helperText',
  'thankYouSectionOnLeadFormHeading',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'}
}), {});

const headingTextLabels = headingTextLabelKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => <HeadingTextLabel {...props} fontSize='20px'/>
  }
}), {});

export const LeadEnhancementUISchema = {
  'ui:order': [
    'wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeFor',
    'introSectionOnLeadFormHeading',
    'doYouWantToIncludeAnIntroSectionOnYourLeadsForm',
    'customConfigurableQuestionsOptionalHeading',
    'question1',
    'answerToQuestion1',
    'question2',
    'answerToQuestion2',
    'question3',
    'answerToQuestion3',
    'helperText',
    'thankYouSectionOnLeadFormHeading',
    'forTheTextFieldsOnTheThankYouScreen',
    'callToActionPostLeadSubmissionDoesTheAdvertiserPreferToDirectClientsTo',
  ],
  ...headingTextLabels,
  ...textFields,
  wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeFor: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.socialAds.solutionStrategy.leadEnhancement.wouldYouPreferTheLeadFormInTheLeadAdsObjectiveOptimizeForErrorMessage')}/>
  },
  doYouWantToIncludeAnIntroSectionOnYourLeadsForm: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.socialAds.solutionStrategy.leadEnhancement.doYouWantToIncludeAnIntroSectionOnYourLeadsFormErrorMessage')}/>
  },
  forTheTextFieldsOnTheThankYouScreen: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.socialAds.solutionStrategy.leadEnhancement.forTheTextFieldsOnTheThankYouScreenErrorMessage')}/>
  },
  callToActionPostLeadSubmissionDoesTheAdvertiserPreferToDirectClientsTo: {
    'ui:widget': (props) => <Dropdown {...props}/>
  },
}

export const LeadEnhancementCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.question1.trim() !== '' &&
      formData?.question1.trim().length > 300) {
    errors.question1.addError(t('pages.socialAds.solutionStrategy.leadEnhancement.questionErrorText'));
    errors.addError(true);
  }
  if (formData?.question2.trim() !== '' &&
      formData?.question2.trim().length > 300) {
    errors.question2.addError(t('pages.socialAds.solutionStrategy.leadEnhancement.questionErrorText'));
    errors.addError(true);
  }
  if (formData?.question3.trim() !== '' &&
      formData?.question3.trim().length > 300) {
    errors.question3.addError(t('pages.socialAds.solutionStrategy.leadEnhancement.questionErrorText'));
    errors.addError(true);
  }
  return errors;
};
