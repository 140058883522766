import {
  IQFormInput,
  IQYesNoFormRadioButtons,
  IQInputEmail,
  IQPhoneInputContainer,
  IQTheme, ValidationProvider
} from '@gannettdigital/shared-react-components';
import {Typography} from '@mui/material';
import GridItem from "components/grid/GridItem";
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import GridContainer from "components/grid/GridContainer";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import schema from './Schema';
import DefaultPageLayout from "layouts/DefaultPageLayout";
import {Box} from "@mui/system";
import { MailOutline } from '@mui/icons-material';
import { SEMDetailProps as  DetailPageFormProps } from '../../../models/SEMDetailProps';
import { useEffect } from 'react';


interface Props {
  product: DetailPageFormProps;
  onSubmit: (data: DetailPageFormProps) => void;
  onBack: () => void;
}

export default function DetailsPageForm(props: Props) {
  const {product, onSubmit, onBack} = props;
  const {t} = useTranslation();
  const defaultValues = {
    clientHaveALocaliqWebsite: product?.clientHaveALocaliqWebsite ?? '',
    isLocaliqWebsiteLive: product?.isLocaliqWebsiteLive ?? '',
    localiqWebsiteUrl: product?.localiqWebsiteUrl ?? '',
    temporaryWebsiteUrl: product?.temporaryWebsiteUrl ?? '',
    clientFullName: product?.clientFullName ?? '',
    clientEmail: product?.clientEmail ?? '',
    phoneNumber: product?.phoneNumber ?? '',
    phoneNumberExtension: product?.phoneNumberExtension ?? '',
    clientHasGoogleAnalyticsAndWantUsToHaveAccessToItToGaugePerformance: product?.clientHasGoogleAnalyticsAndWantUsToHaveAccessToItToGaugePerformance ?? '',
    isThisTheBestPersonToGrantUsGoogleAnalyticsAccess: product?.isThisTheBestPersonToGrantUsGoogleAnalyticsAccess ?? '',
    googleAnalyticsContactFullName: product?.googleAnalyticsContactFullName ?? '',
    googleAnalyticsEmail: product?.googleAnalyticsEmail ?? '',
    googleAnalyticsPhoneNumber: product?.googleAnalyticsPhoneNumber ?? '',
    googleAnalyticsPhoneNumberExtension: product?.googleAnalyticsPhoneNumberExtension ?? ''
  };

  const methods = useForm<DetailPageFormProps>({
    mode: 'onChange',
    defaultValues
  });

  const handleFormSubmit = (data: DetailPageFormProps) => {
    const formData = { ...data };
    onSubmit(formData);
  }

  const {
    handleSubmit,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
    watch,
    setValue
  } = methods;

  const clientHaveALocaliqWebsite = watch('clientHaveALocaliqWebsite');
  const isLocaliqWebsiteLive = watch('isLocaliqWebsiteLive');
  const clientHasGoogleAnalyticsAndWantUsToHaveAccessToItToGaugePerformance = watch('clientHasGoogleAnalyticsAndWantUsToHaveAccessToItToGaugePerformance')
  const isThisTheBestPersonToGrantUsGoogleAnalyticsAccess = watch('isThisTheBestPersonToGrantUsGoogleAnalyticsAccess');

  useEffect(() => {
    if(isLocaliqWebsiteLive === 'yes')
      setValue('temporaryWebsiteUrl', null);
    else if (isLocaliqWebsiteLive === "no") 
      setValue("localiqWebsiteUrl", null);
    else {
      setValue("temporaryWebsiteUrl", null);
      setValue("localiqWebsiteUrl", null);
    }
  }, [isLocaliqWebsiteLive]);

  useEffect(() => {
    if(isThisTheBestPersonToGrantUsGoogleAnalyticsAccess === 'yes') {
      setValue("googleAnalyticsContactFullName", null);
      setValue("googleAnalyticsEmail", null);
      setValue("googleAnalyticsPhoneNumber", null);
      setValue("googleAnalyticsPhoneNumberExtension", null);
    }
  }, [isThisTheBestPersonToGrantUsGoogleAnalyticsAccess]);

  useEffect(() => {
    if (clientHaveALocaliqWebsite !== "yes")
      setValue("isLocaliqWebsiteLive", null);

    if (clientHasGoogleAnalyticsAndWantUsToHaveAccessToItToGaugePerformance !== "yes")
      setValue("isThisTheBestPersonToGrantUsGoogleAnalyticsAccess", null);
    
  }, [
    clientHaveALocaliqWebsite,
    clientHasGoogleAnalyticsAndWantUsToHaveAccessToItToGaugePerformance
  ]);

  return (
      <DefaultPageLayout
          disableContinue={!isValid}
          onBack={onBack}
          header={t('pages.sem-details.title')}
          onContinue={handleSubmit(handleFormSubmit)}
      >
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <Box
                id="mainForm"
                component="form"
                sx={{display: 'flex'}}
                onSubmit={handleSubmit(handleFormSubmit)}
            >
              <GridContainer fullWidth>
                <GridItem sizes={[12]}>
                  <Typography alignContent={'start'} aria-label={t('pages.sem-details.websiteInfoTitle')} fontSize={22} fontWeight={700}>{t('pages.sem-details.websiteInfoTitle')}</Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQYesNoFormRadioButtons field='clientHaveALocaliqWebsite' theme={IQTheme} title={t('pages.sem-details.localiqWebsiteTitle')} fontTitleWeight='800'></IQYesNoFormRadioButtons>
                </GridItem>
                {clientHaveALocaliqWebsite === 'yes' ? 
                <>
                  <GridItem sizes={[12]}>
                    <IQYesNoFormRadioButtons field='isLocaliqWebsiteLive' theme={IQTheme} title={t('pages.sem-details.isLocaliqWebsiteLive')} fontTitleWeight='800'></IQYesNoFormRadioButtons>
                  </GridItem>
                  {isLocaliqWebsiteLive === 'yes' ? 
                  <GridItem sizes={[12]}>
                    <IQFormInput customValidation={schema.yupValidations.localiqWebsiteUrl} id='localiqWebsiteUrl' fontLabelWeight='800' theme={IQTheme} labelText={t('pages.sem-details.localiqWebsiteUrl')} name='localiqWebsiteUrl' clearable fullWidth tooltipText={t('pages.sem-details.localiqWebsiteUrlTooltip')} tooltipPaddingBottom={16}></IQFormInput>
                  </GridItem> : null }
                  {isLocaliqWebsiteLive === 'no' ? 
                  <GridItem sizes={[12]}>
                    <IQFormInput id='temporaryWebsiteUrl' customValidation={schema.yupValidations.temporaryWebsiteUrl} fontLabelWeight='800' theme={IQTheme} labelText={t('pages.sem-details.temporaryWebsiteUrl')} name='temporaryWebsiteUrl' clearable fullWidth tooltipText={t('pages.sem-details.temporaryWebsiteUrlTooltip')} tooltipPaddingBottom={16}></IQFormInput>
                  </GridItem> : null }
                </>
                : null}

                <GridItem sizes={[12]}>
                  <Typography alignContent={'start'} aria-label={t('pages.sem-details.clientInfoTitle')} fontSize={22} fontWeight={700}>{t('pages.sem-details.clientInfoTitle')}</Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormInput id='clientFullName' fontLabelWeight='800' theme={IQTheme} labelText={t('pages.sem-details.clientFullName')} name='clientFullName' clearable fullWidth schema={schema}></IQFormInput>
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormInput id='clientEmail' adornmentIcon={<MailOutline/>} fontLabelWeight='800' theme={IQTheme} labelText={t('pages.sem-details.clientEmail')} name='clientEmail' clearable fullWidth schema={schema}></IQFormInput>
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQPhoneInputContainer country='US' name='phoneNumber' defaultValue={defaultValues.phoneNumber} hasExtension extensionOptions={({name: 'phoneNumberExtension', defaultValue: defaultValues.phoneNumberExtension})} ></IQPhoneInputContainer>
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQYesNoFormRadioButtons field='clientHasGoogleAnalyticsAndWantUsToHaveAccessToItToGaugePerformance' theme={IQTheme} title={t('pages.sem-details.clientHasGoogleAnalyticsAndWantUsToHaveAccessToItToGaugePerformance')} fontTitleWeight='800'></IQYesNoFormRadioButtons>
                </GridItem>
                {
                  clientHasGoogleAnalyticsAndWantUsToHaveAccessToItToGaugePerformance === 'yes' ?
                  <>
                  <GridItem sizes={[12]}>
                    <IQYesNoFormRadioButtons field='isThisTheBestPersonToGrantUsGoogleAnalyticsAccess' theme={IQTheme} title={t('pages.sem-details.isThisTheBestPersonToGrantUsGoogleAnalyticsAccess')} fontTitleWeight='800'></IQYesNoFormRadioButtons>
                  </GridItem> 
                  {isThisTheBestPersonToGrantUsGoogleAnalyticsAccess === 'no' ?
                  <>
                  <GridItem sizes={[12]}>
                    <IQFormInput fontLabelWeight='800' customValidation={schema.yupValidations.googleAnalyticsContactFullName} id='googleAnalyticsContactFullName' theme={IQTheme} labelText={t('pages.sem-details.googleAnalyticsContactFullName')} name='googleAnalyticsContactFullName' clearable fullWidth></IQFormInput>
                  </GridItem>
                  <GridItem sizes={[12]}>
                    <IQFormInput fontLabelWeight='800' customValidation={schema.yupValidations.googleAnalyticsEmail} adornmentIcon={<MailOutline/>} id='googleAnalyticsEmail' theme={IQTheme} labelText={t('pages.sem-details.googleAnalyticsEmail')} name='googleAnalyticsEmail' clearable fullWidth></IQFormInput>
                  </GridItem>
                  <GridItem sizes={[12]}>
                    <IQPhoneInputContainer country='US' name='googleAnalyticsPhoneNumber' defaultValue={defaultValues.googleAnalyticsPhoneNumber} hasExtension extensionOptions={({name: 'googleAnalyticsPhoneNumberExtension', defaultValue: product.googleAnalyticsPhoneNumberExtension})} ></IQPhoneInputContainer>
                  </GridItem>
                  </>
                   : null}
                  </>
                  :  null
                }
              </GridContainer>
            </Box>
          </FormProvider>
        </ValidationProvider>
        <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </DefaultPageLayout>
  );
}