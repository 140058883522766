import styled from '@emotion/styled';

export const FormContainerStyled = styled('div')`
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin-top: 48px;
  .MuiGrid-item {
    margin-top: 24px;
  }
`;
