/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { FormProvider, useForm } from 'react-hook-form';
import {
  IQFormSelect, IQLoadingSpinner, IQTheme, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import IQFileUploader from 'components/fileUploader/NeFileUploader';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { Trans } from 'react-i18next';
import i18n from 'i18n/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import { useEffect, useMemo, useState } from 'react';
import schemaValidate from 'components/schemaValidate';
import GridItem from 'components/grid/GridItem';
import { tooltip } from 'pages/general-questions/form-sections/Tracking';
import {
  ProductTypes,
  deleteFile, getFiles, selectFilesUploaded, uploadFiles,
} from 'services/fileUploaderSlice';
import { selectOrdersContent } from 'services/ordersSlice';
import {
  getBusinessLocations, selectBusinessLocations, selectProviders,
} from 'services/businessLocationSlice';
import CoFileList from 'components/fileList/CoFileList';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import {
  subTypeOptions,
  yesNoOptions,
  neighborlyDescriptionsOptions,
  brightStarDescriptionOptions,
} from './CustomSolutionConstants';
import schema from './CustomSolutionStrategy.schema';

const { t } = i18n;

interface Props {
  product: any;
  onSubmit: (data) => void;
  onBack: () => void;
}

export default function CustomSolutionStrategyForm(props: Props) {
  const { product } = props;
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const dispatch = useDispatch();
  const filesUploaded = useSelector(selectFilesUploaded);
  const [subType, setSubType] = useState(product?.subType || 'custom');
  const [customSow, setCustomSow] = useState(product?.customSow || '');
  const [subTypeTier, setSubTypeTier] = useState(product?.subTypeTier || '');
  const [fileUploadedError, setFileUploadedError] = useState('');
  const [uploaderDirty, setUploaderDirty] = useState(false);
  const { businessId } = useSelector(selectOrdersContent);
  const locations = useSelector(selectBusinessLocations);
  const providers = useSelector(selectProviders);

  const uploaderType = ProductTypes.SOW_DOCUMENT;
  const megabyte = 1_000_000;
  const defaultFileSize = 256 * megabyte;

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      subType: product?.subType || 'custom',
      subTypeTier: product?.subTypeTier || '',
      customSow: product?.customSow || '',
      competitors: product?.competitors || '',
      additionalNotes: product?.additionalNotes || '',
    },
  });

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: {
      isValid,
      isDirty,
      isSubmitting,
      isSubmitSuccessful,
      dirtyFields,
      touchedFields,
    },
  } = methods;

  const subTypeValue = watch('subType');
  // eslint-disable-next-line max-len
  const isSubTypeOptionNeighborlyOrBrightStar = subTypeValue === 'national-neighborly' || subTypeValue === 'national-brightStar';
  const isSubTypeOtionNeighborly = subTypeValue === 'national-neighborly';
  const hasUploadedFiles = filesUploaded.content.length > 0;
  const subTierOptions = useMemo(
    () => (isSubTypeOtionNeighborly ? neighborlyDescriptionsOptions : brightStarDescriptionOptions),
    [watch('subType')],
  );

  useEffect(() => {
    if (dirtyFields?.subType || touchedFields?.subType) {
      setValue('subTypeTier', '');
      setSubTypeTier('');
    }
    if (isSubTypeOptionNeighborlyOrBrightStar) {
      setValue('customSow', '');
      setValue('competitors', '');
      setCustomSow('');
    }
  }, [subTypeValue]);

  // eslint-disable-next-line consistent-return
  const getTooltipForNoOption = (option) => {
    if (option.value === 'no') {
      return tooltip(t('pages.seo.customStrategy.customSowNoTooltip'), <InfoOutlinedIcon />);
    }
  };

  const onUpdate = (newFiles) => {
    dispatch(uploadFiles({
      websiteId, files: newFiles, uploaderType, offeringType,
    }));
  };

  const handleUploaderError = () => {
    setFileUploadedError(filesUploaded.content.length === 0
      ? t('pages.seo.customStrategy.customSowUploadErrorMessage') : '');
  };

  const onDelete = async (file) => {
    await dispatch(deleteFile({ websiteId, file }));
    setUploaderDirty(true);
  };

  const handleFormSubmit = (data) => {
    if (isSubTypeOptionNeighborlyOrBrightStar) {
      if (filesUploaded.content.length > 0) onDelete(filesUploaded?.content[0]);
      // eslint-disable-next-line no-param-reassign
      data.customSow = '';
    }

    props.onSubmit(data);
  };

  useEffect(() => {
    if (uploaderDirty) handleUploaderError();
  }, [uploaderDirty]);

  useEffect(() => {
    dispatch(getFiles({ websiteId, uploaderType, offeringType }));
  }, [websiteId]);

  useEffect(() => {
    if (businessId) dispatch(getBusinessLocations(businessId));
  }, [businessId]);

  const prepopulateFields = (listings) => {
    const { competitors } = listings[listings.length - 1];
    if (!('competitors' in product)) setValue('competitors', competitors.map(comp => comp.name).join('\n'));
  };

  useEffect(() => {
    if (locations.length > 0) prepopulateFields(locations);
    else if (providers.length > 0) prepopulateFields(providers);
  }, [locations, providers]);

  const styledLabel = (label) => {
    const split = label.split('-');
    if (split.length > 1) {
      return (
        <Typography>
          <strong>{split[0]}</strong>
          {` - ${split[1]}`}
        </Typography>
      );
    }

    return label;
  };

  return (
    <DefaultPageLayout
      disableContinue={!isValid || (!isSubTypeOptionNeighborlyOrBrightStar && !hasUploadedFiles)}
      onBack={props.onBack}
      header="Solution Strategy"
      description={(
        <Trans
          i18nKey="pages.seo.customStrategy.pageDescription"
          components={{ br: <br /> }}
        />
      )}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container>
            <Grid item mb={3} mt={2}
              xs={9}
            >
              <Grid item mb={0}>
                <Typography variant="subtitle2" component="h3" fontSize={16}>
                  {t('pages.seo.customStrategy.subTypeLabel')}
                </Typography>
                <RadioGroup
                  aria-labelledby="sub-type"
                  name="subType"
                  value={subType}
                  onChange={(e) => setSubType(e.target.value)}
                >
                  <GridItem sizes={[6, 6]} marginBottom={0}>
                    {subTypeOptions.slice(0, 2).map(option => (
                      <FormControlLabel
                        key={option.value}
                        {...register('subType', {
                          validate: (value) => schemaValidate(value, 'subType', schema),
                        })}
                        value={option.value}
                        control={<Radio />}
                        label={styledLabel(option.label)}
                      />
                    ))}
                  </GridItem>
                  <GridItem sizes={[6, 6]} marginBottom={0}>
                    {subTypeOptions.slice(2, 5).map(option => (
                      <FormControlLabel
                        key={option.value}
                        {...register('subType', {
                          validate: (value) => schemaValidate(value, 'subType', schema),
                        })}
                        value={option.value}
                        control={<Radio />}
                        label={styledLabel(option.label)}
                      />
                    ))}
                  </GridItem>
                </RadioGroup>
              </Grid>
            </Grid>
            {(isSubTypeOptionNeighborlyOrBrightStar) && (
              <GridItem sizes={[9]}>
                <IQFormSelect
                  id="subTypeTier"
                  name="subTypeTier"
                  labelText={t('pages.seo.customStrategy.subTypeTierLabel')}
                  defaultValue={product?.subTypeTier || ''}
                  items={subTierOptions}
                  theme={IQTheme}
                  fontLabelWeight="600"
                  fullWidth
                  schema={schema}
                  showError
                  sx={{ background: IQTheme.palette.common.white }}
                />
              </GridItem>
            )}
            {
                (!isSubTypeOptionNeighborlyOrBrightStar) && (
                <>
                  <Grid item mb={3} xs={12}>
                    <Grid mb={1}>
                      <Typography variant="subtitle2" component="h3" fontSize={16}>
                        {t('pages.seo.customStrategy.customSowLabel')}
                        {tooltip(t('pages.seo.customStrategy.customSowTooltip'), <HelpOutlineOutlinedIcon />)}
                      </Typography>
                      <RadioGroup
                        aria-labelledby="customsow"
                        name="customSow"
                        value={product?.customSow}
                        onChange={(e) => setCustomSow(e.target.value)}
                      >
                        <GridItem sizes={[1, 2]} marginBottom={0}>
                          {yesNoOptions.map(option => (
                            <FormControlLabel
                              key={option.value}
                              {...register('customSow', {
                                validate: (value) => schemaValidate(value, 'customSow', schema),
                              })}
                              value={option.value}
                              control={<Radio />}
                              label={(
                                <Typography variant="body1">
                                  {option.label}
                                  {getTooltipForNoOption(option)}
                                </Typography>
                                    )}
                            />
                          ))}
                        </GridItem>
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <Grid item xs={9} sx={{
                    '& > div > div': { padding: 0, display: 'flex', justifyContent: 'flex-start' },
                    '& > div > div > *': { marginLeft: '10px' },
                  }}
                  >
                    <IQFileUploader
                      showRecommendedImageText={false}
                      showAcceptedFileTypesText
                      required
                      name="uploads" maxFileSize={defaultFileSize}
                      onSuccess={onUpdate}
                      label={t('pages.seo.customStrategy.customUploadSowLabel')}
                      supportingText=""
                      totalAllowedFiles={1}
                      imageTypes={[]}
                      videoTypes={[]}
                      documentTypes={['.pdf', '.docx']}
                      size="md"
                      currentUploadedFiles={filesUploaded.content.length}
                      onBlur={() => handleUploaderError()}
                      requiredError={fileUploadedError}
                    />
                  </Grid>
                  <Grid item xs={9} mb={1}
                    mt={0}
                  >
                    {filesUploaded.status === 'loading' && <IQLoadingSpinner size={40} />}
                    {filesUploaded.content.length
                      ? <CoFileList files={filesUploaded.content} onDelete={onDelete} />
                      : null }
                  </Grid>
                  <Grid item xs={9} mb={3}>
                    <NeFormTextArea
                      {...register('competitors')}
                      id="competitors"
                      name="competitors"
                      labelText={t('pages.seo.solutionStrategy.competitorsLabel')}
                      fullWidth
                      rowCount={2}
                      fontLabelWeight="600"
                    />
                    <FormHelperText>{t('pages.seo.customStrategy.competitorsHelperText')}</FormHelperText>
                  </Grid>
                </>
                )
            }

            <Grid item xs={9} mb={3}>
              <NeFormTextArea
                {...register('additionalNotes')}
                id="additionalNotes"
                name="additionalNotes"
                labelText={t('pages.seo.solutionStrategy.additionalLabel')}
                fullWidth
                rowCount={2}
                fontLabelWeight="600"
              />
            </Grid>
          </Grid>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
