import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Urls } from 'navigation/Urls';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { requestNewOrder, selectOrdersContent } from 'services/ordersSlice';
import {
  getCase, selectCase, selectCaseStatus, selectErrorMessage,
} from 'services/punchlistSlice';

export const loadingPunchlist = () => (
  <>
    <IQLoadingSpinner size={60} />
    <Typography mt={2}>
      Getting information from Salesforce
    </Typography>
  </>
);

export const failedRequest = (handleClick, errorMessage) => (
  <>
    <Typography mb={4}>
      {errorMessage}
    </Typography>
    <Button variant="contained" sx={{ padding: '8px 22px' }} onClick={handleClick}>
      <Typography>Back to Main Page</Typography>
    </Button>
  </>
);

export default function PunchlistLoadingScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const caseId = searchParams.get('CaseId') || searchParams.get('caseId') || searchParams.get('caseid');
  const currentOrder = useSelector(selectOrdersContent);
  const caseContent = useSelector(selectCase);
  const caseStatus = useSelector(selectCaseStatus);
  const errorMessage = useSelector(selectErrorMessage);

  useEffect(() => {
    if (caseId) {
      dispatch(getCase(caseId));
      dispatch(requestNewOrder({}));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(caseContent).length > 0 && Object.keys(currentOrder).length > 0 && !errorMessage) {
      navigate(`/${Urls.Punchlist}/${caseId}`);
    }
  }, [caseContent, currentOrder]);

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {caseStatus !== 'failed' && !errorMessage
        ? loadingPunchlist()
        : failedRequest(() => navigate('/'), errorMessage)}
    </Box>
  );
}
