import React, { ReactNode, useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Autocomplete, TextField, Chip, Box, Typography, Theme, AutocompleteRenderGetTagProps, ChipProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  debounceTime, fromEvent, map, Subject,
} from 'rxjs';
import { IQLabelTooltip, IQTheme } from '@gannettdigital/shared-react-components';
import InfoIcon from '@mui/icons-material/Info';
import schemaValidate from '../schemaValidate';

interface SuggestionParams {
  query: string;
}

export interface Props {
  /**
   * Show label
   */
  label: boolean;
  /**
   * Label for the input field
   */
  labelText?: string;
  /**
   * Tool tip for the label
   */
  labelTooltipText?: string | ReactNode;
  tooltipPlacement?: string;
  arrow?: boolean;
  /**
   * Required or not
   */
  required?: boolean;
  /**
   * Icon for the label
   */
  labelIcon?: SVGElement;
  /**
   * Values to populate Select
   */
  items: SelectItem[];
  /**
   * Name for the field
   */
  name: string;
  /**
   * Placeholder for the field
   */
  placeholder?: string;
  /**
   * Selected values
   */
  values: string[];
  /**
   * removes arrow, hit enter to add keywords
   */
  freeSolo?: boolean;

  /**
   * Hide clear icon in the input field
   */
  disableClear?: boolean;
  /**
   * Max number fo items to be selected
   */
  maxItems: number;
  /**
   * Clear entries more than maxItems
   */
  clearExtraItems?: boolean;
  schema: any;
  theme?: Theme;

  cleanKeywordItems?: boolean;
  /**
   * Callback function to get the suggestions
   */
  getSuggestions?: (params: SuggestionParams) => void;
  searchDebounceTimeMS?: number;
  id?: string;
  supportCustomChips?: boolean;
  onClose?: any;
  onBlur?: any;
  disabled?: boolean;
  hideLimitHelperText?: boolean;
  keywordLengthLimit?: number;
}

export type SelectItem = { label: string; value: string };
const MAX_DEFAULT = 99;

interface CustomChipProps extends ChipProps {
  dark?: boolean;
}

const ChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'dark',
})<CustomChipProps>(({ theme, dark }) => ({
  '&.MuiChip-root': {
    border: `1px solid ${dark ? theme.palette.primary.dark : theme.palette.primary.main}`,
    color: dark ? theme.palette.primary.dark : theme.palette.primary.main,
    zIndex: 1,
  },
  '& .MuiChip-deleteIcon': {
    color: dark ? theme.palette.primary.dark : theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

const TextFieldStyled = styled(TextField)`
  & .MuiOutlinedInput-root > input {
    z-index: 1;
  }
  & .MuiOutlinedInput-root fieldset {
    border-color: ${props => props.theme.palette.text.primary};
    background: ${props => props.theme.palette.common.white};
  }
`;

// eslint-disable-next-line no-useless-escape
const punctuation = /[\.,;?!]/g;

const useDebounce = (time, initialValue) => {
  const [value, setValue] = useState(initialValue);
  const [values] = useState(() => new Subject());
  useEffect(() => {
    const sub = values.pipe(debounceTime(time)).subscribe(setValue);
    return () => sub.unsubscribe();
  }, [time, values]);
  return [value, (v) => values.next(v)];
};

const NeSelectChip = ({
  values,
  items,
  label,
  name,
  placeholder,
  labelIcon,
  labelText,
  labelTooltipText,
  tooltipPlacement = 'top',
  arrow = false,
  freeSolo,
  required,
  maxItems,
  clearExtraItems,
  schema,
  theme,
  getSuggestions,
  id = 'chips-input',
  searchDebounceTimeMS = 500,
  supportCustomChips = true,
  cleanKeywordItems = false,
  onClose, onBlur,
  disabled = false,
  hideLimitHelperText = false,
  keywordLengthLimit = 64,
}: Props) => {
  const maxLimit = maxItems > 0 ? maxItems : MAX_DEFAULT;

  const {
    control,
    register,
    setError,
    clearErrors,
    formState: {
      errors,
      isDirty,
    },
  } = useFormContext();

  const getErrorMessage = (arr) => {
    const castArr = [...arr] as any;
    const keywordError = castArr.filter(error => error?.message)?.[0]?.message;
    return keywordError;
  };

  const keywordError = Array.isArray(errors[name]) ? getErrorMessage(errors[name]) : null;

  const hasError = !!errors[name];
  const renderErrorText = (errors) => (
    <>
      <InfoIcon sx={{ fontSize: '15px', verticalAlign: 'middle' }} />
      {' '}
      {keywordError || errors[name]?.message}
    </>
  );
  const errorMessage = hasError ? renderErrorText(errors) : '';

  const [freeItems, setFreeItems] = useState<string[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>(values || []);
  const valueToLabel: Map<string, string> = new Map<string, string>();
  const [queryValue, setQueryValue] = useDebounce(searchDebounceTimeMS, '');

  items?.forEach((item) => {
    valueToLabel.set(item.value, item.label);
  });

  useEffect(() => {
    setFreeItems(items?.map((item) => (item.value)));
    setSelectedValues(values || []);
  }, []);

  useEffect(() => {
    const autoCompleteUsage = fromEvent(document.getElementById(id), 'blur');
    const result = autoCompleteUsage.pipe(
      map((i) => {
        const currentTarget = i.currentTarget as HTMLInputElement;
        return currentTarget.value;
      }),
      debounceTime(searchDebounceTimeMS),
    ).subscribe((keyValue) => keyValue);

    return () => result.unsubscribe();
  }, []);

  const onChangeValues = (
    event: React.SyntheticEvent<Element, Event>,
    value: NonNullable<string> | string[],
    fieldChangeEvent: any,
    reason: string,
  ) => {
    // Hack to prevent deleting items when entering same value twice
    if (event.type === 'keydown' && reason === 'removeOption') {
      // do nothing
      return;
    }
    if (Array.isArray(value)) {
      // check for max items to be selected, remove the first item
      const tempValue = ((value.length > maxLimit) && clearExtraItems) ? value.slice(1) : value;
      if (cleanKeywordItems === true) {
        setSelectedValues(tempValue.map(e => e.replace(punctuation, '').trim()));
        fieldChangeEvent(tempValue.map(e => e.replace(punctuation, '').trim()));
      } else {
        setSelectedValues(tempValue);
        fieldChangeEvent(tempValue);
      }
    }
  };

  useEffect(() => {
    const invalidKeywords = selectedValues.filter(value => value.length > keywordLengthLimit);

    if (maxLimit) {
      if (selectedValues.length > maxLimit) {
        setError(name, {
          type: 'manual',
          message: `Please review and add up to ${maxLimit} items to continue.`,
        }, { shouldFocus: false });
      }
    } else if (required) {
      if (isDirty && selectedValues.length === 0) {
        setQueryValue('');
        setError(name, {
          type: 'manual',
          message: 'This is a required field.',
        }, { shouldFocus: false });
      }
    } else if (invalidKeywords.length > 0) {
      setError(name, {
        type: 'manual',
        message: `No one keyword may be no longer than ${keywordLengthLimit} characters.`,
      }, { shouldFocus: false });
    } else clearErrors(name);
  }, [selectedValues, required]);

  useEffect(() => {
    if (queryValue && queryValue !== '' && getSuggestions) {
      getSuggestions({
        query: queryValue,
      });
    }
  }, [queryValue]);

  const getLabelFromItems = (value: string, forTag: boolean) => {
    const labelFound = valueToLabel.get(value);
    if (forTag && value && typeof value === 'string') {
      return labelFound || value?.replace(punctuation, '')?.trim();
    }
    return labelFound || `Add "${value}"`;
  };

  const generateTags = (value: string[], getTagProps: AutocompleteRenderGetTagProps):
  ReactNode => value.map((option: string, index: number) => (
    <ChipStyled variant="outlined" dark={disabled} label={getLabelFromItems(option, true)}
      {...getTagProps({ index })}
      deleteIcon={<CloseOutlinedIcon />}
    />
  ));

  const filterItems = (value: string) => {
    const filteredValues = [];
    items?.forEach((item) => {
      if (item.label.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
        filteredValues.push(item.value);
      }
    });
    return filteredValues;
  };

  return (
    <>
      {label && (
        <IQLabelTooltip
          labelText={labelText}
          hasError={hasError}
          theme={IQTheme}
          tooltipText={labelTooltipText}
          tooltipPlacement={tooltipPlacement as 'bottom' | 'left' | 'right' | 'top'}
          required={required}
          arrow={arrow}
          Icon={labelIcon}
          paddingBottom={16}
        />
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Box sx={{ position: 'relative' }}>
            <Autocomplete<string, boolean, boolean, boolean>
              sx={{
                width: '100%', height: '100%', left: 0, top: 0, borderColor: theme.palette.primary.main,
              }}
              {...register(name, { validate: (value) => schemaValidate(value, name, schema) })}
              freeSolo={freeSolo}
              id={id}
              fullWidth
              multiple
              disabled={disabled}
              disableClearable
              options={freeItems}
              value={field.value}
              onInputChange={(_, newInputValue) => setQueryValue(newInputValue)}
              onChange={(e, v, r) => onChangeValues(e, v, field.onChange, r)}
              onBlur={onBlur}
              onClose={onClose}
              filterOptions={(options, params) => {
                const filtered = filterItems(params.inputValue);
                if (params.inputValue !== '' && !selectedValues.includes(params.inputValue) && supportCustomChips) {
                  if (cleanKeywordItems) {
                    filtered.push(params.inputValue.replace(punctuation, '').trim());
                  } else filtered.push(params.inputValue);
                }
                return filtered;
              }}
              // eslint-disable-next-line max-len
              getOptionLabel={(option) => getLabelFromItems(option, false)}
              // eslint-disable-next-line max-len
              renderTags={generateTags}
              renderInput={(params) => (
                <>
                  <TextFieldStyled {...params}
                    variant="outlined"
                    error={hasError}
                    helperText={errorMessage}
                    placeholder={!selectedValues.length ? placeholder : ''}
                  />
                  <Typography
                    variant="caption"
                    component="span"
                    // regardless of the theme, this text will always be in gray color
                    sx={{
                      color: theme.palette.text.secondary, position: 'absolute', right: '10px', top: '55px',
                    }}
                  >
                    { (hideLimitHelperText || maxLimit >= MAX_DEFAULT) ? '' : `${selectedValues.length}/${maxLimit}`}
                  </Typography>
                </>
              )}
            />
          </Box>
        )}
      />
    </>
  );
};

export default NeSelectChip;
