/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';

const { t } = i18n;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SpanishChatSchema: RJSFSchema | any = (data) => ({
  title: t('pages.smm.solutionStrategy.title'),
  // eslint-disable-next-line max-len
  description: t('pages.smm.solutionStrategy.description'),
  type: 'object',
  required: ['chatCampaignType', 'specialRequests'],
  properties: {
    chatCampaignType: {
      type: 'string',
      title: t('pages.chat.spanishChat.chatCampaignType'),
      default: '',
      enum: [
        'New Chat Campaign',
        'Running Chat Campaign',
      ],
    },
    specialRequests: {
      type: 'string',
      title: t('pages.chat.spanishChat.specialRequests'),
      default: '',
      description: t('pages.chat.spanishChat.specialRequestDescription'),
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.chat.spanishChat.notesInstructions'),
      default: '',
    },
  },
  dependencies: {
    chatCampaignType: {
      oneOf: [
        {
          properties: {
            chatCampaignType: {
              enum: ['Running Chat Campaign'],
            },
            runningChatCampaignId: {
              type: 'string',
              title: t('pages.chat.spanishChat.runningChatCampaignId'),
              default: '',
            },
          },
        },
      ],
    },
  },
});

const textFieldKeys = [
  'specialRequests',
  'notesOrInstructions',
];

const textInputFieldKeys = [
  'runningChatCampaignId',
];

const textFields = textFieldKeys.reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'TextareaWidget' } }), {});

const textInputFields = textInputFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'textInputField' },
}), {});

export const SpanishChatUISchema = {
  'ui:order': ['chatCampaignType', 'runningChatCampaignId', 'specialRequests', 'notesOrInstructions'],
  chatCampaignType: {
    'ui:widget': (props) => <Dropdown {...props} errorMessage="Please select the campaign type" />,
  },
  runningChatCampaignId: {
    'ui:widget': 'textInputField',
  },
  ...textFields,
  ...textInputFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SpanishChatCustomValidate = (formData: any, errors, uiSchema: any) => {
  if (formData?.specialRequests === '') {
    errors.specialRequests.addError(t('pages.chat.spanishChat.specialRequestErrorMessage'));
    errors.addError(true);
  }
  if (formData?.chatCampaignType === '') {
    errors.chatCampaignType.addError(t('pages.chat.spanishChat.chatCampaignTypeErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
