import * as yup from 'yup';
import i18n from 'i18n/i18n';
import {urlValidationRegex} from "shared/constants";

const {t} = i18n;

const schema = {
  minimumAge: yup.number().required().nullable()
  .typeError(t('pages.generic-translation.minAgeNumberErrorMessage'))
  .test('is-less-than-or-equal-to-99', t('pages.generic-translation.minAgeLimitErrorMessage'), function (value) {
    return value <= 99;
  }),
  maximumAge: yup.number().required().nullable()
  .typeError(t('pages.generic-translation.maxAgeNumberErrorMessage'))
  .test('is-not-greater-than-99', t('pages.generic-translation.maxAgeLimitErrorMessage'), function (value) {
    return value <= 99;
  }),
  gender: yup.string().required(),
  callToActionButton: yup.string().required(t('pages.xmo.leadAds.callToActionButtonErrorMessage')),
  useAutomaticOrOptimizedPlacement: yup.string().required(),
  selectContactFieldsThatApply: yup.array().of(yup.string()).min(1),
  clientWantTheirAdsToAppear: yup.array().of(yup.string()).min(1),
  demographicTargetDetail: yup.string().required(),
  behavioralTargetDetail: yup.string().required(),
  interestTargetDetail: yup.string().required(),
  privacyPolicyUrl: yup.string().required(t('pages.xmo.leadAds.privacyPolicyUrlErrorMsg'))
  .matches(urlValidationRegex, t('pages.socialAds.socialAdsWebClicksSection.inputError.landingPageRequired')).nullable(),
  advertiserDisclaimerUrl: yup.string()
  .notRequired()
  .nullable()
  .test('is-valid-url', t('pages.socialAds.socialAdsWebClicksSection.inputError.landingPageRequired'), value => {
    if (!value) return true;
    return urlValidationRegex.test(value);
  }),
  visualStrategyClientWantToEmploy: yup.string().required(),
  clientBeProvidingImagesToUse: yup.string().required(),
  campaignUtilizeTheCarouselFormat: yup.string().required(),
  clientBeProvidingTextCreative: yup.string().required(),
  adHeadline: yup.string().required(),
  adBodyText: yup.string().required(),
  newsfeedLinkDescription: yup.string().required(),
  services: yup.array().min(1, t('pages.xmo.leadAds.businessCategoryAtLeastOneError'))
  .max(4, t('pages.xmo.leadAds.businessCategoryMaximumFourError')),
};
export default {
  yupValidations: schema,
  customValidations: {},
};