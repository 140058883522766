/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { StatusType } from 'models/StatusType';
import { RootState } from './redux/store';

export interface HealthcareLocationsState {
  locations: StatusType<any>
  providers: StatusType<any>
}

const initialState: HealthcareLocationsState = {
  locations: {
    status: 'idle',
    message: '',
    content: [],
  },
  providers: {
    status: 'idle',
    message: '',
    content: [],
  },
};

export const addLocation = createAction<any>('healthcare/locations/add');
export const addProvider = createAction<any>('healthcare/providers/add');
export const setLocations = createAction<any>('healthcare/locations/set');
export const setProviders = createAction<any>('healthcare/providers/set');
export const deleteLocation = createAction<any>('healthcare/locations/delete');
export const deleteProvider = createAction<any>('healthcare/providers/delete');
export const updateProvider = createAction<any>('healthcare/provider/update');

export const healthcareLocationsSlice = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(addLocation, (state, action) => {
        const id = state.locations.content.length + 1;
        const data = { ...action.payload, id: `location-${id}` };
        state.locations.content.push(data);
      })
      .addCase(addProvider, (state, action) => {
        const id = state.providers.content.length + 1;
        const data = { ...action.payload, id: `provider-${id}` };
        state.providers.content.push(data);
      })
      .addCase(setLocations, (state, action) => {
        state.locations.content = action.payload;
      })
      .addCase(setProviders, (state, action) => {
        state.providers.content = action.payload;
      })
      .addCase(deleteLocation, (state, action) => {
        const locationId = action.payload;
        const index = state.locations.content.findIndex(item => item.id === locationId);
        const updatedLocations = [...state.locations.content];
        updatedLocations.splice(index, 1);
        state.locations.content = updatedLocations;
      })
      .addCase(deleteProvider, (state, action) => {
        const providerId = action.payload;
        const index = state.providers.content.findIndex(item => item.id === providerId);
        const updatedProviders = [...state.locations.content];
        updatedProviders.splice(index, 1);
        state.providers.content = updatedProviders;
      })
      .addCase(updateProvider, (state, action) => {
        const { providerId, data } = action.payload;
        const index = state.providers.content.findIndex(item => item.id === providerId);
        const updatedProviders = [...state.providers.content];
        const existing = updatedProviders[index];
        updatedProviders.splice(index, 1, { ...existing, ...data });
        state.providers.content = updatedProviders;
      });
  },
);

export const selectLocations = (state: RootState) => state.healthcare.locations.content;
export const selectProviders = (state: RootState) => state.healthcare.providers.content;

export default healthcareLocationsSlice;
