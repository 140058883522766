import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import TextField from '../../custom-fields/TextField';

const {t} = i18n;

export const AusPerformanceMaxDataCollectionChildSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.ausPerformanceMax.performanceMax.title'),
  type: 'object',
  required: ['budget','campaignObjective','linkToSearchCid','advertiserAverageSalesValue','clientContactExpectation','targetedStartDate','likeToBidOnBusinessName','trackingRequirements'],
  properties: {
    budget: {
      type: 'string',
      title: t('pages.ausProduct.ausPerformanceMax.performanceMax.budgetLabel'),
      default: data?.budget || '',
    },
    campaignObjective: {
      type: 'array',
      title: t('pages.ausProduct.ausPerformanceMax.performanceMax.campaingObjective.campaingObjectiveLabel'),
      default: data?.campaignObjective || '',
      items: {
        type: 'string',
        enum: [
          t('pages.ausProduct.ausPerformanceMax.performanceMax.campaingObjective.campaingObjectiveOptions.acquireNewCustomerBase'),
          t('pages.ausProduct.ausPerformanceMax.performanceMax.campaingObjective.campaingObjectiveOptions.createFunnelStrategy'),
          t('pages.ausProduct.ausPerformanceMax.performanceMax.campaingObjective.campaingObjectiveOptions.focusOnNewServiceOrProduct'),
          t('pages.ausProduct.ausPerformanceMax.performanceMax.campaingObjective.campaingObjectiveOptions.remarketingToExistingCustomers'),
          t('pages.ausProduct.ausPerformanceMax.performanceMax.campaingObjective.campaingObjectiveOptions.otherPleaseSpecify'),
        ],
      },
      uniqueItems: true,
      minItems: 1,
    },
    linkToSearchCid: {
      type: 'string',
      title: t('pages.ausProduct.ausPerformanceMax.performanceMax.linkToSearchCIDLabel'),
      default: data?.linkToSearchCid || '',
      description: t('pages.ausProduct.ausPerformanceMax.performanceMax.maximizePerformanceLabel')
    },
    advertiserAverageSalesValue: {
      type: 'string',
      title: t('pages.ausProduct.ausPerformanceMax.performanceMax.advertiserAverageSalesValueLabel'),
      default: data?.advertiserAverageSalesValue || '',
    },
    clientContactExpectation: {
      type: 'string',
      title: t('pages.ausProduct.ausPerformanceMax.performanceMax.clientContactExpectationLabel'),
      default: data?.clientContactExpectation || '',
      description: t('pages.ausProduct.ausPerformanceMax.performanceMax.contactClientExpectsLabel')
    },
    targetedStartDate: {
      type: 'string',
      title: t('pages.ausProduct.ausPerformanceMax.performanceMax.targetedStartDate.targetedStartDateLabel'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.ausPerformanceMax.performanceMax.targetedStartDate.targetedStartDateOptions.yes'),
        t('pages.ausProduct.ausPerformanceMax.performanceMax.targetedStartDate.targetedStartDateOptions.no'),
      ],
      description: t('pages.ausProduct.ausPerformanceMax.performanceMax.clientRequestedStartDate')
    },
    likeToBidOnBusinessName: {
      type: 'string',
      title: t('pages.ausProduct.ausPerformanceMax.performanceMax.likeToBusiness.likeToBidOnBusinessNameLabel'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.ausPerformanceMax.performanceMax.likeToBusiness.likeToBusinessOptions.yes'),
        t('pages.ausProduct.ausPerformanceMax.performanceMax.likeToBusiness.likeToBusinessOptions.no'),
      ],
    },
    happyForRlToUtmString: {
      type: 'string',
      title: t('pages.ausProduct.ausPerformanceMax.performanceMax.happyForRLToUTMString.happyForRLToUTMStringLabel'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.ausPerformanceMax.performanceMax.happyForRLToUTMString.happyForRLToUTMStringOptions.yes'),
        t('pages.ausProduct.ausPerformanceMax.performanceMax.happyForRLToUTMString.happyForRLToUTMStringOptions.no'),
      ],
      description: t('pages.ausProduct.ausPerformanceMax.performanceMax.clientSpecificallyAskedTranslation')
    },
    trackingRequirements: {
      type: 'string',
      title: t('pages.ausProduct.ausPerformanceMax.performanceMax.trackingRequirements.trackingRequirementsLabel'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.ausPerformanceMax.performanceMax.trackingRequirements.trackingRequirementsOptions.callAndWebEvent'),
        t('pages.ausProduct.ausPerformanceMax.performanceMax.trackingRequirements.trackingRequirementsOptions.callTracking'),
        t('pages.ausProduct.ausPerformanceMax.performanceMax.trackingRequirements.trackingRequirementsOptions.webEventTracking'),
        t('pages.ausProduct.ausPerformanceMax.performanceMax.trackingRequirements.trackingRequirementsOptions.noTracking'),
      ],
    },
    campaignComments: {
      type: 'string',
      title:  t('pages.ausProduct.ausPerformanceMax.performanceMax.campaignCommentsLabel'),
      default: data?.campaignComments || '',
      description: t('pages.ausProduct.ausPerformanceMax.performanceMax.anythingElseHasBeenDiscussedWithClient')
    },
  },
  dependencies: {
    targetedStartDate: {
      oneOf: [
        {
          properties: {
            targetedStartDate: {
              enum: [t('pages.ausProduct.ausPerformanceMax.performanceMax.targetedStartDate.targetedStartDateOptions.yes')],
            },
            requestStartDate: {
              type: 'string',
              title: t('pages.ausProduct.ausPerformanceMax.performanceMax.targetedStartDateInput'),
              default: data?.requestStartDate || '',
            },
          },
        },
        {
          properties: {
            targetedStartDate: {
              enum: [t('pages.ausProduct.ausPerformanceMax.performanceMax.targetedStartDate.targetedStartDateOptions.no')],
            },
          },
        },
      ],
    },
    happyForRlToUtmString: {
      oneOf: [
        {
          properties: {
            happyForRlToUtmString: {
              enum: [t('pages.ausProduct.ausPerformanceMax.performanceMax.happyForRLToUTMString.happyForRLToUTMStringOptions.no')],
            },
            source: {
              type: 'string',
              title: t('pages.ausProduct.ausPerformanceMax.performanceMax.source'),
              default: data?.source || '',
            },
            medium: {
              type: 'string',
              title: t('pages.ausProduct.ausPerformanceMax.performanceMax.medium'),
              default: data?.medium || '',
            },
            campaignName: {
              type: 'string',
              title: t('pages.ausProduct.ausPerformanceMax.performanceMax.campaignName'),
              default: data?.campaignName || '',
            },
          },
        },
        {
          properties: {
            happyForRlToUtmString: {
              enum: [t('pages.ausProduct.ausPerformanceMax.performanceMax.happyForRLToUTMString.happyForRLToUTMStringOptions.yes')],
            },
          },
        },
      ],
    },
  }
});

const decimalInputFieldKeys = [
  'budget',
  'advertiserAverageSalesValue'
];
const integerFields = decimalInputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'decimalInputField' },
}), {});
const textFieldKeys = [
  'linkToSearchCid',
  'clientContactExpectation',
  'campaignComments',
  'source',
  'medium',
  'campaignName'
];
const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});
const checkBoxesFieldKeys = [
  'campaignObjective',
];
const checkBoxesFields = checkBoxesFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'checkBoxField' },
}), {});
const dropdownFieldKeys = [
  'targetedStartDate',
  'likeToBidOnBusinessName',
  'happyForRlToUtmString',
  'trackingRequirements'
];
const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});
const datePickerFieldKeys = [
  'requestStartDate',
];
const datePickerFieldFields = datePickerFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'datePickerField' },
}), {});
export const AusPerformanceMaxDataCollectionChildUISchema = {
  'ui:order': ['budget','campaignObjective','linkToSearchCid','advertiserAverageSalesValue','clientContactExpectation','targetedStartDate',
    'requestStartDate','likeToBidOnBusinessName','happyForRlToUtmString','source','medium','campaignName','trackingRequirements','campaignComments'
  ],
 ...textFields,
 ...checkBoxesFields,
 ...dropdownFields,
 ...datePickerFieldFields,
 ...integerFields
};

export const AusPerformanceMaxDataCollectionChildCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.budget === '') {
    errors.budget.addError(t('pages.ausProduct.ausPerformanceMax.requiredError'));
    errors.addError(true);
  }
  if (formData?.linkToSearchCid === '') {
    errors.linkToSearchCid.addError(t('pages.ausProduct.ausPerformanceMax.requiredError'));
    errors.addError(true);
  }
  if (formData?.advertiserAverageSalesValue === '') {
    errors.advertiserAverageSalesValue.addError(t('pages.ausProduct.ausPerformanceMax.requiredError'));
    errors.addError(true);
  }
  if (formData?.clientContactExpectation === '') {
    errors.clientContactExpectation.addError(t('pages.ausProduct.ausPerformanceMax.requiredError'));
    errors.addError(true);
  }
  if (formData?.targetedStartDate === '') {
    errors.targetedStartDate.addError(t('pages.ausProduct.ausPerformanceMax.requiredError'));
    errors.addError(true);
  }
  if (formData?.likeToBidOnBusinessName === '') {
    errors.likeToBidOnBusinessName.addError(t('pages.ausProduct.ausPerformanceMax.requiredError'));
    errors.addError(true);
  }
  if (formData?.trackingRequirements === '') {
    errors.trackingRequirements.addError(t('pages.ausProduct.ausPerformanceMax.requiredError'));
    errors.addError(true);
  }
  if(formData?.happyForRlToUtmString === t('pages.ausProduct.ausPerformanceMax.performanceMax.happyForRLToUTMString.happyForRLToUTMStringOptions.no')){
    if (formData?.source === '') {
      errors.source.addError(t('pages.ausProduct.ausPerformanceMax.requiredError'));
      errors.addError(true);
    }
    if (formData?.medium === '') {
      errors.medium.addError(t('pages.ausProduct.ausPerformanceMax.requiredError'));
      errors.addError(true);
    }
    if (formData?.campaignName === '') {
      errors.campaignName.addError(t('pages.ausProduct.ausPerformanceMax.requiredError'));
      errors.addError(true);
    }
  }
  return errors;
};