import React from 'react';
import {Grid, Typography} from '@mui/material';

export default function AnchorTextLabel(props: any) {
  const {options, label} = props;
  const anchorUrl = options.enumOptions[0]?.value || '';
  return (
      <Grid item xs={9} mt={1}>
        <Typography>
          {props?.textBeforeAnchor && (
              <>
                {props.textBeforeAnchor} {" "}
              </>
          )}
          <a href={anchorUrl} target="_blank" rel="noreferrer">
            <Typography component="span" color="primary" sx={{wordBreak: 'break-word'}}>
              {label}
            </Typography>
          </a>
          {
              !props?.break && <br/>
          }
          {props?.textAfterAnchor && (
              <>
                {" "} {props.textAfterAnchor}
              </>
          )}
        </Typography>
      </Grid>
  );
}
