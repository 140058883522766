/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import { validateEmail } from 'pages/generic-product/details/custom-fields/EmailTextField';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';

const { t } = i18n;

export const ShoppingAdsSchema: RJSFSchema = {
  title: t('pages.shoppingAds.solutionStrategy.pageTitle'),
  // eslint-disable-next-line max-len
  type: 'object',
  required: ['shoppingAdsIntakeForm', 'shoppingAdsExpert', 'productsToExcludeFromCatalogue'],
  properties: {
    shoppingAdsIntakeForm: {
      type: 'string',
      title: t('pages.shoppingAds.solutionStrategy.shoppingAdsIntakeFormLabel'),
      default: '',
      items: {
        enum: [
          `${t('pages.generic-translation.yesOption')}`,
          `${t('pages.generic-translation.noOption')}`,
        ],
      },
    },
    merchantCenterProfile: {
      title: t('pages.shoppingAds.solutionStrategy.merchantCenterProfileLabel'),
      type: 'string',
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    clientInformation: {
      type: 'string',
      title: t('pages.shoppingAds.solutionStrategy.clientInformationLabel'),
    },
    mainPointOfContact: {
      type: 'string',
      title: t('pages.shoppingAds.solutionStrategy.mainPointOfContactLabel'),
    },
    clientsContactName: {
      type: 'string',
      title: t('pages.shoppingAds.solutionStrategy.clientsContactNameLabel'),
      default: '',
    },
    clientsContactNumber: {
      type: 'string',
      title: t('pages.shoppingAds.solutionStrategy.clientsContactNumberLabel'),
      default: '',
    },
    clientsContactEmail: {
      type: 'string',
      title: t('pages.shoppingAds.solutionStrategy.clientsContactEmailLabel'),
      default: '',
    },
    googleAnalyticsAccess: {
      title: t('pages.shoppingAds.solutionStrategy.googleAnalyticsAccessLabel'),
      type: 'string',
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    gmbAccess: {
      title: t('pages.shoppingAds.solutionStrategy.gmbAccessLabel'),
      type: 'string',
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    gtmAndEventSnippet: {
      title: t('pages.shoppingAds.solutionStrategy.gtmAndEventSnippetLabel'),
      type: 'string',
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    affiliateClient: {
      title: t('pages.shoppingAds.solutionStrategy.affiliateClientLabel'),
      type: 'string',
      default: '',
      enum: [
        '',
        'Yes',
        'No',
      ],
    },
    shoppingAdsExpert: {
      type: 'string',
      title: t('pages.shoppingAds.solutionStrategy.shoppingAdsExpertLabel'),
      default: '',
      items: {
        enum: [
          `${t('pages.generic-translation.yesOption')}`,
          `${t('pages.generic-translation.noOption')}`,
        ],
      },
    },
    typeOfCampaign: {
      title: t('pages.shoppingAds.solutionStrategy.typeOfCampaignLabel'),
      type: 'string',
      default: '',
      enum: [
        '',
        'Traditional Shopping Ads',
        'Performance Max (E-Commerce)',
      ],
    },
    targetingType: {
      title: t('pages.shoppingAds.solutionStrategy.targetingTypeLabel'),
      type: 'string',
      default: '',
      enum: [
        '',
        'National',
      ],
    },
    areasToExclude: {
      type: 'string',
      title: t('pages.shoppingAds.solutionStrategy.areasToExcludeLabel'),
      default: '',
    },
    catalogueSetup: {
      title: t('pages.shoppingAds.solutionStrategy.catalogueSetupLabel'),
      type: 'string',
      default: '',
      enum: [
        '',
        'Specific products - File feed provided by client',
        'All products - API feed',
      ],
    },
    landingPageUrl: {
      type: 'string',
      title: t('pages.shoppingAds.solutionStrategy.landingPageUrlLabel'),
      default: '',
    },
    inactivatePublishers: {
      type: 'array',
      title: t('pages.shoppingAds.solutionStrategy.inActivatePublishersLabel'),
      default: '',
      items: {
        type: 'string',
        enum: [
          'Google',
          'Bing',
        ],
      },
      uniqueItems: true,
    },
    productsToExcludeFromCatalogue: {
      type: 'string',
      title: t('pages.shoppingAds.solutionStrategy.productsToExcludeFromCatalogueLabel'),
      default: '',
      items: {
        enum: [
          `${t('pages.generic-translation.yesOption')}`,
          `${t('pages.generic-translation.noOption')}`,
        ],
      },
    },
    createCreatives: {
      title: t('pages.shoppingAds.solutionStrategy.createCreativesLabel'),
      type: 'string',
      default: '',
      enum: [
        '',
        'Use my creatives',
        'Use my creatives and use best practices',
        'Create my creatives and use best practices',
      ],
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.shoppingAds.solutionStrategy.notesLabel'),
      default: '',
    },
  },
  dependencies: {
    shoppingAdsIntakeForm: {
      oneOf: [
        {
          properties: {
            shoppingAdsIntakeForm: {
              enum: ['No'],
            },
            shoppingAdsIntakeFormNoLabel: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.shoppingAdsIntakeFormNoLabel'),
              // eslint-disable-next-line max-len
              enum: ['https://forms.office.com/Pages/ResponsePage.aspx?id=jUySvBY-iE6_Jtf8-xTy1bsD3lBmuW1Lg7DNRYDdNjxUNzVHRU5HWlQyMDIwOEVNSk1UQzkwSDc0Ui4u', true],
            },
          },
          required: [],
        },
      ],
    },
    merchantCenterProfile: {
      oneOf: [
        {
          properties: {
            merchantCenterProfile: {
              enum: ['Yes'],
            },
            googleMerchantAccountId: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.googleMerchantAccountIdLabel'),
              default: '',
            },
            merchantCenterProfileYesLabel: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.merchantCenterProfileYesLabel'),
            },
          },
          required: [],
        },
        {
          properties: {
            merchantCenterProfile: {
              enum: ['No'],
            },
            merchantCenterProfileNoLabel: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.merchantCenterProfileNoLabel'),
            },
          },
          required: [],
        },
      ],
    },
    googleAnalyticsAccess: {
      oneOf: [
        {
          properties: {
            googleAnalyticsAccess: {
              enum: ['No'],
            },
            googleAnalyticsContactLabel: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.googleAnalyticsContactLabel'),
            },
            googleClientsContactName: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.clientContactNameLabel'),
              default: '',
            },
            googleClientsContactNumber: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.clientContactNumberLabel'),
              default: '',
            },
            googleClientsContactEmail: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.clientContactEmailLabel'),
              default: '',
            },
          },
          required: [],
        },
      ],
    },
    gmbAccess: {
      oneOf: [
        {
          properties: {
            gmbAccess: {
              enum: ['No'],
            },
            gmbContactLabel: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.gmbContactLabel'),
            },
            gmbContactName: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.clientContactNameLabel'),
              default: '',
            },
            gmbContactNumber: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.clientContactNumberLabel'),
              default: '',
            },
            gmbContactEmail: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.clientContactEmailLabel'),
              default: '',
            },
          },
          required: [],
        },
      ],
    },
    gtmAndEventSnippet: {
      oneOf: [
        {
          properties: {
            gtmAndEventSnippet: {
              enum: ['No'],
            },
            gtmAndEventSnippetContactLabel: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.gtmAndEventSnippetContactLabel'),
            },
            gtmAndEventSnippetContactName: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.clientContactNameLabel'),
              default: '',
            },
            gtmAndEventSnippetContactNumber: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.clientContactNumberLabel'),
              default: '',
            },
            gtmAndEventSnippetContactEmail: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.clientContactEmailLabel'),
              default: '',
            },
          },
          required: [],
        },
      ],
    },
    productsToExcludeFromCatalogue: {
      oneOf: [
        {
          properties: {
            productsToExcludeFromCatalogue: {
              enum: ['Yes'],
            },
            productsToExclude: {
              type: 'string',
              title: t('pages.shoppingAds.solutionStrategy.productsToExcludeLabel'),
              default: '',
            },
          },
          required: [],
        },
      ],
    },
  },
};

const textFieldKeys = [
  'clientsContactName', 'googleMerchantAccountId', 'googleClientsContactName',
  'gmbContactName', 'gtmAndEventSnippetContactName', 'areasToExclude', 'productsToExclude',
];

const dropdownFieldKeys = [
  'merchantCenterProfile', 'googleAnalyticsAccess', 'gmbAccess', 'gtmAndEventSnippet',
  'affiliateClient', 'typeOfCampaign', 'targetingType', 'catalogueSetup', 'createCreatives',
];

const textLabelFieldKeys = [
  'mainPointOfContact', 'merchantCenterProfileYesLabel', 'merchantCenterProfileNoLabel',
  'googleAnalyticsContactLabel', 'gmbContactLabel', 'gtmAndEventSnippetContactLabel',
];

const radioFieldKeys = [
  'shoppingAdsIntakeForm', 'shoppingAdsExpert', 'productsToExcludeFromCatalogue',
];

const phoneNumberTextFieldKeys = [
  'clientsContactNumber', 'googleClientsContactNumber', 'gmbContactNumber',
  'gtmAndEventSnippetContactNumber',
];

const emailTextFieldKeys = [
  'clientsContactEmail', 'googleClientsContactEmail', 'gmbContactEmail',
  'gtmAndEventSnippetContactEmail',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'textInputField' },
}), {});

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'dropdown' },
}), {});

const textLabelFields = textLabelFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'textLabel' },
}), {});

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'radioButton' },
}), {});

const phoneNumberTextFields = phoneNumberTextFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'phoneNumberTextField' },
}), {});

const emailTextFields = emailTextFieldKeys.reduce((o, key) => ({
  ...o, [key]: { 'ui:widget': 'EmailTextWidget' },
}), {});

export const ShoppingAdsUISchema = {
  'ui:order': [
    'shoppingAdsIntakeForm', 'shoppingAdsIntakeFormNoLabel', 'merchantCenterProfile',
    'googleMerchantAccountId', 'merchantCenterProfileYesLabel', 'merchantCenterProfileNoLabel',
    'clientInformation', 'mainPointOfContact', 'clientsContactName', 'clientsContactNumber',
    'clientsContactEmail', 'googleAnalyticsAccess', 'googleAnalyticsContactLabel', 'googleClientsContactName',
    'googleClientsContactNumber', 'googleClientsContactEmail', 'gmbAccess', 'gmbContactLabel',
    'gmbContactName', 'gmbContactNumber', 'gmbContactEmail', 'gtmAndEventSnippet',
    'gtmAndEventSnippetContactLabel', 'gtmAndEventSnippetContactName', 'gtmAndEventSnippetContactNumber',
    'gtmAndEventSnippetContactEmail', 'affiliateClient', 'shoppingAdsExpert', 'typeOfCampaign',
    'targetingType', 'areasToExclude', 'catalogueSetup', 'landingPageUrl', 'inactivatePublishers',
    'productsToExcludeFromCatalogue', 'productsToExclude', 'createCreatives', 'notesOrInstructions',
  ],
  inactivatePublishers: {
    'ui:widget': 'checkboxes',
    'ui:style': {
      marginTop: '20px',
    },
  },
  shoppingAdsIntakeFormNoLabel: {
    'ui:widget': 'anchorTextLabel',
  },
  clientInformation: {
    'ui:widget': 'headingTextLabel',
  },
  landingPageUrl: {
    'ui:widget': 'UrlTextWidget',
  },
  notesOrInstructions: {
    'ui:widget': 'TextareaWidget',
  },
  ...textFields,
  ...dropdownFields,
  ...textLabelFields,
  ...radioFields,
  ...phoneNumberTextFields,
  ...emailTextFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ShoppingAdsCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.shoppingAdsIntakeForm === '') {
    errors.shoppingAdsIntakeForm.addError('Please select an option');
    errors.addError(true);
  }
  if (formData?.shoppingAdsExpert === '') {
    errors.shoppingAdsExpert.addError('Please select an option');
    errors.addError(true);
  }
  if (formData?.productsToExcludeFromCatalogue === '') {
    errors.productsToExcludeFromCatalogue.addError('Please select an option');
    errors.addError(true);
  }
  if (formData?.clientsContactEmail !== '' && !validateEmail(formData?.clientsContactEmail)) {
    errors.clientsContactEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.googleClientsContactEmail !== undefined
    && formData?.googleClientsContactEmail !== ''
    && !validateEmail(formData?.googleClientsContactEmail)) {
    errors.googleClientsContactEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.gmbContactEmail !== undefined
    && formData?.gmbContactEmail !== ''
    && !validateEmail(formData?.gmbContactEmail)) {
    errors.gmbContactEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.gtmAndEventSnippetContactEmail !== undefined
    && formData?.gtmAndEventSnippetContactEmail !== '' && !validateEmail(formData?.gtmAndEventSnippetContactEmail)) {
    errors.gtmAndEventSnippetContactEmail
      .addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.landingPageUrl !== '' && !validateUrl(formData?.landingPageUrl)) {
    errors.landingPageUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
