import { StepperRootProps } from 'layouts/StepperLayout';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  IQFormLayout, IQYesNoFormRadioButtons, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { getCustomWebsite, putCustomWebsiteApplications, selectCustomWebsite } from 'services/customWebsiteSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { selectActiveProductId } from 'services/navigationSlice';
import ErrorText from 'components/errorText/ErrorText';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import schema from './Applications.schema';

export default function CustomWebsiteApplications(props: StepperRootProps) {
  const { orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const customWebsite = useSelector(selectCustomWebsite);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      applicationsNeeded: '',
      applicationNames: '',
    },
  });

  const {
    getValues, handleSubmit, reset, watch, register, formState: {
      isDirty, isSubmitting, isSubmitSuccessful, isValid, errors,
    },
  } = methods;

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid]);

  const applicationsNeeded = useMemo(() => getValues('applicationsNeeded') === 'yes', [watch('applicationsNeeded')]);

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsite(websiteId.toString()));
  }, [websiteId]);

  const getThirdPartyApplicationsValue = (value) => {
    if (value === 'no') return 'no';
    if (value) return 'yes';
    return '';
  };

  useEffect(() => {
    reset({
      applicationsNeeded: getThirdPartyApplicationsValue(customWebsite.thirdPartyApplications),
      applicationNames: customWebsite.thirdPartyApplications === 'no' ? '' : customWebsite.thirdPartyApplications,
    });
  }, [customWebsite]);

  const onFormSubmit = () => {
    const data = getValues();
    const thirdPartyApplications = data.applicationsNeeded === 'no' ? 'no' : data.applicationNames;
    const content = { thirdPartyApplications };

    dispatch(putCustomWebsiteApplications({ orderItemId, content }));
    props.onContinue();
  };

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container marginTop={2}>
            <Grid item xs={6} marginBottom={5}>
              <Typography variant="h2">{t('pages.website.custom.applications.pageTitle')}</Typography>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6} marginBottom={3}>
              <IQFormLayout
                labelText={t('pages.website.custom.applications.applicationsNeeded')}
                required
                fontLabelWeight="bold"
                showError={false}
              >
                <IQYesNoFormRadioButtons
                  title={t('pages.website.custom.applications.applicationsNeededDescription')}
                  field="applicationsNeeded"
                />
              </IQFormLayout>
            </Grid>
            <Grid item xs={6} />
            {applicationsNeeded && (
              <Grid item xs={6} marginBottom={3}>
                <NeFormTextArea
                  {...register('applicationNames')}
                  id="applicationNames"
                  name="applicationNames"
                  labelText={t('pages.website.custom.applications.applicationNames')}
                  tooltipText={t('pages.website.custom.applications.applicationNamesTooltip')}
                  tooltipPaddingBottom={18}
                  tooltipPlacement="top"
                  fullWidth
                  rowCount={3}
                  fontLabelWeight="600"
                />
                <ErrorText
                  hasError={!!errors.applicationNames}
                  errorText={t('pages.website.custom.applications.applicationNamesRequired')}
                />
              </Grid>
            )}
          </Grid>
        </form>
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
