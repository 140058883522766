/* eslint-disable no-param-reassign */
import { AsyncThunkAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import ProductsEndpoints from 'services/endpoints/ProductsEndpoints';
import { StatusType } from 'models/StatusType';
import { Urls } from 'navigation/Urls';
import {
  AsyncThunkFulfilledActionCreator,
  AsyncThunkPendingActionCreator,
  AsyncThunkRejectedActionCreator,
} from '@reduxjs/toolkit/dist/createAsyncThunk';
import EcommerceType from 'models/EcommerceType';
import LandingPageType from 'models/LandingPageType';
import ChatSilverType from 'models/ChatSilverType';
import { saveSchedule, selectSchedule } from 'services/schedulerSlice';
import { saveWebEvent, selectWebEvent } from 'services/addWebEventSlice';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import AxiosAPI from './common/axios';

type ProductContentObject = {
  identifier: string;
  tier: string;
  data: EcommerceType | LandingPageType | ChatSilverType | any;
  offeringType: string;
  orderId: number;
  orderItemId: number;
  active: boolean;
  userEmail: string;
  userId: number;
  slug: string;
  productType?: any;
};

export interface ProductsState {
  product: StatusType<Partial<ProductContentObject>>,
  productUpdateCompleted: { [key: string]: boolean },
  productTypesMenu: StatusType<any>,
  productTypeList: StatusType<any>,
}

const initialState: ProductsState = {
  product: {
    content: {},
    status: 'notStartedLoading',
    message: '',
  },
  productUpdateCompleted: {},
  productTypesMenu: {
    content: [],
    status: 'idle',
    message: '',
  },
  productTypeList: {
    content: [],
    status: 'idle',
    message: '',
  },
};

export const getProductTypesMenu = createAsyncThunkCatchErrors(
  'products/typesMenu/get',
  async () => {
    const response = await AxiosAPI.get('/product/types/menu?countryId=1');
    return response.data;
  },
);

export const getProduct: CustomActionCreator = createAsyncThunkCatchErrors(
  'products/get',
  async ({ orderItemId }: { orderItemId: string }) =>
    ProductsEndpoints.getSite(orderItemId),
);

type DefaultArgs<T> = {
  orderItemId: string | number;
  completeStep?: Urls;
  content?: T;
  flush?: boolean;
};

type CustomActionCreator = (
  <TContent, R = any>(args: DefaultArgs<TContent>
  ) => AsyncThunkAction<R, DefaultArgs<TContent>, {}>)
& { pending: AsyncThunkPendingActionCreator<DefaultArgs<any>> }
& { rejected: AsyncThunkRejectedActionCreator<DefaultArgs<any>> }
& { fulfilled: AsyncThunkFulfilledActionCreator<any, DefaultArgs<any>> };

export const putProduct: CustomActionCreator = createAsyncThunkCatchErrors(
  'products/put',
  async ({
    orderItemId, content, completeStep, flush = false,
  }: {
    orderItemId: string, content: any, completeStep?: string, flush?: boolean
  }, { getState, dispatch }) => {
    const scheduleItems = selectSchedule(getState());
    const webEventItems = selectWebEvent(getState());
    const contentToUpdate = { ...content };
    if (scheduleItems.scheduleFlag) {
      contentToUpdate.schedule = scheduleItems.schedule;
      dispatch(saveSchedule({ schedule: [], scheduleFlag: false }));
    }
    if (webEventItems.webEventFlag) {
      contentToUpdate.webEventItems = webEventItems?.webEvent?.length > 0 ? webEventItems?.webEvent : '';
      dispatch(saveWebEvent({webEvent: [], webEventFlag: false}));
    }
    return {
      data: await ProductsEndpoints.putSite(orderItemId, contentToUpdate, flush),
      completeStep,
    };
  },
);

export const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    clearProductUpdateCompleted(state, action: PayloadAction<{ step: Urls }>) {
      // eslint-disable-next-line no-param-reassign
      state.productUpdateCompleted[action.payload.step] = false;
    },
    setProductUpdateCompleted(state, action: PayloadAction<{ step: Urls }>) {
      // eslint-disable-next-line no-param-reassign
      state.productUpdateCompleted[action.payload.step] = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProduct.fulfilled, (state, action) => {
        state.product.status = 'idle';
        state.product.content = action.payload;
      })
      .addCase(getProduct.pending, (state) => {
        state.product.status = 'loading';
      })
      .addCase(putProduct.fulfilled, (state, action) => {
        const { data, completeStep } = action.payload;
        state.product.content = {
          ...state.product.content,
          data: { ...state.product.content.data, ...data.data },
        };
        if (completeStep) state.productUpdateCompleted[completeStep] = true;
        state.product.status = 'idle';
      })
      .addCase(putProduct.pending, (state) => {
        state.product.status = 'loading';
      })
      .addCase(putProduct.rejected, (state, action) => {
        state.product.status = 'failed';
        state.product.message = action.error.message;
      })
      .addCase(getProductTypesMenu.fulfilled, (state, action) => {
        state.productTypesMenu.content = action.payload;
        const arr = Object.values(action.payload);
        state.productTypeList.content = arr.flat();
      });
  },
});

export const { clearProductUpdateCompleted, setProductUpdateCompleted } = slice.actions;

export const selectProduct = (state: RootState) => state.products.product;

export const selectProductTypesMenu = (state: RootState) => state.products.productTypesMenu.content;

export const selectProductTypesList = (state: RootState) => state.products.productTypeList.content;

export const selectProductUpdateCompleted = (step: Urls) =>
  (state: RootState) => state.products.productUpdateCompleted[step];

export default slice.reducer;
