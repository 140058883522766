/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';

const { t } = i18n;

// @ts-ignore
export const GetCreativeServiceFeeSchema: RJSFSchema | any = (data) => ({
  title: t('pages.get-creative-service-fee.title'),
  // eslint-disable-next-line max-len
  type: 'object',
  properties: {
    websiteTrackingUrl: {
      type: 'string',
      title: t('pages.get-creative-service-fee.websiteTrackingUrl'),
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.get-creative-service-fee.notesOrInstructions'),
    },
  },
});

export const GetCreativeServiceFeeUISchema = {
  'ui:order': [
    'websiteTrackingUrl',
    'notesOrInstructions',
  ],

  websiteTrackingUrl: {
    'ui:widget': 'UrlTextWidget',
  },
  notesOrInstructions: {
    'ui:widget': 'TextareaWidget',
  },
};

export const GetCreativeServiceFeeValidate = (formData: any, errors: any, uiSchema: any) => errors;
