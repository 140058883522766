import { Typography } from '@mui/material';
import 'styles/website_fonts.css';

export interface H2Props {
  children: Node;
  hasSubHeader?: boolean;
}

export function H2({ children, hasSubHeader }: H2Props) {
  return (
    <Typography variant="h2" gutterBottom component="div"
      sx={{ color: 'common.black', marginTop: hasSubHeader ? '10px' : '48px', marginBottom: '0px' }}
    >
      {children}
    </Typography>
  );
}

export default H2;
