import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import {
  FormHelperText, Grid, Typography, TextField, Tooltip
} from '@mui/material';
import ErrorText from 'components/errorText/ErrorText';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { useState } from 'react';

export default function TextInputField(props: any) {
  const [isTouched, setIsTouched] = useState(!!props.value);

  return (
    <Grid item xs={9} mt={3}>
      <Typography
        fontWeight={600}
        sx={{
          color: (props?.rawErrors?.length > 0 && isTouched)
            ? IQThemeV2.palette.error.main : IQThemeV2.palette.text.primary,
        }}
      >
        {props.label}
      { props.schema && props.schema.tooltip && props.schema.tooltip.trim().length > 1 ? <Tooltip title={props.schema.tooltip}>
        <HelpOutline color="primary" sx={{ marginLeft: 1, fontSize: '15px' }} />
      </Tooltip> : null }
      </Typography>
      <TextField
        id={props.id}
        name={props.name}
        disabled={props.disabled}
        fullWidth
        rows={2}
        error={props?.rawErrors?.length > 0 && isTouched}
        required={props.required}
        onBlur={() => setIsTouched(true)}
        style={{ marginTop: '10px' }}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
      {(props.placeholder && (props?.rawErrors?.length === 0 || !isTouched)) && (
        <FormHelperText error={false}>{props.placeholder}</FormHelperText>
      )}
      <ErrorText
        hasError={props?.rawErrors?.length > 0 && isTouched}
        errorText={props?.rawErrors?.[0]}
      />
    </Grid>
  );
}
