import * as yup from 'yup';

const schema = {
  services: yup.array()
    .of(
      yup.string().max(64, 'Keyword must be 64 characters or less.'),
    ).notRequired().nullable(),
  brands: yup.array(yup.string()).notRequired().nullable(),
  paymentTypes: yup.array(yup.string()).notRequired().nullable(),
  languages: yup.array(yup.string()).notRequired().nullable(),
  demographic: yup.array(yup.string()).notRequired().nullable(),
  competitors: yup.array(yup.string()).notRequired().nullable(),
  fulfilledAdditionalInfo: yup.boolean().required('This is a required field.').nullable(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
