import { useEffect, useState } from 'react';
import { Typography, Box, Link } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { useAppDispatch } from 'services/redux/store';
import { ProductTypes, getFiles, getGettyAssets } from 'services/fileUploaderSlice';
import { getPopUp, selectPopUpOrderItemId } from 'services/addons/addonsSlice';
import { useSelector } from 'react-redux';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useParams } from 'react-router';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { selectOrderItems } from 'services/ordersSlice';
import { renderStatusPill } from 'pages/orders/OrdersTable';
import { headingStyle } from '../Business/BusinessSection';
import { ActionItems } from '../common/ActionItems';
import { findAddonId } from './MasterAddonSection';

const mapOptions = {
  callToAction: 'Call to action',
  informational: 'Informational',
  specialOffer: 'Special Offer',
  leadsGeneration: 'Leads Generation',
  arrivalOnPage: 'Arrival on Page',
  timeDelay: 'Time Delay',
  onScroll: 'On Scroll',
  exitIntent: 'Exit Intent',
  formSubmissions: 'Form Submissions',
  phoneCalls: 'Phone Calls',
};

const formatDate = (date :Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const adjustedMonth = month < 9 ? `0${month + 1}` : month + 1;
  const day = date.getDate();
  const adjustedDay = day < 10 ? `0${day}` : day;

  return `${year}-${adjustedMonth}-${adjustedDay}`;
};

export default function PopUpSection({
  product, isEditable, showActionItems, openBusinessAndSalesAgent,
}:any) {
  const { orderId } = useParams();
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const orderItems = useSelector(selectOrderItems);
  const popupOrderItemId = useSelector(selectPopUpOrderItemId);
  const [popupData, setPopupData] = useState({} as any);
  const [stockAssets, setStockAssets] = useState([]);
  const [uploads, setUploads] = useState([] as any);
  const [addonStatus, setAddonStatus] = useState('');
  const addonName = 'popup';

  const getAssets = () => {
    appDispatch(getGettyAssets({ orderId, orderItemId: product?.id, productType: ProductTypes.POP_UP }))
      .then((response) => setStockAssets(response.payload));

    appDispatch(getFiles({
      websiteId: product?.offeringId,
      uploaderType: ProductTypes.POP_UP,
      offeringType: 'MasterAddon',
    })).then((response) => setUploads(response.payload));
  };

  useEffect(() => {
    if (popupOrderItemId) {
      appDispatch(getPopUp(popupOrderItemId)).then((response) => setPopupData(response.payload));
    }
  }, [popupOrderItemId]);

  useEffect(() => {
    if (product) getAssets();
  }, [product]);

  useEffect(() => {
    if (orderItems?.content?.length && addonName) {
      const currentAddon = orderItems.content
        .filter((item) => item.offeringType?.toLowerCase() === addonName?.toLowerCase());
      if (currentAddon.length > 0) setAddonStatus(currentAddon[0].status);
    }
  }, [orderItems]);

  return (
    <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
        Pop-up
        {!isEditable && renderStatusPill(addonStatus)}
      </Typography>

      <Box>
        {/* Pop-up Details */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () =>
            navigate.to(Urls.Popups, product.id))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Pop-up Details</Typography>
        </Box>
        <GridItem sizes={[6, 6]}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Pop-up Type</Typography>
              <Typography>{mapOptions[popupData?.type]}</Typography>
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Trigger</Typography>
              <Typography>{mapOptions[popupData?.trigger]}</Typography>
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Primary Type of Lead</Typography>
              <Typography>{mapOptions[popupData?.primaryTypeLead]}</Typography>
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Button - Call to Action Details</Typography>
              {displayNotRequiredField('details provided', popupData?.actionDetails)}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Form fields</Typography>
              {popupData.firstName && <Typography>First Name</Typography>}
              {popupData.lastName && <Typography>Last Name</Typography>}
              {popupData.email && <Typography>Email Address</Typography>}
              {popupData.phone && <Typography>Phone Number</Typography>}
              {popupData.other && <Typography>{popupData.otherText}</Typography>}
              {(!popupData.firstName && !popupData.lastName && !popupData.email
                && !popupData.phone && !popupData.phone && !popupData.other)
                  && <Typography fontWeight="bold">No form fields provided</Typography>}
            </Box>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Stock Assets</Typography>
              {stockAssets?.length
                ? stockAssets.map((asset, index) => (
                  <Link href={asset.url} key={asset.url}>
                    <Typography>{`Asset ${index + 1}`}</Typography>
                  </Link>
                ))
                : <Typography fontWeight="bold">No assets</Typography>}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Uploads</Typography>
              {uploads?.length
                ? uploads.map((asset) => (
                  <Link href={asset.url}>
                    <Typography style={{ wordBreak: 'break-word' }}>{asset.fileName}</Typography>
                  </Link>
                ))
                : <Typography fontWeight="bold">No uploads</Typography>}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Countdown Timer</Typography>
              {popupData?.date
                ? <Typography>{formatDate(new Date(popupData?.date))}</Typography>
                : <Typography fontWeight="bold">No countdown timer provided</Typography>}
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Content and Design Information</Typography>
              <Typography>{popupData?.contentDesignInformation}</Typography>
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Additional Notes/Instructions</Typography>
              {displayNotRequiredField('additional notes/instructions', popupData?.additionalNotes)}
            </Box>
          </Box>
        </GridItem>

        {/* Actions items */}
        {showActionItems && ActionItems(false, findAddonId(orderItems, addonName), false, openBusinessAndSalesAgent)}
      </Box>
    </GridItem>
  );
}
