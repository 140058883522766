import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import {
  ProductTypes, deleteFile, getFiles, selectFilesUploaded,
} from 'services/fileUploaderSlice';
import {
  clearExistingContentCompleted, getWebsite,
  putWebsiteExistingContent,
  selectExistingContentCompleted,
  selectWebsites,
} from 'services/websitesSlice';

import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import { Urls } from 'navigation/Urls';

import ContentLoader from 'components/contentLoader/ContentLoader';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import ExistingContentForm, { ExistingContentFormProps } from './ExistingContentForm';

export default function ExistingContent() {
  const uploaderType = 'existing-content';
  const history: any = useLocation();
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);

  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const topNav = useTopnavMenu(Urls.ExistingContent);

  const filesUploaded = useSelector(selectFilesUploaded);
  const website = useSelector(selectWebsites);
  const pageCompleted = useSelector(selectExistingContentCompleted);

  const fileList = filesUploaded.content
    .filter(item => item.uploaderType === ProductTypes.EXISTING_CONTENT);

  const onSubmit = (content: ExistingContentFormProps) => {
    if (content.portContentExistingYesNo === 'no') {
      // Note: May be inefficient if there are a lot of files
      fileList.forEach(file => dispatch(deleteFile({ websiteId, file })));
    }

    dispatch(putWebsiteExistingContent({ websiteId, content, offeringType }));
  };

  const onBack = () => {
    navigate.to(Urls.ContentWriting);
  };

  useEffect(() => {
    dispatch(getFiles({ websiteId, uploaderType, offeringType }));
    if (!website?.content?.id) dispatch(getWebsite({ websiteId, offeringType }));
  }, [websiteId]);

  // after we receive the completed flag from redux, we move to the next page and clear the flag
  useEffect(() => {
    if (pageCompleted) {
      if (history.state?.previousPath.includes('overview')) {
        navigate.to(Urls.Overview);
      } else navigate.to(Urls.WebsiteAgeGate);
      dispatch(clearExistingContentCompleted());
    }
  }, [pageCompleted]);

  return (
    <ContentLoader status={website.status} message={website.message}>
      <ExistingContentForm
        onSubmit={onSubmit}
        onBack={onBack}
        defaultFileList={fileList}
        website={website.content}
      />
    </ContentLoader>
  );
}
