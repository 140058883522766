import { FormHelperText, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { IQTheme, ValidationProvider } from '@gannettdigital/shared-react-components';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import i18n from 'i18n/i18n';
import NeSelectChip from 'components/chips/NeSelectChip';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrdersContent } from 'services/ordersSlice';
import {
  getBusinessLocationServiceAreas, getBusinessLocations, selectBusinessLocationServiceAreas,
  selectBusinessLocations, selectProviders,
} from 'services/businessLocationSlice';
import { useEffect, useState } from 'react';
import useMapboxService from 'services/MapboxService';
import { selectCurrentCountry } from 'services/topNavMenuSlice';
import schema from './SolutionStrategy.schema';

const { t } = i18n;

interface Props {
  product: any; // product type to be created
  onSubmit: (data) => void;
  onBack: () => void;
}

export default function SolutionStrategyForm(props: Props) {
  const { product } = props;
  const dispatch = useDispatch();
  const { businessId } = useSelector(selectOrdersContent);
  const locations = useSelector(selectBusinessLocations);
  const providers = useSelector(selectProviders);
  const serviceAreas = useSelector(selectBusinessLocationServiceAreas);
  const currentCountry = useSelector(selectCurrentCountry);
  const [places, setPlaces] = useState([]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      services: product?.services || [],
      areas: product?.areas || [],
      keywords: product?.keywords || [],
      competitors: product?.competitors || '',
      strategy: product?.strategy || '',
      additionalNotes: product?.additionalNotes || '',
    },
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: {
      isValid,
      isDirty,
      isSubmitting,
      isSubmitSuccessful,
    },
  } = methods;

  const { getServiceAreas } = useMapboxService();

  useEffect(() => {
    if (businessId) dispatch(getBusinessLocations(businessId));
  }, [businessId]);

  const prepopulateFields = (listings) => {
    const { id, services, competitors } = listings[listings.length - 1];
    dispatch(getBusinessLocationServiceAreas(id));

    if (!product?.services) setValue('services', services.map(serv => serv.name));
    if (!('competitors' in product)) setValue('competitors', competitors.map(comp => comp.name).join('\n'));
  };

  useEffect(() => {
    if (locations.length > 0) prepopulateFields(locations);
    else if (providers.length > 0) prepopulateFields(providers);
  }, [locations, providers]);

  useEffect(() => {
    if (serviceAreas.content.length > 0 && !product?.areas) {
      setValue('areas', serviceAreas.content.map(area => area.name));
    }
  }, [serviceAreas]);

  const handleFormSubmit = (data) => props.onSubmit(data);

  const getSuggestions = async (e) => {
    const areas = await getServiceAreas(e.query, currentCountry.name);
    const suggestions = areas.map(area => ({ label: area.label, value: area.label }));
    setPlaces(suggestions);
  };

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={props.onBack}
      header="Solution Strategy"
      description={(
        <Trans
          i18nKey="pages.seo.customStrategy.pageDescription"
          components={{ br: <br /> }}
        />
      )}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container>
            <Grid item xs={9} mb={3}>
              <NeSelectChip label labelText={t('pages.seo.solutionStrategy.servicesLabel')} items={[]}
                name="services" placeholder="Type and enter to add an item"
                values={product?.services} maxItems={0} freeSolo
                disableClear required={false} schema={schema}
                theme={IQTheme}
              />
            </Grid>
            <Grid item xs={9} mb={3}>
              <NeSelectChip label labelText={t('pages.seo.solutionStrategy.areasLabel')}
                items={places}
                name="areas" placeholder="Type and enter to add an item"
                values={product?.areas} maxItems={0} freeSolo={false}
                disableClear required={false} schema={schema}
                theme={IQTheme}
                getSuggestions={(e) => getSuggestions(e)}
                supportCustomChips={false}
              />
            </Grid>
            <Grid item xs={9} mb={3}>
              <NeSelectChip label labelText={t('pages.seo.solutionStrategy.keywordsLabel')} items={[]}
                name="keywords" placeholder="Type and enter to add an item"
                values={product?.keywords} maxItems={5} freeSolo
                disableClear required={false} schema={schema}
                theme={IQTheme}
              />
            </Grid>
            <Grid item xs={9} mb={3}>
              <NeFormTextArea
                {...register('competitors')}
                id="competitors"
                name="competitors"
                labelText={t('pages.seo.solutionStrategy.competitorsLabel')}
                fullWidth
                rowCount={2}
                fontLabelWeight="600"
              />
              <FormHelperText>{t('pages.seo.customStrategy.competitorsHelperText')}</FormHelperText>
            </Grid>
            <Grid item xs={9} mb={3}>
              <NeFormTextArea
                {...register('strategy')}
                id="strategy"
                name="strategy"
                labelText={t('pages.seo.solutionStrategy.strategyLabel')}
                fullWidth
                rowCount={2}
                fontLabelWeight="600"
              />
            </Grid>
            <Grid item xs={9} mb={3}>
              <NeFormTextArea
                {...register('additionalNotes')}
                id="additionalNotes"
                name="additionalNotes"
                labelText={t('pages.seo.solutionStrategy.additionalLabel')}
                fullWidth
                rowCount={2}
                fontLabelWeight="600"
              />
            </Grid>
          </Grid>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
