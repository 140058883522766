import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import {
  clearEmailHostingCompleted, getAddons,
  getEmailHosting,
  getEmailHostingEmails, getMasterAddonDetails,
  putEmailHosting,
  putEmailHostingEmails, selectAddons,
  selectEmailHosting,
  selectEmailHostingCompleted,
  selectEmailHostingEmails,
  selectEmailHostingOrderItemId,
} from 'services/addons/addonsSlice';
import { getOrderItems } from 'services/ordersSlice';
import { Urls } from 'navigation/Urls';
import ContentLoader from 'components/contentLoader/ContentLoader';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import EmailHostingForm from './EmailHostingForm';

export default function EmailHosting() {
  const { orderId, orderItemId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const addons = useSelector(selectAddons);
  const emailHosting = useSelector(selectEmailHosting);
  const emailHostingMails = useSelector(selectEmailHostingEmails);
  const emailHostingCompleted = useSelector(selectEmailHostingCompleted);
  const emailHostingItemId = useSelector(selectEmailHostingOrderItemId);
  const topNav = useTopnavMenu(Urls.EmailHosting);
  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  useEffect(() => {
    if (orderId) {
      dispatch(getEmailHostingEmails({ orderId }));
    }
    if (!emailHostingItemId) {
      dispatch(getOrderItems(orderId));
    }
  }, []);

  const onSubmit = (data) => {
    const emailHostingData = (({ hostingChoice, emailArchiving, additionalNotes }) =>
      ({ hostingChoice, emailArchiving, additionalNotes }))(data);
    const emailHostingEmailsData = (({ emailAccounts }) => ({ emailAccounts }))(data);
    dispatch(putEmailHosting({
      orderItemId: emailHostingItemId, content: emailHostingData,
    }));

    dispatch(putEmailHostingEmails({
      orderItemId: emailHostingItemId, content: emailHostingEmailsData.emailAccounts,
    }));
  };

  const onBack = () => {
    navigate.to(topNav.previousStep());
  };

  useEffect(() => {
    if (emailHostingItemId) {
      dispatch(getEmailHosting({ orderItemId: emailHostingItemId }));
    }
  }, [emailHostingItemId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (emailHostingCompleted) {
      dispatch(clearEmailHostingCompleted());
      if (isRedirectedFromOverviewPage) return navigate.to(Urls.Overview);
      navigate.to(topNav.nextStep());
    }
  }, [emailHostingCompleted]);

  useEffect(() => {
    if (!addons?.content?.id) dispatch(getAddons(orderId));
    if (orderItemId) dispatch(getMasterAddonDetails(orderItemId));
  }, [addons, orderItemId]);

  if (!emailHostingItemId) {
    return (
      <ContentLoader
        status="loading"
        message=""
      />
    );
  }

  return (
    <ContentLoader
      status={[emailHosting.status, emailHostingMails.status]}
      message={[emailHosting.message, emailHostingMails.message]}
    >
      <EmailHostingForm
        onBack={onBack}
        onSubmit={onSubmit}
        emailHosting={emailHosting.content}
        emailHostingEmails={emailHostingMails.content}
      />
    </ContentLoader>
  );
}
