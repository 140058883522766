import {
  IQButtonLink,
  IQFormInput,
  IQTheme, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import React, {useEffect} from 'react';
import {Dialog, DialogContent, DialogTitle, Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';
import GridItem from 'components/grid/GridItem';
import schema from './AdGroups.schema';
import {FormProvider, useForm} from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {v4 as uuidv4} from 'uuid';
import CoNavigationConfirm from "components/navigation/CoNavigationConfirm";
import GridContainer from "components/grid/GridContainer";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

export default function AdGroupsFormDialog({
                                             open,
                                             onOpen,
                                             onClose,
                                             updateAdGroup,
                                             selectedAdGroup,
                                             adGroupLength
                                           }){
  const {t} = useTranslation();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      adGroupFocus: '',
      adGroupLandingPage: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
    setValue,
  } = methods;

  useEffect(() => {
    if (selectedAdGroup) {
      // Populate form fields with selectedAdGroup data
      setValue("adGroupFocus", selectedAdGroup.adGroupFocus);
      setValue("adGroupLandingPage", selectedAdGroup.adGroupLandingPage);
    }
  }, [selectedAdGroup]);

  const handleFormSubmit = async (data) => {

    let {sourceId} = selectedAdGroup || {};

    if (!sourceId) {
      // Generate a unique sourceId if it does not exist
      sourceId = uuidv4();
    }

    // Add life events with sourceId
    updateAdGroup({...data, sourceId});

    // Close the form
    handleClose();
  };

  const handleOpen = () => {
    // Reset the form state
    reset({
      adGroupFocus: '',
      adGroupLandingPage: '',
    });
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  return (
      <>
        <Grid sx={{
          marginTop: -4
        }}>
          {<IQButtonLink disabled={adGroupLength>10} startIcon={<AddIcon/>} onClick={() => handleOpen()}>
            {t('pages.xmo.sem.addAdGroupTitle')}
          </IQButtonLink>}
        </Grid>
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
          <DialogTitle>{'Ad Group'}</DialogTitle>
          <DialogContent>
            <ValidationProvider schema={schema}>
              <FormProvider {...methods}>
                <form>
                  <GridContainer fullWidth>
                    <GridItem sizes={[12]}>
                      <IQFormInput
                          id="adGroupFocus"
                          name="adGroupFocus"
                          labelText={t('pages.xmo.sem.adGroupFocus')}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          schema={schema}
                          showError
                      />
                    </GridItem>
                    <GridItem sizes={[12]}>
                      <IQFormInput
                          id="adGroupLandingPage"
                          name="adGroupLandingPage"
                          labelText={t('pages.xmo.sem.adGroupLandingPage')}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          schema={schema}
                          adornmentIcon={<InsertLinkIcon/>}
                          showError
                      />
                    </GridItem>
                  </GridContainer>
                </form>
                <CoNavigationConfirm
                    showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
                />
              </FormProvider>
            </ValidationProvider>
          </DialogContent>
          <DialogActions>
            <Button
                onClick={handleClose}>{t('pages.youtube.advertising.adGroupOptions.CloseBtn')}</Button>
            <Button disabled={!isValid}
                    onClick={handleSubmit(handleFormSubmit)}>{t("pages.youtube.advertising.adGroupOptions.submitBtn")}</Button>
          </DialogActions>
        </Dialog>
      </>
  );
}