import i18n from 'i18n/i18n';
import { RJSFSchema } from '@rjsf/utils';
import AddAssetGroup from "../../custom-fields/AssetGroup";
const { t } = i18n;

export const AssetGroupsSchema: RJSFSchema | any = (data) => ({
    title: t('pages.ausProduct.ausPerformanceMax.assetGroup.title'),
    type: 'object',
    required: [],
    properties: {
        assetGroupInGooglePerformance: {
            type: 'string',
            title:"",
            description: t('pages.ausProduct.ausPerformanceMax.assetGroup.assetGroupInGooglePerformance'),
        },
        assetGroups: {
            title: t("pages.customTracking.solutionStrategy.webEventsLabel"),
            type: "array",
            default: data?.assetGroups || [],
            items: {
              type: "object",
              properties: {
                sitelinkName: { type: "string" },
                sitelinkUrl: { type: "string" },
                productWantToFocus: { type: "string" },
                keySearchThemeLikeToFocus: { type: "string" },
                idealCustomer: { type: "string" },
                ageRangeOfYourTargetAudience: { type: "string" }
              },
            },
        },
    },
});

const textLabelFieldKeys = [
    'assetGroupInGooglePerformance',
];

const textLabelFields = textLabelFieldKeys.reduce((o, key) => ({
    ...o,
    [key]: { 'ui:widget': 'textLabel' },
}), {});

export const AssetGroupsUISchema = {
    'ui:order': ['assetGroupInGooglePerformance', 'assetGroup1','assetGroups','productWantToFocus','keySearchThemeLikeToFocus','idealCustomer','ageRangeOfYourTargetAudience'],
    assetGroups: { "ui:widget": AddAssetGroup },
    ...textLabelFields,
};

export const AssetGroupsCustomValidate = (formData: any, errors, uiSchema) => {
    return errors;
};
