import { createReducer } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { StatusType } from 'models/StatusType';
import AxiosAPI from './common/axios';
import { thunkBuilder } from './common/functions';
import { createAsyncThunkCatchErrors } from './errorSlice';
import { RootState } from './redux/store';

export interface MaintenanceModeState {
  maintenanceModeData: StatusType<any[]>
}

const initialState: MaintenanceModeState = {
  maintenanceModeData: {
    status: 'idle',
    message: '',
    content: [],
  },
};

export interface ToggleRequest {
  id: number,
  maintenanceMode: boolean,
  user: string,
  sourceId: null,
}

export const getMaintenanceModes = createAsyncThunkCatchErrors(
  'maintenanceModes/get',
  async () => {
    const response = await AxiosAPI.get('/maintenance/maintenance_modes');
    return response.data;
  },
);

const maintenanceModeSlice = createReducer(
  initialState,
  (builder) => {
    thunkBuilder(builder)
      .addCase(getMaintenanceModes, 'maintenanceModeData');
  },
);

// toggle maintenance mode
export const toggleMaintenaceMode = createAsyncThunkCatchErrors(
  'maintenance/toggleMaintenanceMode',
  async (request: ToggleRequest) => {
    const response = await AxiosAPI.post('/maintenance/maintenance_modes', request);
    return response.data;
  },
);

export const selectMaintenanceModes = (state: RootState) => state.maintenanceModes.maintenanceModeData.content;

export const getCurrentMaintenanceMode = () => {
  let newStatusObj = {};

  const mModes = useSelector(selectMaintenanceModes);
  const maintenaceModes = [...mModes].reverse();
  newStatusObj = { ...maintenaceModes[0] };

  let newMaintenanceModeStatus = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in newStatusObj) {
    if (key === 'maintenanceMode') {
      newMaintenanceModeStatus = newStatusObj[key];
    }
  }
  const maintenanceMode = newMaintenanceModeStatus === 0 ? 'OFF' : 'ON';
  return maintenanceMode;
};

export default maintenanceModeSlice;
