import i18n from 'i18n/i18n';
import * as yup from 'yup';

const { t } = i18n;

const schema = {
  locationName: yup.string().required(t('pages.premiumListings.location.healthcare.address.providerNameRequired')),
  prePopulate: yup.string().when(['prePopulate'], {
    is: (prePopulate) => prePopulate !== 'doNot',
    then: yup.string().required(t('pages.premiumListings.location.healthcare.address.prePopulateRequired')),
  }),
  businessAddressOne: yup.string().required(
    t('pages.premiumListings.location.healthcare.address.addressLine1Required'),
  ),
  businessAddressTwo: yup.string().notRequired().nullable().optional(),
  city: yup.string().required(t('pages.premiumListings.location.healthcare.address.cityRequired')),
  stateProvince: yup.string().required(t('pages.premiumListings.location.healthcare.address.stateRequired')),
  postalCode: yup.string().required(t('pages.premiumListings.location.healthcare.address.zipCodeRequired')),
  country: yup.string().required(t('pages.premiumListings.location.healthcare.address.countryRequired')),
  serviceAreas: yup.array().notRequired().nullable().optional()
    .max(20, t('pages.premiumListings.location.healthcare.address.serviceAreaMax')),
  locationIdentifier: yup.string().notRequired().nullable().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
