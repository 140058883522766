import {
  Box, Button, Link, Modal, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import failedIcon from 'icons/failed.svg';
import successIcon from 'icons/success.svg';
import loadingIcon from 'icons/command_loading.gif';
import { selectCommands } from 'services/carbonSlice';

interface Props {
  open: boolean
  onClose: () => void
  onRunAnother: () => void
  siteName: string
}

export default function CommandModal({
  open, onClose, onRunAnother, siteName,
}: Props) {
  const [modalTitle, setModalTitle] = useState('');
  const commands = useSelector(selectCommands);

  const commandRunResults = (command) => command.status === 'success' || command.status === 'failed';
  const allResultsReceived = commands.content.every(commandRunResults);

  useEffect(() => {
    if (commands.status === 'loading') {
      setModalTitle('Command Processing');
    } else if (commands.status === 'idle') {
      setModalTitle('Command Complete');
    } else {
      setModalTitle('Command Error');
    }
  }, [commands.status]);

  const getStatusIcon = (status: string) => {
    if (status === ('loading' || 'idle')) {
      return loadingIcon;
    }

    if (status === 'failed') {
      return failedIcon;
    }

    return successIcon;
  };

  const getStatusColor = (status: string) => {
    if (status === 'loading') {
      return '#0046FF';
    }

    if (status === 'failed') {
      return '#C20F1E';
    }

    return '#008059';
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ margin: 'auto', border: '1px solid' }}
    >
      <Box sx={{
        background: 'white',
        margin: 'auto',
        marginTop: '80px',
        padding: '10px',
        width: '500px',
        minHeight: '400px',
        borderRadius: '8px',
      }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h3" marginBottom={4} marginTop={6}
            {...commands.status === 'failed' && { color: getStatusColor(commands.status) }}
          >
            {modalTitle}
          </Typography>
          <Typography variant="h5" marginBottom={3}>{siteName}</Typography>
          <img src={getStatusIcon(commands.status)} alt="status" style={{ marginBottom: 40 }} />
          <Box>
            {commands.content.map(command => (
              <Box display="flex" flexDirection="row" alignItems="center"
                marginBottom={2} key={command.name}
              >
                {commands.content.length > 0 && (
                  <img src={getStatusIcon(command.status)} alt="status"
                    style={{ width: '24px', height: '24px', marginRight: '10px' }}
                  />
                )}

                {(command.name === 'Unfreeze') && (
                  <Box>
                    {(commands.status === 'idle') && (
                      <Typography variant="subtitle1" mt={5}>
                        Please visit
                        {' '}
                        <Link href={`https://dev-${siteName}.pantheonsite.io`} target="_blank">{siteName}</Link>
                        {' '}
                        in 15-20 minutes.
                      </Typography>
                    )}
                    <Typography
                      textAlign="center"
                      color="#737373"
                      style={{ paddingRight: '20px' }}
                    >
                      {command.name}
                    </Typography>
                  </Box>
                )}

                {(command.name !== 'Unfreeze') && (
                  <Typography
                    alignItems="center"
                    color="#737373"
                    style={{ paddingRight: '20px' }}
                  >
                      {command.name}
                  </Typography>
                )}
              </Box>

            ))}
          </Box>
          {commands.status !== 'loading' && (
            <Box display="flex" flexDirection="row" marginY={5}>
              {(allResultsReceived) && (
              <Button variant="contained" color="primary" onClick={onRunAnother}>
                <Typography fontWeight="bold">Run Another Command</Typography>
              </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
