/* eslint-disable no-multi-spaces */
import * as yup from 'yup';

const notNullMessage            = 'Can\'t be null.';
const noLeadingWhiteSpace       = 'No leading whitespace.';
const minimumLengthMessage      = 'Five digits minimum.';
const numericStringMessage      = 'Numerals only.';

const leadingWhitespace         = /^(?!\s+)/;
const minimumLength             = /^\d{5,}$/;
const numeralsOnly              = /^[0-9]*$/;

const schema = {
  itemId: yup.string().required(notNullMessage)
    .matches(leadingWhitespace, noLeadingWhiteSpace)
    .matches(numeralsOnly, numericStringMessage)
    .matches(minimumLength, minimumLengthMessage),
  campaignId: yup.string().required(notNullMessage)
    .matches(leadingWhitespace, noLeadingWhiteSpace)
    .matches(numeralsOnly, numericStringMessage)
    .matches(minimumLength, minimumLengthMessage),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
