import i18n from 'i18n/i18n';

const { t } = i18n;

export const standardLogoOptions: any = {
  name: 'standardLogo',
  label: t('pages.premiumListings.location.photos.standardLogoTitle'),
  supportingText: null,
  showRecommendedImageText: false,
  showAcceptedFileTypesText: true,
  showMaxNumberFilesText: true,
  showCheckboxes: false,
  showComments: false,
  uploaderType: 'logo_attachments',
  required: false,
  maxFiles: 1,
  totalAllowedFiles: 1,
  maxFileSize: 5000000, // 5mb
  imageTypes: ['.jpg', '.png'],
  videoTypes: [],
  documentTypes: [],
  size: 'md',
};

export const squareLogoOptions: any = {
  name: 'squareLogo',
  label: t('pages.premiumListings.location.photos.squareLogoTitle'),
  supportingText: t('pages.premiumListings.location.photos.squareLogoSupportingText'),
  showRecommendedImageText: true,
  recommendedImgText: t('pages.premiumListings.location.photos.squareLogoRecommendedSize'),
  showAcceptedFileTypesText: true,
  showMaxNumberFilesText: true,
  showCheckboxes: false,
  showComments: false,
  uploaderType: 'square_logo_attachments',
  required: false,
  maxFiles: 1,
  totalAllowedFiles: 1,
  maxFileSize: 5000000, // 5mb
  imageTypes: ['.jpg', '.png'],
  videoTypes: [],
  documentTypes: [],
  size: 'md',
};

export const locationPhotosOptions: any = {
  name: 'locationPhotos',
  label: t('pages.premiumListings.location.photos.locationPhotosTitle'),
  supportingText: t('pages.premiumListings.location.photos.locationPhotosSupportingText'),
  showRecommendedImageText: false,
  showAcceptedFileTypesText: true,
  showMaxNumberFilesText: true,
  showCheckboxes: false,
  showComments: false,
  showRadioButtons: true,
  radioGroupName: 'category',
  radioGroupDefaultValue: 'additional',
  uploaderType: 'picture_attachments',
  required: false,
  maxFiles: 4,
  totalAllowedFiles: 40,
  maxFileSize: 20000000, // 20mb
  imageTypes: ['.jpg', '.png'],
  videoTypes: [],
  documentTypes: [],
  size: 'md',
};
