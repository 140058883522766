import { ExistingContentFormProps } from 'pages/websites/content/existing-content/ExistingContentForm';

import EcommerceType from 'models/EcommerceType';
import WebsitesType from 'models/WebsitesType';

import AxiosAPI from '../common/axios';

interface SitesEndpointsFunctionsType {
  getSite: (id: string, orderItemId?: string) => any,
  putSite?: (orderId: string, orderItemId: string, content: EcommerceType) => any,
  putWebsiteAdditionalFeatures?: (websiteId: string, content: any) => any,
  putWebsiteAdditionalFeaturesBlog?: (websiteId: string, content: any) => any,
  putWebsiteCurrentStep?: (websiteId: string, section: string, step: string) => any,
  putWebsiteExamples?: (websiteId: string, content: any) => any,
  putWebsiteExistingContent?: (websiteId: string, content: ExistingContentFormProps) => any,
  postSitePage?: (websiteId: string, content: any) => any,
  putSitePage?: (content: any) => any,
  deleteSitePage?: (content: any) => any,
  putWebsiteSitePagesNotes?: (websiteId: string, content: any) => any,
  getSitePages?: (websiteId: string) => any,
  putWebsiteAgeGate?:(websiteId: string, content: any) => any,
}

interface SitesEndpointsType {
  Website: SitesEndpointsFunctionsType,
  GoldSite: SitesEndpointsFunctionsType,
}

const SitesEndpoints: SitesEndpointsType = ({
  Website: {
    getSite: async websiteId => {
      const response = await AxiosAPI.get(`/websites/${websiteId}`);
      return response.data;
    },
    putWebsiteAdditionalFeatures: async (websiteId: string, content: any) => {
      const response = await AxiosAPI.put(`/websites/${websiteId}/additional-features`, content);
      return response.data;
    },
    putWebsiteAdditionalFeaturesBlog: async (websiteId: string, content: any) => {
      const response = await AxiosAPI.put(`/websites/${websiteId}/additional-features-blog`, content);
      return response.data;
    },
    putWebsiteCurrentStep: async (websiteId: string, section: string, step: string) => {
      const response = await AxiosAPI.put(
        `/websites/${websiteId}/current-step/`,
        { currentSection: section, currentStep: step },
      );
      return response.data;
    },
    putWebsiteExamples: async (websiteId: string, content: any) => {
      const response = await AxiosAPI.put(
        `/websites/${websiteId}/examples`,
        content.examples,
        {
          headers: {
            'Content-Type': 'text/raw',
          },
        },
      );
      return response.data;
    },
    putWebsiteExistingContent: async (websiteId: string, content: WebsitesType) => {
      const response = await AxiosAPI.put(`/websites/${websiteId}/existing-content`, content);
      return response.data;
    },
    postSitePage: async (websiteId: string, content: any) => {
      const response = await AxiosAPI.post(
        `/websites/${websiteId}/pages`,
        { websiteId, ...content },
      );
      return response.data;
    },
    putSitePage: async (content: any) => {
      const response = await AxiosAPI.put('/websites/pages', content);
      return response.data;
    },
    deleteSitePage: async (content: any) => {
      // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
      const response = await AxiosAPI.delete(`/websites/pages/${content?.id}`);
      return content?.id;
    },
    putWebsiteSitePagesNotes: async (websiteId: string, content: any) => {
      const response = await AxiosAPI.put(`/websites/${websiteId}/site-page-notes`, content, {
        headers: {
          'Content-Type': 'text/raw',
        },
      });
      return response.data;
    },
    getSitePages: async (websiteId: string) => {
      const response = await AxiosAPI.get(`/websites/${websiteId}/pages`);
      return response.data.content;
    },
    putWebsiteAgeGate: async (websiteId: string, content: any) => {
      const response = await AxiosAPI.put(`/websites/${websiteId}/age-gate`, content);
      return response.data;
    },
  },
  GoldSite: {
    getSite: async websiteId => {
      const response = await AxiosAPI.get(`/gold-sites/${websiteId}`);
      return response.data;
    },
    putWebsiteAdditionalFeatures: async (websiteId: string, content: any) => {
      const response = await AxiosAPI.put(`/gold-sites/${websiteId}/additional-features`, content);
      return response.data;
    },
    putWebsiteAdditionalFeaturesBlog: async (websiteId: string, content: any) => {
      const response = await AxiosAPI.put(`/gold-sites/${websiteId}/additional-features-blog`, content);
      return response.data;
    },
    putWebsiteCurrentStep: async (websiteId: string, section: string, step: string) => {
      const response = await AxiosAPI.put(
        `/gold-sites/${websiteId}/current-step/`,
        { currentSection: section, currentStep: step },
      );
      return response.data;
    },
    putWebsiteExamples: async (websiteId: string, content: any) => {
      const response = await AxiosAPI.put(
        `/gold-sites/${websiteId}/examples`,
        content.examples,
        {
          headers: {
            'Content-Type': 'text/raw',
          },
        },
      );
      return response.data;
    },
    putWebsiteExistingContent: async (websiteId: string, content: WebsitesType) => {
      const response = await AxiosAPI.put(`/gold-sites/${websiteId}/existing-content`, content);
      return response.data;
    },
    postSitePage: async (websiteId: string, content: any) => {
      const response = await AxiosAPI.post(
        `/gold-sites/${websiteId}/pages`,
        { goldSiteId: websiteId, ...content },
      );
      return response.data;
    },
    putSitePage: async (content: any) => {
      const response = await AxiosAPI.put('/gold-sites/pages', content);
      return response.data;
    },
    deleteSitePage: async (content: any) => {
      // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
      const response = await AxiosAPI.delete(`/gold-sites/pages/${content?.id}`);
      return content?.id;
    },
    putWebsiteSitePagesNotes: async (websiteId: string, content: any) => {
      const response = await AxiosAPI.put(`/gold-sites/${websiteId}/site-page-notes`, content, {
        headers: {
          'Content-Type': 'text/raw',
        },
      });
      return response.data;
    },
    getSitePages: async (websiteId: string) => {
      const response = await AxiosAPI.get(`/gold-sites/${websiteId}/pages`);
      return response.data.content;
    },
    putWebsiteAgeGate: async (websiteId: string, content: any) => {
      const response = await AxiosAPI.put(`/gold-sites/${websiteId}/age-gate`, content);
      return response.data;
    },
  },
});

export default SitesEndpoints;
