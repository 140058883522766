import { FormProvider, useForm } from 'react-hook-form';

import { Box, Grid } from '@mui/material';
import { IQFormInput, IQThemeV2, ValidationProvider } from '@gannettdigital/shared-react-components';

import LandingPageType from 'models/LandingPageType';

import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';

import { LandingPageInfoTextareas } from 'pages/landing-pages/content/info/LandingPageInfoTextareas';

import { t } from 'i18next';
import schema from './LandingPageInfo.schema';

interface Props {
  product: LandingPageType;
  onSubmit: (data :LandingPageInfoProps) => void;
  onBack: () => void;
}

export type LandingPageInfoProps = {
  pageName?: string;
  targetMessaging?: string;
  featuredOffers?: string;
  otherNotes?: string;
};

export default function LandingPageInfoForm(props: Props) {
  const { product } = props;

  const methods = useForm<LandingPageInfoProps>({
    mode: 'all',
    defaultValues: {
      pageName: product?.pageName || '',
      targetMessaging: product?.targetMessaging || '',
      featuredOffers: product?.featuredOffers || '',
      otherNotes: product?.otherNotes || '',
    },
  });
  const {
    handleSubmit,
    formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful,
    },
  } = methods;

  const onSubmit = (data: LandingPageInfoProps) => props.onSubmit(data);

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={props.onBack}
      header={t('pages.landing.pageInfo.header')}
      onContinue={handleSubmit(onSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid
              container
              display="flex"
              flexDirection="row"
              gap="24px"
            >
              <Grid
                item
                xs={6}
                sx={{ label: { pb: '7px' } }}
              >
                <IQFormInput
                  id="page-name"
                  labelText={t('pages.landing.pageInfo.pageName')}
                  name="pageName"
                  theme={IQThemeV2}
                  fontLabelWeight="600"
                  fullWidth
                  showError
                  schema={schema}
                />
              </Grid>
              <LandingPageInfoTextareas />
            </Grid>
            {/* show the dialog if we changed the field and didn't submit the changes */}
            <CoNavigationConfirm
              showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </Box>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
