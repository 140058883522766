import * as yup from 'yup';
import i18n from 'i18n/i18n';
import {urlValidationRegex} from "shared/constants";

const { t } = i18n;

// eslint-disable-next-line max-len, no-useless-escape

const schema = {
  adGroupFocus: yup.string().required(t('pages.xmo.sem.adGroupFocusErrorMessage')),
  adGroupLandingPage: yup.string()
    .matches(urlValidationRegex, t('pages.xmo.sem.adGroupLandingPageErrorMessage'))
    .required(t('pages.xmo.sem.adGroupLandingPageErrorMessage')),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
