const getCountries = () => [
  {
    value: 'usa',
    description: 'United States',
  },
  {
    value: 'can',
    description: 'Canada',
  },
  {
    value: 'aus',
    description: 'Australia',
  },
  {
    value: 'nzl',
    description: 'New Zealand',
  },
];

export const BusinessFunctions = {
  getCountries,
};
