/* eslint-disable no-unused-vars */
import { combineReducers } from 'redux';
import chatLocationsReducer from 'services/chatLocationsSlice';
import recentCarbonCommandsReducer from 'services/recentCarbonCommandsSlice';
import sitePagesReducer from '../sitePagesSlice';
import domainsReducer from '../domainsSlice';
import websitesReducer from '../websitesSlice';
import contentTonesReducer from '../contentTonesSlice';
import fileUploaderReducer from '../fileUploaderSlice';
import navMenuReducer from '../navigationSlice';
import ordersReducer from '../ordersSlice';
import addonsReducer from '../addons/addonsSlice';
import errorsReducer from '../errorSlice';
import productsReducer from '../productsSlice';
import businessReducer from '../businessSlice';
import addressReducer from '../addressSlice';
import topNavMenuReducer from '../topNavMenuSlice';
import themesReducer from '../themesSlice';
import cartReducer from '../cartSlice';
import adminReducer from '../adminSlice';
import clientsReducer from '../clientsSlice';
import businessLocationReducer from '../businessLocationSlice';
import businessRolesReducer from '../businessRolesSlice';
import premiumListingsReducer from '../premiumListingsSlice';
import userReducer from '../userSlice';
import maintenanceModesReducer from '../maintenanceModeSlice';
import salesAgentReducer from '../salesAgentSlice';
import healthcareReducer from '../healthcareSlice';
import myOrdersReducer from '../myOrdersSlice';
import offerMappingReducer from '../offerMappingSlice';
import carbonReducer from '../carbonSlice';
import customLandingPageReducer from '../customLandingPageSlice';
import customWebsiteReducer from '../customWebsiteSlice';
import campaignReducer from '../campaignSlice';
import clientStockAssetsReducer from '../clientStockAssetsSlice';
import generalQuestionsReducer from '../generalQuestionsSlice';
import cefReducer from '../cefSlice';
import punchlistReducer from '../punchlistSlice';
import schedulerReducer from '../schedulerSlice';
import webEventsReducer from '../addWebEventSlice';
import genericFormReducer from '../genericForm';
import placeDetailReducer from '../placeDetailSlice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rootReducer = (history) => combineReducers({
  addons: addonsReducer,
  sitePages: sitePagesReducer,
  domains: domainsReducer,
  websites: websitesReducer,
  contentTones: contentTonesReducer,
  fileUploader: fileUploaderReducer,
  navMenu: navMenuReducer,
  orders: ordersReducer,
  errors: errorsReducer,
  products: productsReducer,
  business: businessReducer,
  address: addressReducer,
  clients: clientsReducer,
  businessLocation: businessLocationReducer,
  businessRoles: businessRolesReducer,
  chatLocations: chatLocationsReducer,
  topNavMenu: topNavMenuReducer,
  themes: themesReducer,
  cart: cartReducer,
  admin: adminReducer,
  premiumListings: premiumListingsReducer,
  user: userReducer,
  salesAgent: salesAgentReducer,
  healthcare: healthcareReducer,
  myOrders: myOrdersReducer,
  offersMap: offerMappingReducer,
  carbon: carbonReducer,
  maintenanceModes: maintenanceModesReducer,
  customLandingPage: customLandingPageReducer,
  recentCarbonCommands: recentCarbonCommandsReducer,
  customWebsite: customWebsiteReducer,
  campaign: campaignReducer,
  clientStockAssets: clientStockAssetsReducer,
  generalQuestions: generalQuestionsReducer,
  cef: cefReducer,
  punchlist: punchlistReducer,
  genericForm: genericFormReducer,
  scheduler: schedulerReducer,
  webEvents: webEventsReducer,
  placeDetails: placeDetailReducer,
});

export default rootReducer;
