import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import TextField from "pages/generic-product/details/custom-fields/TextField";

const {t} = i18n;

export const LinkedinSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.linkedin.title'),
  type: 'object',
  required: ['trackingRequirements'],
  properties: {
    trackingRequirements: {
      type: 'string',
      title: t('pages.ausProduct.linkedin.trackingRequirementsLabel'),
      default: '',
      enum: [
          '',
        t('pages.ausProduct.linkedin.trackingRequirementsOpt1'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt2'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt3'),
        t('pages.ausProduct.linkedin.trackingRequirementsOpt4'),
      ],
    },
    describeTheLocationTargeting: {
      type: 'string',
      title: t('pages.ausProduct.linkedin.describeTheLocationTargetingLabel'),
      description: t('pages.ausProduct.linkedin.describeTheLocationTargetingHelpText'),
      default: data?.describeTheLocationTargeting || '',
    },
    describeYourAudience: {
      type: 'string',
      title: t('pages.ausProduct.linkedin.describeYourAudienceLabel'),
      description: t('pages.ausProduct.linkedin.describeYourAudienceHelpText'),
      default: data?.describeYourAudience || '',
    },
    haveYouObtainedCreatives: {
      type: 'string',
      title: t('pages.ausProduct.linkedin.haveYouObtainedCreativesLabel'),
      description: t('pages.ausProduct.linkedin.haveYouObtainedCreativesHelpText'),
      default: '',
      enum: [
        '',
        t('pages.ausProduct.tracking.yes'),
        t('pages.ausProduct.tracking.no'),
      ],
    },
    creativeGuidelinesLinks: {
      type: 'string',
      title: t('pages.ausProduct.linkedin.creativeGuidelinesLinkTitle'),
      enum: [t('pages.ausProduct.linkedin.creativeGuidelinesLink'), true]
    },
    additionalBuildComments: {
      type: 'string',
      title: t('pages.ausProduct.linkedin.additionalBuildCommentsLabel'),
      description: t('pages.ausProduct.linkedin.additionalBuildCommentsHelpText'),
      default: data?.additionalBuildComments || '',
    },
  },
});

const dropdownFieldKeys = [
  'trackingRequirements',
  'haveYouObtainedCreatives',
];

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const inputFieldKeys = [
  'describeTheLocationTargeting',
  'describeYourAudience',
  'additionalBuildComments',
];

const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

export const LinkedinUISchema = {
  'ui:order': [
    'trackingRequirements',
    'describeTheLocationTargeting',
    'describeYourAudience',
    'haveYouObtainedCreatives',
    'creativeGuidelinesLinks',
    'additionalBuildComments',
  ],
  creativeGuidelinesLinks: {
    'ui:widget': 'anchorTextLabel',
    'ui:style': {
      marginTop: '-15px',
    },
  },
  ...inputFields,
  ...dropdownFields,
};

export const LinkedinCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.trackingRequirements === '') {
    errors.trackingRequirements.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
