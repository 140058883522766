import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import {
  clearAgeGateCompleted, getWebsite,
  putWebsiteAgeGate,
  selectAgeGateCompleted,
  selectWebsites,
} from 'services/websitesSlice';

import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import { Urls } from 'navigation/Urls';

import ContentLoader from 'components/contentLoader/ContentLoader';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import AgeGateForm, { AgeGateFormProps } from './AgeGateForm';

export default function AgeGate() {
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const history: any = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const topNav = useTopnavMenu(Urls.WebsiteAgeGate);

  const website = useSelector(selectWebsites);
  const pageCompleted = useSelector(selectAgeGateCompleted);

  const onSubmit = (content: AgeGateFormProps) => {
    dispatch(putWebsiteAgeGate({ websiteId, content, offeringType }));
  };

  const onBack = () => {
    navigate.to(Urls.ExistingContent);
  };

  useEffect(() => {
    if (pageCompleted) {
      if (history.state?.previousPath.includes('overview')) {
        navigate.to(Urls.Overview);
      } else navigate.to(Urls.AdditionalFeaturesContent);
      dispatch(clearAgeGateCompleted());
    }
  }, [pageCompleted]);

  useEffect(() => {
    if (!website?.content?.id) dispatch(getWebsite({ websiteId, offeringType }));
  }, [websiteId]);

  return (
    <ContentLoader status={website.status} message={website.message}>
      <AgeGateForm
        onBack={onBack}
        onSubmit={onSubmit}
        product={website.content}
      />
    </ContentLoader>
  );
}
