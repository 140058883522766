import {
  Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Modal, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

interface Props {
  open: boolean
  handleClose: () => void
  handleContinue: () => void
  type: 'landingPage' | 'website' | 'premiumListings'
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '1px solid rgba(0,0,0,0.2)',
  borderRadius: '6px',
  boxShadow: '0 5px 15px rgba(0,0,0,0.5)',
  p: '5px',
};

const comparisonUrl = 'https://order.localiq.com'
  + '/assets/Website-Tier-Comparison-e03b81f2aff6b35d58e14cbf3d3eeea1d951e510d2c3760f974e1823c7e58688.pdf';

export const ModalParagraph = (props: any) => (
  <Typography fontSize="18px" marginBottom={3}>{props.children}</Typography>
);

export default function ThemeDisclaimerModal({
  open, handleClose, type, handleContinue,
}: Props) {
  const [userAccepted, setUserAccepted] = useState(false);
  const [acceptLocationListings, setAcceptLocationListings] = useState(false);
  const [acceptHealthcareListings, setAcceptHealthcareListings] = useState(false);

  const contentMap = {
    landingPage: {
      title: 'Custom Landing Page Acknowledgement',
      content: (
        <>
          <ModalParagraph>
            <strong>NOTE:&nbsp;</strong>
            Standard turnaround time is 3-5 business days for a custom landing page quote, 
            once all clarifications are provided.
          </ModalParagraph>
          <ModalParagraph>
            Custom landing pages will be custom designed as closed as possible to the requested specifications.
            However, the functionalities are more limited and we may not be able to accommodate.
          </ModalParagraph>
          <FormControlLabel
            sx={{ alignItems: 'flex-start' }}
            control={(
              <Checkbox sx={{ paddingTop: '3px' }} onChange={e => setUserAccepted(e.target.checked)} />
            )}
            label={(
              <Typography>
                I accept that not all the requested functionality and design requirements &nbsp;
                may be accomodated for the requested Custom Landing Page.
              </Typography>
            )}
          />
        </>),
      canContinue: userAccepted,
    },
    website: {
      title: 'Custom Website Disclaimer',
      content: (
        <>
          <ModalParagraph>
            <strong>NOTE:&nbsp;</strong>
            Standard turnaround time is 7-10 business days for a
            custom website quote, once all clarifications are provided.
          </ModalParagraph>
          <ModalParagraph>
            Before completing a quote request, be sure that the client understands that we will build&nbsp;
            all custom sites on WordPress or Shopify. Minimum contracts are&nbsp;
            <strong>$20,000</strong>
            &nbsp;and can fluctuate to&nbsp;
            <strong>$100,000+ (USD)</strong>
            , depending on the scope of the project.&nbsp;
            Payment plans will be based on the project outline and can vary depending on the scope.
          </ModalParagraph>
          <ModalParagraph>
            All custom websites are&nbsp;
            <strong>client-hosted</strong>
            &nbsp;due to variability in scope and functionality.
          </ModalParagraph>
          <FormControlLabel
            sx={{ alignItems: 'flex-start' }}
            control={(
              <Checkbox sx={{ paddingTop: '3px' }} onChange={e => setUserAccepted(e.target.checked)} />
            )}
            label={(
              <Typography>
                I have discussed the potential contract costs with the client and have reviewed the&nbsp;
                features and functionality of&nbsp;
                <a href={comparisonUrl} target="_blank" rel="noreferrer">Silver Websites</a>
                &nbsp;to ensure a custom website is required.
              </Typography>
            )}
          />
        </>),
      canContinue: userAccepted,
    },
    premiumListings: {
      title: '',
      content: (
        <>
          <ModalParagraph>
            Any order that contains at least one Provider should use the&nbsp;
            Premium Listings Healthcare 1-9 Order Form.
          </ModalParagraph>

          <Typography fontWeight="600">Location Listings</Typography>
          <ul>
            <li>
              <Typography>Medical Offices, Practices, or Providers that do not want a Doctor.com package.</Typography>
            </li>
            <li>
              <Typography>Must use the Premium Listings 1 Location Offer (1 Offer/Listing).</Typography>
            </li>
            <li>
              <Typography>
                Submitted to standard 60+ directories&nbsp;
                <strong style={{ color: 'black' }}>and not</strong>
                &nbsp;to the Doctor.com suite of directories.
              </Typography>
            </li>
          </ul>
          <FormControlLabel
            sx={{ alignItems: 'flex-start' }}
            control={(
              <Checkbox sx={{ paddingTop: '3px' }} onChange={e => setAcceptLocationListings(e.target.checked)} />
            )}
            label={(
              <Typography marginBottom={3}>
                I understand that these location listings are standard listings and willnbsp;
                not be submitted to the Doctor.com suite of directories
              </Typography>
            )}
          />

          <Typography fontWeight="600">Provider Listings</Typography>
          <ul>
            <li>
              <Typography>
                Any qualifying Healthcare Provider&nbsp;
                <strong style={{ color: 'black' }}>with an NPI number.</strong>
              </Typography>
            </li>
            <li>
              <Typography>
                Must use the Premium Listings Healthcare 1 Provider Offer (1 Offer/Listing).
              </Typography>
            </li>
            <li>
              <Typography>
                Submitted to standard 60+ directories&nbsp;
                <strong style={{ color: 'black' }}>AND</strong>
                &nbsp;to the Doctor.com suite of directories.
              </Typography>
            </li>
          </ul>
          <FormControlLabel
            sx={{ alignItems: 'flex-start' }}
            control={(
              <Checkbox sx={{ paddingTop: '3px' }} onChange={e => setAcceptHealthcareListings(e.target.checked)} />
            )}
            label={(
              <Typography marginBottom={2}>
                I have purchased the Healthcare specific package in order to&nbsp;
                submit Providers to the Doctor.com suite of directories
              </Typography>
            )}
          />
        </>
      ),
      canContinue: acceptLocationListings && acceptHealthcareListings,
    },
  };

  const closeAndReset = () => {
    setUserAccepted(false);
    setAcceptLocationListings(false);
    setAcceptHealthcareListings(false);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={closeAndReset}
    >
      <Box sx={style}>
        <Grid container>
          <Grid item xs={12} display="flex"
            justifyContent="flex-end"
          >
            <IconButton onClick={closeAndReset}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Grid>
          {!!contentMap[type].title && (
            <Grid item xs={12} paddingX={4}
              marginBottom={2}
            >
              <Typography variant="h2">{contentMap[type].title}</Typography>
            </Grid>
          )}
          <Grid item xs={12} marginBottom={4}
            paddingX={4}
          >
            {contentMap[type].content}
          </Grid>
          <Grid item xs={12} marginBottom={5}
            paddingX={4}
          >
            <Grid container>
              <Grid item xs={6} paddingRight={2}>
                <Button fullWidth variant="outlined" onClick={closeAndReset}
                  sx={{ paddingY: 1 }}
                >
                  <Typography fontSize="22px" fontWeight="bold">Go Back</Typography>
                </Button>
              </Grid>
              <Grid item xs={6} paddingLeft={2}>
                <Button fullWidth variant="contained" onClick={handleContinue}
                  sx={{ paddingY: 1 }}
                  disabled={!contentMap[type].canContinue}
                >
                  <Typography fontSize="22px" fontWeight="bold">Continue</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
