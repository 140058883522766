import {
  Button, Dialog, DialogTitle, Grid, IconButton, Typography, useTheme,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean
  onCancel: () => void
}

export default function AddonsUrlConfirmation(props: Props) {
  const {
    open, onCancel,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      sx={{ padding: '20px' }}
    >
      <DialogTitle sx={{ margin: '20px' }}>
        <Grid container direction="row">
          <Grid item xs={12} textAlign="center">
            <ErrorOutlineIcon color="error" fontSize="large" />
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight={600} fontSize={28} textAlign="center">
              {t('pages.business.search.addonsModal.title')}
            </Typography>
          </Grid>
        </Grid>
        <IconButton
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Grid container sx={{ padding: '20px 40px' }}>
        <Grid item xs={12}>
          <Typography textAlign="center" color={theme.palette.text.secondary}
            fontWeight="bold"
          >
            {t('pages.business.search.addonsModal.text')}
          </Typography>
        </Grid>
        <Grid container sx={{ padding: '20px' }}>
          <Grid container direction="column"
            alignItems="center"
            sx={{ marginTop: '40px' }}
          >
            <Button variant="contained" onClick={onCancel}>
              {t('pages.business.search.addonsModal.confirm')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
