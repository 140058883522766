import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import OrderTracker from 'pages/orders/OrderTracker';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useAppDispatch } from 'services/redux/store';
import { Urls } from 'navigation/Urls';
import { useParams } from 'react-router';
import { getProduct } from 'services/productsSlice';
import { GeneralQuestionsSection } from '../generalQuestions/GeneralQuestionsSection';
import { ActionItems } from '../common/ActionItems';
import { headingStyle } from '../Business/BusinessSection';

export default function SeoSection({ product, isEditable, showTracker }: any) {
  const { orderId } = useParams();
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const [blogData, setBlogData] = useState({} as any);

  const getProductData = (orderItemId) => {
    appDispatch(getProduct({
      orderItemId,
    })).then((response) => setBlogData(response.payload));
  };

  useEffect(() => {
    if (product) {
      getProductData(product?.id);
    }
  }, [product, orderId]);

  const offeringType = product.offeringType === 'ProductsData' ? product.pdOfferingType : product.offeringType;
  const status = product?.trackerStatus === 'Completed' ? 'Seo Live' : product?.trackerStatus;

  const renderProductData = () => (
    <>
      {/* Solution Strategy */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid black' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(
            Urls.BlogSolutionStrategy,
            product.id,
          ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Solution Strategy</Typography>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Select blog post form</Typography>
                {displayNotRequiredField('blog post form', blogData?.data?.blogPostForm)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  Topics or Keywords to Prioritize in Blog Content
                </Typography>
                {displayNotRequiredField('keywords', blogData?.data?.topicsToPrioritize)}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  Topics or Keywords to Avoid in Blog Content
                </Typography>
                {displayNotRequiredField('keywords', blogData?.data?.topicsToAvoid)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Additional Notes/Instructions</Typography>
                {displayNotRequiredField('Additional Notes/Instructions', blogData?.data?.additionalNotes)}
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>
    </>
  );

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Status Tracker */}
      {showTracker && (
      <GridItem sizes={[12, 12]} marginBottom={8} sx={{
        padding: '40px 0 0', borderBottom: '1px solid #000000',
      }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => {})}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Status</Typography>
        </Box>
        <Box>
          <OrderTracker
            key={product?.id}
            status={status}
            productName={product?.pdOfferingType}
            product={product}
          />
        </Box>
      </GridItem>
      )}

      {/* General Questions */}
      {GeneralQuestionsSection(offeringType, product.offeringId, product.id, navigate, isEditable) }

      {!product.isRebuild && renderProductData()}

      {/* Actions items */}
      {showTracker && ActionItems(false, product?.id)}
    </Grid>
  );
}
