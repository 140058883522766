import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  getCartItemList,
  removeCartItem,
  selectCartItems,
  selecteCartAddons,
  selectedAddonsUpdated,
  updateCartAddons,
  updateCartAddonsSelection,
} from 'services/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import {
  resetCurrent, selectMenuThemeTypes, setFilter, setFilteredThemes, ThemeFilterType,
} from 'services/themesSlice';
import {
  getOrderItems,
  removeAddonOrderItems,
  removeOrderItem,
  requestNewOrder,
  selectOrderItems,
  selectOrdersContent, startOrder,
} from 'services/ordersSlice';
import { addonsList } from 'pages/orders/OrderDetailsConstants';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Urls } from 'navigation/Urls';
import {
  getCaseDetails, getOpportunity, selectOpportunity, selectOpportunityStatus,
} from 'services/cefSlice';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { mapAddons, mapAddonsToCart } from 'pages/salesforce-entry/OpportunityData';
import i18n from 'i18n/i18n';
import { OrderFlow } from 'shared/constants';
import { useQuery } from 'pages/themes/details/ThemeDetails';
import { updateAgent } from 'services/salesAgentSlice';
import { getSalesAgentDetails } from 'pages/shopping-cart/SalesAgentDetailsApi';
import { defaultAgent } from 'pages/sales_agent/SalesAgentData';
import CartItemRow from './CartItemRow';
import { AddonsObjList } from './Constants';

const { t } = i18n;

export const camelCaseToFlat = (str) => str?.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
export const SEOProductNames = [
  'SEOStandard', 'SEOCustom', 'SEOBlogStandard', 'SEOBlogEnhanced', 'SEOTopicLocation', 'SEOContentPages',
];
export const SMMProductNames = [
  'SMMStandard', 'SMMCustom', 'SMMFacebookPosts', 'SMMInstagram', 'SMMInfographics', 'SMMX', 'SMMPinterest'];
export const DMSNextProductNames = ['DMSNextCustomerCenterByDash', 'DMSNextCustomerCenter'];
export const SocialAdsProductNames = ['SocialAdsLeadAdswithDetailedTargeting', 'SocialAdsWebsiteClicks',
  'SocialAdsSmartOptimization', 'SocialAdsWebsiteClicksWithDetailedTargeting', 'SocialAdsRetargeting'];
export const getProductName = (item) => {
  if (item.productName === null) return camelCaseToFlat(item.orderItem.offeringType);
  if (item.productType === 'Addon') return item.label;
  if (SEOProductNames.includes(item.productName)) return 'SEO';
  if (SMMProductNames.includes(item.productName)) return 'SMM';
  if (item.productName === 'DMSNextCustomerCenterByDash') return 'Customer Center By Dash';
  if (item.productName === 'DMSNextCustomerCenter') return 'Customer Center';
  if (item.productName === 'XMO') return 'XMO';
  if (item.productName === 'XMOUpgrade') return 'XMO Upgrade';

  return item.productName === 'MasterAddon' ? 'Add-Ons' : camelCaseToFlat(item.productName);
};

export const getAddonsList = (addonsObj) => {
  if (addonsObj != null) {
    return Object.keys(addonsObj).filter(key => addonsList.includes(key) && addonsObj[key]);
  }
  return [];
};

const WEBSITE_PRODUCTS = ['Website', 'GoldSite'];

export default function ShoppingCart() {
  const navigate = useNavigationHandler();
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const { opportunityId } = useParams();
  const { pathname, state } = useLocation() as any;
  const currentOrder: any = useSelector(selectOrdersContent);
  const cartItems = useSelector(selectCartItems);
  const cartAddons = useSelector(selecteCartAddons);
  const menuThemes = useSelector(selectMenuThemeTypes);
  const addonsUpdated = useSelector(selectedAddonsUpdated);
  const [isOpportunity, setIsOpportunity] = useState(false);
  const [filteredCartItems, setFilteredCartItems] = useState([]);
  const { content: orderItems } = useSelector(selectOrderItems);
  const opportunity = useSelector(selectOpportunity);
  const opportunityStatus = useSelector(selectOpportunityStatus);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [salesforceIdsList, setSalesforceIdsList] = useState([]);

  const isBlue = useMemo(() => query.get('isBlue'), [query]);
  const partnerAccountId = useMemo(() => query.get('partnerAccount'), [query]);
  const checkForBlue = `?isBlue=${isBlue}`;

  const getAgentDetails = async () => {
    if (Object.keys(opportunity).length > 0) {
      const requestBody = {
        id: opportunity?.account?.ownerId,
        isBlue: isBlue === 'true',
      };
      const agentDetails = await getSalesAgentDetails(requestBody);

      // Update the agent details with the Salesforce agent details data if available
      if (agentDetails && Object.keys(agentDetails)?.length > 0) {
        const agentDetailsObj = {
          salesAgentName: agentDetails?.name || defaultAgent.salesAgentName,
          salesAgentPhoneNumber: agentDetails?.phone || defaultAgent.salesAgentPhoneNumber,
          salesAgentPhoneNumberType: defaultAgent.salesAgentPhoneNumberType,
          salesAgentPhoneNumberExt: defaultAgent.salesAgentPhoneNumberExt,
          salesAgentEmail: agentDetails?.email || defaultAgent.salesAgentEmail,
          salesAgentTypeId: null,
        };
        await dispatch(updateAgent({
          content: agentDetailsObj,
          id: parseInt(currentOrder?.userId, 10),
        }));
      }
    }
  };

  // Reload the page when the user switches back from another tab
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState !== 'hidden') {
        window.location.reload();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (opportunity) {
      const salesforceIds = opportunity?.offers?.map((sfItem: any) => sfItem?.forceOffer?.salesforceOfferId) || [];
      setSalesforceIdsList(salesforceIds);
    }
  }, [opportunity]);

  useEffect(() => {
    if ((isBlue || opportunity) && salesforceIdsList?.length > 0) {
      setSubmitDisabled(true);
      cartItems?.content.forEach((item: any) => {
        if (!salesforceIdsList.includes(item?.orderItem?.salesforceId)) {
          dispatch(removeCartItem(item.orderItem?.id));
        }
      });
    }
    setSubmitDisabled(false);
  }, [isBlue, salesforceIdsList, cartItems]);

  useEffect(() => {
    getAgentDetails();
  }, [opportunity]);

  useEffect(() => {
    if (opportunityId && Object.keys(opportunity).length === 0) {
      dispatch(isBlue === 'true' ? getCaseDetails(opportunityId) : getOpportunity(opportunityId));
    }
  }, [opportunityId]);

  const goToList = async () => {
    if (!partnerAccountId) {
      await dispatch(setFilter({ type: ThemeFilterType.PRODUCT_TYPE, value: '' }));
      const filtered = menuThemes.filter(theme => (theme.featured && theme.active));
      await dispatch(setFilteredThemes(filtered));
    }
    await dispatch(resetCurrent({}));
    if (!opportunityId) {
      navigateTo(partnerAccountId ? `/${Urls.Themes}?partnerAccount=${partnerAccountId}` : `/${Urls.Themes}`);
    } else navigateTo(`/${Urls.Cef}/${opportunityId}${checkForBlue}`);
  };

  const addonState = (neonProduct, name) => (mapAddons[neonProduct] === name
    ? false : (cartAddons[mapAddonsToCart[name]] || false));

  const deleteItem = async (item) => {
    if (item?.productType === 'Addon' && opportunityId) {
      const masterAddonItem = cartItems?.content?.filter(item => item?.productName === 'MasterAddon');
      const resource = {
        emailHosting: addonState(item?.productName, 'Email Hosting'),
        expandableMenus: addonState(item?.productName, 'Expandable Menus'),
        hipaaForms: addonState(item?.productName, 'HIPAA Forms'),
        onlineBooking: addonState(item?.productName, 'Online Booking'),
        paymentForms: addonState(item?.productName, 'Payment Forms'),
        popups: addonState(item?.productName, 'Pop-up'),
        videoBackground: addonState(item?.productName, 'Video Background'),
      };

      await dispatch(updateCartAddonsSelection({ orderItemId: masterAddonItem?.[0]?.orderItem?.id, resource }));
      await dispatch(updateCartAddons(resource as any));
      dispatch(getOrderItems(currentOrder.id));
      return;
    }

    if (item?.productType === 'Addon') {
      dispatch(removeOrderItem(item.id));
    } else {
      dispatch(removeCartItem(item.orderItem?.id));
      if (item.productName === 'GoldSite' && item.orderItem.bundleId !== null) {
        dispatch(removeAddonOrderItems());
      }
      if (WEBSITE_PRODUCTS.includes(item.productName)) {
        orderItems.forEach((bit) => {
          if (bit.pdOfferingType === 'MasterAddon') dispatch(removeCartItem(bit.id));
        });
      }
    }
  };

  const silverEcomOfferError = () => {
    const offerExists = opportunity?.offers?.some(
      offer => offer?.neonProduct?.bundle === 'Website, EcommerceMicrosite',
    );

    if (offerExists) {
      const silverIsOnCart = cartItems?.content?.some(item => item?.productName === 'Website');
      const ecomIsOnCart = cartItems?.content?.some(item => item?.productName === 'EcommerceMicrosite');

      if (!(silverIsOnCart && ecomIsOnCart)) return true;
    }
    return false;
  };

  const getItemsData = async () => {
    if (currentOrder.id) {
      await dispatch(getCartItemList(currentOrder.id));
      await dispatch(getOrderItems(currentOrder.id));
    }
  };

  const orderStart = async () => {
    await getItemsData();
    if (isOpportunity) {
      const orderFlow = isBlue === 'true' ? OrderFlow.SALESFORCE_BLUE : OrderFlow.SALES_FORCE;
      dispatch(startOrder({ orderId: currentOrder.id, orderFlow }));
    } else {
      dispatch(startOrder({ orderId: currentOrder.id, orderFlow: OrderFlow.INTERNAL }));
    }

    if (silverEcomOfferError()) {
      const refreshCart = `${Urls.ShoppingCart}/${Urls.Cef}/${opportunityId}${checkForBlue}`;
      navigateTo(refreshCart, {
        state: { message: t('pages.opportunity.shoppingCart.silverEcomError') },
      });
    } else if (currentOrder.id) navigate.to(`${currentOrder.id}/${Urls.SalesRep}`);
  };

  useEffect(() => {
    dispatch(requestNewOrder({}));
    if (pathname.includes('cef')) {
      setIsOpportunity(true);
    }
  }, []);

  useEffect(() => {
    getItemsData();
  }, [currentOrder, addonsUpdated]);

  useMemo(() => {
    if (isOpportunity) {
      const nonAddonItems = cartItems.content.filter(obj => obj.productName !== 'MasterAddon');
      let addonItems = AddonsObjList.filter(obj => orderItems.map(o => o.offeringType).includes(obj.productName));
      addonItems = addonItems.map(obj => Object.assign(obj, orderItems.find(o => o.offeringType === obj.productName)));
      setFilteredCartItems([...nonAddonItems, ...addonItems]);
    } else {
      setFilteredCartItems(cartItems.content);
    }
  }, [cartItems, orderItems]);

  const isSubmitDisabled = useMemo(() => cartItems.content.length === 0
      || opportunityStatus === 'loading' || submitDisabled, [cartItems, opportunityStatus, submitDisabled]);

  return (
    <Box maxWidth="xl" width="100%" sx={{ margin: '0 auto', paddingBottom: '100px' }}>
      <Toolbar />
      <Grid container sx={{ marginTop: 5 }}>
        {state && (
          <>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Alert icon={false} severity="error"
                sx={{
                  border: '1px solid #DBC1C6',
                  borderRadius: '4px',
                  textAlign: 'center',
                  display: 'block',
                  color: '#C20F1E',
                }}
              >
                <ErrorOutlineIcon fontSize="large" />
                <AlertTitle>
                  <Typography variant="h4">Oops!</Typography>
                </AlertTitle>
                <Typography>{state.message}</Typography>
              </Alert>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <IconButton onClick={() => goToList()} sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            textDecoration: 'none',
            color: '#000000',
            paddingLeft: 0,
            '&:hover': {
              background: 'transparent',
            },
          }}
          >
            <ChevronLeftIcon fontSize="large" />
            <Typography>Back</Typography>
          </IconButton>
        </Grid>
        <Grid item xs={12} marginTop={3}
          marginBottom={5}
        >
          <Typography variant="h2">Cart</Typography>
        </Grid>
        {cartItems.content.length === 0 ? (
          <Grid item xs={8}>
            <Typography>
              Your Cart is Empty.
              <Button onClick={() => goToList()} sx={{ fontSize: '16px', marginTop: '-3px' }}>Select Themes</Button>
              to add to Cart
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={8} paddingRight={4}>
            <TableContainer component={Box} classes={{ root: 'product-list-container' }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Details</TableCell>
                    <TableCell align="left">Type of Sale</TableCell>
                    <TableCell align="left">Estimate</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCartItems.map((item) => (
                    <CartItemRow item={item} key={item?.orderItem?.id} onRemove={() => deleteItem(item)} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        <Grid item xs={4} paddingRight={8}>
          <Box paddingX={4}>
            {opportunityStatus === 'loading' && (
              <Box display="flex" flexDirection="row" alignItems="center"
                mb={2}
              >
                <IQLoadingSpinner />
                <Typography ml={1}>Getting Salesforce data</Typography>
              </Box>
            )}
            <Button variant="contained" sx={{ paddingY: 1.5, width: '240px' }}
              disabled={isSubmitDisabled} onClick={() => orderStart()}
            >
              <Typography fontSize="22px" fontWeight="bold">Start Order</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
