import {
  Box, Button, Accordion, AccordionSummary, Typography,
  AccordionDetails, Grid, Container, Dialog, DialogTitle, IconButton,
} from '@mui/material';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Children, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  accordionStyles, renderSectionContent,
} from 'pages/overview/Overview';
import { useDispatch, useSelector } from 'react-redux';
import { finalizeOrder, getOrderItems, selectOrderItems } from 'services/ordersSlice';
import { useTranslation } from 'react-i18next';
import { getOrderBusinessByOrderId } from 'services/businessSlice';
import { selectBusinessLocations, selectProviders } from 'services/businessLocationSlice';
import { getOrderDetails, selectOrderDetails } from 'services/myOrdersSlice';
import { CoButtonBack } from 'components/buttons/CoButtonBack';
import { CoButtonContinue } from 'components/buttons/CoButtonContinue';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { IQLoadingSpinner } from '@gannettdigital/shared-react-components';
import { mapTitle } from 'pages/overview/OverviewConstants';
import { backgroundDefaultColor } from 'styles/common_styles';
import { renderStatusPill } from '../OrdersTable';

export default function OrderReview() {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderItems = useSelector(selectOrderItems);
  const orderDetails = useSelector(selectOrderDetails);
  const locations = useSelector(selectBusinessLocations);
  const providers = useSelector(selectProviders);
  const [sections, setSections] = useState([]);
  const [expandAll, setExpandAll] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isFinalized, setIsFinalized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef(null);

  const isPlProduct = orderItems?.content?.filter(item => (
    item.offeringType === 'PremiumListing' && !item.healthcare))?.length > 0;

  const isHCProduct = orderItems?.content?.filter(item => (
    item.offeringType === 'PremiumListing' && item.healthcare))?.length > 0;

  /*
    Following method includes only one PL item. No longer required to show the other products in Review page.
    Also, This is necessary in order to avoid the duplicate locations showing in UI.
  */
  const consolidatedProducts = orderItems?.content?.filter((item, index, self) =>
    (index === self.findIndex(o => o.offeringType === 'PremiumListing')));

  useEffect(() => {
    dispatch(getOrderDetails(orderId));
    dispatch(getOrderBusinessByOrderId(orderId));
    dispatch(getOrderItems(orderId));
  }, [orderId]);

  useEffect(() => {
    if (orderItems && orderDetails) {
      const isCompleted = orderItems?.content?.filter(item => (
        item.offeringType === 'PremiumListing' && item.status === 'Completed')).length > 0;
      setIsFinalized(isCompleted);

      const products = consolidatedProducts?.map(item => ({
        title: item.offeringType === 'ProductsData' ? item.pdName : item.offeringType,
        isOpen: item.offeringType === 'PremiumListing',
        ...item,
        showStatus: true,
        status: orderDetails?.filter(details => details?.orderItem?.id === item.id)?.[0]?.orderStatus?.neonStatus,
      }));

      setSections([
        {
          title: t('pages.overview.business.title'),
          isOpen: true,
          showStatus: false,
        },
        ...products,
      ]);
    }
  }, [orderItems, orderDetails]);

  const toggleAllSections = () => {
    setExpandAll(!expandAll);
    const copy = [...sections].map(section => ({ ...section, isOpen: !expandAll }));
    setSections(copy);
  };

  const handleChange = (index) => {
    const copy = [...sections];
    copy[index].isOpen = !sections[index].isOpen;
    setSections(copy);
  };

  const handleCancel = () => {
    if (providers.length > 0) {
      navigate(`/orders?typeFilters%5BpremiumListingHealthcare%5D=on&orderId=${orderId}`);
    }
    navigate(`/orders?typeFilters%5BpremiumListing%5D=on&orderId=${orderId}`);
  };

  const handleFinalizeOrder = async () => {
    setIsLoading(true);
    await dispatch(finalizeOrder(orderId));
    const productId = orderItems.content.filter((item) => item.offeringType === 'PremiumListing')?.[0]?.id;
    navigate(`/order/${orderId}${productId && `/product/${productId}`}`);
  };

  const isAlllocationsReviewed = () => {
    const isLocationsReviewed = locations.length > 0
      ? locations.every(location => location.fulfilledAdditionalInfo !== null) : true;
    const isProvidersReviewed = providers.length > 0
      ? providers.every(location => location.fulfilledAdditionalInfo !== null) : true;

    return (isLocationsReviewed && isProvidersReviewed);
  };

  return (
    <>
      <Grid item xs={12}>
        <IconButton onClick={() => navigate('/orders')} sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          textDecoration: 'none',
          color: '#000000',
          paddingLeft: 0,
          '&:hover': {
            background: 'transparent',
          },
        }}
        >
          <ChevronLeftIcon fontSize="large" />
          <Typography>My Orders</Typography>
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Container>
          <Dialog
            open
            fullScreen
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
              '& .MuiDialog-paper': {
                background: '#F9F6F1',
              },
              '& .MuiDialogTitle-root': {
                padding: '0',
              },
              '& .MuiDialogContent-root': {
                overflowY: 'unset',
                paddingBottom: '80px',
                height: 'auto',
                backgroundColor: backgroundDefaultColor,
              },
              '& .MuiDialogActions-root': {
                backgroundColor: backgroundDefaultColor,
                paddingBottom: '80px',
                justifyContent: 'center',
              },
              '& .MuiDialogContent-root > .MuiGrid-root': {
                maxWidth: '968px',
                marginLeft: 'auto',
                marginRight: 'auto',
              },
              '& .MuiDialogContent-root > .MuiGrid-root > .MuiGrid-root': {
                paddingLeft: '0',
              },
            }}
          >
            <DialogTitle id="scroll-dialog-title" ref={modalRef}>
              <Box sx={{
                width: '100%',
                height: '65px',
                background: '#ffffff',
                borderBottom: '1px solid #AEAEAD',
                marginBottom: '80px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                boxSizing: 'border-box',
                paddingRight: '10px',
              }}
              >
                <Button onClick={() => setOpenModal(true)} sx={{ textDecoration: 'underline' }}>
                  Close
                </Button>
              </Box>
              <Grid container sx={{ maxWidth: '1300px', margin: '0 auto' }}>
                <Grid item xs={12}>
                  <IconButton onClick={() => navigate('/orders')} sx={{
                    display: 'flex',
                    textDecoration: 'underline',
                    color: '#0046FF',
                    fontSize: '14px',
                    paddingLeft: 0,
                    '&:hover': {
                      background: 'transparent',
                    },
                  }}
                  >
                    <ChevronLeftIcon fontSize="large" />
                    <Typography>My Orders</Typography>
                  </IconButton>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h2" marginTop={2} marginBottom={6}>Order Review</Typography>
                    <Button onClick={toggleAllSections} sx={{ alignSelf: 'flex-end', textDecoration: 'underline' }}>
                      {expandAll ? ' Collapse All' : 'Expand All'}
                    </Button>
                    {Children.toArray(sections?.map((section, index) => (
                      <Accordion
                        expanded={sections[index]?.isOpen}
                        onChange={() => handleChange(index)}
                        disableGutters
                        sx={accordionStyles}
                      >
                        <AccordionSummary expandIcon={<ExpandCircleDownOutlinedIcon color="primary" />}>
                          <Typography variant="h5" marginRight={1}>
                            {!section?.healthcare
                              ? mapTitle[section.title]
                              : 'US Premium Listings (1-9) w/Yelp + Healthcare'}
                          </Typography>
                          {section.showStatus && renderStatusPill(section.status)}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container>
                            {sections[index]?.isOpen
                          && renderSectionContent(section.title, section, isPlProduct, isHCProduct, true, true, true)}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    )))}
                  </Box>
                </Grid>

                {isLoading && (
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={8}
                  >
                    <IQLoadingSpinner size={60} />
                  </Box>
                </Grid>
                )}

                <Grid item xs={12} sx={{ marginTop: '80px', padding: '50px 0 117px', borderTop: '1px solid #AEAEAD' }}>
                  <Box>
                    {!isAlllocationsReviewed() && (
                    <CoButtonBack
                      label="Cancel"
                      onClick={() => setOpenModal(true)}
                      disabled={false}
                      sx={{ float: 'left', minWidth: 'fit-content' }}
                    />
                    )}
                    <CoButtonContinue
                      label={!isFinalized ? 'Finalize Order' : 'Resubmit Order'}
                      disabled={!isAlllocationsReviewed() || isLoading}
                      sx={{ float: 'right', minWidth: 'fit-content' }}
                      type="submit"
                      onClick={handleFinalizeOrder}
                    />
                  </Box>
                </Grid>
              </Grid>
            </DialogTitle>
          </Dialog>
          <CoModalConfirm
            open={openModal}
            title=""
            description={(
              <>
                <Typography variant="h5" mb={3}>Leaving Page</Typography>
                <Typography>
                  Are you sure you want to leave this page?
                  The order will not be completed until all requirements
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  are fulfilled and 'Finalize Order' has been clicked.
                </Typography>
              </>
          )}
            confirmText="Yes"
            handleClose={() => setOpenModal(false)}
            handleConfirm={handleCancel}
          />
        </Container>
      </Grid>
    </>
  );
}
