import React, { useEffect, useState } from 'react';
import DefaultPageLayout from 'layouts/DefaultPageLayout';
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@mui/material';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  IQButtonRadioContainer,
  IQFormInput,
  IQFormSelect,
  IQFormTextArea,
  IQInputEmail,
  IQInputNumericField,
  IQInputWebURL,
  IQTheme,
  IQThemeV2,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';

import {Controller, FormProvider, useForm} from 'react-hook-form';
import schema from "pages/xmo/tactics/dynamic/XmoDynamic.schema";
import {
  behavioralTargetingList,
  callToActionDropDown,
  clientBeProvidingImagesToUseList,
  clientWantTheirAdsToAppearList,
  dealerSystemProviderOptions,
  demographicTargetingList,
  detailTargetingPreferencesList,
  genderOptionRadioItems,
  interestTargetingList,
  inventoryToFeatureCampaignOptions,
  leadAdsDynamicFormContactFieldsList,
  noneCustomList,
  visualStrategyClientWantToEmployList,
  yesNoList
} from "pages/xmo/tactics/dynamic/XmoSocialDynamicServices";
import schemaValidate from "components/schemaValidate";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import NeSelectChip from "components/chips/NeSelectChip";
import ErrorIcon from "@mui/icons-material/Error";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NePhoneInput from "components/NePhoneInputContainer/IQPhoneInputContainer";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {normalizeNewLines} from "shared/constants";
interface Props {
  product: any,
  onSubmit: (data) => void,
  onBack: () => void,
}

export type LeadAdsDynamicProps = {
  dealerId:string,
  dealerPOC:string,
  dealerPOCEmail:string,
  dealerPOCPhoneNumber:string,
  dealerInventorySystemProvider:string,
  minimumAge: string,
  maximumAge: string,
  gender: string,
  callToActionButton: string,
  useAutomaticOrOptimizedPlacement: string,
  clientWantTheirAdsToAppear: any,
  selectContactFieldsThatApply: any,
  leadAdsDynamicQuestionOne: string,
  customQuestionOne: string,
  customAnswerOne: string,
  leadAdsDynamicQuestionTwo: string,
  customQuestionTwo: string,
  customAnswerTwo: string,
  leadAdsDynamicQuestionThree: string,
  customQuestionThree: string,
  customAnswerThree: string,
  detailedTargetingPreferencesThatApply: any,
  demographicTargetDetail: string,
  behavioralTargetDetail: string,
  interestTargetDetail: string,
  privacyPolicyUrl: string,
  advertiserDisclaimerUrl: string,
  visualStrategyClientWantToEmploy: string,
  clientBeProvidingImagesToUse: string,
  campaignUtilizeTheCarouselFormat: string,
  clientBeProvidingTextCreative: string,
  adHeadline: string,
  adBodyText: string,
  newsfeedLinkDescription: string,
  inventoryToFeatureCampaign:string,
  services: any
};

export default function SocialAdsSmartLeadAdsDynamicPage({product, onSubmit, onBack}: Props) {
  const {t} = useTranslation();
  const [ageError, setAgeError] = useState<boolean>(false);

  const handleFormSubmit = (data) => {
    if (data?.useAutomaticOrOptimizedPlacement === 'Yes') {
      data.clientWantTheirAdsToAppear = []
    }
    if (data?.leadAdsDynamicQuestionOne === 'None') {
      data.customQuestionOne = ""
      data.customAnswerOne = ""
    }
    if (data?.leadAdsDynamicQuestionTwo === 'None') {
      data.customQuestionTwo = ""
      data.customAnswerTwo = ""
    }
    if (data?.leadAdsDynamicQuestionThree === 'None') {
      data.customQuestionThree = ""
      data.customAnswerThree = ""
    }
    if (!data.detailedTargetingPreferencesThatApply.includes("Demographic Targeting")) {
      data.demographicTargetDetail = ""
    }
    if (!data.detailedTargetingPreferencesThatApply.includes("Behavioral Targeting")) {
      data.behavioralTargetDetail = ""
    }
    if (!data.detailedTargetingPreferencesThatApply.includes("Interest Targeting")) {
      data.interestTargetDetail = ""
    }
    if (data?.clientBeProvidingTextCreative === 'No') {
      data.adHeadline = ""
      data.adBodyText = ""
      data.newsfeedLinkDescription = ""
    }
    const pageFormData = {
      leadAds: data
    };
    onSubmit(pageFormData);
  };
  const methods = useForm<LeadAdsDynamicProps>({
    mode: 'all',
    defaultValues: {
      dealerId:product?.dealerId || '',
      dealerPOC:product?.dealerPOC || '',
      dealerPOCEmail:product?.dealerPOCEmail || '',
      dealerPOCPhoneNumber:product?.dealerPOCPhoneNumber || '',
      dealerInventorySystemProvider:product?.dealerInventorySystemProvider || '',
      minimumAge: product?.minimumAge || null,
      maximumAge: product?.maximumAge || null,
      gender: product?.gender || '',
      callToActionButton: product?.callToActionButton || '',
      useAutomaticOrOptimizedPlacement: product?.useAutomaticOrOptimizedPlacement || '',
      clientWantTheirAdsToAppear: product?.clientWantTheirAdsToAppear || [],
      selectContactFieldsThatApply: product?.selectContactFieldsThatApply || [],
      leadAdsDynamicQuestionOne: product?.leadAdsDynamicQuestionOne || '',
      customQuestionOne: product?.customQuestionOne || '',
      customAnswerOne: product?.customAnswerOne || '',
      leadAdsDynamicQuestionTwo: product?.leadAdsDynamicQuestionTwo || '',
      customQuestionTwo: product?.customQuestionTwo || '',
      customAnswerTwo: product?.customAnswerTwo || '',
      leadAdsDynamicQuestionThree: product?.leadAdsDynamicQuestionThree || '',
      customQuestionThree: product?.customQuestionThree || '',
      customAnswerThree: product?.customAnswerThree || '',
      detailedTargetingPreferencesThatApply: product?.detailedTargetingPreferencesThatApply || [],
      demographicTargetDetail: normalizeNewLines(product?.demographicTargetDetail) || '',
      behavioralTargetDetail: normalizeNewLines(product?.behavioralTargetDetail) || '',
      interestTargetDetail: normalizeNewLines(product?.interestTargetDetail) || '',
      privacyPolicyUrl: product?.privacyPolicyUrl || '',
      advertiserDisclaimerUrl: product?.advertiserDisclaimerUrl || '',
      visualStrategyClientWantToEmploy: product?.visualStrategyClientWantToEmploy || '',
      clientBeProvidingImagesToUse: product?.clientBeProvidingImagesToUse || '',
      campaignUtilizeTheCarouselFormat: product?.campaignUtilizeTheCarouselFormat || '',
      clientBeProvidingTextCreative: product?.clientBeProvidingTextCreative || '',
      adHeadline: product?.adHeadline || '',
      adBodyText: product?.adBodyText || '',
      newsfeedLinkDescription: product?.newsfeedLinkDescription || '',
      inventoryToFeatureCampaign:product?.inventoryToFeatureCampaign,
      services: product?.services || [],
    },
  });
  const {
    handleSubmit, register, control,
    formState: {isValid, isDirty, isSubmitSuccessful, isSubmitting},
    watch, trigger, getValues
  } = methods;

  const useAutomaticOrOptimizedPlacementNo = watch('useAutomaticOrOptimizedPlacement') === 'No'
  const detailedTargetingPreferencesThatApplyDemographicTargeting = watch('detailedTargetingPreferencesThatApply').includes("Demographic Targeting")
  const detailedTargetingPreferencesThatApplyBehavioralTargeting = watch('detailedTargetingPreferencesThatApply').includes("Behavioral Targeting")
  const detailedTargetingPreferencesThatApplyInterestTargeting = watch('detailedTargetingPreferencesThatApply').includes("Interest Targeting")
  const clientBeProvidingTextCreativeYes = watch('clientBeProvidingTextCreative') === "Yes"
  const campaignUtilizeTheCarouselFormatYes = watch('campaignUtilizeTheCarouselFormat') === "Yes"
  const campaignUtilizeTheCarouselFormatNo = watch('campaignUtilizeTheCarouselFormat') === "No"
  const minimumAge = watch('minimumAge');
  const maximumAge = watch('maximumAge');

  useEffect(() => {
    const minAge = parseInt(minimumAge);
    const maxAge = parseInt(maximumAge);

    if (minAge <= 0 || maxAge <= 0 || minAge > maxAge) {
      setAgeError(true);
    } else {
      setAgeError(false);
    }
  }, [minimumAge, maximumAge]);

  return (
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <DefaultPageLayout
              disableContinue={!isValid || ageError}
              onBack={onBack}
              header={t('pages.xmo.dynamic.title')}
              onContinue={handleSubmit(handleFormSubmit)}
          >
            <Box>
              <GridContainer fullWidth>
              <GridItem sizes={[12]}>
                  <Typography variant="h6">{t('pages.xmo.dynamic.dealerInfo')}</Typography>
            </GridItem>
            <GridItem sizes={[12]}>
                  <IQFormInput
                      id="dealerId"
                      name="dealerId"
                      labelText={t('pages.xmo.dynamic.dealerId')}
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      schema={schema}    
                  />
            </GridItem>
            <GridItem sizes={[12]}>
                  <IQFormInput
                      id="dealerPOC"
                      name="dealerPOC"
                      labelText={t('pages.xmo.dynamic.dealerPOC')}
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      schema={schema}
                  />
            </GridItem>
            <GridItem sizes={[12]}>
                    <IQInputEmail
                    labelText={`${t('pages.xmo.dynamic.dealerEmail')}`}
                    fontLabelWeight="600"
                    theme={IQTheme}
                    schema={schema}
                    label={t('pages.xmo.dynamic.dealerEmail')}
                    field="dealerPOCEmail"
                    fullWidth
                    adornmentIcon={<MailOutlineIcon />}
                  />
            </GridItem>
            <GridItem sizes={[12]}>
                      <NePhoneInput
                        name="dealerPOCPhoneNumber"
                        country="US"
                        hasExtension={false}
                        phoneLabelOptions={{
                          labelText: t('pages.xmo.dynamic.dealerNumber'),
                          hasError: true,
                          theme: IQThemeV2,
                          required: false,
                        }}
                        hasType={false}
                        countryCallingCodeEditable={false}
                        international
                    />
            </GridItem>
            <GridItem sizes={[12]}>
              <Typography sx={{ marginBottom: '-18px', fontWeight: 'bold' }}>
                {t('pages.xmo.dynamic.dealerSystemProvider')}
                <Tooltip title={t('pages.xmo.dynamic.dealerSystemProviderTooltip')} placement="top">
                  <HelpOutlineIcon color="primary" sx={{ marginLeft: 1, fontSize: '15px' }} />
                </Tooltip>
              </Typography>
              <IQButtonRadioContainer
                name="dealerInventorySystemProvider"
                optionsList={dealerSystemProviderOptions}
                inline
              />
            </GridItem>
                <GridItem sizes={[12]}>
                  <Typography variant="h6">{t('pages.xmo.dynamic.audience')}</Typography>
                </GridItem>

                <GridItem sizes={[6, 6]}>
                  <IQInputNumericField
                      theme={IQTheme}
                      schema={schema}
                      label={t('pages.xmo.dynamic.minimumAge')}
                      name="minimumAge"
                      disallowNegative
                      required
                      defaultValue={product?.minimumAge || ""}
                      fullWidth/>
                  <IQInputNumericField
                      theme={IQTheme}
                      schema={schema}
                      label={t('pages.xmo.dynamic.maximumAge')}
                      name="maximumAge"
                      disallowNegative
                      required
                      defaultValue={product?.maximumAge || ""}
                      fullWidth/>
                  <GridItem sizes={[12]} marginTop={-2}>
                    <Typography sx={{color: '#C20F1E', fontSize: '14px'}} variant="subtitle1">
                      {ageError && <ErrorIcon sx={{
                        fontSize: '16px',
                        marginTop: '6px'
                      }}/>} {ageError ? t('pages.xmo.dynamic.maximumAgeError') : ''}
                    </Typography>
                  </GridItem>
                </GridItem>

                <GridItem sizes={[12]}>
                  <IQButtonRadioContainer
                      label={t('pages.xmo.dynamic.gender')}
                      name="gender"
                      optionsList={genderOptionRadioItems}
                      required
                      inline
                  />
                </GridItem>

                <GridItem sizes={[12]}>
                  <Typography variant="h6">{t('pages.xmo.dynamic.strategy')}</Typography>
                </GridItem>

                <GridItem sizes={[12]}>
                  <IQFormSelect
                      id="callToActionButton"
                      name="callToActionButton"
                      labelText={t('pages.xmo.dynamic.callToActionButton')}
                      items={callToActionDropDown}
                      defaultValue=""
                      theme={IQTheme}
                      fontLabelWeight="600"
                      fullWidth
                      schema={schema}
                      placeholder={t('pages.xmo.dynamic.callToActionButtonPlaceholder')}
                  />
                </GridItem>

                <GridItem sizes={[12]}>
                  <Typography
                      sx={{fontWeight: 'bold'}}>{t('pages.xmo.dynamic.useAutomaticOrOptimizedPlacement')}
                    <RadioGroup
                        id='useAutomaticOrOptimizedPlacement'
                        name='useAutomaticOrOptimizedPlacement'
                        defaultValue={product?.useAutomaticOrOptimizedPlacement || ''}
                        row
                    >
                      {yesNoList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={opt.description}
                              {...register('useAutomaticOrOptimizedPlacement', {
                                validate: (value) => schemaValidate(value, 'useAutomaticOrOptimizedPlacement', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                {useAutomaticOrOptimizedPlacementNo && (
                    <GridItem sizes={[12]}>
                      <Grid>
                        <Grid>
                          <Typography sx={{fontWeight: 'bold'}}>
                            {t('pages.xmo.dynamic.clientWantTheirAdsToAppear')}
                          </Typography>
                        </Grid>
                        <Grid container>
                          {clientWantTheirAdsToAppearList.map((option) => (
                              <Grid item xs={12} sm={6} key={option.label}>
                                <FormControlLabel
                                    control={
                                      <Controller
                                          name="clientWantTheirAdsToAppear"
                                          control={control}
                                          {...register('clientWantTheirAdsToAppear', {
                                            validate: (value) => schemaValidate(value, 'clientWantTheirAdsToAppear', schema),
                                          })}
                                          render={({field}) => (
                                              <Checkbox
                                                  {...field}
                                                  value={option.label}
                                                  checked={field.value.includes(option.label)}
                                                  onChange={() => {
                                                    const newValue = field.value.includes(option.label)
                                                        ? field.value.filter(e => e !== option.label)
                                                        : [...field.value, option.label];
                                                    field.onChange(newValue);
                                                  }}
                                              />
                                          )}
                                      />
                                    }
                                    label={<>
                                      {option.label}
                                      {option.label === 'MarketPlace' && (
                                          <Tooltip
                                              title={t('pages.xmo.dynamic.marketPlaceTooltipMessage')}
                                              placement={"top"}>
                                            <InfoOutlinedIcon color="primary" sx={{
                                              marginLeft: 1,
                                              fontSize: '18px'
                                            }}/>
                                          </Tooltip>
                                      )}
                                    </>}
                                />
                              </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </GridItem>
                )}

                <GridItem sizes={[12]}>
                  <Grid>
                    <Grid>
                      <Typography sx={{fontWeight: 'bold'}}>
                        {t('pages.xmo.dynamic.selectContactFieldsThatApply')}
                      </Typography>
                    </Grid>
                    <Grid container>
                      {leadAdsDynamicFormContactFieldsList.map((option) => (
                          <Grid item xs={12} sm={6} key={option.label}>
                            <FormControlLabel
                                control={
                                  <Controller
                                      name="selectContactFieldsThatApply"
                                      control={control}
                                      {...register('selectContactFieldsThatApply', {
                                        validate: (value) => schemaValidate(value, 'selectContactFieldsThatApply', schema),
                                      })}
                                      render={({field}) => (
                                          <Checkbox
                                              {...field}
                                              value={option.label}
                                              checked={field.value.includes(option.label)}
                                              onChange={() => {
                                                const newValue = field.value.includes(option.label)
                                                    ? field.value.filter(e => e !== option.label)
                                                    : [...field.value, option.label];
                                                field.onChange(newValue);
                                              }}
                                          />
                                      )}
                                  />
                                }
                                label={option.label}
                            />
                          </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </GridItem>

                <GridItem sizes={[12]}>
                  <Typography sx={{fontWeight: 'bold'}}>
                    {t('pages.xmo.dynamic.leadAdsQuestionOne')}
                    <RadioGroup
                        id="leadAdsDynamicQuestionOne"
                        name="leadAdsDynamicQuestionOne"
                        defaultValue={product?.leadAdsDynamicQuestionOne || ''}
                        row
                    >
                      {noneCustomList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={opt.description}
                              {...register('leadAdsDynamicQuestionOne')}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                {(watch('leadAdsDynamicQuestionOne')) === "Custom" && (
                    <>
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="customQuestionOne"
                            name="customQuestionOne"
                            labelText={t('pages.xmo.dynamic.customQuestionOne')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                        />
                      </GridItem>

                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="customAnswerOne"
                            name="customAnswerOne"
                            labelText={t('pages.xmo.dynamic.customAnswerOne')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                            subDescription={t('pages.xmo.dynamic.customQuestionHelperText')}
                        />
                      </GridItem>
                    </>)}

                <GridItem sizes={[12]}>
                  <Typography sx={{fontWeight: 'bold'}}>
                    {t('pages.xmo.dynamic.leadAdsQuestionTwo')}
                    <RadioGroup
                        id="leadAdsDynamicQuestionTwo"
                        name="leadAdsDynamicQuestionTwo"
                        defaultValue={product?.leadAdsDynamicQuestionTwo || ''}
                        row
                    >
                      {noneCustomList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={opt.description}
                              {...register('leadAdsDynamicQuestionTwo')}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                {(watch('leadAdsDynamicQuestionTwo')) === "Custom" && (
                    <>
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="customQuestionTwo"
                            name="customQuestionTwo"
                            labelText={t('pages.xmo.dynamic.customQuestionTwo')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                        />
                      </GridItem>

                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="customAnswerTwo"
                            name="customAnswerTwo"
                            labelText={t('pages.xmo.dynamic.customAnswerTwo')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                            subDescription={t('pages.xmo.dynamic.customQuestionHelperText')}
                        />
                      </GridItem>
                    </>)}

                <GridItem sizes={[12]}>
                  <Typography sx={{fontWeight: 'bold'}}>
                    {t('pages.xmo.dynamic.leadAdsQuestionThree')}
                    <RadioGroup
                        id="leadAdsDynamicQuestionThree"
                        name="leadAdsDynamicQuestionThree"
                        defaultValue={product?.leadAdsDynamicQuestionThree || ''}
                        row
                    >
                      {noneCustomList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={opt.description}
                              {...register('leadAdsDynamicQuestionThree')}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                {(watch('leadAdsDynamicQuestionThree')) === "Custom" && (
                    <>
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="customQuestionThree"
                            name="customQuestionThree"
                            labelText={t('pages.xmo.dynamic.customQuestionThree')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                        />
                      </GridItem>

                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="customAnswerThree"
                            name="customAnswerThree"
                            labelText={t('pages.xmo.dynamic.customAnswerThree')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                            subDescription={t('pages.xmo.dynamic.customQuestionHelperText')}
                        />
                      </GridItem>
                    </>)}

                <GridItem sx={{marginBottom: 1}}>
                  <Typography variant="h6">{t('pages.xmo.dynamic.detailedTargeting')}</Typography>
                </GridItem>
                <GridItem>
                  <Typography
                      variant="subtitle1">{t('pages.xmo.dynamic.detailedTargetingDescription')}</Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <Grid>
                    <Grid>
                      <Typography sx={{fontWeight: 'bold'}}>
                        {t('pages.xmo.dynamic.detailedTargetingPreferencesThatApply')}
                      </Typography>
                    </Grid>
                    <Grid container>
                      {detailTargetingPreferencesList.map((option) => (
                          <Grid item xs={12} sm={6} key={option.label}>

                            <FormControlLabel
                                control={
                                  <Controller
                                      name="detailedTargetingPreferencesThatApply"
                                      control={control}
                                      {...register('detailedTargetingPreferencesThatApply', {
                                        validate: (value) => schemaValidate(value, 'detailedTargetingPreferencesThatApply', schema),
                                      })}
                                      render={({field}) => (
                                          <Checkbox
                                              {...field}
                                              value={option.label}
                                              checked={field.value.includes(option.label)}
                                              onChange={() => {
                                                const newValue = field.value.includes(option.label)
                                                    ? field.value.filter(e => e !== option.label)
                                                    : [...field.value, option.label];
                                                field.onChange(newValue);
                                              }}
                                          />
                                      )}
                                  />
                                }
                                label={<Box style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}>
                                  {option.label}
                                  <Tooltip
                                      title={option.tooltipMessage}
                                      placement={"top"}>
                                    <InfoOutlinedIcon color="primary" sx={{
                                      marginLeft: 1,
                                      fontSize: '18px'
                                    }}/>
                                  </Tooltip>
                                </Box>}
                            />
                          </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  {detailedTargetingPreferencesThatApplyDemographicTargeting && (
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <Typography variant="h6">{t('pages.xmo.dynamic.demographicTargeting')}
                            </Typography>
                            <Typography variant="body1" mt={1}>
                              {t('pages.xmo.dynamic.demographicTargetingDescription')}
                            </Typography>

                          </Grid>
                          <Grid item xs={12} mt={1}>
                            <Typography variant="body1" component="ul">
                              {demographicTargetingList.map((item, index) => (
                                  <Typography key={index} variant="body1"
                                              component="li">
                                    {item}
                                  </Typography>
                              ))}
                            </Typography>
                          </Grid>
                          <Box mb={1}>
                            <Typography sx={{
                              fontWeight: 'bold',
                              display: "inline-block",
                              marginTop: "15px"
                            }}>
                              {t('pages.xmo.dynamic.demographicTargetingDetails')}
                            </Typography>
                            <Tooltip
                                title={t('pages.xmo.dynamic.detailedTargetingDescription')}
                                placement="top">
                              <InfoOutlinedIcon color="primary"
                                               sx={{
                                                 marginLeft: 1,
                                                 fontSize: '18px',
                                                 cursor: "pointer"
                                               }}/>
                            </Tooltip>
                          </Box>
                          <IQFormTextArea
                              id="demographicTargetDetail"
                              name="demographicTargetDetail"
                              fontLabelWeight="600"
                              rowCount={5}
                              fullWidth
                              showError
                          />
                        </Grid>
                      </Grid>
                  )}
                  {detailedTargetingPreferencesThatApplyBehavioralTargeting && (
                      <Grid container>
                        <Grid item xs={12} mt={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6">{t('pages.xmo.dynamic.behavioralTargeting')}
                            </Typography>
                            <Typography variant="body1" mt={1}>
                              {t('pages.xmo.dynamic.behavioralTargetingDescription')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} mt={1}>
                            <Typography variant="body1" component="ul">
                              {behavioralTargetingList.map((item, index) => (
                                  <Typography key={index} variant="body1"
                                              component="li">
                                    {item}
                                  </Typography>
                              ))}
                            </Typography>
                          </Grid>
                          <Box mb={1}>
                            <Typography sx={{
                              fontWeight: 'bold',
                              display: "inline-block",
                              marginTop: "15px"
                            }}>
                              {t('pages.xmo.dynamic.behavioralTargetingDetails')}
                            </Typography>
                            <Tooltip
                                title={t('pages.xmo.dynamic.behavioralTargetingDescription')}
                                placement="top">
                              <InfoOutlinedIcon color="primary"
                                               sx={{
                                                 marginLeft: 1,
                                                 fontSize: '18px',
                                                 cursor: "pointer"
                                               }}/>
                            </Tooltip>
                          </Box>
                          <IQFormTextArea
                              id="behavioralTargetDetail"
                              name="behavioralTargetDetail"
                              fontLabelWeight="600"
                              rowCount={5}
                              fullWidth
                              showError
                          />
                        </Grid>
                      </Grid>
                  )}
                  {detailedTargetingPreferencesThatApplyInterestTargeting && (
                      <Grid container>
                        <Grid item xs={12} mt={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6">{t('pages.xmo.dynamic.interestTargeting')}
                            </Typography>
                            <Typography variant="body1">
                              {t('pages.xmo.dynamic.interestTargetingDescription')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} mt={1}>
                            <Typography variant="body1" component="ul">
                              {interestTargetingList.map((item, index) => (
                                  <Typography key={index} variant="body1"
                                              component="li">
                                    {item}
                                  </Typography>
                              ))}
                            </Typography>
                          </Grid>
                          <Box mb={1}>
                            <Typography sx={{
                              fontWeight: 'bold',
                              display: "inline-block",
                              marginTop: "15px"
                            }}>
                              {t('pages.xmo.dynamic.interestTargetingDetails')}
                            </Typography>
                            <Tooltip
                                title={t('pages.xmo.dynamic.interestTargetingDescription')}
                                placement="top">
                              <InfoOutlinedIcon color="primary"
                                               sx={{
                                                 marginLeft: 1,
                                                 fontSize: '15px',
                                                 cursor: "pointer"
                                               }}/>
                            </Tooltip>
                          </Box>
                          <IQFormTextArea
                              id="interestTargetDetail"
                              name="interestTargetDetail"
                              fontLabelWeight="600"
                              rowCount={5}
                              fullWidth
                              showError
                          />
                        </Grid>
                      </Grid>
                  )}
                </GridItem>
                <GridItem sx={{marginBottom: 1}}>
                  <Typography variant="h6">{t('pages.xmo.dynamic.privacyPolicy')}</Typography>
                </GridItem>
                <GridItem>
                  <Typography
                      variant="subtitle1">{t('pages.xmo.dynamic.privacyPolicyDescription')}
                    <Link href="https://www.facebook.com/privacy/explanation" target="_blank"
                          rel="noopener noreferrer">
                      {t('pages.xmo.dynamic.here')}
                    </Link>.
                  </Typography>
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormInput
                      id="privacyPolicyUrl"
                      theme={IQTheme}
                      labelText={t('pages.xmo.dynamic.privacyPolicyUrl')}
                      fontLabelWeight="600"
                      name="privacyPolicyUrl"
                      fullWidth
                      showError
                      tooltipText={t('pages.xmo.dynamic.privacyPolicyTooltipMessage')}
                      tooltipPlacement={"top"}
                      tooltipPaddingBottom={18}
                      schema={schema}
                      adornmentIcon={<InsertLinkIcon/>}
                      maxLength={128}
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <IQFormInput
                      id="advertiserDisclaimerUrl"
                      theme={IQTheme}
                      labelText={t('pages.xmo.dynamic.advertiserDisclaimerUrl')}
                      fontLabelWeight="600"
                      name="advertiserDisclaimerUrl"
                      fullWidth
                      showError
                      schema={schema}
                      tooltipText={t('pages.xmo.dynamic.advertiserDisclaimerTooltipMessage')}
                      tooltipPlacement={"top"}
                      tooltipPaddingBottom={18}
                      adornmentIcon={<InsertLinkIcon/>}
                      maxLength={128}
                  />
                </GridItem>
                <GridItem sizes={[12]}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Typography variant="h6" sx={{ marginBottom: '-1px',marginTop: '3px'}}>{t('pages.xmo.dynamic.contentAndCreative')}
                    </Typography>
                    <Typography variant="body1" display="block">{t('pages.xmo.dynamic.dynamicAdsDescription1')}
                    </Typography>
                    <Typography variant="body1" display="block">{t('pages.xmo.dynamic.dynamicAdsDescription2')}
                    </Typography>
                   </Grid>
                  </Grid>
            </GridItem>
                <GridItem sizes={[12]}>
                  <Typography sx={{fontWeight: 'bold'}}>
                    {t('pages.xmo.dynamic.visualStrategyClientWantToEmploy')}
                    <RadioGroup
                        id="visualStrategyClientWantToEmploy"
                        name="visualStrategyClientWantToEmploy"
                        defaultValue={product?.visualStrategyClientWantToEmploy || ''}
                        row
                    >
                      {visualStrategyClientWantToEmployList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={
                                <React.Fragment>
                                  {opt.description}
                                  {(opt.description === 'Single Video' || opt.description === 'Multiple Videos') && (
                                      <Tooltip
                                          title={t('pages.xmo.dynamic.visualStrategyTooltip')}
                                          placement="top">
                                        <InfoOutlinedIcon color="primary"
                                                          sx={{marginLeft: 1, fontSize: '15px'}}/>
                                      </Tooltip>
                                  )}
                                </React.Fragment>
                              }
                              {...register('visualStrategyClientWantToEmploy', {
                                validate: (value) => schemaValidate(value, 'visualStrategyClientWantToEmploy', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                <GridItem sizes={[12]}>
                  <Typography sx={{fontWeight: 'bold'}}>
                    {t('pages.xmo.dynamic.clientBeProvidingImagesToUse')}
                    <RadioGroup
                        id="clientBeProvidingImagesToUse"
                        name="clientBeProvidingImagesToUse"
                        defaultValue={product?.clientBeProvidingImagesToUse || ''}
                        row
                    >
                      {clientBeProvidingImagesToUseList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={opt.description}
                              {...register('clientBeProvidingImagesToUse', {
                                validate: (value) => schemaValidate(value, 'clientBeProvidingImagesToUse', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                <GridItem sizes={[12]}>
                  <Typography sx={{fontWeight: 'bold'}}>
                    {t('pages.xmo.dynamic.campaignUtilizeTheCarouselFormat')}
                    <Tooltip
                        title={t('pages.xmo.dynamic.campaignUtilizeTheCarouselFormatTooltipMessage')}
                        placement="top">
                      <InfoOutlinedIcon color="primary"
                                       sx={{marginLeft: 1, fontSize: '18px', cursor: "pointer"}}/>
                    </Tooltip>
                    <RadioGroup
                        id="campaignUtilizeTheCarouselFormat"
                        name="campaignUtilizeTheCarouselFormat"
                        defaultValue={product?.campaignUtilizeTheCarouselFormat || ''}
                        row
                    >
                      {yesNoList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={opt.description}
                              {...register('campaignUtilizeTheCarouselFormat', {
                                validate: (value) => schemaValidate(value, 'campaignUtilizeTheCarouselFormat', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                <GridItem sizes={[12]}>
                  <Typography sx={{marginBottom: '8px', fontWeight: 'bold'}}>
                    {t('Will the client be providing their own text creative? *')}
                    <RadioGroup
                        id="clientBeProvidingTextCreative"
                        name="clientBeProvidingTextCreative"
                        defaultValue={product?.clientBeProvidingTextCreative || ''}
                        row
                    >
                      {yesNoList.map(opt => (
                          <FormControlLabel
                              key={opt.value}
                              control={<Radio value={opt.value}/>}
                              label={opt.description}
                              {...register('clientBeProvidingTextCreative', {
                                validate: (value) => schemaValidate(value, 'clientBeProvidingTextCreative', schema),
                              })}
                          />
                      ))}
                    </RadioGroup>
                  </Typography>
                </GridItem>

                {((campaignUtilizeTheCarouselFormatNo && clientBeProvidingTextCreativeYes) ||
                    (campaignUtilizeTheCarouselFormatYes && clientBeProvidingTextCreativeYes)) && (
                    <>
                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="adHeadline"
                            name="adHeadline"
                            labelText={t('pages.xmo.dynamic.adHeadline')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                            maxLength={campaignUtilizeTheCarouselFormatNo ? 75 : 60}
                            subDescription={campaignUtilizeTheCarouselFormatNo ? `${watch('adHeadline').length}/75` : `${watch('adHeadline').length}/60`}
                        />
                      </GridItem>

                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="adBodyText"
                            name="adBodyText"
                            labelText={t('pages.xmo.dynamic.adBodyText')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                            maxLength={255}
                            subDescription={`${watch('adBodyText').length}/255`}
                        />
                      </GridItem>

                      <GridItem sizes={[12]}>
                        <IQFormInput
                            id="newsfeedLinkDescription"
                            name="newsfeedLinkDescription"
                            labelText={t('pages.xmo.dynamic.newsfeedLinkDescription')}
                            theme={IQTheme}
                            fontLabelWeight="600"
                            fullWidth
                            schema={schema}
                            maxLength={campaignUtilizeTheCarouselFormatNo ? 250 : 60}
                            subDescription={campaignUtilizeTheCarouselFormatNo ? `${watch('newsfeedLinkDescription').length}/250` : `${watch('newsfeedLinkDescription').length}/60`}
                        />
                      </GridItem>
                    </>
                )}
                <GridItem>
                <GridItem sizes={[12]}>
                  <Typography sx={{ marginBottom: '-18px', fontWeight: 'bold' }}>
                    {t('pages.xmo.dynamic.inventoryToFeatureCampaignLabel')}
                    <Tooltip title={t('pages.xmo.dynamic.inventoryToFeatureCampaignTooltip')} placement="top">
                        <HelpOutlineIcon color="primary" sx={{ marginLeft: 1, fontSize: '15px' }} />
                    </Tooltip>
                  </Typography>
                  <RadioGroup
                      id="inventoryToFeatureCampaign"
                      name="inventoryToFeatureCampaign"
                      defaultValue={product?.inventoryToFeatureCampaign}
                      row
                  >
                    {inventoryToFeatureCampaignOptions.map(opt => (
                        <FormControlLabel
                            key={opt.label}
                            control={<Radio value={opt.label} />}
                            label={t(opt.label)}
                            {...register('inventoryToFeatureCampaign', {
                              validate: (value) => schemaValidate(value, 'inventoryToFeatureCampaign', schema),
                            })}
                        />
                    ))}
                  </RadioGroup>
            </GridItem>
            <GridItem sizes={[12]}>
                  <Typography sx={{ marginBottom: '-1px',marginTop: '-29px'}}>{t('pages.xmo.dynamic.campaignLiveWarning')}
                  </Typography>
            </GridItem>
                  <NeSelectChip label
                                labelText={t('pages.xmo.dynamic.services')}
                                items={[]}
                                name="services"
                                placeholder={t('pages.xmo.dynamic.servicesPlaceholder')}
                                values={product?.services}
                                maxItems={4}
                                freeSolo
                                required
                                disableClear
                                schema={schema}
                                theme={IQTheme}
                  />
                </GridItem>
              </GridContainer>
            </Box>
          </DefaultPageLayout>
          </FormProvider>
        </ValidationProvider>
  );
}