import { IQFormInput, IQFormTextArea, ValidationProvider } from '@gannettdigital/shared-react-components';
import {
  Button, Grid, Typography, useTheme,
} from '@mui/material';
import NeCheckbox from 'components/checkbox/NeCheckbox';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { BusinessFunctionality } from 'services/adminSlice';

interface Props {
  onSubmit: (data: any) => void
  functionality: BusinessFunctionality
}

export default function FunctionalityForm({ onSubmit, functionality }: Props) {
  const theme = useTheme();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      name: '', description: '', notes: '', active: false, slug: '',
    },
  });
  const {
    handleSubmit, setValue,
  } = methods;

  useEffect(() => {
    if (functionality.id !== 0) {
      setValue('name', functionality.name);
      setValue('description', functionality.description);
      setValue('notes', functionality.notes);
      setValue('active', functionality.active);
      setValue('slug', functionality.slug);
    }
  }, [functionality]);

  const schema = {
    yupValidations: {},
    customValidations: {},
  };

  return (
    <Grid container>
      <FormProvider {...methods}>
        <ValidationProvider schema={schema}>
          <Grid item xs={8} marginTop={3}>
            <IQFormInput
              labelText="Name"
              id="name"
              name="name"
              theme={theme}
              schema={schema}
              fullWidth
            />
          </Grid>
          <Grid item xs={8} marginTop={3}>
            <IQFormTextArea
              id="description"
              name="description"
              labelText="Description"
              fullWidth
            />
          </Grid>
          <Grid item xs={8} marginTop={3}>
            <IQFormTextArea
              id="notes"
              name="notes"
              labelText="Notes"
              fullWidth
            />
          </Grid>
          <Grid item xs={8} marginTop={3}>
            <IQFormInput
              labelText="Slug"
              id="slug"
              name="slug"
              theme={theme}
              schema={schema}
              fullWidth
            />
          </Grid>
          <Grid item xs={8} marginTop={3}
            display="flex" flexDirection="row" alignItems="baseline"
          >
            <Typography marginRight={1}>Active</Typography>
            <NeCheckbox
              label="Visible in theme configuration"
              name="active"
              field="active"
            />
          </Grid>
        </ValidationProvider>
      </FormProvider>
      <Grid item xs={12} marginTop={3}>
        <Button variant="contained"
          sx={{ marginRight: 3 }}
          onClick={handleSubmit(onSubmit)}
        >
          <Typography>Save</Typography>
        </Button>
        <Link to="/admin/businessFunctionalities">
          <Button variant="contained">
            <Typography>Back to Business Functionalities</Typography>
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}
