// eslint-disable-next-line no-use-before-define
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import { ThemeProvider } from '@mui/material';
// eslint-disable-next-line import/no-named-as-default
import store from './services/redux/store';
import App from './app';

async function main() {
  // if (process.env.REACT_APP_MOCK_REQUESTS === 'true') {
  //   // eslint-disable-next-line global-require
  //   const { worker } = require('./mocks/browser');
  //   await worker.start({
  //     serviceWorker: {
  //       url: '/mockServiceWorker.js',
  //     },
  //     onUnhandledRequest(req) {
  //       // eslint-disable-next-line no-console
  //       console.error(
  //         'Found an unhandled %s request to %s',
  //         req.method,
  //         req.url.href,
  //       );
  //     },
  //   });
  //   worker.printHandlers();
  // }

  const rootElement = document.getElementById('root');
  // eslint-disable-next-line function-paren-newline
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={IQThemeV2}>
        <React.Suspense fallback="Loading">
          <App />
        </React.Suspense>
      </ThemeProvider>
    </Provider>, rootElement);
}

main();
