import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';

export const chatPlatinumMenu: MenuType = ({
  label: 'Live Chat Platinum',
  url: '',
  status: 'active',
  offeringType: 'ChatPlatinumProduct',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
    {
      label: 'Business URL',
      url: Urls.PlatinumBusinessChat,
      status: 'incomplete',
    },
    {
      label: 'Script Details',
      url: Urls.PlatinumScriptDetails,
      status: 'incomplete',
    },
    {
      label: 'Locations',
      url: Urls.PlatinumChatLocations,
      status: 'incomplete',
    },
    {
      label: 'Additional',
      url: Urls.PlatinumChatAdditional,
      status: 'incomplete',
    },
  ],
});
