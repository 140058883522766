import React, { useState, useEffect } from 'react';
import {
  Grid, Typography, FormControlLabel, Checkbox,
} from '@mui/material';

export default function SingleCheckedCheckbox(props: any) {
  const { options: { enumOptions }, value } = props;

  const initialSelectedOptions = enumOptions.reduce((acc: any, option: any) => {
    acc[option.value] = option.value === value;
    return acc;
  }, {});

  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions);

  useEffect(() => {
    const updatedSelectedOptions = enumOptions.reduce((acc: any, option: any) => {
      acc[option.value] = option.value === value;
      return acc;
    }, {});
    setSelectedOptions(updatedSelectedOptions);
  }, [value, enumOptions]);

  const handleChange = (value: any) => {
    const newSelectedOptions = {
      ...selectedOptions,
      [value]: !selectedOptions[value],
    };

    if (newSelectedOptions[value]) {
      Object.keys(newSelectedOptions).forEach(key => {
        if (key !== value) {
          newSelectedOptions[key] = false;
        }
      });
    }

    setSelectedOptions(newSelectedOptions);
    const selectedValue = newSelectedOptions[value] ? value : undefined;
    props.onChange(selectedValue);
  };

  const selectedKey = Object.keys(selectedOptions).find(key => selectedOptions[key]);

  return (
    <Grid item mt={-1}>
      <Typography variant="subtitle2" component="h3" fontSize={16}>
        {props.label}
      </Typography>
      <Grid container direction="column" mt={1}>
        {enumOptions.map(option => {
          const shouldRender = selectedKey ? selectedKey === option.value : true;
          return shouldRender && (
            <Grid item key={option.value}>
              <FormControlLabel
                id={props.id}
                control={(
                  <Checkbox
                    checked={selectedOptions[option.value]}
                    onChange={() => handleChange(option.value)}
                  />
                      )}
                label={option.label}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
