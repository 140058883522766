import React from 'react';
import { Urls } from 'navigation/Urls';
import DomainPageForm from 'pages/ecommerce/domain/DomainPageForm';
import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import EcommerceType, { EcommerceStateObject } from 'models/EcommerceType';
import { StatusType } from 'models/StatusType';

export default function DomainPage() {
  const prodFunc = useProduct<EcommerceType, StatusType<EcommerceStateObject>>(Urls.Domain);

  return (
    <ContentLoader status={prodFunc.product.status} message={prodFunc.product.message}>
      <DomainPageForm product={prodFunc.product.content?.data} onSubmit={prodFunc.onSubmit} onBack={prodFunc.onBack} />
    </ContentLoader>
  );
}
