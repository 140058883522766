/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import FileUploader from 'pages/generic-product/details/custom-fields/FileUploader';
import { ProductTypes } from 'services/fileUploaderSlice';
import { validateEmail } from 'pages/generic-product/details/custom-fields/EmailTextField';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';
import SchedulerWidget from '../custom-fields/SchedulerWidget';

const { t } = i18n;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SmbRetailGCISmartListingsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.smbRetail.smartListings.pageTitle'),
  type: 'object',
  required: [],
  properties: {
    headingLabel: {
      type: 'string',
      title: t('pages.smbRetail.common.headingLabel'),
    },
    clientContactFirstName: {
      type: 'string',
      title: t('pages.smbRetail.common.firstNameLabel'),
      default: '',
    },
    clientContactLastName: {
      type: 'string',
      title: t('pages.smbRetail.common.lastNameLabel'),
      default: '',
    },
    clientsContactPhone: {
      type: 'string',
      title: t('pages.smbRetail.common.phoneLabel'),
      default: '',
    },
    clientsContactEmail: {
      type: 'string',
      title: t('pages.smbRetail.common.emailLabel'),
      default: '',
      description: t('pages.smbRetail.common.emailHelpLabel'),
    },
    googleMyBusinessAccessGranted: {
      type: 'string',
      title: t('pages.smbRetail.common.gmbAccessDropDownLabel'),
      default: '',
      description: t('pages.smbRetail.common.helpText2'),
      enum: [
        '',
        t('pages.smbRetail.common.option1Label'),
        t('pages.smbRetail.common.option2Label'),
        t('pages.smbRetail.common.option3Label'),
      ],
    },
    businessType: {
      type: 'string',
      title: t('pages.smbRetail.common.businessTypeDropDownLabel'),
      enum: [
        t('pages.smbRetail.common.brickLabel'),
        t('pages.smbRetail.common.serviceAreaLabel'),
        t('pages.smbRetail.common.serviceAreaBusinessLabel'),
      ],
    },
    businessName: {
      type: 'string',
      title: t('pages.smbRetail.common.businessNameLabel'),
      default: '',
    },
    instructions: {
      type: 'string',
      title: t('pages.smbRetail.common.link1'),
      enum: ['https://support.google.com/business/answer/3038177?hl=en', true],
    },
    businessCategory: {
      type: 'string',
      title: t('pages.smbRetail.common.businessCategoryLabel'),
      default: '',
    },
    businessCategoryGuide: {
      type: 'string',
      title: t('pages.smbRetail.common.link2'),
      enum: ['https://www.propellic.com/blog/google-my-business-categories', true],
    },
    businessPhone: {
      type: 'string',
      title: t('pages.smbRetail.common.businessPhoneLabel'),
      default: '',
    },
    secondaryBusinessPhone: {
      type: 'string',
      title: t('pages.smbRetail.common.secondaryBusinessPhoneLabel'),
      default: '',
    },
    businessFaxPhone: {
      type: 'string',
      title: t('pages.smbRetail.common.businessFaxPhoneLabel'),
      default: '',
    },
    businessEmail: {
      type: 'string',
      title: t('pages.smbRetail.common.businessEmailLabel'),
      default: '',
    },
    leadsEmailIfDifferentFromBusinessEmail: {
      type: 'string',
      title: t('pages.smbRetail.common.leadsEmailLabel'),
      default: '',
      description: t('pages.smbRetail.common.helpText3'),
    },
    hoursOfOperation: {
      type: 'string',
      title: t('pages.smbRetail.common.hoursOfOperationLabel'),
    },
    websiteAvailable: {
      type: 'boolean',
      title: t('pages.smbRetail.common.websiteAvailableLabel'),
    },
    businessDescriptionStyleFeatured: {
      type: 'string',
      title: t('pages.smbRetail.common.descriptionStyleLabel'),
      default: '',
      description: t('pages.smbRetail.common.helpText4'),
    },
    businessDescriptionStyleShort: {
      type: 'string',
      title: t('pages.smbRetail.common.descriptionStyleShortLabel'),
      enum: [
        t('pages.smbRetail.common.featuredOption'),
        t('pages.smbRetail.common.longOption'),
        t('pages.smbRetail.common.shortOption'),
      ],
    },
    businessDescriptionStyleLong: {
      type: 'string',
      title: t('pages.smbRetail.common.descriptionStyleLongLabel'),
      default: '',
    },
    uploader_businessLogo: {
      type: 'string',
      default: '',
      title: t('pages.smbRetail.common.logoLabel'),
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.custom.solutions.common.notesInstructionsLabel'),
      default: '',
    },
  },
  dependencies: {
    websiteAvailable: {
      oneOf: [
        {
          properties: {
            websiteAvailable: {
              enum: [true],
            },
            websiteUrl: {
              type: 'string',
              title: t('pages.smbRetail.common.websiteURLLabel'),
              default: '',
            },
          },
        },
      ],
    },
    googleMyBusinessAccessGranted: {
      oneOf: [
        {
          properties: {
            googleMyBusinessAccessGranted: {
              enum: [
                'Client Has Access',
                'Client Does NOT Have Access(Pending Review, Require Manual Verification or Request Ownership)',
              ],
            },
            googleMyBusinessPageLink: {
              type: 'string',
              title: t('pages.smbRetail.common.gmbPageLinkLabel'),
              default: '',
            },
          },
        },
        {
          properties: {
            googleMyBusinessAccessGranted: {
              enum: ['No GMB'],
            },
          },
        },
      ],
    },
    businessType: {
      oneOf: [
        {
          properties: {
            businessType: {
              enum: ['Brick and Mortar'],
            },
            businessAddressForBrickAndMortar: {
              type: 'string',
              title: t('pages.smbRetail.common.businessAddress'),
              default: '',
            },
          },
        },
        {
          properties: {
            businessType: {
              enum: ['Service Area Business + Walk-In'],
            },
            businessAddressForServiceAreaAndWalkIn: {
              type: 'string',
              title: t('pages.smbRetail.common.businessAddress'),
              default: '',
            },
          },
        },
      ],
    },
  },
});

const textFieldKeys = [
  'clientContactFirstName',
  'clientContactLastName',
  'businessName',
  'businessCategory',
  'businessDescriptionStyleLong',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const dropDownKeys = [
  'businessType',
  'businessDescriptionStyleShort',
];

const dropDownFields = dropDownKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const anchorKeys = [
  'instructions',
  'businessCategoryGuide',
];

const anchorFields = anchorKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'anchorTextLabel' },
}), {});

const phoneNumberTextFieldKeys = [
  'clientsContactPhone',
  'businessPhone',
  'secondaryBusinessPhone',
  'businessFaxPhone',
];

const phoneNumberTextFields = phoneNumberTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'phoneNumberTextField' },
}), {});

const urlFieldKeys = [
  'googleMyBusinessPageLink',
  'websiteUrl',
];

const urlFields = urlFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'UrlTextWidget' },
}), {});

const emailFieldKeys = [
  'clientsContactEmail',
  'businessEmail',
  'leadsEmailIfDifferentFromBusinessEmail',
];

const emailFields = emailFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'EmailTextWidget' },
}), {});

const textAreaKeys = [
  'businessDescriptionStyleFeatured',
  'notesOrInstructions',
];

const textAreaFields = textAreaKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const addressKeys = [
  'businessAddressForBrickAndMortar',
  'businessAddressForServiceAreaAndWalkIn',
];

const addressFields = addressKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'addressField' },
}), {});

export const SmbRetailGCISmartListingsUISchema = (data) => ({
  'ui:order': ['headingLabel', 'clientContactFirstName', 'clientContactLastName', 'clientsContactPhone',
    'clientsContactEmail', 'googleMyBusinessAccessGranted', 'googleMyBusinessPageLink',
    'businessType', 'businessName', 'instructions', 'businessCategory', 'businessCategoryGuide',
    'businessAddressForBrickAndMortar', 'businessAddressForServiceAreaAndWalkIn',
    'businessPhone',
    'secondaryBusinessPhone', 'businessFaxPhone', 'businessEmail', 'leadsEmailIfDifferentFromBusinessEmail',
    'hoursOfOperation', 'schedule',
    'websiteAvailable', 'websiteUrl', 'businessDescriptionStyleFeatured', 'businessDescriptionStyleShort',
    'businessDescriptionStyleLong', 'uploader_businessLogo', 'notesOrInstructions'],

  headingLabel: {
    'ui:widget': 'headingTextLabel',
  },
  hoursOfOperation: {
    'ui:widget': (props) => <SchedulerWidget {...props} data={data} />,
  },
  googleMyBusinessAccessGranted: {
    'ui:widget': 'dropdown',
  },
  uploader_businessLogo: {
    'ui:widget': (props) => (
      <FileUploader
        {...props}
        uploaderType={ProductTypes.SMB_RETAIL_GCI_ONLY_SMART_LISTINGS_UPLOADER}
        totalAllowedFiles={1}
        imageTypes={['.png', '.jpeg', '.jpg', '.gif']}
        videoTypes={[]}
        documentTypes={[]}
        supportingText=""
      />
    ),
    'ui:style': {
      marginTop: '20px',
    },
  },

  ...addressFields,
  ...textAreaFields,
  ...emailFields,
  ...urlFields,
  ...phoneNumberTextFields,
  ...anchorFields,
  ...dropDownFields,
  ...textFields,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SmbRetailGCISmartListingsCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.businessEmail !== '' && !validateEmail(formData?.businessEmail)) {
    errors.businessEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
  }
  if (formData?.clientsContactEmail !== '' && !validateEmail(formData?.clientsContactEmail)) {
    errors.clientsContactEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
  }
  if (formData?.leadsEmailIfDifferentFromBusinessEmail !== ''
  && !validateEmail(formData?.leadsEmailIfDifferentFromBusinessEmail)) {
    // eslint-disable-next-line max-len
    errors.leadsEmailIfDifferentFromBusinessEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
  }
  // eslint-disable-next-line max-len
  if (formData?.googleMyBusinessAccessGranted === 'Client Has Access' || formData?.googleMyBusinessAccessGranted === 'Client Does NOT Have Access(Pending Review, Require Manual Verification or Request Ownership)') {
    if (formData?.googleMyBusinessPageLink !== '' && !validateUrl(formData?.googleMyBusinessPageLink)) {
      errors.googleMyBusinessPageLink.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.websiteAvailable === true) {
    if (formData?.websiteUrl !== '' && !validateUrl(formData?.websiteUrl)) {
      errors.websiteUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
      errors.addError(true);
    }
  }
  return errors;
};
