import {
  Grid, Card, CardMedia, CardActions, Button, Typography,
} from '@mui/material';
import { setImageUrl } from 'services/themesSlice';
import { underlinedOnHover } from './Themes';

interface Props {
  themeItem: any
  goToTheme: (id: string | number) => void
  hasAddToCart?: boolean
  addToCart?: (any) => void
}

export default function ThemeTile(props: Props) {
  const { themeItem, goToTheme } = props;

  return (
    <Grid key={themeItem.id} item
      md={6} lg={4} xl={3}
      marginBottom={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&:hover': {
          '.MuiCardMedia-root': {
            opacity: 0.5,
          },
          '.MuiButton-root': {
            display: 'flex',
          },
        },
      }}
      classes={{ root: 'product-grid' }}
    >
      <Card
        sx={{
          position: 'relative',
          width: '250px',
          marginBottom: 3,
          borderRadius: '8px',
        }}
      >
        <CardMedia
          image={setImageUrl(themeItem.id, themeItem.screenshotImage, 'screenshot_image')}
          sx={{ height: '250px', display: 'flex' }}
        />
        <CardActions
          sx={{
            position: 'absolute',
            top: `${props.hasAddToCart ? '80px' : '120px'}`,
            left: 0,
            width: '100%',
            paddingLeft: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {props?.hasAddToCart && (
            <Button
              variant="contained"
              sx={{
                margin: 'auto',
                marginBottom: '15px',
                display: 'none',
                width: '153px',
              }}
              onClick={props?.addToCart}
            >
              Add to Cart
            </Button>
          )}
          <Button
            variant="outlined"
            sx={{
              margin: 'auto',
              marginLeft: 'auto !important',
              display: 'none',
              backgroundColor: '#ffffff !important',
              width: '153px',
            }}
            onClick={() => goToTheme(themeItem.id)}
          >
            See Details Page
          </Button>
        </CardActions>
      </Card>
      <Typography onClick={() => goToTheme(themeItem.id)}
        classes={{ root: 'product-name' }}
        sx={{
          color: '#000000',
          fontWeight: 'bold',
          fontSize: '20px',
          display: 'block',
          width: '250px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...underlinedOnHover,
        }}
      >
        {themeItem.title}
      </Typography>
    </Grid>
  );
}
