import * as yup from 'yup';

const schema = {
  existingDomainUrl: yup.string().url('Please enter a valid URL that includes either http:// or https://').nullable()
    .required('Please enter a valid URL that includes either http:// or https://'),
  domainRegistrar: yup.string().nullable()
    .required('Please enter a domain registrar (i.e. GoDaddy, Google domains, Register.com)'),
  domainEmail: yup.string().email('Please enter a valid email in this form name@example.com')
    .nullable(),
  registrarUsername: yup.string().nullable()
    .required('Please enter registrar username.'),
  registrarPassword: yup.string().nullable()
    .required('Please enter registrar password.'),
  // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
