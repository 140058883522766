import { IQFormInput, IQFormTextArea, ValidationProvider } from '@gannettdigital/shared-react-components';
import {
  Button, Grid, Typography, useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ThemeBenefit } from 'services/adminSlice';

interface Props {
  onSubmit: (data: any) => void
  benefit: ThemeBenefit
}

export default function BenefitForm({ onSubmit, benefit }: Props) {
  const theme = useTheme();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      title: '', description: '',
    },
  });
  const {
    handleSubmit, setValue,
  } = methods;

  useEffect(() => {
    if (benefit.id !== 0) {
      setValue('title', benefit.title);
      setValue('description', benefit.description);
    }
  }, [benefit]);

  const schema = {
    yupValidations: {},
    customValidations: {},
  };

  return (
    <Grid container>
      <FormProvider {...methods}>
        <ValidationProvider schema={schema}>
          <Grid item xs={8} marginTop={3}>
            <IQFormInput
              labelText="Title"
              id="title"
              name="title"
              theme={theme}
              schema={schema}
              fullWidth
            />
          </Grid>
          <Grid item xs={8} marginTop={3}>
            <IQFormTextArea
              id="description"
              name="description"
              labelText="Description"
              fullWidth
            />
          </Grid>
        </ValidationProvider>
      </FormProvider>
      <Grid item xs={12} marginTop={3}>
        <Button variant="contained"
          sx={{ marginRight: 3 }}
          onClick={handleSubmit(onSubmit)}
        >
          <Typography>Save</Typography>
        </Button>
        <Link to="/admin/themeBenefits">
          <Button variant="contained">
            <Typography>Back to Theme Benefits</Typography>
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}
