import { FormProvider, useForm } from 'react-hook-form';
import { IQCheckbox, ValidationProvider } from '@gannettdigital/shared-react-components';
import { useTranslation } from 'react-i18next';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import YesNoRadio from 'components/yesNo/YesNoRadio';
import { styled } from '@mui/material';
import EcommerceType from 'models/EcommerceType';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import React, { useEffect } from 'react';
import { generateTooltipTextElement } from 'shared/generateTooltipText';
import schema from './AgeGate.schema';

interface Props {
  product: EcommerceType;
  onSubmit: (data) => any;
  onBack: () => any;
}

const StyledGridItem = styled(GridItem)`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 16px;
  letter-spacing: 0.25px;
  color: ${props => props.theme.palette.text.primary};
  
  div {
    margin-bottom: 0;
    font-weight: ${props => props.theme.typography.fontWeightBold};
  }
  ul {
    padding: 0 25px;
    margin: 8px 0;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    line-height: 24px;
  }
`;

const acknowledgementYesText = 'An \'age gate\' will be added to this website. '
    + 'Contact Gannett\'s Legal Department if you would like to request an exception to this policy. '
    + 'Exception must be provided in writing.';

const acknowledgmentNoText = 'An \'age gate\' will be added to this website. '
    + 'A client must request in writing that the age gate be removed.';

export default function AgeGateForm(props: Props) {
  const { t } = useTranslation();
  const { product, onSubmit, onBack } = props;

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      websiteContainsDrugsNudityFirearmsDating: product?.websiteContainsDrugsNudityFirearmsDating || '',
      paidAdvertisingCampaigns: product?.paidAdvertisingCampaigns || '',
      ageGateAcknowledgment: product?.ageGateAcknowledgment || null,
    },
  });

  const {
    handleSubmit, formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    },
    watch, setValue, unregister,
  } = methods;

  const websiteContainsSelection = watch('websiteContainsDrugsNudityFirearmsDating');
  const paidAdvertisingYesNo = watch('paidAdvertisingCampaigns');

  const acknowledgementTooltipText = generateTooltipTextElement(t('pages.ecommerce.ageGate.acknowledgementTooltip'), {
    render: true,
    href: 'https://hagar4.localiq.site/age-gate-demo/',
    linkText: 'View Example',
    referrerPolicy: 'no-referrer',
    rel: 'noreferrer',
    target: '_blank',
    style: { color: 'white' },
  });

  useEffect(() => {
    if (websiteContainsSelection === 'no') {
      setValue('paidAdvertisingCampaigns', '');
      unregister('ageGateAcknowledgment');
    }
  }, [websiteContainsSelection]);

  useEffect(() => {
    setValue('ageGateAcknowledgment', null);
    unregister('ageGateAcknowledgment');
  }, [paidAdvertisingYesNo]);

  return (
    <DefaultPageLayout header="Age Gate"
      onBack={onBack} onContinue={handleSubmit(onSubmit)}
      disableContinue={!isValid}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <GridContainer>
            <StyledGridItem marginBottom={0}>
              <div>Will this website contain any content related to the following:</div>
              <ul>
                <li>Alcohol</li>
                <li>Tobacco</li>
                <li>Vaping</li>
                <li>Cannabis</li>
                <li>Nudity</li>
                <li>Firearms</li>
                <li>Dating</li>
              </ul>
            </StyledGridItem>
            <GridItem>
              <YesNoRadio
                name="websiteContainsDrugsNudityFirearmsDating"
              >
                <GridItem marginBottom={0}>
                  <YesNoRadio
                    label="Will the client be running any paid advertising campaigns?"
                    name="paidAdvertisingCampaigns"
                  />
                </GridItem>
              </YesNoRadio>
            </GridItem>
            <GridItem>
              {paidAdvertisingYesNo
              && (
              <IQCheckbox
                title="Age Gate Acknowledgement *"
                label={paidAdvertisingYesNo === 'yes' ? acknowledgementYesText : acknowledgmentNoText}
                name="ageGateAcknowledgment"
                field="ageGateAcknowledgment"
                tooltipText={acknowledgementTooltipText as unknown as string}
                tooltipPlacement="top"
                tooltipArrow={false}
              />
              )}
            </GridItem>
          </GridContainer>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
