import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";

const {t} = i18n;

export const FacebookCampaignObjectiveSchema: RJSFSchema | any = (data) => ({
  title: t('pages.socialAds.solutionStrategy.socialAdsCustom.facebookCampaignObjective'),
  type: 'object',
  required: [
    'demographicTargeting',
    'interestTargeting',
    'behavioralTargeting'
  ],
  properties: {
    facebookCampaignObjective: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.facebookCampaignObjective'),
      description: t('pages.socialAds.solutionStrategy.socialAdsCustom.facebookCampaignObjectiveDescription'),
      default: data?.facebookCampaignObjective || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.socialAdsCustom.facebookCampaignObjectiveOptions.awareness'),
        t('pages.socialAds.solutionStrategy.socialAdsCustom.facebookCampaignObjectiveOptions.traffic'),
        t('pages.socialAds.solutionStrategy.socialAdsCustom.facebookCampaignObjectiveOptions.engagement'),
        t('pages.socialAds.solutionStrategy.socialAdsCustom.facebookCampaignObjectiveOptions.leads'),
        t('pages.socialAds.solutionStrategy.socialAdsCustom.facebookCampaignObjectiveOptions.sales'),
      ],
    },
    campaignObjectiveDetailLabel: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.campaignObjectiveDetailLabel'),
    },
    campaignObjectiveDetail: {
      type: 'string',
      title: 'https://www.facebook.com/business/help/1438417719786914',
      enum: ['https://www.facebook.com/business/help/1438417719786914', true]
    },
    doesClientHaveBrandGuidelines: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.doesClientHaveBrandGuidelines'),
      default: data?.doesClientHaveBrandGuidelines || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.socialAdsCustom.yesOrNoOptions.yes'),
        t('pages.socialAds.solutionStrategy.socialAdsCustom.yesOrNoOptions.no'),
      ],
    },
    addImageDescription: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.addImageDescription'),
    },
    imageMaxSize: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.imageMaxSize'),
    },
    imageMinSize: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.imageMinSize'),
    },
    imageMaxFileSize: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.imageMaxFileSize'),
    },
    textOnImage: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.textOnImage'),
    },
    willClientSupplyOwnVideo: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.willClientSupplyOwnVideo'),
      default: data?.willClientSupplyOwnVideo || '',
      enum: [
        '',
        t('pages.socialAds.solutionStrategy.socialAdsCustom.yesOrNoOptions.yes'),
        t('pages.socialAds.solutionStrategy.socialAdsCustom.yesOrNoOptions.no'),
      ],
    },
    detailedTargeting: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.detailedTargeting'),
      description: t('pages.socialAds.solutionStrategy.socialAdsCustom.detailedTargetingHelperText')
    },
    selectDetailedTargetingPreferences: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.selectDetailedTargetingPreferences'),
    },
    demographicTargeting: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.demographicTargeting'),
      default: data?.demographicTargeting || '',
      enum: [
        '',
        'Yes',
        'No'
      ],
    },
    interestTargeting: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.interestTargeting'),
      default: data?.interestTargeting || '',
      enum: [
        '',
        'Yes',
        'No'
      ],
    },
    behavioralTargeting: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.behavioralTargeting'),
      default: data?.behavioralTargeting || '',
      enum: [
        '',
        'Yes',
        'No'
      ],
    },
  },
  dependencies: {
    willClientSupplyOwnVideo: {
      oneOf: [
        {
          properties: {
            willClientSupplyOwnVideo: {
              enum: ['Yes']
            },
            videoAdCreativeAttachment: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.videoAdCreativeAttachment'),
            },
            facebookVideoAdSize: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.facebookVideoAdSize'),
            },
            textCharacters: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.textCharacters'),
            },
            headlineCharacters: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.headlineCharacters'),
            },
            formatMp4: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.formatMp4'),
            },
            videoLength: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.videoLength'),
            },
          }
        },
        {
          properties: {
            willClientSupplyOwnVideo: {
              enum: ['No']
            },
            willClientPurchaseVideoFromReachlocal: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.willClientPurchaseVideoFromReachlocal'),
              default: data?.willClientPurchaseVideoFromReachlocal || '',
              enum: [
                '',
                'Yes',
                'No'
              ],
            },
          }
        }
      ]
    },
    demographicTargeting: {
      oneOf: [
        {
          properties: {
            demographicTargeting: {
              enum: ['Yes']
            },
            demographicTargetingHeading: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.demographicTargetingHeading'),
              description: t('pages.socialAds.solutionStrategy.socialAdsCustom.demographicTargetingHeadingDescription'),
            },
            education: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.education'),
            },
            financial: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.financial'),
            },
            generation: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.generation'),
            },
            lifeEvents: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.lifeEvents'),
            },
            parents: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.parents'),
            },
            politics: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.politics'),
            },
            work: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.work'),
            },
            desiredDetailDemographicTargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.desiredDetailDemographicTargeting'),
              default: ''
            },
          }
        }
      ]
    },
    interestTargeting: {
      oneOf: [
        {
          properties: {
            interestTargeting: {
              enum: ['Yes']
            },
            interestTargetingHeading: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.interestTargetingHeading'),
              description: t('pages.socialAds.solutionStrategy.socialAdsCustom.interestTargetingHeadingDescription'),
            },
            businessAndIndustry: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.businessAndIndustry'),
            },
            entertainmentAndRelationship: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.entertainmentAndRelationship'),
            },
            fitnessAndWellness: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.fitnessAndWellness'),
            },
            foodAndDrink: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.foodAndDrink'),
            },
            hobbiesAndActivities: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.hobbiesAndActivities'),
            },
            shoppingAndFashion: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.shoppingAndFashion'),
            },
            sportsAndOutdoors: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.sportsAndOutdoors'),
            },
            technology: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.technology'),
            },
            desiredDetailInterestTargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.desiredDetailInterestTargeting'),
              default: ''
            },
          }
        }
      ]
    },
    behavioralTargeting: {
      oneOf: [
        {
          properties: {
            behavioralTargeting: {
              enum: ['Yes']
            },
            behavioralTargetingHeading: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.behavioralTargetingHeading'),
              description: t('pages.socialAds.solutionStrategy.socialAdsCustom.behavioralTargetingHeadingDescription'),
            },
            businessAndIndustryBehavioral: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.businessAndIndustry'),
            },
            entertainmentAndRelationshipBehavioral: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.entertainmentAndRelationship'),
            },
            fitnessAndWellnessBehavioral: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.fitnessAndWellness'),
            },
            foodAndDrinkBehavioral: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.foodAndDrink'),
            },
            hobbiesAndActivitiesBehavioral: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.hobbiesAndActivities'),
            },
            shoppingAndFashionBehavioral: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.shoppingAndFashion'),
            },
            sportsAndOutdoorsBehavioral: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.sportsAndOutdoors'),
            },
            technologyBehavioral: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.technology'),
            },
            desiredDetailBehavioralTargeting: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.desiredDetailInterestTargeting'),
              default: ''
            },
          }
        }
      ]
    },
  }
});

const dropdownFieldKeys = [
  'facebookCampaignObjective',
  'doesClientHaveBrandGuidelines',
  'willClientSupplyOwnVideo',
  'willClientPurchaseVideoFromReachlocal',
  'demographicTargeting',
  'interestTargeting',
  'behavioralTargeting'
]

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => <Dropdown {...props}/>
  },
}), {});

const textLabelKeys = [
  'imageMaxSize',
  'imageMinSize',
  'imageMaxFileSize',
  'textOnImage',
  'facebookVideoAdSize',
  'textCharacters',
  'headlineCharacters',
  'formatMp4',
  'videoLength',
  'education',
  'financial',
  'generation',
  'lifeEvents',
  'parents',
  'politics',
  'work',
  'businessAndIndustry',
  'entertainmentAndRelationship',
  'fitnessAndWellness',
  'foodAndDrink',
  'hobbiesAndActivities',
  'shoppingAndFashion',
  'sportsAndOutdoors',
  'technology',
  'businessAndIndustryBehavioral',
  'entertainmentAndRelationshipBehavioral',
  'fitnessAndWellnessBehavioral',
  'foodAndDrinkBehavioral',
  'hobbiesAndActivitiesBehavioral',
  'shoppingAndFashionBehavioral',
  'sportsAndOutdoorsBehavioral',
  'technologyBehavioral'
]

const labelFields = textLabelKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': 'textLabel', 'ui:style': {
      marginTop: '-20px',
    },
  },
}), {});

const headingKeys = [
  'detailedTargeting',
  'demographicTargetingHeading',
  'interestTargetingHeading',
  'behavioralTargetingHeading'
]

const headingFields = headingKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': 'headingTextLabel',
  },
}), {});

const textAreaKeys = [
  'desiredDetailDemographicTargeting',
  'desiredDetailInterestTargeting',
  'desiredDetailBehavioralTargeting'
]

const textAreaFields = textAreaKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': 'TextareaWidget',
  },
}), {});

export const FacebookCampaignObjectiveUISchema = {
  'ui:order': [
    'facebookCampaignObjective', 'campaignObjectiveDetailLabel', 'campaignObjectiveDetail', 'doesClientHaveBrandGuidelines',
    'addImageDescription', 'imageMaxSize', 'imageMinSize', 'imageMaxFileSize', 'textOnImage', 'willClientSupplyOwnVideo',
    'willClientPurchaseVideoFromReachlocal', 'videoAdCreativeAttachment', 'facebookVideoAdSize', 'textCharacters',
    'headlineCharacters', 'formatMp4', 'videoLength', 'detailedTargeting', 'selectDetailedTargetingPreferences',
    'demographicTargeting', 'interestTargeting', 'behavioralTargeting', 'demographicTargetingHeading',
    'education', 'financial', 'generation', 'lifeEvents', 'parents', 'politics', 'work', 'desiredDetailDemographicTargeting',
    'interestTargetingHeading', 'businessAndIndustry', 'entertainmentAndRelationship', 'fitnessAndWellness', 'foodAndDrink',
    'hobbiesAndActivities', 'shoppingAndFashion', 'sportsAndOutdoors', 'technology', 'desiredDetailInterestTargeting',
    'behavioralTargetingHeading', 'businessAndIndustryBehavioral', 'entertainmentAndRelationshipBehavioral', 'fitnessAndWellnessBehavioral',
    'foodAndDrinkBehavioral', 'hobbiesAndActivitiesBehavioral', 'shoppingAndFashionBehavioral', 'sportsAndOutdoorsBehavioral',
    'technologyBehavioral', 'desiredDetailBehavioralTargeting'
  ],
  ...dropdownFields,
  ...labelFields,
  ...headingFields,
  ...textAreaFields,
  campaignObjectiveDetail: {
    'ui:widget': 'anchorTextLabel',
    'ui:style': {
      marginTop: '-15px',
    },
  },
  addImageDescription: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '10px',
    },
  },
  videoAdCreativeAttachment: {
    'ui:widget': 'textLabel',
    'ui:style': {
      marginBottom: '10px',
    },
  },
  selectDetailedTargetingPreferences: {
    'ui:widget': 'textLabel',
  },
  campaignObjectiveDetailLabel: {
    'ui:widget': 'textLabel',
  },

};

export const FacebookCampaignObjectiveCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.demographicTargeting === '') {
    errors.demographicTargeting.addError(t('pages.socialAds.solutionStrategy.socialAdsCustom.detailedTargetingPreferencesError'));
    errors.addError(true);
  }
  if (formData?.interestTargeting === '') {
    errors.interestTargeting.addError(t('pages.socialAds.solutionStrategy.socialAdsCustom.detailedTargetingPreferencesError'));
    errors.addError(true);
  }
  if (formData?.behavioralTargeting === '') {
    errors.behavioralTargeting.addError(t('pages.socialAds.solutionStrategy.socialAdsCustom.detailedTargetingPreferencesError'));
    errors.addError(true);
  }
  return errors;
};