export const defaultValues = {
  campaignName: '',
  campaignOwner: '',
  budget: '',
  budgetOverride:'',
  salesforceMinBudget:'',
  initialCycles: '',
  budgetReallocationCid: '',
  rebuildCid: '',
  adminStartDate: '',
  autoRenew: '',
  requestedStart: null,
  delayedStart: null,
  paymentDate: '',
  requestedPayDate: '',
  delayedRequestedPayDate: null,
  paymentMethod: [],
  servicesProcessPayment: '',
  emailPreference: '',
  emailFrequency: '',
  trackingMethod: '',
  averageTransactionValue: '',
  costPerLead: '',
  runningMcid: '',
};

export const paymentMethodOptions = [
  'Credit Card',
  'Direct Debit',
  'Check',
  'Monthly Invoicing Required',
  'Auto-Renew Monthly',
  'Combo/Split Payments',
  'Promotion Credit(s)',
  'Issues Credit(s)',
  'Unspent Funds - Campaign Stop Request',
];

export const emailFrequencyOptions = [
  'Daily',
  'Weekly',
  'Monthly',
];

export const adminStartDateOptions = ['ASAP', 'Delay', 'Pending Site Launch'];
export const autoRenewOptions = ['Auto Renew On - Every Month', 'Auto Renew Off', 'Auto Renew On - Immediately'];
export const servicesProcess = ['Yes', 'No - Leave campaign in shopping cart'];

export const chatSilverBudgetOptions = [
  {
    label: 'Standard - $99 USD / $129 CAD',
    value: 99,
  },
  {
    label: 'HIPAA - $149 USD / $199 CAD',
    value: 149,
  },
  {
    label: 'Neighborly - $75 USD / $95',
    value: 75,
  },
];

export const premiumListingBudgetOptions = [
  {
    label: 'Monthly Model: $59 ($79 CAD)/Location',
    value: 59,
  },
  {
    label: 'Biannually Model: $299 ($389 CAD)/Location',
    value: 299,
  },
  {
    label: 'Annually Model: $499 ($649 CAD)/Location',
    value: 499,
  },
];

export const plHealthCareBudgetOptions = [
  {
    label: 'Monthly Model: $89 / Location',
    value: 89,
  },
  {
    label: 'Annually Model: $799 / Location',
    value: 799,
  },
];

export const addonsOfferingTypes = [
  'ExpandableMenu',
  'VideoBackground',
  'EmailHosting',
  'HipaaForm',
  'OnlineBooking',
  'PaymentForms',
  'Popup',
];

export const cyclesOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 99];
