import { t } from 'i18next';
import * as yup from 'yup';

const yupSchema = {
  newWebsiteReason: yup.string().required(),
  otherReason: yup.string().required(t('pages.website.custom.details.otherReasonRequired')),
  openToLocalIqProduct: yup.string().required(),
  budget: yup.string().required(t('pages.website.custom.details.budgetRangeRequired')).nullable(),
};

export default {
  yupValidations: yupSchema,
  customValidations: {},
};
