import { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { useParams } from 'react-router';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { useAppDispatch } from 'services/redux/store';
import { getPaymentForms } from 'services/addons/addonsSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useSelector } from 'react-redux';
import { selectOrderItems } from 'services/ordersSlice';
import { renderStatusPill } from 'pages/orders/OrdersTable';
import { headingStyle } from '../Business/BusinessSection';
import { ActionItems } from '../common/ActionItems';
import { findAddonId } from './MasterAddonSection';

const mapPaymentGateway = {
  authorizeNet: 'Authorize.net',
  stripe: 'Stripe',
  paypalCheckout: 'PayPal Checkout',
  square: 'Square Gateway Payments with Gravity Forms',
};

export default function PaymentFormsSection({
  product, isEditable, showActionItems, openBusinessAndSalesAgent,
}: any) {
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const { orderId } = useParams();
  const orderItems = useSelector(selectOrderItems);
  const [paymentFormsData, setPaymentFormsData] = useState({} as any);
  const [addonStatus, setAddonStatus] = useState('');
  const addonName = 'paymentForms';

  useEffect(() => {
    if (orderId) {
      appDispatch(getPaymentForms({ orderId })).then((response) => setPaymentFormsData(response.payload));
    }
  }, [orderId]);

  useEffect(() => {
    if (orderItems?.content?.length && addonName) {
      const currentAddon = orderItems.content
        .filter((item) => item.offeringType?.toLowerCase() === addonName?.toLowerCase());
      if (currentAddon.length > 0) setAddonStatus(currentAddon[0].status);
    }
  }, [orderItems]);

  return (
    <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
      <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>
        Payment Forms
        {!isEditable && renderStatusPill(addonStatus)}
      </Typography>

      <Box>

        {/* Payment Forms Details */}
        <Box sx={{ display: 'flex', margin: '10px 0' }}>
          {editButton(isEditable, () =>
            navigate.to(Urls.PaymentForms, product.id))}
          <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Payment Forms Details</Typography>
        </Box>
        <GridItem sizes={[6, 6]}>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Payment Gateway Used</Typography>
              <Typography>{mapPaymentGateway[paymentFormsData?.paymentsGateway]}</Typography>
            </Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Payment Form Requirements</Typography>
              <Typography>{paymentFormsData?.paymentFormRequirements}</Typography>
            </Box>
          </Box>
          <Box>
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Additional Notes/Instructions</Typography>
              {displayNotRequiredField('additional notes/instructions', paymentFormsData?.additionalNotes)}
            </Box>
          </Box>
        </GridItem>

        {/* Actions items */}
        {showActionItems && ActionItems(false, findAddonId(orderItems, addonName), false, openBusinessAndSalesAgent)}
      </Box>
    </GridItem>
  );
}
