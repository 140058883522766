import {Urls} from 'navigation/Urls';
import useProduct from 'hooks/useProduct';
import {StatusType} from 'models/StatusType';

import ContentLoader from 'components/contentLoader/ContentLoader';
import {GetYouTubeResponse, YouTubeRequestContentType} from "models/YouTubeType";
import AdvertisingPageForm from "pages/youtube/advertising/AdvertisingPageForm";

export default function Advertising() {
  const {product, onSubmit, onBack} = useProduct<YouTubeRequestContentType,
      StatusType<GetYouTubeResponse>>(Urls.YouTubeAdvertising);

  return (
      <ContentLoader status={product.status} message={product.message}>
        <AdvertisingPageForm
            product={product.content.data}
            onSubmit={onSubmit}
            onBack={onBack}
        />
      </ContentLoader>
  );
}
