import { t } from 'i18next';
import * as yup from 'yup';

const yupSchema = {
  customFormUrls: yup.string().required(t('pages.website.custom.form.exampleUrlsRequired')),
  otherRequirements: yup.string().required(t('pages.website.custom.form.otherRequirementsRequired')),
};

export default {
  yupValidations: yupSchema,
  customValidations: {},
};
