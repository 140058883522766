import React, {useEffect, useState} from 'react';
import {IQThemeV2} from '@gannettdigital/shared-react-components';
import {
  FormHelperText, Grid, InputAdornment, TextField, Typography,
} from '@mui/material';
import ErrorText from 'components/errorText/ErrorText';
import PhoneIcon from '@mui/icons-material/Phone';
import i18n from 'i18n/i18n';

const {t} = i18n;

export default function PhoneNumberTextField(props: any) {
  const [isTouched, setIsTouched] = useState(!!props.value);
  const [validationError, setValidationError] = useState('');
  const [formattedValue, setFormattedValue] = useState('');
  const countryCode = props.countryCode || 'US';

  const formatUSPhoneNumber = (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.replace(/(\d{1})(\d{3})?(\d{3})?(\d{4})?/, '+1 $2 $3 $4').trim();
  };

  const formatAUSPhoneNumber = (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.replace(/(\d{2})(\d{1})?(\d{4})?(\d{4})?/, '+61 $2 $3 $4').trim();
  };

  const formatPhoneNumber = (value: string) => {
    if (countryCode === 'US') {
      return formatUSPhoneNumber(value);
    } else if (countryCode === 'AUS') {
      return formatAUSPhoneNumber(value);
    }
  };

  useEffect(() => {
    if (props.value) {
      setFormattedValue(formatPhoneNumber(props.value));
    }
  }, [props.value, countryCode]);

  useEffect(() => {
    if (props.value && !isTouched) {
      setFormattedValue(formatPhoneNumber(props.value));
    }
  }, [props.value, isTouched, countryCode]);

  const stripNonNumeric = (value: string) => value.replace(/\D/g, '');

  const handleChange = (newValue: string) => {
    setIsTouched(true);
    const strippedValue = stripNonNumeric(newValue);
    const formattedNumber = formatPhoneNumber(strippedValue);
    setFormattedValue(formattedNumber);

    const isValidFormatUS = /^\+1 \d{3} \d{3} \d{4}$/.test(formattedNumber);
    const isValidFormatAUS = /^\+61 \d{1} \d{4} \d{4}$/.test(formattedNumber);

    const isValidFormat = countryCode === 'US' ? isValidFormatUS : isValidFormatAUS;

    if (isValidFormat) {
      props.onChange(newValue);
      setValidationError('');
    } else {
      props.onChange('');
      setValidationError(t('pages.shoppingAds.solutionStrategy.clientsContactNumberErrorLabel'));
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    const isValidKey = /^\d$/.test(e.key);
    if (!isValidKey) {
      e.preventDefault();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if ((e.key === 'Backspace' && (formattedValue === '+1' || formattedValue === '+61'))) {
      e.preventDefault();
    }
  };

  const hasRawErrors = Array.isArray(props.rawErrors) && props.rawErrors.length > 0;

  return (
      <Grid item xs={9} mt={3}>
        <Typography
            fontWeight={600}
            sx={{
              color: validationError || (hasRawErrors && isTouched)
                  ? IQThemeV2.palette.error.main
                  : IQThemeV2.palette.text.primary,
            }}
        >
          {props.label}
        </Typography>
        <TextField
            sx={{mt: 1}}
            id={props.id}
            disabled={props.disabled}
            required={props.required}
            name={props.name}
            type="tel"
            placeholder={countryCode === 'US' ? '+1 xxx xxx xxxx' : '+61 x xxxx xxxx'}
            error={!!validationError || (hasRawErrors && isTouched)}
            value={formattedValue}
            onChange={(e) => handleChange(e.target.value)}
            onKeyPress={handleKeyPress}
            onKeyDown={handleKeyDown}
            fullWidth
            InputProps={{
              startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon/>
                  </InputAdornment>
              ),
            }}
        />
        {props.placeholder && (!hasRawErrors || !isTouched) && (
            <FormHelperText error={false}>{props.placeholder}</FormHelperText>
        )}
        <ErrorText
            hasError={!!validationError || (hasRawErrors && isTouched)}
            errorText={validationError || props?.rawErrors?.[0]}
        />
      </Grid>
  );
}