import { IQButtonRadioContainer } from '@gannettdigital/shared-react-components';
import { Grid } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';

const yesNo = [
  {
    value: 'yes',
    label: 'Yes',
    field: 'yes',
  },
  {
    value: 'no',
    label: 'No',
    field: 'no',
  },
];

interface Props {
  label?: string;
  name: string;
  fontTitleWeight?: string;
  children?: ReactNode;
  shouldClearValues?: boolean;
}

const StyledGrid = styled(Grid)`
  display: flex;
  flex-direction: column;

  .MuiToggleButtonGroup-root {
    gap: 15px;
  }
  
  [role=group] > button {
    max-width: max-content;
  };

  .MuiButtonBase-root {
    background: transparent;
  };

  .MuiButtonBase-root:hover {
    background: transparent;
  };

  .MuiButtonBase-root:focus {
    background: transparent;
  };

  .MuiButtonBase-root.Mui-selected {
    background: transparent;
  };

  .MuiButtonBase-root.Mui-selected:hover {
    background: transparent;
  };

  .MuiButtonBase-root.Mui-selected:focus {
    background: transparent;
  };

  .MuiTouchRipple-root > * {
    display: none;
  };

  .MuiToggleButton-root {
    padding: 4px;
  }
`;

export default function YesNoRadio({ shouldClearValues = true, ...props }: Props) {
  const {
    watch, control, setValue,
  } = useFormContext();

  const radioValue = watch(props.name);

  const clearValues = () => {
    if (props.children) {
      if (Array.isArray(props.children)) {
        // @ts-ignore
        const names = props.children?.map(c => c.props.name || c.props.children?.props?.name);
        names.forEach(name => setValue(name, null));
      } else {
        // @ts-ignore
        const name = props.children.props?.name || props.children.props?.children?.props?.name;
        setValue(name, null);
      }
    }
  };

  useEffect(() => {
    if (radioValue === yesNo[1].value && shouldClearValues) clearValues();
  }, [radioValue]);

  return (
    <Grid container style={{ padding: 0, margin: 0 }}>
      <StyledGrid item xs={12} style={{ padding: 0, margin: 0, marginBottom: props.children ? '20px' : '0' }}>
        <Controller
          name={props.name}
          control={control}
          render={() => (
            <IQButtonRadioContainer
              name={props.name}
              optionsList={yesNo}
              label={props.label}
              fontTitleWeight={props.fontTitleWeight || '600'}
              inline
            />
          )}
        />
      </StyledGrid>
      <Grid item xs={12} style={{ padding: 0, margin: 0 }}>
        {radioValue === yesNo[0].value
          ? props.children : null}
      </Grid>
    </Grid>
  );
}
