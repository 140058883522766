import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Typography, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { IQLoadingSpinner, IQThemeV2, IQUnexpectedError } from '@gannettdigital/shared-react-components';

import {
  clearSendErrorComplete,
  selectSendErrorComplete,
  selectSendErrorStatus,
  sendErrorFeedback,
} from 'services/errorSlice';

import { Urls } from 'navigation/Urls';
import { useNavigationHandler } from 'hooks/useNavigationHandler';

import CoAppBar from 'components/appBar/CoAppBar';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import IQLeftNavIconDark from 'icons/IQLeftNavIconDark.svg';
import { backgroundDefaultColor } from 'styles/common_styles';

const GirdWrapper = styled(Grid)`
  background-color: ${backgroundDefaultColor};
  min-height: 100vh;
  align-content: flex-start;
`;

const GirdContainer = styled(Grid)`
  align-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2)} 0;
`;

const IQLogo = styled.img`
  height: 24px;
`;

const StyledUnexpectedErrorGrid = styled(Grid)`
  .MuiButtonBase-root:last-of-type {
    font-weight: 600;
  }
`;

const StyledCoModalConfirm = styled(CoModalConfirm)<{ theme: typeof IQThemeV2 }>`
  .MuiTypography-h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
  }

  & > .MuiGrid-root > .MuiGrid-grid-xs-12:nth-of-type(2) {
    margin-top: 18px;
  }

  .MuiTypography-subtitle1 strong {
    font-weight: 700;
  }

  .MuiTypography-body1 {
    margin-bottom: 0;
  }
   
  & > .MuiGrid-container > .MuiGrid-root:last-of-type > button {
    padding: 11px 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border: 1px solid ${props => props.theme.palette.primary.main};
  }
`;

const UnexpectedError: FC = () => {
  const theme = useTheme();
  const navigate = useNavigationHandler();

  const [flagOpenModalConfirm, setFlagOpenModalConfirm] = useState<boolean>(false);

  const dispatch = useDispatch();
  const loadingStatus = useSelector(selectSendErrorStatus);
  const sendErrorComplete = useSelector(selectSendErrorComplete);

  useEffect(() => {
    if (sendErrorComplete) setFlagOpenModalConfirm(true);
  }, [sendErrorComplete]);

  const goToHome = () => {
    navigate.to(Urls.Home);
    dispatch(clearSendErrorComplete());
  };

  const handleSubmit = (feedbackMessage) => {
    dispatch(sendErrorFeedback(feedbackMessage));
  };

  const handleClose = () => {
    setFlagOpenModalConfirm(false);
    goToHome();
  };

  if (loadingStatus === 'loading') {
    return <IQLoadingSpinner />;
  }

  return (
    <GirdWrapper container>
      <CoAppBar position="top">
        <IQLogo src={IQLeftNavIconDark} alt="IQLeftNavIconDark" />
      </CoAppBar>
      <GirdContainer container>
        <Grid item xs sm md lg xl />
        <StyledUnexpectedErrorGrid
          item xs={10} sm={8}
          md={6} lg={5} xl={4}
        >
          <IQUnexpectedError
            onRedirect={goToHome}
            onSubmit={handleSubmit}
          />
        </StyledUnexpectedErrorGrid>
        <Grid item xs sm md lg xl />
      </GirdContainer>
      <CoAppBar position="bottom">
        <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
          © 2022 Gannett Co., Inc. All Rights Reserved.
        </Typography>
      </CoAppBar>

      <StyledCoModalConfirm
        open={flagOpenModalConfirm}
        title="Thank you for submitting your feedback."
        description="Our support team has been notified and will review the feedback. Thank you for your patience."
        confirmText="Back to Home"
        showCancel={false}
        handleClose={handleClose}
        handleConfirm={goToHome}
        theme={IQThemeV2}
      />
    </GirdWrapper>
  );
};

export default UnexpectedError;
