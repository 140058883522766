import { useContext, useMemo } from 'react';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt';
import { SaveAndCloseContext } from 'context/SaveAndCloseContext';
import { useLocation } from 'react-router';
import { Typography } from '@mui/material';
import CoModalConfirm from '../modals/CoModalConfirm';

interface Props {
  showDialog: any
  leavePageCopy?: boolean
}

export default function CoNavigationConfirm({ showDialog, leavePageCopy = false }: Props) {
  const saveAndCloseContext = useContext(SaveAndCloseContext);
  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history])
  && saveAndCloseContext.showModal;

  const fromOverview = (isRedirectedFromOverviewPage && !leavePageCopy);

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    !saveAndCloseContext.isSavingAndClosing && showDialog,
  );

  useMemo(() => {
    saveAndCloseContext.setIsFormDirty(showDialog);
  }, [showDialog]);

  const confirmModalDescription = fromOverview ? (
    <div>
      <Typography marginBottom={2}>Are you sure you want to save these changes?</Typography>
      <Typography>
        <b>Note: </b>
        Changes may impact the accuracy of the order so please review the order overview before submitting.
      </Typography>
    </div>
  ) : 'Are you sure you want to leave this page? The information you have entered may not be saved.';

  return (
    <CoModalConfirm title={fromOverview ? 'Save Changes' : 'Leave page?'}
      description={confirmModalDescription}
      confirmText={fromOverview ? 'Save' : 'Leave page'}
      cancelText={fromOverview ? 'Back' : 'Stay on page'}
    // @ts-ignore
      open={showPrompt} handleClose={cancelNavigation} handleConfirm={confirmNavigation}
    />
  );
}
