import { FormHelperText, Grid } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';

type ErrorTextProps = {
  hasError: boolean;
  errorMessage: string;
  alignItems?: string;
};

export const IQErrorText = ({ hasError, errorMessage, alignItems }: ErrorTextProps) => {
  if (hasError) {
    return (
      <FormHelperText
        component="div"
        sx={{
          mt: 0,
          alignItems,
          gap: '8px',
          minHeight: '18px',
        }}
        error
      >
        <Grid container>
          <Grid item width="25px" paddingTop="1px">
            <InfoIcon sx={{ fontSize: '18px' }} />
          </Grid>
          <Grid item xs>
            {errorMessage}
          </Grid>
        </Grid>
      </FormHelperText>
    );
  }
  return null;
};

export default IQErrorText;
