import { Typography, Box, Link } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { Urls } from 'navigation/Urls';
import { headingStyle } from '../Business/BusinessSection';

// eslint-disable-next-line max-len
export const renderStorePoliciesSection = (data, stockAssets, returnsRefunds, product, navigate, offeringType, isEditable) => (
  <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
    <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Store Policies</Typography>

    <Box>
      {/* Points of Contact */}
      <Box sx={{ display: 'flex', marginBottom: '10px' }}>
        {editButton(isEditable, () => navigate.to(
          Urls.Contact,
          product.id,
        ))}
        <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Contact Info</Typography>
      </Box>
      <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Business Address</Typography>
            {data?.pointsOfContact?.addressLine1
              ? (
                <>
                  <Typography>{data.pointsOfContact.addressLine1}</Typography>
                  {data.pointsOfContact.addressLine2 && <Typography>{data.pointsOfContact.addressLine2}</Typography>}
                  <Typography>
                    {`${data.pointsOfContact.city}, ${data.pointsOfContact.state} ${data.pointsOfContact.zipcode}`}
                  </Typography>
                  <Typography>{data.pointsOfContact.country}</Typography>
                </>
              ) : <Typography fontWeight="bold">No address</Typography>}
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Additional Points of Contact</Typography>
            {data?.pointsOfContact?.emails?.map(email => <Typography mb={1}>{email.email}</Typography>)}
            {data?.pointsOfContact?.phones?.map(phone => (
              <Typography mb={1}>{`${phone.phone} ext. ${phone.extension}`}</Typography>
            ))}
          </Box>
        </Box>
      </GridItem>

      {/* Returns and Refund Policy */}
      <Box sx={{ display: 'flex', margin: '10px 0' }}>
        {editButton(isEditable, () => navigate.to(
          Urls.Returns,
          product.id,
        ))}
        <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Returns</Typography>
      </Box>
      <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Returns and Refund Policy</Typography>
            {displayNotRequiredField('returns and refund policy', data?.returnRefundPolicy)}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Excluded or Ineligible Products</Typography>
            {data?.excludeIneligibleProducts?.length
              ? (
                data?.excludeIneligibleProducts.map(product => (
                  <Typography key={product}>{product}</Typography>
                )))
              : <Typography fontWeight="bold">None</Typography>}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Business Pays Return Shipping</Typography>
            {data?.businessPaysReturnShipping
              ? <Typography sx={{ textTransform: 'capitalize' }}>{data?.businessPaysReturnShipping}</Typography>
              : <Typography fontWeight="bold">No selection</Typography>}
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Additional Return & Refund Policy Files</Typography>
            {returnsRefunds?.length
              ? returnsRefunds.map((asset, index) => (
                <Link href={asset.url} key={asset.url} target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography>{`Return Policy File ${index + 1}`}</Typography>
                </Link>
              ))
              : <Typography fontWeight="bold">No Files</Typography>}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Additional Return & Refund Policy Notes</Typography>
            {displayNotRequiredField('additional notes', data?.additionalNotesReturns)}
          </Box>
        </Box>
      </GridItem>

      {/* Shipping */}
      <Box sx={{ display: 'flex', margin: '10px 0' }}>
        {editButton(isEditable, () => navigate.to(
          Urls.Shipping,
          product.id,
        ))}
        <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Shipping</Typography>
      </Box>
      <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Shipping Options</Typography>
            {data?.localPickup && (<Typography>Local Pickup</Typography>)}
            {data?.domestic && (<Typography>Domestic</Typography>)}
            {data?.international && (<Typography>International</Typography>)}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Shipping Rates</Typography>
            {displayNotRequiredField('shipping rates', data?.shippingRates)}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Services Offered</Typography>
            {displayNotRequiredField('services offered', data?.servicesOffered)}
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">
              Integrate third-party shipping to calculate rates?
            </Typography>
            {data?.thirdPartyShipper
              ? <Typography sx={{ textTransform: 'capitalize' }}>{data?.thirdPartyShipper}</Typography>
              : <Typography fontWeight="bold">No selection</Typography>}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Third-party Shipper</Typography>
            {displayNotRequiredField('third-party shipper', data?.thirdPartyShipping)}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Additional Shipping Notes</Typography>
            {displayNotRequiredField('additional notes', data?.shippingAdditionalNotes)}
          </Box>
        </Box>
      </GridItem>

      {/* Age Gate */}
      <Box sx={{ display: 'flex', margin: '10px 0' }}>
        {editButton(isEditable, () => navigate.to(
          Urls.AgeGate,
          product.id,
        ))}
        <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Age Gate</Typography>
      </Box>
      <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Age Gate</Typography>
            <Typography>{data?.ageGateAcknowledgment ? 'Yes' : 'No'}</Typography>
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Age Gate Paid advertising campaigns</Typography>
            {displayNotRequiredField('age gate paid advertising campaigns', data?.paidAdvertisingCampaigns)}
          </Box>
        </Box>
      </GridItem>

      {/* Additional */}
      <Box sx={{ display: 'flex', margin: '10px 0' }}>
        {editButton(isEditable, () => navigate.to(
          Urls.AdditionalStoreDetails,
          product.id,
        ))}
        <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Additional</Typography>
      </Box>
      <GridItem sizes={[6, 6]}>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Unique Tax Situation</Typography>
            {data?.uniqueTaxSituationsYesNo
              ? <Typography style={{ textTransform: 'capitalize' }}>{data?.uniqueTaxSituationsYesNo}</Typography>
              : <Typography fontWeight="bold">No unique tax situations</Typography>}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Unique Tax Situations</Typography>
            {displayNotRequiredField('unique tax situations', data?.uniqueTaxSituations)}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Tax Information</Typography>
            {displayNotRequiredField('tax information', data?.taxInformation)}
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Online Gift Cards For Sale</Typography>
            {data?.onlineGiftCardsForSale
              ? <Typography style={{ textTransform: 'capitalize' }}>{data?.onlineGiftCardsForSale}</Typography>
              : <Typography fontWeight="bold">No online gift cards for sale</Typography>}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Gift Card Notes</Typography>
            {displayNotRequiredField('notes', data?.giftCardsNotes)}
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Additional Store Detail Notes</Typography>
            {displayNotRequiredField('additional notes', data?.additionalNotesStoreDetails)}
          </Box>
        </Box>
      </GridItem>
    </Box>
  </GridItem>
);
