import {
  IQFileUploader, IQFormTextArea, IQLabelTooltip, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Grid, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styled from '@emotion/styled';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { H5 } from 'components/labels/H5';
import HipaaFormEmailsType from 'models/HipaaFormEmailsType';
import HipaaFormCard from 'components/hipaaFormCard/HipaaFormCard';
import HipaaFormCardEdit from 'components/hipaaFormCard/HipaaFormCardEdit';
import { deleteFile, selectFilesUploaded, uploadFiles } from 'services/fileUploaderSlice';
import {
  deleteHipaaFormDefinition,
  postHipaaFormDefinition,
  putHipaaFormDefinition,
  selectHipaaFormAdditionalNotes,
  selectHipaaFormDefinitions,
} from 'services/addons/addonsSlice';
import CoFileList from 'components/fileList/CoFileList';
import ContentLoader from 'components/contentLoader/ContentLoader';
import CoModalPageCard from 'components/pageCard/CoModalPageCard';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import HipaaFormDefinitionType from 'models/HipaaFormDefinitionType';
import AdminEmailForm from './AdminEmailForm';
import schema from './HipaaForms.schema';

interface Props {
  hipaaFormsEmails: HipaaFormEmailsType
  onSubmit: (data) => any
  onBack: () => any
}

const AddFormButton = styled(Button)`
  &.Mui-disabled {
    color: #FFFFFF
  }
  font-weight: 600;
  text-transform: none;
  font-size: 14px;
  line-height: 18px;
  padding: 0 40px 0 0;
  margin-left: 24px;
  min-width: 0;
`;

const styles = makeStyles(
  {
    button: {
      textTransform: 'none',
    },
    addPageWhenEmpty: {
      position: 'relative',
      backgroundColor: '#EBF4FF',
      height: '56px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '3px',
      borderColor: '#D7D7D7',
      margin: '2px 0 35px 0',
    },
    centeredButton: {
      margin: 0,
      marginLeft: '24px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
);

const emptyForm = {
  title: '',
  description: '',
  id: null,
};

const emptyEmailObj = {
  id: '',
  email: '',
};

export default function HipaaFormsForm(props: Props) {
  const { hipaaFormsEmails } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const style = styles();
  const { orderId, offeringType, websiteId } = useParams();
  const { content: hipaaContent } = useSelector(selectHipaaFormAdditionalNotes);

  const uploaderType = 'hipaa-form';

  const initialState = hipaaFormsEmails.length === 0 ? [emptyEmailObj] : hipaaFormsEmails;
  const [emailData, setEmailData] = useState<HipaaFormEmailsType>([]);
  const [flagOpenModal, setFlagOpenModal] = useState(false);
  const [flagOpenModalConfirm, setFlagOpenModalConfirm] = useState(false);
  const [formEdit, setFormEdit] = useState(emptyForm);
  const [formRemove, setFormRemove] = useState(emptyForm);

  const dispatch = useDispatch();
  const filesUploaded = useSelector(selectFilesUploaded);
  const hipaaFormDefinitions = useSelector(selectHipaaFormDefinitions);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      additionalNotes: hipaaContent?.additionalNotes,
    },
  });

  useEffect(() => {
    setEmailData(initialState);
  }, [hipaaFormsEmails]);

  const {
    register, getValues, unregister, trigger, handleSubmit, setValue, formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful,
    },
  } = methods;

  const openModal = (content) => {
    setFormEdit(content);
    setFlagOpenModal(true);
  };

  const openModalConfirm = (content) => {
    setFormRemove(content);
    setFlagOpenModalConfirm(true);
  };

  const closeModal = () => setFlagOpenModal(false);

  const saveCard = (content: HipaaFormDefinitionType) => {
    if (content.id) {
      dispatch(putHipaaFormDefinition({ orderId, content }));
    } else {
      dispatch(postHipaaFormDefinition({ orderId, content }));
    }
    closeModal();
  };

  const removeCard = () => {
    const definitionId = formRemove.id;
    dispatch(deleteHipaaFormDefinition({ definitionId }));
  };

  const onUpdate = (newFiles) => {
    dispatch(uploadFiles({
      websiteId, files: newFiles, uploaderType, offeringType,
    }));
  };

  const onDelete = (file) => {
    dispatch(deleteFile({ websiteId, file }));
  };

  const onSubmit = () => {
    const content = { emailAccounts: emailData, additionalNotes: getValues('additionalNotes') };
    props.onSubmit(content);
  };

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <DefaultPageLayout
          disableContinue={!isValid}
          onBack={props.onBack}
          header={t('pages.addons.hipaaForms.title')}
          onContinue={handleSubmit(onSubmit)}
        >
          <Grid container item xs={7}>
            <Grid item xs={12} sx={{ marginBottom: '35px' }}>
              <H5>
                {t('pages.addons.hipaaForms.emailsTitle')}
              </H5>
              <AdminEmailForm
                setValue={setValue}
                register={register}
                unregister={unregister}
                emailData={emailData}
                setEmailData={setEmailData}
                trigger={trigger}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: '15px' }}>
              <H5>
                {t('pages.addons.hipaaForms.formsTitle')}
              </H5>
            </Grid>
            <ContentLoader status={hipaaFormDefinitions.status} message={hipaaFormDefinitions.message}>
              {hipaaFormDefinitions.content.map((content, index) => (
                <Grid item xs={12} key={content.id}>
                  <HipaaFormCard
                    title={content.title}
                    description={content.description}
                    onEdit={() => openModal(content)}
                    onDelete={() => openModalConfirm(content)}
                    isFirst={index === 0}
                  />
                </Grid>
              ))}
            </ContentLoader>
            <Grid item xs={12} sx={{ margin: '35px 0 35px 0' }}
              className={!hipaaFormDefinitions.content?.length && style.addPageWhenEmpty}
            >
              <AddFormButton
                onClick={() => openModal(emptyForm)}
                className={!hipaaFormDefinitions.content?.length && style.centeredButton}
              >
                +&nbsp;&nbsp;
                <u>{t('pages.addons.hipaaForms.addFormButton')}</u>
              </AddFormButton>
            </Grid>
            <Grid item xs={12} sx={{ mb: '-12px' }}>
              <IQLabelTooltip
                labelText={t('pages.addons.hipaaForms.uploaderTitle')}
                theme={theme}
                hasError={false}
                required={false}
                tooltipText=""
                paddingBottom={10}
              />
              <IQFileUploader
                showRecommendedImageText={false}
                showAcceptedFileTypesText
                required={false}
                name="uploads" maxFileSize={20000000}
                onSuccess={onUpdate}
                label={null}
                supportingText=""
                imageTypes={['.jpg', '.jpeg', '.png']}
                videoTypes={[]}
                documentTypes={['.pdf']}
                size="lg"
                currentUploadedFiles={filesUploaded.content.length}
              />
            </Grid>
            <Grid item xs={12}>
              <ContentLoader status={filesUploaded.status} message={filesUploaded.message}>
                {filesUploaded.content.length
                  ? <CoFileList files={filesUploaded.content} onDelete={onDelete} />
                  : null }
              </ContentLoader>
            </Grid>
            <Grid item xs={12} mt={5}>
              <IQFormTextArea
                id="additionalNotes"
                labelText="Additional Notes/Instructions"
                name="additionalNotes"
                rowCount={2}
                fullWidth
                fontLabelWeight="600"
                showError
              />
            </Grid>
          </Grid>
          <CoModalPageCard title="HIPAA Form" isOpen={flagOpenModal} onClose={closeModal}>
            <HipaaFormCardEdit hipaaFormCard={formEdit} onSave={saveCard} />
          </CoModalPageCard>
          <CoModalConfirm open={flagOpenModalConfirm}
            title="Remove Form?"
            description="Are you sure you want to remove this Form?"
            confirmText="Remove"
            handleClose={() => setFlagOpenModalConfirm(false)}
            handleConfirm={removeCard}
          />
        </DefaultPageLayout>
        {/* show the dialog if we changed the field and didn't submit the changes */}
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
