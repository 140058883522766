import * as yup from 'yup';

const schema = {
  websiteUrl: yup.string().url().required(),
  siteType: yup.string().required(),
  mainContact: yup.string().required(),
  accessContact: yup.string().optional(),
  isSiteLive: yup.string().required(),
  isContactInfoAvailable: yup.string().required(),
  byosAcknowledgement: yup.boolean().required('Select Acknowledge')
    .oneOf([true], 'Please check acknowledgement to continue.'),
  developerName: yup.string().required(),
  developerEmail: yup.string().email('Please enter a valid email in this format: name@example.com').required(),
  developerPhone: yup.string().optional(),
  developerPhoneExtension: yup.string().optional(),
  mainContactName: yup.string().required(),
  mainContactEmail: yup.string().email('Please enter a valid email in this format: name@example.com').required(),
  mainContactPhone: yup.string().required(),
  mainContactPhoneExtension: yup.string().optional(),
  accessContactName: yup.string().required(),
  accessContactEmail: yup.string().email('Please enter a valid email in this format: name@example.com').required(),
  accessContactPhone: yup.string().required(),
  accessContactPhoneExtension: yup.string().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
