import * as yup from 'yup';
import i18n from 'i18n/i18n';

const { t } = i18n;
// eslint-disable-next-line max-len
const urlValidationRegex = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)\.([a-z]{2,6})(?:\/[^#?\n]*)?(?:\?[^#\n]*)?(?:#.*)?$/i;

const schema = {
  adGroupName: yup.string().notRequired(),
  pleaseProvideTheLinkToTheYouTubeVideo: yup.string()
    .url(t('pages.youtube.advertising.adGroupOptions.youTubeVideoLinkErrorMsg'))
    .notRequired(),
  additionalYouTubeVideoLink: yup.string().notRequired(),
  advertiserWebsiteUrl: yup.string()
    .matches(urlValidationRegex, t('pages.youtube.advertising.adGroupOptions.advertiserWebsiteUrlErrorMsg'))
    .required(t('pages.youtube.advertising.adGroupOptions.advertiserWebsiteUrlErrorMsg'))
    .nullable(),
  specificSubpageUrlBeingPromoted: yup.string()
    .url(t('pages.youtube.advertising.adGroupOptions.specificSubpageUrlBeingPromotedErrorMsg'))
    .notRequired(),
  howShouldTheCampaignBeTargeted: yup.string().required(),
  audiences: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
