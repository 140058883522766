import * as yup from 'yup';

const schema = {
  localPickup: yup.boolean().optional(),
  domestic: yup.boolean().optional(),
  international: yup.boolean().optional(),
  servicesAndRatesInfo: yup.string().optional(),
  servicesOffered: yup.string().optional(),
  thirdPartyShipper: yup.string().optional().nullable(),
  thirdPartyShipping: yup.string().optional(),
  shippingAdditionalNotes: yup.string().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
