import {
  IQFormInput, IQLabelTooltip, IQTheme, IQYesNoFormRadioButtons,
} from '@gannettdigital/shared-react-components';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { TitleWithBadge } from '../../premium-listings/TitleWithBadge';

interface ExistingBusinessListingsProps {
  provider?: boolean
}

const listingsEntry = (site: string, errorReference: any, provider?: boolean) => {
  const { t } = useTranslation();
  const {
    getValues, watch,
  } = useFormContext();

  const yesNoName = `${site}YesNo`;
  const urlName = `${site}ListingsUrl`;
  const showUrl = useMemo(
    () => getValues(yesNoName) === 'yes',
    [watch(yesNoName)],
  );

  const urlRegex = (account) => {
    const social = account.toLowerCase();
    switch (social) {
      case 'facebook':
        return /^(?:(?:http|https):\/\/)?(www\.)?(facebook.com|fb.me|fb.com)\/.+/;
      case 'google':
        return /^(?:(?:http|https):\/\/)?(www\.)?(google.com|goo.gl|g.page)\/.+/;
      default:
        return /^(?:(?:http|https):\/\/)?(www\.)?(([a-zA-Z]{2,63})).+/;
    }
  };

  const errorMesssage = t(
    `pages.premiumListings.location.contact.details.existingBusinessListings.${site}InvalidUrl`,
  );
  const validation = yup.string().matches(urlRegex(site), errorMesssage).url(errorMesssage).required(errorMesssage);
  const label = provider
    ? t(`pages.premiumListings.location.healthcare.contact.details.existingBusinessListings.${site}Title`)
    : t(`pages.premiumListings.location.contact.details.existingBusinessListings.${site}Title`);

  return (
    <GridItem>
      <IQLabelTooltip
        tooltipText={t('pages.premiumListings.location.contact.details.existingBusinessListings.tooltip')}
        labelText={label}
        tooltipPlacement="top"
        theme={IQTheme}
        hasError={false}
      />
      <IQYesNoFormRadioButtons
        field={yesNoName}
        title={t(`pages.premiumListings.location.contact.details.existingBusinessListings.${site}Label`)}
      />
      {showUrl && (
      <IQFormInput
        id={urlName}
        theme={IQTheme}
        labelText={t(`pages.premiumListings.location.contact.details.existingBusinessListings.${site}Url`)}
        placeholder={t(`pages.premiumListings.location.contact.details.existingBusinessListings.${site}Placeholder`)}
        fontLabelWeight="600"
        name={urlName}
        fullWidth
        adornmentIcon={<InsertLinkIcon />}
        customValidation={validation}
        directErrorReference={errorReference}
      />
      )}
    </GridItem>
  );
};

export default function ExistingBusinessListings({ provider }: ExistingBusinessListingsProps) {
  const { t } = useTranslation();

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <GridContainer fullWidth>
      <GridItem>
        <TitleWithBadge
          title={t('pages.premiumListings.location.contact.details.existingBusinessListings.title')}
          badge={t('pages.premiumListings.badge')}
          tooltip={t('pages.premiumListings.badgeTooltip')}
        />
      </GridItem>
      {listingsEntry('google', (errors as any).googleListingsUrl, provider)}
      {listingsEntry('facebook', (errors as any).facebookListingsUrl, provider)}
    </GridContainer>
  );
}
