import {
  Box, Grid, Typography, Link,
} from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch } from 'services/redux/store';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { getCustomLandingPage } from 'services/customLandingPageSlice';
import { Urls } from 'navigation/Urls';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import OrderTracker from 'pages/orders/OrderTracker';
import { headingStyle } from '../Business/BusinessSection';

export default function CustomLandingSection({ product, isEditable, showTracker }: any) {
  const { orderId } = useParams();
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const [landingPagesData, setLandingPagesData] = useState({} as any);
  const [attachments, setAttachments] = useState([]);

  const getLandingData = () => {
    appDispatch(getCustomLandingPage(product.id))
      .then((response) => setLandingPagesData(response.payload));
  };

  useEffect(() => {
    if (orderId && product) getLandingData();
  }, [orderId, product]);

  const designReference = () => {
    if (landingPagesData?.data) {
      const {
        designReferenceType, designReference, referenceUrl, otherReference,
      } = landingPagesData.data;

      if (designReferenceType === 'mockup') {
        const reference = JSON.parse(designReference).map((item) => JSON.parse(item.url));
        const elements = reference?.map(ref => (
          <Grid item xs={6} key={ref.filename}>
            <Link href={ref?.url} target="_blank" rel="noopener noreferrer">{ref.fileName}</Link>
          </Grid>
        ));
        return elements;
      }
      if (designReferenceType === 'landingPage') return referenceUrl;
      if (designReferenceType === 'other') return otherReference;
    }

    return '';
  };

  const mapDesignReference = {
    mockup: 'Mockup',
    landingPage: 'Example Landing Page',
    other: 'Other',
  };

  const assetsFormat = (ref, type) => {
    if (ref) {
      const refObj = JSON.parse(ref);
      const mapRef = refObj.map(r => JSON.parse(r.url));
      return mapRef.filter(r => r.uploaderType === type);
    }
    return '';
  };

  useEffect(() => {
    if (landingPagesData?.data?.attachments) {
      const mapAttachments = assetsFormat(landingPagesData.data.attachments, 'additionalInfoAssets');
      setAttachments(mapAttachments);
    }
  }, [landingPagesData]);

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Status Tracker */}
      {showTracker && (
      <GridItem sizes={[12, 12]} marginBottom={8} sx={{ padding: '40px 0 0', borderBottom: '1px solid #000000' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => {})}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Status</Typography>
        </Box>
        <Box>
          <OrderTracker
            key={product?.id}
            status={product?.trackerStatus}
            productName={product?.offeringType}
            product={product}
          />
        </Box>
      </GridItem>
      )}

      {/* Details */}
      <GridItem sizes={[12, 6, 6]} marginBottom={3} sx={{ padding: '20px 0 0' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () =>
            navigate.to(
              Urls.LandingCustomDetails,
              product.id,
            ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Details</Typography>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>Reason for Custom Landing Page</Typography>
            <Typography>{landingPagesData?.data?.newSiteReason}</Typography>
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>LocaliQ Campaign Landing Page</Typography>
            <Typography>{landingPagesData?.data?.openToSilverWebsite ? 'Yes' : 'No'}</Typography>
          </Box>
        </Box>
      </GridItem>

      {/* Design */}
      <GridItem sizes={[12, 6, 6]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () =>
            navigate.to(
              Urls.LandingCustomDesign,
              product.id,
            ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Design</Typography>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>Design reference to quote for build</Typography>
            <Typography>
              {mapDesignReference[landingPagesData?.data?.designReferenceType]}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>Reference</Typography>
            <Typography>{designReference()}</Typography>
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>Will stock assets be needed?</Typography>
            <Typography style={{ textTransform: 'capitalize' }}>
              {landingPagesData?.data?.imageryRequirement}
            </Typography>
          </Box>
        </Box>
      </GridItem>

      {/* Development */}
      <GridItem sizes={[12, 6, 6]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () =>
            navigate.to(
              Urls.LandingCustomDevelopment,
              product.id,
            ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Development</Typography>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>
              Will copywriting services be needed?
            </Typography>
            <Typography style={{ textTransform: 'capitalize' }}>{landingPagesData?.data?.copyRequirement}</Typography>
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>
              Special functionality needed
            </Typography>
            <Typography style={{ textTransform: 'capitalize' }}>
              {landingPagesData?.data?.specialFunctionality}
            </Typography>
          </Box>
        </Box>
      </GridItem>

      {/* Additional Info */}
      <GridItem sizes={[12, 6, 6]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () =>
            navigate.to(
              Urls.LandingCustomAdditionalInfo,
              product.id,
            ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Additional Info</Typography>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>Additional Info</Typography>
            {displayNotRequiredField('additional info', landingPagesData?.data?.additionalInfo)}
          </Box>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary" marginTop={2}>Attachments</Typography>
            {attachments?.length
              ? attachments.map((asset, index) => (
                <Link href={asset.url} key={asset.id} target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography>{`Asset ${index + 1}`}</Typography>
                </Link>
              )) : <Typography fontWeight="bold">No attachments</Typography>}
          </Box>
        </Box>
      </GridItem>
    </Grid>
  );
}
