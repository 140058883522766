import * as yup from 'yup';

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

const schema = {
  type: yup.string().required('Field is required').nullable(),
  trigger: yup.string().required('Field is required').nullable(),
  primaryTypeLead: yup.string().required('Field is required').nullable(),
  additionalInfo: yup.string().optional().nullable(),
  contentDesignInformation: yup.string().required('Field is required').trim().nullable(),
  button: yup.bool().nullable(),
  actionDetails: yup.string().required('Field is required').trim().nullable(),
  timer: yup.bool().nullable(),
  date: yup.date().required('Field is required')
    .min(
      yesterday,
      'This is an expired date. Please enter or select a valid date as of today or  the future.',
    )
    .typeError('Please enter a valid date in the format MM/DD/YYYY')
    .nullable(),
  form: yup.bool().nullable(),
  email: yup.bool().nullable(),
  firstName: yup.bool().nullable(),
  lastName: yup.bool().nullable(),
  phone: yup.bool().nullable(),
  other: yup.bool().nullable(),
  otherText: yup.string().required('Field is required').trim().nullable(),
  image: yup.bool().nullable(),
  popupImages: yup.array().optional().nullable(),
  popupGettyImages: yup.array().optional().nullable(),
  additionalNotesInstructions: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {},
};
