import i18n from 'i18n/i18n';
import { RJSFSchema } from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import PhoneNumberTextField from "pages/generic-product/details/custom-fields/PhoneNumberTextField";

const { t } = i18n;

export const CallConnectSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.ausLiveChat.callConnect.title'),
  type: 'object',
  required: [
    'phoneNumberOnWebsiteToBeMasked',
    'destinationOrTerminationNumber',
    'trackingNumberType',
    'tollFreeNumberFeesBeenCharged',
    'regionForTrackingNumber'
  ],
  properties: {
    phoneNumberOnWebsiteToBeMasked: {
      type: "string",
      title: t('pages.ausProduct.ausLiveChat.callConnect.phoneNumberOnWebsiteToBeMaskedLabel'),
      default: data?.phoneNumberOnWebsiteToBeMasked || '',
    },
    destinationOrTerminationNumber: {
      type: "string",
      title: t('pages.ausProduct.ausLiveChat.callConnect.destinationOrTerminationNumberLabel'),
      default: data?.destinationOrTerminationNumber || '',
      description: t('pages.ausProduct.ausLiveChat.callConnect.destinationOrTerminationNumberDescription')
    },
    trackingNumberType: {
      type: 'string',
      title: t('pages.ausProduct.ausLiveChat.callConnect.trackingNumberTypeLabel'),
      default: data?.trackingNumberType || '',
      enum: [
        t('pages.ausProduct.ausLiveChat.callConnect.localGeo'),
        t('pages.ausProduct.ausLiveChat.callConnect.mobile'),
        t('pages.ausProduct.ausLiveChat.callConnect.phone'),
        t('pages.ausProduct.ausLiveChat.callConnect.1300Au'),
        t('pages.ausProduct.ausLiveChat.callConnect.1800Au'),
        t('pages.ausProduct.ausLiveChat.callConnect.0800Nz'),
      ],
      description: t('pages.ausProduct.ausLiveChat.callConnect.trackingNumberTypeDescription'),
    },
    tollFreeNumberFeesBeenCharged: {
      type: 'string',
      title: t('pages.ausProduct.ausLiveChat.callConnect.tollFreeNumberFeesBeenChargedLabel'),
      default: data?.tollFreeNumberFeesBeenCharged || '',
      enum: [
        t('pages.ausProduct.ausLiveChat.yes'),
        t('pages.ausProduct.ausLiveChat.no'),
      ],
    },
    regionForTrackingNumber: {
      type: "string",
      title: t('pages.ausProduct.ausLiveChat.callConnect.regionForTrackingNumberLabel'),
      default: data?.regionForTrackingNumber || '',
      description: t('pages.ausProduct.ausLiveChat.callConnect.regionForTrackingNumberDescription')
    },
  },

});

const phoneNumberFieldKeys = [
  'phoneNumberOnWebsiteToBeMasked',
];
const phoneNumberFields = phoneNumberFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <PhoneNumberTextField
            {...props}
            countryCode="AUS"
        />
    ),
  },
}), {});

const dropdownFieldKeys = [
  'trackingNumberType',
  'tollFreeNumberFeesBeenCharged',
];
const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
      <Dropdown
        {...props}
        errorMessage={t('pages.ausProduct.ausLiveChat.requiredFieldErrorMessage')}
      />
    ),
  },
}), {});

const numericFieldKeys = [
  'destinationOrTerminationNumber',
]

const numericFields = numericFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'integerInputField'},
}), {});

const inputFieldKeys = [
  'regionForTrackingNumber'
];

const inputFields = inputFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});

export const CallConnectUISchema = {
  'ui:order': [
    'phoneNumberOnWebsiteToBeMasked',
    'destinationOrTerminationNumber',
    'trackingNumberType',
    'tollFreeNumberFeesBeenCharged',
    'regionForTrackingNumber'
  ],
  ...dropdownFields,
  ...phoneNumberFields,
  ...numericFields,
  ...inputFields
};


export const CallConnectCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.phoneNumberOnWebsiteToBeMasked === '') {
    errors.phoneNumberOnWebsiteToBeMasked.addError(t('pages.ausProduct.ausLiveChat.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.destinationOrTerminationNumber === '') {
    errors.destinationOrTerminationNumber.addError(t('pages.ausProduct.ausLiveChat.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.trackingNumberType === '') {
    errors.trackingNumberType.addError(t('pages.ausProduct.ausLiveChat.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.tollFreeNumberFeesBeenCharged === '') {
    errors.tollFreeNumberFeesBeenCharged.addError(t('pages.ausProduct.ausLiveChat.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.regionForTrackingNumber === '') {
    errors.regionForTrackingNumber.addError(t('pages.ausProduct.ausLiveChat.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
