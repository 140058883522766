import * as yup from 'yup';

const schema = {
  productTypesOffered: yup.string()
    .required('Please enter product types offered.')
    .typeError('Please enter product types offered.'),
  numberProductToBeSold: yup.number()
    .required('Please enter the number of product to be sold.')
    .typeError('Please enter the number of product to be sold.')
    .min(0, 'Please enter a positive numerical value.'),
  productsChangeBasedSeason: yup.string(),
  seasonalProducts: yup.string().nullable(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
