import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import ChatSilverType from 'models/ChatSilverType';
import {
  ValidationProvider,
  IQCheckbox,
  IQFormTextArea,
  IQLabelTooltip,
  IQTheme,
  IQFormInput,
  IQButtonRadioContainer,
  IQThemeV2,
} from '@gannettdigital/shared-react-components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import schema from 'pages/chat/script-details/ScriptDetails.schema';
import {
  Box,
  Divider,
  Grid,
  ListSubheader,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import YesNoRadio from 'components/yesNo/YesNoRadio';
import NeDropdown from 'components/dropdown/NeDropdown';
import GridItem from 'components/grid/GridItem';

import i18n from 'i18n/i18n';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import { followUpCheckboxes } from 'pages/chat-platinum/script-details/ScriptDetailsData';
import ErrorText from 'components/errorText/ErrorText';
import {
  checkboxes,
  options,
  optionsEstimatesFree,
  timezoneList,
} from './ScriptDetailsData';

const { t } = i18n;

interface Props {
  product: ChatSilverType;
  onSubmit: (data: ScriptDetailsFormProps) => void;
  onBack: () => void;
}

export type ScriptDetailsFormProps = {
  provideEstimatesConsultations: string,
  estimatesConsultationsInformation: string,
  chatSuggestions: string,
  minimumServiceFee: string,
  additionalDetails: {
    provideEstimateInPerson: boolean,
    provideEstimateByPhone: boolean,
    other: boolean,
    otherDescription: string,
    provideFreeConsultation: string,
    callConnectTimezone: string,
    callConnectAvailable: string,
    customCallConnectHours: string,
  },
  followUpPhone: boolean,
  followUpEmail: boolean,
  followUpSms: boolean,
};

export default function ScriptDetailsForm(props: Props) {
  const { product } = props;
  const addDetails = product.additionalDetails;
  const theme = useTheme();
  const [checkboxFollowUpContainerError, setCheckboxFollowUpContainerError] = useState(false);

  const additionalDetails = addDetails ?? {
    provideEstimateInPerson: addDetails?.provideEstimateInPerson || false,
    provideEstimateByPhone: addDetails?.provideEstimateByPhone || false,
    other: addDetails?.other || false,
    otherDescription: addDetails?.otherDescription || '',
    provideFreeConsultation: addDetails?.provideFreeConsultation || '',
    callConnectTimezone: addDetails?.callConnectTimezone || '',
    callConnectAvailable: addDetails?.callConnectAvailable || 'standardBusinessHours',
    customCallConnectHours: addDetails?.customCallConnectHours.replaceAll('\\n', '\n')
      .replaceAll('\\', '') || '',
  };

  const methods = useForm<ScriptDetailsFormProps>({
    mode: 'onChange',
    defaultValues: {
      provideEstimatesConsultations: product?.provideEstimatesConsultations || '',
      chatSuggestions: product?.chatSuggestions || '',
      estimatesConsultationsInformation: product?.estimatesConsultationsInformation || '',
      minimumServiceFee: product?.minimumServiceFee || '',
      additionalDetails: {
        ...additionalDetails,
        customCallConnectHours: additionalDetails?.customCallConnectHours.replaceAll('\\n', '\n')
          .replaceAll('\\', ''),
      },
      followUpPhone: product.followUpPhone !== undefined ? product.followUpPhone : true,
      followUpEmail: product.followUpEmail !== undefined ? product.followUpEmail : true,
      followUpSms: product.followUpSms !== undefined ? product.followUpSms : true,
    },
  });

  const handleFormSubmit = (data: ScriptDetailsFormProps) => props.onSubmit(data);

  const {
    handleSubmit,
    watch,
    setValue,
    register,
    unregister,
    formState: {
      isValid,
      isDirty,
      isSubmitting,
      isSubmitSuccessful,
      touchedFields,
      dirtyFields,
    },
  } = methods;

  const otherField = watch('additionalDetails.other');

  const formCheckboxFollowUpValues = {
    followUpPhone: watch('followUpPhone'),
    followUpEmail: watch('followUpEmail'),
    followUpSms: watch('followUpSms'),
  };

  const {
    followUpPhone, followUpEmail, followUpSms,
  } = formCheckboxFollowUpValues;

  useEffect(() => {
    if (!otherField) {
      unregister('additionalDetails.otherDescription');
      setValue('additionalDetails.otherDescription', '');
    }
  }, [otherField]);

  const ChannelCheckboxes = checkboxes.map(element => (
    <>
      <IQCheckbox {...element} />
      {element.field === 'additionalDetails.other' && otherField
        && (
        <Grid item pl="33px" marginBottom={3}
          sx={{ '& > div > label': { pb: '4px' }, textarea: { width: 'initial' } }}
        >
          <IQFormInput
            {...register('additionalDetails.otherDescription', { required: 'This is a required field.' })}
            id="otherDescription"
            labelText=""
            name="additionalDetails.otherDescription"
            fullWidth
            schema={schema}
            theme={IQThemeV2}
            showError
          />
        </Grid>
        )}
    </>
  ));

  const followUpCheckboxContainerStyles = {
    display: 'flex',
    '& .MuiFormControlLabel-root': { marginRight: '0' },
    '&.MuiBox-root > label .MuiSvgIcon-root': { margin: '3px 0 0 5px' },
    border: checkboxFollowUpContainerError ? `2px solid ${theme.palette.error.main}` : 'none',
    width: checkboxFollowUpContainerError ? 'fit-content' : 'auto',
    padding: checkboxFollowUpContainerError ? '0 5px 0 2px' : '0',
    '& .MuiTypography-root': { color: checkboxFollowUpContainerError ? theme.palette.error.main : 'inherit' },
    '& .MuiFormHelperText-root': { margin: '0' },
  };

  const followUpCheckboxesField = followUpCheckboxes.map(element => (
    element.field === 'followUpSms' ? (
      <Box
        component="div"
        sx={{ ...followUpCheckboxContainerStyles }}
      >
        <IQCheckbox {...element} />
        <IQLabelTooltip
          tooltipText={t('pages.chat.smsTooltipHelpText')}
          tooltipPlacement="top"
          arrow={false}
          labelText=""
          hasError={false}
          theme={IQThemeV2}
          required={false}
          Icon={InfoOutlinedIcon}
        />
      </Box>
    ) : (
      <Box
        component="div"
        sx={{ ...followUpCheckboxContainerStyles, marginBottom: '8px' }}
      >
        <IQCheckbox {...element} />
      </Box>
    )
  ));

  useEffect(() => {
    const twoCheckboxTrue = Object.values(formCheckboxFollowUpValues).filter(e => e === true).length >= 2;
    const { followUpPhone, followUpEmail, followUpSms } = touchedFields;
    const { followUpPhone: fup, followUpEmail: fue, followUpSms: fus } = dirtyFields;
    const areCheckboxesDirty = [
      followUpPhone, followUpEmail, followUpSms, fup, fue, fus,
    ].some(field => field);

    if (areCheckboxesDirty && !twoCheckboxTrue) {
      setCheckboxFollowUpContainerError(true);
    }

    return () => setCheckboxFollowUpContainerError(false);
  }, [followUpPhone, followUpEmail, followUpSms]);

  const showConsultationTypes = watch('provideEstimatesConsultations') === 'yes';
  const minimumServiceFee = watch('additionalDetails.provideFreeConsultation') === 'no';
  const customCallConnectHours = watch('additionalDetails.callConnectAvailable') === 'customCallConnectHours';

  useEffect(() => {
    if (!showConsultationTypes) {
      unregister('additionalDetails.provideEstimateInPerson');
      setValue('additionalDetails.provideEstimateInPerson', false);
      unregister('additionalDetails.provideEstimateByPhone');
      setValue('additionalDetails.provideEstimateByPhone', false);
      unregister('additionalDetails.other');
      setValue('additionalDetails.other', false);
      unregister('additionalDetails.otherDescription');
      setValue('additionalDetails.otherDescription', '');
      unregister('additionalDetails.provideFreeConsultation');
      setValue('additionalDetails.provideFreeConsultation', '');
      unregister('minimumServiceFee');
      setValue('minimumServiceFee', '');
    }
  }, [showConsultationTypes]);

  useEffect(() => {
    if (!customCallConnectHours) {
      unregister('additionalDetails.customCallConnectHours');
      setValue('additionalDetails.customCallConnectHours', '');
    }
  }, [customCallConnectHours]);

  useEffect(() => {
    if (!minimumServiceFee) {
      unregister('minimumServiceFee');
      setValue('minimumServiceFee', '');
    }
  }, [minimumServiceFee]);

  const timezoneDropdown = (list) => {
    const dropdownOptions = list.map(region => (
      [
        <ListSubheader color="inherit">{region.heading}</ListSubheader>,
        region.options.map(option => <MenuItem value={option.value}>{option.label}</MenuItem>),
        list[list.length - 1].heading !== region.heading
          && <Divider variant="middle" sx={{ borderColor: 'text.secondary' }} />,
      ]
    ));

    return dropdownOptions;
  };

  const customValidation = yup.string().required('Please enter custom Call Connect hours.');

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: 'flex', justifyContent: 'space-around' }}
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <DefaultPageLayout
            disableContinue={!isValid || checkboxFollowUpContainerError}
            onBack={props.onBack}
            header={t('pages.chat.scriptDetails.header')}
            description={t('pages.chat.scriptDetails.description')}
            onContinue={handleSubmit(handleFormSubmit)}
          >
            <Grid container xs={6}>
              <GridItem>
                <Typography variant="h5" component="h2">
                  Estimates or Consultation Process
                </Typography>
                <YesNoRadio
                  label="Does the business provide estimates or consultations? *"
                  name="provideEstimatesConsultations"
                  shouldClearValues={false}
                >
                  <GridItem marginBottom={1}>
                    <IQLabelTooltip
                      labelText={t('pages.chat.scriptDetails.estimatesProvidedLabel')}
                      theme={IQTheme}
                      hasError={false}
                      required={false}
                      tooltipText=""
                    />
                    {ChannelCheckboxes}
                    <Grid item
                      sx={{
                        '& div[role=group]': {
                          maxWidth: 'none',
                        },
                        '& button:first-of-type': {
                          flex: '10%',
                        },
                      }}
                    >
                      <Grid marginBottom={3}
                        sx={{
                          '& > .MuiToggleButtonGroup-root > *': { backgroundColor: 'transparent' },
                          '& > .MuiToggleButtonGroup-root > *:hover': { backgroundColor: 'transparent' },
                          '.MuiTouchRipple-root > *': { display: 'none' },
                        }}
                      >
                        <IQButtonRadioContainer
                          label="Are estimates/consultations free?"
                          name="additionalDetails.provideFreeConsultation"
                          optionsList={optionsEstimatesFree}
                        />
                      </Grid>
                      {minimumServiceFee && (
                        <GridItem marginBottom={1} sx={{ textarea: { width: 'initial' } }}>
                          <IQFormInput
                            id="minimumServiceFee"
                            name="minimumServiceFee"
                            labelText={t('pages.chat.scriptDetails.serviceFeeAmount')}
                            fontLabelWeight="600"
                            theme={IQTheme}
                            fullWidth
                          />
                        </GridItem>
                      )}
                    </Grid>
                  </GridItem>
                </YesNoRadio>
              </GridItem>
              <GridItem marginBottom={6} sx={{ textarea: { width: 'initial' } }}>
                <IQFormTextArea
                  id="estimatesConsultationsInformation"
                  name="estimatesConsultationsInformation"
                  labelText={t('pages.chat.scriptDetails.estimatesNotes')}
                  fontLabelWeight="600"
                  fullWidth
                  rowCount={2}
                />
              </GridItem>
              <GridItem sx={{ textarea: { width: 'initial' } }}>
                <Typography variant="h5" component="h2">
                  Chat Button Suggestions
                </Typography>
                <GridItem marginBottom={3}>
                  <IQFormTextArea
                    id="chatSuggestions"
                    name="chatSuggestions"
                    labelText={t('pages.chat.scriptDetails.chatSuggestions')}
                    fontLabelWeight="600"
                    fullWidth
                    rowCount={2}
                    tooltipText={t('pages.chat.scriptDetails.chatSuggestionsTooltip')}
                    tooltipPlacement="top"
                    tooltipArrow={false}
                    tooltipPaddingBottom={16}
                  />
                </GridItem>
              </GridItem>
              <GridItem sx={{ textarea: { width: 'initial' } }}>
                <Typography variant="h5" component="h2">
                  Call Connect
                </Typography>
                <NeDropdown
                  name="additionalDetails.callConnectTimezone"
                  label={t('pages.chat.scriptDetails.callConnectTimezone')}
                  {...register('additionalDetails.callConnectTimezone', { required: 'This is required' })}
                  required
                  defaultValue={additionalDetails.callConnectTimezone}
                >
                  {timezoneDropdown(timezoneList)}
                </NeDropdown>
                <IQButtonRadioContainer
                  {...register('additionalDetails.callConnectAvailable', { required: 'This is required' })}
                  label={t('pages.chat.scriptDetails.callConnectAvailable')}
                  name="additionalDetails.callConnectAvailable"
                  optionsList={options}
                  singleColumn
                  required
                />
                {customCallConnectHours && (
                  <NeFormTextArea
                    {...register('additionalDetails.customCallConnectHours')}
                    id="customCallConnectHours"
                    name="additionalDetails.customCallConnectHours"
                    labelText={t('pages.chat.scriptDetails.customCallConnectHoursDescription')}
                    required
                    showError
                    fullWidth
                    rowCount={2}
                    customValidation={customValidation}
                    fontLabelWeight="600"
                  />
                )}
              </GridItem>
              <GridItem>
                <Typography variant="h5" component="h2">
                  Follow-Up Preferences
                </Typography>
              </GridItem>
              <GridItem marginBottom={1}>
                <IQLabelTooltip
                  tooltipText=""
                  labelText="How will the business follow-up with their Chat Leads? *"
                  hasError={checkboxFollowUpContainerError}
                  theme={IQThemeV2}
                  required={false}
                />
                <Typography paragraph mb={0}>
                  Select at least two options.
                </Typography>
                {followUpCheckboxesField}
                <ErrorText hasError={checkboxFollowUpContainerError} errorText="Please select at least two options." />
              </GridItem>
            </Grid>
          </DefaultPageLayout>
        </Box>
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
