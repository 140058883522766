/* eslint-disable no-param-reassign */
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { useTranslation } from "react-i18next";
import GridItem from 'components/grid/GridItem';
import React, { useEffect, useMemo } from 'react';
import GridContainer from 'components/grid/GridContainer';
import {
  IQFormInput,
  IQFormSelect, IQInputNumericField, IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { FormProvider, useForm } from 'react-hook-form';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import schema from './WebsiteClicks.schema';
import {
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel, Radio, Checkbox, Tooltip,
} from '@mui/material';
import { useState } from "react";
import schemaValidate from "components/schemaValidate";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NeSelectChip from "components/chips/NeSelectChip";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  callToActionDropDown,
  clientBeProvidingImagesToUseListOptions,
  genderOptionRadioItems,
  socialPlatformListOptions,
  visualStrategyListOptions,
  yesNoRadioOptions
} from "pages/xmo/tactics/websiteClicks/WebsiteClickService";
import ErrorIcon from "@mui/icons-material/Error";
import { Box } from "@mui/system";

interface Props {
  product: any;
  onSubmit: (data: any) => void;
  onBack: () => void;
}

export type WebsiteClickProps = {
  minimumAge: string;
  maximumAge: string;
  gender: string,
  callToActionButton: string,
  useAutomaticOrOptimizedPlacement: string
  clientWantTheirAdsToAppear: string,
  visualStrategy: string,
  clientBeProvidingImagesToUse: string,
  campaignUtilizeTheCarouselFormat: string,
  clientBeProvidingTextCreative: string,
  adHeadline: string,
  adBodyText: string,
  newsfeedLinkDescription: string,
  services: any;
};

export default function WebsiteClicksForm({product, onSubmit, onBack}: Props) {
  const methods = useForm<WebsiteClickProps>({
    resolver: yupResolver(schema.yupValidations),
    mode: 'all',
    defaultValues: {
      minimumAge: product?.minimumAge || '',
      maximumAge: product?.maximumAge || '',
      gender: product?.gender || '',
      callToActionButton: product?.callToActionButton || '',
      useAutomaticOrOptimizedPlacement: product?.useAutomaticOrOptimizedPlacement || '',
      clientWantTheirAdsToAppear: product?.clientWantTheirAdsToAppear || '',
      clientBeProvidingImagesToUse: product?.clientBeProvidingImagesToUse || '',
      visualStrategy: product?.visualStrategy || '',
      campaignUtilizeTheCarouselFormat: product?.campaignUtilizeTheCarouselFormat || '',
      clientBeProvidingTextCreative: product?.clientBeProvidingTextCreative || '',
      adHeadline: product?.adHeadline || '',
      adBodyText: product?.adBodyText || '',
      newsfeedLinkDescription: product?.newsfeedLinkDescription || '',
      services: product?.services || [],
    },
  });

  const {t} = useTranslation();
  const handleFormSubmit = (data: any) => {
    data.adHeadline = adHeadlineValue;
    data.adBodyText = adBodyTextValue;
    data.newsfeedLinkDescription = newsfeedLinkDescriptionValue;
    if (data.useAutomaticOrOptimizedPlacement === 'No') {
      data.clientWantTheirAdsToAppear = selectedOptions;
    } else {
      data.clientWantTheirAdsToAppear = '';
    }

    const pageFormData = {
      websiteClicks: data
    };
    onSubmit(pageFormData);
  };

  const {
    handleSubmit,
    register,
    watch,
    getValues,
    trigger,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  const [isAutomaticOrOptimizedPlacement, setIsAutomaticOrOptimizedPlacement] = useState(product?.useAutomaticOrOptimizedPlacement || '');
  const [isCampaignUtilizeTheCarouselFormat, setIsCampaignUtilizeTheCarouselFormat] = useState(product?.campaignUtilizeTheCarouselFormat || '');
  const [isClientBeProvidingTextCreative, setIsClientBeProvidingTextCreative] = useState(product?.clientBeProvidingTextCreative || '');
  const [adHeadlineValue, setAdHeadlineValue] = useState(product?.adHeadline || '');
  const [adBodyTextValue, setAdBodyTextValue] = useState(product?.adBodyText || '');
  const [newsfeedLinkDescriptionValue, setNewsfeedLinkDescriptionValue] = useState(product?.newsfeedLinkDescription || '');
  const [adHeadlineValueError, setAdHeadlineValueError] = useState<boolean>(false);
  const [newsfeedLinkDescriptionValueError, setNewsfeedLinkDescriptionValueError] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState(product?.clientWantTheirAdsToAppear || []);
  const [ageError, setAgeError] = useState<boolean>(false);
  const minimumAge = watch('minimumAge');
  const maximumAge = watch('maximumAge');

  const validateTextFieldForm = () => {
    if ((isCampaignUtilizeTheCarouselFormat === 'No' && isClientBeProvidingTextCreative === 'Yes')
        || (isCampaignUtilizeTheCarouselFormat === 'Yes' && isClientBeProvidingTextCreative === 'Yes')) {
      return (adBodyTextValue.trim().length > 0 &&
          newsfeedLinkDescriptionValue.trim().length > 0 &&
          adHeadlineValue.trim().length > 0);
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (isCampaignUtilizeTheCarouselFormat && isCampaignUtilizeTheCarouselFormat === 'Yes') {
      setAdHeadlineValueError(adHeadlineValue.length > 60)
      setNewsfeedLinkDescriptionValueError(newsfeedLinkDescriptionValue.length > 60)
    } else if (isCampaignUtilizeTheCarouselFormat && isCampaignUtilizeTheCarouselFormat === 'No') {
      setAdHeadlineValueError(adHeadlineValue.length > 75);
      setNewsfeedLinkDescriptionValueError(newsfeedLinkDescriptionValue.length > 250);
    }
  }, [isCampaignUtilizeTheCarouselFormat, adHeadlineValue, newsfeedLinkDescriptionValue]);

  useEffect(() => {
    const minAge = parseInt(minimumAge);
    const maxAge = parseInt(maximumAge);

    if (minAge <= 0 || maxAge <= 0 || minAge > maxAge) {
      setAgeError(true);
    } else {
      setAgeError(false);
    }
  }, [minimumAge, maximumAge]);

  const handleCheckboxChange = (opt) => {
    if (selectedOptions.includes(opt)) {
      setSelectedOptions(selectedOptions.filter(item => item !== opt));
    } else {
      setSelectedOptions([...selectedOptions, opt]);
    }
  };

  const validateForm = () => {
    if (isAutomaticOrOptimizedPlacement === 'No') {
      return selectedOptions.length > 0;
    } else {
      return true;
    }
  };

  useMemo(() => {
    const {minimumAge, maximumAge} = getValues();
    if (minimumAge && maximumAge) {
      trigger('maximumAge');
    }
  }, [watch('minimumAge')]);

  return (
      <DefaultPageLayout
          disableContinue={
              adHeadlineValueError ||
              newsfeedLinkDescriptionValueError ||
              ageError ||
              !isValid ||
              !validateForm() ||
              !validateTextFieldForm()}
          onBack={onBack}
          header={t('pages.xmo.tactics.xmoWebsiteClick.title')}
          onContinue={handleSubmit(handleFormSubmit)}
      >
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <GridContainer fullWidth>
              <GridItem sizes={[12]}>
                <Typography variant="h6">{t('Audience')}</Typography>
              </GridItem>

              <GridItem sizes={[6, 6]}>
                <IQInputNumericField
                    theme={IQTheme}
                    schema={schema}
                    label={t('pages.socialAds.solutionStrategy.smartWebsiteClick.minimumAgeTitle')}
                    name="minimumAge"
                    required={true}
                    disallowNegative
                    defaultValue={product?.minimumAge || ""}
                    fullWidth
                />
                <IQInputNumericField
                    theme={IQTheme}
                    schema={schema}
                    label={t('pages.socialAds.solutionStrategy.smartWebsiteClick.maximumAgeTitle')}
                    name="maximumAge"
                    required={true}
                    disallowNegative
                    defaultValue={product?.maximumAge || ""}
                    fullWidth
                />
                {ageError &&
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                            sx={{
                              color: '#C20F1E',
                              fontSize: '14px',
                              marginTop: '-15px',
                              marginBottom: '15px',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                            variant="subtitle1"
                        >
                          <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <ErrorIcon
                                sx={{
                                  color: '#C20F1E',
                                  fontSize: '16px',
                                  marginRight: '5px'
                                }}
                            />
                            {t('pages.xmo.leadAds.maximumAgeError')}
                          </Box>
                        </Typography>
                      </Grid>
                    </Grid>
                }
              </GridItem>

              <GridItem sizes={[12]}>
                <Typography
                    sx={{fontWeight: 'bold'}}>{t('pages.custom.solutions.common.genderLabel')}
                  <RadioGroup
                      id='gender'
                      name='gender'
                      row
                      defaultValue={product?.gender || ''}
                  >
                    {genderOptionRadioItems.map(opt => (
                        <FormControlLabel
                            key={opt.label}
                            control={<Radio value={opt.label}/>}
                            label={opt.label}
                            {...register('gender', {
                              validate: (value) => schemaValidate(value, 'gender', schema),
                            })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>

              <GridItem sizes={[12]}>
                <Typography variant="h6">{t('Strategy')}</Typography>
              </GridItem>

              <GridItem sizes={[12]}>
                <IQFormSelect
                    id="callToActionButton"
                    name="callToActionButton"
                    labelText={t('pages.xmo.tactics.xmoWebsiteClick.callToAction')}
                    items={callToActionDropDown}
                    defaultValue=""
                    theme={IQTheme}
                    fontLabelWeight="600"
                    fullWidth
                    schema={schema}
                />
              </GridItem>

              <GridItem sizes={[12]}>
                <Typography
                    sx={{fontWeight: 'bold'}}>{t('pages.socialAds.solutionStrategy.socialAdsCustom.useAutomaticPlacementOnAds')}
                  <RadioGroup
                      id='useAutomaticOrOptimizedPlacement'
                      name='useAutomaticOrOptimizedPlacement'
                      onChange={(e: any) => setIsAutomaticOrOptimizedPlacement(e.target.value)}
                      row
                      defaultValue={product?.useAutomaticOrOptimizedPlacement || ''}
                  >
                    {yesNoRadioOptions.map(opt => (
                        <FormControlLabel
                            key={opt.label}
                            control={<Radio value={opt.label}/>}
                            label={opt.label}
                            {...register('useAutomaticOrOptimizedPlacement', {
                              validate: (value) => schemaValidate(value, 'useAutomaticOrOptimizedPlacement', schema),
                            })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>

              {isAutomaticOrOptimizedPlacement === 'No' && (
                  <Grid container spacing={1}>
                    <Typography sx={{fontWeight: 'bold'}}>
                      {t('pages.xmo.tactics.xmoWebsiteClick.clientWantTheirAdsToAppearLabel')}
                    </Typography>
                    {socialPlatformListOptions.map((opt) => (
                        <Grid key={opt.label} item xs={12}>
                          <FormControlLabel
                              control={
                                <Checkbox
                                    id={`clientWantTheirAdsToAppear_${opt.label}`}
                                    name={`clientWantTheirAdsToAppear_${opt.label}`}
                                    checked={selectedOptions.includes(opt.label)}
                                    onChange={() => handleCheckboxChange(opt.label)}
                                    color="primary"
                                    inputProps={{'aria-label': opt.label}}
                                />
                              }
                              label={
                                <React.Fragment>
                                  {opt.label}
                                  {opt.label === 'MarketPlace' && (
                                      <Tooltip
                                          title={t('pages.xmo.tactics.xmoWebsiteClick.mobileNewsFeedEnabledTooltip')}
                                          placement="top">
                                        <InfoOutlinedIcon color="primary"
                                                          sx={{marginLeft: 1, fontSize: '15px'}}/>
                                      </Tooltip>
                                  )}
                                </React.Fragment>
                              }
                          />
                        </Grid>
                    ))}
                  </Grid>
              )}

              <GridItem sizes={[12]}>
                <Typography variant="h6"
                            sx={{marginBottom: '-16px'}}>{t('pages.xmo.tactics.xmoWebsiteClick.contentCreativeLabel')}
                </Typography>
                <Typography
                    fontSize={17}>{t('pages.xmo.tactics.xmoWebsiteClick.adCreativeLabel')}
                </Typography>
              </GridItem>

              <GridItem sizes={[12]}>
                <Typography sx={{marginBottom: '8px', fontWeight: 'bold'}}>
                  {t('pages.xmo.tactics.xmoWebsiteClick.visualStrategyLabel')}
                  <RadioGroup
                      id="visualStrategy"
                      name="visualStrategy"
                      defaultValue={product?.visualStrategy || ''}
                  >
                    {visualStrategyListOptions.map(opt => (
                        <FormControlLabel
                            key={opt.label}
                            control={<Radio value={opt.label}/>}
                            label={
                              <React.Fragment>
                                {opt.label}
                                {(opt.label === 'Single Video' || opt.label === 'Multiple Videos') && (
                                    <Tooltip
                                        title={t('pages.xmo.tactics.xmoWebsiteClick.visualStrategyTooltip')}
                                        placement="top">
                                      <InfoOutlinedIcon color="primary"
                                                        sx={{marginLeft: 1, fontSize: '15px'}}/>
                                    </Tooltip>
                                )}
                              </React.Fragment>
                            }
                            {...register('visualStrategy', {
                              validate: (value) => schemaValidate(value, 'visualStrategy', schema),
                            })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>

              <GridItem sizes={[12]}>
                <Typography sx={{marginBottom: '-18px', fontWeight: 'bold'}}>
                  {t('pages.xmo.tactics.xmoWebsiteClick.clientBeProvidingImagesToUseLabel')}
                </Typography>
                <RadioGroup
                    id="clientBeProvidingImagesToUse"
                    name="clientBeProvidingImagesToUse"
                    row
                    defaultValue={product?.clientBeProvidingImagesToUse || ''}
                >
                  {clientBeProvidingImagesToUseListOptions.map(opt => (
                      <FormControlLabel
                          key={opt.label}
                          control={<Radio value={opt.label}/>}
                          label={opt.label}
                          {...register('clientBeProvidingImagesToUse', {
                            validate: (value) => schemaValidate(value, 'clientBeProvidingImagesToUse', schema),
                          })}
                      />
                  ))}
                </RadioGroup>
              </GridItem>

              <GridItem sizes={[12]}>
                <Typography sx={{marginBottom: '-18px', fontWeight: 'bold'}}>
                  {t('pages.xmo.tactics.xmoWebsiteClick.campaignUtilizeTheCarouselFormatLabel')}
                  <Tooltip
                    title={t('pages.xmo.tactics.xmoWebsiteClick.campaignUtilizeTheCarouselFormatTooltip')}
                    placement="top"
                  >
                    <HelpOutlineIcon color="primary" sx={{marginLeft: 1, fontSize: '15px'}}/>
                  </Tooltip>
                </Typography>
                <RadioGroup
                    id="campaignUtilizeTheCarouselFormat"
                    name="campaignUtilizeTheCarouselFormat"
                    onChange={(e: any) => setIsCampaignUtilizeTheCarouselFormat(e.target.value)}
                    row
                    defaultValue={product?.campaignUtilizeTheCarouselFormat || ''}
                >
                  {yesNoRadioOptions.map(opt => (
                      <FormControlLabel
                          key={opt.label}
                          value={opt.label}
                          control={<Radio/>}
                          label={opt.label}
                          {...register('campaignUtilizeTheCarouselFormat', {
                            validate: (value) => schemaValidate(value, 'campaignUtilizeTheCarouselFormat', schema),
                          })}
                      />
                  ))}
                </RadioGroup>
              </GridItem>

              <GridItem sizes={[12]}>
                <Typography sx={{marginBottom: '8px', fontWeight: 'bold'}}>
                  {t('pages.xmo.tactics.xmoWebsiteClick.clientBeProvidingTextCreativeLabel')}
                  <RadioGroup
                      id="clientBeProvidingTextCreative"
                      name="clientBeProvidingTextCreative"
                      onChange={(e: any) => setIsClientBeProvidingTextCreative(e.target.value)}
                      defaultValue={product?.clientBeProvidingTextCreative || ''}
                      row
                  >
                    {yesNoRadioOptions.map(opt => (
                        <FormControlLabel
                            key={opt.label}
                            control={<Radio value={opt.label}/>}
                            label={opt.label}
                            {...register('clientBeProvidingTextCreative', {
                              validate: (value) => schemaValidate(value, 'clientBeProvidingTextCreative', schema),
                            })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              {((isCampaignUtilizeTheCarouselFormat === 'No' && isClientBeProvidingTextCreative === 'Yes')
                  || (isCampaignUtilizeTheCarouselFormat === 'Yes' && isClientBeProvidingTextCreative === 'Yes')) && (
                  <>
                    <GridItem sizes={[12]}>
                      <IQFormInput
                          id="adHeadline"
                          name="adHeadline"
                          labelText={t('pages.xmo.tactics.xmoWebsiteClick.adHeadlineLabel')}
                          theme={IQTheme}
                          schema={schema}
                          fontLabelWeight="600"
                          fullWidth
                          onChange={(e: any) => setAdHeadlineValue(e.target.value)}
                          maxLength={isCampaignUtilizeTheCarouselFormat === 'No' ? 75 : 60}
                          subDescription={isCampaignUtilizeTheCarouselFormat === 'No' ? `${adHeadlineValue.length}/75` : `${adHeadlineValue.length}/60`}
                      />
                    </GridItem>
                    {adHeadlineValueError &&
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                                sx={{
                                  color: '#C20F1E',
                                  fontSize: '14px',
                                  marginTop: '-15px',
                                  marginBottom: '15px',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                                variant="subtitle1"
                            >
                              <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <ErrorIcon
                                    sx={{
                                      color: '#C20F1E',
                                      fontSize: '16px',
                                      marginRight: '5px'
                                    }}
                                />
                                {t('pages.xmo.tactics.xmoWebsiteClick.maximumCharacterLimitExceedError')}
                              </Box>
                            </Typography>
                          </Grid>
                        </Grid>
                    }
                    <GridItem sizes={[12]}>
                      <IQFormInput
                          id="adBodyText"
                          name="adBodyText"
                          labelText={t('pages.xmo.tactics.xmoWebsiteClick.adBodyTextLabel')}
                          theme={IQTheme}
                          schema={schema}
                          fontLabelWeight="600"
                          fullWidth
                          maxLength={255}
                          onChange={(e: any) => setAdBodyTextValue(e.target.value)}
                          subDescription={`${adBodyTextValue.length}/255`}
                      />
                    </GridItem>

                    <GridItem sizes={[12]}>
                      <IQFormInput
                          id="newsfeedLinkDescription"
                          name="newsfeedLinkDescription"
                          labelText={t('pages.xmo.tactics.xmoWebsiteClick.newsfeedLinkDescriptionLabel')}
                          theme={IQTheme}
                          schema={schema}
                          fontLabelWeight="600"
                          fullWidth
                          onChange={(e: any) => setNewsfeedLinkDescriptionValue(e.target.value)}
                          maxLength={isCampaignUtilizeTheCarouselFormat === 'No' ? 250 : 60}
                          subDescription={isCampaignUtilizeTheCarouselFormat === 'No' ? `${newsfeedLinkDescriptionValue.length}/250` : `${newsfeedLinkDescriptionValue.length}/60`}
                      />
                    </GridItem>
                    {newsfeedLinkDescriptionValueError &&
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                                sx={{
                                  color: '#C20F1E',
                                  fontSize: '14px',
                                  marginTop: '-15px',
                                  marginBottom: '15px',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                                variant="subtitle1"
                            >
                              <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <ErrorIcon
                                    sx={{
                                      color: '#C20F1E',
                                      fontSize: '16px',
                                      marginRight: '5px'
                                    }}
                                />
                                {t('pages.xmo.tactics.xmoWebsiteClick.maximumCharacterLimitExceedError')}
                              </Box>
                            </Typography>
                          </Grid>
                        </Grid>
                    }
                  </>
              )}
              <GridItem>
                <NeSelectChip label
                              labelText={t('pages.xmo.tactics.xmoWebsiteClick.productServiceOfferPromoteInCampaignLabel')}
                              items={[]}
                              name="services"
                              placeholder="Press enter to an add item"
                              values={product?.services}
                              maxItems={4}
                              freeSolo
                              disableClear
                              schema={schema}
                              theme={IQTheme}
                />
              </GridItem>

            </GridContainer>
            <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </FormProvider>
        </ValidationProvider>
      </DefaultPageLayout>
  );
}
