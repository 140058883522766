/* eslint-disable max-len */
import i18n from 'i18n/i18n';
import {RJSFSchema} from '@rjsf/utils';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';

const {t} = i18n;
const urlValidationParameter = ['https://tiktok.com', 'https://www.tiktok.com'];

export const TrackingTikTokSchema: RJSFSchema | any = (data) => ({
  title: t('pages.ausProduct.ausTiktok.tracking'),
  type: 'object',
  required: ['trackingCampaigns'],
  properties: {
    trackingNote: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.trackingHelperText'),
    },
    trackingCampaigns: {
      type: 'string',
      title: t('pages.ausProduct.tracking.trackingCampaignsLabel'),
      default: data?.trackingCampaigns || '',
      enum: [
        `${t('pages.ausProduct.tracking.reachCode')}`,
        `${t('pages.ausProduct.tracking.3pp')}`,
        `${t('pages.ausProduct.tracking.noTracking')}`,
      ],
    },
    doesClientWantToRunAdsFromTheirTiktokPage: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.doesClientWantToRunAdsFromTheirTiktokPageLabel'),
      enum: [
        `${t('pages.ausProduct.tracking.yes')}`,
        `${t('pages.ausProduct.tracking.no')}`,
      ],
    },
    doesClientHaveSpecialMarginOnBid: {
      type: 'string',
      title: t('pages.ausProduct.ausTiktok.doesClientHaveSpecialMarginOnBidLabel'),
      enum: [
        `${t('pages.ausProduct.tracking.yes')}`,
        `${t('pages.ausProduct.tracking.no')}`,
      ],
    },
  },
  dependencies: {
    doesClientWantToRunAdsFromTheirTiktokPage: {
      oneOf: [
        {
          properties: {
            doesClientWantToRunAdsFromTheirTiktokPage: {
              enum: [t('pages.ausProduct.tracking.yes')],
            },
            tiktokPageUrl: {
              type: 'string',
              title: t('pages.ausProduct.ausTiktok.tiktokPageUrlLabel'),
              default: data?.tiktokPageUrl || '',
              customUrlValidation: true,
              urlValidationParameter,
              urlValidationErrorText: t('pages.ausProduct.ausTiktok.tiktokPageUrlErrorMessage'),
              description: t('pages.ausProduct.ausTiktok.tiktokPageUrlHelperText'),
            },
          },
          required: ['tiktokPageUrl'],
        },
      ],
    },
    doesClientHaveSpecialMarginOnBid: {
      oneOf: [
        {
          properties: {
            doesClientHaveSpecialMarginOnBid: {
              enum: [t('pages.ausProduct.tracking.yes')],
            },
            margin: {
              type: 'string',
              title: t('pages.ausProduct.ausTiktok.marginLabel'),
              default: data?.margin || '',
            },
          },
        },
      ],
    },
  }
});

const dropdownFieldKeys = [
  'trackingCampaigns',
  'doesClientWantToRunAdsFromTheirTiktokPage',
  'doesClientHaveSpecialMarginOnBid'
];

const urlTextFieldKeys = [
  'tiktokPageUrl',
]

const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {
    'ui:widget': (props) => (
        <Dropdown
            {...props}
            errorMessage={t('pages.ausProduct.tracking.requiredFieldErrorMessage')}
        />
    ),
  },
}), {});

const urlTextFields = urlTextFieldKeys.reduce((o, key) => ({
  ...o, [key]: {'ui:widget': 'UrlTextWidget'},
}), {});

export const TrackingTikTokUISchema = {
  'ui:order': [
    'trackingNote',
    'trackingCampaigns',
    'doesClientWantToRunAdsFromTheirTiktokPage',
    'tiktokPageUrl',
    'doesClientHaveSpecialMarginOnBid',
    'margin',
  ],

  margin:{
    'ui:widget': 'textInputField'
  },
  trackingNote:{
    'ui:widget': 'textLabel'
  },
  ...dropdownFields,
  ...urlTextFields
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const TrackingTikTokCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.trackingCampaigns === '') {
    errors.trackingCampaigns.addError(t('pages.ausProduct.tracking.requiredFieldErrorMessage'));
    errors.addError(true);
  }
  if (formData?.doesClientWantToRunAdsFromTheirTiktokPage === t('pages.ausProduct.tracking.yes')) {
    if (formData?.tiktokPageUrl === '' || !urlValidationParameter.some(url => formData?.tiktokPageUrl?.startsWith(url))) {
      errors.tiktokPageUrl.addError(t('pages.ausProduct.ausTiktok.tiktokPageUrlErrorMessage'));
      errors.addError(true);
    }
  }
  return errors;
};
