import ContentLoader from 'components/contentLoader/ContentLoader';
import useProduct from 'hooks/useProduct';
import { Urls } from 'navigation/Urls';
import EcommerceType, { EcommerceStateObject } from 'models/EcommerceType';
import { StatusType } from 'models/StatusType';
import AdditionalStoreDetailsForm from './AdditionalStoreDetailsForm';

export default function AdditionalStoreDetails() {
  const { product, onBack, onSubmit } = useProduct<EcommerceType,
  StatusType<EcommerceStateObject>>(Urls.AdditionalStoreDetails);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <AdditionalStoreDetailsForm onBack={onBack} onSubmit={onSubmit} product={product.content?.data} />
    </ContentLoader>
  );
}
