import React, { useState, useEffect } from 'react';
import {
  Grid, Typography, Box, Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import GridItem from 'components/grid/GridItem';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  createBusinessLocation, deleteBusinessLocation,
  getBusinessLocations, selectBusinessLocations, selectProviders,
} from 'services/businessLocationSlice';
import { getOrder, selectOrdersContent } from 'services/ordersSlice';
import LocationCard from 'components/locationCard/LocationCard';
import { useNavigationHandler } from 'hooks/useNavigationHandler';

export const emptyItem = {
  locationName: '',
  businessAddressOne: '',
  businessAddressTwo: '',
  city: '',
  stateProvince: '',
  country: '',
  postalCode: '',
  sourceId: '',
  provider: null,
};

export default function PremiumListingsReview() {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const locations = useSelector(selectBusinessLocations);
  const providers = useSelector(selectProviders);
  const { businessId } = useSelector(selectOrdersContent);
  const [cardCountLocations, setCardCountLocations] = useState(0);
  const [cardCountProviders, setCardCountProviders] = useState(0);

  useEffect(() => {
    dispatch(getOrder(orderId));
  }, []);

  useEffect(() => {
    if (locations) setCardCountLocations(locations.length);
  }, [locations]);

  useEffect(() => {
    if (providers) setCardCountProviders(providers.length);
  }, [providers]);

  const handleAddLocation = async () => {
    const defaultLocationToCreate = { ...emptyItem };
    defaultLocationToCreate.sourceId = uuidv4();
    await dispatch(createBusinessLocation({ businessId, defaultLocationToCreate }));
    await dispatch(getBusinessLocations(businessId));
  };

  const handleAddProvider = async () => {
    const defaultLocationToCreate = { ...emptyItem };
    defaultLocationToCreate.sourceId = uuidv4();
    defaultLocationToCreate.provider = true;
    await dispatch(createBusinessLocation({ businessId, defaultLocationToCreate }));
    await dispatch(getBusinessLocations(businessId));
  };

  const removeLocation = async (location) => {
    if (location.id) {
      await dispatch(deleteBusinessLocation(location.id));
      await dispatch(getBusinessLocations(businessId));
    }
  };

  const handleReviewRouting = (location, provider = null) => {
    if (location?.isProvider) {
      return provider
        ? `premiumlistings/healthcare/provider/${location.id}/address`
        : `premiumlistings/healthcare/location/${location.id}/address`;
    }
    return `premiumlistings/location/${location.id}/address`;
  };

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F', paddingBottom: '24px' }}>
      <GridItem sizes={[12]} marginBottom={0} sx={{
        padding: '20px 0 0',
        '& .MuiGrid-root': {
          width: '100%',
          paddingRight: '16px',
        },
      }}
      >
        {/* Location Listings */}
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Location Listings</Typography>
        </Box>
        {locations && React.Children.toArray(locations.map(location => (
          <LocationCard
            field={location.sourceId}
            locationName={location.locationName}
            addressLine1={location.businessAddressOne}
            addressLine2={location.businessAddressTwo}
            city={location.city}
            state={location.stateProvince}
            zipcode={location.postalCode}
            locationStatus={location.fulfilledAdditionalInfo !== null ? 'Completed' : 'Review'}
            handleDelete={() => removeLocation(location)}
            onSelect={() => navigate.to(handleReviewRouting(location))}
          />
        )))}
        <Button startIcon={<AddIcon />} disabled={cardCountLocations > 8} onClick={handleAddLocation}>
          Add New Location
        </Button>
      </GridItem>

      {providers.length > 0 && (
        <GridItem sizes={[12]} marginBottom={0} marginTop={2}
          sx={{
            padding: '20px 0 0',
            '& .MuiGrid-root': {
              width: '100%',
              paddingRight: '16px',
            },
          }}
        >
          {/* Healthcare Listings */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Provider Listings</Typography>
          </Box>
          {providers && React.Children.toArray(providers.map(location => (
            <LocationCard
              field={location.sourceId}
              locationName={location.locationName}
              addressLine1={location.businessAddressOne}
              addressLine2={location.businessAddressTwo}
              city={location.city}
              state={location.stateProvince}
              zipcode={location.postalCode}
              locationStatus={location.fulfilledAdditionalInfo !== null ? 'Completed' : 'Review'}
              handleDelete={() => removeLocation(location)}
              onSelect={() => navigate.to(handleReviewRouting(location, true))}
            />
          )))}
          <Button startIcon={<AddIcon />} disabled={cardCountProviders > 8} onClick={handleAddProvider}>
            Add New Provider
          </Button>
        </GridItem>
      )}
    </Grid>
  );
}
