import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IQFormTextArea, ValidationProvider } from '@gannettdigital/shared-react-components';
import { Typography, Grid } from '@mui/material';
import CoFileUploader, { AllUploaders } from 'components/fileUploader/CoFileUploader';
import { ProductTypes, selectFilesUploaded } from 'services/fileUploaderSlice';
import { useParams } from 'react-router';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { Urls } from 'navigation/Urls';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomWebsite, putCustomWebsiteAdditionalInfo, selectCustomWebsite } from 'services/customWebsiteSlice';
import { useEffect, useState } from 'react';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { selectActiveProductId } from 'services/navigationSlice';
import schema from './AdditionalInfo.schema';

export default function CustomWebsiteAdditionalInfo() {
  const { t } = useTranslation();
  const navigate = useNavigationHandler();
  const { orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const customWebsite = useSelector(selectCustomWebsite);
  const files = useSelector(selectFilesUploaded);
  const filteredFiles = files.content.filter(file => file?.uploaderType === ProductTypes.ADDITIONAL_INFORMATION);
  const [formIsEmpty, setFormIsEmpty] = useState(true);

  const fileUploaderProps : AllUploaders['fileUploaderOptions'] = {
    name: 'customWebsiteAdditionalInfo',
    directory: ProductTypes.ADDITIONAL_INFORMATION,
    label: t('pages.website.custom.additionalInfo.assets'),
    supportingText: t('pages.website.custom.additionalInfo.assetsDescription'),
    showRecommendedImageText: false,
    showCheckboxes: false,
    showComments: false,
    imageTypes: ['.png', '.jpg', '.jpeg', '.gif', '.CR2', '.DNG'],
    videoTypes: ['.mp4'],
    documentTypes: ['.pdf', '.docx'],
    maxFileSize: 256 * 1_000_000,
    uploaderType: ProductTypes.ADDITIONAL_INFORMATION,
    withLabelError: false,
  };

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      additionalInfo: '',
    },
  });

  const {
    getValues, handleSubmit, reset, watch, formState: {
      isDirty, isSubmitting, isSubmitSuccessful, isValid,
    },
  } = methods;

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsite(websiteId?.toString()));
  }, [websiteId]);

  useEffect(() => {
    reset({
      additionalInfo: customWebsite.additionalInfo,
    });
  }, [customWebsite]);

  const onFormSubmit = () => {
    const data = getValues();
    const content = {
      additionalInfo: data.additionalInfo,
    };
    dispatch(putCustomWebsiteAdditionalInfo({ orderItemId, content }));
    navigate.to(Urls.Overview);
  };

  const additionalInfoValue = getValues('additionalInfo');

  useEffect(() => {
    setFormIsEmpty(!additionalInfoValue && filteredFiles.length === 0);
  }, [watch('additionalInfo'), filteredFiles]);

  return (
    <DefaultPageLayout
      onContinue={handleSubmit(onFormSubmit)}
      continueButtonLabel={!formIsEmpty ? t('form.buttons.continue') : t('form.buttons.skipAndContinue')}
      disableContinue={!isValid}
      onBack={() => navigate.to(`${Urls.CustomWebsiteDevelopment}/${Urls.CustomExamples}`)}
      header={t('pages.website.custom.additionalInfo.title')}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container>
            <Grid item xs={6} marginBottom={5}>
              <Typography>{t('pages.website.custom.additionalInfo.description')}</Typography>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6} marginBottom={3}>
              <IQFormTextArea
                id="additionalInfo"
                name="additionalInfo"
                labelText={t('pages.website.custom.additionalInfo.info')}
                fontLabelWeight="bold"
                rowCount={3}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={12}>
              <CoFileUploader
                schema={schema}
                productId={websiteId.toString()}
                productType={ProductTypes.ADDITIONAL_INFORMATION}
                showFileUploader
                fileUploaderOptions={fileUploaderProps}
                offeringType={WebsiteOfferingTypes.SILVER}
                containerStyles={{
                  xs: 6,
                }}
              />
            </Grid>
          </Grid>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
