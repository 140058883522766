import {
  Button, Dialog, DialogTitle, Grid, IconButton, Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  open: boolean
  handleClose: () => void
  handleContinue: () => void
}

export default function AddonsDisclaimerModal(props: Props) {
  const {
    open, handleClose, handleContinue,
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle sx={{ padding: '40px', paddingBottom: '20px' }}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography fontWeight={700} fontSize={20} textAlign="left">
              {t('pages.themes.addonsModal.title')}
            </Typography>
          </Grid>
        </Grid>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Grid container sx={{ padding: '0 40px' }}>
        <Grid item xs={12}>
          <Typography variant="body1" style={{ lineHeight: '20px' }}>
            {(<Trans
              i18nKey="pages.themes.addonsModal.description"
              components={{ strong: <strong /> }}
            /> as unknown as string)}
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom={5}>
          <Grid container sx={{ marginTop: '20px' }}>
            <Grid item xs={6} paddingRight={2}>
              <Button variant="outlined" onClick={handleClose}>
                <Typography fontSize="14px" fontWeight="bold">Back</Typography>
              </Button>
            </Grid>
            <Grid item xs={6} paddingLeft={2}
              sx={{ textAlign: 'right' }}
            >
              <Button variant="contained" onClick={handleContinue}>
                <Typography fontSize="14px" fontWeight="bold">Continue to Cart</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
