/* eslint-disable max-len */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import Dropdown from 'pages/generic-product/details/custom-fields/Dropdown';
import { validateEmail } from 'pages/generic-product/details/custom-fields/EmailTextField';
import FileUploader from 'pages/generic-product/details/custom-fields/FileUploader';
import { ProductTypes } from 'services/fileUploaderSlice';
import DatePickerField from 'pages/generic-product/details/custom-fields/DatePickerField';

const { t } = i18n;

export const DisplayCreativeServiceCustomSchema: RJSFSchema | any = (data) => ({
  title: t('pages.smm.solutionStrategy.title'),
  type: 'object',
  required: ['reachOutForReview'],
  properties: {
    reachOutForReview: {
      type: 'string',
      title: t('pages.displayCreativeService.reachOutForReview'),
      default: '',
      enum: [
        'Client',
        'Sales Rep',
        'Client & Sales Rep',
      ],
    },
    creativeIsNeededDate: {
      type: 'string',
      title: t('pages.displayCreativeService.custom.dateCreativeNeededLabel'),
      description: t('pages.displayCreativeService.custom.dateCreativeNeededHelper'),
      default: data?.creativeIsNeededDate || '',
    },
    goalOfAd: {
      type: 'array',
      title: t('pages.displayCreativeService.custom.goalOfAdLabel'),
      description: t('pages.custom.solutions.common.checkAllLabel'),
      default: data?.goalOfAd || '',
      items: {
        type: 'string',
        enum: [
          t('pages.displayCreativeService.custom.goalOfAdopt1'),
          t('pages.displayCreativeService.custom.goalOfAdopt2'),
          t('pages.displayCreativeService.custom.goalOfAdopt3'),
          t('pages.displayCreativeService.custom.goalOfAdopt4'),
        ],
      },
      uniqueItems: true,
    },
    levelOfCreativityOrArtisticDiscretion: {
      type: 'string',
      title: t('pages.displayCreativeService.custom.levelOfCreativityOrArtisticDiscretionLabel'),
      default: data?.levelOfCreativityOrArtisticDiscretion || '',
      items: {
        enum: [
          t('pages.displayCreativeService.custom.levelOfCreativityOrArtisticDiscretionOpt1'),
          t('pages.displayCreativeService.custom.levelOfCreativityOrArtisticDiscretionOpt2'),
          t('pages.displayCreativeService.custom.levelOfCreativityOrArtisticDiscretionOpt3'),
        ],
      },
    },
    typeOfDigitalOrOnlineAd: {
      type: 'string',
      title: t('pages.displayCreativeService.custom.typeOfDigitalOrOnlineAdLabel'),
      default: data?.typeOfDigitalOrOnlineAd || '',
      enum: [
        '',
        t('pages.displayCreativeService.custom.typeOfDigitalOrOnlineAdOpt1'),
        t('pages.displayCreativeService.custom.typeOfDigitalOrOnlineAdOpt2'),
      ],
    },
    siteLookalikeRetargeting: {
      type: 'string',
      title: t('pages.displayCreativeService.custom.siteLookalikeRetargetingLabel'),
      default: data?.siteLookalikeRetargeting || '',
      enum: [
        '',
        t('form.big-box-labels.yes'),
        t('pages.custom.solutions.common.noOption'),
      ],
    },
    targetedDisplayCampaign: {
      type: 'string',
      title: t('pages.displayCreativeService.custom.targetedDisplayCampaignLabel'),
      default: data?.targetedDisplayCampaign || '',
      enum: [
        '',
        t('form.big-box-labels.yes'),
        t('pages.custom.solutions.common.noOption'),
      ],
    },
    landingPageUrl: {
      type: 'string',
      title: t('pages.displayCreativeService.custom.LandingPageUrlLabel'),
      default: data?.landingPageUrl || '',
    },
    headlineOrBenefitStatement: {
      type: 'string',
      title: t('pages.displayCreativeService.custom.headlineOrBenefitStatementLabel'),
      default: data?.headlineOrBenefitStatement || '',
    },
    contentOrOfferOrPromotion: {
      type: 'string',
      title: t('pages.displayCreativeService.custom.contentOrOfferOrPromotionLabel'),
      default: data?.contentOrOfferOrPromotion || '',
    },
    uploader_customCreativeCampaignOrderBrief: {
      type: 'string',
      default: '',
      title: t('pages.displayCreativeService.custom.customCreativeCampaignOrderBriefLabel'),
    },
    uploader_customCreativeCampaignOrderMockup: {
      type: 'string',
      default: '',
      title: t('pages.displayCreativeService.custom.customCreativeCampaignOrderMockupLabel'),
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.displayCreativeService.notesOrInstructions'),
      default: '',
    },
  },

  dependencies: {
    reachOutForReview: {
      oneOf: [
        {
          properties: {
            reachOutForReview: {
              enum: ['Sales Rep'],
            },
            emailForCreativeApprovalNotifications: {
              type: 'string',
              title: t('pages.displayCreativeService.emailForCreativeApprovalNotifications'),
              default: '',
              description: t('pages.displayCreativeService.emailForApprovalNotificationsDescription'),
            },
          },
          required: ['emailForCreativeApprovalNotifications'],
        },
        {
          properties: {
            reachOutForReview: {
              enum: ['Client'],
            },
            clientContactFirstName: {
              type: 'string',
              title: t('pages.displayCreativeService.clientContactFirstName'),
            },
            clientContactLastName: {
              type: 'string',
              title: t('pages.displayCreativeService.clientContactLastName'),
            },
            clientContactPhone: {
              type: 'string',
              title: t('pages.displayCreativeService.clientContactPhone'),
            },
            clientContactEmail: {
              type: 'string',
              title: t('pages.displayCreativeService.clientContactEmail'),
            },
            bestNumberToReachYou: {
              type: 'string',
              title: t('pages.displayCreativeService.bestNumberToReachYou'),
            },
          },
          required: ['clientContactFirstName', 'clientContactLastName', 'clientContactPhone', 'clientContactEmail'],
        },
        {
          properties: {
            reachOutForReview: {
              enum: ['Client & Sales Rep'],
            },
            clientContactFirstName: {
              type: 'string',
              title: t('pages.displayCreativeService.clientContactFirstName'),
            },
            clientContactLastName: {
              type: 'string',
              title: t('pages.displayCreativeService.clientContactLastName'),
            },
            clientContactPhone: {
              type: 'string',
              title: t('pages.displayCreativeService.clientContactPhone'),
            },
            clientContactEmail: {
              type: 'string',
              title: t('pages.displayCreativeService.clientContactEmail'),
              description: t('pages.displayCreativeService.emailHelpContext'),
            },
            bestNumberToReachYou: {
              type: 'string',
              title: t('pages.displayCreativeService.bestNumberToReachYou'),
            },
            emailForCreativeApprovalNotifications: {
              type: 'string',
              title: t('pages.displayCreativeService.emailForCreativeApprovalNotifications'),
              default: '',
              description: t('pages.displayCreativeService.emailForApprovalNotificationsDescription'),
            },
          },
          required: [
            'clientContactFirstName',
            'clientContactLastName',
            'clientContactPhone',
            'clientContactEmail',
            'emailForCreativeApprovalNotifications',
          ],
        },
      ],
    },
    levelOfCreativityOrArtisticDiscretion: {
      oneOf: [
        {
          properties: {
            levelOfCreativityOrArtisticDiscretion: {
              enum: ['Some interpretation'],
            },
            ifSomeInterpretationWasSelectedPleaseProvideAdditionalInformation: {
              type: 'array',
              title: t('pages.displayCreativeService.custom.ifSomeInterpretationWasSelectedPleaseProvideAdditionalInformationLabel'),
              default: data?.ifSomeInterpretationWasSelectedPleaseProvideAdditionalInformation || '',
              items: {
                type: 'string',
                enum: [
                  t('pages.displayCreativeService.custom.changeColorsOpt'),
                  t('pages.displayCreativeService.custom.rearrangeExistingElementsOpt'),
                  t('pages.displayCreativeService.custom.addNewElementsOpt'),
                  t('pages.displayCreativeService.custom.removeElementsOpt'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
        {
          properties: {
            levelOfCreativityOrArtisticDiscretion: {
              enum: ['Use artistic interpretation'],
            },
            ifUseArtisticInterpretationWasSelectedPleaseProvideAdditionalInformation: {
              type: 'array',
              title: t('pages.displayCreativeService.custom.ifUseArtisticInterpretationWasSelectedPleaseProvideAdditionalInformationLabel'),
              default: data?.ifUseArtisticInterpretationWasSelectedPleaseProvideAdditionalInformation || '',
              items: {
                type: 'string',
                enum: [
                  t('pages.displayCreativeService.custom.collegeAgeGroupOpt'),
                  t('pages.displayCreativeService.custom.upscaleLookOpt'),
                  t('pages.displayCreativeService.custom.funDesignOpt'),
                  t('pages.displayCreativeService.custom.appealsToWomenOpt'),
                  t('pages.displayCreativeService.custom.appealsToMenOpt'),
                  t('pages.displayCreativeService.custom.invitingOpt'),
                  t('pages.displayCreativeService.custom.expensiveOpt'),
                  t('pages.displayCreativeService.custom.boldOpt'),
                  t('pages.displayCreativeService.custom.softOpt'),
                  t('pages.displayCreativeService.custom.cleanOpt'),
                ],
              },
              uniqueItems: true,
            },
          },
        },
      ],
    },
  },
});

const textAreaKeys = [
  'notesOrInstructions',
  'emailForCreativeApprovalNotifications',
];

const dropdownFieldKeys = [
  'typeOfDigitalOrOnlineAd',
  'siteLookalikeRetargeting',
  'targetedDisplayCampaign',
];

const textFieldKeys = [
  'clientContactFirstName',
  'clientContactLastName',
  'headlineOrBenefitStatement',
  'contentOrOfferOrPromotion',
];

const radioFieldKeys = [
  'levelOfCreativityOrArtisticDiscretion',
];

const phoneNumberTextKeys = [
  'clientContactPhone',
  'bestNumberToReachYou',
];

const urlTextKeys = [
  'landingPageUrl',
];

const checkBoxFieldKeys = [
  'goalOfAd',
  'ifSomeInterpretationWasSelectedPleaseProvideAdditionalInformation',
  'ifUseArtisticInterpretationWasSelectedPleaseProvideAdditionalInformation',
];

const checkBoxFields = checkBoxFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'checkBoxField' },
}), {});
const dropdownFields = dropdownFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'dropdown' },
}), {});

const radioFields = radioFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'radioButton' },
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const textAreaFields = textAreaKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'TextareaWidget' },
}), {});

const phoneNumberTextFields = phoneNumberTextKeys
  .reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'phoneNumberTextField' } }), {});

const urlTextFields = urlTextKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'UrlTextWidget' },
}), {});

export const DisplayCreativeServiceCustomUISchema = {
  'ui:order': [
    'reachOutForReview',
    'clientContactFirstName',
    'clientContactLastName',
    'clientContactPhone',
    'clientContactEmail',
    'bestNumberToReachYou',
    'emailForCreativeApprovalNotifications',
    'creativeIsNeededDate',
    'goalOfAd',
    'levelOfCreativityOrArtisticDiscretion',
    'ifSomeInterpretationWasSelectedPleaseProvideAdditionalInformation',
    'ifUseArtisticInterpretationWasSelectedPleaseProvideAdditionalInformation',
    'typeOfDigitalOrOnlineAd',
    'siteLookalikeRetargeting',
    'targetedDisplayCampaign',
    'landingPageUrl',
    'headlineOrBenefitStatement',
    'contentOrOfferOrPromotion',
    'uploader_customCreativeCampaignOrderBrief',
    'uploader_customCreativeCampaignOrderMockup',
    'notesOrInstructions',
  ],

  clientContactEmail: {
    'ui:widget': 'EmailTextWidget',
    'ui:placeholder': t('pages.displayCreativeService.emailHelpContext'),
  },
  creativeIsNeededDate: {
    'ui:widget': DatePickerField,
  },

  uploader_customCreativeCampaignOrderBrief: {
    'ui:style': {
      marginTop: '16px',
    },
    'ui:widget': (props) => (
      <FileUploader
        {...props}
        uploaderType={ProductTypes.ORDER_BRIEF_ATTACH_FILE}
        totalAllowedFiles={1}
        imageTypes={['.jpeg', '.jpg', '.png']}
        videoTypes={[]}
        documentTypes={['.docx', '.doc', '.pdf']}
        supportingText=""
      />
    ),
  },
  uploader_customCreativeCampaignOrderMockup: {
    'ui:style': {
      marginTop: '16px',
    },
    'ui:widget': (props) => (
      <FileUploader
        {...props}
        uploaderType={ProductTypes.ORDER_MOCKUP_ATTACH_FILE}
        totalAllowedFiles={1}
        imageTypes={['.jpeg', '.jpg', '.png']}
        videoTypes={[]}
        documentTypes={['.docx', '.doc', '.pdf']}
        supportingText=""
      />
    ),
  },
  reachOutForReview: {
    'ui:widget': (props) => (
      <Dropdown {...props}
        errorMessage="Please select who should be reached out for review/corrections/approvals"
      />
    ),
  },

  ...textAreaFields,
  ...textFields,
  ...dropdownFields,
  ...radioFields,
  ...phoneNumberTextFields,
  ...urlTextFields,
  ...checkBoxFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DisplayCreativeServiceValidate = (formData: any, errors, uiSchema) => {
  if (['Client', 'Client & Sales Rep'].includes(formData?.reachOutForReview)) {
    if (formData?.reachOutForReview) {
      if (formData?.clientContactFirstName === '') {
        errors.clientContactFirstName.addError(t('pages.displayCreativeService.firstNameErrorMessage'));
        errors.addError(true);
      }
    }
    if (formData?.clientContactLastName === '') {
      errors.clientContactLastName.addError(t('pages.displayCreativeService.lastNameErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.reachOutForReview === 'Sales Rep') {
    if (formData?.emailForCreativeApprovalNotifications === '') {
      errors.emailForCreativeApprovalNotifications
        .addError(t('pages.displayCreativeService.emailForApprovalNotificationsErrorMessage'));
      errors.addError(true);
    }
  }
  if (formData?.clientContactEmail === '') {
    errors.clientContactEmail.addError(t('pages.videoCreativeService.clientContactEmailErrorMessage'));
    errors.addError(true);
  }
  if (formData?.clientContactEmail !== ''
      && !validateEmail(formData?.clientContactEmail)
      && formData?.reachOutForReview !== 'Sales Rep') {
    errors?.clientContactEmail?.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  return errors;
};
