import { Typography, Box, Grid } from '@mui/material';
import GridItem from 'components/grid/GridItem';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import { useParams } from 'react-router';
import { getProduct } from 'services/productsSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import OrderTracker from 'pages/orders/OrderTracker';
import { ProductTypes, getFiles } from 'services/fileUploaderSlice';
import { headingStyle } from '../Business/BusinessSection';
import { ActionItems } from '../common/ActionItems';
import { GeneralQuestionsSection } from '../generalQuestions/GeneralQuestionsSection';
import { displayAssets } from '../sites/SitesSection';

export default function SmmSection({ product, isEditable, showTracker }: any) {
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const { orderId } = useParams();
  const [smmData, setSmmData] = useState({} as any);
  const [existingAssets, setExistingAssets] = useState([]);

  const getAssets = (offeringId, offeringType) => {
    appDispatch(getFiles({
      websiteId: offeringId,
      uploaderType: ProductTypes.SOW_DOCUMENT,
      offeringType,
    })).then((response) => setExistingAssets(response.payload));
  };

  const getProductData = (orderItemId) => {
    appDispatch(getProduct({
      orderItemId,
    })).then((response) => setSmmData(response.payload));
  };

  useEffect(() => {
    if (product) {
      getProductData(product?.id);
      getAssets(product?.offeringId, product.pdOfferingType);
    }
  }, [product, orderId]);

  const offeringType = product.offeringType === 'ProductsData' ? product.pdOfferingType : product.offeringType;
  const status = product?.trackerStatus === 'Completed' ? 'Seo Live' : product?.trackerStatus;

  const showPhoneExtension = (value) => ` ext.${value}`;

  const isCustomSmm = product.pdOfferingType === 'SMMCustom';
  const showRepManagementSection = [2, 3].includes(smmData?.data?.variant) || isCustomSmm;

  const noCampaignGoals = (
    !smmData?.data?.brandAwareness
    && !smmData?.data?.activeDigitalPresence
    && !smmData?.data?.communityEngagement
    && !smmData?.data?.customerRetention
    && !smmData?.data?.other) && (
      <Typography fontWeight="bold">No Campaign Goals Selected</Typography>
  );

  const renderProductData = () => (
    <>
      {/* Details */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid black' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(
            Urls.SMMDetails,
            product.id,
          ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Details</Typography>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          {/* Campaign */}
          <Typography sx={{ fontSize: '18px', margin: '10px 0 20px' }}>Campaign</Typography>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Instagram or X</Typography>
                <Typography textTransform="capitalize">{smmData?.data?.campaign}</Typography>
              </Box>
            </Box>
            <Box />
          </GridItem>
          {/* Social Media Business Pages */}
          <Typography sx={{ fontSize: '18px', margin: '20px 0 20px' }}>Social Media Business Pages</Typography>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }} marginBottom={1}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Facebook Business Page</Typography>
                <Typography textTransform="capitalize">{smmData?.data?.existingFacebook}</Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Facebook Business Page url</Typography>
                <Typography>
                  {displayNotRequiredField('facebook business page url', smmData?.data?.facebookUrl)}
                </Typography>
              </Box>
            </Box>

            <Box>
              {(smmData?.data?.campaign === 'instagram')
                    && (
                    <>
                      <Box mb={2}>
                        <Typography sx={headingStyle} color="primary">Instagram Business Page</Typography>
                        <Typography textTransform="capitalize">{smmData?.data?.existingInstagram}</Typography>
                      </Box>
                      <Box mb={2}>
                        <Typography sx={headingStyle} color="primary">Instagram Business Page url</Typography>
                        <Typography>
                          {displayNotRequiredField('instagram business page url', smmData?.data?.instagramUrl)}
                        </Typography>
                      </Box>
                    </>
                    )}
              {(smmData?.data?.campaign === 'x')
                    && (
                    <>
                      <Box mb={2}>
                        <Typography sx={headingStyle} color="primary">X Business Page</Typography>
                        <Typography textTransform="capitalize">{smmData?.data?.existingX}</Typography>
                      </Box>
                      <Box mb={2}>
                        <Typography sx={headingStyle} color="primary">X Business Page url</Typography>
                        <Typography>
                          {displayNotRequiredField('X business page url', smmData?.data?.xUrl)}
                        </Typography>
                      </Box>
                    </>
                    )}
            </Box>
          </GridItem>
          {/* Reputation Management */}
          {showRepManagementSection && (
          <>
            <Typography sx={{
              fontSize: '18px',
              margin: '20px 0 20px',
            }}
            >
              Reputation Management
            </Typography>
            <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }} marginBottom={1}>
              <Box>
                {isCustomSmm && (
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Rep Management</Typography>
                  <Typography textTransform="capitalize">{smmData?.data?.isRepManagementNeeded}</Typography>
                </Box>
                )}
                { ((isCustomSmm && smmData?.data?.isRepManagementNeeded === 'yes') || !isCustomSmm)
                  && (
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">Gbp or Yelp Rep Management</Typography>
                    <Typography textTransform="uppercase">{smmData?.data?.reputationManagement}</Typography>
                  </Box>
                  )}
              </Box>
              <Box>
                { ((isCustomSmm && smmData?.data?.isRepManagementNeeded === 'yes') || !isCustomSmm)
                  && (
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">
                      {smmData?.data?.reputationManagement === 'gbp' ? 'Gbp Url' : 'Yelp Url'}
                    </Typography>
                    <Typography>
                      {smmData?.data?.reputationManagement === 'gbp' ? smmData?.data?.gbpUrl : smmData?.data?.yelpUrl}
                    </Typography>
                  </Box>
                  )}
              </Box>
            </GridItem>
          </>
          )}
          {/* Contact Info */}
          <Typography sx={{ fontSize: '18px', margin: '20px 0 20px' }}>Contact Info</Typography>
          <GridItem sizes={[6, 6]}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Main Contact for Smm Campaign</Typography>
                <Typography>{smmData?.data?.campaignContactName}</Typography>
                <Typography>{smmData?.data?.campaignContactEmail}</Typography>
                {smmData?.data?.campaignContactPhone && (
                  <Typography>
                    {`${smmData?.data?.campaignContactPhone}${smmData?.data?.campaignContactPhoneExtension
                      && showPhoneExtension(smmData?.data?.campaignContactPhoneExtension)}`}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">
                  Contact for Existing Profile Access
                </Typography>
                <Typography>{smmData?.data?.accessContactName}</Typography>
                <Typography>{smmData?.data?.accessContactEmail}</Typography>
                {smmData?.data?.accessContactPhone && (
                  <Typography>
                    {`${smmData?.data?.accessContactPhone}${smmData?.data?.accessContactPhoneExtension
                      && ` ext.${smmData.data.accessContactPhoneExtension}`}`}
                  </Typography>
                )}
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>

      {/* Solution Strategy */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid black' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => navigate.to(
            Urls.SMMSolutionStrategy,
            product.id,
          ))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Solution Strategy</Typography>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <GridItem sizes={[6, 6]}>
            <Box>
              {isCustomSmm && (
              <>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Custom Sow Signed</Typography>
                  <Typography>
                    <Typography textTransform="capitalize">{smmData?.data?.customSow}</Typography>
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Custom Sow uploaded</Typography>
                  {displayAssets(existingAssets, 'Uploaded File')}
                </Box>
              </>
              )}
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">SMM Campaign Goals</Typography>
                {noCampaignGoals}
                <Typography>{smmData?.data?.brandAwareness && 'Brand Awareness'}</Typography>
                <Typography>{smmData?.data?.activeDigitalPresence && 'Active Digital Presence'}</Typography>
                <Typography>{smmData?.data?.communityEngagement && 'Community Engagement & Interaction'}</Typography>
                <Typography>{smmData?.data?.customerRetention && 'Customer Retention'}</Typography>
                <Typography>{smmData?.data?.other && 'Other'}</Typography>
              </Box>
              {smmData?.data?.otherGoals && (
                <Box mb={2}>
                  <Typography sx={headingStyle} color="primary">Other Campaign Goals</Typography>
                  <Typography>{smmData?.data?.otherGoals}</Typography>
                </Box>
              )}
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Competitor Social Handles</Typography>
                <Typography>
                  {displayNotRequiredField('competitor social handles', smmData?.data?.competitorSocial)}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Sales/Promotions schedule</Typography>
                <Typography>
                  {displayNotRequiredField('Sales/Promotions schedule', smmData?.data?.clientSchedule)}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Topics or Hashtags to Focus On</Typography>
                <Typography>
                  {displayNotRequiredField('topics or hashtags to focus', smmData?.data?.topicsToFocus)}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Topics or Hashtags to Avoid</Typography>
                <Typography>
                  {displayNotRequiredField('topics or hashtags to avoid', smmData?.data?.topicsToAvoid)}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Additional Notes/Instructions</Typography>
                <Typography>
                  {displayNotRequiredField('additional notes/instructions', smmData?.data?.additionalNotes)}
                </Typography>
              </Box>
            </Box>
          </GridItem>
        </Box>
      </GridItem>
    </>
  );

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Status Tracker */}
      {showTracker && (
      <GridItem sizes={[12, 12]} marginBottom={8} sx={{
        padding: '40px 0 0', borderBottom: '1px solid #000000',
      }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () => {})}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Status</Typography>
        </Box>
        <Box>
          <OrderTracker
            key={product?.id}
            status={status}
            productName={product?.pdOfferingType}
            product={product}
          />
        </Box>
      </GridItem>
      )}

      {/* General Questions */}
      {GeneralQuestionsSection(offeringType, product.offeringId, product.id, navigate, isEditable) }

      {!product.isRebuild && renderProductData()}

      {/* Actions items */}
      {showTracker && ActionItems(false, product?.id)}
    </Grid>
  );
}
