import React, { useState } from 'react';
import { Grid, Typography, FormHelperText, FormControl, Box } from '@mui/material';
import ErrorText from 'components/errorText/ErrorText';
import { IQThemeV2 } from '@gannettdigital/shared-react-components';
import TimePicker from 'rc-time-picker';
import moment, { Moment } from 'moment';
import 'rc-time-picker/assets/index.css';
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export default function TimePickerField(props: any) {
  const [isTouched, setIsTouched] = useState(!!props.value);
  const hasError = props?.rawErrors?.length > 0 && isTouched;

  const handleChange = (newValue: Moment | null) => {
    setIsTouched(true);
    props.onChange(newValue ? newValue.format('HH:mm') : '');
  };

  return (
      <Grid item xs={12} mt={3}>
        <Typography
            fontWeight={600}
            mb={1}
            sx={{
              color: hasError
                  ? IQThemeV2.palette.error.main
                  : IQThemeV2.palette.text.primary,
            }}
        >
          {props.label}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
          <FormControl sx={{ width: '19%' }}>
            <TimePicker
                name={props.name}
                placeholder={'hh:mm am/pm'}
                onChange={handleChange}
                disabled={props.disabled || props.readonly}
                minuteStep={1}
                allowEmpty={false}
                showSecond={false}
                format="hh:mm A"
                value={props.value ? moment(props.value, ['HH:mm']) : null}
                use12Hours={true}
                className={hasError ? 'rc-time-picker-error' : ''}
                inputIcon={
                  <AccessTimeIcon
                      sx={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        pointerEvents: 'none',
                        zIndex: 2,
                      }}
                  />
                }
            />
            {/* Apply custom styling to the input field */}
            <style>
              {`
              .rc-time-picker-input {
                height: 43px;
                line-height: 50px;
                padding: 8px 16px;
                color: black;
                font-size: 16px;
                border: 1px solid black;
              }
            `}
            </style>
          </FormControl>
        </Box>
        {(props.placeholder && (props?.rawErrors?.length === 0 || !isTouched)) && (
            <FormHelperText error={false}>{props.placeholder}</FormHelperText>
        )}
        <ErrorText
            hasError={hasError}
            errorText={
              props.schema.customError
                  ? props.schema.customErrorMessage
                  : props.errorMessage || props?.rawErrors?.[0]
            }
        />
      </Grid>
  );
}
