import { Urls } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';

export const coreMenu:MenuType[] = [
  {
    label: 'Sales Agent',
    url: Urls.SalesRep,
    status: 'incomplete',
    offeringType: '',
  },
  {
    label: 'Business',
    status: 'incomplete',
    url: '',
    offeringType: '',
    subSteps: [
      {
        label: 'Search',
        url: Urls.BusinessSearch,
        status: 'incomplete',
      },
      {
        label: 'Detail',
        url: Urls.BusinessDetail,
        status: 'incomplete',
      },
      {
        label: 'Client',
        url: Urls.BusinessClient,
        status: 'incomplete',
      },
      {
        label: 'Campaign IDs',
        url: Urls.BusinessCampaign,
        status: 'incomplete',
      },
      {
        label: 'Locations',
        url: Urls.BusinessLocation,
        status: 'incomplete',
      },
    ],
  },
  {
    label: 'Overview',
    url: Urls.Overview,
    status: 'incomplete',
    offeringType: '',
  },
];
