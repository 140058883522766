import { useEffect, useState } from 'react';
import { IQButtonRadioContainer, IQFormTextArea, ValidationProvider } from '@gannettdigital/shared-react-components';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import EmailHostingRow from 'components/emailHosting/emailHostingRow';
import { S1 } from 'components/labels/S1';
import EmailHostingType from 'models/EmailHostingType';
import HostingEmailsType from 'models/HostingEmailsType';
import YesNoRadio from 'components/yesNo/YesNoRadio';
import schema from './EmailHosting.schema';

const ArchivingButtonStyled = styled('div')`
  .MuiRadio-root {
    margin-left: 8px;
  }

  .MuiFormLabel-root {
    line-height: 24px;
  }

  .MuiButtonBase-root {
    padding-left: 4px;
    padding-right: 20px;
  }

  .MuiToggleButton-root.Mui-selected {
    background-color: transparent;
  }

  .MuiToggleButton-root.Mui-selected:hover {
    background-color: transparent;
  }

  .MuiToggleButton-root:hover {
    background-color: transparent;
  }

  .MuiTouchRipple-root > * {
    display: none;
  }
`;

const MigrationButtonStyled = styled('div')`
  .MuiButtonBase-root {
    padding-left: 4px;
    padding-right: 20px;
    min-width: 100px;
  }

  .MuiToggleButtonGroup-root > button:nth-of-type(2) {
      min-width: 190px;
  }

  .MuiToggleButton-root.Mui-selected {
    background-color: transparent;
  }

  .MuiToggleButton-root.Mui-selected:hover {
    background-color: transparent;
  }

  .MuiToggleButton-root:hover {
    background-color: transparent;
  }

  .MuiTouchRipple-root > * {
    display: none;
  }
`;

const emptyEmailObj = {
  id: '',
  name: '',
  email: '',
};

interface Props {
  emailHosting: EmailHostingType
  emailHostingEmails: HostingEmailsType
  onSubmit: (data) => any
  onBack: () => any
}

const hostingOptions = [
  {
    value: 'new_hosting',
    label: 'New Hosting',
    field: 'hostingChoice',
  },
  {
    value: 'migrate_hosting',
    label: 'Migrate Hosting',
    field: 'hostingChoice',
  },
];

export default function EmailHostingForm(props: Props) {
  const { emailHosting } = props;
  const { emailHostingEmails } = props;

  const { t } = useTranslation();

  const initialState = emailHostingEmails.length === 0 ? [emptyEmailObj] : [...emailHostingEmails];
  const [emailData, setEmailData] = useState<HostingEmailsType>([]);

  useEffect(() => {
    setEmailData(initialState);
  }, [emailHostingEmails]);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      hostingChoice: emailHosting.hostingChoice || '',
      emailArchiving: emailHosting.emailArchiving || '',
      additionalNotes: emailHosting.additionalNotes || '',
    },
  });
  const {
    register, unregister, trigger, handleSubmit, watch, formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful,
    }, setValue,
  } = methods;

  const formValues = {
    hostingChoice: watch('hostingChoice') || '',
    emailArchiving: watch('emailArchiving') || '',
  };

  const onSubmit = (data) => {
    const content = {
      ...emailHosting, ...data, emailAccounts: emailData,
    };
    props.onSubmit(content);
  };

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <DefaultPageLayout
          disableContinue={!isValid || (!formValues.hostingChoice || !formValues.emailArchiving)}
          onBack={props.onBack}
          header={t('pages.addons.emailHosting.title')}
          onContinue={handleSubmit(onSubmit)}
        >
          <Grid container item xs={9}>
            <Grid item xs={12}>
              <S1 sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '24px' }}>
                {t('pages.addons.emailHosting.hostingOption')}
              </S1>
            </Grid>
            <Grid item xs={12}>
              <MigrationButtonStyled>
                <IQButtonRadioContainer
                  optionsList={hostingOptions}
                  name="hostingChoice"
                  required
                  inline
                />
              </MigrationButtonStyled>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '16px' }}>
              <ArchivingButtonStyled>
                <YesNoRadio
                  label={`${t('pages.addons.emailHosting.archivingRequired')} *`}
                  name="emailArchiving"
                />
              </ArchivingButtonStyled>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '38px' }}>
              <S1 sx={{ fontSize: '20px', fontWeight: 600 }}>
                {t('pages.addons.emailHosting.emailAccounts')}
              </S1>
            </Grid>
          </Grid>
          <EmailHostingRow
            setValue={setValue}
            register={register}
            unregister={unregister}
            emailData={emailData}
            setEmailData={setEmailData}
            trigger={trigger}
          />
          <Grid item xs={9.7} mt={5}>
            <IQFormTextArea
              id="additionalNotes"
              labelText="Additional Notes/Instructions"
              name="additionalNotes"
              rowCount={2}
              fullWidth
              fontLabelWeight="600"
              showError
            />
          </Grid>
        </DefaultPageLayout>
        {/* show the dialog if we changed the field and didn't submit the changes */}
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
