export const featureIconList = [
  'add-user.svg',
  'arrow-cross.svg',
  'attachment.svg',
  'badget.svg',
  'battery.svg',
  'bookmark.svg',
  'box.svg',
  'brand-listening.svg',
  'briefcase.svg',
  'browser.svg',
  'camera.svg',
  'cell-phone.svg',
  'chat.svg',
  'check.svg',
  'chest.svg',
  'clock-1.svg',
  'clock-2.svg',
  'cofig-file.svg',
  'content-optimization.svg',
  'controls.svg',
  'cursor.svg',
  'date.svg',
  'directional.svg',
  'display-1.svg',
  'document.svg',
  'documents.svg',
  'edit-1.svg',
  'engagement.svg',
  'expand.svg',
  'external.svg',
  'favorite.svg',
  'feature-crm.svg',
  'file.svg',
  'filter.svg',
  'gear.svg',
  'global.svg',
  'graphic.svg',
  'group.svg',
  'headset.svg',
  'high-volume.svg',
  'house.svg',
  'laptop.svg',
  'layout.svg',
  'library.svg',
  'light.svg',
  'line-arrow.svg',
  'link.svg',
  'list.svg',
  'location-map.svg',
  'location.svg',
  'lock.svg',
  'loupe.svg',
  'low-volume.svg',
  'mail.svg',
  'map.svg',
  'megaphone.svg',
  'microphone.svg',
  'mobile.svg',
  'mouse.svg',
  'multi-chat.svg',
  'mute.svg',
  'network.svg',
  'next.svg',
  'notebook.svg',
  'notification-1.svg',
  'open.svg',
  'paper-plane.svg',
  'picture.svg',
  'profile.svg',
  'review-management.svg',
  'search-1.svg',
  'seo-solution.svg',
  'share-alt.svg',
  'shield.svg',
  'shopping-cart-2.svg',
  'star.svg',
  'suitcase.svg',
  'target-shooting.svg',
  'target.svg',
  'technical-enhancements.svg',
  'time.svg',
  'touch.svg',
  'unlock.svg',
  'upload.svg',
  'user.svg',
  'video.svg',
  'visible-2.svg',
  'visible.svg',
  'watch.svg',
  'web-analysis.svg',
].map((icon, index) => ({ id: index, filename: icon }));
