export enum PhoneType {
  BUSINESS = 'Business',
  MOBILE = 'Mobile',
  HOME = 'Home',
  WORK = 'Work',
  FAX = 'Fax',
}

export enum ClientStatus {
  NOT_STARTED = 'notStarted',
  COMPLETE = 'complete',
  REVIEW = 'review',
}

export interface PhoneNumber {
  number: string
  type: PhoneType
  extension: string
}

export interface Client {
  contact: {
    id: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    cellphoneNumber: string,
    email: string,
    businessRoleId: string,
    clientFullName: string,
    phoneNumberType: string,
    sourceId: string,
  },
  contactPhoneNumberList: any [any],
  contactEmailList: any [string],
  status?: string,
}

export interface BusinessRole {
  id: string
  name: string
}
