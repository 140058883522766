import * as yup from 'yup';

const schema = {
  addressFieldsRequired: yup.string().required('Required — please select Yes or No.'),
  additionalCommentsRequired: yup.string().required('Required — please select either Yes or No.'),
  qualifyingQuestionOne: yup.string().optional(),
  qualifyingQuestionOneOptions: yup.string().optional(),
  qualifyingQuestionTwo: yup.string().optional(),
  qualifyingQuestionTwoOptions: yup.string().optional(),
  thankYouMessage: yup.string().optional(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
