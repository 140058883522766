import i18n from 'i18n/i18n';
import {RJSFSchema} from "@rjsf/utils";
import Dropdown from "pages/generic-product/details/custom-fields/Dropdown";

const {t} = i18n;

export const TimedEventsAndFlightsSchema: RJSFSchema | any = (data) => ({
  title: t('pages.socialAds.solutionStrategy.socialAdsCustom.timedEventsAndFlights'),
  type: 'object',
  required: [
    'clientFocusOnTimedEvent',
    'isThisFlightedCampaign',
    'useAutomaticPlacementOnAds'
  ],
  properties: {
    clientFocusOnTimedEvent: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.clientFocusOnTimedEvent'),
      default: data?.clientFocusOnTimedEvent || '',
      enum: [
        'Yes',
        'No',
      ],
    },
    isThisFlightedCampaign: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.isThisFlightedCampaign'),
      default: data?.isThisFlightedCampaign || '',
      enum: [
        'Yes',
        'No',
      ],
    },
    useAutomaticPlacementOnAds: {
      type: 'string',
      title: t('pages.socialAds.solutionStrategy.socialAdsCustom.useAutomaticPlacementOnAds'),
      default: data?.useAutomaticPlacementOnAds || '',
      enum: [
        'Yes',
        'No',
      ],
    },
  },
  dependencies: {
    clientFocusOnTimedEvent: {
      oneOf: [
        {
          properties: {
            clientFocusOnTimedEvent: {
              enum: ['Yes']
            },
            eventDetails: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.eventDetails'),
              default: data?.eventDetails || '',
            },
            eventStartDate: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.eventStartDate'),
              default: data?.eventStartDate || '',
            },
            eventEndDate: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.eventEndDate'),
              default: data?.eventEndDate || '',
            },
          }
        }
      ]
    },
    isThisFlightedCampaign: {
      oneOf: [
        {
          properties: {
            isThisFlightedCampaign: {
              enum: ['Yes']
            },
            datesForFlightedCampaign: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.datesForFlightedCampaign'),
            },
            campaignStartDate: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.campaignStartDate'),
              default: data?.campaignStartDate || '',
            },
            campaignEndDate: {
              type: 'string',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.campaignEndDate'),
              default: data?.campaignEndDate || '',
            },
          }
        }
      ]
    },
    useAutomaticPlacementOnAds: {
      oneOf: [
        {
          properties: {
            useAutomaticPlacementOnAds: {
              enum: ['No']
            },
            whereAdsToAppear: {
              type: 'array',
              title: t('pages.socialAds.solutionStrategy.socialAdsCustom.whereAdsToAppear'),
              default: data?.whereAdsToAppear || '',
              items: {
                type: 'string',
                enum: [
                  'Mobile News Feed',
                  'Desktop News Feed',
                  'Instagram',
                  'Facebook Audience Network',
                  'Marketplace (Must have Mobile News Feed Enabled)'
                ],
              },
              uniqueItems: true,
            }
          }
        }
      ]
    }
  }
});

const textFieldKeys = [
  'eventDetails',
]

const dateKeys = [
  'eventStartDate',
  'eventEndDate',
  'campaignStartDate',
  'campaignEndDate'
];

const dateFields = dateKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'datePickerField'}
}), {});

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: {'ui:widget': 'textInputField'},
}), {});


export const TimedEventsAndFlightsUISchema = {
  'ui:order': [
    'clientFocusOnTimedEvent', 'eventDetails', 'eventStartDate', 'eventEndDate', 'isThisFlightedCampaign',
    'datesForFlightedCampaign', 'campaignStartDate', 'campaignEndDate', 'useAutomaticPlacementOnAds', 'whereAdsToAppear'
  ],
  ...dateFields,
  ...textFields,
  datesForFlightedCampaign: {
    'ui:widget': 'textLabel'
  },
  whereAdsToAppear: {
    'ui:widget': 'checkBoxField'
  },
  clientFocusOnTimedEvent: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.socialAds.solutionStrategy.socialAdsCustom.clientFocusOnTimedEventErrorMessage')}/>
  },
  isThisFlightedCampaign: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.socialAds.solutionStrategy.socialAdsCustom.clientFocusOnTimedEventErrorMessage')}/>
  },
  useAutomaticPlacementOnAds: {
    'ui:widget': (props) => <Dropdown {...props}
                                      errorMessage={t('pages.socialAds.solutionStrategy.socialAdsCustom.clientFocusOnTimedEventErrorMessage')}/>
  },
};

export const TimedEventsAndFlightsCustomValidate = (formData: any, errors, uiSchema) => {
  if (new Date(formData?.eventEndDate) < new Date(formData?.eventStartDate)) {
    errors.eventEndDate.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.endDateErrorMessage'));
    errors.addError(true);
  }
  if (new Date(formData?.campaignEndDate) < new Date(formData?.campaignStartDate)) {
    errors.campaignEndDate.addError(t('pages.socialAds.socialAdsWebClicksSection.inputError.endDateErrorMessage'));
    errors.addError(true);
  }
  return errors;
};