export const yesNoOptions = [{
  label: 'Yes',
  value: 'yes',
},
{
  label: 'No',
  value: 'no',
},
];

export const smsWidgetColorOptions = [
  'Slate Grey',
  'Light Blue',
  'Yellow',
  'Navy Blue',
  'DaGrey',
  'Red',
  'Pink',
  'Mint',
];

export const socialMediaAccounts = [
  {
    name: 'Facebook',
    enabled: false,
    applyToAll: false,
  },
  {
    name: 'Twitter',
    enabled: false,
    applyToAll: false,
  },
  {
    name: 'Instagram',
    enabled: false,
    applyToAll: false,
  },
  {
    name: 'YouTube',
    enabled: false,
    applyToAll: false,
  },
];
