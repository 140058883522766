import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  IQFormInput, IQTheme, IQThemeV2,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { FormProvider, useForm } from 'react-hook-form';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import schema from './SocialSmartOptimization.schema';
import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import schemaValidate from 'components/schemaValidate';
import { useTranslation } from 'react-i18next';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

interface Props {
  product: any;
  onSubmit: (data) => void;
  onBack: () => void;
}

export default function SocialSmartOptimizationForm({product, onSubmit, onBack}: Props) {
  const {t} = useTranslation();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      selectCampaignGoalFocus: product?.selectCampaignGoalFocus || '',
      landingPageUrl: product?.landingPageUrl || '',
      landingPageIsNotLive: product?.landingPageIsNotLive === 'Yes',
      facebookPageUrl: product?.facebookPageUrl || '',
      facebookPageIsNotLive: product?.facebookPageIsNotLive === 'Yes',
      allowEmojis: product?.allowEmojis || '',
    },
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
  } = methods;

  const handleFormSubmit = (data) => {
    // Transform data before submission
    data.landingPageIsNotLive = data.landingPageIsNotLive ? 'Yes' : 'No';
    data.facebookPageIsNotLive = data.facebookPageIsNotLive ? 'Yes' : 'No';

    const formData = {
      socialSmartOptimization: data
    };

    // Submit the transformed data
    onSubmit(formData);
  };

  const selectCampaignGoalFocus = watch('selectCampaignGoalFocus');
  const landingPageIsNotLive = watch('landingPageIsNotLive');
  const facebookPageIsNotLive = watch('facebookPageIsNotLive');
  const allowEmojis = watch('allowEmojis');

  return (
      <DefaultPageLayout
          disableContinue={!isValid}
          onBack={onBack}
          header={t('pages.xmo.tactics.socialSmartOptimization.socialSmartOptimizationHeading')}
          onContinue={handleSubmit(handleFormSubmit)}
      >
        <ValidationProvider schema={schema}>
          <FormProvider {...methods}>
            <GridContainer fullWidth>
              <GridItem sizes={[12]}>
                <Typography
                    fontWeight={600}
                    sx={{color: IQThemeV2.palette.text.primary}}
                >
                  {t('pages.xmo.tactics.socialSmartOptimization.selectCampaignGoalFocusLabel')}
                  <RadioGroup
                      id='selectCampaignGoalFocus'
                      name='selectCampaignGoalFocus'
                      value={selectCampaignGoalFocus}
                      onChange={(e) => setValue('selectCampaignGoalFocus', e.target.value)}
                      row
                  >
                    {['Contacts', 'Contacts Ads - Dynamics Ads (Auto Only)'].map(opt => (
                        <FormControlLabel
                            key={opt}
                            control={<Radio value={opt}/>}
                            label={opt}
                            {...register('selectCampaignGoalFocus', {
                              validate: (value) => schemaValidate(value, 'selectCampaignGoalFocus', schema),
                            })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                    id='landingPageUrl'
                    name='landingPageUrl'
                    labelText={t('pages.xmo.tactics.socialSmartOptimization.landingPageUrlLabel')}
                    theme={IQTheme}
                    fontLabelWeight='600'
                    fullWidth
                    showError
                    schema={schema}
                    adornmentIcon={<InsertLinkIcon/>}
                    tooltipText={t('pages.xmo.tactics.socialSmartOptimization.landingPageUrlTooltipText')}
                    tooltipPlacement='top'
                    tooltipPaddingBottom={14}
                />
              </GridItem>
              <GridItem>
                <FormControlLabel sx={{marginTop: '-20px'}} control={
                  <Checkbox
                      id='landingPageIsNotLive'
                      name='landingPageIsNotLive'
                      checked={landingPageIsNotLive}
                      onChange={(e) => setValue('landingPageIsNotLive', e.target.checked)}
                      {...register('landingPageIsNotLive')}
                  />
                } label={t('pages.xmo.tactics.socialSmartOptimization.landingPageIsNotLiveLabel')}/>
              </GridItem>
              <GridItem sizes={[12]}>
                <IQFormInput
                    id='facebookPageUrl'
                    name='facebookPageUrl'
                    labelText={t('pages.xmo.tactics.socialSmartOptimization.facebookPageUrlLabel')}
                    theme={IQTheme}
                    fontLabelWeight='600'
                    fullWidth
                    showError
                    schema={schema}
                    adornmentIcon={<InsertLinkIcon/>}
                    tooltipText={t('pages.xmo.tactics.socialSmartOptimization.facebookPageUrlTooltipText')}
                    tooltipPlacement='top'
                    tooltipPaddingBottom={14}
                />
              </GridItem>
              <GridItem>
                <FormControlLabel sx={{marginTop: '-20px'}} control={
                  <Checkbox
                      id='facebookPageIsNotLive'
                      name='facebookPageIsNotLive'
                      checked={facebookPageIsNotLive}
                      onChange={(e) => setValue('facebookPageIsNotLive', e.target.checked)}
                      {...register('facebookPageIsNotLive')}
                  />
                }
                                  label={t('pages.xmo.tactics.socialSmartOptimization.facebookPageIsNotLiveLabel')}/>
              </GridItem>
              <GridItem>
                <Typography
                    fontWeight={600}
                    sx={{color: IQThemeV2.palette.text.primary}}
                >
                  {t('pages.xmo.tactics.socialSmartOptimization.allowEmojisLabel')}
                  <RadioGroup
                      id='allowEmojis'
                      name='allowEmojis'
                      value={allowEmojis}
                      onChange={(e) => setValue('allowEmojis', e.target.value)}
                      row
                  >
                    {['Yes', 'No'].map(opt => (
                        <FormControlLabel
                            key={opt}
                            control={<Radio value={opt}/>}
                            label={opt}
                            {...register('allowEmojis', {
                              validate: (value) => schemaValidate(value, 'allowEmojis', schema),
                            })}
                        />
                    ))}
                  </RadioGroup>
                </Typography>
              </GridItem>
            </GridContainer>
            <CoNavigationConfirm
                showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
            />
          </FormProvider>
        </ValidationProvider>
      </DefaultPageLayout>
  );
}
