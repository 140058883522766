import { Urls, MenuName } from 'navigation/Urls';
import { MenuType } from 'services/navigationSlice';
import { TopNavStatusEnum } from '../TopNavStatusEnum';

export const landingPageMenu: MenuType = ({
  label: 'Campaign Landing Page',
  url: '',
  status: 'incomplete',
  offeringType: 'LandingPage',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
    {
      label: 'Campaign',
      url: '',
      status: 'incomplete',
      subSteps: [
        {
          id: 0,
          url: Urls.OrderType,
          name: MenuName[Urls.OrderType],
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 1,
          url: Urls.Timeline,
          name: MenuName[Urls.Timeline],
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 2,
          url: Urls.Audience,
          name: MenuName[Urls.Audience],
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 3,
          url: Urls.Goals,
          name: MenuName[Urls.Goals],
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 4,
          url: Urls.Tracking,
          name: MenuName[Urls.Tracking],
          status: TopNavStatusEnum.NotStarted,
        },
      ],
    },
    {
      label: 'Domain',
      url: Urls.LandingDomain,
      status: 'incomplete',
    },
    {
      label: 'Content',
      url: '',
      status: 'incomplete',
      subSteps: [
        {
          id: 0,
          url: Urls.Info,
          name: MenuName[Urls.Info],
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 1,
          url: Urls.LandingContentWriting,
          name: MenuName[Urls.LandingContentWriting],
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 2,
          url: Urls.LandingExistingContent,
          name: MenuName[Urls.LandingExistingContent],
          status: TopNavStatusEnum.NotStarted,
        },
      ],
    },
    {
      label: 'Design',
      url: '',
      status: 'incomplete',
      subSteps: [
        {
          id: 0,
          url: Urls.Theme,
          name: MenuName[Urls.Theme],
          status: TopNavStatusEnum.NotStarted,
        },
        {
          id: 1,
          url: Urls.LandingStockAssets,
          name: MenuName[Urls.LandingStockAssets],
          status: TopNavStatusEnum.NotStarted,
        },
      ],
    },
  ],
});
