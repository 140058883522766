import {
  Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCurrentMaintenanceMode, selectMaintenanceModes, getMaintenanceModes } from 'services/maintenanceModeSlice';
import formatDateAndTime from 'shared/formatDateAndTime';

export default function MaintenanceMode() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMaintenanceModes(null));
  }, []);

  const mModes = useSelector(selectMaintenanceModes);
  // reverse array order to put newest status at top of heap
  const maintenaceModes = [...mModes].reverse();
  // getCurrentMaintenanceMode also reverses order of rows received, createdAt desc
  const maintenanceMode = getCurrentMaintenanceMode();

  return (
    <>
      <Typography variant="h2">Sites Maintenance Mode</Typography>
      <Typography fontSize="22px">
        Current status:&nbsp;
        <strong>{maintenanceMode}</strong>
      </Typography>
      <Grid container>
        <Grid item xs={12} marginTop={1}>
          <Link to="/admin/newStatus" style={{ marginRight: '10px' }}>
            <Button variant="contained">
              <Typography>New Status</Typography>
            </Button>
          </Link>
          <Link to="/admin/dashboard">
            <Button variant="contained">
              <Typography>Admin Dashboard</Typography>
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} marginTop={2}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ borderBottom: '3px solid lightgray' }}>
                  <TableCell><Typography fontWeight="bold">Status</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Changed By</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">On / At</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {maintenaceModes.map((entry, index) => (
                  <TableRow
                    key={entry.id}
                    sx={{
                      border: 0,
                      '&:nth-of-type(even)': { background: '#FAFAFA' },
                      '& .MuiTableCell-root': { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Typography fontWeight={index === 0 ? 'bold' : 'regular'}>
                        {entry.maintenanceMode === 0 ? 'OFF' : 'ON'}
                      </Typography>
                    </TableCell>
                    <TableCell><Typography>{entry.user}</Typography></TableCell>
                    <TableCell><Typography>{formatDateAndTime(entry.updatedAt)}</Typography></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
