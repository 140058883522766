import { StepperRootProps } from 'layouts/StepperLayout';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IQFormTextArea, ValidationProvider } from '@gannettdigital/shared-react-components';
import { Typography, Grid } from '@mui/material';
import CoFileUploader, { AllUploaders } from 'components/fileUploader/CoFileUploader';
import { ProductTypes } from 'services/fileUploaderSlice';
import { useParams } from 'react-router';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomWebsite, getCustomWebsite, putCustomWebsiteAdditionalFeatures } from 'services/customWebsiteSlice';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { Urls } from 'navigation/Urls';
import { selectActiveProductId } from 'services/navigationSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import schema from './AdditionalFeatures.schema';

export default function CustomWebsiteAdditionalFeatures(props: StepperRootProps) {
  const { t } = useTranslation();
  const { orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const customWebsite = useSelector(selectCustomWebsite);

  const fileUploaderProps : AllUploaders['fileUploaderOptions'] = {
    name: 'additionalFeaturesAssets',
    directory: ProductTypes.ADDITIONAL_FEATURES,
    label: t('pages.website.custom.additional.assets'),
    supportingText: t('pages.website.custom.additional.assetsDescription'),
    showRecommendedImageText: false,
    showCheckboxes: false,
    showComments: false,
    imageTypes: ['.png', '.jpg', '.jpeg', '.gif', '.CR2', '.DNG'],
    videoTypes: ['.mp4'],
    documentTypes: ['.pdf', '.docx'],
    maxFileSize: 256 * 1_000_000,
    uploaderType: ProductTypes.ADDITIONAL_FEATURES,
    withLabelError: false,
  };

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      additionalFeatures: '',
    },
  });

  const {
    getValues, handleSubmit, reset, formState: {
      isDirty, isSubmitting, isSubmitSuccessful, isValid,
    },
  } = methods;

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid]);

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsite(websiteId.toString()));
  }, [websiteId]);

  useEffect(() => {
    reset({
      additionalFeatures: customWebsite.additionalFeatures,
    });
  }, [customWebsite]);

  const onFormSubmit = () => {
    const data = getValues();
    const content = {
      additionalFeatures: data.additionalFeatures,
    };
    dispatch(putCustomWebsiteAdditionalFeatures({ orderItemId, content }));
    navigate.to(`${Urls.CustomWebsiteDevelopment}/${Urls.Content}`);
  };

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container marginTop={2}>
            <Grid item xs={6} marginBottom={5}>
              <Typography variant="h2">{t('pages.website.custom.additional.pageTitle')}</Typography>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6} marginBottom={3}>
              <IQFormTextArea
                id="additionalFeatures"
                name="additionalFeatures"
                labelText={t('pages.website.custom.additional.features')}
                fontLabelWeight="bold"
                rowCount={3}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={12}>
              <CoFileUploader
                schema={schema}
                productId={websiteId.toString()}
                productType={ProductTypes.ADDITIONAL_FEATURES}
                showFileUploader
                fileUploaderOptions={fileUploaderProps}
                offeringType={WebsiteOfferingTypes.SILVER}
                containerStyles={{
                  xs: 6,
                }}
              />
            </Grid>
          </Grid>
        </form>
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
