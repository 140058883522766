import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material';

export const drawerWidth = 224;

export default function OrderNavBar() {
  const theme = useTheme();

  return (

    <Drawer
      sx={{
        maxWidth: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          backgroundColor: theme.palette.common.black,
          width: drawerWidth,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <img
        src={`${process.env.PUBLIC_URL}/assets/logo.svg`}
        alt="Logo"
        style={{
          position: 'absolute',
          left: '3.14%',
          right: '3.14%',
          top: '3.12%',
          bottom: '-15.62%',
          maxHeight: '36px',
        }}
      />

    </Drawer>
  );
}
