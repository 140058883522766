import {
  Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteMarketCategory, selectMarketCategories, getMarketCategories } from 'services/adminSlice';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

export default function MarketCategories() {
  const marketCategories = useSelector(selectMarketCategories);
  const dispatch = useDispatch();
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    dispatch(getMarketCategories({}));
  }, []);

  const deleteCategory = (id) => {
    dispatch(deleteMarketCategory(id));
  };

  return (
    <>
      <Typography variant="h2">{`ReachSites Market Categories (${marketCategories.length})`}</Typography>
      <Grid container>
        <Grid item xs={12} marginTop={1}>
          <Link to="/admin/marketCategories/new" style={{ marginRight: '10px' }}>
            <Button variant="contained">
              <Typography>New Market Category</Typography>
            </Button>
          </Link>
          <Link to="/admin/dashboard">
            <Button variant="contained">
              <Typography>Admin Dashboard</Typography>
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} marginTop={5}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ borderBottom: '3px solid lightgray' }}>
                  <TableCell><Typography fontWeight="bold">Name (theme count)</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Notes</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Active</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Actions</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {marketCategories.map(category => (
                  <TableRow
                    key={category.id}
                    sx={{
                      border: 0,
                      '& .MuiTableCell-root': { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Typography>
                        {`${category.name} (${category.themeCount})`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {category.notes}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {`${category.active}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Link to={`${category.id}/edit`} style={{ marginRight: '10px' }}>
                        <Button variant="contained">
                          <Typography>Edit</Typography>
                        </Button>
                      </Link>
                      <Button variant="contained" color="error"
                        onClick={() => setItemToDelete(category)}
                      >
                        <Typography>Destroy</Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DeleteConfirmationModal
            open={!!itemToDelete}
            onClose={() => setItemToDelete(null)}
            onConfirm={() => deleteCategory(itemToDelete.id)}
            resourceName={itemToDelete?.name} resourceType="Market Category"
          />
        </Grid>
      </Grid>
    </>
  );
}
