import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import {
  IQButtonRadioContainer, IQFormLayout, IQFormSelect, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { Grid, Link, useTheme } from '@mui/material';
import { useEffect, useMemo } from 'react';
import YesNoRadio from 'components/yesNo/YesNoRadio';
import { Urls } from 'navigation/Urls';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomWebsite, putCustomWebsiteDetails, selectCustomWebsite } from 'services/customWebsiteSlice';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { selectActiveProductId } from 'services/navigationSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import ErrorText from 'components/errorText/ErrorText';
import { inputStyles } from 'pages/seo/details/DetailsPageForm';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import schema from './Details.schema';
import DetailsConstants from './DetailsConstants';

export default function CustomWebsiteDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigation = useNavigationHandler();
  const { orderId, orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const customWebsite = useSelector(selectCustomWebsite);
  const { budgetRanges, reasonOptions } = DetailsConstants();
  const theme = useTheme();

  // eslint-disable-next-line max-len
  const tiersUrl = 'https://order.localiq.com/assets/Website-Tier-Comparison-e03b81f2aff6b35d58e14cbf3d3eeea1d951e510d2c3760f974e1823c7e58688.pdf';

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      newWebsiteReason: '',
      otherReason: '',
      openToLocalIqProduct: '',
      budget: '',
    },
  });

  const {
    getValues, watch, reset, handleSubmit, register, formState: {
      isDirty, isSubmitting, isSubmitSuccessful, isValid, errors,
    },
  } = methods;

  const reason = useMemo(() => getValues('newWebsiteReason'), [watch('newWebsiteReason')]);
  const getRadioValue = (value) => (value ? 'yes' : 'no');
  const checkForOptions = (value) => (value === null ? true : reasonOptions.map(obj => obj.value).includes(value));

  const onSubmit = async () => {
    const data = getValues();
    const content = {
      newWebsiteReason: data.newWebsiteReason === 'other' ? data.otherReason : data.newWebsiteReason,
      openToLocalIqProduct: data.openToLocalIqProduct === 'yes',
      budget: data.budget,
    };
    dispatch(putCustomWebsiteDetails({ orderItemId, content }));
    navigation.to(Urls.CustomWebsiteDesign);
  };

  useEffect(() => {
    reset({
      newWebsiteReason: checkForOptions(customWebsite.newWebsiteReason) ? customWebsite.newWebsiteReason : 'other',
      otherReason: checkForOptions(customWebsite.newWebsiteReason) ? null : customWebsite.newWebsiteReason,
      openToLocalIqProduct: customWebsite.openToLocaliqProduct === null ? null
        : getRadioValue(customWebsite.openToLocaliqProduct),
      budget: customWebsite.budget,
    });
  }, [customWebsite]);

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsite(websiteId.toString()));
  }, [websiteId]);

  return (
    <DefaultPageLayout
      onContinue={handleSubmit(onSubmit)}
      disableContinue={!isValid}
      onBack={() => navigate(`/order/${orderId}/businesses/locations`)}
      header={t('pages.website.custom.details.title')}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container marginTop={3} sx={{ ...inputStyles }}>
            <Grid item xs={12} marginBottom={3}>
              <IQButtonRadioContainer
                singleColumn
                name="newWebsiteReason"
                optionsList={reasonOptions}
                label={t('pages.website.custom.details.reason')}
              />
            </Grid>
            {reason === 'other' && (
            <>
              <Grid item xs={6} marginBottom={3}>
                <NeFormTextArea
                  {...register('otherReason')}
                  id="otherReason"
                  name="otherReason"
                  labelText={t('pages.website.custom.details.otherReasonLabel')}
                  fullWidth
                  rowCount={3}
                  fontLabelWeight="600"
                />
                <ErrorText
                  hasError={!!errors.otherReason}
                  errorText={t('pages.website.custom.details.otherReasonRequired')}
                />
              </Grid>
              <Grid item xs={6} />
            </>
            )}
            <Grid item xs={6} marginBottom={3}>
              <IQFormLayout
                labelText={t('pages.website.custom.details.openToStandardWebsite')}
                fontLabelWeight="bold"
                tooltipText={(
                  <Trans
                    i18nKey="pages.website.custom.details.openToStandardWebsiteTooltip"
                    values={{ url: tiersUrl }}
                    components={{
                      a: <Link
                        style={{ textDecorationColor: theme.palette.common.white }}
                        color="inherit"
                        underline="always"
                        href={tiersUrl}
                        target="_blank" rel="noopener noreferrer"
                      />,
                    }}
                  /> as unknown as string
                )}
                tooltipPaddingBottom={30}
                tooltipPlacement="top"
                showError={false}
              >
                <YesNoRadio
                  label=""
                  name="openToLocalIqProduct"
                />
              </IQFormLayout>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6} marginBottom={1}>
              <IQFormSelect
                name="budget"
                items={budgetRanges}
                labelText={t('pages.website.custom.details.budgetRange')}
                fontLabelWeight="bold"
                placeholder={t('pages.website.custom.details.budgetRangePlaceholder')}
                sx={{ '&': { backgroundColor: 'white !important' } }}
                fullWidth
              />
            </Grid>
          </Grid>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
