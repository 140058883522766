import { useEffect, useState } from 'react';
import { locationsTypes } from 'layouts/StepperLayout';
import standardSchema from './LocationContact.schema';
import premiumListingSchema from './PremiumListingsContact.schema';
import healthcareSchema from './PlHealthcareContact.schema';
import LocationContact from './LocationContact';
import { phoneTypes, socialMediaAccounts, socialMediaAccountsPL } from './LocationContact.data';

const baseDefaultValues = {
  locationWebsite: '',
  locationWebsiteSameForAll: false,
  locationEmail: '',
  locationEmailSameForAll: false,
  phoneNumbers: [{ phoneNumber: '', phoneType: phoneTypes[0].value, ext: '' }],
  accounts: socialMediaAccounts,
};

const PlDefaultValues = {
  locationWebsite: '',
  locationWebsiteSameForAll: false,
  locationEmail: '',
  locationEmailSameForAll: false,
  phoneNumbers: [{ phoneNumber: '', phoneType: phoneTypes[0].value, ext: '' }],
  accounts: socialMediaAccountsPL,
  facebookYesNo: 'no',
  googleYesNo: 'no',
  googleListingsUrl: '',
  facebookListingsUrl: '',
};

const HcDefaultValues = {
  locationWebsite: null,
  locationEmail: '',
  phoneNumbers: [{ phoneNumber: '', phoneType: phoneTypes[0].value, ext: '' }],
  accounts: socialMediaAccountsPL,
  facebookYesNo: 'no',
  googleYesNo: 'no',
  googleListingsUrl: '',
  facebookListingsUrl: '',
};

export default function LocationContactPage(props: any) {
  const { locationsType } = props;
  const [schema, setSchema] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (locationsType === locationsTypes.STANDARD) {
      setDefaultValues(baseDefaultValues);
      setSchema(standardSchema);
    }

    if (locationsType === locationsTypes.PREMIUM_LISTINGS) {
      setDefaultValues(PlDefaultValues);
      setSchema(premiumListingSchema);
    }

    if (locationsType === locationsTypes.HEALTHCARE) {
      setDefaultValues(HcDefaultValues);
      setSchema(healthcareSchema);
    }
  }, [locationsType]);

  useEffect(() => {
    setIsReady(schema && defaultValues);
  }, [schema, defaultValues]);

  return isReady && (
    <LocationContact
      {...props}
      type={locationsType}
      schema={schema}
      defaultValues={defaultValues}
    />
  );
}
