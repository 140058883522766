import { t } from 'i18next';
import * as yup from 'yup';

const yupSchema = {
  items: yup.array().of(yup.object({
    id: yup.string().required(),
    label: yup.string().required(),
    subDescription: yup.string(),
    checked: yup.boolean(),
    disabled: yup.boolean(),
  })).min(1, t('pages.website.custom.designItems.atLeastOneItem')),
};

export default {
  yupValidations: yupSchema,
  customValidations: {},
};
