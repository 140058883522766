import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigationHandler } from 'hooks/useNavigationHandler';
import {
  clearExpandableMenuCompleted, getAddons,
  getExpandableMenu, getMasterAddonDetails,
  putExpandableMenu, selectAddons,
  selectExpandableMenu,
  selectExpandableMenuCompleted,
} from 'services/addons/addonsSlice';
import { Urls } from 'navigation/Urls';
import ContentLoader from 'components/contentLoader/ContentLoader';
import ExpandableMenuType from 'models/ExpandableMenuType';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import ExpandableMenuForm from './ExpandableMenuForm';

export type ExpandableMenuFormData = Pick<ExpandableMenuType, 'includeImages' | 'menuRequirements' | 'additionalNotes'>;

export default function ExpandableMenu() {
  const { orderId, orderItemId } = useParams();

  const dispatch = useDispatch();
  const addons = useSelector(selectAddons);
  const expandableMenu = useSelector(selectExpandableMenu);
  const pageCompleted = useSelector(selectExpandableMenuCompleted);
  const topNav = useTopnavMenu(Urls.ExpandableMenus);

  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);

  const navigate = useNavigationHandler();

  const onSubmit = (data :ExpandableMenuFormData | any) => {
    const temporalClone = { ...data };
    delete temporalClone.additionalNotesInstructions;
    dispatch(putExpandableMenu({ orderId, data: temporalClone }));
  };

  const onBack = () => {
    navigate.to(topNav.previousStep());
  };

  useEffect(() => {
    if (orderId) {
      dispatch(getExpandableMenu(orderId));
    }
  }, [orderId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (pageCompleted) {
      dispatch(clearExpandableMenuCompleted());
      if (isRedirectedFromOverviewPage) return navigate.to(Urls.Overview);
      navigate.to(topNav.nextStep());
    }
  }, [pageCompleted]);

  useEffect(() => {
    if (!addons?.content?.id) dispatch(getAddons(orderId));
    if (orderItemId) dispatch(getMasterAddonDetails(orderItemId));
  }, [addons, orderItemId]);

  return (
    <ContentLoader status={expandableMenu.status} message={expandableMenu.message}>
      <ExpandableMenuForm onBack={onBack} onSubmit={onSubmit} expandableMenu={expandableMenu.content} />
    </ContentLoader>
  );
}
