import i18n from 'i18n/i18n';

import {
  TrackingSchema,
  TrackingUISchema,
  TrackingCustomValidate
} from 'pages/generic-product/details/product-schema/aus-product-schema/TrackingSchema';
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/NotesAndInstructionsSchema";
import {
  SitePageCustomValidationSchema,
  SitePageSchema,
  SitePageUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/SitePageSchema";

const {t} = i18n;

export const AusSitePageSchema = (data) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.ausProduct.tracking.title'),
      schema: TrackingSchema(data),
      uiSchema: TrackingUISchema,
      customValidate: TrackingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.site.title'),
      schema: SitePageSchema(data),
      uiSchema: SitePageUISchema,
      customValidate: SitePageCustomValidationSchema,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ]

  return schema;
}

export const AusSitePageUISchema = (data) => ({
  'ui:order': [
    ...TrackingUISchema['ui:order'],
    ...SitePageUISchema['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AusSitePageCustomValidate = (formData: any, errors: any, uiSchema: any) => errors;