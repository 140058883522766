import * as yup from 'yup';

const schema = {
  uniqueTaxSituationsYesNo: yup.string(),
  uniqueTaxSituations: yup.string(),
  taxInformation: yup.string(),
  onlineGiftCardsForSale: yup.string(),
  giftCardsNotes: yup.string(),
  additionalNotesStoreDetails: yup.string(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
