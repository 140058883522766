import {
  Box, Grid, Link, Typography,
} from '@mui/material';
import GridItem from 'components/grid/GridItem';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'services/redux/store';
import { getDomains } from 'services/domainsSlice';
import { getWebsite } from 'services/websitesSlice';
import { displayNotRequiredField, editButton } from 'pages/overview/Overview';
import {
  ProductTypes, getFiles, getGettyAssets, setFiles,
} from 'services/fileUploaderSlice';
import { useParams } from 'react-router';
import { getProduct } from 'services/productsSlice';
import { getContentTones } from 'services/contentTonesSlice';
import { getSitePages } from 'services/sitePagesSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import OrderTracker from 'pages/orders/OrderTracker';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrderFlow, selectOrdersContent } from 'services/ordersSlice';
import { OrderFlow } from 'shared/constants';
import { getThemeById } from 'services/themesSlice';
import { headingStyle } from '../Business/BusinessSection';
import { GeneralQuestionsSection } from '../generalQuestions/GeneralQuestionsSection';
import { renderContent } from './ContentSection';
import { renderProductsSection } from './ProductsSection';
import { renderStorePoliciesSection } from './StorePoliciesSection';
import { ActionItems } from '../common/ActionItems';

export const displayAssetDetails = (asset) => {
  const {
    notes, hero, featured, callToAction, other,
  } = asset;

  return (
    <>
      {hero && <Typography>Category: Hero</Typography>}
      {featured && <Typography>Category: Featured</Typography>}
      {callToAction && <Typography>Category: Call to Action</Typography>}
      {other && <Typography>Category: Other</Typography>}
      {(!hero && !featured && !callToAction && !other)
        && <Typography fontWeight="bold">No category selected</Typography>}
      {notes
        ? <Typography>{`Comments: ${notes}`}</Typography>
        : <Typography fontWeight="bold">No comments provided</Typography>}
    </>
  );
};

export const displayAssets = (assets, assetName) => {
  if (assets?.length) {
    return assets.map((asset, index) => (
      <Box key={asset.url} mb={2}>
        <Link href={asset.url} target="_blank">
          <Typography>{asset?.fileName ? asset.fileName : `${assetName} Asset ${index + 1}`}</Typography>
        </Link>
        {assetName !== 'Uploaded File' ? displayAssetDetails(asset) : null}
      </Box>
    ));
  }

  return <Typography fontWeight="bold">{`No ${assetName} Assets`}</Typography>;
};

export default function SitesSection({
  product, isEditable, showTracker, openBusinessAndSalesAgent,
}: any) {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const navigate = useNavigationHandler();
  const orderFlow = useSelector(selectOrderFlow);
  const orderData = useSelector(selectOrdersContent);
  const [isEcomm, setIsEcomm] = useState(false);
  const [website, setWebsite] = useState({} as any);
  const [ecommerce, setEcommerce] = useState({} as any);
  const [domain, setDomain] = useState({} as any);
  const [stockAssets, setStockAssets] = useState([] as any);
  const [existingAssets, setExistingAssets] = useState([] as any);
  const [existingContent, setExistingContent] = useState([] as any);
  const [productCatalogAssets, setProductCatalogAssets] = useState([] as any);
  const [returnsRefunds, setReturnsRefunds] = useState([] as any);
  const [contentTones, setContentTones] = useState([]);
  const [sitePages, setSitePages] = useState([]);
  const [themeSelection, setThemeSelection] = useState('');

  const offeringType = product.offeringType === 'ProductsData' ? product.pdOfferingType : product.offeringType;
  const domainUrl = offeringType === 'EcommerceMicrosite' ? Urls.Domain : Urls.Domains;
  const assetsUrl = offeringType === 'EcommerceMicrosite' ? Urls.Assets : Urls.StockAssets;
  const isPunchlist = (orderFlow === OrderFlow.PUNCHLIST || orderData.orderFlow === OrderFlow.PUNCHLIST);

  const getDomain = () => {
    appDispatch(getDomains({ websiteId: product?.offeringId, offeringType }))
      .then((response) => setDomain(response.payload));
  };

  const getWebsiteDetails = () => {
    appDispatch(getWebsite({ websiteId: product?.offeringId, offeringType }))
      .then((response) => setWebsite(response.payload));
  };

  const getProductDetails = () => {
    appDispatch(getProduct({ orderItemId: product?.id }))
      .then((response) => setEcommerce(response.payload));
  };

  const getStockAssets = () => {
    appDispatch(getGettyAssets({ orderId, orderItemId: product?.id, productType: ProductTypes.EXISTING_ASSETS }))
      .then((response) => setStockAssets(response.payload));
  };

  const getExistingAssets = async () => {
    await appDispatch(getFiles({
      websiteId: product?.offeringId,
      uploaderType: ProductTypes.EXISTING_ASSETS,
      offeringType,
    })).then((response) => setExistingAssets(response.payload));

    await appDispatch(getFiles({
      websiteId: product?.offeringId,
      uploaderType: ProductTypes.EXISTING_CONTENT,
      offeringType,
    })).then((response) => setExistingContent(response.payload));

    if (isEcomm) {
      await appDispatch(getFiles({
        websiteId: product?.offeringId,
        uploaderType: ProductTypes.PRODUCT_CATALOG,
        offeringType,
      })).then((response) => setProductCatalogAssets(response.payload));

      await appDispatch(getFiles({
        websiteId: product?.offeringId,
        uploaderType: ProductTypes.RETURNS_REFUNDS,
        offeringType,
      })).then((response) => setReturnsRefunds(response.payload));
    }
  };

  const getContentTonesData = () => {
    appDispatch(getContentTones({ websiteId: product?.offeringId, offeringType }))
      .then((response) => setContentTones(response.payload));
  };

  const getPages = () => {
    appDispatch(getSitePages({ websiteId: product?.offeringId, offeringType }))
      .then((response) => setSitePages(response.payload));
  };

  useEffect(() => {
    if (product) setIsEcomm(product.offeringType === 'ProductsData');
  }, [product]);

  useEffect(() => {
    if (!isEcomm) {
      getWebsiteDetails();
      getDomain();
      getStockAssets();
      getExistingAssets();
      getContentTonesData();
      getPages();
    } else {
      getProductDetails();
      getStockAssets();
      getExistingAssets();
    }
  }, [isEcomm]);

  useEffect(() => {
    if (isPunchlist && website?.themeId) {
      appDispatch(getThemeById(website.themeId)).then((res) => setThemeSelection(res.payload.title));
    }
  }, [isPunchlist, website]);

  const returnDomainSection = (domain) => {
    const returnDomainType = (domain) => {
      if (!domain?.buyDomain && !domain?.existingDomainUrl) return 'Skip';
      return (domain.buyDomain && 'Purchase') || (domain.existingDomainUrl && 'Existing');
    };

    return (
      <GridItem sizes={[12, 6, 6]} marginBottom={3} sx={{
        padding: '20px 0 0', borderTop: '1px solid #000000',
      }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {editButton(isEditable, () =>
            navigate.to(domainUrl, product.id))}
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Domain</Typography>
        </Box>
        <Box>
          <Box mb={2}>
            <Typography sx={headingStyle} color="primary">Domain Option</Typography>
            <Typography>{returnDomainType(!isEcomm ? domain : ecommerce.data)}</Typography>
          </Box>
          {domain?.existingDomainUrl && (
            <>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Existing URL</Typography>
                {displayNotRequiredField('domain', domain?.existingDomainUrl)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Domain Registrar</Typography>
                {displayNotRequiredField('domain', domain?.domainRegistrar)}
              </Box>
            </>
          )}
        </Box>
        <Box>
          {domain?.buyDomain && (
            <Box mb={2}>
              <Typography sx={headingStyle} color="primary">Purchase URL</Typography>
              {displayNotRequiredField('domain', domain?.buyDomain)}
            </Box>
          )}
          {domain?.existingDomainUrl && (
            <>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Registrar Username</Typography>
                {displayNotRequiredField('domain', domain?.registrarUsername)}
              </Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Domain Email</Typography>
                {displayNotRequiredField('domain email', domain?.domainEmail)}
              </Box>
            </>
          )}
        </Box>
      </GridItem>
    );
  };

  const renderAdditionalFeatures = (feature, name) => {
    if (!feature) return <Typography fontWeight="bold">{`No ${name}`}</Typography>;
    return <Typography>{`${name} Selected`}</Typography>;
  };

  const renderProductData = () => (
    <>
      {/* Domain */}
      {returnDomainSection(!isEcomm ? domain : ecommerce.data)}

      {/* Design */}
      <GridItem sizes={[12, 12]} marginBottom={3} sx={{ padding: '20px 0 0', borderTop: '1px solid #000000' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Design</Typography>
        <Box>
          {/* Theme Design */}
          {(isPunchlist && themeSelection) && (
            <>
              <Box sx={{ display: 'flex', marginBottom: '10px' }}>
                {editButton(isEditable, () => navigate.to(
                  Urls.Theme,
                  product.id,
                ))}
                <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Theme</Typography>
              </Box>
              <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
                <Box>
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">Theme</Typography>
                    <Typography>{themeSelection}</Typography>
                  </Box>
                </Box>
                <Box />
              </GridItem>
            </>
          )}

          {/* Existing Assets */}
          <Box sx={{ display: 'flex', margin: `${isPunchlist ? '10px 0' : '0 0 10px'}` }}>
            {editButton(isEditable, async () => {
              await dispatch(setFiles([]));
              navigate.to(assetsUrl, product.id);
            })}
            <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Existing Assets</Typography>
          </Box>
          <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Stock Assets</Typography>
                {displayAssets(stockAssets, 'Stock')}
              </Box>
            </Box>
            <Box>
              <Box mb={2}>
                <Typography sx={headingStyle} color="primary">Existing Assets</Typography>
                {displayAssets(existingAssets, 'Existing')}
              </Box>
            </Box>
          </GridItem>

          {!isEcomm ? (
            <>
              {/* Examples */}
              <Box sx={{ display: 'flex', margin: '10px 0' }}>
                {editButton(isEditable, () => navigate.to(
                  Urls.Examples,
                  product.id,
                ))}
                <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Examples</Typography>
              </Box>
              <GridItem sizes={[6, 6]} sx={{ borderBottom: '1px solid #000000' }}>
                <Box>
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">Examples / Inspirations</Typography>
                    {displayNotRequiredField('examples / inspirations', website?.examples)}
                  </Box>
                </Box>
                <Box />
              </GridItem>

              {/* Additional Features */}
              <Box sx={{ display: 'flex', margin: '10px 0' }}>
                {editButton(isEditable, () => navigate.to(
                  Urls.AdditionalFeaturesDesign,
                  product.id,
                ))}
                <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Additional Features</Typography>
              </Box>
              <GridItem sizes={[6, 6]}>
                <Box>
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">Additional Features</Typography>
                    {renderAdditionalFeatures(website?.parallax, 'Parallax')}
                    {renderAdditionalFeatures(website?.animation, 'Animation')}
                    {renderAdditionalFeatures(website?.photoGallery, 'Photo Gallery')}
                  </Box>
                </Box>
                <Box />
              </GridItem>
            </>
          ) : (
            <>
              {/* Branding */}
              <Box sx={{ display: 'flex', margin: '10px 0' }}>
                {editButton(isEditable, () => navigate.to(
                  Urls.Branding,
                  product.id,
                ))}
                <Typography sx={{ fontSize: '18px', margin: '10px 0' }}>Branding</Typography>
              </Box>
              <GridItem sizes={[6, 6]}>
                <Box>
                  <Box mb={2} sx={{ textTransform: 'capitalize' }}>
                    <Typography sx={headingStyle} color="primary">Template</Typography>
                    {displayNotRequiredField('theme title', ecommerce?.data?.selectTemplate)}
                  </Box>
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">Branding Notes</Typography>
                    {displayNotRequiredField('branding notes', ecommerce?.data?.brandingNotes)}
                  </Box>
                </Box>
                <Box>
                  <Box mb={2}>
                    <Typography sx={headingStyle} color="primary">Design Notes</Typography>
                    {displayNotRequiredField('design notes', ecommerce?.data?.designNotes)}
                  </Box>
                </Box>
              </GridItem>
            </>
          )}
        </Box>
      </GridItem>

      {/* Content */}
      {!isEcomm && renderContent(website, existingContent, contentTones, sitePages, product, navigate, isEditable)}

      {/* Products */}
      {isEcomm && (
        renderProductsSection(ecommerce.data, productCatalogAssets, product, navigate, offeringType, isEditable))}
      {isEcomm && (renderStorePoliciesSection(
        ecommerce.data,
        stockAssets,
        returnsRefunds,
        product,
        navigate,
        offeringType,
        isEditable,
      ))}
    </>
  );

  return (
    <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
      {/* Status Tracker */}
      {showTracker && (
        <GridItem sizes={[12, 12]} marginBottom={8} sx={{ padding: '40px 0 0', borderBottom: '1px solid #000000' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {editButton(isEditable, () => {})}
            <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Status</Typography>
          </Box>
          <Box>
            <OrderTracker
              key={product?.id}
              status={product?.trackerStatus}
              product={product}
              productName={product?.offeringType !== 'ProductsData' ? product.offeringType : product?.pdOfferingType}
            />
          </Box>
        </GridItem>
      )}

      {/* General Questions */}
      {GeneralQuestionsSection(
        product.offeringType === 'ProductsData' ? product.pdOfferingType : product.offeringType,
        product.offeringId,
        product.id,
        navigate,
        isEditable,
      )}

      {!product.isRebuild && renderProductData()}

      {/* Actions items */}
      {showTracker && ActionItems(true, product?.id, false, openBusinessAndSalesAgent)}
    </Grid>
  );
}
