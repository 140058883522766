import { Typography } from '@mui/material';
import 'styles/website_fonts.css';

export interface H4Props {
  sx?: object;
  children: any;
}

export function H4({ children, sx }: H4Props) {
  return (
    <Typography variant="h4" gutterBottom component="div"
      sx={{ color: 'common.black', ...sx }}
    >
      {children}
    </Typography>
  );
}

export default H4;
