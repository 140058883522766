import { useEffect } from 'react';
import {
  IQFormInput,
  IQInputEmail,
  IQTheme,
  ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { useTranslation } from 'react-i18next';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { useTopnavMenu } from 'hooks/useTopnavMenu';
import { Urls } from 'navigation/Urls';
import { postDomain, putDomain } from 'services/domainsSlice';
import { useSaveAndClose } from 'context/SaveAndCloseContext';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import { H5 } from '../labels/H5';
import schema from './DomainsExisting.schema';
import CoContinueFooter from '../continueFooter/CoContinueFooter';
import DomainsType from '../../models/DomainsType';
import { FormContainerStyled } from './FormContainerStyled';
import CoNavigationConfirm from '../navigation/CoNavigationConfirm';

export default function CoDomainsExisting({ defaultValue } : { defaultValue?: DomainsType }) {
  const { t } = useTranslation();

  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);

  const dispatch = useDispatch();

  const navigate = useNavigationHandler();
  const topnav = useTopnavMenu(Urls.Domains);

  // react-hook-forms
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      existingDomainUrl: defaultValue?.existingDomainUrl || '',
      domainRegistrar: defaultValue?.domainRegistrar || '',
      domainEmail: defaultValue?.domainEmail || '',
      registrarUsername: defaultValue?.registrarUsername || '',
      registrarPassword: defaultValue?.registrarPassword || '',
    },
  });
  const {
    handleSubmit, formState, formState: {
      isValid, isDirty, isSubmitting, isSubmitSuccessful,
    }, setValue,
  } = methods;

  const saveAndClose = useSaveAndClose();
  saveAndClose.setFormValid(isValid);

  useEffect(() => {
    setValue('existingDomainUrl', defaultValue?.existingDomainUrl);
    setValue('domainRegistrar', defaultValue?.domainRegistrar);
    setValue('domainEmail', defaultValue?.domainEmail);
    setValue('registrarUsername', defaultValue?.registrarUsername);
    setValue('registrarPassword', defaultValue?.registrarPassword);
  }, []);

  const onSubmit = (data) => {
    if (defaultValue.id) dispatch(putDomain({ offeringType, content: { id: defaultValue.id, websiteId, ...data } }));
    else dispatch(postDomain({ offeringType, websiteId, content: data }));
  };

  const onBack = () => {
    navigate.to(topnav.previousStep());
  };

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContainerStyled>
            <Grid container justifyContent="left" flexDirection="column">
              <H5>{t('components.domains.provideDomainDetails')}</H5>
              <Grid item xs={6}>
                <IQFormInput
                  id="existingDomainUrl"
                  theme={IQTheme}
                  labelText={t('components.domains.existingDomainUrl')}
                  fontLabelWeight="600"
                  name="existingDomainUrl"
                  fullWidth
                  customValidation={schema.yupValidations.existingDomainUrl}
                  adornmentIcon={<InsertLinkIcon />}
                />
              </Grid>
              <Grid item xs={6}>
                <IQFormInput
                  labelText={t('components.domains.domainRegistrar')}
                  fontLabelWeight="600"
                  id="domainRegistrar"
                  name="domainRegistrar"
                  theme={IQTheme}
                  schema={schema}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <IQFormInput
                  labelText={t('components.domains.domainUsername')}
                  fontLabelWeight="600"
                  id="registrarUsername"
                  name="registrarUsername"
                  theme={IQTheme}
                  schema={schema}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <IQFormInput
                  labelText={t('components.domains.domainPassword')}
                  fontLabelWeight="600"
                  id="registrarPassword"
                  name="registrarPassword"
                  obscureValue
                  theme={IQTheme}
                  schema={schema}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <IQInputEmail
                  labelText={`${t('components.domains.domainEmail')}`}
                  fontLabelWeight="600"
                  theme={IQTheme}
                  schema={schema}
                  label={t('components.domains.domainEmail')}
                  field="domainEmail"
                  fullWidth
                  adornmentIcon={<MailOutlineIcon />}
                />
              </Grid>
            </Grid>
          </FormContainerStyled>
          <CoContinueFooter onBack={onBack} onContinue={handleSubmit(onSubmit)} continueDisabled={!formState.isValid} />
        </form>
      </FormProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </ValidationProvider>
  );
}
