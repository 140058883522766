import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import GridItem from 'components/grid/GridItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSalesAgentTypes, getSalesAgents, selectAgentTypes, selectAgents,
} from 'services/salesAgentSlice';
import { editButton } from 'pages/overview/Overview';
import { useParams } from 'react-router';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { Urls } from 'navigation/Urls';
import { headingStyle } from '../Business/BusinessSection';

const renderSalesAgent = (isEditable, agent, index, type, navigate, url) => (
  <Grid container sx={{ borderTop: '1px solid #716F6F' }}>
    <GridItem sizes={[12, 6, 6]} marginBottom={3} sx={{ padding: '20px 0 0' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {editButton(isEditable, () => navigate.to(url))}
        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{`Sales Agent ${index + 1}`}</Typography>
      </Box>
      <Box sx={{ marginTop: '15px' }}>
        <Box mb={2}>
          <Typography sx={headingStyle} color="primary">Agent Name</Typography>
          <Typography>{agent.salesAgentName}</Typography>
        </Box>
        <Box mb={2}>
          <Typography sx={headingStyle} color="primary">Phone Number</Typography>
          <Typography>{agent.salesAgentPhoneNumber}</Typography>
        </Box>
        <Box>
          <Typography sx={headingStyle} color="primary">Email</Typography>
          <Typography>{agent.salesAgentEmail}</Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: '15px' }}>
        <Box mb={2}>
          <Typography sx={headingStyle} color="primary">Agent Type</Typography>
          <Typography>{type}</Typography>
        </Box>
        <Box>
          <Typography sx={headingStyle} color="primary">Submission date</Typography>
          <Typography>{new Date(agent?.createdAt)?.toLocaleDateString()}</Typography>
        </Box>
      </Box>
    </GridItem>
  </Grid>
);

export default function SalesAgentSection({ isEditable = true }: any) {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigationHandler();
  const salesAgents = useSelector(selectAgents);
  const agentTypes = useSelector(selectAgentTypes);

  useEffect(() => {
    dispatch(getSalesAgentTypes({}));
  }, []);

  useEffect(() => {
    dispatch(getSalesAgents(orderId));
  }, [orderId]);

  const getAgentType = (agent) => agentTypes.filter(type => type?.id === agent?.salesAgentTypeId)?.[0]?.name;

  const getSalesRepUrl = (agent) => `${orderId}/${Urls.SalesRep}/${agent.id}/edit`;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {React.Children.toArray(salesAgents?.map((agent, index) =>
        renderSalesAgent(
          isEditable,
          agent,
          index,
          getAgentType(agent),
          navigate,
          getSalesRepUrl(agent),
        )))}
    </>
  );
}
