import {
  Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  deleteBusinessFunctionality,
  getBusinessFunctionalities,
  selectBusinessFunctionalities,
} from 'services/adminSlice';
import { useEffect, useState } from 'react';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

export default function BusinessFunctionalities() {
  const businessFunctionalities = useSelector(selectBusinessFunctionalities);
  const dispatch = useDispatch();
  const [itemToDelete, setItemToDelete] = useState(null);

  const deleteFunctionality = (id) => dispatch(deleteBusinessFunctionality(id));

  useEffect(() => {
    dispatch(getBusinessFunctionalities({}));
  }, []);

  return (
    <>
      <Typography variant="h2">{`Cobalt Theme / Style Functionalities (${businessFunctionalities.length})`}</Typography>
      <Grid container>
        <Grid item xs={12} marginTop={1}>
          <Link to="/admin/businessFunctionalities/new" style={{ marginRight: '10px' }}>
            <Button variant="contained">
              <Typography>New Business Functionality</Typography>
            </Button>
          </Link>
          <Link to="/admin/dashboard">
            <Button variant="contained">
              <Typography>Admin Dashboard</Typography>
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} marginTop={5}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ borderBottom: '3px solid lightgray' }}>
                  <TableCell><Typography fontWeight="bold">Name (theme count)</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Description</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Notes</Typography></TableCell>
                  <TableCell><Typography fontWeight="bold">Active</Typography></TableCell>
                  <TableCell><Typography>&nbsp;</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {businessFunctionalities.map(functionality => (
                  <TableRow
                    key={functionality.id}
                    sx={{
                      border: 0,
                      '& .MuiTableCell-root': { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Typography>
                        {`${functionality.name} (${functionality.themeCount})`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {functionality.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {functionality.notes}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {`${functionality.active}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Link to={`${functionality.id}/edit`} style={{ marginRight: '10px' }}>
                        <Button variant="contained">
                          <Typography>Edit</Typography>
                        </Button>
                      </Link>
                      <Button variant="contained" color="error"
                        onClick={() => setItemToDelete(functionality)}
                      >
                        <Typography>Destroy</Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DeleteConfirmationModal
            open={!!itemToDelete}
            onClose={() => setItemToDelete(null)}
            onConfirm={() => deleteFunctionality(itemToDelete.id)}
            resourceName={itemToDelete?.name} resourceType="Business Functionality"
          />
        </Grid>
      </Grid>
    </>
  );
}
