import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18next';
import TextField from 'pages/generic-product/details/custom-fields/TextField';

const { t } = i18n;

export const WebsiteManagedServicesSchema: RJSFSchema | any = () => ({
  title: t('pages.notesAndInstructions.websiteManagedServices'),
  type: 'object',
  required: [],
  properties: {
    notesOrInstructions: {
      type: 'string',
      title: t('pages.notesAndInstructions.notesOrInstructionsLabel'),
      default: '',
    },
  },
});

export const WebsiteManagedServicesUISchema = {
  'ui:order': ['notesOrInstructions'],
  notesOrInstructions: {
    'ui:widget': (props) => <TextField {...props} rows={12} />,
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const WebsiteManagedServicesCustomValidate = (formData: any, errors, uiSchema) => errors;
