import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { Grid, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import customDesignIcon from 'icons/parallax.svg';
import customNavigationIcon from 'icons/menu.svg';
import customFormIcon from 'icons/additionalFeatures/testimonials.svg';
import heroVideoIcon from 'icons/hero-video.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCustomWebsiteItems, postCustomWebsiteItems, selectCustomWebsiteItems,
} from 'services/customWebsiteSlice';
import { Urls } from 'navigation/Urls';
import { useEffect, useState } from 'react';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import NeBigBoxMultipleSelect from 'components/bigBoxMultipleSelect/NeBigBoxMultipleSelect';
import { selectActiveProductId } from 'services/navigationSlice';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import schema from './DesignItems.schema';

export const DesignItemsList = () => {
  const { t } = useTranslation();
  return [
    {
      checked: false,
      id: '1',
      name: 'customDesign',
      label: t('pages.website.custom.designItems.customDesign'),
      svgIcon: customDesignIcon,
      tooltipPlacement: 'top' as any,
      toolTipIcon: InfoOutlinedIcon,
      tooltipText:
  <Typography fontSize="12px">
    {t('pages.website.custom.designItems.customDesignTooltip')}
  </Typography>,
    },
    {
      checked: false,
      id: '2',
      name: 'customNavigation',
      label: t('pages.website.custom.designItems.customNavigation'),
      svgIcon: customNavigationIcon,
      tooltipPlacement: 'top' as any,
      toolTipIcon: InfoOutlinedIcon,
      tooltipText:
  <Typography fontSize="12px">
    {t('pages.website.custom.designItems.customNavigationTooltip')}
  &nbsp;
    <a href="https://cambridge4.localiq.site/" style={{ color: 'white' }}
      target="_blank" rel="noreferrer"
    >
      {t('pages.website.custom.designItems.viewExample')}
    </a>
  </Typography>,
    },
    {
      checked: false,
      id: '3',
      name: 'customForm',
      label: t('pages.website.custom.designItems.customForm'),
      svgIcon: customFormIcon,
      tooltipPlacement: 'top' as any,
      toolTipIcon: InfoOutlinedIcon,
      tooltipText:
  <Typography fontSize="12px">
    {t('pages.website.custom.designItems.customFormTooltip')}
  </Typography>,
    },
    {
      checked: false,
      id: '8',
      name: 'heroVideoBackground',
      label: t('pages.website.custom.designItems.heroVideoBackground'),
      svgIcon: heroVideoIcon,
      tooltipPlacement: 'top' as any,
      toolTipIcon: InfoOutlinedIcon,
      tooltipText:
  <Typography fontSize="12px">
    {t('pages.website.custom.designItems.heroVideoBackgroundTooltip')}
  &nbsp;
    <a href="https://hagar3.localiq.site/" style={{ color: 'white' }}
      target="_blank" rel="noreferrer"
    >
      {t('pages.website.custom.designItems.viewExample')}
    </a>
  </Typography>,
    },
  ];
};

export default function CustomWebsiteDesignItems() {
  const { t } = useTranslation();
  const navigate = useNavigationHandler();
  const dispatch = useDispatch();
  const websiteId = useSelector(selectActiveProductId);
  const customWebsiteItems = useSelector(selectCustomWebsiteItems);
  const designItems = DesignItemsList();

  const [options, setOptions] = useState(designItems);

  const methods = useForm({
    mode: 'all',
  });

  const {
    getValues, reset, handleSubmit, formState: {
      isDirty, isSubmitting, isSubmitSuccessful, isValid,
    },
  } = methods;

  const handleContinue = async () => {
    const { items } = getValues();
    const featureItemIds = [4, 5, 6, 7, 9];
    const customWebsiteItemIds = items.filter(item => item.checked).map(item => Number(item.id));
    const selectedFeatureIds = featureItemIds.filter(id => customWebsiteItems.find(e => id === e));
    const totalSelection = selectedFeatureIds.concat(customWebsiteItemIds);

    const itemNames = items.filter(item => item.checked).map(({ name }) => name);
    await dispatch(postCustomWebsiteItems({
      offeringId: websiteId?.toString(),
      content: { customWebsiteItemIds: totalSelection },
    }));

    if (itemNames[0] === 'heroVideoBackground' || itemNames[0] === undefined) {
      navigate.to(Urls.CustomWebsiteFeatures);
    } else {
      navigate.to(`${Urls.CustomWebsiteDesignItems}/${itemNames[0]}`);
    }
  };

  useEffect(() => {
    if (customWebsiteItems.length > 0) {
      const updatedDesignItems = [];
      designItems.forEach(obj => {
        const item = customWebsiteItems.find(id => Number(obj.id) === Number(id));
        // eslint-disable-next-line no-param-reassign
        if (item) obj.checked = true;
        updatedDesignItems.push(obj);
      });
      setOptions(updatedDesignItems);
      reset({ items: updatedDesignItems });
    }
  }, [customWebsiteItems]);

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsiteItems(websiteId.toString()));
  }, [websiteId]);

  return (
    <DefaultPageLayout
      onContinue={handleSubmit(handleContinue)}
      disableContinue={!isValid}
      onBack={() => navigate.to(Urls.CustomWebsiteDetails)}
      header={t('pages.website.custom.designItems.title')}
    >
      { options?.length > 0 && (
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container>
            <Grid item xs={12}>
              <Typography fontSize="18px">{t('pages.website.custom.designItems.description')}</Typography>
            </Grid>
            <Grid item xs={12} marginTop={3}>
              <NeBigBoxMultipleSelect
                optionsList={options}
                name="items"
              />
            </Grid>
          </Grid>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
      ) }
    </DefaultPageLayout>
  );
}
