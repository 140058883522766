import React, {useEffect, useMemo, useState} from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import i18n from 'i18n/i18n';
import {urlValidationRegex} from "shared/constants";
const { t } = i18n;

type AdSitelink = {
  sitelinkName: string;
  sitelinkUrl: string;
};

const initSitelinkState = () : AdSitelink => ({sitelinkName: '', sitelinkUrl: ''});

export default function AddSitelink(props: any) {
  const theme = useTheme();
  const [sitelinks, setSitelinks] = useState<AdSitelink[]>(props.value || []);
  const [open, setOpen] = useState(false);
  const [sitelink, setSitelink] = useState<AdSitelink>(initSitelinkState());
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const maxReached = useMemo(() => sitelinks.length > 9, [sitelinks])
  const currentWindowCount = useMemo(() => editIndex!==null ? editIndex+1 : sitelinks.length + 1, [editIndex, sitelinks]);
  
  const handleClickOpen = (index: number | null = null) => {
    if (index !== null) {
      setSitelink(sitelinks[index]);
      setEditIndex(index);
    } else {
      setSitelink(initSitelinkState);
      setEditIndex(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsValidUrl(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setSitelink((prevWebEvent) => ({...prevWebEvent, [name]: value}));
    if (name === 'sitelinkUrl') {
      setIsValidUrl(validateUrl(value));
    }
  };

  const validateUrl = (url: string) => {
    return urlValidationRegex.test(url);
  };

  const handleSubmit = () => {
    let items = [...sitelinks];
    const newItem = {...sitelink};
    const idx = editIndex !== null ? editIndex : items.length;
    items.splice(idx, 1, newItem);
    setSitelinks(items);
    setSitelink(initSitelinkState());
    handleClose();
  };

  const handleDelete = (index: number) => {
    setSitelinks((prevItem) => prevItem.filter((_, i) => i !== index));
  };

  useEffect(() => {
    props.onChange(sitelinks.map(ad => ({...ad})))
  }, [sitelinks]);

  return (
      <Grid item xs={9} my={3}>
        <Grid>
          <Box>
            {sitelinks && sitelinks.map((ad, index) => (
                <Grid key={index}>
                  <Box
                      mb={1}
                      sx={{
                        padding: '10px',
                        display: 'flex',
                        fontSize: '15px',
                        alignItems: 'center',
                        backgroundColor: '#ffffff',
                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
                      }}
                  >
                    <Box sx={{
                      flexGrow: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginRight: '20%'
                    }}>
                      <Box>
                        <Typography variant="h6" sx={{marginBottom: '10px', fontWeight: 'bold'}}>
                          {t('sitelinksWidget.title', {count: index + 1})}
                        </Typography>
                        <div style={{margin: 0}}>
                          <span style={{fontWeight: 'bold'}}>{t('sitelinksWidget.name')}:</span>
                          <span style={{
                            marginLeft: '5px',
                            wordBreak: 'break-word'
                          }}>{ad.sitelinkName}</span>
                        </div>
                        <div style={{margin: '10px 0 0 0'}}>
                          <span style={{fontWeight: 'bold'}}>{t('sitelinksWidget.url')}:</span>
                          <span style={{
                            marginLeft: '5px',
                            wordBreak: 'break-word'
                          }}>{ad.sitelinkUrl}</span>
                        </div>
                      </Box>
                    </Box>
                    <IconButton onClick={() => handleClickOpen(index)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleDelete(index)}>
                      <DeleteIcon/>
                    </IconButton>
                  </Box>
                </Grid>
            ))}
            <Box
                sx={{
                  padding: '20px 20px 20px 0',
                  backgroundColor: theme.palette.primary.light,
                }}
            >
              <Button disabled={maxReached} startIcon={<AddIcon/>} onClick={() => handleClickOpen()}>
                {t('sitelinksWidget.add')}
              </Button>
            </Box>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{t('sitelinksWidget.title', {count: currentWindowCount})}</DialogTitle>
              <DialogContent>
              <TextField
                    margin="dense"
                    name="sitelinkName"
                    label={t('sitelinksWidget.name')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={sitelink.sitelinkName}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    name="sitelinkUrl"
                    label={t('sitelinksWidget.url')}
                    type="url"
                    fullWidth
                    variant="outlined"
                    value={sitelink.sitelinkUrl}
                    onChange={handleChange}
                    error={!isValidUrl}
                    helperText={!isValidUrl ? "Please enter a valid URL starting with http:// or https://" : ""}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                {t('sitelinksWidget.cancel')}
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={!isValidUrl}>
                {t(editIndex === null ? 'sitelinksWidget.save' : 'sitelinksWidget.update')}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
  );
}