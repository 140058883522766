import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import i18n from 'i18n/i18n';

const { t } = i18n;

type AdSet = {
  focus: string;
  landingPage: string;
  uniqueTargeting: string;
};

export default function AdSet(props: any) {
  const { value, onChange } = props;
  const [adSet, setAdSet] = useState<AdSet[]>(value || []);
  const [open, setOpen] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [focus, setFocus] = useState('');
  const [landingPage, setLandingPage] = useState('');
  const [uniqueTargeting, setUniqueTargeting] = useState('');
  const theme = useTheme();
  const {data} = props;
  const maxReached = useMemo(() => adSet.length >= data.numberOfAdSets, [adSet]);
  const currentWindowCount = useMemo(() => (editIndex !== null ? editIndex + 1 : adSet.length + 1), [editIndex, adSet]);

  const handleClickOpen = (index: number | null = null) => {
    if (index !== null) {
      const asset = adSet[index];
      setFocus(asset.focus);
      setLandingPage(asset.landingPage);
      setUniqueTargeting(asset.uniqueTargeting);
      setEditIndex(index);
    } else {
      setFocus('');
      setLandingPage('');
      setUniqueTargeting('');
      setEditIndex(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditIndex(null);
  };

  const handleSubmit = () => {
    const newAsset: AdSet = { focus, landingPage, uniqueTargeting };
    const items = [...adSet];

    if (editIndex !== null) {
      items[editIndex] = newAsset;
    } else {
      items.push(newAsset);
    }

    setAdSet(items);
    handleClose();
  };

  const handleDelete = (index: number) => {
    setAdSet((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    onChange(adSet);
  }, [adSet, onChange]);

  return (
      <Grid item xs={9} my={3}>
        <Grid>
          <Box>
            {adSet.map((asset, index) => (
                <Grid key={index}>
                  <Box
                      mb={1}
                      sx={{
                        padding: '10px',
                        display: 'flex',
                        fontSize: '15px',
                        alignItems: 'center',
                        backgroundColor: '#ffffff',
                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                      }}
                  >
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', marginRight: '20%' }}>
                      <Box>
                        <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                          {t('pages.ausProduct.adSet.title', { count: index + 1 })}
                        </Typography>
                        <div style={{ margin: 0 }}>
                          <strong>{t('Focus')}:</strong>
                          <span style={{ marginLeft: '5px', wordBreak: 'break-word' }}>{asset.focus}</span>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                          <strong>{t('Landing Page')}:</strong>
                          <span style={{ marginLeft: '5px', wordBreak: 'break-word' }}>{asset.landingPage}</span>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                          <strong>{t('Unique Targeting')}:</strong>
                          <span style={{ marginLeft: '5px', wordBreak: 'break-word' }}>{asset.uniqueTargeting}</span>
                        </div>
                      </Box>
                    </Box>
                    <IconButton onClick={() => handleClickOpen(index)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Grid>
            ))}
            <Box
                sx={{
                  padding: '20px 20px 20px 0',
                  backgroundColor: theme.palette.primary.light,
                }}
            >
              <Button disabled={maxReached} startIcon={<AddIcon />} onClick={() => handleClickOpen()}>
                {t('pages.ausProduct.adSet.add')}
              </Button>
            </Box>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{t('pages.ausProduct.adSet.title', { count: currentWindowCount })}</DialogTitle>
              <DialogContent>
                <TextField
                    margin="dense"
                    name="focus"
                    label={t('Focus')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={focus}
                    onChange={(e) => setFocus(e.target.value)}
                />
                <TextField
                    margin="dense"
                    name="landingPage"
                    label={t('Landing Page')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={landingPage}
                    onChange={(e) => setLandingPage(e.target.value)}
                />
                <TextField
                    margin="dense"
                    name="uniqueTargeting"
                    label={t('Unique Targeting')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={uniqueTargeting}
                    onChange={(e) => setUniqueTargeting(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t('pages.ausProduct.adSet.cancel')}
                </Button>
                <Button onClick={handleSubmit} color="primary">
                  {t(editIndex === null ? 'pages.ausProduct.adSet.save' : 'pages.ausProduct.adSet.update')}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
  );
}
