import * as yup from 'yup';

const schema = {
  hostingOption: yup.string().required(),
  archivingRequired: yup.string().required(),
  additionalNotesInstructions: yup.string().notRequired(),
};

export default {
  yupValidations: schema,
  customValidations: {
  },
};
