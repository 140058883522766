import { Urls } from 'navigation/Urls';
import { StepperLayout } from 'layouts/StepperLayout';
import { LocationSteps, isLocationStepCompleted } from 'shared/constants';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentLocation } from 'services/businessLocationSlice';
import Box from '@mui/material/Box';
import LocationAddressPage from './address/LocationAddressPage';
import LocationContactPage from './contact/LocationContactPage';
import LocationHoursPage from './hours/LocationHoursPage';
import { LocationPhotos } from './photos/LocationPhotos';
import LocationAdditionalPage from './additional/LocationAdditionalPage';

const steps = [
  {
    name: Urls.BusinessLocationAddress,
    component: ((props) => <LocationAddressPage {...props} />),
    continueTitle: 'pages.business.location.address.continueButtonLabel',
    title: 'pages.business.location.address.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    name: Urls.BusinessLocationContact,
    component: ((props) => <LocationContactPage {...props} />),
    continueTitle: 'pages.business.location.contact.details.continueButtonLabel',
    title: 'pages.business.location.contact.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    name: Urls.BusinessLocationHours,
    component: ((props) => <LocationHoursPage {...props} />),
    continueTitle: 'pages.business.location.hours.continueButtonLabel',
    title: 'pages.business.location.hours.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    name: Urls.BusinessLocationPhotos,
    component: ((props) => <LocationPhotos {...props} />),
    continueTitle: 'pages.business.location.photos.continueButtonLabel',
    title: 'pages.business.location.photos.title',
    hideContinue: false,
    isCompleted: false,
  },
  {
    name: Urls.BusinessLocationAdditional,
    component: ((props) => <LocationAdditionalPage {...props} />),
    continueTitle: 'pages.business.location.additional.continueButtonLabel',
    title: 'pages.business.location.additional.title',
    hideContinue: false,
    isCompleted: false,
  },
];

export default function EditLocationStepper() {
  const location = useSelector(selectCurrentLocation);
  const [stepsWtithState] = useState(steps);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (location) {
      if (location && location.completed === true) {
        stepsWtithState[0].isCompleted = true;
        stepsWtithState[1].isCompleted = true;
        stepsWtithState[2].isCompleted = true;
        stepsWtithState[3].isCompleted = true;
        stepsWtithState[4].isCompleted = true;
      } else {
        stepsWtithState[0].isCompleted = isLocationStepCompleted(LocationSteps.ADDRESS, location.lastCompletedStep);
        stepsWtithState[1].isCompleted = isLocationStepCompleted(LocationSteps.CONTACT, location.lastCompletedStep);
        stepsWtithState[2].isCompleted = isLocationStepCompleted(LocationSteps.HOURS, location.lastCompletedStep);
        stepsWtithState[3].isCompleted = isLocationStepCompleted(LocationSteps.PHOTOS, location.lastCompletedStep);
        stepsWtithState[4].isCompleted = isLocationStepCompleted(
          LocationSteps.ADDITIONAL_INFO,
          location.lastCompletedStep,
        );
      }
    }
    setLoading(false);
  }, [location]);

  return (
    isLoading ? (<Box />) : (<StepperLayout steps={stepsWtithState} formId="business-listings-form" />)
  );
}
