import i18n from 'i18n/i18n';

const {t} = i18n;

export const yesNoDropdownItems = [
  {value: 'Yes', description: t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.yes')},
  {value: 'No', description: t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.no')}
];

export const callToActionDropdownItems = [
  {value: 'No Button', description: t('pages.xmo.tactics.socialAdsRetargeting.noButton')},
  {value: 'Apply Now', description: t('pages.xmo.tactics.socialAdsRetargeting.applyNow')},
  {value: 'Download', description: t('pages.xmo.tactics.socialAdsRetargeting.download')},
  {value: 'Get Quote', description: t('pages.xmo.tactics.socialAdsRetargeting.getQuote')},
  {value: 'Learn More', description: t('pages.xmo.tactics.socialAdsRetargeting.learnMore')},
  {value: 'Sign Up', description: t('pages.xmo.tactics.socialAdsRetargeting.signUp')},
  {value: 'Subscribe', description: t('pages.xmo.tactics.socialAdsRetargeting.subscribe')},
];

export const daysRetargetFacebookVisitorsDropdownItems = [
  {value: '15', description: t('pages.socialAds.solutionStrategy.smartRetargeting.days15')},
  {value: '30', description: t('pages.socialAds.solutionStrategy.smartRetargeting.days30')},
  {value: '60', description: t('pages.socialAds.solutionStrategy.smartRetargeting.days60')},
  {value: '90', description: t('pages.socialAds.solutionStrategy.smartRetargeting.days90')},
  {value: '120', description: t('pages.socialAds.solutionStrategy.smartRetargeting.days120')},
  {value: '150', description: t('pages.socialAds.solutionStrategy.smartRetargeting.days150')},
  {value: '180', description: t('pages.socialAds.solutionStrategy.smartRetargeting.days180')},
];

export const clientWantTheirAdsToAppearCheckboxes = [
  {
    id: t('pages.xmo.tactics.socialAdsRetargeting.mobileNewsFeedLabel'),
    label: t('pages.xmo.tactics.socialAdsRetargeting.mobileNewsFeedLabel'),
    checked: false
  },
  {
    id: t('pages.xmo.tactics.socialAdsRetargeting.desktopNewsFeedLabel'),
    label: t('pages.xmo.tactics.socialAdsRetargeting.desktopNewsFeedLabel'),
    checked: false
  },
  {
    id: t('pages.xmo.tactics.socialAdsRetargeting.instagramLabel'),
    label: t('pages.xmo.tactics.socialAdsRetargeting.instagramLabel'),
    checked: false
  },
  {
    id: t('pages.xmo.tactics.socialAdsRetargeting.facebookAudienceNetworkLabel'),
    label: t('pages.xmo.tactics.socialAdsRetargeting.facebookAudienceNetworkLabel'),
    checked: false
  },
  {
    id: t('pages.xmo.tactics.socialAdsRetargeting.marketplaceLabel'),
    label: t('pages.xmo.tactics.socialAdsRetargeting.marketplaceLabel'),
    checked: false
  },
]

export const selectAllLeadAdsFormContactFieldsCheckboxes = [
  {
    id: t('pages.xmo.tactics.socialAdsRetargeting.phoneNumberLabel'),
    label: t('pages.xmo.tactics.socialAdsRetargeting.phoneNumberLabel'),
    checked: false
  },
  {
    id: t('pages.xmo.tactics.socialAdsRetargeting.zipCodeLabel'),
    label: t('pages.xmo.tactics.socialAdsRetargeting.zipCodeLabel'),
    checked: false
  },
  {
    id: t('pages.xmo.tactics.socialAdsRetargeting.emailLabel'),
    label: t('pages.xmo.tactics.socialAdsRetargeting.emailLabel'),
    checked: false
  },
]

export const genderItems = [
  {label: t('pages.xmo.reach.genderOption.male')},
  {label: t('pages.xmo.reach.genderOption.female')},
  {label: t('pages.xmo.reach.genderOption.both')}];

export const retargetingItems = [
  {label: t('pages.xmo.tactics.socialAdsRetargeting.entireWebsite')},
  {label: t('pages.xmo.tactics.socialAdsRetargeting.selectPages')}];

export const yesNoItems = [
  {label: t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.yes')},
  {label: t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.no')},
];

export const yesLookaLikesItems = [
  {label: t('pages.xmo.tactics.socialAdsRetargeting.yesUseLL')},
  {label: t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.no')}];

export const yesAllItems = [
  {label: t('pages.xmo.tactics.socialAdsRetargeting.yesAll')},
  {label: t('pages.xmo.reach.useAutomaticPlacementOnAdsOptions.no')},
  {label: t('pages.xmo.tactics.socialAdsRetargeting.yesSome')}];

export const imageVideoItems = [
  {label: t('pages.xmo.tactics.socialAdsRetargeting.singleImageLabel')},
  {label: t('pages.xmo.tactics.socialAdsRetargeting.multipleVideosLabel')},
  {label: t('pages.xmo.tactics.socialAdsRetargeting.singleVideoLabel')},
  {label: t('pages.xmo.tactics.socialAdsRetargeting.imageAndVideoCombinationLabel')},
  {label: t('pages.xmo.tactics.socialAdsRetargeting.multipleImagesLabel')}];

export const inventoryItems = [
  {label: t('pages.xmo.tactics.socialAdsRetargeting.all')},
  {label: t('pages.xmo.tactics.socialAdsRetargeting.new')},
  {label: t('pages.xmo.tactics.socialAdsRetargeting.used')}
];