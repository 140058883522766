import { useEffect, useState } from 'react';
import { locationsTypes } from 'layouts/StepperLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderItems, selectOrderItems } from 'services/ordersSlice';
import { useParams } from 'react-router';
import standardSchema, { schemaWithDMS } from './LocationHours.schema';
import premiumListingSchema from './PremiumListingsHours.schema';
import healthcareSchema from './PlHealthcareHours.schema';
import LocationHours from './LocationHours';
import { weekDays } from './LocationHours.data';

const baseDefaultValues = {
  days: weekDays,
  applyHoursToAll: false,
  grandOpeningDate: null,
};

const PlDefaultValues = {
  days: weekDays,
  openingHourNotes: '',
  specialHours: [],
  applyHoursToAll: false,
  grandOpeningDate: null,
};

const HcDefaultValues = {
  days: weekDays,
  openingHourNotes: '',
  specialHours: [],
  grandOpeningDate: null,
  facebookListingsUrl: '',
};

const dashProducts = ['DMSNextCustomerCenterByDash'];

export default function LocationHoursPage(props: any) {
  const { locationsType } = props;
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const { content: orderItems } = useSelector(selectOrderItems);
  const [schema, setSchema] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const [hasDashProduct, setHasDashProduct] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (orderItems.length === 0) {
      dispatch(getOrderItems(orderId));
    } else {
      const filteredProducts = orderItems.filter(item => dashProducts.includes(item?.pdOfferingType));
      setHasDashProduct(orderItems.length === 1 && filteredProducts.length > 0);
    }
  }, [orderItems]);

  useEffect(() => {
    if (locationsType === locationsTypes.STANDARD && !hasDashProduct) {
      setDefaultValues(baseDefaultValues);
      setSchema(standardSchema);
    }

    if (locationsType === locationsTypes.STANDARD && hasDashProduct) {
      setDefaultValues(baseDefaultValues);
      setSchema(schemaWithDMS);
    }

    if (locationsType === locationsTypes.PREMIUM_LISTINGS) {
      setDefaultValues(PlDefaultValues);
      setSchema(premiumListingSchema);
    }

    if (locationsType === locationsTypes.HEALTHCARE) {
      setDefaultValues(HcDefaultValues);
      setSchema(healthcareSchema);
    }
  }, [locationsType, hasDashProduct]);

  useEffect(() => {
    setIsReady(schema && defaultValues && hasDashProduct !== null);
  }, [schema, defaultValues, hasDashProduct]);

  return isReady && (
    <LocationHours
      {...props}
      type={locationsType}
      schema={schema}
      defaultValues={defaultValues}
    />
  );
}
