/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';
import { validateEmail } from 'pages/generic-product/details/custom-fields/EmailTextField';
import { validateUrl } from 'pages/generic-product/details/custom-fields/UrlTextField';

const { t } = i18n;

export const GTMSchema: RJSFSchema = {
  title: t('pages.GTM.details.clientInformation.clientInformationTitle'),
  type: 'object',
  required: ['clientFirstName', 'clientLastName', 'clientContactPhone',
    'clientContactEmail', 'websiteUrl', 'annualMediaSpend', 'discoveryCallCompleted'],
  properties: {
    clientFirstName: {
      type: 'string',
      title: t('pages.GTM.details.clientInformation.clientFirstNameLabel'),
      default: '',
    },
    clientLastName: {
      type: 'string',
      title: t('pages.GTM.details.clientInformation.clientLastNameLabel'),
      default: '',
    },
    clientContactPhone: {
      type: 'string',
      title: t('pages.GTM.details.clientInformation.clientContactPhoneLabel'),
      default: '',
    },
    clientContactEmail: {
      type: 'string',
      title: t('pages.GTM.details.clientInformation.clientContactEmailLabel'),
      default: '',
      description: t('pages.GTM.details.clientInformation.clientInformationDescription'),
    },
    bestNumberToReach: {
      type: 'string',
      title: t('pages.GTM.details.clientInformation.bestNumberToReachLabel'),
      default: '',
    },
    websiteUrl: {
      type: 'string',
      title: t('pages.GTM.details.businessInformation.websiteUrl'),
      default: '',
    },
    annualMediaSpend: {
      type: 'string',
      title: t('pages.GTM.details.businessInformation.annualMediaSpend'),
      default: '',
    },
    discoveryCallCompleted: {
      type: 'string',
      title: t('pages.GTM.details.businessInformation.discoveryCallCompletedLabel'),
      default: '',
      items: {
        enum: [
          `${t('pages.generic-translation.yesOption')}`,
          `${t('pages.generic-translation.noOption')}`,
        ],
      },
    },
    notesOrInstructions: {
      type: 'string',
      title: t('pages.GTM.details.businessInformation.notesOrInstructionsLabel'),
      default: '',
    },
  },
  dependencies: {
    discoveryCallCompleted: {
      oneOf: [
        {
          properties: {
            discoveryCallCompleted: {
              enum: ['No'],
            },
            discoveryCallHeadingTitle: {
              type: 'string',
              title: t('pages.GTM.details.businessInformation.discoveryCallHeadingTitle'),
            },
          },
        },
      ],
    },
  },
};

const textFieldKeys = [
  'clientFirstName',
  'clientLastName',
  'annualMediaSpend',
];

const phoneNumberTextFieldKeys = [
  'clientContactPhone',
  'bestNumberToReach',
];

const headingFieldKeys = [
  'clientInformationTitle',
];

const textFields = textFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'textInputField' },
}), {});

const phoneNumberTextFields = phoneNumberTextFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'phoneNumberTextField' },
}), {});

const headingFields = headingFieldKeys.reduce((o, key) => ({
  ...o,
  [key]: { 'ui:widget': 'headingTextLabel' },
}), {});

export const GTMUISchema = {
  'ui:order': ['clientFirstName', 'clientLastName', 'clientContactPhone', 'clientContactEmail',
    'bestNumberToReach', 'websiteUrl', 'annualMediaSpend', 'discoveryCallCompleted',
    'discoveryCallHeadingTitle', 'notesOrInstructions'],
  clientContactEmail: {
    'ui:widget': 'EmailTextWidget',
  },
  discoveryCallCompleted: {
    'ui:widget': 'radioButton',
  },
  websiteUrl: {
    'ui:widget': 'UrlTextWidget',
  },
  notesOrInstructions: {
    'ui:widget': 'TextareaWidget',
  },
  discoveryCallHeadingTitle: {
    'ui:widget': 'textLabel',
    'ui:style': {
      textAlign: 'center',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
    },
  },
  ...textFields,
  ...phoneNumberTextFields,
  ...headingFields,
};

export const GTMCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.clientFirstName === '') {
    errors.clientFirstName.addError(t('pages.GTM.details.clientInformation.clientFirstNameError'));
    errors.addError(true);
  }

  if (formData?.clientLastName === '') {
    errors.clientLastName.addError(t('pages.GTM.details.clientInformation.clientLastNameError'));
    errors.addError(true);
  }

  if (formData?.clientContactPhone === '') {
    errors.clientContactPhone.addError(t('pages.GTM.details.clientInformation.clientContactPhoneError'));
    errors.addError(true);
  }

  if (formData?.websiteUrl === '' || !validateUrl(formData?.websiteUrl)) {
    errors.websiteUrl.addError(t('pages.genericUrlErrorMessage.urlErrorMessage'));
    errors.addError(true);
  }

  if (formData?.annualMediaSpend === '') {
    errors.annualMediaSpend.addError(t('pages.GTM.details.businessInformation.annualMediaSpendError'));
    errors.addError(true);
  }

  if (formData?.clientContactEmail === '') {
    errors.clientContactEmail.addError(t('pages.videoCreativeService.clientContactEmailErrorMessage'));
    errors.addError(true);
  }

  if (formData?.clientContactEmail !== '' && !validateEmail(formData?.clientContactEmail)) {
    errors.clientContactEmail.addError(t('pages.shoppingAds.solutionStrategy.clientsContactEmailErrorLabel'));
    errors.addError(true);
  }
  if (formData?.discoveryCallCompleted === '') {
    errors.discoveryCallCompleted.addError(t('pages.generic-translation.mandatoryRadioButtonErrorMessage'));
    errors.addError(true);
  }
  return errors;
};
