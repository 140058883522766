import React, { FC } from 'react';
import styled from '@emotion/styled';

export interface CoAppBarProps {
  position: 'top' | 'bottom';
}

const AppBarContainer = styled('div')<CoAppBarProps>(({ theme, position }) => ({
  height: 64,
  position: position === 'top' ? 'relative' : 'fixed',
  width: '100%',
  background: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0 36px',
  gap: 10,
  [position === 'top' ? 'borderBottom' : 'borderTop']: '1px solid #D7D7D7',
  [position]: 0,
  '&': {
    [theme.breakpoints.down('md')]: {
      position: 'relative',
    },
  },
}));

const CoAppBar: FC<CoAppBarProps> = ({ position, children }) => (
  <AppBarContainer position={position}>
    {children}
  </AppBarContainer>
);

export default CoAppBar;
