/* eslint-disable max-len */
import ContentLoader from 'components/contentLoader/ContentLoader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getIsPremiumListingsHealthcare } from 'services/premiumListingsSlice';
import { getCampaignIdsWithGmaid, selectCampaignItems } from 'services/campaignSlice';
import { getOrderBusinessByOrderId, selectOrderBusiness } from 'services/businessSlice';
import CampaignIdsForm from './CampaignIdsForm';

// test data for campaigns

export const TestCampaigns = [
  {
    name: 'GCI | Limited 7 Roofing | Client Center',
    id: 4211230,
    masterCampaignId: 4211230,
    productId: '1:25:1883',
    offerId: 1883,
    advertiserId: 504277,
    websiteOrderId: '4211230',
    status: 'RUNNING',
    feeType: 'RECURRING',
    campaignOwnerId: 612472,
    paymentType: null,
    productName: 'Client Center with Lead Engagement Add-On - SEO Add-On:Terms @ www.reachlocal.com/EdgeSEO',
    productFamilyName: 'Client Center',
    productFamilyId: 4,
    paidDate: '2023-08-10 11:22:17.0',
    cycleEndDate: '2033-08-08',
    insertionOrderId: 0,
    budget: 0,
    amountSpent: 0,
    duration: 3650,
    monthlyFee: 0,
    referrerType: null,
    renewalInfo: {
      autoRenewType: 'BOTH',
      autoRenewStatus: 'PENDING',
      numberOfRenewals: 1,
      cyclesRequested: 12,
    },
    availableOrderActions: [
      {
        type: 'RENEWAL',
        effectiveDate: '2143-07-13',
      },
      {
        type: 'MODIFICATION',
        effectiveDate: '2033-08-08',
      },
    ],
    isUnlimitedCycles: false,
    masterCampaign: {
      rel: 'rel',
      href: 'https://campaignservicev2-qa-usa.localiq-b2b-qa-us-west1.gke-bernard-rands-svc.gannettdigital.com/campaigns/4211230',
    },
    advertiser: {
      rel: 'rel',
      href: 'https://advertiserservice-qa-usa.localiq-b2b-qanx1-us-west1.gke-hilton-als-svc.gannettdigital.com/advertiser-service/rest/v1/advertisers/504277',
    },
    link: {
      rel: 'self',
      href: 'https://campaignservicev2-qa-usa.localiq-b2b-qa-us-west1.gke-bernard-rands-svc.gannettdigital.com/campaigns/4211230',
    },
  },
  {
    name: 'GCI | LSA | Limited 7 Roofing',
    id: 4211832,
    masterCampaignId: 4211832,
    productId: '1:8:2303',
    offerId: 2303,
    advertiserId: 504277,
    websiteOrderId: '4211832',
    status: 'RUNNING',
    feeType: 'RECURRING',
    campaignOwnerId: 612472,
    paymentType: null,
    productName: 'Local Services Ads',
    productFamilyName: 'Search Marketing',
    productFamilyId: 1,
    paidDate: '2023-08-11 07:48:08.0',
    cycleEndDate: '2023-09-22',
    insertionOrderId: 0,
    budget: 2000,
    amountSpent: 298,
    duration: 30,
    monthlyFee: 0,
    referrerType: null,
    renewalInfo: {
      autoRenewType: 'BUDGET',
      autoRenewStatus: 'PENDING',
      numberOfRenewals: 1,
      cyclesRequested: 12,
    },
    availableOrderActions: [
      {
        type: 'RENEWAL',
        effectiveDate: '2024-08-17',
      },
      {
        type: 'MODIFICATION',
        effectiveDate: '2023-09-22',
      },
    ],
    isUnlimitedCycles: false,
    masterCampaign: {
      rel: 'rel',
      href: 'https://campaignservicev2-qa-usa.localiq-b2b-qa-us-west1.gke-bernard-rands-svc.gannettdigital.com/campaigns/4211832',
    },
    advertiser: {
      rel: 'rel',
      href: 'https://advertiserservice-qa-usa.localiq-b2b-qanx1-us-west1.gke-hilton-als-svc.gannettdigital.com/advertiser-service/rest/v1/advertisers/504277',
    },
    link: {
      rel: 'self',
      href: 'https://campaignservicev2-qa-usa.localiq-b2b-qa-us-west1.gke-bernard-rands-svc.gannettdigital.com/campaigns/4211832',
    },
  },
  {
    name: 'GCI | Limited 7 Roofing | SEO',
    id: 4243970,
    masterCampaignId: 4211228,
    productId: '1:23:1875',
    offerId: 1875,
    advertiserId: 504277,
    websiteOrderId: '4211228',
    status: 'RUNNING',
    feeType: 'LUMP-SUM',
    campaignOwnerId: 612472,
    paymentType: null,
    productName: 'SEO',
    productFamilyName: 'SEO',
    productFamilyId: 5,
    paidDate: '2023-09-10 05:22:42.0',
    cycleEndDate: '2023-10-10',
    insertionOrderId: 0,
    budget: 800,
    amountSpent: 800,
    duration: 30,
    monthlyFee: 0,
    referrerType: null,
    renewalInfo: {
      autoRenewType: 'BOTH',
      autoRenewStatus: 'PENDING',
      numberOfRenewals: 2,
      cyclesRequested: 12,
    },
    availableOrderActions: [
      {
        type: 'RENEWAL',
        effectiveDate: '2024-08-05',
      },
      {
        type: 'MODIFICATION',
        effectiveDate: '2023-10-10',
      },
    ],
    isUnlimitedCycles: false,
    masterCampaign: {
      rel: 'rel',
      href: 'https://campaignservicev2-qa-usa.localiq-b2b-qa-us-west1.gke-bernard-rands-svc.gannettdigital.com/campaigns/4211228',
    },
    advertiser: {
      rel: 'rel',
      href: 'https://advertiserservice-qa-usa.localiq-b2b-qanx1-us-west1.gke-hilton-als-svc.gannettdigital.com/advertiser-service/rest/v1/advertisers/504277',
    },
    link: {
      rel: 'self',
      href: 'https://campaignservicev2-qa-usa.localiq-b2b-qa-us-west1.gke-bernard-rands-svc.gannettdigital.com/campaigns/4243970',
    },
  },
  {
    name: 'GCI | XMO | Limited 7 Roofing',
    id: 4247270,
    masterCampaignId: 4211396,
    productId: '1:28:2101',
    offerId: 2101,
    advertiserId: 504277,
    websiteOrderId: null,
    status: 'RUNNING',
    feeType: 'RECURRING',
    campaignOwnerId: 612472,
    paymentType: null,
    productName: 'XMO',
    productFamilyName: 'XMO',
    productFamilyId: 18,
    paidDate: '2023-09-13 10:41:10.0',
    cycleEndDate: '2023-10-16',
    insertionOrderId: 0,
    budget: 4000,
    amountSpent: 2432,
    duration: 30,
    monthlyFee: 0,
    referrerType: null,
    renewalInfo: {
      autoRenewType: 'BUDGET',
      autoRenewStatus: 'PENDING',
      numberOfRenewals: 2,
      cyclesRequested: 99,
    },
    availableOrderActions: [
      {
        type: 'MODIFICATION',
        effectiveDate: '2023-10-16',
      },
    ],
    isUnlimitedCycles: true,
    masterCampaign: {
      rel: 'rel',
      href: 'https://campaignservicev2-qa-usa.localiq-b2b-qa-us-west1.gke-bernard-rands-svc.gannettdigital.com/campaigns/4211396',
    },
    advertiser: {
      rel: 'rel',
      href: 'https://advertiserservice-qa-usa.localiq-b2b-qanx1-us-west1.gke-hilton-als-svc.gannettdigital.com/advertiser-service/rest/v1/advertisers/504277',
    },
    link: {
      rel: 'self',
      href: 'https://campaignservicev2-qa-usa.localiq-b2b-qa-us-west1.gke-bernard-rands-svc.gannettdigital.com/campaigns/4247270',
    },
  },
  {
    name: 'GCI | Limited 7 Roofing | Gold Website Core',
    id: 4265183,
    masterCampaignId: 4194414,
    productId: '1:23:2347',
    offerId: 2347,
    advertiserId: 504277,
    websiteOrderId: '4194414',
    status: 'RUNNING',
    feeType: 'LUMP-SUM',
    campaignOwnerId: 612472,
    paymentType: null,
    productName: 'Gold Website Core - Monthly Payment (US)*',
    productFamilyName: 'Websites',
    productFamilyId: 11,
    paidDate: '2023-09-30 05:26:47.0',
    cycleEndDate: '2023-10-30',
    insertionOrderId: 0,
    budget: 429,
    amountSpent: 429,
    duration: 30,
    monthlyFee: 0,
    referrerType: null,
    renewalInfo: {
      autoRenewType: 'BOTH',
      autoRenewStatus: 'PENDING',
      numberOfRenewals: 3,
      cyclesRequested: 12,
    },
    availableOrderActions: [
      {
        type: 'RENEWAL',
        effectiveDate: '2024-07-26',
      },
      {
        type: 'MODIFICATION',
        effectiveDate: '2023-10-30',
      },
    ],
    isUnlimitedCycles: false,
    masterCampaign: {
      rel: 'rel',
      href: 'https://campaignservicev2-qa-usa.localiq-b2b-qa-us-west1.gke-bernard-rands-svc.gannettdigital.com/campaigns/4194414',
    },
    advertiser: {
      rel: 'rel',
      href: 'https://advertiserservice-qa-usa.localiq-b2b-qanx1-us-west1.gke-hilton-als-svc.gannettdigital.com/advertiser-service/rest/v1/advertisers/504277',
    },
    link: {
      rel: 'self',
      href: 'https://campaignservicev2-qa-usa.localiq-b2b-qa-us-west1.gke-bernard-rands-svc.gannettdigital.com/campaigns/4265183',
    },
  },
  {
    name: 'GCI | Limited 7 Roofing | Premium Listings',
    id: 4265184,
    masterCampaignId: 4194417,
    productId: '1:9:2192',
    offerId: 2192,
    advertiserId: 504277,
    websiteOrderId: '4194417',
    status: 'RUNNING',
    feeType: 'LUMP-SUM',
    campaignOwnerId: 612472,
    paymentType: null,
    productName: 'Premium Listings 1 Location (Monthly)',
    productFamilyName: 'SEO',
    productFamilyId: 5,
    paidDate: '2023-09-30 05:26:48.0',
    cycleEndDate: '2023-10-30',
    insertionOrderId: 0,
    budget: 59,
    amountSpent: 59,
    duration: 30,
    monthlyFee: 0,
    referrerType: null,
    renewalInfo: {
      autoRenewType: 'BOTH',
      autoRenewStatus: 'PENDING',
      numberOfRenewals: 3,
      cyclesRequested: 12,
    },
    availableOrderActions: [
      {
        type: 'RENEWAL',
        effectiveDate: '2024-07-26',
      },
      {
        type: 'MODIFICATION',
        effectiveDate: '2023-10-30',
      },
    ],
    isUnlimitedCycles: false,
    masterCampaign: {
      rel: 'rel',
      href: 'https://campaignservicev2-qa-usa.localiq-b2b-qa-us-west1.gke-bernard-rands-svc.gannettdigital.com/campaigns/4194417',
    },
    advertiser: {
      rel: 'rel',
      href: 'https://advertiserservice-qa-usa.localiq-b2b-qanx1-us-west1.gke-hilton-als-svc.gannettdigital.com/advertiser-service/rest/v1/advertisers/504277',
    },
    link: {
      rel: 'self',
      href: 'https://campaignservicev2-qa-usa.localiq-b2b-qa-us-west1.gke-bernard-rands-svc.gannettdigital.com/campaigns/4265184',
    },
  },
] as any[];

// export const AddonsOfferingTypes = ['VideoBackground', 'EmailHosting', 'HipaaForm', 'ExpandableMenu', 'OnlineBooking', 'PaymentForms', 'Popup'];
export const AddonsOfferingTypes = ['VideoBackground', 'ExpandableMenu', 'PaymentForms', 'Popup'];

export const MapOfferingTypesToProductLabel = {
  Website: 'Silver Website',
  GoldSite: 'Gold Website',
  MasterAddon: 'Standalone Addons',
  LandingPage: 'Landing Page',
  EcommerceMicrosite: 'e-Commerce Microsite',
  ChatProduct: 'Smart Chatbot Silver',
  ChatPlatinumProduct: 'Live Chat Platinum',
  PremiumListing: 'Premium Listing',
  CustomWebsite: 'Custom Website',
  CustomLandingPage: 'Custom Landing Page',
  VideoBackground: 'Video Background Add-on',
  EmailHosting: 'Email Hosting Add-on',
  HipaaForm: 'HIPAA Form Add-on',
  ExpandableMenu: 'Expandable Menu Add-on',
  OnlineBooking: 'Online Booking Add-on',
  PaymentForms: 'Payment Forms Addon',
  Popup: 'Popup Addon',
  SEOStandard: 'Standard SEO',
  SEOCustom: 'Custom SEO',
  SEOBlogStandard: 'Blog Content (Standard)',
  SEOBlogEnhanced: 'Blog Content (Enhanced)',
  SMMStandard: 'Standard SMM',
  SMMCustom: 'Custom SMM',
  DMSNextCustomerCenter: 'Customer Center',
  DMSNextCustomerCenterByDash: 'Customer Center By Dash',
  PiQLandingPages: 'PerformanceiQ Attribution Landing Page',
};

export default function CampaignIds() {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const { content: business } = useSelector(selectOrderBusiness);
  const { content: campaignItems } = useSelector(selectCampaignItems);

  useEffect(() => {
    if (orderId) {
      dispatch(getIsPremiumListingsHealthcare(orderId));
    }
  }, [orderId]);
  // const gmaid = '504277'; For Testing
  useEffect(() => {
    if (orderId) {
      dispatch(getOrderBusinessByOrderId(orderId));
    }
  }, [orderId]);

  useEffect(() => {
    if (business.gmaid) {
      dispatch(getCampaignIdsWithGmaid(business.gmaid));
    }
  }, [business]);

  return (
    <ContentLoader status="idle">
      <CampaignIdsForm
        campaignData={campaignItems}
      />
    </ContentLoader>
  );
}
