/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Grid, Typography, useTheme } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IQFormInput, ValidationProvider } from '@gannettdigital/shared-react-components';
import { StepperRootProps } from 'layouts/StepperLayout';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectCustomWebsite, putCustomWebsiteCrmLoginInfo, getCustomWebsite } from 'services/customWebsiteSlice';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { selectActiveProductId } from 'services/navigationSlice';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import ErrorText from 'components/errorText/ErrorText';
import { checkKeyDown } from 'shared/sharedFunctions';
import schema from './CrmIntegration.schema';

export default function CustomWebsiteCrmIntegration(props: StepperRootProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { orderItemId } = useParams();
  const websiteId = useSelector(selectActiveProductId);
  const dispatch = useDispatch();
  const customWebsite = useSelector(selectCustomWebsite);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      crmIntegrationName: '',
      crmIntegrationInfo: '',
    },
  });

  const {
    getValues, handleSubmit, reset, register, formState: {
      isDirty, isSubmitting, isSubmitSuccessful, isValid, errors,
    },
  } = methods;

  useEffect(() => {
    props.onUpdateStatus(getValues(), isValid);
  }, [isValid]);

  useEffect(() => {
    if (websiteId) dispatch(getCustomWebsite(websiteId.toString()));
  }, [websiteId]);

  useEffect(() => {
    reset({
      crmIntegrationName: customWebsite.crmIntegrationName,
      crmIntegrationInfo: customWebsite.crmIntegrationInfo,
    });
  }, [customWebsite]);

  const onFormSubmit = () => {
    const data = getValues();
    const content = {
      crmIntegrationName: data.crmIntegrationName,
      crmIntegrationInfo: data.crmIntegrationInfo,
      loginPortalInfo: customWebsite.loginPortalInfo || '',
      loginPortalUserType: customWebsite.loginPortalUserType || '',
    };
    dispatch(putCustomWebsiteCrmLoginInfo({ orderItemId, content }));
    props.onContinue();
  };

  return (
    <>
      <Typography variant="h2" marginTop={2}>{t('pages.website.custom.crmIntegration.pageTitle')}</Typography>
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
            <Grid container marginTop={5}>
              <Grid item xs={6} marginBottom={3}>
                <IQFormInput
                  name="crmIntegrationName"
                  id="crmIntegrationName"
                  schema={schema}
                  labelText={t('pages.website.custom.crmIntegration.productName')}
                  theme={theme}
                  fontLabelWeight="bold"
                  tooltipText={t('pages.website.custom.crmIntegration.productNameTooltip')}
                  tooltipPaddingBottom={18}
                  tooltipPlacement="top"
                  fullWidth
                  showError
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <NeFormTextArea
                  {...register('crmIntegrationInfo')}
                  id="crmIntegrationInfo"
                  name="crmIntegrationInfo"
                  labelText={t('pages.website.custom.crmIntegration.integration')}
                  tooltipText={t('pages.website.custom.crmIntegration.integrationTooltip')}
                  tooltipPaddingBottom={18}
                  fullWidth
                  rowCount={3}
                  fontLabelWeight="600"
                />
                <ErrorText
                  hasError={!!errors.crmIntegrationInfo}
                  errorText={t('pages.website.custom.crmIntegration.integrationRequired')}
                />
              </Grid>
            </Grid>
          </form>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </>
  );
}
