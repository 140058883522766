import {
  IQButtonLink,
  IQFormInput, IQFormSelect,
  IQTheme, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import React, {useEffect} from 'react';
import {Dialog, DialogContent, DialogTitle, Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';
import GridItem from 'components/grid/GridItem';
import {webEventValueDropdownItems} from "pages/youtube/webevents/WebEventService";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {FormProvider, useForm} from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import schema from "pages/youtube/webevents/WebEvent.schema";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {v4 as uuidv4} from 'uuid';
import CoNavigationConfirm from "components/navigation/CoNavigationConfirm";
import GridContainer from "components/grid/GridContainer";

export default function WebEventFormDialog({
                                             open,
                                             onOpen,
                                             onClose,
                                             updateWebEvents,
                                             selectedWebEvents
                                           }) {
  const {t} = useTranslation();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      webEventValue: '',
      webEventUrl: '',
      webEventName: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: {
      isValid,
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
    },
    setValue,
  } = methods;

  useEffect(() => {
    if (selectedWebEvents) {
      // Populate form fields with selectedWebEvent data
      setValue("webEventValue", selectedWebEvents.webEventValue);
      setValue("webEventUrl", selectedWebEvents.webEventUrl);
      setValue("webEventName", selectedWebEvents.webEventName);
    }
  }, [selectedWebEvents]);

  const handleFormSubmit = async (data) => {

    let {sourceId} = selectedWebEvents || {};

    if (!sourceId) {
      // Generate a unique sourceId if it does not exist
      sourceId = uuidv4();
    }

    // Add life events with sourceId
    updateWebEvents({...data, sourceId});

    // Close the form
    handleClose();
  };

  const handleOpen = () => {
    // Reset the form state
    reset({
      webEventValue: '',
      webEventUrl: '',
      webEventName: '',
    });
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  return (
      <>
        <Grid sx={{
          marginTop: -4
        }}>
          {<IQButtonLink startIcon={<AddIcon/>} onClick={() => handleOpen()}>
            {t('pages.youtube.webEvents.addIconLabel')}
          </IQButtonLink>}
        </Grid>
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
          <DialogTitle>{t('pages.youtube.webEvents.header')}</DialogTitle>
          <DialogContent>
            <ValidationProvider schema={schema}>
              <FormProvider {...methods}>
                <form>
                  <GridContainer fullWidth>
                    <GridItem sizes={[12]}>
                      <IQFormSelect
                          id="webEventValue"
                          name="webEventValue"
                          value={selectedWebEvents?.webEventValue || ''}
                          labelText={t('pages.youtube.webEvents.websiteValue')}
                          items={webEventValueDropdownItems}
                          defaultValue=""
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          schema={schema}
                          MenuProps={{
                            style: {maxHeight: 230},
                            PaperProps: {
                              sx: {
                                '& .MuiMenuItem-root': {
                                  display: 'flex !important',
                                  justifyContent: 'flex-start !important',
                                  padding: '6px 16px'
                                },
                              },
                            }
                          }}
                      />
                    </GridItem>
                    <GridItem sizes={[12]}>
                      <IQFormInput
                          id="webEventUrl"
                          name="webEventUrl"
                          labelText={t('pages.youtube.webEvents.webEventUrl')}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          showError
                          schema={schema}
                          adornmentIcon={<InsertLinkIcon/>}
                      />
                    </GridItem>
                    <GridItem sizes={[12]}>
                      <IQFormInput
                          id="webEventName"
                          name="webEventName"
                          labelText={t('pages.youtube.webEvents.webEventName')}
                          theme={IQTheme}
                          fontLabelWeight="600"
                          fullWidth
                          schema={schema}
                      />
                    </GridItem>
                  </GridContainer>
                </form>
                <CoNavigationConfirm
                    showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
                />
              </FormProvider>
            </ValidationProvider>
          </DialogContent>
          <DialogActions>
            <Button
                onClick={handleClose}>{t('pages.youtube.advertising.adGroupOptions.CloseBtn')}</Button>
            <Button disabled={!isValid}
                    onClick={handleSubmit(handleFormSubmit)}>{t("pages.youtube.advertising.adGroupOptions.submitBtn")}</Button>
          </DialogActions>
        </Dialog>
      </>
  );
}
