import {createAsyncThunkCatchErrors} from "services/errorSlice";
import AxiosAPI from "services/common/axios";

export const sendgridCheck = createAsyncThunkCatchErrors(
    'sendgrid/check',
    async (resource) => (await AxiosAPI.post('/sendgrid/check', resource)).data,
);
export const sendgridRemove = createAsyncThunkCatchErrors(
    'sendgrid/remove',
    async (resource) => (await AxiosAPI.post('/sendgrid/remove', resource)).data,
);