import * as yup from 'yup';
import {
  IQSwitch, IQFormInput, IQTheme, IQCheckbox,
} from '@gannettdigital/shared-react-components';
import { Typography } from '@mui/material';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { useState, useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function SocialMediaRow({ account, rowNumber, showApplyToAll = true }
: { account: any, rowNumber: number, showApplyToAll?: boolean }) {
  const { t } = useTranslation();
  const [accountEnabled, setAccountEnabled] = useState(false);
  const onAcountEnabled = (enabled) => {
    setAccountEnabled(enabled);
  };

  const {
    formState: {
      errors,
    },
  } = useFormContext();

  const error = useMemo(
    () => (errors?.accounts as any)?.at(rowNumber)?.url,
    [(errors?.accounts as any)?.at(rowNumber)?.url],
  );

  useEffect(() => {
    setAccountEnabled(account.enabled);
  }, [account.enabled]);

  const socialUrlRegex = (account) => {
    const social = account.toLowerCase();
    switch (social) {
      case 'facebook':
        return /^(?:(?:http|https):\/\/)?(www\.)?(facebook.com|fb.me|fb.com)\/.+/;
      case 'twitter':
        return /^(?:(?:http|https):\/\/)?(www\.)?(twitter.com|t.co|x.com)\/.+/;
      case 'instagram':
        return /^(?:(?:http|https):\/\/)?(www\.)?(instagr.co|instagram.com)\/.+/;
      case 'youtube':
        return /^(?:(?:http|https):\/\/)?(www\.)?(?!youtube\.com\/live)(youtube.com|youtu.be)\/.+/;
      case 'vimeo':
        return /^(?:(?:http|https):\/\/)?(www\.)?(vimeo.com)\/.+/;
      case 'linkedin':
        return /^(?:(?:http|https):\/\/)?(www\.)?(linkedin.com|linked.in)\/.+/;
      case 'pinterest':
        return /^(?:(?:http|https):\/\/)?(www|ca\.)?(pinterest.com)\/.+/;
      default:
        return /^(?:(?:http|https):\/\/)?(www\.)?(([a-zA-Z]{2,63})).+/;
    }
  };

  const customValidation = yup.string()
    .matches(
      socialUrlRegex(account.name),
      t(`pages.business.location.contact.details.socialMediaAccounts.${account.name.toLowerCase()}InvalidUrl`),
    )
    .url(t(
      `pages.business.location.contact.details.socialMediaAccounts.${account.name.toLowerCase()}InvalidUrl`,
    )).required(t(
      `pages.business.location.contact.details.socialMediaAccounts.${account.name.toLowerCase()}InvalidUrl`,
    ));
  const placeholder = t(
    `pages.business.location.contact.details.socialMediaAccounts.${account.name.toLowerCase()}Placeholder`,
  );

  return (
    <GridContainer fullWidth key={account.name}>
      <GridItem sizes={[2, 2, 8]} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <Typography sx={{ lineHeight: '40px' }}>{account.name}</Typography>
        <IQSwitch
          label=""
          name={`accounts.${rowNumber}.enabled`}
          key={`accounts.${rowNumber}.enabled`}
          onChange={onAcountEnabled}
          unboxed
        />
        {accountEnabled && (
          <IQFormInput
            id={`accounts.${rowNumber}.url`}
            theme={IQTheme}
            labelText=""
            placeholder={placeholder}
            fontLabelWeight="600"
            name={`accounts.${rowNumber}.url`}
            customValidation={customValidation}
            fullWidth
            adornmentIcon={<InsertLinkIcon />}
            directErrorReference={error}
          />
        )}
      </GridItem>
      {accountEnabled && showApplyToAll && (
        <GridItem sizes={[4, 8]}>
          <GridItem />
          <IQCheckbox
            label={t('pages.business.location.contact.details.socialMediaAccounts.applyToAll')}
            field={`accounts.${rowNumber}.applyToAll`}
            name={`accounts.${rowNumber}.applyToAll`}
          />
        </GridItem>
      )}
    </GridContainer>
  );
}
