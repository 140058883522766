/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import DomainsType from 'models/DomainsType';
import { SiteOfferingTypes } from 'models/SiteOfferingTypes';
import { RootState } from './redux/store';
import { createAsyncThunkCatchErrors } from './errorSlice';
import DomainsEndpoints from './endpoints/DomainsEndpoints';

export interface DomainsState {
  status: 'idle' | 'loading' | 'failed';
  message: string;
  domains: DomainsType;
  domainsCompleted: boolean;
}

const initialState: DomainsState = {
  status: 'idle',
  message: '',
  domains: {},
  domainsCompleted: false,
};

export const getDomains = createAsyncThunkCatchErrors(
  'domains/get',
  async ({ websiteId, offeringType }:
  { websiteId: string, offeringType: SiteOfferingTypes }) =>
    DomainsEndpoints[offeringType].getDomains(websiteId)
  ,
);

export const postDomain = createAsyncThunkCatchErrors(
  'domains/post',
  async ({ websiteId, content, offeringType } :
  { websiteId: string, content: DomainsType, offeringType: string }) =>
    DomainsEndpoints[offeringType].postDomains(websiteId, content),
);

export const putDomain = createAsyncThunkCatchErrors(
  'domains/put',
  async ({ content, offeringType } :
  { content: DomainsType, offeringType: string }) =>
    DomainsEndpoints[offeringType].putDomains(content),
);

export const domainsSlice = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    clearDomainsCompleted(state) {
      state.domainsCompleted = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDomains.fulfilled, (state, action) => {
        state.domains = action.payload;
      })
      .addCase(postDomain.fulfilled, (state, action) => {
        // @ts-ignore
        state.domains = action.payload;
        state.domainsCompleted = true;
      })
      .addCase(putDomain.fulfilled, (state, action) => {
        // @ts-ignore
        state.domains = action.payload;
        state.domainsCompleted = true;
      })
      .addMatcher(
        isAnyOf(getDomains.fulfilled, putDomain.fulfilled, postDomain.fulfilled),
        (state) => {
          state.status = 'idle';
        },
      )
      .addMatcher(
        isAnyOf(getDomains.pending, putDomain.pending, postDomain.pending),
        (state) => {
          state.status = 'loading';
        },
      )
      .addMatcher(
        isAnyOf(getDomains.rejected, putDomain.rejected, postDomain.rejected),
        (state, action) => {
          state.status = 'failed';
          state.message = action.error?.message;
        },
      );
  },
});

export const { clearDomainsCompleted } = domainsSlice.actions;

export const selectDomainsContent = (state: RootState) => state.domains.domains;

export const selectDomainsStatus = (state: RootState) => state.domains.status;

export const selectDomainsMessage = (state: RootState) => state.domains.message;

export const selectDomainsCompleted = (state: RootState) => state.domains.domainsCompleted;

export default domainsSlice.reducer;
