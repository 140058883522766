import * as yup from "yup";
import i18n from 'i18n/i18n';

const {t} = i18n;

const schema = {
  targetingType: yup.string().required(t('pages.xmo.solutionsStrategy.targeting.targetingTypeErrorMessage')),
  targetingDetails: yup.string().required(t('pages.xmo.solutionsStrategy.targeting.targetingDetailsErrorMessage')),
  radiusOrKilometerDistance: yup.string().required(t('pages.xmo.solutionsStrategy.targeting.radiusOrKilometerDistanceErrorMessage')),
  salesforceBusinessAddress: yup.string().required(t('pages.xmo.solutionsStrategy.targeting.salesforceBusinessAddressErrorMessage')),
}

export default {
  yupValidations: schema,
  customValidations: {},
};