import {
  IQButtonRadioContainer,
  IQFormInput, IQTheme, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import {
  Grid, Typography,
} from '@mui/material';
import { useNavigationHandler } from 'hooks/useNavigationHandler';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { Urls } from 'navigation/Urls';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  createClient,
  editClient,
  getClientById,
  getClients,
  selectCurrentClient,
} from 'services/clientsSlice';
import { useContext, useEffect, useState } from 'react';
import { getBusinessRoles, selectBusinessRoles } from 'services/businessRolesSlice';
import { useLocation, useParams } from 'react-router';
import { getOrderBusinessByOrderId } from 'services/businessSlice';
import { SaveAndCloseContext } from 'context/SaveAndCloseContext';
import { selectOrdersContent } from 'services/ordersSlice';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { Client } from './ClientType';
import schema from './EditClient.schema';
import { defaultClient, phoneTypes } from './ClientData';
import ClientPhones, { defaultNumber, matchCountryCode } from './ClientPhones';
import ClientEmails from './ClientEmails';

export default function EditClient() {
  const navigate = useNavigationHandler();
  const saveAndCloseContext = useContext(SaveAndCloseContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history: any = useLocation();
  const { orderId, clientId } = useParams();
  const order = useSelector(selectOrdersContent);
  const { client } = useSelector(selectCurrentClient);
  const businessRoles = useSelector(selectBusinessRoles);
  const [countryCode, setCountryCode] = useState('US');

  const [businessRolesOptions, setBusinessRolesOptions] = useState(null);

  const methods = useForm<Client>({
    mode: 'all',
    defaultValues: defaultClient,
  });

  const {
    handleSubmit, getValues, reset, formState: {
      isValid, errors, isDirty, isSubmitSuccessful, isSubmitting,
    }, setValue, register,
  } = methods;

  useEffect(() => {
    dispatch(getBusinessRoles({}));
    dispatch(getOrderBusinessByOrderId(orderId));
  }, []);

  useEffect(() => {
    if (order?.country) setCountryCode(matchCountryCode[order.country]);
  }, [order]);

  useEffect(() => {
    if (clientId) dispatch(getClientById(clientId));
  }, [clientId]);

  useEffect(() => {
    if (businessRoles) {
      setBusinessRolesOptions(businessRoles.map(role => ({
        label: role.name,
        field: role.name,
        value: role.id,
      })));
    }
  }, [businessRoles]);

  useEffect(() => {
    if (client) {
      const updatedValues = JSON.parse(JSON.stringify({
        contact: {
          clientFullName: client.contact.clientFullName,
          businessRoleId: client.contact.businessRoleId,
        },
        contactPhoneNumberList: client.contactPhoneNumberList.length > 0
          ? client.contactPhoneNumberList : [defaultNumber],
        contactEmailList: client.contactEmailList.length > 0 ? client.contactEmailList : [''],
        status: client.status,
      }));

      reset(formValues => ({
        ...formValues,
        ...updatedValues,
      }));

      client.contactPhoneNumberList.forEach((phone, index) => {
        setValue(`contactPhoneNumberList.${index}`, phone);
        setValue(`contactPhoneNumberList.${index}.phoneNumber`, phone.phoneNumber);
        setValue(`contactPhoneNumberList.${index}.phoneType`, phone.phoneType);
        setValue(`contactPhoneNumberList.${index}.ext`, phone.ext);
      });
      client.contactEmailList.forEach((email, index) => {
        setValue(`contactEmailList.${index}`, email);
      });
    }
  }, [client]);

  const updateClient = async () => {
    const clientData = getValues();
    const contact = {
      contact: {
        id: clientData.contact.id,
        businessRoleId: clientData.contact.businessRoleId,
        clientFullName: clientData.contact.clientFullName,
        email: clientData.contactEmailList[0],
        firstName: clientData.contact.clientFullName,
        lastName: '',
        sourceId: null,
      },
      contactEmailList: clientData.contactEmailList,
      contactPhoneNumberList: clientData.contactPhoneNumberList.map(phone => ({
        phoneNumber: phone.phoneNumber,
        phoneType: phone.phoneType,
        ext: phone.ext,
      })),
    };

    const contactId = client.contact.id;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    contactId === null
      ? await dispatch(createClient({ orderId, contact }))
      : await dispatch(editClient({ contactId, orderId, contact }));

    dispatch(getClients(orderId));
  };

  const saveClient = async () => {
    await updateClient();
    if (history.state?.previousPath.includes('overview')) {
      return navigate.to(Urls.Overview);
    }
    if (history.state?.previousPath.includes('review')) {
      return navigate.to('review?reviewId=true');
    }
    return navigate.to(Urls.BusinessClient);
  };

  useEffect(() => {
    if (client?.contact?.id) saveAndCloseContext.setSaveAndCloseAction(saveClient);
  }, [client]);

  const nameError = 'contact.clientFullName'.split('.').reduce((acc, el) => (acc ? acc[el] : acc), errors);

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onContinue={handleSubmit(saveClient)}
      header={t('pages.business.client.edit.title')}
      continueButtonLabel={t('pages.business.client.edit.continueLabel')}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container>
            <Grid item xs={8}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12} mb={1}>
                  <Typography variant="h5">
                    {t('pages.business.client.edit.contactDetails')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <IQFormInput
                    {...register(
                      'contact.clientFullName',
                      { required: t('pages.business.client.edit.fullNameRequired') },
                    )}
                    fullWidth
                    theme={IQTheme}
                    name="contact.clientFullName"
                    id="clientFullName"
                    labelText={t('pages.business.client.edit.fullNameLabel')}
                    placeholder=""
                    fontLabelWeight="bold"
                    schema={schema}
                    directErrorReference={nameError as any}
                    showError
                  />
                </Grid>
                {countryCode !== null && (
                <ClientPhones
                  getValues={getValues}
                  addPhoneButtonLabel={t('pages.business.client.edit.addPhoneLabel')}
                  removePhoneDialogTitle="pages.business.client.removeModal.title"
                  phoneTypes={phoneTypes}
                  fieldName="contactPhoneNumberList"
                  country={countryCode}
                />
                )}
                <ClientEmails getValues={getValues} setValue={setValue} schema={schema} />

                <Grid item xs={12}>
                  <Typography
                    fontWeight="bold"
                    fontSize="1.5rem"
                  >
                    {t('pages.business.client.edit.businessRole')}
                  </Typography>
                </Grid>
                {businessRolesOptions && (
                <Grid item xs={12}>
                  <IQButtonRadioContainer
                    {...register('contact.businessRoleId', { required: true })}
                    label={t('pages.business.client.edit.selectRoleLabel')}
                    name="contact.businessRoleId"
                    optionsList={businessRolesOptions}
                  />
                </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <CoNavigationConfirm
            showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
          />
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
