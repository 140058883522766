import { useTheme } from '@emotion/react';
import { IQCheckbox } from '@gannettdigital/shared-react-components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import GridItem from 'components/grid/GridItem';
import i18n from 'i18n/i18n';
import { Trans } from 'react-i18next';
import { tooltip } from './form-sections/Tracking';

interface Props {
  fieldName: string
  tracking: boolean
}

const { t } = i18n;

export const PrepopulateCheckbox = ({ fieldName, tracking } : Props) => {
  const theme = useTheme();

  return (
    <GridItem
      sizes={[12]}
      sx={{
        backgroundColor: theme.palette.primary.light,
        '& .MuiFormControlLabel-root': {
          margin: '0',
          padding: '8px 0',
        },
        '& .MuiFormHelperText-root': {
          margin: '0',
        },
      }}
    >
      <IQCheckbox
        label={(
          <>
            <Trans
              i18nKey={!tracking
                ? 'pages.generalQuestions.prepopulateAll'
                : 'pages.generalQuestions.prepopulateTracking'}
              components={{ strong: <strong /> }}
            />
            {tooltip(t(`${!tracking
              ? 'pages.generalQuestions.prepopulateAllTooltip'
              : 'pages.generalQuestions.prepopulateTrackingTooltip'}`), <InfoOutlinedIcon />)}
          </> as unknown as string
        )}
        name={fieldName}
        field={fieldName}
        sx={{ margin: 0 }}
        hideErrors
      />
    </GridItem>
  );
};
