export const yesNoDropdownItems = [
  {value: 'Yes', description: 'Yes'},
  {value: 'No', description: 'No'},
];


export const updateOrAddDataBasedOnSourceId = (prevData: any[], newData) => {
  // Check if sourceId already exists in the previous data
  const existingIndex = prevData.findIndex(item => item.sourceId === newData.sourceId);

  if (existingIndex !== -1) {
    // If sourceId exists, update the existing item
    const updatedData = [...prevData];
    updatedData[existingIndex] = newData;
    return updatedData;
  } else {
    // If sourceId does not exist, add the new data
    return [...prevData, newData];
  }
}