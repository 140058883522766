import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BusinessFunctionality, createBusinessFunctionality } from 'services/adminSlice';
import FunctionalityForm from './FunctionalityForm';

export default function NewFunctionality() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const saveBusinessFunctionality = (functionality) => {
    dispatch(createBusinessFunctionality(functionality));
    navigate('/admin/businessFunctionalities');
  };

  const emptyFunctionality: BusinessFunctionality = {
    id: 0, description: '', name: '', notes: '', themeCount: 0, active: false,
  };

  return (
    <>
      <Typography variant="h2">New Functionality</Typography>
      <FunctionalityForm onSubmit={saveBusinessFunctionality} functionality={emptyFunctionality} />
    </>
  );
}
