import {
  Button, Card, styled, Typography,
} from '@mui/material';
import GridContainer from 'components/grid/GridContainer';
import GridItem from 'components/grid/GridItem';
import { useTranslation } from 'react-i18next';

interface Props {
  video: any
  onRemove: () => void
  onEdit: () => void
}

const StyledCard = styled(Card)`
  padding: 24px;
  border: 1px solid #716F6F;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

export default function VideoCard({ video, onRemove, onEdit }: Props) {
  const { t } = useTranslation();

  return (
    <GridItem sizes={[8]} key={video.videoUrl}>
      <StyledCard>
        <GridContainer fullWidth>
          <GridItem>
            <Typography fontSize="18px" fontWeight="bold">{video.videoTitle}</Typography>
          </GridItem>
          <GridItem sizes={[4, 8]}>
            <Typography fontSize="16px" fontWeight="bold">
              {t('pages.premiumListings.location.photos.publishedVideos.card.videoUrl')}
            </Typography>
            <Typography>{video.videoUrl}</Typography>
          </GridItem>
          <GridItem sizes={[4, 8]}>
            <Typography fontSize="16px" fontWeight="bold">
              {t('pages.premiumListings.location.photos.publishedVideos.card.videoDescription')}
            </Typography>
            <Typography>{video.description}</Typography>
          </GridItem>
          <GridItem sizes={[2, 2]}>
            <Button onClick={onEdit}>
              <Typography fontWeight="bold">
                {t('pages.premiumListings.location.photos.publishedVideos.card.edit')}
              </Typography>
            </Button>
            <Button onClick={onRemove}>
              <Typography fontWeight="bold">
                {t('pages.premiumListings.location.photos.publishedVideos.card.remove')}
              </Typography>
            </Button>
          </GridItem>
        </GridContainer>
      </StyledCard>
    </GridItem>
  );
}
