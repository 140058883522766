import {t} from "i18next";

export const yesOrNoItems = [
  {value: 'Yes', description: t('pages.xmo.sem.yes')},
  {value: 'No', description: t('pages.xmo.sem.no')},
];
export const keywordListCreationItems = [
  {value: 'Best Practices', description: t('pages.xmo.sem.bestPractices')},
  {value: 'My keyword list and expand based on best practices', description: t('pages.xmo.sem.keywordListAndExpand')},
  {value: 'Only my keyword list', description: t('pages.xmo.sem.onlyMyKeywordList')},
];
export const selectSitelinkItems = [
  {value: 'On - Best Practices', description: t('pages.xmo.sem.onBestPractices')},
  {value: 'On - Use Below', description: t('pages.xmo.sem.onUseBelow')},
  {value: 'Off', description: t('pages.xmo.sem.off')},
];
export const textAdPreferenceItems = [
  {value: 'Use my text ads', description: t('pages.xmo.sem.myTextAds')},
  {value: 'Use text ads and use best practices', description: t('pages.xmo.sem.textAdsAndBestPractices')},
  {value: 'Create text ads based on best practices', description: t('pages.xmo.sem.createOnBestPractices')},
];
export const multipleLocationsItems = [
  {value: 'Yes - Multiple Locations', description: t('pages.xmo.sem.yesMultipleLocations')},
  {value: 'No - Single Location', description: t('pages.xmo.sem.noSingleLocation')},
];
export const locationExtensionSettingsItems = [
  {value: 'Text Ad Only', description: t('pages.xmo.sem.textAdOnly')},
  {value: 'Text Ad + Location & Call Extension (not valid for multi-location)', description: t('pages.xmo.sem.textAdAndLocation')},
  {value: 'Text Ad + Call Extension', description: t('pages.xmo.sem.textAdAndCallExt')},
];
export const headLineGuidelinesDetailItems = [
  t('pages.xmo.sem.headLineGuidelinesDetail1'),
  t('pages.xmo.sem.headLineGuidelinesDetail2'),
  t('pages.xmo.sem.headLineGuidelinesDetail3'),
];
export const descriptionGuidelinesDetailItems = [
  t('pages.xmo.sem.descriptionGuidelinesDetail1'),
  t('pages.xmo.sem.descriptionGuidelinesDetail2'),
];