import React, {useState} from "react";
import {Button, Grid, Paper, Typography} from "@mui/material";
import {backgroundDefaultColor} from "styles/common_styles";
import {
  IQInputEmail,
  IQLoadingSpinner,
  IQTheme,
  ValidationProvider
} from "@gannettdigital/shared-react-components";
import {t} from "i18next";
import {FormProvider, useForm} from "react-hook-form";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useDispatch, useSelector} from "react-redux";
import {sendgridCheck, sendgridRemove} from "pages/admin/sendgrid-checker/sendgridServices";
import {selectUserEmail} from "services/userSlice";

const schema = yup.object().shape({
  userEmail: yup.string().email(t('pages.business.client.edit.emailError')).required(t('pages.business.client.edit.emailError')),
});
export default {
  yupValidations: schema,
  customValidations: {},
};
export const SendgridChecker = () => {
  const dispatch = useDispatch();
  const loggedInUserEmail = useSelector(selectUserEmail);
  const [userEmail, setUserEmail] = useState("")
  const [checkMessage, setCheckMessage] = useState("")
  const [removeMessage, setRemoveMessage] = useState("")
  const [loading, setLoading] = useState<boolean>(false);
  const [suppressionList, setSuppressionList] = useState("")
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      userEmail: "",
    },
  });
  const {setValue} = methods;

  const handleSubmit = async (data) => {
    setSuppressionList("")
    setCheckMessage("")
    setRemoveMessage("")
    setUserEmail(data?.userEmail)
    setLoading(true);
    try {
      const bundle = {
        email: data?.userEmail,
        requested_by: loggedInUserEmail,
        request_type: "Check",
        suppression_list: ""
      };

      const res = await dispatch<any>(await sendgridCheck(bundle));
      setCheckMessage(res?.payload?.message);
      setSuppressionList(res?.payload?.suppression_list)
      setValue('userEmail', "")
    } catch (error) {
      console.error(t('sendgridChecker.serviceErrorMessage'), error);
    } finally {
      setLoading(false);
    }
  };
  const handleRemove = async () => {
    setLoading(true);
    setCheckMessage("")
    try {
      const bundle = {
        email: userEmail,
        requested_by: loggedInUserEmail,
        request_type: "Remove",
        suppression_list: suppressionList
      };
      const res = await dispatch<any>(await sendgridRemove(bundle));
      setRemoveMessage(res?.payload?.message);
      setSuppressionList("")
    } catch (error) {
      console.error(t('sendgridChecker.serviceErrorMessage'), error);
    } finally {
      setLoading(false);
    }
  }
  const handleGoBack = () => {
    setSuppressionList("")
    setCheckMessage("")
    setRemoveMessage("")
  }

  return (
      <Paper elevation={2} sx={{
        backgroundColor: backgroundDefaultColor,
        padding: 4,
        maxWidth: 700,
        margin: "auto"
      }}>
        <Grid container spacing={2} justifyContent="center">
          <ValidationProvider schema={schema}>
            <FormProvider {...methods}>
              <Grid item xs={12}>
                <Typography variant="h2" align="center" gutterBottom>
                  {t('sendgridChecker.sendgridTitle')}
                </Typography>

                {checkMessage &&
                    <Typography variant="h6" color='#0046FF' align="center" gutterBottom>
                      {checkMessage}
                    </Typography>
                }
                {removeMessage &&
                    <Typography variant="h6" color='#0046FF' align="center" gutterBottom>
                      {removeMessage}
                    </Typography>
                }
                {loading &&
                    < Grid item sx={{display: "flex", justifyContent: "center"}}>
                      <IQLoadingSpinner/>
                    </Grid>
                }
                {suppressionList &&
                    <Grid mt={3}>
                      <Button variant="contained" color="primary" onClick={handleRemove} fullWidth>
                        {t('sendgridChecker.remove')}
                      </Button>
                      <Button variant="outlined" color="primary" onClick={handleGoBack} fullWidth
                              sx={{mt: 2}}>
                        {t('sendgridChecker.goBack')}
                      </Button>
                    </Grid>
                }

                {!suppressionList && <form onSubmit={methods.handleSubmit(handleSubmit)}>
                  <Grid mt={3}>
                    <IQInputEmail
                        labelText={t('sendgridChecker.emailAddress')}
                        fontLabelWeight="600"
                        theme={IQTheme}
                        label=""
                        field="userEmail"
                        fullWidth
                        adornmentIcon={<MailOutlineIcon/>}
                    />
                  </Grid>
                  <Button disabled={loading} variant="contained" color="primary" type="submit"
                          fullWidth sx={{mt: 2}}>
                    {t('sendgridChecker.submit')}
                  </Button>
                </form>
                }
              </Grid>
            </FormProvider>
          </ValidationProvider>
        </Grid>
      </Paper>
  );
};
