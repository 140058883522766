import i18n from "i18n/i18n";

const {t} = i18n;

export const subTypeOptions = [{
  label: t('pages.seo.customStrategy.subTypeOption.custom'),
  value: 'custom',
},
{
  label: t('pages.seo.customStrategy.subTypeOption.gannettSononva'),
  value: 'gannett-sononva',
},
{
  label: t('pages.seo.customStrategy.subTypeOption.nationalNeighborly'),
  value: 'national-neighborly',
},
{
  label: t('pages.seo.customStrategy.subTypeOption.gannettImagen'),
  value: 'gannett-imagen',
},
{
  label: t('pages.seo.customStrategy.subTypeOption.nationalBrightStar'),
  value: 'national-brightStar',
},
];

export const yesNoOptions = [{
  label: 'Yes',
  value: 'yes',
},
{
  label: 'No',
  value: 'no',
},
];

export const neighborlyDescriptionsOptions = [
  {
    description: t('pages.seo.customStrategy.neighborlyOption.neighborlyTier1'),
    value: 'neighborly1',
  },
  {
    description: t('pages.seo.customStrategy.neighborlyOption.neighborlyTier2'),
    value: 'neighborly2',
  },
  {
    description: t('pages.seo.customStrategy.neighborlyOption.neighborlyTier3'),
    value: 'neighborly3',
  },
  {
    description: t('pages.seo.customStrategy.neighborlyOption.neighborlyTier4'),
    value: 'neighborly4',
  },
  {
    description: t('pages.seo.customStrategy.neighborlyOption.neighborlyTier5'),
    value: 'neighborly5',
  },
  {
    description: t('pages.seo.customStrategy.neighborlyOption.neighborlyTier6'),
    value: 'neighborly6',
  },
];

export const brightStarDescriptionOptions = [
  {
    description: t('pages.seo.customStrategy.brightStarOptions.brightStarBasic'),
    value: 'basic',
  },
  {
    description: t('pages.seo.customStrategy.brightStarOptions.brightStarStandard'),
    value: 'standard',
  },
  {
    description: t('pages.seo.customStrategy.brightStarOptions.brightStarDeluxe'),
    value: 'deluxe',
  },
];

export const neighborlyOptions = [
  {
    label: t('pages.seo.customStrategy.neighborlyOption.neighborlyTier1'),
    value: 'neighborly1',
  },
  {
    label: t('pages.seo.customStrategy.neighborlyOption.neighborlyTier2'),
    value: 'neighborly2',
  },
  {
    label: t('pages.seo.customStrategy.neighborlyOption.neighborlyTier3'),
    value: 'neighborly3',
  },
  {
    label: t('pages.seo.customStrategy.neighborlyOption.neighborlyTier4'),
    value: 'neighborly4',
  },
  {
    label: t('pages.seo.customStrategy.neighborlyOption.neighborlyTier5'),
    value: 'neighborly5',
  },
  {
    label: t('pages.seo.customStrategy.neighborlyOption.neighborlyTier6'),
    value: 'neighborly6',
  },
];

export const brightStarOptions = [
  {
    label: t('pages.seo.customStrategy.brightStarOptions.brightStarBasic'),
    value: 'basic',
  },
  {
    label: t('pages.seo.customStrategy.brightStarOptions.brightStarStandard'),
    value: 'standard',
  },
  {
    label: t('pages.seo.customStrategy.brightStarOptions.brightStarDeluxe'),
    value: 'deluxe',
  },
];
