import { ValidationProvider } from '@gannettdigital/shared-react-components';
import {
  Box,
  Button,
  Grid, Theme, Typography, styled, useTheme,
} from '@mui/material';
import CoFileUploader from 'components/fileUploader/CoFileUploader';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { WebsiteOfferingTypes } from 'services/websitesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ProductTypes, selectFilesGetty } from 'services/fileUploaderSlice';
import { ComponentProps, useEffect } from 'react';
import useUpdateAssets from 'hooks/useUpdateAssets';
import {
  getDetailsFromShortUrl, selectSharedUrl, sendConfirmationEmail,
} from 'services/clientStockAssetsSlice';
import { backgroundDefaultColor } from 'styles/common_styles';
import schema from './StockAssets.schema';

type ThemeProps = {
  theme: Theme;
};

const RootStyle = styled('div')<ThemeProps>`
  background-color: ${backgroundDefaultColor};
`;

const AssetsStyled = styled(Grid)`
  font-size: 16px;
  font-weight: 600;
  font-family: Unify Sans;
  margin-bottom: 8px;
`;

const SelectAssetsStyled = styled(Grid)`
  font-size: 16px;
  font-weight: 400;
  font-family: Unify Sans;
  margin-bottom: 16px;
`;

const gettyUploaderOptions :Pick<
ComponentProps<typeof CoFileUploader>,
'gettyImagesUploaderOptions'>['gettyImagesUploaderOptions'] = {
  name: 'stockList',
  productType: ProductTypes.EXISTING_ASSETS,
  showCheckboxes: true,
  showComments: true,
  displayOnly: 'images',
};

const productType :ProductTypes = ProductTypes.EXISTING_ASSETS;

const mapOfferingType = {
  'e-commerce microsite': 'ecommerce_microsites',
};

export default function SelectStockAssets() {
  const { shortUrl, token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const filesGetty = useSelector(selectFilesGetty, (prev, curr) => prev.content.length === curr.content.length);
  const sharedUrlContent = useSelector(selectSharedUrl);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      stockList: filesGetty.content || [],
    },
  });

  const { watch } = methods;

  const { triggerAllUpdates } = useUpdateAssets(
    watch,
    undefined,
    gettyUploaderOptions.name,
  );

  useEffect(() => {
    if (shortUrl && token) dispatch(getDetailsFromShortUrl({ url: shortUrl, token }));
  }, [shortUrl, token]);

  const submitAssets = async () => {
    const request = {
      orderId: sharedUrlContent.orderId,
      token: sharedUrlContent.urlToken,
      urls: filesGetty.content.map(file => ({
        fileName: file.mediaId,
        url: file.url,
      })),
    };
    triggerAllUpdates();
    await dispatch(sendConfirmationEmail(request));
    navigate(`/public/thank_you/${shortUrl}`);
  };

  return (
    <RootStyle theme={theme}>
      <Box sx={{
        minHeight: '100vh',
        maxWidth: '968px',
        marginRight: 'auto',
        marginLeft: 'auto',
        padding: '50px 0',
        boxSizing: 'border-box',
      }}
      >
        <Grid container spacing={0}>
          <ValidationProvider schema={schema}>
            <FormProvider {...methods}>
              <Grid container
                item xs={8}
                sx={{ margin: '0 auto' }}
              >
                <Typography variant="h2" mb={5}>Select Photos</Typography>
                <AssetsStyled item xs={12}>
                  Assets
                </AssetsStyled>
                <SelectAssetsStyled item xs={12}>
                  Please work with your client to select 10-12 images they would like to utilize on their landing pages.
                </SelectAssetsStyled>
                <Grid item mb={3}>
                  {Object.keys(sharedUrlContent).length > 0 && (
                  <CoFileUploader
                    schema={schema}
                    productType={productType}
                    offeringType={mapOfferingType[sharedUrlContent.offeringType] as WebsiteOfferingTypes}
                    orderId={sharedUrlContent.orderId}
                    orderItemId={sharedUrlContent.sourceId}
                    showGetty
                    productId={sharedUrlContent.websiteId}
                    gettyImagesUploaderOptions={gettyUploaderOptions}
                    containerStyles={{
                      '& .MuiGrid-item > div > p:first-of-type': { lineHeight: '12px', mb: 0 },
                      '& .MuiGrid-item > div:has(> div)': { width: '374px' },
                      '& .MuiGrid-item > button + div': { width: '374px' },
                      '& > button': { minWidth: '600px', marginBottom: '20px' },
                      '& ul': { maxWidth: '600px' },
                    }}
                  />
                  )}
                </Grid>
                <Button variant="contained" sx={{ padding: '8px 22px' }} onClick={submitAssets}>
                  <Typography fontSize={22}>Done</Typography>
                </Button>
              </Grid>
            </FormProvider>
          </ValidationProvider>
        </Grid>
      </Box>
    </RootStyle>
  );
}
