import {
  createThemeCollateral, getThemeBenefit, getThemeBenefitsId, getThemeById, getThemeCollaterals,
  getThemeFeature, getThemeFeaturesId, postThemeBenefits, postThemeFeatures, selectCurrentTheme,
  selectCurrentThemeBenefits, selectCurrentThemeCollaterals,
  selectCurrentThemeFeatures, updateTheme, updateThemeCollateral,
} from 'services/themesSlice';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  getMarketCategories, getThemeBenefits, getThemeFamilies, getThemeFeatures, getBusinessFunctionalities,
  getCountries, getCountriesByThemeId, selectProducts,
} from 'services/adminSlice';
import { useEffect } from 'react';
import ThemeForm from './ThemeForm';

export default function EditTheme() {
  const currentTheme = useSelector(selectCurrentTheme);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { themeId } = useParams();
  const currentThemeFeatures = useSelector(selectCurrentThemeFeatures);
  const currentThemeBenefits = useSelector(selectCurrentThemeBenefits);
  const currentThemeCollaterals = useSelector(selectCurrentThemeCollaterals);
  const products = useSelector(selectProducts);

  const findById = (items, stringId) => items.find(item => item.id === parseInt(stringId, 10));

  useEffect(() => {
    dispatch(getThemeById(parseInt(themeId, 10)));
    dispatch(getThemeBenefitsId(parseInt(themeId, 10)));
    dispatch(getThemeCollaterals(parseInt(themeId, 10)));
    dispatch(getThemeFeaturesId(parseInt(themeId, 10)));
    dispatch(getCountriesByThemeId(parseInt(themeId, 10)));
    dispatch(getMarketCategories({}));
    dispatch(getThemeBenefits({}));
    dispatch(getThemeFeatures({}));
    dispatch(getThemeFamilies({}));
    dispatch(getBusinessFunctionalities({}));
    dispatch(getCountries({}));
  }, [themeId]);

  const fetch = async (id, action) => {
    await dispatch(action(id));
  };

  useEffect(() => {
    if (currentThemeFeatures.idList.length > 0) {
      currentThemeFeatures.idList.forEach(async id => {
        await fetch(id, getThemeFeature);
      });
    }
  }, [currentThemeFeatures.idList]);

  useEffect(() => {
    if (currentThemeBenefits.idList.length > 0) {
      currentThemeBenefits.idList.forEach(async id => {
        await fetch(id, getThemeBenefit);
      });
    }
  }, [currentThemeBenefits.idList]);

  const generateContent = (obj) => obj.map(e => e.id);

  const saveTheme = async (data) => {
    const theme = {
      countries: generateContent(data.countryAvailability),
      theme: {
        active: data.available,
        businessFunctionalityId: +data.businessFunctionalityId,
        callToAction: data.callToAction,
        color: data.color,
        compositeScreenshot: (!data.compositeScreenshotFile && currentTheme.compositeScreenshot)
          ? currentTheme.compositeScreenshot : data.compositeScreenshotFile,
        description: data.description,
        designStyle: data.designStyle,
        detailsAboveTheFold: data.detailsAbove,
        detailsBelowTheFold: data.detailsBelow,
        familyId: +data.familyId,
        featured: data.featured,
        id: parseInt(themeId, 10),
        marketCategoryId: +data.marketCategoryId,
        notes: data.notes,
        product: findById(products, data.productId)?.name,
        screenshotImage: (!data.screenshotImageFile && currentTheme.screenshotImage)
          ? currentTheme.screenshotImage : data.screenshotImageFile,
        sortId: data.sort,
        themePreview: (!data.themePreviewFile && currentTheme.themePreview)
          ? currentTheme.themePreview : (data.themePreviewFile?.toString() || null),
        title: data.title,
        url: data.url,
      },
    };

    const existingCollaterals = [];
    const newCollaterals = [];
    data.collaterals.forEach(collateral => {
      const filterCollateral = currentThemeCollaterals.filter(item => item.id === collateral.id);
      if (filterCollateral.length > 0) {
        existingCollaterals.push(collateral);
      } else {
        newCollaterals.push({
          themeId,
          label: collateral.label,
          collateralBlurb: collateral.collateralBlurb,
          uploadedFile: collateral.uploadedFile,
        });
      }
    });

    if (existingCollaterals.length > 0) existingCollaterals.forEach(c => dispatch(updateThemeCollateral(c)));
    if (newCollaterals.length > 0) newCollaterals.forEach(c => dispatch(createThemeCollateral(c)));
    dispatch(postThemeFeatures({ themeId, content: generateContent(data.themeFeatures) }));
    dispatch(postThemeBenefits({ themeId, content: generateContent(data.themeBenefits) }));
    await dispatch(updateTheme(theme));
    navigate('/admin/themes');
  };

  return Object.keys(currentTheme).length > 0 && (
    <>
      <Typography variant="h2">{`Editing theme "${currentTheme.title}"`}</Typography>
      <Link to={`/ourThemes/detail?id=${currentTheme.id}`} style={{ marginRight: '10px' }}>
        <Typography>See this page</Typography>
      </Link>
      <ThemeForm onSubmit={saveTheme} themeData={currentTheme} />
    </>
  );
}
