import i18n from 'i18n/i18n';

import {
  TrackingSchema,
  TrackingUISchema,
  TrackingCustomValidate
} from 'pages/generic-product/details/product-schema/aus-product-schema/TrackingSchema';
import {
  LinkedinCustomValidate,
  LinkedinSchema,
  LinkedinUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/LinkedinSchema';
import {
  WebEventsCustomValidate,
  WebEventsSchema,
  WebEventsUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/WebEventsSchema';
import {
  CallTrackingCustomValidate,
  CallTrackingSchema,
  CallTrackingUISchema,
} from 'pages/generic-product/details/product-schema/aus-product-schema/CallTrackingSchema';
import {
  TrackingNumberDetailsCustomValidate,
  TrackingNumberDetailsSchema,
  TrackingNumberDetailsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/TrackingNumberDetails";
import {
  NotesAndInstructionsCustomValidate,
  NotesAndInstructionsSchema,
  NotesAndInstructionsUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/NotesAndInstructionsSchema";
import {
  CampaignLinkedinCustomValidate,
  CampaignLinkedinSchema,
  CampaignLinkedinUISchema
} from "pages/generic-product/details/product-schema/aus-product-schema/CampaignLinkedinSchema";

const {t} = i18n;

export const AusLinkedinSchema = (data) => {
  const schema = [
    {
      type: 'object',
      title: t('pages.ausProduct.linkedin.details'),
      schema: CampaignLinkedinSchema(data),
      uiSchema: CampaignLinkedinUISchema,
      customValidate: CampaignLinkedinCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.tracking.title'),
      schema: TrackingSchema(data),
      uiSchema: TrackingUISchema,
      customValidate: TrackingCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.linkedin.title'),
      schema: LinkedinSchema(data),
      uiSchema: LinkedinUISchema,
      customValidate: LinkedinCustomValidate,
    },
    {
      type: 'object',
      title: t('pages.ausProduct.notesAndInstructions.title'),
      schema: NotesAndInstructionsSchema(data),
      uiSchema: NotesAndInstructionsUISchema,
      customValidate: NotesAndInstructionsCustomValidate,
    },
  ];

  const trackingRequirements = data.trackingRequirements;
  const indexToInsert = 2;

  if (trackingRequirements === "Call and Web Event Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    });
    schema.splice(indexToInsert + 1, 0, {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    });
    schema.splice(indexToInsert + 2, 0, {
      type: 'object',
      title: t('pages.ausProduct.trackingNumber1Details.title'),
      schema: TrackingNumberDetailsSchema(data),
      uiSchema: TrackingNumberDetailsUISchema(data),
      customValidate: TrackingNumberDetailsCustomValidate,
    });
  } else if (trackingRequirements === "Web Event Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.webEvents.title'),
      schema: WebEventsSchema(data),
      uiSchema: WebEventsUISchema(data),
      customValidate: WebEventsCustomValidate,
    });
  } else if (trackingRequirements === "Call Tracking") {
    schema.splice(indexToInsert, 0, {
      type: 'object',
      title: t('pages.ausProduct.callTracking.title'),
      schema: CallTrackingSchema(data),
      uiSchema: CallTrackingUISchema,
      customValidate: CallTrackingCustomValidate,
    });
    schema.splice(indexToInsert + 1, 0, {
      type: 'object',
      title: t('pages.ausProduct.trackingNumber1Details.title'),
      schema: TrackingNumberDetailsSchema(data),
      uiSchema: TrackingNumberDetailsUISchema(data),
      customValidate: TrackingNumberDetailsCustomValidate,
    });
  }

  return schema;
};

export const AusLinkedinUISchema = (data) => ({
  'ui:order': [
    ...CampaignLinkedinUISchema['ui:order'],
    ...TrackingUISchema['ui:order'],
    ...LinkedinUISchema['ui:order'],
    ...WebEventsUISchema(data)['ui:order'],
    ...CallTrackingUISchema['ui:order'],
    ...TrackingNumberDetailsUISchema(data)['ui:order'],
    ...NotesAndInstructionsUISchema['ui:order'],
  ],
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AusLinkedinValidate = (formData: any, errors: any, uiSchema: any) => errors;
