import {t} from "i18next";
export const dealerSystemProviderOptions = [
  {field: 'homeNet', label:t('pages.xmo.dynamic.dealerSystemProviderOptions.homeNet'),value:"homeNet"},
  {field: 'vAuto', label: t('pages.xmo.dynamic.dealerSystemProviderOptions.vAuto'),value:"vAuto"},
];
export const genderOptionRadioItems = [
  {field: 'male', label: t('pages.xmo.dynamic.male'), value: "male"},
  {field: 'female', label: t('pages.xmo.dynamic.female'), value: "female"},
  {field: 'both', label: t('pages.xmo.dynamic.both'), value: "both"},
];

export const callToActionDropDown = [
  {value: 'No Button', description: t('pages.xmo.dynamic.noButton')},
  {value: 'Apply Now', description: t('pages.xmo.dynamic.applyNow')},
  {value: 'Download', description: t('pages.xmo.dynamic.download')},
  {value: 'Get Quote', description: t('pages.xmo.dynamic.getQuote')},
  {value: 'Learn More', description: t('pages.xmo.dynamic.learnMore')},
  {value: 'Sign Up', description: t('pages.xmo.dynamic.signUp')},
  {value: 'Subscribe', description: t('pages.xmo.dynamic.subscribe')}
];

export const clientWantTheirAdsToAppearList = [
  {id: 'Mobile News Feed', label: t('pages.xmo.dynamic.mobileNewsFeed'), checked: false},
  {id: 'Facebook Audience Network', label: t('pages.xmo.dynamic.facebookAudienceNetwork'), checked: false},
  {id: 'Desktop News Feed', label: t('pages.xmo.dynamic.desktopNewsFeed'), checked: false},
  {id: 'MarketPlace', label: t('pages.xmo.dynamic.marketPlace'), checked: false},
  {id: 'Instagram', label: t('pages.xmo.dynamic.instagram'), checked: false},
];

export const leadAdsDynamicFormContactFieldsList = [
  {id: 'Phone Number', label: t('pages.xmo.dynamic.phoneNumber'), checked: false},
  {id: 'Zip Code', label: t('pages.xmo.dynamic.zipCode'), checked: false},
  {id: 'Email', label: t('pages.xmo.dynamic.email'), checked: false},
];

export const detailTargetingPreferencesList = [
  {
    id: 'Demographic Targeting',
    label: t('pages.xmo.dynamic.demographicTargeting'),
    checked: false,
    tooltipMessage: t('pages.xmo.dynamic.demographicTargetingTooltipMessage')
  },
  {
    id: 'Behavioral Targeting',
    label: t('pages.xmo.dynamic.behavioralTargeting'),
    checked: false,
    tooltipMessage: t('pages.xmo.dynamic.behavioralTargetingTooltipMessage')
  },
  {
    id: 'Interest Targeting',
    label: t('pages.xmo.dynamic.interestTargeting'),
    checked: false,
    tooltipMessage: t('pages.xmo.dynamic.interestTargetingTooltipMessage')
  },
];

export const yesNoList = [
  {value: 'Yes', description: t('pages.xmo.dynamic.yes')},
  {value: 'No', description: t('pages.xmo.dynamic.no')}
];

export const noneCustomList = [
  {value: 'None', description: t('pages.xmo.dynamic.none')},
  {value: 'Custom', description: t('pages.xmo.dynamic.custom')}
]
export const visualStrategyClientWantToEmployList = [
  {value: 'Single Image', description: t('pages.xmo.dynamic.singleImage')},
  {value: 'Multiple Videos', description: t('pages.xmo.dynamic.multipleVideos')},
  {value: 'Single Video', description: t('pages.xmo.dynamic.singleVideo')},
  {value: 'Image & Video Combination', description: t('pages.xmo.dynamic.imageVideoCombination')},
  {value: 'Multiple Images', description: t('pages.xmo.dynamic.multipleImages')}
]

export const clientBeProvidingImagesToUseList = [
  {value: 'Yes - All', description: t('pages.xmo.dynamic.yesAll')},
  {value: 'No', description: t('pages.xmo.dynamic.no')},
  {value: 'Yes - Some', description: t('pages.xmo.dynamic.yesSome')}
]

export const demographicTargetingList = [
  t('pages.xmo.dynamic.demographicHelp1'),
  t('pages.xmo.dynamic.demographicHelp2'),
  t('pages.xmo.dynamic.demographicHelp3'),
  t('pages.xmo.dynamic.demographicHelp4'),
  t('pages.xmo.dynamic.demographicHelp5'),
  t('pages.xmo.dynamic.demographicHelp6'),
  t('pages.xmo.dynamic.demographicHelp7'),
  t('pages.xmo.dynamic.demographicHelp8'),
]
export const behavioralTargetingList = [
  t('pages.xmo.dynamic.behavioralHelp1'),
  t('pages.xmo.dynamic.behavioralHelp2'),
  t('pages.xmo.dynamic.behavioralHelp3'),
  t('pages.xmo.dynamic.behavioralHelp4'),
  t('pages.xmo.dynamic.behavioralHelp5'),
  t('pages.xmo.dynamic.behavioralHelp6'),
  t('pages.xmo.dynamic.behavioralHelp7'),
  t('pages.xmo.dynamic.behavioralHelp8'),
]
export const interestTargetingList = [
  t('pages.xmo.dynamic.interestHelp1'),
  t('pages.xmo.dynamic.interestHelp2'),
  t('pages.xmo.dynamic.interestHelp3'),
  t('pages.xmo.dynamic.interestHelp4'),
  t('pages.xmo.dynamic.interestHelp5'),
  t('pages.xmo.dynamic.interestHelp6'),
  t('pages.xmo.dynamic.interestHelp7'),
  t('pages.xmo.dynamic.interestHelp8'),
  t('pages.xmo.dynamic.interestHelp9'),
]
export const inventoryToFeatureCampaignOptions = [
  { label: t('pages.xmo.dynamic.inventoryToFeatureCampaignOptions.all') },
  { label: t('pages.xmo.dynamic.inventoryToFeatureCampaignOptions.used') },
  { label: t('pages.xmo.dynamic.inventoryToFeatureCampaignOptions.new') },
];
