import {
  Step, StepConnector, StepIconProps, StepLabel, Stepper, Typography, keyframes, stepConnectorClasses, styled,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectOrderItemsWithCidStatus } from 'services/campaignSlice';
import { StatusTrackerStages } from './OrderDetailsConstants';
import { getCIDStatus } from './utils';

interface Props {
  status: string;
  productName: string;
  product?: any;
}

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.root}`]: {
    left: '-50%',
    right: '50%',
  },
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 3.5,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#0046FF',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#0046FF',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 1.5,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#716F6F',
    borderRadius: 1,
  },
}));

const pulseRing = keyframes`
  0% { transform: scale(.5); }
  80%, 100% { opacity: 0; }
`;

const pulseDot = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
`;

const CustomStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: '#716F6F',
  zIndex: 1,
  color: '#fff',
  width: 8,
  height: 8,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  '&.highlight': {
    background: 'var(--pulsating-dot, #BA5012)',
    '&:before': {
      backgroundColor: 'var(--pulsating-dot, #BA5012)',
    },
  },
  ...(ownerState.active && {
    animation: `${pulseDot} 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite`,
    backgroundColor: 'var(--pulsating-dot, #0046FF)',
    '&:before': {
      animation: `${pulseRing} 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite`,
      backgroundColor: 'var(--pulsating-dot, #0046FF)',
      borderRadius: '8px',
      content: '""',
      display: 'block',
      height: '100%',
      left: '0',
      position: 'relative',
      top: '0',
      width: '100%',
      transform: 'scale(3)',
    },
  }),
  ...(ownerState.completed && {
    backgroundColor: '#0046FF',
  }),
}));

function CustomStepIcon(props: StepIconProps) {
  const {
    active, completed, classes,
  } = props;

  return (
    <CustomStepIconRoot ownerState={{ completed, active }} className={classes ? classes.active : ''} />
  );
}

const getActiveStep = (isPaymentStatus, isAssignmentsStatus, product, steps, statusBasedOnCID, isGenericProduct) => {
  if (isGenericProduct) {
    if (product?.trackerStatus === 'Payment Verified' || product?.orderStatus?.neonStatus === 'Payment Verified') {
      return 2;
    }
  }
  if (!((isPaymentStatus || isAssignmentsStatus)
    && product?.campaignId !== null)) return steps.indexOf(statusBasedOnCID);
  if (isPaymentStatus) return 1;
  if (isAssignmentsStatus) return 2;
  return steps.indexOf(statusBasedOnCID) || 1;
};

export default function OrderTracker({ status, productName, product }: Props) {
  const campaignStatuses = useSelector(selectOrderItemsWithCidStatus);
  const isPaymentStatus = status === 'Payment Pending';
  const isAssignmentsStatus = status === 'Assignments Pending';
  const statusBasedOnCID = product?.campaignId === null ? 'Order Submitted' : status;
  const [cidStatus, setCidStatus] = useState('');
  const [isGenericProduct, setIsGenericProduct] = useState(false);
  const steps = useMemo(() => {
    const productStages = StatusTrackerStages[productName];
    if (!productStages) {
      setIsGenericProduct(true);
      return StatusTrackerStages.GenericProduct;
    }
    return productStages;
  }, [productName]);
  const indexStatusCondition = (index) => ((index === 1 && isPaymentStatus) || (index === 2 && isAssignmentsStatus));
  const activeStep = useMemo(() => getActiveStep(isPaymentStatus, isAssignmentsStatus, product, steps, statusBasedOnCID, isGenericProduct), [isGenericProduct, steps]);

  const statusBasedStyling = (index) => {
    if (product?.campaignId !== null) {
      return (indexStatusCondition(index)) && { classes: { active: 'highlight' } };
    }

    return (index === 0 && cidStatus === 'Failed') && { classes: { active: 'highlight' } };
  };

  useEffect(() => {
    if (campaignStatuses.length > 0) {
      const status = getCIDStatus(campaignStatuses, product.id);
      setCidStatus(status);
    }
  }, [campaignStatuses]);

  return (
    <Stepper activeStep={activeStep} sx={{ width: '100%' }} alternativeLabel
      connector={<CustomConnector />}
    >
      {steps.map((step, index) => (
        <Step key={step}>
          <StepLabel StepIconComponent={CustomStepIcon} StepIconProps={
            statusBasedStyling(index)
          }
          >
            <Typography color="black">
              {(indexStatusCondition(index) && product?.campaignId !== null) ? statusBasedOnCID : step}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
