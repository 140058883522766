import React from 'react';

import { IQTheme } from '@gannettdigital/shared-react-components';
import { SxProps } from '@mui/system';

import { t } from 'i18next';

import { generateTooltipTextElement } from 'shared/generateTooltipText';

type ListOption = {
  name: RadioOptionTypes;
  label: string;
  fontTitleWeight: string;
  options: {
    name: string;
    value: string;
    label: string;
    field: string;
    tooltipText?: string | React.ReactNode;
    tooltipPlacement?: 'bottom' | 'right' | 'top' | 'left';
    arrow?: boolean;
  }[];
};

enum RadioOptionTypes {
  type = 'type',
  trigger = 'trigger',
  primaryTypeLead = 'primaryTypeLead',
}

const boldFontTitleWeight = '600';

export const radioButtonOptions: ListOption[] = [
  {
    name: RadioOptionTypes.type,
    label: `${t('pages.addons.popUp.typeLabel')} *`,
    fontTitleWeight: boldFontTitleWeight,
    options: [
      {
        name: RadioOptionTypes.type,
        value: 'callToAction',
        label: t('pages.addons.popUp.radioButtons.type.callToAction.label'),
        field: 'type',
        tooltipText: generateTooltipTextElement(t('pages.addons.popUp.radioButtons.type.callToAction.tooltipText'), {
          render: true,
          href: 'https://euclid3.localiq.site/pop-up/',
          linkText: 'View Example',
          referrerPolicy: 'no-referrer',
          rel: 'noreferrer',
          target: '_blank',
          style: { color: 'white' },
        }),
        tooltipPlacement: 'top',
        arrow: false,
      },
      {
        name: RadioOptionTypes.type,
        value: 'informational',
        label: t('pages.addons.popUp.radioButtons.type.informational.label'),
        field: 'type',
        tooltipText: generateTooltipTextElement(t('pages.addons.popUp.radioButtons.type.informational.tooltipText'), {
          render: true,
          href: 'https://hagar4.localiq.site/pop-up/',
          linkText: 'View Example',
          referrerPolicy: 'no-referrer',
          rel: 'noreferrer',
          target: '_blank',
          style: { color: 'white' },
        }),
        tooltipPlacement: 'top',
        arrow: false,
      },
      {
        name: RadioOptionTypes.type,
        value: 'specialOffer',
        label: t('pages.addons.popUp.radioButtons.type.specialOffer.label'),
        field: 'type',
        tooltipText: generateTooltipTextElement(t('pages.addons.popUp.radioButtons.type.specialOffer.tooltipText'), {
          render: true,
          href: 'https://crestone.localiq.site/services/pop-up/',
          linkText: 'View Example',
          referrerPolicy: 'no-referrer',
          rel: 'noreferrer',
          target: '_blank',
          style: { color: 'white' },
        }),
        tooltipPlacement: 'top',
        arrow: false,
      },
      {
        name: RadioOptionTypes.type,
        value: 'leadsGeneration',
        label: t('pages.addons.popUp.radioButtons.type.leadsGeneration.label'),
        field: 'type',
        tooltipText: generateTooltipTextElement(t('pages.addons.popUp.radioButtons.type.leadsGeneration.tooltipText'), {
          render: true,
          href: 'https://cambridge3.localiq.site/pop-up/',
          linkText: 'View Example',
          referrerPolicy: 'no-referrer',
          rel: 'noreferrer',
          target: '_blank',
          style: { color: 'white' },
        }),
        tooltipPlacement: 'top',
        arrow: false,
      },
    ],
  },
  {
    name: RadioOptionTypes.trigger,
    label: `${t('pages.addons.popUp.triggerLabel')} *`,
    fontTitleWeight: boldFontTitleWeight,
    options: [
      {
        name: RadioOptionTypes.trigger,
        value: 'arrivalOnPage',
        label: t('pages.addons.popUp.radioButtons.trigger.arrivalOnPage.label'),
        field: 'trigger',
        tooltipText: generateTooltipTextElement(
          t('pages.addons.popUp.radioButtons.trigger.arrivalOnPage.tooltipText'),
          { render: false },
        ),
        tooltipPlacement: 'top',
        arrow: false,
      },
      {
        name: RadioOptionTypes.trigger,
        value: 'timeDelay',
        label: t('pages.addons.popUp.radioButtons.trigger.timeDelay.label'),
        field: 'trigger',
        tooltipText: generateTooltipTextElement(t('pages.addons.popUp.radioButtons.trigger.timeDelay.tooltipText'), {
          render: false,
        }),
        tooltipPlacement: 'top',
        arrow: false,
      },
      {
        name: RadioOptionTypes.trigger,
        value: 'onScroll',
        label: t('pages.addons.popUp.radioButtons.trigger.onScroll.label'),
        field: 'trigger',
        tooltipText: generateTooltipTextElement(t('pages.addons.popUp.radioButtons.trigger.onScroll.tooltipText'), {
          render: false,
        }),
        tooltipPlacement: 'top',
        arrow: false,
      },
      {
        name: RadioOptionTypes.trigger,
        value: 'exitIntent',
        label: t('pages.addons.popUp.radioButtons.trigger.exitIntent.label'),
        field: 'trigger',
        tooltipText: generateTooltipTextElement(t('pages.addons.popUp.radioButtons.trigger.exitIntent.tooltipText'), {
          render: false,
        }),
        tooltipPlacement: 'top',
        arrow: false,
      },
    ],
  },
  {
    name: RadioOptionTypes.primaryTypeLead,
    label: `${t('pages.addons.popUp.leadLabel')} *`,
    fontTitleWeight: boldFontTitleWeight,
    options: [
      {
        name: RadioOptionTypes.primaryTypeLead,
        value: 'formSubmissions',
        label: t('pages.addons.popUp.radioButtons.primaryLead.formSubmissions.label'),
        field: 'primaryTypeLead',
      },
      {
        name: RadioOptionTypes.primaryTypeLead,
        value: 'phoneCalls',
        label: t('pages.addons.popUp.radioButtons.primaryLead.phoneCalls.label'),
        field: 'primaryTypeLead',
      },
    ],
  },
];

type FormSectionCheckbox = {
  field: string;
  label: string;
  title: string;
};

export const formSectionCheckboxes: FormSectionCheckbox[] = [
  { field: 'firstName', title: '', label: t('pages.addons.popUp.features.form.firstName') },
  { field: 'lastName', title: '', label: t('pages.addons.popUp.features.form.lastName') },
  { field: 'email', title: '', label: t('pages.addons.popUp.features.form.email') },
  { field: 'phone', title: '', label: t('pages.addons.popUp.features.form.phone') },
  { field: 'other', title: '', label: t('pages.addons.popUp.features.form.other') },
];

type FeaturesTextAreas = {
  gridItemSx: SxProps<typeof IQTheme>;
  id: string;
  subDescription?: string;
  name: string;
  labelText: string;
  required: boolean;
  fullWidth: boolean;
  supportText?: string;
  fontLabelWeight: string;
  tooltipText?: string | React.ReactNode;
  tooltipPlacement?: 'bottom' | 'right' | 'top' | 'left';
  tooltipArrow?: boolean;
};

export const featuresTextAreas: FeaturesTextAreas[] = [
  {
    gridItemSx: {
      'div > label': { pl: 0 },
      '&.MuiGrid-root': { maxWidth: '452px' },
      mt: '52px',
      'label:nth-child(2)': { mt: '14px', mb: '-1px' },
    },
    id: 'content-and-design-form',
    name: 'contentDesignInformation',
    labelText: t('pages.addons.popUp.features.contentAndDesign.label'),
    required: true,
    fullWidth: true,
    supportText: t('pages.addons.popUp.features.contentAndDesign.supportText'),
    fontLabelWeight: '600',
    tooltipText: generateTooltipTextElement(t('pages.addons.popUp.features.contentAndDesign.tooltipText'), {
      render: false,
    }),
    tooltipPlacement: 'top',
    tooltipArrow: false,
  },
];
