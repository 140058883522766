import React, { useEffect, useState } from "react";
import { Box, Chip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router";

const MyOrderPageFilter = ({
                             setValue,
                             watch,
                             reset,
                             searchParams,
                             allProductTypes,
                             setSearchParams,
                             buildParams
                           }) => {
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const queryParams = useLocation();

  const getProductList = () => {
    const searchParams = new URLSearchParams(queryParams.search);
    const typeFilterKeys: string[] = [];
    searchParams.forEach((value, key) => {
      if (key.startsWith('typeFilters')) {
        const filterKey = key.match(/\[(\d+)\]/)?.[1];
        if (filterKey) {
          typeFilterKeys.push(filterKey); // Store the number
        }
      }
    });
    return typeFilterKeys
  };

  useEffect(() => {
    if (searchParams) {
      const productList = getProductList() || [];
      const list = [];
      Array.isArray(productList) && productList.length > 0 && productList.map((product: any) => {
        const selectedProduct = allProductTypes.length > 0 &&
            allProductTypes.find((list: any) => list?.id.toString() === product.toString());
        list.push(selectedProduct?.name);
      });
      setSelectedProductList(list);
      setStatus(searchParams.get('status') || '');
      setStartDate(searchParams.get('dateFilters[initialDate]') || null);
      setEndDate(searchParams.get('dateFilters[finalDate]') || null);
    }
  }, [searchParams, allProductTypes]);

  const handleDelete = (item) => {
    setSelectedProductList((prev) => prev.filter((product) => product !== item));
    const findItemId = allProductTypes.length > 0 && allProductTypes.find((product: any) => product.name === item).id || null;
    const productList = watch('productList').filter((item: any) => item !== findItemId.toString());
    setValue('productList', productList);
    setTimeout(() => {
      setSearchParams(buildParams());
    }, 1000)
  };

  return (
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
        {selectedProductList.length > 0 &&
            selectedProductList.map((item, index) => (
                <Chip
                    key={index}
                    label={item}
                    onDelete={() => handleDelete(item)}
                    deleteIcon={<CloseIcon style={{
                      color: '#fff',
                      fontSize: '18px'
                    }}/>}
                    sx={{
                      backgroundColor: '#0053F0',
                      color: '#fff',
                      fontSize: '14px'
                    }}
                />
            ))
        }
        {status && (
            <Chip
                label={status.replace('+', ' ')}
                onDelete={() => {
                  setValue('status', '');
                  setSearchParams(buildParams());
                }}
                deleteIcon={<CloseIcon style={{
                  color: '#fff',
                  fontSize: '18px'
                }}/>}
                sx={{
                  backgroundColor: '#0053F0',
                  color: '#fff',
                  fontSize: '14px'
                }}
            />
        )}
        {startDate && endDate && (
            <Chip
                label={`${startDate} - ${endDate}`}
                onDelete={() => {
                  setValue('startDate', null);
                  setValue('endDate', null);
                  setSearchParams(buildParams());
                }}
                deleteIcon={<CloseIcon style={{
                  color: '#fff',
                  fontSize: '18px'
                }}/>}
                sx={{
                  backgroundColor: '#0053F0',
                  color: '#fff',
                  fontSize: '14px'
                }}
            />
        )}
        {(selectedProductList.length > 0 || status || (startDate && endDate)) && (
            <Typography
                onClick={() => {
                  setSelectedProductList([]);
                  reset();
                  setSearchParams(buildParams(true));
                  window.location.reload();
                }}
                sx={{
                  color: '#0053F0',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontSize: '14px',
                }}
            >
              Clear all
            </Typography>
        )}
      </Box>
  );
};

export default MyOrderPageFilter;