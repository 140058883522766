import {
  Alert,
  AlertTitle,
  AppBar, Box, Container, Toolbar,
} from '@mui/material';
import IQTopNavIcon from 'icons/IQTopNavIcon.svg';
import { Urls } from 'navigation/Urls';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailsFromShortUrl, selectSharedUrl } from 'services/clientStockAssetsSlice';
import { useEffect } from 'react';
import { backgroundDefaultColor } from 'styles/common_styles';

export default function StockAssetsConfirmation() {
  const { shortUrl } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sharedUrlContent = useSelector(selectSharedUrl);

  useEffect(() => {
    if (Object.keys(sharedUrlContent).length === 0) dispatch(getDetailsFromShortUrl(shortUrl));
  }, [sharedUrlContent]);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      minHeight: '100vh',
      width: '100vw',
      backgroundColor: backgroundDefaultColor,
    }}
    >
      <AppBar position="fixed"
        sx={{
          backgroundColor: 'black',
          height: '50px',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Container sx={{
          maxWidth: 'initial !important',
          marginLeft: 0,
          marginRight: 0,
        }}
        >
          <Toolbar
            disableGutters
            sx={{ minHeight: '50px !important', padding: '0 !important', justifyContent: 'space-between' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 !important' }}>
              <Box onClick={() => navigate(`/${Urls.Themes}`)}
                sx={{ cursor: 'pointer', padding: '0 !important', width: '126px' }}
              >
                <img src={IQTopNavIcon} alt="IQTopNavIcon" />
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box sx={{
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <Alert
          severity="success"
          sx={{
            border: '1px solid #DFF0D8',
            background: '#DFF0D8',
            borderRadius: '4px',
            textAlign: 'center',
            color: '#3C763D',
            '& .MuiAlert-icon': { color: '#3C763D' },
          }}
        >
          <AlertTitle>Thank you!</AlertTitle>
          {Object.keys(sharedUrlContent).length > 0 && sharedUrlContent.emailRecipient}
          <br />
          <br />
          Your sales representative will be in contact with you shortly.
        </Alert>
      </Box>
    </Box>
  );
}
