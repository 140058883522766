import { Urls } from 'navigation/Urls';

import useProduct from 'hooks/useProduct';

import ShippingPolicyForm from 'pages/ecommerce/store-policies/shipping-policy/ShippingPolicyForm';
import ContentLoader from 'components/contentLoader/ContentLoader';
import EcommerceType, { EcommerceStateObject } from 'models/EcommerceType';
import { StatusType } from 'models/StatusType';

export default function ShippingPolicy() {
  const { onBack, onSubmit, product } = useProduct<EcommerceType,
  StatusType<EcommerceStateObject>>(Urls.Shipping);

  return (
    <ContentLoader status={product.status} message={product.message}>
      <ShippingPolicyForm
        product={product.content.data}
        onBack={onBack}
        onSubmit={onSubmit}
      />
    </ContentLoader>
  );
}
