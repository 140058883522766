import { ComponentProps } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ValidationProvider } from '@gannettdigital/shared-react-components';
import { Box, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { generateTooltipOrderedListElement } from 'shared/generateTooltipText';
import {DefaultPageLayout} from 'layouts/DefaultPageLayout';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import CoDomainField from 'components/domain-field/CoDomainField';
import i18n from 'i18n/i18n';
import schema from './PiQDomain.schema';

const { t } = i18n;

interface Props {
  onSubmit: (content: any) => void;
  onBack: () => void;
  domain: any;
}

export type PiQDomainProps = {
  subdomain: string;
  primaryDomain: string;
  tld: string;
};

type CoDomainFieldProps = ComponentProps<typeof CoDomainField>;

export const NoBackgroundRadioContainer = styled(Grid)`
  [role=group] > button { 
    max-width: max-content; 
  };
  
  .MuiButtonBase-root { 
    background: transparent;
  };
  
  .MuiButtonBase-root:hover { 
    background: transparent;
  };
  
  .MuiButtonBase-root:focus { 
    background: transparent;
  };
  
  .MuiButtonBase-root.Mui-selected { 
    background: transparent;
  };
  
  .MuiButtonBase-root.Mui-selected:hover { 
    background: transparent;
  };
  
  .MuiButtonBase-root.Mui-selected:focus { 
    background: transparent;
  };
  
  .MuiTouchRipple-root > * { 
    display: none;
  };
  
  [role=alert] { 
    position: absolute;
    top: 100%;
  };
`;

const domainOptions :CoDomainFieldProps['domainOptions'] = {
  schema,
  id: 'domain',
  name: 'primaryDomain',
  labelText: t('pages.piq.domain.domainLabel'),
  fontLabelWeight: '600',
  fullWidth: false,
  showError: true,
  disabled: true,
};

const subDomainOptions :CoDomainFieldProps['subDomainOptions'] = {
  schema,
  id: 'sub-domain',
  name: 'subdomain',
  labelText: t('pages.piq.domain.subDomainLabel'),
  fontLabelWeight: '600',
  fullWidth: false,
  showError: true,
  tooltipPlacement: 'top',
  // TODO: Refactor tooltips
  // @ts-ignore
  tooltipText: generateTooltipOrderedListElement(
    t('pages.piq.domain.domainTooltip'),
    t('pages.piq.domain.domainTooltipListHeading'),
    ['Learn', 'Discover', 'Go', 'Campaign', 'Buy', 'Find', '[City_name]', '[Service]'],
    {
      padding: '4px 0 0 8px', maxHeight: 'fit-content', top: '5px',
    },
    { paddingLeft: '18px', marginTop: 0, paddingBottom: '4px' },
  ),
  tooltipPaddingBottom: 20,
  // @ts-ignore
  tooltipPlacement: 'top',
  tooltipArrow: false,
};

const extensionOptions :CoDomainFieldProps['extensionOptions'] = {
  schema,
  id: 'extension',
  name: 'tld',
  labelText: t('pages.piq.domain.extensionLabel'),
  fontLabelWeight: '600',
  fullWidth: false,
  showError: true,
  disabled: true,
};

export default function PiQDomainForm({ domain, onSubmit, onBack } :Props) {
  const methods = useForm<PiQDomainProps>({
    mode: 'all',
    defaultValues: {
      primaryDomain: domain?.primaryDomain || 'localconsumer2',
      subdomain: domain?.subdomain || '',
      tld: domain?.tld || 'info',
    },
  });

  const {
    handleSubmit,
    formState: {
      isValid, isDirty, isSubmitSuccessful, isSubmitting,
    },
  } = methods;

  const handleFormSubmit = (data: PiQDomainProps) => onSubmit(data);

  return (
    <DefaultPageLayout
      disableContinue={!isValid}
      onBack={onBack}
      header={t('pages.piq.domain.header')}
      onContinue={handleSubmit(handleFormSubmit)}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Box
            id="mainForm"
            component="form"
            sx={{ display: 'flex' }}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <NoBackgroundRadioContainer>
              <Typography variant="subtitle2" fontSize="16px">
                {t('pages.piq.domain.radioHeading')}
              </Typography>
              <CoDomainField
                subDomainOptions={subDomainOptions}
                domainOptions={domainOptions}
                extensionOptions={extensionOptions}
              />
            </NoBackgroundRadioContainer>
            <CoNavigationConfirm showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)} />
          </Box>
        </FormProvider>
      </ValidationProvider>
    </DefaultPageLayout>
  );
}
