import {Urls} from 'navigation/Urls';
import {MenuType} from 'services/navigationSlice';
import TopNavStatusEnum from "services/TopNavStatusEnum";

export const targetedDisplayMenu: MenuType = ({
  label: 'Targeted Display',
  url: '',
  status: 'active',
  offeringType: 'TargetedDisplay',
  subSteps: [
    {
      label: 'General Questions',
      url: Urls.GeneralQuestions,
      status: 'incomplete',
    },
    {
      url: Urls.TargetedDisplay,
      label: 'Solution Strategy',
      status: 'incomplete',
    },
  ],
});
