/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useTranslation } from 'react-i18next';
import {
  IQFormInput, IQFormSelect, IQFormTextArea, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation } from 'react-router';
import { StepperRootProps } from 'layouts/StepperLayout';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import {
  createOrUpdateProvider,
  selectCurrentLocation,
  selectProviders,
  getProvider,
  selectProvider,
  getProviderCategories,
  listProviderCategories,
  selectProviderCategories,
  selectCurrentProviderCategories,
  setProvider,
  setCurrentProviderCategories,
  getBusinessLocation,
} from 'services/businessLocationSlice';
import { selectOrdersContent } from 'services/ordersSlice';
import { selectUserAccess } from 'services/userSlice';
import NeSelectChipCheckboxes from 'components/chips/NeSelectChipCheckboxes';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { TitleWithBadge } from 'pages/premium-listings/TitleWithBadge';
import { OrderFlow } from 'shared/constants';
import { checkKeyDown } from 'shared/sharedFunctions';
import schema from './PlHealthcareProvider.schema';
import { credentials, titles } from './PlHealthcareProviderData';

export default function PlHealthcareProvider(props: StepperRootProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { locationId } = useParams();
  const location = useSelector(selectCurrentLocation);
  const isOrderCompleted = useSelector(selectOrdersContent)?.completed;
  const provider = useSelector(selectProvider);
  const providers = useSelector(selectProviders);
  const providerCategories = useSelector(selectCurrentProviderCategories);
  const providerCategoriesList = useSelector(selectProviderCategories);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [providerItems, setProviderItems] = useState([]);
  const [categoryValues, setCategoryValues] = useState([]);
  const [providerCredentials, setProviderCredentials] = useState([]);
  const [credentialValues, setCredentialValues] = useState([]);
  const access = useSelector(selectUserAccess);
  const history: any = useLocation();
  const isRedirectedFromOverviewPage = useMemo(() => history.state?.previousPath.includes('overview'), [history]);
  const ordersContent = useSelector(selectOrdersContent);
  const { orderFlow } = ordersContent;
  const [isPunchlist, setIsPunchlist] = useState(false);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      prePopulateProvider: '',
      providerCategories: [],
      practiceName: '',
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      nationalProviderIdentifier: '',
      credentials: [],
      university: '',
      hospitalAffiliations: '',
      insurancesAccepted: '',
    },
  });

  const {
    handleSubmit, setValue, getValues, formState: {
      errors, isValid, isDirty, isSubmitSuccessful, isSubmitting,
    }, setError, clearErrors, watch, trigger,
  } = methods;

  useEffect(() => {
    dispatch(listProviderCategories(null));
    if (location.providerId) {
      dispatch(getProvider(location.providerId));
      dispatch(getProviderCategories(location.providerId));
    }
  }, [location]);

  const reloadCredentials = () => {
    setProviderCredentials(
      credentials.map(cred => ({ value: cred, label: cred })),
    );
    trigger('credentials');
  };

  const reloadProviderCategories = () => {
    setCategoryOptions(providerCategoriesList?.map((
      { id, name },
    ) => ({ value: id, label: name })));
    trigger('providerCategories');
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    orderFlow === OrderFlow.PUNCHLIST ? setIsPunchlist(true) : setIsPunchlist(false);
  }, []);

  useEffect(() => {
    if (locationId) dispatch(getBusinessLocation(locationId));
  }, [locationId]);

  useEffect(() => {
    const providersCopy = JSON.parse(JSON.stringify(providers));
    const mappedItems = providersCopy.filter(e => e.id !== parseInt(locationId, 10) && e.completed === true)
      .map(({ id, locationName }) => ({ value: id, description: locationName }));

    if (providers.length > 1) {
      mappedItems.push(
        {
          value: 'doNot',
          description: t('pages.premiumListings.location.healthcare.provider.doNotPrePopulate'),
        },
      );
    } else {
      mappedItems.push(
        {
          value: 'doNot',
          description: t('pages.premiumListings.location.healthcare.provider.prePopulateNoLocationsPlaceholder'),
        },
      );
      setValue('prePopulateProvider', 'doNot');
      clearErrors('prePopulateProvider');
    }
    setProviderItems(mappedItems);
  }, [providers]);

  useEffect(() => {
    reloadProviderCategories();
  }, [providerCategoriesList]);

  useEffect(() => {
    reloadCredentials();
  }, [credentials]);

  useEffect(() => {
    setCategoryValues(providerCategories.map(e => e.id));
  }, [providerCategories]);

  useEffect(() => {
    setValue('providerCategories', categoryValues);
    reloadProviderCategories();
  }, [categoryValues]);

  useEffect(() => {
    if (provider) {
      setValue('practiceName', provider.practiceName || '');
      setValue('title', provider.title || '');
      setValue('firstName', provider.firstName);
      setValue('middleName', provider.middleName || '');
      setValue('lastName', provider.lastName);
      setValue('nationalProviderIdentifier', provider.nationalProviderIdentifier);
      setCredentialValues(provider.credentials?.split(','));
      setValue('credentials', provider.credentials?.split(',') || []);
      reloadCredentials();

      setValue('university', provider.university || '');
      setValue('hospitalAffiliations', provider.hospitalAffiliations || '');
      setValue('insurancesAccepted', provider.insurancesAccepted || '');
    }
    if (provider.id === location.providerId) {
      setValue('prePopulateProvider', provider.prePopulateProvider || '');
    }
  }, [provider]);

  const prePopulateProviderChanged = (providerId) => {
    const selectedLocation = providers.filter(e => e.id === providerId);
    if (providerId !== undefined && providerId !== locationId && selectedLocation[0] !== undefined) {
      setValue('prePopulateProvider', selectedLocation[0].id);
      dispatch(getProvider(selectedLocation[0].providerId));
      dispatch(getProviderCategories(selectedLocation[0].providerId));
    }
  };

  const onFormSubmit = async () => {
    const values = getValues();

    const data = {
      prePopulateProvider: values.prePopulateProvider,
      credentials: values.credentials.join(','),
      firstName: values.firstName,
      hospitalAffiliations: values.hospitalAffiliations,
      id: locationId,
      insurancesAccepted: values.insurancesAccepted,
      lastName: values.lastName,
      middleName: values.middleName,
      nationalProviderIdentifier: values.nationalProviderIdentifier,
      practiceName: values.practiceName,
      title: values.title,
      university: values.university,
    };

    const resource = {
      providers: data,
      categoryIds: values.providerCategories,
    };

    await dispatch(createOrUpdateProvider({ locationId, resource }));
    await dispatch(setProvider({}));
    await dispatch(setCurrentProviderCategories([]));

    props.onContinue();
  };

  const [provCategoriesTouched, setProvCategoriesTouched] = useState(false);
  const [credsTouched, setCredsTouched] = useState(false);

  const validateProvCategories = () => {
    if (getValues('providerCategories').length > 0 && getValues('providerCategories').length < 4) {
      trigger('providerCategories');
    } else {
      setError('providerCategories', {
        message: t('pages.premiumListings.location.healthcare.provider.providerCategoriesRequired'),
      });
    }

    if (getValues('providerCategories')?.length > 3) {
      setError('providerCategories', {
        message: t('pages.premiumListings.location.healthcare.provider.providerCategoriesMax'),
      });
    }
  };

  useEffect(() => {
    if (provCategoriesTouched) validateProvCategories();
  }, [watch('providerCategories')]);

  const validateCreds = () => {
    if (getValues('credentials')?.length === 0) {
      setError('credentials', {
        message: 'Please select at least one credential or degree.',
      });
    } else trigger('credentials');
  };

  useEffect(() => {
    if (credsTouched) validateCreds();
  }, [watch('credentials')]);

  const hasError = Object.keys(errors).length > 0;

  useEffect(() => {
    let providerError = false;
    let credentialsError = false;
    if ((isValid && getValues('providerCategories')?.length === 0)
    || (isValid && getValues('providerCategories')?.length > 3)) {
      providerError = true;
    } else providerError = false;

    if (isValid && getValues('credentials')?.length === 0) {
      credentialsError = true;
    } else credentialsError = false;

    // if (isOrderCompleted && location.completed && canFulfillOrder(userRole)) {
    if (isOrderCompleted && location.completed && (access.isAdmin || access.canDoFulfillment)) {
      props.onUpdateStatus(
        getValues(),
        (!hasError && (!providerError && !credentialsError)),
      );
    } else props.onUpdateStatus(getValues(), (!hasError && isValid && (!providerError && !credentialsError)));
  }, [isValid, errors, watch('credentials'), watch('providerCategories')]);

  return (
    <ValidationProvider schema={schema}>
      <FormProvider {...methods}>
        <form id={props.formId} onSubmit={handleSubmit(onFormSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
          <GridContainer fullWidth>
            <GridItem marginBottom={0}>
              <Typography variant="overline" marginBottom={0}
                sx={{ textTransform: 'initial', fontSize: '18px', color: 'text.secondary' }}
              >
                Provider Listing
              </Typography>
            </GridItem>
            <GridItem>
              <Typography variant="h2" marginBottom={3}>Provider</Typography>
              <TitleWithBadge
                title={t('pages.premiumListings.location.healthcare.provider.providerDetails')}
                badge={t('pages.premiumListings.badge')}
                tooltip={t('pages.premiumListings.badgeTooltip')}
              />
            </GridItem>
            {!isPunchlist && (
            <GridItem sizes={[12]}>
              <IQFormSelect
                id="prePopulateProvider"
                name="prePopulateProvider"
                labelText={t('pages.premiumListings.location.healthcare.provider.prePopulate')}
                placeholder={t('pages.premiumListings.location.healthcare.provider.prePopulatePlaceholder')}
                tooltipText={t('pages.premiumListings.location.healthcare.provider.prePopulateTooltip')}
                tooltipPaddingBottom={18}
                tooltipPlacement="top"
                items={providerItems}
                theme={theme}
                fontLabelWeight="600"
                fullWidth
                required
                schema={schema}
                disabled={providers.length === 1}
                onChange={(event) => prePopulateProviderChanged(event.target.value)}
              />
            </GridItem>
            )}
            <GridItem>
              <NeSelectChipCheckboxes name="providerCategories" label values={categoryValues}
                labelText={t('pages.premiumListings.location.healthcare.provider.providerCategories')}
                placeholder={t('pages.premiumListings.location.healthcare.provider.providerCategoriesPlaceholder')}
                labelTooltipText={t('pages.premiumListings.location.healthcare.provider.providerCategoriesTooltip')}
                withCheckboxOptions
                maxItems={3} items={categoryOptions}
                labelTooltipPaddingBottom={18}
                labelTooltipPlacement="top"
                required
                supportCustomChips={false}
                requiredErrorMessage={
                  t('pages.premiumListings.location.healthcare.provider.providerCategoriesRequired')
                }
                onClose={() => { setProvCategoriesTouched(true); validateProvCategories(); }}
              />
            </GridItem>
            <GridItem>
              <Box sx={{ minHeight: '20px' }} />
            </GridItem>
            <GridItem sizes={[12]}>
              <IQFormInput
                id="practiceName"
                name="practiceName"
                labelText={t('pages.premiumListings.location.healthcare.provider.practiceName')}
                tooltipText=""
                tooltipPaddingBottom={18}
                tooltipPlacement="top"
                theme={theme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
            </GridItem>
            <GridItem>
              <Box sx={{ minHeight: '10px' }} />
            </GridItem>
            <GridItem>
              <TitleWithBadge
                title={t('pages.premiumListings.location.healthcare.provider.providerSpecificInformation')}
                badge={t('pages.premiumListings.badge')}
                tooltip={t('pages.premiumListings.badgeTooltip')}
              />
            </GridItem>
            <GridItem sizes={[4, 8]}>
              <IQFormSelect
                id="title"
                name="title"
                labelText={t('pages.premiumListings.location.healthcare.provider.providerTitle')}
                placeholder={t('pages.premiumListings.location.healthcare.provider.providerTitlePlaceholder')}
                items={titles}
                theme={theme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
              <IQFormInput
                id="firstName"
                name="firstName"
                labelText={t('pages.premiumListings.location.healthcare.provider.firstName')}
                theme={theme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
            </GridItem>
            <GridItem>
              <IQFormInput
                id="middleName"
                name="middleName"
                labelText={t('pages.premiumListings.location.healthcare.provider.middleName')}
                theme={theme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
            </GridItem>
            <GridItem>
              <IQFormInput
                id="lastName"
                name="lastName"
                labelText={t('pages.premiumListings.location.healthcare.provider.lastName')}
                theme={theme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
            </GridItem>
            <GridItem>
              <IQFormInput
                id="nationalProviderIdentifier"
                name="nationalProviderIdentifier"
                labelText={t('pages.premiumListings.location.healthcare.provider.nationalProviderIdentifier')}
                tooltipText={t('pages.premiumListings.location.healthcare.provider.nationalProviderIdentifierTooltip')}
                tooltipPaddingBottom={18}
                tooltipPlacement="top"
                theme={theme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
            </GridItem>
            <GridItem>
              <NeSelectChipCheckboxes name="credentials" label values={credentialValues}
                labelText={t('pages.premiumListings.location.healthcare.provider.providerCredentials')}
                placeholder={t('pages.premiumListings.location.healthcare.provider.providerCredentialsPlaceholder')}
                labelTooltipText=""
                labelTooltipPaddingBottom={18}
                withCheckboxOptions
                items={providerCredentials}
                maxItems={credentials.length}
                required
                supportCustomChips={false}
                requiredErrorMessage={
                  t('pages.premiumListings.location.healthcare.provider.providerCredentialsRequired')
                }
                onClose={() => { setCredsTouched(true); validateCreds(); }}
              />
            </GridItem>
            <GridItem>
              <Box sx={{ minHeight: '10px' }} />
            </GridItem>
            <GridItem>
              <TitleWithBadge
                title={t('pages.premiumListings.location.healthcare.provider.additionalInformation')}
                badge={t('pages.premiumListings.badge')}
                tooltip={t('pages.premiumListings.badgeTooltip')}
              />
            </GridItem>
            <GridItem>
              <IQFormInput
                id="university"
                name="university"
                labelText={t('pages.premiumListings.location.healthcare.provider.university')}
                tooltipText={t('pages.premiumListings.location.healthcare.provider.universityTooltip')}
                tooltipPaddingBottom={18}
                tooltipPlacement="top"
                theme={theme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
            </GridItem>
            <GridItem>
              <IQFormInput
                id="hospitalAffiliations"
                name="hospitalAffiliations"
                labelText={t('pages.premiumListings.location.healthcare.provider.hospitalAffiliations')}
                theme={theme}
                fontLabelWeight="600"
                fullWidth
                schema={schema}
              />
            </GridItem>
            <GridItem>
              <IQFormTextArea
                id="insurancesAccepted"
                name="insurancesAccepted"
                labelText={t('pages.premiumListings.location.healthcare.provider.insurancesAccepted')}
                fontLabelWeight="600"
                fullWidth
              />
            </GridItem>
          </GridContainer>
        </form>
        <CoNavigationConfirm
          showDialog={isDirty && !(isSubmitSuccessful || isSubmitting) && isRedirectedFromOverviewPage}
        />
      </FormProvider>
    </ValidationProvider>
  );
}
