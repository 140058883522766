import { IQCheckbox } from '@gannettdigital/shared-react-components';
import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import GridItem from 'components/grid/GridItem';
import { getOrder, selectOrdersContent } from 'services/ordersSlice';
import { getUserInfo, selectUserAccess, selectUserRole } from 'services/userSlice';

interface FulfillmentCheckboxProps {
  fieldName: string,
  isLocationCompleted: boolean,
}

const FulfillmentCheckbox = ({ fieldName, isLocationCompleted } : FulfillmentCheckboxProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const isOrderCompleted = useSelector(selectOrdersContent)?.completed;
  const userRole = useSelector(selectUserRole);
  const [showFulfillmentBox, setShowFulfillmentBox] = useState(false);
  const access = useSelector(selectUserAccess);

  useEffect(() => {
    if (!userRole) dispatch(getUserInfo('me'));
    if (!isOrderCompleted) dispatch(getOrder(orderId));

    // eslint-disable-next-line max-len
    if (isOrderCompleted && isLocationCompleted && (access.canDoFulfillment || access.isAdmin)) setShowFulfillmentBox(true);
  }, [isOrderCompleted, userRole, isLocationCompleted]);

  if (showFulfillmentBox) {
    return (
      <GridItem
        sizes={[12]}
        sx={{
          backgroundColor: theme.palette.primary.light,
          '& .MuiFormControlLabel-root': {
            margin: '0',
            padding: '8px 0',
          },
          '& .MuiFormHelperText-root': {
            margin: '0',
          },
        }}
      >
        <IQCheckbox
          label={(
            <Trans
              i18nKey="pages.premiumListings.fulfillmentText"
              components={{ strong: <strong /> }}
            /> as unknown as string
                )}
          name={fieldName}
          field={fieldName}
          required
          sx={{ margin: 0 }}
          hideErrors
        />
      </GridItem>
    );
  }
  return null;
};

export { FulfillmentCheckbox };
