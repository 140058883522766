/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { StatusType } from 'models/StatusType';
import { ExistingContentFormProps } from 'pages/websites/content/existing-content/ExistingContentForm';
import WebsitesType from 'models/WebsitesType';
import { SiteOfferingTypes } from 'models/SiteOfferingTypes';
import { RootState } from './redux/store';

import AxiosAPI from './common/axios';
import { thunkBuilder } from './common/functions';

import SitesEndpoints from './endpoints/SitesEndpoints';

import { createAsyncThunkCatchErrors } from './errorSlice';

export interface WebsitesState {
  website: StatusType<WebsitesType>;
  websiteUrls: StatusType<string[]>;
  additionalFeaturesContentCompleted: boolean;
  additionalFeaturesDesignCompleted: boolean;
  ageGateCompleted: boolean;
  existingContentCompleted: boolean;
  existingAssetsCompleted: boolean;
  examplesCompleted: boolean;
  sitePagesCompleted: boolean;
}

export enum WebsiteOfferingTypes {
  SILVER = 'Website',
  GOLD = 'GoldSite',
  ECOMM = 'EcommerceMicrosite',
  LANDING_PAGES = 'LandingPage',
  PREMIUM_LISTINGS = 'PremiumListing',
  BUSINESS_LOCATIONS = 'businessLocation',
  CUSTOM_SEO = 'SEOCustom',
}

const initialState: WebsitesState = {
  website: {
    content: {},
    status: 'idle',
    message: '',
  },
  websiteUrls: {
    content: [],
    status: 'idle',
    message: '',
  },
  additionalFeaturesContentCompleted: false,
  additionalFeaturesDesignCompleted: false,
  ageGateCompleted: false,
  existingContentCompleted: false,
  existingAssetsCompleted: false,
  examplesCompleted: false,
  sitePagesCompleted: false,
};

export const getWebsite = createAsyncThunkCatchErrors(
  'websites/get',
  async ({ websiteId, offeringType }: { websiteId: string; offeringType: string }) =>
    SitesEndpoints[offeringType].getSite(websiteId),
);

export const putWebsiteAdditionalFeatures = createAsyncThunkCatchErrors(
  'websites/putAdditionalFeatures',
  async ({ websiteId, content, offeringType }
  : { websiteId: string, content: any, offeringType: SiteOfferingTypes }) =>
    SitesEndpoints[offeringType].putWebsiteAdditionalFeatures(websiteId, content)
  ,
);

export const putWebsiteAdditionalFeaturesBlog = createAsyncThunkCatchErrors(
  'websites/putAdditionalFeaturesBlog',
  async ({ websiteId, content, offeringType }
  : { websiteId: string, content: any, offeringType: SiteOfferingTypes }) =>
    SitesEndpoints[offeringType].putWebsiteAdditionalFeaturesBlog(websiteId, content)
  ,
);

export const putWebsiteExamples = createAsyncThunkCatchErrors(
  'websites/putExamples',
  async ({ websiteId, content, offeringType }:
  { websiteId: string, content: any, offeringType: SiteOfferingTypes }) =>
    SitesEndpoints[offeringType].putWebsiteExamples(websiteId, content)
  ,
);

export const putWebsiteExistingContent = createAsyncThunkCatchErrors(
  'websites/putExistingContent',
  async ({ websiteId, content, offeringType }:
  { websiteId: string, content: ExistingContentFormProps, offeringType: SiteOfferingTypes }) => {
    let payload = content;
    let portContentExistingWebsite = false;

    /*
      ** Endpoint does not accept assets and options props **
      ---
      If user has chosen not to port existing content => set form fields to null

      If options property is not "Yes" or "No" as in current implementation
          => no update done and action will be rejected
    */

    switch (content.portContentExistingYesNo) {
      case 'yes':
        payload = { ...content };
        portContentExistingWebsite = true;
        delete payload.assets;
        delete payload.portContentExistingYesNo;
        break;
      case 'no':
        payload = {
          ...content,
          urlsToMigrate: null,
          numberOfPages: null,
          urlsToRemove: null,
          rewriteExistingContent: null,
        };
        portContentExistingWebsite = false;
        delete payload.assets;
        delete payload.portContentExistingYesNo;
        break;
      default:
        return content;
    }
    return SitesEndpoints[offeringType]
      .putWebsiteExistingContent(websiteId, { ...payload, portContentExistingWebsite });
  },
);

export const putWebsiteExistingAssetConsent = createAsyncThunkCatchErrors(
  'websites/putExistingAssetConsent',
  async ({ websiteId, content }: { websiteId: string | number; content: any }) => {
    const response = await AxiosAPI.put(`/websites/${websiteId}/existing-asset-consent/`, content);
    return response.data;
  },
);

export const putWebsiteCurrentStep = createAsyncThunkCatchErrors(
  'websites/putCurrentStep',
  async ({
    websiteId,
    section,
    step,
    offeringType,
  }: {
    websiteId: string;
    section: string;
    step: string;
    offeringType: SiteOfferingTypes;
  }) => {
    return SitesEndpoints[offeringType].putWebsiteCurrentStep(websiteId, section, step);
  },
);

export const putWebsiteAgeGate = createAsyncThunkCatchErrors(
  'sitePages/putWebsiteAgeGate',
  async ({ websiteId, content, offeringType } : { websiteId: string, content: string, offeringType: string }) => {
    return SitesEndpoints[offeringType].putWebsiteAgeGate(websiteId, content);
  },
);

export const putWebsiteSitePagesNotes = createAsyncThunkCatchErrors(
  'sitePages/putNotes',
  async ({ websiteId, content, offeringType } : { websiteId: string, content: string, offeringType: string }) => {
    return SitesEndpoints[offeringType].putWebsiteSitePagesNotes(websiteId, content);
  },
);

export const getWebsiteUrls = createAsyncThunkCatchErrors(
  'websites/getUrls',
  async (gmaid: string) => {
    const response = await AxiosAPI.get(`/websites/url/${gmaid}`);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const slice = createSlice({
  name: 'websites',
  initialState,
  reducers: {
    clearAdditionalFeaturesContentCompleted(state) {
      state.additionalFeaturesContentCompleted = false;
    },
    clearAdditionalFeaturesDesignCompleted(state) {
      state.additionalFeaturesDesignCompleted = false;
    },
    clearAgeGateCompleted(state) {
      state.ageGateCompleted = false;
    },
    clearExistingContentCompleted(state) {
      state.existingContentCompleted = false;
    },
    clearExistingAssetsCompleted(state) {
      state.existingAssetsCompleted = false;
    },
    setExistingAssetsCompleted(state) {
      state.existingAssetsCompleted = true;
    },
    clearExamplesCompleted(state) {
      state.examplesCompleted = false;
    },
    clearSitePagesCompleted(state) {
      state.sitePagesCompleted = false;
    },
  },
  extraReducers: (builder) => {
    thunkBuilder(builder)
      .addCase(getWebsite, 'website')
      .addCase(getWebsiteUrls, 'websiteUrls');
    builder
      .addCase(putWebsiteAdditionalFeaturesBlog.fulfilled, (state, action) => {
        state.website.content = action.payload;
        state.additionalFeaturesContentCompleted = true;
      })
      .addCase(putWebsiteAdditionalFeatures.fulfilled, (state, action) => {
        state.website.content = action.payload;
        state.additionalFeaturesDesignCompleted = true;
      })
      .addCase(putWebsiteAgeGate.fulfilled, (state, action) => {
        state.website.content = action.payload;
        state.ageGateCompleted = true;
      })
      .addCase(putWebsiteExistingContent.fulfilled, (state, action) => {
        state.website.content = action.payload;
        state.existingContentCompleted = true;
      })
      .addCase(putWebsiteExistingAssetConsent.fulfilled, (state, action) => {
        state.website.content = action.payload;
        state.existingAssetsCompleted = true;
      })
      .addCase(putWebsiteExamples.fulfilled, (state, action) => {
        state.website.content = action.payload;
        state.examplesCompleted = true;
      })
      .addCase(putWebsiteSitePagesNotes.fulfilled, (state, action) => {
        state.website.content = action.payload;
        state.sitePagesCompleted = true;
      })
      .addCase(putWebsiteCurrentStep.fulfilled, (state, action) => {
        state.website.content = action.payload;
      })
      .addMatcher(
        isAnyOf(
          putWebsiteAdditionalFeaturesBlog.fulfilled,
          putWebsiteAdditionalFeatures.fulfilled,
          putWebsiteExistingContent.fulfilled,
          putWebsiteExistingAssetConsent.fulfilled,
          putWebsiteExamples.fulfilled,
          putWebsiteCurrentStep.fulfilled,
          putWebsiteSitePagesNotes.fulfilled,
        ),
        (state) => {
          state.website.status = 'idle';
        },
      )
      .addMatcher(
        isAnyOf(
          putWebsiteAdditionalFeaturesBlog.pending,
          putWebsiteAdditionalFeatures.pending,
          putWebsiteExistingContent.pending,
          putWebsiteExistingAssetConsent.pending,
          putWebsiteExamples.pending,
          putWebsiteCurrentStep.pending,
          putWebsiteSitePagesNotes.pending,
        ),
        (state) => {
          state.website.status = 'loading';
        },
      )
      .addMatcher(
        isAnyOf(
          putWebsiteAdditionalFeaturesBlog.rejected,
          putWebsiteAdditionalFeatures.rejected,
          putWebsiteExistingContent.rejected,
          putWebsiteExistingAssetConsent.rejected,
          putWebsiteExamples.rejected,
          putWebsiteCurrentStep.rejected,
          putWebsiteSitePagesNotes.rejected,
        ),
        (state, action) => {
          state.website.status = 'failed';
          state.website.message = action.error?.message;
        },
      );
  },
});

export const {
  clearAdditionalFeaturesContentCompleted,
  clearAdditionalFeaturesDesignCompleted,
  clearAgeGateCompleted,
  clearExistingContentCompleted,
  clearExistingAssetsCompleted,
  setExistingAssetsCompleted,
  clearExamplesCompleted,
  clearSitePagesCompleted,
} = slice.actions;

export const selectWebsites = (state: RootState) => state.websites.website;
export const selectWebsiteId = (state: RootState) => state.websites.website.content?.id;

/* eslint-disable operator-linebreak */
export const selectAdditionalFeaturesContentCompleted = (state: RootState) =>
  state.websites.additionalFeaturesContentCompleted;

/* eslint-disable operator-linebreak */
export const selectAdditionalFeaturesDesignCompleted = (state: RootState) =>
  state.websites.additionalFeaturesDesignCompleted;

/* eslint-disable operator-linebreak */
export const selectAgeGateCompleted = (state: RootState) =>
  state.websites.ageGateCompleted;

/* eslint-disable operator-linebreak */
export const selectExistingContentCompleted = (state: RootState) => state.websites.existingContentCompleted;

/* eslint-disable operator-linebreak */
export const selectExistingAssetsCompleted = (state: RootState) => state.websites.existingAssetsCompleted;

/* eslint-disable operator-linebreak */
export const selectSitePagesCompleted = (state: RootState) => state.websites.sitePagesCompleted;

export const selectExamplesCompleted = (state: RootState) => state.websites.examplesCompleted;

export default slice.reducer;
