import Button from '@mui/material/Button';
import {useEffect, useState} from 'react';
import {
  CircularProgress, FormControl,
  Grid, IconButton, MenuItem,
  Paper, Select, Snackbar, Typography,
} from '@mui/material';
import {backgroundDefaultColor} from 'styles/common_styles';
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridPaginationModel
} from "@mui/x-data-grid";
import {isNull} from "lodash";
import {Box} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {t} from "i18next";
import {
  filterData,
  getAllAccounts,
  getAllData,
  getRecordsByAccount, handleRowSubmit, saveData
} from "pages/google-search-console/GscApi";
import CoTopNav from "components/topNav/CoTopNav";
import * as XLSX from 'xlsx';
import {
  buttonStyle,
  fileNameStyle,
  uploadContainerStyle
} from "pages/google-search-console/GscUtility";

export default function GoogleSearchConsole() {
  const [rows, setRows] = useState<any>([]);
  const [urls, setUrls] = useState<string[]>([]);
  const [checkedUrls, setCheckedUrls] = useState<any>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [results, setResults] = useState<any>([]);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [gmaIds, setGmaIds] = useState<string[]>([]);
  const [resultRows, setResultRows] = useState<any>([]);
  const [fileName, setFileName] = useState<string>(t('gscIndexing.noFileChosen'));
  const [hoverEnable, setHoverEnable] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [firstCall, setFirstCall] = useState<boolean>(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [recordSnackbarOpen, setRecordSnackbarOpen] = useState(false);
  const [submittedCount, setSubmittedCount] = useState(0);
  const [accountName, setAccountName] = useState('');
  const [accountId, setAccountId] = useState('');
  const [gscAccountId, setGscAccountId] = useState('');
  const [existingAccounts, setExistingAccounts] = useState([]);
  const [accountView, setAccountView] = useState<boolean>(false);
  const [displayAccount, setDisplayAccount] = useState('');

  useEffect(() => {
    const fetchAccounts = async () => {
      const data = await getAllAccounts();
      setExistingAccounts(data);
    };
    fetchAccounts();
  }, []);

  const handleFileUpload = (file: File) => {
    if (file) {
      const fileName = file.name;
      setFileName(fileName);
      if (fileName.includes('.xlsx') || fileName.includes('.xls')) {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const result = e.target?.result;
          if (result instanceof ArrayBuffer) {
            const data = new Uint8Array(result);
            const workbook = XLSX.read(data, {type: 'array'});
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const sheetData: any[][] = XLSX.utils.sheet_to_json(firstSheet, {header: 1});
            const gmaIdList: string[] = [];
            const urlList: string[] = [];
            sheetData.forEach((row) => {
              const [gmaid, url] = row;
              if (gmaid && url) {
                gmaIdList.push(gmaid.trim());
                urlList.push(url.trim());
              }
            });
            setGmaIds(gmaIdList);
            setUrls(urlList);
            setCheckedUrls(new Array(urlList.length).fill(false));
            setData(gmaIdList, urlList);
          }
        };
        reader.readAsArrayBuffer(file);
      } else if (fileName.toString().includes('.txt')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;
          if (typeof content === "string") {
            const lines = content.split('\n').filter(url => url.trim() !== '');
            const gmaIdList = [];
            const urlList = [];
            lines.forEach(line => {
              const [gmaid, url] = line.split(',');
              if (gmaid && url) {
                gmaIdList.push(gmaid.trim());
                urlList.push(url.trim());
              }
            })
            setGmaIds(gmaIdList);
            setUrls(urlList);
            setCheckedUrls(new Array(urlList.length).fill(false));
            setData(gmaIdList, urlList);
          }
        };
        reader.readAsText(file);
      }
    }
  };

  const handleFileInputReset = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleFileUpload(event.target.files[0]);
    }
    event.target.value = '';
  };

  useEffect(() => {
    if (checkedUrls.includes(false)) {
      setSelectAll(false);
    }
  }, [checkedUrls]);

  const columns: GridColDef[] = [
    {
      field: 'select',
      headerName: 'Select',
      width: 150,
      renderHeader: (params) => (
          <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
          />),
      renderCell: (params) => (
          <input
              type="checkbox"
              checked={checkedUrls[params.id] == true}
              onChange={(e) => {
                const newCheckedUrls = [...checkedUrls];
                newCheckedUrls[params.id] = !newCheckedUrls[params.id];
                setCheckedUrls(newCheckedUrls);
              }
              }
          />
      ),
    },
    {
      field: 'gmaid',
      headerName: 'GMAID',
      width: 350,
    },
    {
      field: 'url',
      headerName: 'URL',
      width: 550,
    },
  ];

  function handleRetry(id: String) {
    handleRowSubmit(id);
    setTimeout(() => {
      handleRefresh();
    }, 3000);
  }

  const resultColumns: GridColDef[] = [
    {
      field: 'gmaid',
      headerName: 'GMAID',
      width: 150,
    },
    {
      field: 'url',
      headerName: 'URL',
      width: 500,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 170,
      filterable: false
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 170,
      filterable: false
    },
    {
      field: 'action',
      headerName: '',
      width: 130,
      filterable: false,
      renderCell: (params) => {
        return (results.length > 0 && results[params.id]?.status !== 'Success' && (
                <Button variant={"contained"}
                        onClick={() => handleRetry(results[params.id].id)}>Retry</Button>
            )
        )
      },
      sortable: false,
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      width: 450,
      filterable: false,
      sortable: false,
    },
  ];

  function setData(gmaIdList: any, urlList: any) {
    const tempRows = [];
    gmaIdList.map((gmaid, index) => {
      tempRows.push({id: index, gmaid: gmaid, url: urlList[index]})
    });
    setRows(tempRows)
  }

  function setResultData() {
    const tempRows = [];
    results.map((row, index) => {
      tempRows.push({
        id: index,
        db_id: row.id,
        gmaid: row.gmaid,
        url: row.url,
        status: row.status,
        date: !isNull(row.dateExecuted) ? new Date(row.dateExecuted).toLocaleDateString('en-GB') : t('gscIndexing.noResult'),
        remarks: row.remarks,
      })
    })
    setResultRows(tempRows.sort((a, b) => b.db_id - a.db_id));
  }

  useEffect(() => {
    if (!isFiltered) {
      setFirstCall(true);
      handleRefresh()
    }
  }, [!isFiltered]);

  useEffect(() => {
    firstCall && getAll();
  }, [results]);

  useEffect(() => {
    !firstCall && getAll();
  }, [currentPage, pageSize]);

  useEffect(() => {
    firstCall && setResultData();
  }, [results]);

  useEffect(() => {
    setResultData();
    setTotalRows(results.length);
  }, [pageSize, currentPage, results]);

  const getAll = async () => {
    if (!isFiltered) {
      let data;
      try {
        if (!accountView) {
          data = await getAllData(currentPage, pageSize);
        } else {
          data = await getRecordsByAccount(currentPage, pageSize, displayAccount);
        }
        data.sort((a: any, b: any) => b.id - a.id);
        if (firstCall) {
          setResults([]);
          setResults(data);
        } else {
          setResults(updateResults(results, data));
        }
        setResultData();
        setFirstCall(false);
      } catch (error) {
        console.error("Error: ", error)
      }
    }

  }

  const updateResults = (results, newResults) => {
    const resultsMap = new Map();
    results.forEach(result => resultsMap.set(result.id, result));
    newResults.forEach(newResult => resultsMap.set(newResult.id, newResult));
    return Array.from(resultsMap.values());
  };

  const handleSubmit = async () => {
    let indexingArray = []
    let count = 0;
    for (let i = 0; i < urls.length; i++) {
      if (checkedUrls[i]) {
        setRows(prevRows => prevRows.filter((data) => data.id != i));
        indexingArray.push({
          gmaId: gmaIds[i],
          url: urls[i],
          gscAccountId: gscAccountId
        })
        count++;
      }
    }
    await saveData(indexingArray);
    getAll();
    setFirstCall(true);
    setSubmittedCount(count);
    setSnackbarOpen(true);
    setCheckedUrls(checkedUrls.filter(value => value !== true));
  };

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setCheckedUrls(new Array(rows.length).fill(newSelectAll));
  };

  const CustomFooter = () => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'flex-start', p: 2}}>
          <Button variant="contained" onClick={() => handleSubmit()}>Submit to be
            Indexed</Button>
        </Box>
    );
  };

  const handleRefresh = () => {
    setLoading(true);
    setCurrentPage(0);
    setTimeout(() => {
      getAll();
      setLoading(false);
    }, 1000);
  };

  const handlePageChange = (params: GridPaginationModel) => {
    setCurrentPage(params.page);
    setPageSize(params.pageSize);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseRecordSnackbar = () => {
    setRecordSnackbarOpen(false);
  }

  const handleFilterChange = (filterModel: GridFilterModel) => {
    const filterItem = filterModel.items[0];
    if (filterItem && filterItem.value == '' || filterModel.items.length === 0) {
      setIsFiltered(false);
    } else {
      try {

        setTimeout(async () => {
          if (filterItem && filterItem.field && filterItem.value && filterItem.value !== '') {
            setIsFiltered(true);
            setCurrentPage(0);
            const {field, value} = filterItem;
            const data = await filterData(field, value);
            if (data && data.length < 1) {
              setRecordSnackbarOpen(true);
            }
            setResults(data);
            setResultData();
          }
        }, 1000)
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const handleViewChange = (event) => {
    const {value} = event.target;
    if (value === "all") {
      getAll();
      setFirstCall(true);
      setAccountView(false);
      setCurrentPage(0);
    } else {
      setDisplayAccount(existingAccounts.find(acc => acc.accountName === event.target.value).id);
      setAccountView(true);
      setFirstCall(true);
      getAll();
    }
  };

  return (
      <Box>
        <CoTopNav/>
        <Paper elevation={0} sx={{backgroundColor: backgroundDefaultColor, paddingTop: '100px'}}>
          <Typography variant="h2" mb={3}
                      px={6}>{t('gscIndexing.googleSearchConsole')}</Typography>
          <Grid px={6}>
            <Typography variant="h6" mb={1}>
              {t('gscIndexing.selectAccount')}
            </Typography>

            <FormControl fullWidth margin="normal">
              <Select
                  labelId="account-select-label"
                  value={accountName}
                  onChange={(e) => {
                    setAccountName(e.target.value);
                    setAccountId(existingAccounts.find(acc => acc.accountName === e.target.value).accountId);
                    setGscAccountId(existingAccounts.find(acc => acc.accountName === e.target.value).id);
                  }}
                  displayEmpty
              >
                <MenuItem value=""
                          disabled>{t('gscIndexing.selectFromExistingAccounts')}</MenuItem>
                {existingAccounts.map((account) => (
                    <MenuItem key={account.accountId} value={account.accountName}>
                      {account.accountName}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
            {accountName != '' && (
                <>
                  <Typography variant="h6" mb= {1}>
                    {t('gscIndexing.uploadUrlsFile')}
                  </Typography>
                  <div style={uploadContainerStyle}>
                    <input
                        type="file"
                        accept=".xlsx, .xls ,.txt"
                        id="file-upload"
                        onChange={handleFileInputReset}
                        style={{display: 'none'}}
                    />
                    <label htmlFor="file-upload" className="file-label">
              <span
                  style={buttonStyle(hoverEnable)}
                  onMouseEnter={() => setHoverEnable(true)}
                  onMouseLeave={() => setHoverEnable(false)}
              >
                {t('gscIndexing.chooseUrlsFile')}
              </span>
                      <span style={fileNameStyle}>{fileName}</span>
                    </label>
                  </div>
                </>
            )}
            {urls.length > 0 && (
                <>
                  <Typography variant="h6" mb={1}>
                    {t('gscIndexing.selectUrls')}
                  </Typography>
                  <DataGrid
                      rows={rows}
                      columns={columns}
                      disableRowSelectionOnClick
                      slots={{
                        footer: CustomFooter,
                      }}
                  />
                </>
            )}
            <Snackbar
                open={snackbarOpen}
                onClose={handleCloseSnackbar}
                autoHideDuration={6000}
                message={`Successfully sent ${submittedCount} URL(s) for indexing.`}
            />
            <Snackbar
                open={recordSnackbarOpen}
                onClose={handleCloseRecordSnackbar}
                autoHideDuration={1000}
                message={`No Record Found`}
            />
            {
              <>
                <Grid mt={1}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Typography variant="h4">
                    {t('gscIndexing.indexingHistory')}
                  </Typography>
                  <IconButton onClick={handleRefresh} color="primary" aria-label="refresh">
                    {loading ? (
                        <CircularProgress size={24} color="inherit"/>
                    ) : (
                        <RefreshIcon/>
                    )}
                  </IconButton>
                  <Select  onChange={handleViewChange} defaultValue="all">
                    <MenuItem value="all">{t('gscIndexing.viewAllRecords')}</MenuItem>
                    {existingAccounts.map((account) => (
                        <MenuItem key={account.accountId} value={account.accountName}>
                          {account.accountName}
                        </MenuItem>
                    ))}
                  </Select>
                </div>
                </Grid>
                <Grid mt={2}>
                  <DataGrid
                      rows={resultRows}
                      columns={resultColumns}
                      pagination
                      rowCount={totalRows}
                      pageSizeOptions={[10, 20, 50, 100]}
                      paginationModel={{page: currentPage, pageSize: pageSize}}
                      onPaginationModelChange={handlePageChange}
                      onFilterModelChange={handleFilterChange}
                  />
                </Grid>
              </>
            }
          </Grid>
        </Paper>
      </Box>
  );
}
