import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { IQInputEmail, IQPhoneInputContainer } from '@gannettdigital/shared-react-components';
import styled from '@emotion/styled';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from 'react-i18next';
import { Button, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { S1 } from 'components/labels/S1';
import { Text } from 'components/labels/Text';
import GridItem from 'components/grid/GridItem';
import GridContainer from 'components/grid/GridContainer';
import schema from './Contact.schema';

interface Props {
  register: any
}

const ButtonStyled = styled(Button)`
  min-width: 35px;
  top: 45%;
`;

const EmailUserStyled = styled('div')`
  .MuiFormControl-root {
    gap: 0;
    label {
      margin-bottom: -8px;
    }
  }
`;

const AddButton = styled(Button)`
  &.Mui-disabled {
    color: #FFFFFF
  }
  font-weight: 600;
  text-transform: none;
  font-size: 14px;
  line-height: 18px;
  padding: 0 40px 0 0;
  margin-left: 24px;
  min-width: 0;
`;

export default function AdditionalContactForm(props: Props) {
  const {
    register,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const { fields: emailsArray, append: appendEmail, remove: removeEmail } = useFieldArray({
    name: 'emails',
  });

  const { fields: phonesArray, append: appendPhone, remove: removePhone } = useFieldArray({
    name: 'phones',
  });

  const [openDeleteEmailConfirm, setOpenDeleteEmailConfirm] = useState(false);
  const [openDeletePhoneConfirm, setOpenDeletePhoneConfirm] = useState(false);
  const [funRemoveEmail, setFunRemoveEmail] = useState({ fn: () => {} });
  const [funRemovePhone, setFunRemovePhone] = useState({ fn: () => {} });

  const addNewEmailRow = () => {
    appendEmail({ email: '' });
  };

  const addNewPhoneRow = () => {
    appendPhone({ phone: '', extension: '' });
  };

  const deleteEmail = (key) => {
    removeEmail(key);
    setOpenDeleteEmailConfirm(false);
  };

  const deletePhone = (key) => {
    removePhone(key);
    setOpenDeleteEmailConfirm(false);
  };

  const showDialogDeleteEmail = (funDelete, key) => {
    setFunRemoveEmail({ fn: () => funDelete(key) });
    setOpenDeleteEmailConfirm(true);
  };

  const showDialogDeletePhone = (funDelete, key) => {
    setFunRemovePhone({ fn: () => funDelete(key) });
    setOpenDeletePhoneConfirm(true);
  };

  const newPhoneRow = (data, key) => (
    <GridItem sizes={[11, 1]} columnSpacing={0} key={key}>
      <IQPhoneInputContainer
        {...register(`phones.${key}.phone`)}
        country="US"
        hasExtension
        defaultValue=""
        extensionOptions={{
          name: `phones.${key}.extension`,
          defaultValue: '',
        }}
        phoneLabelOptions={{
          tooltipText: 'Phone numbers include country codes by default and can be edited if needed.',
        }}
      />
      <ButtonStyled onClick={() => showDialogDeletePhone(deletePhone, key)}>
        <DeleteIcon />
      </ButtonStyled>
    </GridItem>
  );

  const newEmailRow = (data, key) => (
    <GridItem sizes={[11, 1]} columnSpacing={0} key={key}>
      <EmailUserStyled>
        <IQInputEmail
          {...register(
            `emails.${key}.email`,
            { required: 'Please enter a valid email in this format: name@example.com.' },
          )}
          labelText={`${t('pages.addons.emailHosting.emailTitle')} *`}
          fontLabelWeight="600"
          theme={theme}
          field={`emails.${key}.email`}
          schema={schema}
          label={t('pages.addons.emailHosting.emailTitle')}
          required
          tooltipPaddingBottom={16}
          fullWidth
          adornmentIcon={<MailOutlineIcon />}
        />
      </EmailUserStyled>
      <ButtonStyled onClick={() => showDialogDeleteEmail(deleteEmail, key)}>
        <DeleteIcon />
      </ButtonStyled>
    </GridItem>
  );

  return (
    <>
      <GridItem marginBottom={2} marginTop={3}>
        <S1 sx={{
          fontSize: '20px', fontWeight: 600, lineHeight: '25px', color: '#03334E',
        }}
        >
          Additional Points of Contact
        </S1>
      </GridItem>
      <GridItem marginBottom={4}>
        <Text style={{ fontSize: '18px', lineHeight: '27px' }}>
          Provide at least one additional point of contact *
        </Text>
      </GridItem>
      {phonesArray.map((data, index) => newPhoneRow(data, index))}
      {emailsArray.map((data, index) => newEmailRow(data, index))}
      <GridContainer fullWidth>
        <GridItem marginTop={2}>
          <AddButton
            onClick={() => addNewPhoneRow()}
          >
            +&nbsp;&nbsp;
            <u>Add Phone Number</u>
          </AddButton>
        </GridItem>
        <GridItem>
          <AddButton
            onClick={() => addNewEmailRow()}
          >
            +&nbsp;&nbsp;
            <u>Add Email</u>
          </AddButton>
        </GridItem>
      </GridContainer>
      <CoModalConfirm open={openDeleteEmailConfirm}
        title="Remove Email Account?"
        description="Are you sure you want to remove the email account ?"
        confirmText="Remove"
        handleClose={() => setOpenDeleteEmailConfirm(false)}
        handleConfirm={funRemoveEmail.fn}
      />
      <CoModalConfirm open={openDeletePhoneConfirm}
        title="Remove Phone Number?"
        description="Are you sure you want to remove the phone number ?"
        confirmText="Remove"
        handleClose={() => setOpenDeletePhoneConfirm(false)}
        handleConfirm={funRemovePhone.fn}
      />
    </>
  );
}
